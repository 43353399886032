import { Grid } from '@mui/material';
import { instantState, useDebounce } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';

import LeadsOrPetitionsList from '../Common/LeadsOrPetitions/Lists';

export const PetitionsList: React.FC<any> = () => {
  const location: any = useLocation();
  const [loader, setLoader] = useState(true);
  const [listData, setListData] = useState<any>({});
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const { editId } = useParams();
  const [params, setParams] = useState({
    associated: location?.pathname?.includes('associated') ? true : false,
    id: '',
    leadsSelected: '',
    name: '',
    order: '',
    orgId: editId,
    orgType: '',
    organizationId: '',
    skip: 0,
    sortby: '',
    status: '',
    take: 50,
    visaId: '',
  });

  const debouncedParams = useDebounce(params, 500);

  const getApi = useCallback(() => {
    setHasMoreResults(false);

    getAllListData(
      `${COMMON_ENDPOINT.Petitions}?skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}${
        userDetail.role_id === 2
          ? `&organizationId=${String(userDetail.companyId)}`
          : userDetail.role_id === 3 && !location?.state?.organization
          ? `&firmId=${String(userDetail.companyId)}`
          : userDetail.role_id === 3 && location?.state?.organization
          ? `&orgId=${String(debouncedParams.orgId)}&associated=${String(
              debouncedParams.associated,
            )}`
          : ''
      }${
        debouncedParams.name !== ''
          ? `&name=${String(debouncedParams.name)}`
          : ''
      }`,
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setListData((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          setListData({ data, total });
        }
        setGetMoreLoader(false);
        setLoader(false);
        const allList = await instantState(setListData);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedParams.associated,
    debouncedParams.name,
    debouncedParams.orgId,
    debouncedParams.skip,
    debouncedParams.take,
    userDetail.companyId,
    userDetail.role_id,
  ]);
  useEffect(() => {
    getApi();
  }, [debouncedParams, getApi]);

  const getMoreList = () => {
    setGetMoreLoader(true);
    setParams((pre) => ({
      ...pre,
      skip: pre.skip + 50,
    }));
  };
  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12} className="pr-2rem">
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <LeadsOrPetitionsList
            showType="petitions"
            setParams={setParams}
            lists={listData}
            params={params}
            setLoader={setLoader}
            showAddBtn={userDetail.role_id === 3 ? true : false}
            hasMoreResults={hasMoreResults}
            getMoreLoader={getMoreLoader}
            getMoreList={getMoreList}
            orgId={editId}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PetitionsList;
