import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Grid, Tab, Typography } from '@mui/material';
import PendingInvites from 'components/PendingInvites';
import { PetitionerPrivateRoutes } from 'config';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';

import useStyles from '../../PrivateStyle';

export const AssociationRequest: React.FC<any> = () => {
  const classes = useStyles();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  // const [params, setParams] = useState<any>({
  //   firmName: '',
  //   skip: 0,
  //   take: 50,
  // });
  // const [cfParams, cfSetParams] = useState<any>({
  //   firmName: '',
  //   skip: 0,
  //   take: 50,
  // });
  const [tabValue, setTabValue] = React.useState('1');
  const [loader, setLoader] = useState(true);
  const [associatedList, setAssociatedList] = useState<any>({});

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === '2') {
      setLoader(true);
      getAssocitedList();
    }
  };
  const getAssocitedList = () => {
    getAllListData(
      userDetail?.userRoleName === 'petitioner'
        ? `/petitoner${String(SETUP_ENDPOINT.ManageUsers)}/firms`
        : `${String(COMMON_ENDPOINT.AssociatedList)}`,
    )
      .then((resp: any) => {
        setAssociatedList(resp);
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12} className="pr-2rem">
        <Typography component={'div'} className="documentTab">
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example">
                {userDetail?.role_id === 2 && (
                  <Tab
                    label="Association Request"
                    value="1"
                    style={{ color: 'white' }}
                  />
                )}
                {userDetail?.role_id === 3 && (
                  <Tab
                    label="Associated List"
                    value="2"
                    style={{ color: 'white' }}
                  />
                )}
              </TabList>
            </Box>
            <TabPanel value="1">
              <PendingInvites
                loader={loader}
                setLoader={setLoader}
                isDashboard={false}
              />
              {/* <Pagination
                total={
                  !_.isEmpty(petitionerData?.take)
                    ? Number(petitionerData?.total)
                    : 0
                }
                skip={params.skip}
                take={params.take}
                setParams={setParams}
              /> */}
            </TabPanel>
            <TabPanel value="2">
              {loader ? (
                <div className="liberty-loader" />
              ) : (
                <Box sx={{ width: '100%' }} className="documentTab">
                  <Grid item md={12} sm={12}>
                    <Typography
                      component={'div'}
                      className="d-flex-jae full-screen-searc mb-1">
                      <Typography
                        component={'div'}
                        className="d-flex-a small-search"
                        sx={{
                          backgroundColor: '#84898C',
                          borderRadius: '4px',
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Typography component={'div'} className="configureBox">
                    {associatedList?.length > 0 ? (
                      associatedList?.map((petitioner: any, index: number) => (
                        <NavLink
                          to={
                            userDetail.role_id === 2 &&
                            `${
                              PetitionerPrivateRoutes.PETITIONS.ASSOCIATELAWFIRM
                            }/${String(petitioner.id)}`
                          }
                          key={index}>
                          <Typography className="configureItem">
                            <Typography className={classes.userItemInner} />
                            <Typography className="d-flex-a d-flex-sb w-100">
                              <Typography
                                component={'div'}
                                className="d-flex-3">
                                <Typography className="black-white-text ctitle">
                                  {petitioner.firmName} ( {petitioner.firmMail}{' '}
                                  )
                                </Typography>
                                <Typography
                                  component={'div'}
                                  className="d-flex-a w-100 mt-2">
                                  <Typography className="rl-text">
                                    Referred By:{' '}
                                    {petitioner.referred_by
                                      ? petitioner.referred_by
                                      : '-'}
                                  </Typography>
                                </Typography>
                              </Typography>
                              <Typography className="blue-text d-flex-1 text-right">
                                Associated
                              </Typography>
                              {/* <Typography className="blue-text d-flex-1 text-right">
                                {petitioner.userRoleName === 'petitioner'
                                  ? 'Resend'
                                  : 'Accept'}
                              </Typography> */}
                            </Typography>
                          </Typography>
                        </NavLink>
                      ))
                    ) : (
                      <Typography
                        className="note-box2"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                        }}>
                        <Typography className="inb2" />
                        <Typography className="black-white-text ctitle hideshow">
                          No Record(s) Found.
                        </Typography>
                      </Typography>
                    )}
                  </Typography>
                </Box>
              )}
              {/* <Pagination
                total={
                  !_.isEmpty(associatedList?.take)
                    ? Number(associatedList?.total)
                    : 0
                }
                skip={cfParams.skip}
                take={cfParams.take}
                setParams={cfSetParams}
              /> */}
            </TabPanel>
          </TabContext>
        </Typography>
      </Grid>
    </Grid>
  );
};
export default AssociationRequest;
