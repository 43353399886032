import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import Space from 'assets/images/space.png';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getPetitionApiData } from 'utils/Handlers';
import Storage from 'utils/Storage';

import BreadCrumbsHeader from './BreadCrumbsHeader';
import Footer from './Footer';
import LoadingToRedirect from './LoadingToRedirect';
import Navbar from './Navbar';

export const useStyles = makeStyles((theme) => ({
  space: {
    backgroundColor: '#09171f',
    backgroundImage: `url(${'https://d17p81usn86n3p.cloudfront.net/space.png'})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    bottom: '0',
    left: '0',
    minHeight: '100%',
    position: 'fixed',
    right: '0',
    top: '0',
    width: '100%',
  },
}));

type headConfigsProps = {
  routes: any;
  showTools?: boolean;
  showHeader?: boolean;
  addEditRoute?: any;
  hasComponent?: string;
  isPetition?: boolean | string;
  children: any;
  DefaultHeaderHide?: boolean;
  setSubmissionPopup?: any;
};

const PrivateLayout = (props: headConfigsProps) => {
  const {
    routes,
    showTools,
    showHeader,
    addEditRoute,
    hasComponent,
    isPetition,
    children,
    DefaultHeaderHide = false,
    setSubmissionPopup,
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const TokenStatus = Storage.getTokens();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const { petitions } = useAppSelector((store: any) => store.petitionUser);
  const { petitionId } = useParams();
  const [loader, setPetitionLoader] = useState(true);
  const [menuOpen, setMenuOpen] = useState<any>(false);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const apiCall = useCallback(() => {
    if (petitionId && !isNaN(Number(petitionId))) {
      if (
        _.isEmpty(petitions) ||
        (!_.isEmpty(petitions) &&
          !_.isEmpty(petitions.petitionData) &&
          petitions.petitionData.id !== Number(petitionId))
      ) {
        setPetitionLoader(true);
        getPetitionApiData(petitionId, dispatch)
          .then(() => {
            setPetitionLoader(false);
          })
          .catch(() => {
            setPetitionLoader(false);
          });
      } else {
        setPetitionLoader(false);
      }
    } else {
      setPetitionLoader(false);
    }
  }, [dispatch, petitionId, petitions]);

  useEffect(() => {
    apiCall();
  }, [petitionId, location.key, apiCall, petitions]);

  if (
    !isNaN(Number(petitionId)) &&
    petitions?.petitionData?.id === Number(petitionId) &&
    petitions?.petitionData?.isAffilateCase &&
    [5].includes(petitions?.petitionData?.affilateRoleId)
  ) {
    toast.info('This case as view only access.');
    navigate(`/${String(userDetail?.userRoleName)}/petitions/my-petitions`);
    return null;
  }
  return !_.isEmpty(TokenStatus) ? (
    loader ||
    (petitionId &&
      !isNaN(Number(petitionId)) &&
      (_.isEmpty(petitions) ||
        petitions?.petitionData?.id !== Number(petitionId))) ? (
      <>
        <Typography component={'div'} className={classes.space} />
        <div className="liberty-loader" />
      </>
    ) : (
      <div>
        <Typography component={'div'} className={classes.space} />
        <Grid container>
          <Grid item md={12}>
            <Typography component={'div'} className="main-container">
              <Typography component={'div'} className="dark-theme">
                <BreadCrumbsHeader
                  menuOpen={menuOpen}
                  routes={routes}
                  showTools={showTools}
                  showHeader={showHeader}
                  addEditRoute={addEditRoute}
                  hasComponent={hasComponent}
                  isPetition={isPetition}
                  DefaultHeaderHide={DefaultHeaderHide}
                  setSubmissionPopup={setSubmissionPopup}
                />
                {showHeader && (
                  <Navbar setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
                )}

                <Typography
                  component={'div'}
                  style={{
                    minHeight: '90vh',
                    position: 'relative',
                    zIndex: 0,
                  }}
                  onClick={(event) => {
                    // event.stopPropagation();
                    setMenuOpen(false);
                  }}>
                  <Typography
                    component={'div'}
                    className="mainpadding"
                    style={
                      menuOpen
                        ? {
                            pointerEvents: 'none',
                          }
                        : {}
                    }>
                    {[2, 4, 5].includes(userDetail.role_id) &&
                      showHeader &&
                      routes?.linkShow && (
                        <Grid container>
                          <Grid
                            item
                            md={12}
                            sx={{ paddingLeft: 'unset !important' }}
                            className="petitioner-menu">
                            <Typography
                              component={'div'}
                              className="nav-menu nav-flex-4"
                              sx={{
                                justifyContent: 'flex-start !important',
                              }}>
                              {' '}
                              {userDetail.role_id !== 5 && (
                                <Typography
                                  component={'div'}
                                  className="nav-item black-white-text"
                                  sx={{ paddingRight: '1.5rem' }}>
                                  <NavLink
                                    to={`/${String(
                                      userDetail?.userRoleName,
                                    )}/dashboard`}
                                    className={({ isActive }) =>
                                      isActive ? 'item-active' : 'item-inactive'
                                    }>
                                    Dashboard
                                  </NavLink>
                                </Typography>
                              )}
                              {[2, 5].includes(userDetail.role_id) && (
                                <Typography
                                  component={'div'}
                                  className="nav-item black-white-text"
                                  sx={{ paddingRight: '1.5rem' }}>
                                  <NavLink
                                    to={
                                      userDetail.role_id === 2
                                        ? `/${String(
                                            userDetail?.userRoleName,
                                          )}/petitions/leads`
                                        : `/${String(
                                            userDetail?.userRoleName,
                                          )}/leads`
                                    }
                                    className={({ isActive }) =>
                                      isActive ? 'item-active' : 'item-inactive'
                                    }>
                                    Leads
                                  </NavLink>
                                </Typography>
                              )}
                              {[2, 3, 5].includes(userDetail.role_id) && (
                                <Typography
                                  component={'div'}
                                  className="nav-item black-white-text"
                                  sx={{ paddingRight: '1.5rem' }}>
                                  <NavLink
                                    to={`/${String(
                                      userDetail?.userRoleName,
                                    )}/petitions/my-petitions`}
                                    className={({ isActive }) =>
                                      isActive ? 'item-active' : 'item-inactive'
                                    }>
                                    Petitions
                                  </NavLink>
                                </Typography>
                              )}
                              {/* {userDetail.role_id === 3 && (
                          <Typography
                            component={'div'}
                            className="nav-item black-white-text">
                            <NavLink
                              to={`/${String(
                                userDetail?.userRoleName,
                              )}/petitions/payment-report`}
                              className={({ isActive }) =>
                                isActive ? 'item-active' : 'item-inactive'
                              }>
                              Reports
                            </NavLink>
                          </Typography>
                        )} */}
                              {userDetail.role_id === 4 && (
                                <Typography
                                  component={'div'}
                                  className="nav-item black-white-text"
                                  sx={{ paddingRight: '1.5rem' }}>
                                  <NavLink
                                    to={`/${String(
                                      userDetail?.userRoleName,
                                    )}/comments`}
                                    className={({ isActive }) =>
                                      isActive ? 'item-active' : 'item-inactive'
                                    }>
                                    Comments
                                  </NavLink>
                                </Typography>
                              )}
                              {userDetail.role_id === 4 && (
                                <Typography
                                  component={'div'}
                                  className="nav-item black-white-text"
                                  sx={{ paddingRight: '1.5rem' }}>
                                  <NavLink
                                    to={`/${String(
                                      userDetail?.userRoleName,
                                    )}/documentreview`}
                                    className={({ isActive }) =>
                                      isActive ? 'item-active' : 'item-inactive'
                                    }>
                                    Pending documents for review
                                  </NavLink>
                                </Typography>
                              )}
                              {userDetail.role_id !== 5 && (
                                <Typography
                                  component={'div'}
                                  className="nav-item black-white-text"
                                  sx={{ paddingRight: '1.5rem' }}>
                                  <NavLink
                                    to={`/${String(
                                      userDetail?.userRoleName,
                                    )}/social`}
                                    className={({ isActive }) =>
                                      isActive ? 'item-active' : 'item-inactive'
                                    }>
                                    Social
                                  </NavLink>
                                </Typography>
                              )}
                              {/* {userDetail.role_id === 4 && (
                            <Typography
                              component={'div'}
                              className="nav-item black-white-text">
                              <NavLink
                                to={`/${String(
                                  userDetail?.userRoleName,
                                )}/dashboard/review-document`}
                                className={({ isActive }) =>
                                  isActive ? 'item-active' : 'item-inactive'
                                }>
                                Review Documents
                              </NavLink>
                            </Typography>
                          )} */}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    {children}
                  </Typography>
                </Typography>
                <Footer menuOpen={menuOpen} />
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
    )
  ) : (
    <LoadingToRedirect />
  );
};

export default PrivateLayout;
