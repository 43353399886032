import { Grid, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { RolesModuleList } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';

import PetitionerInfo from './PetitionerInfo';
import PetitionerRequestAdditionalDocuments from './PetitionerRequestAdditionalDocuments';
import PetitionerRequestAdditionalDocumentsList from './PetitionerRequestAdditionalDocumentsList';

export const RequestAdditionalDocument: React.FC<any> = () => {
  const { petitionId } = useParams();
  const { petitions } = useAppSelector((store: any) => store.petitionUser);
  const [moduleList, setModuleList] = useState<Array<RolesModuleList>>([]);
  const [reloadAPI, setReloadAPI] = useState(true);

  const getModuleListApi = useCallback(async () => {
    await getAllListData(COMMON_ENDPOINT.AdminRoles)
      .then((response: any) => {
        const dependentObj = { module_id: 99, module_name: 'Dependent' };
        const responseByList = response.filter((val) =>
          val.module_id !== 1 && val.module_id !== 3 && val.module_id !== 5
            ? val
            : '',
        );
        responseByList.push(dependentObj);
        const options = [];
        responseByList.map((e: any) => {
          options.push({ label: e.module_name, value: e.module_id });
        });
        setModuleList(options);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    getModuleListApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={12} sm={12}>
        <Typography
          component={'div'}
          className="w-title black-white-text d-flex-a"
          sx={{ marginTop: 'unset !important' }}>
          Request Additional Documents
        </Typography>
        {!_.isEmpty(petitions) ? (
          <>
            <PetitionerInfo petitions={petitions} />
            <PetitionerRequestAdditionalDocuments
              petitions={petitions}
              petitionId={petitionId}
              moduleList={moduleList}
              reloadAPI={reloadAPI}
              setReloadAPI={setReloadAPI}
            />
            <PetitionerRequestAdditionalDocumentsList
              petitions={petitions}
              petitionId={petitionId}
              moduleList={moduleList}
              reloadAPI={reloadAPI}
            />
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};
