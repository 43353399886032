import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Popper,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Win from 'assets/images/winning.png';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import InfinityScroll from 'components/InfinityScroll';
import { instantState } from 'hooks';
import _ from 'lodash';
import HistoryComments from 'pages/private/Client/Common/HistoryComments';
import { CopyStrategyQuestionList } from 'pages/private/Common/Strategy/copyStrategy/CopyStrategyQuestionList';
import { StrategySessionList } from 'pages/private/Common/Strategy/copyStrategy/StrategySessionList';
import { Translate } from 'pages/private/Common/Translate/Translate';
import { WizardForm } from 'pages/private/Common/wizardForm';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import {
  Fill_FORM_ENDPOINT,
  ORG_TREE_VIEW_ENDPOINT,
  SETUP_ENDPOINT,
  STRATEGY_ENDPOINT,
} from 'utils/Constant';
import { alertOptions } from 'utils/helper';

export const OraganizationStrategyInfo: React.FC<any> = () => {
  const { editId, formId } = useParams();
  const location: any = useLocation();
  const wizardRef: any = React.useRef();
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState(true);
  const [treeList, setTreeList] = React.useState([]);
  const [hcData, setHCData] = useState<any>({
    comments: [],
    history: [],
    loading: true,
    selectedQuestion: {},
  });

  const [winningStrategyAnchor, setWinningStrategyAnchor] = useState<any>({
    anchorEl: null,
    open: false,
  });

  const [winningStrategies, setWinningStrategies] = useState<any>({
    data: [],
    total: 0,
  });
  const [winningParams, setWinningParams] = useState<any>({
    search: '',
    skip: 0,
    take: 10,
  });

  const [selectedIndex, setSelectedIndex] = useState<any>({
    index: null,
    show: false,
  });
  const [chooseStrategy, setChooseStrategy] = React.useState<boolean>(false);
  const [strategyQus, setStrategyQus] = React.useState<{
    open: boolean;
    questionList: Array<any>;
    previousStrategyId: any;
  }>({
    open: false,
    previousStrategyId: '',
    questionList: [],
  });
  const [questionLoader, setQuestionLoader] = useState<boolean>(false);

  const winningStrategyId = winningStrategyAnchor.open ? 'simple-popper' : null;

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  // const { petitions } = useAppSelector((store: any) => store.petitionUser);

  const getStrategywizardFormData = useCallback(async () => {
    try {
      const listData = await getAllListData(
        ORG_TREE_VIEW_ENDPOINT(
          location.state?.data?.form_id,
          'org_strategy',
          String(editId),
          userDetail.companyId,
          location?.pathname.includes('invited') ? false : true,
          location.state?.data?.strategy_id,
        ),
      );
      setTreeList([...listData]);
      sessionUpdateFormId();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWinningStrategies = () => {
    getAllListData(
      `${String(SETUP_ENDPOINT.ConfigSection)}/winning?search=${String(
        winningParams.search,
      )}&skip=${String(winningParams.skip)}&take=${String(winningParams.take)}`,
    )
      .then((resp: any) => {
        const mergeData = !_.isEmpty(winningStrategies.data)
          ? [...winningStrategies.data, ...resp.data]
          : resp.data;
        setWinningStrategies({
          data: mergeData,
          total: Number(resp.total),
        });
      })
      .catch((e: any) => {});
  };

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    const { currentTarget } = event;
    setWinningStrategyAnchor((pre) => ({
      ...pre,
      anchorEl: currentTarget,
      open: pre.open == true ? false : true,
    }));
    const winning = await instantState(setWinningStrategyAnchor);
    winning.anchorEl && getWinningStrategies();
  };

  const handleClickAway = () => {
    setWinningStrategyAnchor((pre) => ({
      ...pre,
      anchorEl: null,
      open: false,
    }));
  };

  const rowProps = (winning, index) => {
    return (
      <Typography component={'div'} className="pop-det-box">
        <Typography
          className={'pop-title '.concat(
            selectedIndex.index === index ? 'active' : '',
          )}
          onClick={() =>
            setSelectedIndex({
              index,
              show: !selectedIndex.show,
            })
          }>
          {winning.question}
        </Typography>
        {selectedIndex.index === index && (
          <Typography className="pop-dis">{winning.notes}</Typography>
        )}
      </Typography>
    );
  };

  const sessionUpdateFormId = () => {
    updateData(
      '',
      {},
      STRATEGY_ENDPOINT.updateOrganizationStrategyFormId(
        location.state?.data?.strategy_id,
        formId,
      ),
    );
  };

  const onSelectStrategy = async (copyStrategy) => {
    setQuestionLoader(true);
    try {
      const list = await getAllListData(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `/petition-form/${copyStrategy?.form_id}?leadId=${copyStrategy?.lead_id}&formType=lead&answerOnly=true`,
      );
      const resp = list && Array.isArray(list) ? list[0] : null;
      setQuestionLoader(false);
      setStrategyQus((pre) => ({
        ...pre,
        open: true,
        previousStrategyId: copyStrategy?.id,
        questionList:
          resp &&
          resp?.questionDetails &&
          Array.isArray(resp?.questionDetails) &&
          resp?.questionDetails.length
            ? resp?.questionDetails
                .map((el) => {
                  return {
                    ...el,
                    answer: el?.previousAnswer,
                    status: true,
                  };
                })
                .sort((a: any, b: any) =>
                  String(a.question).toLowerCase() <
                  String(b.question).toLowerCase()
                    ? -1
                    : 1,
                )
            : [],
      }));
    } catch (error) {
      setQuestionLoader(false);
      toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
  };

  const getCopyStrategyQusFormData = useCallback(
    (questionSelectedList: Array<any>) => {
      return {
        copiedStrategyId: strategyQus?.previousStrategyId,
        firmId: userDetail.companyId,
        isCopied: true,
        list: questionSelectedList.map((data: any) => {
          return {
            answer: data.answer,
            formId: location.state?.data?.form_id,
            previousAnswerId: data?.formAnswerId,
            questionId: data.id,
            sectionId: location.state?.data?.form_id,
          };
        }),
        organizationAssociated: location?.pathname.includes('invited')
          ? false
          : true,
        organizationId: String(editId),
        strategyId: location.state?.data?.strategy_id,
      };
    },
    [
      editId,
      location?.pathname,
      location.state?.data?.form_id,
      location.state?.data?.strategy_id,
      strategyQus?.previousStrategyId,
      userDetail.companyId,
    ],
  );
  const onSubmitCopyStategyQus = useCallback(
    async (questionSelectedList: Array<any>) => {
      try {
        setQuestionLoader(true);
        const bodyData = getCopyStrategyQusFormData(
          questionSelectedList && questionSelectedList.length
            ? questionSelectedList
            : [],
        );
        await addNewData(bodyData, `${Fill_FORM_ENDPOINT}/organization`);
        setStrategyQus((pre) => ({
          ...pre,
          open: false,
        }));
        setQuestionLoader(false);
        setChooseStrategy(false);
        setLoader(true);
        toast.success('Copy successfully', alertOptions);
        getStrategywizardFormData();
        wizardRef && wizardRef.current && wizardRef.current.childMethod();
      } catch (error) {
        setQuestionLoader(false);
      }
    },
    [getCopyStrategyQusFormData, getStrategywizardFormData],
  );

  useEffect(() => {
    getStrategywizardFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId]);

  useEffect(() => {
    getWinningStrategies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winningParams]);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12}>
        {loader && <div className="liberty-loader" />}
        <Typography
          component={'div'}
          className="d-flex-a d-flex-sb  mb-2 sticky-tab">
          <Typography
            component={'div'}
            className="black-white-text f-22 font-bold text-overflow-hidden">
            {treeList[0]?.name.slice(0, 50)}
            {treeList[0]?.name.length > 50 && '...'}
          </Typography>
          <Typography component={'div'}>
            <Button
              className="cancel-btn mr-2 "
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '0.9em !important',
                width: '120px !important',
              }}
              onClick={() => navigate(-1)}>
              <Typography
                className="o-box d-flex-a"
                sx={{ width: 'unset !important' }}
              />
              <ArrowBackIcon sx={{ marginRight: '.5rem' }} />
              Back
            </Button>
            {
              <Button
                className="n-add-btn"
                onClick={() => {
                  setChooseStrategy(true);
                }}>
                Copy Strategy
              </Button>
            }
          </Typography>
        </Typography>
        <Typography component={'div'}>
          {location.state?.data?.strategy_id !== '' && (
            <WizardForm
              focusElement={
                location.state?.focusElement
                  ? location.state?.focusElement
                  : null
              }
              ref={wizardRef}
              formType={'strategy'}
              list={treeList}
              tabHidden={true}
              formId={Number(formId)}
              beneficiaryID={null}
              leadId={null}
              strategyId={location.state?.data?.strategy_id}
              organizationId={editId}
              petitionID={null}
              showManageOption={true}
              setHCData={setHCData}
              sessionUpdateFormId={sessionUpdateFormId}
            />
          )}
        </Typography>
      </Grid>
      <Grid item md={2} sm={12}>
        <Typography component={'div'} className="bulk-add-box">
          <Typography component={'div'} className="add-inner">
            <Typography component={'div'} className="add-inner mb-1">
              <Typography component={'div'} className="add-inner-box" />
              <Typography className="link-txt pt-2 f-16" />
              <Typography
                className=" built-option d-flex-a"
                // aria-describedby={winningStrategyId}
                onClick={handleClick}>
                <img
                  src={Win}
                  alt="im"
                  className="mr-1"
                  style={{ width: '20px !important' }}
                />
                Winning Strategies
              </Typography>
              <Popper
                id={winningStrategyId}
                className="own-popper"
                open={winningStrategyAnchor.open}
                anchorEl={winningStrategyAnchor.anchorEl}
                placement="left">
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box sx={{ padding: '1rem 1rem 0.5rem 1rem' }}>
                    <Typography
                      className="new-searc"
                      sx={{ marginBottom: '.5rem' }}>
                      <CustomInput
                        placeHolder={'Type to filter'}
                        className={'custom-search-input'}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={async () => {
                                  setWinningStrategies({
                                    data: [],
                                    total: 0,
                                  });
                                  await instantState(setWinningStrategies);
                                  setWinningParams((prev) => ({
                                    ...prev,
                                    search: '',
                                    skip: 0,
                                    take: 10,
                                  }));
                                }}>
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setWinningStrategies({
                            data: [],
                            total: 0,
                          });

                          setWinningParams((prev) => ({
                            ...prev,
                            search: e.target.value,
                            skip: 0,
                            take: 10,
                          }));
                        }}
                        value={winningParams.search}
                      />
                    </Typography>
                    {winningStrategies.data &&
                    winningStrategies.data.length > 0 ? (
                      <InfinityScroll
                        paramState={setWinningParams}
                        height={'350px'}
                        scrollableTargetId={'winningStrategy'}
                        data={winningStrategies.data}
                        rowProps={rowProps}
                        count={winningStrategies.total}
                      />
                    ) : (
                      <Typography
                        component={'div'}
                        className="pop-det-box"
                        sx={{ height: '350px' }}>
                        <Typography className="pop-dis">
                          No Record(s) Found.
                        </Typography>
                      </Typography>
                    )}
                    <div className="pop-dis" style={{ textAlign: 'center' }}>
                      {!_.isEmpty(winningStrategies.data)
                        ? winningStrategies.data.length
                        : 0}{' '}
                      of {winningStrategies.total} winning strategies
                    </div>
                  </Box>
                </ClickAwayListener>
              </Popper>
            </Typography>
            <Translate />
            <Typography component={'div'} style={{ top: '92px' }}>
              {hcData.selectedQuestion && (
                <HistoryComments
                  hcData={hcData}
                  setHCData={setHCData}
                  wizardRef={wizardRef}
                  screenName={'strategy'}
                  formId={location.state?.data?.form_id}
                  organization={true}
                />
              )}
            </Typography>
          </Typography>
        </Typography>
      </Grid>
      {chooseStrategy && (
        <CustomModalView
          open={true}
          fullWidth={true}
          maxWidth={'xl'}
          scroll={'paper'}
          autoCloseDisable={true}
          isFilters={false}
          title="Strategy Session"
          close={() => setChooseStrategy(false)}>
          <StrategySessionList
            orgStrategy={true}
            formId={formId}
            onSelect={onSelectStrategy}
            loader={!strategyQus.open && questionLoader}
          />
        </CustomModalView>
      )}

      {strategyQus.open && (
        <CustomModalView
          open={true}
          fullWidth={true}
          maxWidth={'lg'}
          scroll={'paper'}
          autoCloseDisable={true}
          isFilters={false}
          title="Strategy Question List"
          close={() =>
            setStrategyQus((pre) => ({
              ...pre,
              open: false,
            }))
          }>
          <CopyStrategyQuestionList
            questionList={strategyQus.questionList}
            setQuestionList={setStrategyQus}
            onSubmit={onSubmitCopyStategyQus}
            questionLoader={questionLoader}
          />
        </CustomModalView>
      )}
    </Grid>
  );
};

export default OraganizationStrategyInfo;
