import Grid from '@mui/material/Grid';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomModalView from 'components/CustomModalView/customModalView';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  BeneficiaryPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import { instantState } from 'hooks';
import _ from 'lodash';
// import _ from 'lodash';
import moment from 'moment';
import { SectionList } from 'pages/private/Common/SectionList';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT, STRATEGY_ENDPOINT } from 'utils/Constant';
// import { getPetitionApiData } from 'utils/Handlers';
import { alertOptions } from 'utils/helper';

import { ListData } from '../../Client/Common/Stratergy';

export const Strategy: React.FC<any> = () => {
  const { editId, petitionId } = useParams();
  const navigate = useNavigate();
  const location: any = useLocation();
  // const dispatch = useAppDispatch();
  const { petitions } = useAppSelector((store: any) => store.petitionUser);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [leadId, setLeadId] = useState(null);
  const [strategyList, setStrategyList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [personList, setPersonList] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [selectedPersonId, setSelectedPersonId] = useState('');
  const [sectionSearch, setSectionSearch] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [benId, setBenId] = useState('');
  const [leadInfo, setLeadInfo] = useState<any>({});
  const [chooseSection, setChooseSection] = useState<{
    open: boolean;
    scheduleData: any | null;
  }>({
    open: false,
    scheduleData: null,
  });
  const [confirmOpen, setConfirmOpen] = useState<{
    strategyId: string | null;
    index: number | null;
    show: boolean;
    message: string;
    title: string;
    delete: boolean;
  }>({
    delete: true,
    index: null,
    message: '',
    show: false,
    strategyId: null,
    title: '',
  });
  const [searchParams] = useSearchParams();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const selectSections = async (sectionsInfo: any) => {
    try {
      const scheduleData = {
        ...chooseSection.scheduleData,
        form_id: sectionsInfo.id,
      };
      await sessionUpdateFormId(
        sectionsInfo.id,
        chooseSection?.scheduleData?.id,
      );
      openWizardFormAnswer(scheduleData);
    } catch (error) {
      console.warn(error);
    }
  };

  const sessionUpdateFormId = async (formId, strategyId) => {
    return await updateData(
      '',
      {},
      STRATEGY_ENDPOINT.updateStrategyFormId(strategyId, formId),
    );
  };

  const openWizardFormAnswer = (scheduleData: any) => {
    scheduleData.formId = scheduleData.form_id;
    scheduleData.paramFirmId = '';
    navigate(
      userDetail.role_id === 3
        ? petitionId
          ? `${AttorneyPrivateRoutes.PETITIONS.STRATEGYDETAIL}/${petitionId}${
              searchParams.get('orgId')
                ? '?orgId=' + searchParams.get('orgId')
                : ''
            }${
              searchParams.get('invited')
                ? '&invited=true&isPetition=true'
                : searchParams.get('associated')
                ? '&associated=true&isPetition=true'
                : ''
            }`
          : `${AttorneyPrivateRoutes.LEADS.STRATEGYDETAIL}/${editId}${
              searchParams.get('orgId')
                ? '?orgId=' + searchParams.get('orgId')
                : ''
            }${
              searchParams.get('invited')
                ? '&invited=true&isLead=true'
                : searchParams.get('associated')
                ? '&associated=true&isLead=true'
                : ''
            }`
        : userDetail.role_id === 2
        ? petitionId
          ? `${PetitionerPrivateRoutes.PETITIONS.STRATEGYDETAIL}/${petitionId}`
          : `${PetitionerPrivateRoutes.LEADS.STRATEGYDETAIL}/${editId}`
        : userDetail.role_id === 4
        ? petitionId
          ? `${String(
              BeneficiaryPrivateRoutes.BENEFICIARYPETITIONERSTRATEGYDETAIL,
            )}/${petitionId ? petitionId : editId}${
              searchParams.get('leadUser')
                ? `?leadUser=${searchParams.get('leadUser')}`
                : ''
            }${`&type=${'petition'}`}`
          : `${BeneficiaryPrivateRoutes.BENEFICIARYSTRATEGYDETAIL}/${
              petitionId ? petitionId : editId
            }`
        : petitionId
        ? `${String(
            AffilatePrivateRoutes.ATTORNEY_PETITIONS.STRATEGYDETAIL,
          )}/${petitionId}`
        : leadInfo?.isAffilateCase && leadInfo?.affilateRoleId === 3
        ? `${String(
            AffilatePrivateRoutes.ATTORNEY_LEADS.STRATEGYDETAIL,
          )}/${editId}`
        : `${String(
            AffilatePrivateRoutes.PETITIONER_LEADS.STRATEGYDETAIL,
          )}/${editId}`,
      {
        state: {
          affilateRoleId: leadInfo?.affilateRoleId,
          affilateUserList: leadInfo?.affilateUserList,
          data: {
            ...scheduleData,
            petitionId: petitionId || '',
          },
          isAffilateCase: leadInfo?.isAffilateCase,
          showType: location.state?.showType,
        },
      },
    );
  };
  const createScheduleFeedback = (scheduleData: any) => {
    scheduleData.petitionerId = '';
    scheduleData.benificieryId = scheduleData.benificiery_id;
    scheduleData.VisaId = '';
    scheduleData.petitionerUserId = '';
    scheduleData.organizationId = '';
    scheduleData.paramFirmId = '';
    scheduleData.leadId =
      scheduleData && scheduleData.lead_id ? scheduleData.lead_id : null;
    scheduleData.strategyId = scheduleData.id;
    if (scheduleData.form_id) {
      openWizardFormAnswer(scheduleData);
    } else {
      setChooseSection((pre) => ({
        ...pre,
        open: true,
        scheduleData: scheduleData,
      }));
    }
  };
  const cancelConfirmation = () => {
    setConfirmOpen((pre) => ({
      ...pre,
      delete: false,
      index: null,
      show: false,
      strategyId: null,
    }));
  };
  const createFeedback = async (imediateScheduleData: any) => {
    let scheduleData: any = {};
    const petitionerId = ''; // for now default added futur will be changed in dynamic
    if (imediateScheduleData) {
      scheduleData = imediateScheduleData;
    }
    scheduleData.strategyId = scheduleData.id;
    scheduleData.petitionerId = petitionerId ? petitionerId : '';
    scheduleData.leadId = await instantState(setLeadId);
    scheduleData.leadUserId =
      personList.find((e) => e.user_id === selectedPersonId)?.contactType ===
      'Benificiery'
        ? selectedPersonId
        : '';
    setChooseSection((pre) => ({
      ...pre,
      open: true,
      scheduleData: scheduleData,
    }));
    setSectionSearch('');
  };
  const getFormData = async () => {
    const startDateAndTime = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
    const endDateAndTime = moment()
      .add(30, 'minutes')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS');
    return {
      benificieryId:
        personList.find((e) => e.user_id === selectedPersonId)?.contactType ===
        'Benificiery'
          ? selectedPersonId
          : '',
      color: '',
      endDate: endDateAndTime,
      leadId: await instantState(setLeadId),
      note: '',
      petitionId: petitionId,
      startDate: startDateAndTime,
      status: 'pending',
      title: `Strategy Session For ${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        personList.find((e) => e.user_id === selectedPersonId)?.name
      }`,
      userId: Number(userDetail?.id),
    };
  };
  const addScheduleDetails = async () => {
    const createSchedule = await getFormData();
    try {
      const scheduleData = await addNewData(
        createSchedule,
        STRATEGY_ENDPOINT.scheduleStrategy,
      );
      cancelConfirmation();
      getStrategyList();
      if (scheduleData && scheduleData.data) {
        createFeedback(scheduleData.data);
      }
    } catch (error) {
      toast.error('Oops!!! Something went wrong', alertOptions);
    }
  };
  const scheduleNow = () => {
    setSubmit(true);
    if (selectedPersonId) {
      setConfirmOpen((pre) => ({
        ...pre,
        delete: false,
        index: null,
        message:
          'Do you wish to schedule an appointment and start the session now?',
        show: true,
        strategyId: null,
        title: 'CONFIRM SCHEDULE CREATION',
      }));
    }
  };
  const deleteStrategy = async (id: string) => {
    try {
      setLoader(true);
      await updateData(
        '',
        { delete: true },
        STRATEGY_ENDPOINT.strategyDelete(id),
      );
      cancelConfirmation();
      getStrategyList();
      toast.success('Deleted successfully', alertOptions);
    } catch (error) {
      setLoader(false);
      toast.error('Failed to delete', alertOptions);
    }
  };
  const deleteOrConfirm = (id: string) => {
    if (confirmOpen.delete) {
      deleteStrategy(id);
    } else {
      addScheduleDetails();
    }
  };

  const getPersonList = useCallback(async () => {
    try {
      const leadId = await instantState(setLeadId);
      const personList = await getAllListData(
        STRATEGY_ENDPOINT.strategyPersonList(leadId),
      );
      if (searchParams && searchParams.get('leadUser') !== '') {
        personList.filter((person) => {
          if (
            person.contactType === 'Benificiery' &&
            Number(person.id) === Number(searchParams.get('leadUser'))
          ) {
            setBenId(person.user_id);
          }
        });
      }

      setPersonList([
        ...(personList && personList.length
          ? personList.map((val) => {
              if (val.contactType === 'petitioner') {
                val.user_id = val.orgId;
              }
              return val;
            })
          : []),
      ]);
      return personList;
    } catch (error) {
      console.warn(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const petitionStrategyList = () => {
    getAllListData(
      `${String(STRATEGY_ENDPOINT.PetitionStrategyList)}/${String(petitionId)}`,
    )
      .then((resp: any) => {
        setPersonList([
          ...(resp && resp.length
            ? resp.map((val) => {
                if (val.contactType === 'petitioner') {
                  val.user_id = val.orgId;
                }
                return val;
              })
            : []),
        ]);
      })
      .catch((error: any) => {
        console.warn(error);
      });
  };

  useEffect(() => {
    if (userDetail.role_id === 3 && location.state?.showType !== 'leads') {
      petitionStrategyList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const getStrategyList = useCallback(async () => {
    try {
      const leadId = await instantState(setLeadId);
      const benId = await instantState(setBenId);
      let leadInfo = await instantState(setLeadInfo);
      const strategyList = await getAllListData(
        STRATEGY_ENDPOINT.leadLevelStrategyList(
          leadId,
          benId,
          leadInfo?.isAffilateCase ? true : false,
        ),
      );
      setLoader(false);
      if (_.isEmpty(leadInfo) && petitionId) {
        leadInfo = petitions?.petitionData;
      }
      setStrategyList(
        (userDetail?.role_id === 3 && !leadInfo?.isAffilateCase) ||
          (leadInfo?.isAffilateCase &&
            [3].includes(
              leadInfo?.affilateRoleId ||
                petitions?.petitionData?.affilateRoleId,
            ))
          ? strategyList
          : strategyList.filter((e) => e.form_id),
      );
      return strategyList;
    } catch (error) {
      setLoader(false);
      toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
  }, [petitionId, petitions?.petitionData, userDetail?.role_id]);

  const getLeadInfo = useCallback(async () => {
    try {
      setLoader(true);
      const leadInfos = await getAllListData(
        `${COMMON_ENDPOINT.LeadList}?id=${String(editId)}`,
      );

      setLeadInfo(
        leadInfos && leadInfos.data && leadInfos.data[0]
          ? leadInfos.data[0]
          : {},
      );
    } catch (error) {
      setLoader(false);
      toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
  }, [editId]);

  const leadOrPetitionSet = useCallback(async () => {
    if (editId) {
      setLeadId(editId);
      await getLeadInfo();
      await getPersonList();
      await getStrategyList();
    }
    if (petitionId) {
      setLeadId(petitions?.petitionData?.lead_id);
      setBenId(petitions?.petitionData?.beneficiary_id);
      getStrategyList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, petitionId, petitions]);

  useEffect(() => {
    leadOrPetitionSet();
  }, [editId, leadOrPetitionSet, petitions]);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={9} sm={12}>
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <ListData
            data={strategyList}
            createScheduleFeedback={createScheduleFeedback}
            setConfirmOpen={setConfirmOpen}
            personList={personList}
            selectedPersonId={selectedPersonId}
            scheduleNow={scheduleNow}
            submit={submit}
            setSelectedPersonId={setSelectedPersonId}
            headerShow={petitionId ? false : true}
            isAffilateCase={
              location.state?.showType === 'leads'
                ? leadInfo?.isAffilateCase
                : petitions?.petitionData?.isAffilateCase
            }
            affilateRoleId={
              location.state?.showType === 'leads'
                ? leadInfo?.affilateRoleId
                : petitions?.petitionData?.affilateRoleId
            }
          />
        )}

        <ConfirmDialog
          title={confirmOpen?.title}
          floatingBtnShow={true}
          open={confirmOpen.show}
          onConfirm={(act) =>
            act === 'yes'
              ? deleteOrConfirm(confirmOpen.strategyId)
              : cancelConfirmation()
          }
          autoCloseDisable={true}>
          {confirmOpen?.message}
        </ConfirmDialog>

        {chooseSection.open && (
          <CustomModalView
            open={true}
            fullWidth={true}
            maxWidth={'xl'}
            scroll={'paper'}
            autoCloseDisable={true}
            isFilters={true}
            title="Add Section"
            filterOnChange={(e) => setSectionSearch(e)}
            close={() =>
              setChooseSection((pre) => ({
                ...pre,
                open: false,
              }))
            }>
            <SectionList
              sectionType={'strategy'}
              searchValue={sectionSearch}
              onSelect={selectSections}
              petitionData=""
              isAffilateCase={
                location.state?.showType === 'leads'
                  ? leadInfo?.isAffilateCase
                  : null
              }
              affilateRoleId={
                location.state?.showType === 'leads'
                  ? leadInfo?.affilateRoleId
                  : null
              }
              attorneyAssigned={
                location.state?.showType === 'leads'
                  ? leadInfo?.attorney_assigned
                  : null
              }
            />
          </CustomModalView>
        )}
      </Grid>
    </Grid>
  );
};

export default Strategy;
