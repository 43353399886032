import { Grid } from '@mui/material';
import { instantState } from 'hooks';
import React, { useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';

import ReportExportButton from '../ReportExportButtons';
import ReportFillter from '../ReportFilters';
import ReportTable from '../ReportTables';

type ReportProps = {
  tab;
  isOrg?: boolean;
  orgId?: string;
  associatedPetitioner?: boolean;
};

export const PaymentReport: React.FC<ReportProps> = ({
  tab,
  isOrg,
  orgId,
  associatedPetitioner,
}) => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [tableIntialloader, setTableIntialloader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isExport, setIsExport] = useState(false);
  const [listData, setListData] = useState<any>({});
  const [params, setParams] = useState<any>({
    amount: '',
    contactId: 'all',
    createdby: '',
    firmId: userDetail?.companyId,
    fromDate: '',
    invoicePurpose: '',
    order: 'DESC',
    paymentMode: 'all',
    paymentReportType: 'payment',
    period: 'all',
    petitionerAssociated: isOrg ? String(associatedPetitioner) : 'false',
    petitionerId: isOrg ? orgId : 'all',
    skip: 0,
    sortby: 'updated_at',
    status: 'success',
    take: 50,
    toDate: '',
    totalAmount: '',
    transactionId: '',
    visaType: 'all',
  });

  useEffect(() => {
    setTableIntialloader(true);

    if (params.period === '2' && (!params.fromDate || !params.toDate)) {
      return;
    }
    getAllListData(
      COMMON_ENDPOINT.PaymentReportParseList(
        params.status,
        params.order,
        params.skip,
        params.take,
        params.sortby,
        params.firmId,
        params.fromDate,
        params.toDate,
        params.createdby,
        params.transactionId,
        params.amount,
        params.totalAmount,
        params.paymentMode,
        params.paymentReportType,
        params.petitionerId,
        params.petitionerAssociated,
        params.visaType,
        params.invoicePurpose,
        params.contactId,
      ),
    )
      .then(async (resp: any) => {
        setTableIntialloader(false);
        const { data, count: total } = resp ? resp : { count: 0, data: [] };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setListData((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          setListData(resp);
        }
        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setListData);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
  }, [params]);

  const getMoreList = () => {
    setGetMoreLoader(true);
    setParams((pre) => ({
      ...pre,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      skip: pre.skip + 50,
    }));
  };

  return (
    <Grid container>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <Grid item md={12} sm={12} className="pl-0">
          <ReportExportButton
            clickToDownLoad={() => setIsExport(true)}
            tab={tab}
            isOrg={isOrg}
            params={params}
            setParams={setParams}
          />
          {!isOrg && (
            <ReportFillter
              params={params}
              userDetail={userDetail}
              setParams={setParams}
              tab={tab}
            />
          )}
          <ReportTable
            setParams={setParams}
            lists={listData}
            params={params}
            userDetail={userDetail}
            isExport={isExport}
            setIsExport={setIsExport}
            tab={tab}
            hasMoreResults={hasMoreResults}
            getMoreLoader={getMoreLoader}
            getMoreList={getMoreList}
            tableIntialloader={tableIntialloader}
            isOrg={isOrg}
            orgId={orgId}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PaymentReport;
