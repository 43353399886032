import { toast } from 'react-toastify';
import instance from 'services/api';
import { getAllListData } from 'services/CommonServices';
import { storePetitionerData } from 'store/actions/LeadsOrPetitionerAction';

import { COMMON_ENDPOINT, SETUP_ENDPOINT } from './Constant';
import { alertOptions } from './helper';

interface PostRequestProps {
  url: string;
  data?: any;
  token?: string;
}

interface GetRequestProps {
  url: string;
  token?: string;
}

const postRequest = async (props: PostRequestProps, options?: any) => {
  return await instance
    .post(process.env.REACT_APP_APIURL + props.url, props.data, options)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      if (error.response) {
        throw error.response.data;
      }
    });
};
const putRequest = async (props: PostRequestProps) => {
  return await instance
    .put(process.env.REACT_APP_APIURL + props.url, props.data)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error.response.data;
    });
};

const deleteRequest = async (props: PostRequestProps) => {
  return await instance
    .delete(process.env.REACT_APP_APIURL + props.url, props.data)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error.response.data;
    });
};

const getRequest = async (props: GetRequestProps, options?: any) => {
  return await instance
    .get(
      process.env.REACT_APP_APIURL + props.url,
      props.token !== '' && {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
        ...options,
      },
    )
    .then((response: any) => {
      if (response.data) {
        return response.data;
      } else {
        return {
          data: response,
        };
      }
    })
    .catch((error: any) => {
      throw error && error.response && error.response.data
        ? error.response.data
        : '';
    });
};

const getPdfRequest = async (props: GetRequestProps, options?: any) => {
  return await instance
    .get(process.env.REACT_APP_APIURL + props.url, options)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error && error.response && error.response.data
        ? error.response.data
        : '';
    });
};

const getPetitionApiData = async (editId, dispatch: any) => {
  try {
    return await new Promise((resolve) => {
      const petitionLists = {};
      getAllListData(
        `${COMMON_ENDPOINT.PetitionDependent}/${String(editId)}`,
      ).then((dependentResp: any) => {
        petitionLists['dependents'] = dependentResp ? dependentResp : [];
      });

      getAllListData(
        `${COMMON_ENDPOINT.Petitions}/team/${String(editId)}`,
      ).then((teamResp: any) => {
        petitionLists['teams'] = teamResp.data ? teamResp.data : {};
      });

      getAllListData(
        `${SETUP_ENDPOINT.SelectMenu}/teammembers/${String(
          editId,
        )}?type=petition`,
      ).then((teamMemberResp: any) => {
        petitionLists['teamMemberEmails'] =
          teamMemberResp && teamMemberResp.length ? teamMemberResp : [];
      });

      getAllListData(
        `${COMMON_ENDPOINT.Petitions}/petition-notes/${String(
          editId,
        )}?type=petition`,
      ).then((notesResp: any) => {
        petitionLists['notes'] = notesResp && notesResp.length ? notesResp : [];
      });

      getAllListData(
        `${COMMON_ENDPOINT.Petitions}?id=${String(editId)}&skip=0&take=1`,
      ).then((petitionsResp) => {
        petitionLists['petitionData'] =
          petitionsResp.data && petitionsResp.data.length
            ? petitionsResp.data[0]
            : [];
        if (petitionsResp.data.length && petitionsResp.data[0].lead_id) {
          getAllListData(
            `${COMMON_ENDPOINT.LeadList}?id=${String(
              petitionsResp.data[0].lead_id,
            )}`,
          ).then((leadResp: any) => {
            petitionLists['leadList'] =
              leadResp && leadResp.data ? leadResp.data[0] : {};

            dispatch(storePetitionerData(petitionLists));
            resolve(petitionLists);
          });
        }
        // return true;
      });
    });
  } catch (error) {
    toast.error(
      error ? String(error) : 'Something went wrong, Please try later',
      alertOptions,
    );
  }
};

const getPublicRequest = async (endpoint: string, options?: any) => {
  return await instance
    .get(process.env.REACT_APP_APIURL + endpoint, options)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error && error.response && error.response.data
        ? error.response.data
        : '';
    });
};

export {
  postRequest,
  putRequest,
  getRequest,
  deleteRequest,
  getPdfRequest,
  getPetitionApiData,
  getPublicRequest,
};
