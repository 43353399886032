// import moment from 'moment';
import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(20, 'Password must not exceed 20 characters'),
});

export const registerSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(20, 'Password must not exceed 20 characters'),
});

export const documentFileUpdateSchema = Yup.object().shape({
  fileName: Yup.string().required('File Name is required'),
});

export const documentRomanUpdateSchema = Yup.object().shape({
  documentName: Yup.string().trim().nullable().required('Name is required'),
  documentRoman: Yup.string().optional(),
});

export const forgotSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
});

export const createPasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .when('password', {
      is: (password) => (password && password.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password doesn't match"),
    }),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*~|()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[A-Z]){1}).*$/,
      'Enter valid password',
    )
    .matches(/^(\S+$)/g, ' password not accept white space'),
});

export const changePasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .when('newPassword', {
      is: (newpassword) =>
        newpassword && newpassword.length > 0 ? true : false,
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        "Password doesn't match",
      ),
    }),
  newPassword: Yup.string()
    .required('New Password is required')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*~|()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[A-Z]){1}).*$/,
      'Enter valid password',
    )
    .matches(/^(\S+$)/g, 'New password not accept white space'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*~|()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[A-Z]){1}).*$/,
      'Enter valid password',
    ),
});

export const AddUserSchema = Yup.object().shape({
  address: Yup.string().optional(),
  city: Yup.string().nullable().optional(),
  contactEmail: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  country: Yup.string().nullable().optional(),
  dateOfBirth: Yup.string().nullable().optional(),
  // dateOfBirth: Yup.string()
  //   .nullable()
  //   .test(
  //     'dateOfBirth',
  //     'Enter a DOB of person above 18 years.',
  //     function (value) {
  //       return (
  //         moment().diff(moment(new Date(value), 'YYYY-MM-DD'), 'years') >= 18
  //       );
  //     },
  //   )
  //   .test(
  //     'dateOfBirth',
  //     'Enter a DOB of person below 100 years.',
  //     function (value) {
  //       return (
  //         moment().diff(moment(new Date(value), 'YYYY-MM-DD'), 'years') < 100
  //       );
  //     },
  //   )
  //   .optional(),
  dateOfJoining: Yup.string().nullable().optional(),
  firstName: Yup.string().required('First Name is required'),
  gender: Yup.string().optional(),
  lastName: Yup.string().required('Last Name is required'),
  logo: Yup.array().optional(),
  mobileNumber: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  roleAssignedId: Yup.array().nullable().required('Role is required'),
  roleRestrict: Yup.boolean().optional(),
  state: Yup.string().nullable().optional(),
  status: Yup.boolean().optional(),
  title: Yup.string().nullable().optional(),
  unitNumber: Yup.string().optional(),
  unitType: Yup.string().optional(),
  zipCode: Yup.string().optional(),
});
export const RegistrationFormSchema = Yup.object().shape({
  // dob: Yup.string()
  //   .nullable()
  //   .test('dob', 'Enter a DOB of person above 18 years.', function (value) {
  //     return (
  //       moment().diff(moment(new Date(value), 'YYYY-MM-DD'), 'years') >= 18
  //     );
  //   })
  //   .test('dob', 'Enter a DOB of person below 100 years.', function (value) {
  //     return (
  //       moment().diff(moment(new Date(value), 'YYYY-MM-DD'), 'years') < 100
  //     );
  //   })
  //   .optional(),
  dob: Yup.string().nullable().optional(),
  email: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  firstName: Yup.string().required('First Name is required'),
  gender: Yup.string().optional(),
  lastName: Yup.string().required('Last Name is required'),
  mobileNumber: Yup.string().optional(),
});
export const Dependentform = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').optional(),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  middleName: Yup.string().optional(),
  realtionship: Yup.string().required('Relation is required'),
});
export const LoginAsSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .when('role', {
      is: (val: string) =>
        Number(val) === 1 || Number(val) === 2 || Number(val) === 3,
      then: Yup.string().nullable().required('User is required'),
    }),
  lawFirm: Yup.string()
    .nullable()
    .when('role', {
      is: (val: string) => Number(val) === 3,
      then: Yup.string().nullable().required('Law Firm is required'),
    }),
  organization: Yup.string()
    .nullable()
    .when('role', {
      is: (val: string) => Number(val) === 2,
      then: Yup.string().nullable().required('Organization is required'),
    }),
  role: Yup.string().required('Role is required'),
});

export const AddVersionformSchema = Yup.object().shape({
  versionCode: Yup.string().required('Version id is required'),
});
export const AddPetitionerAddSchema = Yup.object().shape({
  contactEmail: Yup.string()
    .required('Contact email  is required')
    .email('Email must be a valid email address'),
  contactType: Yup.string().required('Contact type is required'),
  designation: Yup.string().optional(),
  firstName: Yup.string().required('First Name is required'),
  gender: Yup.string().optional(),
  grossAnnualIncome: Yup.string().optional(),
  lastName: Yup.string().required('Last Name is required'),
  middleName: Yup.string().optional(),
  mobileNumber: Yup.string().optional(),
  netAnnualIncome: Yup.string().optional(),
  organizationAddress: Yup.string().optional(),
  organizationCity: Yup.string().optional(),
  organizationCountry: Yup.string().optional(),
  organizationEmail: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  organizationFEINNumber: Yup.string().optional(),
  organizationFax: Yup.string().optional(),
  organizationLogo: Yup.string().optional(),
  organizationName: Yup.string().required('Organization Name is required'),
  organizationPhone: Yup.string().optional(),
  organizationSignatoryEmail: Yup.string().optional(),
  organizationSignatoryFirstName: Yup.string().optional(),
  organizationSignatoryLastName: Yup.string().optional(),
  organizationSignatoryTitle: Yup.string().optional(),
  organizationState: Yup.string().optional(),
  organizationStatus: Yup.string().optional(),
  organizationWebsite: Yup.string().optional(),
  organizationZipCode: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  primaryContact: Yup.boolean().optional(),
  status: Yup.string().required('Status is required'),
  streetNumberAndName: Yup.string().optional(),
  totalNumberOfEmployees: Yup.string().optional(),
  typeOfBusiness: Yup.string().optional(),
  unitNumber: Yup.string().optional(),
  unitType: Yup.string().optional(),
  validTill: Yup.string().nullable().optional(),
  yearEstablished: Yup.string().optional(),
});
export const Lawfirm = Yup.object().shape({
  attorneyAddress: Yup.string().optional(),
  attorneyCity: Yup.string().optional(),
  attorneyCountry: Yup.string().optional(),
  attorneyEmail: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  attorneyFax: Yup.string().optional(),
  attorneyLogo: Yup.string().optional(),
  attorneyName: Yup.string().required('Firm Name is required'),
  attorneyPhone: Yup.string().optional(),
  attorneyState: Yup.string().optional(),
  attorneyStatus: Yup.string().optional(),
  attorneyWebsite: Yup.string().optional(),
  attorneyZipCode: Yup.string().optional(),
  contactEmail: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  contactType: Yup.string().required('Contact Type is required'),
  designation: Yup.string().optional(),
  firstName: Yup.string().required('First Name is required'),
  gender: Yup.string().optional(),
  lastName: Yup.string().required('Last Name is required'),
  middleName: Yup.string().optional(),
  mobileNumber: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  streetNumberAndName: Yup.string().optional(),
  unitNumber: Yup.string().optional(),
  unitType: Yup.string().optional(),
  validTill: Yup.string().nullable().optional(),
});

export const LawfirmEdit = Yup.object().shape({
  attorneyAddress: Yup.string().optional(),
  attorneyCity: Yup.string().optional(),
  attorneyCountry: Yup.string().optional(),
  attorneyEmail: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  attorneyFax: Yup.string().optional(),
  attorneyLogo: Yup.string().optional(),
  attorneyName: Yup.string().required('Firm Name is required'),
  attorneyPhone: Yup.string().optional(),
  attorneyState: Yup.string().optional(),
  attorneyStatus: Yup.string().optional(),
  attorneyWebsite: Yup.string().optional(),
  attorneyZipCode: Yup.string().nullable().optional(),
  // contactEmail: Yup.string().required('Email is required'),
  // contactType: Yup.string().required('Contact type is required'),
  // designation: Yup.string().optional(),
  // firstName: Yup.string().required('First Name is required'),
  // gender: Yup.string().optional(),
  // lastName: Yup.string().required('Last Name is required'),
  // mobileNumber: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  streetNumberAndName: Yup.string().optional(),
  unitNumber: Yup.string().optional(),
  unitType: Yup.string().optional(),
  validTill: Yup.string().nullable().optional(),
});

export const AddPetitionerEditSchema = Yup.object().shape({
  // contactEmail: Yup.string().required('Contact email  is required'),
  // contactType: Yup.string().required('Contact type is required'),
  // designation: Yup.string().optional(),
  // firstName: Yup.string().required('First Name is required'),
  // gender: Yup.string().optional(),
  grossAnnualIncome: Yup.string().optional().nullable(),
  // lastName: Yup.string().required('Last Name is required'),
  // mobileNumber: Yup.string().optional(),
  netAnnualIncome: Yup.string().optional().nullable(),
  organizationAddress: Yup.string().optional().nullable(),
  organizationCity: Yup.string().optional().nullable(),
  organizationCountry: Yup.string().optional().nullable(),
  organizationEmail: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  organizationFEINNumber: Yup.string().optional(),
  organizationFax: Yup.string().optional(),
  organizationLogo: Yup.string().nullable().optional(),
  organizationName: Yup.string().required('Organization Name is required'),
  organizationPhone: Yup.string().optional(),
  organizationSignatoryEmail: Yup.string().optional(),
  organizationSignatoryFirstName: Yup.string().optional(),
  organizationSignatoryLastName: Yup.string().optional(),
  organizationSignatoryTitle: Yup.string().optional(),
  organizationState: Yup.string().optional(),
  organizationStatus: Yup.string().optional(),
  organizationWebsite: Yup.string().optional(),
  organizationZipCode: Yup.string().nullable().optional(),
  // phoneNumber: Yup.string().optional(),
  // primaryContact: Yup.boolean().optional(),
  // status: Yup.string().required('Status is required'),
  streetNumberAndName: Yup.string().optional(),
  totalNumberOfEmployees: Yup.string().optional(),
  typeOfBusiness: Yup.string().optional(),
  unitNumber: Yup.string().optional(),
  unitType: Yup.string().optional(),
  validTill: Yup.string().nullable().optional(),
  yearEstablished: Yup.string().optional(),
});

export const AddContactPetitionerSchema = Yup.object().shape({
  contactEmail: Yup.string().required('Contact email  is required'),
  contactType: Yup.string().required('Contact type is required'),
  designation: Yup.string().optional(),
  firstName: Yup.string().required('First Name is required'),
  gender: Yup.string().optional(),
  lastName: Yup.string().required('Last Name is required'),
  mobileNumber: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  primaryContact: Yup.boolean().optional(),
  status: Yup.string().required('Status is required'),
});

export const RepresentativeAddSchema = Yup.object().shape({
  contactEmail: Yup.string().required('Contact email  is required'),
  contactType: Yup.string().required('Contact type is required'),
  designation: Yup.string().optional(),
  firstName: Yup.string().required('First Name is required'),
  gender: Yup.string().optional(),
  lastName: Yup.string().required('Last Name is required'),
  mobileNumber: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  primaryContact: Yup.boolean().optional(),
  status: Yup.string().required('Status is required'),
});

export const MyProfileSchema = Yup.object().shape({
  address: Yup.string().optional(),
  city: Yup.string().nullable().optional(),
  country: Yup.string().nullable().optional(),
  // dob: Yup.string()
  //   .nullable()
  //   .test('dob', 'Enter a DOB of person above 18 years.', function (value) {
  //     return (
  //       moment().diff(moment(new Date(value), 'YYYY-MM-DD'), 'years') >= 18
  //     );
  //   })
  //   .test('dob', 'Enter a DOB of person below 100 years.', function (value) {
  //     return (
  //       moment().diff(moment(new Date(value), 'YYYY-MM-DD'), 'years') < 100
  //     );
  //   })
  //   .optional(),
  dob: Yup.string().nullable().optional(),
  file: Yup.string().nullable().optional(),
  firstName: Yup.string().required('First Name is required'),
  gender: Yup.string().optional(),
  lastName: Yup.string().required('Last Name is required'),
  mobileCountryCode: Yup.string().optional(),
  mobileNumber: Yup.string().optional(),
  mobileSocCode: Yup.string().optional(),
  phone: Yup.string().optional(),
  state: Yup.string().nullable().optional(),
  streetNumberAndName: Yup.string().optional(),
  unitNumber: Yup.string().optional(),
  unitType: Yup.string().optional(),
  zipCode: Yup.string().optional(),
});

export const RadionButtonPopulateSchema = Yup.object().shape({
  question: Yup.number().nullable().required('Required'),
  questionIds: Yup.array()
    .nullable()
    .of(Yup.number())
    .min(1, 'Choose question is required')
    .required('Required'),
});
export const ConfigureQuestionSchema = Yup.object().shape({
  answerUserField: Yup.string()
    .nullable()
    .when('responseType', {
      is: (val: string) => Number(val) === 10,
      then: Yup.string().nullable().required('Required'),
    }),
  dariNotes: Yup.string().optional(),
  dariQuestion: Yup.string().optional(),
  defaultAnswer: Yup.string().optional(),

  example: Yup.string().optional(),

  hint: Yup.string().optional(),

  issuesLanguage: Yup.string().optional(),

  issuesNumber: Yup.string().optional(),
  // .when('responseType', {
  //   is: (val: string) => Number(val) === 1 || Number(val) === 2,
  //   then: Yup.string().required('Default Answer is required'),
  // }),
  linkCode: Yup.string().optional(),
  notes: Yup.string().optional(),
  pashtoNotes: Yup.string().optional(),
  pashtoQuestion: Yup.string().optional(),
  question: Yup.string().required('Question is required'),
  response: Yup.array().required('Response By is required'),
  responseBy: Yup.array().nullable().required('Response By is required'),
  responseType: Yup.string().required('Response Type is required'),
  status: Yup.boolean().required('Status is required'),
  webUrlLink: Yup.string().optional(),
  winningStrategyLink: Yup.string().optional(),
  winningStrategyNote: Yup.string().optional(),
});

export const ResponseTypeQuestionsSchema = Yup.object().shape({
  response: Yup.array().of(
    Yup.object().shape({
      text: Yup.string().required('Required'),
    }),
  ),
});

export const ConfigureSectionSchema = Yup.object().shape({
  majorSection: Yup.boolean().optional(),
  sectionDescription: Yup.string().optional(),
  sectionStatus: Yup.boolean().required('Status is required'),
  sectionTitle: Yup.string().required('Title is required'),
  sectionType: Yup.string().required('Type is required'),
  templateId: Yup.string()
    .nullable()
    .when('sectionType', {
      is: (val: string) => val === 'contract',
      then: Yup.string().nullable().required('Document is required'),
    }),
});

export const VisaTypeSchema = Yup.object().shape({
  beneficiaryDescription: Yup.string().nullable().optional(),
  country: Yup.string().required('Country is required'),
  petitionerDescription: Yup.string().nullable().optional(),
  status: Yup.string().required('Status is required'),
  visaCode: Yup.string().required('Visa Code is required'),
  visaDescription: Yup.string().optional(),
});

export const InviteMailSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
});
export const VisaTypeMajorSchema = Yup.object().shape({
  major: Yup.boolean().optional(),
});
export const PaymentGatewaySchema = Yup.object().shape({
  achServiceCharge: Yup.string()
    .nullable()
    .test(
      'maxDigitsAfterDecimal',
      'Payment amount must have 2 digits after decimal or less',
      function (value) {
        return /^\d+(\.\d{1,2})?$/.test(value) ? true : !value ? true : false;
      },
    ),
  cardServiceCharge: Yup.string()
    .nullable()
    .test(
      'maxDigitsAfterDecimal',
      'Payment amount must have 2 digits after decimal or less',
      function (value) {
        return /^\d+(\.\d{1,2})?$/.test(value) ? true : !value ? true : false;
      },
    ),
  contract_payment_enable: Yup.boolean().optional(),
  // heartlandSecretId: Yup.string().when('paymentType', {
  //   is: (val: string) => val === 'heartland',
  //   then: Yup.string().required('Secret Key is required'),
  // }),
  // paymentSecretId: Yup.string().when('paymentType', {
  //   is: (val: string) => val === 'stripe',
  //   then: Yup.string().required('Secret Key is required'),
  // }),
  paymentType: Yup.string().when('contract_payment_enable', {
    is: (val: boolean) => val === true,
    then: Yup.string().required('Payment Type is required'),
  }),
});
export const MyfirmSchema = Yup.object().shape({
  attorneyAddress: Yup.string().optional(),
  attorneyCaseManager: Yup.string().nullable().optional(),
  attorneyCity: Yup.string().nullable().optional(),
  attorneyContactPerson: Yup.string().nullable().optional(),
  attorneyContactSupport: Yup.array().nullable().optional(),
  attorneyCountry: Yup.string().nullable().optional(),
  attorneyEmail: Yup.string()
    .required('Email address is required')
    .email('Email must be a valid email address'),
  attorneyFEIN: Yup.string().optional(),
  attorneyFax: Yup.string().nullable().optional(),
  attorneyName: Yup.string().required('Firm name is required'),
  attorneyPhone: Yup.string().nullable().optional(),
  attorneyState: Yup.string().nullable().optional(),
  attorneyStatebarNumber: Yup.string().optional(),
  attorneyStatus: Yup.string().optional(),
  attorneyUSCISElisAccountNumber: Yup.string().optional(),
  attorneyWebsite: Yup.string().optional(),
  attorneyZipCode: Yup.string().nullable().optional(),
  attorneylicensingAuthority: Yup.string().optional(),
  ccMailFirm: Yup.string().optional(),
  streetNumberAndName: Yup.string().optional(),
  unitNumber: Yup.string().nullable().optional(),
  unitType: Yup.string().optional(),
  validTill: Yup.string().nullable().optional(),
});

export const AddnotesSchema = Yup.object().shape({
  // contactType: Yup.string().nullable(),
  contactType: Yup.string()
    .nullable()
    .when('contactVisible', {
      is: (val: boolean) => val === true,
      then: Yup.string().nullable().required('Contact type is required'),
    }),
  contactVisible: Yup.boolean().optional(),
  note: Yup.string()
    .nullable()
    .when('contactVisible', {
      is: (val: boolean) => val === false,
      then: Yup.string().nullable().required('Notes is required'),
    }),
  sendEmail: Yup.boolean().optional(),
  teamMembers: Yup.array()
    .nullable()
    .when('sendMail', {
      is: (val: boolean) => val === true,
      then: Yup.array()
        .nullable()
        .of(Yup.string())
        .min(1, 'Choose Recipients is required')
        .required('Team members is required'),
    }),
  visibleTo: Yup.array().optional(),
});
export const DashboardFilterSchema = Yup.object().shape({
  attorneyId: Yup.string().optional(),
  fromDate: Yup.string()
    .nullable()
    .when('period', {
      is: (val: string) => val === 'custom',
      then: Yup.string().nullable().required('From Date is required'),
    }),
  period: Yup.string().required('Period is required'),
  toDate: Yup.string()
    .nullable()
    .when('period', {
      is: (val: string) => val === 'custom',
      then: Yup.string().nullable().required('To Date is required'),
    }),
});
export const MyOrganizationschema = Yup.object().shape({
  grossAnnualIncome: Yup.string().optional(),
  netAnnualIncome: Yup.string().optional(),
  newSignatoryId: Yup.string().nullable().optional(),
  oldSignatoryId: Yup.string().nullable().optional(),
  organizationAddress: Yup.string().optional(),
  organizationCity: Yup.string().nullable().optional(),
  organizationCountry: Yup.string().nullable().optional(),
  organizationEmail: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  organizationFEINNumber: Yup.string().optional(),
  organizationFax: Yup.string().optional(),
  organizationName: Yup.string().required('Organization Name is required'),
  organizationPhone: Yup.string().optional(),
  organizationSignatoryEmail: Yup.string().optional(),
  organizationSignatoryFirstName: Yup.string().optional(),
  organizationSignatoryLastName: Yup.string().optional(),
  organizationSignatoryTitle: Yup.string().optional(),
  organizationState: Yup.string().nullable().optional(),
  organizationStatus: Yup.string().optional(),
  organizationWebsite: Yup.string().optional(),
  organizationZipCode: Yup.string().optional(),
  streetNumberAndName: Yup.string().optional(),
  totalNumberOfEmployees: Yup.string().optional(),
  typeOfBusiness: Yup.string().optional(),
  unitNumber: Yup.string().optional(),
  unitType: Yup.string().optional(),
  validTill: Yup.string().nullable().optional(),
  yearEstablished: Yup.string().optional(),
});

export const ContractFeesSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  status: Yup.bool().required('Status is required'),
  value: Yup.string().required('Fees is required'),
  visaId: Yup.array().nullable().required('Visa Type is required'),
});

export const ManageOrganizationAdd = Yup.object().shape({
  FirmName: Yup.string().required('Organization Name is required'),
  associated: Yup.boolean().optional(),
  grossAnnualIncome: Yup.string().optional(),
  netAnnualIncome: Yup.string().optional(),
  newSignatoryId: Yup.string().nullable().optional(),
  oldSignatoryId: Yup.string().nullable().optional(),
  organizationCity: Yup.string().optional(),

  organizationCountry: Yup.string().optional(),

  organizationEmail: Yup.string()
    .nullable()
    .email('Email must be a valid email address')
    .required('Email is required'),
  // .when('associated', {
  //   is: (val: boolean) => val === true,
  //   then: Yup.string().nullable().required('Email is required'),
  // }),
  organizationFEINNumber: Yup.string().optional(),
  organizationFax: Yup.string().optional(),
  organizationName: Yup.string().required('Organization Name is required'),
  organizationPhone: Yup.string().optional(),
  organizationSignatoryEmail: Yup.string()
    .nullable()
    .optional()
    .email('Email must be a valid email address'),
  organizationSignatoryFirstName: Yup.string().nullable().optional(),
  organizationSignatoryLastName: Yup.string().nullable().optional(),
  organizationSignatoryTitle: Yup.string().nullable().optional(),
  organizationState: Yup.string().optional(),
  organizationStatus: Yup.string().optional(),
  organizationWebsite: Yup.string().optional(),
  organizationZipCode: Yup.string().optional(),
  streetNumberAndName: Yup.string().optional(),
  totalNumberOfEmployees: Yup.string().optional(),
  typeOfBusiness: Yup.string().optional(),
  unitNumber: Yup.string().optional(),
  unitType: Yup.string().optional(),
  yearEstablished: Yup.string().optional(),
});
export const ManageOrganizationcustomcomponent = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  firstName: Yup.string().nullable().trim().required('First Name is required'),
  lastName: Yup.string().nullable().trim().required('Last Name is required'),
  mobileNumber: Yup.string().optional(),
  title: Yup.string().nullable().optional(),
});

export const LeadCreateStepOneSchema = Yup.object().shape({
  affilateDetails: Yup.array(
    Yup.object({
      affilateEmail: Yup.string()
        .nullable()
        .optional()
        .email('Email must be a valid email address'),
      affilateFirstName: Yup.string()
        .nullable()
        .trim()
        .required('First Name is required'),
      affilateRoleId: Yup.string().nullable().required('Role is required'),
    }),
  ),
  affiliateType: Yup.string().optional(),
  beneficiaryDetails: Yup.array(
    Yup.object({
      beneficiaryEmail: Yup.string()
        .nullable()
        .optional()
        .email('Email must be a valid email address'),
      beneficiaryFirstName: Yup.string()
        .nullable()
        .trim()
        .required('First Name is required'),
    }),
  ),
  beneficiaryEditDetails: Yup.array().optional(),
  contactEmail: Yup.string()
    .nullable()
    .when('orgType', {
      is: (val: string) => val === 'new',
      then: Yup.string()
        .nullable()
        .email('Email must be a valid email address')
        .required('Email is required'),
    }),
  contactLastName: Yup.string()
    .nullable()
    .when('orgType', {
      is: (val: string) => val === 'new',
      then: Yup.string().nullable().trim().required('Last Name is required'),
    }),
  contactMobileCountryCode: Yup.string().optional(),
  contactMobileNumber: Yup.string().optional(),
  contactMobileSocCode: Yup.string().optional(),
  contactPersonName: Yup.string()
    .nullable()
    .when('orgType', {
      is: (val: string) => val === 'new',
      then: Yup.string().nullable().trim().required('First Name is required'),
    }),
  leadOrgType: Yup.string().optional(),
  orgName: Yup.string()
    .nullable()
    .when('orgType', {
      is: (val: string) => val === 'new',
      then: Yup.string()
        .nullable()
        .trim()
        .required('Organization Name is required'),
    }),
  orgType: Yup.string().optional(),
  petitionerAssociated: Yup.boolean().optional(),
  petitionerContactDetails: Yup.array(
    Yup.object({
      orgName: Yup.string().nullable().optional(),
      petitionerEmail: Yup.string()
        .nullable()
        .optional()
        .email('Email must be a valid email address'),
      petitionerFirstName: Yup.string()
        .nullable()
        .trim()
        .required('First Name is required'),
      petitionerLastName: Yup.string().nullable().optional(),
    }),
  ),

  petitionerContactId: Yup.string().nullable().optional(),
  petitionerId: Yup.string().nullable().optional(),
  sendMail: Yup.boolean().optional(),
});

export const LeadCreateStepThreeSchema = Yup.object().shape({
  additionalAttorneyContacts: Yup.array().optional(),
  attorneyContactId: Yup.string().optional(),
  attorneySupport: Yup.array().optional(),
  caseManager: Yup.array().optional(),
  convictions: Yup.string().optional(),
  convictionsExplain: Yup.string().optional(),
  employmentOffered: Yup.string().optional(),
  employmentOfferedExplain: Yup.string().optional(),
  fillingType: Yup.string().optional(),
  leadEmail: Yup.string().optional(),
  leadNotes: Yup.string().optional(),
  leadStatus: Yup.string().optional(),
  numberOfDependents: Yup.string().optional(),
  overstayUsVisa: Yup.string().optional(),
  overstayUsVisaExplain: Yup.string().optional(),
  premiumProcessing: Yup.string().optional(),
  processingType: Yup.string().optional(),
  totalEmployeesInCompany: Yup.string().optional(),
  visaDenials: Yup.string().optional(),
  visaDenialsExplain: Yup.string().optional(),
  visaType: Yup.array().optional(),
});

export const ContractSchema = Yup.object().shape({
  beneficiaryName: Yup.string().optional(),
  description: Yup.string().when('paymentPurpose', {
    is: (val: string) => String(val) === '6',
    then: Yup.string().required('Description is required'),
  }),
  isEnterpriseContract: Yup.string().optional(),
  mailId: Yup.string().when('paymentChecked', {
    is: (val: string) => val === 'yes',
    then: Yup.string()
      .required('Email is required')
      .email('Email must be a valid email address'),
  }),
  orgName: Yup.string().nullable().optional(),
  paymentAmount: Yup.string().when('paymentChecked', {
    is: (val: string) => val === 'yes',
    then: Yup.string()
      .required('Payment amount is required')
      .test(
        'maxDigitsAfterDecimal',
        'Payment amount must have 2 digits after decimal or less',
        (number) => /^\d+(\.\d{1,2})?$/.test(number),
      ),
  }),
  paymentChecked: Yup.string().optional(),
  paymentPurpose: Yup.string().nullable().optional(),
  visaType: Yup.string().optional(),
});
export const Petionerscheme = Yup.object().shape({
  CustomField: Yup.string().required('Custom field is required'),
});

export const ContratBuildSchema = Yup.object().shape({
  contractType: Yup.string().required('Please select contract type'),
  visaType: Yup.string().required('Please select visa type'),
});

export const ACHFormSchema = Yup.object().shape({
  accountNumber: Yup.string().required('Account Number is required'),
  accountType: Yup.string().required('Account Type is required'),
  billingCity: Yup.string().required('City is required'),
  billingCountry: Yup.string().required('Country is required'),
  billingState: Yup.string().required('State is required'),
  billingStreetAddress: Yup.string().required('Street Address is required'),
  billingZip: Yup.string().required('ZIP Code is required'),
  checkHolderName: Yup.string().required('Holder Name is required'),
  checkType: Yup.string().required('Check Type is required'),
  recaptchaReactive: Yup.string().required('Captcha is required'),
  routingNumber: Yup.string().required('Routing Number is required'),
});

export const PetitionAddSchema = Yup.object().shape({
  attorneyId: Yup.string().nullable().required('Attorney is required'),
  beneficiaryId: Yup.string().nullable().required('Leads is required'),
  caseUserId: Yup.string().nullable().optional(),
  leadId: Yup.string().nullable().required('Leads is required'),
  organizationId: Yup.string().nullable().optional(),
  petitionerAssociated: Yup.boolean().optional(),
  visaId: Yup.string().nullable().required('Visa Types is required'),
});

export const PetitionerRequestlDocumentAddSchema = Yup.object().shape({
  documentName: Yup.string().nullable().required('Document Name is required'),
  responsibility: Yup.array().nullable().required('Responsibility is required'),
});

export const AdminDocumentTypeSchema = Yup.object().shape({
  dariDocumentType: Yup.string().optional(),
  defaultForPetitions: Yup.array().nullable().optional(),
  description: Yup.string().optional(),
  documentParsing: Yup.string().optional(),
  documentType: Yup.string().required('Document Type is required'),
  parsingType: Yup.string().when('documentParsing', {
    is: (val: string) => val === 'yes',
    then: Yup.string().required('Parsing Type is required'),
  }),
  pashtoDocumentType: Yup.string().optional(),
  validDocuments: Yup.string().required('Valid Documents is required'),
  visaId: Yup.array().nullable().required('Visa Type is required'),
});

export const AdminDocumentTemplateFormSchema = Yup.object().shape({
  contractType: Yup.string().when('templateAddedFor', {
    is: (val: string) => val === 'contract',
    then: Yup.string().required('Template Contract is required'),
  }),
  htmlContent: Yup.string().optional(),
  imageData: Yup.array().optional(),
  templatFile: Yup.string().when('isTemplatFile', {
    is: (val: boolean) => val === false,
    then: Yup.string().required('Template is required'),
  }),
  templateAddedFor: Yup.string().required('Document Type is required'),
  templateName: Yup.string().required('Template Name is required'),
  templateType: Yup.string().required('Description is required'),
  visaSelected: Yup.array().nullable().required('Visa Type is required'),
});

export const ResourceStationFormSchema = Yup.object().shape({
  documentDescription: Yup.string().optional(),
  documentName: Yup.string().required('Document Name is required'),
  file: Yup.string().required('File is required'),
  status: Yup.array().nullable().optional(),
  visaId: Yup.array().nullable().required('Visa Type is required'),
  visibleTo: Yup.array().nullable().required('Visible To is required'),
});

export const AddPetitionStatusSchema = Yup.object().shape({
  additionalTitle: Yup.string()
    .nullable()
    .when('titleOfDocument', {
      is: (val: string) => [10, 11, 12].includes(Number(val)),
      then: Yup.string()
        .nullable()
        .matches(/^(\S)/i, 'Additional title is required'),
      // .required('Additional title is required'),
    }),
  date: Yup.string().required('Date is required'),
  notify: Yup.boolean().optional(),
  notifyDate: Yup.string()
    .nullable()
    .when('notify', {
      is: (val: boolean) => val === true,
      then: Yup.string().nullable().required('Dude date is required'),
    }),
  notifyNotes: Yup.string()
    .nullable()
    .when('notify', {
      is: (val: boolean) => val === true,
      then: Yup.string().nullable().required('Notes is required'),
    }),
  notifyPerson: Yup.array().when('notify', {
    is: (val: boolean) => val === true,
    then: Yup.array()
      .of(Yup.string())
      .min(1, 'Choose Email is required')
      .required('Choose Email is required'),
  }),
  sendEmail: Yup.boolean().optional(),
  status: Yup.string().required('Case status is required'),
  teamMembers: Yup.array().when('sendEmail', {
    is: (val: boolean) => val === true,
    then: Yup.array()
      .of(Yup.string())
      .min(1, 'Choose Recipients is required')
      .required('Team members is required'),
  }),
  titleOfDocument: Yup.string().required('Case title is required'),
});

export const AddReceiptNoticeSchema = Yup.object().shape({
  notes: Yup.string().nullable().required('Notes is required'),
  teamMembers: Yup.array().when('sendEmail', {
    is: (val: boolean) => val === true,
    then: Yup.array()
      .of(Yup.string())
      .min(1, 'Choose Recipients is required')
      .required('Team members is required'),
  }),
  type: Yup.string().nullable().required('Receipt type is required'),
});

export const AssembleSendToSchema = Yup.object().shape({
  notes: Yup.string()
    .trim()
    .nullable()
    .max(2500, 'Maximum 2500 characters allowed')
    .required('Notes is required'),
  teamMembers: Yup.array()
    .of(Yup.string())
    .min(1, 'Team members is required')
    .required('Team members is required'),
});

export const wizardFormSchema = Yup.object().shape({
  wizardFormFields: Yup.array().optional(),
});

export const documentFormSchema = Yup.object().shape({
  formFields: Yup.array().optional(),
});

// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const StripeSignupFirmSchema = Yup.object().shape({
  choosedUser: Yup.string().optional(),
  email: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  firmName: Yup.string().required('Attorney Firm Name is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  phone: Yup.string().required('Mobile Number is required'),
  // .matches(phoneRegExp, 'Phone is not valid'),
});

export const rfeRespondStep1 = Yup.object().shape({
  file: Yup.string().nullable().required('Attachment is required'),
  notes: Yup.string()
    .trim()
    .nullable()
    .max(2500, 'Maximum 2500 characters allowed')
    .required('Notes is required'),
});

export const rfeRespondStep3 = Yup.object().shape({
  // bbox: Yup.string().optional(),
  id: Yup.string().optional(),
  issueResponse: Yup.string().optional(),
  issueText: Yup.string().required('Text is required'),
  issueTitle: Yup.string().required('Title is required'),
  predIssue: Yup.string().optional(),
  predSubIssue: Yup.string().optional(),
  responseType: Yup.string().optional(),
  // selectedPage: Yup.string().optional(),
});

export const rfeIssueAdd = Yup.object().shape({
  bbox: Yup.string().optional(),
  docLevelGrouping: Yup.string().nullable().optional(),
  fileId: Yup.string().required(),
  issueText: Yup.string().required('Issue Text is required'),
  issueTitle: Yup.string().required('Issue Title is required'),
  issueType: Yup.string().optional(),
  pageGroup: Yup.string().nullable().optional(),
  pageNumber: Yup.string().nullable().optional(),
  parentIssueId: Yup.string().optional(),
  predIssue: Yup.string().optional(),
  predSubIssue: Yup.string().optional(),
  type: Yup.string().optional(),
});

export const passportSchema = Yup.object().shape({
  countryName: Yup.string()
    .nullable()
    .optional()
    .matches(/^[A-Za-z ]*$/, 'Enter valid country'),
  dateOfBirth: Yup.string()
    .nullable()
    .matches(
      /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/,
      'Enter valid date of birth',
    ),
  expiryDate: Yup.string()
    .nullable()
    .required('Expiry date is required')
    .matches(
      /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/,
      'Enter valid expiry date',
    ),
  firstName: Yup.string()
    .nullable()
    .required('First name is required')
    .matches(/^[A-Za-z ]*$/, 'Enter valid first name'),
  gender: Yup.string()
    .nullable()
    .oneOf(['f', 'm'], 'Gender must be male or female')
    .required('Gender is required'),
  lastName: Yup.string()
    .nullable()
    .optional()
    .matches(/^[A-Za-z ]*$/, 'Enter valid last name'),
  middleName: Yup.string()
    .nullable()
    .optional()
    .matches(/^[A-Za-z ]*$/, 'Enter Valid middle name'),
  nationality: Yup.string()
    .nullable()
    .optional()
    .matches(/^[A-Za-z ]*$/, 'Enter valid nationality'),
  passportNumber: Yup.string()
    .nullable()
    .required('Passport number is required')
    .matches(/^[A-Za-z0-9 ]*$/, 'Enter valid passport number'),
});
