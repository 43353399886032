/* eslint-disable @typescript-eslint/restrict-template-expressions */
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomCheckBox from 'components/CustomCheckBox/customCheckBox';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
// import Pagination from 'components/Pagination';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
// import { watch } from 'fs';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
// import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import useStyles from '../../../PrivateStyle';

type LeadOneProps = {
  control;
  errors;
  setValue;
  getValues;
  setError;
  watch;
  handleSubmit;
  cancelForm;
  onSubmitForm: (src: any) => void;
  fields?: any;
  fieldAppend?: any;
  fieldRemove?: any;
  organizations?: any;
  editId?: string;
  setDeleteDialog: (src: any) => void;
  beneficiaryUpdate: (index: number, obj: any) => any;
  formOneIsValid: any;
  leadData: any;
  refresh: any;
};

const BeneficiaryAndPetitionerForm: FC<LeadOneProps> = ({
  control,
  errors,
  setValue,
  getValues,
  setError,
  watch,
  handleSubmit,
  cancelForm,
  onSubmitForm,
  fields,
  fieldAppend,
  fieldRemove,
  organizations,
  editId,
  setDeleteDialog,
  beneficiaryUpdate,
  formOneIsValid,
  leadData,
  refresh,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [petitioners, setPetitioners] = useState<any>([]);
  const [contactPerson, setContactPerson] = useState<any>({});
  const [leadParams, setLeadParams] = useState<any>({
    emailId: '',
    name: '',
    order: 'ASC',
    skip: 0,
    sortby: 'first_name',
    take: 50,
  });
  const [orderDirection, setOrderDirection] = useState<any>({
    created_at: 'asc',
    email_id: 'asc',
    first_name: 'asc',
  });
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [isOpen, setIsOpen] = useState(false);
  const handleLeadsOpen = () => setIsOpen(true);
  const handleSortRequest = (sortby: string) => {
    setOrderDirection((prev) => ({
      ...prev,
      [sortby]: prev[sortby] === 'asc' ? 'desc' : 'asc',
    }));
    setLeadParams((prev) => ({
      ...prev,
      order: orderDirection[sortby] === 'asc' ? 'DESC' : 'ASC',
      skip: 0,
      sortby: sortby,
      take: 50,
    }));
  };
  const handleLeadsClose = () => {
    setLeadParams((prev) => ({
      ...prev,
      name: '',
      order: 'ASC',
      skip: 0,
      sortby: 'first_name',
      take: 50,
    }));
    setOrderDirection((prev) => ({
      ...prev,
      created_at: 'asc',
      email_id: 'asc',
      first_name: 'asc',
    }));
    setIsOpen(false);
  };
  const [availableLeads, setAvailableLeads] = useState<any>({});
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [petitionerEmailDuplicate, setPetitionerEmailDuplicate] =
    useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
  });

  const debouncedParams = useDebounce(leadParams, 500);

  const selectedLeadDetails = (lead: any) => {
    if (lead && lead.id) {
      const id = editId ? '' : lead.id;
      fieldAppend({
        beneficiaryEmail: lead.email_id,
        beneficiaryFirstName: lead.first_name,
        beneficiaryLastName: lead.last_name,
        beneficiaryMobileCode: lead.country_source_code
          ? lead.country_source_code
          : 'us',
        beneficiaryMobileNumber: lead.contact_mobile
          ? String(lead.contact_mobile).indexOf('+') !== -1
            ? lead.contact_mobile
            : lead.country_code_mobile
            ? String(lead.country_code_mobile)
                .concat(' ')
                .concat(lead.contact_mobile)
            : '+1 '.concat(lead.contact_mobile)
          : '',
        checkEmailValidate: '',
        disable: true,
        id: id,
        ids: id,
        leadUserId: '',
        loginCheck:
          lead.conatct_status && lead.conatct_status !== 'pending'
            ? true
            : false,
        mobileCountryCode: lead.country_code_mobile,
        mobileSocCode: lead.country_source_code,
        sendEmailDisable:
          lead.conatct_status && lead.conatct_status !== 'pending'
            ? true
            : false,
        sendMail:
          lead.conatct_status && lead.conatct_status !== 'pending'
            ? true
            : false,
        status: '',
        type: 'existing',
      });
    }
    handleLeadsClose();
  };

  const getPetitionerDetails = useCallback(
    (orId: string) => {
      getAllListData(
        `${SETUP_ENDPOINT.SelectMenu}/${
          leadData && leadData.isAffilateCase && !leadData.petitioner_associated
            ? 'invite'
            : 'petitioner'
        }/${String(orId)}`,
      ).then((contactResp: any) => {
        setValue('petitionerId', Number(orId));
        setPetitioners(contactResp);
        setContactPerson(
          contactResp.find((person) =>
            person.id === watch('petitionerContactId')
              ? watch('petitionerContactId')
              : null,
          ),
        );
      });
    },
    [leadData, setValue, watch],
  );

  useEffect(() => {
    getAllListData(
      `${COMMON_ENDPOINT.BeneficiaryList}?skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}&sortby=${String(
        debouncedParams.sortby,
      )}&order=${String(debouncedParams.order)}${
        debouncedParams.name !== '' ? '&name='.concat(debouncedParams.name) : ''
      }${
        debouncedParams.emailId !== ''
          ? '&emailId='.concat(encodeURIComponent(debouncedParams.emailId))
          : ''
      }`,
    )
      .then(async (res: any) => {
        const { data, count: total } = res ? res : { count: 0, data: [] };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setAvailableLeads((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          setAvailableLeads(res);
        }
        setGetMoreLoader(false);
        setTableLoader(false);
        const allList = await instantState(setAvailableLeads);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setGetMoreLoader(false);
        setHasMoreResults(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setLeadParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };
  useEffect(() => {
    if (watch('petitionerId') || refresh) {
      getPetitionerDetails(watch('petitionerId'));
    }
  }, [getPetitionerDetails, watch, refresh]);

  const onSubmit = (e: any) => {
    if (petitionerEmailDuplicate) {
      setConfirmOpen((pre) => ({
        ...pre,
        show: true,
      }));
      return;
    }
    onSubmitForm(e);
  };

  const onApprove = (action: string) => {
    if (action === 'yes') {
      setConfirmOpen((pre) => ({
        ...pre,
        show: false,
      }));
      setPetitionerEmailDuplicate(false);
    }
  };
  const deleteBeneficiary = (index, beneficiary) => {
    if (editId && beneficiary && beneficiary.ids) {
      setDeleteDialog((pre) => ({
        ...pre,
        deleteId: beneficiary,
        deleteType: 'beneficiary',
        index,
        show: true,
      }));
    } else {
      fieldRemove(index);
    }
  };
  const saveBeneficiary = (index, item) => {
    item =
      getValues('beneficiaryDetails') && getValues('beneficiaryDetails').length
        ? getValues('beneficiaryDetails')[index]
        : null;
    if (
      item &&
      item.beneficiaryFirstName &&
      (!item.beneficiaryEmail ||
        (item.beneficiaryEmail &&
          item.beneficiaryEmail.match(
            // eslint-disable-next-line no-useless-escape
            /^([a-zA-Z0-9+_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{1,15})$/,
          )))
    ) {
      if (editId && item.ids) {
        const bodyObj = {
          ...item,
          beneficiaryMobileNumber: item.beneficiaryMobileNumber
            ? String(item.beneficiaryMobileNumber).split(/(?<=^\S+)\s/)[1]
            : '',
          id: item.ids,
        };
        updateData(
          '',
          bodyObj,
          COMMON_ENDPOINT.LeadUnderBeneficiaryEdit(editId, item.ids),
        ).then(() => {
          beneficiaryUpdate(index, {
            ...item,
            beneficiaryMobileNumber: item.beneficiaryMobileNumber,
            // beneficiaryMobileNumber: item.beneficiaryMobileNumber
            //   ? item.mobileCountryCode
            //     ? String(item.mobileCountryCode).concat(
            //         item.beneficiaryMobileNumber,
            //       )
            //     : ''
            //   : '',
            disable: true,
          });
        });
      } else {
        const bodyObj = {
          ...item,
          beneficiaryMobileNumber: item.beneficiaryMobileNumber
            ? String(item.beneficiaryMobileNumber).split(/(?<=^\S+)\s/)[1]
            : '',
          id: item.ids,
        };
        addNewData(
          bodyObj,
          COMMON_ENDPOINT.LeadUnderBeneficiaryAdd(editId),
        ).then((data) => {
          const modifyObj = {
            ...bodyObj,
            beneficiaryMobileNumber: item.beneficiaryMobileNumber,
            ids: data.data.id,
            leadUserId: data.data.lead_user_id,
            status: data.data.petiton_started,
            type: 'existing',
          };
          beneficiaryUpdate(index, {
            ...modifyObj,
            disable: true,
          });
        });
      }
    } else {
      if (item && item.beneficiaryFirstName === '') {
        toast.error('Beneficiary first name is required', alertOptions);
      }
    }
  };

  const CheckDuplicate = (currentIndex: number, email: string) => {
    const findDuplicate =
      getValues('beneficiaryDetails') && getValues('beneficiaryDetails').length
        ? getValues('beneficiaryDetails').some((e, index) => {
            return (
              e.beneficiaryEmail &&
              email &&
              currentIndex !== index &&
              e.beneficiaryEmail.toLocaleLowerCase().trim() ===
                email.toLocaleLowerCase().trim()
            );
          })
        : false;
    return findDuplicate
      ? 'An email address can only be associated with one client/entity'
      : '';
  };
  return (
    <Typography component={'div'}>
      <form id="lead-step-one" onSubmit={handleSubmit(onSubmit)}>
        <Typography component={'div'} sx={{ marginTop: '0rem !important' }}>
          <Typography component={'div'} className={classes.leadwiteBox}>
            <Typography component={'div'} className="d-flex-a d-flex-sb mb-1">
              <Typography
                component={'div'}
                className="uadd-title"
                sx={{
                  paddingBottom: 'unset !important',
                  paddingTop: 'unset !important',
                }}>
                Beneficiary
              </Typography>
              {!leadData?.isAffilateCase ? (
                <Typography className="d-flex-a">
                  <Typography component={'div'}>
                    <Controller
                      control={control}
                      defaultValue="new"
                      name="leadOrgType"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}>
                            <FormControlLabel
                              value="new"
                              control={<Radio />}
                              label="New"
                            />
                            <FormControlLabel
                              value="existing"
                              control={<Radio />}
                              label="Existing"
                            />
                          </RadioGroup>
                        );
                      }}
                    />
                  </Typography>
                  <Typography
                    className="d-flex-ja"
                    sx={{
                      backgroundColor: '#002c69',
                      borderRadius: '5px',
                      color: '#fff !important',
                      cursor: 'pointer',
                      fontFamily: 'Arial-Medium !important',
                      fontSize: '25px !important',
                      height: '32px',
                      width: '32px',
                    }}
                    onClick={() => {
                      if (watch('leadOrgType') === 'existing') {
                        // Show popup menu
                        getAllListData(
                          `${COMMON_ENDPOINT.BeneficiaryList}?skip=${String(
                            leadParams.skip,
                          )}&take=${String(leadParams.take)}&sortby=${String(
                            leadParams.sortby,
                          )}&order=${String(leadParams.order)}${
                            leadParams.name !== ''
                              ? '&name='.concat(leadParams.name)
                              : ''
                          }`,
                        ).then((res: any) => {
                          setAvailableLeads(res);
                          handleLeadsOpen();
                        });
                      } else {
                        fieldAppend({
                          beneficiaryEmail: '',
                          beneficiaryFirstName: '',
                          beneficiaryLastName: '',
                          beneficiaryMobileCode: 'us',
                          beneficiaryMobileNumber: '',
                          checkEmailValidate: '',
                          disable: false,
                          id: '',
                          ids: '',
                          leadUserId: '',
                          loginCheck: false,
                          mobileCountryCode: '+1 ',
                          mobileSocCode: 'us',
                          sendEmailDisable: false,
                          sendMail: false,
                          status: '',
                          type: watch('leadOrgType'),
                        });
                      }
                    }}>
                    +
                  </Typography>
                </Typography>
              ) : (
                ''
              )}
            </Typography>
            <Grid container>
              <Grid item md={12}>
                <Box
                  component="form"
                  className="lead-box"
                  style={{ padding: '1rem !important' }}
                  noValidate
                  autoComplete="off">
                  {fields.length ? (
                    <Typography>
                      {fields.map((item: any, index: number) => {
                        return (
                          <Grid
                            container
                            key={item.id}
                            columnSpacing={{ md: 2, sm: 1 }}>
                            <Grid item md={2}>
                              <Typography
                                component={'div'}
                                className="custom-field-row"
                                style={{ marginBottom: '0px !important' }}>
                                <Typography
                                  component={'div'}
                                  className="custom-label"
                                  sx={{ margin: '.5rem 0 0 0 !important' }}>
                                  First Name{' '}
                                  <Typography
                                    component={'span'}
                                    className="Mui-error">
                                    *
                                  </Typography>
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={''}
                                  name={`beneficiaryDetails.${index}.beneficiaryFirstName`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'First Name'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={
                                          errors.beneficiaryDetails?.[index]
                                            ?.beneficiaryFirstName?.message ||
                                          ''
                                        }
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.loginCheck
                                              ? true
                                              : false
                                            : item.type === 'existing' &&
                                              item.loginCheck
                                            ? true
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2} sm={12}>
                              <Typography
                                component={'div'}
                                className="custom-field-row"
                                style={{ marginBottom: '0px !important' }}>
                                <Typography
                                  component={'div'}
                                  className="custom-label"
                                  sx={{ margin: '.5rem 0 0 0 !important' }}>
                                  Last Name
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={''}
                                  name={`beneficiaryDetails.${index}.beneficiaryLastName`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'Last Name'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={
                                          errors.beneficiaryDetails?.[index]
                                            ?.beneficiaryLastName?.message || ''
                                        }
                                        onChange={(e) => {
                                          const vals = e.target.value.replace(
                                            /[^a-zA-Z s]/,
                                            '',
                                          );
                                          onChange(vals);
                                        }}
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.loginCheck
                                              ? true
                                              : false
                                            : item.type === 'existing' &&
                                              item.loginCheck
                                            ? true
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2.6}>
                              <Typography
                                component={'div'}
                                className="custom-field-row"
                                style={{ marginBottom: '0px !important' }}>
                                <Typography
                                  component={'div'}
                                  className="custom-label"
                                  sx={{ margin: '.5rem 0 0 0 !important' }}>
                                  Email
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={''}
                                  name={`beneficiaryDetails.${index}.beneficiaryEmail`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'Email'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={
                                          errors.beneficiaryDetails?.[index]
                                            ?.beneficiaryEmail?.message ||
                                          CheckDuplicate(index, value)
                                        }
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                        type="email"
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.loginCheck
                                              ? true
                                              : false
                                            : item.type === 'existing'
                                            ? true
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2.4}>
                              <Typography
                                component={'div'}
                                className="custom-field-row u-pr-1"
                                style={{ marginBottom: '0px !important' }}>
                                <Typography
                                  component={'div'}
                                  className="custom-label"
                                  sx={{ margin: '.5rem 0 0 0 !important' }}>
                                  Phone
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={''}
                                  name={`beneficiaryDetails.${index}.beneficiaryMobileNumber`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <PhoneInput
                                        inputStyle={{ width: '200px' }}
                                        placeholder="Phone"
                                        country={
                                          item.beneficiaryMobileCode
                                            ? item.beneficiaryMobileCode
                                            : 'us'
                                        }
                                        value={value}
                                        onChange={(
                                          value,
                                          data,
                                          event,
                                          formattedValue,
                                        ) => {
                                          const codes: any = data;
                                          onChange(formattedValue);
                                          setValue(
                                            `beneficiaryDetails.${index}.mobileCountryCode`,
                                            '+'.concat(codes?.dialCode),
                                          );
                                          setValue(
                                            `beneficiaryDetails.${index}.mobileSocCode`,
                                            codes?.countryCode,
                                          );
                                          setValue(
                                            `beneficiaryDetails.${index}.beneficiaryMobileCode`,
                                            codes?.countryCode,
                                          );
                                        }}
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.loginCheck
                                              ? true
                                              : false
                                            : item.type === 'existing' &&
                                              item.loginCheck
                                            ? true
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2}>
                              <Typography
                                component={'div'}
                                className="custom-field-row d-flex-a"
                                sx={{ paddingTop: '30px' }}>
                                <Controller
                                  control={control}
                                  defaultValue={false}
                                  name={`beneficiaryDetails.${index}.sendMail`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomCheckBox
                                        checked={value}
                                        onChange={onChange}
                                        // name={name}
                                        value={value}
                                        color="success"
                                        disabled={
                                          item.disable || item?.sendEmailDisable
                                        }
                                      />
                                    );
                                  }}
                                />
                                {!item.beneficiaryInviteSent ? (
                                  <Typography
                                    sx={{
                                      color: '#333333 !important',
                                      fontFamily: 'Arial-Bold !important',
                                      margin: 'unset !important',
                                    }}>
                                    {item.loginCheck
                                      ? 'User Created'
                                      : 'Send Invitation'}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      color: '#333333 !important',
                                      fontFamily: 'Arial-Bold !important',
                                      margin: 'unset !important',
                                    }}>
                                    {item.loginCheck
                                      ? 'User Created'
                                      : 'Resend'}
                                  </Typography>
                                )}
                              </Typography>
                            </Grid>

                            <Grid
                              md={1}
                              sx={{
                                display: 'flex',
                                paddingLeft: '0.5rem !important',
                                paddingTop: '1rem !important',
                              }}>
                              {!leadData?.isAffilateCase ? (
                                <>
                                  {!item.status &&
                                    editId &&
                                    !item.loginCheck &&
                                    item.disable &&
                                    item.ids && (
                                      <Tooltip title={'Edit'} placement="top">
                                        <EditIcon
                                          sx={{
                                            cursor: item.status
                                              ? 'not-allowed'
                                              : 'pointer',
                                            fill: 'gray',
                                            height: '25px',
                                            marginTop: '30px',
                                            width: '25px',
                                          }}
                                          onClick={() =>
                                            beneficiaryUpdate(index, {
                                              ...item,
                                              disable: false,
                                            })
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                  {editId &&
                                    (!item.disable ||
                                      (item.type === 'existing' &&
                                        !item.ids)) && (
                                      <Tooltip title={'Save'} placement="top">
                                        <SaveIcon
                                          sx={{
                                            cursor: 'pointer',
                                            fill: 'gray',
                                            height: '25px',
                                            marginTop: '30px',
                                            width: '25px',
                                          }}
                                          onClick={() =>
                                            saveBeneficiary(index, item)
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                  <Tooltip
                                    title={
                                      item.status
                                        ? 'Petition already created for the Lead. Details can not be deleted.'
                                        : 'Delete'
                                    }
                                    placement="top">
                                    <DeleteForeverIcon
                                      sx={{
                                        cursor: item.status
                                          ? 'not-allowed'
                                          : 'pointer',
                                        fill: 'red',
                                        height: '25px',
                                        marginTop: '30px',
                                        width: '25px',
                                      }}
                                      {...(item.status
                                        ? {}
                                        : {
                                            onClick: () =>
                                              deleteBeneficiary(index, item),
                                          })}
                                    />
                                  </Tooltip>{' '}
                                </>
                              ) : (
                                ''
                              )}
                              {editId &&
                                ![5].includes(leadData?.affilateRoleId) && (
                                  <Tooltip
                                    title={
                                      item.status
                                        ? 'Petition already created for the Lead. Please go to Document Checklist in Petition to upload documents'
                                        : 'Upload'
                                    }
                                    placement="top">
                                    <UploadFileIcon
                                      sx={{
                                        cursor: item.status
                                          ? 'not-allowed'
                                          : 'pointer',
                                        fill: 'gray',
                                        height: '25px',
                                        marginTop: '30px',
                                        width: '25px',
                                      }}
                                      {...(item.status
                                        ? {}
                                        : {
                                            onClick: () =>
                                              navigate(
                                                `${
                                                  userDetail.role_id === 2
                                                    ? PetitionerPrivateRoutes
                                                        .LEADS.UPLOADDOCUMENT
                                                    : userDetail.role_id === 3
                                                    ? AttorneyPrivateRoutes
                                                        .LEADS.UPLOADDOCUMENT
                                                    : userDetail.role_id ===
                                                        5 &&
                                                      leadData?.isAffilateCase &&
                                                      leadData?.affilateRoleId ===
                                                        3
                                                    ? AffilatePrivateRoutes
                                                        .ATTORNEY_LEADS
                                                        .UPLOADDOCUMENT
                                                    : AffilatePrivateRoutes
                                                        .PETITIONER_LEADS
                                                        .UPLOADDOCUMENT
                                                }/${String(leadData.id)}`,
                                                {
                                                  state: {
                                                    data: {
                                                      ...item,
                                                      name: `${
                                                        item.beneficiaryFirstName
                                                      } ${
                                                        item.beneficiaryLastName
                                                          ? item.beneficiaryLastName
                                                          : ''
                                                      }`,
                                                      personId: item.ids,
                                                    },
                                                    isBack: true,
                                                    user: 'beneficiary',
                                                  },
                                                },
                                              ),
                                          })}
                                    />
                                  </Tooltip>
                                )}
                              {!leadData?.isAffilateCase &&
                              !item.status &&
                              ((editId && item.loginCheck) ||
                                (!editId && item.type === 'existing')) ? (
                                <Tooltip
                                  title={
                                    editId
                                      ? item.loginCheck
                                        ? 'You can not edit beneficiary details because there is a pre-existing beneficiary with this email address'
                                        : ''
                                      : item.type === 'new'
                                      ? ''
                                      : item.loginCheck
                                      ? 'You can not edit beneficiary details because there is a pre-existing beneficiary with this email address'
                                      : 'You are not allowed to edit email when Beneficiary is added from existing list. Please save the changes and go to Edit Lead to update the details'
                                  }
                                  placement="top">
                                  <InfoIcon
                                    sx={{
                                      cursor: 'pointer',
                                      fill: 'gray',
                                      height: '25px',
                                      marginTop: '30px',
                                      width: '25px',
                                    }}
                                  />
                                </Tooltip>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Typography>
                  ) : (
                    <Grid item md={12} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-label"
                        sx={{
                          margin: '1.2rem 0 0 0 !important',
                          textAlign: 'center',
                        }}>
                        No Beneficiary Added
                      </Typography>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Typography>
          <Typography component={'div'} className={classes.leadwiteBox}>
            <Typography
              component={'div'}
              className="uadd-title"
              sx={{
                paddingBottom: 'unset !important',
                paddingTop: 'unset !important',
              }}>
              Petitioner
            </Typography>
            <Box
              component="form"
              className="lead-box"
              noValidate
              autoComplete="off">
              <Grid container>
                <Grid item md={12}>
                  <Grid container>
                    {
                      <>
                        <Grid item md={6}>
                          <Typography
                            component={'div'}
                            className="custom-field-row">
                            <Typography
                              component={'div'}
                              className="custom-label"
                              sx={{ margin: '.5rem 0 0 0 !important' }}>
                              Organization Name
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={null}
                              name="petitionerId"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomSelect
                                    labelKey={'org_name'}
                                    valueKey={'id'}
                                    options={
                                      !_.isEmpty(organizations)
                                        ? organizations
                                        : []
                                    }
                                    name={name}
                                    value={value}
                                    placeHolder={'Select'}
                                    className={'custom-input'}
                                    disabled={true}
                                  />
                                );
                              }}
                            />
                          </Typography>
                          {!_.isEmpty(petitioners) && (
                            <Typography
                              component={'div'}
                              className="custom-field-row">
                              <Typography
                                component={'div'}
                                className="custom-label"
                                sx={{ margin: '.5rem 0 0 0 !important' }}>
                                Email
                              </Typography>
                              <CustomInput
                                placeHolder={''}
                                value={
                                  !_.isEmpty(contactPerson)
                                    ? contactPerson.email_id
                                    : ''
                                }
                                className={'custom-input'}
                                disabled={true}
                              />
                            </Typography>
                          )}
                          {!_.isUndefined(
                            leadData && leadData.petitioner_assigned,
                          ) &&
                            ![5].includes(leadData?.affilateRoleId) && (
                              <Typography
                                component={'div'}
                                className="d-flex-a">
                                <Button
                                  className="n-add-btn"
                                  onClick={() =>
                                    navigate(
                                      `${
                                        userDetail.role_id === 2
                                          ? PetitionerPrivateRoutes.LEADS
                                              .UPLOADDOCUMENT
                                          : userDetail.role_id === 3
                                          ? AttorneyPrivateRoutes.LEADS
                                              .UPLOADDOCUMENT
                                          : userDetail.role_id === 5 &&
                                            leadData?.isAffilateCase &&
                                            leadData?.affilateRoleId === 3
                                          ? AffilatePrivateRoutes.ATTORNEY_LEADS
                                              .UPLOADDOCUMENT
                                          : AffilatePrivateRoutes
                                              .PETITIONER_LEADS.UPLOADDOCUMENT
                                      }/${String(leadData.id)}`,
                                      {
                                        state: {
                                          data: {
                                            name: organizations.find(
                                              (org) =>
                                                org.id ===
                                                leadData.petitioner_assigned,
                                            )?.org_name,
                                            personId:
                                              leadData.petitioner_assigned,
                                          },
                                          isBack: true,
                                          user: 'petitioner',
                                        },
                                      },
                                    )
                                  }>
                                  Upload Document
                                </Button>
                              </Typography>
                            )}
                        </Grid>
                        {!_.isEmpty(petitioners) && (
                          <Grid item md={6}>
                            <Typography
                              component={'div'}
                              className="custom-field-row u-pl-1">
                              <Typography
                                component={'div'}
                                className="custom-label"
                                sx={{ margin: '.5rem 0 0 0 !important' }}>
                                Contact Person
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue={null}
                                name="petitionerContactId"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomSelect
                                      isClearable={false}
                                      labelKey={'full_name'}
                                      valueKey={'id'}
                                      options={
                                        !_.isEmpty(petitioners)
                                          ? petitioners
                                          : []
                                      }
                                      name={name}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        setValue(
                                          'petitionerContactId',
                                          Number(e.target.value),
                                        );
                                        setContactPerson(
                                          petitioners.find(
                                            (person) =>
                                              person.id === e.target.value,
                                          ),
                                        );
                                      }}
                                      placeHolder={'Select'}
                                      className={'custom-input'}
                                      error={
                                        errors.petitionerContactId?.message
                                      }
                                      disabled={leadData?.isAffilateCase}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field-row u-pl-1">
                              <Typography
                                component={'div'}
                                className="custom-label"
                                sx={{ margin: '.5rem 0 0 0 !important' }}>
                                Mobile
                              </Typography>
                              <PhoneInput
                                placeholder="Phone"
                                inputStyle={{ width: '200px' }}
                                {...(!_.isEmpty(contactPerson) &&
                                !contactPerson.contact_mobile
                                  ? { country: 'us' }
                                  : {})}
                                value={
                                  !_.isEmpty(contactPerson) &&
                                  contactPerson.contact_mobile
                                    ? String(
                                        contactPerson.contact_mobile,
                                      ).indexOf('+') !== -1
                                      ? contactPerson.contact_mobile
                                      : contactPerson.country_code_mobile
                                      ? String(
                                          contactPerson.country_code_mobile,
                                        )
                                          .concat(' ')
                                          .concat(contactPerson.contact_mobile)
                                      : contactPerson.mobile_country_code
                                      ? String(
                                          contactPerson.mobile_country_code,
                                        )
                                          .concat(' ')
                                          .concat(contactPerson.contact_mobile)
                                      : '+1 '.concat(
                                          contactPerson.contact_mobile,
                                        )
                                    : '+1 '
                                }
                                // className={'custom-input'}
                                disabled={true}
                              />
                            </Typography>
                          </Grid>
                        )}
                      </>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Typography>
        </Typography>
      </form>
      <Grid container className="bottom-fixed">
        <Grid item md={12}>
          <Typography component={'div'} className="d-flex-jae">
            <Button className="cancel-btn mr-2" onClick={cancelForm}>
              <Typography className="o-box" />
              Cancel
            </Button>
            <Button className="add-btn" type="submit" form={'lead-step-one'}>
              Continue
            </Button>
          </Typography>
        </Grid>
      </Grid>

      <CustomModalView
        open={isOpen}
        close={handleLeadsClose}
        title="Available Leads"
        value={leadParams.name}
        isFilters={false}
        fullWidth={true}
        maxWidth={'md'}
        // inputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">
        //       <IconButton
        //         aria-label="toggle password visibility"
        //         edge="end"
        //         {...(leadParams.name === ''
        //           ? {}
        //           : {
        //               onClick: () => {
        //                 setTableLoader(true);
        //                 setLeadParams((prev) => ({
        //                   ...prev,
        //                   name: '',
        //                   skip: 0,
        //                   take: 50,
        //                 }));
        //               },
        //             })}
        //       />
        //     </InputAdornment>
        //   ),
        // }}
        // filterOnChange={(value) => {
        //   setTableLoader(true);
        //   setLeadParams((prev) => ({
        //     ...prev,
        //     name: value,
        //     skip: 0,
        //     take: 50,
        //   }));
        // }}
      >
        <Typography
          component={'div'}
          sx={{
            borderRadius: '8px 8px 0 0',
          }}
          className={classes.scrollbox}
          id="scrollParent">
          <InfiniteScroll
            dataLength={
              availableLeads &&
              availableLeads.data &&
              availableLeads.data.length
                ? availableLeads.data.length
                : 0
            }
            style={{
              overflow: 'unset',
            }}
            next={getMoreList && getMoreList}
            hasMore={hasMoreResults}
            scrollableTarget={'scrollParent'}
            loader={
              getMoreLoader && (
                <div
                  style={{
                    backgroundColor: '#ffffff',
                    textAlign: 'center',
                    width: '100%',
                  }}>
                  <CircularProgress
                    size={30}
                    sx={{
                      color: '#002c69',
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  />
                </div>
              )
            }
            endMessage>
            <table className="lead-table table-hover">
              <thead>
                <tr>
                  <th onClick={() => handleSortRequest('first_name')}>
                    <TableSortLabel
                      active={true}
                      direction={orderDirection.first_name}>
                      NAME
                    </TableSortLabel>
                  </th>
                  <th onClick={() => handleSortRequest('email_id')}>
                    <TableSortLabel
                      active={true}
                      direction={orderDirection.email_id}>
                      EMAIL
                    </TableSortLabel>
                  </th>
                  {/* <th>TYPE</th> */}
                  <th onClick={() => handleSortRequest('created_at')}>
                    <TableSortLabel
                      active={true}
                      direction={orderDirection.created_at}>
                      LEAD DATE
                    </TableSortLabel>
                  </th>
                </tr>
              </thead>
              <tbody
                style={{
                  position: 'sticky',
                  top: '40px',
                  zIndex: 1,
                }}>
                <tr>
                  <td>
                    <Typography
                      component={'div'}
                      className="d-flex-ja mr-1"
                      sx={{
                        color: '#84898c !important',
                      }}>
                      <CustomInput
                        placeHolder={'Search'}
                        className={'custom-search-model'}
                        value={leadParams.name}
                        inputProps={{
                          endAdornment:
                            leadParams.name !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={(e) => {
                                    setTableLoader(true);
                                    setLeadParams((prev) => ({
                                      ...prev,
                                      name: '',
                                      skip: 0,
                                      take: 50,
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          setTableLoader(true);
                          setLeadParams((prev) => ({
                            ...prev,
                            name: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                      />
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      component={'div'}
                      className="d-flex-ja mr-1"
                      sx={{
                        color: '#84898c !important',
                      }}>
                      <CustomInput
                        placeHolder={'Search'}
                        className={'custom-search-model'}
                        value={leadParams.emailId}
                        inputProps={{
                          endAdornment:
                            leadParams.emailId !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={(e) => {
                                    setTableLoader(true);
                                    setLeadParams((prev) => ({
                                      ...prev,
                                      emailId: '',
                                      skip: 0,
                                      take: 50,
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          setTableLoader(true);
                          setLeadParams((prev) => ({
                            ...prev,
                            emailId: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                      />
                    </Typography>
                  </td>
                  <td />
                </tr>
              </tbody>
              <tbody>
                {tableLoader ? (
                  <tr>
                    <td colSpan={8}>
                      <div
                        style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          width: '100%',
                        }}>
                        <CircularProgress
                          size={30}
                          sx={{
                            color: '#002c69',
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {!_.isEmpty(availableLeads) &&
                    availableLeads.data.length > 0
                      ? availableLeads.data.map((lead, index) => (
                          <tr
                            style={{ cursor: 'pointer' }}
                            key={index}
                            onClick={() => selectedLeadDetails(lead)}>
                            <td>
                              <a
                                style={{
                                  color: '#1093df',
                                  textTransform: 'capitalize',
                                }}>
                                {lead.first_name} &nbsp;
                                <span style={{ textTransform: 'capitalize' }}>
                                  {lead.last_name}
                                </span>
                              </a>
                            </td>
                            <td>{lead.email_id ? lead.email_id : '-'}</td>
                            {/* <td>{lead.type ? lead.type : '-'}</td> */}
                            <td>
                              {lead.created_at
                                ? moment(lead.created_at).format('yyyy-MM-DD')
                                : '-'}
                            </td>
                          </tr>
                        ))
                      : !tableLoader && (
                          <tr className={'text-center'}>
                            <th scope="row" colSpan={4}>
                              No Record(s) Found.
                            </th>
                          </tr>
                        )}
                  </>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </Typography>
        {/* <Pagination
          total={
            !_.isEmpty(availableLeads?.data) ? Number(availableLeads?.count) : 0
          }
          skip={leadParams.skip}
          take={leadParams.take}
          setParams={setLeadParams}
        /> */}
      </CustomModalView>

      <ConfirmDialog
        title="CONFIRM"
        floatingBtnShow={true}
        open={confirmOpen.show}
        onConfirm={(act) =>
          act === 'yes'
            ? onApprove(act)
            : setConfirmOpen({
                show: false,
              })
        }
        autoCloseDisable={true}>
        {
          'A Petitioner organization already exists for the email address provided by you. Do you wish to create another organization with the same email address? '
        }
      </ConfirmDialog>
    </Typography>
  );
};

export default BeneficiaryAndPetitionerForm;
