import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

type InfinityScrollInterFace = {
  paramState?: any;
  height?: number;
  scrollableTargetId?: string;
  rowProps?: any;
  data?: any;
  count?: any;
  children?: any;
};
const InfinityScroll: React.FC<any> = (props: InfinityScrollInterFace) => {
  return (
    <InfiniteScroll
      dataLength={props.data.length}
      style={{ padding: '5px' }}
      next={() => {
        if (props.data.length < props.count) {
          props.paramState((prev) => ({
            ...prev,
            skip: Number(prev.skip) + Number(prev.take),
            take: prev.take,
          }));
        }
      }}
      hasMore={props.data.length < Number(props.count)}
      loader={
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      }
      height={props.height}
      scrollableTarget={props.scrollableTargetId}>
      {props.rowProps
        ? props?.data.map((data: any, index: number) => {
            return props.rowProps(data, index);
          })
        : props.children}
    </InfiniteScroll>
  );
};

export default InfinityScroll;
