import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import AddImage from 'assets/images/add.svg';
import { Loader } from 'components/loader';
import React, { useCallback, useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { CustomeRole, ModuleList, SystemRole } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';

import useStyles from '../../PrivateStyle';
import { RolesModuleMenu } from './menu';

export const RolesPermission: React.FC<any> = () => {
  const classes = useStyles();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = React.useState(
    userDetail && userDetail.role_id === 1 ? '2' : '1',
  );
  const [rolesMappingData, setRolesMappingData] = useState<{
    customRole: Array<CustomeRole>;
    systemRole: Array<SystemRole>;
  }>({
    customRole: [],
    systemRole: [],
  });
  const [moduleList, setModuleList] = useState<Array<ModuleList>>([]);
  const [moduleId, setModuleId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const rolesMappingApi = useCallback(async () => {
    await getAllListData(COMMON_ENDPOINT.RoleMap)
      .then((response: any) => {
        setRolesMappingData(response);
        setLoading(false);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    rolesMappingApi();
  }, [rolesMappingApi]);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12}>
        <Box sx={{ width: '100%' }} className="roleTab">
          {loading ? (
            <Loader />
          ) : (
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example">
                  {userDetail && userDetail.role_id === 1 ? (
                    ''
                  ) : (
                    <Tab
                      label="Custom Roles"
                      value="1"
                      sx={{ textTransform: 'capitalize' }}
                    />
                  )}
                  <Tab
                    label="System Roles"
                    value="2"
                    sx={{ textTransform: 'capitalize' }}
                  />
                </TabList>
              </Box>
              <Typography
                component={'div'}
                className={classes.pbox}
                sx={{
                  borderTopLeftRadius: 'unset !important',
                  padding: '16px 12px !important',
                }}>
                <TabPanel value="1">
                  {rolesMappingData.customRole.length ? (
                    rolesMappingData.customRole.map((e, index) => (
                      <Typography
                        component={'div'}
                        className={classes.rbox}
                        key={index}>
                        <Typography className={classes.rtext}>
                          {e.moduleName}
                        </Typography>
                        <img
                          src={AddImage}
                          alt="add"
                          className={classes.pointer}
                          onClick={(event) => {
                            setModuleList(e.moduleList), handleClick(event);
                          }}
                        />
                      </Typography>
                    ))
                  ) : (
                    <Typography component={'div'} className={classes.rbox}>
                      {' '}
                      <Typography className={classes.rtext}>
                        Add roles
                      </Typography>
                      <img
                        src={AddImage}
                        alt="add"
                        className={classes.pointer}
                        onClick={(event) => {
                          handleClick(event);
                        }}
                      />
                    </Typography>
                  )}
                </TabPanel>
                <TabPanel value="2">
                  {rolesMappingData.systemRole.length
                    ? rolesMappingData.systemRole.map((e, index) => (
                        <Typography
                          component={'div'}
                          className={classes.rbox}
                          key={index}>
                          <Typography className={classes.rtext}>
                            {e.moduleName}
                          </Typography>
                          <img
                            src={AddImage}
                            alt="add"
                            className={classes.pointer}
                            onClick={(event) => {
                              setModuleList(e.moduleList),
                                handleClick(event),
                                setModuleId(e.moduleId);
                            }}
                          />
                        </Typography>
                      ))
                    : null}
                </TabPanel>

                <RolesModuleMenu
                  {...{
                    addButtonShow:
                      userDetail && userDetail.role_id === 1
                        ? true
                        : value === '1',
                    anchorEl,
                    currentRoleId: moduleId,
                    handleClose,
                    menuList: moduleList,
                    open,
                    roleId: userDetail && userDetail.role_id,
                  }}
                />
              </Typography>
            </TabContext>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default RolesPermission;
