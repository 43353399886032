import { CircularProgress, DialogContent, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { instantState } from 'hooks';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { SETUP_ENDPOINT, STRATEGY_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

type Props = {
  formId: string;
  onSelect?: (src: any) => void;
  orgStrategy?: boolean;
  loader: boolean;
};
export const StrategySessionList: React.FC<Props> = (props: Props) => {
  const { formId, onSelect, orgStrategy = false, loader: alterLoader } = props;
  const [strategy, setStrategy] = useState<{ data: Array<any>; total: number }>(
    { data: [], total: 0 },
  );
  const [searchData, setSearchData] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchValue, setSearchValue] = useState<{
    benName: string;
    petName: string;
  }>({ benName: '', petName: '' });

  const [orgList, setOrganizationList] = useState<Array<any>>([]);
  const [loader, setLoader] = useState(true);
  const [params, setParams] = useState<{
    associated: string;
    id: string;
    skip: number;
    take: number;
  }>({
    associated: '',
    id: '',
    skip: 0,
    take: 50,
  });
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);

  const selectStrategy = (data: any) => {
    onSelect && onSelect(data);
  };
  const filterOnChange = (benValue, petValue) => {
    const benSearchName = benValue ? benValue : '';
    const petSearchName = petValue ? petValue : '';
    setStrategy((pre) => ({
      ...pre,
      data: [
        ...searchData.filter((val) => {
          const benName = val.benificiery_name
            ? val.benificiery_name.toLowerCase()
            : '';
          const penName = val.org_name ? val.org_name.toLowerCase() : '';
          if (benSearchName && petSearchName) {
            if (
              benName.indexOf(benSearchName) !== -1 &&
              penName.indexOf(petSearchName) !== -1
            ) {
              return val;
            }
          } else if (petSearchName) {
            if (penName.indexOf(petSearchName) !== -1) {
              return val;
            }
          } else if (benSearchName) {
            if (benName.indexOf(benSearchName) !== -1) {
              return val;
            }
          } else {
            return val;
          }
        }),
      ],
    }));
  };
  const organizationApiCall = useCallback(async () => {
    if (orgStrategy) {
      const orgResp = await getAllListData(
        `${SETUP_ENDPOINT.SelectMenu}/petitioner`,
      );
      setOrganizationList(
        orgResp && orgResp.length
          ? orgResp.map((e: any, i: number) => ({ ...e, ids: i + 1 }))
          : [],
      );
    }
  }, [orgStrategy]);
  const getApiList = useCallback(async () => {
    try {
      const list = await getAllListData(
        orgStrategy
          ? STRATEGY_ENDPOINT.leadLevelStrategyList(
              null,
              '',
              null,
              true,
              formId,
              params?.id,
              params.associated,
              params.skip,
              params.take,
            )
          : STRATEGY_ENDPOINT.copyStrategyList(formId),
      );

      const moreLoader = await instantState(setGetMoreLoader);
      if (moreLoader) {
        setStrategy((pre) => ({
          ...pre,
          data: orgStrategy
            ? [...(list.data ? list.data : []), ...pre.data]
            : [...(list && list.data ? list.data : []), ...pre.data],
        }));
      } else {
        const idsEle = document.getElementById('scrollParent');
        idsEle && (idsEle.scrollTop = 0);
        setStrategy((pre) => ({
          ...pre,
          data: orgStrategy
            ? [...(list && list.data ? list.data : [])]
            : [...(list && list.data ? list.data : [])],
        }));
        setSearchData(
          orgStrategy ? [] : [...(list && list.data ? list.data : [])],
        );
      }
      setGetMoreLoader(false);
      setLoader(false);
      const allList = await instantState(setStrategy);
      if (allList.data && allList.data.length < Number(list?.total)) {
        setHasMoreResults(true);
      } else {
        setHasMoreResults(false);
      }
    } catch (error) {
      setGetMoreLoader(false);
      setLoader(false);
      setHasMoreResults(false);
      toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
  }, [
    formId,
    orgStrategy,
    params.associated,
    params?.id,
    params.skip,
    params.take,
  ]);

  const getMoreData = () => {
    setGetMoreLoader(true);
    setParams((pre) => ({
      ...pre,
      skip: pre.skip + 50,
    }));
  };
  useEffect(() => {
    getApiList();
  }, [getApiList, params]);

  useEffect(() => {
    organizationApiCall();
  }, [organizationApiCall]);

  return (
    <DialogContent id="scrollParent">
      {(loader || alterLoader) && <div className="liberty-loader" />}
      <Typography className="d-flex-jae mb-2">
        {orgStrategy ? (
          <Typography
            component="div"
            className="mr-1"
            sx={{
              width: '250px',
            }}>
            <CustomSelect
              labelKey={'org_name'}
              valueKey={'ids'}
              options={orgList}
              onChange={(e) => {
                if (e.target.value) {
                  const findOrgValue = orgList.find(
                    (el) => el.ids == e.target.value,
                  );
                  if (findOrgValue) {
                    setParams((pre) => ({
                      ...pre,
                      associated: String(findOrgValue?.status),
                      id: findOrgValue?.id,
                      skip: 0,
                      take: 50,
                    }));
                  }
                } else {
                  setParams((pre) => ({
                    ...pre,
                    associated: '',
                    id: '',
                    skip: 0,
                    take: 50,
                  }));
                }
              }}
              placeHolder={'Select'}
              className={'custom-input mr-1'}
              searchable={true}
            />
          </Typography>
        ) : (
          <>
            <Typography
              component="div"
              className="mr-1"
              sx={{
                color: '#fff !important',
              }}>
              <CustomInput
                placeHolder={'Search Beneficiary'}
                className={'custom-search-model'}
                onChange={async (e) => {
                  setSearchValue((pre) => ({
                    ...pre,
                    benName: e.target.value,
                  }));
                  const instanceState = await instantState(setSearchValue);
                  filterOnChange(e.target.value, instanceState?.petName);
                }}
              />
            </Typography>
            <Typography
              component="div"
              sx={{
                color: '#fff !important',
              }}>
              <CustomInput
                placeHolder={'Search Petitioner'}
                className={'custom-search-model'}
                onChange={async (e) => {
                  setSearchValue((pre) => ({
                    ...pre,
                    petName: e.target.value,
                  }));
                  const instanceState = await instantState(setSearchValue);
                  filterOnChange(instanceState?.benName, e.target.value);
                }}
              />
            </Typography>
          </>
        )}
      </Typography>
      <InfiniteScroll
        dataLength={
          strategy && strategy.data && strategy.data.length
            ? strategy.data.length
            : 0
        }
        style={{
          overflow: 'unset',
        }}
        next={getMoreData}
        hasMore={hasMoreResults}
        scrollableTarget={'scrollParent'}
        loader={
          getMoreLoader && (
            <div
              style={{
                backgroundColor: '#ffffff',
                textAlign: 'center',
                width: '100%',
              }}>
              <CircularProgress
                size={30}
                sx={{ color: '#002c69', marginBottom: 10, marginTop: 10 }}
              />
            </div>
          )
        }
        endMessage>
        <table className="lead-table table-hover">
          <thead>
            {orgStrategy ? (
              <tr>
                <th scope="col">PETITIONER NAME</th>
                <th scope="col">FORM NAME</th>
                <th scope="col">SCHEDULE DATE</th>
                <th scope="col">LAST MODIFIED DATE</th>
              </tr>
            ) : (
              <tr>
                <th scope="col">BENEFECIARY NAME</th>
                <th scope="col">PETITIONER NAME</th>
                <th scope="col">ATTORNEY NAME</th>
                <th scope="col">VISA TYPE</th>
                <th scope="col">SCHEDULE DATE</th>
                <th scope="col">LAST MODIFIED DATE</th>
              </tr>
            )}
          </thead>
          <tbody>
            {strategy && strategy.data && strategy.data.length > 0 ? (
              strategy.data.map((data, index) =>
                orgStrategy ? (
                  <tr
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => selectStrategy(data)}>
                    <td style={{ textTransform: 'capitalize' }}>
                      {data?.userName}
                    </td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {data?.section_name}
                    </td>
                    <td>
                      {data?.start_date
                        ? moment(data?.start_date).format('yyyy-MM-DD hh:mm A')
                        : ''}
                    </td>
                    <td>
                      {data?.modified_at
                        ? moment(data?.modified_at).format('yyyy-MM-DD hh:mm A')
                        : ''}
                    </td>
                  </tr>
                ) : (
                  <tr
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => selectStrategy(data)}>
                    <td>
                      <a style={{ color: '#1093df' }}>
                        {data?.benificiery_name}
                      </a>
                    </td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {data?.org_name}
                    </td>
                    <td>{data?.attorney_name ? data?.attorney_name : ''}</td>
                    <td>{data?.visa_name_list[0]?.visa_code}</td>
                    <td>
                      {data?.scheduled_on
                        ? moment(data?.scheduled_on).format(
                            'yyyy-MM-DD hh:mm A',
                          )
                        : ''}
                    </td>
                    <td>
                      {data?.strategymodifieddate
                        ? moment(data?.strategymodifieddate).format(
                            'yyyy-MM-DD hh:mm A',
                          )
                        : ''}
                    </td>
                  </tr>
                ),
              )
            ) : (
              <tr className={'text-center'}>
                <th scope="row" colSpan={orgStrategy ? 4 : 6}>
                  No Record(s) Found.
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </InfiniteScroll>
    </DialogContent>
  );
};
