import { Grid, Typography } from '@mui/material';
import Varrow from 'assets/images/assarrow.svg';
import Setting from 'assets/images/buildcase.svg';
import Complete from 'assets/images/complete.svg';
import Contract from 'assets/images/contract.png';
import Elipse from 'assets/images/Ellipse.svg';
import Overx from 'assets/images/overx.svg';
import Path from 'assets/images/Path.svg';
import Strategy from 'assets/images/strategy.png';
import Upload from 'assets/images/upload.svg';
import Vx from 'assets/images/vx.svg';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  BeneficiaryPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import _ from 'lodash';
import { ResourceStation } from 'pages/private/Client/Petitions/PetitionBuildCase/ResourceStation';
// import _ from 'lodash';
import React, { useState } from 'react';
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { UserRoleType } from 'types';

type DetailProps = {
  showType;
  listData?;
  handleOpen?: any;
};

export const LeadsOrPetitionsDetail: React.FC<DetailProps> = ({
  showType,
  listData,
  handleOpen,
}) => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [resourceStation, setResourceStation] = useState({
    open: false,
  });
  const { petitions } = useAppSelector((store: any) => store.petitionUser);

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const petitionData = !_.isEmpty(listData.petitionData)
    ? listData.petitionData
    : listData;

  const showBC = () => {
    return (
      <Grid md={4} item className="m-64 pl-0 pt-0 st-1">
        <Typography
          component={'div'}
          className={
            showType === 'petitions'
              ? petitionData.isAffilateCase
                ? petitionData.affilateRoleId === 3
                  ? 'leadDetailBox'
                  : 'leadDetailBox disable-lead'
                : 'leadDetailBox'
              : 'leadDetailBox disable-lead'
          }
          onClick={() => {
            if (showType === 'petitions' && userDetail.role_id === 3) {
              if (
                showType === 'petitions' &&
                userDetail.role_id === 3 &&
                petitionData.isAffilateCase &&
                petitionData.affilateRoleId === 2
              ) {
                // Existing Attorney but Petitioner's Affiliate (No Access)
                return;
              } else {
                // //Existing Attorney OR Existing Attorney with Attorney Role Affiliate
                navigate(
                  `${String(
                    AttorneyPrivateRoutes.PETITIONS.BUILDCASE,
                  )}/${String(petitions?.petitionData?.id)}${
                    searchParams.get('orgId')
                      ? '?orgId=' + searchParams.get('orgId')
                      : ''
                  }${
                    location.pathname.includes('invited')
                      ? '&invited=true&isPetition=true'
                      : location.pathname.includes('associated')
                      ? '&associated=true&isPetition=true'
                      : ''
                  }`,
                  {
                    state: {
                      showType: showType,
                    },
                  },
                );
                return;
              }
            }
            if (
              showType === 'petitions' &&
              userDetail.role_id === 5 &&
              petitionData.isAffilateCase &&
              petitionData.affilateRoleId === 3
            ) {
              // Individual Affiliate Attorney
              navigate(
                `${String(
                  AffilatePrivateRoutes.ATTORNEY_PETITIONS.BUILDCASE,
                )}/${String(petitions?.petitionData?.id)}`,
                {
                  state: {
                    showType: showType,
                  },
                },
              );
              return;
            }
          }}>
          <Typography component={'div'} className="d-flex align-center mb-2">
            <Typography component={'div'} className="info d-flex-ja">
              <img src={Setting} alt="path" />
            </Typography>
            <Typography className="f-20 f-bold pl-2">
              {petitionData.isAffilateCase
                ? petitionData.affilateRoleId === 3
                  ? 'Build Case'
                  : 'Review & Approve'
                : 'Build Case'}
            </Typography>
          </Typography>
          <Typography component={'div'} className="f-15 dis-text">
            Your legal team builds the complete petition, including forms,
            supporting documents, and brief, for review and submission.
          </Typography>
        </Typography>
      </Grid>
    );
  };

  const showRA = () => {
    return (
      <Grid md={4} item className="m-64 pl-0 pt-0 st-1">
        <Typography
          component={'div'}
          className={
            showType === 'petitions'
              ? petitionData.isAffilateCase
                ? petitionData.affilateRoleId === 2
                  ? userDetail.role_id === 5
                    ? 'leadDetailBox disable-lead'
                    : 'leadDetailBox'
                  : 'leadDetailBox disable-lead'
                : 'leadDetailBox'
              : showType === 'beneficiary' && userDetail.role_id === 4
              ? 'leadDetailBox'
              : 'leadDetailBox disable-lead'
          }
          onClick={() => {
            if (showType === 'petitions' && userDetail.role_id === 2) {
              if (
                showType === 'petitions' &&
                userDetail.role_id === 2 &&
                petitionData.isAffilateCase &&
                petitionData.affilateRoleId === 3
              ) {
                // Existing Petitioner but Attorney's Affiliate (Full Access) - pending work
                return;
              } else {
                // Existing Petitioner OR Existing Petitioner with Petitioner Role Affiliate
                navigate(
                  `${String(
                    PetitionerPrivateRoutes.PETITIONS.REVIEWANDAPPROVE,
                  )}/${String(petitions.petitionData.id)}`,
                  {
                    state: {
                      showType: showType,
                    },
                  },
                );
                return;
              }
            }
            if (
              showType === 'petitions' &&
              userDetail.role_id === 5 &&
              petitionData.isAffilateCase &&
              petitionData.affilateRoleId === 2
            ) {
              // Individual Affiliate Petitioner (No Access)
              // navigate(
              //   `${String(
              //     AffilatePrivateRoutes.PETITIONER_PETITIONS.REVIEWANDAPPROVE,
              //   )}/${String(petitions.petitionData.id)}`,
              //   {
              //     state: {
              //       showType: showType,
              //     },
              //   },
              // );
              return;
            }
            if (showType === 'beneficiary' && userDetail.role_id === 4) {
              navigate(
                `${String(BeneficiaryPrivateRoutes.REVIEWANDAPPROVE)}/${String(
                  listData.petitionId,
                )}?leadUser=${String(listData.lead_user_id)}`,
                {
                  state: {
                    showType: showType,
                  },
                },
              );
              return;
            }
          }}>
          <Typography component={'div'} className="d-flex align-center mb-2">
            <Typography component={'div'} className="info d-flex-ja">
              <img src={Setting} alt="path" />
            </Typography>
            <Typography className="f-20 f-bold pl-2">
              {petitionData.isAffilateCase
                ? petitionData.affilateRoleId === 3
                  ? 'Build Case'
                  : 'Review & Approve'
                : 'Review & Approve'}
            </Typography>
          </Typography>
          <Typography component={'div'} className="f-15 dis-text">
            Your legal team builds the complete petition, including forms,
            supporting documents, and brief, for review and submission.
          </Typography>
        </Typography>
      </Grid>
    );
  };
  return (
    <Typography component={'div'}>
      <Grid container>
        <Grid
          item
          md={11}
          sm={12}
          className="pl-0"
          sx={{ paddingRight: '2rem' }}>
          <Grid container className="o-lead-box">
            <Grid md={4} item className="m-41 m-64 st-1">
              {/* <NavLink
                to={}> */}
              <Typography
                component={'div'}
                className={
                  showType === 'beneficiary' && listData.petitionId
                    ? 'leadDetailBox'
                    : showType !== 'beneficiary'
                    ? 'leadDetailBox'
                    : 'leadDetailBox disable-lead'
                }
                onClick={() => {
                  const invited =
                    location.pathname.includes('invited') ||
                    searchParams.get('invited') === 'true';
                  const associated =
                    location.pathname.includes('associated') ||
                    searchParams.get('associated') === 'true';

                  navigate(
                    showType === 'leads'
                      ? userDetail.role_id === 2
                        ? `${PetitionerPrivateRoutes.LEADS.LEADSEDIT}/${String(
                            listData.id,
                          )}`
                        : userDetail.role_id === 5
                        ? listData.isAffilateCase &&
                          listData.affilateRoleId === 3
                          ? `${String(
                              AffilatePrivateRoutes.ATTORNEY_LEADS.LEADSEDIT,
                            )}/${String(listData.id)}`
                          : `${String(
                              AffilatePrivateRoutes.PETITIONER_LEADS.LEADSEDIT,
                            )}/${String(listData.id)}`
                        : `${AttorneyPrivateRoutes.LEADS.LEADSEDIT}/${String(
                            listData.id,
                          )}${
                            searchParams.get('orgId')
                              ? '?orgId=' + searchParams.get('orgId')
                              : ''
                          }${
                            invited
                              ? '&invited=true&isLead=true'
                              : associated
                              ? '&associated=true&isLead=true'
                              : ''
                          }`
                      : userDetail.role_id === 2 && !_.isEmpty(petitions)
                      ? `${
                          PetitionerPrivateRoutes.PETITIONS.PETITIONCASEDETAIL
                        }/${String(petitions.petitionData?.id)}`
                      : showType === 'beneficiary' && userDetail.role_id === 4
                      ? `${
                          listData.petitionId
                            ? `${
                                BeneficiaryPrivateRoutes.BENEFICIARYCASEDETAIL
                              }/${String(
                                listData.petitionId,
                              )}?leadUser=${String(listData.lead_user_id)}`
                            : ''
                        }`
                      : userDetail.role_id === 5 && !_.isEmpty(petitions)
                      ? `${String(
                          AffilatePrivateRoutes.PETITIONER_PETITIONS
                            .PETITIONCASEDETAIL,
                        )}/${String(petitions.petitionData.id)}`
                      : `${
                          AttorneyPrivateRoutes.PETITIONS.PETITIONCASEDETAIL
                        }/${String(petitions.petitionData?.id)}${
                          searchParams.get('orgId')
                            ? '?orgId=' + searchParams.get('orgId')
                            : ''
                        }${
                          invited
                            ? '&invited=true&isPetition=true'
                            : associated
                            ? '&associated=true&isPetition=true'
                            : ''
                        }`,
                  );
                }}>
                <img src={Vx} alt="arrow" className="x-line" />
                <img src={Overx} alt="arrow" className="r-line" />
                <img src={Varrow} alt="arrow" className="v-line" />
                <Typography
                  component={'div'}
                  className="d-flex align-center mb-2">
                  <Typography
                    component={'div'}
                    className="info d-flex-ja"
                    style={{ backgroundImage: `url(${Elipse})` }}>
                    <img src={Path} alt="path" />
                  </Typography>
                  <Typography className="f-20 f-bold pl-2">
                    {showType === 'leads'
                      ? 'Lead Information'
                      : 'Case Information'}
                  </Typography>
                </Typography>
                <Typography component={'div'} className="f-15 dis-text">
                  The names and contact information for the Beneficiary,
                  Petitioner, Legal Team, and any other individuals related to
                  the case.
                </Typography>
              </Typography>
              {/* </NavLink> */}
            </Grid>
            <Grid md={4} item className="m-41 m-64 pl-0 st-1">
              <Typography
                component={'div'}
                className={
                  userDetail.userRoleName === UserRoleType.Attorney ||
                  userDetail.userRoleName === UserRoleType.Petitioner ||
                  userDetail.userRoleName === UserRoleType.Beneficiary ||
                  userDetail.userRoleName === UserRoleType.Affilate
                    ? showType === 'leads' &&
                      ((listData?.beneficiaryDetails &&
                        listData?.beneficiaryDetails.length) ||
                        listData.petitioner_assigned)
                      ? 'leadDetailBox'
                      : showType === 'petitions'
                      ? 'leadDetailBox'
                      : showType === 'beneficiary' && listData.petitionId
                      ? 'leadDetailBox'
                      : 'leadDetailBox disable-lead'
                    : 'leadDetailBox disable-lead'
                }
                onClick={() => {
                  if (
                    (listData?.beneficiaryDetails &&
                      listData?.beneficiaryDetails.length) ||
                    listData.petitioner_assigned ||
                    showType === 'petitions' ||
                    showType === 'beneficiary'
                  ) {
                    handleOpen();
                  }
                }}>
                <img src={Vx} alt="arrow" className="l-line" />
                <img src={Vx} alt="arrow" className="x-line" />
                <img src={Overx} alt="arrow" className="r-line" />
                <img src={Varrow} alt="arrow" className="v-line" />
                <Typography
                  component={'div'}
                  className="d-flex align-center mb-2">
                  <Typography component={'div'} className="info d-flex-ja">
                    <img src={Upload} alt="path" />
                  </Typography>
                  <Typography className="f-20 f-bold pl-2">
                    Upload Documents
                  </Typography>
                </Typography>
                <Typography component={'div'} className="f-15 dis-text">
                  Upload case documents (e.g. passport, resume) for analysis and
                  integration into the {"platform's"} supporting materials and
                  automatic extraction of answers to questions.
                </Typography>
              </Typography>
            </Grid>
            <Grid md={4} item className="m-64 pl-0 pt-0 st-1">
              <NavLink
                to={
                  showType === 'petitions' &&
                  !_.isEmpty(petitions) &&
                  userDetail.role_id === 3
                    ? `${
                        AttorneyPrivateRoutes.PETITIONS.COMPLETEQUESTIONS
                      }/${String(petitions.petitionData?.id)}${
                        searchParams.get('orgId')
                          ? '?orgId=' + searchParams.get('orgId')
                          : ''
                      }${
                        location.pathname.includes('invited')
                          ? '&invited=true&isPetition=true'
                          : location.pathname.includes('associated')
                          ? '&associated=true&isPetition=true'
                          : ''
                      }`
                    : showType === 'beneficiary' && userDetail.role_id === 4
                    ? `${BeneficiaryPrivateRoutes.COMPLETEQUESTIONS}/${String(
                        listData.petitionId
                          ? listData.petitionId
                          : listData.lead_user_id,
                      )}${
                        listData.petitionId
                          ? `?leadUser=${String(
                              listData.lead_user_id,
                            )}&type=petition`
                          : `?leadUser=${String(
                              listData.lead_user_id,
                            )}&lead=${String(listData?.lead_id)}`
                      } `
                    : showType === 'petitions' &&
                      !_.isEmpty(petitions) &&
                      userDetail.role_id === 5
                    ? `${String(
                        AffilatePrivateRoutes.ATTORNEY_PETITIONS
                          .COMPLETEQUESTIONS,
                      )}/${String(petitions.petitionData.id)}`
                    : showType === 'petitions' &&
                      !_.isEmpty(petitions) &&
                      `${
                        PetitionerPrivateRoutes.PETITIONS.COMPLETEQUESTIONS
                      }/${String(petitions.petitionData?.id)}`
                }
                state={{ showType: showType }}>
                <Typography
                  component={'div'}
                  className={
                    showType === 'leads'
                      ? 'leadDetailBox disable-lead'
                      : showType === 'beneficiary' && listData.petitionId
                      ? 'leadDetailBox'
                      : showType === 'petitions'
                      ? 'leadDetailBox'
                      : 'leadDetailBox disable-lead'
                  }>
                  <Typography component={'div'}>
                    <img src={Vx} alt="arrow" className="l-line" />
                    <img src={Varrow} alt="arrow" className="v-line" />
                    <Typography component={'div'} className="d-flex-a mb-2">
                      <Typography component={'div'} className="info d-flex-ja">
                        <img src={Complete} alt="path" />
                      </Typography>
                      <Typography className="f-20 f-bold pl-2">
                        Complete Questions
                      </Typography>
                    </Typography>
                    <Typography component={'div'} className="f-15 dis-text">
                      Complete any unanswered questions from the Strategy
                      Session or missing from uploaded documents.
                    </Typography>
                  </Typography>
                </Typography>
              </NavLink>
            </Grid>
            <Grid md={4} item className="m-64 pt-0 st-1">
              <Typography
                component={'div'}
                className={
                  userDetail.userRoleName === UserRoleType.Attorney ||
                  userDetail.userRoleName === UserRoleType.Petitioner ||
                  userDetail.userRoleName === UserRoleType.Beneficiary ||
                  userDetail.userRoleName === UserRoleType.Affilate
                    ? showType === 'leads' &&
                      ((listData?.beneficiaryDetails &&
                        listData?.beneficiaryDetails.length) ||
                        listData.petitioner_assigned)
                      ? 'leadDetailBox'
                      : showType === 'petitions'
                      ? 'leadDetailBox'
                      : showType === 'beneficiary' && listData.petitionId
                      ? 'leadDetailBox'
                      : 'leadDetailBox disable-lead'
                    : 'leadDetailBox disable-lead'
                }
                onClick={() => {
                  if (
                    (listData?.beneficiaryDetails &&
                      listData?.beneficiaryDetails.length) ||
                    listData.petitioner_assigned ||
                    showType === 'petitions' ||
                    showType === 'beneficiary'
                  ) {
                    navigate(
                      showType === 'leads'
                        ? userDetail.role_id === 2
                          ? `${PetitionerPrivateRoutes.LEADS.STRATEGY}/${String(
                              listData.id,
                            )}`
                          : userDetail.role_id === 5
                          ? listData.isAffilateCase &&
                            listData.affilateRoleId === 3
                            ? `${String(
                                AffilatePrivateRoutes.ATTORNEY_LEADS.STRATEGY,
                              )}/${String(listData.id)}`
                            : `${String(
                                AffilatePrivateRoutes.PETITIONER_LEADS.STRATEGY,
                              )}/${String(listData.id)}`
                          : `${AttorneyPrivateRoutes.LEADS.STRATEGY}/${String(
                              listData.id,
                            )}${
                              searchParams.get('orgId')
                                ? '?orgId=' + searchParams.get('orgId')
                                : ''
                            }${
                              location.pathname.includes('invited')
                                ? '&invited=true&isLead=true'
                                : location.pathname.includes('associated')
                                ? '&associated=true&isLead=true'
                                : ''
                            }`
                        : userDetail.role_id === 2
                        ? `${
                            PetitionerPrivateRoutes.PETITIONS.STRATEGY
                          }/${String(petitions.petitionData?.id)}`
                        : showType === 'beneficiary' && listData.petitionId
                        ? `${
                            BeneficiaryPrivateRoutes.BENEFICIARYSTRATEGY
                          }/${String(listData.petitionId)}?leadUser=${String(
                            listData.lead_user_id,
                          )}`
                        : userDetail.role_id === 5
                        ? `${String(
                            AffilatePrivateRoutes.ATTORNEY_PETITIONS.STRATEGY,
                          )}/${String(petitions.petitionData?.id)}`
                        : `${AttorneyPrivateRoutes.PETITIONS.STRATEGY}/${String(
                            petitions.petitionData?.id,
                          )}${
                            searchParams.get('orgId')
                              ? '?orgId=' + searchParams.get('orgId')
                              : ''
                          }${
                            location.pathname.includes('invited')
                              ? '&invited=true&isPetition=true'
                              : location.pathname.includes('associated')
                              ? '&associated=true&isPetition=true'
                              : ''
                          }`,
                      {
                        state: {
                          isAffilateCase: listData.isAffilateCase
                            ? { status: true, type: listData.affilate_type }
                            : null,
                          showType: showType,
                        },
                      },
                    );
                  }
                }}>
                <img src={Overx} alt="arrow" className="r-line" />
                <Typography
                  component={'div'}
                  className="d-flex align-center mb-2">
                  <Typography component={'div'} className="info d-flex-ja">
                    <img src={Strategy} alt="path" />
                  </Typography>
                  <Typography className="f-20 f-bold pl-2">
                    Strategy Session
                  </Typography>
                </Typography>
                <Typography component={'div'} className="f-15 dis-text">
                  Attorney and Client collaborate to explore the best strategic
                  approach for an effective case strategy.
                </Typography>
              </Typography>
            </Grid>
            <Grid md={4} item className="m-64 pl-0 pt-0 st-1">
              <Typography
                component={'div'}
                className={
                  userDetail.userRoleName === UserRoleType.Attorney ||
                  userDetail.userRoleName === UserRoleType.Petitioner ||
                  userDetail.userRoleName === UserRoleType.Beneficiary ||
                  userDetail.userRoleName === UserRoleType.Affilate
                    ? showType === 'leads' &&
                      ((listData?.beneficiaryDetails &&
                        listData?.beneficiaryDetails.length) ||
                        listData.petitioner_assigned)
                      ? 'leadDetailBox'
                      : showType === 'petitions'
                      ? 'leadDetailBox'
                      : showType === 'beneficiary' && listData.petitionId
                      ? 'leadDetailBox'
                      : 'leadDetailBox disable-lead'
                    : 'leadDetailBox disable-lead'
                }
                onClick={() => {
                  if (
                    (listData?.beneficiaryDetails &&
                      listData?.beneficiaryDetails.length) ||
                    listData.petitioner_assigned ||
                    showType === 'petitions' ||
                    showType === 'beneficiary'
                  ) {
                    navigate(
                      showType === 'leads'
                        ? userDetail.role_id === 2
                          ? `${
                              PetitionerPrivateRoutes.LEADS.CONTRACTPAYMENT
                            }/${String(listData.id)}`
                          : userDetail.role_id === 5
                          ? listData.isAffilateCase &&
                            listData.affilateRoleId === 3
                            ? `${String(
                                AffilatePrivateRoutes.ATTORNEY_LEADS
                                  .CONTRACTPAYMENT,
                              )}/${String(listData.id)}`
                            : `${String(
                                AffilatePrivateRoutes.PETITIONER_LEADS
                                  .CONTRACTPAYMENT,
                              )}/${String(listData.id)}`
                          : `${
                              AttorneyPrivateRoutes.LEADS.CONTRACTPAYMENT
                            }/${String(listData.id)}${
                              searchParams.get('orgId')
                                ? '?orgId=' + searchParams.get('orgId')
                                : ''
                            }${
                              location.pathname.includes('invited')
                                ? '&invited=true&isLead=true'
                                : location.pathname.includes('associated')
                                ? '&associated=true&isLead=true'
                                : ''
                            }`
                        : userDetail.role_id === 2 && !_.isEmpty(petitions)
                        ? `${
                            PetitionerPrivateRoutes.PETITIONS.CONTRACTPAYMENT
                          }/${String(petitions.petitionData?.id)}`
                        : showType === 'beneficiary'
                        ? `${
                            BeneficiaryPrivateRoutes.BENEFICIARYCONTRACTHISTORY
                          }/${String(listData.petitionId)}?leadUser=${String(
                            listData.lead_user_id,
                          )}`
                        : userDetail.role_id === 5 && !_.isEmpty(petitions)
                        ? `${String(
                            AffilatePrivateRoutes.ATTORNEY_PETITIONS
                              .CONTRACTPAYMENT,
                          )}/${String(petitions.petitionData?.id)}`
                        : `${
                            AttorneyPrivateRoutes.PETITIONS.CONTRACTPAYMENT
                          }/${String(petitions.petitionData?.id)}${
                            searchParams.get('orgId')
                              ? '?orgId=' + searchParams.get('orgId')
                              : ''
                          }${
                            location.pathname.includes('invited')
                              ? '&invited=true&isPetition=true'
                              : location.pathname.includes('associated')
                              ? '&associated=true&isPetition=true'
                              : ''
                          }`,
                    );
                  }
                }}>
                <img src={Overx} alt="arrow" className="r-line" />
                <Typography
                  component={'div'}
                  className="d-flex align-center mb-2">
                  <Typography component={'div'} className="info d-flex-ja">
                    <img src={Contract} alt="path" />
                  </Typography>
                  <Typography className="f-20 f-bold pl-2">
                    Contract and Payment Portal
                  </Typography>
                </Typography>
                <Typography component={'div'} className="f-15 dis-text">
                  Create and send contracts and invoices to clients for easy
                  electronic signature and payment processing.
                </Typography>
              </Typography>
            </Grid>
            {userDetail.role_id === 3 ||
            (userDetail.role_id === 5 &&
              petitionData.isAffilateCase &&
              petitionData.affilateRoleId === 3) ||
            (userDetail.role_id === 3 &&
              petitionData.isAffilateCase &&
              petitionData.affilateRoleId === 3)
              ? showBC()
              : null}

            {userDetail.role_id === 2 ||
            (userDetail.role_id === 5 &&
              petitionData.isAffilateCase &&
              petitionData.affilateRoleId === 2) ||
            (userDetail.role_id === 2 &&
              petitionData.isAffilateCase &&
              petitionData.affilateRoleId === 2) ||
            (showType === 'beneficiary' && userDetail.role_id === 4)
              ? showRA()
              : null}
          </Grid>
        </Grid>
        {userDetail.role_id === 3 ||
        (userDetail.role_id === 5 && !_.isEmpty(listData.petitionData)
          ? listData.petitionData.isAffilateCase &&
            listData.petitionData.affilateRoleId === 3
          : listData.isAffilateCase && listData.affilateRoleId === 3) ? (
          <Grid>
            <Typography component={'div'} className="add-box pb-2">
              <Typography component={'div'} className="add-inner-box" />
              <NavLink
                to={
                  (userDetail.role_id === 3 && showType === 'leads') ||
                  (showType === 'leads' && !_.isEmpty(listData.petitionData)
                    ? listData.petitionData.isAffilateCase &&
                      listData.petitionData.affilateRoleId === 3
                    : listData.isAffilateCase && listData.affilateRoleId === 3)
                    ? userDetail.role_id == 5
                      ? `${String(
                          AffilatePrivateRoutes.ATTORNEY_LEADS.LEADDETAILSLIST,
                        )}/${String(listData?.id)}/petition`
                      : `${String(
                          AttorneyPrivateRoutes.LEADS.LEADDETAILSLIST,
                        )}/${String(listData?.id)}/petition${
                          searchParams.get('orgId')
                            ? '?orgId=' + searchParams.get('orgId')
                            : ''
                        }${
                          location.pathname.includes('invited')
                            ? '&invited=true&isLead=true'
                            : location.pathname.includes('associated')
                            ? '&associated=true&isLead=true'
                            : ''
                        }`
                    : userDetail.role_id == 5
                    ? `${String(
                        AffilatePrivateRoutes.ATTORNEY_LEADS.LEADSEDIT,
                      )}/${String(listData?.leadList?.id)}?fromPetition=true`
                    : `${AttorneyPrivateRoutes.LEADS.LEADSEDIT}/${String(
                        listData?.leadList?.id,
                      )}?fromPetition=true${
                        searchParams.get('orgId')
                          ? '&orgId=' + searchParams.get('orgId')
                          : ''
                      }${
                        location.pathname.includes('invited') ||
                        searchParams.get('invited') === 'true'
                          ? '&invited=true&isLead=true'
                          : location.pathname.includes('associated') ||
                            searchParams.get('associated') === 'true'
                          ? '&associated=true&isLead=true'
                          : ''
                      }`
                }>
                <Typography
                  className="black-white-text d-flex-a a-mt-3"
                  style={{ marginTop: '18px' }}>
                  {(showType === 'leads' && userDetail.role_id === 3) ||
                  (showType === 'leads' &&
                    listData.isAffilateCase &&
                    listData.affilateRoleId === 3)
                    ? 'Petitions'
                    : 'Leads'}
                </Typography>
              </NavLink>
              {showType !== 'leads' && userDetail.role_id === 3 && (
                <Typography
                  className="black-white-text d-flex-a a-mt-3"
                  onClick={(e) =>
                    setResourceStation((pre) => ({
                      ...pre,
                      open: true,
                    }))
                  }>
                  Resource Station
                </Typography>
              )}
            </Typography>
          </Grid>
        ) : null}
        {userDetail.role_id === 2 && showType !== 'leads' ? (
          <Grid>
            <Typography component={'div'} className="add-box pb-2">
              <Typography component={'div'} className="add-inner-box" />
              <Typography
                className="black-white-text d-flex-a a-mt-3"
                style={{ marginTop: '18px' }}
                onClick={(e) =>
                  setResourceStation((pre) => ({
                    ...pre,
                    open: true,
                  }))
                }>
                Resource Station
              </Typography>
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      {resourceStation.open ? (
        <ResourceStation
          modalOnClose={() =>
            setResourceStation((pre) => ({
              ...pre,
              open: false,
            }))
          }
        />
      ) : null}
    </Typography>
  );
};

export default LeadsOrPetitionsDetail;
