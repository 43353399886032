import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React from 'react';

type DocumentParsingListTitleType = {
  params;
  handleChangeShowSubmitedDocument;
  handleChangeSucess;
};

export const DocumentParsingListTitle: React.FC<
  DocumentParsingListTitleType
> = ({ params, handleChangeShowSubmitedDocument, handleChangeSucess }) => {
  return (
    <Grid container className="sticky-tab">
      <Grid item md={12} sm={12}>
        <Grid container>
          <Grid item md={10} sm={6} className="d-flex-jae">
            {params.status == 'success' && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      className="parsin-ceck"
                      checked={params.read}
                      onChange={handleChangeShowSubmitedDocument}
                    />
                  }
                  label={
                    <Typography className="f-16 black-white-text">
                      Show Submitted Document
                    </Typography>
                  }
                />
              </FormGroup>
            )}
          </Grid>
          <Grid item md={2} sm={6} className="d-flex-jae">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={params.status == 'success' ? true : false}
                    onChange={handleChangeSucess}
                  />
                }
                label={
                  <Typography className="f-16 black-white-text">
                    {params.status == 'success' ? 'Success' : 'Pending'}
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentParsingListTitle;
