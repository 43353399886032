import { yupResolver } from '@hookform/resolvers/yup';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Button, Grid, Link, Typography } from '@mui/material';
import { Footer } from 'components/Footer';
import { InputText } from 'components/Forms/InputText';
import { OnlineVisaVideo } from 'components/Onlinevisavideo';
import { UseFocus, useMountEffect } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData } from 'services/CommonServices';
import useStyles from 'style/AuthStyles';
import { CreateSubmitForm } from 'types';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { createPasswordSchema } from 'utils/ValidatorSchema';

const CreatePassword: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [inputFocus, setInputFocus] = UseFocus();

  const [validateEmail, setValidateEmail] = useState('');
  const [loader, setLoader] = useState(false);

  const {
    control: createPasswordControl,
    handleSubmit,
    formState: { errors: createPasswordErrors },
    watch,
  } = useForm<CreateSubmitForm>({
    criteriaMode: 'all',
    defaultValues: {
      confirmPassword: '',
      password: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(createPasswordSchema),
  });

  const submitHandler = ({ confirmPassword, password }: CreateSubmitForm) => {
    setLoader(true);
    const payload = {
      confirmPassword: confirmPassword,
      emailHash: searchParams.get('encrpt'),
      password: password,
    };

    addNewData(payload, MASTER_ENDPOINT.CreatePassword)
      .then((resp: any) => {
        toast.success('Password created successfully.', alertOptions);
        setLoader(false);
        navigate('/login');
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (searchParams.get('encrpt') !== '') {
      setLoader(true);
      getAllListData(
        `${MASTER_ENDPOINT.CreatePassword}?email=${searchParams.get('encrpt')}`,
        {},
        true,
      )
        .then((restRes: any) => {
          setValidateEmail(restRes.email_id !== '' ? restRes.email_id : '');
          setLoader(false);
        })
        .catch((e) => {
          navigate('/login');
          setLoader(false);
        });
    } else {
      navigate('/login');
      toast.error('Link is invalid.', alertOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMountEffect(setInputFocus);

  return (
    <Grid container>
      <Grid
        item
        md={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '100vh',
        }}>
        <OnlineVisaVideo />
        <div className="login temp" style={{ top: '10px ' }}>
          <h2 style={{ color: '#ffffff' }}>Reset Password</h2>
          <div>
            <form className="formheight" onSubmit={handleSubmit(submitHandler)}>
              <Typography style={{ marginBottom: '.4rem' }}>
                <label className="label-text">
                  Email <span style={{ color: 'red' }}>*</span>{' '}
                </label>
              </Typography>
              <Typography component={'div'} style={{ marginBottom: '.5rem' }}>
                <InputText
                  inputRefObj={inputFocus}
                  isRefreshValue={true}
                  name={'email'}
                  value={validateEmail}
                  isEditable={true}
                  type={'email'}
                  variant="filled"
                  className="l-input"
                  placeholder="Your email address"
                />
              </Typography>
              <Typography style={{ marginBottom: '.4rem' }}>
                <label className="label-text">
                  Password <span style={{ color: 'red' }}>*</span>
                </label>
              </Typography>
              <Typography component={'div'} style={{ marginBottom: '.5rem' }}>
                <Controller
                  control={createPasswordControl}
                  defaultValue=""
                  name="password"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={createPasswordErrors.password?.message || ''}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={onChange}
                        isSecureTextEntry={true}
                        isEditable={false}
                        type={'password'}
                        variant="filled"
                        className="l-input"
                        placeholder="Password"
                      />
                    );
                  }}
                />
              </Typography>
              <Typography style={{ marginBottom: '.4rem' }}>
                <label className="label-text">
                  Confirm Password <span style={{ color: 'red' }}>*</span>
                </label>
              </Typography>
              <Typography component={'div'} style={{ marginBottom: '.5rem' }}>
                <Controller
                  control={createPasswordControl}
                  defaultValue=""
                  name="confirmPassword"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={
                          createPasswordErrors.confirmPassword?.message || ''
                        }
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={onChange}
                        isSecureTextEntry={true}
                        isEditable={false}
                        type={'password'}
                        variant="filled"
                        className="l-input"
                        placeholder="Password"
                      />
                    );
                  }}
                />
              </Typography>

              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  component={'div'}
                  className={classes.passwordErrorFormatTitle}>
                  PASSWORD MUST CONTAIN :
                </Typography>

                <Typography
                  component={'div'}
                  className={classes.passwordErrorFormatLabel}>
                  <div>
                    {watch('password').length >= 8 ? (
                      <CheckOutlinedIcon color={'success'} fontSize={'small'} />
                    ) : (
                      <CloseOutlinedIcon color={'error'} fontSize={'small'} />
                    )}
                  </div>
                  Length should be minimum 8 characters
                </Typography>

                <Typography
                  component={'div'}
                  className={classes.passwordErrorFormatLabel}>
                  <div>
                    {/[A-Z]+/.test(watch('password')) ? (
                      <CheckOutlinedIcon color={'success'} fontSize={'small'} />
                    ) : (
                      <CloseOutlinedIcon color={'error'} fontSize={'small'} />
                    )}
                  </div>
                  Use minimum one upper case letter
                </Typography>

                {/* <Typography
                  component={'div'}
                  className={classes.passwordErrorFormatLabel}>
                  <div>
                    {/[0-9]+/.test(watch('password')) ? (
                      <CheckOutlinedIcon color={'success'} fontSize={'small'} />
                    ) : (
                      <CloseOutlinedIcon color={'error'} fontSize={'small'} />
                    )}
                  </div>
                  At least 1 number
                </Typography> */}

                <Typography
                  component={'div'}
                  className={classes.passwordErrorFormatLabel}>
                  <div>
                    {/[*@!$#%&()^~|{}]+/.test(watch('password')) ? (
                      <CheckOutlinedIcon color={'success'} fontSize={'small'} />
                    ) : (
                      <CloseOutlinedIcon color={'error'} fontSize={'small'} />
                    )}
                  </div>
                  Use minimum one special character (! @ # $ % ^ & * ~ |)
                </Typography>
                <Typography
                  component={'div'}
                  className={classes.passwordErrorFormatLabel}>
                  <div>
                    {/[0-9]+/.test(watch('password')) ? (
                      <CheckOutlinedIcon color={'success'} fontSize={'small'} />
                    ) : (
                      <CloseOutlinedIcon color={'error'} fontSize={'small'} />
                    )}
                  </div>
                  Use minimum one number(0-9)
                </Typography>
              </Grid>

              {/* <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                className="submit"
                onClick={() => navigate('/login')}>
                Cancel
              </Button> */}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
                disabled={loader}>
                Submit
              </Button>
            </form>

            <div className="fotter">
              <Link
                className="d-flex-ja pb-1"
                underline="hover"
                href="#"
                onClick={() => navigate('/login')}>
                Back to Login
              </Link>
              <Typography className="s-text">
                For technical assistance, please email{' '}
                <a
                  href={`mailto:${String(process.env.REACT_APP_CONTACT_MAIL)}`}>
                  {String(process.env.REACT_APP_CONTACT_MAIL)}
                </a>
              </Typography>
            </div>
          </div>
        </div>
        <div className="loginstick-footer">
          <Footer />
        </div>
      </Grid>
    </Grid>
  );
};

export default CreatePassword;
