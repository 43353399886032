import _ from 'lodash';

import { PublicRoutes as PublicRoutesDirectives } from '../config/index';

export const alertOptions: any = {
  autoClose: 5000,
  closeOnClick: true,
  draggable: true,
  hideProgressBar: false,
  pauseOnHover: true,
  position: 'top-right',
  progress: undefined,
};

export const imgExtension = ['jpeg', 'jpg', 'png'];

export const mimeTypes = `.rtf, application/rtf, application/pdf, image/*, text/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow,
   application/vnd.openxmlformats-officedocument.presentationml.presentation`;

export const addNoteMimeTypes =
  'image/jpeg,image/png,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const submissionStationMimeTypes =
  'image/jpeg,image/png,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const parsingDocMimeTypes =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf, image/tiff, image/x-tiff, image/jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export const fileTypeCheckSkillParsing = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/pdf',
  'image/png',
  'image/jpg',
  // 'image/tiff',
  // 'image/x-tiff',
  'image/jpeg',
  // 'application/vnd.ms-excel',
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const addNotesFileType = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const profileFileType = ['image/jpeg', 'image/png'];

export const submissionStationFileType = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const docAndPdfType = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/pdf',
];

export const docAndPdfMimeTypes =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf';

export const parsingTypeArr: Array<string> = [
  'i-129',
  'resume',
  'passport',
  'lca',
  'g-28',
  'i-140',
];

export const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
  // if (/^\s*$/.test(str)) return false;
  // str = str.replace(/\\(?:["\\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@');
  // str = str.replace(
  //   /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?/g,
  //   ']',
  // );
  // str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
  // return /^[\],:{}\s]*$/.test(str);
};

export const jsonParseValue = (answer) => {
  try {
    const parseAnswer = JSON.parse(answer);
    return Array.isArray(parseAnswer)
      ? parseAnswer.length
        ? _.isObject(parseAnswer[0])
          ? parseAnswer.some((e) => e.status)
            ? parseAnswer
                .filter((ans) => ans.status)
                .map((res) => res.response)
                .join(', ')
            : '-'
          : parseAnswer.some((e) => String(e).toLowerCase().trim())
          ? parseAnswer.join(', ')
          : '-'
        : '-'
      : answer && String(answer) && String(answer).trim()
      ? answer
      : '-';
  } catch (error) {
    return '-';
  }
};

export const changeDobFormate = (date) => {
  if (!date) {
    return '';
  }
  const checkingDateFormat: any = new Date(date);
  let dateFormate = new Date(date);
  if (checkingDateFormat && checkingDateFormat.toString() !== 'Invalid Date') {
    dateFormate = checkingDateFormat;
  } else {
    const convertDate = date.split('/');
    if (convertDate.length < 3) {
      return date;
    }
    const dat = new Date(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${convertDate[2]}/${convertDate[1]}/${convertDate[0]}`,
    );
    dateFormate = new Date(dat);
  }

  return dateFormate
    ? `${'0'.concat(String(dateFormate.getMonth() + 1)).slice(-2)}/${'0'
        .concat(String(dateFormate.getDate()))
        .slice(-2)}/${dateFormate.getFullYear()}`
    : '';
};

export const formateDate = (num) => {
  if (num) {
    const checkingDateFormat: any = new Date(num);
    let dateFormate = new Date(num);
    if (
      checkingDateFormat &&
      checkingDateFormat.toString() !== 'Invalid Date'
    ) {
      dateFormate = checkingDateFormat;
    } else {
      const convertDate = num.split('/');
      if (convertDate.length < 3) {
        return num;
      }
      const dat = new Date(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${convertDate[2]}/${convertDate[1]}/${convertDate[0]}`,
      );
      dateFormate = new Date(dat);
    }
    return dateFormate
      ? `${'0'.concat(String(dateFormate.getMonth() + 1)).slice(-2)}/${'0'
          .concat(String(dateFormate.getDate()))
          .slice(-2)}/${dateFormate.getFullYear()}`
      : '';
  } else {
    return '';
  }
};

export const kFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return String(Number(num / 1000).toFixed(1)) + ' K'; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return String(Number(num / 1000000).toFixed(1)) + ' M'; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
};

export const getGenderValues = (gender) => {
  switch (gender) {
    case 'M':
      return 'male';
    case 'F':
      return 'female';
    case 'O':
      return 'others';
    case 'm':
      return 'male';
    case 'f':
      return 'female';
    case 'o':
      return 'others';
    default:
      return '';
  }
};

export const passportDefaultValues = {
  countryName: { confidence: '90', value: '' },
  dateOfBirth: { confidence: '90', value: '' },
  expiryDate: { confidence: '90', value: '' },
  firstName: { confidence: '90', value: '' },
  gender: { confidence: '90', value: '' },
  lastName: { confidence: '90', value: '' },
  middleName: { confidence: '90', value: '' },
  nationality: { confidence: '90', value: '' },
  passportNumber: { confidence: '90', value: '' },
};

export const resumeDefaultValues = {
  284: '',
  474: [],
};

export const sizeView = (x: number) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0;
  let n = parseInt(String(x), 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  // Include a decimal point and a tenths-place digit if presenting
  // Less than ten of KB or greater units
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

export const checkRadioButtonEnableAns = (ans) => {
  try {
    const arr: Array<any> = JSON.parse(ans);
    return arr && arr.length ? arr.some((pdf) => pdf.status) : false;
  } catch (error) {
    return false;
  }
};

export const getColorFormat = (confidencePoint): string => {
  return confidencePoint && Number(confidencePoint)
    ? Number(Number(confidencePoint).toFixed(2)) > 0.75
      ? ' custom-input-green'
      : ' custom-input-red'
    : 'custom-input';
};

export const getColorFormatbyTextArea = (confidencePoint): string => {
  return confidencePoint && Number(confidencePoint)
    ? Number(Number(confidencePoint).toFixed(2)) > 0.75
      ? ' custom-textarea-green'
      : ' custom-textarea-red'
    : 'custom-textarea';
};

export const checkAns = (checkBoxValue, ans) => {
  try {
    const arr = JSON.parse(ans);
    const checkInd = arr.findIndex(
      (pdf) => pdf.response.toLowerCase() === checkBoxValue.toLowerCase(),
    );
    if (checkInd !== -1) {
      return arr[checkInd].status;
    }
  } catch (error) {
    return false;
  }
};

export const ValidatePublicUrl = (pathName: string) => {
  const { LOGIN, FORGOTPASSWORD, CREATEPASSWORD, PAYMENT, POLICY, STRIPE } =
    PublicRoutesDirectives;
  return [
    '/',
    LOGIN,
    FORGOTPASSWORD,
    CREATEPASSWORD,
    PAYMENT.ACH,
    PAYMENT.CONTRACTVIEWER,
    PAYMENT.CREDITDEBITCARD,
    PAYMENT.PAYMENTTERMSANDCONDITIONS,
    PAYMENT.SECUREPAYMENT,
    POLICY.ACCEPTABLEUSEPOLICY,
    POLICY.DMCAPOLICY,
    POLICY.PRIVACYPOLICY,
    POLICY.TERMOFSERVICES,
    POLICY.VERSIONHISTORY,
    STRIPE.BILLINGDETAILS,
    STRIPE.CARDFORM,
    STRIPE.FAILURE,
    STRIPE.PRICING,
    STRIPE.SIGNUP,
    STRIPE.SUCCESS,
  ].includes(pathName);
};

export const reorderDnD = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const validateDate = (testdate) => {
  const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  return date_regex.test(testdate);
};

export const phoneNumberFormat = (ans: string) => {
  let phonNumber = String(ans);
  phonNumber = phonNumber
    .substring(phonNumber.indexOf(' ') + 1)
    .replace(/\D/g, '');
  return phonNumber;
};
