/* eslint-disable no-constant-condition */
import 'react-phone-input-2/lib/style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import profileimagelogo from 'assets/images/d-avator.svg';
import pencil from 'assets/images/pencil.svg';
import CustomDatePicker from 'components/CustomDatePicker/customDatePicker';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import SignaturePad from 'react-signature-canvas';
import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  USER_LOGIN_SUCCESS,
  USER_PROFILES_SUCCESS,
} from 'store/types/UserTypes';
import { MyProfiles } from 'types/index';
import { fileApiUrl } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { alertOptions, profileFileType, validateDate } from 'utils/helper';
import { MyProfileSchema } from 'utils/ValidatorSchema';

import { FileUploadDialog } from '../../../../components/Fileupload/fileuploadDialog';
import useStyles from '../../PrivateStyle';

export const MyProfile: React.FC<any> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { userInfo: userDetail, profile: profileDetail } = useAppSelector(
    (store: any) => store.userLogin,
  );
  // const hiddenFileInput = useRef(null);
  const sigPad = useRef<any>({});

  const [loader, setLoader] = useState(true);
  const [countries, setCountries] = useState<any>({
    cities: [],
    countries: [],
    states: [],
  });
  // const [profile, setProfile] = useState<any>({});
  const [imgFile, setImgFile] = useState({
    imagePreviewUrl: null,
    selectedFile: null,
  });

  const [mobileWithCode, setMobileWithCode] = useState<any>({
    MobileSocCode: '',
    mobileCode: '',
  });
  const [fileUploadOpen, setFileUploadOpen] = useState<any>({
    open: false,
  });
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm<MyProfiles>({
    criteriaMode: 'all',
    defaultValues: {
      address: '',
      city: '',
      country: '',
      dob: '',
      file: null,
      firstName: '',
      gender: '',
      lastName: '',
      mobileCountryCode: '',
      mobileNumber: '',
      mobileSocCode: '',
      phone: '',
      state: '',
      streetNumberAndName: '',
      unitNumber: '',
      unitType: '',
      zipCode: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(MyProfileSchema),
  });

  const onBlob = (signaturePadElement) => {
    const dataURL = signaturePadElement;
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; i++) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    const blob = new Blob([uInt8Array], { type: contentType });
    return blob;
  };

  const toBase64DataUrl = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  };

  const onSubmitForm = async (data: any) => {
    if (
      data['dob'] &&
      !validateDate(moment(data['dob']).format('MM/DD/YYYY'))
    ) {
      setError('dob', {
        message: 'Not a valid date format.',
        type: 'custom',
      });
    } else {
      setLoader(true);
      if (userDetail.role_id === 3) {
        const signatureBlob = onBlob(sigPad?.current?.toDataURL('image/png'));
        const signatureFormData = new FormData();
        signatureFormData.append('file', signatureBlob);
        await updateData(
          userDetail.id,
          signatureFormData,
          `${MASTER_ENDPOINT.Myprofile}/signature`,
        )
          .then((resp: any) => {
            setLoader(false);
            // toast.success('Profile updated successfully', alertOptions);
          })
          .catch((e) => {
            console.warn(e);
            setLoader(false);
          });
      }
      const postFormData = new FormData();

      postFormData.append('file', imgFile.selectedFile);
      postFormData.append('firstName', data['firstName']);
      postFormData.append('lastName', data['lastName']);
      postFormData.append('gender', data['gender']);
      postFormData.append(
        'dob',
        data['dob'] ? moment(data['dob']).format('MM/DD/YYYY') : '',
      );
      postFormData.append('address', data['address']);
      postFormData.append('zipCode', data['zipCode']);
      postFormData.append('city', data['city'] ? String(data['city']) : '');
      postFormData.append('state', data['state'] ? String(data['state']) : '');
      postFormData.append(
        'country',
        data['country'] ? String(data['country']) : '',
      );
      postFormData.append('unitType', data['unitType']);
      postFormData.append('unitNumber', data['unitNumber']);
      postFormData.append('phone', data['phone']);
      postFormData.append('streetNumberAndName', data['streetNumberAndName']);
      postFormData.append(
        'mobileNumber',
        data['mobileNumber']
          ? String(data['mobileNumber']).split(/(?<=^\S+)\s/)[1]
          : '',
      );
      postFormData.append('mobileCountryCode', mobileWithCode.mobileCode);
      postFormData.append(
        'mobileSocCode',
        mobileWithCode.MobileSocCode
          ? String(mobileWithCode.MobileSocCode).toUpperCase()
          : null,
      );

      await updateData('', postFormData, MASTER_ENDPOINT.Myprofile)
        .then((resp: any) => {
          getAllListData(MASTER_ENDPOINT.Myprofile).then((Resp: any) => {
            if (Resp.signature_key) {
              toBase64DataUrl(
                `${fileApiUrl}${String(Resp.id)}?key=${String(
                  Resp.signature_key,
                )}`,
                (params: any) => {
                  sigPad?.current?.fromDataURL(params);
                },
              );
            }
            dispatch({
              payload: Resp,
              type: USER_PROFILES_SUCCESS,
            });
            dispatch({
              payload: {
                ...userDetail,
                email_id: Resp.email_id,
                first_name: Resp.first_name,
                full_name: Resp.full_name,
                image: Resp.image,
                last_name: Resp.last_name,
                token: userDetail.token,
              },
              type: USER_LOGIN_SUCCESS,
            });
          });
          setLoader(false);
          toast.success('Profile updated successfully', alertOptions);
        })
        .catch((e) => {
          setLoader(false);
          // toast.error('Oops!!! Something went wrong', alertOptions);
        });
    }
  };

  const getCountriesDependent = useCallback(
    (key?: string, value?: number) => {
      getAllListData(
        key === ''
          ? MASTER_ENDPOINT.Countries
          : userDetail.role_id === 2
          ? `${MASTER_ENDPOINT.Countries}?${key}=`
          : `${MASTER_ENDPOINT.Countries}?${key}=${String(value)}`,
      )
        .then((countryResp: any) => {
          if (key === 'countryId') {
            setCountries((prev) => ({
              ...prev,
              countries: countryResp.countries,
              states: countryResp.states,
            }));
          }
          if (key === 'stateId') {
            setCountries((prev) => ({
              ...prev,
              cities: countryResp.cities,
              countries: countryResp.countries,
            }));
          }
          if (key === '') {
            setCountries({
              cities: countryResp.cities,
              countries: countryResp.countries,
              states: countryResp.states,
            });
          }
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
        });
    },
    [userDetail],
  );

  const profileSelected = (file) => {
    setImgFile((prev) => ({
      ...prev,
      selectedFile: file,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      setImgFile((prev) => ({
        ...prev,
        imagePreviewUrl: reader.result,
      }));
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    getAllListData(MASTER_ENDPOINT.Myprofile)
      .then((pResp: any) => {
        // setProfile(pResp);
        setValue('firstName', pResp.first_name || '');
        setValue('lastName', pResp.last_name || '');
        setValue(
          'gender',
          pResp.gender === 'M' || pResp.gender === 'male'
            ? 'male'
            : pResp.gender === 'F' || pResp.gender === 'female'
            ? 'female'
            : '',
        );
        setValue('dob', pResp.dob ? pResp.dob : '');
        setValue('address', pResp.address || '');
        setValue('unitType', pResp.unit_type || '');
        setValue('unitNumber', pResp.unit_number || '');
        setValue('country', Number(pResp.country) || '');
        setValue('zipCode', pResp.zip_code || '');
        setValue('state', Number(pResp.state) || '');
        setValue('city', Number(pResp.city) || '');
        setValue('phone', pResp.user_phone || '');
        setValue(
          'mobileNumber',
          pResp.contact_mobile
            ? String(pResp.contact_mobile).indexOf('+') !== -1
              ? pResp.contact_mobile
              : pResp.country_code_mobile
              ? String(pResp.country_code_mobile)
                  .concat(' ')
                  .concat(pResp.contact_mobile)
              : '+1 '.concat(pResp.contact_mobile)
            : '',
        );
        setValue('streetNumberAndName', pResp.street_number_and_name || '');

        if (pResp.image) {
          setImgFile((prev) => ({
            ...prev,
            imagePreviewUrl: `${fileApiUrl}${String(pResp.image)}`,
          }));
        }

        if (pResp.signature_key) {
          toBase64DataUrl(
            `${fileApiUrl}${String(pResp.id)}?key=${String(
              pResp.signature_key,
            )}`,
            (params: any) => {
              sigPad?.current?.fromDataURL(params);
            },
          );
        }

        setMobileWithCode({
          MobileSocCode: pResp.country_source_code,
          mobileCode: pResp.country_code_mobile,
        });

        pResp.country
          ? getCountriesDependent('countryId', pResp.country)
          : getCountriesDependent('');
        pResp.state
          ? getCountriesDependent('stateId', pResp.state)
          : getCountriesDependent('');

        // setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeUploadDialog = useCallback((action: any) => {
    if (action) {
      profileSelected(action);
    } else {
      setFileUploadOpen((pre) => ({
        ...pre,
        open: false,
      }));
    }
  }, []);
  return (
    <Grid
      container
      sx={{ marginTop: '1rem !important' }}
      columnSpacing={{ md: 0.6, sm: 1 }}>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <>
          <Grid item md={4} sm={12}>
            <Typography
              component={'div'}
              className={classes.profilePbox}
              sx={{ minHeight: '659px !important', position: 'relative' }}>
              <Typography
                component={'div'}
                className="d-flex-ja flex-column"
                sx={{ paddingTop: '103px' }}>
                <Typography component={'div'} sx={{ position: 'relative' }}>
                  <img
                    src={pencil}
                    alt="pencileditsvg"
                    style={{
                      bottom: '25px',
                      position: 'absolute',
                      right: '14px',
                    }}
                    onClick={() => setFileUploadOpen({ open: true })}
                  />

                  <img
                    src={
                      imgFile.imagePreviewUrl
                        ? imgFile.imagePreviewUrl
                        : profileimagelogo
                    }
                    alt=""
                    className={classes.myphoto}
                  />
                </Typography>
                <Typography className={classes.myname}>
                  {profileDetail.first_name} {profileDetail.last_name}
                </Typography>
                <Typography className={classes.mymail}>
                  {profileDetail.email_id}
                </Typography>
              </Typography>
              <Typography
                className="d-flex-ja flex-column"
                style={{ paddingTop: '134px' }}>
                <Typography className={classes.border} />
                <Typography className={classes.bottext}>
                  Joined{' '}
                  {moment(profileDetail?.created_at).format('MMMM DD, YYYY')}
                </Typography>
              </Typography>

              {/* <input
                type="file"
                hidden
                ref={hiddenFileInput}
                onChange={(e) => {
                  profileSelected(e.target.files);
                }}
                accept="image/png, image/jpeg"
              /> */}
              {fileUploadOpen.open && (
                <FileUploadDialog
                  open={fileUploadOpen.open}
                  title={'Upload Profile '}
                  allowedFileType={profileFileType}
                  errorMessage={
                    'You can upload documents of types JPEG, PNG only'
                  }
                  info={'(You can upload documents of types JPEG, PNG only)'}
                  onConfirm={closeUploadDialog}
                  setFileUploadOpen={setFileUploadOpen}
                  loader={loader}
                />
              )}
            </Typography>
          </Grid>
          <Grid item md={8} sm={12}>
            <form id="edit-profile" onSubmit={handleSubmit(onSubmitForm)}>
              <Typography
                component={'div'}
                className="addbox"
                sx={{
                  marginTop: 'unset !important',
                  minHeight: '659px !important',
                }}>
                <Box
                  component="form"
                  className="u-add-in"
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          First Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name="firstName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                maxLength={50}
                                placeHolder="First Name"
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.firstName?.message}
                                onChange={(val) => onChange(val)}
                                tabIndex={1}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Gender
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="gender"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                tabIndex={3}
                                options={[
                                  {
                                    label: 'Male',
                                    value: 'male',
                                  },
                                  {
                                    label: 'Female',
                                    value: 'female',
                                  },
                                ]}
                                name={name}
                                value={value}
                                onChange={(val) => onChange(val)}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.gender?.message}
                              />
                            );
                          }}
                        />
                      </Typography>

                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Street Number And Name
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="streetNumberAndName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Street Number And Name'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.streetNumberAndName?.message}
                                onChange={(val) => onChange(val)}
                                tabIndex={5}
                              />
                            );
                          }}
                        />
                      </Typography>

                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Unit Number
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="unitNumber"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                maxLength={50}
                                placeHolder={'Unit Number'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.unitNumber?.message}
                                onChange={(val) => onChange(val)}
                                tabIndex={7}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          State
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="state"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                searchable={true}
                                labelKey={'name'}
                                valueKey={'id'}
                                options={
                                  !_.isEmpty(countries?.states)
                                    ? countries?.states
                                    : []
                                }
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  getCountriesDependent(
                                    'stateId',
                                    e.target.value,
                                  );
                                }}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.state?.message}
                                tabIndex={9}
                              />
                            );
                          }}
                        />
                      </Typography>

                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Zip / Postal Code
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="zipCode"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                maxLength={50}
                                placeHolder={'Zip / Postal Code'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.zipCode?.message}
                                onChange={(val) =>
                                  onChange(
                                    val.target.value.replace(/[^\d]/g, ''),
                                  )
                                }
                                type={'text'}
                                tabIndex={11}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Mobile
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="mobileNumber"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <PhoneInput
                                placeholder="Phone"
                                country={'us'}
                                value={value}
                                onChange={(
                                  value,
                                  data,
                                  event,
                                  formattedValue,
                                ) => {
                                  const codes: any = data;
                                  onChange(formattedValue);
                                  setMobileWithCode({
                                    MobileSocCode: codes?.countryCode,
                                    mobileCode: '+'.concat(codes?.dialCode),
                                  });
                                }}
                              />
                              // <CustomInput
                              //   placeHolder={''}
                              //   name={name}
                              //   value={value}
                              //   className={'custom-input'}
                              //   error={errors.mobileNumber?.message}
                              //   onChange={(val) => onChange(val)}
                              //   type={'number'}
                              // />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Last Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="lastName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                maxLength={50}
                                placeHolder="Last Name"
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.lastName?.message}
                                onChange={(val) => onChange(val)}
                                tabIndex={2}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Date Of Birth
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue=""
                          name="dob"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            const now = new Date();
                            return (
                              <CustomDatePicker
                                className="custom-input"
                                errors={errors.dob?.message}
                                name={name}
                                value={value}
                                onChangeDate={(val) => onChange(val)}
                                tabIndex={4}
                                maxDate={
                                  new Date(
                                    now.getFullYear() - 18,
                                    now.getMonth(),
                                    now.getDate(),
                                  )
                                }
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Unit Type
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue="appartment"
                          name="unitType"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name={name}
                                value={value}
                                tabIndex={6}
                                onChange={(e) => {
                                  onChange(e);
                                }}>
                                <FormControlLabel
                                  value="apt"
                                  control={<Radio />}
                                  label="Apartment"
                                />
                                <FormControlLabel
                                  value="ste"
                                  control={<Radio />}
                                  label="Suite"
                                />
                                <FormControlLabel
                                  value="flr"
                                  control={<Radio />}
                                  label="Floor"
                                />
                                {getValues().unitType && (
                                  <FormControlLabel
                                    value=""
                                    control={
                                      <span
                                        className="reset-button"
                                        onClick={() =>
                                          setValue('unitType', '')
                                        }>
                                        Reset
                                      </span>
                                    }
                                    label=""
                                  />
                                )}
                              </RadioGroup>
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Country
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="country"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                searchable={true}
                                labelKey={'name'}
                                valueKey={'id'}
                                options={
                                  !_.isEmpty(countries?.countries)
                                    ? countries?.countries
                                    : []
                                }
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  getCountriesDependent(
                                    'countryId',
                                    e.target.value,
                                  );
                                }}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.country?.message}
                                tabIndex={8}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          City
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue=""
                          name="city"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                searchable={true}
                                labelKey={'name'}
                                valueKey={'id'}
                                options={
                                  !_.isEmpty(countries?.cities)
                                    ? countries?.cities
                                    : []
                                }
                                name={name}
                                value={value}
                                onChange={(val) => onChange(val)}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.city?.message}
                                tabIndex={10}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Phone
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="phone"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'phone'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.phone?.message}
                                onChange={(val) => onChange(val)}
                                type={'number'}
                                tabIndex={12}
                              />
                            );
                          }}
                        />
                      </Typography>
                      {userDetail.role_id === 3 && (
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Signature
                          </Typography>
                          <div
                            className={classes.sigContainer}
                            style={{ alignItems: 'center', display: 'flex' }}
                            tabIndex={13}>
                            <Typography
                              component={'div'}
                              style={{
                                border: '1px solid lightgray',
                                position: 'relative',
                              }}>
                              <SignaturePad
                                canvasProps={{
                                  height: '52px',
                                  width: '250px',
                                }}
                                ref={sigPad}
                                penColor="black"
                              />
                              <div
                                style={{
                                  position: 'absolute',
                                  right: '0',
                                  top: '0',
                                }}>
                                <Tooltip title="clear">
                                  <HighlightOffIcon
                                    sx={{
                                      cursor: 'pointer',
                                      fill: 'red',
                                    }}
                                    onClick={() => sigPad?.current?.clear()}
                                  />
                                </Tooltip>
                              </div>
                            </Typography>
                          </div>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Typography>
            </form>
          </Grid>
          <Grid container className="bottom-fixed">
            <Grid item md={12}>
              <Typography component={'div'} className="d-flex-jae">
                <Button
                  className="add-btn"
                  type="submit"
                  form={'edit-profile'}
                  tabIndex={14}
                  disabled={loader}>
                  Save
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default MyProfile;
