import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Checkbox, Grid, Tooltip, Typography } from '@mui/material';
import { DocumentViewer } from 'components/CustomDocumentViewer/CustomeDocumentViewer';
import CustomInput from 'components/CustomInput/customInput';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import { toast } from 'react-toastify';
import { addNewData, getAllListData, getS3file } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import useStyles from '../../../../PrivateStyle';

export const ContractViewer: React.FC<any> = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [searchParams] = useSearchParams();

  const sigPad = useRef<any>({});
  const [loader, setLoader] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [submitOTP, setSubmitOTP] = useState(false);
  const [otpSendClick, setOtpSendClick] = useState(false);
  const [selectSendTo] = useState('email');
  const [emailID, setEmailID] = useState('');
  const [mobileCodes, setMobileCodes] = useState<any>({
    countryCode: '',
    dialCode: '',
    number: '',
  });
  const [otpValue, setOtpValue] = useState({
    error: '',
    value: '',
  });
  const [paymentInfo, setPaymentInfo] = useState<any>({});
  const [signature, setSignature] = useState<any>({});
  const [arrayBuffer, setArrayBuffer] = useState<any>(null);

  const downloadPdf = (contractKey) => {
    readFile(contractKey, true);
  };
  const readFile = (contractKey, isDownload = false) => {
    if (!contractKey) {
      toast.error('File not found', alertOptions);
      return;
    }
    const obj = {
      key: contractKey,
    };
    const fileName = `contract-${
      new Date().getMonth() + 1
    }/${new Date().getDate()}/${new Date().getFullYear()}.pdf`;
    setLoader(true);

    getS3file(obj, `/${String(COMMON_ENDPOINT.ContractDownloadPDF)}`)
      .then((blobRes: any) => {
        setLoader(false);
        const resUrl = URL.createObjectURL(blobRes);
        setArrayBuffer(resUrl);
        if (isDownload) {
          saveAs(blobRes, fileName);
          toast.success('File download successfully', alertOptions);
          return;
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error('File not found', alertOptions);
      });
  };

  const sendOTP = () => {
    setSubmitOTP(true);
    if (selectSendTo === 'email' && !emailID) {
      return;
    }
    // if (selectSendTo === 'mobile' && this.control.invalid) {
    //   return;
    // }
    const bodyData = {
      contactMobile:
        selectSendTo === 'mobile'
          ? mobileCodes.number
            ? mobileCodes.number
            : ''
          : '',
      countryCodeMobile:
        selectSendTo === 'mobile'
          ? mobileCodes.dialCode
            ? mobileCodes.dialCode
            : ''
          : '',
      countrySourceCode:
        selectSendTo === 'mobile'
          ? mobileCodes.countryCode
            ? mobileCodes.countryCode
            : ''
          : '',
      sendOtpTo: selectSendTo,
    };

    addNewData(
      bodyData,
      `${COMMON_ENDPOINT.ContractPayment}/sendotp/${String(
        paymentInfo.paymentInitiatedFor,
      )}/${String(
        paymentInfo.paymentInitiatedUserId,
      )}?emailId=${emailID}&name=${String(paymentInfo.userName)}`,
    )
      .then((data) => {
        // if (!otpSendClick) {
        //   signPad();
        // }
        setOtpSendClick(true);
        toast.success(
          'OTP has been sent to your ' +
            (selectSendTo === 'mobile' ? 'mobile.' : 'email address.'),
          alertOptions,
        );
      })
      .catch(() => {
        toast.error('Unable to send OTP', alertOptions);
      });
  };

  const approve = () => {
    setOtpSendClick(true);
    if (
      _.isEmpty(signature) ||
      !confirm ||
      otpValue.value === '' ||
      otpValue.error !== ''
    ) {
      return;
    }
    const signatureBlob = onBlob(signature.data);
    const formData = new FormData();
    formData.append('file', signatureBlob);
    formData.append('otpNumber', otpValue.value);
    setLoader(true);
    sigPad?.current?.fromDataURL(signatureBlob);

    addNewData(
      formData,
      `${COMMON_ENDPOINT.ApproveContract}/${String(
        String(paymentInfo?.template_id),
      )}?buildtype=contract&leadUserId=${String(
        String(paymentInfo?.paymentInitiatedUserId),
      )}&contractfor=${String(
        paymentInfo?.paymentInitiatedFor,
      )}&leadId=${String(paymentInfo?.paymentInitiatedLeadId)}&firmId=${String(
        paymentInfo?.firmCompanyId,
      )}&mailId=${String(paymentInfo?.mailId)}`,
    )
      .then((data) => {
        setLoader(false);
        navigate(`/secure-payment?hashKey=${searchParams.get('hashKey')}`);
      })
      .catch((e) => {
        setOtpValue((pre) => ({
          ...pre,
          error: e,
        }));
        // toast.error(e ? e : 'Unable to build the document', alertOptions);
        setLoader(false);
      });
  };

  const onBlob = (signaturePadElement: any) => {
    const dataURL = signaturePadElement;
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; i++) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    const blob = new Blob([uInt8Array], { type: contentType });
    return blob;
  };

  useEffect(() => {
    if (searchParams.get('hashKey') !== '') {
      setLoader(true);
      getAllListData(
        `${COMMON_ENDPOINT.ContractPayment}?hashKey=${searchParams.get(
          'hashKey',
        )}`,
      )
        .then((info: any) => {
          setPaymentInfo(info.data);
          setEmailID(info.data.mailId);
          setLoader(false);
          readFile(info.data ? info.data?.attorneyContract : '');
        })
        .catch((e) => {
          // toast.error(
          //   'The link is invalid. Please contact your attorney.',
          //   alertOptions,
          // );
          navigate('/login');
          setLoader(false);
        });
    } else {
      toast.error('Page not found', alertOptions);
      navigate('/login');
    }
  }, [navigate, searchParams]);
  return (
    <Grid container>
      <Grid item md={12}>
        {loader ? <div className="liberty-loader" /> : ''}
        <Grid container columnSpacing={{ md: 2, sm: 1 }}>
          <Grid item md={6} className="pl-0">
            <Typography component={'div'} className="d-flex-a d-flex-sb mb-1">
              <Typography className="black-white-text f-22 font-bold">
                Contract Details
              </Typography>
              <Button
                className="n-add-btn"
                sx={{
                  borderRadius: '5px !important',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '0.9em !important',
                  height: '35px !important',
                  width: '93px !important',
                }}
                onClick={() => downloadPdf(paymentInfo.attorneyContract)}>
                Download
              </Button>
            </Typography>
            <Typography
              className="assem-box"
              id="parentdocId"
              style={{
                height: 'calc(100vh - 180px)',
                overflow: 'auto',
              }}>
              <DocumentViewer
                fileBlob={arrayBuffer}
                fileType={'pdf'}
                width={
                  document.getElementById('parentdocId') &&
                  document.getElementById('parentdocId').offsetWidth
                    ? `${
                        document.getElementById('parentdocId').offsetWidth - 100
                      }px`
                    : 'auto'
                }
              />
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography component={'div'} className="mb-1">
              <Button
                className="n-cancel-btn"
                sx={{
                  borderRadius: '5px !important',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '0.9em !important',
                  height: '35px !important',
                  width: '93px !important',
                }}
                onClick={() =>
                  navigate(
                    `/secure-payment?hashKey=${searchParams.get('hashKey')}`,
                  )
                }>
                <Typography
                  className="o-box"
                  sx={{
                    borderRadius: '5px !important',
                  }}
                />
                <ArrowBackIcon sx={{ marginRight: '.5rem' }} />
                Back
              </Button>
            </Typography>
            <Typography component={'div'} className="pview-vox">
              <Typography component={'div'} className="paydis f-18">
                Please read through the document and provide your acceptance.
              </Typography>
              <Typography component={'div'} className=" d-flex-a mt-2 mb-2">
                <Checkbox
                  className="pl-0"
                  onChange={(e) => setConfirm(e.target.checked)}
                />
                <Typography className="f-15 font-bold pl-3">
                  I agree of the terms of the agreement.
                </Typography>
              </Typography>
              {confirm && (
                <>
                  {paymentInfo &&
                    paymentInfo.mailId &&
                    selectSendTo === 'email' && (
                      <Typography className="subdis f-15 mb-1">
                        Your email address as per our records. If this is not
                        your email address, please talk to your attorney.
                      </Typography>
                    )}
                  {paymentInfo &&
                    !paymentInfo.mailId &&
                    selectSendTo === 'email' && (
                      <Typography className="subdis f-15 mb-1">
                        Your attorney has not provided your Email Address.
                        Please enter your Email Address below to proceed
                        further.
                      </Typography>
                    )}
                  {paymentInfo &&
                    paymentInfo.contactMobile &&
                    selectSendTo === 'mobile' && (
                      <Typography className="subdis f-15 mb-1">
                        Your Phone Number as per our records. If this is not
                        your Phone Number, Please enter your Phone Number below
                        to proceed further.
                      </Typography>
                    )}
                  {paymentInfo &&
                    !paymentInfo.contactMobile &&
                    selectSendTo === 'mobile' && (
                      <Typography className="subdis f-15 mb-1">
                        Your attorney has not provided your Phone Number. Please
                        enter your Phone Number below to proceed further.
                      </Typography>
                    )}
                  <form>
                    <Grid container>
                      <Grid
                        item
                        md={9}
                        sm={12}
                        className="custom-field"
                        sx={{ paddingRight: '1rem' }}>
                        {selectSendTo === 'email' && (
                          <>
                            {paymentInfo.mailId && (
                              <CustomInput
                                className="custom-input"
                                disabled
                                value={paymentInfo.mailId}
                              />
                            )}
                            {!paymentInfo.mailId && (
                              <>
                                <CustomInput
                                  className="custom-input"
                                  name="email"
                                  id="email"
                                  value={emailID}
                                  type="email"
                                />
                                {submitOTP &&
                                  !emailID &&
                                  selectSendTo === 'email' && (
                                    <span className={'Mui-error'}>
                                      Email is required
                                    </span>
                                  )}
                              </>
                            )}
                          </>
                        )}

                        {selectSendTo === 'mobile' && (
                          <PhoneInput
                            placeholder="phone"
                            inputStyle={{ width: '200px' }}
                            country={'us'}
                            onChange={(value, data, event, formattedValue) => {
                              const codes: any = data;
                              setMobileCodes((pre) => ({
                                ...pre,
                                countryCode: codes?.countryCode,
                                dialCode: '+'.concat(codes?.dialCode),
                                number: formattedValue,
                              }));
                            }}
                          />
                        )}

                        <Typography className="mail-info mt-1">
                          We will send an One Time Password(OTP) to the above
                          {selectSendTo === 'mobile'
                            ? 'Phone Number'
                            : 'Email Address'}
                          . Please click the Send OTP button.
                        </Typography>
                      </Grid>
                      <Grid item md={3} sm={12}>
                        <Button
                          className="n-add-btn"
                          sx={{
                            borderRadius: '5px !important',
                            fontFamily: 'Arial-Regular !important',
                            fontSize: '0.9em !important',
                            height: '35px !important',
                            minWidth: '100% !important',
                          }}
                          onClick={sendOTP}>
                          {otpSendClick ? 'Resend' : 'Send'} OTP
                        </Button>
                      </Grid>
                      {otpSendClick && (
                        <Grid
                          container
                          sx={{ margin: '1.5rem 0 .5rem 0 !important' }}>
                          <Grid item md={12} sm={12}>
                            <Typography
                              className="f-14 pl-2 pr-2 subdis mb-1"
                              sx={{
                                lineHeight: '16px',
                                paddingLeft: 'unset !important',
                              }}>
                              Please enter the OTP that was sent to your email.
                              <span className="required">*</span>
                            </Typography>
                          </Grid>
                          <Grid item md={7} sm={12} className="custom-field">
                            <CustomInput
                              className="custom-input"
                              maxLength={6}
                              name="otp"
                              id="otp"
                              value={otpValue.value}
                              onChange={(e) =>
                                setOtpValue((pre) => ({
                                  ...pre,
                                  error: '',
                                  value: e.target.value,
                                }))
                              }
                            />
                            {otpValue.value === '' && (
                              <span
                                className={'Mui-error f-14'}
                                style={{
                                  display: 'flex',
                                  paddingTop: '10px',
                                }}>
                                Required
                              </span>
                            )}
                            {otpValue.error !== '' && (
                              <span
                                className={'Mui-error f-14'}
                                style={{
                                  display: 'flex',
                                  paddingTop: '10px',
                                }}>
                                {otpValue.error}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {otpSendClick && (
                        <Grid item md={12} sm={12}>
                          <Typography className="f-14 mb-1">
                            Please sign here to acknowledge your acceptance of
                            the terms of the agreement.
                          </Typography>
                          <div
                            className={classes.sigContainer}
                            style={{ alignItems: 'center', display: 'flex' }}>
                            <Typography
                              component={'div'}
                              style={{
                                border: '1px solid lightgray',
                                position: 'relative',
                              }}>
                              <SignaturePad
                                id="sign"
                                canvasProps={{
                                  height: '52px',
                                  width: '250px',
                                }}
                                ref={sigPad}
                                penColor="black"
                                onEnd={() => {
                                  setSignature({
                                    data: sigPad?.current
                                      ?.getTrimmedCanvas()
                                      .toDataURL('image/png'),
                                  });

                                  // const imageData = onBlob(sigPad
                                  //   .getTrimmedCanvas()
                                  //   .toDataURL('image/png'));
                                }}
                              />
                              <div
                                style={{
                                  position: 'absolute',
                                  right: '0',
                                  top: '0',
                                }}>
                                <Tooltip title="clear">
                                  <HighlightOffIcon
                                    sx={{
                                      cursor: 'pointer',
                                      fill: 'red',
                                    }}
                                    onClick={() => {
                                      sigPad?.current?.clear();
                                      setSignature({});
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </Typography>
                            {/* <Button
                                onClick={() => {
                                  sigPad?.current?.clear();
                                  setSignature({});
                                }}>
                                Clear
                              </Button> */}
                          </div>
                          {_.isEmpty(signature) && (
                            <span
                              className={'Mui-error f-14'}
                              style={{
                                display: 'flex',
                                paddingTop: '10px',
                              }}>
                              Signature is required
                            </span>
                          )}
                        </Grid>
                      )}
                      {otpSendClick && (
                        <Grid item md={12} sm={12}>
                          <Typography
                            component={'div'}
                            className="d-flex-jae mt-1 ">
                            <Button
                              className="n-cancel-btn mr-1"
                              sx={{
                                backgroundColor: '#bcc7dc !important',
                                borderRadius: '5px !important',
                                fontFamily: 'Arial-Regular !important',
                                fontSize: '0.9em !important',
                                height: '35px !important',
                                width: '149px !important',
                              }}
                              onClick={() =>
                                navigate(
                                  `/secure-payment?hashKey=${searchParams.get(
                                    'hashKey',
                                  )}`,
                                )
                              }>
                              Cancel
                            </Button>
                            <Button
                              className="n-add-btn"
                              sx={{
                                borderRadius: '5px !important',
                                fontFamily: 'Arial-Regular !important',
                                fontSize: '0.9em !important',
                                height: '35px !important',
                                width: '149px !important',
                              }}
                              onClick={approve}>
                              Accept
                            </Button>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </form>
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContractViewer;
