import { yupResolver } from '@hookform/resolvers/yup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Tooltip, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import _ from 'lodash';
// import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { updateData } from 'services/CommonServices';
import { RequestType } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { PetitionerRequestlDocumentAddSchema } from 'utils/ValidatorSchema';

type PetitionerRequestTableDisplays = {
  data: any;
  moduleList: any;
  deleteRequestDocumentConfirm: any;
  petitionId: any;
  petitionerDocs: any;
  beneficiaryDocs: any;
  index: number;
  setListData: any;
  getDocumentList: any;
  dependentDocs: any;
};

export const PetitionerRequestTableDisplay: React.FC<
  PetitionerRequestTableDisplays
> = ({
  data,
  moduleList,
  deleteRequestDocumentConfirm,
  petitionId,
  petitionerDocs,
  beneficiaryDocs,
  getDocumentList,
  dependentDocs,
}) => {
  const [edit, SetEdit] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedModuleIds, SetSelectedModuleIds] = useState<any>([]);

  const bindModuleName = () => {
    const dataBind = data.moduleMapped.map((moduleId, index) => {
      return moduleList.find(
        (moduleListData) => moduleListData.value == moduleId,
      )?.label;
    });
    return dataBind.join(', ');
  };
  const onSubmitForm = (datas: any) => {
    const responseBy = datas['responsibility'] ? datas['responsibility'] : [];
    const selectedResponse =
      responseBy && Array.isArray(responseBy)
        ? responseBy.map((e) => Number(e))
        : [];
    let ids: Array<any> = [...selectedModuleIds.map((e) => Number(e))];
    let arr = [];
    selectedResponse.map((el) => {
      if (ids.includes(el)) {
        arr = ids.filter((id) => id !== el);
        ids = arr;
      } else {
        ids.push(el);
      }
    });
    datas['responsibility'] = ids;

    const bodyObj = getDocFormData(datas);
    updateData(
      data.id,
      bodyObj,
      `${String(COMMON_ENDPOINT.RequestForRequiredDocument)}/extra-docs`,
    )
      .then((resp: any) => {
        getDocumentList && getDocumentList();
        toast.success('Updated successfully', alertOptions);
        SetEdit(false);
      })
      .catch((error: any) => {
        SetEdit(false);
        toast.error(
          error ? error : 'Oops!!! Something went wrong',
          alertOptions,
        );
      });
  };
  const getDocFormData = (data) => {
    return {
      beneficiaryDocs: beneficiaryDocs,
      dependentDocs: dependentDocs,
      documentName: data.documentName,
      petitionId: petitionId,
      petitionerDocs: petitionerDocs,
      responsibility: data.responsibility,
    };
  };
  const {
    control: control,
    handleSubmit: handleSubmit,
    // reset: resetForm,
    setValue,
    // setError,
    // watch,
    formState: { errors },
  } = useForm<RequestType>({
    criteriaMode: 'all',
    defaultValues: {
      documentName: '',
      responsibility: null,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(PetitionerRequestlDocumentAddSchema),
  });

  useEffect(() => {
    if (data) {
      setValue('documentName', data?.document_type);
      setValue('responsibility', data?.moduleMapped ? data?.moduleMapped : []);
      SetSelectedModuleIds(
        moduleList
          .filter((obj) => data?.moduleMapped.indexOf(obj.value) !== -1)
          .map((selected) => selected.value),
      );
    }
  }, [data, moduleList, setValue]);
  return (
    <tr key={data?.id}>
      <td className="W-4">
        <form id={`petitioner-document-edit-form-${String(data?.id)}`}>
          {edit ? (
            <Controller
              control={control}
              defaultValue={data?.document_type}
              name="documentName"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <CustomInput
                    placeHolder={''}
                    name={name}
                    value={value}
                    className={'custom-input'}
                    error={errors.documentName?.message || ''}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                );
              }}
            />
          ) : (
            data?.document_type
          )}
        </form>
      </td>
      <td className="W-4" style={{ border: 'none', position: 'absolute' }}>
        <form id={`petitioner-document-edit-form-${String(data?.id)}`}>
          {edit ? (
            <Controller
              control={control}
              name="responsibility"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <CustomSelect
                    closeMenuOnSelect={false}
                    labelKey={'label'}
                    valueKey={'value'}
                    options={moduleList}
                    isMulti={true}
                    name={name}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    placeHolder={'Select'}
                    className={'basic-multi-select'}
                    error={errors.responsibility?.message}
                    // disabled={allowAccess}
                  />
                  // <>
                  //   <Multiselect
                  //     {...field}
                  //     // inputRef={ref}
                  //     displayValue="module_name"
                  //     onSelect={(selected, item) => {
                  //       if (selected.length) {
                  //         setError('responsibility', {
                  //           message: '',
                  //           type: 'custom',
                  //         });
                  //       } else {
                  //         setError('responsibility', {
                  //           message: 'Responsibility is required',
                  //           type: 'custom',
                  //         });
                  //       }
                  //       setValue(
                  //         'responsibility',
                  //         selected.map((module) => module.module_id).join(','),
                  //       );
                  //     }}
                  //     onRemove={(selected, item) => {
                  //       if (selected.length) {
                  //         setError('responsibility', {
                  //           message: '',
                  //           type: 'custom',
                  //         });
                  //       } else {
                  //         setError('responsibility', {
                  //           message: 'Responsibility is required',
                  //           type: 'custom',
                  //         });
                  //       }
                  //       setValue(
                  //         'responsibility',
                  //         selected.map((module) => module.module_id).join(','),
                  //       );
                  //     }}
                  //     selectedValues={selectedModuleIds}
                  //     options={!_.isEmpty(moduleList) ? moduleList : []}
                  //   />
                  //   {errors.responsibility?.message && (
                  //     <FormHelperText style={{ color: '#FF0000' }}>
                  //       {errors.responsibility?.message}
                  //     </FormHelperText>
                  //   )}
                  // </>
                );
              }}
            />
          ) : (
            data?.moduleMapped && bindModuleName()
          )}
        </form>
      </td>
      <td className="W-2">
        <form id={`petitioner-document-edit-form-${String(data?.id)}`}>
          {!edit && (
            <Tooltip title={'Edit'} placement="top">
              <EditIcon
                sx={{
                  cursor: 'pointer',
                  fill: 'gray',
                  height: '25px',
                  width: '25px',
                }}
                onClick={() => {
                  SetEdit(true);
                }}
              />
            </Tooltip>
          )}
          {edit && (
            <Tooltip title={'Save'} placement="top">
              <Typography
                component={'span'}
                onClick={handleSubmit(onSubmitForm)}>
                <SaveIcon
                  sx={{
                    cursor: 'pointer',
                    fill: 'gray',
                    height: '25px',
                    width: '25px',
                  }}
                />
              </Typography>
            </Tooltip>
          )}
          <Tooltip title={'Delete'} placement="top">
            <DeleteForeverIcon
              sx={{
                cursor: 'pointer',
                fill: 'red',
                height: '25px',
                width: '25px',
              }}
              onClick={() => deleteRequestDocumentConfirm(data.id)}
            />
          </Tooltip>
        </form>
      </td>
    </tr>
  );
};

export default PetitionerRequestTableDisplay;
