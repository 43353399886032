import { yupResolver } from '@hookform/resolvers/yup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SearchIcon from '@mui/icons-material/Search';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Avator from 'assets/images/d-avator.svg';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomInput from 'components/CustomInput/customInput';
// import CustomSelect from 'components/CustomSelect/customSelect';
// import InfinityScroll from 'components/InfinityScroll';
// import Pagination from 'components/Pagination';
import { AdminPrivateRoutes } from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { fileApiUrl } from 'utils/Config';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { Petionerscheme } from 'utils/ValidatorSchema';

import useStyles from '../../PrivateStyle';

// const Status = [
//   {
//     label: 'Active',
//     value: true,
//   },
//   {
//     label: 'Inactive',
//     value: false,
//   },
// ];
export const ManageLawFirm: React.FC<any> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState('1');
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [tableLoader1, setTableLoader1] = useState(false);
  const [open, setOpen] = React.useState({
    fieldName: '',
    id: '',
    show: false,
  });
  const {
    control: control,
    handleSubmit: handleSubmit,
    // reset: resetForm,
    setValue,
    // watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      CustomField: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(Petionerscheme),
  });
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [params, setParams] = useState<any>({
    firmName: '',
    skip: 0,
    status: true,
    take: 50,
  });
  const [cfParams, setCFParams] = useState<any>({
    fieldName: '',
    id: '',
    roleId: 3,
    skip: 0,
    status: true,
    take: 50,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    deletedId: '',
    show: false,
  });
  const [loader, setLoader] = useState(true);

  const [lawfirmData, setLawfirmData] = useState<any>({});
  const [customFields, setCustomFields] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasMoreResults1, setHasMoreResults1] = useState(false);
  const [getMoreLoader1, setGetMoreLoader1] = useState(false);
  const statusOpen = Boolean(anchorEl);
  const handleStatusOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const gotoUpdate = (firmId: string) => {
    navigate(
      userDetail.role_id === 1 &&
        `${AdminPrivateRoutes.MANAGELAWFIRM.MANAGELAWFIRMLIST}/${firmId}`,
    );
  };

  const handleStatusClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === '2') {
      setLoader(true);
      getCustomFieds();
    }
  };
  const debouncedParams = useDebounce(params, 500);
  const debouncedcfParams = useDebounce(cfParams, 500);

  const createCustomField = () => {
    addNewData(
      { fieldName: open.fieldName, roleId: 3 },
      COMMON_ENDPOINT.CustomFields,
    )
      .then((resp: any) => {
        toast.success('Fields added successfully', alertOptions);
        getCustomFieds();
        setOpen({
          fieldName: '',
          id: '',
          show: false,
        });
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const upateCustomField = () => {
    updateData(
      open.id !== '' && open.id,
      { fieldName: open.fieldName },
      COMMON_ENDPOINT.CustomFields,
    )
      .then((resp: any) => {
        toast.success('Fields added successfully', alertOptions);
        getCustomFieds();
        setOpen({ fieldName: '', id: '', show: false });
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const deleteConfirmation = (action: string) => {
    if (action !== 'yes') {
      setDeleteDialog((pre) => ({
        ...pre,
        show: false,
      }));
      return;
    }
    deleteData(deleteDialog.deletedId, COMMON_ENDPOINT.CustomFields)
      .then((resp: any) => {
        getCustomFieds();
        toast.success('Custom field deleted.', alertOptions);
        setDeleteDialog((pre) => ({
          ...pre,
          show: false,
        }));
      })
      .catch((e) => {
        toast.error('Unable to delete.', alertOptions);
      });
  };

  const getCustomFieds = useCallback(() => {
    getAllListData(
      `${COMMON_ENDPOINT.CustomFields}?skip=${String(
        debouncedcfParams.skip,
      )}&take=${String(debouncedcfParams.take)}&fieldName=${String(
        debouncedcfParams.fieldName,
      )}&id=${String(debouncedcfParams.id)}&roleId=${String(
        debouncedcfParams.roleId,
      )}&status=${String(debouncedcfParams.status)}`,
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setCustomFields((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          const mergeData = !_.isEmpty(customFields)
            ? [...customFields.data, ...resp.data]
            : resp.data;
          setCustomFields({
            count: resp.total,
            data: mergeData,
          });
        }
        setTableLoader1(false);
        setLoader(false);
        setGetMoreLoader(false);

        const allList = await instantState(setCustomFields);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedcfParams]);

  const getMoreList = () => {
    if (!tableLoader1) {
      setGetMoreLoader(true);
      setCFParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };

  useEffect(() => {
    tabValue === '2' && getCustomFieds();
  }, [debouncedcfParams, getCustomFieds, tabValue]);

  useEffect(() => {
    getAllListData(
      `${COMMON_ENDPOINT.ManageLawFirm}?skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}${
        debouncedParams.firmName !== ''
          ? '&firmName='.concat(debouncedParams.firmName)
          : ''
      }&status=${String(debouncedParams.status)}`,
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader1);
        if (moreLoader) {
          setLawfirmData((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          const mergeData = !_.isEmpty(lawfirmData)
            ? [...lawfirmData.data, ...resp.data]
            : resp.data;
          setLawfirmData({
            count: resp.total,
            data: mergeData,
          });
        }
        setTableLoader(false);
        setLoader(false);
        setGetMoreLoader1(false);
        const allList = await instantState(setLawfirmData);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults1(true);
        } else {
          setHasMoreResults1(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const getMoreList1 = () => {
    if (!tableLoader) {
      setGetMoreLoader1(true);
      setParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };
  // const rowProps = (lawfirm, index) => {
  //   return (
  //     <NavLink
  //       to={
  //         userDetail.role_id === 1 &&
  //         `${AdminPrivateRoutes.MANAGELAWFIRM.MANAGELAWFIRMLIST}/${String(
  //           lawfirm.id,
  //         )}`
  //       }
  //       key={index}>
  //       <Typography className="configureItem">
  //         <img
  //           src={
  //             !lawfirm?.file_name
  //               ? Avator
  //               : `${fileApiUrl}${String(lawfirm?.file_name?.id)}`
  //           }
  //           alt="upic"
  //           className={classes.userImg}
  //         />
  //         <Typography className="d-flex-a d-flex-sb w-100 pl-2">
  //           <Typography component={'div'} className="d-flex-3">
  //             <Typography className="black-white-text ctitle">
  //               {lawfirm.firm_name}
  //             </Typography>
  //             <Grid
  //               container
  //               component={'div'}
  //               sx={{ paddingTop: '.5rem !important' }}>
  //               <Grid item md={12} sm={12}>
  //                 <Typography className="rl-text">
  //                   Email: {lawfirm.firm_email}
  //                 </Typography>
  //               </Grid>
  //             </Grid>
  //           </Typography>
  //           <Typography className="blue-text d-flex-1 text-right">
  //             {lawfirm.firm_status === 'active' ? 'Active' : 'Inactive'}
  //           </Typography>
  //         </Typography>
  //       </Typography>
  //     </NavLink>
  //   );
  // };

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12} className="documentTab pr-2rem">
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Typography component={'div'} className="d-flex-a w-100 d-flex-sb">
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example">
                <Tab label="Law Firms" value="1" />
                <Tab label="Custom Fields" value="2" />
              </TabList>
              {tabValue === '1' && (
                <Typography
                  component={'div'}
                  className="d-flex-jae full-screen-searc mt-1">
                  <Typography
                    component={'div'}
                    className="d-flex-a small-search"
                    sx={{
                      backgroundColor: '#ffffff',
                      borderRadius: '4px',
                    }}>
                    <Typography
                      className="d-flex-a searc-box1"
                      component={'div'}>
                      <SearchIcon
                        sx={{
                          fill: '#3d3f40 !important',
                          left: '5px',
                          position: 'absolute',
                          zIndex: '1',
                        }}
                      />
                      <CustomInput
                        placeHolder={'Search'}
                        className={'custom-search-input'}
                        value={params.firmName}
                        inputProps={{
                          endAdornment:
                            params.firmName !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    setTableLoader(true);
                                    setLawfirmData({
                                      count: 0,
                                      data: [],
                                    });
                                    setParams((prev) => ({
                                      ...prev,
                                      firmName: '',
                                      skip: 0,
                                      take: 50,
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          setTableLoader(true);

                          setLawfirmData({
                            count: 0,
                            data: [],
                          });
                          setParams((prev) => ({
                            ...prev,
                            firmName: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                      />
                    </Typography>
                  </Typography>
                  {/* <Typography sx={{ width: '205px' }} className="searc-box1">
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      value={params.status}
                      options={Status}
                      selectedValue={''}
                      onChange={(e) => {
                        setLawfirmData({
                          count: 0,
                          data: [],
                        });
                        setParams((prev) => ({
                          ...prev,
                          skip: 0,
                          status: e.target.value,
                          take: 50,
                        }));
                      }}
                      placeHolder={'Status'}
                      className={'custom-search-input'}
                    />
                  </Typography> */}
                </Typography>
              )}{' '}
              {tabValue === '2' && (
                <Typography className="d-flex-jae mt-1">
                  <CustomInput
                    extraStyle={{ width: '20vw' }}
                    className={'outer-custom-input'}
                    type="text"
                    placeHolder={'Custom Field Name'}
                    onChange={(e) => {
                      const fieldName = e.target.value.replace(
                        /[^A-Z a-z]/g,
                        '',
                      );
                      e.target.value = fieldName;
                      setOpen((pre) => ({
                        ...pre,
                        fieldName: e.target.value,
                      }));
                    }}
                  />
                  <Button
                    disabled={open?.fieldName ? false : true}
                    onClick={() => createCustomField()}
                    className="n-add-btn ml-1"
                    sx={{
                      borderRadius: '5px !important',
                      height: '39px !important',
                    }}>
                    Add
                  </Button>
                  <Typography component={'div'} className="d-flex-a ml-1">
                    <Button
                      className="n-add-btn"
                      sx={{
                        borderRadius: '5px !important',
                        height: '39px !important',
                      }}
                      id="basic-button"
                      aria-controls={statusOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={statusOpen ? 'true' : undefined}
                      onClick={handleStatusOpen}>
                      {' '}
                      {cfParams.status ? 'Active ' : 'InActive'}{' '}
                      <ArrowDropDownIcon />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={statusOpen}
                      onClose={handleStatusClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}>
                      <MenuItem
                        sx={{ width: '118px !important' }}
                        onClick={() => {
                          setCFParams((prev) => ({
                            ...prev,
                            skip: 0,
                            status: true,
                            take: 50,
                          }));
                          setTableLoader1(true);
                          setCustomFields([]);
                          handleStatusClose();
                        }}>
                        Active
                      </MenuItem>
                      <MenuItem
                        sx={{ width: '118px !important' }}
                        onClick={() => {
                          setCFParams((prev) => ({
                            ...prev,
                            skip: 0,
                            status: false,
                            take: 50,
                          }));
                          setTableLoader1(true);
                          setCustomFields([]);
                          handleStatusClose();
                        }}>
                        InActive
                      </MenuItem>
                    </Menu>
                  </Typography>
                  <Modal
                    open={open.show}
                    onClose={() =>
                      setOpen({ fieldName: '', id: '', show: false })
                    }
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box className="custom-file-modal">
                      <Typography className="modal-title font-bold">
                        Custom Field
                      </Typography>
                      <Typography className="m-border">
                        <form
                          id="tableupdate"
                          onSubmit={handleSubmit(upateCustomField)}>
                          <Typography className="custom-field">
                            <Typography className="custom-label">
                              Custom Field <span className="required">*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name="CustomField"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    name={name}
                                    className={'custom-input'}
                                    placeHolder={'Custom Field'}
                                    onChange={(e) => {
                                      const vals = e.target.value.replace(
                                        /[^a-zA-Z s]/,
                                        '',
                                      );
                                      onChange(vals);
                                      setOpen((pre) => ({
                                        ...pre,
                                        fieldName: e.target.value,
                                      }));
                                    }}
                                    value={value}
                                    error={errors.CustomField?.message}
                                  />
                                );
                              }}
                            />
                          </Typography>

                          <Typography
                            className="d-flex-jae modal-footer"
                            sx={{ padding: '1rem 0 !important' }}>
                            <Button
                              className="mcancel-btn f-18 font-bold mr-1"
                              onClick={() =>
                                setOpen({
                                  fieldName: '',
                                  id: '',
                                  show: false,
                                })
                              }>
                              Cancel
                            </Button>
                            <Button
                              className="madd-btn f-18 font-bold"
                              type={'submit'}
                              form={'tableupdate'}>
                              Save
                            </Button>
                          </Typography>
                        </form>
                      </Typography>
                    </Box>
                  </Modal>
                </Typography>
              )}
            </Typography>
          </Box>
          <TabPanel value="1">
            {loader ? (
              <div className="liberty-loader" />
            ) : (
              <Typography
                sx={{
                  borderRadius: '10px 10px 0px 0px',
                }}
                className={classes.scrollbox}
                id="scrollParent">
                <InfiniteScroll
                  dataLength={
                    lawfirmData && lawfirmData.data && lawfirmData.data.length
                      ? lawfirmData.data.length
                      : 0
                  }
                  style={{
                    overflow: 'unset',
                  }}
                  next={getMoreList1 && getMoreList1}
                  hasMore={hasMoreResults1}
                  scrollableTarget={'scrollParent'}
                  loader={
                    getMoreLoader1 && (
                      <div
                        style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          width: '100%',
                        }}>
                        <CircularProgress
                          size={30}
                          sx={{
                            color: '#002c69',
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        />
                      </div>
                    )
                  }
                  endMessage>
                  <table className="lead-table table-hover">
                    <thead>
                      <tr>
                        <td className="W-2">Organization Name</td>
                        <td className="W-1">Country </td>
                        <td className="W-1">State</td>
                        <td className="W-1">City</td>
                        <td className="W-1">Phone</td>
                        <td className="W-1">Email</td>
                        <td className="W-1">Valid Till </td>
                        <td className="W-1">Status </td>
                      </tr>
                    </thead>
                    <tbody>
                      {tableLoader ? (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <div
                              style={{
                                backgroundColor: '#ffffff',
                                textAlign: 'center',
                                width: '100%',
                              }}>
                              <CircularProgress
                                size={30}
                                sx={{
                                  color: '#002c69',
                                  marginBottom: 10,
                                  marginTop: 10,
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : !_.isEmpty(lawfirmData?.data) &&
                        lawfirmData.data.length > 0 ? (
                        lawfirmData.data.map((lawfirm, index) => (
                          <tr
                            key={index}
                            onClick={() => gotoUpdate(String(lawfirm.id))}
                            style={{ cursor: 'pointer' }}>
                            <td
                              style={{
                                alignItems: 'center',
                                display: 'flex',
                              }}
                              // className="section">
                            >
                              {' '}
                              <img
                                src={
                                  !lawfirm?.file_name
                                    ? Avator
                                    : `${fileApiUrl}${String(
                                        lawfirm?.file_name?.id,
                                      )}`
                                }
                                alt="upic"
                                className={classes.userImg}
                              />
                              <span style={{ paddingLeft: '5px' }}>
                                <div
                                  className="hideshow"
                                  style={{
                                    width: '150px',
                                    wordBreak: 'break-all',
                                  }}>
                                  {lawfirm.firm_name ? lawfirm.firm_name : ''}
                                </div>
                              </span>
                            </td>
                            <td>
                              {lawfirm.firm_country
                                ? lawfirm.firm_country
                                : '-'}
                            </td>
                            <td>
                              {lawfirm.firm_state ? lawfirm.firm_state : '-'}
                            </td>
                            <td>
                              {lawfirm.firm_city ? lawfirm.firm_city : '-'}
                            </td>
                            <td>
                              {lawfirm.firm_phone ? lawfirm.firm_phone : '-'}
                            </td>
                            <td>
                              <div
                                className="hideshow"
                                style={{
                                  width: '150px',
                                  wordBreak: 'break-all',
                                }}>
                                {lawfirm.firm_email ? lawfirm.firm_email : ''}
                              </div>
                            </td>
                            <td>
                              {lawfirm.firm_valid_till
                                ? moment(lawfirm.firm_valid_till).format(
                                    'MM/DD/yyyy',
                                  )
                                : '-'}
                              {/* {lawfirm.firm_valid_till} */}
                            </td>
                            <td>
                              {lawfirm.firm_status === 'active'
                                ? 'Active'
                                : 'Inactive'}
                            </td>
                            {/* // </NavLink> */}
                          </tr>
                        ))
                      ) : (
                        !tableLoader && (
                          <tr className={'text-center'}>
                            <td colSpan={8}>No Record(s) Found.</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </Typography>
            )}
          </TabPanel>
          <TabPanel value="2">
            {loader ? (
              <div className="liberty-loader" />
            ) : (
              <TableContainer
                sx={{
                  borderRadius: '8px 8px 0 0',
                }}>
                <Typography
                  component={'div'}
                  className={classes.scrollbox}
                  id="scrollParent"
                  style={{ minHeight: '106px' }}>
                  <InfiniteScroll
                    dataLength={
                      customFields &&
                      customFields.data &&
                      customFields.data.length
                        ? customFields.data.length
                        : 0
                    }
                    style={{
                      overflow: 'unset',
                    }}
                    next={getMoreList && getMoreList}
                    hasMore={hasMoreResults}
                    scrollableTarget={'scrollParent'}
                    loader={
                      getMoreLoader && (
                        <div
                          style={{
                            backgroundColor: '#ffffff',
                            textAlign: 'center',
                            width: '100%',
                          }}>
                          <CircularProgress
                            size={30}
                            sx={{
                              color: '#002c69',
                              marginBottom: 10,
                              marginTop: 10,
                            }}
                          />
                        </div>
                      )
                    }
                    endMessage>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      className="lead-table table-hover"
                      style={{ minHeight: '106px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell className="W-1">S.No</TableCell>
                          <TableCell className="W-5">Field Name</TableCell>
                          <TableCell className="W-1">Status</TableCell>
                          <TableCell className="W-1">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableLoader1 ? (
                          <tr>
                            <td colSpan={8}>
                              <div
                                style={{
                                  backgroundColor: '#ffffff',
                                  textAlign: 'center',
                                  width: '100%',
                                }}>
                                <CircularProgress
                                  size={30}
                                  sx={{
                                    color: '#002c69',
                                    marginBottom: 10,
                                    marginTop: 10,
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>
                            {customFields &&
                            customFields.data &&
                            customFields.data.length > 0 ? (
                              customFields.data.map((field, index) => (
                                <TableRow
                                  key={index}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setOpen({
                                      fieldName: field.field_name,
                                      id: field.id,
                                      show: true,
                                    });
                                    setValue('CustomField', field.field_name);
                                  }}>
                                  <TableCell>{Number(index) + 1}</TableCell>
                                  <TableCell>{field.field_name}</TableCell>
                                  <TableCell>
                                    {field.status ? 'Active' : 'In Active'}
                                  </TableCell>
                                  <TableCell>
                                    <DeleteForeverIcon
                                      sx={{
                                        cursor: 'pointer',
                                        fill: 'red',
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteDialog((pre) => ({
                                          ...pre,
                                          deletedId: field.id,
                                          show: true,
                                        }));
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow className={'text-center'}>
                                <TableCell colSpan={4}>
                                  No Record(s) Found.
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </InfiniteScroll>
                </Typography>
              </TableContainer>
            )}
          </TabPanel>
        </TabContext>
        <ConfirmDialog
          title="DELETE"
          floatingBtnShow={true}
          open={deleteDialog.show}
          close={() => {
            setDeleteDialog((pre) => ({
              ...pre,
              show: false,
            }));
          }}
          onConfirm={deleteConfirmation}
          autoCloseDisable={true}>
          {'Are you sure do you want to delete?'}
        </ConfirmDialog>
      </Grid>
    </Grid>
  );
};

export default ManageLawFirm;
