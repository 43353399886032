import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Breakpoint,
  Button,
  Dialog,
  DialogTitle,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import React from 'react';

type ModalProps = {
  open?: any;
  close?: any;
  title?: string;
  subTitle?: string;
  isFilters?: any;
  children?: any;
  filterOnChange?: (src: any) => void;
  filterVisaOnChange?: (src: any) => void;
  visaTypes?: any;
  fullWidth?: boolean;
  maxWidth?: Breakpoint;
  scroll?: 'body' | 'paper';
  autoCloseDisable?: boolean;
  value?: any;
  inputProps?: any;
  navigationLinkShow?: boolean;
  navLinkTitle?: string;
  onClickNavLink?: () => void;
  minHeight?: string;
  defaultVisaSet?: any;
  onFileDownloadIcon?: () => void;
  linkRedirect?: boolean;
  petitionerName?: string;
  beneficiaryName?: string;
  visaType?: string;
};

const CustomModalView: React.FC<ModalProps> = ({
  open,
  close,
  title,
  subTitle,
  isFilters,
  children,
  filterOnChange,
  filterVisaOnChange,
  visaTypes,
  fullWidth = false,
  maxWidth,
  scroll = 'body',
  autoCloseDisable = false,
  navigationLinkShow = false,
  navLinkTitle,
  onClickNavLink,
  minHeight,
  value,
  inputProps,
  defaultVisaSet,
  linkRedirect,
  petitionerName,
  beneficiaryName,
  visaType,
  onFileDownloadIcon,
}) => {
  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            maxHeight: '90vh',
            ...(minHeight ? { minHeight: minHeight } : {}),
          },
        }}
        className="custom-page"
        open={open}
        {...(autoCloseDisable ? {} : { onClose: () => close() })}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={maxWidth ? maxWidth : 'md'}
        fullWidth={fullWidth}
        scroll={scroll}>
        <DialogTitle sx={{ m: 0, p: 0 }}>
          <Typography
            component={'div'}
            className={'d-flex-a d-flex-sb mb-1 mt-1'}
            sx={{ flexWrap: 'unset !important', padding: '0 1.5rem' }}>
            <Typography className={'d-flex-a d-flex-sb mb-1 mt-1'}>
              <Typography
                component={'div'}
                sx={{
                  color: '#333333 !important',
                  fontFamily: 'Arial-Bold !important',
                  fontSize: '1.25em !important',
                }}>
                {title}{' '}
                {linkRedirect && (
                  <span
                    className="f-12 font-bold pointer"
                    style={{
                      color: 'blue',
                      textDecoration: 'underline',
                    }}
                    onClick={() => onClickNavLink && onClickNavLink()}>
                    GoTo Page
                  </span>
                )}
              </Typography>
              {subTitle !== '' && (
                <Typography component={'div'} className={'ml-1'}>
                  {subTitle}
                </Typography>
              )}
            </Typography>
            {linkRedirect && (
              <>
                <Typography component={'div'}>
                  <Typography
                    component={'div'}
                    sx={{
                      color: '#333333 !important',
                      fontFamily: 'Arial-Bold !important',
                      fontSize: '1.25em !important',
                      paddingBottom: '5px',
                    }}>
                    Petitioner
                  </Typography>
                  <Typography>{petitionerName}</Typography>
                </Typography>
                <Typography component={'div'}>
                  <Typography
                    component={'div'}
                    sx={{
                      color: '#333333 !important',
                      fontFamily: 'Arial-Bold !important',
                      fontSize: '1.25em !important',
                      paddingBottom: '5px',
                    }}>
                    Beneficiary
                  </Typography>
                  <Typography> {beneficiaryName}</Typography>
                </Typography>

                <Typography component={'div'}>
                  <Typography
                    component={'div'}
                    sx={{
                      color: '#333333 !important',
                      fontFamily: 'Arial-Bold !important',
                      fontSize: '1.25em !important',
                      paddingBottom: '5px',
                    }}>
                    VisaType
                  </Typography>
                  <Typography className={'ml-1'}> {visaType}</Typography>
                </Typography>
              </>
            )}
            <Typography component={'div'} className="d-flex-jae">
              {navigationLinkShow ? (
                <Typography
                  component={'div'}
                  className="d-flex-a mr-1 add-template-modal-text"
                  onClick={() => onClickNavLink && onClickNavLink()}>
                  <span className="plus d-flex-ja">+</span>
                  {navLinkTitle}
                </Typography>
              ) : (
                ''
              )}
              {isFilters && (
                <>
                  <Typography
                    component={'div'}
                    className="d-flex-ja mr-1"
                    sx={{
                      color: '#84898c !important',
                      paddingLeft: '10px',
                    }}>
                    <CustomInput
                      placeHolder={'Search'}
                      className={'custom-search-model'}
                      onChange={(e) => filterOnChange(e.target.value)}
                    />
                  </Typography>
                  {visaTypes && visaTypes.length > 0 && (
                    <Typography
                      component={'div'}
                      sx={{ marginRight: '.5rem', width: '255px' }}>
                      <CustomSelect
                        searchable={true}
                        labelKey={'visa_code'}
                        disabled={title === 'Resource Station' ? true : false}
                        valueKey={'visa_id'}
                        value={defaultVisaSet}
                        options={visaTypes}
                        onChange={(e) => filterVisaOnChange(e.target.value)}
                        onClose={close}
                        placeHolder={'Select visa type'}
                        className={'custom-search-input bo-rles'}
                      />
                    </Typography>
                  )}
                </>
              )}
              {onFileDownloadIcon && (
                <Typography
                  component={'div'}
                  className="d-flex-jae"
                  sx={{
                    backgroundColor: 'white',
                    bottom: '-20px',
                    position: 'sticky',
                    zIndex: 1,
                  }}>
                  <Button
                    className="n-add-btn mt-2 mb-2"
                    onClick={() => onFileDownloadIcon()}>
                    <span className="d-flex-jae">
                      <DownloadIcon
                        style={{
                          fill: '#FFFFFF',
                        }}
                      />
                      Download
                    </span>
                  </Button>
                </Typography>
              )}

              <Typography component={'div'}>
                <CancelIcon
                  sx={{
                    cursor: 'pointer',
                    fill: 'gray',
                    height: '44px',
                    width: '44px',
                  }}
                  onClick={() => close()}
                />
              </Typography>
            </Typography>
          </Typography>
        </DialogTitle>
        {children}
      </Dialog>
    </div>
  );
};

export default CustomModalView;
