// import 'react-tiff/dist/index.css';
import '../CustomDocumentViewer/tiffviewer.css';

import React, { useCallback, useEffect } from 'react';
import { TIFFViewer } from 'react-tiff';

type Props = {
  fileBlob: string;
  fileType?: string;
  onError?: (src: any) => void;
  onDocumentComplete?: (src: any) => void;
  width?: any;
  applyBorder?: boolean;
};
export const DocumentViewer: React.FC<Props> = (props: Props) => {
  const {
    fileBlob,
    fileType,
    onError,
    onDocumentComplete,
    width,
    applyBorder,
  } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loader, setLoader] = React.useState(false);
  const pdfRef = React.useRef(null);
  const checkingForGoogleViewer = () => {
    // checking google viwer only
    const iframe: any = document.getElementById('iframe');
    if (iframe) {
      reloadIFrame();
      const timerId = setInterval(() => {
        reloadIFrame();
      }, 5000);
      document.querySelector('iframe').onload = () => {
        clearInterval(timerId);
      };
    }
  };
  const reloadIFrame = () => {
    const iframe: any = document.getElementById('iframe');

    if (iframe && iframe.contentDocument && iframe.contentDocument.URL) {
      setLoader(false);
    }
    if (
      iframe &&
      iframe.contentDocument &&
      iframe.contentDocument.URL == 'about:blank'
    ) {
      // eslint-disable-next-line no-self-assign
      iframe.src = iframe.src;
    }
  };
  const onCheckError = (eve: any) => {
    setLoader(false);
    onError && onError(eve);
  };
  const onChecComplete = useCallback(
    (ev: any) => {
      setLoader(false);
      onDocumentComplete && onDocumentComplete(ev);
    },
    [onDocumentComplete],
  );
  useEffect(() => {
    setTimeout(() => {
      checkingForGoogleViewer();
    }, 2000);
  });
  useEffect(() => {
    pdfRef &&
      pdfRef.current &&
      pdfRef.current.addEventListener('pdfDocumentProxy', onChecComplete);

    pdfRef &&
      pdfRef.current &&
      pdfRef.current.addEventListener('pdfDocumentError', onCheckError);

    if (applyBorder) {
      const childViwerId = document.getElementById('viewer');
      if (childViwerId && childViwerId.parentElement) {
        childViwerId.parentElement.style.border = '1px solid black';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileType, applyBorder]);

  return (
    <>
      {loader && <div className="liberty-loader-div" />}
      {fileType === 'pdf' ||
      (fileType && String(fileType).split('/')[1] === 'pdf') ? (
        <ng-pdf-view-edit
          ref={pdfRef}
          pdfurl={fileBlob}
          showall="true"
          inputfieldenable="false"
          page="1"
          pdfviewerwidth={width}
        />
      ) : fileType &&
        String(fileType).split('/')[0] === 'image' &&
        ['tiff', 'x-tiff'].includes(fileType.split('/')[1]) ? (
        <TIFFViewer tiff={fileBlob} lang="en" />
      ) : // <iframe
      //   style={{ height: '100vh' }}
      //   id={'iframe'}
      //   width={'100%'}
      //   height={'100%'}
      //   src={`https://docs.google.com/gview?url=${fileBlob}&embedded=true`}
      //   frameBorder="0"
      // />
      fileType && String(fileType).split('/')[0] === 'image' ? (
        <div
          style={{
            padding: '0px 25px',
          }}>
          <div
            className="img-viewer"
            // style={{ background: 'url(' + fileBlob + ')' }}
          />
          <img
            src={fileBlob}
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto',
            }}
          />
        </div>
      ) : (
        <iframe
          style={{ height: '100vh' }}
          id={'iframe'}
          width={'100%'}
          height={'100%'}
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            fileBlob,
          )}`}
          title="file"
        />
      )}
    </>
  );
};
