import SwapVertIcon from '@mui/icons-material/SwapVert';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

export const Drag = ({ id, index, innerStyle, ...props }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...props}>
            {props?.dragableHide ? (
              ''
            ) : (
              <div
                className="drag-handle"
                {...provided.dragHandleProps}
                style={innerStyle}>
                <SwapVertIcon
                  // color="rgb(59, 144, 237)"
                  sx={{ fill: '#002c69' }}
                />
              </div>
            )}

            {props.children}
          </div>
        );
      }}
    </Draggable>
  );
};
