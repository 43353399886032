import { instantState } from 'hooks';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { addNewData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions, phoneNumberFormat } from 'utils/helper';

type Props = {
  pdfDocument: string;
  petitionId: string;
  beneficiaryId: string;
  leadId: string;
  templateId: string;
  dependentUserId?: string;
  onlyShow?: string;
  setTotalPageArr?: (src: number[]) => void;
  pageNo?: number;
  setCurrentPageNo?: (src: number) => void;
  zoomProps?: { zoom: number; type: string };
  setTreeList?: (src: any) => void;
  treeList?: Array<any>;
  selectedQus?: any;
  updatedFieldValue?: Array<any>;
  setupdatedFieldValue?: (src: any) => void;
  setZoomProps?: (src: any) => void;
  latestTemplateId?: string;
};
export const BuildCasePDFEdit: React.FC<Props> = (props: Props) => {
  const {
    petitionId,
    beneficiaryId,
    // leadId,
    templateId,
    // onlyShow,
    dependentUserId,
    setTotalPageArr,
    setCurrentPageNo,
    pageNo = 1,
    zoomProps,
    setTreeList,
    selectedQus,
    updatedFieldValue,
    setupdatedFieldValue,
    setZoomProps,
    // treeList,
    latestTemplateId,
  } = props;
  const pdfRef = React.useRef(null);
  const [pdfLoader, setPdfLoader] = React.useState(true);
  const [pdfDoc, setPdfDoc] = React.useState('');
  const [setpdffieldsvalue, setPdfFieldsValue] = React.useState([]);
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);

  const getQuesFields = (fields: string[]) => {
    setPdfLoader(true);
    setTreeList([]);
    const bodyData = {
      beneficiaryId: beneficiaryId,
      formType: dependentUserId ? 'dependent' : 'configureForms',
      list: fields,
      petitionId: Number(petitionId),
      templateId: Number(templateId),
      ...(latestTemplateId ? { latestDocumentId: latestTemplateId } : {}),
      ...(dependentUserId
        ? {
            dependentUserId: dependentUserId ? Number(dependentUserId) : '',
          }
        : {}),
    };

    addNewData(
      bodyData,
      `${String(COMMON_ENDPOINT.pdfFieldsValue)}?isAffilate=${
        petitions?.petitionData?.isAffilateCase
          ? String(petitions?.petitionData?.isAffilateCase)
          : ''
      }&affilateRoleId=${
        petitions?.petitionData?.isAffilateCase
          ? String(petitions?.petitionData?.affilateRoleId)
          : ''
      }&firmId=${
        petitions?.petitionData?.attorney_assigned
          ? String(petitions?.petitionData?.attorney_assigned)
          : ''
      }`,
    )
      .then((suc) => {
        if (suc && Array.isArray(suc)) {
          const pdfMappedQus = suc.reduce((acc, currentVal) => {
            if (currentVal.pdf_field_name) {
              acc.push({
                exportValue: '',
                fieldName: currentVal.pdf_field_name,
                value: [14].includes(currentVal.response_type)
                  ? phoneNumberFormat(
                      currentVal.previousAnswer
                        ? currentVal.previousAnswer
                        : '',
                    )
                  : currentVal.previousAnswer,
              });
            } else if (
              currentVal.multiple_pdf_field &&
              Array.isArray(currentVal.multiple_pdf_field)
            ) {
              let arr = [];
              try {
                arr = JSON.parse(currentVal.previousAnswer);
              } catch (error) {
                arr = [];
              }
              currentVal.multiple_pdf_field.map((subQus) => {
                acc.push({
                  exportValue: subQus.exportValue,
                  fieldName: subQus.pdf_field_name,
                  value: arr.find(
                    (multi) => multi.pdf_field_name === subQus.pdf_field_name,
                  )?.status,
                });
              });
            }
            return acc;
          }, []);
          setupdatedFieldValue([...pdfMappedQus]);
          setPdfFieldsValue([...pdfMappedQus]);
        } else {
          setupdatedFieldValue([]);
          setPdfFieldsValue([]);
        }
        const WizardArr = [
          {
            name: '',
            order: 1,
            questionDetails: suc && Array.isArray(suc) && suc.length ? suc : [],
            question_id:
              suc && Array.isArray(suc) && suc.length
                ? suc.filter((ch: any) => ch.link_code).map((e: any) => e.id)
                : [],
            section_approve: false,
            section_id: 1,
            section_start_Count: 1,
            visa_form_id: 8,
          },
        ];
        setTreeList(WizardArr);
        setPdfLoader(false);
      })
      .catch(() => {
        setupdatedFieldValue([]);
        setPdfFieldsValue([]);
        setPdfLoader(false);
      });
  };
  const pdfClickField = (e: any) => {
    instantState(setTreeList).then((res) => {
      const qusInfo =
        res && Array.isArray(res) && Array.isArray(res[0].questionDetails)
          ? res[0].questionDetails.find(
              (ch) =>
                ch.pdf_field_name === e?.detail ||
                (ch.multiple_pdf_field &&
                  Array.isArray(ch.multiple_pdf_field) &&
                  ch.multiple_pdf_field.some(
                    (el) => el.pdf_field_name === e?.detail,
                  )),
            )
          : null;
      if (qusInfo) {
        const ele = document.getElementById(qusInfo.id);
        if (ele) {
          ele.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
          ele.focus();
          ele.style.border = '2px solid #4f72f1';
          setTimeout(() => {
            if ([14].includes(qusInfo?.response_type)) {
              ele.style.border = '1px solid #CACACA';
            } else {
              ele.style.border = 'unset';
            }
          }, 1000);
        }
      } else {
        toast.error('Question not found', alertOptions);
      }
    });
  };
  const PdfPageFields = (e: any) => {
    if (Array.isArray(e?.detail)) {
      const arrFields = e?.detail.map((e) => e?.name);
      getQuesFields(arrFields);
    }
  };
  const valueOnChange = (e: any) => {
    // console.warn({ info: e?.detail });
  };
  const pageOnChange = (e: any) => {
    window.scrollTo(0, 0);
    setCurrentPageNo(e?.detail);
  };
  const pageProxcyDoc = (e: any) => {
    setPdfLoader(false);
    const counts = [];
    for (let index = 1; index <= e?.detail?.numPages; index++) {
      counts.push(index);
    }
    setTotalPageArr && setTotalPageArr([...counts]);

    setTimeout(() => {
      if (document.getElementById('inline-edit-pdf-X56T78I09P')) {
        const pdfViewPortWidth = Math.floor(
          document.getElementById('inline-edit-pdf-X56T78I09P')?.clientWidth /
            204,
        );
        Array(Math.abs(pdfViewPortWidth - 4))
          .fill(pdfViewPortWidth - 4 >= 0 ? 0.25 : -0.25)
          .reduce(async (preAcc, e) => {
            await Promise.resolve(preAcc);
            setZoomProps((pre: { zoom: number }) => ({
              type: pdfViewPortWidth - 4 >= 0 ? 'in' : 'out',
              zoom: pre.zoom + +e,
            }));
            setTimeout(async () => {
              return preAcc.push(await new Promise((resole) => resole(e)));
            }, 100);
          }, []);
      }
    }, 1000);
  };
  // const getFilledPdf = () => {
  //   let url = `${String(COMMON_ENDPOINT.DocumentBuild)}/${String(
  //     petitionId,
  //   )}/${String(beneficiaryId)}/${String(templateId)}?leadId=${String(
  //     leadId,
  //   )}&pdfFillOnly=true`;
  //   if (onlyShow && onlyShow === 'dependent') {
  //     url =
  //       url + `&buildtype=dependent&dependentUserId=${String(dependentUserId)}`;
  //   }
  //   getAllListData(url, { responseType: 'blob' })
  //     .then((blobRes: any) => {
  //       const resUrl = URL.createObjectURL(blobRes);
  //       setPdfDoc(resUrl);
  //       setPdfLoader(false);
  //     })
  //     .catch(() => {
  //       setPdfLoader(false);
  //     });
  // };
  useEffect(() => {
    const apiUrl = `${
      process.env.REACT_APP_APIURL
    }/document-template/view/${String(templateId)}`;
    setPdfDoc(apiUrl);
    // getFilledPdf();
  }, [templateId]);

  useEffect(() => {
    const copyRef = pdfRef && pdfRef.current;
    copyRef && copyRef.addEventListener('onchange', valueOnChange);
    copyRef && copyRef.addEventListener('onchangepage', pageOnChange);
    copyRef && copyRef.addEventListener('pdfdocumentproxy', pageProxcyDoc);
    copyRef && copyRef.addEventListener('OnClickField', pdfClickField);
    copyRef && copyRef.addEventListener('onChangePageFields', PdfPageFields);
    return () => {
      copyRef && copyRef.removeEventListener('onchange', valueOnChange);
      copyRef && copyRef.removeEventListener('pdfdocumentproxy', pageOnChange);
      copyRef && copyRef.removeEventListener('pdfdocumentproxy', pageOnChange);
      copyRef && copyRef.removeEventListener('OnClickField', pageProxcyDoc);
      copyRef &&
        copyRef.removeEventListener('onChangePageFields', pageProxcyDoc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {pdfLoader && <div className="liberty-loader" />}
      <ng-pdf-view-edit
        ref={pdfRef}
        pdfurl={pdfDoc}
        showall="false"
        inputfieldenable="true"
        pdfpage={pageNo}
        inputdisable={'true'}
        updatefieldvalue={
          updatedFieldValue && Array.isArray(updatedFieldValue)
            ? JSON.stringify(updatedFieldValue)
            : JSON.stringify([])
        }
        setpdffieldsvalue={
          setpdffieldsvalue && Array.isArray(setpdffieldsvalue)
            ? JSON.stringify(setpdffieldsvalue)
            : JSON.stringify([])
        }
        focusfieldid={
          selectedQus
            ? selectedQus.pdf_field_name
              ? selectedQus.pdf_field_name
              : selectedQus.multiple_pdf_field &&
                Array.isArray(selectedQus.multiple_pdf_field)
              ? selectedQus.multiple_pdf_field[0].pdf_field_name
              : ''
            : ''
        }
        viewhidden={pdfDoc ? 'false' : 'true'}
        {...(zoomProps.type === 'in'
          ? { pdfzoomin: zoomProps.zoom }
          : zoomProps.type === 'out'
          ? { pdfzoomout: zoomProps.zoom }
          : {})}
      />
    </>
  );
};
