import { Typography } from '@mui/material';
import InfinityScroll from 'components/InfinityScroll';
import {
  AttorneyPrivateRoutes,
  BeneficiaryPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import _ from 'lodash';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { MASTER_ENDPOINT } from 'utils/Constant';

type ListPropsTypes = {
  reviews: any;
  reviewParams: any;
  setReviewParams: any;
};

const PendingReviews: React.FC<any> = (props: ListPropsTypes) => {
  const { reviews, setReviewParams } = props;
  const navigate = useNavigate();
  const [pendingReviewParams, setPendingReviewParam] = useState({
    skip: 0,
    take: 50,
  });
  const [pendingReviews, setPendingReviews] = useState<any>({});
  const [, setLoader] = useState(false);

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const pendingReview = (doc, index) => {
    if (userDetail.role_id === 3) {
      // Attorney Reviwes
      if (
        (doc.build_document_for.includes(2) &&
          doc.petitioner_update_count === 1) ||
        (doc.build_document_for.includes(4) &&
          doc.beneficiary_update_count === 1)
      ) {
        // 1 => Pending Documents
        return showPendingReview(
          doc,
          index,
          doc.build_document_for.includes(2) ? 'Petitioner' : 'Beneficiary',
        );
      }
    } else {
      // Petitioner or Beneficiary Reviwes
      if (
        (doc.build_document_for.includes(2) &&
          doc.petitioner_update_count === 0) ||
        (doc.build_document_for.includes(4) &&
          doc.beneficiary_update_count === 0)
      ) {
        return showPendingReview(
          doc,
          index,
          doc.build_document_for.includes(2) ? 'Petitioner' : 'Beneficiary',
        );
      }
    }
  };

  useEffect(() => {
    if (userDetail.role_id === 4) {
      if (pendingReviewParams.take) {
        getAllListData(
          `${String(MASTER_ENDPOINT.DocumentPendingForReview)}?skip=${String(
            pendingReviewParams.skip,
          )}&take=${String(pendingReviewParams.take)}`,
        )
          .then((resp: any) => {
            const mergeData = !_.isEmpty(pendingReviews)
              ? [...pendingReviews.data, ...resp.data]
              : resp.data;
            setPendingReviews({
              data: mergeData,
              total: resp.total,
            });
          })
          .catch((e: any) => {
            setLoader(false);
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingReviewParams.skip, pendingReviewParams.take]);

  const showPendingReview = (doc: any, index?, type?) => {
    return (
      <Typography
        component={'div'}
        className="cp-item d-flex-a pay-container"
        key={index}
        onClick={() => {
          const pathUrl =
            userDetail.role_id === 3
              ? `${String(
                  AttorneyPrivateRoutes.PETITIONS.ACCEPTORREJECT,
                )}/${String(doc.petition_id)}/${String(doc.form_id)}/${String(
                  doc.id,
                )}`
              : userDetail.role_id === 2
              ? `${String(
                  PetitionerPrivateRoutes.PETITIONS.REVIEWANDAPPROVE,
                )}/${String(doc.petition_id)}`
              : `${String(BeneficiaryPrivateRoutes.REVIEWANDAPPROVE)}/${String(
                  doc.petition_id,
                )}?leadUser=${String(doc.user_id)}`; // leadUser doubt
          navigate(
            pathUrl,
            userDetail.role_id === 3 && {
              state: {
                data: {
                  fileFormat: doc.type,
                  templateFilePath: doc.template_file_path,
                  templateName: doc.template_name,
                  updatedBy: type,
                  // userName: doc.user_name,
                },
              },
            },
          );
        }}
        style={{ cursor: 'pointer' }}>
        <Typography component={'div'}>
          <Typography component={'h2'} className="cp-name black-white-text">
            {doc.template_name}
          </Typography>
          <Typography component={'p'} className="cp-by">
            {capitalize(doc.type)} review pending for {doc.user_name}
          </Typography>
        </Typography>

        <Typography
          component={'div'}
          className="cps-text black-white-text pr-2">
          Status for {type}
          <Typography component={'h6'} className="cp-status">
            Pending
          </Typography>
        </Typography>
      </Typography>
    );
  };
  return (
    <Typography component={'div'}>
      <Typography className="c-title black-white-text">
        Pending documents for review
      </Typography>
      <Typography component={'div'} className="cp-box">
        <Typography component={'div'} className="cp-flex-4">
          {userDetail.role_id !== 4 ? (
            <Typography component={'div'}>
              {reviews && reviews.data && reviews.data.length > 0 ? (
                <InfinityScroll
                  paramState={setReviewParams}
                  height={reviews.data.length > 3 ? 325 : null}
                  scrollableTargetId={'pendingReviews'}
                  data={reviews.data}
                  rowProps={pendingReview}
                  count={reviews.count}
                />
              ) : (
                <Typography
                  className="note-box2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                  <Typography className="inb2" />
                  <Typography className="black-white-text ctitle hideshow">
                    No Record(s) Found.
                  </Typography>
                </Typography>
              )}
            </Typography>
          ) : (
            <Typography component={'div'}>
              {pendingReviews &&
              pendingReviews.data &&
              pendingReviews.data.length > 0 ? (
                <InfinityScroll
                  paramState={setPendingReviewParam}
                  height={pendingReviews.data.length > 3 ? 325 : null}
                  scrollableTargetId={'pendingReviews'}
                  data={pendingReviews.data}
                  rowProps={pendingReview}
                  count={pendingReviews.count}
                />
              ) : (
                <Typography
                  className="note-box2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                  <Typography className="inb2" />
                  <Typography className="black-white-text ctitle hideshow">
                    No Record(s) Found.
                  </Typography>
                </Typography>
              )}
            </Typography>
          )}
        </Typography>
      </Typography>
    </Typography>
  );
};

export default React.memo(PendingReviews);
