import { AttorneyPrivateRoutes } from 'config';

export const fileApiUrl =
  process.env.REACT_APP_FILE_URL || `${window.location.origin}/api/aws-files/`;

export const documentUrl =
  process.env.REACT_APP_DOCUMENT_URL ||
  `${window.location.origin}/api/generate-document/preview/`;

export const showAddToolTips = [
  {
    route: AttorneyPrivateRoutes.MANAGEUSER.USERADD,
    routeSegment: 'manage-users',
  },
  {
    route: AttorneyPrivateRoutes.QUESTION.QUESTIONADD,
    routeSegment: 'configure-questions',
  },
  {
    route: AttorneyPrivateRoutes.SECTION.SECTIONADD,
    routeSegment: 'configure-section',
  },
  {
    route: AttorneyPrivateRoutes.MYFIRM,
    routeSegment: 'my-firm',
  },
  {
    route: AttorneyPrivateRoutes.DOCUMENT.DOCUMENTADD,
    routeSegment: 'document-template',
  },
  {
    route: AttorneyPrivateRoutes.MANAGEORGANIZATION.ORGANIZATIONADD,
    routeSegment: 'manage-organization',
  },
  {
    route: AttorneyPrivateRoutes.CONTRACT.CONTRACTADD,
    routeSegment: 'contract-fees',
  },
  {
    route: AttorneyPrivateRoutes.MYFIRM,
    routeSegment: 'leads', // yet to start
  },
  {
    route: AttorneyPrivateRoutes.MYFIRM,
    routeSegment: 'schedule-strategy-session', // yet to start
  },
  {
    route: AttorneyPrivateRoutes.MYFIRM,
    routeSegment: 'my-petitions', // yet to start
  },
];

export const defaultPeriod = [
  {
    label: 'Today',
    value: 'Today',
  },
  {
    label: 'Current Week',
    value: 'Current_Week',
  },
  {
    label: 'Previous Week',
    value: 'Previous_Week',
  },
  {
    label: 'Current Month',
    value: 'Current_Month',
  },
  {
    label: 'Previous Month',
    value: 'Previous_Month',
  },
  {
    label: 'Current Quarter',
    value: 'Current_Quarter',
  },
  {
    label: 'Previous Quarter',
    value: 'Previous_Quarter',
  },
  {
    label: 'Current Year',
    value: 'Current_Year',
  },
  {
    label: 'All Records',
    value: 'all',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];
