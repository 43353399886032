import './PdfPage.css';

import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

// const pdfjsLib: any = window['pdfjs-dist/build/pdf'];
declare const pdfjsLib: any;
const PdfPage = React.memo((props: any) => {
  const { page, scale, selectedPage, setCoOrdinates, shouldDraw } = props;
  // pdfjsLib.GlobalWorkerOptions.workerSrc =
  //   '//mozilla.github.io/pdf.js/build/pdf.worker.js';
  const canvasRef = useRef(null);
  const textLayerRef = useRef();
  const [pdf_image, setPdf_image] = useState<any>('');
  const [hwp, setHWP] = useState({
    h: null,
    p: 1,
    w: null,
  });
  const [xy, setXY] = useState({
    offsetX: null,
    offsetY: null,
    startX: null,
    startY: null,
  });
  const [cursorInCanvas, setCursorInCanvas] = useState(false);

  const saveInitialCanvas = () => {
    const canvasOfDoc = canvasRef && canvasRef.current;
    if (canvasOfDoc?.getContext) {
      const canvasPic = new Image();
      canvasPic.src = canvasOfDoc.toDataURL();
      setPdf_image(canvasPic);
    }
  };

  const handleMouseIn = (e) => {
    const canvasOfDoc = canvasRef && canvasRef.current;
    if (typeof pdf_image == 'string') {
      saveInitialCanvas();
    }
    e.preventDefault();
    e.stopPropagation();
    setXY((pre) => ({
      ...pre,
      startX:
        ((e.nativeEvent.offsetX * canvasOfDoc.width) /
          canvasOfDoc.clientWidth) |
        0,
      startY:
        ((e.nativeEvent.offsetY * canvasOfDoc.width) /
          canvasOfDoc.clientWidth) |
        0,
    }));

    setCursorInCanvas(true);
  };

  const handleMouseOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (hwp.h && hwp.w) {
      // setCoOrdinates.enabled = true;
      // if (setCoOrdinates.page_data.pageNumber !== hwp.p) {
      //   setCoOrdinates.page_data.push({
      //     h: hwp.h,
      //     pageNumber: hwp.p,
      //     w: hwp.w,
      //     x: xy.startX,
      //     y: xy.startY,
      //   });
      // }
      // setCoOrdinates({
      //   enabled: true,
      //   h: hwp.h,
      //   pageNumber: hwp.p,
      //   w: hwp.w,
      //   x: xy.startX,
      //   y: xy.startY,
      // });
    }

    setCursorInCanvas(false);
  };

  function upsert(array, element) {
    const i = array.findIndex(
      (_element) => _element.pageNumber === element.pageNumber,
    );
    if (i > -1) array[i] = element;
    else array.push(element);
  }

  const handleMouseMove = (e) => {
    const canvasOfDoc = canvasRef && canvasRef.current;
    e.preventDefault();
    e.stopPropagation();
    if (!cursorInCanvas) {
      return;
    }
    const mouseX =
      ((e.nativeEvent.offsetX * canvasOfDoc.width) / canvasOfDoc.clientWidth) |
      0;
    const mouseY =
      ((e.nativeEvent.offsetY * canvasOfDoc.width) / canvasOfDoc.clientWidth) |
      0;

    const width = mouseX - xy.startX;
    const height = mouseY - xy.startY;
    const canvas: any = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx?.clearRect(0, 0, canvasOfDoc.width, canvasOfDoc.height);
      ctx?.drawImage(pdf_image, 0, 0);
      ctx.beginPath();
      // console.log('XYWH', xy.startX, xy.startY, width, height);
      setHWP({
        h: height,
        p: page.pageNumber,
        w: width,
      });

      setCoOrdinates.enabled = true;
      upsert(setCoOrdinates.page_data, {
        h: height,
        pageNumber: page.pageNumber,
        w: width,
        x: xy.startX,
        y: xy.startY,
      });

      ctx.rect(xy.startX, xy.startY, width, height);
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 1;
      ctx.stroke();
    }
  };

  useEffect(() => {
    if (!page) {
      return;
    }
    const viewport = page.getViewport({ scale });

    // Prepare canvas using PDF page dimensions
    const canvas: any = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      const canvasOffset = canvas.getBoundingClientRect();
      setXY((pre) => ({
        ...pre,
        offsetX: canvasOffset.left,
        offsetY: canvasOffset.top,
      }));

      canvas.height = viewport.height;
      canvas.width = viewport.width;
      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      page.render(renderContext).promise.then(function () {
        if (context && !_.isEmpty(selectedPage)) {
          const boundinBoxes = selectedPage.combined_bbox;

          const canvas: any = canvasRef.current;
          const ctx = canvas.getContext('2d');
          ctx.strokeStyle = 'red';
          ctx.strokeRect(
            boundinBoxes['x_min'],
            boundinBoxes['y_min'],
            boundinBoxes['x_max'],
            boundinBoxes['y_max'],
          );
        }
      });
      page.getTextContent().then((textContent) => {
        if (!textLayerRef.current) {
          return;
        }

        // Pass the data to the method for rendering of text over the pdf canvas.
        pdfjsLib.renderTextLayer({
          container: textLayerRef.current,
          textContentSource: textContent,
          textDivs: [],
          viewport: viewport,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPage, page, scale]);

  useEffect(() => {
    const externalScript = document.createElement('script');
    const loadError = (onError) => {
      console.error(onError);
    };

    const LoadExternalScript = () => {
      externalScript.onerror = loadError;
      externalScript.id = 'external';
      externalScript.async = true;
      externalScript.type = 'text/javascript';
      externalScript.setAttribute('crossorigin', 'anonymous');
      document.body.appendChild(externalScript);
      externalScript.src = '//mozilla.github.io/pdf.js/build/pdf.js';
    };
    LoadExternalScript();

    return () => {
      document.body.removeChild(externalScript);
    };
  }, []);

  return (
    <div className="PdfPage">
      <canvas ref={canvasRef} />
      <div
        ref={textLayerRef}
        className="PdfPage__textLayer"
        onMouseDown={shouldDraw ? handleMouseIn : null}
        onMouseUp={shouldDraw ? handleMouseOut : null}
        onMouseMove={shouldDraw ? handleMouseMove : null}
        onMouseOut={shouldDraw ? handleMouseOut : null}
      />
    </div>
  );
});

export default PdfPage;
