import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ErrorIcon from '@mui/icons-material/Error';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Tab,
  Tooltip,
  Typography,
} from '@mui/material';
import ContractIcon from 'assets/images/contractmenu.svg';
import Eye from 'assets/images/eye.svg';
// import Avator from 'assets/images/fill-avator.svg';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import { saveAs } from 'file-saver';
import { instantState } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
  getPdfFile,
  getS3file,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions, mimeTypes } from 'utils/helper';
import { ContractSchema } from 'utils/ValidatorSchema';

import useStyles from '../../../../PrivateStyle';
import PreviousContractsListing from './PreviousContractsListing';

export const ContractPaymentDetails: React.FC<any> = () => {
  const { editId, contractId, userId, contractType } = useParams();
  const classes = useStyles();
  const navigate: any = useNavigate();
  const [searchParams] = useSearchParams();
  const location: any = useLocation();
  const { contractName } = location && location.state ? location.state : '';
  const [tabValue, setTabValue] = React.useState('1');
  const [loader, setLoader] = useState(true);
  const [waitingForRes, setWaitingForRes] = useState(false);
  // const [disabledBtn, setDisabledBtn] = useState(false);
  const [isShowPGAlert, setIsShowPGAlert] = useState(false);

  const [disabledRequiredPayments, setDisabledRequiredPayments] =
    useState(false);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    content: '',
    id: '',
    key: '',
    show: false,
  });
  const [contractPaymentModel, setContractPaymentModel] = useState<{
    open: boolean;
  }>({
    open: false,
  });
  const [leadContractPayment, setLeadContractPayment] = useState<any>({});
  const [contractMappings, setContractMappings] = useState<any>({});
  const [contractHistory, setContractHistory] = useState<any>([]);
  const [visaTypes, setVisaTypes] = useState<any>([]);
  const [versionList, setVersionList] = useState<any>([]);
  const [paymentPurposeOptions, setpaymentPurposeOptions] = useState([]);
  const [leadData, setLeadData] = useState<any>({});

  const petitionerFileInput = useRef<any>(null);
  const beneficiaryFileInput = useRef<any>(null);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const {
    control: control,
    handleSubmit: handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      beneficiaryName: '',
      description: '',
      isEnterpriseContract: 'no',
      mailId: '',
      orgName: '',
      paymentAmount: '0.00',
      paymentChecked: 'no',
      paymentPurpose: '',
      visaType: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ContractSchema),
  });

  const handleContractPaymentModel = () => {
    setContractPaymentModel((pre) => ({
      ...pre,
      open: true,
    }));
  };

  const paymentList = () => {
    getAllListData(
      `${COMMON_ENDPOINT.ContractPayment}/history?userId=${String(
        contractId,
      )}&paymentFor=${
        contractType.toLowerCase() === 'petitioner'
          ? '2'
          : contractType.toLowerCase() === 'affilate'
          ? '5'
          : '4'
      }`,
    )
      .then((historyResp: any) => {
        setLoader(false);
        setContractHistory(historyResp);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === '2') {
      paymentList();
    } else {
      getFirmPaymentDetails();
    }
  };

  const openFillForm = () => {
    const data = {
      contract: true,
      contractName: contractName ? contractName : '',
      id: location.state ? location.state.id : editId,
      isLead: location.state ? location.state.isLead : true,
      isOrg: searchParams.get('orgId') ? true : false,
      selectedContract: leadContractPayment,
    };

    navigate(
      `${AttorneyPrivateRoutes.LEADS.VIEWEDITCONTRACTS}/${String(
        editId,
      )}/${String(leadContractPayment.id)}/${String(
        contractType.toLowerCase() === 'petitioner'
          ? leadContractPayment.petitionerId
          : userId,
      )}/${String(contractType)}${
        searchParams.get('orgId')
          ? '?orgId=' + String(searchParams.get('orgId'))
          : ''
      }${
        searchParams.get('invited')
          ? '&invited=true'
          : searchParams.get('associated')
          ? '&associated=true'
          : ''
      }${
        searchParams.get('isLead')
          ? '&isLead=true'
          : searchParams.get('isPetition')
          ? '&isPetition=true'
          : ''
      }`,
      { state: data },
    );
  };

  const paymentLink = (e) => {
    try {
      navigator.clipboard.writeText(e);
      toast.success('Link copied successfully.', alertOptions);
    } catch (err) {
      toast.error('Try again later.', alertOptions);
    }
  };
  const fileDownloadTransaztion = (data) => {
    const fileName = `Report-${String(data.transaction_id)}-${
      new Date().getMonth() + 1
    }/${new Date().getDate()}/${new Date().getFullYear()}.pdf`;
    setLoader(true);
    const obj = {
      key: 'pdf',
    };
    getPdfFile(
      obj,
      `${COMMON_ENDPOINT.PaymentSlip}` + `${String(data.transaction_id)}`,
    )
      .then((resData: any) => {
        saveAs(resData, fileName);
        setLoader(false);
        toast.success(fileName + ' File downloaded successfully', alertOptions);
      })
      .catch((err) => {
        setLoader(false);
        toast.error('Unable to read the file.', alertOptions);
      });
  };
  const deletePayment = (action) => {
    if (action === 'yes') {
      deleteData(confirmOpen.id, COMMON_ENDPOINT.ContractPayment)
        .then((resp: any) => {
          getcontractPersonDetails();
          toast.success('Deleted successfully', alertOptions);
          setConfirmOpen({
            content: '',
            id: '',
            key: '',
            show: false,
          });
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };
  const fileDownload = (file: any) => {
    const obj = {
      key: file,
    };
    const fileName: string = file ? file.split('/').pop() : 'download';
    setLoader(true);
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(fileName + 'File downloaded successfully', alertOptions);
      })
      .catch((err) => {
        setLoader(false);
        toast.error('Unable to read the file.', alertOptions);
      });
  };
  const build = () => {
    if (contractMappings.contractFormId && leadContractPayment?.template_id) {
      const feesNotEnable = contractFeesCheck(
        leadContractPayment?.contract_question,
      );
      if (feesNotEnable) {
        toast.error('Please select the contract fees', alertOptions);
        return;
      }
      const contractFor = contractType === 'petitioner' ? 2 : 4;
      // use contractId instead of leadContractPayment?.lead_id - if undefined ?
      const leadId =
        leadContractPayment && leadContractPayment?.lead_id
          ? leadContractPayment?.lead_id
          : leadContractPayment?.id;
      const leadUserId =
        leadContractPayment && leadContractPayment?.id
          ? leadContractPayment?.id
          : '';
      const formId = leadContractPayment?.template_id;
      setWaitingForRes(true);
      setLoader(true);

      getAllListData(
        `${COMMON_ENDPOINT.DocumentBuild}/null/null/${String(
          formId,
        )}?buildtype=contract&leadUserId=${String(
          leadUserId,
        )}&contractfor=${String(contractFor)}&leadId=${String(
          leadId,
        )}&firmId=${String(userDetail.companyId)}`,
      )
        .then((data: any) => {
          setLoader(false);
          setContractMappings((pre) => ({
            ...pre,
            attorneyDocumnet: data && data.key ? data.key : '',
          }));
          toast.success('Contract document has been generated', alertOptions);
          setWaitingForRes(false);
          getcontractPersonDetails();
        })
        .catch((err) => {
          setLoader(false);
          setWaitingForRes(false);
          toast.error(
            'Unable to generate the contract. Please try again.',
            alertOptions,
          );
        });
    } else {
      toast.error('Please select the contract form name.', alertOptions);
    }
  };

  const getcontractPersonDetails = () => {
    getFirmPaymentDetails();
    getAllListData(
      `${COMMON_ENDPOINT.LeadList}?id=${String(
        editId,
      )}&status=&skip=0&take=10&sortby=&order=&visaId=&organizationId=&orgType=&leadsSelected=&associated=&name=`,
    )
      .then((resp) => {
        setLeadData(resp.data[0]);
        getAllListData(
          `${COMMON_ENDPOINT.Contract}/${String(
            editId,
          )}?contactType=${contractType}&userId=${contractId}`,
        )
          .then((contractResp: any) => {
            const contracts: any = contractResp;
            contracts['attorney_contact_id'] =
              resp.data[0]?.attorney_contact_id;
            contracts['organizationId'] = resp.data[0]?.petitioner_assigned;
            contracts['organizationType'] = resp.data[0]?.org_type;
            contracts['petitionerContactUserId'] =
              resp.data[0]?.petitioner_conatact_id;
            contracts['petitionerAssociated'] =
              resp.data[0].petitioner_associated;
            if (!contractResp?.visaselectedid) {
              contracts['visaApiCall'] = true;
            }
            contracts['visaselectedid'] = contractResp.visaselectedid
              ? contractResp.visaselectedid
              : resp.data[0]?.visa_type[0];
            setLeadContractPayment(contracts);

            const cMapping = {};

            cMapping['attorneyDocumnet'] = contracts.attorneydocumentkey
              ? contracts.attorneydocumentkey
              : null;
            cMapping['petitionerDocument'] = contracts.petitionerdocumentkey
              ? contracts.petitionerdocumentkey
              : null;
            cMapping['beneficiaryDocument'] = contracts.benificierydocumentkey
              ? contracts.benificierydocumentkey
              : null;
            cMapping['orgId'] = contracts.orgId ? contracts.orgId : null;
            cMapping['beneficiaryId'] = contracts.id ? contracts.id : null;
            cMapping['status'] = contracts.status ? contracts.status : null;
            cMapping['contractFormId'] = contracts.contractformid
              ? contracts.contractformid
              : null;
            cMapping['latestPaymentLink'] = contracts.paymentLink
              ? contracts.paymentLink
              : '';
            cMapping['personEmailId'] = contracts.emailid
              ? contracts.emailid
              : contracts.previousEmailId
              ? contracts.previousEmailId
              : '';
            setContractMappings(cMapping);
            setValue(
              'paymentPurpose',
              contracts.payment_option_id ? contracts.payment_option_id : '',
            );
            setValue(
              'orgName',
              contractType.toLowerCase() === 'affilate'
                ? resp.data[0].org_name
                : contractResp.orgName
                ? contractResp.orgName
                : '',
            );
            setValue(
              'beneficiaryName',
              contractType.toLowerCase() === 'affilate'
                ? contractResp.affilatename
                : contractResp.beneficiaryname || '',
            );
            setValue(
              'visaType',
              contractResp.visaselectedid
                ? contractResp.visaselectedid
                : resp.data[0]?.visa_type[0] || '',
            );
            setValue(
              'isEnterpriseContract',
              contractResp.partofenterprisecontract ? 'yes' : 'no' || 'no',
            );
            setValue(
              'mailId',
              contractResp.previousEmailId
                ? contractResp.previousEmailId
                : contracts.emailid
                ? contracts.emailid
                : '' || '',
            );
          })
          .catch((e: any) => {
            setLoader(false);
          });
      })
      .catch((e) => {
        setLoader(false);
      });
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`)
      .then((visaResp: any) => {
        setVisaTypes(visaResp.data);
      })
      .catch((e) => {
        setLoader(false);
      });
    getAllListData(
      `${COMMON_ENDPOINT.Contract}/previous/${
        contractType.toLowerCase() === 'petitioner'
          ? '2'
          : contractType.toLowerCase() === 'affilate'
          ? '5'
          : '4'
      }/${userId}?status=true`,
    )
      .then((resp: any) => {
        setVersionList(resp.data);
      })
      .catch((e) => {
        setLoader(false);
      });
    getAllListData(
      `${COMMON_ENDPOINT.ContractPayment}/history?userId=${String(
        contractId,
      )}&paymentFor=${
        contractType.toLowerCase() === 'petitioner'
          ? '2'
          : contractType.toLowerCase() === 'affilate'
          ? '5'
          : '4'
      }`,
    )
      .then((historyResp: any) => {
        setLoader(false);
        if (historyResp && historyResp.length) {
          setContractHistory(historyResp);
          const paymentLast = historyResp[0];
          const paymentSelect =
            paymentLast && paymentLast.response_code ? 'no' : 'yes';
          // setValue('paymentChecked', paymentLast.response_code ? 'no' : 'yes');

          if (paymentSelect === 'yes') {
            setValue(
              'paymentAmount',
              paymentLast && paymentLast.payment_total
                ? parseFloat(paymentLast.payment_total).toFixed(2)
                : '0.00',
            );
            // setDisabledBtn(true);
          } else {
            setValue('paymentAmount', '0.00');
            // setDisabledBtn(false);
          }
        } else {
          setContractHistory([]);
          // setDisabledBtn(false);
        }
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const contractFeesCheck = (contractFees) => {
    try {
      const fees = JSON.parse(contractFees);
      return fees && fees.length ? false : true;
    } catch (error) {
      return true;
    }
  };

  const paymentAmountCheck = (value) => {
    if (value === 'yes') {
      setValue(
        'paymentAmount',
        contractHistory &&
          !contractHistory.response_code &&
          contractHistory.payment_total
          ? parseFloat(contractHistory.payment_total).toFixed(2)
          : leadContractPayment && leadContractPayment.paymentTotal
          ? parseFloat(leadContractPayment.paymentTotal).toFixed(2)
          : '0.00',
      );
    } else {
      setValue('paymentAmount', '0.00');
    }
  };

  const uploadContractFile = (file: any) => {
    setWaitingForRes(true);
    const id =
      contractType.toLowerCase() === 'petitioner'
        ? contractMappings.orgId
        : contractMappings.beneficiaryId;
    const formdata = new FormData();
    formdata.append('file', file ? file[0] : '');
    formdata.append('uploadFor', contractType.toLowerCase());
    formdata.append(
      'roleId',
      file[0]
        ? contractType.toLowerCase() === 'petitioner'
          ? '2'
          : contractType.toLowerCase() === 'affilate'
          ? '5'
          : '4'
        : '3',
    );
    formdata.append(
      'key',
      userDetail.role_id === 3 && contractMappings.attorneyDocumnet
        ? contractMappings.attorneyDocumnet
        : '',
    );
    setLoader(true);
    updateData(
      '',
      formdata,
      `${COMMON_ENDPOINT.Contract}/upload/${String(contractId)}/${String(id)}`,
    )
      .then((resp: any) => {
        toast.success('Uploaded successfully', alertOptions);
        setWaitingForRes(false);
        setLoader(false);
        getcontractPersonDetails();
      })
      .catch((e) => {
        setWaitingForRes(false);
        setLoader(false);
        toast.error('Failed to upload', alertOptions);
      });
  };

  const enterpriseContract = async () => {
    const leadContractPayment = await instantState(setLeadContractPayment);
    setLoader(true);
    updateData(
      '',
      {
        leadId:
          contractType.toLowerCase() === 'petitioner'
            ? Number(leadContractPayment?.id)
            : leadContractPayment?.id,
        // eslint-disable-next-line sort-keys-fix/sort-keys-fix
        leadFor: contractType.toLowerCase() === 'petitioner' ? 2 : 4,
      },
      `${String(COMMON_ENDPOINT.EnterpriseContract)}`,
    )
      .then((resp: any) => {
        setLoader(false);
      })
      .catch((error: any) => {
        setLoader(false);
      });
  };
  const finalContractApproved = () => {
    const contractFor =
      contractType.toLowerCase() === 'petitioner'
        ? 2
        : contractType.toLowerCase() === 'affilate'
        ? 5
        : 4;
    const leadId = leadContractPayment?.lead_id
      ? leadContractPayment?.lead_id
      : leadContractPayment.id;
    const formId = leadContractPayment?.template_id;
    const leadUserId = leadContractPayment.id ? leadContractPayment.id : '';
    setWaitingForRes(true);
    setLoader(true);
    addNewData(
      {},
      `${COMMON_ENDPOINT.ApproveContract}/approve/${String(
        formId,
      )}?buildtype=contract&leadUserId=${String(
        leadUserId,
      )}&contractfor=${contractFor}&leadId=${String(leadId)}&firmId=${String(
        userDetail.companyId,
      )}&mailId=${
        leadContractPayment.emailid ? String(leadContractPayment.emailid) : ''
      }&attorneyAccept=true`,
    )
      .then((resp: any) => {
        setLoader(false);
        toast.success('Contract approved successfully.', alertOptions);
        setWaitingForRes(false);
        getcontractPersonDetails();
      })
      .catch((e) => {
        setLoader(false);
        setWaitingForRes(false);
        toast.error(
          'Unable to approve the contract. Please try again.',
          alertOptions,
        );
      });
  };

  const contractApproved = (data: any) => {
    if (watch('paymentChecked') === 'yes' && Number(data.paymentAmount) <= 0) {
      setError('paymentAmount', {
        message: 'Payment amount should be greater than 0',
        type: 'custom',
      });
      return;
    }
    if (
      (!contractMappings.attorneyDocumnet && Number(data.paymentAmount) <= 0) ||
      (watch('paymentChecked') === 'yes' && Number(data.paymentAmount) <= 0)
    ) {
      toast.error(
        watch('paymentChecked') === 'yes' && Number(data.paymentAmount) <= 0
          ? 'Please enter the amount.'
          : 'Please build a contract or please provide the payment amount to proceed further.',
        alertOptions,
      );
      return;
    }
    setLeadContractPayment((pre) => ({
      ...pre,
      emailid: data.mailId
        ? data.mailId
        : contractMappings.personEmailId
        ? contractMappings.personEmailId
        : '',
    }));
    const content =
      contractMappings.personEmailId || data.mailId !== ''
        ? `Are you sure you want to send the link to the email ${String(
            data.mailId ? data.mailId : contractMappings.personEmailId,
          )}?`
        : 'Email is not mentioned. Do you want initiate Contract (or) Payment link?';
    setConfirmOpen({ content: content, key: 'approve', show: true });
  };

  const onApprove = async (action) => {
    if (action === 'yes') {
      try {
        let currentPaymentOption = watch('paymentPurpose')
          ? Number(watch('paymentPurpose'))
          : '';
        if (
          String(watch('paymentPurpose')) === '6' &&
          String(watch('description')).trim()
        ) {
          const addedRes = await addNewData(
            { name: watch('description') },
            `${COMMON_ENDPOINT.Contract}/payment-option/add`,
          );
          currentPaymentOption = addedRes?.data?.id;
          getPaymentPurpose();
        }
        const approveData = {
          leadFor:
            contractType.toLowerCase() === 'petitioner'
              ? 2
              : contractType.toLowerCase() === 'affilate'
              ? 5
              : 4,
          leadUserId:
            leadContractPayment && leadContractPayment.id
              ? leadContractPayment.id
              : '',
          mailId:
            leadContractPayment && leadContractPayment.emailid
              ? leadContractPayment.emailid
              : '',
          name:
            leadContractPayment && leadContractPayment.name
              ? leadContractPayment.name
              : '',
          paymentAmount:
            watch('paymentChecked') === 'no'
              ? ''
              : String(watch('paymentAmount')),
          paymentChecked: watch('paymentChecked') === 'no' ? false : true,
          paymentOption: currentPaymentOption,
          petitionerName: leadContractPayment.orgName,
          visaType: leadContractPayment.visaTypeFromLead,
        };

        contractType.toLowerCase() === 'affilate'
          ? (approveData['affilateName'] = leadContractPayment.affilatename)
          : (approveData['beneficiaryName'] =
              leadContractPayment.beneficiaryname);

        setConfirmOpen({ content: '', id: '', key: '', show: false });

        updateData('', approveData, `${COMMON_ENDPOINT.Contract}/approve`)
          .then((resp: any) => {
            setValue('paymentChecked', 'no');
            toast.success('Link sent successfully', alertOptions);
            getcontractPersonDetails();
          })
          .catch((e) => {
            toast.error(
              'Unable to send the link. Please try again.',
              alertOptions,
            );
          });
      } catch (error) {
        toast.error('Unable to send the link. Please try again.', alertOptions);
      }
    }
  };
  const getPaymentPurpose = () => {
    getAllListData(
      `${COMMON_ENDPOINT.Contract}/payment-option/list${
        leadData?.isAffilateCase
          ? `?isAffilate=true&affilateRoleId=${Number(
              leadData?.affilateRoleId,
            )}&firmId=${Number(leadData?.attorney_assigned)}${
              leadData?.affilateRoleId === 2 && leadData?.petitioner_assigned
                ? `&petitionerId=${Number(leadData?.petitioner_assigned)}`
                : ''
            }`
          : ''
      }`,
    )
      .then((res) => {
        setpaymentPurposeOptions(
          res && Array.isArray(res) && res.length ? res : [],
        );
      })
      .catch(() => {});
  };

  const getFirmPaymentDetails = () => {
    if (userDetail && userDetail.role_id === 3) {
      getAllListData(`${String(COMMON_ENDPOINT.PaymentGateway)}`)
        .then((resp: any) => {
          setDisabledRequiredPayments(resp.contract_payment_enable);
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    getcontractPersonDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!_.isEmpty(leadData)) {
      getPaymentPurpose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadData]);

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Grid container>
      <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
        <Typography
          component={'div'}
          className="d-flex-a d-flex-sb sticky-tab mb-1">
          <Typography
            component={'div'}
            className="d-flex-a d-flex-sb sticky-tab mb-1">
            <Typography
              component={'div'}
              className="black-white-text"
              sx={{
                fontFamily: 'Arial-Bold !important',
                fontSize: '1.25em !important',
              }}>
              Contract: {contractName}
            </Typography>
          </Typography>
          <Button
            className="cancel-btn"
            sx={{
              borderRadius: '5px !important',
              fontFamily: 'Arial-Regular !important',
              fontSize: '0.9rem !important',
              // height: '35px !important',
              width: '120px !important',
            }}
            onClick={() => {
              userDetail.role_id === 2
                ? navigate(
                    location.state?.isLead
                      ? `${
                          PetitionerPrivateRoutes.LEADS.CONTRACTPAYMENT
                        }/${String(location.state?.id)}`
                      : `${
                          PetitionerPrivateRoutes.PETITIONS.CONTRACTPAYMENT
                        }/${String(location.state?.id)}`,
                  )
                : userDetail.role_id === 5
                ? leadData?.isAffilateCase && leadData?.affilateRoleId === 3
                  ? navigate(
                      location.state?.isLead
                        ? `${String(
                            AffilatePrivateRoutes.ATTORNEY_LEADS
                              .CONTRACTPAYMENT,
                          )}/${String(location.state?.id)}`
                        : `${String(
                            AffilatePrivateRoutes.ATTORNEY_PETITIONS
                              .CONTRACTPAYMENT,
                          )}/${String(location.state?.id)}`,
                    )
                  : navigate(
                      location.state?.isLead
                        ? `${String(
                            AffilatePrivateRoutes.PETITIONER_LEADS
                              .CONTRACTPAYMENT,
                          )}/${String(location.state?.id)}`
                        : `${String(
                            AffilatePrivateRoutes.PETITIONER_PETITIONS
                              .CONTRACTPAYMENT,
                          )}/${String(location.state?.id)}`,
                    )
                : navigate(
                    `${String(
                      location.state?.isLead
                        ? AttorneyPrivateRoutes.LEADS.CONTRACTPAYMENT
                        : AttorneyPrivateRoutes.PETITIONS.CONTRACTPAYMENT,
                    )}/${String(location.state?.id)}${
                      searchParams.get('orgId')
                        ? '?orgId=' + String(searchParams.get('orgId'))
                        : ''
                    }${
                      searchParams.get('invited')
                        ? '&invited=true'
                        : searchParams.get('associated')
                        ? '&associated=true'
                        : ''
                    }${
                      searchParams.get('isLead')
                        ? '&isLead=true'
                        : searchParams.get('isPetition')
                        ? '&isPetition=true'
                        : ''
                    }`,
                  );
            }}>
            <Typography
              className="o-box d-flex-a"
              sx={{ width: 'unset !important' }}
            />
            <ArrowBackIcon sx={{ marginRight: '.5rem' }} />
            Back
          </Button>
        </Typography>

        <Box
          sx={{ marginTop: '1rem', width: '100%' }}
          className="contractPayTab">
          <TabContext value={tabValue}>
            <Typography component={'div'} className="d-flex-a d-flex-sb">
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example">
                  <Tab label="Contract & Payment" value="1" />
                  {(userDetail.role_id === 3 && !leadData?.isAffilateCase) ||
                  (leadData?.isAffilateCase &&
                    leadData?.affilateRoleId === 3) ? (
                    <Tab label="Payment History" value="2" />
                  ) : null}
                </TabList>
              </Box>
            </Typography>
            <Typography component={'div'}>
              <TabPanel value="1">
                <Grid container>
                  <Grid item md={12}>
                    <form
                      id="approve-contract"
                      onSubmit={handleSubmit(contractApproved)}>
                      <Grid container>
                        <Grid md={12}>
                          <Typography component={'div'} className="pr-24">
                            <Box
                              component="form"
                              className="u-add-in"
                              sx={{
                                border: 'unset',
                                borderRadius: '12px !important',
                              }}
                              noValidate
                              autoComplete="off">
                              <Grid container columnSpacing={{ md: 3, sm: 2 }}>
                                <Grid item md={6} sm={12}>
                                  <Typography
                                    component={'div'}
                                    className="custom-field ">
                                    <Typography
                                      component={'div'}
                                      className="custom-label ">
                                      Organization Name
                                    </Typography>
                                    <Controller
                                      control={control}
                                      defaultValue=""
                                      name="orgName"
                                      render={({
                                        field: { onChange, value, name, ref },
                                        formState,
                                      }) => {
                                        return (
                                          <CustomInput
                                            placeHolder={''}
                                            name={name}
                                            value={value}
                                            className={'custom-input'}
                                            error={errors.orgName?.message}
                                            onChange={(val) => onChange(val)}
                                            disabled={true}
                                          />
                                        );
                                      }}
                                    />
                                  </Typography>
                                  <Typography
                                    component={'div'}
                                    className="custom-field">
                                    <Typography
                                      component={'div'}
                                      className="custom-label ">
                                      Visa Type
                                    </Typography>
                                    <Controller
                                      control={control}
                                      defaultValue=""
                                      name="visaType"
                                      render={({
                                        field: { onChange, value, name, ref },
                                        formState,
                                      }) => {
                                        return (
                                          <CustomSelect
                                            searchable={true}
                                            labelKey={'visa_code'}
                                            valueKey={'visa_id'}
                                            options={
                                              !_.isEmpty(visaTypes)
                                                ? visaTypes
                                                : []
                                            }
                                            name={name}
                                            value={value}
                                            onChange={(val) => onChange(val)}
                                            placeHolder={'Select'}
                                            className={'custom-input'}
                                            error={errors.visaType?.message}
                                            disabled={true}
                                          />
                                        );
                                      }}
                                    />
                                  </Typography>
                                  {![2, 5].includes(userDetail.role_id) ||
                                  (leadData?.isAffilateCase &&
                                    leadData?.affilateRoleId !== 2) ? (
                                    <Typography
                                      className="pb-1"
                                      component={'a'}
                                      sx={{ cursor: 'pointer' }}
                                      onClick={(e) => {
                                        versionList.length
                                          ? handleContractPaymentModel()
                                          : false;
                                      }}>
                                      <img src={Eye} alt="eye" />
                                      <span className="n-blue-text f-15 font-bold ">
                                        {' '}
                                        {versionList.length} previous contracts
                                        found
                                      </span>
                                    </Typography>
                                  ) : null}
                                </Grid>
                                <Grid item md={6} sm={12}>
                                  <Typography
                                    component={'div'}
                                    className="custom-field">
                                    <Typography
                                      component={'div'}
                                      className="custom-label ">
                                      {contractType.toLowerCase() === 'affilate'
                                        ? 'Affiliate'
                                        : 'Beneficiary'}{' '}
                                      Name
                                    </Typography>
                                    <Controller
                                      control={control}
                                      defaultValue=""
                                      name="beneficiaryName"
                                      render={({
                                        field: { onChange, value, name, ref },
                                        formState,
                                      }) => {
                                        return (
                                          <CustomInput
                                            placeHolder={''}
                                            name={name}
                                            value={value}
                                            className={'custom-input'}
                                            error={
                                              errors.beneficiaryName?.message
                                            }
                                            onChange={(val) => onChange(val)}
                                            disabled={true}
                                          />
                                        );
                                      }}
                                    />
                                  </Typography>
                                  {contractType.toLowerCase() !== 'affilate' &&
                                  (![2, 5].includes(userDetail.role_id) ||
                                    (leadData?.isAffilateCase &&
                                      leadData?.affilateRoleId !== 2)) ? (
                                    <Typography
                                      component={'div'}
                                      className="custom-field">
                                      <Typography
                                        component={'div'}
                                        className="custom-label ">
                                        Is this case part of an enterprise
                                        contract?
                                      </Typography>
                                      <Controller
                                        control={control}
                                        defaultValue="no"
                                        name="isEnterpriseContract"
                                        render={({
                                          field: { onChange, value, name, ref },
                                          formState,
                                        }) => {
                                          return (
                                            <RadioGroup
                                              row
                                              aria-labelledby="demo-row-radio-buttons-group-label "
                                              name={name}
                                              value={value}
                                              onChange={(e) => {
                                                onChange(e);
                                                enterpriseContract();
                                              }}>
                                              <FormControlLabel
                                                value="no"
                                                control={
                                                  <Radio
                                                    disabled={
                                                      leadData?.isAffilateCase
                                                    }
                                                  />
                                                }
                                                label="No"
                                              />
                                              <FormControlLabel
                                                value="yes"
                                                control={
                                                  <Radio
                                                    disabled={
                                                      leadData?.isAffilateCase
                                                    }
                                                  />
                                                }
                                                label="Yes"
                                              />
                                            </RadioGroup>
                                          );
                                        }}
                                      />
                                    </Typography>
                                  ) : null}
                                </Grid>
                              </Grid>
                            </Box>
                          </Typography>
                          ;
                          {watch('isEnterpriseContract') === 'no' && (
                            <Grid container rowSpacing={{ md: 3, sm: 2 }}>
                              {/* Contract Form */}
                              {userDetail.role_id === 3 ||
                              (leadData?.isAffilateCase &&
                                leadData?.affilateRoleId === 3) ? (
                                <Grid item md={4} className="pr-24">
                                  <div className="contractPayBox">
                                    <Typography
                                      component={'div'}
                                      sx={{ position: 'relative' }}
                                      className={'d-flex-jr'}>
                                      {leadContractPayment?.contractformid &&
                                      !contractFeesCheck(
                                        leadContractPayment?.contract_question,
                                      ) ? (
                                        <CheckCircleIcon
                                          className={classes.avatoricon}
                                          fontSize="small"
                                          style={{ color: '#21e069' }}
                                        />
                                      ) : (
                                        <ErrorIcon
                                          className={classes.avatoricon}
                                          fontSize="small"
                                          style={{ color: '#ffb535' }}
                                        />
                                      )}
                                    </Typography>

                                    <Typography
                                      component={'div'}
                                      className={'d-flex-ja'.concat(
                                        userDetail.role_id !== 3
                                          ? ' disable-contract'
                                          : '',
                                      )}
                                      sx={{ minHeight: '285px' }}>
                                      <div
                                        style={{
                                          padding: '2rem',
                                          textAlign: 'center',
                                        }}>
                                        <img
                                          style={{
                                            margin: '16px 0',
                                            width: '60px',
                                          }}
                                          src={ContractIcon}
                                          alt="icon"
                                        />
                                        <Typography
                                          className="font-bold f-18 mb-1"
                                          sx={{ minHeight: '41px' }}>
                                          {/* Contract Form */}EDIT
                                        </Typography>
                                        <Typography>
                                          {leadContractPayment?.contractformid
                                            ? 'Your contract has been created. To make a change, select Edit Contract'
                                            : 'Select your contract type, assign fees, and add notes'}
                                        </Typography>
                                      </div>
                                    </Typography>
                                    {!leadData?.isAffilateCase ? (
                                      <Typography
                                        className={'d-flex-ja'.concat(
                                          userDetail.role_id !== 3
                                            ? ' disable-contract'
                                            : '',
                                        )}
                                        style={{
                                          borderTop: '1px solid #ccc',
                                          padding: '1rem .5rem',
                                        }}>
                                        <Button
                                          className="mnone-btn"
                                          style={{
                                            marginRight: '.5rem !important',
                                          }}
                                          onClick={openFillForm}>
                                          {leadContractPayment?.contractformid
                                            ? 'Edit Contract'
                                            : 'Add Contract'}
                                        </Button>
                                      </Typography>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </Grid>
                              ) : null}

                              {/* Attorney Form */}
                              <Grid
                                item
                                md={
                                  userDetail.role_id === 3 ||
                                  (leadData?.isAffilateCase &&
                                    leadData?.affilateRoleId === 3)
                                    ? 4
                                    : 6
                                }
                                className="pr-24">
                                <div className="contractPayBox">
                                  <Typography
                                    component={'div'}
                                    className="d-flex-jr"
                                    sx={{ position: 'relative' }}>
                                    {contractMappings.attorneyDocumnet ? (
                                      <CheckCircleIcon
                                        className={classes.avatoricon}
                                        fontSize="small"
                                        style={{ color: '#21e069' }}
                                      />
                                    ) : (
                                      <ErrorIcon
                                        className={classes.avatoricon}
                                        fontSize="small"
                                        style={{ color: '#ffb535' }}
                                      />
                                    )}
                                  </Typography>

                                  <Typography
                                    component={'div'}
                                    className={'d-flex-ja'.concat(
                                      leadContractPayment?.contractformid &&
                                        Number(
                                          leadContractPayment?.paymentTotal,
                                        ) != 0
                                        ? ''
                                        : contractMappings.attorneyDocumnet
                                        ? ''
                                        : ' disable-contract',
                                    )}
                                    sx={{ minHeight: '285px' }}>
                                    <div
                                      style={{
                                        padding: '2rem',
                                        textAlign: 'center',
                                        ...(userDetail.role_id === 3
                                          ? {}
                                          : { maxWidth: '550px' }),
                                      }}>
                                      {/* <img
                                        className={classes.avatorcss}
                                        src={Avator}
                                        alt="icon"
                                      /> */}
                                      <img
                                        style={{
                                          margin: '16px 0',
                                          width: '60px',
                                        }}
                                        src={ContractIcon}
                                        alt="icon"
                                      />
                                      <Typography
                                        component={'div'}
                                        sx={{ minHeight: '41px' }}>
                                        <Typography
                                          component={'div'}
                                          className="font-bold f-18 mb-1 "
                                          style={{ cursor: 'pointer' }}>
                                          {/* Attorney */}BUILD
                                        </Typography>
                                        <Typography
                                          component={'p'}
                                          sx={{ cursor: 'pointer' }}
                                          className="txt-color"
                                          onClick={() =>
                                            fileDownload(
                                              contractMappings.attorneyDocumnet,
                                            )
                                          }>
                                          {contractMappings.attorneyDocumnet
                                            ? contractMappings.attorneyDocumnet
                                                .split('/')
                                                .pop()
                                            : ''}
                                        </Typography>
                                      </Typography>
                                      <Typography>
                                        {userDetail.role_id === 3 ||
                                        (leadData?.isAffilateCase &&
                                          leadData?.affilateRoleId === 3)
                                          ? contractMappings.attorneyDocumnet
                                            ? 'Contract build is complete. Build a new contract after any changes are made in order to finalize your edits'
                                            : leadContractPayment?.contractformid
                                            ? 'Click “Build Contract” to finalize and download your contract'
                                            : 'Click “Build Contract” to finalize and download your contract'
                                          : contractMappings.attorneyDocumnet
                                          ? 'Contract build is complete.'
                                          : 'Contract not yet to build.If you need the contract, Please contact you attorney.'}
                                      </Typography>
                                    </div>
                                  </Typography>
                                  {userDetail.role_id === 3 &&
                                    !leadData?.isAffilateCase && (
                                      <Typography
                                        className={'d-flex-ja'.concat(
                                          leadContractPayment?.contractformid &&
                                            Number(
                                              leadContractPayment?.paymentTotal,
                                            ) != 0
                                            ? ''
                                            : contractMappings.attorneyDocumnet
                                            ? ''
                                            : ' disable-contract',
                                        )}
                                        style={{
                                          borderTop: '1px solid #ccc',
                                          padding: '1rem .5rem',
                                        }}>
                                        <Button
                                          className="mnone-btn"
                                          style={{
                                            marginRight: '.5rem !important',
                                          }}
                                          onClick={build}
                                          disabled={
                                            waitingForRes ||
                                            contractType.toLowerCase() ===
                                              'affilate' ||
                                            !leadContractPayment?.contractformid
                                          }>
                                          Build Contract
                                        </Button>
                                      </Typography>
                                    )}
                                </div>
                              </Grid>

                              <Grid
                                item
                                md={
                                  userDetail.role_id === 3 ||
                                  (leadData?.isAffilateCase &&
                                    leadData?.affilateRoleId === 3)
                                    ? 4
                                    : 6
                                }
                                className="pr-24">
                                <div className="contractPayBox">
                                  <Typography
                                    component={'div'}
                                    className="d-flex-jr"
                                    sx={{ position: 'relative' }}>
                                    {contractType.toLowerCase() ===
                                      'petitioner' &&
                                    contractMappings.petitionerDocument &&
                                    contractMappings.status === 'approved' ? (
                                      <CheckCircleIcon
                                        className={classes.avatoricon}
                                        fontSize="small"
                                        style={{ color: '#21e069' }}
                                      />
                                    ) : contractType.toLowerCase() ===
                                        'benificiery' &&
                                      contractMappings.beneficiaryDocument &&
                                      contractMappings.status === 'approved' ? (
                                      <CheckCircleIcon
                                        className={classes.avatoricon}
                                        fontSize="small"
                                        style={{ color: '#21e069' }}
                                      />
                                    ) : (
                                      <ErrorIcon
                                        className={classes.avatoricon}
                                        fontSize="small"
                                        style={{ color: '#ffb535' }}
                                      />
                                    )}
                                  </Typography>
                                  {/* Petitioner Form */}
                                  {contractType.toLowerCase() ===
                                    'petitioner' && (
                                    <>
                                      <Typography
                                        component={'div'}
                                        className={'d-flex-ja '.concat(
                                          contractMappings.petitionerDocument ||
                                            (contractMappings.attorneyDocumnet &&
                                              contractMappings.latestPaymentLink &&
                                              contractMappings?.status !==
                                                'Built')
                                            ? ''
                                            : ' disable-contract',
                                        )}
                                        style={{ minHeight: '285px' }}>
                                        <div
                                          style={{
                                            padding: '2rem',
                                            textAlign: 'center',
                                            width: '100% ',
                                            wordWrap: 'break-word',
                                            ...(userDetail.role_id === 3
                                              ? {}
                                              : { maxWidth: '550px' }),
                                          }}>
                                          {/* <img
                                            className={classes.avatorcss}
                                            src={Avator}
                                            alt="icon"
                                          /> */}
                                          <img
                                            style={{
                                              margin: '16px 0',
                                              width: '60px',
                                            }}
                                            src={ContractIcon}
                                            alt="icon"
                                          />
                                          <Typography
                                            component={'div'}
                                            sx={{ minHeight: '41px' }}>
                                            <Typography
                                              component={'div'}
                                              className="font-bold f-18 mb-1"
                                              style={{ cursor: 'pointer' }}>
                                              FINALIZE
                                            </Typography>
                                            <Typography
                                              component={'p'}
                                              className="txt-color"
                                              onClick={() =>
                                                fileDownload(
                                                  contractMappings.petitionerDocument,
                                                )
                                              }>
                                              {contractMappings.petitionerDocument
                                                ? contractMappings.petitionerDocument
                                                    .split('/')
                                                    .pop()
                                                : ''}
                                            </Typography>
                                          </Typography>
                                          <Typography>
                                            <>
                                              {userDetail.role_id === 2 ||
                                              (leadData?.isAffilateCase &&
                                                leadData?.affilateRoleId === 2)
                                                ? contractMappings.petitionerDocument
                                                  ? contractMappings?.status ===
                                                    'signed'
                                                    ? 'you have signed the contract. contract have been moved to approval for attorney'
                                                    : contractMappings?.status ===
                                                      'approved'
                                                    ? 'Contract has been approved.'
                                                    : 'Contract has been approved.'
                                                  : contractMappings?.status ===
                                                    'sent'
                                                  ? 'Contract has been waiting for you, Please  sign the contract'
                                                  : `${
                                                      contractMappings.attorneyDocumnet
                                                        ? 'Contract build is complete. please contact your attorney'
                                                        : 'Contract not yet to build.If you need the contract, Please contact you attorney.'
                                                    } `
                                                : ''}
                                              {userDetail.role_id === 3 ||
                                              (leadData?.isAffilateCase &&
                                                leadData?.affilateRoleId === 3)
                                                ? contractMappings.petitionerDocument
                                                  ? contractMappings?.status ===
                                                    'signed'
                                                    ? 'The contract has been signed by the client and is ready for your review. Select “Approve” to finalize the contract.'
                                                    : contractMappings?.status ===
                                                      'approved'
                                                    ? 'The contract has been executed and acknowledged by both parties.'
                                                    : contractMappings?.status ===
                                                      'sent'
                                                    ? 'Once the contract is signed by the client, it will upload here for your approval.'
                                                    : ''
                                                  : contractMappings?.status ===
                                                    'Built'
                                                  ? 'Once the contract is signed by the client, it will upload here for your approval.'
                                                  : contractMappings?.status ===
                                                    'Not Required'
                                                  ? 'Once the contract is signed by the client, it will upload here for your approval.'
                                                  : contractMappings?.status ===
                                                    'sent'
                                                  ? 'Once the contract is signed by the client, it will upload here for your approval.'
                                                  : ''
                                                : ''}
                                            </>
                                          </Typography>
                                        </div>
                                      </Typography>
                                      {userDetail.role_id === 3 &&
                                        !leadData?.isAffilateCase && (
                                          <Typography
                                            className={'d-flex-ja '.concat(
                                              contractMappings.petitionerDocument ||
                                                (contractMappings.attorneyDocumnet &&
                                                  contractMappings.latestPaymentLink &&
                                                  contractMappings?.status !==
                                                    'Built')
                                                ? ''
                                                : ' disable-contract',
                                            )}
                                            style={{
                                              borderTop: '1px solid #ccc',
                                              padding: '1rem .5rem',
                                            }}>
                                            {contractMappings.status ===
                                            'approved' ? (
                                              <Button
                                                className="mnone-btn"
                                                sx={{
                                                  marginRight:
                                                    '.5rem !important',
                                                }}
                                                disabled={true}>
                                                Approved
                                              </Button>
                                            ) : contractMappings.status ===
                                              'signed' ? (
                                              <>
                                                <Button
                                                  className="mnone-btn"
                                                  sx={{
                                                    marginRight:
                                                      '.5rem !important',
                                                  }}
                                                  onClick={
                                                    finalContractApproved
                                                  }
                                                  disabled={
                                                    contractMappings.status !==
                                                      'signed' ||
                                                    waitingForRes ||
                                                    !contractMappings.attorneyDocumnet
                                                  }>
                                                  Approve
                                                </Button>
                                                <Button
                                                  className="mnone-btn"
                                                  sx={{
                                                    marginRight:
                                                      '.5rem !important',
                                                  }}
                                                  onClick={() =>
                                                    contractMappings.attorneyDocumnet &&
                                                    petitionerFileInput.current?.click()
                                                  }>
                                                  {contractMappings.petitionerDocument
                                                    ? 'Re Upload'
                                                    : 'Upload Now'}

                                                  <input
                                                    type="file"
                                                    hidden
                                                    ref={petitionerFileInput}
                                                    onChange={(e) =>
                                                      uploadContractFile(
                                                        e.target.files,
                                                      )
                                                    }
                                                    accept={mimeTypes}
                                                  />
                                                </Button>
                                              </>
                                            ) : (
                                              <Button
                                                className="mnone-btn"
                                                sx={{
                                                  marginRight:
                                                    '.5rem !important',
                                                }}
                                                onClick={() =>
                                                  contractMappings.attorneyDocumnet &&
                                                  petitionerFileInput.current?.click()
                                                }
                                                disabled={
                                                  contractMappings.status ===
                                                    'approved' ||
                                                  waitingForRes ||
                                                  !contractMappings.attorneyDocumnet
                                                }>
                                                {contractMappings.petitionerDocument
                                                  ? 'Re Upload'
                                                  : 'Upload Now'}

                                                <input
                                                  type="file"
                                                  hidden
                                                  ref={petitionerFileInput}
                                                  onChange={(e) =>
                                                    uploadContractFile(
                                                      e.target.files,
                                                    )
                                                  }
                                                  accept={mimeTypes}
                                                />
                                              </Button>
                                            )}
                                          </Typography>
                                        )}
                                      {userDetail.role_id === 2 &&
                                      contractMappings.latestPaymentLink ? (
                                        <Typography
                                          className={'d-flex-ja '.concat(
                                            contractMappings.petitionerDocument ||
                                              (contractMappings.attorneyDocumnet &&
                                                contractMappings.latestPaymentLink &&
                                                contractMappings?.status !==
                                                  'Built')
                                              ? ''
                                              : ' disable-contract',
                                          )}
                                          style={{
                                            borderTop: '1px solid #ccc',
                                            padding: '1rem .5rem',
                                          }}>
                                          <Button
                                            className="mnone-btn"
                                            sx={{
                                              marginRight: '.5rem !important',
                                            }}
                                            onClick={() =>
                                              window.open(
                                                contractMappings.latestPaymentLink,
                                              )
                                            }>
                                            View
                                          </Button>
                                        </Typography>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  )}

                                  {/* Beneficiary Form */}
                                  {contractType.toLowerCase() ===
                                    'benificiery' && (
                                    <>
                                      <Typography
                                        component={'div'}
                                        className={'d-flex-ja '.concat(
                                          contractMappings.beneficiaryDocument ||
                                            (contractMappings.attorneyDocumnet &&
                                              contractMappings.latestPaymentLink &&
                                              contractMappings?.status !==
                                                'Built')
                                            ? ''
                                            : ' disable-contract',
                                        )}
                                        style={{
                                          minHeight: '285px',
                                        }}>
                                        <div
                                          style={{
                                            padding: '2rem',
                                            textAlign: 'center',
                                            width: '100% ',
                                            wordWrap: 'break-word',
                                            ...(userDetail.role_id === 3
                                              ? {}
                                              : { maxWidth: '550px' }),
                                          }}>
                                          <img
                                            style={{
                                              margin: '16px 0',
                                              width: '60px',
                                            }}
                                            src={ContractIcon}
                                            alt="icon"
                                          />
                                          <Typography
                                            component={'div'}
                                            sx={{ minHeight: '50px' }}>
                                            <Typography
                                              component={'div'}
                                              className="font-bold f-18 mb-1"
                                              style={{ cursor: 'pointer' }}>
                                              {/* Beneficiary */}FINALIZE
                                            </Typography>
                                            <Typography
                                              component={'p'}
                                              sx={{
                                                cursor: 'pointer',
                                              }}
                                              className="txt-color"
                                              onClick={() =>
                                                fileDownload(
                                                  contractMappings.beneficiaryDocument,
                                                )
                                              }>
                                              {contractMappings.beneficiaryDocument
                                                ? contractMappings.beneficiaryDocument
                                                    .split('/')
                                                    .pop()
                                                : ''}
                                            </Typography>
                                          </Typography>
                                          <Typography>
                                            <>
                                              {userDetail.role_id === 2 ||
                                              (leadData?.isAffilateCase &&
                                                leadData?.affilateRoleId === 2)
                                                ? contractMappings.beneficiaryDocument
                                                  ? contractMappings?.status ===
                                                    'signed'
                                                    ? 'Beneficiary have signed the contract. contract have been moved to approval for attorney'
                                                    : contractMappings?.status ===
                                                      'approved'
                                                    ? 'Contract has been approved.'
                                                    : contractMappings?.status ===
                                                      'sent'
                                                    ? 'Contract has been waiting for Beneficiary.'
                                                    : ''
                                                  : contractMappings?.status ===
                                                    'sent'
                                                  ? 'Contract has been waiting for Beneficiary.'
                                                  : `${
                                                      contractMappings.attorneyDocumnet
                                                        ? 'Contract build is complete. please contact your attorney'
                                                        : 'Contract not yet to build.If you need the contract, Please contact you attorney.'
                                                    } `
                                                : ''}
                                              {userDetail.role_id === 3 ||
                                              (leadData?.isAffilateCase &&
                                                leadData?.affilateRoleId === 3)
                                                ? contractMappings.beneficiaryDocument
                                                  ? contractMappings?.status ===
                                                    'signed'
                                                    ? 'The contract has been signed by the client and is ready for your review. Select “Approve” to finalize the contract.'
                                                    : contractMappings?.status ===
                                                      'approved'
                                                    ? 'The contract has been executed and acknowledged by both parties.'
                                                    : contractMappings?.status ===
                                                      'sent'
                                                    ? 'Once the contract is signed by the client, it will upload here for your approval.'
                                                    : ''
                                                  : contractMappings?.status ===
                                                    'Built'
                                                  ? 'Once the contract is signed by the client, it will upload here for your approval.'
                                                  : contractMappings?.status ===
                                                    'Not Required'
                                                  ? 'Once the contract is signed by the client, it will upload here for your approval.'
                                                  : contractMappings?.status ===
                                                    'sent'
                                                  ? 'Once the contract is signed by the client, it will upload here for your approval.'
                                                  : ''
                                                : ''}
                                            </>
                                          </Typography>
                                        </div>
                                      </Typography>
                                      {userDetail.role_id === 3 &&
                                        !leadData?.isAffilateCase && (
                                          <Typography
                                            className={'d-flex-ja '.concat(
                                              contractMappings.beneficiaryDocument ||
                                                (contractMappings.attorneyDocumnet &&
                                                  contractMappings.latestPaymentLink &&
                                                  contractMappings?.status !==
                                                    'Built')
                                                ? ''
                                                : ' disable-contract',
                                            )}
                                            style={{
                                              borderTop: '1px solid #ccc',
                                              padding: '1rem .5rem',
                                            }}>
                                            {contractMappings.status ===
                                            'approved' ? (
                                              <Button
                                                className="mnone-btn"
                                                sx={{
                                                  marginRight:
                                                    '.5rem !important',
                                                }}
                                                disabled={true}>
                                                Approved
                                              </Button>
                                            ) : contractMappings.status ===
                                              'signed' ? (
                                              <>
                                                <Button
                                                  className="mnone-btn"
                                                  sx={{
                                                    marginRight:
                                                      '.5rem !important',
                                                  }}
                                                  onClick={
                                                    finalContractApproved
                                                  }
                                                  disabled={
                                                    contractMappings.status !==
                                                      'signed' ||
                                                    waitingForRes ||
                                                    !contractMappings.attorneyDocumnet
                                                  }>
                                                  Approve
                                                </Button>{' '}
                                                <Button
                                                  className="mnone-btn"
                                                  sx={{
                                                    marginRight:
                                                      '.5rem !important',
                                                  }}
                                                  onClick={() =>
                                                    !contractMappings.attorneyDocumnet
                                                      ? window.open(
                                                          contractMappings.latestPaymentLink,
                                                        )
                                                      : beneficiaryFileInput.current?.click()
                                                  }
                                                  disabled={
                                                    contractMappings.status ===
                                                      'approved' ||
                                                    waitingForRes
                                                  }>
                                                  Re Upload
                                                  <input
                                                    type="file"
                                                    hidden
                                                    ref={beneficiaryFileInput}
                                                    onChange={(e) =>
                                                      uploadContractFile(
                                                        e.target.files,
                                                      )
                                                    }
                                                    accept={mimeTypes}
                                                  />
                                                </Button>
                                              </>
                                            ) : (
                                              <Button
                                                className="mnone-btn"
                                                sx={{
                                                  marginRight:
                                                    '.5rem !important',
                                                }}
                                                onClick={() =>
                                                  !contractMappings.attorneyDocumnet
                                                    ? window.open(
                                                        contractMappings.latestPaymentLink,
                                                      )
                                                    : beneficiaryFileInput.current?.click()
                                                }
                                                disabled={
                                                  contractMappings.status ===
                                                    'approved' || waitingForRes
                                                }>
                                                Upload Now
                                                <input
                                                  type="file"
                                                  hidden
                                                  ref={beneficiaryFileInput}
                                                  onChange={(e) =>
                                                    uploadContractFile(
                                                      e.target.files,
                                                    )
                                                  }
                                                  accept={mimeTypes}
                                                />
                                              </Button>
                                            )}
                                          </Typography>
                                        )}
                                    </>
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          )}
                          {userDetail.role_id === 3 ||
                          (leadData?.isAffilateCase &&
                            leadData?.affilateRoleId === 3) ? (
                            <div className="pr-24">
                              <Box
                                component="form"
                                className="u-add-in mb-2"
                                sx={{
                                  border: 'unset',
                                  borderRadius: '12px !important',
                                  marginTop: '1.5rem',
                                }}
                                noValidate
                                autoComplete="off">
                                <Grid
                                  container
                                  columnSpacing={{ md: 3, sm: 2 }}>
                                  <Grid item md={6} sm={12}>
                                    <Typography
                                      component={'div'}
                                      className="custom-field d-flex-a">
                                      <Typography
                                        component={'div'}
                                        className="custom-label d-flex-1">
                                        Does this case require payment?
                                      </Typography>
                                      <Controller
                                        control={control}
                                        defaultValue={'no'}
                                        name={'paymentChecked'}
                                        render={({
                                          field: { onChange, value, name, ref },
                                          formState,
                                        }) => {
                                          return (
                                            <RadioGroup
                                              className="ml-2"
                                              row
                                              aria-labelledby="demo-row-radio-buttons-group-label"
                                              name={name}
                                              value={value}
                                              onChange={(e) => {
                                                paymentAmountCheck(
                                                  e.target.value,
                                                );
                                                onChange(e);
                                              }}>
                                              <FormControlLabel
                                                value="no"
                                                control={
                                                  <Radio
                                                    disabled={
                                                      !disabledRequiredPayments
                                                    }
                                                  />
                                                }
                                                label="No"
                                              />
                                              <FormControlLabel
                                                value="yes"
                                                control={
                                                  <Radio
                                                    disabled={
                                                      !disabledRequiredPayments
                                                    }
                                                  />
                                                }
                                                label="Yes"
                                              />
                                            </RadioGroup>
                                          );
                                        }}
                                      />
                                      {!disabledRequiredPayments &&
                                        !leadData?.isAffilateCase && (
                                          <Typography
                                            className="pb-1"
                                            component={'a'}
                                            sx={{
                                              cursor: 'pointer',
                                              marginTop: '6px !important',
                                            }}
                                            onClick={(e) => {
                                              setIsShowPGAlert(true);
                                            }}>
                                            <InfoRoundedIcon
                                              style={{
                                                cursor: 'pointer',
                                                fill: 'rgb(14 106 234)',
                                                marginBottom: '-7px',
                                                marginLeft: '5px',
                                              }}
                                            />{' '}
                                            <span
                                              className="f-15 font-bold"
                                              style={{
                                                textDecoration: 'underline',
                                              }}>
                                              Why this disabled?
                                            </span>
                                          </Typography>
                                        )}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6} sm={12} />
                                  {watch('paymentChecked') === 'yes' ||
                                  contractMappings.attorneyDocumnet ? (
                                    <Grid item md={6} sm={12}>
                                      <Typography
                                        component={'div'}
                                        className="custom-field">
                                        <Typography
                                          component={'div'}
                                          className="custom-label ">
                                          Email{' '}
                                          <Typography
                                            component={'span'}
                                            className="Mui-error">
                                            *
                                          </Typography>
                                        </Typography>
                                        <Controller
                                          control={control}
                                          defaultValue=""
                                          name="mailId"
                                          render={({
                                            field: {
                                              onChange,
                                              value,
                                              name,
                                              ref,
                                            },
                                            formState,
                                          }) => {
                                            return (
                                              <CustomInput
                                                placeHolder={''}
                                                name={name}
                                                value={value}
                                                type={'email'}
                                                className={'custom-input'}
                                                error={errors.mailId?.message}
                                                onChange={(val) =>
                                                  onChange(val)
                                                }
                                              />
                                            );
                                          }}
                                        />
                                      </Typography>
                                    </Grid>
                                  ) : null}
                                  {watch('paymentChecked') === 'yes' && (
                                    <Grid item md={6} sm={12}>
                                      <Typography
                                        component={'div'}
                                        className="custom-field">
                                        <Typography
                                          component={'div'}
                                          className="custom-label ">
                                          Payment Amount{' '}
                                          <span className="required">*</span>
                                        </Typography>
                                        <Controller
                                          control={control}
                                          defaultValue=""
                                          name="paymentAmount"
                                          render={({
                                            field: {
                                              onChange,
                                              value,
                                              name,
                                              ref,
                                            },
                                            formState,
                                          }) => {
                                            return (
                                              <CustomInput
                                                placeHolder={''}
                                                name={name}
                                                value={value}
                                                type={'number'}
                                                className={'custom-input'}
                                                error={
                                                  errors.paymentAmount?.message
                                                }
                                                onChange={(val) => {
                                                  const vals =
                                                    val.target.value.replace(
                                                      /^0/,
                                                      '',
                                                    );
                                                  return onChange(vals);
                                                }}
                                              />
                                            );
                                          }}
                                        />
                                      </Typography>
                                    </Grid>
                                  )}
                                  <Grid item md={6} sm={12}>
                                    <Typography
                                      component={'div'}
                                      className="custom-field">
                                      <Typography
                                        component={'div'}
                                        className="custom-label ">
                                        Invoice Purpose
                                      </Typography>
                                      <Controller
                                        control={control}
                                        defaultValue=""
                                        name="paymentPurpose"
                                        render={({
                                          field: { onChange, value, name, ref },
                                          formState,
                                        }) => {
                                          return (
                                            <CustomSelect
                                              placeHolder={'Invoice purpose'}
                                              name={name}
                                              value={value}
                                              valueKey={'id'}
                                              labelKey={'name'}
                                              options={paymentPurposeOptions}
                                              className={'custom-textarea-note'}
                                              error={
                                                errors.paymentPurpose
                                                  ?.message || ''
                                              }
                                              disabled={
                                                leadData?.isAffilateCase
                                              }
                                              onChange={(e) => {
                                                onChange(e);
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    </Typography>
                                  </Grid>
                                  {String(watch('paymentPurpose')) === '6' ? (
                                    <Grid item md={6} sm={12}>
                                      <Typography
                                        component={'div'}
                                        className="custom-field">
                                        <Typography
                                          component={'div'}
                                          className="custom-label ">
                                          Description
                                          <span className="required">*</span>
                                        </Typography>
                                        <Controller
                                          control={control}
                                          defaultValue=""
                                          name="description"
                                          render={({
                                            field: {
                                              onChange,
                                              value,
                                              name,
                                              ref,
                                            },
                                            formState,
                                          }) => {
                                            return (
                                              <CustomInput
                                                placeHolder={'Description'}
                                                name={name}
                                                value={value}
                                                className={
                                                  'custom-textarea-note'
                                                }
                                                error={
                                                  errors.description?.message ||
                                                  ''
                                                }
                                                isTextArea={true}
                                                onChange={(e) => {
                                                  onChange(e);
                                                }}
                                              />
                                            );
                                          }}
                                        />
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    ''
                                  )}
                                </Grid>
                                {userDetail.role_id === 3 &&
                                  tabValue === '1' &&
                                  !leadData?.isAffilateCase && (
                                    <Grid item md={12}>
                                      <Typography
                                        component={'div'}
                                        className="d-flex-jae">
                                        <Button
                                          className="add-btn"
                                          type="submit"
                                          form={'approve-contract'}>
                                          {contractMappings.personEmailId
                                            ? 'Send'
                                            : 'Initiate'}
                                        </Button>
                                      </Typography>
                                    </Grid>
                                  )}
                              </Box>
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
                {![2, 5].includes(userDetail.role_id) ||
                (leadData?.isAffilateCase && leadData?.affilateRoleId !== 2)
                  ? !_.isEmpty(leadContractPayment) &&
                    leadContractPayment.paymentDetails &&
                    leadContractPayment.paymentDetails.length > 0 &&
                    leadContractPayment.paymentDetails.map(
                      (detail, index) =>
                        detail.paymentId && (
                          <Grid container key={index}>
                            <Grid item md={12} className="pr-24">
                              <Box
                                component="form"
                                className="u-add-in mb-2"
                                sx={{
                                  border: 'unset !important',
                                  borderRadius: '12px !important',
                                }}
                                noValidate
                                autoComplete="off">
                                <Grid
                                  container
                                  columnSpacing={{ md: 3, sm: 2 }}>
                                  <Grid item md={12} sm={12}>
                                    <Typography
                                      component={'div'}
                                      className="custom-field d-flex-a d-flex-sb">
                                      <Typography
                                        component={'div'}
                                        className=" f-18 font-bold ">
                                        Payment Link
                                      </Typography>
                                      {!leadData?.isAffilateCase && (
                                        <Tooltip
                                          title={'Remove'}
                                          placement="top">
                                          <DeleteForeverIcon
                                            sx={{
                                              cursor: 'pointer',
                                              fill: 'red',
                                              height: '25px',
                                              width: '25px',
                                            }}
                                            onClick={() =>
                                              setConfirmOpen({
                                                content:
                                                  'Are you sure do you want to delete?',
                                                id: detail?.paymentId,
                                                key: 'delete',
                                                show: true,
                                              })
                                            }
                                          />
                                        </Tooltip>
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6} sm={12}>
                                    <Typography
                                      component={'div'}
                                      className="custom-field">
                                      <Typography
                                        component={'div'}
                                        className="custom-label ">
                                        Request Date
                                      </Typography>
                                      <CustomInput
                                        placeHolder={''}
                                        value={moment(
                                          new Date(detail?.requestDate),
                                        ).format('MMMM DD, YYYY')}
                                        className={'custom-input'}
                                        disabled
                                      />
                                    </Typography>
                                    <Typography
                                      component={'div'}
                                      className="custom-field">
                                      <Typography
                                        component={'div'}
                                        className="custom-label ">
                                        Status
                                      </Typography>
                                      <CustomInput
                                        placeHolder={''}
                                        value={detail?.paymentStatus}
                                        className={'custom-input'}
                                        disabled
                                      />
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6} sm={12}>
                                    <Typography
                                      component={'div'}
                                      className="custom-field">
                                      <Typography
                                        component={'div'}
                                        className="custom-label ">
                                        Pay Amount
                                      </Typography>
                                      <CustomInput
                                        placeHolder={''}
                                        value={
                                          detail?.paymentTotal !== ''
                                            ? parseFloat(
                                                detail.paymentTotal,
                                              ).toFixed(2)
                                            : ''
                                        }
                                        className={'custom-input'}
                                        disabled
                                      />
                                    </Typography>
                                    <Typography
                                      component={'div'}
                                      className="custom-field">
                                      <Typography
                                        component={'div'}
                                        className="custom-label ">
                                        Transaction ID
                                      </Typography>
                                      <CustomInput
                                        placeHolder={''}
                                        value={detail?.transactionId}
                                        className={'custom-input'}
                                        disabled
                                      />
                                    </Typography>
                                  </Grid>
                                  <Grid item md={11} sm={11}>
                                    <Typography
                                      component={'div'}
                                      className="custom-field">
                                      <Typography
                                        component={'div'}
                                        className="custom-label ">
                                        Link
                                      </Typography>
                                      <CustomInput
                                        placeHolder={''}
                                        value={detail?.paymentLink}
                                        className={'custom-input'}
                                        disabled
                                      />
                                    </Typography>
                                  </Grid>
                                  <Grid item md={1} sm={1} className="d-flex-a">
                                    <Typography
                                      component={'div'}
                                      className="custom-field">
                                      <Tooltip title={'Copy'} placement="top">
                                        <ContentCopyIcon
                                          sx={{
                                            cursor: 'pointer',
                                            fill: 'gray',
                                            height: '25px',
                                            marginTop: '1.3rem',
                                            width: '25px',
                                          }}
                                          onClick={(e) =>
                                            paymentLink(detail?.paymentLink)
                                          }
                                        />
                                      </Tooltip>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        ),
                    )
                  : null}
                {![2, 5].includes(userDetail.role_id) ||
                (leadData?.isAffilateCase && leadData?.affilateRoleId !== 2)
                  ? contractMappings.latestPaymentLink &&
                    (_.isEmpty(contractHistory) ||
                      (!_.isEmpty(contractHistory[0]) &&
                        contractHistory[0]?.response_code)) && (
                      <Grid container>
                        <Grid item md={12} className="pr-24">
                          <Box
                            component="form"
                            className="u-add-in mb-2"
                            sx={{
                              border: 'unset !important',
                              borderRadius: '12px !important',
                            }}
                            noValidate
                            autoComplete="off">
                            <Grid container columnSpacing={{ md: 3, sm: 2 }}>
                              <Grid item md={11} sm={11}>
                                <Typography
                                  component={'div'}
                                  className="custom-field">
                                  <Typography
                                    component={'div'}
                                    className="custom-label">
                                    Link
                                  </Typography>
                                  <CustomInput
                                    placeHolder={''}
                                    value={contractMappings.latestPaymentLink}
                                    className={'custom-input'}
                                    disabled
                                  />
                                </Typography>
                              </Grid>
                              {contractMappings.attorneyDocumnet && (
                                <Grid item md={1} sm={1} className="d-flex-a">
                                  <Typography
                                    component={'div'}
                                    className="custom-field">
                                    <Tooltip title={'Copy'} placement="top">
                                      <ContentCopyIcon
                                        sx={{
                                          cursor: 'pointer',
                                          fill: 'gray',
                                          height: '25px',
                                          marginTop: '1.3rem',
                                          width: '25px',
                                        }}
                                        onClick={(e) =>
                                          paymentLink(
                                            contractMappings.latestPaymentLink,
                                          )
                                        }
                                      />
                                    </Tooltip>
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    )
                  : null}
              </TabPanel>
              {![2, 5].includes(userDetail.role_id) ||
              (leadData?.isAffilateCase && leadData?.affilateRoleId !== 2) ? (
                <TabPanel value="2">
                  <Typography component={'div'} className="ph-box2 pr-24">
                    {!_.isEmpty(contractHistory) &&
                    contractHistory.length > 0 ? (
                      contractHistory.map((pay, index) => (
                        <Typography
                          component={'div'}
                          className="ph-item d-flex-a"
                          key={index}>
                          <Grid container columnSpacing={{ md: 3, sm: 2 }}>
                            <Grid item md={3} className="d-flex-a">
                              <Typography className="f-15 font-bold ">
                                $ {parseFloat(pay.payment_total).toFixed(2)}
                              </Typography>
                            </Grid>
                            <Grid item md={9}>
                              <Grid container columnSpacing={{ md: 3, sm: 2 }}>
                                <Grid item md={4}>
                                  <Typography className="ablue ">
                                    Request Date
                                  </Typography>
                                </Grid>
                                <Grid item md={8}>
                                  {moment(pay?.created_at).format(
                                    'MMMM DD, YYYY',
                                  )}
                                </Grid>
                                {pay?.response_code === '00' ? (
                                  <Grid item md={4}>
                                    <Typography className="ablue ">
                                      Payment Date
                                    </Typography>
                                  </Grid>
                                ) : (
                                  ''
                                )}
                                {pay?.response_code === '00' ? (
                                  <Grid item md={8}>
                                    {moment(pay?.updated_at).format(
                                      'MMMM DD, YYYY',
                                    )}
                                  </Grid>
                                ) : (
                                  ''
                                )}
                                <Grid item md={4}>
                                  <Typography className="ablue">
                                    Status
                                  </Typography>
                                </Grid>
                                <Grid item md={8}>
                                  {pay?.response_message === 'APPROVAL'
                                    ? 'Success'
                                    : pay?.response_message}
                                  {/* {pay.response_code === '00'
                                    ? 'Success'
                                    : !pay.response_code
                                    ? 'Pending'
                                    : 'Failed'} */}
                                </Grid>

                                <Grid item md={4}>
                                  <Typography className="ablue ">
                                    Transaction ID
                                  </Typography>
                                </Grid>
                                <Grid item md={8}>
                                  <Typography
                                    className="blue-text pointer"
                                    sx={{ fontSize: '15px !important' }}
                                    onClick={() =>
                                      !loader && pay.response_code === '00'
                                        ? fileDownloadTransaztion(pay)
                                        : false
                                    }>
                                    {!_.isEmpty(pay.transaction_id) &&
                                    pay.response_code === '00'
                                      ? pay.transaction_id
                                      : '-'}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      ))
                    ) : (
                      <Typography
                        component={'div'}
                        className="ph-item d-flex-a">
                        <Grid container>
                          <Grid item md={12} className="d-flex-ja">
                            <Typography className="f-15 font-bold">
                              No Record(s) Found.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    )}
                  </Typography>
                </TabPanel>
              ) : null}
            </Typography>
          </TabContext>
        </Box>
      </Grid>

      {contractPaymentModel.open && (
        <CustomModalView
          open={true}
          fullWidth={true}
          maxWidth={'md'}
          scroll={'paper'}
          autoCloseDisable={true}
          isFilters={false}
          title="Preview"
          close={() =>
            setContractPaymentModel((pre) => ({
              ...pre,
              open: false,
            }))
          }>
          <PreviousContractsListing leadContractPayment={leadContractPayment} />
        </CustomModalView>
      )}
      <ConfirmDialog
        title={confirmOpen.key === 'approve' ? 'Confirm' : 'Delete'}
        floatingBtnShow={true}
        open={confirmOpen.show}
        onConfirm={(act) =>
          act === 'yes'
            ? confirmOpen.key === 'approve'
              ? onApprove(act)
              : deletePayment(act)
            : setConfirmOpen({ content: '', id: '', key: '', show: false })
        }
        autoCloseDisable={true}>
        {confirmOpen.content}
      </ConfirmDialog>
      <Modal
        open={isShowPGAlert}
        // onClose={() => setIsShowPGAlert(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="success-modal custom-pay-modal">
          <Typography className="f-18 s-title info-message-text text-center">
            {
              "Your firm has not provided Online Payment Gateway information. Please enable payments in your Firm's settings."
            }
          </Typography>
          <Typography component={'div'} className="d-flex-ja mt-2 ">
            <Button
              className="n-add-btn"
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '0.9em !important',
                height: '35px !important',
                width: '100px !important',
              }}
              onClick={() => setIsShowPGAlert(false)}
              autoFocus>
              Ok
            </Button>
          </Typography>
        </Box>
      </Modal>
    </Grid>
  );
};

export default ContractPaymentDetails;
