import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormHelperText, Typography } from '@mui/material';
import UploadIcon from 'assets/images/clupload.svg';
import DeleteIcon from 'assets/images/udelete.svg';
import PdfIcon from 'assets/images/updf.svg';
import Closeicon from 'assets/images/wclose.svg';
import CustomInput from 'components/CustomInput/customInput';
import Footer from 'components/Footer';
import { AttorneyPrivateRoutes } from 'config';
import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData } from 'services/CommonServices';
// import { useAppSelector } from 'store/hooks';
import { AddRfeRespondStep1 } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions, mimeTypes } from 'utils/helper';
import { rfeRespondStep1 } from 'utils/ValidatorSchema';

export const RfeUpload = () => {
  const { petitionId }: any = useParams();
  // const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    watch,
    setValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
  } = useForm<AddRfeRespondStep1>({
    criteriaMode: 'all',
    defaultValues: {
      file: '',
      notes: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(rfeRespondStep1),
  });
  const navigate = useNavigate();
  const [file, setFile] = React.useState<any>(null);
  const [loader, setLoader] = React.useState<any>(false);
  const [submitted] = React.useState<any>(false);
  const uploadRef = React.useRef<any>(null);

  const checkFileValidation = (file) => {
    setValue('file', file.name);
    const files = file.name.split('.').pop();
    if (['pdf'].includes(files)) {
      if (file.size / 1024 / 1024 < 90) {
        setFile(file);
      } else {
        uploadRef.current.value = '';
        toast.error(
          'File is too big, please select a file less than 90 MB.',
          alertOptions,
        );
        return;
      }
    } else {
      toast.error('You can upload documents type of PDF only', alertOptions);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    checkFileValidation(acceptedFiles[0]);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);

  const rfeResondSubmit = (data: any) => {
    if (file) {
      setLoader(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('notes', data.notes);
      formData.append('petitionId', petitionId);

      addNewData(formData, COMMON_ENDPOINT.rfeResponder)
        .then((res) => {
          if (res && res.id) {
            resetForm();
            setLoader(false);
            navigate(
              `${String(
                AttorneyPrivateRoutes.RFERESPOEND.RFERESPONDER,
              )}/${String(petitionId)}/${String(res.id)}`,
              {
                state: { parse: true, rfeResponse: res },
              },
            );
          } else {
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err.message, alertOptions);
        });
    }
  };

  return (
    <Typography component={'div'}>
      {loader ? <div className="liberty-loader" /> : ''}
      <Typography component={'div'} className="submis-head">
        <Typography component={'div'} className="s1-title">
          RFE Responder / Step 1 of 3
        </Typography>
        <Typography component={'div'} className="d-flex-a">
          <img
            src={Closeicon}
            alt="Close"
            className="pointer"
            onClick={() => navigate(-1)}
          />
        </Typography>
      </Typography>
      <Typography
        component={'div'}
        className="upload-body"
        sx={{ backgroundColor: ' #F6F6F6' }}>
        <Typography component={'div'} className="center-upload mb-2">
          <Typography className="f-18-1">
            Upload your attachment <span className="required">*</span>
          </Typography>
          {watch(submitted) && !file ? (
            <FormHelperText style={{ color: '#FF0000' }}>
              {errors?.file?.message}
            </FormHelperText>
          ) : (
            ''
          )}
          <Typography
            component={'div'}
            className="upload-div d-flex-ja pointer">
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <Typography
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    minHeight: '140px',
                    minWidth: '450px',
                    position: 'absolute',
                  }}>
                  <input
                    type="file"
                    {...getInputProps()}
                    accept={mimeTypes}
                    hidden
                    id="fileUploadId"
                    ref={uploadRef}
                    onChange={(e) => {
                      checkFileValidation(e.target?.files[0]);
                    }}
                  />
                </Typography>
              )}
            </Dropzone>

            <img src={UploadIcon} alt="icon" />
            <Typography className="f-16">
              Drag and drop file here, or{' '}
              <a style={{ color: '#007CC3', textDecoration: 'underline' }}>
                Browse
              </a>
            </Typography>
            {/* <Typography className="f-12">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
              diam nonumy eirmod tempor invidunt ut labore et dolore magna
              aliquyam erat
            </Typography> */}
          </Typography>

          <Typography className="mb-1">
            {file ? 'Uploaded - 1 file' : 'Upload File'}
          </Typography>

          <Typography
            component={'div'}
            className="uinfo-box d-flex-a"
            sx={{ backgroundColor: file ? 'White' : '#f0f0f0 !important' }}>
            <Typography component={'div'} className="d-flex-a intext">
              {/* <img src={PdfIcon} className="mr-1" /> */}
              {file && (
                <span>
                  <img src={PdfIcon} />
                </span>
              )}
              <span className="ml-1">{file?.name}</span>
            </Typography>
            <Typography className="w-16">
              {file && (
                <img
                  src={DeleteIcon}
                  onClick={() => {
                    setFile(null);
                    uploadRef.current.value = '';
                  }}
                />
              )}
            </Typography>
          </Typography>

          <Typography className="mt-2 f-18-1 mb-1">
            Notes <span className="required">*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue=""
            name="notes"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <CustomInput
                  placeHolder={'Notes'}
                  name={name}
                  value={value}
                  isTextArea={true}
                  error={errors?.notes?.message}
                  onChange={onChange}
                  extraStyle={{ width: '100%' }}
                />
              );
            }}
          />

          <Button
            className="n-add-btn mt-2"
            sx={{ width: '100%' }}
            onClick={handleSubmit(rfeResondSubmit)}>
            Submit
          </Button>
        </Typography>
      </Typography>
      <Typography component={'div'} className="rfe-footer">
        <Footer />
      </Typography>
    </Typography>
  );
};
