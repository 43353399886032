import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import Avator from 'assets/images/d-avator.svg';
import pencil from 'assets/images/pencil.svg';
import pencilImg from 'assets/images/pencil-img.svg';
import CustomDatePicker from 'components/CustomDatePicker/customDatePicker';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { FileUploadDialog } from 'components/Fileupload/fileuploadDialog';
import { AdminPrivateRoutes } from 'config';
import _ from 'lodash';
import moment from 'moment';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
// import { useAppSelector } from 'store/hooks';
import { PetitionersAddUserSubmitForm } from 'types';
import { fileApiUrl } from 'utils/Config';
import {
  COMMON_ENDPOINT,
  MASTER_ENDPOINT,
  SETUP_ENDPOINT,
} from 'utils/Constant';
import { alertOptions, profileFileType, validateDate } from 'utils/helper';
import {
  AddPetitionerAddSchema,
  AddPetitionerEditSchema,
} from 'utils/ValidatorSchema';

import useStyles from '../../../PrivateStyle';

export const ManagePetitionerAddAndEdit: React.FC<any> = (props) => {
  // const userDetail: any = useAppSelector(
  //   (store: any) => store.userLogin.userInfo,
  // );
  const classes = useStyles();
  const navigate: any = useNavigate();
  const { editId } = useParams();
  const location = useLocation();
  // const hiddenFileInput = useRef(null);
  const activeStatusInput = useRef(null);
  const inActiveStatusInput = useRef(null);
  const activeContactStatusInput = useRef(null);
  const inActiveContactStatusInput = useRef(null);
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(true);
  const [inviteHash, setInviteHash] = useState('');
  const [contactTypes, setContactTypes] = useState<any>([]);
  const [contactData, setContactData] = useState<any>([]);
  const [countries, setCountries] = useState<any>({
    cities: [],
    coutries: [],
    state: [],
  });
  const [mobileWithCode, setMobileWithCode] = useState<any>({
    MobileSocCode: 'us',
    mobileCode: '+1 ',
  });
  const [profileImage, setProfileImage] = useState<any>({
    imageURL: '',
    selectedImage: null,
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [statusToggle, setStatusToggle] = useState(true);
  const [contactStatusToggle, setContactStatusToggle] = useState(true);
  const [fileUploadOpen, setFileUploadOpen] = useState<any>({
    open: false,
  });
  const {
    control: controlAdd,
    handleSubmit: handleSubmitAdd,
    reset: resetFormAdd,
    setValue: setValueAdd,
    setError: setErrorAdd,
    getValues: getValuesAdd,
    watch,
    formState: { errors: errorsAdd },
  } = useForm<PetitionersAddUserSubmitForm>({
    criteriaMode: 'all',
    defaultValues: {
      contactEmail: '',
      contactType: '',
      designation: '',
      firstName: '',
      gender: '',
      grossAnnualIncome: '',
      lastName: '',
      middleName: '',
      mobileNumber: '',
      netAnnualIncome: '',
      organizationAddress: '',
      organizationCity: '',
      organizationCountry: '',
      organizationEmail: '',
      organizationFEINNumber: '',
      organizationFax: '',
      organizationLogo: '',
      organizationName: '',
      organizationPhone: '',
      organizationSignatoryEmail: '',
      organizationSignatoryFirstName: '',
      organizationSignatoryLastName: '',
      organizationSignatoryTitle: '',
      organizationState: '',
      organizationStatus: 'active',
      organizationWebsite: '',
      organizationZipCode: '',
      phoneNumber: '',
      primaryContact: true,
      status: 'active',
      streetNumberAndName: '',
      totalNumberOfEmployees: '',
      typeOfBusiness: '',
      unitNumber: '',
      unitType: 'apt',
      validTill: '',
      yearEstablished: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      _.isUndefined(editId) ? AddPetitionerAddSchema : AddPetitionerEditSchema,
    ),
  });

  const cancelForm = () => {
    resetFormAdd();
    navigate(-1);
  };

  useEffect(() => {
    // Check invite is valid or not
    const invite = searchParams.get('invite');
    if (invite) {
      getAllListData(
        `${String(SETUP_ENDPOINT.ManageOrganization)}?hash=${String(invite)}`,
      )
        .then((resp: any) => {
          const { accepted } = resp;
          if (accepted === false) {
            const { firm_name, firm_mail } = resp;
            setInviteHash(invite);
            setValueAdd('organizationName', firm_name);
            setValueAdd('contactEmail', firm_mail);
          }
        })
        .catch((e: any) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onToggleStatus = () => setStatusToggle((b) => !b);
  const onToggleContactStatus = () => setContactStatusToggle((b) => !b);

  const getInitialStatus = useCallback(async () => {
    setLoader(true);
    await getAllListData(
      `${COMMON_ENDPOINT.ManagePetitioner}/${String(editId)}`,
    )
      .then((resp) => {
        const {
          // attorney_users,
          // file_name,
          org_address,
          org_business_type,
          org_city,
          org_country,
          org_email,
          org_fax,
          org_fein_number,
          org_gross_annual_income,
          org_logo,
          org_name,
          org_net_annual_income,
          org_phone,
          org_signatory_email,
          org_signatory_first_name,
          // org_signatory_full_name,
          org_signatory_last_name,
          org_signatory_title,
          org_state,
          org_status,
          org_street_number_and_name,
          org_total_employess,
          // org_type,
          org_unit_number,
          org_unit_type,
          org_valid_till,
          org_website,
          org_year_established,
          org_zip_code,
          // updated_by,
        } = resp;
        getAllListData(
          `${COMMON_ENDPOINT.ManagePetitioner}/user/${String(editId)}`,
        )
          .then((userResp: any) => {
            setContactData(userResp);

            !_.isNull(org_country)
              ? getCountriesDependent('countryId', org_country)
              : getCountriesDependent('');
            !_.isNull(org_state)
              ? getCountriesDependent('stateId', org_state)
              : getCountriesDependent('');
          })
          .catch((e: any) => {
            setLoader(false);
          });
        if (org_logo) {
          setProfileImage({
            imageURL: `${fileApiUrl}${String(org_logo)}`,
            selectedImage: { org_logo },
          });
        }

        // setValueEdit('contactEmail', '');
        // setValueEdit('contactType', '');
        // setValueEdit('designation', '');
        // setValueEdit('firstName', '');
        // setValueEdit('gender', '');
        // setValueEdit('lastName', '');
        // setValueEdit('mobileNumber', '');
        // setValueEdit('primaryContact', true);
        // setValueEdit('phoneNumber', '');
        // setValueEdit('status', true);
        // setContactStatusToggle(status === 'active' ? true : false);
        setValueAdd('grossAnnualIncome', org_gross_annual_income || '');
        setValueAdd('netAnnualIncome', org_net_annual_income || '');
        setValueAdd('organizationAddress', org_address || '');
        setValueAdd('organizationCity', Number(org_city) || '');
        setValueAdd('organizationCountry', Number(org_country) || '');
        setValueAdd('organizationEmail', org_email || '');
        setValueAdd('organizationFEINNumber', org_fein_number || '');
        setValueAdd('organizationFax', org_fax || '');
        setValueAdd('organizationLogo', org_logo || '');
        setValueAdd('organizationName', org_name || '');
        setValueAdd('organizationPhone', org_phone || '');

        setValueAdd('organizationSignatoryEmail', org_signatory_email || '');
        setValueAdd(
          'organizationSignatoryFirstName',
          org_signatory_first_name || '',
        );
        setValueAdd(
          'organizationSignatoryLastName',
          org_signatory_last_name || '',
        );
        setValueAdd('organizationSignatoryTitle', org_signatory_title || '');
        setValueAdd('organizationState', Number(org_state) || '');
        setValueAdd('organizationWebsite', org_website || '');
        setValueAdd('organizationStatus', org_status || 'active');
        setValueAdd('organizationZipCode', org_zip_code || '');
        setValueAdd('streetNumberAndName', org_street_number_and_name || '');
        setValueAdd('totalNumberOfEmployees', org_total_employess || '');
        setValueAdd('typeOfBusiness', org_business_type || '');
        setValueAdd('unitNumber', org_unit_number || '');
        setValueAdd('unitType', org_unit_type || '');
        setValueAdd(
          'validTill',
          moment(org_valid_till).format('MM/DD/YYYY') || '',
        );
        setValueAdd('yearEstablished', org_year_established || '');

        setStatusToggle(org_status === 'active' ? true : false);

        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitForm = (data: any) => {
    if (
      data['validTill'] &&
      !validateDate(moment(data['validTill']).format('MM/DD/YYYY'))
    ) {
      setErrorAdd('validTill', {
        message: 'Not a valid date format.',
        type: 'custom',
      });
    } else {
      setLoader(true);
      const postFormData = new FormData();
      !_.isBuffer(profileImage.selectedImage) &&
        postFormData.append('organizationLogo', profileImage.selectedImage);
      postFormData.append('roleId', '2');
      postFormData.append(
        'organizationName',
        data['organizationName'] ? data['organizationName'] : '',
      );
      postFormData.append(
        'organizationAddress',
        data['organizationAddress'] ? data['organizationAddress'] : '',
      );
      postFormData.append(
        'organizationZipCode',
        data['organizationZipCode'] ? data['organizationZipCode'] : '',
      );
      postFormData.append(
        'organizationCity',
        data['organizationCity'] ? String(data['organizationCity']) : '',
      );
      postFormData.append(
        'organizationState',
        data['organizationState'] ? String(data['organizationState']) : '',
      );
      postFormData.append(
        'organizationCountry',
        data['organizationCountry'] ? String(data['organizationCountry']) : '',
      );
      postFormData.append(
        'organizationPhone',
        data['organizationPhone'] ? data['organizationPhone'] : '',
      );
      postFormData.append(
        'organizationFax',
        data['organizationFax'] ? data['organizationFax'] : '',
      );
      postFormData.append(
        'organizationEmail',
        data['organizationEmail'] ? data['organizationEmail'] : '',
      );
      postFormData.append(
        'organizationWebsite',
        data['organizationWebsite'] ? data['organizationWebsite'] : '',
      );
      postFormData.append(
        'streetNumberAndName',
        data['streetNumberAndName'] ? data['streetNumberAndName'] : '',
      );
      postFormData.append('unitType', data['unitType'] ? data['unitType'] : '');
      postFormData.append(
        'unitNumber',
        data['unitNumber'] ? data['unitNumber'] : '',
      );
      postFormData.append(
        'validTill',
        data['validTill'] ? data['validTill'] : '',
      );
      postFormData.append(
        'organizationStatus',
        contactStatusToggle ? 'active' : 'inactive',
      );
      postFormData.append(
        'typeOfBusiness',
        data['typeOfBusiness'] ? data['typeOfBusiness'] : '',
      );
      postFormData.append(
        'yearEstablished',
        data['yearEstablished'] ? data['yearEstablished'] : '',
      );
      postFormData.append(
        'totalNumberOfEmployees',
        data['totalNumberOfEmployees'] ? data['totalNumberOfEmployees'] : '',
      );
      postFormData.append(
        'grossAnnualIncome',
        data['grossAnnualIncome'] ? data['grossAnnualIncome'] : '',
      );
      postFormData.append(
        'netAnnualIncome',
        data['netAnnualIncome'] ? data['netAnnualIncome'] : '',
      );
      postFormData.append(
        'organizationFEINNumber',
        data['organizationFEINNumber'] ? data['organizationFEINNumber'] : '',
      );
      postFormData.append(
        'organizationSignatoryFirstName',
        data['organizationSignatoryFirstName']
          ? data['organizationSignatoryFirstName']
          : '',
      );

      postFormData.append(
        'organizationSignatoryLastName',
        data['organizationSignatoryLastName']
          ? data['organizationSignatoryLastName']
          : '',
      );
      postFormData.append(
        'organizationSignatoryEmail',
        data['organizationSignatoryEmail']
          ? data['organizationSignatoryEmail']
          : '',
      );
      postFormData.append(
        'organizationSignatoryTitle',
        data['organizationSignatoryTitle']
          ? data['organizationSignatoryTitle']
          : '',
      );

      // console.log('editIdeditIdeditId', editId);

      if (_.isUndefined(editId)) {
        postFormData.append(
          'contactType',
          data['contactType'] ? data['contactType'] : '',
        );
        postFormData.append(
          'firstName',
          data['firstName'] ? data['firstName'] : '',
        );
        postFormData.append(
          'primaryContact',
          data['primaryContact'] ? data['primaryContact'] : '',
        );
        postFormData.append(
          'lastName',
          data['lastName'] ? data['lastName'] : '',
        );
        postFormData.append(
          'middleName',
          data['middleName'] ? data['middleName'] : '',
        );
        postFormData.append('gender', data['gender'] ? data['gender'] : '');
        postFormData.append(
          'designation',
          data['designation'] ? data['designation'] : '',
        );
        postFormData.append(
          'phoneNumber',
          data['phoneNumber'] ? data['phoneNumber'] : '',
        );
        // postFormData.append(
        //   'mobileNumber',
        //   data['mobileNumber'] ? data['mobileNumber'] : '',
        // );

        postFormData.append(
          'mobileNumber',
          data['mobileNumber']
            ? String(data['mobileNumber']).split(/(?<=^\S+)\s/)[1]
            : '',
        );

        postFormData.append(
          'mobileCountryCode',
          mobileWithCode.mobileCode ? mobileWithCode.mobileCode : '+1 ',
        );

        postFormData.append(
          'mobileSocCode',
          mobileWithCode.MobileSocCode ? mobileWithCode.MobileSocCode : 'us',
        );

        postFormData.append(
          'contactEmail',
          data['contactEmail'] ? data['contactEmail'] : '',
        );
        postFormData.append('status', statusToggle ? 'active' : 'inactive');
      }

      if (!_.isUndefined(editId)) {
        updateData(editId, postFormData, COMMON_ENDPOINT.ManagePetitioner)
          .then((resp: any) => {
            // cancelForm();
            setLoader(false);
            toast.success('Updated successfully', alertOptions);
          })
          .catch((e) => {
            setLoader(false);
          });
      } else {
        addNewData(
          postFormData,
          `${String(SETUP_ENDPOINT.AddPetitioner)}${
            inviteHash ? `?hashkey=${inviteHash}` : ''
          }`,
        )
          .then((resp: any) => {
            if (props.from != 'signup') {
              toast.success('Created successfully', alertOptions);
            }
            if (inviteHash) {
              setLoader(false);
              const { emailHash } = resp;
              navigate(`/new-password?encrpt=${String(emailHash)}`);
              localStorage.removeItem('orgHash');
            }
            if (props.from === 'signup') {
              setLoader(false);
              handleOpen();
            } else {
              setLoader(false);
              navigate(
                AdminPrivateRoutes.MANAGEPETITIONERS.MANAGEPETITIONERSLIST,
              );
            }
          })
          .catch((e) => {
            setLoader(false);
          });
      }
    }
  };

  const getCountriesDependent = useCallback((key?: string, value?: number) => {
    getAllListData(
      key === ''
        ? MASTER_ENDPOINT.Countries
        : `${MASTER_ENDPOINT.Countries}?${key}=${String(value)}`,
    )
      .then((countryResp: any) => {
        if (key === 'countryId') {
          setCountries((prev) => ({
            ...prev,
            countries: countryResp.countries,
            states: countryResp.states,
          }));
        }
        if (key === 'stateId') {
          setCountries((prev) => ({
            ...prev,
            cities: countryResp.cities,
            countries: countryResp.countries,
          }));
        }
        if (key === '') {
          setCountries({
            cities: countryResp.cities,
            countries: countryResp.countries,
            states: countryResp.states,
          });
        }
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  }, []);

  const profileSelected = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    setProfileImage({
      selectedImage: file,
    });
    reader.onloadend = () => {
      setProfileImage((prev) => ({
        ...prev,
        imageURL: [reader.result],
      }));
    };
  };

  useEffect(() => {
    if (!_.isUndefined(editId)) {
      getInitialStatus();
    } else {
      getAllListData(COMMON_ENDPOINT.ResponseTypes)
        .then((typeResp: any) => {
          setContactTypes(typeResp);
          getCountriesDependent('');
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeUploadDialog = useCallback((action: any) => {
    if (action) {
      profileSelected(action);
    } else {
      setFileUploadOpen((pre) => ({
        ...pre,
        open: false,
      }));
    }
  }, []);
  return (
    <Grid container>
      <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }} className="pl-0">
        {loader ? (
          <div className="liberty-loader" />
        ) : !open ? (
          <>
            {props.from === 'signup' && (
              <Typography component={'div'} style={{ color: '#fff' }}>
                <h1>Organization Registration</h1>
              </Typography>
            )}
            <form
              id="add-petitioner-all"
              onSubmit={handleSubmitAdd(onSubmitForm)}>
              <Typography
                component={'div'}
                className="addbox"
                sx={{ marginTop: '4rem !important' }}>
                <div className="user-poto-box">
                  <div
                    className="pencil-box"
                    onClick={() => setFileUploadOpen({ open: true })}>
                    <img className="d-block" src={pencil} alt="pencileditsvg" />
                    <img
                      className="d-none"
                      src={pencilImg}
                      alt="pencileditsvg"
                    />
                    {/* <input
                      type="file"
                      hidden
                      ref={hiddenFileInput}
                      onChange={(e) => profileSelected(e.target.files)}
                      accept="image/png, image/jpeg"
                    /> */}
                  </div>
                  {fileUploadOpen.open && (
                    <FileUploadDialog
                      open={fileUploadOpen.open}
                      title={'Upload Profile '}
                      allowedFileType={profileFileType}
                      errorMessage={
                        'You can upload documents of types JPEG, PNG only'
                      }
                      info={
                        '(You can upload documents of types JPEG, PNG only)'
                      }
                      onConfirm={closeUploadDialog}
                      setFileUploadOpen={setFileUploadOpen}
                    />
                  )}
                  {_.isBuffer(profileImage.selectedImage) ? (
                    <img
                      src={profileImage.imageURL}
                      alt="user"
                      className="user-poto"
                    />
                  ) : (
                    <img
                      src={
                        profileImage.imageURL !== ''
                          ? profileImage.imageURL
                          : Avator
                      }
                      alt="user"
                      className="user-poto"
                    />
                  )}
                </div>
                <Typography component={'div'} sx={{ marginTop: '4rem' }}>
                  <Box
                    component="form"
                    className="u-add-in"
                    noValidate
                    autoComplete="off">
                    <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                      <Grid item md={6} sm={12}>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Organization Name{' '}
                            <span className="required">*</span>
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="organizationName"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Organization Name'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errorsAdd.organizationName?.message}
                                  onChange={(e) => onChange(e)}
                                  tabIndex={1}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Street Number And Name
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="streetNumberAndName"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Street Number And Name'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errorsAdd.streetNumberAndName?.message}
                                  onChange={(e) => onChange(e)}
                                  tabIndex={3}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Unit Type
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue="apt"
                            name="unitType"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name={name}
                                  value={value}
                                  tabIndex={5}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}>
                                  <FormControlLabel
                                    value="apt"
                                    control={<Radio />}
                                    label="Apartment"
                                  />
                                  <FormControlLabel
                                    value="ste"
                                    control={<Radio />}
                                    label="Suite"
                                  />
                                  <FormControlLabel
                                    value="flr"
                                    control={<Radio />}
                                    label="Floor"
                                  />
                                  {getValuesAdd().unitType && (
                                    <FormControlLabel
                                      value=""
                                      control={
                                        <span
                                          className="reset-button"
                                          onClick={() =>
                                            setValueAdd('unitType', '')
                                          }>
                                          Reset
                                        </span>
                                      }
                                      label=""
                                    />
                                  )}
                                </RadioGroup>
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Unit Number
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="unitNumber"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Unit Number'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errorsAdd.unitNumber?.message}
                                  onChange={(e) => onChange(e)}
                                  tabIndex={7}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Phone
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="organizationPhone"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Phone'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errorsAdd.organizationPhone?.message}
                                  onChange={(e) => onChange(e)}
                                  type={'number'}
                                  tabIndex={9}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Email <span className="required">*</span>
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="organizationEmail"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Email'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errorsAdd.organizationEmail?.message}
                                  onChange={(e) => onChange(e)}
                                  type={'email'}
                                  tabIndex={11}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Website
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="organizationWebsite"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Website'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errorsAdd.organizationWebsite?.message}
                                  onChange={(e) => onChange(e)}
                                  tabIndex={13}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Type of Business
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="typeOfBusiness"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Type of Business'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errorsAdd.typeOfBusiness?.message}
                                  onChange={(e) => onChange(e)}
                                  tabIndex={15}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Current Number of Employees in the united States
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="totalNumberOfEmployees"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={
                                    'Current Number of Employees in the united States'
                                  }
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={
                                    errorsAdd.totalNumberOfEmployees?.message
                                  }
                                  onChange={(val) =>
                                    onChange(
                                      val.target.value.replace(/[^\d]/g, ''),
                                    )
                                  }
                                  tabIndex={19}
                                />
                              );
                            }}
                          />
                        </Typography>
                        {props.from !== 'signup' && (
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Net Annual Income
                            </Typography>
                            <Controller
                              control={controlAdd}
                              defaultValue=""
                              name="netAnnualIncome"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    placeHolder={'Net Annual Income'}
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errorsAdd.netAnnualIncome?.message}
                                    onChange={(e) => onChange(e)}
                                    tabIndex={21}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Country
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="organizationCountry"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomSelect
                                  searchable={true}
                                  labelKey={'name'}
                                  valueKey={'id'}
                                  options={
                                    !_.isEmpty(countries?.countries)
                                      ? countries?.countries
                                      : []
                                  }
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    getCountriesDependent(
                                      'countryId',
                                      e.target.value,
                                    );
                                  }}
                                  placeHolder={'Select'}
                                  className={'custom-input'}
                                  error={errorsAdd.organizationCountry?.message}
                                  tabIndex={2}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            State
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="organizationState"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomSelect
                                  searchable={true}
                                  labelKey={'name'}
                                  valueKey={'id'}
                                  options={
                                    !_.isEmpty(countries?.states)
                                      ? countries?.states
                                      : []
                                  }
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    getCountriesDependent(
                                      'stateId',
                                      e.target.value,
                                    );
                                  }}
                                  placeHolder={'Select'}
                                  className={'custom-input'}
                                  error={errorsAdd.organizationState?.message}
                                  tabIndex={4}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            City
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="organizationCity"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomSelect
                                  searchable={true}
                                  labelKey={'name'}
                                  valueKey={'id'}
                                  options={
                                    !_.isEmpty(countries?.cities)
                                      ? countries?.cities
                                      : []
                                  }
                                  name={name}
                                  value={value}
                                  onChange={(e) => onChange(e)}
                                  placeHolder={'Select'}
                                  className={'custom-input'}
                                  error={errorsAdd.organizationCity?.message}
                                  tabIndex={6}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Zip / Postal Code
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="organizationZipCode"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Zip / Postal Code'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errorsAdd.organizationZipCode?.message}
                                  onChange={(e) =>
                                    onChange(
                                      e.target.value.replace(/[^\d]/g, ''),
                                    )
                                  }
                                  type={'text'}
                                  tabIndex={8}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Fax
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="organizationFax"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Fax'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errorsAdd.organizationFax?.message}
                                  onChange={(e) => onChange(e)}
                                  tabIndex={10}
                                />
                              );
                            }}
                          />
                        </Typography>

                        {props.from !== 'signup' && (
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Valid Till
                            </Typography>
                            <Controller
                              control={controlAdd}
                              defaultValue=""
                              name="validTill"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomDatePicker
                                    className="custom-input"
                                    errors={errorsAdd.validTill?.message}
                                    name={name}
                                    value={value}
                                    onChangeDate={(val) => onChange(val)}
                                    tabIndex={12}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        )}
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Year Business Established
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="yearEstablished"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={''}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errorsAdd.yearEstablished?.message}
                                  onChange={(e) => onChange(e)}
                                  tabIndex={14}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Gross Annual Income
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="grossAnnualIncome"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={''}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errorsAdd.grossAnnualIncome?.message}
                                  onChange={(e) => onChange(e)}
                                  tabIndex={16}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Federal Employer Identification Number (FEIN)
                          </Typography>
                          <Controller
                            control={controlAdd}
                            defaultValue=""
                            name="organizationFEINNumber"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={
                                    'Federal Employer Identification Number (FEIN)'
                                  }
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={
                                    errorsAdd.organizationFEINNumber?.message
                                  }
                                  onChange={(e) => onChange(e)}
                                  type={'number'}
                                  tabIndex={20}
                                />
                              );
                            }}
                          />
                        </Typography>
                        {props.from === 'signup' && (
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Net Annual Income
                            </Typography>
                            <Controller
                              control={controlAdd}
                              defaultValue=""
                              name="netAnnualIncome"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    placeHolder={'Net Annual Income'}
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errorsAdd.netAnnualIncome?.message}
                                    onChange={(e) => onChange(e)}
                                    tabIndex={21}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        )}
                        {props.from !== 'signup' && (
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Status
                            </Typography>
                            <div className="switch-field" tabIndex={22}>
                              <input
                                type="radio"
                                id="switch_left"
                                name="switchToggle"
                                value={'inactive'}
                                onChange={onToggleStatus}
                                checked={!statusToggle}
                                ref={inActiveStatusInput}
                              />
                              <label
                                htmlFor="switch_left d-flex-ja"
                                onClick={() =>
                                  inActiveStatusInput.current.click()
                                }>
                                Inactive
                              </label>

                              <input
                                type="radio"
                                id="switch_right"
                                name="switchToggle"
                                value={'active'}
                                onChange={onToggleStatus}
                                checked={statusToggle}
                                ref={activeStatusInput}
                              />
                              <label
                                htmlFor="switch_right d-flex-ja"
                                onClick={() =>
                                  activeStatusInput.current.click()
                                }>
                                Active
                              </label>
                            </div>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  {props.from !== 'signup' && (
                    <>
                      <Typography
                        component={'div'}
                        className="uadd-title"
                        sx={{ paddingTop: '3.5rem !important' }}>
                        Signatory Details
                      </Typography>
                      <Box
                        component="form"
                        className="u-add-in"
                        noValidate
                        autoComplete="off">
                        <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                          <Grid item md={6} sm={12}>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                First Name
                              </Typography>
                              <Controller
                                control={controlAdd}
                                defaultValue=""
                                name="organizationSignatoryFirstName"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      placeHolder={'First Name'}
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={
                                        errorsAdd.organizationSignatoryFirstName
                                          ?.message
                                      }
                                      onChange={(e) => onChange(e)}
                                      tabIndex={23}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Email
                              </Typography>
                              <Controller
                                control={controlAdd}
                                defaultValue=""
                                name="organizationSignatoryEmail"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      placeHolder={'Email'}
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={
                                        errorsAdd.organizationSignatoryEmail
                                          ?.message
                                      }
                                      onChange={(e) => onChange(e)}
                                      type={'email'}
                                      tabIndex={25}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={6} sm={12}>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Last Name
                              </Typography>
                              <Controller
                                control={controlAdd}
                                defaultValue=""
                                name="organizationSignatoryLastName"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      placeHolder={'Last Name'}
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={
                                        errorsAdd.organizationSignatoryLastName
                                          ?.message
                                      }
                                      onChange={(e) => onChange(e)}
                                      tabIndex={24}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Title
                              </Typography>
                              <Controller
                                control={controlAdd}
                                defaultValue=""
                                name="organizationSignatoryTitle"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      placeHolder={'Title'}
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={
                                        errorsAdd.organizationSignatoryTitle
                                          ?.message
                                      }
                                      onChange={(e) => onChange(e)}
                                      tabIndex={26}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                </Typography>
                {!location.pathname.includes('/edit') && _.isUndefined(editId) && (
                  <>
                    <Typography component={'div'} className="uadd-title">
                      Contact Person
                    </Typography>
                    <Box
                      component="form"
                      className="u-add-in"
                      noValidate
                      autoComplete="off">
                      <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                        <Grid item md={6} sm={12}>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Contact Type <span className="required">*</span>
                            </Typography>
                            <Controller
                              control={controlAdd}
                              defaultValue=""
                              name="contactType"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomSelect
                                    labelKey={'conatct_type'}
                                    valueKey={'type_id'}
                                    options={
                                      !_.isEmpty(contactTypes)
                                        ? contactTypes
                                        : []
                                    }
                                    name={name}
                                    value={value}
                                    onChange={(e) => onChange(e)}
                                    placeHolder={'Select'}
                                    className={'custom-input'}
                                    error={errorsAdd.contactType?.message}
                                    tabIndex={27}
                                  />
                                );
                              }}
                            />
                          </Typography>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              First Name <span className="required">*</span>
                            </Typography>
                            <Controller
                              control={controlAdd}
                              defaultValue=""
                              name="firstName"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    placeHolder={'First Name'}
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errorsAdd.firstName?.message}
                                    onChange={(e) => onChange(e)}
                                    tabIndex={29}
                                  />
                                );
                              }}
                            />
                          </Typography>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Gender
                            </Typography>
                            <Controller
                              control={controlAdd}
                              defaultValue=""
                              name="gender"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomSelect
                                    labelKey={'label'}
                                    valueKey={'value'}
                                    options={[
                                      { label: 'Male', value: 'M' },
                                      { label: 'Female', value: 'F' },
                                    ]}
                                    name={name}
                                    value={value}
                                    onChange={(e) => onChange(e)}
                                    placeHolder={'Select'}
                                    className={'custom-input'}
                                    error={errorsAdd.gender?.message}
                                    tabIndex={31}
                                  />
                                );
                              }}
                            />
                          </Typography>
                          {/* <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Phone
                            </Typography>
                            <Controller
                              control={controlAdd}
                              defaultValue=""
                              name="phoneNumber"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    placeHolder={'Phone'}
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errorsAdd.phoneNumber?.message}
                                    onChange={(e) => onChange(e)}
                                    type={'number'}
                                    tabIndex={33}
                                  />
                                );
                              }}
                            />
                          </Typography> */}
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Email <span className="required">*</span>
                            </Typography>
                            <Controller
                              control={controlAdd}
                              defaultValue=""
                              name="contactEmail"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    placeHolder={'Email'}
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errorsAdd.contactEmail?.message}
                                    onChange={(e) => onChange(e)}
                                    type={'email'}
                                    disabled={inviteHash ? true : false}
                                    tabIndex={35}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={6}
                          sm={12}
                          style={{ paddingTop: '12px' }}>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Primary Contact
                            </Typography>
                            <Controller
                              control={controlAdd}
                              defaultValue={false}
                              name="primaryContact"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <Checkbox
                                    inputRef={ref}
                                    checked={value}
                                    onChange={onChange}
                                    name={name}
                                    value={value}
                                    color="primary"
                                    disabled={
                                      props.from !== 'signup' &&
                                      watch('primaryContact')
                                        ? true
                                        : false
                                    }
                                    tabIndex={28}
                                  />
                                );
                              }}
                            />
                          </Typography>
                          {/* {props.from !== 'singup' && (
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                MiddleName
                              </Typography>
                              <Controller
                                control={controlAdd}
                                defaultValue=""
                                name="middleName"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      placeHolder={'Middle Name'}
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={errorsAdd.middleName?.message}
                                      onChange={(e) => onChange(e)}
                                      tabIndex={30}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          )} */}
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Last Name <span className="required">*</span>
                            </Typography>
                            <Controller
                              control={controlAdd}
                              defaultValue=""
                              name="lastName"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    placeHolder={'Last Name'}
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errorsAdd.lastName?.message}
                                    onChange={(e) => onChange(e)}
                                    tabIndex={30}
                                  />
                                );
                              }}
                            />
                          </Typography>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Designation
                            </Typography>
                            <Controller
                              control={controlAdd}
                              defaultValue=""
                              name="designation"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    placeHolder={'Designation'}
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errorsAdd.designation?.message}
                                    onChange={(e) => onChange(e)}
                                    tabIndex={32}
                                  />
                                );
                              }}
                            />
                          </Typography>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Mobile
                            </Typography>
                            <Controller
                              control={controlAdd}
                              defaultValue=""
                              name="mobileNumber"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  // <CustomInput
                                  //   placeHolder={'Mobile'}
                                  //   name={name}
                                  //   value={value}
                                  //   className={'custom-input'}
                                  //   error={errorsAdd.mobileNumber?.message}
                                  //   onChange={(e) => onChange(e)}
                                  //   type={'number'}
                                  //   tabIndex={34}
                                  // />
                                  <PhoneInput
                                    placeholder="Mobile"
                                    country={'us'}
                                    value={value}
                                    isValid={true}
                                    onChange={(
                                      value,
                                      data,
                                      event,
                                      formattedValue,
                                    ) => {
                                      const codes: any = data;
                                      onChange(formattedValue);
                                      setMobileWithCode({
                                        MobileSocCode: codes?.countryCode,
                                        mobileCode: '+'.concat(codes?.dialCode),
                                      });
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                          {props.from !== 'signup' && (
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Contact Status{' '}
                                <span style={{ color: 'red' }}>*</span>
                              </Typography>
                              <div className="switch-field">
                                <input
                                  type="radio"
                                  id="switch_left"
                                  name="switchToggle"
                                  value={'inactive'}
                                  onChange={onToggleContactStatus}
                                  checked={!contactStatusToggle}
                                  ref={inActiveContactStatusInput}
                                />
                                <label
                                  htmlFor="switch_left d-flex-ja"
                                  onClick={() =>
                                    inActiveContactStatusInput.current.click()
                                  }>
                                  Inactive
                                </label>

                                <input
                                  type="radio"
                                  id="switch_right"
                                  name="switchToggle"
                                  value={'active'}
                                  onChange={onToggleContactStatus}
                                  checked={contactStatusToggle}
                                  ref={activeContactStatusInput}
                                />
                                <label
                                  htmlFor="switch_right d-flex-ja"
                                  onClick={() =>
                                    activeContactStatusInput.current.click()
                                  }>
                                  Active
                                </label>
                              </div>
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
              </Typography>
            </form>
            {!_.isUndefined(editId) && (
              <Grid container className="bottom-fixed">
                <Grid item md={12}>
                  <Typography component={'div'} className="d-flex-jae ">
                    <Button
                      className="n-cancel-btn mr-2"
                      // sx={{
                      //   color: 'black !important',
                      //   width: '153px !important',
                      // }}
                      onClick={cancelForm}>
                      <Typography className="o-box" />
                      Cancel
                    </Button>
                    <Button
                      className="n-add-btn"
                      sx={{ width: '153px !important' }}
                      type="submit"
                      form={'add-petitioner-all'}
                      disabled={loader}>
                      {props.from !== 'signup' ? 'Save' : 'Submit'}
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            )}
            {_.isUndefined(editId) && (
              <Grid container className="bottom-fixed">
                <Grid item md={12}>
                  <Typography component={'div'} className="d-flex-jae">
                    <Button
                      className="cancel-btn mr-2"
                      type="button"
                      onClick={cancelForm}>
                      <Typography className="o-box" />
                      Cancel
                    </Button>
                    <Button
                      className="add-btn"
                      type="submit"
                      form={'add-petitioner-all'}>
                      Save
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            )}
            {!_.isUndefined(editId) && contactData.length && (
              <Grid item md={12} sm={12}>
                <Typography component={'div'} className="d-flex-jae">
                  <Button
                    type="button"
                    className="n-add-btn mt-2 mb-1"
                    onClick={() =>
                      navigate(
                        `${
                          AdminPrivateRoutes.MANAGEPETITIONERS.CONTACTADD
                        }/${String(editId)}`,
                      )
                    }>
                    Add Contact Person
                  </Button>
                </Typography>

                <Typography
                  component={'div'}
                  sx={{
                    borderRadius: '8px 8px 0 0',
                  }}
                  className={classes.scrollbox}>
                  <TableContainer>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      className="lead-table table-hover"
                      style={{ minHeight: '106px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>CONTACT TYPE</TableCell>
                          <TableCell>NAME</TableCell>
                          <TableCell>EMAIL</TableCell>
                          <TableCell>PHONE</TableCell>
                          <TableCell>PRIMARY CONTACT</TableCell>
                          <TableCell>STATUS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contactData.map((cData, index) => (
                          <TableRow
                            key={index}
                            className="pointer"
                            onClick={() =>
                              navigate(
                                `${
                                  AdminPrivateRoutes.MANAGEPETITIONERS
                                    .CONTACTEDIT
                                }/${String(editId)}/${String(cData.id)}`,
                              )
                            }>
                            <TableCell scope="row">
                              {cData?.contact_type}
                            </TableCell>
                            <TableCell>
                              <Typography
                                component={'div'}
                                className="multiline">
                                {cData?.full_name}
                              </Typography>
                            </TableCell>
                            <TableCell>{cData?.email_id}</TableCell>
                            <TableCell>{cData?.user_phone}</TableCell>
                            <TableCell>
                              <Checkbox
                                className="checkBoxFlex"
                                checked={cData?.primary_contact}
                                onChange={(e) => {}}
                                value={cData?.primary_contact}
                                color="primary"
                                disabled
                              />
                            </TableCell>
                            <TableCell>
                              {cData?.conatct_status === 'active'
                                ? 'Active'
                                : 'Inactive'}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Typography>
              </Grid>
            )}
          </>
        ) : null}
        {props.from === 'signup' && (
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className="success-re-modal">
              <Typography component={'div'} className="d-flex-a">
                <CheckCircleOutlineIcon
                  sx={{ fill: 'green', fontSize: '35px' }}
                />
                <Typography className="s-title mt-2">
                  You have successfully completed your registration!
                </Typography>
                <Typography className="s-title mt-1">
                  Please check your{' '}
                  <span
                    style={{
                      textAlign: 'center',
                      textDecoration: 'underline',
                      textUnderlinePosition: 'from-font',
                    }}>
                    email
                  </span>{' '}
                  to activate your account.
                </Typography>
                <Button
                  className="madd-btn mt-2"
                  onClick={() => {
                    handleClose();
                    navigate('/login');
                  }}>
                  Done
                </Button>
              </Typography>
            </Box>
          </Modal>
        )}
      </Grid>
    </Grid>
  );
};
