import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
// import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
// import InfinityScroll from 'components/InfinityScroll';
// import Pagination from 'components/Pagination';
import { AttorneyPrivateRoutes } from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import useStyles from 'pages/private/PrivateStyle';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  // updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { InviteMailSchema } from 'utils/ValidatorSchema';

export const ManageOrganization: React.FC<any> = () => {
  const classes = useStyles();
  const location: any = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [tabValue, setTabValue] = useState(
    location?.state?.tab ? location?.state?.tab : 'invited',
  );
  const [invitedFirms, setInvitedFirms] = useState<any>({});
  const [associatedFirms, setAssociatedFirms] = useState<any>([]);
  const [myFirms, setMyFirms] = useState<any>([]);
  const [hasMoreResults1, setHasMoreResults1] = useState(false);
  const [getMoreLoader1, setGetMoreLoader1] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [resentInvite, setResentInvite] = useState(false);
  const [params, setParams] = useState<any>({
    firmMail: '',
    id: '',
    name: '',
    order: 'ASC',
    refererId: '',
    referredBy: '',
    skip: 0,
    sortby: 'firm_name',
    status: false,
    take: 50,
  });
  const debouncedParams = useDebounce(params, 500);

  const [confirmOpen, setConfirmOpen] = useState<any>({
    data: {},
    inviteConfirm: true,
    show: false,
  });

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const {
    control: control,
    handleSubmit: handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(InviteMailSchema),
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const reSendMail = (invitedData: any) => {
    if (userDetail.role_id === 3) {
      // attorney
      if (
        _.isEmpty(invitedData) ||
        _.isEmpty(invitedData.contactPersonEmails)
      ) {
        toast.error('Please add contact person details', alertOptions);
        return;
      }
      if (invitedData.contactPersonEmails.length > 1) {
        setConfirmOpen({
          data: invitedData,
          inviteConfirm: false,
          show: true,
        });
        return;
      }
      const inviteData = getInviteData(
        invitedData,
        invitedData.contactPersonEmails[0].email_id,
      );
      inviteLawfirmResendCall(inviteData);
    } else {
      setConfirmOpen({
        data: invitedData,
        inviteConfirm: true,
        show: true,
      });
    }
  };

  const chooseContactPerson = (data: any) => {
    if (invitedFirms.data.find((e) => e.firm_mail === data.email)) {
      setLoader(true);
      const inviteData = getInviteData(confirmOpen.data, data.email);
      inviteLawfirmResendCall(inviteData);
    } else {
      setResentInvite(true);
    }
  };

  const resentContactPerson = (data: any) => {
    setLoader(true);
    const inviteDatas = getInviteData(confirmOpen.data, getValues('email'));
    inviteLawfirmResendCall(inviteDatas);
    setResentInvite(false);
  };

  const getInviteData = (value: any, email: string) => {
    const inviteOrgData = {
      FirmName: !_.isEmpty(myFirms) ? myFirms.firm_name : '-',
      email,
      mailSend: true,
      module: value.refered_user_module,
      subject: 'Inviting you to join with  us',
    };
    return inviteOrgData;
  };

  const inviteLawfirmResendCall = (data: any) => {
    addNewData(data, SETUP_ENDPOINT.InviteResend)
      .then((resp: any) => {
        reset();
        toast.success('Invite Sent', alertOptions);
        setConfirmOpen({
          data: {},
          inviteConfirm: true,
          show: false,
        });
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (tabValue === 'invited') {
      getAllListData(
        `${SETUP_ENDPOINT.ManageOrganization}/list?id=${String(
          debouncedParams.id,
        )}&firmMail=${String(debouncedParams.firmMail)}&referredBy=${String(
          debouncedParams.referredBy,
        )}&refererId=${String(debouncedParams.refererId)}&status=${String(
          debouncedParams.status,
        )}&order=${String(debouncedParams.order)}&sortby=${String(
          debouncedParams.sortby,
        )}&name=${String(debouncedParams.name)}&skip=${String(
          debouncedParams.skip,
        )}&take=${String(debouncedParams.take)}`,
      )
        .then(async (resp: any) => {
          const { data, total } = resp ? resp : { data: [], total: 0 };
          const moreLoader = await instantState(setGetMoreLoader1);
          if (moreLoader) {
            setInvitedFirms((pre) => ({
              ...pre,
              data: [...pre.data, ...data],
            }));
          } else {
            const idsEle = document.getElementById('scrollParent');
            idsEle && (idsEle.scrollTop = 0);
            setInvitedFirms(resp);
          }
          setTableLoader(false);
          setLoader(false);
          setGetMoreLoader1(false);
          const allList = await instantState(setInvitedFirms);
          if (allList.data && allList.data.length < Number(total)) {
            setHasMoreResults1(true);
          } else {
            setHasMoreResults1(false);
          }
        })
        .catch((e: any) => {
          setTableLoader(false);
          setLoader(false);
        });
    }
    if (tabValue === 'associated') {
      getAllListData(
        `/${String(userDetail?.userRoleName)}${
          SETUP_ENDPOINT.ManageUsers
        }/petitioner?name=${String(debouncedParams.name)}&id=`,
      )
        .then((resp: any) => {
          setAssociatedFirms(resp);
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
  }, [debouncedParams, tabValue, userDetail?.userRoleName]);

  useEffect(() => {
    getAllListData(
      `/${String(userDetail?.userRoleName)}${SETUP_ENDPOINT.MyFirm}`,
    )
      .then((resp: any) => {
        setMyFirms(resp);
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader1(true);
      setParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12} className="pr-2rem">
        <Box sx={{ width: '100%' }} className="documentTab">
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Typography
                component={'div'}
                className="d-flex-a w-100 d-flex-sb mt-2">
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example">
                  <Tab label="Invited List" value="invited" />
                  <Tab label="Associated List" value="associated" />
                </TabList>
                {userDetail.role_id === 3 && (
                  <Typography component={'div'} sx={{ width: '200px' }}>
                    <CustomInput
                      placeHolder={'Type to filter'}
                      className={'custom-search-input'}
                      value={params.name}
                      inputProps={{
                        endAdornment:
                          params.name !== '' ? (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={() => {
                                  setTableLoader(true);
                                  setParams((prev) => ({
                                    ...prev,
                                    name: '',
                                    skip: 0,
                                    take: 50,
                                  }));
                                }}>
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                      }}
                      onChange={(e) => {
                        setTableLoader(true);
                        setParams((prev) => ({
                          ...prev,
                          name: e.target.value,
                          skip: 0,
                          take: 50,
                        }));
                      }}
                    />
                  </Typography>
                )}
              </Typography>
            </Box>
            {loader ? (
              <div className="liberty-loader" />
            ) : (
              <Typography component={'div'}>
                <TabPanel value="invited">
                  <Typography
                    sx={{
                      borderRadius: '10px 10px 0px 0px',
                    }}
                    className={classes.scrollbox}
                    id="scrollParent">
                    <InfiniteScroll
                      dataLength={
                        invitedFirms &&
                        invitedFirms.data &&
                        invitedFirms.data.length
                          ? invitedFirms.data.length
                          : 0
                      }
                      style={{
                        overflow: 'unset',
                      }}
                      next={getMoreList && getMoreList}
                      hasMore={hasMoreResults1}
                      scrollableTarget={'scrollParent'}
                      loader={
                        getMoreLoader1 && (
                          <div
                            style={{
                              backgroundColor: '#ffffff',
                              textAlign: 'center',
                              width: '100%',
                            }}>
                            <CircularProgress
                              size={30}
                              sx={{
                                color: '#002c69',
                                marginBottom: 10,
                                marginTop: 10,
                              }}
                            />
                          </div>
                        )
                      }
                      endMessage>
                      <table className="lead-table table-hover pointer">
                        <thead>
                          <tr>
                            <th className="W-1">Organization name</th>
                            <th className="W-3">Organization mail </th>
                            <th className="W-3">Refered By</th>
                            <th className="W-1">Invitation</th>
                            <th className="W-1">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableLoader ? (
                            <tr>
                              <td colSpan={8}>
                                <div
                                  style={{
                                    backgroundColor: '#ffffff',
                                    textAlign: 'center',
                                    width: '100%',
                                  }}>
                                  <CircularProgress
                                    size={30}
                                    sx={{
                                      color: '#002c69',
                                      marginBottom: 10,
                                      marginTop: 10,
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ) : (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                              {!_.isEmpty(invitedFirms?.data) &&
                              invitedFirms.data.length > 0
                                ? invitedFirms.data.map((user, index) => (
                                    <tr
                                      key={index}
                                      onClick={() =>
                                        navigate(
                                          `${String(
                                            AttorneyPrivateRoutes
                                              .MANAGEORGANIZATION
                                              .ORGANIZATIONINVITED
                                              .ORGANIZATIONINVITEDOVERVIEW,
                                          )}/${String(user.id)}`,
                                          {
                                            state: {
                                              data: user,
                                              tabValue: '1',
                                            },
                                          },
                                        )
                                      }>
                                      <td>
                                        {' '}
                                        {user.firm_name ? user.firm_name : '-'}
                                      </td>
                                      <td>
                                        {user.org_email ? user.org_email : '-'}
                                      </td>
                                      <td>
                                        {user.referred_by
                                          ? user.referred_by
                                          : '-'}
                                      </td>
                                      <td>
                                        {!user.accepted && (
                                          <Button
                                            variant="contained"
                                            style={{
                                              backgroundColor: '#002c69',
                                              borderRadius: '15px',
                                              cursor: 'poiner',
                                              fontSize: '11px',
                                              lineHeight: '2',
                                              minWidth: '120px',
                                              opacity: '1',
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              reSendMail(user);
                                            }}>
                                            {userDetail.role_id === 3
                                              ? 'Invite Resend'
                                              : 'Invite Accept'}
                                          </Button>
                                        )}
                                      </td>
                                      <td className="blue-text  ">
                                        {' '}
                                        {user.accepted
                                          ? 'Associated'
                                          : 'Pending'}
                                      </td>
                                    </tr>
                                  ))
                                : !tableLoader && (
                                    <tr className={'text-center'}>
                                      <td colSpan={8}>No Record(s) Found.</td>
                                    </tr>
                                  )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </Typography>
                </TabPanel>
                <TabPanel value="associated">
                  <Typography
                    component={'div'}
                    sx={{
                      borderRadius: '8px 8px 0 0',
                    }}
                    className={classes.scrollbox}>
                    <table className="lead-table table-hover pointer">
                      <thead>
                        <tr>
                          <th className="W-2">Organization name</th>
                          <th className="W-3">Organization mail</th>
                          <th className="W-3">Refered By</th>
                          <th className="W-1">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {associatedFirms && associatedFirms.length > 0 ? (
                          associatedFirms.map((item, index) => (
                            <tr
                              key={index}
                              onClick={() =>
                                navigate(
                                  `${
                                    AttorneyPrivateRoutes.MANAGEORGANIZATION
                                      .ORGANIZATIONASSOCIATED
                                      .ORGANIZATIONASSOCIATEDOVERVIEW
                                  }/${String(item.orgId)}`,
                                  {
                                    state: {
                                      tabValue: '2',
                                    },
                                  },
                                )
                              }>
                              <td>{item.orgName ? item.orgName : '-'}</td>
                              <td>{item.orgEmail ? item.orgEmail : '-'}</td>
                              <td>
                                {item.referred_by ? item.referred_by : '-'}
                              </td>
                              <td>Associated</td>
                            </tr>
                          ))
                        ) : (
                          <tr className={'text-center'}>
                            <td colSpan={8}>No Record(s) Found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Typography>
                </TabPanel>
              </Typography>
            )}
          </TabContext>
        </Box>

        <CustomModalView
          open={confirmOpen.show}
          fullWidth={true}
          title={'Invite Confirmation!'}
          maxWidth={'sm'}
          scroll={'paper'}
          autoCloseDisable={false}
          close={() =>
            setConfirmOpen({
              data: {},
              inviteConfirm: true,
              show: false,
            })
          }>
          {!confirmOpen.inviteConfirm ? (
            <>
              <DialogContent>
                <form
                  id="invite-form"
                  onSubmit={handleSubmit(chooseContactPerson)}>
                  <Grid container>
                    <Grid item md={12}>
                      <Typography
                        component={'div'}
                        style={{ minHeight: '150px' }}
                        className="custom-field u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Choose Email <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name="email"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'email_id'}
                                valueKey={'email_id'}
                                options={
                                  !_.isEmpty(
                                    confirmOpen.data?.contactPersonEmails,
                                  ) && !_.isEmpty(confirmOpen.data)
                                    ? confirmOpen.data?.contactPersonEmails
                                    : []
                                }
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.email?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </DialogContent>
              <DialogActions>
                <Typography component={'div'} className="d-flex-jae mt-1 ">
                  <Button
                    className="n-add-btn"
                    type="submit"
                    form={'invite-form'}
                    sx={{
                      borderRadius: '5px !important',
                      fontFamily: 'Arial-Regular !important',
                      fontSize: '0.9em !important',
                      height: '35px !important',
                      width: '149px !important',
                    }}>
                    Submit
                  </Button>
                </Typography>
              </DialogActions>
            </>
          ) : (
            <Typography className="black-white-text ctitle">
              You have invited from {confirmOpen.data?.referred_by}
            </Typography>
          )}
        </CustomModalView>
        {/* </ConfirmDialog> */}
        <ConfirmDialog
          title={'Resent Invite'}
          floatingBtnShow={true}
          open={resentInvite}
          onConfirm={(act) =>
            act === 'yes' ? resentContactPerson(act) : setResentInvite(false)
          }
          autoCloseDisable={true}>
          Invite already sent . Now sending to {getValues('email')}. New invite
          will become active, old invite will become inactive. Confirm update?
        </ConfirmDialog>
      </Grid>
    </Grid>
  );
};

export default ManageOrganization;
