import { Button, Typography } from '@mui/material';
import Logo from 'assets/images/liberty-logoheader.png';
import Success from 'assets/images/payment-check.png';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const StripeSucessPayment = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Typography
        className=" black-white-text p-f pt-2"
        sx={{
          backgroundColor: '#000',
          height: '84px',
          position: 'sticky',
          top: '0',
        }}>
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <div className="innerBox payment-public">
        <img
          src={Success}
          alt="Success"
          style={{ marginBottom: '30px', marginTop: '60px' }}
        />
        <Typography className="suc-title">Payment Successful</Typography>
        <Typography className="suc-dis">
          Your payment was successfully processed. We have sent you the
          acknowledgement and the payment receipt to your registered Email ID.
          We have notified your attorney. Please discuss with your attorney to
          proceed with your case.
        </Typography>{' '}
        <Typography className="suc-dis">
          If you would like to request for any Support in the meantime, please
          contact us at{' '}
          <a href={`mailto:${String(process.env.REACT_APP_CONTACT_MAIL)}`}>
            <span style={{ color: 'rgba(25, 82, 167, 1)' }}>
              {String(process.env.REACT_APP_CONTACT_MAIL)}
            </span>
          </a>
        </Typography>
        <Button className="success-btn-new" onClick={() => navigate('/login')}>
          Done
        </Button>
      </div>
    </div>
  );
};

const StripeInprocessPayment = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Typography
        className=" black-white-text p-f pt-2"
        sx={{
          backgroundColor: '#000',
          height: '84px',
          position: 'sticky',
          top: '0',
        }}>
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <div className="innerBox payment-public">
        <img
          src={Success}
          alt="Success"
          style={{ marginBottom: '30px', marginTop: '60px' }}
        />
        <Typography className="suc-title">Payment is In-process</Typography>
        <Typography className="suc-dis">
          We are currently processing your payment and still waiting on a
          confirmation from your Bank. We will send you an Email notification as
          soon as we get a confirmation from your Bank.
        </Typography>{' '}
        <Typography className="suc-dis">
          If you do not receive an Email to your registered Email ID within 30
          minutes, please write to us at{' '}
          <a href={`mailto:${String(process.env.REACT_APP_CONTACT_MAIL)}`}>
            <span style={{ color: 'rgba(25, 82, 167, 1)' }}>
              {String(process.env.REACT_APP_CONTACT_MAIL)}
            </span>
          </a>{' '}
          providing your registered email address for us to check and get back
          to you.
        </Typography>
        <Button className="success-btn-new" onClick={() => navigate('/login')}>
          Done
        </Button>
      </div>
    </div>
  );
};

export { StripeSucessPayment, StripeInprocessPayment };
