import './assemble.css';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  // Badge,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Tab,
  Tooltip,
  Typography,
} from '@mui/material';
import Badge from '@mui/material/Badge';
// import Backbg from 'assets/images/asbg.svg';
// import FirstA from 'assets/images/asfarrow.svg';
// import SecondA from 'assets/images/assarrow.svg';
// import ThirdA from 'assets/images/astarrow.svg';
import Assemble from 'assets/images/assemble.png';
import Bsend from 'assets/images/b-send.png';
import Cedit from 'assets/images/gedit.png';
import img from 'assets/images/image-icon.png';
import Pdf from 'assets/images/pdf-new.png';
import wrd from 'assets/images/word.png';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomCheckBox from 'components/CustomCheckBox/customCheckBox';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
// import CustomSelect from 'components/CustomSelect/customSelect';
import { FileBulkUploadDialog } from 'components/Fileupload/fileBulkUploadDialog';
// import saveAs from 'file-saver';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
  getS3file,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import {
  documentFileUpdateSchema,
  documentRomanUpdateSchema,
} from 'utils/ValidatorSchema';

import { NestedListComponent } from './nestedList';
import { SendEmail } from './SendEmail';
import { AssembleTreeView } from './Treeview';

// interface deleteCheck {
//   id: string;
//   index: number;
//   status: boolean;
// }
export const AssembleDragDrop: React.FC<any> = () => {
  const { petitionId } = useParams();
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const wizardAttachmentRef: any = useRef();
  const wizardExhibtRef: any = useRef();
  const [treeOrderRecall, setTreeOrderRecall] = useState(null);
  const [buildDocuments, setBuildDocuments] = useState({
    list: [],
    open: false,
  });
  const [uploadDocuments, setUploadDocuments] = useState({
    list: [],
    open: false,
  });
  const [assambleReview, setAssambleReview] = useState({
    deleteId: '',
    deleteOpen: false,
    fileUrl: '',
    list: [],
    open: false,
  });
  const [sendToModal, setSendToModal] = useState({
    open: false,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteChecked, setDeleteChecked] = useState([]);

  const handlemodelClose = () => {
    setDeleteModal(false);
  };
  const handlemodelOpen = () => {
    setDeleteModal(true);
    setDeleteChecked([]);
  };
  const [loader, setLoader] = useState(true);
  const [assembles, setAssembles] = useState<any>({
    count: null,
    data: [],
  });
  const [tabValue, setTabValue] = useState('1');
  const [typeOfAssembles, setTypeOfAssembles] = useState({
    attachment: [],
    brief: [],
    coverPage: [],
    exhibit: [],
    selectedBrief: [],
  });
  const [isShowFileEditModal, setIsShowFileEditModal] = useState<any>({
    fileName: '',
    id: null,
    show: false,
  });
  const [isShowRomanEditModal, setIsShowRomanEditModal] = useState({
    assemblePetitionId: '',
    documentRoman: '',
    documentType: '',
    id: null,
    show: false,
    type: '',
  });
  const [isDeleteDocument, setIsDeleteDocument] = useState<any>({
    id: null,
    show: false,
  });
  const [isDeleteAssembles, setIsDeleteAssembles] = useState<any>({
    id: null,
    show: false,
    type: '',
  });
  const [isDeleteChildDoc, setIsDeleteChildDoc] = useState({
    deleteId: '',
    show: false,
    type: '',
  });
  const [deleteDialog, setDeleteDialog] = React.useState({
    id: '',
    show: false,
  });
  const [params, setParams] = useState({
    id: '',
    order: 'DESC',
    search: '',
    skip: '',
    sortby: '',
    take: '',
  });
  const [teamMembers, setTeamMembers] = useState<any>([]);
  const [sendList, setSendList] = React.useState({
    list: [],
    visible: true,
  });

  const debouncedParams = useDebounce(params, 500);

  let attachmentNum = 1;
  let exhibitNum = 1;

  const {
    control: control,
    handleSubmit: handleSubmit,
    setValue,
    reset: resetForm,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      fileName: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(documentFileUpdateSchema),
  });

  const {
    control: controlRoman,
    handleSubmit: handleRomanSubmit,
    setValue: setRomanValue,
    reset: resetRomanForm,
    formState: { errors: errorsRoman },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      documentName: '',
      documentRoman: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(documentRomanUpdateSchema),
  });

  const onUpdateFileNameSubmit = (data: any) => {
    if (isShowFileEditModal.id) {
      const fileNameWithoutExtension: string = data.fileName;
      const payload = {
        fileName: `${fileNameWithoutExtension}`,
      };

      updateData(
        String(isShowFileEditModal.id),
        payload,
        COMMON_ENDPOINT.AssemblePetition,
      )
        .then((data) => {
          getAssembles();
          getLatestBriefOrCover();
          resetForm();
          setIsShowFileEditModal({
            fileName: '',
            id: null,
            show: false,
          });
          toast.success('Updated successfully', alertOptions);
        })
        .catch((err) => {
          toast.error(err, alertOptions);
        });
    }
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const onUpdateRomanNameSubmit = async (data: any) => {
    const isShowRomanEditModal = await instantState(setIsShowRomanEditModal);
    try {
      if (isShowRomanEditModal.id || isShowRomanEditModal.assemblePetitionId) {
        setLoader(true);
        // if (
        //   isShowRomanEditModal.documentRoman &&
        //   String(isShowRomanEditModal.documentRoman).trim()
        // ) {
        const payload = {
          documentRoman:
            data.documentRoman && String(data.documentRoman).trim()
              ? isShowRomanEditModal.type === 'parent'
                ? `[${String(data.documentRoman)}]`
                : `(${String(data.documentRoman)})`
              : '',
        };

        await updateData(
          String(isShowRomanEditModal.id),
          payload,
          `${COMMON_ENDPOINT.AssemblePetition}/document-roman`,
        );
        // }
        if (isShowRomanEditModal.assemblePetitionId) {
          const bodyData = {
            fileName: data.documentName,
          };
          await updateData(
            isShowRomanEditModal.assemblePetitionId,
            bodyData,
            `${COMMON_ENDPOINT.AssemblePetition}`,
          );
        }
        toast.success('Updated successfully', alertOptions);
        getAssembles();
        resetRomanForm();
        setIsShowRomanEditModal({
          assemblePetitionId: '',
          documentRoman: '',
          documentType: '',
          id: null,
          show: false,
          type: '',
        });
      }
    } catch (error) {
      setLoader(false);
    }
    if (isShowRomanEditModal.documentType === 'header') {
      addNewNodeAPiCall(data, isShowRomanEditModal.type);
    }
  };

  const addNewNodeAPiCall = (data: any, type: string) => {
    const bodyData = {
      documentType: 'header',
      headingName: data.documentName,
      parentDocumentId: null,
      petitionId: Number(petitionId),
      romanName:
        data.documentRoman && String(data.documentRoman).trim()
          ? `[${String(data.documentRoman)}]`
          : '',
      type,
    };
    setLoader(true);
    addNewData(bodyData, `${COMMON_ENDPOINT.AssemblePetition}/document-header`)
      .then(() => {
        toast.success('Added successfully', alertOptions);
        getAssembles();
        resetRomanForm();
        setIsShowRomanEditModal((pre) => ({
          ...pre,
          assemblePetitionId: '',
          documentType: '',
          id: null,
          show: false,
        }));
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const deleteAssemble = (action) => {
    if (action === 'yes') {
      deleteData(isDeleteDocument.id, COMMON_ENDPOINT.AssemblePetition)
        .then((resp: any) => {
          toast.success('Deleted successfully', alertOptions);
          setIsDeleteDocument({
            id: null,
            show: false,
          });
          getAssembles();
        })
        .catch((e) => {
          setIsDeleteDocument({
            id: null,
            show: false,
          });
          setLoader(false);
        });
    }
  };

  const deleteAE = (action) => {
    if (action === 'yes') {
      deleteData(
        isDeleteAssembles.id,
        `${COMMON_ENDPOINT.AssemblePetition}/document`,
      )
        .then((resp: any) => {
          setIsDeleteAssembles({ id: null, show: false, type: '' });
          getAssembles();
          getLatestBriefOrCover();
        })
        .catch((e) => {
          setIsDeleteAssembles({ id: null, show: false, type: '' });
          setLoader(false);
        });
    }
  };

  const deleteChildAE = (id) => {
    setIsDeleteChildDoc((pre) => ({
      ...pre,
      deleteId: id,
      show: true,
    }));
  };
  const deleteChildApi = async () => {
    setLoader(true);
    const childDelete = await instantState(setIsDeleteChildDoc);
    deleteData(
      childDelete.deleteId,
      `${COMMON_ENDPOINT.AssemblePetition}/document`,
    )
      .then(() => {
        setLoader(false);
        setIsDeleteChildDoc((pre) => ({
          ...pre,
          deleteId: null,
          show: false,
        }));
        getAssembles();
        getLatestBriefOrCover();
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  const getAssembles = (assambleGetApiOnlyCall?) => {
    getAllListData(
      `${String(COMMON_ENDPOINT.AssemblePetition)}/${String(
        petitionId,
      )}?id=${String(debouncedParams.id)}&search=${String(
        debouncedParams.search,
      )}&skip=${String(debouncedParams.skip)}&take=${String(
        debouncedParams.take,
      )}&order=${String(debouncedParams.order)}&sortby=${String(
        debouncedParams.sortby,
      )}`,
    )
      .then((assAllResp: any) => {
        setAssembles({
          count: assAllResp.count,
          data: assAllResp.data,
        });
        setTimeout(() => {
          setTreeOrderRecall(Date.now());
        }, 500);

        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
    if (!assambleGetApiOnlyCall) {
      getAllListData(
        `${String(COMMON_ENDPOINT.AssemblePetition)}/document/${String(
          petitionId,
        )}?type=attachment`,
      )
        .then((attachmentResp: any) => {
          setTypeOfAssembles((pre) => ({
            ...pre,
            attachment: attachmentResp.length > 0 ? attachmentResp : [],
          }));
        })
        .catch((e: any) => {
          setLoader(false);
        });
      getAllListData(
        `${String(COMMON_ENDPOINT.AssemblePetition)}/document/${String(
          petitionId,
        )}?type=exhibit`,
      )
        .then((exhibitResp: any) => {
          setTypeOfAssembles((pre) => ({
            ...pre,
            exhibit: exhibitResp.length > 0 ? exhibitResp : [],
          }));
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const DeleteAll = () => {
    const deletedArr = [];
    assembles?.data.filter((e) => {
      if (!deleteChecked.includes(e.id)) {
        deletedArr.push(e.id);
      }
    });

    updateData(
      '',
      {
        AssembleDocumentId: deletedArr,
      },
      `${String(COMMON_ENDPOINT.AssemblePetition)}/delete/all/${String(
        petitionId,
      )}`,
    ).then((resp: any) => {
      toast.success('Deleted successfully', alertOptions);
      getAssembles();
      getLatestBriefOrCover();
      handlemodelClose();
      setConfirmDeleteModal(false);
    });
  };

  const onDragEnd = (result: any, provided?: any) => {
    const { type, source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (
      type === 'droppable-item-attachment' ||
      type === 'droppable-category-attachment'
    ) {
      if (
        wizardAttachmentRef &&
        wizardAttachmentRef.current &&
        wizardAttachmentRef.current.reOrder
      ) {
        wizardAttachmentRef.current.reOrder(result);
      }
      return;
    }

    if (
      type === 'droppable-item-exhibit' ||
      type === 'droppable-category-exhibit'
    ) {
      if (
        wizardExhibtRef &&
        wizardExhibtRef.current &&
        wizardExhibtRef.current.reOrder
      ) {
        wizardExhibtRef.current.reOrder(result);
      }
      return;
    }
    // const sourceCategoryId = source.droppableId;
    const destinationCategoryId = destination.droppableId;

    const payload = {
      documentId: assembles.data[source?.index]?.id,
      documentType: 'doc',
      parentDocumentId:
        destinationCategoryId !== 'attachment-parent'
          ? Number(
              destinationCategoryId.split('-')[
                destinationCategoryId.split('-').length - 1
              ],
            )
          : null,
      petitionId: Number(petitionId),
      romanName: null,
      type: destinationCategoryId.split('-')[0],
    };

    if (
      ['brief'].includes(destinationCategoryId?.split('-')[0]) &&
      payload.documentId
    ) {
      addBrief([payload.documentId]);
    } else {
      addNewData(payload, `${COMMON_ENDPOINT.AssemblePetition}/document`)
        .then((resp: any) => {
          toast.success('Added successfully', alertOptions);
          if (payload?.type === 'coverPage') {
            getLatestBriefOrCover();
          } else {
            getAssembles();
          }
        })
        .catch((err) => {
          // setLoader(false);
        });
    }
  };

  const addNewNode = (type: string) => {
    setRomanValue('documentName', '');
    setRomanValue('documentRoman', '');
    setIsShowRomanEditModal((pre) => ({
      ...pre,
      assemblePetitionId: '',
      documentRoman: '',
      documentType: 'header',
      id: '',
      show: true,
      type: type,
    }));
  };

  const getBuildAssemble = () => {
    getAllListData(
      `${COMMON_ENDPOINT.AssemblePetition}/merge/pdf/${String(
        petitions?.petitionData?.id,
      )}?isAffilate=${
        petitions?.petitionData?.isAffilateCase
          ? String(petitions?.petitionData?.isAffilateCase)
          : ''
      }&affilateRoleId=${
        petitions?.petitionData?.isAffilateCase
          ? String(petitions?.petitionData?.affilateRoleId)
          : ''
      }&firmId=${
        petitions?.petitionData?.attorney_assigned
          ? String(petitions?.petitionData?.attorney_assigned)
          : ''
      }`,
    )
      .then((res) => {
        setAssambleReview((pre) => ({
          ...pre,
          list: res && Array.isArray(res) && res.length ? res : [],
        }));
      })
      .catch((err) => {});
  };
  const buildAssamble = () => {
    if (assambleReview.fileUrl) {
      URL.revokeObjectURL(assambleReview.fileUrl);
    }
    setAssambleReview((pre) => ({
      ...pre,
      fileUrl: '',
    }));
    setLoader(true);
    addNewData(
      {},
      `${COMMON_ENDPOINT.AssemblePetition}/merge/pdf/${String(
        petitions?.petitionData?.id,
      )}/${String(petitions?.petitionData?.visa_id)}?isAffilate=${
        petitions?.petitionData?.isAffilateCase
          ? String(petitions?.petitionData?.isAffilateCase)
          : ''
      }&affilateRoleId=${
        petitions?.petitionData?.isAffilateCase
          ? String(petitions?.petitionData?.affilateRoleId)
          : ''
      }&firmId=${
        petitions?.petitionData?.attorney_assigned
          ? String(petitions?.petitionData?.attorney_assigned)
          : ''
      }`,
      {
        responseType: 'blob',
      },
    )
      .then((blobRes: any) => {
        getBuildAssemble();
        const resUrl = URL.createObjectURL(blobRes);
        window.open(resUrl);
        // Previw not shown in Popup model
        setAssambleReview((pre) => ({
          ...pre,
          fileUrl: resUrl,
        }));
        setLoader(false);
      })
      .catch(() => {
        toast.error('Something went wrong, Please try again.', alertOptions);
        setAssambleReview((pre) => ({
          ...pre,
          fileUrl: '',
        }));
        setLoader(false);
      });
  };
  const deleteBuildVersion = (id) => {
    setLoader(true);
    deleteData(
      id,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${COMMON_ENDPOINT.AssemblePetition}/merge/pdf`,
    )
      .then((succ) => {
        setLoader(false);
        toast.success('File deleted Successfully', alertOptions);
        setAssambleReview((pre) => ({
          ...pre,
          deleteId: '',
          deleteOpen: false,
          list: pre.list.filter((e: any) => e.id !== id),
          ...(pre.list.filter((e: any) => e.id !== id).length === 0
            ? { open: false }
            : {}),
        }));
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const openFileToNewWindow = (fileKey) => {
    const obj = {
      key: fileKey,
    };
    setLoader(true);
    // const fileName = fileKey.split('/').pop();
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        if (assambleReview.fileUrl) {
          URL.revokeObjectURL(assambleReview.fileUrl);
        }
        const resUrl = URL.createObjectURL(data);
        setAssambleReview((pre) => ({
          ...pre,
          fileUrl: resUrl,
        }));
        window.open(resUrl);
        setLoader(false);
        // saveAs(data, fileName);
        // toast.success(`${String(fileName)} File Downloaded`, alertOptions);
      })
      .catch(() => {
        toast.error(
          'Something went wrong, Please try again later',
          alertOptions,
        );
        setLoader(false);
      });
  };
  const getLatestBriefOrCover = useCallback(() => {
    getAllListData(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${COMMON_ENDPOINT.AssemblePetition}/build-cover/brief/${String(
        petitions?.petitionData?.id,
      )}/${String(petitions?.petitionData?.visa_id)}?isAffilate=${
        petitions?.petitionData?.isAffilateCase
          ? String(petitions?.petitionData?.isAffilateCase)
          : ''
      }&affilateRoleId=${
        petitions?.petitionData?.isAffilateCase
          ? String(petitions?.petitionData?.affilateRoleId)
          : ''
      }&firmId=${
        petitions?.petitionData?.attorney_assigned
          ? String(petitions?.petitionData?.attorney_assigned)
          : ''
      }`,
    )
      .then((res: { coverPage: Array<any>; brief: Array<any> }) => {
        setTimeout(() => {
          setTreeOrderRecall(Date.now());
        }, 500);
        setTypeOfAssembles((pre) => ({
          ...pre,
          ...res,
        }));
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, [
    petitions?.petitionData?.affilateRoleId,
    petitions?.petitionData?.attorney_assigned,
    petitions?.petitionData?.id,
    petitions?.petitionData?.isAffilateCase,
    petitions?.petitionData?.visa_id,
  ]);
  const getBuildDocuments = useCallback(
    (dependentUserId?) => {
      let url: string =
        String(COMMON_ENDPOINT.DocumentBuild) +
        '/' +
        String(petitionId) +
        '/' +
        String(
          petitions && petitions.petitionData
            ? petitions.petitionData.visa_id
            : '',
        );
      if (dependentUserId) {
        url =
          url +
          `?formType=dependent&dependentUserId=${String(dependentUserId)}`;
      }
      getAllListData(url)
        .then((res: any) => {
          setLoader(false);
          setBuildDocuments((pre) => ({
            ...pre,
            list:
              res && Array.isArray(res) && res.length
                ? res
                    .filter(
                      (e: any) =>
                        e.latestBuild &&
                        Array.isArray(e.latestBuild) &&
                        e.latestBuild.length,
                    )
                    .map((val) => ({ ...val, checked: false }))
                : [],
          }));
        })
        .catch(() => {
          setLoader(false);
        });
    },
    [petitionId, petitions],
  );

  const getUploadDocumentData = () => {
    setLoader(true);
    getAllListData(
      `${String(COMMON_ENDPOINT.ReviewDocuments)}/${String(
        petitionId,
      )}/${String(petitions?.petitionData?.beneficiary_id)}`,
    )
      .then((res) => {
        const emptyDocList = [];
        res &&
          res.length &&
          res.map((docs) => {
            if (
              docs.multipleDocumentsList &&
              docs.multipleDocumentsList.length
            ) {
              docs.multipleDocumentsList.map((list) => {
                emptyDocList.push({
                  ...list,
                  checked: false,
                  document_type: docs.document_type,
                  module_name: docs.module_name,
                });
              });
            }
          });

        setUploadDocuments((pre) => ({
          ...pre,
          list: emptyDocList,
        }));
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const UploadDocumnetFile = () => {
    const selectedDocument = uploadDocuments?.list.filter((e) => e.checked);
    if (selectedDocument && selectedDocument?.length) {
      setLoader(true);

      const documentId = selectedDocument.map((e) => e.documentId);
      addNewData(
        {
          documentId: documentId,
          petitionId: Number(petitionId),
        },
        `${String(COMMON_ENDPOINT.AssemblePetition)}/upload-document`,
      )
        .then(() => {
          setUploadDocuments((pre) => ({
            ...pre,
            open: false,
          }));
          getAssembles('assembleGetApiOnly');
          setLoader(false);
          toast.success('Files uploaded successfully.', alertOptions);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const UploadFile = () => {
    const selectedTemplate = buildDocuments.list.filter((e) => e.checked);
    if (selectedTemplate && selectedTemplate.length) {
      setLoader(true);
      addNewData(
        {
          petitionId: Number(petitionId),
          templateId: selectedTemplate.map((e) => e.current_form_id),
        },
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${COMMON_ENDPOINT.AssemblePetition}/build-document`,
      )
        .then(() => {
          setBuildDocuments((pre) => ({
            ...pre,
            open: false,
          }));
          getAssembles('assembleGetApiOnly');
          setLoader(false);
          toast.success('Files uploaded successfully.', alertOptions);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const getSendEmailList = React.useCallback(
    (loaderEnable?: any) => {
      getAllListData(
        `${
          COMMON_ENDPOINT.AssemblePetition
        }/merge/pdf/send/${petitionId}?isAffilate=${
          petitions?.petitionData?.isAffilateCase
            ? String(petitions?.petitionData?.isAffilateCase)
            : ''
        }&affilateRoleId=${
          petitions?.petitionData?.isAffilateCase
            ? String(petitions?.petitionData?.affilateRoleId)
            : ''
        }&firmId=${
          petitions?.petitionData?.attorney_assigned
            ? String(petitions?.petitionData?.attorney_assigned)
            : ''
        }`,
      )
        .then((resp: any) => {
          setSendList((pre) => ({
            ...pre,
            list: Array.isArray(resp) && resp.length ? resp : [],
            visible: Array.isArray(resp) && resp.length ? true : false,
          }));
          loaderEnable && setLoader(false);
        })
        .catch(() => {
          loaderEnable && setLoader(false);
        });
    },
    [
      petitionId,
      petitions?.petitionData?.affilateRoleId,
      petitions?.petitionData?.attorney_assigned,
      petitions?.petitionData?.isAffilateCase,
    ],
  );

  const teamMembersEmail = () => {
    getAllListData(
      `${String(COMMON_ENDPOINT.Email)}/${String(petitionId)}?type=petition`,
    )
      .then((emailResp: any) => {
        setTeamMembers(
          Array.isArray(emailResp) && emailResp.length
            ? emailResp.map((row: any) => ({ label: row, value: row }))
            : [],
        );
      })
      .catch((e: any) => {});
  };

  const addBrief = async (templateIds: number[]) => {
    // const templateIds = typeOfAssembles?.brief
    //   .filter((e) => e.checked)
    //   .map((ch) => ch.id);
    if (templateIds.length) {
      setLoader(true);
      try {
        const arrPromise = await templateIds.map(async (id) => {
          const obj = {
            templateId: id,
          };
          const url = petitions?.petitionData?.isAffilateCase
            ? `?isAffilate=true&firmId=${String(
                petitions?.petitionData?.attorney_assigned,
              )}&affilateRoleId=${String(
                petitions?.petitionData?.affilateRoleId,
              )}`
            : '';
          await addNewData(
            obj,
            `${COMMON_ENDPOINT.AssemblePetition}/build-cover/brief/${String(
              petitions?.petitionData?.id,
            )}${String(url)}`,
          );
        });
        await Promise.all(arrPromise);
        toast.success('Brief Added Successfully', alertOptions);
        // setBrief((pre) => ({
        //   ...pre,
        //   open: false,
        // }));
        getLatestBriefOrCover();
      } catch (error) {
        setLoader(false);
        toast.error(error ? error : 'Something went wrong.', alertOptions);
      }
    }
  };

  const deleteBrief = React.useCallback(async () => {
    const deleteDialog = await instantState(setDeleteDialog);
    setLoader(true);
    const url = petitions?.petitionData?.isAffilateCase
      ? `?isAffilate=true&firmId=${String(
          petitions?.petitionData?.attorney_assigned,
        )}&affilateRoleId=${String(petitions?.petitionData?.affilateRoleId)}`
      : '';
    deleteData(
      '',
      `${COMMON_ENDPOINT.AssemblePetition}/build-cover/brief/${String(
        petitions?.petitionData?.id,
      )}/${String(deleteDialog?.id)}${String(url)}`,
    )
      .then((e) => {
        setDeleteDialog((pre) => ({
          ...pre,
          show: false,
        }));
        toast.success('Brief Removed Successfully', alertOptions);
        getLatestBriefOrCover && getLatestBriefOrCover();
      })
      .catch(() => {
        setLoader(false);
      });
  }, [getLatestBriefOrCover, petitions?.petitionData]);

  useEffect(() => {
    if (!_.isUndefined(petitionId) && !_.isEmpty(petitions)) {
      getAssembles();
      getBuildAssemble();
      getBuildDocuments();
      getLatestBriefOrCover();
      teamMembersEmail();
      getSendEmailList();
    }
    return () => {
      if (assambleReview.fileUrl) {
        URL.revokeObjectURL(assambleReview.fileUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitions, debouncedParams]);

  // findDocumentName
  const findDocumentName = (typeOfAssembles, doc) => {
    const documentTypeNames = [];
    typeOfAssembles.attachment.find(
      (x) =>
        x.assemble_petition_id === doc.id ||
        x?.documents_list.find((x) => x.assemble_petition_id === doc.id),
    )
      ? documentTypeNames.push('Attachment')
      : null;
    // Exhibit
    typeOfAssembles.exhibit.find(
      (x) =>
        x.assemble_petition_id === doc.id ||
        x.documents_list.find((x) => x.assemble_petition_id === doc.id),
    )
      ? documentTypeNames.push('Exhibit')
      : null;
    // Brief
    Array.isArray(typeOfAssembles?.selectedBrief) &&
    typeOfAssembles?.selectedBrief.find((x) => x.id === doc.id)
      ? documentTypeNames.push('Brief')
      : null;
    // Cover Page
    Array.isArray(typeOfAssembles?.coverPage) &&
    typeOfAssembles?.coverPage.some((x) => x.assemble_petition_id === doc.id)
      ? documentTypeNames.push('Cover Page')
      : null;
    return documentTypeNames.join(',');
  };

  return (
    <Grid container>
      {!_.isEmpty(petitions) && petitions?.petitionData ? (
        <Grid item md={10} className="sticky-tab">
          <Typography component={'div'} className="d-flex-ja d-flex-sb">
            <Typography
              component={'div'}
              className="black-white-text"
              sx={{
                fontFamily: 'Arial-Bold !important',
                fontSize: '1.25em !important',
                marginLeft: '15px',
              }}>
              {petitions?.petitionData?.visa_type} {'for'}{' '}
              {petitions?.petitionData?.benificiery_name}
            </Typography>

            {/* <Typography component={'div'}>
              {Array.isArray(assambleReview.list) &&
              assambleReview.list.length ? (
                <Badge
                  badgeContent={
                    Array.isArray(assambleReview.list)
                      ? assambleReview.list.length
                      : 0
                  }
                  sx={{
                    marginLeft: '15px',
                  }}
                  color={'error'}>
                  <Button
                    className="cancel-btn"
                    onClick={() => {
                      setAssambleReview((pre) => ({
                        ...pre,
                        open: true,
                      }));
                    }}>
                    <Typography className="o-box" />
                    Previous Versions{' '}
                  </Button>
                </Badge>
              ) : (
                ''
              )}
              {(typeOfAssembles.attachment &&
                typeOfAssembles.attachment.length) ||
              (typeOfAssembles.coverPage && typeOfAssembles.coverPage.length) ||
              (typeOfAssembles.exhibit && typeOfAssembles.exhibit.length) ? (
                <Button
                  className="add-btn ml-2"
                  onClick={() => buildAssamble()}>
                  Build
                </Button>
              ) : (
                ''
              )}
            </Typography> */}
          </Typography>
        </Grid>
      ) : (
        ''
      )}
      <Grid container>
        {loader ? <div className="liberty-loader" /> : ''}
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid item md={10} sm={12}>
            <Grid container columnSpacing={{ md: 2, sm: 1 }}>
              <Grid item md={6} sm={12}>
                <Typography
                  component={'div'}
                  className="assem-box mt-1"
                  sx={{
                    top: '135px',
                    zIndex: 1,
                  }}>
                  <Typography
                    component={'div'}
                    className="d-flex-a d-flex-sb assem-head">
                    <Typography
                      className="f-18 font-bold as-title"
                      sx={{ padding: '1rem .5rem 1rem 1rem !important' }}>
                      Documents
                    </Typography>
                    <Typography
                      component={'div'}
                      className="d-flex-a d-flex-sb">
                      <Typography
                        sx={{
                          border: '1px solid #cccccc',
                          borderRadius: '5px',
                          height: '34px',
                          marginRight: '.5rem',
                          width: '162px',
                        }}>
                        <CustomInput
                          placeHolder={'Search'}
                          className={'assemble-search '}
                          value={params.search}
                          inputProps={{
                            endAdornment:
                              params.search !== '' ? (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={() =>
                                      setParams((prev) => ({
                                        ...prev,
                                        search: '',
                                      }))
                                    }>
                                    <CloseIcon />
                                  </IconButton>
                                </InputAdornment>
                              ) : null,
                          }}
                          onChange={(e) => {
                            setParams((prev) => ({
                              ...prev,
                              search: e.target.value.replace(
                                /[^a-zA-Z0-9]]/,
                                '',
                              ),
                            }));
                          }}
                        />
                      </Typography>
                      <FileBulkUploadDialog
                        petitionId={petitionId}
                        callAssembleList={getAssembles}
                        uploadType={'assembleDocumentUpload'}
                        getUploadDocumentData={getUploadDocumentData}
                        openDialog={() =>
                          setBuildDocuments((pre) => ({
                            ...pre,
                            list: pre.list.map((e) => ({
                              ...e,
                              checked: false,
                            })),
                            open: true,
                          }))
                        }
                        openUploadDialog={() =>
                          setUploadDocuments((pre) => ({
                            ...pre,
                            list: pre.list.map((e) => ({
                              ...e,
                              checked: false,
                            })),
                            open: true,
                          }))
                        }
                      />
                    </Typography>
                  </Typography>
                  <Box
                    sx={{
                      height: 'calc(100vh - 292px) !important',
                      // height: '394px',
                      overflowY: 'auto',
                      padding: '16px',
                      width: '100%',
                    }}
                    className="asseTab">
                    <Droppable
                      droppableId="assemblesListItems"
                      isDropDisabled={true}>
                      {(droppableProvided: DroppableProvided) => (
                        <Typography
                          className="as-list"
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.dragHandleProps}>
                          <Typography
                            sx={{
                              flexDirection: 'column',
                              overflow: 'hidden',
                            }}>
                            {assembles.data.length > 0 ? (
                              assembles.data.map((doc: any, index) => {
                                return (
                                  <Draggable
                                    key={index}
                                    draggableId={String(index)}
                                    index={index}>
                                    {(
                                      draggableProvided: DraggableProvided,
                                      snapshot: DraggableStateSnapshot,
                                    ) => {
                                      return (
                                        <Typography
                                          className="as-item"
                                          component={'div'}
                                          key={index}
                                          ref={draggableProvided.innerRef}
                                          {...draggableProvided.draggableProps}
                                          {...draggableProvided.dragHandleProps}
                                          // isDragging={snapshot.isDragging}
                                          style={{
                                            ...draggableProvided.draggableProps
                                              .style,
                                            background: snapshot.isDragging
                                              ? 'rgba(245,245,245, 0.75)'
                                              : 'none',
                                          }}>
                                          <Typography
                                            component={'div'}
                                            className={
                                              typeOfAssembles.attachment.find(
                                                (x) =>
                                                  x.assemble_petition_id ===
                                                    doc.id ||
                                                  x.documents_list.find(
                                                    (x) =>
                                                      x.assemble_petition_id ===
                                                      doc.id,
                                                  ),
                                              ) &&
                                              typeOfAssembles.exhibit.find(
                                                (x) =>
                                                  x.assemble_petition_id ===
                                                    doc.id ||
                                                  x.documents_list.find(
                                                    (x) =>
                                                      x.assemble_petition_id ===
                                                      doc.id,
                                                  ),
                                              )
                                                ? 'strick List'
                                                : 'List'
                                            }>
                                            <Grid container>
                                              {/* <Grid md={1} className="d-flex-a">
                                                <Typography
                                                  sx={{
                                                    height: '100%',
                                                    width: '10px',
                                                  }}
                                                  className="d-flex-a">
                                                  <img
                                                    src={
                                                      doc.original_name.split(
                                                        '.',
                                                      )[1] === 'pdf'
                                                        ? Pdf
                                                        : wrd
                                                    }
                                                    alt={
                                                      doc.file_format === 'pdf'
                                                        ? 'pdf'
                                                        : 'word'
                                                    }
                                                  />
                                                </Typography>
                                              </Grid> */}
                                              <Grid md={9} className="d-flex-b">
                                                <Typography
                                                  sx={{
                                                    height: '100%',
                                                    width: '10px',
                                                  }}
                                                  className="d-flex-a">
                                                  <img
                                                    src={
                                                      doc?.original_name?.split(
                                                        '.',
                                                      )[1] === 'pdf'
                                                        ? Pdf
                                                        : [
                                                            'png',
                                                            'jpg',
                                                            'jpeg',
                                                            'img',
                                                          ].includes(
                                                            doc?.mimeType?.split(
                                                              '/',
                                                            )[1],
                                                          )
                                                        ? img
                                                        : wrd
                                                    }
                                                    alt={
                                                      doc?.mimeType?.split(
                                                        '/',
                                                      )[1] === 'pdf'
                                                        ? 'pdf'
                                                        : [
                                                            'png',
                                                            'jpg',
                                                            'jpeg',
                                                            'img',
                                                          ].includes(
                                                            doc?.mimeType?.split(
                                                              '/',
                                                            )[1],
                                                          )
                                                        ? 'img'
                                                        : 'word'
                                                    }
                                                  />
                                                </Typography>
                                                <Typography
                                                  component={'div'}
                                                  className="d-flex-sa ml-2 hideshow">
                                                  {doc?.original_name?.length >
                                                  20 ? (
                                                    <Tooltip
                                                      title={doc.original_name}
                                                      placement="top-start">
                                                      <Typography
                                                        component={'span'}>
                                                        {doc.original_name}
                                                      </Typography>
                                                    </Tooltip>
                                                  ) : (
                                                    <Typography
                                                      component={'span'}>
                                                      {doc.original_name}
                                                    </Typography>
                                                  )}
                                                  <Typography
                                                    component={'div'}
                                                    className="d-flex">
                                                    <Typography
                                                      component={'h6'}
                                                      className="uploadName">
                                                      {doc.document_type ===
                                                        'uploadDoc' &&
                                                      doc.documentUploadFor ===
                                                        Number(2)
                                                        ? 'Petitioner'
                                                        : doc.documentUploadFor ===
                                                          Number(4)
                                                        ? 'Beneficiary'
                                                        : ''}
                                                    </Typography>
                                                    <Typography
                                                      component={'h6'}
                                                      className="uploadName"
                                                      style={{
                                                        marginLeft:
                                                          '0rem !important',
                                                      }}>
                                                      {doc?.document_type ===
                                                        'uploadDoc' &&
                                                        doc?.documentTypeName}
                                                    </Typography>
                                                  </Typography>
                                                </Typography>
                                              </Grid>
                                              <Grid
                                                md={3}
                                                className="d-flex-jae"
                                                sx={{
                                                  flexWrap: 'nowrap !important',
                                                }}>
                                                <Typography className="d-flex-a flex-end">
                                                  {typeOfAssembles.attachment.find(
                                                    (x) =>
                                                      x.assemble_petition_id ===
                                                        doc.id ||
                                                      x.documents_list.find(
                                                        (x) =>
                                                          x.assemble_petition_id ===
                                                          doc.id,
                                                      ),
                                                  ) && (
                                                    <Button
                                                      variant="contained"
                                                      className="assem-badge ">
                                                      Attachment
                                                    </Button>
                                                  )}
                                                  {typeOfAssembles.exhibit.find(
                                                    (x) =>
                                                      x.assemble_petition_id ===
                                                        doc.id ||
                                                      x.documents_list.find(
                                                        (x) =>
                                                          x.assemble_petition_id ===
                                                          doc.id,
                                                      ),
                                                  ) && (
                                                    <Button
                                                      variant="contained"
                                                      className={
                                                        typeOfAssembles.attachment.find(
                                                          (x) =>
                                                            x.assemble_petition_id ===
                                                              doc.id ||
                                                            x.documents_list.find(
                                                              (x) =>
                                                                x.assemble_petition_id ===
                                                                doc.id,
                                                            ),
                                                        )
                                                          ? 'assem-badge mt-1'
                                                          : 'assem-badge '
                                                      }>
                                                      Exhibit
                                                    </Button>
                                                  )}
                                                  {Array.isArray(
                                                    typeOfAssembles?.selectedBrief,
                                                  ) &&
                                                    typeOfAssembles?.selectedBrief.find(
                                                      (x) => x.id === doc.id,
                                                    ) && (
                                                      <Button
                                                        variant="contained"
                                                        className={
                                                          'assem-badge mt-1'
                                                        }>
                                                        Brief
                                                      </Button>
                                                    )}
                                                  {Array.isArray(
                                                    typeOfAssembles?.coverPage,
                                                  ) &&
                                                    typeOfAssembles?.coverPage.some(
                                                      (x) =>
                                                        x.assemble_petition_id ===
                                                        doc.id,
                                                    ) && (
                                                      <Button
                                                        variant="contained"
                                                        className={
                                                          'assem-badge mt-1'
                                                        }>
                                                        Cover
                                                      </Button>
                                                    )}
                                                </Typography>
                                                <Typography
                                                  component={'div'}
                                                  className="d-flex-jae pl-1 ">
                                                  <img
                                                    src={Cedit}
                                                    alt="im"
                                                    className="edit-icon mr-1"
                                                    onClick={() => {
                                                      setValue(
                                                        'fileName',
                                                        doc.original_name,
                                                      ); // file name without extension
                                                      setIsShowFileEditModal(
                                                        (pre) => ({
                                                          ...pre,
                                                          fileName:
                                                            doc.original_name,
                                                          id: doc.id,
                                                          show: true,
                                                        }),
                                                      );
                                                    }}
                                                  />

                                                  {typeOfAssembles.attachment.find(
                                                    (x) =>
                                                      x.assemble_petition_id ===
                                                        doc.id ||
                                                      x.documents_list.find(
                                                        (x) =>
                                                          x.assemble_petition_id ===
                                                          doc.id,
                                                      ),
                                                  ) ||
                                                  typeOfAssembles.exhibit.find(
                                                    (x) =>
                                                      x.assemble_petition_id ===
                                                        doc.id ||
                                                      x.documents_list.find(
                                                        (x) =>
                                                          x.assemble_petition_id ===
                                                          doc.id,
                                                      ),
                                                  ) ||
                                                  (Array.isArray(
                                                    typeOfAssembles?.selectedBrief,
                                                  ) &&
                                                    typeOfAssembles?.selectedBrief.find(
                                                      (x) => x.id === doc.id,
                                                    )) ||
                                                  (Array.isArray(
                                                    typeOfAssembles?.coverPage,
                                                  ) &&
                                                    typeOfAssembles?.coverPage.some(
                                                      (x) =>
                                                        x.assemble_petition_id ===
                                                        doc.id,
                                                    )) ? (
                                                    ''
                                                  ) : (
                                                    // <img
                                                    //   src={Close}
                                                    //   alt="im"
                                                    //   className="float-right"

                                                    // />
                                                    <HighlightOffIcon
                                                      className="pointer float-right"
                                                      sx={{ fill: '#999999' }}
                                                      onClick={() =>
                                                        setIsDeleteDocument({
                                                          id: doc.id,
                                                          show: true,
                                                        })
                                                      }
                                                    />
                                                  )}
                                                </Typography>
                                              </Grid>
                                              {/* <Grid
                                                md={1.5}
                                                className="d-flex-jae"></Grid> */}
                                            </Grid>
                                          </Typography>
                                        </Typography>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })
                            ) : (
                              <Typography className="as-item text-center">
                                <Typography component={'span'}>
                                  No Record(s) Found.
                                </Typography>
                              </Typography>
                            )}
                          </Typography>
                          {droppableProvided.placeholder}
                        </Typography>
                      )}
                    </Droppable>
                  </Box>
                </Typography>
              </Grid>
              <Grid item md={6} sm={12}>
                <Box className="Assem-tab mt-1">
                  <TabContext value={tabValue}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                      }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example">
                        <Tab label="Cover" value="1" />
                        <Tab label="Attachments" value="2" />
                        <Tab label="Brief" value="3" />
                        <Tab label="Exhibit" value="4" />

                        {tabValue == '4' ? (
                          <Typography
                            component={'div'}
                            className="d-flex-jae"
                            sx={{
                              color: '#002c69',
                              cursor: 'pointer',
                              fontSize: '15px !important',
                              justifyContent: 'flex-end',
                              paddingRight: '20px',
                              width: '100%',
                            }}
                            onClick={() => addNewNode('exhibit')}>
                            <AddIcon
                              sx={{
                                fontSize: '22px !important',
                                marginRight: '8px',
                              }}
                            />{' '}
                            Add Header
                          </Typography>
                        ) : (
                          ''
                        )}
                      </TabList>
                    </Box>

                    <TabPanel value="2" sx={{ padding: '0px' }}>
                      <Typography
                        component={'div'}
                        className="as-bg-box"
                        style={{
                          borderRadius: '0.5rem',
                          padding: '5px',
                        }}>
                        {/* <Typography
                          component={'div'}
                          className="d-flex-a d-flex-sb mb-2">
                          <Typography component={'div'} className="f-18 ">
                            Attachments{' '}
                            {typeOfAssembles.attachment.length > 0 &&
                              '(' +
                                String(typeOfAssembles.attachment.length) +
                                ')'}
                          </Typography>
                        </Typography> */}
                        <div className="widget-height">
                          {typeOfAssembles.attachment.length ? (
                            <NestedListComponent
                              ref={wizardAttachmentRef}
                              list={typeOfAssembles.attachment}
                              subChildName="documents_list"
                              setRomanValue={setRomanValue}
                              setIsShowRomanEditModal={setIsShowRomanEditModal}
                              setIsDeleteAssembles={setIsDeleteAssembles}
                              deleteChild={deleteChildAE}
                              type={'attachment'}
                              petitionId={petitionId}
                              getAssembles={getAssembles}
                            />
                          ) : (
                            <Typography className="as-item text-center pt-1">
                              <Typography component={'span'}>
                                No Record(s) Found.
                              </Typography>
                            </Typography>
                          )}
                        </div>
                      </Typography>
                    </TabPanel>

                    <TabPanel value="4" sx={{ padding: '0px' }}>
                      <Typography
                        component={'div'}
                        className="as-bg-box"
                        style={{
                          borderRadius: '0.5rem',
                          padding: '5px',
                        }}>
                        <div className="widget-height">
                          {typeOfAssembles.exhibit.length ? (
                            <NestedListComponent
                              ref={wizardExhibtRef}
                              list={typeOfAssembles.exhibit}
                              subChildName="documents_list"
                              setRomanValue={setRomanValue}
                              setIsShowRomanEditModal={setIsShowRomanEditModal}
                              setIsDeleteAssembles={setIsDeleteAssembles}
                              deleteChild={deleteChildAE}
                              type={'exhibit'}
                              petitionId={petitionId}
                              getAssembles={getAssembles}
                            />
                          ) : (
                            <Typography className="as-item text-center pt-1">
                              <Typography component={'span'}>
                                No Record(s) Found.
                              </Typography>
                            </Typography>
                          )}
                        </div>
                      </Typography>
                    </TabPanel>

                    <TabPanel value="3" sx={{ padding: '0px' }}>
                      <Typography
                        component={'div'}
                        className="as-bg-box"
                        style={{
                          borderRadius: '0.5rem',
                          padding: '5px',
                        }}>
                        <div className="widget-height">
                          {typeOfAssembles?.selectedBrief?.length ? (
                            typeOfAssembles.selectedBrief.map(
                              (category, categoryIndex) => (
                                <div
                                  className=" draggable-category individual-list category-container"
                                  key={categoryIndex}>
                                  <div className="item  parent-head">
                                    <Grid container>
                                      <Grid
                                        md={
                                          category.document_type === 'header'
                                            ? 10
                                            : 10
                                        }
                                        className="d-flex-b">
                                        {category.document_type !== 'header' ? (
                                          <img
                                            className={
                                              category.roman_name ? 'pr-1' : ''
                                            }
                                            style={{ marginLeft: '12px' }}
                                            src={
                                              category?.original_name?.split(
                                                '.',
                                              )[1] === 'pdf'
                                                ? Pdf
                                                : wrd
                                            }
                                            alt={
                                              category?.original_name?.split(
                                                '.',
                                              )[1] === 'pdf'
                                                ? 'pdf'
                                                : 'word'
                                            }
                                          />
                                        ) : (
                                          ''
                                        )}
                                        <div className="wb parent-name-bold f-14">
                                          {category.roman_name}{' '}
                                          {category?.original_name?.length >
                                          18 ? (
                                            <Tooltip
                                              title={category.original_name}
                                              placement="top-start">
                                              <Typography
                                                component={'span'}
                                                className="text-wrap ">
                                                {category.original_name}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              component={'span'}
                                              className="text-wrap ">
                                              {category.original_name}
                                            </Typography>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid md={2}>
                                        <div className="d-flex-jae">
                                          <HighlightOffIcon
                                            sx={{ fill: '#999999' }}
                                            className="imgs pointer"
                                            onClick={() =>
                                              setDeleteDialog((pre) => ({
                                                ...pre,
                                                id: category?.briefId,
                                                show: true,
                                              }))
                                            }
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              ),
                            )
                          ) : (
                            <Typography className="as-item text-center pt-1">
                              <Typography component={'span'}>
                                No Record(s) Found.
                              </Typography>
                            </Typography>
                          )}
                        </div>
                      </Typography>
                    </TabPanel>

                    <TabPanel value="1" sx={{ padding: '0px' }}>
                      <Typography
                        component={'div'}
                        className="as-bg-box"
                        style={{
                          borderRadius: '0.5rem',
                          padding: '5px',
                        }}>
                        <div className="widget-height">
                          {typeOfAssembles.coverPage.length ? (
                            <NestedListComponent
                              ref={wizardExhibtRef}
                              list={typeOfAssembles.coverPage}
                              subChildName="documents_list"
                              setRomanValue={setRomanValue}
                              setIsShowRomanEditModal={setIsShowRomanEditModal}
                              setIsDeleteAssembles={setIsDeleteAssembles}
                              deleteChild={deleteChildAE}
                              type={'coverPage'}
                              petitionId={petitionId}
                              getAssembles={getLatestBriefOrCover}
                            />
                          ) : (
                            <Typography className="as-item text-center pt-1">
                              <Typography component={'span'}>
                                No Record(s) Found.
                              </Typography>
                            </Typography>
                          )}
                        </div>
                      </Typography>
                    </TabPanel>
                  </TabContext>
                  {tabValue ? (
                    <Droppable
                      droppableId={
                        tabValue === '2'
                          ? 'attachment-parent'
                          : tabValue === '3'
                          ? 'brief-parent'
                          : tabValue === '1'
                          ? 'coverPage-parent'
                          : 'exhibit-parent'
                      }>
                      {(provided) => (
                        <Typography
                          component={'div'}
                          className="assem-drop-box">
                          <Typography
                            className="d-flex-ja f-15 drop"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            Drag and drop here
                            {provided.placeholder}
                          </Typography>
                        </Typography>
                      )}
                    </Droppable>
                  ) : (
                    ''
                  )}
                </Box>
              </Grid>
              <Grid item md={6} sm={12}>
                <Box className="Assem-tab mt-1">
                  <Typography
                    component={'div'}
                    className="d-flex-a d-flex-sb "
                    sx={{
                      borderBottom: '1px solid #cccccc',
                    }}>
                    <Typography
                      className="f-18 font-bold as-title"
                      sx={{ padding: '1rem .5rem 1rem 1rem !important' }}>
                      Assemble Preview
                    </Typography>
                    {/* 
                    <Typography
                      className="f-18 add-template-modal-text d-flex align-center"
                      sx={{ backgroundColor: 'unset' }}
                      onClick={() => {
                        setBrief((pre) => ({
                          ...pre,
                          open: true,
                        }));
                        setTypeOfAssembles((pre) => ({
                          ...pre,
                          brief: pre.brief.map((e) => ({
                            ...e,
                            checked: false,
                          })),
                        }));
                      }}>
                      <span className="plus d-flex-ja"> + </span>
                      Add Brief
                    </Typography> */}
                  </Typography>
                  <Box
                    sx={{
                      minHeight: 'calc(100vh - 292px) !important',
                      padding: '0px 16px',
                      width: '100%',
                    }}>
                    <AssembleTreeView
                      attachment={
                        typeOfAssembles.attachment &&
                        typeOfAssembles.attachment.length
                          ? typeOfAssembles.attachment.map((e, index) => {
                              const object = {
                                ...e,
                                nodeId: attachmentNum,
                                // eslint-disable-next-line sort-keys-fix/sort-keys-fix
                                documents_list:
                                  Array.isArray(e.documents_list) &&
                                  e.documents_list.length
                                    ? e.documents_list.map((sub, subInd) => {
                                        attachmentNum += 1;
                                        return {
                                          ...sub,
                                          nodeId: attachmentNum,
                                        };
                                      })
                                    : [],
                              };
                              e.document_type === 'header' &&
                              (!Array.isArray(e.documents_list) ||
                                !e.documents_list.length)
                                ? (attachmentNum += 2)
                                : (attachmentNum += 1);
                              return object;
                            })
                          : []
                      }
                      exhibit={
                        typeOfAssembles.exhibit &&
                        typeOfAssembles.exhibit.length
                          ? typeOfAssembles.exhibit.map((e, index) => {
                              // let num = 1;
                              const object = {
                                ...e,
                                nodeId: exhibitNum,
                                // eslint-disable-next-line sort-keys-fix/sort-keys-fix
                                documents_list:
                                  Array.isArray(e.documents_list) &&
                                  e.documents_list.length
                                    ? e.documents_list.map((sub, subInd) => {
                                        exhibitNum += 1;
                                        return { ...sub, nodeId: exhibitNum };
                                      })
                                    : [],
                              };
                              e.document_type === 'header' &&
                              (!Array.isArray(e.documents_list) ||
                                !e.documents_list.length)
                                ? (exhibitNum += 2)
                                : (exhibitNum += 1);
                              return object;
                            })
                          : []
                      }
                      buiildCover={typeOfAssembles.coverPage}
                      brief={
                        typeOfAssembles.selectedBrief &&
                        typeOfAssembles.selectedBrief.length
                          ? typeOfAssembles.selectedBrief.map((e, index) => {
                              const object = {
                                ...e,
                                nodeId: index + 1,
                                template_name: e?.original_name,
                              };
                              return object;
                            })
                          : []
                      }
                      treeOrderRecall={treeOrderRecall}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} sm={12}>
            <Typography component={'div'} className="add-box">
              <Typography component={'div'} className="add-inner-box" />
              <Typography className=" bu-color f-16 pt-5 pb-2 pl-2 pr-2">
                Attachment are forms check,any previous visa (s) and I-94s that
                precede the Brief and exhibits in the petition package
              </Typography>

              {(typeOfAssembles?.attachment &&
                typeOfAssembles?.attachment?.length) ||
              (typeOfAssembles?.coverPage &&
                typeOfAssembles?.coverPage?.length) ||
              (typeOfAssembles?.selectedBrief &&
                typeOfAssembles?.selectedBrief?.length) ||
              (typeOfAssembles?.exhibit &&
                typeOfAssembles?.exhibit?.length &&
                typeOfAssembles?.exhibit.some(
                  (e) =>
                    Array.isArray(e.documents_list) && e.documents_list.length,
                )) ? (
                <Typography
                  className=" built-option  d-flex-a"
                  onClick={() => buildAssamble()}>
                  <img
                    src={Assemble}
                    alt="im"
                    className="mr-1"
                    style={{ height: '24px', width: '24px' }}
                  />
                  Assemble
                </Typography>
              ) : (
                <Tooltip title={'Please upload a document to Assemble.'}>
                  <Typography className=" built-cancel  d-flex-a">
                    <img
                      src={Assemble}
                      alt="im"
                      className="mr-1"
                      style={{ height: '24px', width: '24px' }}
                    />
                    Assemble
                  </Typography>
                </Tooltip>
              )}

              {Array.isArray(assambleReview.list) &&
              assambleReview.list.length ? (
                <Typography
                  className=" built-option d-flex-a"
                  onClick={() => {
                    setAssambleReview((pre) => ({
                      ...pre,
                      open: true,
                    }));
                  }}>
                  <VisibilityIcon className="mr-1" />
                  Previous Versions{' '}
                  <Badge
                    className="badge-tick"
                    badgeContent={
                      Array.isArray(assambleReview.list)
                        ? assambleReview.list.length
                        : 0
                    }
                    sx={{
                      marginLeft: '15px',
                    }}
                    color={'error'}
                  />
                </Typography>
              ) : (
                ''
              )}

              {(Array.isArray(assambleReview.list) &&
                assambleReview.list.length) ||
              (sendList.list && sendList.list.length) ? (
                <Typography
                  className=" built-option d-flex-a"
                  onClick={() => {
                    setSendToModal((pre) => ({
                      ...pre,
                      open: true,
                    }));
                    setSendList((pre) => ({
                      ...pre,
                      visible:
                        Array.isArray(pre.list) && pre.list.length
                          ? true
                          : false,
                    }));
                  }}>
                  <img
                    src={Bsend}
                    alt="send to"
                    className="mr-1"
                    style={{ width: '20px !important' }}
                  />
                  Send to
                </Typography>
              ) : (
                ''
              )}
              {assembles?.data?.length ? (
                <Typography
                  className=" built-option d-flex-a"
                  onClick={handlemodelOpen}>
                  <DeleteIcon />
                  <a style={{ paddingLeft: '4px' }}>Delete All</a>
                </Typography>
              ) : (
                ''
              )}
            </Typography>
          </Grid>
        </DragDropContext>
      </Grid>
      <Modal
        open={isShowFileEditModal.show}
        onClose={() => {
          resetForm();
          setIsShowFileEditModal((pre) => ({ ...pre, show: false }));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="custom-modal">
          <Typography component={'div'} className="f-20 font-bold modal-title">
            {/* {isShowFileEditModal.fileName} */}
            Do you want to update this document file name ?
          </Typography>
          <form
            id={'file-name-update-form'}
            onSubmit={handleSubmit(onUpdateFileNameSubmit)}>
            <Typography
              component={'div'}
              className="custom-field mb-0"
              sx={{
                marginBottom: '0px !important',
                padding: '1.5rem 2rem 0  2rem',
              }}>
              <Controller
                control={control}
                name={'fileName'}
                defaultValue={''}
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <CustomInput
                      focus={true}
                      className="custom-input"
                      placeHolder={'Enter the file name'}
                      name={name}
                      value={value}
                      error={errors.fileName?.message || ''}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
            </Typography>
            <Typography
              component={'div'}
              sx={{ margin: '1rem 0' }}
              className="d-flex-jae modal-footer">
              <Button
                className="mcancel-btn f-18 font-bold mr-1"
                onClick={() => {
                  resetForm();
                  setIsShowFileEditModal((pre) => ({
                    ...pre,
                    show: false,
                  }));
                }}>
                Cancel
              </Button>
              <Button
                className="madd-btn f-18 font-bold"
                type="submit"
                form="file-name-update-form">
                Update
              </Button>
            </Typography>
          </form>
        </Box>
      </Modal>
      <Modal
        open={isShowRomanEditModal.show}
        // onClose={() => {
        //   resetRomanForm();
        //   setIsShowRomanEditModal((pre) => ({
        //     ...pre,
        //     documentType: '',
        //     show: false,
        //   }));
        // }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="custom-modal">
          <Typography component={'div'} className="f-20 font-bold modal-title">
            {isShowRomanEditModal.documentType === 'header'
              ? 'Add new header'
              : tabValue === '2'
              ? 'Update Attachment'
              : 'Update Exhibit'}
          </Typography>
          <form
            id={'roman-name-update-form'}
            onSubmit={handleRomanSubmit(onUpdateRomanNameSubmit)}>
            <Grid
              container
              spacing={2}
              sx={{
                padding: '1.5rem 2rem 0  2rem',
              }}>
              <Grid item md={2} sm={12} xs={12}>
                {tabValue !== '2' ? (
                  <Typography
                    className="f-15 font-bold "
                    sx={{ paddingTop: '8px' }}>
                    Exhibit ID
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item md={10} sm={12} xs={12}>
                {tabValue !== '2' ? (
                  <Typography
                    component={'div'}
                    className="custom-field mb-0"
                    sx={{
                      marginBottom: '0px !important',
                    }}>
                    <Controller
                      control={controlRoman}
                      name={'documentRoman'}
                      defaultValue={''}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomInput
                            focus={true}
                            className="custom-input"
                            placeHolder={'Enter the value'}
                            name={name}
                            value={value}
                            error={errorsRoman.documentRoman?.message || ''}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>

              <Grid item md={2} sm={12} xs={12}>
                <Typography
                  className="f-15 font-bold pr-2"
                  sx={{ paddingTop: '8px' }}>
                  {isShowRomanEditModal.documentType === 'header'
                    ? 'Name'
                    : 'Name'}{' '}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item md={10} sm={12} xs={12}>
                <Typography
                  component={'div'}
                  className="custom-field mb-0"
                  sx={{
                    marginBottom: '0px !important',
                  }}>
                  <Controller
                    control={controlRoman}
                    name={'documentName'}
                    defaultValue={''}
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <CustomInput
                          focus={true}
                          className="custom-input"
                          placeHolder={'Enter the name'}
                          name={name}
                          value={value}
                          error={errorsRoman.documentName?.message || ''}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Typography
              component={'div'}
              sx={{ margin: '1rem 0' }}
              className="d-flex-jae modal-footer">
              <Button
                className="mcancel-btn f-18 font-bold mr-1"
                onClick={() => {
                  resetRomanForm();
                  setIsShowRomanEditModal((pre) => ({
                    ...pre,
                    assemblePetitionId: '',
                    documentType: '',
                    id: null,
                    show: false,
                  }));
                }}>
                Cancel
              </Button>
              <Button
                className="madd-btn f-18 font-bold"
                type="submit"
                form="roman-name-update-form">
                {isShowRomanEditModal.documentType === 'header'
                  ? 'Save'
                  : 'Update'}
              </Button>
            </Typography>
          </form>
        </Box>
      </Modal>
      <ConfirmDialog
        title={'Delete Assemble'}
        floatingBtnShow={true}
        open={confirmDeleteModal}
        onConfirm={(act) =>
          act === 'yes' ? DeleteAll() : setConfirmDeleteModal(false)
        }
        autoCloseDisable={true}>
        Are you sure do you want to delete?
      </ConfirmDialog>
      <ConfirmDialog
        title={'Delete'}
        floatingBtnShow={true}
        open={isDeleteDocument.show}
        onConfirm={(act) =>
          act === 'yes'
            ? deleteAssemble(act)
            : setIsDeleteDocument({ id: null, show: false })
        }
        autoCloseDisable={true}>
        Are you sure do you want to delete?
      </ConfirmDialog>

      <ConfirmDialog
        title={
          isDeleteAssembles.type === 'attachment'
            ? 'Delete attachments'
            : isDeleteAssembles.type === 'coverPage'
            ? 'Delete Cover Page'
            : 'Delete exhibits'
        }
        floatingBtnShow={true}
        open={isDeleteAssembles.show}
        onConfirm={(act) =>
          act === 'yes'
            ? deleteAE(act)
            : setIsDeleteAssembles({ id: null, show: false, type: '' })
        }
        autoCloseDisable={true}>
        {typeOfAssembles[isDeleteAssembles.type]?.find(
          (val) => val.id === isDeleteAssembles?.id,
        ).documents_list?.length > 0
          ? 'Are you sure do you want to delete all?'
          : 'Are you sure do you want to delete?'}
      </ConfirmDialog>

      <ConfirmDialog
        title={'Delete document'}
        floatingBtnShow={true}
        open={isDeleteChildDoc.show}
        onConfirm={(act) =>
          act === 'yes'
            ? deleteChildApi()
            : setIsDeleteChildDoc((pre) => ({
                ...pre,
                deleteId: null,
                show: false,
              }))
        }
        autoCloseDisable={true}>
        Are you sure do you want to delete?
      </ConfirmDialog>

      <ConfirmDialog
        title={'Delete'}
        floatingBtnShow={true}
        open={assambleReview.deleteOpen}
        onConfirm={(act) =>
          act === 'yes'
            ? deleteBuildVersion(assambleReview.deleteId)
            : setAssambleReview((pre) => ({
                ...pre,
                deleteId: '',
                deleteOpen: false,
              }))
        }
        autoCloseDisable={true}>
        Are you sure do you want to delete?
      </ConfirmDialog>
      <CustomModalView
        open={assambleReview?.open}
        fullWidth={true}
        maxWidth={'lg'}
        scroll={'paper'}
        close={() =>
          setAssambleReview((pre) => ({
            ...pre,
            open: false,
          }))
        }
        title="Previous Versions">
        <DialogContent>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th scope="col" style={{ width: '40%' }}>
                  Name
                </th>
                <th scope="col" style={{ width: '30%' }}>
                  Date
                </th>
                {!petitions?.petitionData?.isAffilateCase ? (
                  <th scope="col">Delete</th>
                ) : (
                  ''
                )}
              </tr>
            </thead>
            <tbody>
              {assambleReview.list.length > 0 ? (
                assambleReview.list.map((datas, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        color: '#1093df',
                      }}>
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => openFileToNewWindow(datas?.key)}>
                        {datas?.file_name}
                      </a>
                    </td>
                    <td>
                      {moment(new Date(datas?.created_at)).format(
                        'DD-MM-YYYY hh:mm A',
                      )}
                    </td>
                    {!petitions?.petitionData?.isAffilateCase ? (
                      <td>
                        <Tooltip title={'Delete'} placement="top">
                          <DeleteForeverIcon
                            sx={{
                              cursor: 'pointer',
                              fill: 'red',
                              height: '25px',
                              width: '25px',
                            }}
                            onClick={() =>
                              setAssambleReview((pre) => ({
                                ...pre,
                                deleteId: datas.id,
                                deleteOpen: true,
                              }))
                            }
                          />
                        </Tooltip>
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                ))
              ) : (
                <tr className={'text-center'}>
                  <th scope="row" colSpan={5}>
                    No Record(s) Found.
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
      </CustomModalView>

      <CustomModalView
        open={deleteModal}
        fullWidth={true}
        maxWidth={'lg'}
        scroll={'paper'}
        close={() => handlemodelClose()}
        title="Delete All">
        <DialogContent>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th scope="col" style={{ width: '40%' }}>
                  Document
                </th>
                <th scope="col" style={{ width: '30%' }}>
                  Type
                </th>

                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {assembles?.data?.length > 0 ? (
                assembles?.data.map((doc, index) => {
                  return (
                    <tr key={index}>
                      <td
                        style={{
                          color: '#1093df',
                        }}>
                        <Grid md={9} className="d-flex-b">
                          <Typography
                            sx={{
                              height: '100%',
                              width: '10px',
                            }}
                            className="d-flex-a">
                            <img
                              src={
                                doc.original_name.split('.')[1] === 'pdf'
                                  ? Pdf
                                  : wrd
                              }
                              alt={doc.file_format === 'pdf' ? 'pdf' : 'word'}
                            />
                          </Typography>
                          <a
                            className="hideshow"
                            style={{ cursor: 'pointer', paddingLeft: '15px' }}>
                            {doc?.original_name}
                          </a>
                        </Grid>
                      </td>
                      <td>
                        <a>{findDocumentName(typeOfAssembles, doc)}</a>
                      </td>
                      <td>
                        <CustomCheckBox
                          checked={
                            deleteChecked?.includes(doc.id) ? false : true
                          }
                          onChange={(e) => {
                            e.target.checked === true
                              ? setDeleteChecked(
                                  deleteChecked?.filter((e) => e !== doc.id),
                                )
                              : setDeleteChecked([...deleteChecked, doc.id]);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className={'text-center'}>
                  <th scope="row" colSpan={5}>
                    No Record(s) Found.
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Grid item md={12} sm={12} className="d-flex-jae mt-1 ">
            {deleteChecked?.length !== assembles?.data?.length && (
              <Typography component={'div'} className="custom-field u-pr-1">
                <Button
                  className="n-add-btn f-18 font-bold"
                  onClick={() =>
                    deleteChecked?.length !== assembles?.data?.length &&
                    setConfirmDeleteModal(true)
                  }>
                  delete
                </Button>
              </Typography>
            )}
          </Grid>
        </DialogActions>
      </CustomModalView>
      <CustomModalView
        open={buildDocuments?.open}
        fullWidth={true}
        maxWidth={'lg'}
        scroll={'paper'}
        autoCloseDisable={true}
        close={() =>
          setBuildDocuments((pre) => ({
            ...pre,
            open: false,
          }))
        }
        title="Build Documents">
        <DialogContent>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th> Action{''}</th>
                <th scope="col" style={{ width: '40%' }}>
                  Name
                </th>
                <th scope="col" style={{ width: '30%' }}>
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {buildDocuments.list.length > 0 ? (
                buildDocuments.list.map((datas, index) => (
                  <tr
                    key={index}
                    style={{
                      cursor: 'pointer',
                      ...(Array.isArray(assembles.data) &&
                      assembles.data.some(
                        (ch) =>
                          ch.document_type === 'build' &&
                          datas.current_form_id === ch.template_id,
                      )
                        ? { opacity: '0.5' }
                        : {}),
                    }}
                    onClick={() =>
                      Array.isArray(assembles.data) &&
                      assembles.data.some(
                        (ch) =>
                          ch.document_type === 'build' &&
                          datas.current_form_id === ch.template_id,
                      )
                        ? {}
                        : setBuildDocuments((pre) => ({
                            ...pre,
                            list: pre.list.map((e, listIndex) => ({
                              ...e,
                              ...(listIndex === index
                                ? { checked: e.checked ? false : true }
                                : {}),
                            })),
                          }))
                    }>
                    <td>
                      <CustomCheckBox
                        checked={
                          datas?.checked ||
                          (Array.isArray(assembles.data) &&
                            assembles.data.some(
                              (ch) =>
                                ch.document_type === 'build' &&
                                datas.current_form_id === ch.template_id,
                            ))
                        }
                        disabled={
                          Array.isArray(assembles.data) &&
                          assembles.data.some(
                            (ch) =>
                              ch.document_type === 'build' &&
                              datas.current_form_id === ch.template_id,
                          )
                        }
                      />
                    </td>
                    <td
                      style={{
                        color: '#1093df',
                        ...(Array.isArray(assembles.data) &&
                        assembles.data.some(
                          (ch) =>
                            ch.document_type === 'build' &&
                            datas.current_form_id === ch.template_id,
                        )
                          ? { textDecoration: ' line-through' }
                          : {}),
                      }}>
                      <a>{datas?.current_formName}</a>
                    </td>
                    <td>
                      {moment(
                        new Date(datas?.latestBuild[0]?.updated_at),
                      ).format('DD-MM-YYYY hh:mm A')}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className={'text-center'}>
                  <th scope="row" colSpan={5}>
                    No Record(s) Found.
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
        {buildDocuments.list.length > 0 &&
          buildDocuments.list.some((e) => e.checked) && (
            <DialogActions>
              <Grid item md={12} sm={12} className="d-flex-jae mt-1 ">
                <Typography component={'div'} className="custom-field u-pr-1">
                  <Button
                    className="n-add-btn f-18 font-bold"
                    onClick={(e) => UploadFile()}>
                    {'upload'}
                  </Button>
                </Typography>
              </Grid>
            </DialogActions>
          )}
      </CustomModalView>
      <CustomModalView
        open={uploadDocuments?.open}
        fullWidth={true}
        maxWidth={'lg'}
        scroll={'paper'}
        autoCloseDisable={true}
        close={() =>
          setUploadDocuments((pre) => ({
            ...pre,
            open: false,
          }))
        }
        title="Upload Documents">
        <DialogContent>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th> Action{''}</th>
                <th scope="col" style={{ width: '40%' }}>
                  Name
                </th>
                <th scope="col" style={{ width: '30%' }}>
                  Upload for
                </th>
                <th scope="col" style={{ width: '30%' }}>
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {uploadDocuments.list.length > 0 ? (
                uploadDocuments.list.map((datas, index) => (
                  <tr
                    key={index}
                    style={{
                      cursor: 'pointer',
                      ...(Array.isArray(assembles.data) &&
                      assembles.data.some(
                        (ch) =>
                          ch.document_type === 'uploadDoc' &&
                          datas.documentId === ch.upload_document_id,
                      )
                        ? { cursor: 'not-allowed', opacity: '0.5' }
                        : {}),
                    }}
                    onClick={() =>
                      Array.isArray(assembles.data) &&
                      assembles.data.some(
                        (ch) =>
                          ch.document_type === 'uploadDoc' &&
                          datas.documentId === ch.upload_document_id,
                      )
                        ? {}
                        : setUploadDocuments((pre) => ({
                            ...pre,
                            list: pre.list.map((e, listIndex) => ({
                              ...e,
                              ...(listIndex === index
                                ? { checked: e.checked ? false : true }
                                : {}),
                            })),
                          }))
                    }>
                    <td>
                      <CustomCheckBox
                        checked={
                          datas?.checked ||
                          (Array.isArray(assembles.data) &&
                            assembles.data.some(
                              (ch) =>
                                ch.document_type === 'uploadDoc' &&
                                datas.documentId === ch.upload_document_id,
                            ))
                        }
                        disabled={
                          Array.isArray(assembles.data) &&
                          assembles.data.some(
                            (ch) =>
                              ch.document_type === 'uploadDoc' &&
                              datas.documentId === ch.upload_document_id,
                          )
                        }
                      />
                    </td>
                    <td
                      style={{
                        color: '#1093df',
                        ...(Array.isArray(assembles.data) &&
                        assembles.data.some(
                          (ch) =>
                            ch.document_type === 'uploadDoc' &&
                            datas.documentId === ch.upload_document_id,
                        )
                          ? { textDecoration: ' line-through' }
                          : {}),
                      }}>
                      <a>
                        {datas?.document_type} - {datas?.original_name}
                      </a>
                    </td>
                    <td>{datas.module_name}</td>
                    <td>
                      {moment(new Date(datas?.created_at)).format(
                        'DD-MM-YYYY hh:mm A',
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className={'text-center'}>
                  <th scope="row" colSpan={5}>
                    No Record(s) Found.
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
        {uploadDocuments.list.length > 0 &&
          uploadDocuments.list.some((e) => e.checked) && (
            <DialogActions>
              <Grid item md={12} sm={12} className="d-flex-jae mt-1 ">
                <Typography component={'div'} className="custom-field u-pr-1">
                  <Button
                    className="n-add-btn f-18 font-bold"
                    onClick={(e) => UploadDocumnetFile()}>
                    {'upload'}
                  </Button>
                </Typography>
              </Grid>
            </DialogActions>
          )}
      </CustomModalView>
      {sendToModal?.open ? (
        <SendEmail
          teamMembers={teamMembers}
          setLoader={setLoader}
          petitionId={petitionId}
          setSendToModal={setSendToModal}
          sendToModal={sendToModal}
          getSendEmailList={getSendEmailList}
          setSendList={setSendList}
          sendList={sendList}
          petitions={petitions}
        />
      ) : (
        ''
      )}

      <ConfirmDialog
        title="DELETE"
        floatingBtnShow={true}
        open={deleteDialog.show}
        onConfirm={(act) => {
          if (act !== 'yes') {
            setDeleteDialog((pre) => ({
              ...pre,
              show: false,
            }));
          } else {
            deleteBrief();
          }
        }}
        autoCloseDisable={true}>
        {'Are you sure do you want to delete?'}
      </ConfirmDialog>
    </Grid>
  );
};
export default AssembleDragDrop;
