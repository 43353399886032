import { Button, Grid, Typography } from '@mui/material';
import Avator from 'assets/images/d-avator.svg';
import { BeneficiaryPrivateRoutes } from 'config';
import saveAs from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import AddNote from 'pages/private/Client/Common/AddNote';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getS3file } from 'services/CommonServices';
import { storePetitionerData } from 'store/actions/LeadsOrPetitionerAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { getPetitionApiData } from 'utils/Handlers';
import { alertOptions } from 'utils/helper';

// import { CommentsWidgets } from '../AttorneyDashboard/CommentsWidgets';
// import PendingReviews from '../AttorneyDashboard/PendingReviews';

export const BeneDashboard: React.FC<any> = ({
  beneficiaries,
  // pendingReviews,
  // pendingReviewParams,
  // setPendingReviewParams,
  // commentsWidget,
  // commentsParams,
  // setCommentsParams,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { profile }: any = useAppSelector((store: any) => store.userLogin);
  // const uploadRef = useRef<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [viewNotes, setViewNotes] = useState({
    leadOrPetitionId: '',
    show: false,
    type: '',
  });
  // const [file, setFile] = useState<any>([]);
  // const [note, setNote] = useState<any>([]);
  // const [contact, setContact] = useState<any>([]);
  // const [emailList, setEmailList] = useState<any>([]);
  // const [selector, setSelector] = useState<string>('');
  // const [checkMail, setCheckMail] = useState<boolean>(false);
  // const [isUscisNotesDialog, setIsUscisNotesDialog] = useState<any>({
  //   benId: '',
  //   notes: {},
  //   show: false,
  // });
  // const clearData = () => {
  //   setNote('');
  //   setFile('');
  //   setCheckMail(false);
  //   setSelector('');
  //   setContact('');
  // };

  // const onSubmit = () => {
  //   setLoader(true);
  //   if (isUscisNotesDialog && isUscisNotesDialog.notes) {
  //     const formData = new FormData();
  //     formData.append('notes', note);
  //     formData.append('file', file);
  //     formData.append('sendEmail', checkMail.toString());
  //     formData.append('teamMembers', JSON.stringify([selector]));
  //     formData.append('userId', isUscisNotesDialog.benId);
  //     formData.append('notesFrom', 'lead');
  //     formData.append('notesType', contact);
  //     formData.append('visibleTo', null);

  //     addNewData(
  //       formData,
  //       `${String(COMMON_ENDPOINT.Contact)}/${String(
  //         isUscisNotesDialog.notes?.lead_id,
  //       )}`,
  //     )
  //       .then((resp: any) => {
  //         clearData();
  //         setLoader(false);
  //         toast.success('Notes added successfully.');
  //         setIsUscisNotesDialog({
  //           benId: '',
  //           notes: {},
  //           show: false,
  //         });
  //       })
  //       .catch(() => {
  //         setLoader(false);
  //       });
  //   }
  // };

  // const getTeamsAndHistory = (beneficiary) => {
  //   // setIsUscisNotesDialog({
  //   //   benId: profile.id,
  //   //   notes: beneficiary,
  //   //   show: true,
  //   // });
  //   getAllListData(
  //     `${String(COMMON_ENDPOINT.Email)}/${String(
  //       beneficiary?.lead_id,
  //     )}?type=${String('lead')}`,
  //   )
  //     .then((resp: any) => {
  //       const emailList = [];
  //       resp.map((row: any) => {
  //         emailList.push({ label: row, value: row });
  //       });
  //       // setEmailList(emailList);
  //     })
  //     .catch((e: any) => {});
  // };

  const roundNumber = (numbers) => {
    if (!numbers) {
      return 0.0;
    }
    return Number(numbers).toFixed(2);
  };

  const openPayment = (url) => {
    if (url) {
      window.open(url);
    }
  };

  const viewContract = (contractKey, type?) => {
    let fileName = '';
    if (type) {
      fileName = contractKey.split('/').pop();
    } else {
      fileName = `contract-${
        new Date().getMonth() + 1
      }/${new Date().getDate()}/${new Date().getFullYear()}.pdf`;
    }
    const obj = {
      key: contractKey,
    };
    setLoader(true);
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(
          `${String(fileName)} File downloaded successfully`,
          alertOptions,
        );
      })
      .catch((err) => {
        setLoader(false);
        toast.error('Unable to read the file.', alertOptions);
      });
  };

  const uploadDoc = async (user) => {
    if (!user.petitionId) {
      // lead
      navigate(
        `${String(BeneficiaryPrivateRoutes.UPLOADDOCUMENT)}/${String(
          user.lead_user_id,
        )}?leadUser=${String(user.lead_user_id)}&lead=${String(user.lead_id)}`,
        {
          state: {
            data: {
              name: profile
                ? (profile.first_name ? `${String(profile.first_name)} ` : '') +
                  (profile.last_name ? `${String(profile.last_name)} ` : '')
                : '',
            },
          },
        },
      );
    } else if (user.petitionId && !user.petition_submitted) {
      // petition
      setLoader(true);
      await getPetitionApiData(user.petitionId, dispatch);
      setLoader(false);
      navigate(
        `${String(BeneficiaryPrivateRoutes.UPLOADPETITIONSDOCUMENT)}/${String(
          user.petitionId,
        )}?leadUser=${String(user.lead_user_id)}`,
        {
          state: {
            data: {
              name: profile
                ? (profile.first_name ? `${String(profile.first_name)} ` : '') +
                  (profile.last_name ? `${String(profile.last_name)} ` : '')
                : '',
            },
          },
        },
      );
    } else if (user.petition_submitted) {
      toast.error(
        'Petition submitted. please contact your attorney',
        alertOptions,
      );
    }
  };

  const navigateToHistory = async (beneficiary) => {
    if (beneficiary.petitionId && !beneficiary.petition_submitted) {
      setLoader(true);
      await getPetitionApiData(beneficiary.petitionId, dispatch);
      setLoader(false);
      navigate(
        `${BeneficiaryPrivateRoutes.BENEFICIARYCONTRACTHISTORY}/${String(
          beneficiary.petitionId,
        )}?leadUser=${String(beneficiary.lead_user_id)}`,
      );
    } else {
      navigate(
        `${String(
          BeneficiaryPrivateRoutes.BENEFICIARYLEADCONTRACTHISTORY,
        )}/${String(beneficiary.lead_id)}?leadUser=${String(
          beneficiary.lead_user_id,
        )}`,
      );
    }
  };

  useEffect(() => {
    dispatch(storePetitionerData({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Typography component={'div'}>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <Grid container>
          <Grid item md={12} sm={12} sx={{ marginBottom: '1.5rem !important' }}>
            <Typography component={'div'} className="mt-3">
              {/* <PendingReviews
                reviews={pendingReviews}
                reviewParams={pendingReviewParams}
                setReviewParams={setPendingReviewParams}
              /> */}
            </Typography>
            {/* <Grid md={12} sm={12}>
              <Typography component={'div'} className="mt-3">
                <CommentsWidgets
                  commentsReview={commentsWidget}
                  commentsParams={commentsParams}
                  setCommentsParams={setCommentsParams}
                />
              </Typography>
            </Grid> */}
          </Grid>
          {!_.isEmpty(beneficiaries) ? (
            beneficiaries.map((beneficiary, index) => (
              <React.Fragment key={index}>
                <Grid item md={12} sx={{ marginBottom: '1.5rem !important' }}>
                  <Typography component={'div'} className="bene">
                    <Typography className="inb" />
                    <Typography component={'div'} className="bene-box1">
                      <Typography
                        component={'div'}
                        className="d-flex-a d-flex-sb mb-1">
                        <Typography
                          className=" black-white-text f-22 font-bold"
                          sx={{ marginBottom: '2rem' }}>
                          {beneficiary?.visa_type_name
                            ? `${String(beneficiary?.visa_type_name)} for`
                            : ''}{' '}
                          {profile
                            ? (profile.first_name
                                ? `${String(profile.first_name)} `
                                : '') +
                              (profile.last_name
                                ? `${String(profile.last_name)} `
                                : '')
                            : ''}
                          {moment(new Date(beneficiary?.created_at)).format(
                            'MMM DD, YYYY',
                          )}
                        </Typography>

                        <Typography
                          component={'div'}
                          className="d-flex-ja my-2">
                          {beneficiary?.paymentHistory &&
                          beneficiary?.paymentHistoryDetails.length > 0 ? (
                            <Button
                              className="add-btn"
                              style={{ marginRight: '12px' }}
                              onClick={async () => {
                                if (
                                  beneficiary.petitionId &&
                                  !beneficiary.petition_submitted
                                ) {
                                  setLoader(true);
                                  await getPetitionApiData(
                                    beneficiary.petitionId,
                                    dispatch,
                                  );
                                  setLoader(false);
                                  navigate(
                                    `${
                                      BeneficiaryPrivateRoutes.BENEFICIARYCONTRACTHISTORY
                                    }/${String(
                                      beneficiary.petitionId,
                                    )}?leadUser=${String(
                                      beneficiary.lead_user_id,
                                    )}`,
                                  );
                                } else {
                                  navigate(
                                    `${String(
                                      BeneficiaryPrivateRoutes.BENEFICIARYLEADCONTRACTHISTORY,
                                    )}/${String(
                                      beneficiary.lead_id,
                                    )}?leadUser=${String(
                                      beneficiary.lead_user_id,
                                    )}`,
                                  );
                                }
                              }}>
                              Payment History
                            </Button>
                          ) : null}

                          <Button
                            className="add-btn"
                            onClick={async () => {
                              if (
                                beneficiary.petitionId &&
                                !beneficiary.petition_submitted
                              ) {
                                setLoader(true);
                                await getPetitionApiData(
                                  beneficiary.petitionId,
                                  dispatch,
                                );
                                setLoader(false);
                                navigate(
                                  `${
                                    BeneficiaryPrivateRoutes.BENEFICIARYDETAILLIST
                                  }/${String(
                                    beneficiary.petitionId,
                                  )}?leadUser=${String(
                                    beneficiary.lead_user_id,
                                  )}`,
                                );
                              } else {
                                setViewNotes({
                                  leadOrPetitionId: beneficiary?.petitionId
                                    ? beneficiary?.petitionId
                                    : beneficiary?.lead_id,
                                  show: true,
                                  type: beneficiary?.petitionId
                                    ? 'petition'
                                    : 'lead',
                                });
                              }
                            }}>
                            Contact Attorney
                          </Button>
                        </Typography>
                      </Typography>
                      <Typography className="lp-box d-flex-a">
                        <Typography className="lp-item ">
                          <Typography className="in-item" />
                          <Typography className="black-white-text f-16 mb-1">
                            Status :
                          </Typography>
                          <Typography className="f-20 font-bold b-text">
                            {beneficiary?.petiton_started &&
                            beneficiary.petitionId
                              ? 'Petition'.concat(
                                  beneficiary?.petition_submitted
                                    ? ' Submitted'
                                    : '',
                                )
                              : 'Lead'}
                          </Typography>
                        </Typography>
                        <Typography className="lp-item ">
                          <Typography className="in-item" />
                          <Typography className="black-white-text f-16 mb-1">
                            Fees :
                          </Typography>
                          <Typography className="f-20 font-bold b-text">
                            ${roundNumber(beneficiary?.payment_total)}
                          </Typography>
                        </Typography>
                        <Typography className="lp-item ">
                          <Typography className="in-item" />
                          <Typography className="black-white-text f-16 mb-1">
                            Payment :
                          </Typography>
                          <Typography
                            className="f-20 font-bold b-text italic"
                            style={
                              beneficiary?.payment_staus === 'pending' &&
                              beneficiary?.payment_total
                                ? {
                                    cursor: 'pointer',
                                    position: 'relative',
                                    textDecoration: 'underline',
                                    zIndex: 1,
                                  }
                                : {}
                            }
                            onClick={() => {
                              if (
                                beneficiary.paymentHistoryDetails &&
                                beneficiary.paymentHistoryDetails.length > 1
                              ) {
                                navigateToHistory(beneficiary);
                              } else {
                                beneficiary?.payment_staus === 'pending' &&
                                  beneficiary?.payment_total &&
                                  openPayment(beneficiary?.paymentLink);
                              }
                            }}>
                            {beneficiary?.payment_staus === 'pending' &&
                            beneficiary?.payment_total
                              ? 'Required'
                              : 'Not Applicable'}
                          </Typography>
                        </Typography>
                        <Typography className="lp-item ">
                          <Typography className="in-item" />
                          <Typography className="black-white-text f-16 mb-1">
                            Contract :
                          </Typography>
                          {beneficiary?.benificiery_upload_contract ? (
                            <Typography
                              className="f-20 font-bold b-text italic"
                              style={
                                beneficiary?.benificiery_upload_contract
                                  ? {
                                      cursor: 'pointer',
                                      position: 'relative',
                                      textDecoration: 'underline',
                                      zIndex: 1,
                                    }
                                  : {}
                              }
                              onClick={() =>
                                viewContract(
                                  beneficiary?.benificiery_upload_contract,
                                )
                              }>
                              View
                            </Typography>
                          ) : (
                            <Typography
                              className="f-20 font-bold b-text italic"
                              style={
                                beneficiary?.contract_staus == 'sent' &&
                                beneficiary?.paymentLink
                                  ? {
                                      cursor: 'pointer',
                                      position: 'relative',
                                      textDecoration: 'underline',
                                      zIndex: 1,
                                    }
                                  : {}
                              }
                              onClick={() => {
                                if (
                                  beneficiary.paymentHistoryDetails &&
                                  beneficiary.paymentHistoryDetails.length > 1
                                ) {
                                  navigateToHistory(beneficiary);
                                } else {
                                  beneficiary?.contract_staus == 'sent' &&
                                    beneficiary?.paymentLink &&
                                    openPayment(beneficiary?.paymentLink);
                                }
                              }}>
                              {beneficiary?.contract_staus == 'sent' &&
                              beneficiary?.paymentLink
                                ? 'To Sign'
                                : 'Not Applicable'}
                            </Typography>
                          )}
                        </Typography>
                        <Typography className="lp-item">
                          <Typography className="in-item" />
                          <Typography className="black-white-text f-16 mb-1">
                            Document Checklist :
                          </Typography>

                          {beneficiary?.petition_submitted ? (
                            <Typography className="f-20 font-bold b-text italic">
                              Not Applicable
                            </Typography>
                          ) : (
                            <Typography
                              className="f-20 font-bold b-text italic"
                              style={{
                                cursor: 'pointer',
                                position: 'relative',
                                textDecoration: 'underline',
                                zIndex: 1,
                              }}
                              onClick={() => uploadDoc(beneficiary)}>
                              To Upload
                            </Typography>
                          )}
                        </Typography>
                        <Typography className="lp-item">
                          <Typography className="in-item" />
                          <Typography className="black-white-text f-16 mb-1">
                            Beneficiary Information :
                          </Typography>

                          {beneficiary?.visa_type &&
                          beneficiary?.visa_type.length ? (
                            <>
                              {beneficiary?.petitionId &&
                                !beneficiary.petition_submitted && (
                                  <Typography
                                    className="f-20 font-bold b-text italic"
                                    style={{
                                      cursor: 'pointer',
                                      position: 'relative',
                                      textDecoration: 'underline',
                                      zIndex: 1,
                                    }}
                                    onClick={async () => {
                                      setLoader(true);
                                      await getPetitionApiData(
                                        beneficiary.petitionId,
                                        dispatch,
                                      );
                                      setLoader(false);
                                      navigate(
                                        `${
                                          BeneficiaryPrivateRoutes.COMPLETEQUESTIONS
                                        }/${String(
                                          beneficiary?.petitionId,
                                        )}?leadUser=${String(
                                          beneficiary.lead_user_id,
                                        )}&type=petition`,
                                      );
                                    }}>
                                    View / Edit
                                  </Typography>
                                )}

                              {!beneficiary?.petitionId && (
                                <NavLink
                                  style={{
                                    cursor: 'pointer',
                                    position: 'relative',

                                    zIndex: 1,
                                  }}
                                  to={`${String(
                                    BeneficiaryPrivateRoutes.LEADCOMPLETEQUESTIONS,
                                  )}/${String(
                                    beneficiary?.lead_user_id,
                                  )}?leadUser=${String(
                                    beneficiary.lead_user_id,
                                  )}&lead=${String(beneficiary.lead_id)}`}>
                                  <Typography
                                    className="f-20 font-bold b-text italic"
                                    sx={{
                                      textDecoration: 'underline',
                                    }}>
                                    View / Edit
                                  </Typography>
                                </NavLink>
                              )}

                              {beneficiary?.petition_submitted && (
                                <Typography className="f-20 font-bold b-text italic">
                                  Not Applicable
                                </Typography>
                              )}
                            </>
                          ) : (
                            <Typography className="f-20 font-bold b-text italic">
                              Not Applicable
                            </Typography>
                          )}
                        </Typography>

                        <Typography className="lp-item">
                          <Typography className="in-item" />
                          <Typography className="black-white-text f-16 mb-1">
                            Strategy :
                          </Typography>
                          {!beneficiary?.petitionId &&
                            !beneficiary.petition_submitted && (
                              <NavLink
                                style={{
                                  cursor: 'pointer',
                                  position: 'relative',
                                  zIndex: 1,
                                }}
                                to={`${String(
                                  BeneficiaryPrivateRoutes.BENEFICIARYLEADSTRATEGY,
                                )}/${String(
                                  beneficiary.lead_id,
                                )}?leadUser=${String(
                                  beneficiary.lead_user_id,
                                )}`}>
                                <Typography
                                  className="f-20 font-bold b-text italic"
                                  sx={{
                                    textDecoration: 'underline',
                                  }}>
                                  View / Edit
                                </Typography>
                              </NavLink>
                            )}

                          {beneficiary?.petitionId &&
                            !beneficiary.petition_submitted && (
                              <NavLink
                                style={{
                                  cursor: 'pointer',
                                  position: 'relative',
                                  zIndex: 1,
                                }}
                                to={`${
                                  BeneficiaryPrivateRoutes.BENEFICIARYSTRATEGY
                                }/${String(
                                  beneficiary.petitionId,
                                )}?leadUser=${String(
                                  beneficiary.lead_user_id,
                                )}`}>
                                <Typography
                                  className="f-20 font-bold b-text italic"
                                  sx={{
                                    textDecoration: 'underline',
                                  }}>
                                  View / Edit
                                </Typography>
                              </NavLink>
                            )}

                          {beneficiary?.petition_submitted && (
                            <Typography className="f-20 font-bold b-text italic">
                              Not Editable
                            </Typography>
                          )}
                        </Typography>
                        {beneficiary?.petitionId ? (
                          <Typography className="lp-item">
                            <Typography className="in-item" />
                            <Typography className="black-white-text f-16 mb-1">
                              Review and Approve :
                            </Typography>
                            {beneficiary?.petitionId &&
                              !beneficiary.petition_submitted && (
                                <Typography
                                  className="f-20 font-bold b-text italic"
                                  style={{
                                    cursor: 'pointer',
                                    position: 'relative',
                                    textDecoration: 'underline',
                                    zIndex: 1,
                                  }}
                                  onClick={async () => {
                                    setLoader(true);
                                    await getPetitionApiData(
                                      beneficiary.petitionId,
                                      dispatch,
                                    );
                                    setLoader(false);
                                    navigate(
                                      `${
                                        BeneficiaryPrivateRoutes.REVIEWANDAPPROVE
                                      }/${String(
                                        beneficiary?.petitionId,
                                      )}?leadUser=${String(
                                        beneficiary.lead_user_id,
                                      )}`,
                                    );
                                  }}>
                                  View / Approve
                                </Typography>
                              )}

                            {beneficiary?.petition_submitted && (
                              <Typography className="f-20 font-bold b-text italic">
                                Not Editable
                              </Typography>
                            )}
                          </Typography>
                        ) : null}
                      </Typography>
                    </Typography>
                    <Typography component={'div'} className="bene-box2">
                      <Typography className="inb2" />
                      <Typography className="black-white-text f-18 mb-1">
                        TEAM MEMBERS
                      </Typography>
                      <Typography component={'div'} className="d-flex-a mt-2">
                        <Typography className="d-flex-ja mr-2 flex-column mt-2">
                          <img src={Avator} alt="im" className="team-im" />
                          <Typography className="f-16 mt-1 black-white-text">
                            {beneficiary?.attorney_name
                              ? beneficiary?.attorney_name
                              : '-'}
                          </Typography>
                          <Typography className="mt-1 b-text">
                            Attorney
                          </Typography>
                        </Typography>
                        <Typography className="d-flex-ja mr-2 mt-2 flex-column">
                          <img src={Avator} alt="im" className="team-im" />
                          <Typography className="f-16 mt-1 black-white-text">
                            {beneficiary?.case_managers[0]
                              ? String(
                                  beneficiary?.case_managers[0].first_name,
                                ) +
                                String(beneficiary?.case_managers[0]?.last_name)
                              : '-'}
                          </Typography>
                          <Typography className="mt-1 b-text">
                            Case Manager
                          </Typography>
                        </Typography>
                        <Typography className="d-flex-ja mr-2 mt-2 flex-column">
                          <img src={Avator} alt="im" className="team-im" />
                          <Typography className="f-16 mt-1 black-white-text">
                            {beneficiary?.petitioner_name
                              ? beneficiary?.petitioner_name
                              : '-'}
                          </Typography>
                          <Typography className="mt-1 b-text">
                            Petitioner
                          </Typography>
                        </Typography>
                      </Typography>
                    </Typography>
                  </Typography>
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <Typography
              className="note-box2"
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}>
              <Typography className="inb2" />
              <Typography className="black-white-text ctitle hideshow">
                No Record(s) Found.
              </Typography>
            </Typography>
          )}

          {viewNotes.show && (
            <AddNote
              notes={viewNotes}
              setNotes={setViewNotes}
              type={viewNotes.type ? viewNotes.type : 'lead'}
              showModel={true}
              showTable={true}
              leadId={viewNotes.leadOrPetitionId}
            />
          )}
        </Grid>
      )}
    </Typography>
  );
};
