import { Button, Grid, Typography } from '@mui/material';
import Success from 'assets/images/payment-check.png';
import Right from 'assets/images/rightarr.svg';
// import Right from 'assets/images/rightarr.svg';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import React, { FC, memo, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData, getS3file, updateData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

export const PaymentDetail: FC<any> = memo(() => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<any>({});
  const [confirmOpen, setConfirmOpen] = useState<any>({
    content: '',
    show: false,
    type: '',
  });
  const [paymentSuccess, setPaymentSucess] = useState<any>({});
  const fileDownload = (file) => {
    const obj = {
      key: file,
    };
    const fileName = file ? file.split('/').pop() : 'download';
    setLoader(true);

    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        toast.success(fileName + 'File downloaded', alertOptions);
      })
      .catch((err) => {
        setLoader(false);
        toast.error('Unable to read the file.', alertOptions);
      });
  };

  const confirmPayment = (action) => {
    if (action === 'yes') {
      confirmOpen.type === 'card' ? redirectCard() : redirectAch();
    }
  };

  const redirectCard = async () => {
    setLoader(true);
    const payload = {
      hashKey: searchParams.get('hashKey'),
      paymentType: 'card',
    };
    await updateData(
      '',
      payload,
      `${COMMON_ENDPOINT.ContractPayment}/update-payment`,
    )
      .then((resp: any) => {
        navigate(
          `/secure-payment/payment-option?hashKey=${searchParams.get(
            'hashKey',
          )}`,
          { state: { type: 'card' } },
        );
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  const redirectAch = async () => {
    setLoader(true);
    const payload = {
      hashKey: searchParams.get('hashKey'),
      paymentType: 'ach',
    };
    await updateData(
      '',
      payload,
      `${COMMON_ENDPOINT.ContractPayment}/update-payment`,
    )
      .then((resp: any) => {
        navigate(
          `/secure-payment/payment-option?hashKey=${searchParams.get(
            'hashKey',
          )}`,
          { state: { type: 'ach' } },
        );
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (searchParams.get('hashKey') !== '') {
      setLoader(true);
      getAllListData(
        `${COMMON_ENDPOINT.ContractPayment}?hashKey=${searchParams.get(
          'hashKey',
        )}`,
      )
        .then((info: any) => {
          setPaymentInfo(info.data);
          setPaymentSucess(info);
          setLoader(false);
        })
        .catch((e) => {
          // toast.error(
          //   'The link is invalid. Please contact your attorney.',
          //   alertOptions,
          // );
          navigate('/login');
          setLoader(false);
        });
    } else {
      toast.error('Page not found', alertOptions);
      navigate('/login');
    }
  }, [navigate, searchParams]);

  return loader ? (
    <div className="liberty-loader" />
  ) : !_.isEmpty(paymentInfo) ? (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} className="pl-0">
        <Typography component={'div'} className="payment-box">
          <Typography component={'div'} className="top-box">
            <Typography className="f-20 font-bold mb-2">
              Hi {!_.isEmpty(paymentInfo) ? paymentInfo.userName : '-'}
            </Typography>
            <Typography className="f-15 pay-dis mb-2">
              Thank you for choosing{' '}
              {!_.isEmpty(paymentInfo) ? paymentInfo.lawFirmName : '-'} to help
              you achieve your immigration goals. Please complete the below
              steps to initiate the process. If you have any questions, our team
              is available to help you. Simply click on the chat now button,
              email{' '}
              <a href={`mailto:${String(process.env.REACT_APP_CONTACT_MAIL)}`}>
                {String(process.env.REACT_APP_CONTACT_MAIL)}
              </a>{' '}
              or call <a href="tel:+1-405-310-4333">+1 405 310 4333</a>.
            </Typography>
            <Grid container className="pt-2">
              {!_.isEmpty(paymentInfo) &&
                paymentInfo.paymentInitiatedFor === 4 && (
                  <Grid item md={4} sm={12}>
                    <Typography className="oname-label f-15">
                      Organization Name:
                    </Typography>
                    <Typography className="oname-text f-15 font-bold">
                      {paymentInfo.orgName ? paymentInfo.orgName : '-'}
                    </Typography>
                  </Grid>
                )}
              {!_.isEmpty(paymentInfo) &&
                paymentInfo.paymentInitiatedFor === 2 && (
                  <Grid item md={4} sm={12}>
                    <Typography className="oname-label f-15">
                      Beneficiary Name:
                    </Typography>
                    <Typography className="oname-text f-15 font-bold">
                      {paymentInfo.beneficiaryname
                        ? paymentInfo.beneficiaryname
                        : '-'}
                    </Typography>
                  </Grid>
                )}
              <Grid item md={4} sm={12}>
                <Typography className="oname-label f-15">Visa Type:</Typography>
                <Typography className="oname-text f-15 font-bold">
                  {!_.isEmpty(paymentInfo) && paymentInfo.visaType
                    ? paymentInfo.visaType
                    : '-'}
                </Typography>
              </Grid>

              <Grid item md={4} sm={12}>
                <Typography className="oname-label f-15">
                  Invoice Purpose
                </Typography>
                <Typography className="oname-text f-15 font-bold">
                  {!_.isEmpty(paymentInfo) && paymentInfo.paymentOptionName
                    ? paymentInfo.paymentOptionName
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </Typography>
          <Typography component={'div'} className="bottom-box">
            <Grid container spacing={{ md: 2, sm: 1 }}>
              {!_.isEmpty(paymentInfo) &&
                !_.isNull(paymentInfo.attorneyContract) &&
                _.isNull(paymentInfo.userContract) && (
                  <>
                    <Grid item md={10} sm={12} className="d-flex-jae">
                      <Typography className="pay-ri">
                        Please click the View button to view your contract. This
                        contract is based on the discussions we have had with
                        you.
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={12} className="d-flex">
                      {!_.isEmpty(paymentInfo.attorneyContract) ? (
                        <Button
                          className="view-btn"
                          onClick={() =>
                            navigate(
                              `/secure-payment/contract-viewer?hashKey=${searchParams.get(
                                'hashKey',
                              )}`,
                            )
                          }>
                          View
                        </Button>
                      ) : (
                        <Button className="view-btn">No File</Button>
                      )}
                    </Grid>
                  </>
                )}

              {!_.isEmpty(paymentInfo) && !_.isNull(paymentInfo.userContract) && (
                <>
                  <Grid item md={10} sm={12} className="d-flex-jae">
                    <Typography className="pay-ri">
                      Please click the download button to view your contract.
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={12}>
                    <Button
                      className="view-btn"
                      onClick={() => fileDownload(paymentInfo.userContract)}>
                      Download
                    </Button>
                  </Grid>
                </>
              )}
              {!_.isEmpty(paymentInfo) && paymentInfo.paymentTotal && (
                <>
                  <Grid item md={12} sm={12} className="d-flex-ja">
                    <Typography className="pay-ri pl-5">
                      You have been requested to pay ${paymentInfo.paymentTotal}{' '}
                      towards the charges for preparing your petition. You can
                      pay via ACH/Credit Card/Debit Card
                    </Typography>
                  </Grid>
                  <Grid item md={12} sm={12} className="d-flex-ja">
                    <Typography className="pay-ri pay-head">
                      Select your preferred payment method from the option below
                    </Typography>
                  </Grid>
                  {/* <Grid item md={2} sm={12}>
                      {!_.isNull(paymentInfo.paymentTotal) ? (
                        <Button
                          className="pay-btn"
                          onClick={() => {
                            if (
                              !_.isNull(paymentInfo.attorneyContract) &&
                              _.isNull(paymentInfo.userContract)
                            ) {
                              setConfirmOpen({
                                content:
                                  'You have not accepted the contract yet. Are you sure you would like to complete the payment?',
                                show: true,
                              });
                            } else {
                              navigate(
                                `/secure-payment/payment-option?hashKey=${searchParams.get(
                                  'hashKey',
                                )}`,
                              );
                            }
                          }}>
                          Pay Now
                        </Button>
                      ) : (
                        <Button className="pay-btn" disabled>
                          Pay
                        </Button>
                      )}
                    </Grid> */}
                  {!_.isNull(paymentInfo.paymentTotal) ? (
                    <Typography
                      component={'div'}
                      className="pay-method d-flex-a">
                      {/* Pay with credit card */}
                      <Typography
                        component={'div'}
                        className="pay-met-box payment-choose-card mb-2"
                        onClick={() => {
                          if (
                            !_.isNull(paymentInfo.attorneyContract) &&
                            _.isNull(paymentInfo.userContract)
                          ) {
                            setConfirmOpen({
                              content:
                                'You have not accepted the contract yet. Are you sure you would like to complete the payment?',
                              show: true,
                              type: 'card',
                            });
                          } else {
                            redirectCard();
                          }
                        }}>
                        <Grid container>
                          <Grid item md={11} sm={11}>
                            <Typography className="f-17 font-bold mb-2">
                              Pay with Credit / Debit Card
                            </Typography>
                            <Typography className="f-15 pay-dis-text">
                              <span className="opacity-text">
                                Make a one time payment with your credit or
                                debit card.{' '}
                              </span>
                              {!_.isEmpty(paymentInfo) &&
                              paymentInfo.cardServiceCharge ? (
                                <>
                                  <span className="opacity-text">
                                    There will be an additional{' '}
                                  </span>
                                  <span className="percentage">
                                    {paymentInfo.cardServiceCharge}
                                    {'%'}
                                  </span>{' '}
                                  <span className="opacity-text">
                                    transaction charge on the total fees
                                    payable.
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={1}
                            sm={1}
                            className="d-flex-jae arrow-hide">
                            <img src={Right} alt="arr" />
                          </Grid>
                        </Grid>
                      </Typography>
                      {/* Pay with ACH */}
                      <Typography
                        component={'div'}
                        className="pay-met-box payment-choose-card mb-2"
                        onClick={() => {
                          if (
                            !_.isNull(paymentInfo.attorneyContract) &&
                            _.isNull(paymentInfo.userContract)
                          ) {
                            setConfirmOpen({
                              content:
                                'You have not accepted the contract yet. Are you sure you would like to complete the payment?',
                              show: true,
                              type: 'ach',
                            });
                          } else {
                            redirectAch();
                          }
                        }}>
                        <Grid container>
                          <Grid item md={11} sm={11}>
                            <Typography className="f-17 font-bold mb-2">
                              ACH Payment
                            </Typography>
                            <Typography className="f-15 pay-dis-text">
                              <span className="opacity-text">
                                {' '}
                                Make a one time payment with your bank account.
                              </span>
                              {!_.isEmpty(paymentInfo) &&
                              paymentInfo.achServiceCharge ? (
                                <>
                                  <span className="opacity-text">
                                    You will be charged{' '}
                                  </span>
                                  <span className="percentage">
                                    {'$'}
                                    {paymentInfo.achServiceCharge}
                                  </span>{' '}
                                  <span className="opacity-text">
                                    as transaction charge.
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                              &nbsp;
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={1}
                            sm={1}
                            className="d-flex-jae arrow-hide">
                            <img src={Right} alt="arr" />
                          </Grid>
                        </Grid>
                      </Typography>
                    </Typography>
                  ) : null}
                </>
              )}
            </Grid>
          </Typography>
          <ConfirmDialog
            title="Confirm"
            floatingBtnShow={true}
            open={confirmOpen.show}
            onConfirm={(act) =>
              act === 'yes'
                ? confirmPayment(act)
                : setConfirmOpen({
                    content: '',
                    show: false,
                    type: '',
                  })
            }
            autoCloseDisable={true}>
            {confirmOpen.content}
          </ConfirmDialog>
        </Typography>
      </Grid>
    </Grid>
  ) : (
    paymentSuccess?.paymentCompleted && (
      <div>
        <div
          className="innerBox payment-public"
          style={{ borderRadius: '12px', marginTop: '30px' }}>
          <img
            src={Success}
            alt="Success"
            style={{ marginBottom: '30px', marginTop: '60px' }}
          />
          <Typography className="suc-title">
            {paymentSuccess?.message}
          </Typography>

          <Typography className="suc-dis">
            If you would like to request for any Support in the meantime, please
            contact us at{' '}
            <a href={`mailto:${String(process.env.REACT_APP_CONTACT_MAIL)}`}>
              <span style={{ color: 'rgba(25, 82, 167, 1)' }}>
                {String(process.env.REACT_APP_CONTACT_MAIL)}
              </span>
            </a>
          </Typography>
          <Button
            className="success-btn-new"
            onClick={() => navigate('/login')}>
            Done
          </Button>
        </div>
      </div>
    )
  );
});

export default PaymentDetail;
