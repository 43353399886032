import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from 'assets/images/liberty-logoheader.png';
// import Space from 'assets/images/space.png';
import Footer from 'components/Footer';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
// import '../PrivacyPolicy/style.css'
// import { useHistory } from "react-router-dom";

export const useStyles = makeStyles((theme) => ({
  space: {
    backgroundColor: '#09171f',
    backgroundImage: `url(${'https://d17p81usn86n3p.cloudfront.net/space.png'})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    bottom: '0',
    left: '0',
    minHeight: '100%',
    position: 'fixed',
    right: '0',
    top: '0',
    width: '100%',
  },
}));

const TermsOfService: React.FC = React.memo(() => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const classes = useStyles();
  const navigate = useNavigate();
  window.scrollTo({
    behavior: 'smooth',
    top: 0,
  });
  return (
    <div>
      <Typography className=" black-white-text p-f pt-2">
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <Typography className={classes.space} />
      <div className="innerBox">
        <div
          className=" d-flex-a d-flex-sb mb-1 sticky-tab"
          style={{ top: '0px' }}>
          <div className="black-white-text f-20 font-bold p-wtext">
            Terms of Service
          </div>
          <div>
            <Button
              className="cancel-btn mr-4"
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '1em !important',
                width: '120px !important',
              }}
              onClick={() =>
                navigate(`/${String(userDetail?.userRoleName)}/dashboard`)
              }>
              <Typography
                className="o-box d-flex-a"
                sx={{ width: 'unset !important' }}
              />
              Cancel
            </Button>
          </div>
        </div>
        <div
          className="card"
          style={{
            paddingBottom: '15px',
            paddingLeft: '35px',
            paddingRight: '35px',
            paddingTop: '15px',
          }}>
          <h2 className="text-center">CUSTOMER TERMS OF SERVICE</h2>
          <p className="font-bold">
            PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY CLICKING “ACCEPTED
            AND AGREED TO,” YOU AGREE TO THESE TERMS AND CONDITIONS ON BEHALF OF
            YOUR CUSTOMER.
          </p>
          <p>
            These Customer Terms of Service constitute an agreement (this
            “Agreement”) by and between OnlineVisas, Inc., a Delaware
            Corporation, whose principal place of business is at 401 W. Main
            St., Ste. 300, Norman, OK 73069 (“OnlineVisas”) and the corporation,
            LLC, partnership, sole proprietorship, or other business entity
            executing this Agreement (“Customer”). This Agreement is effective
            as of the date Customer clicks “Accepted and Agreed To” (the
            “Effective Date”). Customer’s use of and OnlineVisas’ provision of
            OnlineVisas’ OnlineVisas Gateway software-as-a-service and related
            professional services are governed by this Agreement.
          </p>
          <p>
            EACH PARTY ACKNOWLEDGES THAT IT HAS READ THIS AGREEMENT, UNDERSTANDS
            IT, AND AGREES TO BE BOUND BY ITS TERMS, AND THAT THE PERSON SIGNING
            ON ITS BEHALF HAS BEEN AUTHORIZED TO DO SO. THE PERSON EXECUTING
            THIS AGREEMENT ON CUSTOMER’S BEHALF REPRESENTS THAT HE OR SHE HAS
            THE AUTHORITY TO BIND CUSTOMER TO THESE TERMS AND CONDITIONS.
          </p>
          <ol className="information">
            <li>
              <span className="font-bold">DEFINITIONS.</span> The following
              capitalized terms will have the following meanings whenever used
              in this Agreement.
              <ol>
                <li>
                  “AUP” means OnlineVisas’ acceptable use policy currently
                  posted at www.OnlineVisas.com.
                </li>
                <li>
                  “Customer Data” means data in electronic form input or
                  collected through the System by or from Customer, including
                  without limitation by Customer’s Users.
                </li>
                <li>
                  “Deliverables” means any software, manual, or other
                  deliverable created or provided pursuant to Professional
                  Services.
                </li>
                <li>
                  “Law Firm” means any law firm or lawyer that is using
                  OnlineVisas program.
                </li>
                <li>
                  “Order” means an order for access to the System, executed by
                  Customer and accepted by OnlineVisas through OnlineVisas’
                  then-standard ordering system, whether such system calls for
                  paper orders, electronic forms, or electronic execution
                  online.
                </li>
                <li>
                  “Privacy Policy” means OnlineVisas’ privacy policy, currently
                  posted at www.OnlineVisas.com.
                </li>
                <li>
                  “Professional Services” means such OnlineVisas’ training,
                  custom development, or other professional services called for
                  in the Order or in an SoW, if any.
                </li>
                <li>
                  “SoW” means a statement of work on OnlineVisas’ standard form,
                  executed by each party.
                </li>
                <li>
                  “System” means OnlineVisas’ OnlineVisas Gateway system,
                  provided to the commercial market as software-as-a-service.
                </li>
                <li>
                  “SLA” means OnlineVisas’ standard service level agreement,
                  currently posted at www.OnlineVisas.com.
                </li>
                <li>“Term” is defined in Section 12.1 below.</li>
                <li>
                  “User” means any company or individual who uses the System on
                  Customer’s behalf or through Customer’s account or passwords,
                  whether authorized or not.
                </li>
              </ol>
            </li>

            <li>
              <span className="font-bold">THE SYSTEM.</span>
              <ol>
                <li>
                  Use of the System. During the Term, Customer may access and
                  use the System pursuant to the terms of any outstanding Order,
                  including such authorized users, features, and functions as
                  the Order requires.
                </li>
                <li>
                  Service Levels. OnlineVisas shall provide the remedies listed
                  in the SLA for any failure of the System listed in the SLA.
                  Such remedies are Customer’s sole remedy for any failure of
                  the System, and Customer recognizes and agrees that if the SLA
                  does not list a remedy for a given failure, it has no remedy;
                  provided this sentence does not restrict any Customer right to
                  terminate this Agreement for breach where applicable. Credits
                  issued pursuant to the SLA apply to outstanding or future
                  invoices only and are forfeit upon termination of this
                  Agreement. OnlineVisas is not required to issue refunds or to
                  make payments against such credits under any circumstances,
                  including without limitation after termination of this
                  Agreement. OnlineVisas may revise the SLA at any time. If any
                  such revision materially reduces service levels provided
                  pursuant to an outstanding Order, the revisions will not go
                  into effect with respect to such Order until the start of the
                  Term beginning 45 or more days after OnlineVisas posts the
                  revision and so informs Customer.
                </li>
                <li>
                  Legal Services not Included. Customer recognizes and agrees
                  that neither the System nor the Professional Services includes
                  legal services, and any legal services Customer may receive
                  from a Law Firm related to use of the System or other matters
                  are provided by that Law Firm and not by OnlineVisas. The
                  disclaimer in the preceding sentence applies to Law Firms that
                  themselves subscribe to the System and use it to provide
                  services to Customer, to Law Firms that Customer encounters as
                  a result of the System, and to all other Law Firms.
                </li>
              </ol>
            </li>
            <li>
              <span className="font-bold">PROFESSIONAL SERVICES.</span>
              <ol>
                <li>
                  Provision of Professional Services. OnlineVisas shall provide
                  the Professional Services to the extent called for in an Order
                  or SoW. Customer shall provide any assistance and cooperation
                  called for in an Order or SoW or necessary or convenient to
                  facilitate the Professional Services.
                </li>
                <li>
                  Deliverables.
                  <ol>
                    <li>
                      License. If the Order or SoW requires that OnlineVisas
                      provide copies of a Deliverable, then effective upon full
                      payment of fees associated with the Deliverable,
                      OnlineVisas grants Customer a nonexclusive license to
                      reproduce and use the Deliverable, in such quantities as
                      are set forth on the applicable Order or SoW, solely as
                      necessary for Customer’s internal business purposes and
                      solely in support of Customer’s use of the System. The
                      license in the preceding sentence continues only so long
                      as Customer complies with the restrictions set forth below
                      in Subsection 3.2(b) (Restrictions on Deliverables).
                    </li>
                    <li>
                      Restrictions on Deliverables. Copies of the software
                      components of Deliverables licensed to Customer are not
                      sold, and Customer receives no title to or ownership of
                      any copy or of such software itself. Furthermore, Customer
                      receives no rights to Deliverables other than those
                      specifically granted in this Section 3.1 or in the Order
                      or SoW. Without limiting the generality of the foregoing,
                      Customer shall not: (a) modify, create derivative works
                      from, distribute, publicly display, publicly perform, or
                      sublicense any software provided by OnlineVisas; (b) use
                      any software provided by OnlineVisas in any way forbidden
                      by Section 6.1 below (Acceptable Use); or (c) reverse
                      engineer, decompile, disassemble, or otherwise attempt to
                      derive any source code for software provided by
                      OnlineVisas. Customer recognizes and agrees that it
                      receives no copies of or copyright license to any
                      Deliverable incorporated into the System. Customer’s
                      rights to such a Deliverable are limited to remote access
                      and use as part of the System.
                    </li>
                    <li>
                      Acceptance &amp; Rejection. Unless the applicable Order or
                      SoW provides to the contrary, Deliverables customized for
                      Customer will be considered accepted (“Acceptance”) (a)
                      when Customer provides OnlineVisas written notice of
                      acceptance or (b) 5 business days after delivery, if
                      Customer has not first provided OnlineVisas with written
                      notice of rejection. Customer may reject a customized
                      Deliverable only if it materially deviates from its
                      specifications and requirements listed in the Order of SoW
                      and only via written notice setting forth the nature of
                      such deviation. In the event of such rejection,
                      OnlineVisas shall correct the deviation and redeliver the
                      customized Deliverable reasonably promptly. After
                      redelivery pursuant to the previous sentence, the parties
                      shall again follow the acceptance procedures set forth in
                      this Subsection 3.2(c). This Subsection 3.2(c), in
                      conjunction with Customer’s right to terminate for
                      material breach where applicable, sets forth Customer’s
                      only remedy and OnlineVisas’ only liability for failure of
                      customized Deliverables.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <span className="font-bold">FEES.</span> Customer shall (a) pay
              OnlineVisas the fees set forth in each Order for access to the
              System and for Professional Services; and (b) reimburse
              OnlineVisas’ reasonable expenses and preauthorized expenses
              incurred in provision of Professional Services. Professional
              Services fees listed in Orders and SoW’s are estimates and will
              not be binding, except to the extent that the Order or SoW
              specifically provides to the contrary. OnlineVisas will not be
              required to refund fees under any circumstances. OnlineVisas’
              invoices are due within 30 days of issuance. For late payment,
              Customer shall pay interest charges from the time the payment was
              due at the rate that is the lower of 1.5% per month or the highest
              rate permissible under applicable law.
            </li>

            <li>
              <span className="font-bold">CUSTOMER DATA &amp; PRIVACY.</span>
              <ol>
                <li>
                  Use of Customer Data. Unless it receives Customer’s prior
                  written consent, OnlineVisas: (a) shall not access, process,
                  or otherwise use Customer Data other than as necessary to
                  facilitate the System; and (b) shall not intentionally grant
                  any third-party access to Customer Data, including without
                  limitation OnlineVisas’ other customers, except subcontractors
                  that are subject to a reasonable nondisclosure agreement.
                  Notwithstanding the foregoing, OnlineVisas may disclose
                  Customer Data as required by applicable law or by proper legal
                  or governmental authority. OnlineVisas shall give Customer
                  prompt notice of any such legal or governmental demand and
                  reasonably cooperate with Customer in any effort to seek a
                  protective order or otherwise to contest such required
                  disclosure, at Customer’s expense.
                </li>
                <li>
                  Data Security Audits. OnlineVisas represents that, as of the
                  Effective Date, it retains a certified public accounting firm
                  to perform an annual audit of the System’s data protection
                  features and to provide a SOC 2 Type II report, pursuant to
                  the standards of the American Institute of Certified Public
                  Accountants (the “AICPA”), or such other report as AICPA may
                  promulgate to replace SOC 2 Type II. Reasonably promptly after
                  Customer’s request, OnlineVisas shall provide a copy of its
                  most recent such report, provided such report will be
                  OnlineVisas’ Confidential Information pursuant to Article 8
                  below. If OnlineVisas ceases the auditing referenced in this
                  Section 5.2, it shall inform Customer in writing reasonably
                  promptly and in any case no later than 1 year following
                  OnlineVisas’ receipt of the last audit report.
                </li>
                <li>
                  Privacy Policy. The Privacy Policy applies only to the System
                  and does not apply to any third-party website or service
                  linked to the System or recommended or referred to through the
                  System or by OnlineVisas’ staff.
                </li>
                <li>
                  Risk of Exposure. Customer recognizes and agrees that hosting
                  data online involves risks of unauthorized disclosure or
                  exposure and that, in accessing and using the System, Customer
                  assumes such risks. OnlineVisas offers no representation,
                  warranty, or guarantee that Customer Data will not be exposed
                  or disclosed through errors or the actions of third parties.
                </li>
                <li>
                  Data Accuracy. OnlineVisas shall have no responsibility or
                  liability for the accuracy of data uploaded to the System by
                  Customer, including without limitation Customer Data and any
                  other data uploaded by Users.
                </li>
                <li>
                  Data Deletion. OnlineVisas may permanently erase Customer Data
                  if Customer’s account is delinquent, suspended, or terminated
                  for 30 days or more.
                </li>
                <li>
                  Aggregate &amp; Anonymized Data. Notwithstanding the
                  provisions above of this Article 5, OnlineVisas may use,
                  reproduce, sell, publicize, or otherwise exploit Aggregate
                  Data in any way, in its sole discretion. (“Aggregate Data”
                  refers to Customer Data with the following removed: personally
                  identifiable information and the names and addresses of
                  Customer and any of its Users.)
                </li>
              </ol>
            </li>

            <li>
              <span className="font-bold">
                CUSTOMER’S RESPONSIBILITIES &amp; RESTRICTIONS.
              </span>
              <ol>
                <li>
                  Acceptable Use. Customer shall comply with the AUP. Customer
                  shall not: (a) use the System or Deliverables for service
                  bureau or time-sharing purposes or in any other way allow
                  third parties to exploit the System; (b) provide System
                  passwords or other log-in information to any third party; (c)
                  share non-public System or Deliverables features or content
                  with any third party; or (d) access the System or Deliverables
                  in order to build a competitive product or service, to build a
                  product using similar ideas, features, functions or graphics,
                  or to copy any ideas, features, functions or graphics of the
                  System or Deliverables. In the event that it suspects any
                  breach of the requirements of this Section 6.1, including
                  without limitation by Users, OnlineVisas may suspend
                  Customer’s access to the System without advanced notice, in
                  addition to such other remedies as OnlineVisas may have.
                  Neither this Agreement nor the AUP requires that OnlineVisas
                  take any action against Customer or any User or other third
                  party for violating the AUP, this Section 6.1, or this
                  Agreement, but OnlineVisas is free to take any such action it
                  sees fit.
                </li>
                <li>
                  Unauthorized Access. Customer shall take reasonable steps to
                  prevent unauthorized access to the System and Deliverables,
                  including without limitation by protecting its passwords and
                  other log-in information. Customer shall notify OnlineVisas
                  immediately of any known or suspected unauthorized use of the
                  System or Deliverables or breach of its security and shall use
                  best efforts to stop said breach.
                </li>
                <li>
                  Compliance with Laws. In its use of the System and
                  Deliverables, Customer shall comply with all applicable laws,
                  including without limitation laws governing the protection of
                  personally identifiable information and other laws applicable
                  to the protection of Customer Data.
                </li>
                <li>
                  Users; System Access. Customer is responsible and liable for:
                  (a) Users’ use of the System and Deliverables, including
                  without limitation unauthorized User conduct and any User
                  conduct that would violate the AUP or the requirements of this
                  Agreement applicable to Customer; and (b) any use of the
                  System or Deliverables through Customer’s account, whether
                  authorized or unauthorized.
                </li>
              </ol>
            </li>

            <li>
              <span className="font-bold">IP &amp; FEEDBACK.</span>
              <ol>
                <li>
                  IP Rights in the System. OnlineVisas retains all right, title,
                  and interest in and to the System, Professional Services, and
                  Deliverables, including without limitation all software used
                  to provide the System and all graphics, user interfaces,
                  logos, and trademarks reproduced through the System. This
                  Agreement does not grant Customer any intellectual property
                  license or rights in or to the System or any of its
                  components, except to the limited extent that this Agreement
                  specifically sets forth Customer license rights to
                  Deliverables. Customer recognizes that the System and
                  Deliverables and their components are protected by copyright
                  and other laws.
                </li>
                <li>
                  Feedback. OnlineVisas has not agreed to and does not agree to
                  treat as confidential any Feedback (as defined below) that
                  Customer or Users provide to OnlineVisas, and nothing in this
                  Agreement or in the parties’ dealings arising out of or
                  related to this Agreement will restrict OnlineVisas’ right to
                  use, profit from, disclose, publish, keep secret, or otherwise
                  exploit Feedback, without compensating or crediting Customer
                  or User in question. Feedback will not constitute Customer’s
                  confidential information. (“Feedback” refers to any suggestion
                  or idea for improving or otherwise modifying any of
                  OnlineVisas’ products or services.)
                </li>
              </ol>
            </li>

            <li>
              <span className="font-bold">CONFIDENTIAL INFORMATION.</span>{' '}
              “Confidential Information” refers to the following items
              OnlineVisas discloses to Customer: (a) any document OnlineVisas
              marks “Confidential”; (b) any information OnlineVisas orally
              designates as “confidential” at the time of disclosure, whether or
              not marked or designated confidential; and (c) any other
              nonpublic, sensitive information Customer should reasonably
              consider a trade secret or otherwise confidential. Notwithstanding
              the foregoing, Confidential Information does not include
              information that: (i) is in Customer’s possession at the time of
              disclosure; (ii) is independently developed by Customer without
              use of or reference to Confidential Information; (iii) becomes
              known publicly, before or after disclosure, other than as a result
              of Customer’s improper action or inaction; or (iv) is approved for
              release in writing by OnlineVisas.
              <ol>
                <li>
                  Nondisclosure. Customer shall not use Confidential Information
                  for any purpose other than managing and developing Immigration
                  cases or other related legal services (the “Purpose”).
                  Customer: (a) shall not disclose Confidential Information to
                  any employee or contractor of Customer unless such person
                  needs access in order to facilitate the Purpose and executes a
                  nondisclosure agreement with Customer with terms no less
                  restrictive than those of this Article 8; and (b) shall not
                  disclose Confidential Information to any other third party
                  without OnlineVisas’ prior written consent. Without limiting
                  the generality of the foregoing, Customer shall protect
                  Confidential Information with the same degree of care it uses
                  to protect its own confidential information of similar nature
                  and importance, but with no less than reasonable care.
                  Customer shall promptly notify OnlineVisas of any misuse or
                  misappropriation of Confidential Information that comes to
                  Customer’s attention. Notwithstanding the foregoing, Customer
                  may disclose Confidential Information as required by
                  applicable law or by proper legal or governmental authority.
                  Customer shall give OnlineVisas prompt notice of any such
                  legal or governmental demand and reasonably cooperate with
                  OnlineVisas in any effort to seek a protective order or
                  otherwise to contest such required disclosure, at OnlineVisas’
                  expense.
                </li>
                <li>
                  Injunction. Customer agrees that breach of this Article 8
                  would cause OnlineVisas irreparable injury, for which monetary
                  damages would not provide adequate compensation, and that in
                  addition to any other remedy, OnlineVisas will be entitled to
                  injunctive relief against such breach or threatened breach,
                  without proving actual damage or posting a bond or other
                  security.
                </li>
                <li>
                  Termination &amp; Return. Upon termination of this Agreement,
                  Customer shall return all copies of Confidential Information
                  to OnlineVisas or certify, in writing, the destruction
                  thereof. (The preceding sentence does not release Customer
                  from its obligations surrounding any Confidential Information
                  retained in memory or otherwise retained.)
                </li>
                <li>
                  Retention of Rights. This Agreement does not transfer
                  ownership of Confidential Information or grant a license
                  thereto. OnlineVisas will retain all right, title, and
                  interest in and to all Confidential Information.
                </li>
                <li>
                  Exception &amp; Immunity. Pursuant to the Defend Trade Secrets
                  Act of 2016, 18 USC Section 1833(b), Customer is on notice and
                  acknowledges that, notwithstanding the foregoing or any other
                  provision of this Agreement:
                  <ol>
                    <li>
                      IMMUNITY. An individual shall not be held criminally or
                      civilly liable under any Federal or State trade secret law
                      for the disclosure of a trade secret that- (A) is made-
                      (i) in confidence to a Federal, State, or local government
                      official, either directly or indirectly, or to an
                      attorney; and (ii) solely for the purpose of reporting or
                      investigating a suspected violation of law; or (B) is made
                      in a complaint or other document filed in a lawsuit or
                      other proceeding, if such filing is made under seal.
                    </li>
                    <li>
                      USE OF TRADE SECRET INFORMATION IN ANTI-RETALIATION
                      LAWSUIT. An individual who files a lawsuit for retaliation
                      by an employer for reporting a suspected violation of law
                      may disclose the trade secret to the attorney of the
                      individual and use the trade secret information in the
                      court proceeding, if the individual- (A) files any
                      document containing the trade secret under seal; and (B)
                      does not disclose the trade secret, except pursuant to
                      court order.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <span className="font-bold">
                REPRESENTATIONS &amp; WARRANTIES.
              </span>
              <ol>
                <li>
                  From OnlineVisas. OnlineVisas represents and warrants that it
                  is the owner of the System and Deliverables and of each and
                  every component thereof, or the recipient of a valid license
                  thereto, and that it has and will maintain the full power and
                  authority to grant the rights granted in this Agreement
                  without the further consent of any third party. In the event
                  of a breach of the warranty in this Section 9.1, OnlineVisas,
                  at its own expense, shall promptly take the following actions:
                  (a) secure for Customer the right to continue using the System
                  or Deliverable; (b) replace or modify the System or
                  Deliverable to make it noninfringing; or (c) terminate the
                  infringing features of the System or Deliverable and refund to
                  Customer any prepaid fees for such features, in proportion to
                  the portion of the Term left after such termination. In
                  conjunction with Customer’s right to terminate for breach
                  where applicable, the preceding sentence states OnlineVisas’
                  sole obligation and liability, and Customer’s sole remedy, for
                  breach of the warranty in this Section 9.1 and for potential
                  or actual intellectual property infringement by the System,
                  Professional Services, or Deliverables.
                </li>
                <li>
                  From Customer. Customer represents and warrants that: (a) it
                  has the full right and authority to enter into, execute, and
                  perform its obligations under this Agreement and that no
                  pending or threatened claim or litigation known to it would
                  have a material adverse impact on its ability to perform as
                  required by this Agreement; (b) it has accurately identified
                  itself and it has not provided any inaccurate information
                  about itself to or through the System; and (c) it is a
                  corporation, the sole proprietorship of an individual 18 years
                  or older, or another entity authorized to do pursuant to
                  applicable law.
                </li>
                <li>
                  Warranty Disclaimers. Except to the extent set forth in the
                  SLA and in Section 9.1 above, CUSTOMER ACCEPTS THE SYSTEM,
                  PROFESSIONAL SERVICES, AND DELIVERABLES “AS IS” AND AS
                  AVAILABLE, WITH NO REPRESENTATION OR WARRANTY OF ANY KIND,
                  EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED
                  WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                  PURPOSE, OR ANY IMPLIED WARRANTY ARISING FROM STATUTE, COURSE
                  OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE. WITHOUT
                  LIMITING THE GENERALITY OF THE FOREGOING: (a) ONLINEVISAS HAS
                  NO OBLIGATION TO INDEMNIFY OR DEFEND CUSTOMER OR USERS AGAINST
                  CLAIMS RELATED TO INFRINGEMENT OF INTELLECTUAL PROPERTY; (b)
                  ONLINEVISAS DOES NOT REPRESENT OR WARRANT THAT THE SYSTEM OR
                  DELIVERABLES WILL PERFORM WITHOUT INTERRUPTION OR ERROR; AND
                  (c) ONLINEVISAS DOES NOT REPRESENT OR WARRANT THAT THE SYSTEM
                  OR DELIVERABLES ARE SECURE FROM HACKING OR OTHER UNAUTHORIZED
                  INTRUSION OR THAT CUSTOMER DATA WILL REMAIN PRIVATE OR SECURE.
                </li>
                <li>
                  Disclaimers re Law Firms and Legal Advice.
                  <ol>
                    <li>
                      No Representations/Warranties re Law or Law Firms.
                      ONLINEVISAS MAKES NO REPRESNTATION OR WARRANTY ABOUT: (i)
                      THE OUTCOME OF ANY VISA APPLICATION OR ANY OTHER MATTER
                      CALLING FOR LEGAL ADVICE OR LEGAL ASSISTANCE; OR (ii) THE
                      ADVICE OR ASSISTANCE PROVIDED BY ANY LAW FIRM, INCLUDING
                      WITHOUT LIMITATION LAW FIRMS THAT THEMSELVES SUBSCRIBE TO
                      THE SYSTEM AND USE IT TO PROVIDE SERVICES TO CUSTOMER AND
                      LAW FIRMS THAT CUSTOMER ENCOUNTERS AS A RESULT OF THE
                      SYSTEM.
                    </li>
                    <li>
                      No Legal Services. CUSTOMER RECOGNIZES AND AGREES THAT:
                      (i) ONLINEVISAS IS NOT A LAW FIRM; (ii) PROVISION OF THE
                      SYSTEM AND PROFESSIONAL SERVICES DO NOT CONSTITUTE THE
                      PRACTICE OF LAW AND ARE NOT LEGAL COUNSEL OR ADVICE; (iii)
                      NEITHER ONLINEVISAS NOR ANY OF ITS EMPLOYEES, OFFICERS,
                      DIRECTORS, PRINCIPALS, CONTRACTORS, OR OTHER STAFF
                      (COLLECTIVELY, “REPRESENTATIVES”) IS RENDERING A LEGAL
                      SERVICE TO CUSTOMER, ITS USERS, OR ANY OTHE PERSON OR
                      ENTITY THROUGH PROVISION OF THE SYSTEM OR PROFESSIONAL
                      SERVICES, EVEN IF SUCH REPRESENTATIVE IS SEPARATELY AN
                      ATTORNEY; (iv) NEITHER ONLINEVISAS NOR ANY OF ITS
                      REPRESENTATIVES REPRESENTS THAT THE SYSTEM OR PROFESSIONAL
                      SERVICES WILL MEET CUSTOMER’S SPECIFIC GOALS OR PROTECT
                      ITS SPECIFIC INTERESTS; (v) THE SYSTEM IS GENERAL IN
                      NATURE AND MAY NOT BE SUFFICIENT FOR A SPECIFIC LEGAL
                      PROBLEM OR DISPUTE; AND (vi) THE SYSTEM AND PROFESSIONAL
                      SERVICES SHOULD NOT BE RELIED UPON AS A SUBSTITUTE FOR
                      CONSULTATION WITH AN ATTORNEY.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <span className="font-bold">INDEMNIFICATION.</span> Customer shall
              defend, indemnify, and hold harmless OnlineVisas and the
              OnlineVisas Associates (as defined below) against any “Indemnified
              Claim,” meaning any third party claim, suit, or proceeding arising
              out of or related to Customers alleged or actual use of, misuse
              of, or failure to use the System, Professional Services, or
              Deliverables, including without limitation: (a) claims by
              Customer’s employees or other Users; (b) claims related to
              unauthorized disclosure or exposure of personally identifiable
              information or other private information, including Customer Data;
              (c) claims related to infringement or violation of a copyright,
              trademark, trade secret, or privacy or confidentiality right by
              written material, images, logos or other content uploaded to the
              System through Customer’s account, including without limitation by
              Customer Data; and (d) claims that use of the System through
              Customer’s account, including by Users, harasses, defames, or
              defrauds a third party or violates the CAN-Spam Act of 2003 or any
              other law or restriction on electronic advertising. Indemnified
              Claims include, without limitation, claims arising out of or
              related to OnlineVisas’ negligence. Customer’s obligations set
              forth in this Article 10 include retention and payment of
              attorneys and payment of court costs, as well as settlement at
              Customer’s expense and payment of judgments. OnlineVisas will have
              the right, not to be exercised unreasonably, to reject any
              settlement or compromise that requires that it admit wrongdoing or
              liability or subjects it to any ongoing affirmative obligations.
              (The “OnlineVisas Associates” are OnlineVisas’ officers,
              directors, shareholders, parents, subsidiaries, agents,
              successors, and assigns.)
            </li>

            <li>
              <span className="font-bold">LIMITATION OF LIABILITY.</span>
              <ol>
                <li>
                  Dollar Cap. ONLINEVISAS’ LIABILITY ARISING OUT OF OR RELATED
                  TO THIS AGREEMENT WILL NOT EXCEED THE FEES PAID TO ONLINEVISAS
                  DURING THE 1-YEAR PERIOD PRECEDING THE INJURY IN QUESTION.
                </li>
                <li>
                  Exclusion of Consequential Damages. IN NO EVENT WILL
                  ONLINEVISAS BE LIABLE FOR ANY CONSEQUENTIAL, INDIRECT,
                  SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT OF OR
                  RELATED TO THIS AGREEMENT.
                </li>
                <li>
                  Clarifications &amp; Disclaimers. THE LIABILITIES LIMITED BY
                  THIS ARTICLE 11 APPLY: (a) TO LIABILITY FOR NEGLIGENCE; (b)
                  REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT,
                  STRICT PRODUCT LIABILITY, OR OTHERWISE; (c) EVEN IF
                  ONLINEVISAS IS ADVISED IN ADVANCE OF THE POSSIBILITY OF THE
                  DAMAGES IN QUESTION AND EVEN IF SUCH DAMAGES WERE FORESEEABLE;
                  AND (d) EVEN IF CUSTOMER’S REMEDIES FAIL OF THEIR ESSENTIAL
                  PURPOSE. If applicable law limits the application of the
                  provisions of this Article 11, OnlineVisas’ liability will be
                  limited to the maximum extent permissible. For the avoidance
                  of doubt, OnlineVisas’ liability limits and other rights set
                  forth in this Article 11 apply likewise to OnlineVisas’
                  affiliates, licensors, suppliers, advertisers, agents,
                  sponsors, directors, officers, employees, consultants, and
                  other representatives.
                </li>
              </ol>
            </li>

            <li>
              <span className="font-bold">Term &amp; Termination.</span>
              <ol>
                <li>
                  Term. The term of this Agreement (the “Term”) will commence on
                  the Effective Date and continue for the period set forth in
                  the Order or, if none, for 1 year. Thereafter, the Term will
                  renew for successive 1-year periods, unless either party
                  refuses such renewal by written notice 30 or more days before
                  the renewal date.
                </li>
                <li>
                  Termination for Cause. Either party may terminate this
                  Agreement for the other’s material breach by written notice
                  specifying in detail the nature of the breach, effective in 30
                  days unless the other party first cures such breach, or
                  effective immediately if the breach is not subject to cure.
                  Without limiting OnlineVisas’ other rights and remedies,
                  OnlineVisas may suspend or terminate a User’s access to the
                  System at any time, without advanced notice, if OnlineVisas
                  reasonably concludes such User has conducted itself in a way
                  that is not consistent with the requirements of the AUP or the
                  other requirements of this Agreement or in a way that subjects
                  OnlineVisas to potential liability.
                </li>
                <li>
                  Effects of Termination. Upon termination of this Agreement,
                  Customer shall cease all use of the System and delete,
                  destroy, or return all copies of the Deliverables in its
                  possession or control. The following provisions will survive
                  termination or expiration of this Agreement: (a) any
                  obligation of Customer to pay fees incurred before
                  termination; (b) Articles and Sections 3.2(b) (Restrictions on
                  Deliverables), 7 (IP &amp; Feedback), 8 (Confidential
                  Information), 9.2 (Warranty Disclaimers), 10
                  (Indemnification), and 11 (Limitation of Liability); and (c)
                  any other provision of this Agreement that must survive to
                  fulfill its essential purpose.
                </li>
              </ol>
            </li>

            <li>
              <span className="font-bold">MISCELLANEOUS.</span>
              <ol>
                <li>
                  Independent Contractors. The parties are independent
                  contractors and shall so represent themselves in all regards.
                  Neither party is the agent of the other, and neither may make
                  commitments on the other’s behalf. The parties agree that no
                  OnlineVisas employee or contractor will be an employee of
                  Customer.
                </li>
                <li>
                  Notices. OnlineVisas may send notices pursuant to this
                  Agreement to Customer’s email contact points provided by
                  Customer, and such notices will be deemed received 24 hours
                  after they are sent. Customer may send notices pursuant to
                  this Agreement to Online Visas, Inc. 401 W. Main St., Ste.
                  300, Norman, OK 73069, and such notices will be deemed
                  received 72 hours after they are sent.
                </li>
                <li>
                  Force Majeure. No delay, failure, or default, other than a
                  failure to pay fees when due, will constitute a breach of this
                  Agreement to the extent caused by acts of war, terrorism,
                  hurricanes, earthquakes, other acts of God or of nature,
                  strikes or other labor disputes, riots or other acts of civil
                  disorder, embargoes, or other causes beyond the performing
                  party’s reasonable control.
                </li>
                <li>
                  Assignment &amp; Successors. Customer may not assign this
                  Agreement or any of its rights or obligations hereunder
                  without OnlineVisas’ express written consent. Except to the
                  extent forbidden in this Section 13.4, this Agreement will be
                  binding upon and inure to the benefit of the parties’
                  respective successors and assigns.
                </li>
                <li>
                  Severability. To the extent permitted by applicable law, the
                  parties hereby waive any provision of law that would render
                  any clause of this Agreement invalid or otherwise
                  unenforceable in any respect. In the event that a provision of
                  this Agreement is held to be invalid or otherwise
                  unenforceable, such provision will be interpreted to fulfill
                  its intended purpose to the maximum extent permitted by
                  applicable law, and the remaining provisions of this Agreement
                  will continue in full force and effect.
                </li>
                <li>
                  No Waiver. Neither party will be deemed to have waived any of
                  its rights under this Agreement by lapse of time or by any
                  statement or representation other than by an authorized
                  representative in an explicit written waiver. No waiver of a
                  breach of this Agreement will constitute a waiver of any other
                  breach of this Agreement.
                </li>
                <li>
                  Choice of Law &amp; Jurisdiction: This Agreement will be
                  governed solely by the internal laws of the State of Oklahoma,
                  including applicable U.S. federal law, without reference to:
                  (a) any conflicts of law principle that would apply the
                  substantive laws of another jurisdiction to the parties’
                  rights or duties; (b) the 1980 United Nations Convention on
                  Contracts for the International Sale of Goods; or (c) other
                  international laws. The parties consent to the personal and
                  exclusive jurisdiction of the federal and state courts of
                  Cleveland County, Oklahoma. This Section 13.7 governs all
                  claims arising out of or related to this Agreement, including
                  without limitation tort claims.
                </li>
                <li>
                  Conflicts. In the event of any conflict among the attachments
                  to this Agreement and this main body, the following order of
                  precedence will govern, with lower numbers governing over
                  higher ones: (1) this main body of this Agreement; (2) any
                  SoW; and (3) any OnlineVisas policy posted online, including
                  without limitation the AUP or Privacy Policy. No SoW or other
                  attachment incorporated into this Agreement will be construed
                  to amend this main body.
                </li>
                <li>
                  Technology Export. Customer shall not: (a) permit any third
                  party to access or use the System in violation of any U.S. law
                  or regulation; or (b) export any software provided by
                  OnlineVisas or otherwise remove it from the United States
                  except in compliance with all applicable U.S. laws and
                  regulations. Without limiting the generality of the foregoing,
                  Customer shall not permit any third party to access or use the
                  System in, or export such software to, a country subject to a
                  United States embargo (as of the Effective Date, Cuba, Iran,
                  North Korea, Sudan, and Syria).
                </li>
                <li>
                  Entire Agreement. This Agreement sets forth the entire
                  agreement of the parties and supersedes all prior or
                  contemporaneous writings, negotiations, and discussions with
                  respect to its subject matter. Neither party has relied upon
                  any such prior or contemporaneous communications.
                </li>
                <li>
                  Amendment. OnlineVisas may amend this Agreement from time to
                  time by posting an amended version at its Website and sending
                  Customer written notice thereof. Such amendment will be deemed
                  accepted and become effective 30 days after such notice (the
                  “Proposed Amendment Date”) unless Customer first gives
                  OnlineVisas written notice of rejection of the amendment. In
                  the event of such rejection, this Agreement will continue
                  under its original provisions, and the amendment will become
                  effective at the start of Customer’s next Term following the
                  Proposed Amendment Date (unless Customer first terminates this
                  Agreement pursuant to Article 12, Term &amp; Termination).
                  Customer’s continued use of the System following the effective
                  date of an amendment will confirm Customer’s consent thereto.
                  This Agreement may not be amended in any other way except
                  through a written agreement by authorized representatives of
                  each party. Notwithstanding the foregoing provisions of this
                  Section 13.11, OnlineVisas may revise the Privacy Policy and
                  Acceptable Use Policy at any time by posting a new version of
                  either at the Website, and such new version will become
                  effective on the date it is posted.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default TermsOfService;
