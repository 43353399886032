import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from 'assets/images/liberty-logoheader.png';
// import Space from 'assets/images/space.png';
import Footer from 'components/Footer';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
// import '../PrivacyPolicy/style.css'
// import { useHistory } from "react-router-dom";

export const useStyles = makeStyles((theme) => ({
  space: {
    backgroundColor: '#09171f',
    backgroundImage: `url(${'https://d17p81usn86n3p.cloudfront.net/space.png'})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    bottom: '0',
    left: '0',
    minHeight: '100%',
    position: 'fixed',
    right: '0',
    top: '0',
    width: '100%',
  },
}));

const DMCAPolicy: React.FC = React.memo(() => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  window.scrollTo({
    behavior: 'smooth',
    top: 0,
  });
  return (
    <div>
      <Typography className=" black-white-text p-f pt-2">
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <Typography className={classes.space} />
      <div className="innerBox">
        <div
          className=" d-flex-a d-flex-sb mb-1 sticky-tab"
          style={{ top: '0px' }}>
          <div className="black-white-text f-20 font-bold p-wtext">
            DMCA Policy
          </div>
          <div>
            <Button
              className="cancel-btn mr-4 "
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '1em !important',
                width: '120px !important',
              }}
              onClick={() =>
                navigate(`/${String(userDetail?.userRoleName)}/dashboard`)
              }>
              <Typography
                className="o-box d-flex-a"
                sx={{ width: 'unset !important' }}
              />
              Cancel
            </Button>
          </div>
        </div>
        <div
          className="card"
          style={{
            paddingBottom: '15px',
            paddingLeft: '35px',
            paddingRight: '35px',
            paddingTop: '15px',
          }}>
          <p>
            <b>Date Posted: March 02, 2018</b>
          </p>

          <p>
            This policy statement lists our requirements for notice of copyright
            infringement and for responses to such a notice if you or your
            materials are accused.
          </p>

          <p>
            We use the copyright infringement procedures of the Digital
            Millennium Copyright Act.
          </p>

          <h4>A. Notice of Copyright Infringement</h4>
          <p>
            To notify us of copyright infringement, please send a written
            communication to our Copyright Notices Department, at the contact
            points listed below in Part C. That written communication should
            include the following:
          </p>
          <span className="information">
            <ol>
              <li>
                A physical or electronic signature of a person authorized to act
                on behalf of the owner of an exclusive right that is allegedly
                infringed.
              </li>
              <li>
                Identification of the copyrighted work claimed to have been
                infringed,&nbsp;or,&nbsp;if multiple copyrighted works at a
                single online site are covered by a single notification, a
                representative list of such works at that site.
              </li>
              <li>
                Identification of the material that is claimed to be infringing
                or to be the subject of infringing activity and that is to be
                removed or access to which is to be disabled, and information
                reasonably sufficient to permit us to locate the material.
              </li>

              <li>
                Information reasonably sufficient to permit us to contact the
                complaining party, such as an address, telephone number, and, if
                available, an electronic mail address at which the complaining
                party may be contacted.
              </li>
              <li>
                A statement that the complaining party has a good faith belief
                that use of the material in the manner complained of is not
                authorized by the copyright owner, its agent, or the law.
              </li>
              <li>
                A statement that the information in the notification is
                accurate, and under penalty of perjury, that the complaining
                party is authorized to act on behalf of the owner of an
                exclusive right that is allegedly infringed.
              </li>
            </ol>
          </span>
          <h4>B. Counter-Notice by Accused Subscriber</h4>
          <p>
            If you are a subscriber and we have taken down your materials due to
            suspicion of copyright infringement, you may dispute the alleged
            infringement by sending a written communication to our Copyright
            Notice Department, at the contact points listed in Part C below.
            That written communication should include the following:
          </p>
          <span className="information">
            <ol>
              <li>A physical or electronic signature of the subscriber.</li>
              <li>
                Identification of the material that has been removed or to which
                access has been disabled and the location at which the material
                appeared before it was removed or access to it was disabled.
              </li>
              <li>
                A statement under penalty of perjury that the subscriber has a
                good faith belief that the material was removed or disabled as a
                result of mistake or misidentification of the material to be
                removed or disabled.
              </li>
              <li>
                The subscriber’s name, address, and telephone number, and a
                statement that the subscriber consents to the jurisdiction of
                Federal District Court for the judicial district in which such
                address is located, or if the subscriber’s address is outside of
                the United States, the Federal District Court for the Western
                District of Oklahoma, and that the subscriber will accept
                service of process from the person who provided notification of
                copyright infringement or an agent of such person.
              </li>
            </ol>
          </span>
          <h4>C. Agent for Notices</h4>
          <p>
            Please send all notices required by this policy to our Copyright
            Notice Department at info@onlinvisas.com [address, phone number, and
            e-mail address].
          </p>

          <h4>D. Termination of Repeat Infringers</h4>
          <p>
            In appropriate circumstances, we will terminate the accounts of
            subscribers who are repeat copyright infringers.
          </p>

          <h4>E. Revision of Policy</h4>
          <p>
            We may revise this policy at any time, including by posting a new
            version at this website.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default DMCAPolicy;
