import { CircularProgress, DialogContent, Grid } from '@mui/material';
import CustomModalView from 'components/CustomModalView/customModalView';
import { SectionList } from 'pages/private/Common/SectionList';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import { updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';

export const AddSection: React.FC<any> = ({
  isDependent,
  setSectionParams,
  isSection,
  setIsSection,
  treeList,
  getQuestionWizardFormData,
  hasMoreResults: HasMoreResults,
  getMoreLoader,
  getMoreListAdd,
}) => {
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);

  const [sectionSearch, setSectionSearch] = React.useState('');

  const addSection = (section: any) => {
    if (section && section.id) {
      const checkDuplicate =
        treeList &&
        Array.isArray(treeList) &&
        treeList &&
        treeList.findIndex((val) => val.section_id === section.id);
      if (checkDuplicate === -1) {
        const payload = isDependent
          ? {
              addFor: isSection.type,
              dependentId: isDependent,
              sectionId: section.id,
            }
          : { addFor: isSection.type, sectionId: section.id };

        updateData(
          petitions.petitionData.id,
          payload,
          COMMON_ENDPOINT.BeneficiaryAddSection,
        ).then(() => {
          setIsSection((pre) => ({
            ...pre,
            show: false,
          }));
          getQuestionWizardFormData();
        });
      } else {
        toast.error('This section is already mapped.');
      }
    }
  };
  return (
    <Grid item md={2} sm={12}>
      {!isSection?.allSection ? (
        <CustomModalView
          open={isSection.show}
          close={() =>
            setIsSection((pre) => ({
              ...pre,
              data: [],
              show: false,
            }))
          }
          title={'Add Form'}
          scroll={'paper'}
          fullWidth={true}
          maxWidth={'lg'}
          isFilters={true}
          filterOnChange={(value) =>
            setSectionParams((prev) => ({
              ...prev,
              name: value,
              skip: 0,
              take: 50,
            }))
          }>
          <DialogContent id="scrollParent">
            <InfiniteScroll
              dataLength={
                isSection && isSection.data && isSection.data.total
                  ? isSection.data.total
                  : 0
              }
              style={{
                overflow: 'unset',
              }}
              next={getMoreListAdd && getMoreListAdd}
              hasMore={HasMoreResults}
              scrollableTarget={'scrollParent'}
              loader={
                getMoreLoader && (
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      textAlign: 'center',
                      width: '100%',
                    }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#002c69', marginBottom: 10, marginTop: 10 }}
                    />
                  </div>
                )
              }
              endMessage>
              <table className="lead-table table-hover">
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>DESCRIPTION</th>
                    <th>TYPE</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {isSection.data && isSection.data.length > 0 ? (
                    isSection.data.map((data: any, index) => (
                      <tr
                        key={index}
                        onClick={() => addSection(data)}
                        style={{ cursor: 'pointer' }}>
                        <td>
                          <div
                            className="hideshow"
                            style={{ width: '255px', wordBreak: 'break-all' }}>
                            <a style={{ color: '#1093DF' }}>
                              {data.section_name}
                            </a>
                          </div>
                        </td>
                        <td>
                          <div
                            className="hideshow"
                            style={{ width: '255px', wordBreak: 'break-all' }}>
                            {data.section_description}
                          </div>
                        </td>
                        <td className="text-capitalize">
                          {data.section_type === 'lca'
                            ? 'Labour condition application'
                            : data.section_type}
                        </td>
                        <td>{data.section_status ? 'Active' : 'InActive'}</td>
                      </tr>
                    ))
                  ) : (
                    <tr className={'text-center'}>
                      <td colSpan={5} align={'center'}>
                        No Record(s) Found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </InfiniteScroll>
          </DialogContent>
        </CustomModalView>
      ) : (
        <CustomModalView
          open={isSection?.show}
          fullWidth={true}
          maxWidth={'xl'}
          scroll={'paper'}
          autoCloseDisable={true}
          isFilters={true}
          title="Add Form"
          filterOnChange={(e) => setSectionSearch(e)}
          close={() =>
            setIsSection((pre) => ({
              ...pre,
              allSection: false,
              show: false,
            }))
          }>
          <SectionList
            sectionType={isSection?.type}
            searchValue={sectionSearch}
            onSelect={(e) => addSection(e)}
            petitionData=""
            isAffilateCase={''}
            affilateRoleId={null}
            attorneyAssigned={null}
            AttorneyQueryParam={true}
            showAllQueryParam={true}
          />
        </CustomModalView>
      )}
    </Grid>
  );
};
export default AddSection;
