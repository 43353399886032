import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from 'assets/images/liberty-logoheader.png';
// import Space from 'assets/images/space.png';
import Footer from 'components/Footer';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
// import '../PrivacyPolicy/style.css'
// import { useHistory } from "react-router-dom";

export const useStyles = makeStyles((theme) => ({
  space: {
    backgroundColor: '#09171f',
    backgroundImage: `url(${'https://d17p81usn86n3p.cloudfront.net/space.png'})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    bottom: '0',
    left: '0',
    minHeight: '100%',
    position: 'fixed',
    right: '0',
    top: '0',
    width: '100%',
  },
}));

const PrivacyPolicy: React.FC = React.memo(() => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const classes = useStyles();
  const navigate = useNavigate();
  window.scrollTo({
    behavior: 'smooth',
    top: 0,
  });
  return (
    <div>
      <Typography className=" black-white-text p-f pt-2">
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <Typography className={classes.space} />
      <div className="innerBox">
        <div
          className=" d-flex-a d-flex-sb mb-1 sticky-tab"
          style={{ top: '0px' }}>
          <div className="black-white-text f-20 font-bold p-wtext">
            Privacy Policy
          </div>
          <div>
            <Button
              className="cancel-btn mr-4 "
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '1em !important',
                width: '120px !important',
              }}
              onClick={() =>
                navigate(`/${String(userDetail?.userRoleName)}/dashboard`)
              }>
              <Typography
                className="o-box d-flex-a"
                sx={{ width: 'unset !important' }}
              />
              Cancel
            </Button>
          </div>
        </div>

        <div
          className="card"
          style={{
            paddingBottom: '15px',
            paddingLeft: '35px',
            paddingRight: '35px',
            paddingTop: '15px',
          }}>
          <p>
            <b>Effective Date: March 02, 2018</b>
          </p>

          <p>
            We collect certain information through our website, located at{' '}
            <a href="http://onlinevisas.com/">onlinevisas.com</a> (our
            “Website”), including through the products and services provided at
            the Website. This page (this “Privacy Policy”) lays out our policies
            and procedures surrounding the collection and handling of any such
            information that identifies an individual user or that could be used
            to contact or locate him or her (“Personally Identifiable
            Information” or “PII”).
          </p>

          <p>
            This Privacy Policy applies only to our Website and to the products
            and services provided through our Website. It does not apply to any
            third party site or service linked to our Website or recommended or
            referred by our Website, through our products or services, or by our
            staff. And it does not apply to any other website, product, or
            service operated by our company, or to any of our offline
            activities.
          </p>

          <h4>
            A. <span title="Personally Identifiable Information">PII</span>
            <span> We Collect </span>
          </h4>
          <p>
            We may collect various Personally Identifiable Information from
            users who use our products or services, which may include, but is
            not limited to, the following:
          </p>
          <div className="information">
            <div className="row  d-flex">
              <div className="col-md-6 d-flex-1">
                <ul>
                  <li>Name</li>
                  <li>Social Security Number (or national equivalent)</li>
                  <li>Address(es)</li>
                  <li>Telephone number(s)</li>
                  <li>Email address(es)</li>
                </ul>
              </div>
              <div className="col-md-6 d-flex-1">
                <ul>
                  <li>Passport number</li>
                  <li>Credit card details</li>
                  <li>Dates of key events, including date of birth</li>
                  <li>Employment history</li>
                  <li>Educational qualifications</li>
                  <li>Legal and/or criminal records</li>
                </ul>
              </div>
            </div>
          </div>
          <br className="clearer" />
          <p>
            We also use “cookies” to collect certain information from all users,
            including Web visitors who don’t buy anything through our Website. A
            cookie is a string of data our system sends to your computer and
            then uses to identify your computer when you return to our Website.
            Cookies give us usage data, like how often you visit, where you go
            at the site, and what you do.
          </p>

          <h4>B. Our Use of PII</h4>
          <p>
            We use your Personally Identifiable Information to create your
            account, to communicate with you about products and services you’ve
            purchased, to offer you additional products and services, and to
            bill you. We also use that information to the extent necessary to
            enforce our Website terms of service and to prevent imminent harm to
            persons or property.
          </p>
          <p>
            We use cookies so that our Website can remember you and provide you
            with the information you’re most likely to need. For instance, when
            you return to our Website, cookies identify you and prompt the site
            to provide your username (not your password), so you can sign in
            more quickly. Cookies also allow our Website to remind you of your
            past purchases and to suggest similar products and services.
            Finally, we use information gained through cookies to compile
            statistical information about use of our Website, such as the time
            users spend at the site and the pages they visit most often. Those
            statistics do not include PII.
          </p>

          <h4>C. Protection of PII</h4>
          <p>
            We employ a range of data security measures to protect Personally
            Identifiable Information, including password encryption, multi-level
            database security, firewalls, and cross-domain insertion prevention.
            Unfortunately, even with these measures, we cannot guarantee the
            security of PII. By using our Website, you acknowledge and agree
            that we make no such guarantee, and that you use our Website at your
            own risk.
          </p>

          <h4>D. Contractor and Other Third Party Access to PII</h4>
          <p>
            We give certain independent contractors access to Personally
            Identifiable Information. Those contractors may assist us with
            various programming, graphic design, and web development tasks. All
            those contractors are required to sign contracts in which they
            promise to protect PII using procedures reasonably similar to ours.
            (Users are not third party beneficiaries of those contracts.) We
            also may disclose PII to attorneys, collection agencies, or law
            enforcement authorities to address potential AUP violations, other
            contract violations, or illegal behavior. And we disclose any
            information demanded in a court order or otherwise required by law
            or to prevent imminent harm to persons or property. Finally, we may
            share PII in connection with a corporate transaction, like a merger
            or sale of our company, or a sale of all or substantially all of our
            assets or of the product or service line you received from us, or a
            bankruptcy.
          </p>
          <p>
            As noted above, we compile Website usage statistics from data
            collected through cookies. We may publish those statistics or share
            them with third parties, but they don’t include PII.
          </p>
          <p>
            Except as set forth in this Privacy Policy, we do not share PII with
            third parties. Except when the PII provided is part of a lead,
            petition or case that is being submitted which includes the PII.
          </p>

          <h4>E. Accessing and Correcting Your PII</h4>
          <p>
            You can access and change any Personally Identifiable Information we
            store through your user details page (by clicking on your name in
            the top navigation and selecting “Edit”.
          </p>

          <h4>F. Amendment of This Privacy Policy</h4>
          <p>
            We may change this Privacy Policy at any time by posting a new
            version on this page or on a successor page. The new version will
            become effective on the date it is posted, which will be listed at
            the top of the page as the new Effective Date.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default PrivacyPolicy;
