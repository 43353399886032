import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';

import WinningStrategyList from './WinningStrategyList';

export const WinningStrategy: React.FC<any> = () => {
  const { formId } = useParams();
  const [loader, setLoader] = useState(true);
  const [listData, setListData] = useState<any>({});
  const [params] = useState<any>({
    formId: formId,
    formType: 'winning',
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterParams, setFilterParams] = useState<any>({
    link_code: '',
    question: '',
  });
  const formatedData = (data) => {
    if (data && data.length) {
      data.map((val) => {
        if (val.question_id && val.question_id.length) {
          const qusOrder = [];
          val.question_id.map((qusId) => {
            const searchQus = val.questionDetails.find(
              (qusFullView) => qusFullView.id === qusId,
            );
            if (searchQus) {
              qusOrder.push(searchQus);
            }
          });
          val.questionDetails = qusOrder;
        }
      });
    }
    return data;
  };

  useEffect(() => {
    getAllListData(
      COMMON_ENDPOINT.WinningStrategyList(params.formId, params.formType),
    )
      .then((resp: any) => {
        if (resp && resp.length) {
          setListData(formatedData(resp));
        }
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  }, [params]);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={12} sm={12}>
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <Grid container columnSpacing={{ md: 2, sm: 1 }}>
            <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
              <WinningStrategyList
                lists={listData}
                setFilterParams={setFilterParams}
                filterParams={filterParams}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default WinningStrategy;
