import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Link, Typography } from '@mui/material';
import Logo from 'assets/images/liberty-logoheader1.png';
import { Footer } from 'components/Footer';
import { InputText } from 'components/Forms/InputText';
import { OnlineVisaVideo } from 'components/Onlinevisavideo';
import { UseFocus, useMountEffect } from 'hooks';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { login } from 'store/actions/UserAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { LoginSubmitForm, UserRoleType } from 'types';
import { SETUP_ENDPOINT } from 'utils/Constant';
import { loginSchema } from 'utils/ValidatorSchema';

const Login: React.FC = () => {
  const [inputFocus, setInputFocus] = UseFocus();
  const [inviteHash, setInviteHash] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userDetail: any = useAppSelector((store: any) => store.userLogin);

  const {
    control: loginControl,
    handleSubmit,
    formState: { errors: loginErrors },
  } = useForm<LoginSubmitForm>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(loginSchema),
  });

  const submitHandler = ({ email, password }: LoginSubmitForm) => {
    dispatch(login(email, password, 'login'));
  };

  // const [err, setErr] = useState('');
  // const Share = async () => {
  //   try {
  //     const shareData = {
  //       text: 'Learn web development on MDN!',
  //       title: 'MDN',
  //       url: 'https://developer.mozilla.org',
  //     };
  //     await navigator.share(shareData);
  //     setErr('MDN shared successfully');
  //   } catch (err) {
  //     setErr(`Error: ${String(err)}`);
  //   }
  // };

  useEffect(() => {
    if (!_.isEmpty(userDetail?.userInfo)) {
      userDetail?.userInfo.userRoleName === UserRoleType.Attorney
        ? navigate('/attorney/dashboard')
        : userDetail?.userInfo.userRoleName === UserRoleType.Beneficiary
        ? navigate('/beneficiary/dashboard')
        : userDetail?.userInfo.userRoleName === UserRoleType.Petitioner
        ? navigate('/petitioner/dashboard')
        : userDetail?.userInfo.userRoleName === UserRoleType.Affilate
        ? navigate('/affilate/dashboard')
        : navigate('/admin/dashboard');
    }
  }, [navigate, userDetail]);

  useEffect(() => {
    // Check invite is valid or not
    const invite = searchParams.get('invite');
    if (invite) {
      getAllListData(
        `${String(SETUP_ENDPOINT.ManageOrganization)}?hash=${String(invite)}`,
      )
        .then((resp: any) => {
          const { accepted } = resp;
          if (accepted === false) {
            setInviteHash(invite);
            localStorage.setItem('orgHash', invite);
          }
        })
        .catch((e: any) => {});
    }
  }, [searchParams]);

  useMountEffect(setInputFocus);

  return (
    <Grid container>
      <Grid
        item
        md={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '100vh',
        }}>
        {userDetail?.loading ? <div className="liberty-loader" /> : null}
        <OnlineVisaVideo />

        <div className="login login-temp temp">
          <Typography
            component={'div'}
            sx={{
              textAlign: 'center',
            }}>
            <img
              src={Logo}
              alt="logo"
              style={{ marginBottom: '1rem', width: '230px' }}
            />
          </Typography>
          <Typography component={'div'} className="top-text">
            Use your OnlineVisas username to sign into your account
          </Typography>
          <div>
            <form className="formheight" onSubmit={handleSubmit(submitHandler)}>
              <Typography style={{ marginBottom: '.4rem' }}>
                <label className="label-text">Email </label>
              </Typography>
              <Typography component={'div'} style={{ marginBottom: '1rem' }}>
                <Controller
                  control={loginControl}
                  defaultValue=""
                  name="email"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={inputFocus}
                        errors={loginErrors.email?.message || ''}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(e) => {
                          const vals = e.replace(/\s/g, '');
                          return onChange(vals);
                        }}
                        isEditable={false}
                        type={'text'}
                        variant="filled"
                        className="l-input"
                        placeholder="Your email address"
                      />
                    );
                  }}
                />
              </Typography>
              <Typography
                className="d-flex-a d-flex-sb"
                style={{ marginBottom: '.4rem' }}>
                <label className="label-text">Password</label>
                <span className="forgotlable">
                  <Link
                    underline="hover"
                    href="#"
                    onClick={() => navigate('/forgot-password')}>
                    Forgot Password?
                  </Link>
                </span>
              </Typography>
              <Typography component={'div'} style={{ marginBottom: '.5rem' }}>
                <Controller
                  control={loginControl}
                  defaultValue=""
                  name="password"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={loginErrors.password?.message || ''}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(e) => {
                          const vals = e.replace(/\s/g, '');
                          return onChange(vals);
                        }}
                        isSecureTextEntry={true}
                        isEditable={false}
                        type={'password'}
                        variant="filled"
                        className="l-input"
                        placeholder="Password"
                      />
                    );
                  }}
                />
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit">
                Sign In
              </Button>
            </form>
            <div className="middle">
              <span className="line">.</span>
              <Typography component={'div'} className="name">
                OR
              </Typography>
              <span className="line">.</span>
            </div>
            <div className="fotter">
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
                onClick={() => {
                  navigate(
                    inviteHash
                      ? `/petitioner/signup?invite=${inviteHash}`
                      : '/signup-type',
                  );
                }}>
                Sign Up
              </Button>
              <Typography className="s-text">
                For technical assistance, please email{' '}
                <a
                  href={`mailto:${String(process.env.REACT_APP_CONTACT_MAIL)}`}>
                  {String(process.env.REACT_APP_CONTACT_MAIL)}
                </a>
              </Typography>
            </div>
          </div>
        </div>
        <div className="loginstick-footer">
          <Footer />
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
