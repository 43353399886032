import { Typography } from '@mui/material';
import TranslateImg from 'assets/images/translate.png';
import React, { useEffect } from 'react';

export const Translate = () => {
  const googleTranslateElementInit = () => {
    new (window as any).google.translate.TranslateElement(
      {
        autoDisplay: false,
        layout: 'vertical',
        pageLanguage: 'en',
        powered: false,
      },
      'google_translate_element',
    );
  };
  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
    );
    document.body.appendChild(addScript);
    (window as any).googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <Typography component={'div'} className="add-inner mb-1 mt-2">
      <Typography component={'div'} className="add-inner-box" />
      <Typography className="pt-1 f-16" />
      <Typography className="d-flex-a bu-color pl-2 pb-1 ">
        <img
          src={TranslateImg}
          alt="img"
          className="mr-1 fon-500"
          style={{ height: '30px', width: '30px !important' }}
        />
        Translate
      </Typography>
      <Typography className="d-flex-a bu-color pl-2 pb-1 ">
        Would you like to see the questions in a different language? choose a
        language from the options below. Please note that the answers to the
        questions are still required to be in english.
      </Typography>
      <Typography
        component={'div'}
        className={'ml-3'}
        id="google_translate_element"
        translate="no"
      />
    </Typography>
  );
};
