import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { FileUploadDialog } from 'components/Fileupload/fileuploadDialog';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  getS3file,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { AdminDocumentTemplateForm } from 'types';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { AdminDocumentTemplateFormSchema } from 'utils/ValidatorSchema';

import DocumentFieldsViewer from './DocumentFieldsViewer';

export const DocumentAdd: React.FC<any> = () => {
  const navigate: any = useNavigate();
  const location: any = useLocation();
  const { editId } = useParams();
  const [loader, setLoader] = useState(true);
  const [visaTypes, setVisaTypes] = useState<any>([]);
  const [visaOptions, setVisaOptions] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visaTypeIds, setVisaTypeIds] = useState<any>([]);
  const [contentType, setContentType] = useState('application/pdf');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [docFormat, setDocFormat] = useState('');
  const [fileProps, setFileProps] = useState<any>({
    documentType: '',
    file: '',
    fileEdit: false,
    fileName: '',
    fileSelected: false,
    htmlContent: '',
    imageReferenceField: [],
    pdfSrc: '',
    selectedFile: null,
    wordDocument: null,
  });

  const [documentFields, setDocumentFields] = useState<any>([]);
  const [pdfAlreadyMappedValues, setPDFAlreadyMappedValues] = useState<any>([]);

  const [mappedQuestion, setMappedQuestion] = useState<any>([]);
  const [mappedQuestionWithLabelValues, setMappedQuestionWithLabelValues] =
    useState<any>([]);
  // const [questionIds, setQuestionIds] = useState<any>([]);
  const [fileUploadOpen, setFileUploadOpen] = useState<any>({
    open: false,
  });
  const [allowAccess, setAllowAccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AdminDocumentTemplateForm>({
    criteriaMode: 'all',
    defaultValues: {
      contractType: '',
      htmlContent: '',
      imageData: [],
      isTemplatFile: false,
      templatFile: '',
      templateAddedFor:
        location.state && location.state.templateType
          ? location.state.templateType
          : '',
      templateName: '',
      templateType: '',
      visaSelected: null,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AdminDocumentTemplateFormSchema),
  });

  const cancelForm = () => {
    resetForm();
    navigate(-1);
  };
  const selectType = (type, view?) => {
    setDocFormat(type);
    if (type === 'document') {
      setContentType(
        ' application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      );
    } else {
      setContentType('application/pdf');
    }
    handleClose();
    if (!view) {
      setTimeout(() => {
        // fileInput.current.click();
        setFileUploadOpen({ open: true });
      }, 100);
    }
  };

  // const chooseFile = (file) => {
  //   if (file?.length) {
  //     let ifAllow = false;
  //     const files = file[0].name.split('.').pop();
  //     if (['docx', 'pdf'].includes(files)) {
  //       if (file[0].size / 1024 / 1024 < 90) {
  //         if (
  //           contentType !== 'application/pdf' &&
  //           file[0].type !== 'application/pdf' &&
  //           [
  //             'application/msword',
  //             'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  //           ].includes(file[0].type)
  //         ) {
  //           // Choosed DOCS
  //           ifAllow = true;
  //         } else if (
  //           file[0].type === 'application/pdf' &&
  //           contentType === file[0].type
  //         ) {
  //           // Choosed PDF
  //           ifAllow = true;
  //         } else {
  //           ifAllow = false;
  //         }

  //         if (!ifAllow) {
  //           toast.error('Please choose appropriate file format.', alertOptions);
  //           return false;
  //         } else {
  //           setValue('templatFile', file[0].name);
  //           setValue('isTemplatFile', true);

  //           setFileProps((pre) => ({
  //             ...pre,
  //             fileEdit: false,
  //             fileName: file[0].name,
  //             fileSelected: true,
  //             htmlContent: '',
  //             imageReferenceField: [],
  //             selectedFile: file[0],
  //             wordDocument: null,
  //           }));
  //           handleClose();
  //         }
  //       } else {
  //         toast.error(
  //           'File is too big, Please select a file less than 90 MB',
  //           alertOptions,
  //         );
  //         return;
  //       }
  //     } else {
  //       toast.error(
  //         // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  //         'Please choose appropriate file format.',
  //         alertOptions,
  //       );
  //       fileInput.current.value = '';
  //       return;
  //     }
  //   }
  // };
  const closeUploadDialog = useCallback((action: any) => {
    if (action) {
      // console.log(action);
      setValue('templatFile', action.name);
      setValue('isTemplatFile', true);

      setFileProps((pre) => ({
        ...pre,
        fileEdit: false,
        fileName: action.name,
        fileSelected: true,
        htmlContent: '',
        imageReferenceField: [],
        selectedFile: action,
        wordDocument: null,
      }));
      handleClose();
    } else {
      setFileUploadOpen((pre) => ({
        ...pre,
        open: false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmitForm = (data: any) => {
    if (watch('visaSelected')?.length === 0) {
      setError('visaSelected', {
        message: 'Visa Type is required',
        type: 'custom',
      });
      return;
    } else {
      setError('visaSelected', {
        message: '',
        type: 'custom',
      });
      if (fileProps.fileSelected) {
        setLoader(true);
        const formData = new FormData();
        formData.append('templateType', data['templateType']);
        formData.append('templateName', data['templateName']);
        formData.append(
          'contractType',
          data['templateAddedFor'] === 'contract'
            ? data['contractType'] === 'individual'
              ? '4'
              : '2'
            : '',
        );
        formData.append('file', fileProps.selectedFile);
        formData.append('htmlContent', '');
        formData.append(
          'documentType',
          fileProps.fileName.split('.').pop() === 'pdf' ? 'pdf' : 'document',
        );
        formData.append('visaSelected', JSON.stringify(data['visaSelected']));

        formData.append(
          'imageData',
          JSON.stringify(fileProps.imageReferenceField),
        );
        formData.append('templateAddedFor', data['templateAddedFor']);

        if (!_.isEmpty(editId)) {
          updateData(editId, formData, SETUP_ENDPOINT.DocumentTemplate)
            .then((resp: any) => {
              // cancelForm();
              // setLoader(false);
              toast.success('Document type updated.', alertOptions);
              getInitialStatus(visaTypes);
            })
            .catch((e) => {
              setLoader(false);
            });
        } else {
          addNewData(formData, SETUP_ENDPOINT.DocumentTemplate)
            .then((resp: any) => {
              cancelForm();
              toast.success('Document type created.', alertOptions);
              setLoader(false);
            })
            .catch((e) => {
              setLoader(false);
            });
        }
      }
    }
  };

  const openFile = () => {
    const obj = {
      key: fileProps.file,
    };

    const fileName = fileProps.file.split('/').pop();
    setLoader(true);
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(
          `${String(fileName)} File downloaded successfully`,
          alertOptions,
        );
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err ? err : 'something Went Wrong', alertOptions);
      });
  };

  // Start Pdf Parsing
  const getPdfParsingValues = (id, templatePath) => {
    setFileProps((pre) => ({
      ...pre,
      pdfSrc: `${process.env.REACT_APP_APIURL}/document-template/view/${String(
        id,
      )}`,
      // pdfSrc: `${process.env.REACT_APP_APIURL}/${String(templatePath)}`,
    }));
  };

  const getInitialStatus = useCallback(async (visaTypesResp) => {
    setLoader(true);
    await getAllListData(
      `${String(SETUP_ENDPOINT.DocumentTemplate)}/${String(editId)}`,
    )
      .then((resp) => {
        const {
          contract_type,
          document_data,
          file_format,
          template_added_for,
          template_file_path,
          template_name,
          template_type,
          visa_selected,
          role_id,
        } = resp.templateData;
        selectType(file_format, true);
        if (userDetail.role_id !== role_id) {
          setAllowAccess(true);
        }

        if (template_file_path) {
          setValue('templatFile', template_file_path.split('/templates/')[1]);
          setValue('isTemplatFile', true);
          setFileProps((pre) => ({
            ...pre,
            file: template_file_path,
            fileEdit: true,
            fileName: template_file_path.split('/templates/')[1],
            fileSelected: true,
          }));
          if (file_format === 'document') {
            setFileProps((pre) => ({
              ...pre,
              fileName: template_file_path.split('/templates/')[1],
              htmlContent: resp.htmlFileContent,
              wordDocument: resp.htmlFileContent,
            }));
          }
          // File Expose Start
          if (file_format === 'pdf') {
            getPdfParsingValues(editId, template_file_path);
            setFileProps((pre) => ({
              ...pre,
              documentType: 'pdf',
            }));
            setPDFAlreadyMappedValues(
              !_.isEmpty(document_data) ? document_data : [],
            );
          } else {
            setDocumentFields(!_.isEmpty(document_data) ? document_data : []);
          }
          // File Expose End
        }

        setValue('templateType', template_type);
        setValue('templateName', template_name);
        setValue('visaSelected', visa_selected);
        setValue(
          'templateAddedFor',
          template_added_for === 'dependent-form'
            ? 'document'
            : template_added_for,
        );
        setValue(
          'contractType',
          contract_type
            ? contract_type === 4
              ? 'individual'
              : 'enterprise'
            : '',
        );

        setVisaTypeIds(
          visaTypesResp
            .filter((obj) => visa_selected.indexOf(obj.visa_id) !== -1)
            .map((selected) => selected.visa_id),
        );
        setDocFormat(file_format);

        // Mapped Questions Start
        getAllListData(
          `${String(SETUP_ENDPOINT.DocumentTemplateLinkCode)}?visaId=${String(
            visa_selected.join(','),
          )}&documentType=${file_format === 'pdf' ? 'pdf' : 'doc'}`,
        )
          .then((linkResp) => {
            const data =
              linkResp && !_.isEmpty(linkResp.data) ? linkResp.data : [];
            data.map((linkr) => {
              if (
                String(linkr.link_code).trim() === '' ||
                _.isNull(linkr.link_code)
              ) {
                linkr.link_code = 'no';
              }
            });
            setMappedQuestion(data);
            const options = [];
            data.map((link) =>
              options.push({ label: link.link_code, value: link.id }),
            );
            setMappedQuestionWithLabelValues(options);
            // setQuestionIds(
            //   linkResp.data.filter(
            //     (obj) => document_data.indexOf(obj.question_id) !== -1,
            //   ),
            // );
            setLoader(false);
          })
          .catch((e) => {
            setLoader(false);
          });
        // Mapped Questions End
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`)
      .then((resp: any) => {
        setVisaTypes(resp.data);
        const options = [];
        resp.data.map((visa) =>
          options.push({ label: visa.visa_code, value: visa.visa_id }),
        );
        setVisaOptions(options);
        if (!_.isUndefined(editId)) {
          getInitialStatus(resp.data);
        } else {
          setLoader(false);
        }
      })
      .catch((e: any) => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.state?.visaTypeID) {
      setValue('visaSelected', [location?.state?.visaTypeID]);
      setVisaTypeIds(
        visaTypes
          .filter((e) => {
            return e.visa_id === location?.state?.visaTypeID;
          })
          .map((selected) => selected.visa_id),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, visaTypes]);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <>
          <Grid item md={10} sm={12}>
            <Typography
              component={'div'}
              className="d-flex-a d-flex-sb sticky-tab mb-2">
              <Typography
                component={'div'}
                className="w-title black-white-text"
                sx={{ margin: 'unset !important' }}>
                {!_.isUndefined(editId) ? 'Edit' : 'Add'} Document Template
              </Typography>

              <Typography component={'div'} className="d-flex-a">
                <Button className="n-cancel-btn mr-2" onClick={cancelForm}>
                  <Typography className="o-box" />
                  Cancel
                </Button>
                <Button
                  className="n-add-btn"
                  type="submit"
                  form={'add-doc-template'}
                  disabled={allowAccess}>
                  {_.isUndefined(editId) ? 'Submit' : 'Update'}
                </Button>
              </Typography>
            </Typography>
            <form id="add-doc-template" onSubmit={handleSubmit(onSubmitForm)}>
              <Typography
                component={'div'}
                className="addbox"
                sx={{ marginTop: 'unset !important' }}>
                <Box
                  component="form"
                  className="u-add-in"
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Visa Type <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name="visaSelected"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                autoFocus={true}
                                closeMenuOnSelect={false}
                                labelKey={'label'}
                                valueKey={'value'}
                                options={visaOptions}
                                isMulti={true}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  setVisaTypeIds(value);
                                }}
                                placeHolder={'Select'}
                                className={'basic-multi-select'}
                                error={errors.visaSelected?.message}
                                disabled={allowAccess}
                                tabIndex={1}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Descriptions <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="templateType"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Descriptions'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.templateType?.message}
                                onChange={onChange}
                                isTextArea={true}
                                minRows={3}
                                disabled={allowAccess}
                                tabIndex={3}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Template <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Typography
                          component={'div'}
                          className="d-flex-a"
                          style={{ cursor: 'pointer' }}>
                          <Button
                            className="add-btn"
                            sx={{
                              borderRadius: '5px !important',
                              fontFamily: 'Arial-Regular !important',
                              fontSize: '0.9em !important',
                              height: '35px !important',
                              textTransform: 'capitalize !important',
                              width: '118px !important',
                            }}
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            disabled={allowAccess}>
                            Browse
                            {/* <input
                            type="file"
                            hidden
                            ref={fileInput}
                            onChange={(e) => {
                              chooseFile(e.target?.files);
                            }}
                            accept={contentType}
                            tabIndex={5}
                          /> */}
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}>
                            {watch('templateAddedFor') !== 'contract' && (
                              <MenuItem
                                sx={{ width: '118px !important' }}
                                onClick={() => selectType('pdf')}>
                                PDF
                              </MenuItem>
                            )}
                            <MenuItem
                              sx={{ width: '118px !important' }}
                              onClick={() => selectType('document')}>
                              Word (.docx)
                            </MenuItem>
                          </Menu>
                          <Typography
                            className="line-20"
                            sx={{
                              color: '#333333',
                              fontSize: '14px !important',
                              opacity: '0.5',
                              paddingLeft: '.5rem',
                            }}
                            {...(fileProps.fileEdit
                              ? {
                                  onClick: () => openFile(),
                                }
                              : {})}>
                            {fileProps.fileName !== ''
                              ? fileProps.fileName
                              : 'No file is chosen'}
                          </Typography>
                        </Typography>

                        {fileUploadOpen.open && (
                          <FileUploadDialog
                            open={fileUploadOpen.open}
                            allowedFileType={contentType}
                            errorMessage={
                              'Please choose appropriate file format.'
                            }
                            info={`(You can upload documents of types ${
                              docFormat === 'document'
                                ? 'DOCX'
                                : docFormat.toUpperCase()
                            } only.You can upload upto a maximum of 90MB per upload)`}
                            onConfirm={closeUploadDialog}
                            setFileUploadOpen={setFileUploadOpen}
                          />
                        )}
                        {/* {!fileProps.fileSelected && (
                        <FormHelperText style={{ color: '#FF0000' }}>
                          Template is required
                        </FormHelperText>
                      )} */}
                        {errors.templatFile?.message &&
                          watch('templatFile') === '' && (
                            <FormHelperText style={{ color: '#FF0000' }}>
                              {errors.templatFile?.message}
                            </FormHelperText>
                          )}
                      </Typography>
                      <span
                        style={{
                          fontSize: '.75em !important',
                          fontStyle: 'italic',
                        }}>
                        (You can upload upto a maximum of 90MB per upload)
                      </span>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Template Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="templateName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Template Name'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.templateName?.message}
                                onChange={onChange}
                                disabled={allowAccess}
                                tabIndex={2}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Template Type <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="templateAddedFor"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={
                                  location.state && location.state.templateType
                                    ? [{ label: 'Others', value: 'document' }]
                                    : [
                                        {
                                          label: 'Contract',
                                          value: 'contract',
                                        },
                                        {
                                          label: 'Dependent',
                                          value: 'dependent',
                                        },
                                        { label: 'Others', value: 'document' },
                                      ]
                                }
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.templateAddedFor?.message}
                                disabled={allowAccess}
                                tabIndex={4}
                              />
                            );
                          }}
                        />
                      </Typography>
                      {watch('templateAddedFor') === 'contract' && (
                        <Typography
                          component={'div'}
                          className="custom-field"
                          style={{ paddingTop: ' 76px ' }}>
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Contract Type{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue="individual"
                            name="contractType"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}>
                                    <FormControlLabel
                                      value="individual"
                                      control={<Radio />}
                                      label="Individual"
                                    />
                                    <FormControlLabel
                                      value="enterprise"
                                      control={<Radio />}
                                      label="Enterprise"
                                    />
                                  </RadioGroup>
                                  {errors.contractType?.message && (
                                    <FormHelperText
                                      style={{ color: '#FF0000' }}>
                                      {errors.contractType?.message}
                                    </FormHelperText>
                                  )}
                                </>
                              );
                            }}
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Typography>

              {/* {_.isUndefined(editId) && !allowAccess && (
              <Grid container>
                <Grid item md={12}>
                  <Typography component={'div'} className="d-flex-ja my-2">
                    <Button className="cancel-btn mr-2" onClick={cancelForm}>
                      <Typography className="o-box" />
                      Cancel
                    </Button>
                    <Button
                      className="add-btn"
                      type="submit"
                      form={'add-doc-template'}>
                      Submit
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            )} */}
            </form>
            {!_.isUndefined(editId) && (
              <DocumentFieldsViewer
                editId={String(editId)}
                fileProps={fileProps}
                documentFields={documentFields}
                setDocumentFields={setDocumentFields}
                mappedQuestion={mappedQuestion}
                mappedQuestionWithLabelValues={mappedQuestionWithLabelValues}
                // questionIds={questionIds}
                pdfAlreadyMappedValues={pdfAlreadyMappedValues}
              />
            )}
          </Grid>
          <Grid item md={2} sm={12}>
            <Typography component={'div'} className="add-box">
              <Typography component={'div'} className="add-inner-box" />
              <Typography className=" user-dis pt-5 ">
                visualize your own model and create fields with link codes to
                pre-populate from the applicable case or you can add the data to
                customize document. Name the document and select the visa
                type&apos;s it will apply and upload into the system
                <Typography />
              </Typography>
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DocumentAdd;
