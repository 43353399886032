import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getAllListData } from 'services/CommonServices';
import { login } from 'store/actions/UserAction';
import { useAppDispatch } from 'store/hooks';
import { LoginAsForm } from 'types';
import { SETUP_ENDPOINT } from 'utils/Constant';
import { LoginAsSchema } from 'utils/ValidatorSchema';

const LoginAs: React.FC<any> = ({
  open,
  close,
  allLawFirm,
  allPetitioner,
  allBeneficiary,
}) => {
  const dispatch = useAppDispatch();
  const [allSelectedPetitioner, setAllSelectedPetitioner] = useState<any>([]);
  const [allSelectedLawFirm, setAllSelectedLawFirm] = useState<any>([]);

  const {
    control: control,
    handleSubmit: handleSubmit,
    formState: { errors },
    watch,
  } = useForm<LoginAsForm>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
      lawFirm: '',
      organization: '',
      role: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LoginAsSchema),
  });

  const getPetionerUser = (id) => {
    getAllListData(
      `${String(SETUP_ENDPOINT.SelectMenu)}/allpetitioner/${String(id)}`,
    ).then((resp: any) => {
      setAllSelectedPetitioner(resp);
    });
  };
  const getLawFirmUser = (id) => {
    getAllListData(
      `${String(SETUP_ENDPOINT.SelectMenu)}/alllawfirm/${String(id)}`,
    ).then((resp: any) => {
      setAllSelectedLawFirm(resp);
    });
  };

  const onSubmitForm = (data: any) => {
    dispatch(login(data['email'], '', 'loginAs'));
  };
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className="custom-modal">
        <Typography component={'div'} className="f-20 font-bold modal-title">
          Direct Login
        </Typography>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Typography component={'div'} className="d-flex-a m-border">
            <Typography className="f-15">
              Role <span className="required">*</span>
            </Typography>
            <Typography component={'div'} className="d-flex-a">
              <Typography component={'div'} className="pl-2">
                <Controller
                  control={control}
                  defaultValue=""
                  name="role"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group">
                          <FormControlLabel
                            value="1"
                            control={
                              <Radio checked={value == '1' ? true : false} />
                            }
                            label={
                              <Typography
                                component={'div'}
                                className="label-text">
                                <div>Beneficiary</div>
                              </Typography>
                            }
                            onChange={(e: any) => {
                              onChange(e.target.checked ? e.target.value : '');
                            }}
                          />
                          <FormControlLabel
                            value="2"
                            control={
                              <Radio checked={value == '2' ? true : false} />
                            }
                            label={
                              <Typography
                                component={'div'}
                                className="label-text">
                                <div>Petitioner</div>
                              </Typography>
                            }
                            onChange={(e: any) => {
                              onChange(e.target.checked ? e.target.value : '');
                            }}
                          />
                          <FormControlLabel
                            value="3"
                            control={
                              <Radio checked={value == '3' ? true : false} />
                            }
                            label={
                              <Typography
                                component={'div'}
                                className="label-text">
                                <div>Law Firm</div>
                              </Typography>
                            }
                            onChange={(e: any) => {
                              onChange(e.target.checked ? e.target.value : '');
                            }}
                          />
                        </RadioGroup>
                        {errors.role?.message && (
                          <FormHelperText style={{ color: '#FF0000' }}>
                            {errors.role?.message}
                          </FormHelperText>
                        )}
                      </>
                    );
                  }}
                />
              </Typography>
            </Typography>
          </Typography>
          <Typography component={'div'} sx={{ padding: '1.5rem 0 0 0' }}>
            {watch('role') === '1' && (
              <Typography
                component={'div'}
                className="custom-field mb-0"
                sx={{
                  // marginBottom: 'unset !important',
                  padding: '0  2rem',
                }}>
                <Typography component={'div'} className="custom-label">
                  User Name <span className="required">*</span>
                </Typography>
                <FormControl>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="email"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <CustomSelect
                          labelKey={'full_name'}
                          valueKey={'email_id'}
                          options={allLawFirm}
                          name={name}
                          value={value}
                          onChange={onChange}
                          placeHolder={'Select'}
                          className={'custom-input'}
                          error={errors.email?.message}
                          tabIndex={4}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Typography>
            )}
            {watch('role') === '2' && (
              <>
                <Typography
                  component={'div'}
                  className="custom-field mb-0"
                  sx={{
                    // marginBottom: 'unset !important',
                    padding: '0  2rem',
                  }}>
                  <Typography component={'div'} className="custom-label">
                    Organization <span className="required">*</span>
                  </Typography>
                  <FormControl>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="organization"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomSelect
                            labelKey={'org_name'}
                            valueKey={'id'}
                            options={allPetitioner}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              getPetionerUser(e.target.value);
                            }}
                            placeHolder={'Select'}
                            className={'custom-input'}
                            error={errors.organization?.message}
                            tabIndex={4}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Typography>
                <Typography
                  component={'div'}
                  className="custom-field mb-0"
                  sx={{
                    // marginBottom: 'unset !important',
                    padding: ' 0  2rem',
                  }}>
                  <Typography component={'div'} className="custom-label">
                    User Name <span className="required">*</span>
                  </Typography>
                  <FormControl>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="email"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomSelect
                            labelKey={'full_name'}
                            valueKey={'email_id'}
                            options={allSelectedPetitioner}
                            name={name}
                            value={value}
                            onChange={onChange}
                            placeHolder={'Select'}
                            className={'custom-input'}
                            error={errors.email?.message}
                            tabIndex={4}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Typography>
              </>
            )}
            {watch('role') === '3' && (
              <>
                <Typography
                  component={'div'}
                  className="custom-field mb-0"
                  sx={{
                    // marginBottom: 'unset !important',
                    padding: '0  2rem',
                  }}>
                  <Typography component={'div'} className="custom-label">
                    Law Firm <span className="required">*</span>
                  </Typography>
                  <FormControl>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="lawFirm"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomSelect
                            labelKey={'firm_name'}
                            valueKey={'id'}
                            options={allBeneficiary}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              getLawFirmUser(e.target.value);
                            }}
                            placeHolder={'Select'}
                            className={'custom-input'}
                            error={errors.lawFirm?.message}
                            tabIndex={4}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Typography>
                <Typography
                  component={'div'}
                  className="custom-field mb-0"
                  sx={{
                    // marginBottom: 'unset !important',
                    padding: '0  2rem',
                  }}>
                  <Typography component={'div'} className="custom-label">
                    User Name <span className="required">*</span>
                  </Typography>
                  <FormControl>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="email"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomSelect
                            labelKey={'full_name'}
                            valueKey={'email_id'}
                            options={allSelectedLawFirm}
                            name={name}
                            value={value}
                            onChange={onChange}
                            placeHolder={'Select'}
                            className={'custom-input'}
                            error={errors.email?.message}
                            tabIndex={4}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Typography>
              </>
            )}
          </Typography>
          <Typography
            component={'div'}
            className="d-flex-jae modal-footer"
            style={{ paddingBottom: '18px', paddingTop: '9px' }}>
            <Button
              className="mcancel-btn f-18 font-bold mr-1"
              style={{ height: '42px', minWidth: '124px' }}
              onClick={close}>
              Cancel
            </Button>
            <Button className="madd-btn f-18 font-bold" type="submit">
              Login
            </Button>
          </Typography>
        </form>
      </Box>
    </Modal>
  );
};

export default LoginAs;
