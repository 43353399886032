import { Box, Button, Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
// import Multiselect from 'multiselect-react-dropdown';
import React, { FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

import useStyles from '../../../PrivateStyle';
import QuestionAndIssues from './QuestionAndIssues';

type LeadThreeProps = {
  control;
  errors;
  setValue;
  watch;
  handleSubmit;
  cancelForm;
  onSubmitForm: (src: any) => void;
  firmData?: any;
  processingTypes?: any;
  filingTypes?: any;
  visaTypes?: any;
  attorneyContactPersonList?: any;
  setAttorneyContactPersonList?: any;
  attorneyInitDetails?: any;
  setAttorneyInitDetails?: any;
  attorneySupportIds?: any;
  setAttorneySupportIds?: any;
  additionalAttorneyContactIds?: any;
  setAdditionalAttorneyContactIds?: any;
  editId?: string;
  leadData?: any;
};

const AttorneyForm: FC<LeadThreeProps> = ({
  control,
  errors,
  setValue,
  watch,
  handleSubmit,
  cancelForm,
  onSubmitForm,
  firmData,
  processingTypes,
  filingTypes,
  visaTypes,
  attorneyContactPersonList,
  setAttorneyContactPersonList,
  attorneyInitDetails,
  setAttorneyInitDetails,
  attorneySupportIds,
  setAttorneySupportIds,
  additionalAttorneyContactIds,
  setAdditionalAttorneyContactIds,
  editId,
  leadData,
}) => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Typography component={'div'}>
      <form id="lead-step-three" onSubmit={handleSubmit(onSubmitForm)}>
        <Typography component={'div'} sx={{ marginTop: '1rem !important' }}>
          <Typography component={'div'} className={classes.leadwiteBox}>
            <Typography
              component={'div'}
              className="uadd-title"
              sx={{ paddingTop: 'unset !important' }}>
              Lead
            </Typography>

            <Grid container>
              <Grid item md={12}>
                <Box
                  component="form"
                  className="lead-box"
                  sx={{ paddingRight: '20px !important' }}
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 2, sm: 1 }}>
                    <Grid item md={6} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-field-row"
                        style={{ marginBottom: '0px !important' }}>
                        <Typography component={'div'} className="custom-label">
                          Visa Type
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'visaType'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'visa_code'}
                                valueKey={'visa_id'}
                                options={!_.isEmpty(visaTypes) ? visaTypes : []}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.visaType?.message}
                                searchable={true}
                                disabled={leadData?.isAffilateCase}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography
                        component={'div'}
                        className="custom-field-row"
                        style={{ marginBottom: '0px !important' }}>
                        <Typography component={'div'} className="custom-label">
                          Status
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue="prospect"
                          name={'leadStatus'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  {
                                    label: 'Prospect',
                                    value: 'prospect',
                                  },
                                  {
                                    label: 'Ready to Contract',
                                    value: 'readyForContract',
                                  },
                                  {
                                    label: 'Contract',
                                    value: 'contract',
                                  },
                                  {
                                    label: 'Petition',
                                    value: 'petition',
                                  },
                                  {
                                    label: 'Closed',
                                    value: 'closed',
                                  },
                                ]}
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.leadStatus?.message}
                                searchable={true}
                                isClearable={false}
                                disabled={
                                  _.isUndefined(editId) ||
                                  leadData?.isAffilateCase
                                }
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-field-row"
                        style={{ marginBottom: '0px !important' }}>
                        <Typography component={'div'} className="custom-label">
                          Notes
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'leadNotes'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Notes'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.leadNotes?.message || ''}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                isTextArea={true}
                                minRows={6}
                                disabled={[5].includes(
                                  leadData?.affilateRoleId,
                                )}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Typography>
          {visaTypes &&
            Array.isArray(visaTypes) &&
            visaTypes.length &&
            visaTypes.find((e) => e.visa_id == watch('visaType'))
              ?.is_premium_processing && (
              <QuestionAndIssues
                control={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
                processingTypes={processingTypes}
                filingTypes={filingTypes}
              />
            )}
          <Typography component={'div'} className={classes.leadwiteBox}>
            <Typography
              component={'div'}
              className="uadd-title"
              sx={{ paddingTop: '0px !important' }}>
              Lead Attorney
            </Typography>

            <Grid container>
              <Grid item md={12}>
                <Box
                  component="form"
                  className="lead-box"
                  sx={{ paddingRight: '20px !important' }}
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 2, sm: 1 }}>
                    <Grid item md={2} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-field-row"
                        style={{ marginBottom: '0px !important' }}>
                        <Typography component={'div'} className="custom-label">
                          Firm Name
                        </Typography>
                        <CustomInput
                          value={
                            !_.isEmpty(attorneyInitDetails)
                              ? attorneyInitDetails.attorneyName
                              : ''
                          }
                          className={'custom-input'}
                          disabled={true}
                          placeHolder="Firm Name"
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-field-row"
                        style={{ marginBottom: '0px !important' }}>
                        <Typography component={'div'} className="custom-label">
                          Contact Person <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'attorneyContactId'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                isClearable={false}
                                labelKey={'full_name'}
                                valueKey={'id'}
                                options={
                                  !_.isEmpty(attorneyContactPersonList)
                                    ? attorneyContactPersonList
                                    : []
                                }
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  const attorneyPerson =
                                    attorneyContactPersonList.find(
                                      (person) => person.id === e.target.value,
                                    );
                                  if (attorneyPerson) {
                                    setAttorneyInitDetails((pre) => ({
                                      ...pre,
                                      attorneyCountryCode:
                                        attorneyPerson.country_code_mobile
                                          ? attorneyPerson.country_code_mobile
                                          : '+1',
                                      attorneyEmail: attorneyPerson.email_id,
                                      attorneyPosition:
                                        attorneyPerson.designation
                                          ? attorneyPerson.designation
                                          : '',
                                      attorneyTelephone:
                                        attorneyPerson.contact_mobile
                                          ? attorneyPerson.contact_mobile
                                          : '',
                                    }));
                                  }
                                }}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.attorneyContactId?.message}
                                searchable={true}
                                disabled={leadData?.isAffilateCase}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-field-row"
                        style={{ marginBottom: '0px !important' }}>
                        <Typography component={'div'} className="custom-label">
                          Job Title
                        </Typography>
                        <CustomInput
                          // name={name}
                          value={
                            !_.isEmpty(attorneyInitDetails)
                              ? attorneyInitDetails.attorneyPosition
                              : ''
                          }
                          className={'custom-input'}
                          disabled={true}
                          placeHolder="Job Title"
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={3} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-field-row"
                        style={{ marginBottom: '0px !important' }}>
                        <Typography component={'div'} className="custom-label">
                          Email
                        </Typography>
                        <CustomInput
                          // name={name}
                          value={
                            !_.isEmpty(attorneyInitDetails)
                              ? attorneyInitDetails.attorneyEmail
                              : ''
                          }
                          className={'custom-input'}
                          disabled={true}
                          placeHolder="Email"
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={3} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-field-row"
                        style={{ marginBottom: '0px !important' }}>
                        <Typography component={'div'} className="custom-label">
                          Phone
                        </Typography>
                        <PhoneInput
                          placeholder="Phone"
                          inputStyle={{ width: '200px' }}
                          country="us"
                          // country={
                          //   attorneyInitDetails.attorneyCountryCode
                          //     ? attorneyInitDetails.attorneyCountryCode
                          //     : 'us'
                          // }
                          value={
                            !_.isEmpty(attorneyInitDetails) &&
                            attorneyInitDetails.attorneyTelephone
                              ? String(
                                  attorneyInitDetails.attorneyTelephone,
                                ).indexOf('+') !== -1
                                ? attorneyInitDetails.attorneyTelephone
                                : attorneyInitDetails.attorneyCountryCode
                                ? String(
                                    attorneyInitDetails.attorneyCountryCode,
                                  )
                                    .concat(' ')
                                    .concat(
                                      attorneyInitDetails.attorneyTelephone,
                                    )
                                : '+1 '.concat(
                                    attorneyInitDetails.attorneyTelephone,
                                  )
                              : '+1 '
                          }
                          disabled={true}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Typography>
          <Typography component={'div'} className={classes.leadwiteBox}>
            <Typography
              component={'div'}
              className="uadd-title"
              sx={{ paddingTop: '0px !important' }}>
              Attorney Additional Contacts
            </Typography>

            <Grid container>
              <Grid item md={12}>
                <Box
                  component="form"
                  className="lead-box"
                  sx={{ paddingRight: '20px !important' }}
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 2, sm: 1 }}>
                    <Grid item md={4} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-field-row"
                        style={{ marginBottom: '0px !important' }}>
                        <Typography component={'div'} className="custom-label">
                          Case Manager
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'caseManager'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                closeMenuOnSelect={false}
                                labelKey={'full_name'}
                                valueKey={'id'}
                                options={attorneyContactPersonList}
                                isMulti={true}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder={'Select'}
                                className={'basic-multi-select'}
                                disabled={leadData?.isAffilateCase}
                                // error={errors.teamMembers?.message}
                                // disabled={allowAccess}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-field-row"
                        style={{ marginBottom: '0px !important' }}>
                        <Typography component={'div'} className="custom-label">
                          Support Officer
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={[]}
                          name={'attorneySupport'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                closeMenuOnSelect={false}
                                labelKey={'full_name'}
                                valueKey={'id'}
                                options={attorneyContactPersonList}
                                isMulti={true}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder={'Select'}
                                className={'basic-multi-select'}
                                disabled={leadData?.isAffilateCase}
                                // error={errors.teamMembers?.message}
                                // disabled={allowAccess}
                              />
                              // <Multiselect
                              //   {...field}
                              //   placeholder={
                              //     watch('attorneySupport').length === 0
                              //       ? 'Support Officer'
                              //       : ''
                              //   }
                              //   displayValue="full_name"
                              //   onSelect={(selected, item) => {
                              //     setValue(
                              //       'attorneySupport',
                              //       selected.map((support) => support.id),
                              //     );
                              //   }}
                              //   onRemove={(selected, item) => {
                              //     setValue(
                              //       'attorneySupport',
                              //       selected.map((support) => support.id),
                              //     );
                              //   }}
                              //   selectedValues={attorneySupportIds}
                              //   options={
                              //     !_.isEmpty(attorneyContactPersonList)
                              //       ? attorneyContactPersonList
                              //       : []
                              //   }
                              // />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-field-row"
                        style={{ marginBottom: '0px !important' }}>
                        <Typography component={'div'} className="custom-label">
                          Managing Attorney
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'additionalAttorneyContacts'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                closeMenuOnSelect={false}
                                labelKey={'full_name'}
                                valueKey={'id'}
                                options={attorneyContactPersonList}
                                isMulti={true}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder={'Select'}
                                className={'basic-multi-select'}
                                disabled={leadData?.isAffilateCase}
                                // error={errors.teamMembers?.message}
                                // disabled={allowAccess}
                              />
                              // <Multiselect
                              //   {...field}
                              //   placeholder={
                              //     watch('additionalAttorneyContacts').length ===
                              //     0
                              //       ? 'Managing attorney'
                              //       : ''
                              //   }
                              //   displayValue="full_name"
                              //   onSelect={(selected, item) => {
                              //     setValue(
                              //       'additionalAttorneyContacts',
                              //       selected.map((support) => support.id),
                              //     );
                              //   }}
                              //   onRemove={(selected, item) => {
                              //     setValue(
                              //       'additionalAttorneyContacts',
                              //       selected.map((support) => support.id),
                              //     );
                              //   }}
                              //   selectedValues={additionalAttorneyContactIds}
                              //   options={
                              //     !_.isEmpty(attorneyContactPersonList)
                              //       ? attorneyContactPersonList
                              //       : []
                              //   }
                              // />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Typography>
        </Typography>
      </form>
      <Grid container className="bottom-fixed">
        <Grid item md={12}>
          <Typography component={'div'} className="d-flex-jae">
            <Button className="cancel-btn mr-2" onClick={cancelForm}>
              <Typography className="o-box" />
              Cancel
            </Button>
            {![5].includes(leadData?.affilateRoleId) ? (
              <Button
                className="add-btn"
                type="submit"
                form={'lead-step-three'}>
                Save
              </Button>
            ) : (
              ''
            )}
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};
export default AttorneyForm;
