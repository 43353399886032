import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import CustomTextArea from 'components/CustomTextArea/customTextArea';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resumeDefaultValues } from 'utils/helper';

import { DocumentViewer } from '../CustomDocumentViewer/CustomeDocumentViewer';

type Props = {
  fileUrl: string;
  resumeInfo: {
    474: string[];
    284: string;
  };
  onConfirm: (src, val) => void;
  setResumeInfo?: any;
  uploadDisable?: boolean;
  BackBtn?: boolean;
  inputDisable?: boolean;
  fileType?: string;
};

export const ResumeParsing: React.FC<Props> = (props) => {
  const {
    fileUrl,
    resumeInfo,
    onConfirm,
    setResumeInfo,
    uploadDisable,
    BackBtn,
    inputDisable,
    fileType = '',
  } = props;

  const navigate = useNavigate();
  const [resumeVal, setResumeVal] = useState<{
    474: string[];
    284: string;
  }>(resumeDefaultValues);

  const remove = (index: number) => {
    const arr = resumeVal[474];
    arr.splice(index, 1);
    setResumeVal((pre) => ({
      ...pre,
      [474]: arr,
    }));
    setResumeInfo &&
      setResumeInfo((pre) => ({
        ...pre,
        [474]: arr,
      }));
  };
  const add = () => {
    const arr = resumeVal[474];
    arr.push('');
    setResumeVal((pre) => ({
      ...pre,
      [474]: arr,
    }));

    setResumeInfo &&
      setResumeInfo((pre) => ({
        ...pre,
        [474]: arr,
      }));
  };
  useEffect(() => {
    setResumeVal((pre) => ({
      ...pre,
      ...resumeInfo,
    }));
  }, [resumeInfo]);
  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={7}>
            <Typography
              className="assem-box"
              id="parentdocId"
              sx={{
                maxHeight: '100vh',
                minHeight: '100vh',
                overflow: 'auto',
              }}>
              <DocumentViewer
                fileBlob={fileUrl}
                fileType={fileType}
                width={
                  document.getElementById('parentdocId') &&
                  document.getElementById('parentdocId').offsetWidth
                    ? `${
                        document.getElementById('parentdocId').offsetWidth - 100
                      }px`
                    : 'auto'
                }
              />
            </Typography>
          </Grid>

          <Grid item md={5} sm={12}>
            <Typography component={'div'}>
              <Box
                component="form"
                className="u-add-in"
                sx={{
                  backgroundColor: 'unset !important',
                  border: 'unset !important',
                  maxHeight: '100vh',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  padding: 'unset !important',
                }}
                noValidate
                autoComplete="off">
                <Grid container>
                  <Grid item md={12}>
                    <Typography
                      component={'div'}
                      className="custom-field u-pr-1">
                      <Typography component={'div'} className="custom-label">
                        Beneficiary Working History
                      </Typography>
                      <CustomTextArea
                        className="custom-textarea"
                        value={resumeVal[284]}
                        extraStyle={{ width: '100%' }}
                        onChange={(e) => {
                          setResumeVal((pre) => ({
                            ...pre,
                            [284]: e.target.value,
                          }));
                          setResumeInfo &&
                            setResumeInfo((pre) => ({
                              ...pre,
                              [284]: e.target.value,
                            }));
                        }}
                        disabled={inputDisable}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      component={'div'}
                      className="custom-field u-pr-1">
                      <Typography component={'div'} className="custom-label">
                        Beneficiary Education History
                      </Typography>
                      {resumeVal[474] && resumeVal[474].length
                        ? resumeVal[474].map((e, index) => (
                            <div key={index}>
                              {index !== 0 && !inputDisable && (
                                <CloseIcon
                                  sx={{
                                    cursor: 'pointer',
                                    fill: 'gray',
                                    height: '25px',
                                    width: '25px',
                                  }}
                                  onClick={() => remove(index)}
                                />
                              )}
                              <Typography
                                component={'div'}
                                className="uadd-title"
                                sx={{
                                  paddingBottom: 'unset !important',
                                  paddingTop: 'unset !important',
                                }}>
                                <CustomTextArea
                                  className="custom-textarea"
                                  value={e}
                                  extraStyle={{ width: '100%' }}
                                  onChange={(e) => {
                                    const arr = resumeVal[474];
                                    arr[index] = e.target.value;
                                    setResumeVal((pre) => ({
                                      ...pre,
                                      [474]: arr,
                                    }));
                                    setResumeInfo &&
                                      setResumeInfo((pre) => ({
                                        ...pre,
                                        [474]: arr,
                                      }));
                                  }}
                                  disabled={inputDisable}
                                />
                              </Typography>
                            </div>
                          ))
                        : null}
                      {!inputDisable && (
                        <AddIcon
                          sx={{
                            cursor: 'pointer',
                            fill: 'gray',
                            height: '25px',
                            width: '25px',
                          }}
                          onClick={() => add()}
                        />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Typography component={'div'} className="d-flex-jae mt-1 ">
          {!uploadDisable && (
            <Button
              className="back-btn mr-1"
              onClick={() => onConfirm('reUpload', '')}>
              Re Upload
            </Button>
          )}
          {BackBtn && (
            <Button className="back-btn mr-1" onClick={() => navigate(-1)}>
              Back
            </Button>
          )}
          {!inputDisable && (
            <Button
              className="n-add-btn"
              onClick={() => onConfirm('submit', resumeVal)}>
              Submit
            </Button>
          )}
        </Typography>
      </DialogActions>
    </>
  );
};
