import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Grid, Tab, Typography } from '@mui/material';
import Avator from 'assets/images/d-avator.svg';
// import Popover from '@mui/material/Popover';
// import Saddbtn from 'assets/images/saddbtn.svg';
// import Sedit from 'assets/images/small-edit.svg';
// import Seye from 'assets/images/small-eye.svg';
// import Sx from 'assets/images/smallx.svg';
// import Plus from 'assets/images/splus.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from 'lodash';
import React, { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { fileApiUrl } from 'utils/Config';

import useStyles from '../../../PrivateStyle';
// import { getPetitionApiData } from 'utils/Handlers';
import AddNote from '../../Common/AddNote';
import CustomInput from './../../../../../components/CustomInput/customInput';

export const PetitionCaseDetail: FC<any> = (props: any) => {
  const { petitionId } = useParams();
  const classes = useStyles();
  const notesListComponentRef: any = React.useRef();
  const [value, setValue] = useState('1');
  const [notesAdded, setNotesAdded] = useState(null);
  const { petitions } = useAppSelector((store: any) => store.petitionUser);
  const [viewAttorneyNotes, setViewAttorneyNotes] = useState<any>({
    leadOrPetitionId: '',
    show: false,
    type: '',
  });
  const [attorneyNotes, setAttorneyNotes] = useState<any>({
    leadOrPetitionId: '',
    show: false,
    type: '',
  });
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  const handleChange = (event, newValue) => {
    if (newValue === 3) {
      setViewAttorneyNotes({
        leadOrPetitionId: petitionId,
        show: true,
        type: 'Petition',
      });
    }
    setValue(newValue);
  };

  const getAndShowLeadStatus = useCallback((status: string) => {
    switch (status) {
      case 'prospect':
        return <Typography className="d-flex-a">Prospect</Typography>;
      case 'readyForContract':
        return <Typography className="d-flex-a">Ready to Contract</Typography>;
      case 'contract':
        return <Typography className="d-flex-a">Contract</Typography>;
      case 'petition':
        return <Typography className="d-flex-a">Petition</Typography>;
      case 'closed':
        return <Typography className="d-flex-a">Closed</Typography>;

      default:
        return <Typography className="d-flex-a">-</Typography>;
    }
  }, []);

  const notesRecall = () => {
    notesListComponentRef &&
      notesListComponentRef.current &&
      notesListComponentRef.current.reCallNotesApi &&
      notesListComponentRef.current.reCallNotesApi();
  };

  console.warn(petitions?.petitionData);
  return (
    <>
      {petitions?.petitionData ? (
        <Typography
          component={'div'}
          className="black-white-text"
          sx={{
            fontFamily: 'Arial-Bold !important',
            fontSize: '1.25em !important',
          }}>
          {petitions?.petitionData?.visa_type} {'for'}{' '}
          {petitions?.petitionData?.benificiery_name}
        </Typography>
      ) : (
        '-'
      )}

      <Grid container>
        <Grid item md={10} sm={12} sx={{ paddingRight: '2rem' }}>
          <Box sx={{ marginTop: '1rem', width: '100%' }} className="uploadTab">
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example">
                  <Tab label="Stakeholders" value="1" />
                  <Tab label="General Information" value="2" />
                  {!props?.notesShowHide ? (
                    <Tab label="Note" value="3" />
                  ) : null}
                </TabList>
              </Box>
              <Typography
                component={'div'}
                sx={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '0px 8px 8px 8px',
                  minHeight: '130px',
                }}>
                <TabPanel value="1">
                  {petitions && petitions.petitionData ? (
                    <Typography
                      component={'div'}
                      className="c-p-box"
                      sx={{ paddingLeft: '40px !important' }}>
                      <Grid container columnSpacing={2}>
                        <Grid md={15}>
                          <div className="d-flex-ja">
                            <Typography
                              className="w-title"
                              sx={{ paddingLeft: '16px' }}>
                              Legal Team -
                            </Typography>
                            <Typography
                              className="c-noraml f-14"
                              sx={{ marginTop: '7px' }}>
                              &nbsp;
                              {petitions?.petitionData?.lawfirm}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container columnSpacing={2}>
                        {/* Beneficiary */}
                        <Grid item md={3} sm={12}>
                          <Typography
                            component={'div'}
                            className="c-p-item d-flex-a flex-column"
                            sx={{
                              borderRadius: '8px !important',
                              minHeight: '230px !important',
                              padding: '1rem !important',
                            }}>
                            <img
                              src={
                                !petitions?.teams?.image
                                  ? Avator
                                  : `${fileApiUrl}${String(
                                      petitions?.teams?.image,
                                    )}`
                              }
                              alt="upic"
                              className={classes.userImg}
                            />
                            <Typography className="d-flex-a mt-2 text-center">
                              {petitions &&
                                petitions.petitionData &&
                                petitions?.petitionData?.benificiery_name}
                            </Typography>
                            <Typography
                              className="mt-1 f-12"
                              sx={{
                                color: 'grey !important',
                              }}>
                              Beneficiary
                            </Typography>

                            <div className="d-flex-a mt-2  ">
                              <Typography className="d-flex-ja flex-column stack-info ">
                                <Typography className="c-noraml f-14 ">
                                  {
                                    petitions?.petitionData
                                      ?.beneficiary_email_id
                                  }
                                </Typography>
                                <div className="mt-1">
                                  {petitions?.petitionData
                                    ?.beneficiary_contact_mobile
                                    ? ' '.concat(
                                        petitions?.petitionData
                                          ?.beneficiary_contact_mobile,
                                      )
                                    : ''}
                                </div>
                              </Typography>
                            </div>
                          </Typography>
                        </Grid>
                        {/* Petitioner */}
                        <Grid item md={3} sm={12}>
                          <Typography
                            component={'div'}
                            className="c-p-item d-flex-a flex-column"
                            sx={{
                              borderRadius: '8px !important',
                              minHeight: '230px !important',
                              padding: '1rem !important',
                            }}>
                            <img
                              src={
                                !petitions?.teams?.petitioner_image
                                  ? Avator
                                  : `${fileApiUrl}${String(
                                      petitions?.teams?.petitioner_image,
                                    )}`
                              }
                              alt="upic"
                              className={classes.userImg}
                            />
                            <Typography
                              component={'div'}
                              className="mt-2 text-center">
                              {petitions?.petitionData?.petitioner_user_name}
                            </Typography>
                            <Typography
                              className="mt-1 f-12"
                              sx={{
                                color: 'grey !important',
                              }}>
                              Petitioner
                            </Typography>

                            <Typography className="c-noraml f-14 mt-1">
                              {petitions?.petitionData?.organization}
                            </Typography>
                            {/* <Typography className="c-blue">
                              Primary Contact
                            </Typography> */}
                            <div className="d-flex-a">
                              <Typography className="d-flex-ja flex-column mt-1">
                                <Typography className="c-noraml f-14">
                                  {petitions?.petitionData?.petitioner_email_id}
                                </Typography>
                                <div className="mt-1">
                                  {petitions?.petitionData
                                    ?.petitioner_contact_mobile
                                    ? ' '.concat(
                                        petitions?.petitionData
                                          ?.petitioner_contact_mobile,
                                      )
                                    : ''}
                                </div>
                              </Typography>
                            </div>
                            <div className="d-flex-jae" />
                          </Typography>
                        </Grid>
                        {/* Attorney */}
                        <Grid item md={3} sm={12}>
                          <Typography
                            component={'div'}
                            className="c-p-item d-flex-a flex-column"
                            sx={{
                              borderRadius: '8px !important',
                              minHeight: '230px !important',
                              padding: '1rem !important',
                            }}>
                            <img
                              src={
                                !petitions?.teams?.attorney_image
                                  ? Avator
                                  : `${fileApiUrl}${String(
                                      petitions?.teams?.attorney_image,
                                    )}`
                              }
                              alt="upic"
                              className={classes.userImg}
                            />

                            <Typography
                              component={'div'}
                              className="mt-2 text-center">
                              {petitions?.petitionData?.attorney_user_name}
                              {/* {petitions?.petitionData?.attorney_email_id
                                  ? ''
                                  : ''} */}
                            </Typography>

                            <Typography
                              className="mt-1 f-12"
                              sx={{ color: 'grey !important' }}>
                              Lead Attorney
                            </Typography>
                            <Typography className="d-flex-ja flex-column mt-1">
                              <Typography className="c-noraml f-14 mt-1">
                                {petitions?.petitionData?.attorney_email_id}
                              </Typography>
                              <div className="mt-1">
                                {petitions?.petitionData
                                  ?.attorney_contact_mobile
                                  ? ' '.concat(
                                      petitions?.petitionData
                                        ?.attorney_contact_mobile,
                                    )
                                  : ''}
                              </div>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12}>
                          <Typography
                            component={'div'}
                            className="c-p-item d-flex-a flex-column"
                            sx={{
                              borderRadius: '8px !important',
                              minHeight: '230px !important',
                              padding: '1rem !important',
                            }}>
                            <img
                              src={
                                !petitions?.teams?.case_manager_image
                                  ? Avator
                                  : `${fileApiUrl}${String(
                                      petitions?.teams?.case_manager_image,
                                    )}`
                              }
                              alt="upic"
                              className={classes.userImg}
                            />
                            <Typography className="d-flex-a mt-2 text-center">
                              {petitions &&
                                petitions.teams &&
                                petitions?.teams?.case_manager_name?.split(',')}
                            </Typography>
                            <Typography
                              className="mt-1 f-12"
                              sx={{ color: 'grey !important' }}>
                              Case Manager
                            </Typography>
                          </Typography>
                        </Grid>
                        {/* Dependents */}
                        {petitions.dependents &&
                          petitions?.dependents.length > 0 && (
                            <Grid item md={12} sm={12}>
                              <Typography className="w-title">
                                Dependents
                              </Typography>
                              <Grid
                                container
                                columnSpacing={2}
                                sx={{ marginLeft: '-16px !important' }}>
                                {petitions?.dependents.map(
                                  (dependent, index) => (
                                    <React.Fragment key={index}>
                                      {index !== 0 && (
                                        <div className="d-flex-a" />
                                      )}
                                      <Grid item md={3} sm={12}>
                                        <Typography
                                          component={'div'}
                                          className="c-p-item d-flex-a flex-column"
                                          sx={{
                                            borderRadius: '8px !important',
                                            minHeight: '230px !important',
                                            padding: '1rem !important',
                                          }}>
                                          <img
                                            src={Avator}
                                            alt="upic"
                                            className={classes.userImg}
                                          />

                                          <div>
                                            <Typography className="d-flex-ja mt-2 text-center">
                                              {dependent.first_name}{' '}
                                              {dependent.last_name}
                                            </Typography>
                                            <div className="d-flex-ja text-center">
                                              <Typography
                                                className="c-noraml f-14"
                                                sx={{
                                                  textTransform: 'capitalize',
                                                }}>
                                                {dependent.relationship}
                                              </Typography>
                                            </div>
                                            <Typography
                                              className="mt-1 f-12 text-center"
                                              sx={{ color: 'gray !important' }}>
                                              Dependents
                                            </Typography>
                                            <Typography className="c-noraml d-flex-ja mt-2 f-14 text-center">
                                              {dependent.email}
                                            </Typography>
                                          </div>
                                        </Typography>
                                      </Grid>
                                    </React.Fragment>
                                  ),
                                )}
                              </Grid>
                            </Grid>
                          )}
                        {/* Affiliate */}
                        {petitions?.petitionData?.affilateUserList &&
                          petitions?.petitionData?.affilateUserList.length >
                            0 && (
                            <Grid item md={12} sm={12}>
                              <Typography className="w-title">
                                Affiliate / Referrer
                              </Typography>
                              <Grid
                                container
                                columnSpacing={2}
                                sx={{ marginLeft: '-16px !important' }}>
                                {petitions?.petitionData?.affilateUserList.map(
                                  (affiliate, index) => (
                                    <React.Fragment key={index}>
                                      {index !== 0 && (
                                        <div className="d-flex-a" />
                                      )}

                                      <Grid item md={3} sm={12}>
                                        <Typography
                                          component={'div'}
                                          className="c-p-item d-flex-a flex-column"
                                          sx={{
                                            borderRadius: '8px !important',
                                            minHeight: '230px !important',
                                            padding: '1rem!important',
                                          }}>
                                          <img
                                            src={Avator}
                                            alt="upic"
                                            className={classes.userImg}
                                          />
                                          <Typography className="c-noraml f-14 mt-2 text-center">
                                            {affiliate?.first_name}{' '}
                                            {affiliate?.last_name}
                                          </Typography>

                                          <Typography className="c-noraml f-14 mt-1">
                                            {affiliate?.email_id}
                                          </Typography>
                                          <div className="mt-1">
                                            {affiliate?.contact_mobile
                                              ? ' '.concat(
                                                  affiliate?.contact_mobile,
                                                )
                                              : ''}
                                          </div>
                                        </Typography>
                                      </Grid>
                                    </React.Fragment>
                                  ),
                                )}
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                    </Typography>
                  ) : (
                    <Typography component={'div'} className="c-p-box">
                      <Typography
                        component={'div'}
                        className="c-p-item d-flex-a"
                        sx={{ padding: '1rem 2rem !important' }}>
                        <Grid container>
                          <Grid item md={12} sm={12} className="d-flex-a">
                            <Typography>No Record(s) Found.</Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Typography>
                  )}
                </TabPanel>
                <TabPanel value="2">
                  <Typography component={'div'} className="c-p-box">
                    {/* General Info */}
                    <form>
                      <Grid container className="custom-field">
                        <Grid item md={6} sm={12}>
                          <Typography className="custom-label">
                            Visa Type
                          </Typography>
                          <Typography className="d-flex-a">
                            {petitions &&
                              petitions.petitionData &&
                              petitions?.petitionData?.visa_type}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12}>
                          <Typography className="custom-label">
                            Status
                          </Typography>
                          <div>
                            {getAndShowLeadStatus(
                              petitions &&
                                petitions.leadList &&
                                petitions?.leadList?.lead_status,
                            )}
                          </div>
                        </Grid>
                        <Grid item md={12} sm={12}>
                          <Typography className="custom-label mt-2">
                            Notes
                          </Typography>
                          <CustomInput
                            disabled={true}
                            value={
                              petitions &&
                              petitions.leadList &&
                              petitions?.leadList?.notes
                            }
                            isTextArea={true}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  </Typography>
                </TabPanel>
                <TabPanel value="3">
                  <Grid>
                    <AddNote
                      notes={attorneyNotes}
                      setNotes={setAttorneyNotes}
                      type="petition"
                      showModel={true}
                      showTable={false}
                      notesAdded={notesAdded}
                      setNotesAdded={setNotesAdded}
                      notesRecall={notesRecall}
                    />
                    <AddNote
                      ref={notesListComponentRef}
                      notes={viewAttorneyNotes}
                      setNotes={setViewAttorneyNotes}
                      type="petition"
                      showModel={false}
                      showTable={true}
                      notesAdded={notesAdded}
                      setNotesAdded={setNotesAdded}
                    />
                  </Grid>
                </TabPanel>
              </Typography>
            </TabContext>
          </Box>
        </Grid>
        {value === '3' &&
          (!petitions?.petitionData?.isAffilateCase ||
            (petitions?.petitionData?.isAffilateCase &&
              [2, 3].includes(petitions?.petitionData?.affilateRoleId))) && (
            <Grid item md={2} sm={12}>
              <Typography component={'div'} className="bulk-add-box">
                <Typography component={'div'} className="add-inner mb-1">
                  <Typography component={'div'} className="add-inner-box" />
                  <Typography className="link-txt f-16 pt-5" />
                  <Typography
                    className="d-flex-a bu-color pl-2 pt-2 pb-1"
                    onClick={() =>
                      setAttorneyNotes({
                        leadOrPetitionId: petitionId,
                        show: true,
                        type: 'Petition',
                      })
                    }
                    style={{ cursor: 'pointer' }}>
                    Response
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default PetitionCaseDetail;
