import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import Bdownload from 'assets/images/b-download.png';
import CustomModalView from 'components/CustomModalView/customModalView';
import saveAs from 'file-saver';
import React from 'react';
import { toast } from 'react-toastify';
import { addNewData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

interface Props {
  documentList: Array<any>;
  downloadFor: 'lead' | 'petition';
  userName: string;
  isDependent?: boolean;
}
export const DocumentBulkDownload: React.FC<Props> = (props: Props) => {
  const { documentList, downloadFor, userName, isDependent = false } = props;
  const [dialogView, setDialogView] = React.useState<boolean>(false);
  const [list, setList] = React.useState([]);
  const [loader, setLoader] = React.useState<boolean>(false);

  const downloadAll = async () => {
    try {
      const finalList = list.filter((e) =>
        e.multipleDocumentsList.some((el) => el.checked),
      );
      if (finalList.length) {
        const documentIds: number[] = [];

        finalList.map((el) => {
          el.multipleDocumentsList.map((e) => {
            if (e.checked) documentIds.push(e.documentId);
          });
        });

        if (documentIds.length) {
          const bodyObj = {
            downloadFor: downloadFor,
            userName: userName,
            ...(isDependent
              ? { depdendentDocumentId: documentIds, isDependent: true }
              : { documentId: documentIds }),
          };
          setLoader(true);
          const fileBlob = await addNewData(
            bodyObj,
            `${String(COMMON_ENDPOINT.bulkDocumentDownload)}`,
            { responseType: 'blob' },
          );

          const fileName: string =
            documentIds.length === 1
              ? finalList
                  .filter((e) =>
                    e.multipleDocumentsList.filter((l) => l.checked),
                  )[0]
                  ?.multipleDocumentsList?.find(
                    (el) => el.documentId === documentIds[0],
                  )?.original_name
              : `${userName.trim().split(' ').join('_')}_${
                  downloadFor === 'lead' ? 'Lead' : 'Petition'
                }_${new Date().getMonth() + 1}-${
                  new Date().getDate() < 10
                    ? // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      '0' + new Date().getDate()
                    : new Date().getDate()
                }-${new Date().getFullYear()}.zip`;
          saveAs(fileBlob, fileName);
          setLoader(false);
          setDialogView(false);
          toast.success(
            `${fileName} File downloaded successfully`,
            alertOptions,
          );
        }
      }
    } catch (error) {
      setLoader(false);
    }
  };
  const removeOrAddInMultiDoc = (list, mainIndex, subIndex, checked) => {
    list[mainIndex].multipleDocumentsList[subIndex].checked = checked;
    setList([...list]);
  };
  const removeOrAddDoc = (currentData, checked) => {
    setList((pre) => [
      ...pre.map((e) => {
        return {
          ...(e.currentDocumentId === currentData.currentDocumentId
            ? {
                ...e,
                multipleDocumentsList: [
                  ...e.multipleDocumentsList.map((multi) => ({
                    ...multi,
                    checked: checked,
                  })),
                ],
              }
            : e),
        };
      }),
    ]);
  };

  React.useEffect(() => {
    setList(
      documentList && documentList.length
        ? [
            ...documentList.map((e) => ({
              ...e,
              multipleDocumentsList: e.multipleDocumentsList.map((el, i) => ({
                ...el,
                checked: i === 0 ? true : false,
              })),
            })),
          ]
        : [],
    );
  }, [documentList]);

  return (
    <>
      <Typography
        component={'div'}
        className="add-inner mt-2 mb-2 pointer"
        onClick={() => setDialogView(true)}>
        <Typography component={'div'} className="add-inner-box" />
        <Typography className=" d-flex-a bu-color pl-2 pt-2 pb-1">
          <img
            src={Bdownload}
            alt="im"
            className="mr-1"
            style={{ width: '20px !important' }}
          />
          Bulk Download
        </Typography>
      </Typography>
      <CustomModalView
        open={dialogView}
        fullWidth={true}
        maxWidth={'lg'}
        scroll={'paper'}
        autoCloseDisable={true}
        title="Document List"
        close={() => {
          setDialogView(false);
        }}>
        {loader && <div className="liberty-loader" />}
        <DialogContent>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th scope="col" style={{ width: '50%' }}>
                  {'Document Type'}
                </th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {list && list.length ? (
                list.map((data, index) => (
                  <tr key={index} style={{ cursor: 'pointer' }}>
                    <td>
                      <a
                        style={{
                          color: '#1093df',
                          fontFamily: ' Arial-Bold',
                          fontSize: '16px',
                        }}>
                        {data?.document_type}
                      </a>
                      {data.multipleDocumentsList.map((e, i) => {
                        return (
                          <Typography
                            key={i}
                            className="d-flex-a"
                            component={'div'}>
                            <Checkbox
                              checked={e?.checked}
                              onChange={() =>
                                removeOrAddInMultiDoc(
                                  list,
                                  index,
                                  i,
                                  !e?.checked as any,
                                )
                              }
                            />
                            <Typography>{e.original_name} </Typography>
                          </Typography>
                        );
                      })}
                    </td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {' '}
                      <Checkbox
                        size={'medium'}
                        checked={
                          data?.multipleDocumentsList &&
                          data?.multipleDocumentsList.every(
                            (multi) => multi.checked,
                          )
                        }
                        onChange={(e) => removeOrAddDoc(data, e.target.checked)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr className={'text-center'}>
                  <th scope="row" colSpan={2}>
                    No Record(s) Found.
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
        {list.filter((e) => e.multipleDocumentsList.some((el) => el.checked))
          .length ? (
          <DialogActions>
            <Typography
              component={'div'}
              className="d-flex-jae"
              sx={{ padding: '0 1.5rem' }}>
              <Button
                className="n-add-btn"
                sx={{
                  borderRadius: '5px !important',
                  float: 'right',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '0.9em !important',
                  height: '35px !important',
                  margin: '15px',
                  width: '149px !important',
                }}
                onClick={() => downloadAll()}>
                Download
              </Button>
            </Typography>
          </DialogActions>
        ) : (
          ''
        )}
      </CustomModalView>
    </>
  );
};
