import axios from 'axios';
import { useCustomNavigation } from 'hooks';
import { toast } from 'react-toastify';
import store from 'store';
// import { refreshToken } from 'store/actions/UserAction';
import { USER_LOGOUT } from 'store/types/UserTypes';
import { alertOptions } from 'utils/helper';
import Storage from 'utils/Storage';

const { dispatch } = store;

let id: any = '';

const instance = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const TokenStatus: any = Storage.getTokens();
    const token = TokenStatus && TokenStatus.token ? TokenStatus.token : '';
    if (token) {
      config.headers['Authorization'] = 'Bearer '.concat(token);
    }
    config.headers['ClientDate'] = String(new Date());
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    // const responseData = err.response.data;
    if (originalConfig.url !== 'login' && err.response) {
      // Access Token was expired

      // if (
      //   err.response.status === 401 &&
      //   originalConfig.url === 'http://127.0.0.1:3000/auth/token'
      // ) {
      //   useCustomNavigation('/login', { replace: true });
      //   return Promise.reject(err);
      // }

      // if (err.response.status === 401 && !originalConfig._retry) {
      //   originalConfig._retry = true;
      //   const TokenStatus: any = Storage.getTokens();
      //   return instance
      //     .post('tokens/exchange', {
      //       refresh_token: TokenStatus.refreshToken,
      //     })
      //     .then((res) => {
      //       if (res.status === 201) {
      //         const { token } = res.data;
      //         dispatch(refreshToken(token));
      //         Storage.updateAccessToken(token);
      //         instance.defaults.headers.common['Authorization'] =
      //           'Bearer '.concat(token);
      //         instance.defaults.headers.common['ClientDate'] = String(
      //           new Date(),
      //         );
      //         return instance(originalConfig);
      //       }
      //     });
      // }

      if (
        err.response &&
        err.response.data &&
        err.response.data.error?.message &&
        err.response.data.error?.message === 'Invalid Token!'
      ) {
        dispatch({
          type: USER_LOGOUT,
        });
        Storage.clear();
        if (!toast.isActive(id)) {
          id = toast.error(
            'Session expired! Please login again. ',
            alertOptions,
          );
        }
        useCustomNavigation('/login', { replace: true });
        return;
      }
    }

    // if (err.response) {
    //   console.log('originalConfig', originalConfig);
    //   console.log('err.response', err.response);
    // }

    return Promise.reject(err);
  },
);
export default instance;
