import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  Modal,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Avator from 'assets/images/d-avator.svg';
// import pencil from 'assets/images/pencil.svg';
// import pencilImg from 'assets/images/pencil-img.svg';
import CustomDatePicker from 'components/CustomDatePicker/customDatePicker';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
import { AdminPrivateRoutes } from 'config';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { LawfirmAddUserSubmitForm } from 'types';
import { fileApiUrl } from 'utils/Config';
import { COMMON_ENDPOINT, MASTER_ENDPOINT } from 'utils/Constant';
import { alertOptions, validateDate } from 'utils/helper';
import { Lawfirm, LawfirmEdit } from 'utils/ValidatorSchema';

import useStyles from '../../../PrivateStyle';

export const ManageLawFirmAddAndEdit: React.FC<any> = (props) => {
  const navigate: any = useNavigate();
  const { editId } = useParams();
  const classes = useStyles();

  // const userDetail: any = useAppSelector(
  //   (store: any) => store.userLogin.userInfo,
  // );
  // const hiddenFileInput = useRef(null);
  const activeStatusInput = useRef(null);
  const inActiveStatusInput = useRef(null);
  const activeContactStatusInput = useRef(null);
  const inActiveContactStatusInput = useRef(null);
  const [openModel, setOpenModel] = useState(false);
  const handleModelOpen = () => setOpenModel(true);
  const handleModelClose = () => setOpenModel(false);
  const [loader, setLoader] = useState(false);
  const [lawFirmPlans, setLawFirmPlans] = useState<any>({});
  const [contactTypes, setContactTypes] = useState<any>([]);
  const [contactData, setContactData] = useState<any>([]);
  const [progress, setProgress] = React.useState(0);

  const [countries, setCountries] = useState<any>({
    cities: [],
    coutries: [],
    state: [],
  });
  const [profileImage, setProfileImage] = useState<any>({
    imageURL: '',
    selectedImage: null,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [statusToggle, setStatusToggle] = useState(true);
  const [contactStatusToggle, setContactStatusToggle] = useState(true);

  const {
    control: control,
    handleSubmit: handleSubmit,
    // reset: resetForm,
    setValue: setValueAdd,
    setError: setErrorAdd,
    getValues: getValuesAdd,
    watch,
    formState: { errors },
  } = useForm<LawfirmAddUserSubmitForm>({
    criteriaMode: 'all',
    defaultValues: {
      attorneyAddress: '',
      attorneyCity: '',
      attorneyCountry: '',
      attorneyEmail: '',
      attorneyFax: '',
      attorneyLogo: '',
      attorneyName: '',
      attorneyPhone: '',
      attorneyState: '',
      attorneyStatus: '',
      attorneyWebsite: '',
      attorneyZipCode: '',
      contactEmail: '',
      contactType: '',
      designation: '',
      firstName: '',
      gender: '',
      lastName: '',
      middleName: '',
      mobileNumber: '',
      phoneNumber: '',
      primaryContact: true,
      roleId: '3',
      streetNumberAndName: '',
      unitNumber: '',
      unitType: '',
      validTill: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(_.isUndefined(editId) ? Lawfirm : LawfirmEdit),
  });
  // const cancelForm = () => {
  //   resetForm();
  //   navigate(-1);
  // };

  const onToggleStatus = () => setStatusToggle((b) => !b);
  const onToggleContactStatus = () => setContactStatusToggle((b) => !b);

  const getInitialStatus = useCallback(() => {
    setLoader(true);
    getAllListData(
      `${COMMON_ENDPOINT.subscriptionPlan}?firmId=${String(editId)}`,
    )
      .then((resp: any) => {
        setLawFirmPlans(resp);
        setProgress(100 * (resp.activeUserCount / resp.total_user));
      })
      .catch((e: any) => {
        setLoader(false);
      });
    getAllListData(`${COMMON_ENDPOINT.ManageLawFirm}/${String(editId)}`)
      .then((resp) => {
        const {
          firm_name,
          firm_street_number_and_name,
          firm_unit_type,
          firm_unit_number,
          firm_zip_code,
          firm_city,
          firm_state,
          firm_country,
          firm_phone,
          firm_fax,
          firm_website,
          firm_valid_till,
          firm_status,
          firm_email,
          firm_logo,
        } = resp;

        !_.isNull(firm_country)
          ? getCountriesDependent('countryId', firm_country)
          : getCountriesDependent('');
        !_.isNull(firm_state)
          ? getCountriesDependent('stateId', firm_state)
          : getCountriesDependent('');
        if (firm_logo) {
          setProfileImage({
            imageURL: `${fileApiUrl}${String(firm_logo)}`,
            selectedImage: { firm_logo },
          });
        }

        setValueAdd('attorneyName', firm_name || '');
        setValueAdd('attorneyZipCode', firm_zip_code || '');
        setValueAdd('attorneyCity', Number(firm_city) || '');
        setValueAdd('attorneyState', Number(firm_state) || '');
        setValueAdd('attorneyCountry', Number(firm_country) || '');
        setValueAdd('attorneyPhone', firm_phone || '');
        setValueAdd('attorneyFax', firm_fax || '');
        setValueAdd('attorneyEmail', firm_email || '');
        setValueAdd('attorneyWebsite', firm_website || '');
        setValueAdd(
          'validTill',
          firm_valid_till ? moment(firm_valid_till).format('MM/DD/YYYY') : '',
        );

        setValueAdd('unitType', firm_unit_type || '');
        setValueAdd('unitNumber', firm_unit_number || '');
        setValueAdd('streetNumberAndName', firm_street_number_and_name || '');

        setStatusToggle(firm_status === 'active' ? true : false);

        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
    getAllListData(`${COMMON_ENDPOINT.ManageLawFirm}/user/${String(editId)}`)
      .then((userResp: any) => {
        setContactData(userResp);
      })
      .catch((e: any) => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitForm = (data: any) => {
    if (
      data['validTill'] &&
      !validateDate(moment(data['validTill']).format('MM/DD/YYYY'))
    ) {
      setErrorAdd('validTill', {
        message: 'Not a valid date format.',
        type: 'custom',
      });
    } else {
      setLoader(true);

      const postFormData = new FormData();
      !_.isBuffer(profileImage.selectedImage) &&
        postFormData.append('attorneyLogo', profileImage.selectedImage);
      postFormData.append('roleId', '3');
      postFormData.append(
        'attorneyName',
        data['attorneyName'] ? data['attorneyName'] : '',
      );
      postFormData.append(
        'attorneyAddress',
        data['attorneyAddress'] ? data['attorneyAddress'] : '',
      );
      postFormData.append(
        'attorneyZipCode',
        data['attorneyZipCode'] ? data['attorneyZipCode'] : '',
      );
      postFormData.append(
        'attorneyCity',
        data['attorneyCity'] ? String(data['attorneyCity']) : '',
      );
      postFormData.append(
        'attorneyState',
        data['attorneyState'] ? String(data['attorneyState']) : '',
      );
      postFormData.append(
        'attorneyCountry',
        data['attorneyCountry'] ? String(data['attorneyCountry']) : '',
      );
      postFormData.append(
        'attorneyPhone',
        data['attorneyPhone'] ? data['attorneyPhone'] : '',
      );
      postFormData.append(
        'attorneyFax',
        data['attorneyFax'] ? data['attorneyFax'] : '',
      );
      postFormData.append(
        'attorneyEmail',
        data['attorneyEmail'] ? data['attorneyEmail'] : '',
      );
      postFormData.append(
        'attorneyWebsite',
        data['attorneyWebsite'] ? data['attorneyWebsite'] : '',
      );
      postFormData.append(
        'validTill',
        data['validTill']
          ? moment(data['validTill']).format('YYYY-MM-DD hh:mm')
          : '',
      );
      postFormData.append(
        'streetNumberAndName',
        data['streetNumberAndName'] ? data['streetNumberAndName'] : '',
      );
      postFormData.append('unitType', data['unitType'] ? data['unitType'] : '');
      postFormData.append(
        'unitNumber',
        data['unitNumber'] ? data['unitNumber'] : '',
      );
      postFormData.append(
        'attorneyStatus',
        statusToggle ? 'active' : 'inactive',
      );
      if (_.isUndefined(editId)) {
        postFormData.append(
          'contactType',
          data['contactType'] ? data['contactType'] : '',
        );
        postFormData.append(
          'firstName',
          data['firstName'] ? data['firstName'] : '',
        );
        postFormData.append(
          'primaryContact',
          data['primaryContact'] ? data['primaryContact'] : '',
        );
        postFormData.append(
          'lastName',
          data['lastName'] ? data['lastName'] : '',
        );
        postFormData.append(
          'middleName',
          data['lastName'] ? data['lastName'] : '',
        );
        postFormData.append('gender', data['gender'] ? data['gender'] : '');
        postFormData.append(
          'designation',
          data['designation'] ? data['designation'] : '',
        );
        postFormData.append(
          'phoneNumber',
          data['phoneNumber'] ? data['phoneNumber'] : '',
        );
        postFormData.append(
          'mobileNumber',
          data['mobileNumber'] ? data['mobileNumber'] : '',
        );
        postFormData.append(
          'contactEmail',
          data['contactEmail'] ? data['contactEmail'] : '',
        );
      }
      if (!_.isUndefined(editId)) {
        updateData(editId, postFormData, COMMON_ENDPOINT.ManageLawFirm)
          .then((resp: any) => {
            // cancelForm();
            setLoader(false);
            toast.success('Updated successfully', alertOptions);
          })
          .catch((e) => {
            setLoader(false);
          });
      } else {
        addNewData(postFormData, COMMON_ENDPOINT.AddLawFirm)
          .then((resp: any) => {
            if (props.from != 'signup') {
              toast.success('Created successfully', alertOptions);
            }
            if (props.from === 'signup') {
              setLoader(false);
              handleOpen();
            } else {
              setLoader(false);
              navigate(AdminPrivateRoutes.MANAGELAWFIRM.MANAGELAWFIRMLIST);
            }
          })
          .catch((e) => {
            setLoader(false);
          });
      }

      // postFormData.append(
      //   'dateOfJoining',
      //   moment(data['dateOfJoining']).format('YYYY-MM-DD hh:mm'),
      // );
      // postFormData.append('status', statusToggle ? 'active' : 'inactive');

      // if (!_.isUndefined(editId)) {
      //   updateData(editId, postFormData, COMMON_ENDPOINT.ManagePetitioner)
      //     .then((resp: any) => {
      //       cancelForm();
      //       setLoader(false);
      //     })
      //     .catch((e) => {
      //       setLoader(false);
      //     });
      // } else {
      //   addNewData(postFormData, COMMON_ENDPOINT.ManagePetitioner)
      //     .then((resp: any) => {
      //       cancelForm();
      //     })
      //     .catch((e) => {
      //       setLoader(false);
      //     });
      // }
    }
  };

  const getCountriesDependent = useCallback((key?: string, value?: number) => {
    getAllListData(
      key === ''
        ? MASTER_ENDPOINT.Countries
        : `${MASTER_ENDPOINT.Countries}?${key}=${String(value)}`,
    )
      .then((countryResp: any) => {
        if (key === 'countryId') {
          setCountries((prev) => ({
            ...prev,
            countries: countryResp.countries,
            states: countryResp.states,
          }));
        }
        if (key === 'stateId') {
          setCountries((prev) => ({
            ...prev,
            cities: countryResp.cities,
            countries: countryResp.countries,
          }));
        }
        if (key === '') {
          setCountries({
            cities: countryResp.cities,
            countries: countryResp.countries,
            states: countryResp.states,
          });
        }
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  }, []);

  // const profileSelected = (file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file[0]);
  //   setProfileImage({
  //     selectedImage: file[0],
  //   });
  //   reader.onloadend = () => {
  //     setProfileImage((prev) => ({
  //       ...prev,
  //       imageURL: [reader.result],
  //     }));
  //   };
  // };

  useEffect(() => {
    if (!_.isUndefined(editId)) {
      getInitialStatus();
    } else {
      getAllListData(COMMON_ENDPOINT.ResponseTypes)
        .then((typeResp: any) => {
          setContactTypes(typeResp);
          getCountriesDependent('');
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }} className="pl-0">
        {loader ? (
          <div className="liberty-loader" />
        ) : !open ? (
          <>
            {props.from === 'signup' && (
              <Typography component={'div'} style={{ color: '#fff' }}>
                <h1>Law Firm Registration</h1>
              </Typography>
            )}
            <form id="add-petitioner-all" onSubmit={handleSubmit(onSubmitForm)}>
              <fieldset
                style={{ border: 'none', padding: 'unset' }}
                disabled={true}>
                <Typography
                  component={'div'}
                  className="addbox"
                  sx={{ marginTop: '4rem !important' }}>
                  <div className="user-poto-box">
                    {/* <div
                      className="pencil-box"
                      onClick={() => hiddenFileInput.current.click()}>
                      <img
                        className="d-block"
                        src={pencil}
                        alt="pencileditsvg"
                      />
                      <img
                        className="d-none"
                        src={pencilImg}
                        alt="pencileditsvg"
                      />

                      <input
                        type="file"
                        hidden
                        ref={hiddenFileInput}
                        onChange={(e) => profileSelected(e.target.files)}
                        accept="image/png, image/jpeg"
                      />
                    </div> */}
                    {_.isBuffer(profileImage.selectedImage) ? (
                      <img
                        src={profileImage.imageURL}
                        alt="user"
                        className="user-poto"
                      />
                    ) : (
                      <img
                        src={
                          profileImage.imageURL !== ''
                            ? profileImage.imageURL
                            : Avator
                        }
                        alt="user"
                        className="user-poto"
                      />
                    )}
                  </div>
                  <Box
                    sx={{ marginTop: '4rem' }}
                    component="form"
                    className="u-add-in"
                    noValidate
                    autoComplete="off">
                    <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                      <Grid item md={6} sm={12}>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Firm Name <span style={{ color: 'red' }}>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="attorneyName"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Firm Name'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errors.attorneyName?.message}
                                  onChange={(e) => onChange(e)}
                                  tabIndex={1}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Street Number And Name
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="streetNumberAndName"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Street Number And Name'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errors.streetNumberAndName?.message}
                                  onChange={(e) => onChange(e)}
                                  tabIndex={3}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Unit Type
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue="appartment"
                            name="unitType"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name={name}
                                  value={value}
                                  tabIndex={5}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}>
                                  <FormControlLabel
                                    value="appartment"
                                    control={<Radio />}
                                    label="Apartment"
                                  />
                                  <FormControlLabel
                                    value="suite"
                                    control={<Radio />}
                                    label="Suite"
                                  />
                                  <FormControlLabel
                                    value="floor"
                                    control={<Radio />}
                                    label="Floor"
                                  />
                                  {getValuesAdd().unitType && (
                                    <FormControlLabel
                                      value=""
                                      control={
                                        <span
                                          className="reset-button"
                                          onClick={() =>
                                            setValueAdd('unitType', '')
                                          }>
                                          Reset
                                        </span>
                                      }
                                      label=""
                                    />
                                  )}
                                </RadioGroup>
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Unit Number
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="unitNumber"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Unit Number'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errors.unitNumber?.message}
                                  onChange={(e) => onChange(e)}
                                  tabIndex={7}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Country
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="attorneyCountry"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomSelect
                                  searchable={true}
                                  labelKey={'name'}
                                  valueKey={'id'}
                                  options={
                                    !_.isEmpty(countries?.countries)
                                      ? countries?.countries
                                      : []
                                  }
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    getCountriesDependent(
                                      'countryId',
                                      e.target.value,
                                    );
                                  }}
                                  placeHolder={'Select'}
                                  className={'custom-input'}
                                  error={errors.attorneyCountry?.message}
                                  tabIndex={9}
                                />
                              );
                            }}
                          />
                        </Typography>

                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            City
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="attorneyCity"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomSelect
                                  searchable={true}
                                  labelKey={'name'}
                                  valueKey={'id'}
                                  options={
                                    !_.isEmpty(countries?.cities)
                                      ? countries?.cities
                                      : []
                                  }
                                  name={name}
                                  value={value}
                                  onChange={(e) => onChange(e)}
                                  placeHolder={'Select'}
                                  className={'custom-input'}
                                  error={errors.attorneyCity?.message}
                                  tabIndex={11}
                                />
                              );
                            }}
                          />
                        </Typography>
                        {props.from !== 'signup' && (
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Fax
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=""
                              name="attorneyFax"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    placeHolder={'Fax'}
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errors.attorneyFax?.message}
                                    onChange={(e) => onChange(e)}
                                    tabIndex={13}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        )}
                      </Grid>

                      <Grid item md={6} sm={12}>
                        {props.from !== 'signup' && (
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Valid Till
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=""
                              name="validTill"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomDatePicker
                                    className="custom-input"
                                    errors={errors.validTill?.message}
                                    name={name}
                                    value={value}
                                    onChangeDate={(val) => onChange(val)}
                                    tabIndex={2}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        )}
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Phone
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="attorneyPhone"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Phone'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errors.attorneyPhone?.message}
                                  onChange={(e) =>
                                    onChange(
                                      e.target.value.replace(/[^\d]/g, ''),
                                    )
                                  }
                                  type={'text'}
                                  tabIndex={4}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Email{' '}
                            <Typography
                              component={'span'}
                              className="Mui-error">
                              *
                            </Typography>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="attorneyEmail"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Email'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errors.attorneyEmail?.message}
                                  onChange={(e) => onChange(e)}
                                  type={'email'}
                                  tabIndex={6}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Website
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="attorneyWebsite"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Website'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errors.attorneyWebsite?.message}
                                  onChange={(e) => onChange(e)}
                                  tabIndex={8}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            State
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="attorneyState"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomSelect
                                  searchable={true}
                                  labelKey={'name'}
                                  valueKey={'id'}
                                  options={
                                    !_.isEmpty(countries?.states)
                                      ? countries?.states
                                      : []
                                  }
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    getCountriesDependent(
                                      'stateId',
                                      e.target.value,
                                    );
                                  }}
                                  placeHolder={'Select'}
                                  className={'custom-input'}
                                  error={errors.attorneyState?.message}
                                  tabIndex={10}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Zip / Postal Code
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="attorneyZipCode"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder={'Zip / Postal Code'}
                                  name={name}
                                  value={value}
                                  className={'custom-input'}
                                  error={errors.attorneyZipCode?.message}
                                  onChange={(e) =>
                                    onChange(
                                      e.target.value.replace(/[^\d]/g, ''),
                                    )
                                  }
                                  type={'text'}
                                  tabIndex={12}
                                />
                              );
                            }}
                          />
                        </Typography>
                        {props.from === 'signup' && (
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Fax
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=""
                              name="attorneyFax"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    placeHolder={'Fax'}
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errors.attorneyFax?.message}
                                    onChange={(e) => onChange(e)}
                                    tabIndex={13}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        )}
                        {props.from !== 'signup' && (
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Status
                            </Typography>
                            <div className="switch-field" tabIndex={14}>
                              <input
                                type="radio"
                                id="switch_left"
                                name="switchToggle"
                                value={'inactive'}
                                onChange={onToggleStatus}
                                checked={!statusToggle}
                                ref={inActiveStatusInput}
                              />
                              <label
                                htmlFor="switch_left d-flex-ja"
                                onClick={() =>
                                  inActiveStatusInput.current.click()
                                }>
                                Inactive
                              </label>

                              <input
                                type="radio"
                                id="switch_right"
                                name="switchToggle"
                                value={'active'}
                                onChange={onToggleStatus}
                                checked={statusToggle}
                                ref={activeStatusInput}
                              />
                              <label
                                htmlFor="switch_right d-flex-ja"
                                onClick={() =>
                                  activeStatusInput.current.click()
                                }>
                                Active
                              </label>
                            </div>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  {!location.pathname.includes('/edit') &&
                    _.isUndefined(editId) && (
                      <>
                        <Typography component={'div'} className="uadd-title">
                          {props.from === 'signup'
                            ? 'Contact Person'
                            : 'Representative'}
                        </Typography>
                        <Box
                          component="form"
                          className="u-add-in"
                          noValidate
                          autoComplete="off">
                          <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                            <Grid item md={6} sm={12}>
                              <Typography
                                component={'div'}
                                className="custom-field">
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  Contact Type{' '}
                                  <Typography
                                    component={'span'}
                                    className="Mui-error">
                                    *
                                  </Typography>
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue=""
                                  name="contactType"
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomSelect
                                        labelKey={'conatct_type'}
                                        valueKey={'type_id'}
                                        options={
                                          !_.isEmpty(contactTypes)
                                            ? contactTypes
                                            : []
                                        }
                                        name={name}
                                        value={value}
                                        onChange={(e) => onChange(e)}
                                        placeHolder={'Select'}
                                        className={'custom-input'}
                                        error={errors.contactType?.message}
                                        tabIndex={15}
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                              <Typography
                                component={'div'}
                                className="custom-field">
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  First Name{' '}
                                  <span style={{ color: 'red' }}>*</span>
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue=""
                                  name="firstName"
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'First Name'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={errors.firstName?.message}
                                        onChange={(e) => onChange(e)}
                                        tabIndex={16}
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                              <Typography
                                component={'div'}
                                className="custom-field">
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  Gender
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue=""
                                  name="gender"
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomSelect
                                        labelKey={'label'}
                                        valueKey={'value'}
                                        tabIndex={18}
                                        options={[
                                          { label: 'Male', value: 'M' },
                                          { label: 'Female', value: 'F' },
                                        ]}
                                        name={name}
                                        value={value}
                                        onChange={(e) => onChange(e)}
                                        placeHolder={'Select'}
                                        className={'custom-input'}
                                        error={errors.gender?.message}
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                              <Typography
                                component={'div'}
                                className="custom-field">
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  Phone
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue=""
                                  name="phoneNumber"
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'Phone'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={errors.phoneNumber?.message}
                                        onChange={(e) =>
                                          onChange(
                                            e.target.value.replace(
                                              /[^\d]/g,
                                              '',
                                            ),
                                          )
                                        }
                                        type={'text'}
                                        tabIndex={20}
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                              <Typography
                                component={'div'}
                                className="custom-field">
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  Email{' '}
                                  <Typography
                                    component={'span'}
                                    className="Mui-error">
                                    *
                                  </Typography>
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue=""
                                  name="contactEmail"
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'Email'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={errors.contactEmail?.message}
                                        onChange={(e) => onChange(e)}
                                        type={'email'}
                                        tabIndex={22}
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              md={6}
                              sm={12}
                              style={{ paddingTop: '13px' }}>
                              <Typography
                                component={'div'}
                                className="custom-field">
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  Primary Contact
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={false}
                                  name="primaryContact"
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <Checkbox
                                        inputRef={ref}
                                        checked={value}
                                        onChange={onChange}
                                        name={name}
                                        value={value}
                                        color="primary"
                                        disabled={
                                          props.from !== 'signup' &&
                                          watch('primaryContact')
                                            ? true
                                            : false
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                              {/* {props.from !== 'singup' && (
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                MiddleName
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=""
                                name="middleName"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      placeHolder={'Middle Name'}
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={errors.middleName?.message}
                                      onChange={(e) => onChange(e)}
                                      tabIndex={17}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          )} */}
                              <Typography
                                component={'div'}
                                className="custom-field">
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  Last Name{' '}
                                  <span style={{ color: 'red' }}>*</span>
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue=""
                                  name="lastName"
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'Last Name'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={errors.lastName?.message}
                                        onChange={(e) => onChange(e)}
                                        tabIndex={17}
                                      />
                                    );
                                  }}
                                />
                              </Typography>

                              <Typography
                                component={'div'}
                                className="custom-field">
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  Designation
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue=""
                                  name="designation"
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'Designation'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={errors.designation?.message}
                                        onChange={(e) => onChange(e)}
                                        tabIndex={19}
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                              <Typography
                                component={'div'}
                                className="custom-field">
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  Mobile
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue=""
                                  name="mobileNumber"
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'Mobile'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={errors.mobileNumber?.message}
                                        onChange={(e) =>
                                          onChange(
                                            e.target.value.replace(
                                              /[^\d]/g,
                                              '',
                                            ),
                                          )
                                        }
                                        type={'text'}
                                        tabIndex={21}
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                              {props.from !== 'signup' && (
                                <Typography
                                  component={'div'}
                                  className="custom-field">
                                  <Typography
                                    component={'div'}
                                    className="custom-label">
                                    Contact Status{' '}
                                    <span style={{ color: 'red' }}>*</span>
                                  </Typography>
                                  <div className="switch-field" tabIndex={23}>
                                    <input
                                      type="radio"
                                      id="switch_left"
                                      name="switchToggle"
                                      value={'inactive'}
                                      onChange={onToggleContactStatus}
                                      checked={!contactStatusToggle}
                                      ref={inActiveContactStatusInput}
                                    />
                                    <label
                                      htmlFor="switch_left d-flex-ja"
                                      onClick={() =>
                                        inActiveContactStatusInput.current.click()
                                      }>
                                      Inactive
                                    </label>

                                    <input
                                      type="radio"
                                      id="switch_right"
                                      name="switchToggle"
                                      value={'active'}
                                      onChange={onToggleContactStatus}
                                      checked={contactStatusToggle}
                                      ref={activeContactStatusInput}
                                    />
                                    <label
                                      htmlFor="switch_right d-flex-ja"
                                      onClick={() =>
                                        activeContactStatusInput.current.click()
                                      }>
                                      Active
                                    </label>
                                  </div>
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    )}
                </Typography>
                {/* <Grid container className="bottom-fixed">
                  <Grid item md={12}>
                    <Typography component={'div'} className="d-flex-jae ">
                      <Button
                        className="cancel-btn mr-2"
                        type="button"
                        onClick={cancelForm}>
                        <Typography className="o-box" />
                        Cancel
                      </Button>
                      <Button
                        className="add-btn"
                        type="submit"
                        form={'add-petitioner-all'}
                        disabled={loader}>
                        {props.from !== 'signup' ? 'Save' : 'Submit'}
                      </Button>
                    </Typography>
                  </Grid>
                </Grid> */}
              </fieldset>
            </form>
            {!_.isUndefined(editId) && contactData.length > 0 ? (
              <Grid item md={12} sm={12}>
                <Typography component={'div'} className="d-flex-jae mt-2 mb-1">
                  {/* <Button
                    type="button"
                    className="n-add-btn mt-2 mb-1"
                    onClick={() =>
                      navigate(
                        `${
                          AdminPrivateRoutes.MANAGELAWFIRM.ADDREPRESENTIVE
                        }/${String(editId)}`,
                      )
                    }>
                    Add Contact Person
                  </Button> */}
                </Typography>

                <Typography
                  component={'div'}
                  sx={{
                    borderRadius: '8px 8px 0 0',
                  }}
                  className={classes.scrollbox}>
                  <TableContainer>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      className="lead-table table-hover"
                      style={{ minHeight: '106px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Contact Type</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Primary Contact</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contactData.map((cData, index) => (
                          <TableRow
                            key={index}
                            // onClick={() =>
                            //   navigate(
                            //     `${
                            //       AdminPrivateRoutes.MANAGELAWFIRM
                            //         .EDITREPTESENTIVE
                            //     }/${String(editId)}/${String(cData.id)}`,
                            //   )
                            // }
                          >
                            <TableCell scope="row">
                              {cData?.contact_type ? cData?.contact_type : '-'}
                            </TableCell>
                            <TableCell>
                              <Typography
                                component={'div'}
                                className="multiline">
                                {cData?.full_name ? cData?.full_name : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {cData?.email_id ? cData?.email_id : '-'}
                            </TableCell>
                            <TableCell>
                              {cData?.user_phone ? cData?.user_phone : '-'}
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                className="checkBoxFlex"
                                checked={cData?.primary_contact}
                                onChange={(e) => {}}
                                value={cData?.primary_contact}
                                color="primary"
                                disabled
                              />
                            </TableCell>
                            <TableCell>
                              {cData?.conatct_status === 'active'
                                ? 'Active'
                                : 'Inactive'}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Typography>
              </Grid>
            ) : (
              ''
            )}
          </>
        ) : null}

        {props.from === 'signup' && (
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className="success-re-modal">
              <Typography component={'div'} className="d-flex-a">
                <CheckCircleOutlineIcon
                  sx={{ fill: 'green', fontSize: '35px' }}
                />
                <Typography className="s-title mt-2">
                  You have successfully completed your registration!
                </Typography>
                <Typography className="s-title mt-1">
                  Please check your{' '}
                  <span
                    style={{
                      textAlign: 'center',
                      textDecoration: 'underline',
                      textUnderlinePosition: 'from-font',
                    }}>
                    email
                  </span>{' '}
                  to activate your account.
                </Typography>
                <Button
                  className="madd-btn"
                  onClick={() => {
                    handleClose();
                    navigate('/login');
                  }}>
                  Done
                </Button>
              </Typography>
            </Box>
          </Modal>
        )}
      </Grid>
      {!_.isEmpty(lawFirmPlans) && lawFirmPlans.is_subscription_need && (
        <Grid item md={2} sm={12}>
          <Typography component={'div'} className="add-box">
            <Typography component={'div'} className="add-inner-box" />
            <Typography
              className=" built-option d-flex-a mt-1"
              onClick={handleModelOpen}>
              Plan Details
            </Typography>
          </Typography>
        </Grid>
      )}
      <CustomModalView
        open={openModel}
        close={handleModelClose}
        fullWidth={true}
        maxWidth={'md'}
        title="Your Plan Details">
        <div
          className="subscription-box"
          style={{ backgroundColor: '#FFFFFF' }}>
          <div style={{ height: '45vh', paddingLeft: '28px' }}>
            <Grid container columnSpacing={{ md: 3, sm: 2 }}>
              <Grid item md={12} sm={12}>
                <Typography component={'div'} className="plan-card">
                  <Typography component={'div'} className="d-flex d-flex-sb">
                    <Typography className="d-flex plan-title">
                      {lawFirmPlans.strip_product_name
                        ? lawFirmPlans.strip_product_name
                        : '-'}
                      <Button className="assem-badge ml-1">
                        {lawFirmPlans.recurring?.interval
                          ? capitalize(
                              lawFirmPlans.recurring?.interval.concat('ly'),
                            )
                          : ''}
                      </Button>
                    </Typography>

                    <Typography className="plan-info d-flex-a">
                      <span style={{ fontSize: '18px' }}>Status</span>
                      {'     '}
                      <Button className="assem-badge ml-1">
                        {lawFirmPlans.subscription_status
                          ? capitalize(lawFirmPlans.subscription_status)
                          : ''}
                      </Button>
                    </Typography>
                  </Typography>

                  <Typography component={'div'} className="d-flex d-flex-sa">
                    <Grid item md={6} sm={12}>
                      <Typography className="plan-info">
                        {lawFirmPlans.total_user} of{' '}
                        {lawFirmPlans.activeUserCount} users
                      </Typography>
                      <Box sx={{ width: '80%' }}>
                        <LinearProgress
                          className="progress"
                          variant="determinate"
                          value={progress}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={6} sm={12} className={'d-flex-jae'}>
                      <Typography
                        component={'div'}
                        className="d-flex content-end plan-title">
                        <span style={{ fontSize: '30px' }}>
                          $
                          {lawFirmPlans.planAmount
                            ? Number(lawFirmPlans.planAmount) / 100
                            : '0'}{' '}
                          <span
                            style={{
                              color: '#848080',
                              fontSize: '15px',
                            }}>
                            {' '}
                            / Monthly
                          </span>
                        </span>
                      </Typography>
                    </Grid>
                  </Typography>
                  <Typography component={'div'} style={{ paddingTop: '30px' }}>
                    <Typography className="plan-info d-flex">
                      <span style={{ fontSize: '18px' }}>Joined on:</span>
                      <span className="fon-500 pl-6  plan-title">
                        {moment(lawFirmPlans.subscription_start_date).format(
                          'MMMM DD, YYYY',
                        )}
                      </span>
                    </Typography>
                    <Typography className="plan-info d-flex">
                      <span style={{ fontSize: '18px' }}>Renewal on:</span>{' '}
                      <span className="fon-500 pl-6  plan-title">
                        {moment(lawFirmPlans.subscription_end_date).format(
                          'MMMM DD, YYYY',
                        )}
                      </span>
                    </Typography>
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </CustomModalView>
    </Grid>
  );
};

export default ManageLawFirmAddAndEdit;
