import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Footer } from 'components/Footer';
import { OnlineVisaVideo } from 'components/Onlinevisavideo';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { SETUP_ENDPOINT } from 'utils/Constant';

const SignUp: React.FC = () => {
  const [radio, setRadio] = useState<any>('');
  const [inviteHash, setInviteHash] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Check invite is valid or not
    const invite = searchParams.get('invite');
    if (invite) {
      getAllListData(
        `${String(SETUP_ENDPOINT.ManageOrganization)}?hash=${String(invite)}`,
      )
        .then((resp: any) => {
          if (resp?.accepted === false) {
            setInviteHash(invite);
          }
        })
        .catch((e: any) => {});
    }
  }, [searchParams]);

  return (
    <Grid container>
      <Grid
        item
        md={12}
        sm={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '100vh',
        }}>
        <OnlineVisaVideo />
        <div className="login singup-page temp">
          <Typography
            component={'div'}
            sx={{
              color: '#fff',
              fontFamily: 'Arial-Bold !important',
              fontSize: '1.1em !important',
              margin: '.5rem 0 1rem 0',
            }}>
            Please select an appropriate registration type from the below
            options
          </Typography>
          <div>
            <FormControl className="formheight">
              <RadioGroup className="wite-radio" name="radio-buttons-group">
                {/* <FormControlLabel
                  value="1"
                  control={<Radio checked={radio == '1' ? true : false} />}
                  label={
                    <Typography component={'div'} className="label-text">
                      <div>Applying Visa for myself</div>
                    </Typography>
                  }
                  onChange={(e: any) => {
                    setRadio(e.target.checked ? e.target.value : '');
                  }}
                /> */}
                <FormControlLabel
                  value="3"
                  control={<Radio checked={radio == '3' ? true : false} />}
                  label={
                    <Typography component={'div'} className="label-text">
                      <div>I would like to register an Law Firm</div>
                    </Typography>
                  }
                  onChange={(e: any) => {
                    setRadio(e.target.checked ? e.target.value : '');
                  }}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio checked={radio == '2' ? true : false} />}
                  label={
                    <Typography component={'div'} className="label-text">
                      <div>
                        I would like to register as an Organization/Petitioner
                      </div>
                    </Typography>
                  }
                  onChange={(e: any) => {
                    setRadio(e.target.checked ? e.target.value : '');
                  }}
                />
              </RadioGroup>
            </FormControl>
            <Grid container sx={{ paddingTop: '8px' }}>
              <Grid
                item
                md={12}
                sm={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <Button
                  className="cancel-btn"
                  type="button"
                  sx={{
                    borderRadius: '4px !important',
                    boxShadow:
                      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                    fontFamily: 'Arial-Regular !important',
                    fontSize: '0.9em !important',
                    height: '36px !important',
                    marginLeft: '-12px',
                    textTransform: 'uppercase !important',
                  }}
                  onClick={() => {
                    navigate('/login');
                  }}>
                  {/* <Typography className="o-box" /> */}
                  Cancel
                </Button>

                <Button
                  className="add-btn"
                  type="submit"
                  sx={{
                    backgroundColor: '#164baf !important',
                    borderRadius: '4px !important',
                    boxShadow:
                      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                    fontFamily: 'Arial-Regular !important',
                    fontSize: '0.9em !important',
                    height: '36px !important',
                    marginLeft: '15px',
                    textTransform: 'uppercase !important',
                  }}
                  onClick={() => {
                    if (inviteHash) {
                      radio === '2' &&
                        navigate(`/petitioner/signup?invite=${inviteHash}`);
                    } else {
                      if (radio === '2') {
                        navigate('/petitioner/signup');
                      } else {
                        window.location.href = 'https://visas.ai/pricing/';
                      }
                    }
                  }}
                  disabled={!['1', '2', '3'].includes(radio)}>
                  Next
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="stick-footer">
          <Footer />
        </div>
      </Grid>
    </Grid>
  );
};

export default SignUp;
