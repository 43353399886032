import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Popper,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Win from 'assets/images/winning.png';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import InfinityScroll from 'components/InfinityScroll';
// import { AttorneyPrivateRoutes } from 'config';
import { instantState } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import HistoryComments from 'pages/private/Client/Common/HistoryComments';
import { WizardForm } from 'pages/private/Common/wizardForm';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import {
  COMMON_ENDPOINT,
  Fill_FORM_ENDPOINT,
  SETUP_ENDPOINT,
  STRATEGY_ENDPOINT,
  TREE_VIEW_ENDPOINT,
} from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import { Translate } from '../../Translate/Translate';
import { CopyStrategyQuestionList } from '../copyStrategy/CopyStrategyQuestionList';
import { StrategySessionList } from '../copyStrategy/StrategySessionList';

export const StrategyInfo: React.FC<any> = () => {
  const location: any = useLocation();
  const { editId, petitionId } = useParams();
  const wizardRef: any = React.useRef();
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState(true);
  const [treeList, setTreeList] = React.useState([]);

  const [chooseStrategy, setChooseStrategy] = React.useState<boolean>(false);
  const [formId, setFormId] = React.useState<any>();
  const [listPetitionerStrategy, setListPetitionerStrategy] =
    React.useState<any>([]);
  const [strategyQus, setStrategyQus] = React.useState<{
    open: boolean;
    questionList: Array<any>;
    previousStrategyId: any;
  }>({
    open: false,
    previousStrategyId: '',
    questionList: [],
  });
  const [organizationQus, setOragnizationQus] = React.useState<any>({
    open: false,
    questionList: [],
  });
  const [hcData, setHCData] = useState<any>({
    comments: [],
    history: [],
    loading: true,
    selectedQuestion: {},
  });

  const [winningStrategyAnchor, setWinningStrategyAnchor] = useState<any>({
    anchorEl: null,
    open: false,
  });

  const [winningStrategies, setWinningStrategies] = useState<any>({
    data: [],
    total: 0,
  });
  const [winningParams, setWinningParams] = useState<any>({
    search: '',
    skip: 0,
    take: 10,
  });

  const [selectedIndex, setSelectedIndex] = useState<any>({
    index: null,
    show: false,
  });
  const [questionLoader, setQuestionLoader] = useState<boolean>(false);

  const winningStrategyId = winningStrategyAnchor.open ? 'simple-popper' : null;

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const { petitions } = useAppSelector((store: any) => store.petitionUser);

  const onSelectStrategy = async (copyStrategy) => {
    setQuestionLoader(true);
    try {
      const list = await getAllListData(
        STRATEGY_ENDPOINT.copyStrategyQuestionList(
          copyStrategy.benificiery_id,
          location?.state?.data?.formId,
        ),
      );
      setQuestionLoader(false);
      setStrategyQus((pre) => ({
        ...pre,
        open: true,
        previousStrategyId: copyStrategy?.strategy_id,
        questionList:
          list && list.data && list.data.length
            ? list.data.map((e) => {
                return { ...e, status: true };
              })
            : [],
      }));
    } catch (error) {
      setQuestionLoader(false);
      toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
  };
  const getStrategywizardFormData = useCallback(async () => {
    try {
      const formType = location?.state?.data?.benificiery_id
        ? 'strategy'
        : 'lead';
      const beneficiaryId = location?.state?.data?.benificiery_id || '';
      const leadId =
        formType === 'lead'
          ? location?.state?.data?.lead_id
            ? location?.state?.data?.lead_id
            : petitions?.petitionData?.lead_id
          : '';
      const formId = location?.state?.data?.formId;
      const firmId = petitions?.petitionData?.firm_id || '';
      const organizationId = petitions?.petitionData?.organizationId || '';
      const petitionId = location?.state?.data?.petitionId || '';
      const petitionerAssociate =
        petitions?.petitionData?.petitionerAssociate || '';
      const petitionerUserId = petitions?.petitionData?.petitionerUserId || '';
      const visaId = petitions?.petitionData?.visaId || '';
      const isAffilate =
        location.state?.showType === 'leads'
          ? location.state?.isAffilateCase
          : petitions?.petitionData?.isAffilateCase || false;
      const affilateRoleId =
        location.state?.showType === 'leads'
          ? location.state?.affilateRoleId
          : petitions?.petitionData?.affilateRoleId || null;

      const listData = await getAllListData(
        TREE_VIEW_ENDPOINT(
          formId,
          leadId,
          beneficiaryId,
          formType,
          petitionId,
          visaId,
          petitionerUserId,
          organizationId,
          firmId,
          null,
          null,
          null,
          petitionerAssociate,
          null,
          null,
          null,
          isAffilate,
          affilateRoleId,
        ),
      );
      setTreeList([...listData]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location?.state?.data?.benificiery_id,
    location?.state?.data?.formId,
    location?.state?.data?.lead_id,
    setLoader,
  ]);
  const getCopyStrategyQusFormData = useCallback(
    (questionSelectedList: Array<any>) => {
      return {
        benificiaryId: location?.state?.data?.benificiery_id,
        copiedStrategyId: strategyQus?.previousStrategyId,
        formId: location?.state?.data?.formId,
        formType: 'strategy',
        isCopied: true,
        list: questionSelectedList.map((data: any) => {
          return {
            answer: data.answer,
            previousAnswerId: data?.id,
            questionId: data.question_id,
            sectionId: data.section_id,
          };
        }),
        sectionId:
          questionSelectedList[questionSelectedList.length - 1].section_id,
      };
    },
    [
      location?.state?.data?.benificiery_id,
      location?.state?.data?.formId,
      strategyQus?.previousStrategyId,
    ],
  );
  const onSubmitCopyStategyQus = useCallback(
    async (questionSelectedList: Array<any>) => {
      try {
        setQuestionLoader(true);
        const bodyData = getCopyStrategyQusFormData(
          questionSelectedList && questionSelectedList.length
            ? questionSelectedList
            : [],
        );
        await addNewData(bodyData, STRATEGY_ENDPOINT.copyStrategyQusAndAnswer);
        setStrategyQus((pre) => ({
          ...pre,
          open: false,
        }));
        setQuestionLoader(false);
        setChooseStrategy(false);
        setLoader(true);
        getStrategywizardFormData();
        wizardRef && wizardRef.current && wizardRef.current.childMethod();
      } catch (error) {
        setQuestionLoader(false);
        toast.error(
          error ? error : 'Oops!!! Something went wrong',
          alertOptions,
        );
      }
    },
    [getCopyStrategyQusFormData, getStrategywizardFormData],
  );
  const getWinningStrategies = () => {
    getAllListData(
      `${String(SETUP_ENDPOINT.ConfigSection)}/winning?search=${String(
        winningParams.search,
      )}&skip=${String(winningParams.skip)}&take=${String(winningParams.take)}`,
    )
      .then((resp: any) => {
        const mergeData = !_.isEmpty(winningStrategies.data)
          ? [...winningStrategies.data, ...resp.data]
          : resp.data;
        setWinningStrategies({
          data: mergeData,
          total: Number(resp.total),
        });
      })
      .catch((e: any) => {});
  };

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    const { currentTarget } = event;
    setWinningStrategyAnchor((pre) => ({
      ...pre,
      anchorEl: currentTarget,
      open: pre.open == true ? false : true,
    }));
    const winning = await instantState(setWinningStrategyAnchor);
    winning.anchorEl && getWinningStrategies();
  };

  const handleClickAway = () => {
    setWinningStrategyAnchor((pre) => ({
      ...pre,
      anchorEl: null,
      open: false,
    }));
  };

  const rowProps = (winning, index) => {
    return (
      <Typography component={'div'} className="pop-det-box">
        <Typography
          className={'pop-title '.concat(
            selectedIndex.index === index ? 'active' : '',
          )}
          onClick={() =>
            setSelectedIndex({
              index,
              show: !selectedIndex.show,
            })
          }>
          {winning.question}
        </Typography>
        {selectedIndex.index === index && (
          <Typography className="pop-dis">{winning.notes}</Typography>
        )}
      </Typography>
    );
  };
  const getLeadInfo = useCallback(async () => {
    try {
      setLoader(true);
      const leadInfos = await getAllListData(
        location.pathname.includes('lead-details')
          ? `${COMMON_ENDPOINT.LeadList}?id=${String(editId)}`
          : `/petitions?id=${String(petitionId)}`,
      );
      await getAllListData(
        `${STRATEGY_ENDPOINT.OrganizationStrategy}?organizationId=${String(
          leadInfos?.data[0]?.petitioner_assigned,
        )}&associated=${String(
          leadInfos?.data[0]?.petitioner_associated,
        )}&formId=${String(
          location?.state?.data?.formId,
        )}&skip=0&take=50&order=DESC&sortby=created_at&sectionName=`,
      ).then(async (resp: any) => {
        setListPetitionerStrategy(resp && resp.data);
        setFormId(resp.data[0].form_id);
        await getAllListData(
          COMMON_ENDPOINT.OrganizationStrategyList(
            resp.data[0].form_id,
            leadInfos?.data[0]?.petitioner_assigned,
            resp.data[0].firm_id,
            leadInfos?.data[0]?.petitioner_associated,
            'org_strategy',
            resp.data[0].id,
          ),
        )
          .then((resp: any) => {
            const Data = resp[0]?.questionDetails?.map((e) => ({
              ...e,
              checked: false,
            }));
            setOragnizationQus((pre) => ({
              ...pre,
              questionList: Data,
            }));
          })
          .catch((e: any) => {});
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removeOrAddQues = (currentData, checked) => {
    setOragnizationQus((pre) => ({
      ...pre,
      questionList: [
        ...pre.questionList.map((e) => {
          return {
            ...(e.id === currentData.id
              ? {
                  ...e,
                  checked: checked,
                }
              : e),
          };
        }),
      ],
    }));
  };
  const checkAll = () => {
    if (organizationQus?.questionList?.length > 0) {
      return organizationQus?.questionList.every((el) => el.checked);
    } else {
      return false;
    }
  };
  const selectAllDocument = (ele) => {
    setOragnizationQus((pre) => ({
      ...pre,
      questionList: [
        ...pre.questionList.map((e) => {
          return {
            ...e,
            checked: ele?.target.checked,
          };
        }),
      ],
    }));
  };
  const copytoMaster = (
    answer: any,
    question: any,
    formAnswerId: any,
    setCopyDialog: any,
    response_type: any,
  ) => {
    const selectedDocuments: any = [
      {
        answer: response_type === 13 ? JSON.stringify(answer) : answer,
        formId: listPetitionerStrategy[0]?.form_id,
        previousAnswerId: formAnswerId,
        questionId: question,
        sectionId: listPetitionerStrategy[0]?.form_id,
      },
    ];
    const payload = {
      copiedStrategyId: listPetitionerStrategy[0]?.id,
      firmId: listPetitionerStrategy[0]?.firm_id,
      isCopied: true,
      list: selectedDocuments,
      organizationAssociated:
        listPetitionerStrategy[0]?.organization_associated,
      organizationId: listPetitionerStrategy[0]?.organization_id,
      strategyId: listPetitionerStrategy[0]?.id,
    };
    addNewData(payload, `${Fill_FORM_ENDPOINT}/organization`)
      .then(() => {
        toast.success('Updated successfully', alertOptions);
        setCopyDialog &&
          setCopyDialog((pre) => ({
            ...pre,
            show: false,
          }));
        getLeadInfo();
      })
      .catch((err) => {
        toast.error(err, alertOptions);
      });
  };
  const answerSubmit = useCallback(() => {
    const selectedDocuments: any = [];
    organizationQus?.questionList?.map((e) => {
      e['checked']
        ? selectedDocuments.push({
            answer: e.previousAnswer,
            copiedStrategyId: listPetitionerStrategy[0]?.id,
            formId: formId,
            previousAnswerId: e?.formAnswerId,
            questionId: e.id,
            sectionId: formId,
          })
        : '';
    });
    const payload = {
      benificieryId: null,
      dependentUserId: null,
      formType: 'lead',
      isCopied: true,
      leadFor: null,
      leadId: location.pathname.includes('lead-details')
        ? editId
        : location?.state?.data?.lead_id,
      list: selectedDocuments,
      petitionId: location.pathname.includes('lead-details') ? '' : petitionId,
      strategyId: location?.state?.data?.id,
    };

    addNewData(payload, `${String(Fill_FORM_ENDPOINT)}`)
      .then((resp: any) => {
        getStrategywizardFormData();
        setOragnizationQus((pre) => ({
          ...pre,
          open: false,
          questionList: pre.questionDetails?.map((e) => ({
            ...e,
            checked: false,
          })),
        }));
        getLeadInfo();
        toast.success('Copy successfully', alertOptions);
      })
      .catch((e) => {
        setLoader(false);
      });
  }, [
    editId,
    formId,
    getLeadInfo,
    getStrategywizardFormData,
    listPetitionerStrategy,
    location.pathname,
    location?.state?.data?.id,
    location?.state?.data?.lead_id,
    organizationQus?.questionList,
    petitionId,
  ]);
  useEffect(() => {
    if (!location?.state?.data?.benificiery_id && userDetail.role_id === 3) {
      getLeadInfo();
    }
    getStrategywizardFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWinningStrategies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winningParams]);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12}>
        {loader && <div className="liberty-loader" />}
        <Typography
          component={'div'}
          className="d-flex-a d-flex-sb  mb-2 sticky-tab">
          <Typography
            component={'div'}
            className="black-white-text f-22 font-bold text-overflow-hidden">
            {treeList[0]?.name.slice(0, 50)}
            {treeList[0]?.name.length > 50 && '...'}
          </Typography>
          <Typography component={'div'}>
            <Button
              className="cancel-btn mr-2 "
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '0.9em !important',
                width: '120px !important',
              }}
              onClick={() => navigate(-1)}>
              <Typography
                className="o-box d-flex-a"
                sx={{ width: 'unset !important' }}
              />
              <ArrowBackIcon sx={{ marginRight: '.5rem' }} />
              Back
            </Button>
            {!location.state?.isAffilateCase &&
              userDetail.role_id === 3 &&
              location?.state?.data?.benificiery_id && (
                <Button
                  className="n-add-btn"
                  onClick={() => {
                    setChooseStrategy(true);
                  }}>
                  Copy Strategy
                </Button>
              )}
            {!location.state?.isAffilateCase &&
              userDetail.role_id === 3 &&
              !location?.state?.data?.benificiery_id && (
                <Button
                  className="n-add-btn"
                  onClick={() => {
                    setOragnizationQus((pre) => ({
                      ...pre,
                      open: true,
                      questionList: pre?.questionList?.map((e) => ({
                        ...e,
                        checked: false,
                      })),
                    }));
                  }}>
                  Copy Strategy
                </Button>
              )}
          </Typography>
        </Typography>
        <Typography component={'div'}>
          <WizardForm
            focusElement={
              location.state?.focusElement ? location.state?.focusElement : null
            }
            ref={wizardRef}
            formType={
              location?.state?.data?.benificiery_id ? 'strategy' : 'lead'
            }
            list={treeList}
            tabHidden={true}
            formId={location?.state?.data?.formId}
            beneficiaryID={location?.state?.data?.benificiery_id}
            leadId={
              location?.state?.data?.benificiery_id
                ? ''
                : location?.state?.data?.lead_id
            }
            strategyId={location?.state?.data?.id}
            // firmId={petitions?.petitionData?.firm_id || ''}
            // organizationId={petitions?.petitionData?.organizationId || ''}
            petitionID={location?.state?.data?.petitionId || ''}
            showManageOption={true}
            setHCData={setHCData}
            copytoMaster={copytoMaster}
            copyEnable={
              !location.state?.isAffilateCase &&
              userDetail.role_id === 3 &&
              !location?.state?.data?.benificiery_id &&
              listPetitionerStrategy?.length
            }
          />
        </Typography>
      </Grid>
      <Grid item md={2} sm={12}>
        <Typography component={'div'} className="bulk-add-box">
          <Typography component={'div'} className="add-inner">
            <Typography component={'div'} className="add-inner mb-1">
              <Typography component={'div'} className="add-inner-box" />
              <Typography className="link-txt pt-2 f-16" />
              <Typography
                className=" built-option d-flex-a"
                // aria-describedby={winningStrategyId}
                onClick={handleClick}>
                <img
                  src={Win}
                  alt="im"
                  className="mr-1"
                  style={{ width: '20px !important' }}
                />
                Winning Strategies
              </Typography>
              <Popper
                id={winningStrategyId}
                className="own-popper"
                open={winningStrategyAnchor.open}
                anchorEl={winningStrategyAnchor.anchorEl}
                placement="left">
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box sx={{ padding: '1rem 1rem 0.5rem 1rem' }}>
                    <Typography
                      className="new-searc"
                      sx={{ marginBottom: '.5rem' }}>
                      <CustomInput
                        placeHolder={'Type to filter'}
                        className={'custom-search-input'}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={async () => {
                                  setWinningStrategies({
                                    data: [],
                                    total: 0,
                                  });
                                  await instantState(setWinningStrategies);
                                  setWinningParams((prev) => ({
                                    ...prev,
                                    search: '',
                                    skip: 0,
                                    take: 10,
                                  }));
                                }}>
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setWinningStrategies({
                            data: [],
                            total: 0,
                          });

                          setWinningParams((prev) => ({
                            ...prev,
                            search: e.target.value,
                            skip: 0,
                            take: 10,
                          }));
                        }}
                        value={winningParams.search}
                      />
                    </Typography>
                    {winningStrategies.data &&
                    winningStrategies.data.length > 0 ? (
                      <InfinityScroll
                        paramState={setWinningParams}
                        height={'350px'}
                        scrollableTargetId={'winningStrategy'}
                        data={winningStrategies.data}
                        rowProps={rowProps}
                        count={winningStrategies.total}
                      />
                    ) : (
                      <Typography
                        component={'div'}
                        className="pop-det-box"
                        sx={{ height: '350px' }}>
                        <Typography className="pop-dis">
                          No Record(s) Found.
                        </Typography>
                      </Typography>
                    )}
                    <div className="pop-dis" style={{ textAlign: 'center' }}>
                      {!_.isEmpty(winningStrategies.data)
                        ? winningStrategies.data.length
                        : 0}{' '}
                      of {winningStrategies.total} winning strategies
                    </div>
                  </Box>
                </ClickAwayListener>
              </Popper>
            </Typography>
            <Translate />
            <Typography component={'div'} style={{ top: '92px' }}>
              {hcData.selectedQuestion && (
                <HistoryComments
                  hcData={hcData}
                  setHCData={setHCData}
                  petitionID={
                    location.pathname.includes('lead-details')
                      ? ''
                      : location?.state?.data?.petitionId
                      ? location?.state?.data?.petitionId
                      : null
                  }
                  wizardRef={wizardRef}
                  tabName={location.state?.data?.for_petitioner ? '2' : '1'}
                  screenName={'strategy'}
                  type={
                    location.state?.showType === 'leads' ? 'lead' : 'petition'
                  }
                  formId={location.state?.data?.form_id}
                  leadId={
                    location.state?.showType === 'leads'
                      ? location?.state?.data?.lead_id
                      : null
                  }
                  beneficiaryId={
                    !location.state?.data?.for_petitioner
                      ? location.state?.data?.benificiery_id
                      : location.state?.data?.leadId
                  }
                  affilateUserList={
                    location.state?.showType === 'leads'
                      ? location.state?.isAffilateCase ||
                        userDetail.role_id === 3
                        ? location.state?.affilateUserList
                        : []
                      : petitions?.petitionData?.isAffilateCase ||
                        userDetail.role_id === 3
                      ? petitions?.petitionData?.affilateUserList
                      : []
                  }
                />
              )}
            </Typography>
          </Typography>
        </Typography>
      </Grid>

      {chooseStrategy && (
        <CustomModalView
          open={true}
          fullWidth={true}
          maxWidth={'xl'}
          scroll={'paper'}
          autoCloseDisable={true}
          isFilters={false}
          title="Strategy Session"
          close={() => setChooseStrategy(false)}>
          <StrategySessionList
            formId={location?.state?.data?.formId}
            onSelect={onSelectStrategy}
            loader={!strategyQus.open && questionLoader}
          />
        </CustomModalView>
      )}

      {strategyQus.open && (
        <CustomModalView
          open={true}
          fullWidth={true}
          maxWidth={'lg'}
          scroll={'paper'}
          autoCloseDisable={true}
          isFilters={false}
          title="Strategy Question List"
          close={() =>
            setStrategyQus((pre) => ({
              ...pre,
              open: false,
            }))
          }>
          <CopyStrategyQuestionList
            questionList={strategyQus.questionList}
            setQuestionList={setStrategyQus}
            onSubmit={onSubmitCopyStategyQus}
            questionLoader={questionLoader}
          />
        </CustomModalView>
      )}

      {organizationQus.open && (
        <CustomModalView
          open={true}
          fullWidth={true}
          maxWidth={'lg'}
          scroll={'paper'}
          autoCloseDisable={true}
          isFilters={false}
          title="Master Strategy Question List"
          close={() =>
            setOragnizationQus((pre) => ({
              ...pre,
              open: false,
            }))
          }>
          {loader && <div className="liberty-loader" />}
          <DialogContent>
            <table className="lead-table table-hover">
              <thead>
                <tr>
                  <th scope="col" style={{ width: '50%' }}>
                    {'Question'}
                  </th>
                  <th scope="col" style={{ width: '50%' }}>
                    {'Answer'}
                  </th>
                  <th scope="col">
                    Action
                    <Checkbox
                      size={'medium'}
                      checked={checkAll()}
                      onChange={(e) => selectAllDocument(e)}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {organizationQus && organizationQus?.questionList?.length ? (
                  organizationQus.questionList.map((data, index) => (
                    <tr key={index} style={{ cursor: 'pointer' }}>
                      <td>{data?.question}</td>
                      <td>
                        {[1, 2, 10].includes(data?.response_type)
                          ? data?.previousAnswer
                          : [7, 15].includes(data?.response_type)
                          ? moment(data?.previousAnswer).format('DD-MM-YYYY')
                          : [6].includes(data?.response_type)
                          ? (() => {
                              try {
                                const previousAnswer = JSON.parse(
                                  data?.previousAnswer,
                                )[0];
                                const status = previousAnswer.status
                                  ? 'Checked'
                                  : 'Unchecked';
                                return status;
                              } catch (error) {
                                console.log(error);
                                return null;
                              }
                            })()
                          : [13].includes(data?.response_type)
                          ? (() => {
                              try {
                                const previousAnswer = JSON.parse(
                                  data?.previousAnswer,
                                )[0];
                                return previousAnswer;
                              } catch (error) {
                                console.log(error);
                                return null;
                              }
                            })()
                          : data?.previousAnswer}
                      </td>
                      <td style={{ textTransform: 'capitalize' }}>
                        <Checkbox
                          size={'medium'}
                          checked={data.checked}
                          onChange={(e) =>
                            removeOrAddQues(data, e.target.checked)
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className={'text-center'}>
                    <th scope="row" colSpan={3}>
                      Master strategy was not created yet for this petitioner.
                      Please create master strategy{' '}
                      {/* <span
                        className="f-12 font-bold pointer"
                        style={{
                          color: 'blue',
                          textDecoration: 'underline',
                        }}
                        >
                        Click here
                      </span> */}
                    </th>
                  </tr>
                )}
              </tbody>
            </table>
          </DialogContent>
          {organizationQus?.questionList?.some((el) => el.checked) ? (
            <DialogActions>
              <Typography
                component={'div'}
                className="d-flex-jae"
                sx={{ padding: '0 1.5rem' }}>
                <Button
                  className="n-add-btn"
                  sx={{
                    borderRadius: '5px !important',
                    float: 'right',
                    fontFamily: 'Arial-Regular !important',
                    fontSize: '0.9em !important',
                    height: '35px !important',
                    margin: '15px',
                    width: '149px !important',
                  }}
                  onClick={() => answerSubmit()}>
                  submit
                </Button>
              </Typography>
            </DialogActions>
          ) : (
            ''
          )}
        </CustomModalView>
      )}
    </Grid>
  );
};

export default StrategyInfo;
