import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  dflexa: {
    alignItems: 'center',
    color: '#A4AFB7 !important',
    display: 'flex',
    fontSize: '14px !important',
  },
}));

export default useStyles;
