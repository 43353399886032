import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import { FileUploadDialog } from 'components/Fileupload/fileuploadDialog';
import { saveAs } from 'file-saver';
// import { instantState } from 'hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  getS3file,
  updateData,
} from 'services/CommonServices';
import { VisaTypeSection } from 'types';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { VisaTypeSchema } from 'utils/ValidatorSchema';

import SectionMapping from './SectionMapping';

export const VisaAdd: React.FC<any> = () => {
  const navigate: any = useNavigate();
  const { editId } = useParams();

  const activeStatusInput = useRef(null);
  const inActiveStatusInput = useRef(null);
  // const beneficiaryUploadRef = useRef<any>();
  // const petitionerUploadRef = useRef<any>();

  const [loader, setLoader] = useState(false);
  const [statusToggle, setStatusToggle] = useState(true);
  const [file, setFile] = useState<any>({
    ben: '',
    benFile: null,
    pen: '',
    penFile: null,
  });
  const [mappedSectionList, setMappedSectionList] = useState<any>([]);
  const [orderChangeList, setOrderChangeList] = useState<any>({
    beneficiaryList: [],
    dependentList: [],
    petitionerList: [],
    strategyList: [],
  });
  const [confirmOpen, setConfirmOpen] = useState<any>({
    id: null,
    index: null,
    show: false,
    type: '',
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileUploadOpenBen, setFileUploadOpenBen] = useState<any>({
    open: false,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileUploadOpenPen, setFileUploadOpenPen] = useState<any>({
    open: false,
  });
  const sectionTypes: any = [
    {
      id: 'India',
      name: 'India',
    },
    {
      id: 'USA',
      name: 'USA',
    },
    {
      id: 'UK',
      name: 'UK ',
    },
  ];

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    watch,
    formState: { errors },
  } = useForm<VisaTypeSection>({
    criteriaMode: 'all',
    defaultValues: {
      beneficiaryDescription: '',
      country: '',
      petitionerDescription: '',
      status: 'active',
      visaCode: '',
      visaDescription: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(VisaTypeSchema),
  });

  // const onFileChange = (file: FileList, type: string) => {
  //   if (file[0] && file[0].size) {
  //     let fileSize = 0;
  //     const fsize = file[0].size;
  //     fileSize = Math.round(fsize / 1024);
  //     if (fileSize > 92160) {
  //       toast.error(
  //         'File is too Big, Please select a file less than 90 MB',
  //         alertOptions,
  //       );
  //       return;
  //     }
  //     if (file[0] && file.length && type === 'ben') {
  //       setFile((pre) => ({
  //         ...pre,
  //         ben: file[0].name,
  //         benFile: file[0],
  //       }));
  //       // setValue('beneficiaryDescription', file[0]);
  //       beneficiaryUploadRef.current.value = '';
  //     }
  //     if (file[0] && file.length && type === 'pen') {
  //       setFile((pre) => ({
  //         ...pre,
  //         pen: file[0].name,
  //         penFile: file[0],
  //       }));
  //       // setValue('petitionerDescription', file[0]);
  //       petitionerUploadRef.current.value = '';
  //     }
  //   }
  // };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const openFile = (type: string) => {
    const file =
      type === 'pen'
        ? watch('petitionerDescription')
        : watch('beneficiaryDescription');
    const obj = {
      key: file,
    };

    const fileName = file.split('/').pop();
    setLoader(true);
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(
          `${String(fileName)} File downloaded successfully`,
          alertOptions,
        );
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err ? err : 'something Went Wrong', alertOptions);
      });
  };

  const cancelForm = () => {
    resetForm();
    navigate(-1);
  };

  const getInitialStatus = useCallback(async () => {
    setLoader(true);
    await getAllListData(
      `${String(SETUP_ENDPOINT.VisaType)}?id=${String(editId)}`,
    )
      .then((resp) => {
        const {
          visa_code,
          visa_description,
          country,
          status,
          beneficiary_steps,
          petitioner_steps,
          sectionDetails,
        } = resp.data[0];

        setMappedSectionList(sectionDetails);

        setOrderChangeList({
          beneficiaryList: sectionDetails
            .filter((val) => val.section_type === 'beneficiary')
            .sort((a, b) => a.order - b.order),
          dependentList: sectionDetails
            .filter((val) => val.section_type === 'dependent')
            .sort((a, b) => a.order - b.order),
          petitionerList: sectionDetails
            .filter((val) => val.section_type === 'petitioner')
            .sort((a, b) => a.order - b.order),
          strategyList: sectionDetails
            .filter((val) => val.section_type === 'strategy')
            .sort((a, b) => a.order - b.order),
        });

        setValue('visaCode', visa_code || '');
        setValue('visaDescription', visa_description || '');
        setValue('country', country || '');
        setValue('status', status || 'active');
        setValue('beneficiaryDescription', beneficiary_steps || null);

        setValue('petitionerDescription', petitioner_steps || null);
        setStatusToggle(status === 'active' ? true : false);

        if (!_.isEmpty(orderChangeList.beneficiaryList)) {
          submitOrder(orderChangeList.beneficiaryList, 'beneficiary');
        }

        if (!_.isEmpty(orderChangeList.petitionerList)) {
          submitOrder(orderChangeList.petitionerList, 'petitioner');
        }

        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderRewind = useCallback((list: any) => {
    if (!_.isEmpty(list)) {
      setOrderChangeList({
        beneficiaryList: list
          .filter((val) => val.section_type === 'beneficiary')
          .sort((a, b) => a.order - b.order),
        dependentList: list
          .filter((val) => val.section_type === 'dependent')
          .sort((a, b) => a.order - b.order),
        petitionerList: list
          .filter((val) => val.section_type === 'petitioner')
          .sort((a, b) => a.order - b.order),
        strategyList: list
          .filter((val) => val.section_type === 'strategy')
          .sort((a, b) => a.order - b.order),
      });
    } else {
      setOrderChangeList({
        beneficiaryList: [],
        dependentList: [],
        petitionerList: [],
        strategyList: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitOrder = useCallback(
    async (arr: Array<any>, type: string, toastrView?: string) => {
      if (arr.length) {
        const formValue = getReorderForm(arr, type);
        if (formValue.list && formValue.list.length) {
          await addNewData(
            formValue,
            `${SETUP_ENDPOINT.VisaType}/section-ordering`,
          )
            .then((resp: any) => {
              if (toastrView) {
                toast.success('Section updated', alertOptions);
              }
            })
            .catch((e) => {
              toast.error(
                'Unable to update the section. Please try again.',
                alertOptions,
              );
            });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getReorderForm = (arr: Array<any>, sectionType: string) => {
    const orderRechangeArr = arr.map((value, index) => {
      const ind = mappedSectionList.findIndex((val) => val.id === value.id);
      if (ind !== -1) {
        setMappedSectionList((prevState) => [
          ...prevState,
          (prevState[ind].order = Number(index) + 1),
        ]);
      }
      return {
        order: Number(index) + 1,
        sectionId: value.id,
        sectionType,
        visaId: Number(editId),
      };
    });
    return {
      list: orderRechangeArr,
    };
  };

  const onToggleStatus = () => setStatusToggle((b) => !b);

  const onSubmitForm = (data: any) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('visaCode', data['visaCode']);
    formData.append('visaDescription', data['visaDescription']);
    formData.append('country', data['country']);
    formData.append('status', statusToggle ? 'active' : 'inactive');
    formData.append('beneficiaryDescription', file.benFile ? file.benFile : '');
    formData.append('petitionerDescription', file.penFile ? file.penFile : '');

    if (!_.isEmpty(editId)) {
      updateData(editId, formData, SETUP_ENDPOINT.VisaType)
        .then((resp: any) => {
          // cancelForm();
          getInitialStatus();
          setFile({
            ben: '',
            benFile: null,
            pen: '',
            penFile: null,
          });
          toast.success('Updated successfully', alertOptions);
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
        });
    } else {
      addNewData(formData, SETUP_ENDPOINT.VisaType)
        .then((resp: any) => {
          // cancelForm();
          toast.success('Created successfully', alertOptions);
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };

  // useEffect(() => {
  //   if (!_.isEmpty(orderChangeList.beneficiaryList)) {
  //     submitOrder(orderChangeList.beneficiaryList, 'beneficiary');
  //   }

  //   if (!_.isEmpty(orderChangeList.petitionerList)) {
  //     submitOrder(orderChangeList.petitionerList, 'petitioner');
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [orderChangeList.beneficiaryList, orderChangeList.petitionerList]);

  useEffect(() => {
    if (!_.isUndefined(editId)) {
      getInitialStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const closeUploadDialogBen = useCallback((action: any) => {
    if (action) {
      setFile((pre) => ({
        ...pre,
        ben: action.name,
        benFile: action,
      }));
    } else {
      setFileUploadOpenBen((pre) => ({
        ...pre,
        open: false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const closeUploadDialogPen = useCallback((action: any) => {
    if (action) {
      setFile((pre) => ({
        ...pre,
        pen: action.name,
        penFile: action,
      }));
    } else {
      setFileUploadOpenPen((pre) => ({
        ...pre,
        open: false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <>
          <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
            <Typography
              component={'div'}
              className="d-flex-a d-flex-sb sticky-tab mb-2">
              <Typography
                component={'div'}
                className="w-title black-white-text">
                {_.isUndefined(editId) ? 'New' : 'Edit'} Visa Type
              </Typography>

              <Typography component={'div'} className="d-flex-ja">
                <Button className="n-cancel-btn mr-2" onClick={cancelForm}>
                  <Typography className="o-box" />
                  {_.isUndefined(editId) ? 'Cancel' : 'back'}
                </Button>
                <Button
                  className="n-add-btn"
                  type="submit"
                  form={'add-section'}>
                  {_.isUndefined(editId) ? 'Submit' : 'Update'}
                </Button>
              </Typography>
            </Typography>
            <form id="add-section" onSubmit={handleSubmit(onSubmitForm)}>
              <Typography
                component={'div'}
                className="addbox"
                sx={{ marginTop: 'unset !important' }}>
                <Box
                  component="form"
                  className="u-add-in"
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Visa Code <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="visaCode"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Visa Code'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.visaCode?.message}
                                onChange={onChange}
                                tabIndex={1}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Visa Description
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="visaDescription"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Visa Description'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.visaDescription?.message}
                                onChange={onChange}
                                isTextArea={true}
                                tabIndex={3}
                              />
                            );
                          }}
                        />
                      </Typography>
                      {/* <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Beneficiary Steps
                        </Typography>
                        <Typography component={'div'} className="d-flex-a">
                          <Button
                            className="add-btn"
                            sx={{
                              borderRadius: '5px !important',
                              fontFamily: 'Arial-Regular !important',
                              fontSize: '0.9em !important',
                              height: '35px !important',
                              textTransform: 'capitalize !important',
                              width: '118px !important',
                            }}
                            onClick={() => setFileUploadOpenBen({ open: true })}
                            tabIndex={6}>
                            Browse
                          </Button>
                          {fileUploadOpenBen.open && (
                            <FileUploadDialog
                              open={fileUploadOpenBen.open}
                              allowedFileType={'allFileType'}
                              info={
                                '(You can upload upto a maximum of 90MB per upload)'
                              }
                              onConfirm={closeUploadDialogBen}
                              setFileUploadOpen={setFileUploadOpenBen}
                            />
                          )}
                          <Typography
                            className="mt-1"
                            sx={{
                              color: '#333333',
                              opacity: '0.5',
                              paddingLeft: '.5rem',
                              wordBreak: 'break-all',
                            }}
                            {...(!_.isUndefined(editId)
                              ? {
                                  onClick: () => openFile('ben'),
                                }
                              : {})}>
                            {file?.ben !== ''
                              ? file?.ben
                              : watch('beneficiaryDescription')
                              ? watch('beneficiaryDescription')?.split('/')[1]
                              : 'No file is chosen'}
                          </Typography>
                        </Typography>
                      </Typography> */}
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Country <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="country"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'name'}
                                valueKey={'id'}
                                options={sectionTypes}
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.country?.message}
                                tabIndex={2}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Status
                        </Typography>
                        <div className="switch-field" tabIndex={4}>
                          <input
                            type="radio"
                            id="switch_left"
                            name="switchToggle"
                            value={'false'}
                            onChange={onToggleStatus}
                            checked={!statusToggle}
                            ref={inActiveStatusInput}
                          />
                          <label
                            htmlFor="switch_left d-flex-ja"
                            onClick={() => inActiveStatusInput.current.click()}>
                            Inactive
                          </label>

                          <input
                            type="radio"
                            id="switch_right"
                            name="switchToggle"
                            value={'true'}
                            onChange={onToggleStatus}
                            checked={statusToggle}
                            ref={activeStatusInput}
                          />
                          <label
                            htmlFor="switch_right d-flex-ja"
                            onClick={() => activeStatusInput.current.click()}>
                            Active
                          </label>
                        </div>
                      </Typography>
                      {/* <Typography
                        component={'div'}
                        className="custom-field"
                        style={{ paddingTop: ' 40px ' }}>
                        <Typography component={'div'} className="custom-label">
                          Petitioner Steps
                        </Typography>
                        <Typography component={'div'} className="d-flex-a">
                          <Button
                            className="add-btn"
                            sx={{
                              borderRadius: '5px !important',
                              fontFamily: 'Arial-Regular !important',
                              fontSize: '0.9em !important',
                              height: '35px !important',
                              textTransform: 'capitalize !important',
                              width: '118px !important',
                            }}
                            onClick={() => setFileUploadOpenPen({ open: true })}
                            tabIndex={5}>
                            Browse
                          </Button>
                          {fileUploadOpenPen.open && (
                            <FileUploadDialog
                              open={fileUploadOpenPen.open}
                              allowedFileType={'allFileType'}
                              info={
                                '(You can upload upto a maximum of 90MB per upload)'
                              }
                              onConfirm={closeUploadDialogPen}
                              setFileUploadOpen={setFileUploadOpenPen}
                            />
                          )}
                          <Typography
                            className="mt-1"
                            sx={{
                              color: '#333333',
                              opacity: '0.5',
                              paddingLeft: '.5rem',
                              wordBreak: 'break-all',
                            }}
                            {...(!_.isUndefined(editId)
                              ? {
                                  onClick: () => openFile('pen'),
                                }
                              : {})}>
                            {file.pen !== ''
                              ? file.pen
                              : watch('petitionerDescription')
                              ? watch('petitionerDescription')?.split('/')[1]
                              : 'No file is chosen'}
                          </Typography>
                        </Typography>
                      </Typography> */}
                    </Grid>
                  </Grid>
                </Box>
              </Typography>
            </form>
            {/* {_.isUndefined(editId) && (
              <Grid container>
                <Grid item md={12}>
                  <Typography component={'div'} className="d-flex-jae my-2">
                    <Button className="cancel-btn mr-2" type="button">
                      <Typography className="o-box" onClick={cancelForm} />
                      Cancel
                    </Button>
                    <Button
                      className="add-btn"
                      type="submit"
                      form={'add-section'}>
                      Submit
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            )} */}
          </Grid>
          {!_.isUndefined(editId) && (
            <SectionMapping
              sData={mappedSectionList}
              setConfirmOpen={setConfirmOpen}
              open={confirmOpen}
              editId={editId}
              cancelForm={cancelForm}
              setSectionData={setMappedSectionList}
              orderRewind={orderRewind}
              beneficiaryList={
                !_.isEmpty(orderChangeList.beneficiaryList)
                  ? orderChangeList.beneficiaryList
                  : []
              }
              petitionerList={
                !_.isEmpty(orderChangeList.petitionerList)
                  ? orderChangeList.petitionerList
                  : []
              }
              strategyList={
                !_.isEmpty(orderChangeList.strategyList)
                  ? orderChangeList.strategyList
                  : []
              }
              dependentList={
                !_.isEmpty(orderChangeList.dependentList)
                  ? orderChangeList.dependentList
                  : []
              }
              setOrderChangeList={setOrderChangeList}
              submitOrder={submitOrder}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default VisaAdd;
