import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomCheckBox from 'components/CustomCheckBox/customCheckBox';
import React, { useState } from 'react';
import { isJSON, jsonParseValue } from 'utils/helper';

type Props = {
  questionList: Array<any>;
  setQuestionList: (src: any) => any;
  onSubmit: (src: any) => void;
  questionLoader: boolean;
};
export const CopyStrategyQuestionList: React.FC<Props> = (props: Props) => {
  const { questionList, setQuestionList, onSubmit, questionLoader } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);

  const strategyCopy = () => {
    setConfirmOpen(false);
    onSubmit &&
      onSubmit(
        questionList && questionList.length
          ? questionList.filter((e) => e.status)
          : [],
      );
  };
  return (
    <>
      <DialogContent>
        {questionLoader && <div className="liberty-loader" />}
        <table className="lead-table table-hover">
          <thead>
            <tr>
              <th scope="col" style={{ width: '40%' }}>
                QUESTION
              </th>
              <th scope="col" style={{ width: '40%' }}>
                ANSWER
              </th>
              <th scope="col">
                ACTION{' '}
                <CustomCheckBox
                  checked={questionList.every((qus) => qus.status)}
                  onChange={(event) => {
                    setQuestionList &&
                      setQuestionList((pre) => ({
                        ...pre,
                        questionList: questionList.map((e: any) => {
                          return { ...e, status: event.target.checked };
                        }),
                      }));
                  }}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {questionList && questionList.length > 0 ? (
              questionList.map((data, index) => (
                <tr key={index} style={{ cursor: 'pointer' }}>
                  <td>{data?.question}</td>
                  <td>
                    {isJSON(data?.answer)
                      ? jsonParseValue(data?.answer)
                      : data?.answer || '-'}
                  </td>
                  <td>
                    {' '}
                    <CustomCheckBox
                      checked={data?.status}
                      onChange={(event) => {
                        setQuestionList &&
                          setQuestionList((pre) => ({
                            ...pre,
                            questionList: questionList.map(
                              (e: any, i: number) => {
                                return {
                                  ...e,
                                  ...(i === index
                                    ? { status: event.target.checked }
                                    : {}),
                                };
                              },
                            ),
                          }));
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className={'text-center'}>
                <th scope="row" colSpan={2}>
                  No Record(s) Found.
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
      {questionList.some((e: any) => e.status) && (
        <DialogActions>
          <Typography component={'div'} className="d-flex-jae mt-1 ">
            <Button
              className="n-add-btn"
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '0.9em !important',
                height: '35px !important',
                width: '149px !important',
              }}
              onClick={() => setConfirmOpen(true)}>
              {'Copy Strategy'}
            </Button>
          </Typography>
        </DialogActions>
      )}

      <ConfirmDialog
        title={'Copy Startegy Confirmation'}
        floatingBtnShow={true}
        open={confirmOpen}
        onConfirm={(act) =>
          act === 'yes' ? strategyCopy() : setConfirmOpen(false)
        }
        autoCloseDisable={true}>
        {
          'Copying the information will overwrite all the details in the current strategy session with details from the strategy session you have selected. Do you wish to proceed'
        }
      </ConfirmDialog>
    </>
  );
};
