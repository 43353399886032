import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
// import { useParams } from 'react-router-dom';
// import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';

import ContractList from '../../Common/ContractList/index';

export const Contractpayment: React.FC<any> = () => {
  const { petitionId } = useParams();
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const [loader, setLoader] = useState(true);
  const [leadContractPerson, setLeadContractPerson] = useState<any>([]);

  useEffect(() => {
    if (!_.isUndefined(petitionId) && !_.isEmpty(petitions)) {
      getAllListData(
        `${COMMON_ENDPOINT.Contract}/${String(
          petitions?.petitionData?.lead_id,
        )}`,
      )
        .then((contractResp: any) => {
          setLeadContractPerson(
            contractResp.filter(
              (val) =>
                val.contactType === 'petitioner' ||
                val.user_id === petitions.petitionData?.beneficiary_id,
            ),
          );
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitions]);

  return (
    <>
      {!_.isEmpty(petitions) && petitions?.petitionData ? (
        <Typography
          component={'div'}
          className="black-white-text"
          sx={{
            fontFamily: 'Arial-Bold !important',
            fontSize: '18px !important',
            marginBottom: '16px',
            marginLeft: '15px',
          }}>
          {petitions?.petitionData?.visa_type} {'for'}{' '}
          {petitions?.petitionData?.benificiery_name}
        </Typography>
      ) : (
        ''
      )}
      <Grid container columnSpacing={{ md: 2, sm: 1 }}>
        <Grid item md={10} sm={12}>
          {loader ? (
            <div className="liberty-loader" />
          ) : (
            <Typography>
              <ContractList
                contractList={leadContractPerson}
                isLead={false}
                loader={loader}
                setLoader={setLoader}
                editId={petitionId}
              />
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Contractpayment;
