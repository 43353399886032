import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import CustomDatePicker from 'components/CustomDatePicker/customDatePicker';
import CustomSelect from 'components/CustomSelect/customSelect';
import PendingInvites from 'components/PendingInvites';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { DashboardFilterTypes } from 'types';
import { defaultPeriod } from 'utils/Config';
import { SETUP_ENDPOINT } from 'utils/Constant';
import { validateDate } from 'utils/helper';
import { DashboardFilterSchema } from 'utils/ValidatorSchema';

import Cases from '../ContractAndCases/Cases';
import ContractPayments from '../ContractAndCases/ContractPayments';
import LeadsAndKPIs from '../LeadsAndKPIs';
// import { CommentsWidgets } from './CommentsWidgets';
import { CommentsTable } from './CommentsTable';
import DocumentReview from './DocumentReview';
import PendingReviews from './PendingReviews';

const today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0');
const yyyy = today.getFullYear();
const month = [
  {
    fromDate: `${yyyy}-10-01`,
    id: 1,
    toDate: `${yyyy}-12-31`,
  },
  {
    fromDate: `${yyyy}-01-01`,
    id: 2,
    toDate: `${yyyy}-03-31`,
  },
  {
    fromDate: `${yyyy}-04-01`,
    id: 3,
    toDate: `${yyyy}-06-30`,
  },
  {
    fromDate: `${yyyy}-07-01`,
    id: 4,
    toDate: `${yyyy}-09-30`,
  },
];

const defaultPeriodCurrentYear = defaultPeriod.find(
  (name) => name.value === 'Current_Year',
);

const AttorneyDashboard: React.FC<any> = ({
  counts,
  dashboardKey,
  setDashboardKey,
  attorneyName,
  setAttorneyName,
  contract,
  reportFilterKey,
  setReportFilterKey,
  cases,
  caseKey,
  setCasesKey,
  filterOnChangeResetState,
  pendingReviews,
  pendingReviewParams,
  setPendingReviewParams,
  documentReviews,
  documentReviewParams,
  setDocumentReviewParams,
  commentsWidget,
  commentsParams,
  setCommentsParams,
}) => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [periodValue, setPeriodValue] = useState({
    disabled: true,
    label: defaultPeriodCurrentYear?.label,
    value: defaultPeriodCurrentYear?.value,
  });

  const [selectedDates, setSelectedDates] = useState({
    fromDate: '',
    toDate: '',
  });

  const [attorneyList, setAttorneyList] = useState([]);
  const [petitionerList, setPetitionerList] = useState([]);
  const [invitesLoader, setInvitesLoader] = useState(true);

  const [sortingData, setSortingData] = useState({
    attorneyid: '',
    from: '',
    name: '',
    period: '',
    show: false,
    to: '',
    type: '',
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
    // getValues,
  } = useForm<DashboardFilterTypes>({
    criteriaMode: 'all',
    defaultValues: {
      attorneyId: '',
      fromDate: '',
      period: '',
      toDate: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(DashboardFilterSchema),
  });

  const onSubmitForm = (data: any) => {
    if (!validateDate(moment(data.fromDate).format('MM/DD/YYYY'))) {
      setError('fromDate', {
        message: 'Not a valid date format.',
        type: 'custom',
      });
    } else if (!validateDate(moment(data.toDate).format('MM/DD/YYYY'))) {
      setError('toDate', {
        message: 'Not a valid date format.',
        type: 'custom',
      });
    } else {
      getAttorneyId(data.attorneyId ? data.attorneyId : '');
      onChangePeriod(data.period);
      setDashboardKey({
        attorneyId: data.attorneyId,
        fromDate: data.fromDate
          ? moment(data.fromDate).format('YYYY-MM-DD')
          : '',
        toDate: data.toDate ? moment(data.toDate).format('YYYY-MM-DD') : '',
      });

      if (userDetail.role_id === 3) {
        setReportFilterKey((pre) => ({
          ...pre,
          createdby: data.attorneyId,
          fromDate: data.fromDate
            ? moment(data.fromDate).format('YYYY-MM-DD')
            : '',
          skip: 0,
          take: 50,
          toDate: data.toDate ? moment(data.toDate).format('YYYY-MM-DD') : '',
        }));
      }
      const selectedPeriodData = defaultPeriod.find(
        (period) => period.value === data.period,
      );
      setPeriodValue((pre) => ({
        ...pre,
        label: selectedPeriodData?.label,
        value: selectedPeriodData?.value,
      }));

      setCasesKey((pre) => ({
        ...pre,
        attorneyId: data.attorneyId,
        fromDate: data.fromDate
          ? moment(data.fromDate).format('YYYY-MM-DD')
          : '',
        skip: 0,
        take: 50,
        toDate: data.toDate ? moment(data.toDate).format('YYYY-MM-DD') : '',
      }));
      filterOnChangeResetState && filterOnChangeResetState();
      handleClose();
    }
  };

  const handleOpen = () => {
    setSortingData({
      attorneyid: dashboardKey.attorneyId,
      from: dashboardKey.fromDate,
      name: attorneyName,
      period: periodValue.value,
      show: true,
      to: dashboardKey.toDate,
      type:
        userDetail.role_id === 3
          ? 'attorney'
          : userDetail.role_id === 2
          ? 'petitioner'
          : '',
    });

    setValue('attorneyId', dashboardKey.attorneyId);
    setValue('period', periodValue.value ? periodValue.value : null);

    getAttorneyList();
    onChangePeriod(periodValue.value);
    userDetail.role_id === 2 && getPetitionerList();
  };
  const handleClose = () => {
    setSortingData((pre) => ({ ...pre, show: false }));
    // reset();
  };

  const getAttorneyList = () => {
    getAllListData(
      `${SETUP_ENDPOINT.SelectMenu}/lawfirm/${String(userDetail.companyId)}`,
    ).then((resp: any) => {
      setAttorneyList(resp);
    });
  };

  const getPetitionerList = () => {
    getAllListData(`${SETUP_ENDPOINT.SelectMenu}/lawfirm`).then((resp: any) => {
      setPetitionerList(resp);
    });
  };

  const onChangePeriod = (e) => {
    // const selectedPeriodData = defaultPeriod.find(
    //   (period) => period.value === e,
    // );
    // setPeriodValue((pre) => ({
    //   ...pre,
    //   label: selectedPeriodData?.label,
    //   value: selectedPeriodData?.value,
    // }));
    const d = new Date();
    let m = Math.floor(d.getMonth() / 3) + 2;
    m = m > 4 ? m - 4 : m;
    if (e === 'custom') {
      setPeriodValue((pre) => ({ ...pre, disabled: false }));
      setSelectedDates({
        fromDate: moment().format('yyyy-MM-DD'),
        toDate: moment().format('yyyy-MM-DD'),
      });
      setValue('fromDate', moment().format('yyyy-MM-DD'));
      setValue('toDate', moment().format('yyyy-MM-DD'));
    } else {
      setPeriodValue((pre) => ({ ...pre, disabled: true }));
      setSelectedDates({
        fromDate: '',
        toDate: '',
      });
      setValue('fromDate', '');
      setValue('toDate', '');
    }
    if (e === 'Today') {
      const from = `${yyyy}-${mm}-${dd}`;
      const to = `${yyyy}-${mm}-${dd}`;
      setSelectedDates({
        fromDate: moment(from).format('yyyy-MM-DD'),
        toDate: moment(to).format('yyyy-MM-DD'),
      });

      setValue('fromDate', from);
      setValue('toDate', to);
    } else if (e === 'Current_Week') {
      const thisSunday = moment().startOf('week').format('MM/DD/YYYY');
      const thisSaturday = moment().endOf('week').format('MM/DD/YYYY');

      const from = moment(thisSunday).format('yyyy-MM-DD');
      const to = moment(thisSaturday).format('yyyy-MM-DD');

      setSelectedDates({
        fromDate: moment(from).format('yyyy-MM-DD'),
        toDate: moment(to).format('yyyy-MM-DD'),
      });

      setValue('fromDate', from);
      setValue('toDate', to);
    } else if (e === 'Previous_Week') {
      const lastSunday = moment()
        .subtract(1, 'weeks')
        .startOf('week')
        .format('MM/DD/YYYY');
      const lastSaturday = moment()
        .subtract(1, 'weeks')
        .endOf('week')
        .format('MM/DD/YYYY');
      const from = moment(lastSunday).format('yyyy-MM-DD');
      const to = moment(lastSaturday).format('yyyy-MM-DD');
      setSelectedDates({
        fromDate: moment(from).format('yyyy-MM-DD'),
        toDate: moment(to).format('yyyy-MM-DD'),
      });

      setValue('fromDate', from);
      setValue('toDate', to);
    } else if (e === 'Current_Month') {
      const from = `${yyyy}-${mm}-01`;
      const to = `${yyyy}-${mm}-${dd}`;
      setSelectedDates({
        fromDate: moment(from).format('yyyy-MM-DD'),
        toDate: moment(to).format('yyyy-MM-DD'),
      });

      setValue('fromDate', from);
      setValue('toDate', to);
    } else if (e === 'Previous_Month') {
      const from = moment()
        .subtract(1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
      const to = moment()
        .subtract(1, 'months')
        .endOf('month')
        .format('YYYY-MM-DD');

      setSelectedDates({
        fromDate: moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('MM-DD-YYYY'),
        toDate: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('MM-DD-YYYY'),
      });

      setValue('fromDate', from);
      setValue('toDate', to);
    } else if (e === 'Current_Quarter') {
      month.find((list) => {
        setSelectedDates({
          fromDate: moment(list.fromDate).format('yyyy-MM-DD'),
          toDate: moment(list.toDate).format('yyyy-MM-DD'),
        });

        setValue('fromDate', list.fromDate);
        setValue('toDate', list.toDate);
        return list.id === m;
      });
    } else if (e === 'Previous_Quarter') {
      const previous = moment().subtract(1, 'quarter');
      const previousStartOf = moment(previous)
        .startOf('quarter')
        .format('YYYY-MM-DD');
      const previousEndOf = moment(previous)
        .endOf('quarter')
        .format('YYYY-MM-DD');

      setSelectedDates({
        fromDate: moment(previous).startOf('quarter').format('MM-DD-YYYY'),
        toDate: moment(previous).endOf('quarter').format('MM-DD-YYYY'),
      });

      setValue('fromDate', previousStartOf);
      setValue('toDate', previousEndOf);
    } else if (e === 'Current_Year') {
      const from = `${today.getFullYear()}-01-01`;
      const to = `${today.getFullYear()}-12-31`;
      setSelectedDates({
        fromDate: moment(from).format('yyyy-MM-DD'),
        toDate: moment(to).format('yyyy-MM-DD'),
      });

      setValue('fromDate', from);
      setValue('toDate', to);
    } else if (e === 'all') {
      setSelectedDates({
        fromDate: '',
        toDate: '',
      });
      setValue('fromDate', '');
      setValue('toDate', '');
    }
  };

  const getAttorneyId = (value) => {
    if (sortingData.type === 'attorney') {
      const attorney = attorneyList.find((name) => name.id === Number(value));
      setAttorneyName(attorney.full_name);
    }
    if (sortingData.type === 'petitioner') {
      const petitioner = petitionerList.find(
        (name) => name.id === Number(value),
      );
      setAttorneyName(petitioner?.firm_name);
    }
  };
  return (
    <Typography component={'div'}>
      <Grid container>
        <Grid item md={12}>
          {[2, 3].includes(userDetail.role_id) && (
            <Typography
              component={'div'}
              className="d-flex-jae "
              sx={{
                // marginTop: '50px',
                paddingRight: '1rem',
              }}>
              <Typography
                component={'div'}
                className="d-flex-a black-white-text"
                onClick={handleOpen}
                sx={{
                  backgroundColor: '#84898c',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  height: '36px',
                  marginBottom: '23px',
                  minWidth: '208px',
                  padding: '.5rem 1rem',
                }}>
                {attorneyName ? attorneyName.concat(', ') : ''}{' '}
                {periodValue.label}
              </Typography>
              <Modal
                open={sortingData.show}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className="filter-modal">
                  <form
                    style={{
                      backgroundColor: '#ffffff',
                      marginBottom: '.5rem',
                      padding: '32px',
                    }}
                    id="dashboard-filter"
                    onSubmit={handleSubmit(onSubmitForm)}>
                    <Grid container>
                      <Grid item md={12} sm={12}>
                        <Typography component={'div'} className="custom-field">
                          <Typography className="custom-label">
                            {sortingData.type === 'attorney'
                              ? 'Attorney'
                              : 'Law Firm'}{' '}
                            {''}
                            {userDetail.role_id === 3 && (
                              <Typography
                                component={'span'}
                                className="Mui-error">
                                *
                              </Typography>
                            )}
                          </Typography>
                          {sortingData.type === 'attorney' && (
                            <Controller
                              control={control}
                              name={'attorneyId'}
                              defaultValue={''}
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomSelect
                                    isClearable={false}
                                    valueKey="id"
                                    labelKey="full_name"
                                    value={value}
                                    options={
                                      !_.isEmpty(attorneyList)
                                        ? attorneyList
                                        : []
                                    }
                                    name={name}
                                    placeHolder={'Select'}
                                    className={'custom-input'}
                                    onChange={(e) => {
                                      // if (e.target.value.length <= 1) {
                                      //   return false;
                                      // } else {
                                      onChange(e);
                                      // getAttorneyId(e.target.value);
                                      // }
                                    }}
                                    error={errors.attorneyId?.message}
                                    searchable={true}
                                    // isClearable={true}
                                  />
                                );
                              }}
                            />
                          )}
                          {sortingData.type === 'petitioner' && (
                            <Controller
                              control={control}
                              name={'attorneyId'}
                              defaultValue={''}
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomSelect
                                    isClearable={false}
                                    valueKey="id"
                                    labelKey="firm_name"
                                    value={value}
                                    options={
                                      !_.isEmpty(petitionerList)
                                        ? petitionerList
                                        : []
                                    }
                                    name={name}
                                    placeHolder={'Select'}
                                    className={'custom-input'}
                                    onChange={(e) => {
                                      onChange(e);
                                      // getAttorneyId(e.target.value);
                                    }}
                                    error={errors.attorneyId?.message}
                                    searchable={true}
                                  />
                                );
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12}>
                        <Typography component={'div'} className="custom-field">
                          <Typography className="custom-label">
                            Period{' '}
                            <Typography
                              component={'span'}
                              className="Mui-error">
                              *
                            </Typography>
                          </Typography>
                          <Controller
                            control={control}
                            name={'period'}
                            defaultValue={''}
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomSelect
                                  isClearable={false}
                                  valueKey="value"
                                  labelKey="label"
                                  value={value}
                                  options={defaultPeriod}
                                  name={name}
                                  placeHolder={'Select'}
                                  className={'custom-input'}
                                  onChange={(e) => {
                                    onChange(e);
                                    onChangePeriod(e.target.value);
                                  }}
                                  error={errors.period?.message}
                                  searchable={true}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12}>
                        <Typography component={'div'} className="custom-field">
                          <Grid container>
                            <Grid item md={5} sm={5}>
                              <Typography className="custom-label">
                                From Date{' '}
                                <Typography
                                  component={'span'}
                                  className="Mui-error">
                                  *
                                </Typography>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=""
                                name="fromDate"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomDatePicker
                                      isEditable={periodValue.disabled}
                                      placeHolder={selectedDates.fromDate}
                                      className="custom-input"
                                      errors={errors.fromDate?.message}
                                      name={name}
                                      value={value}
                                      isReadOnlyInput={true}
                                      onChangeDate={(e) => {
                                        // const frmDat = new Date(e);
                                        // const from = `${frmDat.getFullYear()}-${
                                        //   frmDat.getMonth() + 1
                                        // }-${frmDat.getDate()}`;
                                        onChange(e);
                                      }}
                                      isUpdatefeildpararms={true}
                                    />
                                  );
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={2}
                              sm={2}
                              className="d-flex-ja"
                              sx={{ paddingTop: '26px' }}>
                              to
                            </Grid>
                            <Grid item md={5} sm={5}>
                              <Typography className="custom-label">
                                To Date{' '}
                                <Typography
                                  component={'span'}
                                  className="Mui-error">
                                  *
                                </Typography>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=""
                                name="toDate"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomDatePicker
                                      isEditable={periodValue.disabled}
                                      placeHolder={selectedDates.toDate}
                                      className="custom-input"
                                      errors={errors.toDate?.message}
                                      name={name}
                                      isReadOnlyInput={true}
                                      value={value}
                                      onChangeDate={(e) => {
                                        // const toDat = new Date(e);
                                        // const to = `${toDat.getFullYear()}-${
                                        //   toDat.getMonth() + 1
                                        // }-${toDat.getDate()}`;
                                        onChange(e);
                                      }}
                                      isUpdatefeildpararms={true}
                                    />
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography
                      component={'div'}
                      sx={{ margin: '2rem 0 0 0' }}
                      className="d-flex-jae modal-footer">
                      <Button
                        className="mcancel-btn f-18 font-bold mr-1"
                        onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button
                        className="n-add-btn f-18 font-bold"
                        type="submit"
                        form="dashboard-filter">
                        Apply
                      </Button>
                    </Typography>
                  </form>
                </Box>
              </Modal>
            </Typography>
          )}
          <Typography component={'div'}>
            {[2, 3].includes(userDetail.role_id) && (
              <LeadsAndKPIs dashboardCounts={counts} />
            )}

            {[2, 3].includes(userDetail.role_id) && (
              <Grid container md={12} sm={12}>
                {commentsWidget?.data?.length > 0 ? (
                  <Grid md={12} sm={12}>
                    <CommentsTable
                      commentsReview={commentsWidget}
                      commentsParams={commentsParams}
                      setCommentsParams={setCommentsParams}
                    />
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            )}
            {userDetail.role_id === 3 && (
              <Typography component={'div'} className="mt-3">
                <ContractPayments
                  dashboardCounts={counts}
                  contract={contract}
                  reportFilterKey={reportFilterKey}
                  setReportFilterKey={setReportFilterKey}
                />
              </Typography>
            )}
            {userDetail.role_id === 2 && (
              <PendingInvites
                loader={invitesLoader}
                setLoader={setInvitesLoader}
                isDashboard={true}
              />
            )}
            {[2, 3].includes(userDetail.role_id) && (
              <Cases
                cases={cases}
                caseKey={caseKey}
                setCasesKey={setCasesKey}
              />
            )}

            {/* Review documents */}
            {[2, 3].includes(userDetail.role_id) && (
              <Grid container md={12} sm={12}>
                <Grid md={userDetail.role_id === 3 ? 6 : 12} sm={12}>
                  <Typography component={'div'} className="mt-3">
                    <PendingReviews
                      reviews={pendingReviews}
                      reviewParams={pendingReviewParams}
                      setReviewParams={setPendingReviewParams}
                    />
                  </Typography>
                </Grid>
                {userDetail.role_id === 3 && (
                  <Grid md={6} sm={12}>
                    <Typography component={'div'} className="mt-3">
                      <DocumentReview
                        docReviews={documentReviews}
                        docReviewParams={documentReviewParams}
                        setDocReviewParams={setDocumentReviewParams}
                      />
                    </Typography>
                  </Grid>
                )}
                {/* <Grid md={12} sm={12}>
                  <Typography component={'div'} className="mt-3">
                    <CommentsWidgets
                      commentsReview={commentsWidget}
                      commentsParams={commentsParams}
                      setCommentsParams={setCommentsParams} 
                    />
                  </Typography>
                </Grid> */}
              </Grid>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};

export default React.memo(AttorneyDashboard);
