import {
  Box,
  Button,
  InputAdornment,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import React, { useCallback, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { alertOptions, mimeTypes, parsingDocMimeTypes } from 'utils/helper';

// import { Controller } from 'react-hook-form';
import UploadIcon from '../../assets/images/clupload.svg';
import DeleteIcon from '../../assets/images/udelete.svg';

type Props = {
  open: boolean;
  onConfirm: (src: any) => void;
  isParsingDoc?: boolean;
  setCopyFile?: (src: any) => void;
  setFileUploadOpen?: any;
  allowedFileType?: any;
  errorMessage?: string;
  info?: any;
  title?: string;
  loader?: boolean;
};
export const FileUploadDialog: React.FC<Props> = (props: Props) => {
  const {
    open,
    onConfirm,
    isParsingDoc,
    setCopyFile,
    setFileUploadOpen,
    allowedFileType,
    errorMessage,
    info,
    title,
    loader,
  } = props;

  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState<any>(null);

  const onDrop = useCallback((acceptedFiles) => {
    onFileChange(acceptedFiles);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);

  const onFileChange = (file: FileList) => {
    if (file[0] && file[0].size) {
      if (isParsingDoc) {
        const unwantedFileTypeCheckSkillParsing = !allowedFileType.includes(
          file[0].type,
        );
        if (unwantedFileTypeCheckSkillParsing) {
          toast.error(errorMessage, alertOptions);
          return;
        }
      }
      if (allowedFileType === 'allFileType') {
        setFile(file[0]);
        setCopyFile && setCopyFile(file[0]);
        hiddenFileInput.current.value = '';
        return;
      }
      if (allowedFileType?.includes(file[0].type)) {
        let fileSize = 0;
        const fsize = file[0].size;
        fileSize = Math.round(fsize / 1024);
        if (fileSize > 92160) {
          toast.error(
            'File is too big, Please select a file less than 90 MB',
            alertOptions,
          );
          return;
        }
      } else {
        toast.error(
          errorMessage ? errorMessage : 'Something went wrong',
          alertOptions,
        );
        return;
      }
    }
    setFile(file[0]);
    setCopyFile && setCopyFile(file[0]);
    hiddenFileInput.current.value = '';
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        onConfirm('');
        hiddenFileInput.current.value = '';
        setFile(null);
      }}>
      <Box className="custom-file-modal">
        {loader && <div className="liberty-loader" />}
        <Typography
          component={'div'}
          className="f-20 font-bold modal-title"
          sx={{ borderBottom: '1px solid #cccccc', padding: '1rem 1.5rem' }}>
          File Upload
        </Typography>
        <Typography className="mt-2" sx={{ padding: '.5rem 1.5rem' }}>
          <Typography component={'span'} className="font-bold">
            {title ? title : 'Upload Document '}
          </Typography>
          <Typography
            component={'span'}
            sx={{ fontSize: '.75em !important', fontStyle: 'italic' }}>
            {info}
          </Typography>
        </Typography>
        <Typography
          component={'div'}
          className="upload-drag-drop d-flex-ja pointer"
          style={{ marginLeft: '25px' }}
          // onClick={(e) => {
          //   e.preventDefault();
          //   hiddenFileInput.current?.click();
          // }}
        >
          <Dropzone onDrop={onDrop}>
            {({ getRootProps }) => {
              return (
                <>
                  <img src={UploadIcon} alt="icon" />
                  <Typography
                    {...getRootProps({
                      className: 'dropzone',
                      onClick: (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        hiddenFileInput.current?.click();
                      },
                    })}
                  />
                </>
              );
            }}
          </Dropzone>
          <Typography className="f-16">
            Drag and drop file here, or click to select file
          </Typography>
        </Typography>

        <input
          type="file"
          hidden
          ref={hiddenFileInput}
          onChange={(e) => onFileChange(e.target.files)}
          accept={isParsingDoc ? parsingDocMimeTypes : mimeTypes}
        />
        <Typography sx={{ padding: '.5rem 1.5rem' }}>
          <form className="d-flex-a">
            <Typography
              component={'div'}
              className="custom-field mb-0  d-flex-1"
              sx={{ marginBottom: 'unset !important' }}>
              <CustomInput
                className="custom-input"
                disabled={true}
                value={file && file.name}
                inputProps={{
                  endAdornment: file && (
                    <InputAdornment position="end">
                      <Tooltip title={'Remove'} placement={'top'}>
                        <img
                          src={DeleteIcon}
                          alt="deleteIcon"
                          style={{ cursor: 'pointer', width: '28px' }}
                          onClick={() => {
                            hiddenFileInput.current.value = '';
                            setFile('');
                          }}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Typography>
          </form>
        </Typography>
        <Typography component={'div'} className="d-flex-jae modal-footer">
          <Button
            className="mcancel-btn f-18 font-bold mr-1"
            onClick={() => {
              onConfirm(''),
                ((hiddenFileInput.current.value = ''), setFile(null));
            }}>
            Cancel
          </Button>

          <Button
            className="madd-btn f-18 font-bold"
            disabled={file && file.name ? false : true}
            onClick={() => {
              if (file && file.name) {
                onConfirm(file);
              }
              setFileUploadOpen &&
                setFileUploadOpen((pre) => ({
                  ...pre,
                  open: false,
                }));
            }}>
            Upload
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
};
