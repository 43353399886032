import { Checkbox, Typography } from '@mui/material';
import React, { CSSProperties, ReactElement } from 'react';

import useStyles from './style';

type CheckboxProps = {
  name?: string;
  color?:
    | 'success'
    | 'info'
    | 'warning'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error';
  size?: 'small' | 'medium';
  extraStyle?: CSSProperties;
  checked?: boolean;
  onChange?: (src: any) => void;
  value?: any;
  disabled?: boolean;
  onFocus?: (src: any) => void;
  id?: any;
};

const CustomCheckBox = (props: CheckboxProps): ReactElement => {
  const {
    name,
    color,
    size,
    extraStyle,
    checked,
    onChange,
    value,
    disabled,
    onFocus,
    id,
  } = props;
  const classes = useStyles();
  return (
    <Typography className={classes.dflexa} style={extraStyle}>
      <Checkbox
        id={id}
        disabled={disabled ? disabled : false}
        color={color ? color : 'success'}
        size={size ? size : 'medium'}
        checked={checked}
        onChange={onChange}
        value={value}
        onFocus={onFocus && onFocus}
      />
      {name}
    </Typography>
  );
};

export default CustomCheckBox;
