import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, DialogActions, DialogContent } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import profileimagelogo from 'assets/images/d-avator.svg';
import pencil from 'assets/images/pencil.svg';
import pencilImg from 'assets/images/pencil-img.svg';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomCheckBox from 'components/CustomCheckBox/customCheckBox';
import CustomDatePicker from 'components/CustomDatePicker/customDatePicker';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
// import CustomMultiSelect from 'components/CustomMultiSelect/customMultiSelect';
import CustomSelect from 'components/CustomSelect/customSelect';
import { FileUploadDialog } from 'components/Fileupload/fileuploadDialog';
import { Loader } from 'components/loader';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
// import Multiselect from 'multiselect-react-dropdown';
import React, {
  useCallback,
  useEffect,
  // useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { MyOrganizationtype } from 'types/index';
import { fileApiUrl } from 'utils/Config';
import {
  COMMON_ENDPOINT,
  MASTER_ENDPOINT,
  SETUP_ENDPOINT,
} from 'utils/Constant';
import { alertOptions, profileFileType, validateDate } from 'utils/helper';
import { MyOrganizationschema } from 'utils/ValidatorSchema';

export const MyOrganization: React.FC<any> = () => {
  const navigate: any = useNavigate();
  const location = useLocation();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [loader, setLoader] = useState(false);
  // const uploadRef = useRef<any>();
  const [profileImage, setProfileImage] = useState<any>({
    imageURL: 'assets/images/d-avator.svg',
    selectedImage: null,
  });
  const [contactPerson, setContactPerson] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [select, setSelect] = useState<any>(false);
  const [fileUploadOpen, setFileUploadOpen] = useState<any>({
    open: false,
  });
  const [confirmDialog, setConfirmDialog] = useState({
    show: false,
  });
  const [params, setParams] = React.useState({
    associated: true,
    name: '',
    skip: 0,
    take: 50,
  });
  const debouncedParams = useDebounce(params, 500);
  const [dialogView, setDialogView] = React.useState<boolean>(false);
  const [list, setList] = React.useState([]);
  const [selectPetitionId, setSelectPetitionId] = React.useState([]);
  // const useEditDetail: any = useAppSelector((state) => state.firm.edit);
  // For Testing purpose
  const useEditDetail: any =
    location.pathname.split('/').pop() === 'edit' ? true : false;
  const onSubmitForm = async (data: any = {}) => {
    const select = await instantState(setSelect);
    setConfirmDialog((pre) => ({
      ...pre,
      show: false,
    }));
    data = getValuesAdd();
    if (
      data['validTill'] &&
      !validateDate(moment(data['validTill']).format('MM/DD/YYYY'))
    ) {
      setError('validTill', {
        message: 'Not a valid date format.',
        type: 'custom',
      });
    } else {
      setLoader(true);
      const postFormData: any = new FormData();
      postFormData.append(
        'organizationLogo',
        profileImage.selectedImage ? profileImage.selectedImage : null,
      );
      postFormData.append(
        'organizationName',
        data?.organizationName ? data?.organizationName : '',
      );
      postFormData.append(
        'organizationAddress',
        data?.organizationAddress ? data?.organizationAddress : '',
      );
      postFormData.append(
        'organizationCity',
        data?.organizationCity ? String(data?.organizationCity) : '',
      );
      postFormData.append(
        'organizationState',
        data?.organizationState ? String(data?.organizationState) : '',
      );
      postFormData.append(
        'organizationCountry',
        data?.organizationCountry ? String(data?.organizationCountry) : '',
      );
      postFormData.append(
        'organizationPhone',
        data?.organizationPhone ? data?.organizationPhone : '',
      );
      postFormData.append(
        'organizationFax',
        data?.organizationFax ? data?.organizationFax : '',
      );
      postFormData.append(
        'organizationEmail',
        data?.organizationEmail ? data?.organizationEmail : '',
      );
      postFormData.append(
        'organizationWebsite',
        data?.organizationWebsite ? data?.organizationWebsite : '',
      );
      postFormData.append(
        'organizationZipCode',
        data?.organizationZipCode ? data?.organizationZipCode : '',
      );
      postFormData.append('validTill', data?.validTill ? data?.validTill : '');
      postFormData.append(
        'organizationStatus',
        data?.organizationStatus ? data?.organizationStatus : '',
      );
      postFormData.append(
        'organizationAddress',
        data?.organizationAddress ? data?.organizationAddress : '',
      );
      postFormData.append('unitType', data?.unitType ? data?.unitType : '');
      postFormData.append(
        'unitNumber',
        data?.unitNumber ? data?.unitNumber : '',
      );
      postFormData.append(
        'typeOfBusiness',
        data?.typeOfBusiness ? data?.typeOfBusiness : '',
      );
      postFormData.append(
        'yearEstablished',
        data?.yearEstablished ? data?.yearEstablished : '',
      );
      postFormData.append(
        'totalNumberOfEmployees',
        data?.totalNumberOfEmployees ? data?.totalNumberOfEmployees : '',
      );
      postFormData.append(
        'grossAnnualIncome',
        data?.grossAnnualIncome ? data?.grossAnnualIncome : '',
      );
      postFormData.append(
        'netAnnualIncome',
        data?.netAnnualIncome ? data?.netAnnualIncome : '',
      );
      postFormData.append(
        'organizationSignatoryFirstName',
        data?.organizationSignatoryFirstName
          ? data?.organizationSignatoryFirstName
          : '',
      );
      postFormData.append(
        'organizationSignatoryLastName',
        data?.organizationSignatoryLastName
          ? data?.organizationSignatoryLastName
          : '',
      );
      postFormData.append(
        'organizationSignatoryEmail',
        data?.organizationSignatoryEmail
          ? data?.organizationSignatoryEmail
          : '',
      );
      postFormData.append(
        'organizationSignatoryTitle',
        data?.organizationSignatoryTitle
          ? data?.organizationSignatoryTitle
          : '',
      );
      postFormData.append(
        'organizationFEINNumber',
        data?.organizationFEINNumber ? data?.organizationFEINNumber : '',
      );

      postFormData.append('isSignatoryUpdated', select);
      postFormData.append(
        'oldSignatoryId',
        data?.oldSignatoryId ? data?.oldSignatoryId : '',
      );
      postFormData.append(
        'newSignatoryId',
        data?.newSignatoryId ? data?.newSignatoryId : '',
      );
      if (selectPetitionId.length) {
        postFormData.append('petitionId', selectPetitionId);
        postFormData.append('updatePetitionData', true);
      }
      updateData(
        userDetail?.companyId,
        postFormData,
        COMMON_ENDPOINT.ManagePetitioner,
      )
        .then((resp: any) => {
          cancelForm();
          setLoader(false);
          toast.success('Updated successfully', alertOptions);
          setDialogView(false);
          setSelectPetitionId([]);
          setValueAdd('oldSignatoryId', watch('newSignatoryId'));
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };
  const [countries, setCountries] = useState<any>({
    cities: [],
    coutries: [],
    state: [],
  });
  const getCountriesDependent = (key?: string, value?: number) => {
    getAllListData(
      key === ''
        ? MASTER_ENDPOINT.Countries
        : `${MASTER_ENDPOINT.Countries}?${key}=${String(value)}`,
    )
      .then((countryResp: any) => {
        if (key === 'countryId') {
          setCountries((prev) => ({
            ...prev,
            countries: countryResp.countries,
            states: countryResp.states,
          }));
        }
        if (key === 'stateId') {
          setCountries((prev) => ({
            ...prev,
            cities: countryResp.cities,
            countries: countryResp.countries,
          }));
        }
        if (key === '') {
          setCountries({
            cities: countryResp.cities,
            countries: countryResp.countries,
            states: countryResp.states,
          });
        }
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };
  const {
    control: control,
    handleSubmit: handleSubmit,
    // reset: resetForm,
    setValue: setValueAdd,
    getValues: getValuesAdd,
    watch,
    setError,
    formState: { errors },
  } = useForm<MyOrganizationtype>({
    criteriaMode: 'all',
    defaultValues: {
      grossAnnualIncome: '',
      isSignatoryUpdated: false,
      netAnnualIncome: '',
      newSignatoryId: '',
      oldSignatoryId: '',
      organizationAddress: '',
      organizationCity: '',
      organizationCountry: '',
      organizationEmail: '',
      organizationFEINNumber: '',
      organizationFax: '',
      organizationLogo: '',
      organizationName: '',
      organizationPhone: '',
      organizationSignatoryEmail: '',
      organizationSignatoryFirstName: '',
      organizationSignatoryLastName: '',
      organizationSignatoryTitle: '',
      organizationState: '',
      organizationStatus: 'active',
      organizationWebsite: '',
      organizationZipCode: '',
      // streetNumberAndName: '',
      totalNumberOfEmployees: '',
      typeOfBusiness: '',
      unitNumber: '',
      unitType: '',
      validTill: '',
      yearEstablished: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(MyOrganizationschema),
  });
  const cancelForm = () => {
    navigate(-1);
    getInitialStatus();
  };
  const getInitialStatus = useCallback(async () => {
    setLoader(true);
    await getAllListData(`/${SETUP_ENDPOINT.OrganizationDetails}`)
      .then((resp) => {
        const {
          org_address,
          org_name,
          org_business_type,
          org_city,
          org_country,
          org_email,
          org_fax,
          org_fein_number,
          org_gross_annual_income,
          org_logo,
          org_net_annual_income,
          org_phone,
          org_signatory_email,
          org_signatory_first_name,
          org_signatory_last_name,
          org_signatory_title,
          org_state,
          org_status,
          org_street_number_and_name,
          org_total_employess,
          org_unit_number,
          org_unit_type,
          org_valid_till,
          org_website,
          org_year_established,
          org_zip_code,
        } = resp;
        setProfileImage({
          imageURL: `${fileApiUrl}${String(org_logo)}`,
          selectedImage: { org_logo },
        });
        !_.isNull(org_country)
          ? getCountriesDependent('countryId', org_country)
          : getCountriesDependent('');
        !_.isNull(org_state)
          ? getCountriesDependent('stateId', org_state)
          : getCountriesDependent('');
        setLoader(false);
        setValueAdd('organizationName', org_name || '');
        setValueAdd('organizationAddress', org_address || '');
        setValueAdd('organizationCity', Number(org_city) || '');
        setValueAdd('organizationState', Number(org_state) || '');
        setValueAdd('organizationCountry', Number(org_country) || '');
        setValueAdd('organizationPhone', org_phone || '');
        setValueAdd('organizationFax', org_fax || '');
        setValueAdd('organizationEmail', org_email || '');
        setValueAdd('organizationWebsite', org_website || '');
        setValueAdd(
          'validTill',
          org_valid_till ? moment(org_valid_till).format('MM/DD/YYYY') : '',
        );
        setValueAdd('organizationAddress', org_street_number_and_name || '');
        setValueAdd('unitType', org_unit_type || '');
        setValueAdd('unitNumber', org_unit_number || '');
        setValueAdd('typeOfBusiness', org_business_type || '');
        setValueAdd('yearEstablished', org_year_established || '');
        setValueAdd('totalNumberOfEmployees', org_total_employess || '');
        setValueAdd('grossAnnualIncome', org_gross_annual_income || '');
        setValueAdd('netAnnualIncome', org_net_annual_income || '');
        setValueAdd(
          'organizationSignatoryFirstName',
          org_signatory_first_name || '',
        );
        setValueAdd(
          'organizationSignatoryLastName',
          org_signatory_last_name || '',
        );
        setValueAdd('organizationSignatoryEmail', org_signatory_email || '');
        setValueAdd('organizationSignatoryTitle', org_signatory_title || '');
        setValueAdd('organizationFEINNumber', org_fein_number || '');
        setValueAdd('organizationZipCode', org_zip_code || '');
        setValueAdd('organizationStatus', org_status || '');
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname.split('/').pop() === 'edit' && !useEditDetail) {
      cancelForm();
    }
    getInitialStatus();
    getCountriesDependent('');
    getContactPerson();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getContactPerson = () => {
    getAllListData(
      `/petitoner${SETUP_ENDPOINT.ManageUsers}?skip=0&take=50&status=active`,
    )
      .then((Resp: any) => {
        setContactPerson(Resp.data);
        const signatoryContact = Resp.data?.find((e) => {
          return e.is_signatory;
        });
        setValueAdd('newSignatoryId', signatoryContact?.id);
        setValueAdd('oldSignatoryId', signatoryContact?.id);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };
  const contactPersonChange = (e) => {
    const contactPersonSelect = contactPerson.find(
      (person) => person.id === e.target.value,
    );

    if (contactPersonSelect) {
      setSelect(true);
      setValueAdd(
        'organizationSignatoryFirstName',
        contactPersonSelect?.first_name || '',
      );
      setValueAdd(
        'organizationSignatoryLastName',
        contactPersonSelect?.last_name || '',
      );
      setValueAdd(
        'organizationSignatoryEmail',
        contactPersonSelect?.email_id || '',
      );
      setValueAdd(
        'organizationSignatoryTitle',
        contactPersonSelect?.title || '',
      );
      setValueAdd('newSignatoryId', contactPersonSelect?.id);
    }
    // onSubmitForm();
  };

  const profileSelected = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    setProfileImage({
      selectedImage: file,
    });
    reader.onloadend = () => {
      setProfileImage((prev) => ({
        ...prev,
        imageURL: [reader.result],
      }));
    };
  };
  const closeUploadDialog = useCallback((action: any) => {
    if (action) {
      profileSelected(action);
    } else {
      setFileUploadOpen((pre) => ({
        ...pre,
        open: false,
      }));
    }
  }, []);
  const checkDataList = (value, data) => {
    if (value) {
      setSelectPetitionId((pre) => [...pre, data.id]);
    } else {
      setSelectPetitionId((pre) => [...pre.filter((e) => e !== data.id)]);
    }
  };
  useEffect(() => {
    getAllListData(
      `/${String(SETUP_ENDPOINT.OrganizationDetails)}/${String(
        userDetail.companyId,
      )}?associated=${String(debouncedParams.associated)}&skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}&name=${String(
        debouncedParams.name,
      )}`,
    )
      .then((resp: any) => {
        setList(resp.data && resp.data);
      })
      .catch((e) => {
        // setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);
  const changeConfirmation = async (action: string) => {
    if (action !== 'yes') {
      setConfirmDialog((pre) => ({
        ...pre,
        show: false,
      }));
      return onSubmitForm();
    }
    await setDialogView(true);
  };
  return (
    <Typography component={'div'}>
      {loader ? (
        <Loader />
      ) : (
        <Grid container>
          <Grid item md={10} sm={12} className="pl-0 pr-2rem">
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <Grid container>
                <Grid item md={12} sm={12}>
                  <fieldset
                    style={{ border: 'none', padding: 'unset' }}
                    disabled={!useEditDetail && true}>
                    <Typography component={'div'} className="addbox">
                      {/* <img className="user-photo" src="" alt="user" /> */}
                      <div className="user-poto-box">
                        {useEditDetail && (
                          <div
                            className="pencil-box"
                            onClick={() => setFileUploadOpen({ open: true })}>
                            <img
                              className="d-block"
                              src={pencil}
                              alt="pencileditsvg"
                            />
                            <img
                              className="d-none"
                              src={pencilImg}
                              alt="pencileditsvg"
                            />
                            {/* <input
                              type="file"
                              style={{ display: 'none' }}
                              ref={uploadRef}
                              onChange={(e) => {
                                // setpicture(URL.createObjectURL(e.target.files[0]));
                                profileSelected(e.target.files);
                              }}
                            /> */}
                          </div>
                        )}
                        {fileUploadOpen.open && (
                          <FileUploadDialog
                            open={fileUploadOpen.open}
                            title={'Upload Profile '}
                            allowedFileType={profileFileType}
                            errorMessage={
                              'You can upload documents of types JPEG, PNG only'
                            }
                            info={
                              '(You can upload documents of types JPEG, PNG only)'
                            }
                            onConfirm={closeUploadDialog}
                            setFileUploadOpen={setFileUploadOpen}
                          />
                        )}
                        {_.isBuffer(profileImage.selectedImage) ? (
                          <img
                            src={profileImage.imageURL ?? profileimagelogo}
                            alt=""
                            className="user-poto"
                          />
                        ) : (
                          <img
                            src={
                              profileImage.imageURL
                                ? profileImage.imageURL
                                : profileimagelogo
                            }
                            alt=""
                            className="user-poto"
                          />
                        )}
                      </div>
                      <Typography
                        component={'div'}
                        className="uadd-title"
                        sx={{ paddingTop: '3.5rem !important' }}>
                        Organization Details
                      </Typography>
                      <Box
                        component="form"
                        className="u-add-in"
                        noValidate
                        autoComplete="off">
                        <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                          <Grid item md={6}>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Organization Name
                              </Typography>
                              <Controller
                                control={control}
                                name="organizationName"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      maxLength={50}
                                      placeHolder="Organization Name"
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={errors.organizationName?.message}
                                      onChange={(val) => onChange(val)}
                                      tabIndex={1}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Unit Type
                              </Typography>

                              <Controller
                                control={control}
                                defaultValue="appartment"
                                name="unitType"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name={name}
                                      value={value}
                                      tabIndex={3}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}>
                                      <FormControlLabel
                                        value="apt"
                                        control={<Radio />}
                                        label="Apartment"
                                      />
                                      <FormControlLabel
                                        value="ste"
                                        control={<Radio />}
                                        label="Suite"
                                      />
                                      <FormControlLabel
                                        value="flr"
                                        control={<Radio />}
                                        label="Floor"
                                      />
                                      {useEditDetail &&
                                        getValuesAdd().unitType && (
                                          <FormControlLabel
                                            value=""
                                            control={
                                              <span
                                                className="reset-button"
                                                onClick={() =>
                                                  setValueAdd('unitType', '')
                                                }>
                                                Reset
                                              </span>
                                            }
                                            label=""
                                          />
                                        )}
                                    </RadioGroup>
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Country
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=""
                                name="organizationCountry"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomSelect
                                      searchable={true}
                                      labelKey={'name'}
                                      valueKey={'id'}
                                      options={
                                        !_.isEmpty(countries?.countries)
                                          ? countries?.countries
                                          : []
                                      }
                                      name={name}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        getCountriesDependent(
                                          'countryId',
                                          e.target.value,
                                        );
                                      }}
                                      placeHolder={'Select'}
                                      className={'custom-input'}
                                      error={
                                        errors.organizationCountry?.message
                                      }
                                      tabIndex={5}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                State
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=""
                                name="organizationState"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomSelect
                                      searchable={true}
                                      labelKey={'name'}
                                      valueKey={'id'}
                                      options={
                                        !_.isEmpty(countries?.states)
                                          ? countries?.states
                                          : []
                                      }
                                      name={name}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        getCountriesDependent(
                                          'stateId',
                                          e.target.value,
                                        );
                                      }}
                                      placeHolder={'Select'}
                                      className={'custom-input'}
                                      error={errors.organizationState?.message}
                                      tabIndex={7}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                City
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=""
                                name="organizationCity"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomSelect
                                      searchable={true}
                                      labelKey={'name'}
                                      valueKey={'id'}
                                      options={
                                        !_.isEmpty(countries?.cities)
                                          ? countries?.cities
                                          : []
                                      }
                                      name={name}
                                      value={value}
                                      onChange={(val) => onChange(val)}
                                      placeHolder={'Select'}
                                      className={'custom-input'}
                                      error={errors.organizationCity?.message}
                                      tabIndex={9}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Phone
                              </Typography>
                              <Controller
                                control={control}
                                name="organizationPhone"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      maxLength={10}
                                      placeHolder="phone number"
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={errors.organizationPhone?.message}
                                      onChange={(val) => onChange(val)}
                                      type="number"
                                      tabIndex={11}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Email
                              </Typography>
                              <Controller
                                control={control}
                                name="organizationEmail"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      maxLength={50}
                                      placeHolder="Email"
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={errors.organizationEmail?.message}
                                      onChange={(val) => onChange(val)}
                                      type="email"
                                      tabIndex={13}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Type of Business
                              </Typography>
                              <Controller
                                control={control}
                                name="typeOfBusiness"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      maxLength={50}
                                      placeHolder="Type of Business"
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={errors.typeOfBusiness?.message}
                                      onChange={(val) => onChange(val)}
                                      tabIndex={15}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Current Number of Employees in the USA
                              </Typography>
                              <Controller
                                control={control}
                                name="totalNumberOfEmployees"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      maxLength={50}
                                      placeHolder="Current Number of Employees in the united States"
                                      name={name}
                                      value={value}
                                      type="number"
                                      className={'custom-input'}
                                      error={
                                        errors.totalNumberOfEmployees?.message
                                      }
                                      onChange={(val) =>
                                        onChange(
                                          val.target.value.replace(
                                            /[^\d]/g,
                                            '',
                                          ),
                                        )
                                      }
                                      tabIndex={17}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Street Number And Name
                              </Typography>
                              <Controller
                                control={control}
                                name="organizationAddress"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      maxLength={50}
                                      placeHolder="Street Number And Name"
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={
                                        errors.organizationAddress?.message
                                      }
                                      onChange={(val) => onChange(val)}
                                      tabIndex={2}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Unit Number
                              </Typography>
                              <Controller
                                control={control}
                                name="unitNumber"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      maxLength={50}
                                      placeHolder="Unit Number"
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={errors.unitNumber?.message}
                                      onChange={(val) => onChange(val)}
                                      tabIndex={4}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Zip / Postal Code
                              </Typography>
                              <Controller
                                control={control}
                                name="organizationZipCode"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      maxLength={50}
                                      placeHolder="Zip / Postal Code"
                                      name={name}
                                      value={value}
                                      type="text  "
                                      className={'custom-input'}
                                      error={
                                        errors.organizationZipCode?.message
                                      }
                                      onChange={(val) =>
                                        onChange(
                                          val.target.value.replace(
                                            /[^\d]/g,
                                            '',
                                          ),
                                        )
                                      }
                                      tabIndex={6}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Fax Number
                              </Typography>
                              <Controller
                                control={control}
                                name="organizationFax"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      maxLength={50}
                                      placeHolder="Fax Number"
                                      name={name}
                                      value={value}
                                      type="number"
                                      className={'custom-input'}
                                      error={errors.organizationFax?.message}
                                      onChange={(val) => onChange(val)}
                                      tabIndex={8}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Website
                              </Typography>
                              <Controller
                                control={control}
                                name="organizationWebsite"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      maxLength={50}
                                      placeHolder="Website"
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={
                                        errors.organizationWebsite?.message
                                      }
                                      onChange={(val) => onChange(val)}
                                      tabIndex={10}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Valid Until
                              </Typography>

                              <Controller
                                control={control}
                                defaultValue=""
                                name="validTill"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomDatePicker
                                      className="custom-input"
                                      errors={errors.validTill?.message}
                                      name={name}
                                      value={value}
                                      onChangeDate={(val) => onChange(val)}
                                      tabIndex={12}
                                      isEditable={useEditDetail ? false : true}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Year Business Established
                              </Typography>
                              <Controller
                                control={control}
                                name="yearEstablished"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      placeHolder="Year Business Established"
                                      name={name}
                                      value={value}
                                      type="number"
                                      className={'custom-input'}
                                      error={errors.yearEstablished?.message}
                                      onChange={(val) => onChange(val)}
                                      tabIndex={14}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Gross Annual Income
                              </Typography>
                              <Controller
                                control={control}
                                name="grossAnnualIncome"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      placeHolder="Gross Annual Income"
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={errors.grossAnnualIncome?.message}
                                      onChange={(val) => onChange(val)}
                                      tabIndex={16}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Net Annual Income
                              </Typography>
                              <Controller
                                control={control}
                                name="netAnnualIncome"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      placeHolder="Net Annual Income"
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={errors.netAnnualIncome?.message}
                                      onChange={(val) => onChange(val)}
                                      tabIndex={18}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12}>
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Federal Employer Identification Number (FEIN)
                              </Typography>
                              <Controller
                                control={control}
                                name="organizationFEINNumber"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      maxLength={50}
                                      placeHolder="Federal Employer Identification Number (FEIN)"
                                      name={name}
                                      value={value}
                                      className={'custom-input'}
                                      error={
                                        errors.organizationFEINNumber?.message
                                      }
                                      onChange={(val) => onChange(val)}
                                      tabIndex={19}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Typography>
                  </fieldset>
                </Grid>
              </Grid>
              <Grid item md={12} sm={12} sx={{ marginTop: '1rem !important' }}>
                <fieldset
                  style={{ border: 'none', padding: 'unset' }}
                  disabled={!useEditDetail && true}>
                  <Typography component={'div'} className="addbox">
                    <Typography
                      component={'div'}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                      }}>
                      <Typography
                        component={'div'}
                        className="uadd-title"
                        sx={{ paddingTop: '0.5rem !important' }}>
                        Signatory Details
                      </Typography>
                      <Typography
                        component={'div'}
                        className="outform-select"
                        style={{
                          fontSize: '1rem',
                          width: '250px',
                        }}>
                        <Controller
                          control={control}
                          name="newSignatoryId"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                searchable={true}
                                labelKey={'full_name'}
                                valueKey={'id'}
                                options={contactPerson ? contactPerson : []}
                                value={value}
                                onChange={(val) => contactPersonChange(val)}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                disabled={!useEditDetail}
                                isClearable={false}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Typography>
                    <Box
                      component="form"
                      className="u-add-in"
                      noValidate
                      autoComplete="off">
                      <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                        <Grid item md={6}>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              First Name
                            </Typography>
                            <Controller
                              control={control}
                              name="organizationSignatoryFirstName"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    maxLength={50}
                                    placeHolder="First Name"
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={
                                      errors.organizationSignatoryFirstName
                                        ?.message
                                    }
                                    onChange={(val) => onChange(val)}
                                    tabIndex={20}
                                    disabled={true}
                                  />
                                );
                              }}
                            />
                          </Typography>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Email
                            </Typography>
                            <Controller
                              control={control}
                              name="organizationSignatoryEmail"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    maxLength={50}
                                    placeHolder="Email"
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={
                                      errors.organizationSignatoryEmail?.message
                                    }
                                    onChange={(val) => onChange(val)}
                                    tabIndex={22}
                                    disabled={true}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Last Name
                            </Typography>
                            <Controller
                              control={control}
                              name="organizationSignatoryLastName"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    maxLength={50}
                                    placeHolder="Last Name"
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={
                                      errors.organizationSignatoryLastName
                                        ?.message
                                    }
                                    onChange={(val) => onChange(val)}
                                    tabIndex={21}
                                    disabled={true}
                                  />
                                );
                              }}
                            />
                          </Typography>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Title
                            </Typography>
                            <Controller
                              control={control}
                              name="organizationSignatoryTitle"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    maxLength={50}
                                    placeHolder="Title"
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={
                                      errors.organizationSignatoryTitle?.message
                                    }
                                    onChange={(val) => onChange(val)}
                                    tabIndex={23}
                                    disabled={true}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Typography>
                </fieldset>
              </Grid>
              {useEditDetail && (
                <div className="bottom-fixed">
                  <Typography component={'div'} className="d-flex-jae ">
                    <Button className="cancel-btn mr-2" onClick={cancelForm}>
                      <Typography className="o-box" />
                      Cancel
                    </Button>
                    <Button
                      className="add-btn"
                      onClick={() =>
                        setConfirmDialog({
                          show: true,
                        })
                      }>
                      Save
                    </Button>
                  </Typography>
                </div>
              )}
            </form>
          </Grid>
        </Grid>
      )}
      <ConfirmDialog
        title="Confirmation"
        floatingBtnShow={true}
        open={confirmDialog.show}
        close={() => {
          setConfirmDialog((pre) => ({
            ...pre,
            show: false,
          }));
        }}
        visibleClose
        onConfirm={(act) => changeConfirmation(act)}
        autoCloseDisable={true}>
        {
          'Do you want to update this latest petitioner Information in petitions?'
        }
      </ConfirmDialog>
      <CustomModalView
        open={dialogView}
        fullWidth={true}
        maxWidth={'lg'}
        scroll={'paper'}
        autoCloseDisable={true}
        isFilters={true}
        filterOnChange={(searchvalue) => {
          setParams((pre) => ({
            ...pre,
            name: searchvalue,
            skip: 0,
            take: 50,
          }));
        }}
        title="Petitions List"
        close={() => {
          setDialogView(false);
          setSelectPetitionId([]);
          setParams((pre) => ({
            ...pre,
            name: '',
            skip: 0,
            take: 50,
          }));
        }}>
        {loader && <div className="liberty-loader" />}
        <DialogContent>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th scope="col" style={{ width: '50%' }}>
                  {'List items'}
                </th>
                <th
                  scope="col"
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                  Action
                  <CustomCheckBox
                    checked={list.length === selectPetitionId.length}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectPetitionId(list.map((e) => e.id));
                      } else {
                        setSelectPetitionId([]);
                      }
                    }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {list && list.length ? (
                list.map((data, index) => (
                  <tr key={index} style={{ cursor: 'pointer' }}>
                    <td>
                      <a>
                        {data?.first_name} {data?.last_name} for{' '}
                        {data?.visa_code}
                      </a>
                    </td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {' '}
                      <Checkbox
                        size={'medium'}
                        checked={selectPetitionId.some((e) => e === data.id)}
                        onChange={(e) => checkDataList(e.target.checked, data)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr className={'text-center'}>
                  <th scope="row" colSpan={2}>
                    No Record(s) Found.
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
        {selectPetitionId.length && selectPetitionId.length > 0 ? (
          <DialogActions>
            <Typography
              component={'div'}
              className="d-flex-jae"
              sx={{ padding: '0 1.5rem' }}>
              <Button
                className="n-add-btn"
                sx={{
                  borderRadius: '5px !important',
                  float: 'right',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '0.9em !important',
                  height: '35px !important',
                  margin: '15px',
                  width: '149px !important',
                }}
                onClick={() => onSubmitForm()}>
                Submit
              </Button>
            </Typography>
          </DialogActions>
        ) : (
          ''
        )}
      </CustomModalView>
    </Typography>
  );
};

export default MyOrganization;
