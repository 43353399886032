import { Tooltip, Typography } from '@mui/material';
import InfinityScroll from 'components/InfinityScroll';
// import Pagination from 'components/Pagination';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { kFormatter } from 'utils/helper';

type ListPropsTypes = {
  dashboardCounts: any;
  contract: any;
  reportFilterKey: any;
  setReportFilterKey: any;
};

const ContractPayments: React.FC<any> = (props: ListPropsTypes) => {
  const { dashboardCounts, contract, setReportFilterKey } = props;
  const rowProps = (rowProps, index) => {
    return (
      <Typography
        component={'div'}
        className="cp-item d-flex-a pay-container"
        key={index}>
        <Typography component={'div'}>
          <Typography component={'h2'} className="cp-name black-white-text">
            {rowProps.payerName}
          </Typography>
          <Typography component={'p'} className="cp-by">
            Initiated By: {rowProps.createduser} on{' '}
            {moment(rowProps.created_at).format('DD MMM, yyyy')}
          </Typography>
        </Typography>
        <Typography component={'div'} className="pay-flex">
          <Typography component={'div'} className="payment-info">
            <Typography component={'h3'} className="cps-text black-white-text">
              Contract
            </Typography>
            <Typography component={'h6'} className="cp-status">
              {rowProps.contract_staus}
            </Typography>
          </Typography>

          <Typography component={'div'} className="payment-info">
            <Typography component={'h3'} className="cps-text black-white-text">
              Payment
            </Typography>
            <Typography component={'h6'} className="cp-status">
              Pending
            </Typography>
          </Typography>

          {/* <Grid container>
            <Grid item md={5} sm={5}>
              {' '}
              <Typography>
                <Typography
                  component={'div'}
                  className="cps-text black-white-text">
                  Contract
                </Typography>
                <Typography component={'div'} className="cp-status">
                  {rowProps.contract_staus}
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={2} sm={2}>
              {' '}
              <Typography component={'div'} className="cp-border" />
            </Grid>
            <Grid item md={5} sm={5}>
              {' '}
              <Typography>
                <Typography
                  component={'div'}
                  className="cps-text black-white-text">
                  Payment
                </Typography>
                <Typography component={'div'} className="cp-status">
                  Pending
                </Typography>
              </Typography>
            </Grid>
          </Grid> */}
        </Typography>
      </Typography>
    );
  };
  return (
    <Typography component={'div'}>
      <Typography className="c-title black-white-text">
        Contracts & Payments
      </Typography>
      <Typography component={'div'} className="cp-box">
        <Typography component={'div'} className="cp-flex-4">
          <Typography component={'div'}>
            {contract && contract.data && contract.data.length > 0 ? (
              <InfinityScroll
                style={{ padding: '0 5px 0 0 !important' }}
                paramState={setReportFilterKey}
                height={325}
                scrollableTargetId={'contractAndPayment'}
                data={contract.data}
                rowProps={rowProps}
                count={contract.count}
              />
            ) : (
              // <InfiniteScroll
              //   dataLength={contract.data.length}
              //   next={() => {
              //     if (contract.data.length < contract.count) {
              //       setReportFilterKey((prev) => ({
              //         ...prev,
              //         skip: Number(prev.skip) + Number(prev.take),
              //         take: prev.take,
              //       }));
              //     }
              //   }}
              //   hasMore={contract.data.length < contract.count}
              //   loader={
              //     <div className="lds-ellipsis">
              //       <div />
              //       <div />
              //       <div />
              //       <div />
              //     </div>
              //   }
              //   height={340}
              //   scrollableTarget="scrollableDiv">
              //   {contract?.data.map((cont: any, cIndex: number) => (
              //     <Typography
              //       component={'div'}
              //       className="cp-item d-flex-a"
              //       key={cIndex}>
              //       <Typography component={'div'} className="cp-inner" />
              //       <Typography component={'div'} className="cp-flex-3">
              //         <Typography
              //           component={'div'}
              //           className="cp-name black-white-text">
              //           {cont.payerName}
              //         </Typography>
              //         <Typography component={'div'} className="cp-by">
              //           Initiated By: {cont.createduser} on{' '}
              //           {moment(cont.created_at).format('DD MMM, yyyy')}
              //         </Typography>
              //       </Typography>
              //       <Typography
              //         component={'div'}
              //         className="cp-flex-1 d-flex-jae">
              //         <Grid container>
              //           <Grid item md={5} sm={5}>
              //             {' '}
              //             <Typography>
              //               <Typography
              //                 component={'div'}
              //                 className="cps-text black-white-text">
              //                 Contract
              //               </Typography>
              //               <Typography component={'div'} className="cp-status">
              //                 {cont.contract_staus}
              //               </Typography>
              //             </Typography>
              //           </Grid>
              //           <Grid item md={2} sm={2}>
              //             {' '}
              //             <Typography component={'div'} className="cp-border" />
              //           </Grid>
              //           <Grid item md={5} sm={5}>
              //             {' '}
              //             <Typography>
              //               <Typography
              //                 component={'div'}
              //                 className="cps-text black-white-text">
              //                 Payment
              //               </Typography>
              //               <Typography component={'div'} className="cp-status">
              //                 Pending
              //               </Typography>
              //             </Typography>
              //           </Grid>
              //         </Grid>
              //       </Typography>
              //     </Typography>
              //   ))}
              // </InfiniteScroll>

              <Typography
                className="note-box2"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                <Typography className="inb2" />
                <Typography className="black-white-text ctitle hideshow">
                  No Record(s) Found.
                </Typography>
              </Typography>
            )}
          </Typography>
          {/* <Pagination
            total={!_.isEmpty(contract) ? Number(contract.count) : 0}
            skip={reportFilterKey.skip}
            take={reportFilterKey.take}
            setParams={setReportFilterKey}
          /> */}
        </Typography>
        <Typography component={'div'} className="cp-flex-1">
          <Tooltip
            title={'$'.concat(dashboardCounts.totalFees)}
            placement="top-start">
            <Typography component={'div'} className="cp-total">
              <Typography component={'div'} className="cp-inner" />
              <Typography className="cp-t-title black-white-text">
                Total Fees
              </Typography>
              <Typography className="cp-t-count">
                ${' '}
                {!_.isEmpty(dashboardCounts)
                  ? kFormatter(dashboardCounts.totalFees)
                  : 0}
              </Typography>
            </Typography>
          </Tooltip>
          <Typography component={'div'} className="cp-total">
            <Typography component={'div'} className="cp-inner" />
            <Typography className="cp-t-title black-white-text">
              Number of Clients
            </Typography>
            <Typography className="cp-t-count">
              {!_.isEmpty(dashboardCounts)
                ? kFormatter(dashboardCounts.noofClients)
                : 0}
            </Typography>
          </Typography>
          <Tooltip
            title={'$'.concat(dashboardCounts.averageFeesPerCase)}
            placement="top-start">
            <Typography component={'div'} className="cp-total">
              <Typography component={'div'} className="cp-inner" />
              <Typography className="cp-t-title black-white-text">
                Average Fees Per Case
              </Typography>
              <Typography className="cp-t-count">
                ${' '}
                {!_.isEmpty(dashboardCounts)
                  ? kFormatter(dashboardCounts.averageFeesPerCase)
                  : 0}
              </Typography>
            </Typography>
          </Tooltip>
        </Typography>
      </Typography>
    </Typography>
  );
};

export default React.memo(ContractPayments);
