import _ from 'lodash';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { UserRoleType } from 'types';

import AdminRoutes from './AdminRoutes';
import AffilateRoutes from './AffilateRoutes';
import AttorneyRoutes from './AttorneyRoutes';
import BeneficiaryRoutes from './BeneficiaryRoutes';
import PetitionerRoutes from './PetitionerRoutes';

const PrivateRoutes: React.FC = (): React.ReactElement => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const location: any = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!_.isEmpty(userDetail)) {
      if (location.state?.from) {
        navigate(location.state.from);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail]);
  return (
    <div>
      {!_.isEmpty(userDetail) &&
        userDetail?.userRoleName === UserRoleType.Attorney && (
          <AttorneyRoutes />
        )}

      {!_.isEmpty(userDetail) &&
        userDetail?.userRoleName === UserRoleType.Beneficiary && (
          <BeneficiaryRoutes />
        )}

      {!_.isEmpty(userDetail) &&
        userDetail?.userRoleName === UserRoleType.Petitioner && (
          <PetitionerRoutes />
        )}

      {!_.isEmpty(userDetail) &&
        userDetail?.userRoleName === UserRoleType.Affilate && (
          <AffilateRoutes />
        )}

      {!_.isEmpty(userDetail) &&
        userDetail?.userRoleName === UserRoleType.Admin && <AdminRoutes />}
    </div>
  );
};

export default PrivateRoutes;
