import EventIcon from '@mui/icons-material/Event';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  DialogContent,
  Grid,
  // Tab,
  Typography,
} from '@mui/material';
// import Bdownload from 'assets/images/b-download.png';
import Bsend from 'assets/images/b-send.png';
import Bupload from 'assets/images/b-upload.png';
import PdfIcon from 'assets/images/updf.svg';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import { FileUploadDialog } from 'components/Fileupload/fileuploadDialog';
import { AttorneyPrivateRoutes } from 'config';
import saveAs from 'file-saver';
import { instantState } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
// import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData, getS3file, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions, submissionStationFileType } from 'utils/helper';

// import { PetitionStatusTable } from './petitionStatus/table';
import { PetitionStatusDocumentUpload } from './petitionStatusDocumentUpload';
import { PetitionStatusDocumentUploadTable } from './petitionStatusDocumentUpload/table';

type Props = {
  setSubmissionPopup?: any;
  submissionPopup?: boolean;
};

export const SubmissionStation: React.FC<any> = (props: Props) => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [searchParams] = useSearchParams();
  const [submissionPopup, setSubmissionPopup] = React.useState(false);
  const { petitionId } = useParams();
  const navigate = useNavigate();
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const [loader, setLoader] = useState(true);
  const [teamMembers, setTeamMembers] = useState<any>([]);
  const [petitionStatusTableView, setPetitionStatusTableView] = useState({
    index: null,
    show: false,
  });
  const [lastUpdatedData, setLastUpdatedData] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileUploadDoc, setFileUploadDoc] = useState({});
  const [tabValue, setTabValue] = useState('submissionStation');
  const [fileUploadOpen, setFileUploadOpen] = useState<any>({
    open: false,
  });
  const [notesModel, setNotesModel] = useState<any>({
    data: {},
    open: false,
  });
  const handleClose = () => {
    setNotesModel({ data: {}, open: false });
  };
  const getPetitionStatus = useCallback(
    (openUploadedFile?: any) => {
      getAllListData(
        `${String(COMMON_ENDPOINT.submissionStatus)}/upload-document/${String(
          petitionId,
        )}?isAffilate=${
          petitions?.petitionData?.isAffilateCase
            ? String(petitions?.petitionData?.isAffilateCase)
            : ''
        }&affilateRoleId=${
          petitions?.petitionData?.isAffilateCase
            ? String(petitions?.petitionData?.affilateRoleId)
            : ''
        }&firmId=${
          petitions?.petitionData?.attorney_assigned
            ? String(petitions?.petitionData?.attorney_assigned)
            : ''
        }`,
      )
        .then((resp: any) => {
          setLastUpdatedData(resp.data && resp.data.length ? resp.data : []);

          if (openUploadedFile) {
            setPetitionStatusTableView({
              index: openUploadedFile?.index,
              show: true,
            });
          }
        })
        .catch((e: any) => {
          setLoader(false);
        });

      getAllListData(
        `${String(COMMON_ENDPOINT.Email)}/${String(petitionId)}?type=petition`,
      )
        .then((emailResp: any) => {
          const emailList = [];
          emailResp.map((row: any) => {
            emailList.push({ label: row, value: row });
          });
          setTeamMembers(emailList);
        })
        .catch((e: any) => {});
      setLoader(false);
    },
    [petitionId, petitions],
  );

  const download = (key) => {
    const obj = {
      key,
    };
    const fileName = key.split('/').pop();
    setLoader(true);
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(
          String(fileName) + 'File downloaded successfully',
          alertOptions,
        );
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  // const checkFileValidation = (file) => {
  //   const files = file.name.split('.').pop();
  //   if (['docx', 'pdf', 'jpeg', 'png', 'xls', 'jpg'].includes(files)) {
  //     if (file.size / 1024 / 1024 < 90) {
  //       uploadFiles(file);
  //     } else {
  //       toast.error(
  //         'File is too big, Please select a file less than 90 MB',
  //         alertOptions,
  //       );
  //       return;
  //     }
  //   } else {
  //     toast.error(
  //       'You can upload documents of types JPG, JPEG, PNG, PDF, XLS, DOCX.',
  //       alertOptions,
  //     );
  //   }
  //   uploadSingleRef?.current?.value ? (uploadSingleRef.current.value = '') : '';
  // };
  const uploadFiles = async (file: any) => {
    const docInfo = await instantState(setFileUploadDoc);
    const formData = new FormData();
    formData.append('file', file ? file : '');
    setLoader(true);
    updateData(
      docInfo?.id,
      formData,
      `${COMMON_ENDPOINT.submissionStatus}/upload-document`,
    )
      .then(() => {
        toast.success('Document Upload Successfully', alertOptions);
        getPetitionStatus(docInfo);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const closeUploadDialog = useCallback((action: any) => {
    if (action) {
      uploadFiles(action);
    } else {
      setFileUploadOpen((pre) => ({
        ...pre,
        open: false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => setTabValue(newValue);
  useEffect(() => {
    if (
      !_.isUndefined(petitionId) &&
      !_.isEmpty(petitions) &&
      !_.isUndefined(petitions.petitionData.beneficiary_id)
    ) {
      if (!submissionPopup) {
        getPetitionStatus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitions, submissionPopup]);
  return (
    <Grid container spacing={2}>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <>
          <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
            <Grid container spacing={1} className="pe-box">
              <Box sx={{ width: '100%' }} className="documentTab">
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                      variant="scrollable"
                      onChange={handleChange}
                      aria-label="lab API tabs example">
                      <Typography
                        component={'div'}
                        className="black-white-text d-flex-a"
                        sx={{
                          fontFamily: 'Arial-Bold !important',
                          fontSize: '1.25em !important',
                          fontWeight: 400,
                          margin: '1.5rem 0 0rem 0',
                        }}>
                        {petitions.petitionData.visa_type} for{' '}
                        {petitions.petitionData?.benificiery_name}
                      </Typography>
                      {/* <Tab
                        label="Submission Station"
                        value="submissionStation"
                      />
                      <Tab label="RFE Responder" value="rfeResponder" /> */}
                    </TabList>
                  </Box>

                  <Typography component={'div'}>
                    <TabPanel value="submissionStation">
                      <Typography component={'div'}>
                        <Grid
                          item
                          md={12}
                          sm={12}
                          sx={{ paddingRight: '1.8rem' }}>
                          {lastUpdatedData.length ? (
                            lastUpdatedData.map((data, index) => (
                              <Typography
                                component={'div'}
                                className={'build-box mt-2'}
                                sx={
                                  data.id === searchParams.get('Id')
                                    ? {
                                        boxShadow: '0 0 15px #17c8e7',
                                        padding: '1rem 0',
                                      }
                                    : { padding: '1rem 0' }
                                }
                                key={index}>
                                <Typography
                                  component={'div'}
                                  className="d-flex-a">
                                  <Typography component={'div'}>
                                    <Typography
                                      className="mb-2 pl-2"
                                      sx={{ opacity: 0.8 }}>
                                      {!_.isEmpty(petitions) && (
                                        <span className="f-17 font-bold black-white-text d-flex-1">
                                          Status: {data.statusname}:{' '}
                                          {data.petitiontitle}{' '}
                                          {petitions.petitionData.visa_type}{' '}
                                          Petition of{' '}
                                          {
                                            petitions.petitionData
                                              ?.benificiery_name
                                          }
                                          ,{' '}
                                          {moment(new Date(data.date)).format(
                                            'MMM DD, YYYY',
                                          )}
                                        </span>
                                      )}
                                    </Typography>
                                    <Typography
                                      component={'div'}
                                      className="d-flex-a pl-2"
                                      sx={{
                                        cursor: 'pointer',
                                      }}>
                                      <Typography
                                        component={'div'}
                                        className="d-flex-a mr-2"
                                        sx={{
                                          color: '#efefef !important',
                                        }}
                                        onClick={() =>
                                          setPetitionStatusTableView({
                                            index: index,
                                            show:
                                              index ===
                                                petitionStatusTableView.index &&
                                              petitionStatusTableView.show
                                                ? false
                                                : true,
                                          })
                                        }>
                                        {index ===
                                          petitionStatusTableView.index &&
                                        petitionStatusTableView.show ? (
                                          <VisibilityOffIcon
                                            sx={{ marginRight: '0.5rem' }}
                                          />
                                        ) : (
                                          <VisibilityIcon
                                            sx={{ marginRight: '0.5rem' }}
                                          />
                                        )}
                                        {index ===
                                          petitionStatusTableView.index &&
                                        petitionStatusTableView.show
                                          ? 'Hide'
                                          : 'View'}
                                      </Typography>

                                      {/* <Typography
                            className="d-flex-a mr-2 "
                            sx={
                              data.key
                                ? {
                                    color: '#efefef !important',
                                    cursor: 'pointer',
                                  }
                                : {
                                    color: '#BFC2C3!important',
                                    cursor: 'not-allowed',
                                    opacity: 0.5,
                                  }
                            }
                            onClick={() =>
                              data.key ? download(data.key) : ''
                            }>
                            <img src={Bdownload} className="mr-1" alt="img" />
                            Download
                          </Typography> */}

                                      <Typography
                                        component={'div'}
                                        className="d-flex-a"
                                        sx={{
                                          cursor: 'pointer',
                                        }}>
                                        <Typography
                                          className="d-flex-a mr-2 "
                                          sx={{
                                            color: '#efefef !important',
                                          }}
                                          onClick={() => {
                                            setFileUploadDoc({
                                              ...data,
                                              index: index,
                                            });
                                            setFileUploadOpen({ open: true });
                                          }}>
                                          <img
                                            src={Bupload}
                                            className="mr-1"
                                            alt="img"
                                          />
                                          Upload
                                        </Typography>
                                      </Typography>

                                      <Typography
                                        component={'div'}
                                        className="d-flex-a"
                                        sx={{
                                          cursor: 'pointer',
                                        }}>
                                        <Typography
                                          className="d-flex-a mr-2 "
                                          sx={{
                                            color: '#BFC2C3!important',
                                            cursor: 'not-allowed',
                                            opacity: 0.5,
                                          }}
                                          onClick={() => {}}>
                                          <img
                                            src={Bsend}
                                            className="mr-1"
                                            alt="img"
                                          />
                                          Send
                                        </Typography>
                                      </Typography>
                                      {data.isNotify && (
                                        <Typography
                                          component={'div'}
                                          className="d-flex-a"
                                          sx={{
                                            cursor: 'pointer',
                                          }}>
                                          <Typography
                                            className="d-flex-a mr-2"
                                            sx={{
                                              color: '#efefef !important',
                                            }}
                                            onClick={() => {
                                              setNotesModel({
                                                data: data,
                                                open: true,
                                              });
                                            }}>
                                            <EventIcon className="mr-1" />
                                            Reminder Notes
                                          </Typography>
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Typography>
                                  {index === petitionStatusTableView.index &&
                                    petitionStatusTableView.show && (
                                      <PetitionStatusDocumentUploadTable
                                        files={data?.files}
                                        statusName={data?.statusname}
                                        petitionStatusTableView={
                                          petitionStatusTableView.show
                                        }
                                        download={download}
                                        getPetitionStatus={getPetitionStatus}
                                        setLastUpdatedData={setLastUpdatedData}
                                        mainIndex={index}
                                      />
                                    )}
                                </Typography>
                              </Typography>
                            ))
                          ) : (
                            <Typography
                              className="note-box2"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                              }}>
                              <Typography className="inb2" />
                              <Typography className="black-white-text ctitle hideshow">
                                No Record(s) Found.
                              </Typography>
                            </Typography>
                          )}
                        </Grid>
                      </Typography>
                    </TabPanel>
                    {userDetail.role_id === 3 && (
                      <TabPanel value="rfeResponder">
                        <Grid item md={12}>
                          <Typography
                            component={'div'}
                            className="build-box mt-2"
                            sx={{ padding: '1rem 0' }}>
                            <Typography component={'div'} className="d-flex-a">
                              <Typography component={'div'}>
                                <Typography
                                  className="mb-2 pl-2 black-white-text d-flex-a"
                                  sx={{ opacity: 0.8 }}>
                                  <span>
                                    <img
                                      src={PdfIcon}
                                      className="mr-1"
                                      style={{ height: '25px' }}
                                    />
                                  </span>
                                  RFE Responder
                                </Typography>
                                <Typography
                                  component={'div'}
                                  className="d-flex-a pl-2"
                                  sx={{
                                    cursor: 'pointer',
                                  }}>
                                  <Typography
                                    component={'div'}
                                    className="d-flex-a pl-2"
                                    sx={{
                                      cursor: 'pointer',
                                    }}>
                                    <Typography
                                      component={'div'}
                                      className="d-flex-a"
                                      sx={{
                                        cursor: 'pointer',
                                      }}>
                                      <Typography
                                        className="d-flex-a mr-2 "
                                        sx={{
                                          color: '#efefef !important',
                                        }}
                                        onClick={() => {
                                          navigate(
                                            `${String(
                                              AttorneyPrivateRoutes.RFERESPOEND
                                                .RFEUPLOAD,
                                            )}/${String(petitionId)}`,
                                          );
                                        }}>
                                        <img
                                          src={Bupload}
                                          className="mr-1"
                                          alt="img"
                                        />
                                        Upload
                                      </Typography>
                                    </Typography>
                                    <Typography
                                      component={'div'}
                                      className="d-flex-a mr-2"
                                      sx={{
                                        color: '#efefef !important',
                                      }}
                                      onClick={() => {
                                        navigate(
                                          `${String(
                                            AttorneyPrivateRoutes.RFERESPOEND
                                              .RFEVIEW,
                                          )}/${String(petitionId)}`,
                                        );
                                      }}>
                                      <VisibilityIcon
                                        sx={{ marginRight: '0.5rem' }}
                                      />
                                      <span>View</span>
                                    </Typography>
                                  </Typography>
                                </Typography>
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                      </TabPanel>
                    )}
                  </Typography>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
          {userDetail?.role_id === 3 ? (
            <Grid item md={2} sm={12}>
              {
                <Typography component={'div'} className="add-box">
                  <Typography component={'div'} className="add-inner-box" />
                  <Typography
                    className="built-option d-flex mb-1"
                    onClick={() => setSubmissionPopup(true)}>
                    <span
                      className="plus d-flex-ja"
                      style={{
                        height: '23px',
                        width: '30px',
                      }}>
                      +
                    </span>
                    Upload Document & Note Status
                  </Typography>
                </Typography>
              }
            </Grid>
          ) : (
            ''
          )}
        </>
      )}

      <CustomModalView
        open={submissionPopup}
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        close={() => setSubmissionPopup(false)}
        title="Petition Status and Document Upload"
        autoCloseDisable={true}
        minHeight={'55vh'}>
        <PetitionStatusDocumentUpload
          teamMembers={teamMembers}
          petitionId={petitionId}
          closeModal={() => {
            setSubmissionPopup(false);
          }}
        />
      </CustomModalView>
      {fileUploadOpen.open && (
        <FileUploadDialog
          open={fileUploadOpen.open}
          allowedFileType={submissionStationFileType}
          errorMessage={
            'You can upload documents of types JPG, JPEG, PNG, PDF, XLS, DOCX only'
          }
          info={
            '(You can upload documents of types JPG, JPEG, PNG, PDF, XLS, DOCX only.You can upload upto a maximum of 90MB per upload)'
          }
          onConfirm={closeUploadDialog}
          setFileUploadOpen={setFileUploadOpen}
        />
      )}
      <CustomModalView
        open={notesModel.open}
        close={handleClose}
        title={'Notes'}
        scroll={'paper'}
        fullWidth={true}
        minHeight={'auto'}
        maxWidth={'md'}>
        <DialogContent>
          <Typography component={'div'} className="d-flex mt-1">
            <Typography className="f-13 d-flex font-bold">
              Date:{' '}
              <Typography component={'div'} className="ml-1 f-13">
                {moment(notesModel.data.notifyDate).format('DD-MM-yyyy')}
              </Typography>
            </Typography>
            <Typography component={'div'} className="d-flex ml-2">
              <Typography className="f-13 font-bold">Email: </Typography>
              <Typography component={'div'} className="ml-1 f-13">
                {String(notesModel?.data?.notifyEmail).split(',').join(', ')}
              </Typography>
            </Typography>
          </Typography>
          <Typography component={'div'} className="mt-2 d-flex">
            <CustomInput
              className={'custom-textarea-note'}
              isTextArea={true}
              extraStyle={{
                fontFamily: 'Arial-Regular !important',
                fontSize: '16px',
                fontWeight: '500',
                height: '100%',
                width: '100%',
              }}
              minRows={6}
              value={notesModel.data.notifyNotes}
            />
          </Typography>
        </DialogContent>
      </CustomModalView>
    </Grid>
  );
};

export default SubmissionStation;
