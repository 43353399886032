import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Grid, Tab, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

import ContractReport from '../Report/ContractReport';
import PaymentReport from '../Report/PaymentReport';

export const OrganizationContractPaymentList: React.FC = React.memo(() => {
  const { editId } = useParams();
  const [tabValue, setTabValue] = useState('payment');
  const location: any = useLocation();
  const organizationData: any = useAppSelector(
    (store: any) => store.organizationData.organization,
  );
  // const [params, setParams] = useState<any>({
  //   amount: '',
  //   createdby: '',
  //   firmId: userDetail?.companyId,
  //   fromDate: '',
  //   invoicePurpose: '',
  //   order: 'DESC',
  //   paymentMode: 'all',
  //   paymentReportType: 'payment',
  //   period: 'all',
  //   petitionerAssociated: location?.pathname.includes('invited')
  //     ? 'false'
  //     : 'true',
  //   petitionerId: editId,
  //   skip: 0,
  //   sortby: 'updated_at',
  //   status: 'pending',
  //   take: 50,
  //   toDate: '',
  //   totalAmount: '',
  //   transactionId: '',
  //   visaType: 'all',
  // });

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Grid item md={12} sm={12}>
        {location?.pathname?.includes('manage-organization') ? (
          <Typography
            component={'div'}
            className="black-white-text mb-2"
            sx={{
              fontFamily: 'Arial-Bold !important',
              fontSize: '1.25em !important',
              marginLeft: '3px',
            }}>
            Organization : {''}
            {location?.pathname?.includes('invited')
              ? organizationData?.firm_name
              : organizationData?.orgName}
          </Typography>
        ) : (
          ''
        )}
        <Box sx={{ width: '100%' }} className="documentTab">
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                variant="scrollable"
                onChange={handleChange}
                aria-label="lab API tabs example">
                <Tab label="Payments" value="payment" />
                <Tab label="Goal sheet" value="contract" />
              </TabList>
            </Box>

            <Typography component={'div'}>
              {/* <TabPanel value={tabValue}>
                <Typography component={'div'}>
                  <Grid item md={12} sm={12} sx={{ paddingRight: '1.8rem' }}>
                    <Grid container>
                      {loader ? (
                        <div className="liberty-loader" />
                      ) : (
                        <Grid item md={12} sm={12} className="pl-0">
                          <TableContainer
                            sx={{
                              borderRadius: '10px 10px 0px 0px',
                            }}>
                            <Typography
                              component={'div'}
                              className={classes.scrollbox}
                              id="scrollParent">
                              <InfiniteScroll
                                dataLength={
                                  listData &&
                                  listData.data &&
                                  listData.data.length
                                    ? listData.data.length
                                    : 0
                                }
                                style={{
                                  overflow: 'unset',
                                }}
                                next={getMoreList && getMoreList}
                                hasMore={hasMoreResults}
                                scrollableTarget={'scrollParent'}
                                loader={
                                  getMoreLoader && (
                                    <div
                                      style={{
                                        backgroundColor: '#ffffff',
                                        textAlign: 'center',
                                        width: '100%',
                                      }}>
                                      <CircularProgress
                                        size={30}
                                        sx={{
                                          color: '#002c69',
                                          marginBottom: 10,
                                          marginTop: 10,
                                        }}
                                      />
                                    </div>
                                  )
                                }
                                endMessage>
                                <Table
                                  stickyHeader
                                  aria-label="sticky table"
                                  className="new-report-table table-hover"
                                  style={{
                                    minHeight: '106px',
                                    minWidth: '2000px',
                                  }}
                                  ref={tableRef}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Beneficiary</TableCell>
                                      <TableCell className="W-2">
                                        Visa
                                      </TableCell>
                                      {params.status === 'success' && (
                                        <TableCell>Transaction ID</TableCell>
                                      )}
                                      {params.status === 'success' && (
                                        <TableCell
                                          onClick={() =>
                                            handleSortRequest(
                                              'payment_done_through',
                                            )
                                          }>
                                          <TableSortLabel
                                            active={true}
                                            direction={
                                              orderDirection.payment_done_through
                                            }>
                                            Payment Method
                                          </TableSortLabel>
                                        </TableCell>
                                      )}
                                      <TableCell
                                        onClick={() =>
                                          handleSortRequest('payment_total')
                                        }>
                                        <TableSortLabel
                                          active={true}
                                          direction={
                                            orderDirection.payment_total
                                          }>
                                          Payment Amount
                                        </TableSortLabel>
                                      </TableCell>
                                      {params.status === 'success' && (
                                        <TableCell>
                                          Transaction Amount
                                        </TableCell>
                                      )}
                                      {params.status === 'success' && (
                                        <TableCell
                                          onClick={() =>
                                            handleSortRequest('total_amount')
                                          }>
                                          <TableSortLabel
                                            active={true}
                                            direction={
                                              orderDirection.total_amount
                                            }>
                                            Total Amount with transaction amount
                                          </TableSortLabel>
                                        </TableCell>
                                      )}
                                      {params.status === 'success' && (
                                        <TableCell>Status</TableCell>
                                      )}
                                      {params.status === 'success' && (
                                        <TableCell
                                          onClick={() =>
                                            handleSortRequest('updated_at')
                                          }>
                                          <TableSortLabel
                                            active={true}
                                            direction={
                                              orderDirection.updated_at
                                            }>
                                            Paid Date
                                          </TableSortLabel>
                                        </TableCell>
                                      )}
                                      {params.status === 'pending' && (
                                        <TableCell>Initiated Date</TableCell>
                                      )}
                                      {params.status === 'success' && (
                                        <TableCell>View</TableCell>
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {!_.isEmpty(listData?.data) &&
                                    listData.data.length > 0 ? (
                                      listData.data.map((data, index) => (
                                        <TableRow key={index}>
                                          <TableCell>
                                            {data?.beneficiaryName}
                                          </TableCell>
                                          <TableCell>
                                            {data?.visaType}
                                          </TableCell>
                                          {params.status === 'success' && (
                                            <TableCell>
                                              <Typography
                                                className="blue-text pointer"
                                                onClick={() =>
                                                  !loader
                                                    ? fileDownload(data)
                                                    : false
                                                }>
                                                {data?.transaction_id}
                                              </Typography>
                                            </TableCell>
                                          )}

                                          {params.status === 'success' && (
                                            <TableCell>
                                              {data?.payment_done_through ===
                                              'ach'
                                                ? 'ACH'
                                                : data?.payment_done_through ===
                                                  'card'
                                                ? 'Card'
                                                : '-'}
                                            </TableCell>
                                          )}
                                          <TableCell>
                                            $
                                            {parseFloat(
                                              data.payment_total,
                                            ).toFixed(2)}
                                          </TableCell>
                                          {params.status === 'success' && (
                                            <TableCell>
                                              {data?.transaction_amount
                                                ? `$${parseFloat(
                                                    data?.transaction_amount,
                                                  ).toFixed(2)}`
                                                : '$0.00'}
                                            </TableCell>
                                          )}
                                          {params.status === 'success' && (
                                            <TableCell>
                                              {data?.total_amount
                                                ? `$${parseFloat(
                                                    data?.total_amount,
                                                  ).toFixed(2)}`
                                                : '$0.00'}
                                            </TableCell>
                                          )}
                                          {params.status === 'success' && (
                                            <TableCell>
                                              {data?.response_message ===
                                              'APPROVAL'
                                                ? 'Success'
                                                : data?.response_message}
                                            </TableCell>
                                          )}
                                          {params.status === 'success' && (
                                            <TableCell>
                                              {data.updated_at
                                                ? moment(
                                                    data.updated_at,
                                                  ).format('DD,MMM,YYYY')
                                                : '-'}
                                            </TableCell>
                                          )}
                                          {params.status === 'pending' && (
                                            <TableCell>
                                              {data.updated_at
                                                ? moment(
                                                    data.created_at,
                                                  ).format('DD MMM, YYYY')
                                                : '-'}
                                            </TableCell>
                                          )}
                                          {params.status === 'success' && (
                                            <TableCell>
                                              <NavLink
                                                to={`${String(
                                                  AttorneyPrivateRoutes.LEADS
                                                    .CONTRACTPAYMENT,
                                                )}/${String(data.leadId)}`}>
                                                <Typography className="blue-text d-flex-1">
                                                  View
                                                </Typography>
                                              </NavLink>
                                            </TableCell>
                                          )}
                                        </TableRow>
                                      ))
                                    ) : (
                                      <TableRow>
                                        <TableCell colSpan={16}>
                                          <Typography className="d-flex d-flex-sb w-100">
                                            <Typography
                                              component={'div'}
                                              sx={{ paddingLeft: '.5rem' }}
                                              className="d-flex-1">
                                              <Typography
                                                className="ctitle"
                                                sx={{
                                                  opacity: '0.9',
                                                  textAlign: 'center',
                                                }}>
                                                No Record(s) Found.
                                              </Typography>
                                            </Typography>
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </InfiniteScroll>
                            </Typography>
                          </TableContainer>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Typography>
              </TabPanel> */}
              <TabPanel value="payment">
                <Typography component={'div'}>
                  <Grid item md={12} sm={12} sx={{ paddingRight: '1.8rem' }}>
                    <PaymentReport
                      tab={tabValue}
                      isOrg={true}
                      orgId={editId}
                      associatedPetitioner={
                        location?.pathname.includes('invited') ? false : true
                      }
                    />
                  </Grid>
                </Typography>
              </TabPanel>
              <TabPanel value="contract">
                <Typography component={'div'}>
                  <Grid item md={12} sm={12} sx={{ paddingRight: '1.8rem' }}>
                    <ContractReport
                      tab={tabValue}
                      isOrg={true}
                      orgId={editId}
                      associatedPetitioner={
                        location?.pathname.includes('invited') ? false : true
                      }
                    />
                  </Grid>
                </Typography>
              </TabPanel>
            </Typography>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
});

export default OrganizationContractPaymentList;
