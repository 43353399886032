import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Badge, Button, Drawer, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import Assemble from 'assets/images/assemble.png';
import Bedit from 'assets/images/bpedit.svg';
import Caseinfo from 'assets/images/caseinfo.svg';
import Casemenu from 'assets/images/casemenu.svg';
import Contract from 'assets/images/contractmenu.svg';
import Upload from 'assets/images/docupload.png';
import Petitions from 'assets/images/folderp.png';
import Home from 'assets/images/home.png';
import Layer from 'assets/images/layer.png';
import Leads from 'assets/images/leads.png';
import Logo from 'assets/images/liberty-logoheader.png';
import OvieRectangle from 'assets/images/ovie-rectangle.svg';
import Ovieim from 'assets/images/ovie-universal.png';
import Question from 'assets/images/questions.png';
import Stratey from 'assets/images/stratey.svg';
import subscription from 'assets/images/sub-icon1.png';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import LoginAs from 'pages/private/Client/LoginAs';
import React, { useEffect, useState } from 'react';
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { editFirmAction } from 'store/actions/FirmAction';
import {
  ParsingCount,
  storeI_129ExtractionLength,
} from 'store/actions/LeadsOrPetitionerAction';
import { logout } from 'store/actions/UserAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  USER_LOGIN_SUCCESS,
  USER_PROFILES_SUCCESS,
  USER_SIDEMENU_SUCCESS,
} from 'store/types/UserTypes';
import { MASTER_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions, ValidatePublicUrl } from 'utils/helper';

import Storage from '../utils/Storage';

const { edit } = editFirmAction;

type headConfigsProps = {
  menuOpen?: boolean;
  routes: any;
  showTools?: boolean;
  showHeader?: boolean;
  addEditRoute?: any;
  hasComponent?: string;
  isPetition?: boolean;
  DefaultHeaderHide?: boolean;
  setSubmissionPopup?: any;
};

type loginAsProps = {
  setIsShowLoginAs?: any;
  setAllLawFirm?: any;
  setAllBeneficiary?: any;
  setAllPetitioner?: any;
  isExitButtonView?: any;
};
export const LoginAsButtons: React.FC<any> = (props: loginAsProps) => {
  const {
    setAllLawFirm,
    setAllBeneficiary,
    setAllPetitioner,
    setIsShowLoginAs,
    isExitButtonView,
  } = props;
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const getAllUserData = () => {
    getAllListData(`${String(SETUP_ENDPOINT.SelectMenu)}/alllawfirm`)
      .then((firmResp: any) => {
        setAllBeneficiary(firmResp);
      })
      .catch((e) => {
        setIsShowLoginAs(false);
      });
    getAllListData(`${String(SETUP_ENDPOINT.SelectMenu)}/allbeneficiary`)
      .then((benResp: any) => {
        setAllLawFirm(benResp);
      })
      .catch((e) => {
        setIsShowLoginAs(false);
      });
    getAllListData(`${String(SETUP_ENDPOINT.SelectMenu)}/allpetitioner`)
      .then((petResp: any) => {
        setAllPetitioner(petResp);
      })
      .catch((e) => {
        setIsShowLoginAs(false);
      });
    setIsShowLoginAs(true);
  };

  const exitAs = () => {
    const TokenStatus: any = Storage.getAdminTokens();
    if (
      !_.isEmpty(TokenStatus) &&
      !Storage.isTokenExpired(TokenStatus?.token)
    ) {
      const userData: any = jwtDecode(TokenStatus.token);
      const tokenData: any = {
        token: TokenStatus?.token,
        userId: userData['data'].id,
      };
      if (userData['data'].userRoleName.toLowerCase() === 'admin') {
        Storage.removeItem('petitionerToken');
        Storage.removeItem('beneficiaryToken');
        Storage.removeItem('attorneyToken');
      }
      Storage.setItem('userRole', userData['data'].userRoleName.toLowerCase());
      Storage.setItem(
        userData['data'].userRoleName.toLowerCase().concat('Token'),
        JSON.stringify(tokenData),
      );
      dispatch({
        payload: userData['data'],
        type: USER_PROFILES_SUCCESS,
      });
      dispatch({
        payload: userData['data'],
        type: USER_LOGIN_SUCCESS,
      });
      getAllListData(MASTER_ENDPOINT.SideMenu).then((resp: any) => {
        dispatch({
          payload: resp,
          type: USER_SIDEMENU_SUCCESS,
        });
      });
    } else {
      !ValidatePublicUrl(location.pathname) &&
        toast.error('Session expired! Please login again. ', alertOptions);
      dispatch(logout());
    }
  };

  return (
    <Typography component={'div'}>
      {userDetail.role_id === 1 && (
        <Typography
          component={'span'}
          onClick={() => {
            getAllUserData();
          }}>
          <Typography
            component={'span'}
            className="home-text d-flex-a pointer"
            style={{ minWidth: '120px' }}>
            <Typography component={'div'} className="in-top" />
            <Typography component={'div'} className="inner-box" />
            <Typography
              component={'div'}
              className="text black-white-text d-flex-a">
              Login As
            </Typography>
          </Typography>
        </Typography>
      )}
      {isExitButtonView && (
        <Typography component={'span'} onClick={exitAs}>
          <Typography
            component={'span'}
            className="home-text d-flex-a pointer"
            style={{ minWidth: '70px' }}>
            <Typography component={'div'} className="in-top" />
            <Typography component={'div'} className="inner-box" />
            <Typography
              component={'div'}
              className="text black-white-text d-flex-a">
              Exit
            </Typography>
          </Typography>
        </Typography>
      )}
    </Typography>
  );
};

const BreadCrumbsHeader: React.FC<any> = (props: headConfigsProps) => {
  const {
    menuOpen,
    routes,
    showTools,
    showHeader,
    addEditRoute,
    isPetition,
    DefaultHeaderHide = false,
  } = props;

  const navigate = useNavigate();
  const { editId, petitionId } = useParams();
  const location: any = useLocation();
  const [searchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const [position, setPosition] = useState(window.pageYOffset);
  // const [visible, setVisible] = useState(true);
  const [allLawFirm, setAllLawFirm] = useState<any>([]);
  const [allPetitioner, setAllPetitioner] = useState<any>([]);
  const [allBeneficiary, setAllBeneficiary] = useState<any>([]);
  const [isShowLoginAs, setIsShowLoginAs] = useState(false);
  const [isExitButtonView, setExitButtonView] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { petitions } = useAppSelector((store: any) => store.petitionUser);
  const { userInfo: userDetail, sideMenu }: any = useAppSelector(
    (store: any) => store.userLogin,
  );
  const parsingData = useAppSelector((store) => store.i_129ExtractionLength);
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    top: false,
  });
  const [leadShow, setLeadShow] = useState(false);
  const [petitionsShow, setPetitionsShow] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleCloseLoginAs = () => {
    setIsShowLoginAs(false);
  };

  const getIcons = () => {
    switch (routes.icon) {
      case 'leads':
        return <img src={Leads} alt="im" className="mr-1" />;
      case 'petitions':
        return <img src={Petitions} alt="im" className="mr-1" />;
      case 'stratey':
        return <img src={Stratey} alt="im" className="mr-1" />;
      case 'caseinfo':
        return <img src={Caseinfo} alt="im" className="mr-1" />;
      case 'casemenu':
        return <img src={Casemenu} alt="im" className="mr-1" />;
      case 'docupload':
        return <img src={Upload} alt="im" className="mr-1" />;
      case 'question':
        return <img src={Question} alt="im" className="mr-1" />;
      case 'contract':
        return <img src={Contract} alt="im" className="mr-1" />;
      case 'assemble':
        return <img src={Assemble} alt="im" className="mr-1" />;
      case 'submission':
        return <img src={Layer} alt="im" className="mr-1" />;
      case 'subscription':
        return <img src={subscription} alt="im" className="mr-1" />;
      default:
        return null;
    }
  };

  const getDescription = () => {
    switch (routes.isDescription) {
      case 'manageUsers':
        return (
          // <Typography className=" user-dis pt-5 ">
          //   Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do
          //   eiusmod temporinci d idunt ut labore
          // </Typography>
          <Typography />
        );
      case 'managePetitioners':
        return (
          // <Typography className=" user-dis pt-5 ">
          //   Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do
          //   eiusmod temporinci d idunt ut labore
          // </Typography>
          <Typography />
        );
      case 'manageLawFirm':
        return (
          // <Typography className=" user-dis pt-5 ">
          //   Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do
          //   eiusmod temporinci d idunt ut labore
          // </Typography>
          <Typography />
        );
      case 'manageVisaTypes':
        return (
          // <Typography className=" user-dis pt-5 ">
          //   Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do
          //   eiusmod temporinci d idunt ut labore
          // </Typography>
          <Typography />
        );
      case 'configureQuestions':
        return (
          // <Typography className=" user-dis pt-5 ">
          //   Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do
          //   eiusmod temporinci d idunt ut labore
          // </Typography>
          <Typography />
        );
      case 'configureSections':
        return (
          // <Typography className=" user-dis pt-5 ">
          // Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do
          // eiusmod temporinci d idunt ut labore
          // </Typography>
          <Typography />
        );
      case 'manageOrganization':
        return (
          // <Typography className=" user-dis pt-5 ">
          //   Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do
          //   eiusmod temporinci d idunt ut labore
          // </Typography>
          <Typography />
        );
      case 'contractFees':
        return (
          // <Typography className="user-dis pt-5 ">
          //   Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do
          //   eiusmod temporinci d idunt ut labore
          // </Typography>
          <Typography />
        );
      case 'documentTemplate':
        return (
          // <Typography className=" user-dis pt-5 ">
          //   Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do
          //   eiusmod temporinci d idunt ut labore
          // </Typography>
          <Typography />
        );
      case 'leads':
        return (
          // <Typography className=" user-dis pt-5 ">
          //   There has been activity on two of your petitions since you last
          //   logged in. I have highlighted them for you.
          // </Typography>
          <Typography />
        );
      case 'petitions':
        return (
          // <Typography className="user-dis pt-5 ">
          //   This is your case menu where you and your team can work on any
          //   aspects of your immigration petition.
          // </Typography>
          <Typography />
        );
      case 'manageDocumentTypes':
        return (
          // <Typography className="user-dis pt-5 ">
          //   Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do
          //   eiusmod temporinci d idunt ut labore
          // </Typography>
          <Typography />
        );
      default:
        return '';
    }
  };

  const getParsingCount = async () => {
    if (userDetail?.role_id === 3) {
      const data = await ParsingCount(userDetail?.id);
      dispatch(storeI_129ExtractionLength(data));
    }
  };

  useEffect(() => {
    getParsingCount();
    if (userDetail.login_as_admin) {
      setExitButtonView(true);
    }
    return () => {
      if (open) {
        handleClose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sideMenu && !_.isEmpty(sideMenu?.sideMenuArray)) {
      sideMenu.sideMenuArray
        .find((indexval) => indexval.moduleId === 4)
        ?.moduleList.filter((menu) => {
          if (menu.screen_id === 11) {
            setLeadShow(true);
          }
          if (menu.screen_id === 28) {
            setPetitionsShow(true);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideMenu]);

  return (
    <Typography
      component={'div'}
      className="black-white-head"
      style={
        menuOpen
          ? {
              pointerEvents: 'none',
            }
          : {}
      }>
      <Typography component={'div'} className="w-1366">
        <Typography component={'div'} className="nav-flex-1 black-white-text">
          <img src={Logo} alt="logo" className="ov-logo" />
        </Typography>
        {showHeader && routes?.show ? (
          <Typography
            component={'div'}
            className={String('nav-menu nav-flex-').concat(
              userDetail.role_id === 1 ? '6' : '6',
            )}
            sx={{ justifyContent: 'unset !important' }}>
            <Typography component={'div'} className="home d-flex-ja">
              <NavLink to={`/${String(userDetail?.userRoleName)}/dashboard`}>
                <img src={Home} className="home35" alt="im" />
              </NavLink>
            </Typography>

            {/* Organizaiton Menu */}
            {searchParams.get('invited') === 'true' ||
            searchParams.get('associated') === 'true' ? (
              <Typography
                component={'div'}
                onClick={() => {
                  if (searchParams.get('orgId')) {
                    navigate(
                      `${String(
                        searchParams.get('invited')
                          ? AttorneyPrivateRoutes.MANAGEORGANIZATION
                              .ORGANIZATIONINVITED.ORGANIZATIONINVITEDOVERVIEW
                          : AttorneyPrivateRoutes.MANAGEORGANIZATION
                              .ORGANIZATIONASSOCIATED
                              .ORGANIZATIONASSOCIATEDOVERVIEW,
                      )}/${String(searchParams.get('orgId'))}`,
                    );
                    return;
                  }
                }}>
                <Typography
                  component={'div'}
                  className="home-text d-flex-a pointer">
                  <Typography component={'div'} className="in-top" />
                  <Typography component={'div'} className="inner-box" />
                  <Typography
                    component={'div'}
                    className="text black-white-text d-flex-a">
                    <img src={Casemenu} alt="im" className="mr-1" />
                    Organization
                  </Typography>
                </Typography>
              </Typography>
            ) : (
              ''
            )}

            {/* Organizaiton Menu */}

            {routes?.caseTitle !== '' &&
              !_.isUndefined(routes?.caseTitle) &&
              !searchParams.get('lead') && (
                <Typography
                  component={'div'}
                  onClick={() => {
                    if (
                      location.state &&
                      location.state?.isLead === true &&
                      location.state?.isOrg === false
                    ) {
                      if (userDetail.role_id === 5) {
                        navigate(
                          `${String(
                            AffilatePrivateRoutes.ATTORNEY_LEADS
                              .LEADDETAILSLIST,
                          )}/${String(location.state?.id)}`,
                        );
                        return;
                      } else {
                        navigate(
                          `${String(
                            userDetail.role_id === 3
                              ? AttorneyPrivateRoutes.LEADS.LEADDETAILSLIST
                              : PetitionerPrivateRoutes.LEADS.LEADDETAILSLIST,
                          )}/${String(location.state?.id)}`,
                        );
                        return;
                      }
                    }
                    if (
                      location.state &&
                      location.state?.isLead === false &&
                      location.state?.isOrg === false
                    ) {
                      if (userDetail.role_id === 5) {
                        navigate(
                          `${String(
                            AffilatePrivateRoutes.ATTORNEY_PETITIONS
                              .PETITIONLIST,
                          )}/${String(location.state?.id)}`,
                        );
                        return;
                      } else {
                        navigate(
                          `${String(
                            userDetail.role_id === 3
                              ? AttorneyPrivateRoutes.PETITIONS.PETITIONLIST
                              : PetitionerPrivateRoutes.PETITIONS.PETITIONLIST,
                          )}/${String(location.state?.id)}`,
                        );
                        return;
                      }
                    }
                    if (
                      searchParams.get('orgId') ||
                      location.state?.organization
                    ) {
                      if (
                        searchParams.get('invited') === 'true' ||
                        location.pathname.includes('invited')
                      ) {
                        const isLeadOrPetition =
                          searchParams.get('isLead') === 'true' ||
                          searchParams.get('isPetition') === 'true';
                        if (isLeadOrPetition) {
                          navigate(
                            `${String(
                              searchParams.get('isLead') === 'true'
                                ? AttorneyPrivateRoutes.MANAGEORGANIZATION
                                    .ORGANIZATIONINVITED
                                    .ORGANIZATIONINVITEDLEADDETAILS
                                : AttorneyPrivateRoutes.MANAGEORGANIZATION
                                    .ORGANIZATIONINVITED
                                    .ORGANIZATIONINVITEDPETITIONDETAILS,
                            )}/${String(
                              searchParams.get('isLead') === 'true'
                                ? editId
                                : location.state &&
                                  location.state?.isLead === false
                                ? location.state.id
                                : petitionId,
                            )}?orgId=${String(searchParams.get('orgId'))}`,
                          );
                          return;
                        } else {
                          navigate(
                            `${String(
                              location.pathname.includes('invited')
                                ? AttorneyPrivateRoutes.MANAGEORGANIZATION
                                    .ORGANIZATIONINVITED
                                    .ORGANIZATIONINVITEDOVERVIEW
                                : AttorneyPrivateRoutes.MANAGEORGANIZATION
                                    .ORGANIZATIONASSOCIATED
                                    .ORGANIZATIONASSOCIATEDOVERVIEW,
                            )}/${String(
                              searchParams.get('orgId')
                                ? searchParams.get('orgId')
                                : editId,
                            )}`,
                          );
                          return;
                        }
                      }

                      if (
                        searchParams.get('associated') === 'true' ||
                        location.pathname.includes('associated')
                      ) {
                        const isLeadOrPetition =
                          searchParams.get('isLead') === 'true' ||
                          searchParams.get('isPetition') === 'true';
                        if (isLeadOrPetition) {
                          navigate(
                            `${String(
                              searchParams.get('isLead') === 'true'
                                ? AttorneyPrivateRoutes.MANAGEORGANIZATION
                                    .ORGANIZATIONASSOCIATED
                                    .ORGANIZATIONASSOCIATEDLEADDETAILS
                                : AttorneyPrivateRoutes.MANAGEORGANIZATION
                                    .ORGANIZATIONASSOCIATED
                                    .ORGANIZATIONASSOCITEDPETITIONDETAILS,
                            )}/${String(
                              searchParams.get('isLead') === 'true'
                                ? editId
                                : location.state &&
                                  location.state?.isLead === false
                                ? location.state?.id
                                : petitionId,
                            )}?orgId=${String(searchParams.get('orgId'))}`,
                          );
                          return;
                        } else {
                          navigate(
                            `${String(
                              location.pathname.includes('invited')
                                ? AttorneyPrivateRoutes.MANAGEORGANIZATION
                                    .ORGANIZATIONINVITED
                                    .ORGANIZATIONINVITEDOVERVIEW
                                : AttorneyPrivateRoutes.MANAGEORGANIZATION
                                    .ORGANIZATIONASSOCIATED
                                    .ORGANIZATIONASSOCIATEDOVERVIEW,
                            )}/${String(
                              searchParams.get('orgId')
                                ? searchParams.get('orgId')
                                : editId,
                            )}`,
                          );
                          return;
                        }
                      } else {
                        navigate(
                          `${String(
                            location.pathname.includes('invited')
                              ? AttorneyPrivateRoutes.MANAGEORGANIZATION
                                  .ORGANIZATIONINVITED
                                  .ORGANIZATIONINVITEDOVERVIEW
                              : AttorneyPrivateRoutes.MANAGEORGANIZATION
                                  .ORGANIZATIONASSOCIATED
                                  .ORGANIZATIONASSOCIATEDOVERVIEW,
                          )}/${String(
                            searchParams.get('orgId')
                              ? searchParams.get('orgId')
                              : editId,
                          )}`,
                        );
                        return;
                      }
                    } else {
                      navigate(
                        `${String(routes?.path)}/${String(
                          petitionId ? petitionId : editId,
                        )}${
                          searchParams.get('leadUser')
                            ? `?leadUser=${searchParams.get('leadUser')}`
                            : ''
                        }`,
                      );
                      return;
                    }
                  }}>
                  <Typography
                    component={'div'}
                    className="home-text d-flex-a pointer">
                    <Typography component={'div'} className="in-top" />
                    <Typography component={'div'} className="inner-box" />
                    <Typography
                      component={'div'}
                      className="text black-white-text d-flex-a">
                      <img src={Casemenu} alt="im" className="mr-1" />
                      {routes?.caseTitle}
                    </Typography>
                  </Typography>
                </Typography>
              )}

            {routes?.title && (
              <Typography
                component={'div'}
                className="home-text d-flex-a"
                onClick={() => {
                  if (routes?.navigate && !searchParams.get('orgId')) {
                    if (routes?.isParamId) {
                      if (
                        routes?.title === 'Contract & Payment' &&
                        location.state?.isLead === true
                      ) {
                        navigate(
                          `${String(
                            (userDetail.role_id === 3
                              ? AttorneyPrivateRoutes
                              : PetitionerPrivateRoutes
                            ).LEADS.CONTRACTPAYMENT,
                          )}/${String(location.state?.id)}`,
                        );
                        return;
                      } else if (
                        routes?.title === 'Contract & Payment' &&
                        location.state?.isLead === false
                      ) {
                        navigate(
                          `${String(
                            (userDetail.role_id === 3
                              ? AttorneyPrivateRoutes
                              : PetitionerPrivateRoutes
                            ).PETITIONS.CONTRACTPAYMENT,
                          )}/${String(location.state?.id)}`,
                        );
                        return;
                      } else if (
                        routes?.title === 'Upload Documents' &&
                        location.state?.isLead === false
                      ) {
                        navigate(-1);
                        return;
                      } else {
                        navigate(
                          `${String(routes?.path)}/${String(
                            petitionId
                              ? petitionId
                              : searchParams.get('orgId')
                              ? searchParams.get('orgId')
                              : editId,
                          )}${
                            searchParams.get('leadUser')
                              ? `?leadUser=${searchParams.get('leadUser')}`
                              : ''
                          }`,
                        );
                        return;
                      }
                    }
                    navigate(routes?.path);
                  }
                }}>
                <Typography
                  component={'div'}
                  className="in-top"
                  sx={{ cursor: 'pointer' }}
                />
                <Typography component={'div'} className="inner-box" />
                {routes?.caseTitle !== '' &&
                !petitions?.petitionData?.isAffilateCase ? (
                  <>
                    <Typography
                      aria-describedby={id}
                      onClick={(e) => {
                        if (
                          [
                            'Assemble',
                            'Build Case',
                            // 'Brief Cover Pages',
                            'Submission Station',
                          ].includes(routes?.title)
                        ) {
                          userDetail.role_id === 3 && handleClick(e);
                        }
                      }}
                      component={'button'}
                      sx={{
                        backgroundColor: 'unset',
                        border: 'unset',
                        cursor: 'pointer',
                        height: '100%',
                        textTransform: 'capitalize',
                        width: '100%',
                        zIndex: 1,
                      }}
                      className="text black-white-text d-flex-a w-28">
                      {routes.icon && getIcons()}
                      {routes?.title}
                      {[
                        'Assemble',
                        'Build Case',
                        // 'Brief Cover Pages',
                        'Submission Station',
                      ].includes(routes?.title) &&
                        userDetail.role_id === 3 && (
                          <ArrowDropDownIcon
                            sx={{ fontSize: '45px', height: '30px' }}
                          />
                        )}
                    </Typography>
                    {[
                      'Build Case',
                      'Assemble',
                      // 'Brief Cover Pages',
                      'Submission Station',
                    ].includes(routes?.title) &&
                      userDetail.role_id === 3 && (
                        <Popover
                          id={id}
                          className="cdrop"
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            horizontal: 'left',
                            vertical: 'bottom',
                          }}>
                          <Typography className="drop-box" />
                          {routes?.title !== 'Build Case' && (
                            <Typography
                              className="d-text"
                              onClick={() => {
                                setAnchorEl(null);
                                navigate(
                                  `${String(
                                    AttorneyPrivateRoutes.PETITIONS.BUILDCASE,
                                  )}/${String(petitions.petitionData.id)}${
                                    searchParams.get('orgId')
                                      ? '?orgId=' + searchParams.get('orgId')
                                      : ''
                                  }${
                                    searchParams.get('invited')
                                      ? '&invited=true&isPetition=true'
                                      : searchParams.get('associated')
                                      ? '&associated=true&isPetition=true'
                                      : ''
                                  }`,
                                );
                              }}>
                              Build Case
                            </Typography>
                          )}
                          {routes?.title !== 'Assemble' && (
                            <Typography
                              className="d-text"
                              onClick={() => {
                                setAnchorEl(null);
                                navigate(
                                  `${String(
                                    AttorneyPrivateRoutes.PETITIONS.ASSEMBLE,
                                  )}/${String(petitions.petitionData.id)}${
                                    searchParams.get('orgId')
                                      ? '?orgId=' + searchParams.get('orgId')
                                      : ''
                                  }${
                                    searchParams.get('invited')
                                      ? '&invited=true&isPetition=true'
                                      : searchParams.get('associated')
                                      ? '&associated=true&isPetition=true'
                                      : ''
                                  }`,
                                );
                              }}>
                              Assemble
                            </Typography>
                          )}
                          {routes?.title !== 'Submission Station' && (
                            <Typography
                              className="d-text"
                              onClick={() => {
                                setAnchorEl(null);
                                navigate(
                                  `${String(
                                    AttorneyPrivateRoutes.PETITIONS
                                      .SUBMISSIONSTATION,
                                  )}/${String(petitions.petitionData.id)}${
                                    searchParams.get('orgId')
                                      ? '?orgId=' + searchParams.get('orgId')
                                      : ''
                                  }${
                                    searchParams.get('invited')
                                      ? '&invited=true&isPetition=true'
                                      : searchParams.get('associated')
                                      ? '&associated=true&isPetition=true'
                                      : ''
                                  }`,
                                );
                              }}>
                              Submission Station
                            </Typography>
                          )}
                          {/* {routes?.title !== 'Brief Cover Pages' && (
                            <Typography
                              className="d-text"
                              onClick={() => {
                                setAnchorEl(null);
                                navigate(
                                  `${String(
                                    AttorneyPrivateRoutes.PETITIONS.BRIEFCOVER,
                                  )}/${String(petitions.petitionData.id)}${
                            searchParams.get('orgId')
                              ? '?orgId=' + searchParams.get('orgId')
                              : ''
                          }${
                            searchParams.get('invited')
                              ? '&invited=true&isPetition=true'
                              : searchParams.get('associated')
                              ? '&associated=true&isPetition=true'
                              : ''
                          }`,
                                );
                              }}>
                              Brief Cover Pages
                            </Typography>
                          )} */}
                        </Popover>
                      )}
                  </>
                ) : (
                  <NavLink
                    to={
                      isPetition
                        ? !_.isEmpty(petitions) &&
                          petitions?.petitionData &&
                          `${String(routes?.path)}/${String(
                            petitions.petitionData.id,
                          )}`
                        : routes?.path
                    }>
                    <Typography
                      component={'div'}
                      className="text black-white-text d-flex-a w-28">
                      {routes.icon && getIcons()}
                      {routes?.title}
                    </Typography>
                  </NavLink>
                )}
              </Typography>
            )}
          </Typography>
        ) : (
          [1, 3].includes(userDetail.role_id) &&
          !DefaultHeaderHide && (
            <>
              <Typography
                component={'div'}
                className="nav-menu nav-flex-6 b-screen">
                <Typography
                  component={'div'}
                  className="nav-item black-white-text">
                  <NavLink
                    to={`/${String(userDetail?.userRoleName)}/dashboard`}
                    className={({ isActive }) =>
                      isActive ? 'item-active' : 'item-inactive'
                    }>
                    Dashboard
                  </NavLink>
                </Typography>
                {userDetail.role_id === 3 && leadShow && (
                  <Typography
                    component={'div'}
                    className="nav-item black-white-text">
                    <NavLink
                      to={`/${String(
                        userDetail?.userRoleName,
                      )}/petitions/leads`}
                      className={({ isActive }) =>
                        isActive ? 'item-active' : 'item-inactive'
                      }>
                      Leads
                    </NavLink>
                  </Typography>
                )}
                {userDetail.role_id === 3 && petitionsShow && (
                  <Typography
                    component={'div'}
                    className="nav-item black-white-text">
                    <NavLink
                      to={`/${String(
                        userDetail?.userRoleName,
                      )}/petitions/my-petitions`}
                      className={({ isActive }) =>
                        isActive ? 'item-active' : 'item-inactive'
                      }>
                      Petitions
                    </NavLink>
                  </Typography>
                )}
                {/* {userDetail.role_id === 3 && (
                  <Typography
                    component={'div'}
                    className="nav-item black-white-text">
                    <NavLink
                      to={`/${String(
                        userDetail?.userRoleName,
                      )}/dashboard/calendar`}
                      className={({ isActive }) =>
                        isActive ? 'item-active' : 'item-inactive'
                      }>
                      Calendar
                    </NavLink>
                  </Typography>
                )} */}
                {userDetail.role_id === 3 && (
                  <Typography
                    component={'div'}
                    className="nav-item black-white-text">
                    <NavLink
                      to={
                        AttorneyPrivateRoutes.MANAGEORGANIZATION
                          .ORGANIZATIONLIST
                      }
                      className={({ isActive }) =>
                        isActive ? 'item-active' : 'item-inactive'
                      }>
                      Organization
                    </NavLink>
                  </Typography>
                )}
                {userDetail.role_id === 3 && (
                  <Typography
                    component={'div'}
                    className="nav-item black-white-text">
                    <NavLink
                      to={`/${String(userDetail?.userRoleName)}/reports`}
                      className={({ isActive }) =>
                        isActive ? 'item-active' : 'item-inactive'
                      }>
                      Reports
                    </NavLink>
                  </Typography>
                )}
                <Typography
                  component={'div'}
                  className="nav-item black-white-text">
                  <NavLink
                    to={`/${String(userDetail?.userRoleName)}/social`}
                    className={({ isActive }) =>
                      isActive ? 'item-active' : 'item-inactive'
                    }>
                    Social
                  </NavLink>
                </Typography>
              </Typography>
              <Typography className="nav-flex-4 s-screen">
                <Button
                  className="nav-item black-white-text small-screen-btn"
                  onClick={toggleDrawer('top', true)}>
                  Menu
                </Button>
                <Drawer
                  className="small-screen-menu"
                  anchor="top"
                  open={state['top']}
                  onClose={toggleDrawer('top', false)}>
                  <Typography component={'div'} className="nav-menu">
                    <Typography
                      sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                      }}
                      onClick={toggleDrawer('top', false)}>
                      <CancelIcon
                        sx={{
                          cursor: 'pointer',
                          fill: 'gray',
                          height: '44px',
                          width: '44px',
                        }}
                      />
                    </Typography>

                    <Typography
                      component={'div'}
                      className="nav-item black-white-text">
                      <NavLink
                        to={`/${String(userDetail?.userRoleName)}/dashboard`}
                        className={({ isActive }) =>
                          isActive ? 'item-active' : 'item-inactive'
                        }
                        onClick={toggleDrawer('top', false)}>
                        Dashboard
                      </NavLink>
                    </Typography>

                    <Typography
                      component={'div'}
                      className="nav-item black-white-text">
                      <NavLink
                        to={`/${String(
                          userDetail?.userRoleName,
                        )}/petitions/leads`}
                        className={({ isActive }) =>
                          isActive ? 'item-active' : 'item-inactive'
                        }
                        onClick={toggleDrawer('top', false)}>
                        Leads
                      </NavLink>
                    </Typography>
                    <Typography
                      component={'div'}
                      className="nav-item black-white-text">
                      <NavLink
                        to={`/${String(
                          userDetail?.userRoleName,
                        )}/petitions/my-petitions`}
                        className={({ isActive }) =>
                          isActive ? 'item-active' : 'item-inactive'
                        }
                        onClick={toggleDrawer('top', false)}>
                        Petitions
                      </NavLink>
                    </Typography>
                    {userDetail.role_id === 3 && (
                      <Typography
                        component={'div'}
                        className="nav-item black-white-text">
                        <NavLink
                          to={`/${String(
                            userDetail?.userRoleName,
                          )}/dashboard/calendar`}
                          className={({ isActive }) =>
                            isActive ? 'item-active' : 'item-inactive'
                          }
                          onClick={toggleDrawer('top', false)}>
                          Calendar
                        </NavLink>
                      </Typography>
                    )}
                    {userDetail.role_id === 3 && (
                      <Typography
                        component={'div'}
                        className="nav-item black-white-text">
                        <NavLink
                          to={`/${String(
                            userDetail?.userRoleName,
                          )}/manage-organization`}
                          className={({ isActive }) =>
                            isActive ? 'item-active' : 'item-inactive'
                          }
                          onClick={toggleDrawer('top', false)}>
                          Manage Organization
                        </NavLink>
                      </Typography>
                    )}
                    {userDetail.role_id === 3 && (
                      <Typography
                        component={'div'}
                        className="nav-item black-white-text">
                        <NavLink
                          to={`/${String(userDetail?.userRoleName)}/reports`}
                          className={({ isActive }) =>
                            isActive ? 'item-active' : 'item-inactive'
                          }
                          onClick={toggleDrawer('top', false)}>
                          Reports
                        </NavLink>
                      </Typography>
                    )}

                    {userDetail.role_id === 3 && (
                      <Typography
                        component={'div'}
                        className="nav-item black-white-text">
                        <NavLink
                          to={`/${String(userDetail?.userRoleName)}/social`}
                          className={({ isActive }) =>
                            isActive ? 'item-active' : 'item-inactive'
                          }
                          onClick={toggleDrawer('top', false)}>
                          Social
                        </NavLink>
                      </Typography>
                    )}
                  </Typography>
                </Drawer>
              </Typography>
            </>
          )
        )}

        {isShowLoginAs && (
          <LoginAs
            open={isShowLoginAs}
            close={handleCloseLoginAs}
            allLawFirm={allLawFirm}
            allPetitioner={allPetitioner}
            allBeneficiary={allBeneficiary}
          />
        )}

        <Typography component={'div'} className="nav-flex-1 d-flex-b flex-end">
          <LoginAsButtons
            setAllLawFirm={setAllLawFirm}
            setAllBeneficiary={setAllBeneficiary}
            setAllPetitioner={setAllPetitioner}
            setIsShowLoginAs={setIsShowLoginAs}
            isExitButtonView={isExitButtonView}
          />

          {userDetail?.role_id === 3 ? (
            <Badge
              badgeContent={Number(
                parsingData && Array.isArray(parsingData)
                  ? parsingData.length
                  : 0,
              )}
              color="primary"
              sx={{
                marginRight: '8px',
              }}>
              <NotificationsNoneIcon
                fontSize="large"
                sx={{
                  color: '#ffff',
                  cursor: 'pointer',
                }}
                {...(parsingData &&
                Array.isArray(parsingData) &&
                parsingData.length
                  ? {
                      onClick: () =>
                        navigate(
                          AttorneyPrivateRoutes.DOCUMENT.DOCUMENTPARSING,
                        ),
                    }
                  : {})}
              />
            </Badge>
          ) : (
            ''
          )}
          <Typography sx={{ position: 'relative' }}>
            <Typography
              className="oviebox"
              sx={{
                position: 'relative',
                top: '7px',
                zIndex: 3,
              }}
              component={'img'}
              src={OvieRectangle}
              alt="imgae"
            />
            <Typography
              className="p-image"
              style={{ backgroundImage: `url(${String(Ovieim)})` }}
            />
            {showTools && (
              <Typography
                component={'div'}
                className="add-box pb-2"
                sx={{
                  position: 'absolute',
                  right: '16px',
                  top: '79px',
                  zIndex: 1,
                }}>
                <Typography component={'div'} className="add-inner-box" />
                {routes.isDescription && getDescription()}
                {addEditRoute && (
                  <Typography
                    className="black-white-text d-flex-a a-mt-3"
                    style={{ marginTop: '18px' }}
                    onClick={() => {
                      if (addEditRoute.path === '/attorney/setup/my-firm') {
                        dispatch(edit());
                      } else {
                        // navigate(addEditRoute?.path);
                        navigate(
                          `${String(addEditRoute?.path)}${
                            !_.isUndefined(location?.state?.orgId) &&
                            location?.state?.orgId
                              ? '?orgId=' + String(location?.state?.orgId)
                              : ''
                          }${
                            location.pathname.includes('invited')
                              ? '&invited=true'
                              : location.pathname.includes('associated')
                              ? '&associated=true'
                              : ''
                          }`,
                        );
                      }
                    }}>
                    {addEditRoute.isAdd && (
                      <span className="plus d-flex-ja">+</span>
                    )}
                    {addEditRoute.isEdit && (
                      <span className="mr-1">
                        <img
                          src={Bedit}
                          alt="edit"
                          style={{ height: '24px', width: '27px' }}
                        />
                      </span>
                    )}

                    {addEditRoute?.title}
                  </Typography>
                )}
              </Typography>
            )}
          </Typography>
        </Typography>
      </Typography>
    </Typography>
  );
};

export default BreadCrumbsHeader;
