import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { logger } from 'redux-logger';
import thunk from 'redux-thunk';

import { MyfirmReducer } from './reducers/FirmReducer';
import {
  i_129ExtractionLengthReducer,
  leadSaveReducer,
  organizationSaveReducer,
  petitionerSaveReducer,
} from './reducers/LeadsOrPetitionerReducer';
import { captchaReducer, userLoginReducer } from './reducers/UserReducer';

const reducers = combineReducers({
  captcha: captchaReducer,
  firm: MyfirmReducer,
  i_129ExtractionLength: i_129ExtractionLengthReducer,
  leadUser: leadSaveReducer,
  organizationData: organizationSaveReducer,
  petitionUser: petitionerSaveReducer,
  userLogin: userLoginReducer,
});

let middlewares = [];

if (process.env.REACT_APP_ENV === 'LOCAL') {
  middlewares = [thunk, logger];
} else {
  middlewares = [thunk];
}

// const middlewareEnhancer = composeWithDevTools(applyMiddleware(...middlewares));

// const enhancers = [middlewareEnhancer];
// const composedEnhancers = compose(...enhancers);

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
