import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography } from '@mui/material';
import ArrowLeft from 'assets/images/arrow-left.svg';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { AffilatePrivateRoutes, AttorneyPrivateRoutes } from 'config';
import { instantState } from 'hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import {
  COMMON_ENDPOINT,
  MASTER_ENDPOINT,
  SETUP_ENDPOINT,
} from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { LeadCreateStepOneSchema } from 'utils/ValidatorSchema';

import AddNote from '../../Common/AddNote';
import AttorneyForm from './AttorneyForm';
import BeneficiaryAndPetitionerForm from './BeneficiaryAndPetitionerForm';

export const LeadAdd: React.FC<any> = () => {
  const navigate: any = useNavigate();
  const [searchParams] = useSearchParams();
  const { editId } = useParams();
  const [loader, setLoader] = useState(true);
  const [formSteps, setFormSteps] = useState(1);
  const [organizations, setOrganizations] = useState<any>([]);
  const [visaTypes, setVisaTypes] = useState<any>([]);
  const [firmData, setFirmData] = useState<any>([]);
  const [processingTypes, setProcessingTypes] = useState<any>([]);
  const [filingTypes, setFilingTypes] = useState<any>([]);
  const [attorneyContactPersonList, setAttorneyContactPersonList] =
    useState<any>([]);
  const [attorneyInitDetails, setAttorneyInitDetails] = useState<any>({
    attorneyCountryCode: '+1',
    attorneyEmail: '',
    attorneyName: '',
    attorneyPosition: '',
    attorneyTelephone: '',
    // attorneyContactPerson: '',
    // caseManager: '',
    // officer: [],
  });
  const [attorneySupportIds, setAttorneySupportIds] = useState<any>([]);
  const [additionalAttorneyContactIds, setAdditionalAttorneyContactIds] =
    useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [countDetails, setCountDetails] = useState<any>({
    pending: 0,
    total: 0,
  });
  const [formOneData, setFormOneData] = useState(null);
  const [leadData, setLeadData] = useState<any>({});
  const [deleteDialog, setDeleteDialog] = useState({
    deleteId: null,
    deleteType: '',
    index: null,
    show: false,
  });
  // const [uploadCheck, setUploadCheck] = useState(true);
  // const [isDocumentReview, setIsDocumentReview] = useState<any>({
  //   reviewDoc: false,
  //   token: null,
  // });
  const [viewAttorneyNotes, setViewAttorneyNotes] = useState<any>({
    leadOrPetitionId: '',
    show: false,
    type: '',
  });

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const {
    control: controlOne,
    handleSubmit: handleSubmitOne,
    reset: resetFormOne,
    setValue: setValueOne,
    getValues: getValueOne,
    setError: setErrorOne,
    watch: watchOne,
    formState: { errors: errorsOne, isValid: formOneIsValid },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      affilateDetails: [],
      affiliateType: 'new',
      beneficiaryDetails: [],
      beneficiaryEditDetails: [],
      contactEmail: '',
      contactLastName: '',
      contactMobileCountryCode: '',
      contactMobileNumber: '',
      contactMobileSocCode: '',
      contactPersonName: '',
      leadOrgType: 'new',
      orgName: '',
      orgType: 'existing',
      petitionerAssociated: false,
      petitionerContactDetails: [],
      petitionerContactId: null,
      petitionerId: null,
      sendMail: false,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LeadCreateStepOneSchema),
  });

  const {
    control: controlThree,
    handleSubmit: handleSubmitThree,
    reset: resetFormThree,
    setValue: setValueThree,
    watch: watchThree,
    formState: { errors: errorsThree },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      additionalAttorneyContacts: [],
      attorneyContactId: '',
      attorneySupport: [],
      caseManager: [],
      convictions: 'no',
      convictionsExplain: null,
      employmentOffered: 'no',
      employmentOfferedExplain: null,
      fillingType: null,
      leadEmail: '',
      leadNotes: '',
      leadStatus: 'prospect',
      numberOfDependents: null,
      overstayUsVisa: 'no',
      overstayUsVisaExplain: null,
      premiumProcessing: 'no',
      processingType: null,
      totalEmployeesInCompany: null,
      visaDenials: 'no',
      visaDenialsExplain: null,
      visaType: [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LeadCreateStepOneSchema),
  });

  const {
    fields: beneficiaryFields,
    append: beneficiaryAppend,
    remove: beneficiaryFieldRemove,
    update: beneficiaryUpdate,
  } = useFieldArray({ control: controlOne, name: 'beneficiaryDetails' });

  const {
    fields: affiliateFields,
    append: affiliateAppend,
    remove: affiliateFieldRemove,
    update: affiliateUpdate,
  } = useFieldArray({ control: controlOne, name: 'affilateDetails' });

  const {
    fields: petitionerContactFields,
    append: petitionerContactAppend,
    remove: petitionerContactFieldRemove,
    update: additionalPetitionerUpdate,
  } = useFieldArray({ control: controlOne, name: 'petitionerContactDetails' });

  const cancelFormOne = () => {
    resetFormOne();
    resetFormThree();
    const removedIndex: Array<number> = [];
    beneficiaryFields.map((e: any, index: number) => {
      removedIndex.push(index);
    });
    beneficiaryFieldRemove(removedIndex);
    const removedAfIndex: Array<number> = [];
    affiliateFields.map((e: any, index: number) => {
      removedAfIndex.push(index);
    });
    affiliateFieldRemove(removedAfIndex);

    const removedPcfIndex: Array<number> = [];
    petitionerContactFields.map((e: any, index: number) => {
      removedPcfIndex.push(index);
    });
    petitionerContactFieldRemove(removedPcfIndex);
    if (editId && searchParams.get('fromPetition')) {
      navigate(`${AttorneyPrivateRoutes.LEADS.LEADDETAILSLIST}/${editId}`);
    } else {
      navigate(-1);
    }
  };
  const cancelforStepOne = () => {
    if (!location.pathname.includes('add-leads') && userDetail.role_id === 3) {
      navigate(
        `${AttorneyPrivateRoutes.LEADS.LEADDETAILSLIST}/${editId}${
          searchParams.get('orgId') ? '?orgId=' + searchParams.get('orgId') : ''
        }${
          searchParams.get('invited')
            ? '&invited=true&isLead=true'
            : searchParams.get('associated')
            ? '&associated=true&isLead=true'
            : ''
        }`,
      );
    } else if (userDetail.role_id === 5) {
      navigate(
        `${AffilatePrivateRoutes.ATTORNEY_LEADS.LEADDETAILSLIST}/${editId}`,
      );
    } else {
      navigate(-1);
    }
  };

  const onSubmitStepOne = (data: any) => {
    if (data.beneficiaryDetails && data.beneficiaryDetails.length) {
      const uniqueValues = new Set(
        data.beneficiaryDetails
          .filter((v) => v.beneficiaryEmail)
          .map((e) => e.beneficiaryEmail.toLocaleLowerCase().trim()),
      );
      if (
        uniqueValues.size <
        data.beneficiaryDetails.filter((v) => v.beneficiaryEmail).length
      ) {
        toast.error(
          'An email address can only be associated with one client/entity ',
          alertOptions,
        );
        return;
      } else if (editId && data.beneficiaryDetails.some((e) => !e.disable)) {
        toast.error('Please save beneficiary details', alertOptions);
        return;
      }
    }
    if (data.affilateDetails && data.affilateDetails.length) {
      const uniqueValues = new Set(
        data.affilateDetails
          .filter((v) => v.affilateEmail)
          .map((e) => e.affilateEmail.toLocaleLowerCase().trim()),
      );
      if (
        uniqueValues.size <
        data.affilateDetails.filter((v) => v.affilateEmail).length
      ) {
        toast.error(
          'An email address can only be associated with one client/entity ',
          alertOptions,
        );
        return;
      } else if (editId && data.affilateDetails.some((e) => !e.disable)) {
        toast.error('Please save affiliate details', alertOptions);
        return;
      }
    }
    if (data.petitionerContactDetails && data.petitionerContactDetails.length) {
      const uniqueValues = new Set(
        data.petitionerContactDetails
          .filter((v) => v.petitionerEmail)
          .map((e) => e.petitionerEmail.toLocaleLowerCase().trim()),
      );
      if (
        uniqueValues.size <
        data.petitionerContactDetails.filter((v) => v.petitionerEmail).length
      ) {
        toast.error(
          'An email address can only be associated with one client/entity ',
          alertOptions,
        );
        return;
      } else if (
        editId &&
        data.petitionerContactDetails.some((e) => !e.disable)
      ) {
        toast.error('Please save additional petitioner details', alertOptions);
        return;
      }
    }
    data['attorneyId'] = leadData?.isAffilateCase
      ? leadData.attorney_assigned
      : userDetail.companyId;
    setFormOneData(data);
    setFormSteps(2);
  };

  // const onSubmitStepTwo = (data: any) => {
  //   if (data.petitionerContactDetails && data.petitionerContactDetails.length) {
  //     const uniqueValues = new Set(
  //       data.petitionerContactDetails
  //         .filter((v) => v.petitionerEmail)
  //         .map((e) => e.petitionerEmail.toLocaleLowerCase().trim()),
  //     );
  //     if (
  //       uniqueValues.size <
  //       data.petitionerContactDetails.filter((v) => v.petitionerEmail).length
  //     ) {
  //       toast.error(
  //         'One email address can be associated with only one ',
  //         alertOptions,
  //       );
  //       return;
  //     } else if (
  //       editId &&
  //       data.petitionerContactDetails.some((e) => !e.disable)
  //     ) {
  //       toast.error('Please save additional petitioner details', alertOptions);
  //       return;
  //     }
  //   }
  //   setFormTwoData(data);
  //   setFormSteps(3);
  // };

  const onSubmitStepThree = (data: any) => {
    if (data['attorneyContactId']) {
      data['employmentOffered'] =
        data['employmentOffered'] === 'no' ? false : true;
      data['visaDenials'] = data['visaDenials'] === 'no' ? false : true;
      data['convictions'] = data['convictions'] === 'no' ? false : true;
      data['overstayUsVisa'] = data['overstayUsVisa'] === 'no' ? false : true;

      setLoader(true);
      // data['leadEmail'] = editData.email ? editData.email : '';
      data['leadEmail'] = '';
      data['visaType'] = data['visaType']
        ? Array.isArray(data['visaType'])
          ? data['visaType']
          : [data['visaType']]
        : [];
      data['caseManager'] = data['caseManager']
        ? Array.isArray(data['caseManager'])
          ? data['caseManager']
          : [data['caseManager']]
        : [];
      data['additionalAttorneyContacts'] = data['additionalAttorneyContacts']
        ? Array.isArray(data['additionalAttorneyContacts'])
          ? data['additionalAttorneyContacts']
          : [data['additionalAttorneyContacts']]
        : [];
      data['attorneySupport'] = data['attorneySupport']
        ? Array.isArray(data['attorneySupport'])
          ? data['attorneySupport']
          : [data['attorneySupport']]
        : [];

      formOneData['contactMobileNumber'] = String(
        formOneData['contactMobileNumber'],
      ).split(/(?<=^\S+)\s/)[1];

      const formData = {
        ...formOneData,
        ...data,
        fillingType:
          data?.fillingType && String(data?.fillingType).trim()
            ? data?.fillingType
            : null,
        numberOfDependents:
          data?.numberOfDependents && String(data?.numberOfDependents).trim()
            ? data?.numberOfDependents
            : null,
        processingType:
          data?.processingType && String(data?.processingType).trim()
            ? data?.processingType
            : null,
        totalEmployeesInCompany:
          data?.totalEmployeesInCompany &&
          String(data?.totalEmployeesInCompany).trim()
            ? data?.totalEmployeesInCompany
            : null,
        // eslint-disable-next-line sort-keys-fix/sort-keys-fix
        affilateDetails:
          formOneData &&
          formOneData.affilateDetails &&
          formOneData.affilateDetails.length
            ? formOneData.affilateDetails.map((val: any) => {
                return {
                  ...val,
                  affilateMobileNumber: val.affilateMobileNumber
                    ? String(val.affilateMobileNumber).split(/(?<=^\S+)\s/)[1]
                    : '',
                  id: val.ids,
                };
              })
            : [],
        beneficiaryDetails:
          formOneData &&
          formOneData.beneficiaryDetails &&
          formOneData.beneficiaryDetails.length
            ? formOneData.beneficiaryDetails.map((val: any) => {
                return {
                  ...val,
                  beneficiaryMobileNumber: val.beneficiaryMobileNumber
                    ? String(val.beneficiaryMobileNumber).split(
                        /(?<=^\S+)\s/,
                      )[1]
                    : '',
                  id: val.ids,
                };
              })
            : [],
        leadOrgType: formOneData.orgType,
        ...(watchOne('orgType') === 'new' || watchOne('orgType') === 'sameas'
          ? { petitionerAssociated: false }
          : {}),
        orgType:
          formOneData.orgType === 'existing'
            ? formOneData.petitionerAssociated
              ? 'existing'
              : 'new'
            : formOneData.orgType,
        petitionerContactDetails:
          formOneData &&
          formOneData.petitionerContactDetails &&
          formOneData.petitionerContactDetails.length
            ? formOneData.petitionerContactDetails.map((val: any) => {
                return {
                  ...val,
                  id: val.ids,
                  petitionerMobileNumber: val.petitionerMobileNumber
                    ? String(val.petitionerMobileNumber).split(/(?<=^\S+)\s/)[1]
                    : '',
                };
              })
            : [],
        petitionerContactId: formOneData?.petitionerContactId
          ? formOneData?.petitionerContactId
          : null,
        petitionerId:
          organizations && organizations.length
            ? organizations.find(
                (e) => e.ids === Number(formOneData.petitionerId),
              )?.id
            : null,
        ...(watchOne('orgType') === 'sameas'
          ? {
              orgName: '',
              petitionerAssociated: false,
              petitionerContactId: null,
              petitionerId: null,
            }
          : {}),
      };
      if (!_.isUndefined(editId)) {
        updateData(
          '',
          formData,
          `${COMMON_ENDPOINT.CreateLead}/${editId}?isAffilate=${String(
            leadData?.isAffilateCase,
          )}&affilateRoleId=${[3].includes(leadData?.affilateRoleId) ? 3 : ''}`,
        )
          .then((resp: any) => {
            cancelFormOne();
            toast.success(resp?.message, alertOptions);
            return;
            // eslint-disable-next-line no-unreachable
            setLoader(false);
          })
          .catch((e) => {
            setLoader(false);
          });
      } else {
        addNewData(formData, COMMON_ENDPOINT.CreateLead)
          .then((resp: any) => {
            cancelFormOne();
            toast.success(resp?.message, alertOptions);
            return;
          })
          .catch((e) => {
            setLoader(false);
          });
      }
    } else {
      toast.error('Contact person should not be empty', alertOptions);
      return;
    }
  };

  const getInitialStatus = useCallback(async () => {
    return new Promise((resolve, reject) => {
      setLoader(true);
      getAllListData(
        `${COMMON_ENDPOINT.LeadList}?id=${String(
          editId,
        )}&status=&skip=0&take=10&sortby=&order=&visaId=&organizationId=&orgType=&leadsSelected=&associated=&name=`,
      )
        .then((resp) => {
          const {
            affilateUserList,
            additional_attorney,
            attorney_assigned,
            attorney_contact_id,
            attorney_support,
            case_manager,
            beneficiaryDetails,
            org_contact,
            org_contact_last,
            mail_trigger,
            org_type,
            org_name,
            org_mail,
            org_mobile_number,
            lead_status,
            convictions,
            convictions_explain,
            employees_in_company,
            employment_offered,
            employment_offered_explain,
            notes,
            number_of_dependents,
            overstay_us_visa,
            overstay_us_visa_explain,
            petitionerContactDetails,
            petitioner_assigned,
            petitioner_associated,
            petitioner_conatact_id,
            premium_processing,
            type_of_filing,
            type_of_processing,
            visa_denials,
            visa_denials_explain,
            visa_type,
            isAffilateCase,
            affilateRoleId,
          } = resp.data[0];

          if (
            userDetail.userRoleName === 'attorney' ||
            (isAffilateCase && affilateRoleId === 3)
          ) {
            getSkilBukDocCount(editId);
          }
          getAttorenyContactPersonList(
            isAffilateCase ? attorney_assigned : userDetail.companyId,
            attorney_contact_id ? attorney_contact_id : '',
          );
          setLeadData(resp.data && resp.data.length > 0 ? resp.data[0] : {});
          resolve(resp.data[0]);

          setValueOne('contactEmail', org_mail || '');
          setValueOne('contactPersonName', org_contact || '');
          setValueOne('contactLastName', org_contact_last || '');
          setValueOne('contactMobileNumber', org_mobile_number || '');
          setValueOne('orgName', org_name || '');
          setValueOne('orgType', org_type === 'sameas' ? 'sameas' : 'existing');
          setValueOne('petitionerAssociated', petitioner_associated || false);
          setValueOne(
            'petitionerContactId',
            Number(petitioner_conatact_id) || null,
          );
          setValueOne('petitionerId', Number(petitioner_assigned) || null);
          setValueOne('sendMail', mail_trigger);
          setValueThree(
            'attorneySupport',
            attorney_support ? attorney_support : [],
          );
          setValueThree('attorneyContactId', attorney_contact_id || '');
          setValueThree(
            'caseManager',
            case_manager && Array.isArray(case_manager) ? case_manager : [],
          );
          setValueThree(
            'additionalAttorneyContacts',
            additional_attorney || [],
          );
          setValueThree('convictions', convictions ? 'yes' : 'no' || 'no');
          setValueThree('convictionsExplain', convictions_explain || null);
          setValueThree(
            'employmentOffered',
            employment_offered ? 'yes' : 'no' || 'no',
          );
          setValueThree(
            'employmentOfferedExplain',
            employment_offered_explain || null,
          );
          setValueThree(
            'overstayUsVisa',
            overstay_us_visa ? 'yes' : 'no' || 'no',
          );
          setValueThree(
            'overstayUsVisaExplain',
            overstay_us_visa_explain || null,
          );
          setValueThree('visaDenials', visa_denials ? 'yes' : 'no' || 'no');
          setValueThree('visaDenialsExplain', visa_denials_explain || null);
          setValueThree(
            'premiumProcessing',
            premium_processing === 'yes' ? 'yes' : 'no' || 'no',
          );
          setValueThree('processingType', type_of_processing || null);
          setValueThree('fillingType', type_of_filing || null);
          setValueThree('leadNotes', notes || '');
          setValueThree('leadStatus', lead_status || 'prospect');
          setValueThree('numberOfDependents', number_of_dependents || null);
          setValueThree(
            'totalEmployeesInCompany',
            employees_in_company || null,
          );
          setValueThree(
            'visaType',
            visa_type && Array.isArray(visa_type) ? visa_type[0] : '',
          );
          if (beneficiaryDetails.length) {
            beneficiaryDetails.map((resp, index) =>
              beneficiaryAppend({
                beneficiaryEmail: resp.email_id,
                beneficiaryFirstName: resp.first_name,
                beneficiaryInviteSent: resp.beneficiary_invite_sent,
                beneficiaryLastName: resp.last_name,
                beneficiaryMobileCode: resp.country_source_code,
                beneficiaryMobileNumber: resp.contact_mobile
                  ? String(resp.contact_mobile).indexOf('+') !== -1
                    ? resp.contact_mobile
                    : resp.country_code_mobile
                    ? String(resp.country_code_mobile)
                        .concat(' ')
                        .concat(resp.contact_mobile)
                    : '+1 '.concat(resp.contact_mobile)
                  : '',
                disable: true,
                id: resp.user_id,
                ids: resp.user_id,
                leadUserId: resp.id,
                loginCheck:
                  resp.conatct_status && resp.conatct_status !== 'pending'
                    ? true
                    : false,
                mobileCountryCode: resp.country_code_mobile,
                mobileSocCode: resp.country_source_code,
                sendEmailDisable:
                  resp.conatct_status && resp.conatct_status !== 'pending'
                    ? true
                    : false,
                sendMail: resp.conatct_status === 'active' ? true : false,
                status: resp.petiton_started,
                type: 'existing',
                userId: resp.user_id,
              }),
            );
          }
          if (affilateUserList.length) {
            affilateUserList.map((resp, index) =>
              affiliateAppend({
                affilateEmail: resp.email_id,
                affilateFirstName: resp.first_name,
                affilateLastName: resp.last_name,
                affilateMobileNumber: resp.contact_mobile
                  ? String(resp.contact_mobile).indexOf('+') !== -1
                    ? resp.contact_mobile
                    : resp.country_code_mobile
                    ? String(resp.country_code_mobile)
                        .concat(' ')
                        .concat(resp.contact_mobile)
                    : '+1 '.concat(resp.contact_mobile)
                  : '',
                affilateRoleId: resp.affilate_role_id,
                disable: true,
                id: resp.user_id,
                ids: resp.user_id,
                loginCheck:
                  resp.conatct_status && resp.conatct_status !== 'pending'
                    ? true
                    : false,
                mobileCountryCode: resp.country_code_mobile,
                mobileSocCode: resp.country_source_code,
                sendEmailDisable:
                  resp.conatct_status && resp.conatct_status !== 'pending'
                    ? true
                    : false,
                sendMail: resp.conatct_status === 'active' ? true : false,
                status: '',
                type: 'existing',
                userId: resp.user_id,
              }),
            );
          }
          if (petitionerContactDetails.length) {
            petitionerContactDetails.map((resp, index) =>
              petitionerContactAppend({
                contactMobileCountryCode: resp.contact_country_code,
                contactMobileSocCode: resp.contact_soc_code,
                disable: true,
                ids: resp.id,
                leadId: resp.lead_id,
                orgName: resp.org_name,
                petitionerEmail: resp.contact_email,
                petitionerFirstName: resp.contact_first_name,
                petitionerLastName: resp.contact_last_name,
                petitionerMobileCode: resp.contact_soc_code,
                petitionerMobileNumber: resp.contact_mobile_number
                  ? String(resp.contact_mobile_number).indexOf('+') !== -1
                    ? resp.contact_mobile_number
                    : resp.contact_country_code
                    ? String(resp.contact_country_code)
                        .concat(' ')
                        .concat(resp.contact_mobile_number)
                    : '+1 '.concat(resp.contact_mobile_number)
                  : '',
                roleId: resp.role_id,
                sendMail: false,
                status: '',
              }),
            );
          }
          // setLoader(false);
        })
        .catch((e) => {
          reject(e);
          setLoader(false);
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSkilBukDocCount = (leadId: any) => {
    getAllListData(
      `${COMMON_ENDPOINT.DocumentParse}/bulkdoc/count?id=${String(
        leadId,
      )}&uploadFor=lead`,
    )
      .then((resp) => {
        setCountDetails((pre) => ({
          ...pre,
          pending: !_.isEmpty(resp)
            ? resp.pendingCount
              ? resp.pendingCount
              : 0
            : 0,
        }));
      })
      .catch((e) => {
        toast.error(e, alertOptions);
      });
  };

  const chooseAttorneyDetails = useCallback(
    (selectedPerson) => {
      if (!_.isNull(selectedPerson)) {
        if (_.isUndefined(selectedPerson)) {
          return;
        }
        attorneyContactPersonList.map((ele) => {
          if (ele.id === Number(selectedPerson)) {
            setAttorneyInitDetails({
              attorneyCountryCode: '+1',
              attorneyEmail: ele.email_id,
              attorneyPosition: ele.designation,
              attorneyTelephone: ele.contact_mobile ? ele.contact_mobile : '',
            });
          }
        });
      } else {
        setAttorneyInitDetails({
          attorneyCountryCode: '+1',
          attorneyEmail: '',
          attorneyPosition: '',
          attorneyTelephone: '',
        });
      }
    },
    [attorneyContactPersonList],
  );

  const getAttorenyContactPersonList = useCallback(
    (firmId, initalContactPersonId) => {
      getAllListData(
        `${SETUP_ENDPOINT.SelectMenu}/lawfirm/${String(firmId)}`,
      ).then(async (resp: any) => {
        setAttorneyContactPersonList(resp);
        const firmData = await instantState(setFirmData);
        if (resp && resp.length) {
          // if (!editId) {
          const attorneyPerson = resp.find(
            (person) => person.id === initalContactPersonId,
          );
          if (attorneyPerson) {
            setAttorneyInitDetails((pre) => ({
              ...pre,
              attorneyCountryCode: attorneyPerson.country_code_mobile
                ? attorneyPerson.country_code_mobile
                : '+1',
              attorneyEmail: attorneyPerson.email_id,
              attorneyPosition: attorneyPerson.designation,
              attorneyTelephone: attorneyPerson.contact_mobile
                ? attorneyPerson.contact_mobile
                : '',
            }));
          }
          // }
        }
        chooseAttorneyDetails(initalContactPersonId);
        setAttorneySupportIds(
          resp.filter((obj) =>
            _.isUndefined(editId)
              ? firmData.attorney_support &&
                firmData.attorney_support.indexOf(obj.id) !== -1
              : watchThree('attorneySupport').indexOf(obj.id) !== -1,
          ),
        );
        setAdditionalAttorneyContactIds(
          resp.filter((obj) =>
            _.isUndefined(editId)
              ? firmData.additional_attorney &&
                firmData.additional_attorney.indexOf(obj.id) !== -1
              : watchThree('additionalAttorneyContacts').indexOf(obj.id) !== -1,
          ),
        );
      });
    },
    [chooseAttorneyDetails, editId, watchThree],
  );

  // const checkToken = useCallback(async () => {
  //   const doc = {
  //     documentFor: 'lead',
  //     leadId: editId ? parseInt(editId) : '',
  //     uploadCheck: uploadCheck,
  //     uploadForId: '',
  //     uploadedFor: '',
  //   };
  //   setLoader(true);
  //   await addNewData(doc, `${COMMON_ENDPOINT.BulkDocumentUpload}/review`)
  //     .then((res) => {
  //       if (res === 'Unauthorized') {
  //         setUploadCheck(true);
  //         checkToken();
  //         return;
  //       }
  //       setUploadCheck(false);
  //       setIsDocumentReview({ reviewDoc: true, token: res.data });
  //       setLoader(false);
  //       isDocumentReview['reviewDoc'] = true;
  //       isDocumentReview['token'] = res.data;
  //       navigate(`${AttorneyPrivateRoutes.LEADS.REVIEWDOCUMENT}/${editId}`, {
  //         state: {
  //           review: isDocumentReview,
  //         },
  //       });
  //     })
  //     .catch((err) => {
  //       setUploadCheck(false);
  //       setLoader(false);
  //       // toast.error(err, alertOptions);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const onDelete = (action: string) => {
    if (action !== 'yes') {
      setDeleteDialog((pre) => ({
        ...pre,
        show: false,
      }));
      return;
    }
    if (deleteDialog.deleteType === 'beneficiary') {
      deleteData(
        '',
        COMMON_ENDPOINT.LeadUnderDeleteBeneficiary(
          editId,
          deleteDialog.deleteId && deleteDialog.deleteId.ids
            ? deleteDialog.deleteId.ids
            : '',
        ),
      ).then(() => {
        beneficiaryFieldRemove(deleteDialog.index);
      });
      setDeleteDialog((pre) => ({
        ...pre,
        show: false,
      }));
    }

    if (deleteDialog.deleteType === 'affiliate') {
      deleteData(
        '',
        COMMON_ENDPOINT.LeadUnderAffiliateDelete(
          editId,
          deleteDialog.deleteId && deleteDialog.deleteId.ids
            ? deleteDialog.deleteId.ids
            : '',
        ),
      ).then(() => {
        affiliateFieldRemove(deleteDialog.index);
      });
      setDeleteDialog((pre) => ({
        ...pre,
        show: false,
      }));
    }

    if (deleteDialog.deleteType === 'petitioner') {
      deleteData(
        '',
        COMMON_ENDPOINT.LeadUnderDeleteAdditionalPetitioner(
          editId,
          deleteDialog.deleteId && deleteDialog.deleteId.ids
            ? deleteDialog.deleteId.ids
            : '',
        ),
      ).then(() => {
        petitionerContactFieldRemove(deleteDialog.index);
      });
      setDeleteDialog((pre) => ({
        ...pre,
        show: false,
      }));
    }
  };
  const processingTypeData = async () => {
    await getAllListData(
      `${MASTER_ENDPOINT.ProcessingTypes}?type=processingType`,
    )
      .then((processResp: any) => {
        setProcessingTypes(processResp);
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };
  const filingTypeData = async () => {
    await getAllListData(`${MASTER_ENDPOINT.ProcessingTypes}?type=filingType`)
      .then((fillingResp: any) => {
        setFilingTypes(fillingResp);
        if (watchThree('attorneyContactId')) {
          chooseAttorneyDetails(watchThree('attorneyContactId'));
        }
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };
  const apiCall = async () => {
    try {
      if (!_.isUndefined(editId)) {
        await getInitialStatus();
      }
      const leadInfo: {
        isAffilateCase: boolean;
        attorney_assigned: number;
        petitioner_associated: boolean;
        petitioner_assigned: number;
        affilateRoleId: number;
      } = await instantState(setLeadData);
      getAllListData(
        `${SETUP_ENDPOINT.SelectMenu}/petitioner${
          leadInfo?.isAffilateCase
            ? `?isAffilate=true&affilateRoleId=${String(
                leadInfo?.affilateRoleId,
              )}&firmId=${String(
                leadInfo?.attorney_assigned,
              )}&associated=${String(leadInfo.petitioner_associated)}${
                leadInfo?.petitioner_assigned
                  ? `&petitionerId=${String(leadInfo?.petitioner_assigned)}`
                  : ''
              }`
            : ''
        }`,
      )
        .then((orgResp: any) => {
          if (editId) {
            const orgsIds = (
              orgResp && orgResp.length
                ? orgResp.map((e: any, i: number) => ({ ...e, ids: i + 1 }))
                : []
            ).find(
              (el) =>
                el.status === leadInfo.petitioner_associated &&
                el.id === leadInfo.petitioner_assigned,
            )?.ids;
            setValueOne('petitionerId', orgsIds);
          }
          setOrganizations(
            orgResp && orgResp.length
              ? orgResp.map((e: any, i: number) => ({ ...e, ids: i + 1 }))
              : [],
          );
        })
        .catch((e: any) => {
          setLoader(false);
        });
      getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`)
        .then((visaResp: any) => {
          setVisaTypes(visaResp.data);
        })
        .catch((e: any) => {
          setLoader(false);
        });

      const affiliateParams =
        editId && leadInfo && leadInfo.isAffilateCase
          ? `?firmId=${String(
              leadInfo.attorney_assigned,
            )}&isAffliate=true&affilateRoleId=${String(
              leadInfo.affilateRoleId,
            )}${
              leadInfo?.affilateRoleId === 2
                ? `&petitionerId=${Number(leadInfo?.petitioner_assigned)}`
                : ''
            }`
          : '';

      getAllListData(`/attorney${SETUP_ENDPOINT.MyFirm}${affiliateParams}`)
        .then((firmResp: any) => {
          setFirmData(firmResp);
          setAttorneyInitDetails((pre) => ({
            ...pre,
            attorneyName: firmResp.firm_name,
          }));
          if (_.isUndefined(editId)) {
            setValueThree(
              'attorneyContactId',
              firmResp && firmResp.attorney_contact_id
                ? firmResp.attorney_contact_id
                : '',
            );
            if (
              firmResp.attorney_support &&
              Array.isArray(firmResp?.attorney_support) &&
              firmResp.attorney_support.length
            ) {
              setValueThree('attorneySupport', firmResp.attorney_support);
            }
            if (firmResp?.case_manager) {
              setValueThree('caseManager', [firmResp?.case_manager]);
            }
            getAttorenyContactPersonList(
              leadData.isAffilateCase
                ? leadData.attorney_assigned
                : userDetail.companyId,
              firmResp && firmResp.attorney_contact_id
                ? firmResp.attorney_contact_id
                : '',
            );
          }
        })
        .catch((e: any) => {
          setLoader(false);
        });
      // getAllListData(`${MASTER_ENDPOINT.ProcessingTypes}?type=processingType`)
      //   .then((processResp: any) => {
      //     setProcessingTypes(processResp);
      //   })
      //   .catch((e: any) => {
      //     setLoader(false);
      //   });
      // getAllListData(`${MASTER_ENDPOINT.ProcessingTypes}?type=filingType`)
      //   .then((fillingResp: any) => {
      //     setFilingTypes(fillingResp);
      //     if (watchThree('attorneyContactId')) {
      //       chooseAttorneyDetails(watchThree('attorneyContactId'));
      //     }
      //     setLoader(false);
      //   })
      // .catch((e: any) => {
      //   setLoader(false);
      // });
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    apiCall();
    processingTypeData();
    filingTypeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <Grid item md={12} sm={12}>
          <Typography
            component={'div'}
            className="d-flex-a d-flex-sb sticky-tab">
            <Typography
              component={'div'}
              className="w-title black-white-text d-flex-a"
              sx={{ padding: 'unset !important' }}>
              <Typography className="d-flex-a">
                {formSteps !== 1 && (
                  <Typography
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setFormSteps(formSteps === 2 ? 1 : 2);
                    }}>
                    <img className="edit mr-2" src={ArrowLeft} alt="edit" />
                  </Typography>
                )}
              </Typography>
              {editId ? 'Edit Lead' : 'Add New Lead'}
            </Typography>

            {editId && ![5].includes(leadData?.affilateRoleId) && (
              <Typography component={'div'} className="d-flex-jae">
                <Button
                  className="cancel-btn mr-2"
                  onClick={() =>
                    setViewAttorneyNotes({
                      leadOrPetitionId: editId,
                      show: true,
                      type: 'lead',
                    })
                  }>
                  <Typography className="o-box" />
                  {userDetail.userRoleName == 'attorney'
                    ? 'Add Notes'
                    : 'Contact Attorney'}
                </Button>
                {/* {userDetail.userRoleName === 'attorney' ||
                (leadData?.isAffilateCase && leadData?.affilateRoleId === 3) ? (
                  <Badge
                    {...(countDetails.pending === 0
                      ? {}
                      : { badgeContent: Number(countDetails.pending) })}
                    color="primary"
                    sx={{ maxWidth: 'max-content' }}>
                    <Button
                      className="add-btn"
                      onClick={() => {
                        if (
                          userDetail.userRoleName === 'attorney' ||
                          (leadData?.isAffilateCase &&
                            leadData?.affilateRoleId === 3)
                        ) {
                          checkToken();
                        }
                      }}>
                      Review Document
                    </Button>
                  </Badge>
                ) : null} */}
              </Typography>
            )}
          </Typography>
          <Grid container sx={{ margin: '10px 0 !important' }}>
            <Grid item md={6} sm={6} sx={{ paddingRight: '10px' }}>
              <Typography
                className={formSteps === 1 ? 'steps step-active' : 'steps'}
              />
            </Grid>
            <Grid md={6} sm={6} sx={{ paddingLeft: '10px' }}>
              <Typography
                className={formSteps === 2 ? 'steps step-active' : 'steps'}
              />
            </Grid>
          </Grid>
          {formSteps === 1 ? (
            <BeneficiaryAndPetitionerForm
              control={controlOne}
              errors={errorsOne}
              setValue={setValueOne}
              getValues={getValueOne}
              setError={setErrorOne}
              watch={watchOne}
              handleSubmit={handleSubmitOne}
              onSubmitForm={onSubmitStepOne}
              cancelForm={() => {
                cancelforStepOne();
                // navigate(-1);
              }}
              fields={beneficiaryFields}
              fieldAppend={beneficiaryAppend}
              fieldRemove={beneficiaryFieldRemove}
              organizations={organizations}
              editId={editId}
              setDeleteDialog={setDeleteDialog}
              beneficiaryUpdate={beneficiaryUpdate}
              formOneIsValid={formOneIsValid}
              leadData={leadData}
              affiliateFields={affiliateFields}
              affiliateFieldAppend={affiliateAppend}
              affiliateFieldRemove={affiliateFieldRemove}
              affiliateUpdate={affiliateUpdate}
              petitionerContactFields={petitionerContactFields}
              petitionerContactFieldAppend={petitionerContactAppend}
              petitionerContactFieldRemove={petitionerContactFieldRemove}
              petitionerContactUpdate={additionalPetitionerUpdate}
              firmData={firmData}
            />
          ) : formSteps === 2 ? (
            <AttorneyForm
              control={controlThree}
              errors={errorsThree}
              setValue={setValueThree}
              watch={watchThree}
              handleSubmit={handleSubmitThree}
              onSubmitForm={onSubmitStepThree}
              cancelForm={() => {
                // resetFormThree();
                setFormSteps(1);
              }}
              firmData={firmData}
              processingTypes={processingTypes}
              filingTypes={filingTypes}
              visaTypes={visaTypes}
              attorneyContactPersonList={attorneyContactPersonList}
              setAttorneyContactPersonList={setAttorneyContactPersonList}
              attorneyInitDetails={attorneyInitDetails}
              setAttorneyInitDetails={setAttorneyInitDetails}
              attorneySupportIds={attorneySupportIds}
              setAttorneySupportIds={setAttorneySupportIds}
              additionalAttorneyContactIds={additionalAttorneyContactIds}
              setAdditionalAttorneyContactIds={setAdditionalAttorneyContactIds}
              editId={editId}
              leadData={leadData}
            />
          ) : null}
        </Grid>
      )}

      {/* <ContactAttorneyAndNotes
        notes={viewAttorneyNotes}
        setNotes={setViewAttorneyNotes}
       
      /> */}
      {viewAttorneyNotes.show && (
        <AddNote
          notes={viewAttorneyNotes}
          setNotes={setViewAttorneyNotes}
          type="lead"
          showModel={true}
          showTable={true}
        />
      )}
      <ConfirmDialog
        title="DELETE"
        floatingBtnShow={true}
        open={deleteDialog.show}
        close={() => {
          setDeleteDialog((pre) => ({
            ...pre,
            show: false,
          }));
        }}
        onConfirm={(act) => onDelete(act)}
        autoCloseDisable={true}>
        {'Do you wish to delete?'}
      </ConfirmDialog>
    </Grid>
  );
};

export default LeadAdd;
