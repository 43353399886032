import { Button, CircularProgress, DialogContent } from '@mui/material';
import CustomModalView from 'components/CustomModalView/customModalView';
import saveAs from 'file-saver';
import { instantState, useDebounce } from 'hooks';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import { getAllListData, getS3file } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

type Props = {
  modalOnClose: () => void;
};
export const ResourceStation: React.FC<any> = (props: Props) => {
  const { modalOnClose } = props;
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [params, setParams] = React.useState({
    documentName: '',
    id: '',
    skip: 0,
    status: true,
    take: 50,
    visaType: petitions?.petitionData?.visa_id,
  });
  const [documentLoader, setDocumentLoader] = React.useState(true);
  const [hasMoreResults, setHasMoreResults] = React.useState(false);
  const [tableLoader, setTableLoader] = React.useState(false);
  const [visaTypes, setVisaTypes] = React.useState<any>([]);
  const [getMoreLoader, setGetMoreLoader] = React.useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [resourceList, setResourceList] = React.useState({
    data: [],
    total: 0,
  });
  const debouncedParams = useDebounce(params, 500);

  const openFile = (file) => {
    // setLoader(true);
    const obj = {
      key: file,
    };

    const fileName = file.split('/').pop();
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(`${String(fileName)} File Downloaded`, alertOptions);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };

  const getResouceStationData = React.useCallback(() => {
    const firmId = [2, 4].includes(userDetail.role_id)
      ? `&firmId=${String(petitions?.petitionData?.attorney_assigned)}`
      : '';
    getAllListData(
      `${String(COMMON_ENDPOINT.ResourceStation)}?&skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}&id=${String(
        debouncedParams.id,
      )}&name=${String(debouncedParams.documentName)}&visaId=${String(
        debouncedParams.visaType,
      )}&status=${String(debouncedParams.status)}${firmId}&listView=true`,
    )
      .then(async (res: any) => {
        const { data, count: total } = res ? res : { count: 0, data: [] };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setResourceList((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
            total,
          }));
        } else {
          const idsEle = document.getElementById('paraentScroll');
          idsEle && (idsEle.scrollTop = 0);
          setResourceList((pre) => ({
            ...pre,
            data: data,
            total,
          }));
        }
        setTableLoader(false);
        setDocumentLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setResourceList);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch(() => {
        setTableLoader(false);
        setGetMoreLoader(false);
        setDocumentLoader(false);
        setHasMoreResults(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedParams.documentName,
    debouncedParams.id,
    debouncedParams.skip,
    debouncedParams.status,
    debouncedParams.take,
    debouncedParams.visaType,
  ]);

  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setParams((pre) => ({
        ...pre,
        skip: pre.skip + 50,
      }));
    }
  };

  React.useEffect(() => {
    getResouceStationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getResouceStationData, debouncedParams]);
  React.useEffect(() => {
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`).then(
      (resp) => {
        setVisaTypes(resp.data);
      },
    );
  }, []);

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <CustomModalView
      open={true}
      fullWidth={true}
      isFilters={true}
      maxWidth={'lg'}
      scroll={'paper'}
      visaTypes={visaTypes}
      close={() => {
        modalOnClose();
        setParams((pre) => ({
          ...pre,
          skip: 0,
          take: 50,
        }));
      }}
      defaultVisaSet={petitions?.petitionData?.visa_id}
      filterVisaOnChange={(searchvalue) => {
        setTableLoader(true);
        setParams((pre) => ({
          ...pre,
          skip: 0,
          take: 50,
          visaType: searchvalue,
        }));
      }}
      filterOnChange={(searchvalue) => {
        setTableLoader(true);
        setParams((pre) => ({
          ...pre,
          documentName: searchvalue,
          skip: 0,
          take: 50,
        }));
      }}
      title="Resource Station">
      <DialogContent id="paraentScroll">
        {documentLoader && <div className="liberty-loader" />}
        <InfiniteScroll
          dataLength={
            resourceList && resourceList.data && resourceList.data.length
              ? resourceList.data.length
              : 0
          }
          style={{
            overflow: 'unset',
          }}
          next={getMoreList && getMoreList}
          hasMore={hasMoreResults}
          scrollableTarget={'paraentScroll'}
          loader={
            getMoreLoader && (
              <div
                style={{
                  backgroundColor: '#ffffff',
                  textAlign: 'center',
                  width: '100%',
                }}>
                <CircularProgress
                  size={30}
                  sx={{ color: '#002c69', marginBottom: 10, marginTop: 10 }}
                />
              </div>
            )
          }
          endMessage>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th className="W-3">DOCUMENT NAME</th>
                <th className="W-5">DESCRIPTION</th>
                <th className="W-2">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {tableLoader ? (
                <tr>
                  <td colSpan={8}>
                    <div
                      style={{
                        backgroundColor: '#ffffff',
                        textAlign: 'center',
                        width: '100%',
                      }}>
                      <CircularProgress
                        size={30}
                        sx={{
                          color: '#002c69',
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {resourceList && resourceList.data && resourceList.data.length
                    ? resourceList.data.map((row: any, index) => (
                        <tr key={index} style={{ cursor: 'pointer' }}>
                          <td>
                            {row?.document_name ? row?.document_name : '-'}
                          </td>
                          <td>
                            {row?.document_description
                              ? row?.document_description
                              : '-'}
                          </td>
                          <td>
                            <Button
                              className="n-add-btn"
                              sx={{
                                borderRadius: '5px !important',
                                height: '30px !important',
                                minWidth: '90px !important',
                              }}
                              onClick={() => openFile(row.document_key)}>
                              Download
                            </Button>
                          </td>
                        </tr>
                      ))
                    : !tableLoader && (
                        <tr className={'text-center'}>
                          <td colSpan={3} align={'center'}>
                            No Record(s) Found.
                          </td>
                        </tr>
                      )}
                </>
              )}
            </tbody>
          </table>
        </InfiniteScroll>
      </DialogContent>
    </CustomModalView>
  );
};
