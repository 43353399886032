import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from 'assets/images/liberty-logoheader.png';
// import Space from 'assets/images/space.png';
import Footer from 'components/Footer';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
// import '../PrivacyPolicy/style.css'
// import { useHistory } from "react-router-dom";

export const useStyles = makeStyles((theme) => ({
  space: {
    backgroundColor: '#09171f',
    backgroundImage: `url(${'https://d17p81usn86n3p.cloudfront.net/space.png'})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    bottom: '0',
    left: '0',
    minHeight: '100%',
    position: 'fixed',
    right: '0',
    top: '0',
    width: '100%',
  },
}));

const AcceptableUsePolicy: React.FC = React.memo(() => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  window.scrollTo({
    behavior: 'smooth',
    top: 0,
  });
  return (
    <div>
      <Typography className=" black-white-text p-f pt-2">
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <Typography className={classes.space} />
      <div className="innerBox">
        <div
          className=" d-flex-a d-flex-sb mb-1 sticky-tab"
          style={{ top: '0px' }}>
          <div className="black-white-text font-bold f-20 p-wtext">
            Acceptable Use Policy
          </div>

          <div>
            <Button
              className="cancel-btn mr-4 "
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '1em !important',
                width: '120px !important',
              }}
              onClick={() =>
                navigate(`/${String(userDetail?.userRoleName)}/dashboard`)
              }>
              <Typography
                className="o-box d-flex-a"
                sx={{ width: 'unset !important' }}
              />
              Cancel
            </Button>
          </div>
        </div>
        <div
          className="card"
          style={{
            paddingBottom: '15px',
            paddingLeft: '35px',
            paddingRight: '35px',
            paddingTop: '15px',
          }}>
          <p>
            <b>Date Posted: March 02, 2018</b>
          </p>
          <h4>A. Unacceptable Use</h4>
          <p>
            OnlineVisas requires that all customers and other users of
            OnlineVisas’ OnlineVisas Gateway software-as-a-service and related
            professional services (the “System &amp; Services”) conduct
            themselves with respect for others. In particular, observe the
            following rules in your use of the System &amp; Services:
          </p>
          <span className="information">
            <ol>
              <li>
                Abusive Behavior: Do not harass, threaten, or defame any person
                or entity. Do not contact any person who has requested no
                further contact. Do not use ethnic, national, or religious slurs
                against any person or group.
              </li>
              <li>
                Privacy: Do not upload private information about any person to
                the System without authorization. Do not violate the privacy
                rights of any person, including applicants for visa
                applications. Do not collect or disclose any personal address,
                social security number, or other personally identifiable
                information without each holder’s written permission. Do not
                cooperate in or facilitate identity theft. Do not violate any
                law governing privacy rights in any jurisdiction.
              </li>
              <li>
                Intellectual Property: Do not infringe upon the copyrights,
                trademarks, trade secrets, or other intellectual property rights
                of any person or entity. Do not reproduce, publish, or
                disseminate text, photographs, or other works of authorship
                without the written permission of the copyright holder.
              </li>
              <li>
                Hacking, Viruses, &amp; Network Attacks: Do not access any
                computer or communications system without authorization,
                including the computers used to provide the System &amp;
                Services. Do not attempt to penetrate or disable any security
                system. Do not intentionally distribute a computer virus, launch
                a denial of service attack, or in any other way attempt to
                interfere with the functioning of any computer, communications
                system, or website. Do not attempt to access or otherwise
                interfere with the accounts of other users of the System &amp;
                Services.
              </li>
              <li>
                Spam: Do not send bulk unsolicited e-mails (“Spam”) or sell or
                market any product or service advertised by or connected with
                Spam. Do not facilitate or cooperate in the dissemination of
                Spam in any way. Do not violate the CAN-Spam Act of 2003.
              </li>
              <li>
                Fraudulent Visa Applications: Do not submit false information in
                a visa application to the United States federal government. Do
                not attempt to mislead any officer of the U.S. federal
                government regarding any matter related to a visa application.
                Do not commit fraud in any other way.
              </li>
              <li>
                Violations of Law: Do not violate any law, including U.S.
                federal laws governing immigration and visas and U.S. state laws
                related to immigration.
              </li>
            </ol>
          </span>
          <h4>B. Consequences of Violation</h4>
          <p>
            Violation of this Acceptable Use Policy (this “AUP”) may lead to
            suspension or termination of the user’s account or legal action. In
            addition, the user may be required to pay for the costs of
            investigation and remedial action related to AUP violations.
            OnlineVisas reserves the right to take any other remedial action it
            sees fit.
          </p>

          <h4>C. Reporting Unacceptable Use</h4>
          <p>
            OnlineVisas requests that anyone with information about a violation
            of this AUP report it via e-mail to the following address:
            <a href={`mailto:${String(process.env.REACT_APP_CONTACT_MAIL)}`}>
              {String(process.env.REACT_APP_CONTACT_MAIL)}
            </a>
            . Please provide the date and time (with time zone) of the violation
            and any identifying information regarding the violator, including
            e-mail or IP (Internet Protocol) address if available, as well as
            details of the violation.
          </p>

          <h4>D. Revision of AUP</h4>
          <p>
            OnlineVisas may change this AUP at any time by posting a new version
            on this page and sending the user written notice thereof. The new
            version will become effective on the date of such notice.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default AcceptableUsePolicy;
