import UploadIcon from '@mui/icons-material/Upload';
import { Button, Grid, Typography } from '@mui/material';
import Delete from 'assets/images/deleteicon.png';
import Uploadimg from 'assets/images/uploadnow.svg';
import View from 'assets/images/viewicon.png';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { FileUploadDialog } from 'components/Fileupload/fileuploadDialog';
import InfinityScroll from 'components/InfinityScroll';
import { useDebounce } from 'hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { UserRoleType } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';
import {
  alertOptions,
  fileTypeCheckSkillParsing,
  parsingTypeArr,
} from 'utils/helper';

import { EditFileNameUploadDocuments } from '../Common/EditFileNameUploadDocuments';

export const OrganizationUploadDocument: React.FC<any> = ({
  leadInfo,
  setLoader,
  loader,
  viewFileDoc,
  associated,
  params,
  setParams,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [confirmOpen, setConfirmOpen] = useState<any>({
    doc: {},
    index: null,
    show: false,
  });
  const location: any = useLocation();
  const [fileUploadOpen, setFileUploadOpen] = useState<{
    open: boolean;
    documentInfo: any;
    roleId: number | any;
    editDocId: any;
  }>({
    documentInfo: null,
    editDocId: null,
    open: false,
    roleId: null,
  });
  const [petitionerDocuments, setPetitionerDocuments] = useState<any>({
    data: [],
    total: 0,
  });
  const { editId } = useParams();
  const [isUploadDocs, setIsUploadDocs] = useState({
    index: 0,
    show: true,
  });
  const debouncedParams = useDebounce(params, 500);

  const uploadDialog = (action) => {
    if (action) {
      if (fileUploadOpen?.editDocId) {
        organizationEditUploadDocuments(action);
      } else {
        organizationUploadDoc(action);
      }
    }
    setFileUploadOpen((pre) => ({
      ...pre,
      open: false,
    }));
  };
  const organizationUploadDoc = (file) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('firmId', userDetail.companyId);
    formData.append(
      'documentId',
      fileUploadOpen?.documentInfo?.currentDocumentId,
    );
    addNewData(
      formData,
      `${COMMON_ENDPOINT.OrganizationUploadDocuments}/${String(
        editId,
      )}?associated=${String(
        location.pathname.includes('associated') ? true : false,
      )}`,
    )
      .then(() => {
        setParams((pre) => ({
          ...pre,
          take:
            pre.skip === 0
              ? 50 + Number(pre.take)
              : Number(pre.skip) + Number(pre.take),
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          skip: 0,
        }));
        setLoader(false);
        toast.success('File uploaded successfully', alertOptions);
      })
      .catch(() => {
        setLoader(false);
        toast.error('Something went wrong', alertOptions);
      });
  };
  const organizationEditUploadDocuments = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      await updateData(
        fileUploadOpen?.editDocId,
        formData,
        COMMON_ENDPOINT.OrganizationUploadDocuments,
      );
      setParams((pre) => ({
        ...pre,
        take:
          pre.skip === 0
            ? 50 + Number(pre.take)
            : Number(pre.skip) + Number(pre.take),
        // eslint-disable-next-line sort-keys-fix/sort-keys-fix
        skip: 0,
      }));
      setFileUploadOpen((pre) => ({
        ...pre,
        documentInfo: null,
        editDocId: null,
        open: false,
        roleId: null,
      }));
    } catch (error) {
      setLoader(false);
    }
  };
  const deleteUploadDoc = (doc: any) => {
    setLoader(true);
    deleteData(doc.documentId, `${COMMON_ENDPOINT.OrganizationUploadDocuments}`)
      .then((resp: any) => {
        toast.success('File deleted successfully', alertOptions);
        setConfirmOpen({
          doc: {},
          index: null,
          show: false,
        });
        // getOrganizationData();
        setParams((pre) => ({
          ...pre,
          take:
            pre.skip === 0
              ? 50 + Number(pre.take)
              : Number(pre.skip) + Number(pre.take),
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          skip: 0,
        }));
      })
      .catch((e) => {
        toast.error(e ? e : 'Oops!!! Something went wrong', alertOptions);
        setLoader(false);
      });
  };
  const getOrganizationData = useCallback(() => {
    getAllListData(
      `${COMMON_ENDPOINT.OrganizationUploadDocuments}/${String(
        editId,
      )}?associated=${String(debouncedParams.associated)}&firmId=${String(
        debouncedParams.firmId,
      )}&visaId=${String(debouncedParams.visaId)}&documentType=${String(
        debouncedParams.documentType,
      )}&skip=${String(debouncedParams.skip)}&take=${String(
        debouncedParams.take,
      )}`,
    )
      .then((resp: any) => {
        const mergeData =
          params.skip !== 0 && !_.isEmpty(petitionerDocuments.data)
            ? [...petitionerDocuments.data, ...resp.data]
            : resp.data;
        setPetitionerDocuments({
          data: mergeData,
          total: params.skip === 0 ? resp.total : petitionerDocuments.total,
        });
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
  }, [
    debouncedParams.associated,
    debouncedParams.documentType,
    debouncedParams.firmId,
    debouncedParams.skip,
    debouncedParams.take,
    debouncedParams.visaId,
    editId,
    params.skip,
    petitionerDocuments.data,
    petitionerDocuments.total,
    setLoader,
  ]);

  useEffect(() => {
    getOrganizationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);
  const rowProps = (document, index) => {
    return (
      <li
        key={index}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          setIsUploadDocs({
            index: index,
            show: true,
          })
        }>
        <Typography
          component={'div'}
          className={
            isUploadDocs.index === index && isUploadDocs.show
              ? document.multipleDocumentsList.length > 0
                ? 'blue-ul'
                : 'white-ul'
              : ''
          }>
          <div
            style={{
              display: 'flex',
              padding: '15px 0',
              position: 'relative',
            }}>
            {document.multipleDocumentsList.length > 0 && (
              <span className="g-badge d-flex-ja">
                {Number(document.multipleDocumentsList.length)}
              </span>
            )}
            <span className="u-no">{Number(index) + 1}</span>
            <span>{document.document_type}</span>
          </div>
          {isUploadDocs.index === index && isUploadDocs.show && (
            <Button
              className="u-btn-outer"
              onClick={() => {
                if (
                  document.parsing_type === 'i-129' &&
                  leadInfo &&
                  (!leadInfo.visa_type || leadInfo.visa_type.length === 0)
                ) {
                  toast.error('Please select the visa type', alertOptions);
                  return;
                }
                if (
                  document.parsing_type === 'i-129' &&
                  leadInfo &&
                  // leadInfo.hasOwnProperty('attorney_assigned') &&
                  !leadInfo.attorney_assigned
                ) {
                  toast.error(
                    'Please select the attorney firm in the lead to review and accept the parsed data.',
                    alertOptions,
                  );
                  return;
                }
                setFileUploadOpen((pre) => ({
                  ...pre,
                  documentInfo: document,
                  editDocId: null,
                  open: true,
                  roleId: 2,
                }));
              }}>
              <img src={Uploadimg} alt="upload" />
            </Button>
          )}
          {isUploadDocs?.index === index &&
          isUploadDocs?.show &&
          document?.multipleDocumentsList?.length > 0 ? (
            <ul>
              {document?.multipleDocumentsList?.map((subDoc, sIndex) => (
                <li key={sIndex}>
                  <Grid
                    container
                    sx={{
                      paddingLeft: '2.5rem !important',
                      paddingRight: '2rem !important',
                    }}>
                    <Grid
                      item
                      md={5}
                      sm={12}
                      className="f-15"
                      sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        wordBreak: 'break-all',
                      }}>
                      {' '}
                      <Typography component={'div'} className="udot" />
                      <EditFileNameUploadDocuments
                        document={subDoc}
                        sIndex={sIndex}
                        setLoader={setLoader}
                        type={'organization'}
                        multipleDocuemnt={document.multipleDocumentsList}
                      />
                    </Grid>
                    <Grid md={2} sm={3} className="text-center f-15 d-flex-ja">
                      <Typography
                        className="white-text"
                        onClick={() =>
                          viewFileDoc(subDoc.document_path, subDoc.mime_type)
                        }>
                        <img src={View} alt="View" className="pr-1" />
                        View
                      </Typography>
                    </Grid>
                    {!parsingTypeArr.includes(document?.parsing_type) &&
                      leadInfo &&
                      !leadInfo.isAffilateCase && (
                        <Grid
                          md={2}
                          sm={3}
                          className="text-center f-15 d-flex-ja">
                          {' '}
                          <Typography
                            className="white-text"
                            onClick={() =>
                              setFileUploadOpen((pre) => ({
                                ...pre,
                                documentInfo: document,
                                editDocId: subDoc.documentId,
                                open: true,
                                // roleId: 2,
                              }))
                            }>
                            <span className="d-flex-jae f-16">
                              <UploadIcon
                                style={{
                                  fill: '#FFFFFF',
                                }}
                              />
                              Re Upload
                            </span>
                          </Typography>
                        </Grid>
                      )}
                    {userDetail.userRoleName === UserRoleType.Attorney && (
                      <Grid
                        md={2}
                        sm={3}
                        className="text-center f-15 d-flex-ja">
                        <Typography
                          className="white-text d-flex align-center"
                          onClick={() => {
                            setConfirmOpen({
                              doc: subDoc,
                              index: sIndex,
                              show: true,
                            });
                          }}>
                          <img src={Delete} alt="view" className="pr-1 pb-0" />
                          Delete
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </li>
              ))}
            </ul>
          ) : null}
        </Typography>
      </li>
    );
  };
  return (
    <div>
      <ul className="upload-ul">
        {petitionerDocuments?.data?.length > 0 ? (
          <Typography component={'div'} id="organizationUpload">
            <InfinityScroll
              paramState={setParams}
              rowProps={rowProps}
              height={'80vh'}
              scrollableTargetId={'organizationUpload'}
              data={petitionerDocuments.data}
              count={petitionerDocuments.total}
            />
          </Typography>
        ) : (
          <li>
            <span className="d-flex-ja">No Record(s) Found.</span>
          </li>
        )}
      </ul>
      <ConfirmDialog
        title="DELETE"
        floatingBtnShow={true}
        open={confirmOpen.show}
        onConfirm={(act) =>
          act === 'yes'
            ? deleteUploadDoc(confirmOpen.doc)
            : setConfirmOpen({
                doc: {},
                index: null,
                show: false,
              })
        }
        autoCloseDisable={true}>
        Are you sure do you want to delete?
      </ConfirmDialog>

      {fileUploadOpen.open && (
        <FileUploadDialog
          open={fileUploadOpen.open}
          allowedFileType={fileTypeCheckSkillParsing}
          errorMessage={
            'You can upload documents of types JPEG, TIFF, PDF, XLS, XLSX, DOCX and DOC only'
          }
          info={
            '(You can upload documents of types JPEG, TIFF, PDF, XLS, XLSX, DOCX and DOC only.You can upload upto a maximum of 90MB per upload)'
          }
          onConfirm={uploadDialog}
          setFileUploadOpen={setFileUploadOpen}
          loader={loader}
        />
      )}
    </div>
  );
};
