import Storage from 'utils/Storage';

import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PROFILES_FAIL,
  USER_PROFILES_SUCCESS,
  USER_REFRESH_TOKEN,
  USER_SIDEMENU_FAIL,
  USER_SIDEMENU_SUCCESS,
  VERIFY_CAPTCHA,
} from '../types/UserTypes';

const user = Storage.isTokenValidDetails();
export interface UserState {
  loading?: boolean;
  error?: string;
  userInfo?: any;
  isLoggedIn?: boolean;
  sideMenu?: any;
  profile?: any;
}

export interface Action {
  type: string;
  payload?: string;
}

const INITIAL_STATE: UserState = user
  ? {
      error: null,
      isLoggedIn: true,
      loading: false,
      profile: {},
      sideMenu: {},
      userInfo: user,
    }
  : {
      error: null,
      isLoggedIn: false,
      loading: false,
      profile: {},
      sideMenu: {},
      userInfo: null,
    };

export const userLoginReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        isLoggedIn: true,
        loading: false,
        userInfo: action.payload,
      };
    case USER_LOGIN_FAIL:
      return {
        error: action.payload,
        isLoggedIn: false,
        loading: false,
        userInfo: null,
      };
    case USER_LOGOUT:
      return {
        error: null,
        isLoggedIn: false,
        loading: false,
        sideMenu: {},
        userInfo: null,
      };
    case USER_REFRESH_TOKEN:
      return {
        ...state,
        userInfo: { ...user, token: action.payload },
      };
    case USER_SIDEMENU_SUCCESS:
      return {
        ...state,
        sideMenu: action.payload,
      };
    case USER_SIDEMENU_FAIL:
      return {
        error: action.payload,
        sideMenu: {},
      };
    case USER_PROFILES_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    case USER_PROFILES_FAIL:
      return {
        error: action.payload,
        profile: {},
      };
    case VERIFY_CAPTCHA:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const captchaReducer = (
  state = { reCaptchaValue: null },
  action: Action,
) => {
  switch (action.type) {
    case VERIFY_CAPTCHA:
      return {
        ...state,
        reCaptchaValue: action.payload,
      };
    default:
      return state;
  }
};
