import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { AttorneyPrivateRoutes } from 'config';
// import { watch } from 'fs';
import _ from 'lodash';
// import Multiselect from 'multiselect-react-dropdown';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { ContractFeeTypes } from 'types/index';
import { COMMON_ENDPOINT, MASTER_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { ContractFeesSchema } from 'utils/ValidatorSchema';

export const ContractAdd: React.FC<any> = () => {
  const { editId } = useParams();
  const [loader, setLoader] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visaData, setVisaData] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visaIds, setVisaIds] = useState<any>([]);
  const [visaOptions, setVisaOptions] = useState<any>([]);
  const [statusToggle, setStatusToggle] = useState(true);
  const navigate = useNavigate();
  const activeStatusInput = useRef(null);
  const inActiveStatusInput = useRef(null);

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    setError,
    // watch,

    formState: { errors },
  } = useForm<ContractFeeTypes>({
    criteriaMode: 'all',
    defaultValues: {
      name: '',
      status: true,
      value: '',
      visaId: null,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ContractFeesSchema),
  });

  const getInitialStatus = useCallback(async () => {
    await getAllListData(`${MASTER_ENDPOINT.ContractFee}?id=${String(editId)}`)
      .then((resp) => {
        getAllListData(
          `${String(COMMON_ENDPOINT.VisaTypes)}?status=active`,
        ).then((visaResp: any) => {
          setVisaData(visaResp.data ? visaResp.data : []);
          const options = [];
          visaResp.data.map((visa) =>
            options.push({ label: visa.visa_code, value: visa.visa_id }),
          );
          setVisaOptions(options);
          const visaAssinged = resp.data[0].visa_id_array.map(Number);

          setVisaIds(
            visaResp.data
              .filter((obj) => visaAssinged.indexOf(obj.visa_id_array) !== -1)
              .map((selected) => selected.visa_id_array),
          );
        });

        if (resp && !_.isEmpty(resp.data)) {
          setValue('name', resp.data[0].name);
          setValue('value', parseFloat(resp.data[0].value).toFixed(2));
          setValue('visaId', resp.data[0].visa_id_array);
          setValue('status', resp.data[0].status);
          setStatusToggle(resp.data[0].status);
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToggleStatus = () => setStatusToggle((b) => !b);

  const onSubmitForm = (data: any) => {
    const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

    if (data.value <= 0) {
      setError('value', {
        message: 'Payment amount should be greater than 0.00',
        type: 'custom',
      });
      return;
    }

    if (!patternTwoDigisAfterComma.test(data.value)) {
      setError('value', {
        message: 'Fees must have 2 digits after decimal or less',
        type: 'custom',
      });
      return;
    }
    setLoader(true);
    if (!_.isUndefined(editId)) {
      updateData(
        editId,
        {
          name: data.name,
          status: statusToggle,
          value: parseFloat(data.value).toFixed(2),
          visaId: data.visaId,
        },
        `${String(MASTER_ENDPOINT.ContractFee)}`,
      )
        .then((updated) => {
          // navigate(`${AttorneyPrivateRoutes.CONTRACT.CONTRACTFEES}`);
          toast.success('Contract Fees Updated Successfully', alertOptions);
          resetValues();
        })
        .catch((e) => {
          // setLoader(false);
        });
    } else {
      addNewData(
        {
          name: data.name,
          status: statusToggle,
          value: Number(data.value),
          visaId: data.visaId,
        },
        `${String(MASTER_ENDPOINT.ContractFee)}`,
      )
        .then((resultrs) => {
          // navigate(`${AttorneyPrivateRoutes.CONTRACT.CONTRACTFEES}`);
          toast.success('Created Successfully', alertOptions);
          resetValues();
        })
        .catch((e) => {
          // setLoader(false);
        });
    }
  };

  const resetValues = () => {
    resetForm();
    navigate(`${AttorneyPrivateRoutes.CONTRACT.CONTRACTFEES}`);
  };

  useEffect(() => {
    if (!_.isUndefined(editId)) {
      getInitialStatus();
    } else {
      getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`).then(
        (resp: any) => {
          setVisaData(resp.data ? resp.data : []);
          const options = [];
          resp.data.map((visa) =>
            options.push({ label: visa.visa_code, value: visa.visa_id }),
          );
          setVisaOptions(options);
          setLoader(false);
        },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <form
            id="fee-add"
            className="mt-2"
            onSubmit={handleSubmit(onSubmitForm)}>
            <Typography
              component={'div'}
              className="w-title black-white-text"
              sx={{ marginTop: 'unset !important' }}>
              Add Contract Fees
            </Typography>
            <Typography
              component={'div'}
              className="addbox"
              sx={{ marginTop: 'unset !important' }}>
              <Box
                component="form"
                className="u-add-in"
                noValidate
                autoComplete="off">
                <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                  <Grid item md={6}>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Visa Type <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={null}
                        name="visaId"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              autoFocus={true}
                              closeMenuOnSelect={false}
                              labelKey={'label'}
                              valueKey={'value'}
                              options={visaOptions}
                              isMulti={true}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                setVisaIds(value);
                              }}
                              placeHolder={'Select'}
                              className={'basic-multi-select'}
                              error={errors.visaId?.message}
                              // disabled={allowAccess}
                              tabIndex={1}
                            />
                            // <>
                            //   <Multiselect
                            //     {...field}
                            //     placeholder={
                            //       watch('visaId')?.length === 0
                            //         ? ' Visa Type'
                            //         : ''
                            //     }
                            //     displayValue="visa_code"
                            //     onSelect={(selected, selectedItem) => {
                            //       if (selected.length) {
                            //         setError('visaId', {
                            //           message: '',
                            //           type: 'custom',
                            //         });
                            //       } else {
                            //         setError('visaId', {
                            //           message: 'Visa type is required',
                            //           type: 'custom',
                            //         });
                            //       }
                            //       setValue(
                            //         'visaId',
                            //         selected.map((ea) => ea.visa_id).join(','),
                            //       );
                            //     }} // Function will trigger on select event
                            //     onRemove={(selected, removedItem) => {
                            //       if (selected.length) {
                            //         setError('visaId', {
                            //           message: '',
                            //           type: 'custom',
                            //         });
                            //       } else {
                            //         setError('visaId', {
                            //           message: 'Visa type is required',
                            //           type: 'custom',
                            //         });
                            //       }
                            //       setValue(
                            //         'visaId',
                            //         selected.map((ea) => ea.visa_id).join(','),
                            //       );
                            //     }}
                            //     selectedValues={visaIds}
                            //     options={!_.isEmpty(visaData) ? visaData : []}
                            //   />
                            //   {errors.visaId?.message && (
                            //     <FormHelperText style={{ color: '#ff0000' }}>
                            //       {String(errors.visaId?.message)}
                            //     </FormHelperText>
                            //   )}
                            // </>
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Fees <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        name="value"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              type={'number'}
                              placeHolder="Fees"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.value?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              tabIndex={3}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        name="name"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder="Name"
                              name={name}
                              value={value}
                              className={'custom-textarea'}
                              error={errors.name?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={2}
                              isTextArea={true}
                              minRows={3}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Status <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <div className="switch-field" tabIndex={3}>
                        <input
                          type="radio"
                          id="switch_left"
                          name="switchToggle"
                          value={'inactive'}
                          onChange={onToggleStatus}
                          checked={!statusToggle}
                          ref={inActiveStatusInput}
                        />
                        <label
                          htmlFor="switch_left d-flex-ja"
                          onClick={() => inActiveStatusInput.current.click()}>
                          Inactive
                        </label>

                        <input
                          type="radio"
                          id="switch_right"
                          name="switchToggle"
                          value={'active'}
                          onChange={onToggleStatus}
                          checked={statusToggle}
                          ref={activeStatusInput}
                        />
                        <label
                          htmlFor="switch_right d-flex-ja"
                          onClick={() => activeStatusInput.current.click()}>
                          Active
                        </label>
                      </div>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Typography>
            <Grid container>
              <Grid item md={12}>
                <Typography component={'div'} className="d-flex-ja my-2">
                  <Button className="cancel-btn mr-2" onClick={resetValues}>
                    <Typography className="o-box" />
                    Cancel
                  </Button>
                  <Button
                    className="add-btn"
                    type="submit"
                    form={'fee-add'}
                    disabled={loader}>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        )}
      </Grid>
    </Grid>
  );
};

export default ContractAdd;
