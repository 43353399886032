import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { IconButton, TablePagination } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';

const useStyles1 = makeStyles((theme: any) => ({
  button: { marginRight: '.5rem' },
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const useStyles2 = makeStyles({
  menuItem: {
    '&:hover': {
      backgroundColor: '#3b3f58',
    },
  },
  selectDropdown: { backgroundColor: '#1b1f38', color: '#fff' },
  table: {
    minWidth: 500,
  },
});

type PropsTypes = {
  total: number;
  skip: number;
  take: number;
  setParams: any;
};
const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme: any = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, Number(page) - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, Number(page) + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.button}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        {theme?.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        className={classes.button}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
        {theme?.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        className={classes.button}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme?.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        className={classes.button}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme?.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

const Pagination = (props: PropsTypes) => {
  const classes = useStyles2();
  const { total, skip, take, setParams } = props;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setParams((prev) => ({
      ...prev,
      skip: newPage * take,
    }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setParams((prev) => ({
      ...prev,
      skip: 0,
      take: parseInt(event.target.value, 10),
    }));
  };

  return (
    <TablePagination
      // SelectProps={{
      //   MenuProps: { classes: { paper: classes.selectDropdown } },
      //   inputProps: { 'aria-label': 'rows per page' },
      // }}
      classes={{ menuItem: classes.menuItem }}
      component="div"
      count={total}
      page={skip / take}
      rowsPerPageOptions={[
        50, 100, 150, 200,
        // { label: 'All', value: total - 1 },
      ]}
      onPageChange={handleChangePage}
      rowsPerPage={take}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  );
};

export default Pagination;
