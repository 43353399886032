/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable simple-import-sort/imports */
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Edit from 'assets/images/sedit.png';
// import Tick from 'assets/images/tick.svg';
import SaveIcon from '@mui/icons-material/Save';
import CustomInput from 'components/CustomInput/customInput';
import { ManageOrganizationcustomcomponent } from '../../../../../../utils/ValidatorSchema';
import { ManageOrganizationcustomtypes } from '../../../../../../types/index';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { addNewData, updateData } from 'services/CommonServices';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/helper';
import _ from 'lodash';
import { Tooltip } from '@mui/material';
import moment from 'moment';

const ContactPersonList = ({
  contactdetails,
  editId,
  index,
  setcontactslist,
  associated,
  getAllData,
}) => {
  const [fieldsetval, setfieldsetval] = useState(true);
  const [mobileWithCode, setMobileWithCode] = useState<any>({
    MobileSocCode: 'us',
    mobileCode: '+1 ',
  });

  const {
    control: control,
    setValue,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ManageOrganizationcustomtypes>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      mobileNumber: '',
      title: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ManageOrganizationcustomcomponent),
  });

  useEffect(() => {
    console.log(contactdetails);

    setValue('email', contactdetails.email_id);
    setValue('firstName', contactdetails.first_name);
    setValue('lastName', contactdetails.last_name);
    setValue('title', contactdetails.title);

    if (location.pathname.includes('associated')) {
      setValue(
        'mobileNumber',
        contactdetails?.contact_mobile
          ? String(contactdetails?.contact_mobile).indexOf('+') !== -1
            ? contactdetails?.contact_mobile
            : contactdetails?.country_code_mobile
            ? String(contactdetails?.country_code_mobile)
                .concat(' ')
                .concat(contactdetails?.contact_mobile)
            : '+1 '.concat(contactdetails?.contact_mobile)
          : '',
      );
      setMobileWithCode((pre) => ({
        ...pre,
        MobileSocCode: contactdetails?.country_source_code
          ? contactdetails?.country_source_code
          : 'us',
        mobileCode: contactdetails?.country_code_mobile
          ? contactdetails?.country_code_mobile
          : '+1 ',
      }));
    } else {
      setValue(
        'mobileNumber',
        contactdetails?.mobile_number
          ? String(contactdetails?.mobile_number).indexOf('+') !== -1
            ? contactdetails?.mobile_number
            : contactdetails?.mobile_country_code
            ? String(contactdetails?.mobile_country_code)
                .concat(' ')
                .concat(contactdetails?.mobile_number)
            : '+1 '.concat(contactdetails?.mobile_number)
          : '',
      );
      setMobileWithCode((pre) => ({
        ...pre,
        MobileSocCode: contactdetails?.mobile_soc_code
          ? contactdetails?.mobile_soc_code
          : 'us',
        mobileCode: contactdetails?.mobile_country_code
          ? contactdetails?.mobile_country_code
          : '+1 ',
      }));
    }
    setMobileWithCode((pre) => ({
      ...pre,
      MobileSocCode: contactdetails?.mobile_soc_code
        ? contactdetails?.mobile_soc_code
        : 'us',
      mobileCode: contactdetails?.mobile_country_code
        ? contactdetails?.mobile_country_code
        : '+1 ',
    }));
  }, [contactdetails, setValue]);

  const onSubmit = () => {
    handleSubmit((e) => onSubmitForm(e))();
  };
  const onSubmitForm = async (data: any) => {
    const person = contactdetails;
    if (!_.isEmpty(errors)) {
      return;
    }
    try {
      if (location.pathname.includes('associated')) {
        const postFormData = new FormData();

        postFormData.append('logo', person.image ? person.image : null);
        postFormData.append(
          'firstName',
          data['firstName'] ? data['firstName'] : '',
        );
        postFormData.append('title', data['title'] ? data['title'] : '');
        postFormData.append(
          'lastName',
          data['lastName'] ? data['lastName'] : '',
        );
        postFormData.append('gender', person.gender ? person.gender : '');
        postFormData.append(
          'dateOfJoining',
          person.date_of_join
            ? moment(person.date_of_join).format('YYYY-MM-DD hh:mm')
            : null,
        );
        postFormData.append(
          'dateOfBirth',
          person.dob ? moment(person.dob).format('YYYY-MM-DD hh:mm') : null,
        );
        postFormData.append('address', person.address ? person.address : '');
        postFormData.append('zipCode', person.zip_code ? person.zip_code : '');
        postFormData.append('city', person.city ? person.city : '');
        postFormData.append('state', person.state ? person.state : '');
        postFormData.append('country', person.country ? person.country : '');
        postFormData.append(
          'unitType',
          person.unit_type ? person.unit_type : '',
        );
        postFormData.append(
          'unitNumber',
          person.unit_number ? person.unit_number : '',
        );

        // postFormData.append(
        //   'roleRestrict',
        //   data['roleRestrict'] ? data['roleRestrict'] : false,
        // );
        postFormData.append(
          'phoneNumber',
          data['phoneNumber'] ? data['phoneNumber'] : '',
        );
        postFormData.append(
          'mobileNumber',
          data['mobileNumber']
            ? String(data['mobileNumber']).split(/(?<=^\S+)\s/)[1]
            : '',
        );

        postFormData.append('mobileCode', mobileWithCode.mobileCode);
        postFormData.append(
          'MobileSocCode',
          String(mobileWithCode.MobileSocCode).toUpperCase(),
        );

        postFormData.append('contactEmail', data['email'] ? data['email'] : '');
        postFormData.append(
          'roleAssignedId',
          person.role_assigned ? person.role_assigned : 2,
        );

        postFormData.append(
          'status',
          person.conatct_status ? person.conatct_status : 'pending',
        );
        if (!person.id) {
          postFormData.append('addedBy', String(3));
          postFormData.append('petitionerId', editId);
        }
        if (!_.isUndefined(person.id)) {
          updateData(
            person.id,
            postFormData,
            COMMON_ENDPOINT.ManageAddEditUsers,
          )
            .then((resp: any) => {
              toast.success(
                'Contact Person Updated successfully',
                alertOptions,
              );
              getAllData();
              setfieldsetval(true);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          addNewData(postFormData, COMMON_ENDPOINT.ManageAddEditUsers)
            .then((resp: any) => {
              toast.success(
                'Contact Person Created Successfully.',
                alertOptions,
              );
              getAllData();
              setfieldsetval(true);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } else {
        if (person?.id) {
          const bodyObj = {
            associated: associated,
            contactMobileCountryCode: mobileWithCode.mobileCode
              ? mobileWithCode.mobileCode
              : '+1 ',
            contactMobileSocCode: mobileWithCode.MobileSocCode
              ? mobileWithCode.MobileSocCode
              : 'us',
            primaryContact: person?.primary_contact,
            ...watch(),
            mobileNumber: getValues('mobileNumber')
              ? String(getValues('mobileNumber')).split(/(?<=^\S+)\s/)[1]
              : '',
            ...(location.pathname.includes('edit-associatedOrg') && {
              contactEmail: getValues('email'),
              contactType: person.contact_type ? person.contact_type : null,
              status: person.conatct_status ? person.conatct_status : null,
            }),
          };

          await updateData(
            person?.id,
            bodyObj,
            location.pathname.includes('edit-associatedOrg')
              ? `${COMMON_ENDPOINT.ManagePetitioner}/user`
              : `${SETUP_ENDPOINT.ManageOrganization}/contact`,
          );
          setcontactslist &&
            setcontactslist((pre) => [
              ...pre.map((e, oldIndex) => {
                if (oldIndex === index) {
                  return {
                    ...e,
                    email_id: location.pathname.includes('edit-associatedOrg')
                      ? bodyObj?.contactEmail
                      : bodyObj?.email,
                    first_name: bodyObj?.firstName,
                    last_name: bodyObj?.lastName,
                    mobile_country_code: bodyObj?.contactMobileCountryCode,
                    mobile_number: bodyObj?.mobileNumber,
                    mobile_soc_code: bodyObj?.contactMobileSocCode,
                    title: bodyObj?.title,
                  };
                }
                return { ...e };
              }),
            ]);
          getAllData();
          toast.success('Contact person details modified', alertOptions);
          setfieldsetval(true);
        } else {
          const bodyObj = {
            associated: associated,
            contactMobileCountryCode: mobileWithCode.mobileCode
              ? mobileWithCode.mobileCode
              : '+1 ',
            contactMobileSocCode: mobileWithCode.MobileSocCode
              ? mobileWithCode.MobileSocCode
              : 'us',
            primaryContact: false,
            ...watch(),
            mobileNumber: getValues('mobileNumber')
              ? String(getValues('mobileNumber')).split(/(?<=^\S+)\s/)[1]
              : '',
          };
          const addInfo = await addNewData(
            bodyObj,
            `${SETUP_ENDPOINT.ManageOrganization}/contact/${editId}`,
          );
          getAllData();
          toast.success('Contact person has been added', alertOptions);
          setcontactslist &&
            setcontactslist((pre) => [
              ...pre.map((e, oldIndex) => {
                if (oldIndex === index) {
                  return {
                    ...e,
                    email_id: bodyObj?.email,
                    first_name: bodyObj?.firstName,
                    id: addInfo.id,
                    last_name: bodyObj?.lastName,
                    mobile_country_code: bodyObj?.contactMobileCountryCode,
                    mobile_number: bodyObj?.mobileNumber,
                    mobile_soc_code: bodyObj?.contactMobileSocCode,
                    primary_contact: addInfo.primary_contact,
                    title: bodyObj?.title,
                    user_phone: null,
                  };
                }
                return { ...e };
              }),
            ]);
          setfieldsetval(true);
        }
      }
    } catch (e) {
      // console.log(e, 'error');
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container columnSpacing={1.5} rowSpacing={2}>
        <Grid item md={2.2} className="mb-1" sm={2.67}>
          <Typography
            component={'div'}
            className="custom-field custom-field-additional">
            <Typography component={'div'} className="custom-label">
              First Name{' '}
              <Typography component={'span'} className="Mui-error">
                *
              </Typography>
            </Typography>
            <Controller
              control={control}
              name="firstName"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <CustomInput
                    maxLength={50}
                    placeHolder="First Name"
                    name={name}
                    value={value}
                    className={'custom-input'}
                    disabled={fieldsetval && contactdetails?.id}
                    error={errors.firstName?.message}
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={2.2} className="mb-1" sm={2.67}>
          <Typography
            component={'div'}
            className="custom-field custom-field-additional">
            <Typography component={'div'} className="custom-label">
              Last Name{' '}
              <Typography component={'span'} className="Mui-error">
                *
              </Typography>
            </Typography>
            <Controller
              control={control}
              name="lastName"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <CustomInput
                    maxLength={50}
                    placeHolder="Last Name"
                    name={name}
                    value={value}
                    className={'custom-input'}
                    error={errors.lastName?.message}
                    disabled={fieldsetval && contactdetails?.id}
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={2.2} className="mb-1" sm={2.67}>
          <Typography
            component={'div'}
            className="custom-field custom-field-additional">
            <Typography component={'div'} className="custom-label">
              Email{' '}
              <Typography component={'span'} className="Mui-error">
                *
              </Typography>
            </Typography>
            <Controller
              control={control}
              name={'email'}
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <CustomInput
                    maxLength={50}
                    placeHolder="email@id.com"
                    name={name}
                    value={value}
                    className={'custom-input'}
                    error={errors.email?.message}
                    disabled={
                      location.pathname.includes('edit-associatedOrg')
                        ? contactdetails.conatct_status === 'active'
                          ? true
                          : fieldsetval && contactdetails?.id
                        : fieldsetval && contactdetails?.id
                    }
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={2.2} className="mb-1" sm={2.67}>
          <Typography
            component={'div'}
            className="custom-field custom-field-additional">
            <Typography component={'div'} className="custom-label">
              Title
            </Typography>
            <Controller
              control={control}
              name="title"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <CustomInput
                    maxLength={50}
                    placeHolder="Tiltle"
                    name={name}
                    value={value}
                    className={'custom-input'}
                    disabled={fieldsetval && contactdetails?.id}
                    error={errors.title?.message}
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={2.2} className="mb-1" sm={2.67}>
          <Typography
            component={'div'}
            className="custom-field custom-field-additional">
            <Typography component={'div'} className="custom-label">
              Mobile Number
            </Typography>
            <Controller
              control={control}
              name="mobileNumber"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <PhoneInput
                    placeholder="Phone"
                    country={'us'}
                    value={value}
                    isValid={true}
                    disabled={fieldsetval && contactdetails?.id}
                    onChange={(value, data, event, formattedValue) => {
                      const codes: any = data;
                      onChange(formattedValue);
                      setMobileWithCode({
                        MobileSocCode: codes?.countryCode,
                        mobileCode: '+'.concat(codes?.dialCode),
                      });
                    }}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={1} sm={1} className="d-flex-a mb-1">
          <Typography className="text-right">
            {fieldsetval && contactdetails?.id ? (
              <Tooltip title={'Edit'} placement="top">
                <img
                  style={{
                    cursor: 'pointer',
                    height: '23px',
                    marginTop: '28px',
                  }}
                  className="edit"
                  src={Edit}
                  alt="edit"
                  onClick={() => setfieldsetval((p) => !p)}
                />
              </Tooltip>
            ) : (
              <Tooltip title={'Save'} placement="top">
                <SaveIcon
                  sx={{
                    fill: 'gray',
                    marginTop: '28px',
                  }}
                  className="edit pointer"
                  onClick={() => {
                    onSubmit();
                  }}
                />
              </Tooltip>
            )}
            {!contactdetails?.id ? (
              <Tooltip title={'Delete'} placement="top">
                <DeleteForeverIcon
                  sx={{
                    cursor: 'pointer',
                    fill: 'red',
                    height: '25px',
                    marginTop: '28px',
                    width: '25px',
                  }}
                  {...{
                    onClick: () => {
                      setcontactslist &&
                        setcontactslist((pre) => [
                          ...pre.filter((e, oldIndex) => oldIndex !== index),
                        ]);
                    },
                  }}
                />
              </Tooltip>
            ) : (
              ''
            )}
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactPersonList;
