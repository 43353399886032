import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { USER_LOGOUT } from 'store/types/UserTypes';
import Storage from 'utils/Storage';

const LoadingToRedirect = () => {
  const dispatch = useAppDispatch();
  const [count, setCount] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => currentCount - 1);
    }, 1000);

    if (count === 0) {
      dispatch({
        type: USER_LOGOUT,
      });
      Storage.clear();
      navigate('/login');
    }

    return () => clearInterval(interval);
  }, [count, dispatch, navigate]);

  return <div className="liberty-loader" />;
};

export default LoadingToRedirect;
