import { yupResolver } from '@hookform/resolvers/yup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SearchIcon from '@mui/icons-material/Search';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import Avator from 'assets/images/d-avator.svg';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomInput from 'components/CustomInput/customInput';
// import CustomSelect from 'components/CustomSelect/customSelect';
// import InfinityScroll from 'components/InfinityScroll';
// import Pagination from 'components/Pagination';
import { AdminPrivateRoutes } from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { fileApiUrl } from 'utils/Config';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { Petionerscheme } from 'utils/ValidatorSchema';

import useStyles from '../../PrivateStyle';

// const Status = [
//   {
//     label: 'Active',
//     value: true,
//   },
//   {
//     label: 'Inactive',
//     value: false,
//   },
// ];

export const ManagePetitioner: React.FC<any> = () => {
  const navigate = useNavigate();

  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState('1');
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [hasMoreResults1, setHasMoreResults1] = useState(false);
  const [getMoreLoader1, setGetMoreLoader1] = useState(false);
  const [open, setOpen] = React.useState({
    fieldName: '',
    id: '',
    show: false,
  });
  const {
    control: control,
    handleSubmit: handleSubmit,
    // reset: resetForm,
    setValue,
    // watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      CustomField: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(Petionerscheme),
  });
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [params, setParams] = useState<any>({
    orgName: '',
    skip: 0,
    status: true,
    take: 50,
  });
  const [cfParams, setCFParams] = useState<any>({
    fieldName: '',
    id: '',
    roleId: 2,
    skip: 0,
    status: true,
    take: 50,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    deletedId: '',
    show: false,
  });
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [petitionerData, setPetitionerData] = useState<any>({});
  const [customFields, setCustomFields] = useState<any>({});
  const [tableLoader1, setTableLoader1] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const statusOpen = Boolean(anchorEl);
  const handleStatusOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleStatusClose = () => {
    setAnchorEl(null);
  };
  const debouncedParams = useDebounce(params, 500);
  const debouncedcfParams = useDebounce(cfParams, 500);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // if (newValue === '2') {
    // setLoader(true);
    // getCustomFieds();
    // }
  };

  const createCustomField = () => {
    addNewData(
      { fieldName: open.fieldName, roleId: 2 },
      COMMON_ENDPOINT.CustomFields,
    )
      .then((resp: any) => {
        toast.success('Fields added successfully', alertOptions);
        getCustomFieds();
        setOpen({
          fieldName: '',
          id: '',
          show: false,
        });
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const onUpdateCustomField = (data: any) => {
    updateData(
      open.id !== '' && open.id,
      { fieldName: data.CustomField },
      COMMON_ENDPOINT.CustomFields,
    )
      .then((resp: any) => {
        toast.success('Fields added successfully', alertOptions);
        getCustomFieds();
        setOpen({ fieldName: '', id: '', show: false });
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  const deleteConfirmation = (action: string) => {
    if (action !== 'yes') {
      setDeleteDialog((pre) => ({
        ...pre,
        show: false,
      }));
      return;
    }
    deleteData(deleteDialog.deletedId, COMMON_ENDPOINT.CustomFields)
      .then((resp: any) => {
        getCustomFieds();
        toast.success('Custom field deleted.', alertOptions);
        setDeleteDialog((pre) => ({
          ...pre,
          show: false,
        }));
      })
      .catch((e) => {
        toast.error('Unable to delete.', alertOptions);
      });
  };

  const getCustomFieds = useCallback(() => {
    getAllListData(
      `${COMMON_ENDPOINT.CustomFields}?skip=${String(
        debouncedcfParams.skip,
      )}&take=${String(debouncedcfParams.take)}&fieldName=${String(
        debouncedcfParams.fieldName,
      )}&id=${String(debouncedcfParams.id)}&roleId=${String(
        debouncedcfParams.roleId,
      )}&status=${String(debouncedcfParams.status)}`,
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setCustomFields((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          const mergeData = !_.isEmpty(customFields)
            ? [...customFields.data, ...resp.data]
            : resp.data;
          setCustomFields({
            count: resp.total,
            data: mergeData,
          });
        }
        setTableLoader1(false);
        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setCustomFields);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedcfParams]);
  const getMoreList = () => {
    if (!tableLoader1) {
      setGetMoreLoader(true);
      setCFParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };

  useEffect(() => {
    getAllListData(
      `${COMMON_ENDPOINT.ManagePetitioner}?skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}${
        debouncedParams.orgName !== ''
          ? '&orgName='.concat(debouncedParams.orgName)
          : ''
      }&status=${String(debouncedParams.status)}`,
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader1);
        if (moreLoader) {
          setPetitionerData((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          const mergeData = !_.isEmpty(petitionerData)
            ? [...petitionerData.data, ...resp.data]
            : resp.data;
          setPetitionerData({
            count: resp.total,
            data: mergeData,
          });
        }
        setTableLoader(false);
        setLoader(false);
        setGetMoreLoader1(false);
        const allList = await instantState(setPetitionerData);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults1(true);
        } else {
          setHasMoreResults1(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);
  const getMoreList1 = () => {
    if (!tableLoader) {
      setGetMoreLoader1(true);
      setParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };

  useEffect(() => {
    tabValue === '2' && getCustomFieds();
  }, [debouncedParams, getCustomFieds, tabValue]);
  // const rowProps = (petitioner, index) => {
  //   return (
  //     <NavLink
  //       to={
  //         userDetail.role_id === 1 &&
  //         `${
  //           AdminPrivateRoutes.MANAGEPETITIONERS.MANAGEPETITIONEREDIT
  //         }/${String(petitioner.id)}`
  //       }
  //       key={index}>
  //       <Typography className="configureItem">
  //         <img
  //           src={
  //             !petitioner?.file_name
  //               ? Avator
  //               : `${fileApiUrl}${String(petitioner?.file_name?.id)}`
  //           }
  //           alt="upic"
  //           className={classes.userImg}
  //         />
  //         <Typography className="d-flex-a d-flex-sb w-100 pl-2">
  //           <Typography component={'div'} className="d-flex-3">
  //             <Typography className="black-white-text ctitle">
  //               {petitioner.org_name}
  //             </Typography>
  //             <Grid
  //               container
  //               component={'div'}
  //               sx={{ paddingTop: '.5rem !important' }}>
  //               <Grid item md={12} sm={12}>
  //                 <Typography className="rl-text">
  //                   Email: {petitioner.org_email}
  //                 </Typography>
  //               </Grid>
  //             </Grid>
  //           </Typography>
  //           <Typography className="blue-text d-flex-1 text-right">
  //             {petitioner.org_status === 'active' ? 'Active' : 'Inactive'}
  //           </Typography>
  //         </Typography>
  //       </Typography>
  //     </NavLink>
  //   );
  // };
  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12} className="documentTab pr-2rem">
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Typography component={'div'} className="d-flex-a w-100 d-flex-sb">
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example">
                <Tab label="Petitioner" value="1" />
                <Tab label="Custom Fields" value="2" />
              </TabList>
              {tabValue === '1' && (
                <Typography
                  component={'div'}
                  className="d-flex-jae full-screen-searc mt-1">
                  <Typography
                    component={'div'}
                    className="d-flex-a small-search"
                    sx={{
                      backgroundColor: '#ffffff',
                      borderRadius: '4px',
                    }}>
                    <Typography
                      className="d-flex-a searc-box1"
                      component={'div'}>
                      <SearchIcon
                        sx={{
                          fill: '#3d3f40 !important',
                          left: '5px',
                          position: 'absolute',
                          zIndex: '1',
                        }}
                      />
                      <CustomInput
                        placeHolder={'Search'}
                        className={'custom-search-input'}
                        value={params.orgName}
                        inputProps={{
                          endAdornment:
                            params.orgName !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    setTableLoader(true);
                                    setPetitionerData({
                                      count: 0,
                                      data: [],
                                    });
                                    setParams((prev) => ({
                                      ...prev,
                                      orgName: '',
                                      skip: 0,
                                      take: 50,
                                    }));
                                  }}>
                                  <CloseIcon
                                    sx={{
                                      fill: '#3d3f40 !important',
                                    }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          setTableLoader(true);

                          setPetitionerData({
                            count: 0,
                            data: [],
                          });
                          setParams((prev) => ({
                            ...prev,
                            orgName: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                      />
                      {/* <CustomInput
                        placeHolder={'Search for filter'}
                        className={'custom-search-input'}
                        value={params.orgName}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                {...(params.orgName === ''
                                  ? {}
                                  : {
                                      onClick: () => {
                                        setPetitionerData({
                                          count: 0,
                                          data: [],
                                        });
                                        setParams((prev) => ({
                                          ...prev,
                                          orgName: '',
                                        }));
                                      },
                                    })}>
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setPetitionerData({
                            count: 0,
                            data: [],
                          });
                          setParams((prev) => ({
                            ...prev,
                            orgName: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                      /> */}
                    </Typography>
                  </Typography>
                  {/* <Typography sx={{ width: '205px' }} className="searc-box1">
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      value={params.status}
                      options={Status}
                      selectedValue={''}
                      onChange={(e) => {
                        setPetitionerData({
                          count: 0,
                          data: [],
                        });
                        setParams((prev) => ({
                          ...prev,
                          skip: 0,
                          status: e.target.value,
                          take: 50,
                        }));
                      }}
                      placeHolder={'Status'}
                      className={'custom-search-input'}
                    />
                  </Typography> */}
                </Typography>
              )}
              {tabValue === '2' && (
                <Typography className="d-flex-jae mt-1">
                  <CustomInput
                    extraStyle={{ width: '20vw' }}
                    className={'outer-custom-input'}
                    type="text"
                    placeHolder={'Custom Field Name'}
                    onChange={(e) => {
                      const fieldName = e.target.value.replace(
                        /[^A-Z a-z]/g,
                        '',
                      );
                      e.target.value = fieldName;

                      setOpen((pre) => ({
                        ...pre,
                        fieldName: fieldName,
                      }));
                    }}
                  />
                  <Button
                    disabled={open?.fieldName ? false : true}
                    onClick={() => {
                      createCustomField();
                    }}
                    className="n-add-btn ml-1"
                    sx={{
                      borderRadius: '5px !important',
                      height: '39px !important',
                    }}>
                    Add
                  </Button>
                  <Typography component={'div'} className="d-flex-a ml-1">
                    <Button
                      className="n-add-btn"
                      sx={{
                        borderRadius: '5px !important',
                        height: '39px !important',
                      }}
                      id="basic-button"
                      aria-controls={statusOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={statusOpen ? 'true' : undefined}
                      onClick={handleStatusOpen}>
                      {cfParams.status ? 'Active' : 'InActive'}{' '}
                      <ArrowDropDownIcon />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={statusOpen}
                      onClose={handleStatusClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}>
                      <MenuItem
                        sx={{ width: '118px !important' }}
                        onClick={() => {
                          setCFParams((prev) => ({
                            ...prev,
                            skip: 0,
                            status: true,
                            take: 50,
                          }));
                          setTableLoader1(true);
                          setCustomFields([]);
                          handleStatusClose();
                        }}>
                        Active
                      </MenuItem>
                      <MenuItem
                        sx={{ width: '118px !important' }}
                        onClick={() => {
                          setCFParams((prev) => ({
                            ...prev,
                            skip: 0,
                            status: false,
                            take: 50,
                          }));
                          setTableLoader1(true);
                          setCustomFields([]);
                          handleStatusClose();
                        }}>
                        InActive
                      </MenuItem>
                    </Menu>
                  </Typography>
                  <Modal
                    open={open.show}
                    onClose={() =>
                      setOpen({ fieldName: '', id: '', show: false })
                    }
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box className="custom-file-modal">
                      <Typography className="modal-title font-bold">
                        Custom Field
                      </Typography>
                      <Typography className="m-border">
                        <form
                          id="tableupdate"
                          onSubmit={handleSubmit(onUpdateCustomField)}>
                          <Typography className="custom-field">
                            <Typography className="custom-label">
                              Custom Field <span className="required">*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name="CustomField"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    placeHolder="Custom Field"
                                    name={name}
                                    className={'custom-input'}
                                    onChange={(e) => {
                                      const vals = e.target.value.replace(
                                        /[^a-zA-Z s]/,
                                        '',
                                      );
                                      onChange(vals);
                                      setOpen((pre) => ({
                                        ...pre,
                                        fieldName: e.target.value,
                                      }));
                                    }}
                                    value={value}
                                    error={errors.CustomField?.message}
                                  />
                                );
                              }}
                            />
                          </Typography>
                          <Typography className="d-flex-jae modal-footer">
                            <Button
                              className="mcancel-btn f-18 font-bold mr-1"
                              onClick={() =>
                                setOpen({
                                  fieldName: '',
                                  id: '',
                                  show: false,
                                })
                              }>
                              Cancel
                            </Button>
                            <Button
                              className="madd-btn f-18 font-bold"
                              type={'submit'}
                              form={'tableupdate'}>
                              Save
                            </Button>
                          </Typography>
                        </form>
                      </Typography>
                    </Box>
                  </Modal>
                </Typography>
              )}
            </Typography>
          </Box>
          <TabPanel value="1">
            {loader ? (
              <div className="liberty-loader" />
            ) : (
              // <Box sx={{ width: '100%' }}>
              //   <Typography
              //     component={'div'}
              //     className="configureBox pad-sadow">
              //     {petitionerData?.data.length > 0 ? (
              //       <InfinityScroll
              //         paramState={setParams}
              //         // height={'56vh'}
              //         scrollableTargetId={'managePetitioner'}
              //         data={petitionerData.data}
              //         rowProps={rowProps}
              //         count={petitionerData.count}
              //       />
              //     ) : (
              //       <Typography
              //         className="note-box2"
              //         style={{
              //           display: 'flex',
              //           justifyContent: 'center',
              //           width: '100%',
              //         }}>
              //         <Typography className="inb2" />
              //         <Typography className="black-white-text ctitle hideshow">
              //           No Record(s) Found.
              //         </Typography>
              //       </Typography>
              //     )}
              //   </Typography>
              // </Box>
              <Typography component={'div'}>
                {/* <Typography
                  component={'div'}
                  className="d-flex-a small-search"
                  sx={{
                    backgroundColor: '#84898c',
                    borderRadius: '4px',
                  }}>
                  <Typography className="d-flex-a searc-box1" component={'div'}>
                    <SearchIcon
                      sx={{
                        fill: '#3d3f40 !important',
                        left: '5px',
                        position: 'absolute',
                        zIndex: '1',
                      }}
                    />
                    <CustomInput
                      placeHolder={'Search for filter'}
                      className={'custom-search-input'}
                      value={params.orgName}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              {...(params.orgName === ''
                                ? {}
                                : {
                                    onClick: () => {
                                      setPetitionerData({
                                        count: 0,
                                        data: [],
                                      });
                                      setParams((prev) => ({
                                        ...prev,
                                        orgName: '',
                                      }));
                                    },
                                  })}>
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setPetitionerData({
                          count: 0,
                          data: [],
                        });
                        setParams((prev) => ({
                          ...prev,
                          orgName: e.target.value,
                          skip: 0,
                          take: 50,
                        }));
                      }}
                    />
                  </Typography>
                </Typography> */}
                <Typography
                  component={'div'}
                  className={classes.scrollbox}
                  id="scrollParent"
                  sx={{
                    borderRadius: '10px 10px 0px 0px',
                  }}>
                  <InfiniteScroll
                    dataLength={
                      petitionerData &&
                      petitionerData.data &&
                      petitionerData.data.length
                        ? petitionerData.data.length
                        : 0
                    }
                    style={{
                      overflow: 'unset',
                    }}
                    next={getMoreList1 && getMoreList1}
                    hasMore={hasMoreResults1}
                    scrollableTarget={'scrollParent'}
                    loader={
                      getMoreLoader1 && (
                        <div
                          style={{
                            backgroundColor: '#ffffff',
                            textAlign: 'center',
                            width: '100%',
                          }}>
                          <CircularProgress
                            size={30}
                            sx={{
                              color: '#002c69',
                              marginBottom: 10,
                              marginTop: 10,
                            }}
                          />
                        </div>
                      )
                    }
                    endMessage>
                    <table className="lead-table table-hover">
                      <thead>
                        <tr>
                          <th className="W-2">Organization Name</th>
                          <th className="W-1">Country </th>
                          <th className="W-1">State</th>
                          <th className="W-1">City</th>
                          <th className="W-1">Phone</th>
                          <th className="W-1">Email</th>
                          <th className="W-1">Valid Till </th>
                          <th className="W-1">Status </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableLoader ? (
                          <tr>
                            <td colSpan={8}>
                              <div
                                style={{
                                  backgroundColor: '#ffffff',
                                  textAlign: 'center',
                                  width: '100%',
                                }}>
                                <CircularProgress
                                  size={30}
                                  sx={{
                                    color: '#002c69',
                                    marginBottom: 10,
                                    marginTop: 10,
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>
                            {!_.isEmpty(petitionerData?.data) &&
                            petitionerData.data.length > 0
                              ? petitionerData.data.map((petitioner, index) => (
                                  // <NavLink
                                  //   to={
                                  //     userDetail.role_id === 1 &&
                                  //     `${
                                  //       AdminPrivateRoutes.VISATYPE.VISATYPEEDIT
                                  //     }/${String(petitioner.visa_id)}`
                                  //   }
                                  //   key={index}>
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      navigate(
                                        userDetail.role_id === 1 &&
                                          `${
                                            AdminPrivateRoutes.MANAGEPETITIONERS
                                              .MANAGEPETITIONEREDIT
                                          }/${String(petitioner.id)}`,
                                      )
                                    }
                                    style={{ cursor: 'pointer' }}>
                                    <td
                                      style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                      }}
                                      // className="section"
                                    >
                                      <img
                                        src={
                                          !petitioner?.file_name
                                            ? Avator
                                            : `${fileApiUrl}${String(
                                                petitioner?.file_name?.id,
                                              )}`
                                        }
                                        alt="upic"
                                        className={classes.userImg}
                                      />
                                      <span style={{ paddingLeft: '5px' }}>
                                        <div
                                          className="hideshow"
                                          style={{
                                            width: '200px',
                                            wordBreak: 'break-all',
                                          }}>
                                          {petitioner.org_name
                                            ? petitioner.org_name
                                            : '-'}
                                        </div>
                                      </span>
                                    </td>
                                    <td>
                                      {petitioner.org_country
                                        ? petitioner.org_country
                                        : '-'}
                                    </td>
                                    <td>
                                      {petitioner.org_state
                                        ? petitioner.org_state
                                        : '-'}
                                    </td>
                                    <td>
                                      {petitioner.org_city
                                        ? petitioner.org_city
                                        : '-'}
                                    </td>
                                    <td>
                                      {petitioner.org_phone
                                        ? petitioner.org_phone
                                        : '-'}
                                    </td>
                                    <td>
                                      <div
                                        className="hideshow"
                                        style={{
                                          width: '150px',
                                          wordBreak: 'break-all',
                                        }}>
                                        {petitioner.org_signatory_email
                                          ? petitioner.org_signatory_email
                                          : '-'}
                                      </div>
                                    </td>
                                    <td>
                                      {petitioner.org_valid_till
                                        ? moment(
                                            petitioner.org_valid_till,
                                          ).format('MM/DD/yyyy')
                                        : '-'}
                                      {/* {petitioner.org_valid_till
                                    ? petitioner.org_valid_till
                                    : '-'} */}
                                    </td>
                                    <td>
                                      {petitioner.org_status === 'active'
                                        ? 'Active'
                                        : 'Inactive'}
                                    </td>
                                    {/* // </NavLink> */}
                                  </tr>
                                ))
                              : !tableLoader && (
                                  <tr className={'text-center'}>
                                    <td colSpan={8}>No Record(s) Found.</td>
                                  </tr>
                                )}
                          </>
                        )}
                      </tbody>
                    </table>
                    {/* <Pagination
          total={!_.isEmpty(lists?.data) ? Number(lists?.count) : 0}
          skip={params.skip}
          take={params.take}
          setParams={setParams}
        /> */}
                  </InfiniteScroll>
                </Typography>
              </Typography>
            )}
          </TabPanel>
          <TabPanel value="2">
            {loader ? (
              <div className="liberty-loader" />
            ) : (
              <Typography component={'div'} sx={{ overflow: 'hidden' }}>
                <TableContainer
                  sx={{
                    borderRadius: '8px 8px 0 0',
                  }}>
                  <Typography
                    component={'div'}
                    className={classes.scrollbox}
                    id="scrollParent"
                    style={{ minHeight: '106px' }}>
                    <InfiniteScroll
                      dataLength={
                        customFields &&
                        customFields.data &&
                        customFields.data.length
                          ? customFields.data.length
                          : 0
                      }
                      style={{
                        overflow: 'unset',
                      }}
                      next={getMoreList && getMoreList}
                      hasMore={hasMoreResults}
                      scrollableTarget={'scrollParent'}
                      loader={
                        getMoreLoader && (
                          <div
                            style={{
                              backgroundColor: '#ffffff',
                              textAlign: 'center',
                              width: '100%',
                            }}>
                            <CircularProgress
                              size={30}
                              sx={{
                                color: '#002c69',
                                marginBottom: 10,
                                marginTop: 10,
                              }}
                            />
                          </div>
                        )
                      }
                      endMessage>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        className="lead-table table-hover"
                        style={{ minHeight: '106px' }}>
                        <TableHead>
                          <TableRow>
                            <TableCell className="W-1">S.No</TableCell>
                            <TableCell className="W-5">Field Name</TableCell>
                            <TableCell className="W-1">Status</TableCell>
                            <TableCell className="W-1">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableLoader1 ? (
                            <tr>
                              <td colSpan={8}>
                                <div
                                  style={{
                                    backgroundColor: '#ffffff',
                                    textAlign: 'center',
                                    width: '100%',
                                  }}>
                                  <CircularProgress
                                    size={30}
                                    sx={{
                                      color: '#002c69',
                                      marginBottom: 10,
                                      marginTop: 10,
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ) : (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                              {customFields &&
                              customFields.data &&
                              customFields.data.length > 0
                                ? customFields.data.map((field, index) => (
                                    <TableRow
                                      key={index}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setOpen({
                                          fieldName: field.field_name,
                                          id: field.id,
                                          show: true,
                                        });
                                        setValue(
                                          'CustomField',
                                          field.field_name,
                                        );
                                      }}>
                                      <TableCell>{Number(index) + 1}</TableCell>
                                      <TableCell>{field.field_name}</TableCell>
                                      <TableCell>
                                        {field.status ? 'Active' : 'In Active'}
                                      </TableCell>
                                      <TableCell>
                                        <DeleteForeverIcon
                                          sx={{
                                            cursor: 'pointer',
                                            fill: 'red',
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setDeleteDialog((pre) => ({
                                              ...pre,
                                              deletedId: field.id,
                                              show: true,
                                            }));
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))
                                : !tableLoader1 && (
                                    <TableRow className={'text-center'}>
                                      <TableCell colSpan={4}>
                                        No Record(s) Found.
                                      </TableCell>
                                    </TableRow>
                                  )}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </InfiniteScroll>
                  </Typography>
                </TableContainer>
              </Typography>
            )}
            {/* <Pagination
              total={
                !_.isEmpty(customFields?.data) ? Number(customFields?.count) : 0
              }
              skip={cfParams.skip}
              take={cfParams.take}
              setParams={setCFParams}
            /> */}
          </TabPanel>
        </TabContext>
        <ConfirmDialog
          title="DELETE"
          floatingBtnShow={true}
          open={deleteDialog.show}
          close={() => {
            setDeleteDialog((pre) => ({
              ...pre,
              show: false,
            }));
          }}
          onConfirm={deleteConfirmation}
          autoCloseDisable={true}>
          {'Are you sure do you want to delete?'}
        </ConfirmDialog>
      </Grid>
    </Grid>
  );
};

export default ManagePetitioner;
