import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
// import Pagination from 'components/Pagination';
import { AttorneyPrivateRoutes } from 'config';
import { saveAs } from 'file-saver';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { downloadExcel } from 'react-export-table-to-excel';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPdfFile } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import useStyles from '../../PrivateStyle';

type TableProps = {
  lists;
  params;
  setParams: any;
  userDetail;
  isExport;
  setIsExport;
  tab;
  hasMoreResults?: boolean;
  getMoreLoader?: boolean;
  getMoreList: any;
  tableIntialloader: boolean;
  isOrg?: boolean;
  orgId?: string;
};

export const ReportTable: React.FC<TableProps> = ({
  params,
  setParams,
  lists,
  userDetail,
  isExport,
  setIsExport,
  tab,
  hasMoreResults: HasMoreResults,
  getMoreLoader,
  getMoreList,
  tableIntialloader,
  isOrg,
  orgId,
}) => {
  const tableRef = useRef(null);
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [orderDirection, setOrderDirection] = useState<any>({
    beneficiaryName: 'desc',
    payment_done_through: 'desc',
    payment_total: 'desc',
    petitionerName: 'desc',
    total_amount: 'desc',
    updated_at: 'desc',
  });
  const handleSortRequest = (sortby: string) => {
    setOrderDirection((prev) => ({
      ...prev,
      [sortby]: prev[sortby] === 'asc' ? 'desc' : 'asc',
    }));
    setParams((prev) => ({
      ...prev,
      order: orderDirection[sortby] === 'asc' ? 'DESC' : 'ASC',
      sortby: sortby,
    }));
  };

  const fileDownload = (data) => {
    const fileName = `Report-${String(data.transaction_id)}-${
      new Date().getMonth() + 1
    }/${new Date().getDate()}/${new Date().getFullYear()}.pdf`;
    setLoader(true);
    const obj = {
      key: 'pdf',
    };
    getPdfFile(
      obj,
      `${COMMON_ENDPOINT.PaymentSlip}` + `${String(data.transaction_id)}`,
    )
      .then((resData: any) => {
        saveAs(resData, fileName);
        setLoader(false);
        toast.success(fileName + ' File downloaded successfully', alertOptions);
      })
      .catch((err) => {
        setLoader(false);
        toast.error('Unable to read the file.', alertOptions);
      });
  };

  const checkIfKeyExist = (objectName, keyNames) => {
    return Object.keys(objectName)
      .map((key) => {
        if (!keyNames.includes(key)) {
          return key;
        }
      })
      .filter((element) => element !== undefined);
  };

  const checkIfValueExist = (objectName, keyNames) => {
    const valueArray = [];
    Object.entries(objectName).forEach(([key, value]) => {
      if (keyNames.includes(key)) {
        valueArray.push('$'.concat(value ? String(value) : '0.00'));
      }
    });
    return valueArray;
  };

  useEffect(() => {
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    if (isExport) {
      if (lists.data && lists.data.length > 0) {
        let headerList = [
          'S. No',
          'Date',
          "Day's Pending",
          'Beneficiary Contact',
          'Beneficiary Phone',
          'Beneficiary Email',
          'Petitioner',
          'Petitioner Contact',
          'Petitioner Phone',
          'Petitioner Email',
          'Visa',
        ];
        let bodyList = [];
        if (tab === 'payment') {
          headerList = headerList.concat(['Payment Total']);
          if (params.status === 'success') {
            headerList = headerList.concat(['Payment Method']);
            headerList = headerList.concat(['Tranaction ID']);
            headerList = headerList.concat(['Transaction Amount']);
            headerList = headerList.concat(['Total Amount']);
          }
          bodyList = lists.data.map((report: any, index: number) => [
            index + 1,
            report.updated_at
              ? moment(report.updated_at).format('MM/DD/YYYY')
              : '-',
            report.dueDays ? report.dueDays : '-',
            report.beneficiaryName ? report.beneficiaryName : '-',
            report.beneficiaryPhone ? report.beneficiaryPhone : '-',
            report.beneficiaryEmail ? report.beneficiaryEmail : '-',
            report.petitionerName ? report.petitionerName : '-',
            report.petitionerContactPersonName
              ? report.petitionerContactPersonName
              : '-',
            report.petitionerContactPersonMobile
              ? report.petitionerContactPersonMobile
              : '-',
            report.petitionerContactPersonEmail
              ? report.petitionerContactPersonEmail
              : '-',
            report.visaType ? report.visaType : '-',
            report.payment_total
              ? '$'.concat(parseFloat(report.payment_total).toFixed(2))
              : '$0.00',
            params.status === 'success'
              ? report.payment_done_through
                ? report.payment_done_through
                : '-'
              : null,
            params.status === 'success'
              ? report.transaction_id
                ? report.transaction_id
                : '-'
              : null,
            params.status === 'success'
              ? report.transaction_amount
                ? '$'.concat(parseFloat(report.transaction_amount).toFixed(2))
                : '$0.00'
              : null,
            params.status === 'success'
              ? report.total_amount
                ? '$'.concat(parseFloat(report.total_amount).toFixed(2))
                : '$0.00'
              : null,
          ]);
        } else {
          const keyHead = checkIfKeyExist(lists.data[0], [
            'id',
            'updated_at',
            'dueDays',
            'contractTotalAmount',
            'transaction_amount',
            'totalAmountToPay',
            'paidAmount',
            'pendingAmountToPay',
            'payerName',
            'beneficiaryName',
            'beneficiaryEmail',
            'beneficiaryPhone',
            'petitionerName',
            'petitionerContactPersonName',
            'petitionerContactPersonMobile',
            'petitionerContactPersonEmail',
            'visaType',
          ]);
          headerList = headerList.concat(
            [
              'Contract Total Amount',
              'Transaction Amount',
              'Total Amount ToPay',
              'Paid Amount',
              'Pending Amount ToPay',
            ],
            keyHead,
          );
          bodyList = lists.data.map((report: any, index: number) => {
            return [
              index + 1,
              report.updated_at
                ? moment(report.updated_at).format('MM/DD/YYYY')
                : '-',
              report.dueDays ? report.dueDays : '-',
              report.beneficiaryName ? report.beneficiaryName : '-',
              report.beneficiaryPhone ? report.beneficiaryPhone : '-',
              report.beneficiaryEmail ? report.beneficiaryEmail : '-',
              report.petitionerName ? report.petitionerName : '-',
              report.petitionerContactPersonName
                ? report.petitionerContactPersonName
                : '-',
              report.petitionerContactPersonMobile
                ? report.petitionerContactPersonMobile
                : '-',
              report.petitionerContactPersonEmail
                ? report.petitionerContactPersonEmail
                : '-',
              report.visaType ? report.visaType : '-',
              report.contractTotalAmount
                ? '$'.concat(parseFloat(report.contractTotalAmount).toFixed(2))
                : '$0.00',
              report.transaction_amount
                ? '$'.concat(parseFloat(report.transaction_amount).toFixed(2))
                : '$0.00',
              report.totalAmountToPay
                ? '$'.concat(parseFloat(report.totalAmountToPay).toFixed(2))
                : '$0.00',
              report.paidAmount
                ? '$'.concat(parseFloat(report.paidAmount).toFixed(2))
                : '$0.00',
              report.pendingAmountToPay
                ? '$'.concat(parseFloat(report.pendingAmountToPay).toFixed(2))
                : '$0.00',
            ].concat(checkIfValueExist(report, keyHead));
          });
        }

        downloadExcel({
          fileName: `${String(capitalize(tab))} Reports`,
          sheet: `${String(capitalize(tab))} Reports`,
          tablePayload: {
            body: bodyList,
            header: headerList,
          },
        });
        setIsExport(false);
      } else {
        toast.error('No Reports Found.', alertOptions);
        setIsExport(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsExport, isExport]);

  return (
    <TableContainer
      sx={{
        borderRadius: '10px 10px 0px 0px',
      }}>
      <Typography
        component={'div'}
        className={classes.scrollbox}
        id="scrollParent">
        <InfiniteScroll
          dataLength={
            lists && lists.data && lists.data.length ? lists.data.length : 0
          }
          style={{
            overflow: 'unset',
          }}
          next={getMoreList && getMoreList}
          hasMore={HasMoreResults}
          scrollableTarget={'scrollParent'}
          loader={
            getMoreLoader && (
              <div
                style={{
                  backgroundColor: '#ffffff',
                  textAlign: 'center',
                  width: '100%',
                }}>
                <CircularProgress
                  size={30}
                  sx={{ color: '#002c69', marginBottom: 10, marginTop: 10 }}
                />
              </div>
            )
          }
          endMessage>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="new-report-table table-hover"
            style={{ minHeight: '106px', minWidth: '2000px' }}
            ref={tableRef}>
            <TableHead>
              <TableRow>
                {!isOrg && <TableCell>Petitioner</TableCell>}
                <TableCell>Beneficiary</TableCell>
                <TableCell className="W-2">Visa</TableCell>
                {tab === 'payment' && (
                  <>
                    {params.status === 'success' && (
                      <TableCell>Transaction ID</TableCell>
                    )}
                    {params.status === 'success' && (
                      <TableCell
                        onClick={() =>
                          handleSortRequest('payment_done_through')
                        }>
                        <TableSortLabel
                          active={true}
                          direction={orderDirection.payment_done_through}>
                          Payment Method
                        </TableSortLabel>
                      </TableCell>
                    )}
                    <TableCell
                      onClick={() => handleSortRequest('payment_total')}>
                      <TableSortLabel
                        active={true}
                        direction={orderDirection.payment_total}>
                        Payment Amount
                      </TableSortLabel>
                    </TableCell>
                    {params.status === 'success' && (
                      <TableCell>Transaction Amount</TableCell>
                    )}
                    {params.status === 'success' && (
                      <TableCell
                        onClick={() => handleSortRequest('total_amount')}>
                        <TableSortLabel
                          active={true}
                          direction={orderDirection.total_amount}>
                          Total Amount with transaction amount
                        </TableSortLabel>
                      </TableCell>
                    )}
                    {params.status === 'success' && (
                      <TableCell>Status</TableCell>
                    )}
                    {params.status === 'success' && (
                      <TableCell
                        onClick={() => handleSortRequest('updated_at')}>
                        <TableSortLabel
                          active={true}
                          direction={orderDirection.updated_at}>
                          Paid Date
                        </TableSortLabel>
                      </TableCell>
                    )}
                    {params.status === 'pending' && (
                      <TableCell>Initiated Date</TableCell>
                    )}
                  </>
                )}
                {tab === 'contract' && (
                  <>
                    <TableCell>Contract Total Amount</TableCell>
                    <TableCell>Transaction Amount</TableCell>
                    <TableCell>Total Amount To Pay</TableCell>
                    <TableCell>Paid Amount</TableCell>
                    <TableCell>Pending Amount To Pay</TableCell>
                    <TableCell>Legal Fees</TableCell>
                    <TableCell>Filing Fee Anti-fraud</TableCell>
                    <TableCell>Admin Fees</TableCell>
                    <TableCell>
                      Attorneys Fees For H1B Non-immigrant Visa
                    </TableCell>
                    <TableCell>Filing Fees</TableCell>
                    <TableCell>Processing Fees</TableCell>
                  </>
                )}
                {tab === 'payment' &&
                  userDetail?.userRoleName == 'attorney' && (
                    <TableCell>View</TableCell>
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(lists?.data) && lists.data.length > 0 ? (
                lists.data.map((data, index) => (
                  <TableRow key={index}>
                    {!isOrg && <TableCell>{data?.petitionerName}</TableCell>}
                    <TableCell>{data?.beneficiaryName}</TableCell>
                    <TableCell>{data?.visaType}</TableCell>
                    {tab === 'payment' && (
                      <>
                        {params.status === 'success' && (
                          <TableCell>
                            <Typography
                              className="blue-text pointer"
                              onClick={() =>
                                !loader ? fileDownload(data) : false
                              }>
                              {data?.transaction_id}
                            </Typography>
                          </TableCell>
                        )}

                        {params.status === 'success' && (
                          <TableCell>
                            {data?.payment_done_through === 'ach'
                              ? 'ACH'
                              : data?.payment_done_through === 'card'
                              ? 'Card'
                              : '-'}
                          </TableCell>
                        )}
                        <TableCell>
                          ${parseFloat(data.payment_total).toFixed(2)}
                        </TableCell>
                        {params.status === 'success' && (
                          <TableCell>
                            {data?.transaction_amount
                              ? `$${parseFloat(
                                  data?.transaction_amount,
                                ).toFixed(2)}`
                              : '$0.00'}
                          </TableCell>
                        )}
                        {params.status === 'success' && (
                          <TableCell>
                            {data?.total_amount
                              ? `$${parseFloat(data?.total_amount).toFixed(2)}`
                              : '$0.00'}
                          </TableCell>
                        )}
                        {params.status === 'success' && (
                          <TableCell>
                            {data?.response_message === 'APPROVAL'
                              ? 'Success'
                              : data?.response_message}
                          </TableCell>
                        )}
                        {params.status === 'success' && (
                          <TableCell>
                            {data.updated_at
                              ? moment(data.updated_at).format('DD,MMM,YYYY')
                              : '-'}
                          </TableCell>
                        )}
                        {params.status === 'pending' && (
                          <TableCell>
                            {data.updated_at
                              ? moment(data.created_at).format('DD MMM, YYYY')
                              : '-'}
                          </TableCell>
                        )}
                      </>
                    )}
                    {tab === 'contract' && (
                      <>
                        <TableCell>
                          {data?.contractTotalAmount
                            ? `$${parseFloat(data?.contractTotalAmount).toFixed(
                                2,
                              )}`
                            : '$0.00'}
                        </TableCell>
                        <TableCell>
                          {data?.transaction_amount
                            ? `$${parseFloat(data?.transaction_amount).toFixed(
                                2,
                              )}`
                            : '$0.00'}
                        </TableCell>
                        <TableCell>
                          {data?.totalAmountToPay
                            ? `$${parseFloat(data?.totalAmountToPay).toFixed(
                                2,
                              )}`
                            : '$0.00'}
                        </TableCell>
                        <TableCell>
                          {data?.paidAmount
                            ? `$${parseFloat(data?.paidAmount).toFixed(2)}`
                            : '$0.00'}
                        </TableCell>
                        <TableCell>
                          {data.pendingAmountToPay
                            ? `$${parseFloat(data.pendingAmountToPay).toFixed(
                                2,
                              )}`
                            : '$0.00'}
                        </TableCell>
                        <TableCell>
                          {data['Legal Fees']
                            ? `$${parseFloat(data['Legal Fees']).toFixed(2)}`
                            : '$0.00'}
                        </TableCell>
                        <TableCell>
                          {data['Filing Fee Anti-Fraud']
                            ? `$${parseFloat(
                                data['Filing Fee Anti-Fraud'],
                              ).toFixed(2)}`
                            : '$0.00'}
                        </TableCell>
                        <TableCell>
                          {data['Admin fees']
                            ? `$${parseFloat(data['Admin fees']).toFixed(2)}`
                            : '$0.00'}
                        </TableCell>
                        <TableCell>
                          {data['ATTORNEYS FEES FOR H1B NON-IMMIGRANT VISA *']
                            ? `$${parseFloat(
                                data[
                                  'ATTORNEYS FEES FOR H1B NON-IMMIGRANT VISA *'
                                ],
                              ).toFixed(2)}`
                            : '$0.00'}
                        </TableCell>
                        <TableCell>
                          {data['Filling Fees']
                            ? `$${parseFloat(data['Filling Fees']).toFixed(2)}`
                            : '$0.00'}
                        </TableCell>
                        <TableCell>
                          {data['processing fee']
                            ? `$${parseFloat(data['processing fee']).toFixed(
                                2,
                              )}`
                            : '$0.00'}
                        </TableCell>
                      </>
                    )}
                    {(tab === 'payment' && userDetail?.userRoleName) ==
                      'attorney' && (
                      <TableCell>
                        <NavLink
                          to={`${
                            AttorneyPrivateRoutes.LEADS.CONTRACTPAYMENT
                          }/${String(data.leadId)}`}>
                          <Typography className="blue-text d-flex-1">
                            View
                          </Typography>
                        </NavLink>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={tab === 'payment' ? 16 : 21}>
                    <Typography className="d-flex d-flex-sb w-100">
                      <Typography
                        component={'div'}
                        sx={{ paddingLeft: '.5rem' }}
                        className="d-flex-1">
                        <Typography
                          className="ctitle"
                          sx={{
                            opacity: '0.9',
                            textAlign: 'center',
                          }}>
                          No Record(s) Found.
                        </Typography>
                      </Typography>
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </Typography>
    </TableContainer>
  );
};

export default ReportTable;
