import { Grid } from '@mui/material';
import { instantState } from 'hooks';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';

import DocumentParsingList from './DocumentParsingList';
import DocumentParsingListTitle from './DocumentParsingListTitle';

export const DocumentParsing: React.FC<any> = () => {
  const [loader, setLoader] = useState(true);
  const [listData, setListData] = useState<any>({});
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [params, setParams] = useState<any>({
    id: '',
    order: 'DESC',
    read: false,
    skip: 0,
    status: 'success',
    take: 50,
    userId: '',
  });

  useEffect(() => {
    getAllListData(
      COMMON_ENDPOINT.DocumentParseList(
        params.id,
        params.status,
        params.order,
        params.read,
        params.skip,
        params.take,
        params.userId,
      ),
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setListData((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          const mergeData = !_.isEmpty(listData)
            ? [...listData.data, ...resp.data]
            : resp.data;
          setListData({
            count: resp.total,
            data: mergeData,
          });
        }
        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setListData);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const getMoreList = () => {
    setGetMoreLoader(true);
    setParams((pre) => ({
      ...pre,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      skip: pre.skip + 50,
    }));
  };

  const handleChangeShowSubmitedDocument = (event) => {
    const { checked } = event.target;
    setListData({
      count: 0,
      data: [],
    });
    setParams((prev) => ({
      ...prev,
      read: checked,
      skip: 0,
      take: 50,
    }));
  };

  const handleChangeSucess = (event) => {
    const { checked } = event.target;
    setListData({
      count: 0,
      data: [],
    });
    setParams((prev) => ({
      ...prev,
      read: checked ? prev.read : false,
      skip: 0,
      status: checked ? 'success' : 'pending',
      take: 50,
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} className="pr-2">
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <Grid container spacing={2}>
            <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
              <DocumentParsingListTitle
                params={params}
                handleChangeShowSubmitedDocument={
                  handleChangeShowSubmitedDocument
                }
                handleChangeSucess={handleChangeSucess}
              />
              <DocumentParsingList
                setParams={setParams}
                lists={listData}
                params={params}
                hasMoreResults={hasMoreResults}
                getMoreLoader={getMoreLoader}
                getMoreList={getMoreList}
                setListData={setListData}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DocumentParsing;
