import { Box, Button, Modal, Typography } from '@mui/material';
import { AttorneyPrivateRoutes } from 'config';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type InfoMessageProps = {
  open?: boolean;
  close?: any;
  infoMessage?: string;
  showManageUser?: boolean;
};
const InfoMessage: React.FC<any> = (props: InfoMessageProps) => {
  const { open, close, infoMessage, showManageUser } = props;
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      // onClose={!open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className="custom-modal pd">
        <Typography
          component={'div'}
          className="f-20 s-title info-message-text">
          {infoMessage}
        </Typography>

        <Typography component={'div'} className="d-flex-jae modal-footer">
          <Button className="mcancel-btn f-18 font-bold mr-1" onClick={close}>
            Okay
          </Button>
          {showManageUser && (
            <Button
              className="madd-btn f-18 font-bold"
              onClick={() => {
                close();
                navigate(`${AttorneyPrivateRoutes.MANAGEUSER.MANAGEUSERLIST}`);
              }}>
              Manage User
            </Button>
          )}
        </Typography>
      </Box>
    </Modal>
  );
};

export default InfoMessage;
