// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Typography } from '@mui/material';
import Logo from 'assets/images/liberty-logoheader.png';
import Success from 'assets/images/payment-check.png';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SubscriptionSuccess: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('attorneySubscription');
  }, []);

  return (
    <div>
      <Typography
        className=" black-white-text p-f pt-2"
        sx={{
          backgroundColor: '#000',
          height: '84px',
          position: 'sticky',
          top: '0',
        }}>
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <div className="innerBox payment-public">
        <img
          src={Success}
          alt="Success"
          style={{ marginBottom: '30px', marginTop: '60px' }}
        />
        <Typography className="suc-title">Payment Successful</Typography>
        <Typography className="suc-dis">
          Your payment was successful. We have received your request and will
          send an email confirmation as soon as we process your details. Please
          check your email for further instructions on accessing your{' '}
          <a
            href="https://visas.ai"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'rgba(25, 82, 167, 1)' }}>
            visas.ai
          </a>
          &nbsp; account.
        </Typography>{' '}
        <Typography className="suc-dis">
          If you would like to request for any Support in the meantime, please
          contact us at{' '}
          <a href={`mailto:${String(process.env.REACT_APP_CONTACT_MAIL)}`}>
            <span style={{ color: 'rgba(25, 82, 167, 1)' }}>
              {String(process.env.REACT_APP_CONTACT_MAIL)}
            </span>
          </a>
        </Typography>
        <Button className="success-btn-new" onClick={() => navigate('/login')}>
          Go to Login
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;
