import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AttorneyPrivateRoutes } from 'config';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';

export const LeadTOPetition = () => {
  const { editId } = useParams();

  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [petiondata, setPetitondata] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setParams] = useState({
    firmID: userDetail.companyId,
    skip: 0,
    take: 50,
  });
  const [searchParams] = useSearchParams();
  const getData = () => {
    getAllListData(
      `${COMMON_ENDPOINT.Petitions}?skip=${String(params.skip)}&take=${String(
        params.take,
      )}&firmId=${String(params.firmID)}&leadId=${String(editId)}`,
    )
      .then((resp: any) => {
        setPetitondata(resp.data);
      })
      .catch((e: any) => {});
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  return (
    <Grid container>
      <Grid>
        <Typography component={'div'} className="d-flex-a d-flex-sb mt-1">
          <Typography className="black-white-text font-bold f-30">
            Petitions
          </Typography>
        </Typography>
      </Grid>
      <Grid md={10} sm={12} className="pl-0 pt-2 mt-2">
        <TableContainer
          sx={{
            backgroundColor: 'unset !important',
            borderRadius: '10px 10px 0px 0px',
          }}>
          <Typography component={'div'}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className="lead-table table-hover"
              style={{ minHeight: '106px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Organization</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell>Date Starteds</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {petiondata && petiondata.length ? (
                  petiondata.map((data, index) => (
                    <TableRow
                      key={index}
                      onClick={() => {
                        navigate(
                          `${
                            AttorneyPrivateRoutes.PETITIONS.PETITIONLIST
                          }/${String(data.id)}${
                            searchParams.get('orgId')
                              ? '?orgId=' + searchParams.get('orgId')
                              : ''
                          }${
                            searchParams.get('invited')
                              ? '&invited=true&isPetition=true'
                              : searchParams.get('associated')
                              ? '&associated=true&isPetition=true'
                              : ''
                          }`,
                        );
                      }}
                      className="pointer"
                      style={{
                        overflowWrap: 'break-word',
                      }}>
                      <TableCell>
                        <div className="hideshow">
                          {data.visa_type} for {data.benificiery_name}{' '}
                          {moment(data.created_at).format('MM/DD/YYYY')}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className="hideshow"
                          style={{
                            wordBreak: 'break-all',
                          }}>
                          {' '}
                          {data.organization ? data.organization : '-'}
                        </div>
                      </TableCell>

                      <TableCell>
                        <div
                          className="hideshow"
                          style={{
                            wordBreak: 'break-all',
                          }}>
                          {data.notes ? data.notes : '-'}
                        </div>
                      </TableCell>

                      <TableCell>
                        {data.created_at
                          ? moment(data.created_at).format('yyyy-MM-DD hh:mm a')
                          : '-'}
                      </TableCell>

                      <TableCell>{data.status ? data.status : '-'}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <tr className={'text-center'}>
                    <td colSpan={10}>No Record(s) Found.</td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </Typography>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
export default LeadTOPetition;
