import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import moment from 'moment';
import React, { ReactElement } from 'react';

type FormInputTextProps = {
  errors?: string;
  defaultValue?: string;
  value?: string;
  id?: string;
  tabIndex?: number;
  onChangeDate?: (src: string) => void;
  onFocus?: (src: any) => void;
  onBlur?: (src: any) => void;
  onBlurText?: () => void;
  isSecureTextEntry?: boolean;
  isEditable?: boolean;
  name: string;
  className?: string;
  label?: string;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  initIcon?: any;
  minDate?: Date;
  maxDate?: Date;
  disableFuture?: boolean;
  placeHolder?: string;
  isCompleteOnchange?: boolean;
  isInputRef?: boolean;
  isReadOnlyInput?: boolean;
  isUpdatefeildpararms?: boolean;
  PopperProps?: any;
};

const CustomDatePicker = React.forwardRef(
  (props: FormInputTextProps, ref: any): ReactElement => {
    const {
      errors,
      value,
      id = '',
      // isInputRef = false,
      onChangeDate,
      onFocus,
      onBlur,
      isEditable = false,
      minDate,
      maxDate,
      label,
      tabIndex,
      className,
      placeHolder,
      // resticted,
      isCompleteOnchange,
      disableFuture = false,
      isReadOnlyInput = false,
      isUpdatefeildpararms = false,
      PopperProps,
    } = props;
    const classes = useStyles();
    const [inputVal, setInputVal] = React.useState<Dayjs | null>(dayjs(value));

    const onValueChange = React.useCallback(
      (val: any) => {
        if (!isCompleteOnchange) {
          setInputVal(val);
          onChangeDate && onChangeDate(val);
        }
      },
      [isCompleteOnchange, onChangeDate],
    );

    // React.useEffect(() => {
    //   if (isRefreshValue && inputVal !== value) {
    //     setInputVal(value);
    //   }
    // }, [inputVal, value, isRefreshValue, inputRefObj]);

    // const onKeyDown: any = (e) => {
    //   e.preventDefault();
    // };
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          ref={ref}
          // openTo="day"
          views={['year', 'month', 'day']}
          label={label}
          value={
            moment(value).isValid()
              ? value
              : isUpdatefeildpararms
              ? null
              : inputVal
          }
          className={classes.dateRange}
          PopperProps={disableFuture ? PopperProps : ''}
          disabled={isEditable}
          renderInput={(params) => (
            <TextField
              {...params}
              // {...(isInputRef ? { inputRef: ref } : {})}
              // inputRef={isInputRef ? ref : null}
              id={id}
              // onKeyDown={resticted ? onKeyDown : null}
              error={!_.isEmpty(errors) ? true : false}
              helperText={!_.isEmpty(errors) ? errors : null}
              className={className}
              placeholder={placeHolder}
              tabIndex={tabIndex}
              onFocus={onFocus && onFocus}
              onBlur={onBlur && onBlur}
              inputProps={{
                ...params.inputProps,
                readOnly: isReadOnlyInput,
              }}
            />
          )}
          disableFuture={disableFuture}
          disableOpenPicker={isEditable}
          {...(maxDate ? { maxDate: moment(maxDate) } : {})}
          {...(minDate ? { minDate: moment(minDate) } : {})}
          onChange={(date: any) => {
            onValueChange(date);
          }}
          onAccept={(date: any) => {
            if (isCompleteOnchange) {
              onChangeDate && onChangeDate(date);
            }
          }}
        />
      </LocalizationProvider>
    );
  },
);

const useStyles = makeStyles((theme: any) => ({
  dateRange: {
    '& .MuiPaper-root': {
      backgroundColor: '#fff !important',
    },
    '& .MuiPickersDay-root': {
      backgroundColor: '#fff !important',
    },
    '& .MuiTextField-root': {
      height: 10,
    },
  },

  helperText: {
    color: `${String(theme.palette?.warning?.main)} !important`,
    marginLeft: 'unset !important',
    position: 'relative',
  },
}));

export default CustomDatePicker;
