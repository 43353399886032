/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AttorneyPrivateRoutes } from 'config';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addNewData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT, Fill_FORM_ENDPOINT } from 'utils/Constant';
import { isJSON, jsonParseValue } from 'utils/helper';

import useStyles from '../../../../PrivateStyle';

export const AcceptOrReject: React.FC<any> = ({ onlyShow }) => {
  const classes = useStyles();
  const { petitionId, templateId, buildId } = useParams();
  const location: any = useLocation();
  const navigate: any = useNavigate();
  const [loader, setLoader] = useState(true);
  const [questions, setQuestions] = useState<any>([]);
  const [answeredList, setAnsweredList] = useState<any>({
    draftAnswers: [],
    id: null,
  });

  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);

  const acceptExists = (type) =>
    answeredList.draftAnswers.some((el) => el.type === type);

  const draftAnsweredCall = () => {
    addNewData(answeredList, `${COMMON_ENDPOINT.DraftDocument}/answered`)
      .then((Resp: any) => {
        navigate(
          `${String(AttorneyPrivateRoutes.PETITIONS.BUILDCASEEDIT)}/${String(
            petitionId,
          )}/${String(templateId)}`,
          {
            state: {
              data: {
                fileFormat: location.state.data?.fileFormat,
                pdfId: templateId,
                templateFilePath: location.state.data?.templateFilePath,
                templateName: location.state.data?.templateName,
              },
            },
          },
        );
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const buildNow = () => {
    getAllListData(
      `${String(COMMON_ENDPOINT.DocumentBuild)}/${String(petitionId)}/${String(
        petitions.petitionData?.beneficiary_id,
      )}/${String(templateId)}?leadId=${String(
        petitions.petitionData?.lead_id,
      )}`,
    )
      .then((res: any) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitAcceptReject = () => {
    if (!_.isEmpty(answeredList.draftAnswers)) {
      setLoader(true);
      const listArr = [];
      questions.map((question) => {
        const type = answeredList.draftAnswers.find(
          (item) => item.id === question.id,
        )?.type;
        if (type === 'accept') {
          listArr.push({
            answer: Array.isArray(question.answer)
              ? JSON.stringify(question.answer)
              : question.answer,
            formId: 19,
            questionId: Number(question.question_id),
            sectionId: 4,
          });
        }
      });

      const payload = {
        benificieryId: petitions.petitionData?.beneficiary_id,
        dependentUserId: null,
        formType: 'configureForms',
        leadFor: null,
        leadId: petitions.petitionData?.lead_id,
        list: listArr,
        petitionId: petitionId,
        strategyId: null,
      };

      if (acceptExists('accept')) {
        addNewData(payload, Fill_FORM_ENDPOINT)
          .then((data) => {
            buildNow();
            draftAnsweredCall();
          })
          .catch((err) => {
            setLoader(false);
          });
      } else {
        draftAnsweredCall();
      }
    }
  };

  useEffect(() => {
    if (!_.isUndefined(petitionId) && !_.isEmpty(petitions)) {
      getAllListData(
        `${String(COMMON_ENDPOINT.DraftDocument)}/${String(
          petitionId,
        )}/${String(templateId)}/${String(buildId)}/${String(
          petitions.petitionData.beneficiary_id,
        )}`,
      )
        .then((response: any) => {
          if (response && response.data) {
            setQuestions(response.data);
            const questionAccepted = [];
            let latest_doc_id = null;
            response.data.map((que) => {
              questionAccepted.push({ id: que.id, type: 'accept' });
              latest_doc_id = que.latest_doc_id;
            });
            setAnsweredList({
              draftAnswers: questionAccepted,
              id: latest_doc_id,
            });
          }

          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitions]);

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Grid container>
      {!_.isEmpty(petitions) && petitions?.petitionData ? (
        <Typography
          component={'div'}
          className="black-white-text mb-2 mt-2"
          sx={{
            fontFamily: 'Arial-Bold !important',
            fontSize: '1.25em !important',
            marginLeft: '15px',
          }}>
          {location.state.data?.templateName}
          {' review for '}
          {location.state.data?.updatedBy === 'Petitioner'
            ? petitions?.petitionData.petitioner_user_name
            : petitions?.petitionData.benificiery_name}
        </Typography>
      ) : (
        ''
      )}
      <Grid item md={10} sm={12} sx={{ paddingRight: '2rem' }}>
        <TableContainer
          sx={{
            backgroundColor: 'unset !important',
            borderRadius: '10px 10px 0px 0px',
          }}>
          <Typography component={'div'} className={classes.scrollbox}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className="lead-table table-hover"
              style={{ minHeight: '106px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Question Name</TableCell>
                  <TableCell>Attorney Answer</TableCell>
                  <TableCell>{location.state.data?.updatedBy} Answer</TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center !important',
                    }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(questions) && questions.length > 0 ? (
                  questions.map((list, index) => {
                    return (
                      <TableRow
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {}}>
                        <TableCell>
                          <div className="hideshow">{list.question}</div>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: '#174baf !important',
                          }}>
                          <div className="hideshow">
                            {isJSON(list?.currentAnswer)
                              ? jsonParseValue(list?.currentAnswer)
                              : list?.currentAnswer || '-'}
                          </div>
                        </TableCell>
                        <TableCell sx={{ color: 'red !important' }}>
                          <div className="hideshow">
                            {isJSON(list?.answer)
                              ? jsonParseValue(list?.answer)
                              : list?.answer || '-'}
                          </div>
                        </TableCell>
                        <TableCell>
                          <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            value={
                              answeredList.draftAnswers.length > 0
                                ? answeredList.draftAnswers.find(
                                    (item) => item.id === list.id,
                                  )?.type
                                : ''
                            }
                            onChange={(e) => {
                              const findAnswerIndex =
                                answeredList.draftAnswers.findIndex(
                                  (item) => item.id === list.id,
                                );
                              if (findAnswerIndex !== -1) {
                                const answeredListArray =
                                  answeredList.draftAnswers;
                                answeredListArray[findAnswerIndex].type =
                                  e.target.value;
                                setAnsweredList((pre) => ({
                                  ...pre,
                                  draftAnswers: [...answeredListArray],
                                }));
                              } else {
                                setAnsweredList((pre) => [
                                  ...pre,
                                  {
                                    id: list.id,
                                    type: e.target.value,
                                  },
                                ]);
                              }
                            }}>
                            <Typography
                              component={'div'}
                              className="d-flex-a d-flex-sb">
                              <FormControlLabel
                                value="accept"
                                control={<Radio />}
                                label="Accept"
                              />
                              <FormControlLabel
                                value="reject"
                                control={<Radio />}
                                label="Reject"
                              />
                            </Typography>
                          </RadioGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <tr className={'text-center'}>
                    <td colSpan={5} align={'center'}>
                      No Record(s) Found.
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </Typography>
          {questions && questions.length > 0 ? (
            <Grid
              item
              md={12}
              sm={12}
              className="sticky-tab"
              sx={{ padding: '8px 20px' }}>
              <Typography component={'div'} className="d-flex-jae mt-1">
                <Button
                  className="n-cancel-btn mr-1"
                  sx={{
                    backgroundColor: '#bcc7dc !important',
                    borderRadius: '5px !important',
                    fontFamily: 'Arial-Regular !important',
                    fontSize: '0.9em !important',
                    height: '35px !important',
                    width: '149px !important',
                  }}
                  onClick={() => {
                    navigate(
                      `${String(
                        AttorneyPrivateRoutes.PETITIONS.BUILDCASE,
                      )}/${String(petitionId)}`,
                    );
                  }}>
                  Cancel
                </Button>

                <Button
                  className="n-add-btn mr-1"
                  sx={{
                    borderRadius: '5px !important',
                    fontFamily: 'Arial-Regular !important',
                    fontSize: '0.9em !important',
                    height: '35px !important',
                    width: '149px !important',
                  }}
                  onClick={handleSubmitAcceptReject}>
                  Submit
                </Button>
              </Typography>
            </Grid>
          ) : null}
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default AcceptOrReject;
