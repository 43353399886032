import CloseIcon from '@mui/icons-material/Close';
// import InfoIcon from '@mui/icons-material/Info';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  // Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from '@mui/material/Popper';
import Stratey from 'assets/images/stratey.svg';
// import Groupm from 'assets/images/Groupm.svg';
// import ICD from 'assets/images/ICD.png';
// import Layer from 'assets/images/Layer21.png';
// import Or from 'assets/images/or.png';
// import PD from 'assets/images/PD.png';
import Win from 'assets/images/winning.png';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
import InfinityScroll from 'components/InfinityScroll';
import { AffilatePrivateRoutes, AttorneyPrivateRoutes } from 'config';
import saveAs from 'file-saver';
import { instantState, useDebounce } from 'hooks';
// import parse from 'html-react-parser';
import _ from 'lodash';
import moment from 'moment';
import HistoryComments from 'pages/private/Client/Common/HistoryComments';
import { Translate } from 'pages/private/Common/Translate/Translate';
import { WizardForm } from 'pages/private/Common/wizardForm';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData, getS3file } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions, phoneNumberFormat } from 'utils/helper';

import { BuildCasePDFEdit } from './PDF';

declare const mammoth: any;

export const BuildCaseEdit: React.FC<any> = ({ onlyShow }) => {
  const { petitionId, depUserId, templateId } = useParams();
  const navigate: any = useNavigate();
  const location: any = useLocation();
  const [searchParams] = useSearchParams();
  const wizardRef: any = useRef();
  const [loader, setLoader] = useState(true);
  const [htmlState, setHtmlState] = useState('');
  const [linkCodes, setLinkCodes] = useState<any>([]);
  const [pdfDocument, setPdfDocument] = useState('');
  const [treeList, setTreeList] = useState([]);
  const [contactPersonSelected, setContactPersonSelected] = useState(false);
  const [signatureInDocument, setSignatureInDocument] = useState(false);

  const [confirmBuildOpen, setConfirmBuildOpen] = useState<any>({
    key: '',
    show: false,
  });

  const [showStrategyListOpen, setShowStrategyListOpen] = useState({
    buildSelect: false,
    includeSignature: false,
    show: false,
    strategySelect: false,
  });

  const [selectedStrategy, setSelectedStrategy] = React.useState({
    formId: null,
    id: null,
    isPetitioner: false,
    value: '',
  });

  const [hcData, setHCData] = useState<any>({
    comments: [],
    history: [],
    loading: true,
    selectedQuestion: {},
  });

  const [winningStrategies, setWinningStrategies] = useState<any>({
    data: [],
    total: 0,
  });
  const [winningParams, setWinningParams] = useState<any>({
    search: '',
    skip: 0,
    take: 10,
  });
  const [selectedIndex, setSelectedIndex] = useState<any>({
    index: null,
    show: false,
  });
  // const [answerError, setAnswerError] = React.useState(false);
  const [winningStrategyAnchor, setWinningStrategyAnchor] = useState<any>({
    anchorEl: null,
    open: false,
  });
  const [totalPageArr, setTotalPageArr] = React.useState([1]);
  const [currentPageNo, setCurrentPageNo] = React.useState(1);
  const [zoomProps, setZoomProps] = React.useState({
    type: '',
    zoom: 1,
  });
  const [updatedFieldValue, setupdatedFieldValue] = React.useState<
    Array<{ fieldName: string; value: string }>
  >([]);
  const [currentSelectQus, setCurrentSelectQus] = React.useState(null);
  const winningStrategyId = winningStrategyAnchor.open ? 'simple-popper' : null;

  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [params, setParams] = React.useState({
    skip: 0,
    take: 50,
  });
  const [hasMoreResults, setHasMoreResults] = React.useState(false);
  const [tableLoader, setTableLoader] = React.useState(false);
  const [getMoreLoader, setGetMoreLoader] = React.useState(false);
  const [strategyLoader, setStrategyLoader] = React.useState(true);
  const [strategyList, setStrategyList] = React.useState({
    data: [],
    total: 0,
  });
  const debouncedParams = useDebounce(params, 500);

  const getVisaSpecificDocById = useCallback((dependentUserId?) => {
    let url = `${COMMON_ENDPOINT.DocumentBuild}/${String(petitionId)}/${String(
      petitions.petitionData.visa_id,
    )}`;
    if (dependentUserId) {
      url =
        url + `?formType=dependent&dependentUserId=${String(dependentUserId)}`;
    }
    getAllListData(url).then((res: any) => {
      // setVisaSpecificDocument(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmClose = () => {
    setConfirmBuildOpen({
      key: '',
      show: false,
    });
    setShowStrategyListOpen((pre) => ({
      ...pre,
      buildSelect: true,
      includeSignature: false,
    }));
    buildDocumentForProduction();
  };

  const refs: any =
    linkCodes &&
    linkCodes.reduce((acc, value) => {
      acc[Number(value.id)] = React.createRef();
      return acc;
    }, {});

  const handleDocumentAnswerClick = useCallback(
    (ids: string, type: string) => {
      if (location.state?.data?.fileFormat === 'pdf') {
        return;
      }
      const id = Number(ids.split('_')[1]);
      if (Number(type) === 4) {
        const docEle = document.getElementById(ids);
        if (docEle) {
          const inputEle = document.getElementById(String(id));
          inputEle.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
          inputEle.focus();
          if (docEle.classList.contains('inactive')) {
            docEle.style.border = '2px solid red';
            docEle.style.background = 'bisque';
            docEle.style.borderRadius = '5px';
          } else {
            docEle.style.border = '2px solid #1964CD';
            docEle.style.background = 'bisque';
            docEle.style.borderRadius = '5px';
          }
          inputEle.style.border = '2px solid #1964CD';

          setTimeout(() => {
            inputEle.style.border = 'unset';
            docEle.style.border = 'unset';
          }, 1000);
        }
      }
      if (refs[id] && refs[id].current) {
        refs[id].current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
        [7, 15].includes(Number(type))
          ? refs[id].current.getElementsByTagName('input')[0].focus()
          : refs[id].current.focus();
      }
    },
    [location.state?.data?.fileFormat, refs],
  );

  const handleInputClick = useCallback(
    (id: any) => {
      if (location.state?.data?.fileFormat === 'pdf') {
        return;
      }
      const allHighlightedText: any =
        document.getElementsByClassName('parsed_highlight');
      for (let i = 0; i < allHighlightedText.length; i++) {
        if (Number(allHighlightedText[i]?.id.split('_')[1]) === Number(id)) {
          if (allHighlightedText[i].classList.contains('inactive')) {
            allHighlightedText[i].classList.add('inactive-selected');
          } else {
            allHighlightedText[i].classList.add('active-selected');
          }

          document.getElementById(allHighlightedText[i]?.id).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        } else {
          if (allHighlightedText[i].classList.contains('inactive-selected')) {
            allHighlightedText[i].classList.remove('inactive-selected');
          }
          if (allHighlightedText[i].classList.contains('active-selected')) {
            allHighlightedText[i].classList.remove('active-selected');
          }
        }
      }
    },
    [location.state?.data?.fileFormat],
  );

  const applyHighlightedContent = (checked, answer) => {
    const allHighlightedText: any =
      document.getElementsByClassName('parsed_highlight');
    for (let i = 0; i < allHighlightedText.length; i++) {
      if (
        allHighlightedText[i].attributes['question-name'].value ===
        checked.question
      ) {
        if (checked.response_type !== 13) {
          if (checked.response_type === 2) {
            allHighlightedText[i]['outerHTML'] = allHighlightedText[i][
              'outerHTML'
            ].replace(/span/g, 'div');
            allHighlightedText[i].style.whiteSpace = 'break-spaces';
            allHighlightedText[i].style.paddingLeft = '10px';
          }
          allHighlightedText[i]['innerText'] = answer
            ? answer
            : checked.question;
        } else {
          if (JSON.parse(answer).length === 1 && JSON.parse(answer)[0] === '') {
            allHighlightedText[i]['innerText'] = checked.question;
          } else {
            const Ids = allHighlightedText[i].attributes['id'].value;
            const ul = document.getElementById(String(Ids));
            ul.innerHTML = '';
            try {
              JSON.parse(answer).map((item) => {
                if (Number(Ids.split('_')[1]) === checked.id) {
                  const li = document.createElement('li');
                  li.appendChild(document.createTextNode(String(item)));
                  li.setAttribute(
                    'style',
                    'background-color: #FFF !important;',
                  );
                  ul.appendChild(li);
                  //   return;
                }
              });
            } catch (error) {
              console.log('error', error);
            }
          }
        }

        const copyAnswer = answer.slice();
        const hasWhiteSpace = copyAnswer.replace(/\s/g, '');
        if (
          allHighlightedText[i].classList.contains('inactive') &&
          hasWhiteSpace
        ) {
          allHighlightedText[i].classList.remove('inactive');
          allHighlightedText[i].classList.add('active');

          if (allHighlightedText[i].classList.contains('inactive-selected')) {
            allHighlightedText[i].classList.remove('inactive-selected');
          }
        }
        if (!hasWhiteSpace) {
          if (allHighlightedText[i].classList.contains('active-selected')) {
            allHighlightedText[i].classList.remove('active-selected');
          }
          if (allHighlightedText[i].classList.contains('active')) {
            allHighlightedText[i].classList.remove('active');
          }
          allHighlightedText[i].classList.add('inactive');
        }
      }
    }
  };

  const buildNow = () => {
    if (contactPersonSelected) {
      if (signatureInDocument) {
        setConfirmBuildOpen({ key: 'signature-document', show: true });
      } else {
        setShowStrategyListOpen((pre) => ({
          ...pre,
          buildSelect: true,
          includeSignature: false,
        }));
        buildDocumentForProduction();
      }
    } else {
      if (location.state?.data?.fileFormat === 'pdf') {
        buildDocumentForProduction();
      } else {
        setConfirmBuildOpen({ key: 'contact-person', show: true });
      }
    }
  };

  const buildDocumentForProduction = async () => {
    // setShowStrategyListOpen((pre) => ({
    //   ...pre,
    //   show:
    //     location.state?.data?.fileFormat === 'pdf'
    //       ? false
    //       : pre.strategySelect
    //       ? false
    //       : true,
    // }));
    setConfirmBuildOpen({ key: '', show: false });
    await buildDocumentForProductionWithStrategy();
  };

  const buildDocumentForProductionWithStrategy = async () => {
    const strategyListOpen = await instantState(setShowStrategyListOpen);

    const selectedStg = await instantState(setSelectedStrategy);
    if (!strategyListOpen.buildSelect && strategyListOpen.strategySelect) {
      getLinkDataForHTMLParsing();
    }
    if (
      strategyListOpen.buildSelect ||
      location.state?.data?.fileFormat === 'pdf'
    ) {
      setLoader(true);
      let url = `${String(COMMON_ENDPOINT.DocumentBuild)}/${String(
        petitionId,
      )}/${String(petitions.petitionData?.beneficiary_id)}/${String(
        templateId,
      )}?leadId=${String(petitions.petitionData?.lead_id)}&visaType=${String(
        petitions.petitionData?.visa_type,
      )}&beneficiaryName=${String(petitions?.petitionData?.benificiery_name)}`;
      if (strategyListOpen.includeSignature) {
        url = url + '&includeSignature=true';
      }
      if (onlyShow && onlyShow === 'dependent') {
        url = url + `&buildtype=dependent&dependentUserId=${String(depUserId)}`;
      }
      if (
        location.state?.data?.fileFormat !== 'pdf' &&
        !_.isNull(selectedStg.id) &&
        !_.isNull(selectedStg.form_id)
      ) {
        url =
          url +
          `&formId=${String(selectedStg.formId)}&isPetitioner=${String(
            selectedStg.isPetitioner,
          )}`;
      }

      getAllListData(url)
        .then((res: any) => {
          setShowStrategyListOpen({
            buildSelect: false,
            includeSignature: false,
            show: false,
            strategySelect: false,
          });
          setSelectedStrategy({
            formId: null,
            id: null,
            isPetitioner: false,
            value: '',
          });
          const dependentUserId =
            onlyShow && onlyShow === 'dependent' && !_.isUndefined(depUserId)
              ? depUserId
              : '';
          getVisaSpecificDocById(dependentUserId);

          if (res.documentType === 'pdf') {
            setLoader(false);
            const url =
              String(process.env.REACT_APP_DOCUMENT_URL) + String(res.data.id);
            window.open(url);
          } else {
            const obj = {
              key: res.key,
            };
            const fileName = res.key.split('/').pop();
            getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
              .then((data: any) => {
                setLoader(false);
                saveAs(data, fileName);
                getLinkDataForHTMLParsing();
                toast.success(
                  `${String(fileName)} File Downloaded`,
                  alertOptions,
                );
              })
              .catch((err) => {
                setLoader(false);
                toast.error(
                  err ? err : 'Oops!!! Something went wrong',
                  alertOptions,
                );
              });
          }
        })
        .catch((err) => {
          toast.error(err, alertOptions);
          setLoader(false);
        });
    }
  };

  const onBuildSuccess = (action) => {
    if (action === 'yes') {
      setShowStrategyListOpen((pre) => ({
        ...pre,
        buildSelect: pre.buildSelect ? false : true,
        includeSignature: true,
      }));
      buildDocumentForProduction();
    } else {
      setShowStrategyListOpen((pre) => ({
        ...pre,
        buildSelect: pre.buildSelect ? false : true,
        includeSignature: false,
      }));
      buildDocumentForProduction();
    }
  };

  const getLinkDataForHTMLParsing = async () => {
    const selectedStg = await instantState(setSelectedStrategy);
    try {
      let url = `${String(COMMON_ENDPOINT.DocumentBuild)}/${String(
        petitionId,
      )}/${String(petitions.petitionData?.beneficiary_id)}/${String(
        templateId,
      )}?leadId=${String(petitions.petitionData?.lead_id)}&jsonView=true`;

      await getAllListData(
        `/petitioner/transaction/strategysession/list/${String(
          petitions?.petitionData?.lead_id,
        )}?skip=&take=&userId=${String(
          petitions?.petitionData?.beneficiary_id,
        )}`,
      )
        .then(async (res) => {
          if (selectedStg.id && selectedStg.formId) {
            url =
              url +
              `&formId=${String(selectedStg.formId)}&isPetitioner=${String(
                selectedStg.isPetitioner,
              )}`;
          } else {
            if (res && Array.isArray(res) && res[0]) {
              setSelectedStrategy({
                formId: res[0].form_id,
                id: res[0].id,
                isPetitioner: res[0].for_petitioner,
                value: 'yes',
              });
              url =
                url +
                `&formId=${String(res[0].form_id)}&isPetitioner=${String(
                  res[0].for_petitioner,
                )}`;
            }
          }

          await getAllListData(url)
            .then((res: any) => {
              setShowStrategyListOpen((pre) => ({
                ...pre,
                buildSelect: false,
                show: false,
                // strategySelect: false,
              }));

              const linkCodesResp = res.data;
              const obj = {
                key: location.state?.data?.templateFilePath,
              };
              setContactPersonSelected(res.contactPersonSelected);
              setSignatureInDocument(res.signatureInDocument);

              setLoader(true);
              getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
                .then((data: any) => {
                  return data.arrayBuffer();
                })
                .then((ab) => {
                  mammoth
                    .convertToHtml({ arrayBuffer: ab })
                    .then(async (result) => {
                      let htmlContent = result.value;

                      const apiRes = await addNewData(
                        { htmlString: htmlContent },
                        `${String(COMMON_ENDPOINT.DocumentBuild)}/document-key`,
                      );
                      let replacedContentArr =
                        apiRes && Array.isArray(apiRes) ? apiRes : [];

                      replacedContentArr =
                        replacedContentArr &&
                        Array.isArray(replacedContentArr) &&
                        replacedContentArr.filter((field, ind) => {
                          if (field && field.startsWith('%')) {
                            htmlContent = htmlContent.replace(
                              field.replace(/ *<.*?> */g, '').trim(),
                              '',
                            );
                            return;
                          } else if (field && field.startsWith('#')) {
                            htmlContent = htmlContent.replace(
                              field.replace(/ *<.*?> */g, '').trim(),
                              '',
                            );
                            return;
                          } else if (field && field.startsWith('/')) {
                            htmlContent = htmlContent.replace(
                              field.replace(/ *<.*?> */g, '').trim(),
                              '',
                            );
                            return;
                          } else {
                            return field.replace(/ *<.*?> */g, '').trim();
                          }
                        });
                      const filterArr: Array<any> =
                        res.data && Array.isArray(res.data) && res.data.length
                          ? res.data
                              .filter((ch: any) => ch.link_code && !ch.disable)
                              .map((e: any) => ({
                                ...e,
                                previousAnswer: e.value,
                              }))
                          : [];
                      const finalModifiedArr =
                        replacedContentArr && Array.isArray(replacedContentArr)
                          ? replacedContentArr
                              .filter(
                                (re, index) =>
                                  replacedContentArr.indexOf(re) === index,
                              )
                              .reduce((previousArr, currentI) => {
                                const obj = filterArr.find(
                                  (o) =>
                                    String(o.docField).trim() ===
                                    String(currentI).trim(),
                                );
                                if (obj) {
                                  previousArr.push(obj);
                                }
                                return previousArr;
                              }, [])
                          : [];
                      setLinkCodes(finalModifiedArr);
                      const WizardArr = [
                        {
                          name: '',
                          order: 1,
                          questionDetails: finalModifiedArr,
                          question_id:
                            finalModifiedArr &&
                            Array.isArray(finalModifiedArr) &&
                            finalModifiedArr.length
                              ? finalModifiedArr
                                  .filter(
                                    (ch: any) => ch.link_code && !ch.disable,
                                  )
                                  .map((e: any) => e.id)
                              : [],
                          section_approve: false,
                          section_id: 1,
                          section_start_Count: 1,
                          visa_form_id: 8,
                        },
                      ];
                      setTreeList(WizardArr);
                      let final: string = htmlContent;
                      replacedContentArr &&
                        Array.isArray(replacedContentArr) &&
                        replacedContentArr.map((ch: string, index: number) => {
                          const code = linkCodesResp.find(
                            (e) =>
                              String(e.docField).toLowerCase().trim() ===
                              String(ch)
                                .replace(/ *<.*?> */g, '')
                                .toLowerCase()
                                .trim(),
                          );
                          if (!_.isEmpty(code) && !_.isUndefined(code)) {
                            if (
                              code.disable ||
                              (!code.disable && _.isNull(code.link_code))
                            ) {
                              const regex = RegExp(`&lt;${ch}&gt;`, 'g');
                              final = final.replaceAll(
                                regex,
                                `<span class="parsed_highlight_disabled" id="${String(
                                  index,
                                ).concat(
                                  `_${String(code.id)}`,
                                )}" question-name="${String(code.question)}">${
                                  code.value && !code.response_type
                                    ? _.isArray(code?.value)
                                      ? `<ul>${String(
                                          code?.value.map((item) => {
                                            return `<li>${String(
                                              // eslint-disable-next-line prettier/prettier
                                              item[code?.docField],
                                            )}</li>`;
                                          }),
                                        )}</ul>`
                                      : String(code?.value)
                                    : code?.value
                                    ? String(code?.value)
                                    : String(
                                        code?.question
                                          ? code?.question
                                          : code?.docField,
                                      )
                                }</span>`,
                              );
                              return;
                            } else {
                              if (code.value && !code.response_type) {
                                const regex = RegExp(`&lt;${ch}&gt;`, 'g');
                                final = final.replaceAll(
                                  regex,
                                  _.isArray(code?.value)
                                    ? `${String(
                                        code?.value.map((item) => {
                                          return `<li>${String(
                                            item[code?.docField],
                                          )}</li>`;
                                        }),
                                      ).replace(/,/g, '')}`
                                    : String(code?.value),
                                );
                                return;
                              } else {
                                const regex = RegExp(`&lt;${ch}&gt;`, 'g');
                                final = final.replaceAll(
                                  regex,
                                  code.value && code.response_type === 13
                                    ? `<ul class="parsed_highlight ${
                                        code.value ? 'active' : 'inactive'
                                      }" id="${String(index).concat(
                                        `_${String(code.id)}`,
                                      )}" question-name="${String(
                                        code.question,
                                      )}" response-type="${String(
                                        code.response_type,
                                      )}">${String(
                                        JSON.parse(code?.value).map(
                                          (item) =>
                                            `<li style="background-color: #FFF !important;">${String(
                                              item,
                                            )}</li>`,
                                        ),
                                      ).replace(/,/g, '')}</ul>`
                                    : code.value && code.response_type === 2
                                    ? `<div class="parsed_highlight ${
                                        code.value ? 'active' : 'inactive'
                                      }" id="${String(index).concat(
                                        `_${String(code.id)}`,
                                      )}" question-name="${String(
                                        code.question,
                                      )}" response-type="${String(
                                        code.response_type,
                                      )}" style="white-space: break-spaces; padding-left: 10px;">${
                                        code?.value
                                          ? String(code?.value)
                                          : String(
                                              code?.question
                                                ? code?.question
                                                : code?.docField,
                                            )
                                      }</div>`
                                    : `<span class="parsed_highlight ${
                                        code.value ? 'active' : 'inactive'
                                      }" id="${String(index).concat(
                                        `_${String(code.id)}`,
                                      )}" question-name="${String(
                                        code.question,
                                      )}" response-type="${String(
                                        code.response_type,
                                      )}">${
                                        code?.value
                                          ? String(code?.value)
                                          : String(
                                              code?.question
                                                ? code?.question
                                                : code?.docField,
                                            )
                                      }</span>`,
                                );
                                return;
                              }
                            }
                          }
                        });
                      const reactElement: any = String(final)
                        .replace(/&lt;/g, '')
                        .replace(/&gt;/g, '')
                        .replace(/&lt;&lt;&lt;/g, '')
                        .replace(/&gt;&gt;&gt;/g, '');
                      setHtmlState(reactElement);
                      setLoader(false);
                    })
                    .catch((e) => {
                      toast.error(e, alertOptions);
                      setLoader(false);
                    });
                })
                .catch((err) => {
                  setLoader(false);
                  toast.error(
                    err ? err : 'Oops!!! Something went wrong',
                    alertOptions,
                  );
                });
            })
            .catch((err) => {
              toast.error(err, alertOptions);
              setLoader(false);
            });
        })
        .catch((e) => {
          console.warn(e);
        });
    } catch (error) {
      console.log('eeeeeeeeeeeeeeeeee', error);
    }
  };

  const updateHtmlListener = useCallback(() => {
    const allHighlightedText: any =
      document.getElementsByClassName('parsed_highlight');
    for (let i = 0; i < allHighlightedText.length; i++) {
      allHighlightedText[i].addEventListener(
        'click',
        (e) => {
          if (e.target.classList[1] !== 'disabled') {
            const type =
              allHighlightedText[i].attributes['response-type'].value;
            const Ids = allHighlightedText[i]?.id;
            handleDocumentAnswerClick(Ids, type);
          }
        },
        false,
      );
    }
  }, [handleDocumentAnswerClick]);

  const getWinningStrategies = () => {
    getAllListData(
      `${String(SETUP_ENDPOINT.ConfigSection)}/winning?search=${String(
        winningParams.search,
      )}&skip=${String(winningParams.skip)}&take=${String(winningParams.take)}`,
    )
      .then((resp: any) => {
        const mergeData = !_.isEmpty(winningStrategies.data)
          ? [...winningStrategies.data, ...resp.data]
          : resp.data;
        setWinningStrategies({
          data: mergeData,
          total: Number(resp.total),
        });
      })
      .catch((e: any) => {});
  };

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    const { currentTarget } = event;
    setWinningStrategyAnchor((pre) => ({
      ...pre,
      anchorEl: currentTarget,
      open: pre.open == true ? false : true,
    }));
    const winning = await instantState(setWinningStrategyAnchor);
    winning.anchorEl && getWinningStrategies();
  };

  const handleClickAway = () => {
    setWinningStrategyAnchor((pre) => ({
      ...pre,
      anchorEl: null,
      open: false,
    }));
  };

  const rowProps = (winning, index) => {
    return (
      <Typography component={'div'} className="pop-det-box">
        <Typography
          className={'pop-title '.concat(
            selectedIndex.index === index ? 'active' : '',
          )}
          onClick={() =>
            setSelectedIndex({
              index,
              show: !selectedIndex.show,
            })
          }>
          {winning.question}
        </Typography>
        {selectedIndex.index === index && (
          <Typography className="pop-dis">{winning.notes}</Typography>
        )}
      </Typography>
    );
  };

  useEffect(() => {
    getWinningStrategies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winningParams]);

  useEffect(() => {
    if (htmlState) {
      updateHtmlListener();
    }
    return () => {
      // component Did mount
      const allHighlightedText: any =
        document.getElementsByClassName('parsed_highlight');
      for (let i = 0; i < allHighlightedText.length; i++) {
        allHighlightedText[i].removeEventListener('click', () => {}, false);
      }
    };
  }, [htmlState, updateHtmlListener]);

  useEffect(() => {
    if (!_.isUndefined(petitionId)) {
      if (location.state?.data?.fileFormat === 'pdf') {
        setPdfDocument(
          String(process.env.REACT_APP_DOCUMENT_URL) +
            String(location.state?.data?.pdfId),
        );
        setLoader(false);
      } else {
        getLinkDataForHTMLParsing();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitions, onlyShow, depUserId]);

  const getStrategy = useCallback(() => {
    getAllListData(
      `/petitioner/transaction/strategysession/list/${String(
        petitions?.petitionData?.lead_id,
      )}?skip=${String(debouncedParams.skip)}&take=${String(
        debouncedParams.take,
      )}&userId=${String(petitions?.petitionData?.beneficiary_id)}`,
    )
      .then(async (res) => {
        if (res && Array.isArray(res) && res[0]) {
          setSelectedStrategy({
            formId: res[0].form_id,
            id: res[0].id,
            isPetitioner: res[0].for_petitioner,
            value: 'yes',
          });
        }
        setShowStrategyListOpen((pre) => ({
          ...pre,
          buildSelect: false,
          strategySelect: true,
        }));
        const { data, total } =
          res && res.length
            ? { data: res, total: res.length }
            : { data: [], total: 0 };

        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setStrategyList((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
            total,
          }));
        } else {
          const idsEle = document.getElementById('parentScroll');
          idsEle && (idsEle.scrollTop = 0);
          setStrategyList((pre) => ({
            ...pre,
            data: data,
            total,
          }));
        }
        setTableLoader(false);
        setStrategyLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setStrategyList);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch(() => {
        setTableLoader(false);
        setStrategyLoader(false);
        setGetMoreLoader(false);
        setHasMoreResults(false);
      });
  }, [debouncedParams, petitions]);

  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setParams((pre) => ({
        ...pre,
        skip: pre.skip + 50,
      }));
    }
  };

  useEffect(() => {
    if (location.state?.data?.fileFormat !== 'pdf') {
      getStrategy();
    }
  }, [getStrategy, debouncedParams, location.state?.data?.fileFormat]);

  return (
    <>
      {loader ? <div className="liberty-loader" /> : ''}
      <Grid container>
        <Grid
          item
          md={10}
          sm={10}
          className="sticky-tab"
          sx={{ marginBottom: '.5rem' }}>
          <Typography component={'div'}>
            <Typography
              component={'div'}
              sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {location.state?.data?.fileFormat === 'pdf' ? (
                <Typography component={'div'}>
                  <>
                    {zoomProps.zoom <= 0.25 ? (
                      ''
                    ) : (
                      <Tooltip title={'ZoomOut'} placement="top">
                        <ZoomOutIcon
                          fontSize={'large'}
                          sx={{
                            fill: '#fff',
                            ...(zoomProps.zoom <= 0.25
                              ? { cursor: 'not-allowed', opacity: 0.5 }
                              : { cursor: 'pointer' }),
                          }}
                          onClick={() =>
                            zoomProps.zoom <= 0.25
                              ? ''
                              : setZoomProps((pre) => ({
                                  type: 'out',
                                  zoom: pre.zoom - 0.25,
                                }))
                          }
                        />
                      </Tooltip>
                    )}

                    <Tooltip title={'ZoomIn'} placement="top">
                      <ZoomInIcon
                        fontSize={'large'}
                        sx={{
                          cursor: 'pointer',
                          fill: '#fff',
                        }}
                        onClick={() =>
                          setZoomProps((pre) => ({
                            type: 'in',
                            zoom: pre.zoom + 0.25,
                          }))
                        }
                      />
                    </Tooltip>
                  </>
                </Typography>
              ) : (
                ''
              )}
              {location.state?.data?.fileFormat === 'pdf' ? (
                <Typography
                  component={'div'}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  <Typography
                    component={'label'}
                    sx={{
                      color: '#ffff',
                      paddingRight: '12px',
                    }}>
                    Jump to page
                  </Typography>
                  <CustomSelect
                    searchable={false}
                    isClearable={false}
                    value={String(currentPageNo)}
                    options={
                      !_.isEmpty(totalPageArr)
                        ? totalPageArr.map((e) => ({ label: e, value: e }))
                        : []
                    }
                    onChange={(e) => {
                      window.scrollTo(0, 0);
                      setCurrentPageNo(e.target.value);
                    }}
                    placeHolder={''}
                    className={'custom-search-input bo-rles'}
                  />
                </Typography>
              ) : (
                ''
              )}

              <Typography
                className="black-white-text"
                sx={{
                  fontFamily: 'Arial-Bold !important',
                  fontSize: '1.25em !important',
                }}>
                {location.state?.data?.templateName}
              </Typography>
              <Typography component={'div'}>
                <Button
                  className="cancel-btn mr-2"
                  onClick={() => {
                    if (userDetail.role_id === 3) {
                      navigate(
                        `${String(
                          AttorneyPrivateRoutes.PETITIONS.BUILDCASE,
                        )}/${String(petitionId)}${
                          searchParams.get('orgId')
                            ? '?orgId=' + String(searchParams.get('orgId'))
                            : ''
                        }${
                          searchParams.get('invited')
                            ? '&invited=true&isPetition=true'
                            : searchParams.get('associated')
                            ? '&associated=true&isPetition=true'
                            : ''
                        }`,
                      );
                      return;
                    }
                    if (userDetail.role_id === 5) {
                      navigate(
                        `${String(
                          AffilatePrivateRoutes.ATTORNEY_PETITIONS.BUILDCASE,
                        )}/${String(petitionId)}`,
                      );
                      return;
                    }
                  }}>
                  <Typography className="o-box" />
                  Cancel
                </Button>
                {location.state?.data?.fileFormat !== 'pdf' ? (
                  <Button
                    className="cancel-btn mr-2"
                    onClick={() => {
                      const oNewWin = window.open(
                        'about:blank',
                        '_blank',
                        'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=700,height=450',
                      );
                      oNewWin.document.open();
                      // oNewWin.document.write(
                      //   '<link rel="stylesheet" type="text/css" href="appstyle.css" />',
                      // );
                      oNewWin.document.write(
                        document.getElementById('buildedit').innerHTML,
                      );
                      const style = document.createElement('style');
                      style.type = 'text/css';
                      style.innerHTML =
                        '.buildedit { background-color: #ffffff; padding: 2.5rem; p,a,span,label,li {font-size: 18px !important;} .o-text {color: #343434 !important;opacity: 0.8;line-height: 34px !important;}} .font-bold { font-family: Arial-Bold !important; } .f-30 {font-size: 30px !important;} .mb-1 {margin-bottom: 0.5rem !important;} .mt-1 {margin-top: 0.5rem !important;} .parsed_highlight.active {cursor: pointer; color: #4f72f1; font-size: 18px !important; font-family: "Arial-Regular" !important; padding: 4px 2px; min-height: 38px;} .parsed_highlight.inactive {cursor: pointer;color: red;font-size: 18px !important;font-family: "Arial-Regular" !important; padding: 4px 2px;min-height: 38px; } .parsed_highlight_disabled {background: rgba(177, 177, 177, 0.301); cursor: not-allowed;}';
                      oNewWin.document
                        .getElementsByTagName('head')[0]
                        .appendChild(style);
                      oNewWin.document.close();
                      return false;
                    }}>
                    <Typography className="o-box" />
                    Preview
                  </Button>
                ) : (
                  ''
                )}
                {(!petitions?.petitionData?.isAffilateCase &&
                  userDetail.role_id === 3) ||
                (petitions?.petitionData?.isAffilateCase &&
                  [3].includes(petitions?.petitionData?.affilateRoleId)) ? (
                  <Button className="add-btn" onClick={buildNow}>
                    Build
                  </Button>
                ) : (
                  ''
                )}
              </Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid
          item
          md={6}
          sm={10}
          sx={{ paddingRight: '.5rem' }}
          id="inline-edit-pdf-X56T78I09P">
          {location.state?.data?.fileFormat === 'pdf' ? (
            <Typography
              component={'div'}
              sx={{
                maxHeight: '70vh',
                overflowX: 'auto',
                overflowY: 'auto',
              }}>
              <BuildCasePDFEdit
                pdfDocument={pdfDocument}
                petitionId={petitionId}
                beneficiaryId={petitions.petitionData?.beneficiary_id}
                leadId={petitions.petitionData?.lead_id}
                templateId={templateId}
                dependentUserId={
                  onlyShow &&
                  onlyShow === 'dependent' &&
                  !_.isUndefined(depUserId)
                    ? depUserId
                    : ''
                }
                onlyShow={onlyShow}
                setTotalPageArr={setTotalPageArr}
                pageNo={currentPageNo}
                setCurrentPageNo={setCurrentPageNo}
                zoomProps={zoomProps}
                setTreeList={setTreeList}
                treeList={treeList}
                selectedQus={currentSelectQus}
                updatedFieldValue={updatedFieldValue}
                setupdatedFieldValue={setupdatedFieldValue}
                setZoomProps={setZoomProps}
              />
            </Typography>
          ) : (
            <Typography component={'div'} id="buildedit">
              <Typography
                component={'div'}
                className="buildedit"
                sx={{
                  maxHeight: '70vh',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                }}>
                <Typography className="font-bold f-30 mb-1">
                  {location.state?.data?.templateName}
                </Typography>
                <Typography
                  component={'div'}
                  className="mb-1 mt-1 o-text"
                  dangerouslySetInnerHTML={{
                    __html: htmlState ? htmlState : '',
                  }}
                />
                {/* {htmlState !== '' ? htmlState : pdfDocument} */}
                {/* </Typography> */}
              </Typography>
            </Typography>
          )}
        </Grid>
        <Grid item md={4} sm={10}>
          <Typography
            component={'div'}
            sx={{
              maxHeight: '70vh',
              overflowX: 'hidden',
              overflowY: 'auto',
            }}>
            <WizardForm
              focusElement={
                location.state?.focusElement
                  ? location.state?.focusElement
                  : null
              }
              ref={wizardRef}
              formType={
                onlyShow &&
                onlyShow === 'dependent' &&
                !_.isUndefined(depUserId)
                  ? 'dependent'
                  : 'configureForms'
              }
              list={treeList}
              tabHidden={true}
              formId={petitions.petitionData.petitioner_form_id}
              beneficiaryID={petitions.petitionData.beneficiary_id}
              leadId={petitions.petitionData.lead_id}
              petitionID={petitions.petitionData.id}
              hiddenMargin={true}
              showManageOption={true}
              sectionApproveHide={true}
              handleClickToScroll={(e) => handleInputClick(e)}
              inputRefs={refs}
              dependentPersonId={
                onlyShow &&
                onlyShow === 'dependent' &&
                !_.isUndefined(depUserId)
                  ? Number(depUserId)
                  : null
              }
              draftCall={false}
              setCurrentSelectQus={setCurrentSelectQus}
              hideDependentNotes={true}
              highlightText={(id, value) => {
                if (location.state?.data?.fileFormat === 'pdf') {
                  setupdatedFieldValue(
                    treeList[0].questionDetails
                      .filter((e) => e.id === id)
                      .reduce((acc, currentVal) => {
                        if (currentVal.pdf_field_name) {
                          acc.push({
                            exportValue: '',
                            fieldName: currentVal.pdf_field_name,
                            value: [14].includes(currentVal.response_type)
                              ? phoneNumberFormat(value ? value : '')
                              : value,
                          });
                        } else if (
                          currentVal.multiple_pdf_field &&
                          Array.isArray(currentVal.multiple_pdf_field)
                        ) {
                          try {
                            const arr = JSON.parse(value);
                            if (arr && arr.length) {
                              arr.map((subQus) => {
                                acc.push({
                                  exportValue: subQus.exportValue,
                                  fieldName: subQus.pdf_field_name,
                                  value: subQus.status,
                                });
                              });
                            }
                          } catch (error) {
                            console.warn('Someting went wrong');
                          }
                        }
                        return acc;
                      }, []),
                  );
                  return;
                }
                const checkId = linkCodes.find(
                  (code) => Number(id) === Number(code.id) && !code.disable,
                );
                if (
                  checkId &&
                  (_.isNull(checkId.value) ? '' : checkId.value) !== value
                ) {
                  applyHighlightedContent(checkId, value);
                  setLinkCodes([
                    ...linkCodes.map((el) => {
                      if (el.id === Number(id)) {
                        el.value = value;
                      }
                      return el;
                    }),
                  ]);
                }
              }}
              setHCData={setHCData}
              callDocumentProductionAPI={() => getLinkDataForHTMLParsing()}
            />
          </Typography>
        </Grid>

        <Grid item md={2} sm={12}>
          <Typography component={'div'} className="add-inner">
            <Typography component={'div'} className="bulk-add-box">
              <Typography component={'div'} className="add-inner mb-1">
                <Typography component={'div'} className="add-inner-box" />
                <Typography className="link-txt f-16 pt-5" />
                <Typography
                  className=" built-option d-flex-a"
                  // aria-describedby={winningStrategyId}
                  onClick={handleClick}>
                  <img
                    src={Win}
                    alt="im"
                    className="mr-1"
                    style={{ width: '20px !important' }}
                  />
                  Winning Strategies
                </Typography>
                <Popper
                  id={winningStrategyId}
                  className="own-popper"
                  open={winningStrategyAnchor.open}
                  anchorEl={winningStrategyAnchor.anchorEl}
                  placement="left">
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box sx={{ padding: '1rem 1rem 0.5rem 1rem' }}>
                      <Typography
                        className="new-searc"
                        sx={{ marginBottom: '.5rem' }}>
                        <CustomInput
                          placeHolder={'Type to filter'}
                          className={'custom-search-input'}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={async () => {
                                    setWinningStrategies({
                                      data: [],
                                      total: 0,
                                    });
                                    await instantState(setWinningStrategies);
                                    setWinningParams((prev) => ({
                                      ...prev,
                                      search: '',
                                      skip: 0,
                                      take: 10,
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setWinningStrategies({
                              data: [],
                              total: 0,
                            });

                            setWinningParams((prev) => ({
                              ...prev,
                              search: e.target.value,
                              skip: 0,
                              take: 10,
                            }));
                          }}
                          value={winningParams.search}
                        />
                      </Typography>
                      {winningStrategies.data &&
                      winningStrategies.data.length > 0 ? (
                        <InfinityScroll
                          paramState={setWinningParams}
                          height={'350px'}
                          scrollableTargetId={'winningStrategy'}
                          data={winningStrategies.data}
                          rowProps={rowProps}
                          count={winningStrategies.total}
                        />
                      ) : (
                        <Typography
                          component={'div'}
                          className="pop-det-box"
                          sx={{ height: '350px' }}>
                          <Typography className="pop-dis">
                            No Record(s) Found.
                          </Typography>
                        </Typography>
                      )}
                      <div className="pop-dis" style={{ textAlign: 'center' }}>
                        {!_.isEmpty(winningStrategies.data)
                          ? winningStrategies.data.length
                          : 0}{' '}
                        of {winningStrategies.total} winning strategies
                      </div>
                    </Box>
                  </ClickAwayListener>
                </Popper>
                {(location.state?.data?.fileFormat !== 'pdf' &&
                  !petitions?.petitionData?.isAffilateCase &&
                  userDetail.role_id === 3) ||
                (location.state?.data?.fileFormat !== 'pdf' &&
                  petitions?.petitionData?.isAffilateCase &&
                  [3].includes(petitions?.petitionData?.affilateRoleId)) ? (
                  <Typography
                    className=" built-option d-flex-a"
                    onClick={() =>
                      setShowStrategyListOpen({
                        buildSelect: false,
                        includeSignature: false,
                        show: true,
                        strategySelect: true,
                      })
                    }>
                    {' '}
                    <img
                      src={Stratey}
                      alt="im"
                      className="mr-1"
                      style={{ width: '20px !important' }}
                    />
                    Select Strategy
                  </Typography>
                ) : (
                  ''
                )}
                {/*
              <Typography className=" built-option disalbed d-flex-a">
                {' '}
                <img
                  width="21"
                  src={ICD}
                  alt="im"
                  className="mr-1"
                  style={{ width: '21px !important' }}
                />
                Import Case Docs
              </Typography>
              <Typography className=" built-option disalbed d-flex-a">
                {' '}
                <img
                  width="26"
                  src={Layer}
                  alt="im"
                  className="mr-1"
                  style={{ width: '26px !important' }}
                />
                Copy Data From Case
              </Typography>
              <Typography className=" built-option disalbed d-flex-a mb-1">
                {' '}
                <img
                  width="25"
                  src={PD}
                  alt="im"
                  className="mr-1"
                  style={{ width: '25px !important' }}
                />
                Positions
              </Typography> */}
              </Typography>

              {/* <Typography component={'div'} className="add-inner mt-1 mb-1">
              <Typography component={'div'} className="add-inner-box" />
              <Typography
                component={'div'}
                className=" d-flex-a bu-color pl-2 pt-2 pb-1">
                <img src={Groupm} alt="" className="mr-1" />
                Warning
              </Typography>

              <Typography className="link-txt d-flex-a f-14 pb-2 pl-2">
                <Typography className="link-txt" component={'span'}>
                  <InfoIcon fontSize="small" className="mr-1" />
                </Typography>
                <Typography className="link-txt" component={'span'}>
                  Product Manager
                </Typography>
              </Typography>
              <Typography className=" bu-color f-16 pb-2 pl-2 l-y">
                The job title &quot;product manager&quot; may be too vague and
                is associated with triggering requests for evidence
              </Typography>
            </Typography> */}
              <Translate />

              {hcData.selectedQuestion && (
                <HistoryComments
                  hcData={hcData}
                  setHCData={setHCData}
                  wizardRef={wizardRef}
                  tabName={''}
                  screenName={'build-case'}
                  type={
                    location.state?.showType === 'leads' ? 'lead' : 'petition'
                  }
                  formId={templateId}
                  petitionID={petitionId}
                  leadId={null}
                  beneficiaryId={null}
                  affilateUserList={
                    petitions?.petitionData?.isAffilateCase
                      ? petitions?.petitionData?.affilateUserList
                      : []
                  }
                />
              )}
            </Typography>
          </Typography>
        </Grid>
        <ConfirmDialog
          title={'Confirm'}
          floatingBtnShow={true}
          close={() => {
            setConfirmBuildOpen({
              key: '',
              show: false,
            });
          }}
          visibleClose={true}
          open={
            confirmBuildOpen.show && confirmBuildOpen.key === 'contact-person'
          }
          selectForm={' Myfirm'}
          buildForm={'Build Now'}
          onConfirm={(act) =>
            act === 'yes' ? confirmClose() : navigate('/attorney/setup/my-firm')
          }
          autoCloseDisable={true}>
          The contact person is not available. Please select the contact person
          on the My Firm screen.
        </ConfirmDialog>

        <ConfirmDialog
          title={'Confirm'}
          floatingBtnShow={true}
          open={
            confirmBuildOpen.show &&
            confirmBuildOpen.key === 'signature-document'
          }
          onConfirm={(act) => onBuildSuccess(act)}
          autoCloseDisable={true}>
          Do you wish to include a signature?
        </ConfirmDialog>

        {showStrategyListOpen.show ? (
          <CustomModalView
            open={true}
            fullWidth={true}
            maxWidth={'xl'}
            scroll={'paper'}
            autoCloseDisable={true}
            close={() => {
              setShowStrategyListOpen({
                buildSelect: false,
                includeSignature: false,
                show: false,
                strategySelect: false,
              });
              getStrategy();
            }}
            isFilters={false}
            title="Select any one strategy">
            <DialogContent id="parentScroll">
              {strategyLoader && <div className="liberty-loader" />}
              <InfiniteScroll
                dataLength={
                  strategyList && strategyList.data && strategyList.data.length
                    ? strategyList.data.length
                    : 0
                }
                style={{
                  overflow: 'unset',
                }}
                next={getMoreList && getMoreList}
                hasMore={hasMoreResults}
                scrollableTarget={'parentScroll'}
                loader={
                  getMoreLoader && (
                    <div
                      style={{
                        backgroundColor: '#ffffff',
                        textAlign: 'center',
                        width: '100%',
                      }}>
                      <CircularProgress
                        size={30}
                        sx={{
                          color: '#002c69',
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                      />
                    </div>
                  )
                }
                endMessage>
                <table className="lead-table table-hover">
                  <thead>
                    <tr>
                      <th>Section Name</th>
                      <th>Session For</th>
                      <th>Scheduled Date</th>
                      <th>Updated By</th>
                      <th>Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableLoader ? (
                      <tr>
                        <td colSpan={8}>
                          <div
                            style={{
                              backgroundColor: '#ffffff',
                              textAlign: 'center',
                              width: '100%',
                            }}>
                            <CircularProgress
                              size={30}
                              sx={{
                                color: '#002c69',
                                marginBottom: 10,
                                marginTop: 10,
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ) : strategyList &&
                      strategyList.data &&
                      strategyList.data.length ? (
                      strategyList.data.map((row: any, index) => (
                        <tr
                          key={index}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSelectedStrategy({
                              formId: row.form_id,
                              id: row.id,
                              isPetitioner: row.for_petitioner,
                              value: 'yes',
                            });
                          }}>
                          <td>{row?.section_name}</td>
                          <td>
                            {row?.strategyFor
                              ? row?.userName
                              : row?.username || row?.userName}
                          </td>
                          <td>
                            {row?.start_date
                              ? moment(row?.start_date).format(
                                  'YYYY-MM-DD hh:mm A',
                                )
                              : ''}
                          </td>
                          <td>
                            {row?.modifieduser
                              ? `${String(row?.modifieduser)}  at`
                              : ''}{' '}
                            {row?.modified_at
                              ? moment(row?.modified_at).format(
                                  'YYYY-MM-DD hh:mm A',
                                )
                              : ''}
                          </td>
                          <td>
                            <td
                              style={{
                                borderBottom: 'none',
                              }}>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name={'stselect'}
                                value={
                                  selectedStrategy.id === row.id
                                    ? selectedStrategy.value
                                    : ''
                                }
                                tabIndex={6}
                                onChange={(e) => {
                                  setSelectedStrategy({
                                    formId: row.form_id,
                                    id: row.id,
                                    isPetitioner: row.for_petitioner,
                                    value: e.target.value,
                                  });
                                }}>
                                <FormControlLabel
                                  value="yes"
                                  control={<Radio />}
                                  label=""
                                />
                              </RadioGroup>
                            </td>
                          </td>
                        </tr>
                      ))
                    ) : (
                      !tableLoader && (
                        <tr className={'text-center'}>
                          <td colSpan={5} align={'center'}>
                            No Record(s) Found.
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            </DialogContent>
            {selectedStrategy.id && (
              <DialogActions>
                <Grid item md={12} sm={12} className="d-flex-jae mt-1 pr-2">
                  <Typography component={'div'} className="custom-field u-pr-1">
                    <Button
                      className="n-add-btn f-18 font-bold"
                      onClick={buildDocumentForProductionWithStrategy}>
                      Submit
                    </Button>
                  </Typography>
                </Grid>
              </DialogActions>
            )}
          </CustomModalView>
        ) : null}
      </Grid>
    </>
  );
};

export default BuildCaseEdit;
