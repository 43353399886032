import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import useStyles from '../../PrivateStyle';

type WinningStrategyListDisplays = {
  data;
  filterParams;
};
export const WinningStrategyListDisplay: React.FC<
  WinningStrategyListDisplays
> = ({ data, filterParams }) => {
  const classes = useStyles();
  const [filterData, setFilterData] = useState([]);
  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;
      const hashValue = hash?.replace('#', '');
      if (hashValue) {
        // setActiveHash(hashValue);
        setTimeout(() => {
          const elementToScroll = document.getElementById(hashValue);

          if (!elementToScroll) return;

          if (elementToScroll) {
            elementToScroll.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            });
          }
        }, 1000);
      }
    };

    scrollToHashElement();
  }, []);

  useEffect(() => {
    if (filterParams.question === '' || filterParams.link_code === '') {
      setFilterData(data);
    }
    if (filterParams.question) {
      setFilterData(
        data.filter(
          (item) =>
            item.question
              .toLowerCase()
              .indexOf(filterParams.question.toLowerCase()) > -1,
        ),
      );
    }
    if (filterParams.link_code) {
      setFilterData(
        data.filter(
          (item) =>
            item.link_code
              .toLowerCase()
              .indexOf(filterParams.link_code.toLowerCase()) > -1,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  return (
    <Typography component={'div'}>
      {filterData.length ? (
        filterData.map((dataQuestions, index) => (
          <Typography component={'div'} className="configureBox" key={index}>
            <Typography
              className="configureItem"
              sx={
                dataQuestions?.link_code &&
                window.location.hash?.replace('#', '') ===
                  dataQuestions?.link_code
                  ? {
                      boxShadow: '0 0 15px #17c8e7',
                      padding: '25px 50px !important',
                    }
                  : { padding: '25px 50px !important' }
              }>
              <Typography className="d-flex-a d-flex-sb w-100">
                <Typography
                  component={'div'}
                  id={dataQuestions?.link_code ? dataQuestions?.link_code : ''}>
                  <Typography className="black-white-text ctitle">
                    {Number(index) + Number(1)} . {dataQuestions?.question}
                  </Typography>
                  <Typography component={'div'} className="w-100 mt-2">
                    <Typography className="rl-text hideshow">
                      {dataQuestions?.notes}
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
          </Typography>
        ))
      ) : (
        <Typography component={'div'} className="leadBox">
          <Typography
            className="leadItem"
            sx={{ marginBottom: 'unset !important' }}>
            <Typography className={classes.userItemInner} />
            <Typography className="d-flex d-flex-sb w-100">
              <Typography
                component={'div'}
                sx={{ paddingLeft: '.5rem' }}
                className="d-flex-1">
                <Typography
                  className="black-white-text ctitle"
                  sx={{
                    opacity: '0.9',
                  }}>
                  No Record(s) Found.
                </Typography>
              </Typography>
            </Typography>
          </Typography>
        </Typography>
      )}
    </Typography>
  );
};

export default WinningStrategyListDisplay;
