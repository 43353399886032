import { Typography } from '@mui/material';
import React from 'react';
import CountUp from 'react-countup';

export const Counting: React.FC<any> = ({ end }) => {
  // const [counting, setCounting] = useState(0);
  // const ref = useRef(0);
  // const isMounted = useRef(false);

  // const accumulator = end / 200;

  // const updateCounterState = useCallback(() => {
  //   if (ref.current < end) {
  //     const result = Math.ceil(Number(ref.current) + Number(accumulator));
  //     if (result > end) return setCounting(end);
  //     setCounting(result);
  //     ref.current = result;
  //   }
  //   setTimeout(updateCounterState, 20);
  // }, [accumulator, end]);

  // useEffect(() => {
  //   isMounted.current = true;
  //   if (isMounted.current) {
  //     updateCounterState();
  //   }
  //   return () => {
  //     isMounted.current = false;
  //   };
  // }, [end, updateCounterState]);

  return (
    <CountUp start={0} end={end} delay={0}>
      {({ countUpRef }) => <Typography className="t-count" ref={countUpRef} />}
    </CountUp>
  );
};
