import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import InfinityScroll from 'components/InfinityScroll';
import { AdminPrivateRoutes, AttorneyPrivateRoutes } from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { SETUP_ENDPOINT } from 'utils/Constant';

import useStyles from '../../PrivateStyle';

const SectionTypes = [
  {
    label: 'Beneficiary',
    value: 'beneficiary',
  },
  {
    label: 'Petitioner',
    value: 'petitioner',
  },
  {
    label: 'Case Plan',
    value: 'caseplan',
  },
  {
    label: 'Strategy',
    value: 'strategy',
  },
  {
    label: 'Contract',
    value: 'contract',
  },
  {
    label: 'Dependent',
    value: 'dependent',
  },
  {
    label: 'Winning Strategy',
    value: 'winning',
  },

  {
    label: 'Labor Condition Application',
    value: 'lca',
  },
];
const Status = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
  {
    label: 'All',
    value: 'all',
  },
];

export const ConfigureSection: React.FC<any> = React.memo(() => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const classes = useStyles();
  const navigate = useNavigate();
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [params, setParams] = useState<any>({
    id: '',
    majorsection: '',
    name: '',
    order: '',
    sectiontype: '',
    skip: 0,
    sortby: '',
    status: 'all',
    take: 50,
  });
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [sections, setSections] = useState<any>([]);
  const debouncedParams = useDebounce(params, 500);
  useEffect(() => {
    getAllListData(
      `${String(SETUP_ENDPOINT.ConfigSection)}?id=${String(
        debouncedParams.id,
      )}&name=${String(debouncedParams.name)}&sectiontype=${String(
        debouncedParams.sectiontype,
      )}&status=${String(
        debouncedParams.status === 'all' ? '' : debouncedParams.status,
      )}&majorsection=${String(debouncedParams.majorsection)}&skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}&order=${String(
        debouncedParams.order,
      )}&sortby=${String(debouncedParams.sortby)}&attorney=${
        userDetail.role_id === 3 ? 'true' : 'false'
      }${
        userDetail?.role_id === 3 && debouncedParams.sectiontype == 'contract'
          ? '&sectionMenu=true'
          : ''
      }`,
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setSections((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          const mergeData = !_.isEmpty(sections)
            ? [...sections.data, ...resp.data]
            : resp.data;
          setSections({
            count: resp.total,
            data: mergeData,
          });
        }
        setTableLoader(false);
        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setSections);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams, userDetail]);

  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12} className="pr-2rem">
        <Typography component={'div'} className="configureBox">
          {loader ? (
            <div className="liberty-loader" />
          ) : (
            <Box sx={{ width: '100%' }} className="documentTab">
              <Grid item md={12} sm={12}>
                <Typography
                  component={'div'}
                  className="d-flex-jae full-screen-searc mb-2 mt-2">
                  <Typography
                    component={'div'}
                    className="d-flex-a small-search"
                    sx={{
                      // backgroundColor: '#84898c',
                      borderRadius: '4px',
                    }}>
                    <Typography
                      className="d-flex-a searc-box1"
                      component={'div'}
                      sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: '4px',
                        marginRight: '.5rem',
                        width: '235px',
                      }}>
                      <SearchIcon
                        sx={{
                          fill: '#3d3f40 !important',
                          left: '5px',
                          position: 'absolute',
                          zIndex: '1',
                        }}
                      />
                      <CustomInput
                        placeHolder={'Search for Name'}
                        className={'custom-search-input bo-rles'}
                        value={params.name}
                        inputProps={{
                          endAdornment:
                            params.name !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    setTableLoader(true);
                                    setSections({
                                      count: 0,
                                      data: [],
                                    });
                                    setParams((prev) => ({
                                      ...prev,
                                      name: '',
                                      skip: 0,
                                      take: 50,
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          setTableLoader(true);
                          setSections({
                            count: 0,
                            data: [],
                          });
                          setParams((prev) => ({
                            ...prev,
                            name: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                      />
                    </Typography>
                    <Typography
                      sx={{
                        borderRadius: '4px',
                        marginRight: '.5rem',
                        paddingLeft: 'unset !important',
                        width: '205px',
                      }}
                      className="searc-box2">
                      <CustomSelect
                        searchable={true}
                        labelKey={'label'}
                        valueKey={'value'}
                        options={SectionTypes}
                        selectedValue={''}
                        onChange={(e) => {
                          setTableLoader(true);
                          setSections({
                            count: 0,
                            data: [],
                          });
                          setParams((prev) => ({
                            ...prev,
                            sectiontype: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                        placeHolder={'Select Type'}
                        className={'custom-search-input'}
                      />
                    </Typography>
                    <Typography sx={{ width: '205px' }} className="searc-box3">
                      <CustomSelect
                        searchable={true}
                        labelKey={'label'}
                        valueKey={'value'}
                        value={
                          params.status === 'all'
                            ? 'all'
                            : params.status
                            ? 'active'
                            : 'inactive'
                        }
                        isClearable={params.status === 'all' ? false : true}
                        options={Status}
                        selectedValue={''}
                        onChange={(e) => {
                          setTableLoader(true);
                          setSections({
                            count: 0,
                            data: [],
                          });
                          setParams((prev) => ({
                            ...prev,
                            skip: 0,
                            status:
                              e.target.value === 'all' || e.target.value === ''
                                ? 'all'
                                : e.target.value === 'active'
                                ? true
                                : false,
                            take: 50,
                          }));
                        }}
                        placeHolder={'Status'}
                        className={'custom-search-input'}
                      />
                    </Typography>
                  </Typography>
                </Typography>
              </Grid>
              <Typography
                component={'div'}
                sx={{
                  borderRadius: '8px 8px 0 0',
                }}
                className={classes.scrollbox}
                id="scrollParent">
                <InfiniteScroll
                  dataLength={
                    sections && sections.data && sections.data.length
                      ? sections.data.length
                      : 0
                  }
                  style={{
                    overflow: 'unset',
                  }}
                  next={getMoreList && getMoreList}
                  hasMore={hasMoreResults}
                  scrollableTarget={'scrollParent'}
                  loader={
                    getMoreLoader && (
                      <div
                        style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          width: '100%',
                        }}>
                        <CircularProgress
                          size={30}
                          sx={{
                            color: '#002c69',
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        />
                      </div>
                    )
                  }
                  endMessage>
                  <table className="lead-table table-hover">
                    <thead>
                      <tr>
                        <th className="W-4">Name</th>
                        <th className="W-4">description</th>
                        <th className="W-1">type</th>
                        <th className="W-1">status</th>
                      </tr>
                    </thead>
                    <tbody style={{ cursor: 'pointer' }}>
                      {tableLoader ? (
                        <tr>
                          <td colSpan={8}>
                            <div
                              style={{
                                backgroundColor: '#ffffff',
                                textAlign: 'center',
                                width: '100%',
                              }}>
                              <CircularProgress
                                size={30}
                                sx={{
                                  color: '#002c69',
                                  marginBottom: 10,
                                  marginTop: 10,
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      ) : (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                          {sections && sections.data && sections.data.length > 0
                            ? sections.data.map((item, index) => (
                                <tr
                                  key={index}
                                  onClick={() =>
                                    navigate(
                                      userDetail.role_id === 1
                                        ? `${
                                            AdminPrivateRoutes.SECTION
                                              .SECTIONEDIT
                                          }/${String(item.id)}`
                                        : userDetail.role_id === 3
                                        ? `${
                                            AttorneyPrivateRoutes.SECTION
                                              .SECTIONEDIT
                                          }/${String(item.id)}`
                                        : '',
                                    )
                                  }>
                                  <td>
                                    <div
                                      className="hideshow"
                                      style={{
                                        width: '255px',
                                        wordBreak: 'break-all',
                                      }}>
                                      {item.section_name
                                        ? item.section_name
                                        : '-'}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className="hideshow"
                                      style={{
                                        width: '255px',
                                        wordBreak: 'break-all',
                                      }}>
                                      {item.section_description
                                        ? item.section_description
                                        : '-'}
                                    </div>
                                  </td>
                                  <td>
                                    {item.section_type
                                      ? item.section_type
                                      : '-'}
                                  </td>
                                  <td>
                                    {item.section_status
                                      ? 'Active'
                                      : 'Inactive'}
                                  </td>
                                </tr>
                              ))
                            : !tableLoader && (
                                <tr className={'text-center'}>
                                  <td colSpan={10}>No Record(s) Found.</td>
                                </tr>
                              )}
                        </>
                      )}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </Typography>
            </Box>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
});

export default ConfigureSection;
