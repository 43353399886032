import { Button, Grid, Typography } from '@mui/material';
import { PublicRoutes } from 'config';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

export const BeneficiaryContractHistory: React.FC<any> = () => {
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(true);
  const [beneficiaryData, setBeneficiaryData] = useState<any>({});

  useEffect(() => {
    if (searchParams.get('leadUser') !== '') {
      getAllListData(
        `${MASTER_ENDPOINT.Dashboard}/beneficiary?leadUserId=${String(
          searchParams.get('leadUser'),
        )}`,
      )
        .then((resp: any) => {
          setBeneficiaryData(
            resp && resp.data && resp.data.length > 0 ? resp.data[0] : {},
          );
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item md={9}>
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <Typography component={'div'} className="c-p-box paylist">
            <Typography
              component={'div'}
              className="ph-box"
              sx={{ padding: '0px !important' }}>
              {!_.isEmpty(beneficiaryData) &&
              beneficiaryData.paymentHistoryDetails &&
              beneficiaryData.paymentHistoryDetails.length > 0 ? (
                beneficiaryData.paymentHistoryDetails.map((pay, index) => (
                  <Typography
                    component={'div'}
                    className="ph-item d-flex-a"
                    key={index}>
                    <Grid container>
                      <Grid item md={3} sm={12} className="d-flex-a">
                        <Typography className="f-15 font-bold">
                          $ {pay.payment_total}
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={12}>
                        <Grid container>
                          <Grid item md={4}>
                            <Typography className="dblue">
                              Request Date
                            </Typography>
                          </Grid>
                          <Grid item md={8}>
                            {moment(pay?.created_at).format('MMMM DD, YYYY')}
                          </Grid>
                          <Grid item md={4}>
                            <Typography className="dblue"> Status</Typography>
                          </Grid>
                          <Grid item md={8}>
                            {pay?.response_message === 'APPROVAL'
                              ? 'Success'
                              : pay?.response_message
                              ? pay?.response_message
                              : 'Pending'}
                            {/* {pay.response_code === '00'
                              ? 'Success'
                              : !pay.response_code
                              ? 'Pending'
                              : pay.response_code === '00011'
                              ? 'Waiting for payment gateway response'
                              : 'Failed'} */}
                          </Grid>

                          {pay.transaction_id && (
                            <>
                              <Grid item md={4}>
                                <Typography className="dblue">
                                  {' '}
                                  Transaction ID
                                </Typography>
                              </Grid>
                              <Grid item md={8}>
                                {pay.transaction_id}
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      {!pay.response_code && (
                        <Grid item md={2} sm={12} className="d-flex-a">
                          <Button
                            className="n-add-btn"
                            sx={{
                              borderRadius: '5px !important',
                              height: '35px !important',
                              width: '93px !important',
                            }}
                            onClick={() =>
                              window.open(
                                `${String(
                                  PublicRoutes.PAYMENT.SECUREPAYMENT,
                                )}?hashKey=${String(pay.payment_hash)}`,
                              )
                            }>
                            Pay
                          </Button>
                          {/* <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description">
                          <Box className="success-modal custom-pay-modal">
                            <Typography className="text-center mt-2 mb-2">
                              <CheckCircleIcon
                                sx={{ fill: 'green', fontSize: '35px' }}
                              />
                            </Typography>
                            <Typography className="f-20 font-bold text-center mb-2">
                              Payment Successful
                            </Typography>
                            <Typography
                              component={'div'}
                              className="d-flex-ja mt-2 ">
                              <Button
                                className="n-add-btn"
                                sx={{
                                  borderRadius: '5px !important',
                                  fontFamily: 'Arial-Regular !important',
                                  fontSize: '0.9em !important',
                                  height: '35px !important',
                                  width: '100px !important',
                                }}>
                                Ok
                              </Button>
                            </Typography>
                          </Box>
                        </Modal> */}
                        </Grid>
                      )}
                    </Grid>
                  </Typography>
                ))
              ) : (
                <Typography component={'div'} className="ph-item d-flex-a">
                  <Grid container>
                    <Grid item md={12} className="d-flex-a">
                      <Typography className="f-15 font-bold">
                        No Record(s) Found.
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
              )}
            </Typography>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default BeneficiaryContractHistory;
