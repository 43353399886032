import { TextareaAutosize } from '@mui/material';
import React, { ReactElement } from 'react';

type InputProps = {
  placeHolder?: string;
  className?: string;
  onChange?: (src: any) => void;
  extraStyle?: any;
  value?: string;
  error?: boolean;
  name?: string;
  id?: string;
  disabled?: boolean;
  minRows?: number;
  onBlur?: (src: any) => void;
  onFocus?: (src: any) => void;
  focus?: boolean;
  defaultValue?: string;
};

const CustomTextArea = React.forwardRef(
  (props: InputProps, ref: any): ReactElement => {
    const {
      placeHolder,
      className,
      onChange,
      onBlur,
      extraStyle,
      value,
      error,
      name = '',
      id = '',
      disabled,
      minRows = 3,
      onFocus,
      focus,
      defaultValue,
    } = props;

    return (
      <TextareaAutosize
        defaultValue={defaultValue}
        autoFocus={focus}
        minRows={minRows}
        placeholder={placeHolder}
        className={className}
        onChange={onChange && onChange}
        name={name}
        ref={ref}
        id={id}
        onBlur={onBlur && onBlur}
        onFocus={onFocus && onFocus}
        style={{
          ...extraStyle,
          borderColor: error ? 'red' : null,
        }}
        value={value}
        disabled={disabled}
      />
    );
  },
);

export default CustomTextArea;
