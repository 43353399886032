import { yupResolver } from '@hookform/resolvers/yup';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  // FormHelperText,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
} from '@mui/material';
import CustomDatePicker from 'components/CustomDatePicker/customDatePicker';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import CustomTextArea from 'components/CustomTextArea/customTextArea';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { addNewData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { AddPetitionStatus } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';
import {
  alertOptions,
  submissionStationFileType,
  submissionStationMimeTypes,
} from 'utils/helper';
import { AddPetitionStatusSchema } from 'utils/ValidatorSchema';

import UploadIcon from '../../../../../../../assets/images/clupload.svg';
import DeleteIcon from '../../../../../../../assets/images/udelete.svg';

type Props = {
  teamMembers: Array<any>;
  petitionId: string;
  closeModal: () => void;
};
export const PetitionStatusDocumentUpload: React.FC<Props> = (props: Props) => {
  const { teamMembers, petitionId, closeModal } = props;
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const [statusLoader, setStatusLoader] = useState<any>(true);
  const [file, setFile] = useState<any>(null);

  const [petitionStatus, setPetitionStatus] = useState<Array<any>>([]);
  const [petitionTitle, setPetitionTitle] = useState<Array<any>>([]);
  const [showAdditionalTitle, setShowAdditionalTitle] = useState<any>(false);
  const uploadRef = useRef(null);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    watch,
    // setValue,
    formState: { errors },
  } = useForm<AddPetitionStatus>({
    criteriaMode: 'all',
    defaultValues: {
      additionalTitle: '',
      date: new Date().toString(),
      notify: false,
      notifyDate: '',
      notifyNotes: '',
      notifyPerson: [],
      sendEmail: false,
      status: '',
      teamMembers: [],
      titleOfDocument: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(AddPetitionStatusSchema),
  });

  const petitionStatusSUbmit = (data: AddPetitionStatus) => {
    setStatusLoader(true);
    const formData = new FormData();
    formData.append('status', data.status);
    formData.append('titleOfDocument', data.titleOfDocument);
    formData.append('date', moment(data.date).format('YYYY-MM-DD'));
    formData.append('additionalTitle', data.additionalTitle);
    formData.append(
      'receiverName',
      userDetail.role_id === 3
        ? !_.isEmpty(petitions) &&
          !_.isUndefined(petitions.petitionData?.petitioner_user_name)
          ? petitions.petitionData?.petitioner_user_name
          : petitions.petitionData?.benificiery_name
        : petitions.petitionData?.organization,
    );
    formData.append('file', file ? file : '');
    formData.append('sendEmail', String(data.sendEmail));
    formData.append('teamMembers', JSON.stringify(data?.teamMembers));

    if (data.notify) {
      formData.append('isNotify', 'true');
      formData.append(
        'notifyDate',
        moment(data.notifyDate).startOf('day').format(),
      );
      formData.append('notifyNotes', data.notifyNotes);
      formData.append('notifyEmail', JSON.stringify(data?.notifyPerson));
    }

    addNewData(
      formData,
      `${String(COMMON_ENDPOINT.submissionStatus)}/upload-document/${String(
        petitionId,
      )}`,
    )
      .then((resp: any) => {
        toast.success('Petition status has been updated.', alertOptions);
        setStatusLoader(false);
        closeModal();
        resetForm();
        setFile(null);
      })
      .catch((e) => {
        setStatusLoader(false);
      });
  };
  const submitOn = () => {
    handleSubmit(petitionStatusSUbmit)();
  };
  const checkFileValidation = (file) => {
    const files = file[0]?.name.split('.').pop();
    if (
      ['docx', 'pdf', 'jpeg', 'png', 'xls', 'jpg'].includes(files) &&
      submissionStationFileType?.includes(file[0].type)
    ) {
      if (file[0].size / 1024 / 1024 < 90) {
        setFile(file[0]);
      } else {
        uploadRef.current.value = '';
        toast.error(
          'File is too big, Please select a file less than 90 MB',
          alertOptions,
        );
        return;
      }
    } else {
      toast.error(
        'You can upload documents of types JPG, JPEG, PNG, PDF, XLS, DOCX.',
        alertOptions,
      );
    }
  };
  const onDrop = useCallback((acceptedFiles) => {
    checkFileValidation(acceptedFiles);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);
  const getPetitionStatusAndTitle = useCallback(() => {
    getAllListData(
      `${String(COMMON_ENDPOINT.submissionStatus)}/petition/status`,
    )
      .then((resp: any) => {
        setPetitionStatus(resp && resp.length ? resp : []);
      })
      .catch((e: any) => {
        setStatusLoader(false);
      });
    getAllListData(`${String(COMMON_ENDPOINT.submissionStatus)}/petition/title`)
      .then((resp: any) => {
        setPetitionTitle(resp && resp.length ? resp : []);
      })
      .catch((e: any) => {
        setStatusLoader(false);
      });
    setStatusLoader(false);
  }, []);

  useEffect(() => {
    getPetitionStatusAndTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <DialogContent>
        {statusLoader && <div className="liberty-loader" />}
        <Typography
          component={'form'}
          id={'addPetitionStatusForm'}
          sx={{ padding: '12px' }}>
          <Grid container spacing={1.5}>
            <Grid item md={12} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item md={3} sm={12} xs={12}>
                  <Typography className="f-15 font-bold pr-2">
                    Case Status <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <Controller
                    control={control}
                    name="status"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <CustomSelect
                          valueKey="id"
                          labelKey="name"
                          placeHolder={'Case Status'}
                          name={name}
                          value={value}
                          options={petitionStatus}
                          className={'custom-textarea-note'}
                          error={errors.status?.message || ''}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item md={3} sm={12} xs={12}>
                  <Typography className="f-15 font-bold pr-2">
                    Title <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <Controller
                    control={control}
                    name="titleOfDocument"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <CustomSelect
                          valueKey="id"
                          labelKey="name"
                          placeHolder={'Title'}
                          name={name}
                          value={value}
                          options={petitionTitle}
                          className={'custom-textarea-note'}
                          error={errors.titleOfDocument?.message || ''}
                          onChange={(e) => {
                            setShowAdditionalTitle(
                              petitionTitle.find(
                                (title) => title.id === e.target.value,
                              )?.isRequired,
                            );
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {showAdditionalTitle ? (
              <Grid item md={12} sm={12} xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={3} sm={12} xs={12}>
                    <Typography className="f-15 font-bold pr-2">
                      Additional Title <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item md={9} sm={12} xs={12}>
                    <Controller
                      control={control}
                      name={'additionalTitle'}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <div style={{ width: '100%' }}>
                            <Typography
                              component={'div'}
                              className="custom-field"
                              sx={{ marginBottom: '5px !important' }}>
                              <CustomInput
                                placeHolder={'Additional Title'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.additionalTitle?.message || ''}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            </Typography>
                          </div>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}

            <Grid item md={12} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item md={3} sm={12} xs={12}>
                  <Typography component={'div'} className="custom-field">
                    <Typography className="f-15 font-bold pr-2">
                      Date
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <Typography component={'div'} className="custom-field">
                    <Controller
                      control={control}
                      defaultValue=""
                      name="date"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomDatePicker
                            className="custom-input"
                            errors={errors.date?.message}
                            name={name}
                            value={value}
                            onChangeDate={(e) => onChange(e)}
                            isReadOnlyInput={true}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid
                  item
                  md={3}
                  sm={12}
                  xs={12}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    paddingLeft: 'unset',
                  }}>
                  <Typography className="f-15 font-bold pr-2">
                    Send Email
                  </Typography>

                  <Controller
                    control={control}
                    defaultValue={false}
                    name={'sendEmail'}
                    render={({ field, formState }) => {
                      return (
                        <Checkbox sx={{ marginLeft: '26px' }} {...field} />
                      );
                    }}
                  />
                </Grid>

                {watch('sendEmail') ? (
                  <Grid
                    item
                    md={9}
                    sm={12}
                    xs={12}
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      paddingLeft: 'unset',
                    }}>
                    <Typography className="f-15 font-bold pr-2">
                      Choose Recipients
                    </Typography>
                    <Controller
                      control={control}
                      name={'teamMembers'}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <div style={{ width: '100%' }}>
                            <CustomSelect
                              closeMenuOnSelect={false}
                              labelKey={'label'}
                              valueKey={'value'}
                              options={teamMembers}
                              isMulti={true}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e.target.value ? e.target.value : []);
                              }}
                              placeHolder={'Select'}
                              className={'basic-multi-select'}
                              error={errors.teamMembers?.message}
                              // tabIndex={1}
                            />
                          </div>
                        );
                      }}
                    />
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className="d-flex-b m-border">
                    <Typography
                      className="f-15 font-bold"
                      sx={{ display: 'contents !important' }}>
                      Attachment
                      <span>
                        <Tooltip
                          title="(You can upload documents of types JPG, JPEG, PNG, PDF, XLS, DOCX. You can upload upto a maximum of 90MB per upload)"
                          placement="top-start">
                          <InfoRoundedIcon
                            style={{
                              cursor: 'pointer',
                              fill: 'rgb(14 106 234)',
                              marginLeft: '5px',
                            }}
                          />
                        </Tooltip>
                      </span>
                    </Typography>

                    <Typography
                      component={'div'}
                      className="d-flex flex-column"
                      sx={{ marginLeft: '85px' }}>
                      {/* <Button
                        className="browse-btn"
                        sx={{
                          backgroundColor: 'lightgray',
                          color: '#000 !important',
                          height: '35px',
                          marginLeft: '5px',
                          minWidth: '110px !important',
                          textTransform: 'capitalize !important',
                        }}
                        onClick={() => {
                          setFileUploadOpen({ open: true });
                        }}>
                        Browse
                      </Button> */}
                      <Typography
                        component={'div'}
                        className="upload-drag-drop d-flex-ja pointer">
                        <Dropzone onDrop={onDrop}>
                          {({ getRootProps }) => {
                            return (
                              <>
                                <img src={UploadIcon} alt="icon" />
                                <Typography
                                  {...getRootProps({
                                    className: 'dropzone',
                                    onClick: (event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      uploadRef.current?.click();
                                    },
                                  })}
                                />
                              </>
                            );
                          }}
                        </Dropzone>
                        <Typography className="f-16">
                          Drag and drop file here, or click to select file
                        </Typography>
                      </Typography>

                      <input
                        type="file"
                        hidden
                        ref={uploadRef}
                        onChange={(e) => checkFileValidation(e.target.files)}
                        accept={submissionStationMimeTypes}
                      />
                      <Typography sx={{ marginTop: '10px' }}>
                        <form className="d-flex-a">
                          <Typography
                            component={'div'}
                            className="custom-field mb-0  d-flex-1"
                            sx={{ marginBottom: 'unset !important' }}>
                            <CustomInput
                              className="custom-input"
                              disabled={true}
                              value={file && file.name}
                              inputProps={{
                                endAdornment: file && (
                                  <InputAdornment position="end">
                                    <Tooltip title={'Remove'} placement={'top'}>
                                      <img
                                        src={DeleteIcon}
                                        alt="deleteIcon"
                                        style={{
                                          cursor: 'pointer',
                                          width: '28px',
                                        }}
                                        onClick={() => {
                                          uploadRef.current.value = '';
                                          setFile('');
                                        }}
                                      />
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Typography>
                        </form>
                      </Typography>
                      {/* <div className="d-flex-b">
                        <span
                          className="f-14 line-20 "
                          style={{ paddingLeft: '5px' }}>
                          {file?.name}
                        </span>
                        {file?.name ? (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setFile('');
                            }}>
                            <Tooltip title="Remove" placement="top">
                              <DeleteForeverSharpIcon style={{ fill: 'red' }} />
                            </Tooltip>
                          </IconButton>
                        ) : (
                          ''
                        )}
                      </div> */}
                    </Typography>
                    {/* {fileUploadOpen.open && (
                      <FileUploadDialog
                        open={fileUploadOpen.open}
                        allowedFileType={submissionStationFileType}
                        errorMessage={
                          'You can upload documents of types JPG, JPEG, PNG, PDF, XLS, DOCX only'
                        }
                        info={
                          '(You can upload documents of types JPG, JPEG, PNG, PDF, XLS, DOCX only.You can upload upto a maximum of 90MB per upload)'
                        }
                        onConfirm={closeUploadDialog}
                        setFileUploadOpen={setFileUploadOpen}
                      />
                    )} */}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Grid
                container
                spacing={1}
                sx={{
                  alignItems: 'center',
                  backgroundColor: '#f7f7f7',
                  display: 'flex',
                  padding: '12px',
                }}>
                <Grid
                  item
                  md={3}
                  sm={12}
                  xs={12}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    paddingLeft: 'unset !important',
                    paddingTop: 'unset !important',
                  }}>
                  <Typography className="f-15 font-bold pr-2">
                    Reminder &nbsp;
                  </Typography>

                  <Controller
                    control={control}
                    defaultValue={false}
                    name={'notify'}
                    render={({ field, formState }) => {
                      return (
                        <Checkbox sx={{ marginLeft: '26px' }} {...field} />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {watch('notify') ? (
              <>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ paddingTop: 'unset !important' }}>
                  <Grid container spacing={1}>
                    <Grid item md={3} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className="custom-field mb-0  d-flex-1">
                        <Typography className="f-15 font-bold pr-2">
                          Choose Email <span style={{ color: 'red' }}>*</span>
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item md={9} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className="custom-field mb-0  d-flex-1">
                        <Controller
                          control={control}
                          defaultValue={[]}
                          name="notifyPerson"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                closeMenuOnSelect={false}
                                labelKey={'label'}
                                valueKey={'value'}
                                options={teamMembers}
                                isMulti={true}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(
                                    e.target.value ? e.target.value : [],
                                  );
                                }}
                                placeHolder={'Select'}
                                className={'basic-multi-select'}
                                error={errors.notifyPerson?.message}
                                // tabIndex={1}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ paddingTop: 'unset !important' }}>
                  <Grid container spacing={1}>
                    <Grid item md={3} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className="custom-field mb-0  d-flex-1">
                        <Typography className="f-15 font-bold pr-2">
                          Due Date <span style={{ color: 'red' }}>*</span>
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item md={9} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className="custom-field mb-0  d-flex-1">
                        <Controller
                          control={control}
                          defaultValue=""
                          name="notifyDate"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomDatePicker
                                className="custom-input"
                                errors={errors.notifyDate?.message}
                                name={name}
                                value={value}
                                minDate={
                                  new Date().setDate(
                                    new Date().getDate() + 1,
                                  ) as any
                                }
                                onChangeDate={(e) => onChange(e)}
                                isReadOnlyInput={true}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ paddingTop: 'unset !important' }}>
                  <Grid container spacing={1}>
                    <Grid item md={3} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className="custom-field mb-0  d-flex-1">
                        <Typography className="f-15 font-bold pr-2">
                          Notes <span style={{ color: 'red' }}>*</span>
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item md={9} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className="custom-field mb-0  d-flex-1">
                        <Controller
                          control={control}
                          defaultValue=""
                          name="notifyNotes"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                className={'custom-textarea'}
                                placeHolder=""
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                isTextArea={true}
                                error={errors.notifyNotes?.message}
                                value={value}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>{' '}
              </>
            ) : (
              ''
            )}
          </Grid>
        </Typography>
      </DialogContent>{' '}
      <DialogActions>
        <Typography
          component={'div'}
          className="d-flex-jae"
          sx={{ padding: '0 1.5rem' }}>
          <Button
            className="n-add-btn"
            sx={{
              borderRadius: '5px !important',
              float: 'right',
              fontFamily: 'Arial-Regular !important',
              fontSize: '0.9em !important',
              height: '35px !important',
              margin: '15px',
              width: '149px !important',
            }}
            onClick={() => submitOn()}>
            Submit
          </Button>
        </Typography>
      </DialogActions>
    </React.Fragment>
  );
};
