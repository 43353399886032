import { yupResolver } from '@hookform/resolvers/yup';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { Loader } from 'components/loader';
import saveAs from 'file-saver';
// import _ from 'lodash';
import moment from 'moment';
// import Multiselect from 'multiselect-react-dropdown';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  // useRef,
  useState,
} from 'react';
import Dropzone from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData, getS3file } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { AddnotesTypes } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { addNoteMimeTypes, addNotesFileType, alertOptions } from 'utils/helper';
import { AddnotesSchema } from 'utils/ValidatorSchema';

import UploadIcon from '../../../../../assets/images/clupload.svg';
import DeleteIcon from '../../../../../assets/images/udelete.svg';

type Props = {
  notes: any;
  setNotes: any;
  type: any;
  showModel: any;
  showTable: any;
  notesAdded?: any;
  setNotesAdded?: any;
  notesRecall?: any;
  leadId?: any;
};
const AddNote: React.FC<any> = React.forwardRef((props: Props, ref) => {
  const {
    notes,
    setNotes,
    type,
    showModel,
    showTable,
    notesAdded,
    setNotesAdded,
    notesRecall,
    leadId,
  } = props;
  // const multiselectRef = React.createRef<any>();
  // const uploadRef = useRef<any>();
  const { editId, petitionId } = useParams();

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const { petitions } = useAppSelector((store: any) => store.petitionUser);
  const [leadList, setLeadList] = useState<any>([]);
  const [email, setEmail] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [visibleAttorney, setVisibleAttorney] = useState<boolean>(
    userDetail.role_id === 3 ? true : false,
  );
  const [leadInfo, setLeadInfo] = useState<any>({});

  const [visibleAffiliate, setVisibleAffiliate] = useState<boolean>(
    userDetail.role_id === 5 ? true : false,
  );
  const [visiblePetitioner, setVisiblePetitioner] = useState<boolean>(
    userDetail.role_id === 2 ? true : false,
  );
  const [visibleBeneficiary, setVisibleBeneficiary] = useState<boolean>(
    userDetail.role_id === 4 ? true : false,
  );
  // const [attorneySupportIds, setAttorneySupportIds] = useState<any>([]);

  const [file, setFile] = useState<any>(null);
  useImperativeHandle(ref, () => ({
    reCallNotesApi() {
      getNotes();
    },
  }));
  const uploadRef = useRef(null);

  const checkFileValidation = (file) => {
    const files = file[0]?.name.split('.').pop();
    if (
      ['xlsx', 'pdf', 'jpeg', 'png', 'xls', 'jpg'].includes(files) &&
      addNotesFileType?.includes(file[0].type)
    ) {
      if (file[0].size / 1024 / 1024 < 90) {
        setFile(file[0]);
      } else {
        uploadRef.current.value = '';
        toast.error(
          'File is too big, Please select a file less than 90 MB',
          alertOptions,
        );
        return;
      }
    } else {
      toast.error(
        'You can upload documents of types JPEG, JPG, PDF, XLS, XLSX, PNG.',
        alertOptions,
      );
    }
  };

  const {
    control: control,
    handleSubmit: handleSubmit,
    // reset: resetForm,
    watch,
    setValue,
    formState: { errors },
  } = useForm<AddnotesTypes>({
    criteriaMode: 'all',
    defaultValues: {
      contactType:
        type === 'petition' && userDetail.role_id === 2 ? 'others' : '',
      contactVisible:
        type === 'lead' &&
        (userDetail.role_id === 2 || userDetail.role_id === 4)
          ? true
          : false,
      note: '',
      sendMail: false,
      teamMembers: [],
      visibleTo: [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AddnotesSchema),
  });
  const getLeadInfo = useCallback(async () => {
    try {
      setLoader(true);
      const leadInfo = await getAllListData(
        `${COMMON_ENDPOINT.LeadList}?id=${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          editId
            ? String(editId)
            : petitions?.petitionData && petitions?.petitionData?.lead_id
            ? petitions?.petitionData?.lead_id
            : ''
        }`,
      );

      setLeadInfo(
        leadInfo && leadInfo.data && leadInfo.data[0] ? leadInfo.data[0] : {},
      );
      setLoader(false);
      return leadInfo;
    } catch (error) {
      setLoader(false);
      toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
  }, [editId, petitions.petitionData]);

  const clearData = () => {
    setValue('note', '');
    setValue('teamMembers', []);
    setValue('sendMail', false);
    setValue('contactType', null);
    setFile('');
    setVisibleAttorney(userDetail.role_id === 3 ? true : false);
    setVisibleAffiliate(userDetail.role_id === 5 ? true : false);
    setVisiblePetitioner(userDetail.role_id === 2 ? true : false);
    setVisibleBeneficiary(userDetail.role_id === 4 ? true : false);
    // resetForm();
  };
  const onSubmitForm = (data: any) => {
    setLoader(true);
    const visibleTo = [];
    if (visibleAttorney) {
      visibleTo.push(3);
    }
    if (visibleAffiliate) {
      visibleTo.push(5);
    }
    if (visiblePetitioner) {
      if (userDetail.role_id === 2 && type === 'lead') {
        visibleTo.push(2, 3);
      }
      if (userDetail.role_id === 2 && type !== 'lead') {
        visibleTo.push(2);
      }
      if (userDetail.role_id === 3) {
        visibleTo.push(2);
      }
    }
    if (visibleBeneficiary) {
      if (userDetail.role_id === 4 && type === 'lead') {
        visibleTo.push(2, 3, 4);
      } else {
        visibleTo.push(4);
      }
    }
    const formData = new FormData();
    formData.append('notes', data?.note);
    formData.append('file', file);
    formData.append('sendEmail', data?.sendMail);
    formData.append(
      'teamMembers',
      data?.teamMembers ? JSON.stringify(data?.teamMembers) : '',
    );
    formData.append('notesType', data?.contactType);
    formData.append('userId', '');
    formData.append('notesFrom', type === 'lead' ? 'lead' : 'petition');
    formData.append('visibleTo', JSON.stringify(visibleTo));

    addNewData(
      formData,
      `${String(COMMON_ENDPOINT.Contact)}/${String(
        leadId ? leadId : petitionId ? petitionId : editId,
      )}`,
    )
      .then((resp: any) => {
        clearData();
        getNotes();
        notesRecall && notesRecall();
        setNotesAdded(true);
        setNotes && setNotes({ leadOrPetitionId: '', show: false, type: '' });
        setLoader(false);
        // setLeadList((leadList) => [...leadList, resp]);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getNotes = useCallback(() => {
    getAllListData(
      `${String(COMMON_ENDPOINT.Contact)}/${String(
        leadId ? leadId : petitionId ? petitionId : editId,
      )}?type=${String(type)}`,
    )
      .then((resp: any) => {
        setLeadList(resp);
      })
      .catch((e: any) => {
        setLeadList([]);
      });
  }, [leadId, petitionId, editId, type]);

  const getTeamMembers = () => {
    getAllListData(
      `${String(COMMON_ENDPOINT.Email)}/${String(
        leadId ? leadId : petitionId ? petitionId : editId,
      )}?type=${String(type)}`,
    ).then((resp: any) => {
      const emailList = [];
      resp.map((row: any) => {
        emailList.push({ label: row, value: row });
      });
      setEmail(emailList);
      // const filterSupprotValues =
      //   resp &&
      //   resp.length &&
      //   watch('teamMembers') &&
      //   watch('teamMembers').length
      //     ? resp.filter((e) => watch('teamMembers').includes(e.id))
      //     : [];

      // setAttorneySupportIds(filterSupprotValues);
    });
  };

  useEffect(() => {
    if (notesAdded) {
      getNotes();
      setNotesAdded(false);
      getTeamMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getNotes();
    getTeamMembers();
    getLeadInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId]);

  const openFile = (file) => {
    const obj = {
      key: file,
    };

    const fileName = file.split('/').pop();
    // setLoader(true);
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(`${String(fileName)} File Downloaded`, alertOptions);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };

  const option = [
    {
      label: 'Request Contract',
      value: 'requestContract',
    },
    {
      label: 'Request Invoice',
      value: 'requestInvoice',
    },
    {
      label: 'Request PaymentLink',
      value: 'requestPaymentLink',
    },
    {
      label: 'Other',
      value: 'others',
    },
  ];
  const onDrop = useCallback((acceptedFiles) => {
    checkFileValidation(acceptedFiles);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);
  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Grid>
      {showModel ? (
        <Modal
          open={notes?.show}
          onClose={() =>
            setNotes({ leadOrPetitionId: '', show: false, type: '' })
          }
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className="custom-modal-note">
            <Typography
              component={'div'}
              className="f-20 font-bold modal-title">
              {userDetail.role_id === 2 ? 'Contact Attorney' : 'Add Notes'}
            </Typography>
            <form id={'addform'} onSubmit={handleSubmit(onSubmitForm)}>
              {type !== 'petition' &&
              (userDetail.role_id === 2 || userDetail.role_id === 4) ? (
                <Typography component={'div'} className="d-flex-a m-border">
                  <Typography className="f-15">
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Contact Type <span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                  </Typography>
                  <Typography
                    component={'div'}
                    className="d-flex-a"
                    style={{ width: '150px' }}>
                    <Typography component={'div'} className="pl-2">
                      <Typography
                        component={'div'}
                        className="custom-field mb-0"
                        sx={{
                          marginBottom: 'unset !important',
                          padding: '.7rem 0rem 0  1rem',
                          width: '200px',
                        }}>
                        <Controller
                          control={control}
                          name={'contactType'}
                          defaultValue={''}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                valueKey="value"
                                labelKey="label"
                                value={value}
                                options={option}
                                name={name}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                error={errors.contactType?.message}
                                searchable={true}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              ) : (
                <Typography component={'div'} className="d-flex-a m-border">
                  <Typography className="f-15">
                    {' '}
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Visible to <span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                  </Typography>
                  <Typography component={'div'} className="d-flex-a">
                    <Typography component={'div'} className="pl-2">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={visibleAttorney}
                              disabled={userDetail.role_id === 3 ? true : false}
                              onChange={(e) => {
                                setVisibleAttorney(e.target.checked);
                              }}
                            />
                          }
                          label="Attorney"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={visiblePetitioner}
                              disabled={userDetail.role_id === 2 ? true : false}
                              onChange={(e) => {
                                setVisiblePetitioner(e.target.checked);
                              }}
                            />
                          }
                          label="Petitioner"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={visibleBeneficiary}
                              disabled={userDetail.role_id === 4 ? true : false}
                              onChange={(e) => {
                                setVisibleBeneficiary(e.target.checked);
                              }}
                            />
                          }
                          label="Beneficiary"
                        />
                        {(type === 'lead' &&
                          leadInfo?.isAffilateCase &&
                          leadInfo?.affilateUserList?.length > 0 &&
                          userDetail.role_id !== 5) ||
                        (type === 'petition' &&
                          petitions?.leadList?.isAffilateCase &&
                          petitions?.leadList?.affilateUserList?.length > 0) ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={visibleAffiliate}
                                disabled={
                                  userDetail.role_id === 5 ? true : false
                                }
                                onChange={(e) => {
                                  setVisibleAffiliate(e.target.checked);
                                }}
                              />
                            }
                            label="Affiliate"
                          />
                        ) : (
                          ''
                        )}
                      </FormGroup>
                    </Typography>
                  </Typography>
                </Typography>
              )}
              <Typography
                component={'div'}
                className="mb-0 m-border"
                sx={{
                  marginBottom: '0px !important',
                  padding: '1.5rem 2rem 0  2rem',
                }}>
                {!(
                  type === 'lead' &&
                  (userDetail.role_id === 2 || userDetail.role_id === 4)
                ) ? (
                  <Typography
                    sx={{
                      margin: '0px 0 10px !important',
                    }}
                    className="custom-label">
                    Notes <span style={{ color: 'red' }}>*</span>
                  </Typography>
                ) : (
                  <Typography className="custom-label">Notes</Typography>
                )}
                <Controller
                  control={control}
                  name="note"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <CustomInput
                        placeHolder={'Notes'}
                        name={name}
                        value={value}
                        className={'custom-textarea-note'}
                        error={errors.note?.message || ''}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        isTextArea={true}
                      />
                    );
                  }}
                />
              </Typography>
              {/* <span className="pl-2">
                
              </span> */}
              <Typography component={'div'} className="d-flex-b m-border">
                <Typography
                  className="f-15 font-bold"
                  sx={{ display: 'contents !important' }}>
                  Attachment
                  <span>
                    <Tooltip
                      title="(You can upload documents of types JPEG, JPG, PDF, XLS, XLSX, PNG)"
                      placement="top-start">
                      <InfoRoundedIcon
                        style={{
                          cursor: 'pointer',
                          fill: 'rgb(14 106 234)',
                          marginLeft: '5px',
                        }}
                      />
                    </Tooltip>
                  </span>
                </Typography>

                <Typography
                  component={'div'}
                  className="pl-2 d-flex flex-column">
                  {/* <Button
                    className="browse-btn"
                    sx={{
                      backgroundColor: 'lightgray',
                      color: '#000 !important',
                      height: '35px',
                      minWidth: '110px !important',
                      textTransform: 'capitalize !important',
                    }}
                    onClick={() => {
                      setFileUploadOpen({ open: true });
                    }}>
                    Browse
                  </Button> */}
                  <Typography
                    component={'div'}
                    className="upload-drag-drop d-flex-ja pointer">
                    <Dropzone onDrop={onDrop}>
                      {({ getRootProps }) => {
                        return (
                          <>
                            <img src={UploadIcon} alt="icon" />
                            <Typography
                              {...getRootProps({
                                className: 'dropzone',
                                onClick: (event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  uploadRef.current?.click();
                                },
                              })}
                            />
                          </>
                        );
                      }}
                    </Dropzone>
                    <Typography className="f-16">
                      Drag and drop file here, or click to select file
                    </Typography>
                  </Typography>

                  <input
                    type="file"
                    hidden
                    ref={uploadRef}
                    onChange={(e) => checkFileValidation(e.target.files)}
                    accept={addNoteMimeTypes}
                  />
                  <Typography sx={{ marginTop: '10px' }}>
                    <form className="d-flex-a">
                      <Typography
                        component={'div'}
                        className="custom-field mb-0  d-flex-1"
                        sx={{ marginBottom: 'unset !important' }}>
                        <CustomInput
                          className="custom-input"
                          disabled={true}
                          value={file && file.name}
                          inputProps={{
                            endAdornment: file && (
                              <InputAdornment position="end">
                                <Tooltip title={'Remove'} placement={'top'}>
                                  <img
                                    src={DeleteIcon}
                                    alt="deleteIcon"
                                    style={{
                                      cursor: 'pointer',
                                      width: '28px',
                                    }}
                                    onClick={() => {
                                      uploadRef.current.value = '';
                                      setFile('');
                                    }}
                                  />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Typography>
                    </form>
                  </Typography>
                  {/* <div className="d-flex-b">
                    <span
                      className="f-14 line-20 "
                      style={{ paddingLeft: '5px' }}>
                      {file?.name}
                    </span>
                    {file?.name ? (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setFile('');
                        }}>
                        <Tooltip title="Remove" placement="top">
                          <DeleteForeverSharpIcon style={{ fill: 'red' }} />
                        </Tooltip>
                      </IconButton>
                    ) : (
                      ''
                    )}
                  </div> */}
                </Typography>
                {/* {fileUploadOpen.open && (
                  <FileUploadDialog
                    open={fileUploadOpen.open}
                    allowedFileType={addNotesFileType}
                    errorMessage={
                      'You can upload documents of types JPEG, PDF, XLS, XLSX, PNG only'
                    }
                    info={
                      '(You can upload documents of types JPEG, PDF, XLS, XLSX, PNG only.You can upload upto a maximum of 90MB per upload)'
                    }
                    onConfirm={closeUploadDialog}
                    setFileUploadOpen={setFileUploadOpen}
                  />
                )} */}
              </Typography>
              <Typography component={'div'} className="d-flex-a m-border">
                <Typography className="f-15 font-bold">Send Email</Typography>
                <Typography
                  component={'div'}
                  className="d-flex-a"
                  sx={{ flex: 1 }}>
                  <Typography component={'div'} className="pl-2">
                    <Controller
                      control={control}
                      defaultValue={false}
                      name={'sendMail'}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <Checkbox
                            checked={value}
                            onChange={onChange}
                            name={name}
                            value={value}
                          />
                        );
                      }}
                    />
                  </Typography>
                  {watch('sendMail') && (
                    <Typography
                      component={'div'}
                      className="custom-field mb-0"
                      sx={{
                        marginBottom: 'unset !important',
                        padding: '.7rem 0rem 0  1rem',
                        width: '88%',
                      }}>
                      <Controller
                        control={control}
                        // defaultValue={[]}
                        name={'teamMembers'}
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              closeMenuOnSelect={false}
                              labelKey={'label'}
                              valueKey={'value'}
                              options={email}
                              isMulti={true}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              placeHolder={'Select'}
                              className={'basic-multi-select'}
                              error={errors.teamMembers?.message}
                              // disabled={allowAccess}
                            />
                            // <Multiselect
                            //   // valueKey="value"
                            //   // value={value}
                            //   {...field}
                            //   displayValue="label"
                            //   // options={email}
                            //   placeholder={
                            //     watch('teamMembers').length === 0
                            //       ? 'Choose Recipients'
                            //       : ''
                            //   }
                            //   className={'custom-input'}
                            //   onSelect={(selected, item) => {
                            //     setValue(
                            //       'teamMembers',
                            //       selected.map((support) => support.id),
                            //     );
                            //   }}
                            //   onRemove={(selected, item) => {
                            //     setValue(
                            //       'teamMembers',
                            //       selected.map((support) => support.id),
                            //     );
                            //   }}
                            //   options={!_.isEmpty(email) ? email : []}
                            //   ref={multiselectRef}
                            //   // selectedValues={}
                            // />
                          );
                        }}
                      />
                    </Typography>
                  )}
                </Typography>
              </Typography>
              <Typography
                component={'div'}
                sx={{ margin: '1rem 0' }}
                className="d-flex-jae modal-footer">
                <Button
                  className="mcancel-btn f-18 font-bold mr-1"
                  onClick={() =>
                    setNotes({ leadOrPetitionId: '', show: false, type: '' })
                  }>
                  Cancel
                </Button>
                <Button
                  className="madd-btn f-18 font-bold"
                  type="submit"
                  form="addform">
                  Add
                </Button>
              </Typography>
            </form>
            {showTable && (
              <Typography
                sx={{
                  borderTop: '1px solid lightgrey',
                  padding: '1.5rem 20px 1rem  20px',
                }}>
                <table
                  className="table-hover lead-table"
                  style={{
                    display: 'block',
                    height: '30vh',
                    overflow: 'auto',
                  }}>
                  <thead>
                    <tr>
                      <th>ATTACHMENT </th>
                      <th>CONTACT TYPE </th>
                      <th>NOTES</th>
                      <th>ADDED BY</th>
                      <th>ADDED ON</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leadList.length > 0 ? (
                      leadList
                        ?.filter((e) =>
                          e.visible_to?.includes(userDetail.role_id),
                        )
                        .map((data: any, index) => {
                          return (
                            <tr key={index} style={{ cursor: 'pointer' }}>
                              <td>
                                {data.key ? (
                                  <Button
                                    className="n-add-btn"
                                    sx={{
                                      borderRadius: '5px !important',
                                      height: '30px !important',
                                      minWidth: '90px !important',
                                    }}
                                    onClick={() => openFile(data.key)}>
                                    Download
                                  </Button>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td
                                className={
                                  data.notes_type ? '' : 'text-center'
                                }>
                                {data.notes_type ? data.notes_type : '-'}
                              </td>
                              <td>{data.notes}</td>
                              <td>{data.answerAddedByUserName}</td>
                              <td>
                                {moment(data.created_at).format(
                                  'DD-MM-yyyy hh:mm A',
                                )}
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <tr className={'text-center'}>
                        <td colSpan={5} align={'center'}>
                          No Record(s) Found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Typography>
            )}
          </Box>
        </Modal>
      ) : (
        <Box sx={{ padding: '20px' }}>
          <Typography>
            <table className="lead-table table-hover table-scroll">
              <thead>
                <tr>
                  <th>ATTACHMENT </th>
                  <th>CONTACT TYPE </th>
                  <th>NOTES</th>
                  <th>ADDED BY</th>
                  <th>ADDED ON</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <Loader />
                ) : leadList.length > 0 ? (
                  leadList
                    // ?.filter((e) => e.visible_to?.includes(userDetail.role_id))
                    .map((data: any, index) => {
                      return (
                        <tr key={index} style={{ cursor: 'pointer' }}>
                          <td>
                            {data.key ? (
                              <Button
                                variant="contained"
                                onClick={() => openFile(data.key)}
                                style={{
                                  borderRadius: '20px',
                                  marginRight: '10px',
                                }}>
                                Download
                              </Button>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>{data.notes_type ? data.notes_type : '-'}</td>
                          <td>{data.notes ? data.notes : '-'}</td>
                          <td>
                            {data.answerAddedByUserName
                              ? data.answerAddedByUserName
                              : '-'}
                          </td>
                          <td>
                            {data.created_at
                              ? moment(data.created_at).format(
                                  'DD-MM-yyyy hh:mm A',
                                )
                              : '-'}
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr className={'text-center'}>
                    <td colSpan={5} align={'center'}>
                      No Record(s) Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Typography>
        </Box>
      )}
    </Grid>
  );
});

export default AddNote;
