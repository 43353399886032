import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';

import ContractList from '../../Common/ContractList';

export const ContractPayment: React.FC<any> = () => {
  const { editId } = useParams();

  const [loader, setLoader] = useState(true);
  const [leadContractPerson, setLeadContractPerson] = useState<any>([]);

  useEffect(() => {
    getAllListData(`${COMMON_ENDPOINT.Contract}/${String(editId)}`)
      .then((contractResp: any) => {
        setLeadContractPerson(contractResp);
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  }, [editId]);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sx={{ paddingRight: '1rem' }}>
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <ContractList
            contractList={leadContractPerson}
            isLead={true}
            loader={loader}
            setLoader={setLoader}
            editId={editId}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ContractPayment;
