import { Button, Grid, Typography } from '@mui/material';
import Right from 'assets/images/right.svg';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import saveAs from 'file-saver';
import _ from 'lodash';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getS3file } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

export const ContractList: React.FC<any> = ({
  contractList,
  isLead,
  loader,
  setLoader,
  editId,
}) => {
  const navigate: any = useNavigate();

  const [searchParams] = useSearchParams();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const fileDownload = (contract: any) => {
    let file = '';
    if (contract['petitionerdocumentkey']) {
      file = contract.petitionerdocumentkey;
    } else if (contract['benificierydocumentkey']) {
      file = contract.benificierydocumentkey;
    }
    if (!_.isEmpty(file)) {
      const obj = {
        key: file,
      };
      const fileName = file ? file.split('/').pop() : 'download';
      setLoader(true);
      getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
        .then((data: any) => {
          setLoader(false);
          saveAs(data, fileName);
          toast.success(
            fileName + 'File downloaded successfully',
            alertOptions,
          );
        })
        .catch((err) => {
          setLoader(false);
          toast.error('Unable to read the file.', alertOptions);
        });
    } else {
      toast.error('Unable to read the file.', alertOptions);
    }
  };

  return (
    <Typography
      component={'div'}
      className="c-p-box"
      sx={{ minHeight: '195px', padding: '40px' }}>
      {contractList.length > 0 ? (
        contractList.map((contract, index) => (
          <Typography
            key={index}
            component={'div'}
            sx={{ padding: '0.5rem 2rem !important' }}
            className="c-p-item d-flex-a"
            onClick={() => {
              if (
                contract?.contactType !== 'Affilate' &&
                (userDetail.role_id === 3 ||
                  userDetail.role_id === 2 ||
                  userDetail.role_id === 5)
              ) {
                userDetail.role_id === 2
                  ? navigate(
                      `${String(
                        PetitionerPrivateRoutes.LEADS.CONTRACTPAYMENTDETAILS,
                      )}/${String(
                        isLead
                          ? editId
                          : contract.contactType.toLowerCase() === 'petitioner'
                          ? contract.id
                          : contract.lead_id,
                      )}/${String(contract.id)}/${String(
                        contract.contactType.toLowerCase() === 'petitioner'
                          ? contract.petitionerId
                          : contract.user_id,
                      )}/${String(contract.contactType)}`,
                      {
                        state: {
                          contractName: contract.name,
                          id: editId,
                          isLead: isLead,
                          isOrg: searchParams.get('orgId') ? true : false,
                        },
                      },
                    )
                  : userDetail.role_id === 5
                  ? navigate(
                      `${String(
                        AffilatePrivateRoutes.ATTORNEY_LEADS
                          .CONTRACTPAYMENTDETAILS,
                      )}/${String(
                        isLead
                          ? editId
                          : contract.contactType.toLowerCase() === 'petitioner'
                          ? contract.id
                          : contract.lead_id,
                      )}/${String(contract.id)}/${String(
                        contract.contactType.toLowerCase() === 'petitioner'
                          ? contract.petitionerId
                          : contract.user_id,
                      )}/${String(contract.contactType)}`,
                      {
                        state: {
                          contractName: contract.name,
                          id: editId,
                          isLead: isLead,
                          isOrg: searchParams.get('orgId') ? true : false,
                        },
                      },
                    )
                  : navigate(
                      `${String(
                        AttorneyPrivateRoutes.LEADS.CONTRACTPAYMENTDETAILS,
                      )}/${String(
                        isLead
                          ? editId
                          : contract.contactType.toLowerCase() === 'petitioner'
                          ? contract.id
                          : contract.lead_id,
                      )}/${String(contract.id)}/${String(
                        contract.contactType.toLowerCase() === 'petitioner'
                          ? contract.petitionerId
                          : contract.user_id,
                      )}/${String(contract.contactType)}${
                        searchParams.get('orgId')
                          ? '?orgId=' + searchParams.get('orgId')
                          : ''
                      }${
                        searchParams.get('invited')
                          ? '&invited=true'
                          : searchParams.get('associated')
                          ? '&associated=true'
                          : ''
                      }${
                        searchParams.get('isLead')
                          ? '&isLead=true'
                          : searchParams.get('isPetition')
                          ? '&isPetition=true'
                          : ''
                      }`,
                      {
                        state: {
                          contractName: contract.name,
                          id: editId,
                          isLead: isLead,
                          isOrg: searchParams.get('orgId') ? true : false,
                        },
                      },
                    );
              }
            }}>
            <Grid container className="pointer">
              <Grid item md={2} sm={3} className="d-flex-a">
                <Typography
                  className="font-bold"
                  sx={{ textTransform: 'capitalize' }}>
                  {contract?.contactType === 'Benificiery'
                    ? 'beneficiary'
                    : contract?.contactType === 'Affilate'
                    ? 'Affiliate'
                    : contract?.contactType}
                </Typography>
              </Grid>
              <Grid item md={6} sm={6} className="d-flex-a">
                <Typography className="c-blue">{contract?.name}</Typography>
              </Grid>
              <Grid item md={2} sm={2} className="d-flex-a">
                <Typography textTransform={'capitalize'}>
                  {contract?.status}
                </Typography>
              </Grid>
              <Grid item md={2} sm={1} className="d-flex-jae">
                {isLead && contract?.contactType !== 'Affilate' && (
                  <Typography className="text-right">
                    <img src={Right} alt="arrow" />
                  </Typography>
                )}
                {!isLead && !['approved', 'signed'].includes(contract.status) && (
                  <Typography
                    component={'span'}
                    sx={{
                      color: 'red !important',
                      fontFamily: 'Arial-Bold !important',
                      paddingRight: '.5rem',
                    }}>
                    Pending
                  </Typography>
                )}
                {!isLead && ['approved', 'signed'].includes(contract.status) && (
                  <Typography>
                    <Button
                      className="n-add-btn"
                      sx={{
                        fontFamily: 'Arial-Regular !important',
                        height: '35px !important',
                        minWidth: '90px !important',
                      }}
                      color="primary"
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        fileDownload(contract);
                      }}>
                      Download
                    </Button>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Typography>
          // </NavLink>
        ))
      ) : (
        <Typography component={'div'} className="c-p-item d-flex-a">
          <Grid container>
            <Grid item md={12} className="d-flex-a content-center">
              <Typography className="font-bold">No Record(s) Found.</Typography>
            </Grid>
          </Grid>
        </Typography>
      )}
    </Typography>
  );
};

export default ContractList;
