import React from 'react';

export const PetitionerRequestAdditionalTableHeading: React.FC<any> =
  React.memo(() => {
    return (
      <thead>
        <tr>
          <th>DOCUMENT NAME </th>
          <th>RESPONSIBILITY </th>
          <th>ACTION</th>
        </tr>
      </thead>
    );
  });
export default PetitionerRequestAdditionalTableHeading;
