/* eslint-disable @typescript-eslint/restrict-plus-operands */
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Addc from 'assets/images/add-circle.svg';
import Comments from 'assets/images/comments.svg';
import Groupm from 'assets/images/Groupm.svg';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomCheckBox from 'components/CustomCheckBox/customCheckBox';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomTextArea from 'components/CustomTextArea/customTextArea';
import { urlify } from 'helpers';
import { instantState } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { Fill_FORM_ENDPOINT } from 'utils/Constant';
import { alertOptions, isJSON, jsonParseValue } from 'utils/helper';

type Props = {
  hcData: any;
  setHCData: any;
  petitionID?: any;
  wizardRef?: any;
  tabName?: string;
  screenName?: string;
  type?: string;
  formId?: any;
  leadId?: any;
  beneficiaryId?: any;
  affilateUserList?: any;
  organization?: boolean;
};

export const HistoryComments: React.FC<any> = (props: Props) => {
  const {
    hcData,
    setHCData,
    petitionID = '',
    wizardRef,
    tabName,
    screenName,
    type,
    formId,
    leadId,
    beneficiaryId,
    affilateUserList,
    organization,
  } = props;
  const [searchParams] = useSearchParams();
  const [expand, setExpand] = useState(0);
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [visibleToError, setVisibleToError] = useState(false);
  const [commentError, setCommentError] = useState(false);

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const [formData, setFormData] = useState<any>({
    comments: '',
    petitionId: petitionID !== '' ? petitionID : '',
    showToAll: true,
    viewBy: [],
  });
  const [deleteDialog, setDeleteDialog] = useState({
    id: '',
    show: false,
  });
  const questionHints = [
    {
      key: 'notes',
      lable: 'Description',
    },
    {
      key: 'hint',
      lable: 'Hint',
    },
    {
      key: 'example',
      lable: 'Example',
    },
    {
      key: 'winning_strategy_note',
      lable: 'Winning Strategy',
    },
    {
      icon: ErrorOutlineSharpIcon,
      key: 'issues_language',
      lable: 'Issue/Language',
    },
    {
      icon: ErrorOutlineSharpIcon,
      key: 'issues_number',
      lable: 'Issue/Number',
    },
  ];

  const handleHistoryModalOpen = () => {
    setOpenHistoryModal(true);
  };

  const handleHistoryModelClose = () => {
    setOpenHistoryModal(false);
  };
  const handleCommentModalOpen = () => {
    setOpenCommentModal(true);
  };
  const handleCommentModelClose = () => {
    setOpenCommentModal(false);
  };
  const toggleAccordion = (qIndex) => {
    setExpand(qIndex === expand ? -1 : qIndex);
  };
  const handleModalOpen = async () => {
    const getHCData = await instantState(setHCData);

    if (
      getHCData.selectedQuestion &&
      !getHCData.selectedQuestion.formAnswerId
    ) {
      if (wizardRef && wizardRef.current) {
        try {
          setHCData((pre) => ({
            ...pre,
            loading: true,
          }));
          const checking = await wizardRef.current.dummyMethod();
          setHCData((pre) => ({
            ...pre,
            loading: false,
          }));
          if (checking?.history?.form_answer_id) {
            handleModalOpen();
          }
        } catch (error) {
          setHCData((pre) => ({
            ...pre,
            loading: false,
          }));
        }
      }
      return;
    }

    setFormData((pre) => ({
      ...pre,
      beneficiaryId,
      comments: '',
      formId,
      leadId,
      petitionId: petitionID ? petitionID : petitions?.petitionData?.id,
      screen: screenName ? screenName : '',
      showToAll: true,
      tab:
        tabName !== ''
          ? tabName === '1'
            ? 'beneficiary'
            : tabName === '2'
            ? 'petitioner'
            : 'dependent'
          : null,
      type,
      viewBy: [String(userDetail.role_id)],
    }));
    setOpenModal(true);
  };

  const handlemodelClose = () => {
    setOpenModal(false);
    if (!formData.comments) setCommentError(false);
    if (formData?.viewBy?.length === 0) setVisibleToError(false);
  };

  const handleAddFormChange = (e) => {
    const { name, value, checked } = e.target;
    if (typeof checked !== 'undefined') {
      const tempChecked = formData.viewBy;
      if (checked) {
        tempChecked.push(value);
      } else {
        const idx = tempChecked.findIndex((id) => Number(value) === Number(id));
        tempChecked.splice(idx, 1);
      }
      setFormData((pre) => ({
        ...pre,
        viewBy: tempChecked,
      }));
      if (tempChecked?.length === 0) {
        setVisibleToError(true);
      } else {
        setVisibleToError(false);
      }
    } else {
      setFormData((pre) => ({
        ...pre,
        [name]: value,
      }));
      if (!value) {
        setCommentError(true);
      } else {
        setCommentError(false);
      }
    }
  };

  const handleAddCommentsSubmit = () => {
    if (formData?.viewBy?.length > 0 && formData.comments) {
      addNewData(
        formData,
        `${Fill_FORM_ENDPOINT}/comments/${String(
          hcData.selectedQuestion?.formAnswerId,
        )}`,
      )
        .then((data) => {
          getAllListData(
            `${Fill_FORM_ENDPOINT}/comments/${String(
              hcData.selectedQuestion?.formAnswerId,
            )}`,
          )
            .then((cmdResp: any) => {
              cmdResp.slice(0, 3);
              setHCData((pre) => ({ ...pre, comments: cmdResp }));
            })
            .catch((e: any) => {});
          toast.success('Added successfully');
          handlemodelClose();
        })
        .catch((err) => {
          toast.error(err, alertOptions);
        });
    } else {
      if (!formData.comments) setCommentError(true);
      if (formData?.viewBy?.length === 0) setVisibleToError(true);
    }
  };

  const handleDeleteComment = (action: string) => {
    if (action !== 'yes') {
      setDeleteDialog((pre) => ({
        ...pre,
        show: false,
      }));
      return;
    }
    deleteData(deleteDialog.id, `${Fill_FORM_ENDPOINT}/comments`)
      .then(() => {
        toast.success('Deleted successfully', alertOptions);
        const idx = hcData.comments.findIndex(
          (value) => Number(value?.id) === Number(deleteDialog.id),
        );
        setDeleteDialog((pre) => ({
          ...pre,
          show: false,
        }));
        if (idx !== -1) {
          hcData.comments.splice(idx, 1);
          setHCData((pre) => ({ ...pre, comments: [...hcData.comments] }));
        }
      })
      .catch((err) => {
        toast.error(err, alertOptions);
      });
  };
  useEffect(() => {
    if (hcData.selectedQuestion.id !== hcData.selectedQuestion.id) {
      setExpand(0);
    }
  }, [hcData]);

  return (
    <>
      {!_.isEmpty(hcData.selectedQuestion) &&
        hcData.selectedQuestion?.webUrlLInk && (
          <Typography component={'div'} className={'add-inner mb-1 mt-1'}>
            <Typography component={'div'} className="add-inner-box" />

            <Typography className="link-txt f-14 pl-2 d-flex-a mt-1 pd ">
              <Typography className="link-txt mt-1" component={'span'}>
                <InfoIcon fontSize="small" className="mr-1" />
              </Typography>

              <Tooltip
                title={hcData.selectedQuestion?.webUrlLInk}
                placement="top">
                <Typography
                  className={'  f-14 mt-1 '}
                  sx={{
                    color: '#ffffff',
                    fontFamily: 'Arial-Bold !important',
                    paddingRight: '1rem !important',
                  }}
                  {...(hcData.selectedQuestion?.webUrlLInk
                    ? {
                        component: 'a',
                        href: hcData.selectedQuestion?.webUrlLInk,
                        target: '_blank',
                      }
                    : { component: 'span' })}>
                  Reference link
                </Typography>
              </Tooltip>
            </Typography>
            {/* <Typography
                className=" bu-color f-16 pb-2 pt-1 pl-2 l-y"
                sx={{
                  paddingLeft: '50px !important',
                  paddingRight: '10px !important',
                }}>
                {String(hcData.selectedQuestion?.webUrlLInk)}
              </Typography> */}
          </Typography>
        )}

      {!_.isEmpty(hcData.selectedQuestion) &&
        (hcData.selectedQuestion?.webUrlLInk ||
          questionHints.findIndex(
            (item) => hcData.selectedQuestion[item.key],
          ) !== -1) && (
          <Typography component={'div'} className={'add-inner mb-1 mt-1'}>
            <Typography component={'div'} className="add-inner-box" />
            <Typography component={'div'} className="commentAcc">
              {questionHints.map((qHints, qIndex) => (
                <Typography key={qIndex}>
                  {hcData.selectedQuestion[qHints.key] &&
                    hcData.selectedQuestion[qHints.key] !== '' && (
                      <Accordion expanded={expand === qIndex ? true : false}>
                        <AccordionSummary
                          onClick={(e) => toggleAccordion(qIndex)}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${String(qIndex)}a-content`}
                          id={`panel${String(qIndex)}a-header`}>
                          {qHints?.icon ? (
                            <Typography className="d-flex-ja">
                              <Typography>{qHints.lable}</Typography>
                              <Typography
                                sx={{
                                  marginBottom: '-4px',
                                  marginLeft: '4px',
                                }}>
                                <ErrorOutlineSharpIcon sx={{ fill: 'red' }} />
                              </Typography>
                            </Typography>
                          ) : (
                            <Typography>{qHints.lable}</Typography>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            className="inner-desc-link"
                            sx={{ overflowWrap: 'break-word' }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: urlify(
                                  hcData.selectedQuestion[qHints.key],
                                ),
                              }}
                            />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    )}
                </Typography>
              ))}
            </Typography>
          </Typography>
        )}

      {!_.isEmpty(hcData.selectedQuestion) &&
        (!searchParams.get('leadUser') ||
          searchParams.get('type') === 'petition') && (
          <Typography component={'div'} className={'add-inner mb-1 mt-1'}>
            <Typography component={'div'} className="add-inner-box" />
            <Typography>
              <Typography
                component={'div'}
                className={' d-flex-a pt-2 pb-2'.concat(
                  hcData.history && hcData.history.length > 3 ? '' : '  ml-2',
                )}
                sx={{
                  ...(hcData.history && hcData.history.length > 3
                    ? { justifyContent: 'space-evenly' }
                    : {}),
                }}>
                <Typography
                  component={'div'}
                  className="bu-color"
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  <img src={Groupm} alt="" className="mr-1" />
                  Change History
                </Typography>
                {hcData.history && hcData.history.length > 3 ? (
                  <Typography
                    component={'div'}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    className="bu-color"
                    onClick={handleHistoryModalOpen}>
                    See All
                  </Typography>
                ) : (
                  ''
                )}
              </Typography>

              <Divider
                sx={{
                  borderColor: '#4c4c4c',
                  borderWidth: '1px',
                }}
              />
              <List sx={{ width: '100%' }}>
                {hcData.loading ? (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{
                        color: '#ffffff',
                      }}
                    />
                  </div>
                ) : hcData.history?.length === 0 ? (
                  <Typography component={'div'} className="custom-field">
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={
                          <Typography className="f-15 mb-1 font-bold bu-color">
                            No History Found
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Typography>
                ) : (
                  hcData.history?.slice(0, 3)?.map((history, historyIndex) => (
                    <Typography
                      component={'div'}
                      className="custom-field"
                      key={historyIndex}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={
                            <Typography
                              component={'div'}
                              sx={{
                                color: '#ffff',
                              }}
                              className="f-15 mb-1 font-bold">
                              {isJSON(history?.answer)
                                ? jsonParseValue(history?.answer)
                                : history?.answer || '-'}
                            </Typography>
                          }
                          secondary={
                            <Typography component={'div'} className="f-15">
                              <Typography
                                sx={{
                                  display: 'inline',
                                  fontSize: '12px',
                                }}
                                component="span"
                                variant="body2"
                                className="mr-1 mb-1 bu-color">
                                {history?.answerAddedByUserName} -{' '}
                                {moment(history?.created_on).format(
                                  'DD MMM, YYYY - hh:mm A',
                                )}
                              </Typography>
                            </Typography>
                          }
                        />
                      </ListItem>
                      <Divider
                        className="mb-1"
                        sx={{
                          borderColor: '#4c4c4c',
                          borderWidth: '1px',
                        }}
                      />
                    </Typography>
                  ))
                )}
              </List>
            </Typography>
          </Typography>
        )}

      {!organization &&
        !_.isEmpty(hcData.selectedQuestion) &&
        (!searchParams.get('leadUser') ||
          searchParams.get('type') === 'petition') && (
          <Typography component={'div'} className={'add-inner mb-1 mt-1'}>
            <Typography component={'div'} className="add-inner-box" />
            <Typography>
              <Typography
                component={'div'}
                className={' d-flex-a pt-2 pb-2'}
                sx={{
                  justifyContent: 'space-evenly',
                }}>
                <Typography
                  component={'div'}
                  className="bu-color"
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  <img
                    src={Comments}
                    alt=""
                    className="mr-8"
                    style={{ width: '20px' }}
                  />
                  Comments
                </Typography>
                <Typography
                  className="d-flex-a bu-color"
                  component={'div'}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={handleModalOpen}>
                  <img
                    src={Addc}
                    alt=""
                    className="mr-8"
                    style={{ width: '18px' }}
                  />
                  <span>Add</span>
                </Typography>
                {hcData.comments && hcData.comments.length > 3 ? (
                  <Tooltip title={'See all previous comments'} placement="top">
                    <Typography
                      component={'div'}
                      sx={{
                        cursor: 'pointer',
                        paddingRight: '10px',
                        textDecoration: 'underline',
                      }}
                      className="bu-color"
                      onClick={handleCommentModalOpen}>
                      See All
                    </Typography>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Typography>
              <Divider
                sx={{
                  borderColor: '#4c4c4c',
                  borderWidth: '1px',
                }}
              />
              <List sx={{ width: '100%' }}>
                {hcData.loading ? (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{
                        color: '#ffffff',
                      }}
                    />
                  </div>
                ) : hcData.comments &&
                  hcData.comments?.slice(0, 3).length === 0 ? (
                  <Typography component={'div'} className="custom-field">
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={
                          <Typography className="f-15 font-bold bu-color">
                            No Comments Found
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </Typography>
                ) : (
                  hcData.comments &&
                  hcData.comments?.slice(0, 3)?.map((comment, commentIndex) => (
                    <Typography
                      component={'div'}
                      className="custom-field"
                      key={commentIndex}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={
                            <Typography
                              component={'div'}
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}>
                              <Typography
                                component={'div'}
                                className="f-15 mb-1 font-bold hideshow"
                                sx={{
                                  color: '#ffff',
                                  wordBreak: 'break-all',
                                }}>
                                {comment.comments}
                              </Typography>
                              {comment.deleteView ? (
                                <Typography
                                  component={'div'}
                                  className="text-right pointer bu-color pl-2"
                                  onClick={(e) =>
                                    setDeleteDialog((pre) => ({
                                      ...pre,
                                      id: comment.id,
                                      show: true,
                                    }))
                                  }>
                                  <Tooltip title={'Delete'} placement="top">
                                    <DeleteIcon color="error" />
                                  </Tooltip>
                                </Typography>
                              ) : (
                                '-'
                              )}
                            </Typography>
                          }
                          secondary={
                            <Typography component={'div'} className="f-15">
                              <Typography
                                sx={{ display: 'inline', fontSize: '12px' }}
                                component="div"
                                variant="body2"
                                className="mr-1 bu-color">
                                {comment.answerAddedByUserName} -{' '}
                                {moment(comment?.created_on).format(
                                  'DD MMM, YYYY - hh:mm A',
                                )}
                              </Typography>
                            </Typography>
                          }
                        />
                      </ListItem>
                      <Divider
                        sx={{
                          borderColor: '#4c4c4c',
                          borderWidth: '1px',
                        }}
                      />
                    </Typography>
                  ))
                )}
              </List>
            </Typography>
          </Typography>
        )}

      <CustomModalView
        open={openModal}
        close={handlemodelClose}
        fullWidth={true}
        maxWidth={'sm'}
        scroll={'paper'}
        title="Add Comments">
        <DialogContent
          sx={{
            padding: 0,
          }}>
          <Box
            className="u-add-in"
            component="form"
            sx={{
              backgroundColor: 'unset !important',
              border: 'unset !important',
              padding: 'unset !important',
            }}
            noValidate
            autoComplete="off">
            <Grid container columnSpacing={{ md: 0, sm: 2, xs: 1 }}>
              <Grid item md={12} sm={12}>
                <Typography component={'div'} className="custom-field u-pr-1">
                  <Typography component={'div'} className="pl-2">
                    <Typography component={'div'} className="custom-label">
                      Visible To{' '}
                      <Typography component={'span'} className="Mui-error">
                        *
                      </Typography>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <CustomCheckBox
                              value={'3'}
                              checked={
                                formData.viewBy.includes('3') ? true : false
                              }
                              onChange={
                                userDetail.role_id !== 3 && handleAddFormChange
                              }
                            />
                          }
                          label="Attorney"
                        />
                        <FormControlLabel
                          control={
                            <CustomCheckBox
                              value={'2'}
                              checked={
                                formData.viewBy.includes('2') ? true : false
                              }
                              onChange={
                                userDetail.role_id !== 2 && handleAddFormChange
                              }
                            />
                          }
                          label="Petitioner"
                        />
                        {['strategy', 'build-case'].includes(screenName) && (
                          <FormControlLabel
                            control={
                              <CustomCheckBox
                                value={'4'}
                                checked={
                                  formData.viewBy.includes('4') ? true : false
                                }
                                onChange={
                                  userDetail.role_id !== 4 &&
                                  handleAddFormChange
                                }
                              />
                            }
                            label="Beneficiary"
                          />
                        )}

                        {tabName !== '2' &&
                          screenName === 'complete-question' && (
                            <FormControlLabel
                              control={
                                <CustomCheckBox
                                  value={'4'}
                                  checked={
                                    formData.viewBy.includes('4') ? true : false
                                  }
                                  onChange={
                                    userDetail.role_id !== 4 &&
                                    handleAddFormChange
                                  }
                                />
                              }
                              label="Beneficiary"
                            />
                          )}

                        {affilateUserList?.length > 0 && (
                          <FormControlLabel
                            control={
                              <CustomCheckBox
                                value={'5'}
                                checked={
                                  formData.viewBy.includes('5') ? true : false
                                }
                                onChange={
                                  userDetail.role_id !== 5 &&
                                  handleAddFormChange
                                }
                              />
                            }
                            label="Affiliate"
                          />
                        )}
                      </FormGroup>
                      {visibleToError && (
                        <Typography
                          component={'span'}
                          className="Mui-error mt-1 f-14">
                          Select any one
                        </Typography>
                      )}
                    </Typography>

                    <Typography component={'div'} className="custom-label">
                      Comments{' '}
                      <Typography component={'span'} className="Mui-error">
                        *
                      </Typography>
                      <CustomTextArea
                        placeHolder={'Comments'}
                        name={'comments'}
                        extraStyle={{ marginTop: '12px', width: '100%' }}
                        value={formData.comments}
                        className="custom-textarea"
                        minRows={3}
                        onChange={handleAddFormChange}
                      />
                    </Typography>
                    {commentError && (
                      <Typography
                        component={'span'}
                        className="Mui-error mb-1 mt-1 f-14">
                        Comments is required
                      </Typography>
                    )}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid item md={12} sm={12} className="d-flex-jae mt-1 ">
            <Typography component={'div'} className="custom-field u-pr-1">
              <Button
                className="n-add-btn"
                sx={{
                  borderRadius: '5px !important',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '1.2em !important',
                  height: '35px !important',
                  width: '149px !important',
                }}
                onClick={handleAddCommentsSubmit}>
                Submit
              </Button>
            </Typography>
          </Grid>
        </DialogActions>
      </CustomModalView>
      <CustomModalView
        open={openHistoryModal}
        close={handleHistoryModelClose}
        fullWidth={true}
        maxWidth={'sm'}
        scroll={'paper'}
        title="History">
        <DialogContent>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th className="W-1">S.no</th>
                <th className="W-2">Answer</th>
                <th className="W-2">Added By</th>
                <th className="W-5">Created At</th>
              </tr>
            </thead>
            <tbody>
              {hcData.history && hcData.history.length > 0 ? (
                hcData.history.map((history, historyIndex) => (
                  <tr key={historyIndex}>
                    <td>{historyIndex + 1}</td>
                    <td>
                      {isJSON(history?.answer)
                        ? jsonParseValue(history?.answer)
                        : history?.answer || '-'}
                    </td>
                    <td>{history?.answerAddedByUserName || '-'}</td>
                    <td>
                      {moment(history?.created_on).format(
                        'DD MMM, YYYY hh:mm A',
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className={'text-center'}>
                  <td colSpan={4} align={'center'}>
                    No Comments Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
      </CustomModalView>
      <CustomModalView
        open={openCommentModal}
        close={handleCommentModelClose}
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        title="Comments">
        <DialogContent>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th className="W-4">Comments</th>
                <th className="W-1">Add By</th>
                <th className="W-4">Created At</th>
                <th className="W-1">Action</th>
              </tr>
            </thead>
            <tbody>
              {hcData.comments && hcData.comments.length > 0 ? (
                hcData.comments.map((comment, commentIndex) => (
                  <tr key={commentIndex}>
                    <td>
                      <div className="hideshow">
                        {isJSON(comment?.comments)
                          ? jsonParseValue(comment?.comments)
                          : comment?.comments || '-'}
                      </div>
                    </td>
                    <td>{comment?.answerAddedByUserName || '-'}</td>
                    <td>
                      {moment(comment?.created_on).format(
                        'DD MMM, YYYY hh:mm A',
                      ) || '-'}
                    </td>
                    <td>
                      {comment.deleteView ? (
                        <Typography
                          component={'div'}
                          className="pointer bu-color"
                          onClick={(e) =>
                            setDeleteDialog((pre) => ({
                              ...pre,
                              id: comment.id,
                              show: true,
                            }))
                          }>
                          <Tooltip title={'Delete'} placement="top">
                            <DeleteIcon sx={{ fill: 'red' }} />
                          </Tooltip>
                        </Typography>
                      ) : (
                        <Typography
                          component={'div'}
                          className="pointer bu-color ml-1">
                          -
                        </Typography>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className={'text-center'}>
                  <td colSpan={4} align={'center'}>
                    No Comments Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
      </CustomModalView>
      <ConfirmDialog
        title="DELETE"
        floatingBtnShow={true}
        open={deleteDialog.show}
        close={() => {
          setDeleteDialog((pre) => ({
            ...pre,
            show: false,
          }));
        }}
        onConfirm={handleDeleteComment}
        autoCloseDisable={true}>
        {'Are you sure do you want to delete?'}
      </ConfirmDialog>
    </>
  );
};

export default HistoryComments;
