// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, Typography } from '@mui/material';
import Credit from 'assets/images/creditcard.png';
import Logo from 'assets/images/liberty-logoheader.png';
import CustomInput from 'components/CustomInput/customInput';
import React from 'react';

const CardForm: React.FC = () => {
  return (
    <div>
      <Typography
        className=" black-white-text p-f pt-2"
        sx={{
          backgroundColor: '#000',
          height: '84px',
          position: 'sticky',
          top: '0',
        }}>
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <div className="innerBox payment-public" style={{ textAlign: 'left' }}>
        <div className="minmax-paybox">
          <form>
            <Grid container sx={{ paddingTop: '24px' }}>
              <Grid md={10}>
                <img
                  src={Credit}
                  alt="Credit"
                  style={{ paddingBottom: '59px' }}
                />
                <Typography component={'div'}>
                  <Box
                    component="form"
                    className="u-add-in"
                    sx={{
                      border: 'unset',
                      borderRadius: '12px !important',
                      marginLeft: '-24px',
                      padding: 'unset !important',
                    }}
                    noValidate
                    autoComplete="off">
                    <Grid container columnSpacing={{ md: 3, sm: 2 }}>
                      <Grid item md={6} sm={12}>
                        <Typography component={'div'} className="custom-field ">
                          <Typography
                            component={'div'}
                            className="custom-label ">
                            Card Holder Name
                          </Typography>
                          <CustomInput
                            placeHolder={'Name'}
                            name={'name'}
                            value={''}
                            className={'custom-input'}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label ">
                            Card Number
                          </Typography>
                          <CustomInput
                            placeHolder={'**** **** **** ****'}
                            name={'name'}
                            value={''}
                            className={'custom-input'}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <Typography component={'div'} className="custom-field ">
                          <Typography
                            component={'div'}
                            className="custom-label ">
                            Card Expiry Date
                          </Typography>
                          <CustomInput
                            placeHolder={'MM/YYYY'}
                            name={'name'}
                            value={''}
                            className={'custom-input'}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label ">
                            Card CVV
                          </Typography>
                          <CustomInput
                            placeHolder={'****'}
                            name={'name'}
                            value={''}
                            className={'custom-input'}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <Typography
                          component={'div'}
                          className="d-flex-a"
                          sx={{ paddingTop: '34px' }}>
                          <input type="checkbox" />
                          <p style={{ paddingLeft: '10px' }}>
                            I agree to the{' '}
                            <span
                              style={{
                                color: 'rgba(25, 82, 167, 1) !important',
                              }}>
                              Term of service
                            </span>{' '}
                            and{' '}
                            <span
                              style={{
                                color: 'rgba(25, 82, 167, 1) !important',
                              }}>
                              Privacy Policy
                            </span>
                          </p>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sm={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          paddingTop: '34px',
                        }}>
                        <Button className="pay-button">PAY</Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CardForm;
