import {
  // Box,
  Button,
  // Checkbox,
  // FormLabel,
  Grid,
  // Modal,
  Typography,
} from '@mui/material';
import Avator from 'assets/images/d-avator.svg';
import Clock from 'assets/images/fclock.svg';
import Fadd from 'assets/images/fhome.svg';
import StatusI from 'assets/images/status.svg';
// import Pagination from 'components/Pagination';
// import CustomSelect from 'components/CustomSelect/customSelect';
// import CustomTextArea from 'components/CustomTextArea/customTextArea';
import { BeneficiaryPrivateRoutes } from 'config';
import saveAs from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData, getS3file } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { fileApiUrl } from 'utils/Config';
import { COMMON_ENDPOINT, MASTER_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import AddNote from '../../Common/AddNote';
import LeadsOrPetitionsDetail from '../../Common/LeadsOrPetitions/Details';
import { ResourceStation } from '../../Petitions/PetitionBuildCase/ResourceStation';

const USCIS = {
  data: [
    {
      icon: StatusI,
      link: 'https://egov.uscis.gov/casestatus/landing.do',
      title: 'Check Your Case Status',
    },
    {
      icon: Clock,
      link: 'https://egov.uscis.gov/processing-times/',
      title: 'Check Processing Times',
    },
    {
      icon: Fadd,
      link: 'https://egov.uscis.gov/coa/displayCOAForm.do',
      title: 'Change Your Address Online',
    },
  ],
  subTitle: 'Use USCIS online tools and resources to manage your case:',
  title: 'USCIS Case Status',
};

export const BeneficiaryDetailList: React.FC<any> = () => {
  const { petitionId } = useParams();
  const [searchParams] = useSearchParams();
  const { profile }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [beneficiaryData, setBeneficiaryData] = useState<any>({});
  const [loader, setLoader] = useState(petitionId ? true : false);
  const [resourceStation, setResourceStation] = useState({
    open: false,
  });
  const [viewAttorneyNotes, setViewAttorneyNotes] = useState<any>({
    leadOrPetitionId: '',
    show: false,
    type: '',
  });
  const [teams, setTeams] = useState<any>({});
  const [notesList, setNotesList] = useState<any>([]);
  const handleOpen = () => {
    if (!beneficiaryData.petitionId) {
      // lead
      navigate(
        `${String(BeneficiaryPrivateRoutes.UPLOADDOCUMENT)}/${String(
          beneficiaryData.petitionId,
        )}?leadUser=${String(beneficiaryData.lead_user_id)}`,
      );
    } else if (
      beneficiaryData.petitionId &&
      !beneficiaryData.petition_submitted
    ) {
      // petition
      navigate(
        `${String(BeneficiaryPrivateRoutes.UPLOADPETITIONSDOCUMENT)}/${String(
          beneficiaryData.petitionId,
        )}?leadUser=${String(beneficiaryData.lead_user_id)}`,
      );
    } else if (beneficiaryData.petition_submitted) {
      toast.error(
        'Petition submitted. please contact your attorney',
        alertOptions,
      );
    }
  };

  const openFile = (file) => {
    const obj = {
      key: file,
    };

    const fileName = file.split('/').pop();
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        // setLoader(false);
        saveAs(data, fileName);
        toast.success(`${String(fileName)} File Downloaded`, alertOptions);
      })
      .catch((err) => {
        // setLoader(false);
        toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };

  const showModal = (notes) => {
    if (!notes.petitionId) {
      setViewAttorneyNotes({
        leadOrPetitionId: petitionId,
        show: true,
        type: 'petition',
      });
    }
  };

  const notesApiCall = () => {
    setViewAttorneyNotes({
      leadOrPetitionId: '',
      show: false,
      type: '',
    });
    getAllListData(
      `${String(COMMON_ENDPOINT.Contact)}/${String(petitionId)}?type=petition`,
    )
      .then((notes: any) => {
        setNotesList(notes);
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    if (petitionId && searchParams.get('leadUser') !== '') {
      getAllListData(
        `${MASTER_ENDPOINT.Dashboard}/beneficiary?leadUserId=${String(
          searchParams.get('leadUser'),
        )}`,
      )
        .then((resp: any) => {
          setBeneficiaryData(
            resp && resp.data && resp.data.length > 0 ? resp.data[0] : {},
          );
        })
        .catch((e: any) => {
          setLoader(false);
        });
      getAllListData(`${COMMON_ENDPOINT.Petitions}/team/${String(petitionId)}`)
        .then((teamResp: any) => {
          setTeams(teamResp.data ? teamResp.data : {});
          notesApiCall();
        })
        .catch((e) => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Grid container>
      {petitionId && (
        <Grid item md={10} sm={12} className="pl-0">
          <Typography
            component={'div'}
            className="black-white-text"
            sx={{
              fontFamily: 'Arial-Bold !important',
              fontSize: '1.25em !important',
              margin: '1.5rem 0 1.5rem 0',
            }}>
            {beneficiaryData?.visa_type_name
              ? `${String(beneficiaryData?.visa_type_name)} for`
              : ''}{' '}
            {profile
              ? (profile.first_name ? `${String(profile.first_name)} ` : '') +
                (profile.last_name ? `${String(profile.last_name)} ` : '')
              : ''}
          </Typography>
        </Grid>
      )}
      <LeadsOrPetitionsDetail
        showType="beneficiary"
        handleOpen={handleOpen}
        listData={beneficiaryData}
      />
      {petitionId && (
        <Grid container>
          <Grid item md={11} sm={12} sx={{ paddingRight: '3.5rem' }}>
            <Grid container>
              <Grid item md={12} sm={12}>
                <Typography
                  className="black-white-text f-24"
                  sx={{ padding: '46px 0 18px 0 !important' }}>
                  Team Members
                </Typography>
                <Typography component={'div'} className="beneteam-box2">
                  <Typography className="inb2" />
                  <Typography component={'div'} className="d-flex-a">
                    <Typography className="d-flex-ja mr-2 mt-2 flex-column">
                      <img
                        src={
                          !_.isEmpty(teams)
                            ? teams.attorney_image
                              ? `${fileApiUrl}${String(teams.attorney_image)}`
                              : Avator
                            : Avator
                        }
                        alt="im"
                        className="team-im"
                      />
                      <Typography className="f-16 mt-1 black-white-text">
                        {!_.isEmpty(teams) ? teams.attorney_name : '-'}
                      </Typography>
                      <Typography className="mt-1 b-text">Attorney</Typography>
                    </Typography>
                    <Typography className="d-flex-ja mr-2 mt-2 flex-column">
                      <img src={Avator} alt="im" className="team-im" />
                      <Typography className="f-16 mt-1 black-white-text">
                        {!_.isEmpty(teams) ? teams.case_manager_name : '-'}
                      </Typography>
                      <Typography className="mt-1 b-text">
                        Case Manager
                      </Typography>
                    </Typography>
                    <Typography className="d-flex-ja mr-2 mt-2 flex-column">
                      <img
                        src={
                          !_.isEmpty(teams)
                            ? teams.petitioner_image
                              ? `${fileApiUrl}${String(teams.petitioner_image)}`
                              : Avator
                            : Avator
                        }
                        alt="im"
                        className="team-im"
                      />
                      <Typography className="f-16 mt-1 black-white-text">
                        {!_.isEmpty(teams) ? teams.petitioner_name : '-'}
                      </Typography>
                      <Typography className="mt-1 b-text">
                        Petitioner
                      </Typography>
                    </Typography>
                    <Typography className="d-flex-ja mr-2 mt-2 flex-column">
                      <img
                        src={
                          !_.isEmpty(teams)
                            ? teams.image
                              ? `${fileApiUrl}${String(teams.image)}`
                              : Avator
                            : Avator
                        }
                        alt="im"
                        className="team-im"
                      />
                      <Typography className="f-16 mt-1 black-white-text">
                        {!_.isEmpty(teams) ? teams.benificiery_name : '-'}
                      </Typography>
                      <Typography className="mt-1 b-text">
                        Beneficiary
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12} sm={12}>
                <Typography
                  className="black-white-text f-24"
                  sx={{ padding: '40px 0 10px 0' }}>
                  {USCIS.title}
                </Typography>
                <Typography
                  className="f-18"
                  sx={{
                    color: '#fff !important',
                    fontStyle: 'italic',
                    opacity: '0.75',
                    paddingBottom: '20px',
                  }}>
                  {USCIS.subTitle}
                </Typography>
              </Grid>
              {USCIS.data.map((uscis, index) => (
                <Grid item md={4} sm={12} key={index}>
                  <Typography
                    component={'div'}
                    className="usics-box2 d-flex-a"
                    sx={{
                      cursor: 'pointer',
                      flexWrap: 'nowrap',
                      position: 'relative',
                      zIndex: 1,
                    }}
                    onClick={() => window.open(uscis.link)}>
                    <Typography className="inb2" />
                    <Typography component={'div'}>
                      <img src={uscis.icon} alt="" />
                    </Typography>
                    <Typography
                      className="black-white-text f-16"
                      sx={{
                        lineHeight: '22px !important',
                        paddingLeft: '30px',
                      }}>
                      {uscis.title}
                    </Typography>
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Grid container>
              <Grid item md={12} sm={12} sx={{ paddingRight: '2.5rem' }}>
                <Typography
                  component={'div'}
                  className="d-flex-a d-flex-sb sticky-tab mt-2 mb-2"
                  sx={{
                    alignItems: 'baseline !important',
                    padding: '20px !important',
                  }}>
                  <Typography className="black-white-text f-24">
                    Notes
                  </Typography>
                  <Button
                    className="n-add-btn"
                    sx={{
                      borderRadius: '5px !important',
                      height: '36px !important',
                      position: 'relative',
                    }}
                    onClick={showModal}>
                    Add Notes
                  </Button>
                </Typography>
                <Typography component={'div'}>
                  <Typography>
                    {!_.isEmpty(notesList) ? (
                      notesList.map((note, index) => (
                        <Typography
                          component={'div'}
                          className="note-box2"
                          key={index}>
                          <Typography className="inb2" />
                          <Typography component={'div'}>
                            <Typography className="black-white-text ctitle hideshow">
                              {note.notes}
                            </Typography>
                            <Typography className="rl-text">
                              Added By: {note.answerAddedByUserName} on{' '}
                              {moment(note.created_at).format(
                                'DD-MM-yyyy hh:mm A',
                              )}
                            </Typography>
                          </Typography>

                          {note.key && (
                            <Button
                              className="n-add-btn"
                              sx={{
                                borderRadius: '5px !important',
                                height: '36px !important',
                              }}
                              onClick={() => openFile(note.key)}>
                              Download
                            </Button>
                          )}
                        </Typography>
                      ))
                    ) : (
                      <Typography component={'div'} className="note-box2">
                        <Typography className="inb2" />
                        <Typography component={'div'}>
                          <Typography className="black-white-text ctitle hideshow">
                            No Record(s) Found.
                          </Typography>
                        </Typography>
                      </Typography>
                    )}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Typography component={'div'} className="add-box pb-2">
              <Typography component={'div'} className="add-inner-box" />
              <Typography
                className="black-white-text d-flex-a a-mt-3"
                style={{ marginTop: '18px' }}
                onClick={(e) =>
                  setResourceStation((pre) => ({
                    ...pre,
                    open: true,
                  }))
                }>
                Resource Station
              </Typography>
            </Typography>
          </Grid>
          {resourceStation.open ? (
            <ResourceStation
              modalOnClose={() =>
                setResourceStation((pre) => ({
                  ...pre,
                  open: false,
                }))
              }
            />
          ) : null}
          {viewAttorneyNotes.show && (
            <AddNote
              notes={viewAttorneyNotes}
              setNotes={setViewAttorneyNotes}
              type="petition"
              showModel={true}
              showTable={false}
              notesRecall={notesApiCall}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default BeneficiaryDetailList;
