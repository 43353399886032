import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData } from 'services/CommonServices';
import { ACHFormTypes } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { ACHFormSchema } from 'utils/ValidatorSchema';

export const AchPayment: React.FC<any> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loader, setLoader] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState<any>({});
  const [confirm, setConfirm] = useState(true);

  const {
    control: control,
    handleSubmit: handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ACHFormTypes>({
    criteriaMode: 'all',
    defaultValues: {
      accountNumber: '',
      accountType: '',
      billingCity: '',
      billingCountry: '',
      billingState: '',
      billingStreetAddress: '',
      billingZip: '',
      checkHolderName: '',
      checkType: '',
      recaptchaReactive: '',
      routingNumber: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ACHFormSchema),
  });

  const onACHSubmitForm = (data: any) => {
    setLoader(true);
    googleCaptcahCheck(data);
  };

  const googleCaptcahCheck = async (data) => {
    const tokenFormValues = {
      reCaptcha: data.recaptchaReactive,
    };
    setLoader(true);
    await addNewData(
      tokenFormValues,
      `${COMMON_ENDPOINT.ContractPayment}/token-validate`,
    )
      .then((resp: any) => {
        achPaymentApi(data);
      })
      .catch((e) => {
        setLoader(false);
        toast.error('Enter a valid captcha.', alertOptions);
      });
  };

  const achPaymentApi = async (data) => {
    setLoader(true);
    const payload = getpaymentData(data);
    await addNewData(payload, `${COMMON_ENDPOINT.ContractPayment}/check`)
      .then((resp: any) => {
        // toast.success('Payment successfully', alertOptions);
        setLoader(false);
        navigate('/secure-payment/success');
      })
      .catch((e) => {
        toast.error(
          'Your payment failed. Please try again after sometime or contact your attorney.',
          alertOptions,
        );
        setLoader(false);
      });
  };

  const getpaymentData = (data: any) => {
    return {
      accountNumber: data.accountNumber,
      accountType: data.accountType,
      billingCity: data.billingCity,
      billingState: data.billingState,
      billingStreetAddress: data.billingStreetAddress,
      billingZip: data.billingZip,
      checkHolderName: data.checkHolderName,
      checkType: data.checkType,
      hashKey: searchParams.get('hashKey'),
      paymentTotal: paymentInfo.totalPaymentAmount,
      routingNumber: data.routingNumber,
    };
  };

  useEffect(() => {
    if (searchParams.get('hashKey') !== '') {
      getAllListData(
        `${COMMON_ENDPOINT.ContractPayment}?hashKey=${searchParams.get(
          'hashKey',
        )}`,
      )
        .then((info: any) => {
          setPaymentInfo(info.data);
          const paymentAmount =
            info.data && info.data.paymentTotal ? info.data.paymentTotal : '';
          if (!paymentAmount) {
            navigate(`/secure-payment?hashKey=${searchParams.get('hashKey')}`);
            return;
          }
          setLoader(false);
        })
        .catch((e) => {
          // toast.error(
          //   'The link is invalid. Please contact your attorney.',
          //   alertOptions,
          // );
          navigate('/login');
          setLoader(false);
        });
    } else {
      toast.error('Page not found', alertOptions);
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <Grid item md={10} className="pl-0">
          <Typography component={'div'} className="d-flex-a d-flex-sb mb-2">
            <Typography component={'div'} className="d-flex-a ">
              <Typography className="black-white-text f-22 font-bold">
                Payment Amount:
              </Typography>
              <Typography className="black-white-text f-22 font-bold pl-2">
                $
                {!_.isEmpty(paymentInfo)
                  ? parseFloat(paymentInfo.totalPaymentAmount).toFixed(2)
                  : '-'}
              </Typography>
            </Typography>
            <div>
              <Button
                className="cancel-btn mr-4 "
                sx={{
                  borderRadius: '5px !important',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '1em !important',
                  width: '120px !important',
                }}
                onClick={() => navigate(-1)}>
                <Typography
                  className="o-box d-flex-a"
                  sx={{ width: 'unset !important' }}
                />
                <ArrowBackIcon sx={{ marginRight: '1rem' }} />
                Back
              </Button>
            </div>
          </Typography>
          <Typography component={'div'} className="payment-me-box">
            <Typography
              component={'div'}
              className="top-box"
              sx={{
                borderBottom: '1px solid lightgrey',
                padding: '15px 46px 5px 46px !important',
              }}>
              <Grid container className="pt-2">
                <Grid item md={4} sm={12}>
                  <Typography className="oname-label f-15">
                    Payment Amount:
                  </Typography>
                  <Typography className="oname-text f-15 font-bold">
                    USD{' '}
                    {!_.isEmpty(paymentInfo)
                      ? parseFloat(paymentInfo.paymentTotal).toFixed(2)
                      : '-'}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12}>
                  <Typography className="oname-label f-15">
                    Transaction Fee:
                  </Typography>
                  <Typography className="oname-text f-15 font-bold">
                    USD{' '}
                    {!_.isEmpty(paymentInfo)
                      ? parseFloat(paymentInfo.transactionAmount).toFixed(2)
                      : '-'}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12}>
                  <Typography className="oname-label f-15">
                    Total Amount:
                  </Typography>
                  <Typography className="oname-text f-15 font-bold">
                    USD{' '}
                    {!_.isEmpty(paymentInfo)
                      ? parseFloat(paymentInfo.totalPaymentAmount).toFixed(2)
                      : '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Typography>

            <Typography component={'div'} className="top-box">
              <Grid container className="pt-2">
                <Grid item md={6} sm={12} className="mb-2">
                  <Typography className="oname-label f-15">
                    Firm Name:
                  </Typography>
                  <Typography className="oname-text f-15 font-bold">
                    {!_.isEmpty(paymentInfo) ? paymentInfo.lawFirmName : '-'}
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} className="mb-2">
                  <Typography className="oname-label f-15">
                    {paymentInfo?.paymentInitiatedFor === 4
                      ? 'Beneficiary'
                      : 'Organization'}
                    {' Name:'}
                  </Typography>
                  <Typography className="oname-text f-15 font-bold">
                    {!_.isEmpty(paymentInfo) ? paymentInfo.userName : '-'}
                  </Typography>
                </Grid>

                {paymentInfo?.paymentInitiatedFor === 4 && (
                  <Grid item md={6} sm={12} className="mb-2">
                    <Typography className="oname-label f-15">
                      Organization Name:
                    </Typography>

                    <Typography className="oname-text f-15 font-bold">
                      {paymentInfo?.orgName}
                    </Typography>
                  </Grid>
                )}
                {paymentInfo?.paymentInitiatedFor === 2 && (
                  <Grid item md={6} sm={12} className="mb-2">
                    {paymentInfo?.paymentInitiatedFor === 2 && (
                      <Typography className="oname-label f-15">
                        Beneficiary Name:
                      </Typography>
                    )}
                    <Typography className="oname-text f-15 font-bold">
                      {paymentInfo?.beneficiaryname}
                    </Typography>
                  </Grid>
                )}
                <Grid item md={6} sm={12} className="mb-2">
                  <Typography className="oname-label f-15">
                    Visa Type:
                  </Typography>
                  <Typography className="oname-text f-15 font-bold">
                    {paymentInfo?.visaType ? paymentInfo?.visaType : '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
            <Typography component={'div'} className="bottom-box">
              <form id="ach-form" onSubmit={handleSubmit(onACHSubmitForm)}>
                <Box
                  className="u-add-in"
                  component="form"
                  sx={{
                    backgroundColor: 'unset !important',
                    border: 'unset !important',
                    padding: 'unset !important',
                  }}
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 0, sm: 2, xs: 1 }}>
                    <Grid item md={12} className="pl-0">
                      <Typography
                        component={'div'}
                        className="f-20 font-bold mt-1"
                        sx={{ marginBottom: '2rem' }}>
                        Billing Address
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} className="pl-0">
                      <Typography
                        component={'div'}
                        className="custom-field u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Street Address <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="billingStreetAddress"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                // style={{ resize: 'none' }}
                                error={errors.billingStreetAddress?.message}
                                onChange={onChange}
                                isTextArea={true}
                                minRows={3}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography
                        component={'div'}
                        className="custom-field  u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Country <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="billingCountry"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className="custom-input"
                                error={errors.billingCountry?.message}
                                onChange={onChange}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={12}
                      style={{ paddingLeft: '16px !important' }}>
                      <Typography
                        component={'div'}
                        className="custom-field  u-pl-1">
                        <Typography component={'div'} className="custom-label">
                          City <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="billingCity"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className="custom-input"
                                error={errors.billingCity?.message}
                                onChange={onChange}
                              />
                            );
                          }}
                        />
                      </Typography>

                      <Typography
                        component={'div'}
                        className="custom-field  u-pl-1">
                        <Typography component={'div'} className="custom-label">
                          State <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="billingState"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className="custom-input"
                                error={errors.billingState?.message}
                                onChange={onChange}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography
                        component={'div'}
                        className="custom-field  u-pl-1">
                        <Typography component={'div'} className="custom-label">
                          Zip Code <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="billingZip"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className="custom-input"
                                error={errors.billingZip?.message}
                                onChange={(val) => {
                                  const vals = val.target.value.replace(
                                    /\s/g,
                                    '',
                                  );
                                  return onChange(vals);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container columnSpacing={{ md: 0, sm: 2, xs: 1 }}>
                    <Grid item md={12} className="pl-0">
                      <Typography
                        component={'div'}
                        className="f-20 font-bold"
                        sx={{ marginBottom: '2rem', marginTop: '1rem' }}>
                        Bank Transfer{' '}
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} className="pl-0">
                      <Typography
                        component={'div'}
                        className="custom-field  u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Account Holder Name{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="checkHolderName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className="custom-input"
                                error={errors.checkHolderName?.message}
                                onChange={onChange}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography
                        component={'div'}
                        className="custom-field  u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Routing Number <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="routingNumber"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className="custom-input"
                                error={errors.routingNumber?.message}
                                onChange={(val) => {
                                  const vals = val.target.value.replace(
                                    /\s/g,
                                    '',
                                  );
                                  return onChange(vals);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>

                      <Typography
                        component={'div'}
                        className="custom-field  u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Check Type <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name={'checkType'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  {
                                    label: 'PERSONAL',
                                    value: 'PERSONAL',
                                  },
                                  {
                                    label: 'BUSINESS',
                                    value: 'BUSINESS',
                                  },
                                  {
                                    label: 'PAYROLL',
                                    value: 'PAYROLL',
                                  },
                                ]}
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeHolder={'CHOOSE ONE'}
                                className={'custom-input'}
                                error={errors.checkType?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} className="pl-0">
                      <Typography
                        component={'div'}
                        className="custom-field  u-pl-1">
                        <Typography component={'div'} className="custom-label">
                          Account Number <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="accountNumber"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className="custom-input"
                                error={errors.accountNumber?.message}
                                onChange={(val) => {
                                  const vals = val.target.value.replace(
                                    /\s/g,
                                    '',
                                  );
                                  return onChange(vals);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>

                      <Typography
                        component={'div'}
                        className="custom-field  u-pl-1">
                        <Typography component={'div'} className="custom-label">
                          Account Type <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name={'accountType'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  {
                                    label: 'CHECKING',
                                    value: 'CHECKING',
                                  },
                                  {
                                    label: 'SAVINGS',
                                    value: 'SAVINGS',
                                  },
                                ]}
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeHolder={'CHOOSE ONE'}
                                className={'custom-input'}
                                error={errors.accountType?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Typography component={'div'} className="captca">
                  <Controller
                    control={control}
                    defaultValue=""
                    name={'recaptchaReactive'}
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <ReCAPTCHA
                          className="captca"
                          ref={ref}
                          sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                          onChange={(e) => onChange(e)}
                          onErrored={errors.recaptchaReactive?.message}
                        />
                      );
                    }}
                  />
                  {watch('recaptchaReactive') === '' && (
                    <div className="invalid-text">Captcha is required</div>
                  )}
                </Typography>

                <Typography component={'div'} className="d-flex-a d-flex-sb">
                  <Typography component={'div'} className="d-flex-a">
                    <Checkbox
                      className="pb-0 pl-0 sv"
                      checked={confirm}
                      onChange={(e) => setConfirm(e.target.checked)}
                      color="primary"
                    />
                    <Typography
                      className="f-15 font-bold"
                      style={{
                        color: !confirm ? 'red' : 'black',
                      }}>
                      I AGREE TO THE TERMS OF USE AND PRIVACY POLICY
                    </Typography>
                  </Typography>
                  <Button
                    className="pay-am-btn"
                    type="submit"
                    form={'ach-form'}
                    disabled={!confirm}>
                    PAY ${parseFloat(paymentInfo.totalPaymentAmount).toFixed(2)}
                  </Button>
                </Typography>
              </form>
            </Typography>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AchPayment;
