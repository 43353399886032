/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@mui/material';
import InfinityScroll from 'components/InfinityScroll';
import _, { capitalize } from 'lodash';
import React from 'react';

type ListPropsTypes = {
  docReviews: any;
  docReviewParams: any;
  setDocReviewParams: any;
};

const documentStatus = [
  {
    key: 'pending',
    value: 'Pending',
  },
  {
    key: 'reviewPending',
    value: 'Review Pending',
  },
  {
    key: 'approved',
    value: 'Approved',
  },
];

const DocumentReview: React.FC<any> = (props: ListPropsTypes) => {
  const { docReviews, setDocReviewParams } = props;
  const rowProps = (rowProps, index) => {
    return (
      <Typography
        component={'div'}
        className="cp-item d-flex-a pay-container"
        key={index}>
        <Typography component={'div'}>
          <Typography component={'h2'} className="cp-name black-white-text">
            {rowProps.template_name}
          </Typography>

          <Typography component={'p'} className="cp-by">
            {capitalize(rowProps.type)} sent to{' '}
            {rowProps.build_document_for.includes(2)
              ? rowProps.petitioner_user_name
              : rowProps.beneficiary_name}
          </Typography>
        </Typography>

        <Typography component={'div'} className="cps-text black-white-text">
          Status{' '}
          {rowProps.build_document_for &&
          Array.isArray(rowProps.build_document_for) &&
          rowProps.build_document_for.includes(4)
            ? ' for Beneficiary'
            : rowProps.build_document_for &&
              Array.isArray(rowProps.build_document_for) &&
              rowProps.build_document_for.includes(2)
            ? ' for Petitioner'
            : ''}
          <Typography component={'h6'} className="cp-status">
            {rowProps.build_document_for &&
            Array.isArray(rowProps.build_document_for) &&
            rowProps.build_document_for.includes(2)
              ? documentStatus.find(
                  (sts) =>
                    sts.key === rowProps.petitioner_build_document_status,
                )?.value
              : rowProps.build_document_for &&
                Array.isArray(rowProps.build_document_for) &&
                rowProps.build_document_for.includes(4)
              ? documentStatus.find(
                  (sts) =>
                    sts.key === rowProps.beneficiary_build_document_status,
                )?.value
              : ''}
          </Typography>
        </Typography>
      </Typography>
    );
  };
  return (
    <Typography component={'div'}>
      <Typography className="c-title black-white-text">
        Document sent for review
      </Typography>
      <Typography component={'div'} className="cp-box">
        <Typography component={'div'} className="cp-flex-4">
          <Typography component={'div'}>
            {docReviews && docReviews.data && docReviews.data.length > 0 ? (
              <InfinityScroll
                paramState={setDocReviewParams}
                height={325}
                scrollableTargetId={'pendingReviews'}
                data={docReviews.data}
                rowProps={rowProps}
                count={docReviews.count}
              />
            ) : (
              <Typography
                className="note-box2"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                <Typography className="inb2" />
                <Typography className="black-white-text ctitle hideshow">
                  No Record(s) Found.
                </Typography>
              </Typography>
            )}
          </Typography>
        </Typography>
      </Typography>
    </Typography>
  );
};
export default React.memo(DocumentReview);
