import { yupResolver } from '@hookform/resolvers/yup';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  Button,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  // Tooltip,
  Typography,
} from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
import moment from 'moment';
// import Multiselect from 'multiselect-react-dropdown';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { addNewData, deleteData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { AssembleSendToSchema } from 'utils/ValidatorSchema';

type Props = {
  teamMembers: string[];
  setLoader: (src: any) => void;
  petitionId: string;
  setSendToModal: (src: any) => void;
  sendToModal: any;
  getSendEmailList: (src: any) => void;
  setSendList: (src: any) => void;
  sendList: any;
  petitions: any;
};

export const SendEmail: React.FC<Props> = (props: Props) => {
  const {
    teamMembers,
    setLoader,
    petitionId,
    setSendToModal,
    sendToModal,
    setSendList,
    sendList,
    getSendEmailList,
    petitions,
  } = props;
  const multiselectRef = React.createRef<any>();
  const [showLongtext, setShowLongText] = React.useState({
    info: {
      created_at: '',
      email_address: [],
      notes: '',
      userInfo: { full_name: '' },
    },
    open: false,
  });
  const [deleteItem, setDeleteItem] = React.useState({
    deleteId: '',
    deleteOpen: false,
  });

  const {
    control: control,
    handleSubmit,
    reset: resetForm,
    // watch,
    // setValue,
    formState: { errors },
  } = useForm<{ notes: string; teamMembers: any[] }>({
    criteriaMode: 'all',
    defaultValues: {
      notes: '',
      teamMembers: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(AssembleSendToSchema),
  });

  const SubmitSendEmail = (data: { notes: string; teamMembers: string[] }) => {
    setLoader && setLoader(true);
    addNewData(
      data,
      `${
        COMMON_ENDPOINT.AssemblePetition
      }/merge/pdf/send/${petitionId}?isAffilate=${
        petitions?.petitionData?.isAffilateCase
          ? String(petitions?.petitionData?.isAffilateCase)
          : ''
      }&affilateRoleId=${
        petitions?.petitionData?.isAffilateCase
          ? String(petitions?.petitionData?.affilateRoleId)
          : ''
      }&firmId=${
        petitions?.petitionData?.attorney_assigned
          ? String(petitions?.petitionData?.attorney_assigned)
          : ''
      }`,
    )
      .then(() => {
        resetForm();
        multiselectRef &&
          multiselectRef.current &&
          multiselectRef.current.resetSelectedValues();
        getSendEmailList && getSendEmailList('loading');
      })
      .catch(() => {
        setLoader && setLoader(false);
      });
  };

  const submitOn = () => {
    handleSubmit(SubmitSendEmail)();
  };

  const deleteSendEmail = (deleteId: any) => {
    setLoader && setLoader(true);
    deleteData(
      deleteId,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${COMMON_ENDPOINT.AssemblePetition}/merge/pdf/send`,
    )
      .then((succ) => {
        setLoader && setLoader(false);
        toast.success('Deleted Successfully', alertOptions);
        setDeleteItem((pre) => ({
          ...pre,
          deleteId: '',
          deleteOpen: false,
        }));

        if (sendList.list.filter((e: any) => e.id !== deleteId).length === 0) {
          setSendToModal &&
            setSendToModal((pre) => ({
              ...pre,
              open: false,
            }));
          setSendList((pre) => ({
            ...pre,
            list: pre.list.filter((e: any) => e.id !== deleteId),
            ...(pre.list.filter((e: any) => e.id !== deleteId).length === 0
              ? { visible: false }
              : {}),
          }));
        } else {
          setSendList((pre) => ({
            ...pre,
            list: pre.list.filter((e: any) => e.id !== deleteId),
            ...(pre.list.filter((e: any) => e.id !== deleteId).length === 0
              ? { visible: false }
              : {}),
          }));
        }
      })
      .catch(() => {
        setLoader && setLoader(false);
      });
  };

  const showFulltext = (elemetId: string, notes) => {
    const el = document.getElementById(elemetId);

    const styles = getComputedStyle(el);
    const widthEl = parseFloat(styles.width);
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.font = `${styles.fontSize} ${styles.fontFamily}`;
    const text = ctx.measureText(el.innerText);

    if (text.width > widthEl) {
      setShowLongText((pre) => ({
        ...pre,
        info: notes,
        open: true,
      }));
    }
  };

  return (
    <>
      <ConfirmDialog
        title={'Delete'}
        floatingBtnShow={true}
        open={deleteItem.deleteOpen}
        onConfirm={(act) =>
          act === 'yes'
            ? deleteSendEmail(deleteItem.deleteId)
            : setDeleteItem((pre) => ({
                ...pre,
                deleteId: '',
                deleteOpen: false,
              }))
        }
        autoCloseDisable={true}>
        If you delete this records users will not able to access the assemble
        document. Are you sure do you want to delete?
      </ConfirmDialog>
      <CustomModalView
        open={showLongtext?.open}
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        close={() =>
          setShowLongText((pre) => ({
            ...pre,
            open: false,
          }))
        }
        title="Details">
        <DialogContent>
          <Typography
            component={'form'}
            className={'custom-field-row'}
            sx={{ padding: '12px' }}>
            <Grid container spacing={1.5}>
              <Grid item md={12} sm={12} xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={2} sm={12} xs={12}>
                    <Typography className="f-15 font-bold pr-2">
                      Notes
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={10}
                    sm={12}
                    xs={12}
                    sx={{ paddingTop: 'unset !important' }}>
                    <div style={{ width: '100%' }}>
                      <CustomInput
                        isTextArea={true}
                        minRows={15}
                        value={showLongtext?.info?.notes}
                        placeHolder={'Notes'}
                        className={'custom-textarea-note'}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={3} sm={12} xs={12}>
                    <Typography className="f-15 font-bold pr-2">
                      Date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={9}
                    sm={12}
                    xs={12}
                    sx={{ paddingTop: 'unset !important' }}>
                    <div style={{ width: '100%' }}>
                      <CustomInput
                        value={moment(
                          new Date(showLongtext?.info?.created_at),
                        ).format('MM-DD-YYYY hh:mm A')}
                        placeHolder={'Date'}
                        className={'custom-input'}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={3} sm={12} xs={12}>
                    <Typography className="f-15 font-bold pr-2">
                      Sent By
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={9}
                    sm={12}
                    xs={12}
                    sx={{ paddingTop: 'unset !important' }}>
                    <div style={{ width: '100%' }}>
                      <CustomInput
                        value={showLongtext?.info?.userInfo?.full_name}
                        placeHolder={'Name'}
                        className={'custom-input'}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={1.5} sm={12} xs={12}>
                    <Typography className="f-15 font-bold pr-2">
                      Sent to email address
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={10.5}
                    sm={12}
                    xs={12}
                    sx={{ paddingTop: 'unset !important' }}>
                    <div style={{ width: '100%' }}>
                      <CustomInput
                        value={showLongtext?.info?.email_address.toString()}
                        placeHolder={'Email'}
                        className={'custom-input'}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Typography>
        </DialogContent>
      </CustomModalView>
      <CustomModalView
        open={sendToModal && sendToModal?.open}
        fullWidth={true}
        maxWidth={'lg'}
        scroll={'paper'}
        autoCloseDisable
        navigationLinkShow={!sendList.visible ? false : true}
        navLinkTitle={'New'}
        onClickNavLink={() =>
          setSendList((pre) => ({
            ...pre,
            visible: false,
          }))
        }
        close={() =>
          setSendToModal &&
          setSendToModal((pre) => ({
            ...pre,
            open: false,
          }))
        }
        title="Send to">
        <DialogContent>
          {sendList.visible && sendList.list ? (
            <table className="lead-table table-hover">
              <thead>
                <tr>
                  <th scope="col" style={{ width: '30%' }}>
                    Notes
                  </th>
                  <th scope="col" style={{ width: '20%' }}>
                    Date
                  </th>
                  <th scope="col" style={{ width: '20%' }}>
                    Sent by
                  </th>
                  <th scope="col" style={{ width: '20%' }}>
                    Sent to email address
                  </th>
                  {/* <th scope="col">Delete</th> */}
                </tr>
              </thead>
              <tbody>
                {sendList.list.length > 0 ? (
                  sendList.list.map((datas, index: number) => (
                    <tr key={index}>
                      <td
                        style={{
                          color: '#1093df',
                        }}>
                        <a
                          className="longTextHide"
                          id={String(index + 1)}
                          onClick={() =>
                            showFulltext(String(index + 1), datas)
                          }>
                          {datas?.notes}
                        </a>
                      </td>
                      <td>
                        {moment(new Date(datas?.created_at)).format(
                          'MM-DD-YYYY hh:mm A',
                        )}
                      </td>
                      <td>{datas?.userInfo?.full_name}</td>
                      <td>{datas?.email_address.join(', ')}</td>
                      {/* <td>
                        <Tooltip title={'Delete'} placement="top">
                          <DeleteForeverIcon
                            sx={{
                              cursor: 'pointer',
                              fill: 'red',
                              height: '25px',
                              width: '25px',
                            }}
                            onClick={() =>
                              setDeleteItem((pre) => ({
                                ...pre,
                                deleteId: datas.id,
                                deleteOpen: true,
                              }))
                            }
                          />
                        </Tooltip>
                      </td> */}
                    </tr>
                  ))
                ) : (
                  <tr className={'text-center'}>
                    <th scope="row" colSpan={5}>
                      No Record(s) Found.
                    </th>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            ''
          )}
          {!sendList.visible ? (
            <Typography component={'form'} sx={{ padding: '12px' }}>
              <Grid container spacing={1.5}>
                <Grid item md={12} sm={12} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item md={2} sm={12} xs={12}>
                      <Typography className="f-15 font-bold pr-2">
                        Choose Recipients{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={10}
                      sm={12}
                      xs={12}
                      sx={{ paddingTop: 'unset !important' }}>
                      <Controller
                        control={control}
                        name={'teamMembers'}
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <div style={{ width: '100%' }}>
                              <CustomSelect
                                autoFocus={true}
                                closeMenuOnSelect={false}
                                labelKey={'label'}
                                valueKey={'value'}
                                options={
                                  !_.isEmpty(teamMembers) ? teamMembers : []
                                }
                                isMulti={true}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e.target.value ? e : []);
                                }}
                                placeHolder={'Choose Recipients'}
                                className={'basic-multi-select'}
                                error={errors.teamMembers?.message}
                              />
                              {/* <Multiselect
                                  displayValue="label"
                                  placeholder={
                                    watch('teamMembers').length === 0
                                      ? 'Choose Recipients'
                                      : ''
                                  }
                                  className={'custom-input'}
                                  onSelect={(selected, item) => {
                                    onChange(
                                      selected.map((support) => support.value),
                                    );
                                    setValue(
                                      'teamMembers',
                                      selected.map((support) => support.value),
                                    );
                                  }}
                                  onRemove={(selected, item) => {
                                    onChange(
                                      selected.map((support) => support.value),
                                    );
                                    setValue(
                                      'teamMembers',
                                      selected.map((support) => support.value),
                                    );
                                  }}
                                  options={
                                    !_.isEmpty(teamMembers) ? teamMembers : []
                                  }
                                  ref={multiselectRef}
                                />

                                {errors.teamMembers?.message && (
                                  <FormHelperText style={{ color: '#ff0000' }}>
                                    {errors.teamMembers?.message}
                                  </FormHelperText>
                                )} */}
                            </div>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item md={2} sm={12} xs={12}>
                      <Typography className="f-15 font-bold pr-2">
                        Notes <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={10}
                      sm={12}
                      xs={12}
                      sx={{ paddingTop: 'unset !important' }}>
                      <Controller
                        control={control}
                        name={'notes'}
                        render={({ field }) => {
                          return (
                            <div style={{ width: '100%' }}>
                              <CustomInput
                                {...field}
                                isTextArea={true}
                                minRows={10}
                                placeHolder={'Notes'}
                                className={'custom-textarea-note'}
                              />
                              {errors.notes?.message && (
                                <FormHelperText style={{ color: '#ff0000' }}>
                                  {errors.notes?.message}
                                </FormHelperText>
                              )}
                            </div>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography className="f-15 pr-2 d-flex-b">
                        <InfoRoundedIcon
                          style={{
                            cursor: 'pointer',
                            fill: 'rgb(14 106 234)',
                            marginLeft: '5px',
                          }}
                        />
                        <Typography
                          component={'span'}
                          sx={{ opacity: 0.5, paddingLeft: '8px' }}>
                          The user would receive an email with a link to access
                          the download page of the petition.
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Typography>
          ) : (
            ''
          )}
        </DialogContent>
        {!sendList.visible ? (
          <DialogActions>
            <Typography
              component={'div'}
              className="d-flex-jae"
              sx={{ padding: '0 1.5rem' }}>
              {sendList.list && sendList.list.length ? (
                <Button
                  className="n-cancel-btn"
                  sx={{
                    borderRadius: '5px !important',
                    float: 'right',
                    fontFamily: 'Arial-Regular !important',
                    fontSize: '0.9em !important',
                    height: '35px !important',
                    margin: '15px',
                    width: '149px !important',
                  }}
                  onClick={() =>
                    setSendList((pre) => ({
                      ...pre,
                      visible: true,
                    }))
                  }>
                  Cancel
                </Button>
              ) : (
                ''
              )}
              <Button
                className="n-add-btn"
                sx={{
                  borderRadius: '5px !important',
                  float: 'right',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '0.9em !important',
                  height: '35px !important',
                  margin: '15px',
                  width: '149px !important',
                }}
                onClick={() => submitOn()}>
                Submit
              </Button>
            </Typography>
          </DialogActions>
        ) : (
          ''
        )}
      </CustomModalView>
    </>
  );
};
