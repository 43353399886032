import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import EditIcon from '@mui/icons-material/Edit';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import { instantState } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
// import { getPetitionApiData } from 'utils/Handlers';
import { alertOptions } from 'utils/helper';

import useStyles from '../../../../PrivateStyle';

type TableProps = {
  showType;
  lists;
  setLoader?: any;
  hasMoreResults?: boolean;
  getMoreLoader?: boolean;
  getMoreList: any;
  setListData?: any;
};
const TableView: React.FC<TableProps> = ({
  showType,
  lists,
  setLoader,
  hasMoreResults: HasMoreResults,
  getMoreLoader,
  getMoreList,
  setListData,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const { editId } = useParams();
  const location: any = useLocation();
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [deleteConfirm, setDeleteConfirm] = React.useState({
    deleteId: '',
    info: {
      lead_status: '',
    },
    show: false,
  });

  const deleteLead = async () => {
    setDeleteLoader(true);
    const deletConfirm = await instantState(setDeleteConfirm);
    deleteData(
      '',
      `${String(COMMON_ENDPOINT.DeleteLead)}/${String(deletConfirm.deleteId)}`,
    )
      .then(() => {
        setDeleteConfirm((pre) => ({
          ...pre,
          show: false,
        }));
        setDeleteLoader(false);
        toast.success('Deleted successfully', alertOptions);
        showType === 'leads' && setListData
          ? setListData((pre) => ({
              ...pre,
              data: [...pre.data.filter((e) => e.id !== deletConfirm.deleteId)],
            }))
          : '';
      })
      .catch(() => {
        setDeleteLoader(false);
      });
    setDeleteLoader(false);
  };
  return (
    <TableContainer
      sx={{
        backgroundColor: 'unset !important',
        borderRadius: '10px 10px 0px 0px',
      }}>
      {deleteLoader && <div className="liberty-loader" />}
      <Typography
        component={'div'}
        className={classes.scrollbox}
        id="scrollParent">
        <InfiniteScroll
          dataLength={
            lists && lists.data && lists.data.length ? lists.data.length : 0
          }
          style={{
            overflow: 'unset',
          }}
          next={getMoreList && getMoreList}
          hasMore={HasMoreResults}
          scrollableTarget={'scrollParent'}
          loader={
            getMoreLoader && (
              <div
                style={{
                  backgroundColor: '#ffffff',
                  textAlign: 'center',
                  width: '100%',
                }}>
                <CircularProgress
                  size={30}
                  sx={{ color: '#002c69', marginBottom: 10, marginTop: 10 }}
                />
              </div>
            )
          }
          endMessage>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="lead-table table-hover"
            style={{ minHeight: '106px' }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  {showType === 'leads' ? 'Beneficiary' : 'Title'}
                </TableCell>
                <TableCell>
                  {showType === 'leads' ? 'Petitioner' : 'Organization'}
                </TableCell>
                <TableCell>
                  {showType === 'leads' ? 'Visa type' : 'Notes'}
                </TableCell>
                <TableCell>
                  {showType === 'leads' ? 'Notes' : 'Date Started'}
                </TableCell>
                <TableCell>Status</TableCell>
                {showType === 'leads' ? (
                  <TableCell>Date Started</TableCell>
                ) : (
                  userDetail.role_id !== 2 && <TableCell>ACTION</TableCell>
                )}

                {showType === 'leads' && userDetail.role_id === 3 ? (
                  <TableCell>Delete</TableCell>
                ) : (
                  ''
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {lists.data.map((data, index) => (
                <TableRow
                  key={index}
                  onClick={() => {
                    if (showType === 'petitions') {
                      if (
                        data.isAffilateCase &&
                        [5].includes(data?.affilateRoleId)
                      ) {
                        toast.info('This case has view only access.');
                        return;
                      }
                    } else if (showType === 'leads') {
                      if (
                        data.isAffilateCase &&
                        [5].includes(data?.affilateRoleId)
                      ) {
                        toast.info('This Case has view only access.');
                        return;
                      }
                    }
                    if (showType === 'leads') {
                      if (
                        data.isAffilateCase &&
                        [5].includes(data?.affilateRoleId)
                      ) {
                        toast.info('This Case has view only access.');
                        return;
                      }
                      navigate(
                        userDetail.role_id === 3
                          ? location?.pathname.includes('manage-organization')
                            ? location?.pathname.includes('invited')
                              ? `${
                                  AttorneyPrivateRoutes.MANAGEORGANIZATION
                                    .ORGANIZATIONINVITED
                                    .ORGANIZATIONINVITEDLEADDETAILS
                                }/${String(data.id)}${
                                  editId ? '?orgId=' + editId : ''
                                }`
                              : location.pathname.includes('associated') &&
                                `${
                                  AttorneyPrivateRoutes.MANAGEORGANIZATION
                                    .ORGANIZATIONASSOCIATED
                                    .ORGANIZATIONASSOCIATEDLEADDETAILS
                                }/${String(data.id)}${
                                  editId ? '?orgId=' + editId : ''
                                }`
                            : `${
                                AttorneyPrivateRoutes.LEADS.LEADDETAILSLIST
                              }/${String(data.id)}`
                          : userDetail.role_id === 5
                          ? data?.isAffilateCase && data?.affilateRoleId === 3
                            ? `${String(
                                AffilatePrivateRoutes.ATTORNEY_LEADS
                                  .LEADDETAILSLIST,
                              )}/${String(data.id)}`
                            : `${String(
                                AffilatePrivateRoutes.PETITIONER_LEADS
                                  .LEADDETAILSLIST,
                              )}/${String(data.id)}`
                          : `${String(
                              PetitionerPrivateRoutes.LEADS.LEADDETAILSLIST,
                            )}/${String(data.id)}`,
                      );
                      return;
                    } else {
                      if (
                        data.isAffilateCase &&
                        [5].includes(data?.affilateRoleId)
                      ) {
                        toast.info('This case has view only access.');
                        return;
                      }
                      navigate(
                        userDetail.role_id === 3
                          ? location.pathname.includes('manage-organization')
                            ? location.pathname.includes('invited')
                              ? `${
                                  AttorneyPrivateRoutes.MANAGEORGANIZATION
                                    .ORGANIZATIONINVITED
                                    .ORGANIZATIONINVITEDPETITIONDETAILS
                                }/${String(data.id)}${
                                  editId ? '?orgId=' + editId : ''
                                }`
                              : location.pathname.includes('associated') &&
                                `${
                                  AttorneyPrivateRoutes.MANAGEORGANIZATION
                                    .ORGANIZATIONASSOCIATED
                                    .ORGANIZATIONASSOCITEDPETITIONDETAILS
                                }/${String(data.id)}${
                                  editId ? '?orgId=' + editId : ''
                                }`
                            : `${String(
                                AttorneyPrivateRoutes.PETITIONS.PETITIONLIST,
                              )}/${String(data.id)}`
                          : userDetail.role_id === 5
                          ? `${
                              AffilatePrivateRoutes.PETITIONER_PETITIONS
                                .PETITIONLIST
                            }/${String(data.id)}`
                          : `${String(
                              PetitionerPrivateRoutes.PETITIONS.PETITIONLIST,
                            )}/${String(data.id)}`,
                        { replace: false },
                      );
                      return;
                    }
                  }}>
                  <TableCell
                    className={showType === 'petitions' ? 'W-3' : ''}
                    style={
                      showType === 'leads' &&
                      data.lead_created_through === 'web'
                        ? {
                            borderLeft: '14px solid rgb(0, 98, 255)',
                          }
                        : {}
                    }>
                    <div className="hideshow">
                      {showType === 'leads' ? (
                        !_.isEmpty(data.beneficiaryDetails) ? (
                          data.beneficiaryDetails
                            .map(
                              (x) =>
                                `${String(x.first_name)} ${String(
                                  x.last_name,
                                )}`,
                            )
                            .join(', ')
                        ) : (
                          ''
                        )
                      ) : (
                        <>
                          {data.visa_type} for {data.benificiery_name}{' '}
                          {moment(data.created_at).format('MM/DD/YYYY')}
                        </>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={showType === 'petitions' ? 'W-2' : ''}>
                    {showType === 'leads' ? (
                      <a className="hideshow">
                        {data.org_name ? data.org_name : ''}
                      </a>
                    ) : data.organization_id ? (
                      <a className="hideshow">{data.organization}</a>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  {showType === 'leads' ? (
                    <TableCell>
                      {!_.isEmpty(data.visa_name_list)
                        ? data.visa_name_list.map(
                            (visa, vIndex) => `(${String(visa.visa_code)})`,
                          )
                        : ''}
                    </TableCell>
                  ) : (
                    <TableCell
                      className={showType === 'petitions' ? 'W-15' : ''}>
                      <Typography component={'div'} className="hideshow">
                        {data.notes}
                      </Typography>
                    </TableCell>
                  )}
                  {showType === 'leads' ? (
                    <TableCell>
                      <Typography component={'div'} className="hideshow">
                        {data.notes ? data.notes : ''}
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell
                      className={showType === 'petitions' ? 'W-15' : ''}>
                      {moment(data.created_at).format('MM/DD/YYYY')}
                    </TableCell>
                  )}
                  <TableCell
                    className={showType === 'petitions' ? 'W-1' : ''}
                    sx={{ textTransform: 'capitalize' }}>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: '12px',
                      }}>
                      {showType === 'leads'
                        ? data.lead_status
                          ? data.lead_status
                          : ''
                        : data.status
                        ? data.status
                        : ''}
                      {userDetail?.role_id !== 3 &&
                      data?.isAffilateCase &&
                      showType === 'petitions' ? (
                        <div
                          style={{
                            color: '#002c69',
                            fontStyle: 'italic',
                            marginLeft: '12px',
                          }}>
                          (Affiliate)
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </TableCell>
                  {showType === 'leads' ? (
                    <TableCell sx={{ alignItems: 'center', display: 'flex' }}>
                      {moment(data.created_at).format('MM/DD/YYYY')}{' '}
                      {userDetail?.role_id !== 3 &&
                      data?.isAffilateCase &&
                      showType === 'leads' ? (
                        <div
                          style={{
                            color: '#002c69',
                            fontStyle: 'italic',
                            marginLeft: '12px',
                          }}>
                          (Affiliate)
                        </div>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  ) : (
                    userDetail.userRoleName === 'attorney' &&
                    showType === 'petitions' && (
                      <TableCell
                        className={showType === 'petitions' ? 'W-1' : ''}>
                        {!data?.isAffilateCase ? (
                          <Tooltip title={'Edit'} placement="top">
                            <EditIcon
                              sx={
                                showType === 'petitions'
                                  ? {
                                      cursor: 'pointer',
                                      height: '25px',
                                      width: '25px',
                                    }
                                  : {
                                      cursor: 'pointer',
                                      fill: '#fff',
                                      height: '25px',
                                      width: '25px',
                                    }
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  `${
                                    AttorneyPrivateRoutes.PETITIONS.PETITIONEDIT
                                  }/${String(data.id)}`,
                                );
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <div style={{ fontStyle: 'italic' }}>Affiliate</div>
                        )}
                      </TableCell>
                    )
                  )}

                  {showType === 'leads' &&
                    userDetail.role_id === 3 &&
                    (!data?.isAffilateCase ? (
                      <TableCell
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteConfirm((pre) => ({
                            ...pre,
                            deleteId: data.id,
                            info: data,
                            show: true,
                          }));
                        }}>
                        <Tooltip title="Delete" placement="top">
                          <DeleteForeverSharpIcon
                            style={{ cursor: 'pointer', fill: 'red' }}
                          />
                        </Tooltip>
                      </TableCell>
                    ) : (
                      <TableCell sx={{ fontStyle: 'italic' }}>
                        Affiliate
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </Typography>
      <ConfirmDialog
        title="Confirm Action"
        floatingBtnShow={true}
        open={deleteConfirm.show}
        onConfirm={(act) => {
          if (act !== 'yes') {
            setDeleteConfirm((pre) => ({
              ...pre,
              show: false,
            }));
          } else {
            deleteLead();
          }
        }}
        autoCloseDisable={true}>
        {deleteConfirm?.info?.lead_status === 'petition' ? (
          <>
            <Typography
              component={'p'}
              width="100%"
              sx={{
                color: 'unset',
                fontSize: 'unset',
              }}>
              Are you sure, want to delete this lead?
            </Typography>
            <Typography
              component={'p'}
              width="100%"
              sx={{
                color: 'unset',
                fontSize: 'unset',
              }}>
              Petition is already created. if you delete this lead petition also
              deleted
            </Typography>
          </>
        ) : (
          'Are you sure, want to delete this lead?'
        )}
      </ConfirmDialog>
    </TableContainer>
  );
};

export default TableView;
