// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Typography } from '@mui/material';
import Carrow from 'assets/images/curve-arrow.svg';
import Logo from 'assets/images/liberty-logoheader.png';
import Footer from 'components/Footer';
// import { PublicRoutes } from 'config';
import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { getPublicRequest } from 'utils/Handlers';
import { alertOptions } from 'utils/helper';

const Pricing: React.FC = () => {
  // const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [isChecked, setIsChecked] = useState(false); // false => Monthly, true => Yearly
  const [pricePlans, setPricePlans] = useState<any>([]);

  const choosePlan = (product, price) => {
    const url = `${
      process.env.REACT_APP_BASEURL
    }/payment-signup-form?product-id=${String(product.id)}&price-id=${String(
      price.id,
    )}`;
    window.location.href = url;
    // navigate(PublicRoutes.STRIPE.SIGNUP, {
    //   state: {
    //     price: {
    //       amount: price.unit_amount / 100,
    //       currency: price.currency,
    //       id: price.id,
    //       type: price.recurring.interval,
    //     },
    //     product: product,
    //   },
    // });
  };

  useEffect(() => {
    getPublicRequest(MASTER_ENDPOINT.StripeProductList)
      .then((resp: any) => {
        setPricePlans(resp);
        setLoader(false);
      })
      .catch((e: any) => {
        toast.error(e.error.message, alertOptions);
        setLoader(false);
      });
  }, []);

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <div>
      <Typography
        className=" black-white-text p-f pt-2"
        sx={{
          backgroundColor: '#000',
          height: '84px',
          position: 'sticky',
          top: '0',
        }}>
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <div className="innerBox payment-public">
        <Typography className="pb-title">
          Powerful features for powerful creators
        </Typography>
        <Typography className="pb-title1">
          Choose a plan that{"'"}s right for you
        </Typography>
        {/* <Typography
          component={'div'}
          className="d-flex-ja"
          sx={{ paddingBottom: '30px' }}>
          <Typography className="pb-title2">
            How many users do you have?
          </Typography>
          <Typography className="d-flex-a" sx={{ paddingLeft: '16px' }}>
            <input type="number" className="pay-input" />
            <Tooltip title="Add information" placement="right" arrow>
              <InfoOutlinedIcon
                sx={{
                  fill: 'rgba(25, 82, 167, 1)',
                  fontSize: '16px',
                  marginLeft: '8px',
                }}
              />
            </Tooltip>
          </Typography>
        </Typography> */}
        <Typography component={'div'} className="d-flex-ja">
          <Typography component={'div'} className="d-flex-a">
            {' '}
            <Typography className="pb-bold">Billing cycle: &nbsp;</Typography>
            <Typography className="pb-normal"> Pay Monthly</Typography>
          </Typography>
          <Typography component={'div'} className="pb-rel d-flex-a">
            <label className="switchpay">
              <input
                type="checkbox"
                onChange={(event) => setIsChecked(event.currentTarget.checked)}
                checked={isChecked}
              />
              <span className="slider round" />
            </label>
            <Typography className="pb-normal" sx={{ paddingLeft: '20px' }}>
              Pay Annual
            </Typography>
            <img className="cuarrow" src={Carrow} alt="Carrow" />
            <span className="percen-25">save 25%</span>
          </Typography>
        </Typography>
        <Typography
          component={'div'}
          className="d-flex-ja"
          sx={{ columnGap: '40px', marginTop: '84px' }}>
          {pricePlans.length
            ? pricePlans.map(
                (plan: any, index: number) =>
                  plan.active && (
                    <Typography
                      component={'div'}
                      className="pb-card-box"
                      key={index}>
                      <Typography className="pb-card-title">
                        {plan.name}
                      </Typography>
                      <Typography className="pb-card-info">
                        ({plan.metadata.total_user} users)
                      </Typography>
                      <Typography className="idea-info">
                        {plan.description}
                      </Typography>
                      <Typography className="prise-info">
                        $
                        {isChecked
                          ? Number(plan.price[0].unit_amount) / 100
                          : Number(plan.price[1].unit_amount) / 100}{' '}
                        <sup className="sub">
                          / {isChecked ? 'Year' : 'Month'}
                        </sup>
                      </Typography>
                      <Button
                        className="prise-btn"
                        onClick={() =>
                          choosePlan(
                            plan,
                            isChecked ? plan.price[0] : plan.price[1],
                          )
                        }>
                        Sign Up
                      </Button>
                      <Typography
                        component={'div'}
                        className="d-flex-a"
                        sx={{ marginBottom: '12px' }}>
                        <Typography className="tick-box d-flex-ja">
                          <DoneIcon
                            sx={{
                              fill: 'rgba(25, 82, 167, 1)',
                              fontSize: '14px',
                            }}
                          />
                        </Typography>
                        <Typography className="tick-info">
                          Lorem ipsum dolor sit amet, consetetur
                        </Typography>
                      </Typography>
                      <Typography
                        component={'div'}
                        className="d-flex-a"
                        sx={{ marginBottom: '12px' }}>
                        <Typography className="tick-box d-flex-ja">
                          <DoneIcon
                            sx={{
                              fill: 'rgba(25, 82, 167, 1)',
                              fontSize: '14px',
                            }}
                          />
                        </Typography>
                        <Typography className="tick-info">
                          Lorem ipsum dolor sit amet, consetetur
                        </Typography>
                      </Typography>
                      <Typography
                        component={'div'}
                        className="d-flex-a"
                        sx={{ marginBottom: '12px' }}>
                        <Typography className="tick-box d-flex-ja">
                          <DoneIcon
                            sx={{
                              fill: 'rgba(25, 82, 167, 1)',
                              fontSize: '14px',
                            }}
                          />
                        </Typography>
                        <Typography className="tick-info">
                          Lorem ipsum dolor sit amet, consetetur
                        </Typography>
                      </Typography>
                      <Typography
                        component={'div'}
                        className="d-flex-a"
                        sx={{ marginBottom: '12px' }}>
                        <Typography className="tick-box d-flex-ja">
                          <DoneIcon
                            sx={{
                              fill: 'rgba(25, 82, 167, 1)',
                              fontSize: '14px',
                            }}
                          />
                        </Typography>
                        <Typography className="tick-info">
                          Lorem ipsum dolor sit amet, consetetur
                        </Typography>
                      </Typography>
                      <Typography
                        component={'div'}
                        className="d-flex-a"
                        sx={{ marginBottom: '12px' }}>
                        <Typography className="tick-box d-flex-ja">
                          <DoneIcon
                            sx={{
                              fill: 'rgba(25, 82, 167, 1)',
                              fontSize: '14px',
                            }}
                          />
                        </Typography>
                        <Typography className="tick-info">
                          Lorem ipsum dolor sit amet, consetetur
                        </Typography>
                      </Typography>
                      <Typography
                        component={'div'}
                        className="d-flex-a"
                        sx={{ marginBottom: '12px' }}>
                        <Typography className="tick-box d-flex-ja">
                          <DoneIcon
                            sx={{
                              fill: 'rgba(25, 82, 167, 1)',
                              fontSize: '14px',
                            }}
                          />
                        </Typography>
                        <Typography className="tick-info">
                          Lorem ipsum dolor sit amet, consetetur
                        </Typography>
                      </Typography>
                    </Typography>
                  ),
              )
            : null}
          {/* <Typography component={'div'} className="pb-card-box">
            <Typography className="pb-card-title">Law Firm</Typography>
            <Typography className="pb-card-info">
              (4+ users required)
            </Typography>
            <Typography className="idea-info">
              Ideal for businessess who need personalized services and security
              for large teams.
            </Typography>
            <Typography className="prise-info">
              $1000 <sup className="sub">/ Year</sup>
            </Typography>
            <Button className="prise-btn">Buy Now</Button>
            <Typography
              component={'div'}
              className="d-flex-a"
              sx={{ marginBottom: '12px' }}>
              <Typography className="tick-box d-flex-ja">
                <DoneIcon
                  sx={{ fill: 'rgba(25, 82, 167, 1)', fontSize: '14px' }}
                />
              </Typography>
              <Typography className="tick-info">
                Lorem ipsum dolor sit amet, consetetur
              </Typography>
            </Typography>
            <Typography
              component={'div'}
              className="d-flex-a"
              sx={{ marginBottom: '12px' }}>
              <Typography className="tick-box d-flex-ja">
                <DoneIcon
                  sx={{ fill: 'rgba(25, 82, 167, 1)', fontSize: '14px' }}
                />
              </Typography>
              <Typography className="tick-info">
                Lorem ipsum dolor sit amet, consetetur
              </Typography>
            </Typography>
            <Typography
              component={'div'}
              className="d-flex-a"
              sx={{ marginBottom: '12px' }}>
              <Typography className="tick-box d-flex-ja">
                <DoneIcon
                  sx={{ fill: 'rgba(25, 82, 167, 1)', fontSize: '14px' }}
                />
              </Typography>
              <Typography className="tick-info">
                Lorem ipsum dolor sit amet, consetetur
              </Typography>
            </Typography>
            <Typography
              component={'div'}
              className="d-flex-a"
              sx={{ marginBottom: '12px' }}>
              <Typography className="tick-box d-flex-ja">
                <DoneIcon
                  sx={{ fill: 'rgba(25, 82, 167, 1)', fontSize: '14px' }}
                />
              </Typography>
              <Typography className="tick-info">
                Lorem ipsum dolor sit amet, consetetur
              </Typography>
            </Typography>
            <Typography
              component={'div'}
              className="d-flex-a"
              sx={{ marginBottom: '12px' }}>
              <Typography className="tick-box d-flex-ja">
                <DoneIcon
                  sx={{ fill: 'rgba(25, 82, 167, 1)', fontSize: '14px' }}
                />
              </Typography>
              <Typography className="tick-info">
                Lorem ipsum dolor sit amet, consetetur
              </Typography>
            </Typography>
            <Typography
              component={'div'}
              className="d-flex-a"
              sx={{ marginBottom: '12px' }}>
              <Typography className="tick-box d-flex-ja">
                <DoneIcon
                  sx={{ fill: 'rgba(25, 82, 167, 1)', fontSize: '14px' }}
                />
              </Typography>
              <Typography className="tick-info">
                Lorem ipsum dolor sit amet, consetetur
              </Typography>
            </Typography>
          </Typography> */}
        </Typography>
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;
