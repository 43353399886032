import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import { BeneficiaryPrivateRoutes, PetitionerPrivateRoutes } from 'config';
import parse from 'html-react-parser';
import _ from 'lodash';
import HistoryComments from 'pages/private/Client/Common/HistoryComments';
import { Translate } from 'pages/private/Common/Translate/Translate';
import { WizardForm } from 'pages/private/Common/wizardForm';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  getS3file,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions, phoneNumberFormat } from 'utils/helper';

import { BuildCasePDFEdit } from './PDF';

declare const mammoth: any;

export const PetitionerOrBeneficiaryBuildCaseEdit: React.FC<any> = () => {
  const { petitionId, templateId } = useParams();
  const navigate: any = useNavigate();
  const location: any = useLocation();
  const wizardRef: any = useRef();
  const [loader, setLoader] = useState(true);
  const [htmlState, setHtmlState] = useState('');
  const [linkCodes, setLinkCodes] = useState<any>([]);
  const [pdfDocument, setPdfDocument] = useState('');
  const [treeList, setTreeList] = useState([]);

  const [hcData, setHCData] = useState<any>({
    comments: [],
    history: [],
    loading: true,
    selectedQuestion: {},
  });
  const [isEdited, setIsEdited] = useState(false);
  const [totalPageArr, setTotalPageArr] = React.useState([1]);
  const [currentPageNo, setCurrentPageNo] = React.useState(1);
  const [zoomProps, setZoomProps] = React.useState({
    type: '',
    zoom: 1,
  });
  const [updatedFieldValue, setupdatedFieldValue] = React.useState<
    Array<{ fieldName: string; value: string }>
  >([]);
  const [currentSelectQus, setCurrentSelectQus] = React.useState(null);

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);

  const refs: any =
    linkCodes &&
    linkCodes.reduce((acc, value) => {
      acc[Number(value.id)] = React.createRef();
      return acc;
    }, {});

  const handleDocumentAnswerClick = useCallback(
    (ids: string, type: string) => {
      if (location.state?.data?.fileFormat === 'pdf') {
        return;
      }
      const id = Number(ids.split('_')[1]);
      if (Number(type) === 4) {
        const docEle = document.getElementById(ids);
        if (docEle) {
          const inputEle = document.getElementById(String(id));
          inputEle.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
          inputEle.focus();
          if (docEle.classList.contains('inactive')) {
            docEle.style.border = '2px solid red';
            docEle.style.background = 'bisque';
            docEle.style.borderRadius = '5px';
          } else {
            docEle.style.border = '2px solid #1964CD';
            docEle.style.background = 'bisque';
            docEle.style.borderRadius = '5px';
          }
          inputEle.style.border = '2px solid #1964CD';

          setTimeout(() => {
            inputEle.style.border = 'unset';
            docEle.style.border = 'unset';
          }, 1000);
        }
      }
      if (refs[id] && refs[id].current) {
        refs[id].current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
        refs[id].current.focus();
      }
    },
    [location.state?.data?.fileFormat, refs],
  );

  const handleInputClick = useCallback(
    (id: any) => {
      if (location.state?.data?.fileFormat === 'pdf') {
        return;
      }
      const allHighlightedText: any =
        document.getElementsByClassName('parsed_highlight');
      for (let i = 0; i < allHighlightedText.length; i++) {
        if (Number(allHighlightedText[i]?.id.split('_')[1]) === Number(id)) {
          if (allHighlightedText[i].classList.contains('inactive')) {
            allHighlightedText[i].classList.add('inactive-selected');
          } else {
            allHighlightedText[i].classList.add('active-selected');
          }
          document.getElementById(allHighlightedText[i]?.id).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        } else {
          allHighlightedText[i].classList.remove('inactive-selected');
          allHighlightedText[i].classList.remove('active-selected');
        }
      }
    },
    [location.state?.data?.fileFormat],
  );
  const applyHighlightedContent = (checked, answer) => {
    const allHighlightedText: any =
      document.getElementsByClassName('parsed_highlight');
    for (let i = 0; i < allHighlightedText.length; i++) {
      if (
        allHighlightedText[i].attributes['question-name'].value ===
        checked.question
      ) {
        if (checked.response_type !== 13) {
          if (checked.response_type === 2) {
            allHighlightedText[i]['outerHTML'] = allHighlightedText[i][
              'outerHTML'
            ].replace(/span/g, 'div');
            allHighlightedText[i].style.whiteSpace = 'break-spaces';
            allHighlightedText[i].style.paddingLeft = '10px';
          }
          allHighlightedText[i]['innerText'] = answer
            ? answer
            : checked.question;
        } else {
          if (JSON.parse(answer).length === 1 && JSON.parse(answer)[0] === '') {
            allHighlightedText[i]['innerText'] = checked.question;
          } else {
            const Ids = allHighlightedText[i].attributes['id'].value;
            const ul = document.getElementById(String(Ids));
            ul.innerHTML = '';
            try {
              JSON.parse(answer).map((item) => {
                if (Number(Ids.split('_')[1]) === checked.id) {
                  const li = document.createElement('li');
                  li.appendChild(document.createTextNode(String(item)));
                  li.setAttribute(
                    'style',
                    'background-color: #FFF !important;',
                  );
                  ul.appendChild(li);
                  //   return;
                }
              });
            } catch (error) {
              console.log('error', error);
            }
          }
        }

        const copyAnswer = answer.slice();
        const hasWhiteSpace = copyAnswer.replace(/\s/g, '');
        if (
          allHighlightedText[i].classList.contains('inactive') &&
          hasWhiteSpace
        ) {
          allHighlightedText[i].classList.remove('inactive');
          allHighlightedText[i].classList.add('active');

          if (allHighlightedText[i].classList.contains('inactive-selected')) {
            allHighlightedText[i].classList.remove('inactive-selected');
          }
        }
        if (!hasWhiteSpace) {
          allHighlightedText[i].classList.remove('active-selected');
          allHighlightedText[i].classList.remove('active');
          allHighlightedText[i].classList.add('inactive');
        }
      }
    }
  };

  // const applyHighlightedContent = (checked, answer) => {
  //   const allHighlightedText: any =
  //     document.getElementsByClassName('parsed_highlight');
  //   for (let i = 0; i < allHighlightedText.length; i++) {
  //     if (
  //       allHighlightedText[i].attributes['question-name'].value ===
  //       checked.question
  //     ) {
  //       allHighlightedText[i]['innerText'] = answer ? answer : checked.question;
  //       if (allHighlightedText[i].classList.contains('inactive') && answer) {
  //         allHighlightedText[i].classList.remove('inactive');
  //         allHighlightedText[i].classList.add('active');

  //         if (allHighlightedText[i].classList.contains('inactive-selected')) {
  //           allHighlightedText[i].classList.remove('inactive-selected');
  //         }
  //       }
  //       if (!answer) {
  //         allHighlightedText[i].classList.add('inactive');
  //       }
  //     }
  //   }
  // };
  const submitApprove = async () => {
    setLoader(true);
    const buildDocId = location.state?.data?.document?.latestBuild[0]?.id;
    try {
      await updateData(
        buildDocId,
        {
          documentFor: [userDetail.role_id],
          documentName: location.state?.data?.document?.current_formName,
          documentStatus: isEdited ? 'pending' : 'approved',
          petitionId: petitionId,
        },
        COMMON_ENDPOINT.DocumentBuildSendTo,
      );
      toast.info('Your document has been sent for review.', alertOptions);
      userDetail?.role_id === 2
        ? navigate(
            `${String(
              PetitionerPrivateRoutes.PETITIONS.REVIEWANDAPPROVE,
            )}/${String(petitionId)}`,
          )
        : navigate(
            `${String(BeneficiaryPrivateRoutes.REVIEWANDAPPROVE)}/${String(
              petitionId,
            )}?leadUser=${String(location.state?.data?.leadUserId)}`,
          );
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  // const escapeHtml = (value) => {
  //   return value.match(
  //     /(?<=(&lt;&lt;&lt;|&#60;&#60;&#60;)).*?(?=((&gt;.*?&gt;.*?&gt;)|(&#62;.*?&#62;.*?&#62;)))/gs,
  //   );
  // };

  const getLinkDataForHTMLParsing = (latestDocId: string) => {
    getAllListData(
      `${String(COMMON_ENDPOINT.DocumentBuild)}/${String(petitionId)}/${String(
        petitions.petitionData?.beneficiary_id,
      )}/${String(templateId)}?leadId=${String(
        petitions.petitionData?.lead_id,
      )}&jsonView=true&latestDocumentId=${latestDocId}`,
    )
      .then((res: any) => {
        const linkCodesResp = res.data;
        const obj = {
          key: location.state?.data?.document?.template_file_path,
        };

        setLoader(true);
        getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
          .then((data: any) => {
            return data.arrayBuffer();
          })
          .then((ab) => {
            mammoth
              .convertToHtml({ arrayBuffer: ab })
              .then(async (result) => {
                let htmlContent = result.value;
                const apiRes = await addNewData(
                  { htmlString: htmlContent },
                  `${String(COMMON_ENDPOINT.DocumentBuild)}/document-key`,
                );
                let replacedContentArr =
                  apiRes && Array.isArray(apiRes) ? apiRes : [];
                replacedContentArr =
                  replacedContentArr &&
                  Array.isArray(replacedContentArr) &&
                  replacedContentArr.filter((field, ind) => {
                    if (field && field.startsWith('%')) {
                      htmlContent = htmlContent.replace(
                        field.replace(/ *<.*?> */g, '').trim(),
                        '',
                      );
                      return;
                    } else if (field && field.startsWith('#')) {
                      htmlContent = htmlContent.replace(
                        field.replace(/ *<.*?> */g, '').trim(),
                        '',
                      );
                      return;
                    } else if (field && field.startsWith('/')) {
                      htmlContent = htmlContent.replace(
                        field.replace(/ *<.*?> */g, '').trim(),
                        '',
                      );
                      return;
                    } else {
                      return field.replace(/ *<.*?> */g, '').trim();
                    }
                  });
                const filterArr: Array<any> =
                  res.data && Array.isArray(res.data) && res.data.length
                    ? res.data
                        .filter((ch: any) => ch.link_code && !ch.disable)
                        .map((e: any) => ({
                          ...e,
                          previousAnswer: e.value,
                        }))
                    : [];
                const finalModifiedArr =
                  replacedContentArr && Array.isArray(replacedContentArr)
                    ? replacedContentArr
                        .filter(
                          (re, index) =>
                            replacedContentArr.indexOf(re) === index,
                        )
                        .reduce((previousArr, currentI) => {
                          const obj = filterArr.find(
                            (o) =>
                              String(o.docField).trim() ===
                              String(currentI).trim(),
                          );
                          if (obj) {
                            previousArr.push(obj);
                          }
                          return previousArr;
                        }, [])
                    : [];
                setLinkCodes(finalModifiedArr);
                const WizardArr = [
                  {
                    name: '',
                    order: 1,
                    questionDetails: finalModifiedArr,
                    question_id:
                      finalModifiedArr &&
                      Array.isArray(finalModifiedArr) &&
                      finalModifiedArr.length
                        ? finalModifiedArr
                            .filter((ch: any) => ch.link_code && !ch.disable)
                            .map((e: any) => e.id)
                        : [],
                    section_approve: false,
                    section_id: 1,
                    section_start_Count: 1,
                    visa_form_id: 8,
                  },
                ];
                setTreeList(WizardArr);
                let final: string = htmlContent;
                replacedContentArr &&
                  Array.isArray(replacedContentArr) &&
                  replacedContentArr.map((ch: string, index: number) => {
                    const code = linkCodesResp.find(
                      (e) =>
                        String(e.docField).toLowerCase().trim() ===
                        String(ch).toLowerCase().trim(),
                    );

                    if (!_.isEmpty(code) && !_.isUndefined(code)) {
                      if (
                        code.disable ||
                        (!code.disable && _.isNull(code.link_code))
                      ) {
                        const regex = RegExp(`&lt;${ch}&gt;`, 'g');
                        final = final.replaceAll(
                          regex,
                          `<span class="parsed_highlight_disabled" id="${String(
                            index,
                          ).concat(
                            `_${String(code.id)}`,
                          )}" question-name="${String(code.question)}">${
                            code.value && !code.response_type
                              ? _.isArray(code?.value)
                                ? `<ul>${String(
                                    code?.value.map((item) => {
                                      return `<li>${String(
                                        // eslint-disable-next-line prettier/prettier
                                        item[code?.docField],
                                      )}</li>`;
                                    }),
                                  )}</ul>`
                                : String(code?.value)
                              : code?.value
                              ? String(code?.value)
                              : String(
                                  code?.question
                                    ? code?.question
                                    : code?.docField,
                                )
                          }</span>`,
                        );
                        return;
                      } else {
                        const regex = RegExp(`&lt;${ch}&gt;`, 'g');
                        final = final.replaceAll(
                          regex,
                          `<span class="parsed_highlight ${
                            code.value ? 'active' : 'inactive'
                          }" id="${String(index).concat(
                            `_${String(code.id)}`,
                          )}" question-name="${String(
                            code.question,
                          )}" response-type="${String(code.response_type)}">${
                            code.value && !code.response_type
                              ? _.isArray(code?.value)
                                ? `<ul>${String(
                                    code?.value.map((item) => {
                                      return `<li>${String(
                                        item[code?.docField],
                                      )}</li>`;
                                    }),
                                  )}</ul>`
                                : String(code?.value)
                              : code?.value
                              ? String(code?.value)
                              : String(
                                  code?.question
                                    ? code?.question
                                    : code?.docField,
                                )
                          }</span>`,
                        );
                        return;
                      }
                    }
                  });
                const reactElement: any = parse(
                  String(final)
                    .replace(/&lt;/g, '')
                    .replace(/&gt;/g, '')
                    .replace(/&lt;&lt;&lt;/g, '')
                    .replace(/&gt;&gt;&gt;/g, ''),
                );

                setHtmlState(reactElement);
                setLoader(false);
              })
              .catch((e) => {
                toast.error(e, alertOptions);
                setLoader(false);
              });
          })
          .catch((err) => {
            setLoader(false);
            toast.error(
              err ? err : 'Oops!!! Something went wrong',
              alertOptions,
            );
          });
      })
      .catch((err) => {
        toast.error(err, alertOptions);
        setLoader(false);
      });
  };

  const updateHtmlListener = useCallback(() => {
    const allHighlightedText: any =
      document.getElementsByClassName('parsed_highlight');
    for (let i = 0; i < allHighlightedText.length; i++) {
      allHighlightedText[i].addEventListener(
        'click',
        (e) => {
          if (e.target.classList[1] !== 'disabled') {
            const type =
              allHighlightedText[i].attributes['response-type'].value;
            const Ids = allHighlightedText[i]?.id;
            handleDocumentAnswerClick(Ids, type);
          }
        },
        false,
      );
    }
  }, [handleDocumentAnswerClick]);

  const updateValue = (value: string, qusId: number) => {
    addNewData(
      {
        answer: Array.isArray(value) ? JSON.stringify(value) : value,
        formId: Number(templateId),
        latestDocumentId: location.state?.data?.document?.latestBuild[0]?.id,
        petitionId: petitionId,
        questionId: Number(qusId),
      },
      COMMON_ENDPOINT.DraftDocument,
    )
      .then((draftData) => {
        setIsEdited(true);
        setupdatedFieldValue(
          treeList[0].questionDetails
            .filter((e) => e.id === qusId)
            .reduce((acc, currentVal) => {
              if (currentVal.pdf_field_name) {
                acc.push({
                  exportValue: '',
                  fieldName: currentVal.pdf_field_name,
                  value: [14].includes(currentVal.response_type)
                    ? phoneNumberFormat(value ? value : '')
                    : value,
                });
              } else if (
                currentVal.multiple_pdf_field &&
                Array.isArray(currentVal.multiple_pdf_field)
              ) {
                try {
                  const arr = JSON.parse(value);
                  if (arr && arr.length) {
                    arr.map((subQus) => {
                      acc.push({
                        exportValue: subQus.exportValue,
                        fieldName: subQus.pdf_field_name,
                        value: subQus.status,
                      });
                    });
                  }
                } catch (error) {
                  console.warn('Someting went wrong');
                }
              }
              return acc;
            }, []),
        );
      })
      .catch((err) => {
        toast.error(err, alertOptions);
      });
  };
  useEffect(() => {
    if (htmlState) {
      updateHtmlListener();
    }
    return () => {
      // component Did mount
      const allHighlightedText: any =
        document.getElementsByClassName('parsed_highlight');
      for (let i = 0; i < allHighlightedText.length; i++) {
        allHighlightedText[i].removeEventListener('click', () => {}, false);
      }
    };
  }, [htmlState, updateHtmlListener]);

  useEffect(() => {
    if (!_.isUndefined(petitionId) && !_.isEmpty(petitions)) {
      setIsEdited(location.state?.data?.document?.isEdited ? true : false);
      if (location.state?.data?.document?.file_format === 'pdf') {
        setPdfDocument(
          String(process.env.REACT_APP_DOCUMENT_URL) +
            String(location.state?.data?.document?.templateid),
        );
        setLoader(false);
      } else {
        getLinkDataForHTMLParsing(
          location.state?.data?.document?.latestBuild &&
            Array.isArray(location.state?.data?.document?.latestBuild) &&
            location.state?.data?.document?.latestBuild.length &&
            location.state?.data?.document?.latestBuild[0]
            ? location.state?.data?.document?.latestBuild[0]?.id
            : '',
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitions]);

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Grid container>
      <Grid
        item
        md={10}
        sm={10}
        className="sticky-tab"
        sx={{ marginBottom: '.5rem' }}>
        <Typography component={'div'}>
          <Typography
            component={'div'}
            className="d-flex-ja "
            sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography component={'div'}>
              {location.state?.data?.fileFormat === 'pdf' ? (
                <>
                  {zoomProps.zoom <= 0.25 ? (
                    ''
                  ) : (
                    <Tooltip title={'ZoomOut'} placement="top">
                      <ZoomOutIcon
                        fontSize={'large'}
                        sx={{
                          fill: '#fff',
                          ...(zoomProps.zoom <= 0.25
                            ? { cursor: 'not-allowed', opacity: 0.5 }
                            : { cursor: 'pointer' }),
                        }}
                        onClick={() =>
                          zoomProps.zoom <= 0.25
                            ? ''
                            : setZoomProps((pre) => ({
                                type: 'out',
                                zoom: pre.zoom - 0.25,
                              }))
                        }
                      />
                    </Tooltip>
                  )}
                  <Tooltip title={'ZoomIn'} placement="top">
                    <ZoomInIcon
                      fontSize={'large'}
                      sx={{
                        cursor: 'pointer',
                        fill: '#fff',
                      }}
                      onClick={() =>
                        setZoomProps((pre) => ({
                          type: 'in',
                          zoom: pre.zoom + 0.25,
                        }))
                      }
                    />
                  </Tooltip>
                </>
              ) : (
                ''
              )}
            </Typography>
            {location.state?.data?.document?.file_format === 'pdf' ? (
              <Typography
                component={'div'}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}>
                <Typography
                  component={'label'}
                  sx={{
                    color: '#ffff',
                    paddingRight: '12px',
                  }}>
                  Jump to page
                </Typography>
                <CustomSelect
                  searchable={false}
                  isClearable={false}
                  value={String(currentPageNo)}
                  options={
                    !_.isEmpty(totalPageArr)
                      ? totalPageArr.map((e) => ({ label: e, value: e }))
                      : []
                  }
                  onChange={(e) => {
                    window.scrollTo(0, 0);
                    setCurrentPageNo(e.target.value);
                  }}
                  placeHolder={''}
                  className={'custom-search-input bo-rles'}
                />
              </Typography>
            ) : (
              ''
            )}

            <Typography component={'div'}>
              <Button className="cancel-btn mr-2" onClick={() => navigate(-1)}>
                <Typography className="o-box" />
                Cancel
              </Button>
              {location.state?.data?.fileFormat !== 'pdf' ? (
                <Button
                  className="cancel-btn mr-2"
                  onClick={() => {
                    const oNewWin = window.open(
                      'about:blank',
                      '_blank',
                      'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=700,height=450',
                    );
                    oNewWin.document.open();
                    oNewWin.document.write(
                      document.getElementById('buildedit').innerHTML,
                    );
                    const style = document.createElement('style');
                    style.type = 'text/css';
                    style.innerHTML =
                      '.buildedit { background-color: #ffffff; padding: 2.5rem; p,a,span,label,li {font-size: 18px !important;} .o-text {color: #343434 !important;opacity: 0.8;line-height: 34px !important;}} .font-bold { font-family: Arial-Bold !important; } .f-30 {font-size: 30px !important;} .mb-1 {margin-bottom: 0.5rem !important;} .mt-1 {margin-top: 0.5rem !important;} .parsed_highlight.active {cursor: pointer; color: #4f72f1; font-size: 18px !important; font-family: "Arial-Regular" !important; padding: 4px 2px; min-height: 38px;} .parsed_highlight.inactive {cursor: pointer;color: red;font-size: 18px !important;font-family: "Arial-Regular" !important; padding: 4px 2px;min-height: 38px; } .parsed_highlight_disabled {background: rgba(177, 177, 177, 0.301); cursor: not-allowed;}';
                    oNewWin.document
                      .getElementsByTagName('head')[0]
                      .appendChild(style);
                    oNewWin.document.close();
                    return false;
                  }}>
                  <Typography className="o-box" />
                  Preview
                </Button>
              ) : (
                ''
              )}
              <Button className="add-btn" onClick={submitApprove}>
                {isEdited ? 'Send for Review' : 'Approve'}
              </Button>
            </Typography>
          </Typography>
        </Typography>
      </Grid>

      <Grid
        item
        md={6}
        sm={10}
        sx={{ paddingRight: '.5rem' }}
        id="inline-edit-pdf-X56T78I09P">
        {location.state?.data?.document?.file_format === 'pdf' ? (
          <Typography
            component={'div'}
            sx={{
              maxHeight: '70vh',
              overflowX: 'auto',
              overflowY: 'auto',
            }}>
            <BuildCasePDFEdit
              pdfDocument={pdfDocument}
              petitionId={petitionId}
              beneficiaryId={petitions.petitionData?.beneficiary_id}
              leadId={petitions.petitionData?.lead_id}
              templateId={templateId}
              // dependentUserId={
              //   onlyShow &&
              //   onlyShow === 'dependent' &&
              //   !_.isUndefined(depUserId)
              //     ? depUserId
              //     : ''
              // }
              // onlyShow={onlyShow}
              setTotalPageArr={setTotalPageArr}
              pageNo={currentPageNo}
              setCurrentPageNo={setCurrentPageNo}
              zoomProps={zoomProps}
              setTreeList={setTreeList}
              treeList={treeList}
              selectedQus={currentSelectQus}
              updatedFieldValue={updatedFieldValue}
              setupdatedFieldValue={setupdatedFieldValue}
              setZoomProps={setZoomProps}
              latestTemplateId={
                location.state?.data?.document?.latestBuild[0]?.id
              }
            />
          </Typography>
        ) : (
          <Typography component={'div'} id="buildedit">
            <Typography
              component={'div'}
              className="buildedit"
              sx={{
                maxHeight: '70vh',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}>
              <Typography className="font-bold f-30 mb-2">
                {location.state?.data?.document?.template_name}
              </Typography>
              <Typography className="f-20 mb-2 mt-2 o-text">
                {htmlState !== '' ? htmlState : pdfDocument}
              </Typography>
            </Typography>
          </Typography>
        )}
      </Grid>

      <Grid item md={4} sm={10}>
        <Typography
          component={'div'}
          sx={{
            maxHeight: '70vh',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
          <WizardForm
            focusElement={
              location.state?.focusElement ? location.state?.focusElement : null
            }
            ref={wizardRef}
            formType={'configureForms'}
            list={treeList}
            tabHidden={true}
            formId={petitions.petitionData.petitioner_form_id}
            beneficiaryID={petitions.petitionData.beneficiary_id}
            leadId={petitions.petitionData.lead_id}
            petitionID={petitions.petitionData.id}
            hiddenMargin={true}
            showManageOption={true}
            sectionApproveHide={true}
            handleClickToScroll={(e) => handleInputClick(e)}
            inputRefs={refs}
            draftCall={true}
            setCurrentSelectQus={setCurrentSelectQus}
            highlightText={(id, value) => {
              if (location.state?.data?.fileFormat === 'pdf') {
                updateValue(value, id);
                return;
              }
              const checkId = linkCodes.find(
                (code) => Number(id) === Number(code.id) && !code.disable,
              );
              if (
                checkId &&
                (_.isNull(checkId.value) ? '' : checkId.value) !== value
              ) {
                const copyValue = value.slice();
                const hasWhiteSpace = copyValue.replace(/\s/g, '');
                if (hasWhiteSpace) {
                  addNewData(
                    {
                      answer: Array.isArray(value)
                        ? JSON.stringify(value)
                        : value,
                      formId: Number(templateId),
                      latestDocumentId:
                        location.state?.data?.document?.latestBuild[0]?.id,
                      petitionId: petitionId,
                      questionId: Number(checkId.id),
                    },
                    COMMON_ENDPOINT.DraftDocument,
                  )
                    .then((draftData) => {
                      setIsEdited(true);
                    })
                    .catch((err) => {
                      toast.error(err, alertOptions);
                    });
                }

                applyHighlightedContent(checkId, value);
                setLinkCodes([
                  ...linkCodes.map((el) => {
                    if (el.id === Number(id)) {
                      el.value = value;
                    }
                    return el;
                  }),
                ]);
              }
            }}
            setHCData={setHCData}
          />
        </Typography>
      </Grid>

      <Grid item md={2} sm={12}>
        <Typography component={'div'} className="add-inner">
          <Typography component={'div'} className="bulk-add-box">
            <Translate />
            {hcData.selectedQuestion && (
              <HistoryComments
                hcData={hcData}
                setHCData={setHCData}
                wizardRef={wizardRef}
                tabName={''}
                screenName={'build-case'}
                type={
                  location.state?.showType === 'leads' ? 'lead' : 'petition'
                }
                formId={templateId}
                leadId={null}
                beneficiaryId={null}
                petitionID={petitionId}
                affilateUserList={
                  petitions?.petitionData?.isAffilateCase
                    ? petitions?.petitionData?.affilateUserList
                    : []
                }
              />
            )}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PetitionerOrBeneficiaryBuildCaseEdit;
