import { Grid, Typography } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';

export const Footer: React.FC<any> = ({ menuOpen }): ReactElement => {
  const navigate = useNavigate();
  const [versionId, setVersionId] = useState<any>('');

  useEffect(() => {
    getAllListData(COMMON_ENDPOINT.Version_History)
      .then((resp: any) => {
        setVersionId(resp.data[0].version_id);
      })
      .catch((e: any) => {});
  }, []);

  return (
    <Grid container>
      <Grid
        item
        md={12}
        style={
          menuOpen
            ? {
                pointerEvents: 'none',
              }
            : {}
        }>
        <Typography
          className="l-footer"
          component={'div'}
          style={{
            alignItems: 'center',
            color: '#fff !important',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            zIndex: '1',
          }}>
          <Typography
            component={'div'}
            style={{ alignItems: 'center', display: 'flex', flex: '1' }}>
            <Typography component={'div'}>
              Copyright {new Date().getFullYear()}{' '}
              <Typography
                sx={{ color: '#fff' }}
                component={'span'}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => window.open('http://onlinevisas.com/')}>
                OnlineVisas
              </Typography>
              , Inc.{' '}
            </Typography>
            <Typography
              component={'div'}
              style={{
                borderLeft: '2px solid #ffffff',
                cursor: 'pointer',
                marginLeft: '.5rem',
                paddingLeft: '.5rem',
                textDecoration: 'underline solid #fff',
              }}>
              {' '}
              <a
                style={{ color: '#fff' }}
                href={`mailto:${String(process.env.REACT_APP_CONTACT_MAIL)}`}>
                {String(process.env.REACT_APP_CONTACT_MAIL)}
              </a>
            </Typography>
          </Typography>
          <Typography
            component={'div'}
            style={{
              alignItems: 'center',
              display: 'flex',
              flex: '1',
              justifyContent: 'space-between',
            }}
            className="div">
            <Typography
              component={'div'}
              onClick={() => {
                navigate('/privacy-policy');
              }}>
              Privacy Policy
            </Typography>
            <Typography
              component={'div'}
              onClick={() => {
                navigate('/dmca-policy');
              }}>
              DMCA Policy
            </Typography>
            <Typography
              component={'div'}
              onClick={() => {
                navigate('/acceptable-use-policy');
              }}>
              Acceptable Use Policy
            </Typography>
            <Typography
              component={'div'}
              onClick={() => {
                navigate('/client-agreement');
              }}>
              Terms of Service
            </Typography>
            <Typography
              component={'div'}
              onClick={(e) => {
                navigate('/version-history/details');
              }}>
              V{versionId}
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
