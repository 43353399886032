import { CircularProgress, DialogContent, Typography } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomModalView from 'components/CustomModalView/customModalView';
import { AttorneyPrivateRoutes } from 'config';
import { instantState, useDebounce } from 'hooks';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import {
  COMMON_ENDPOINT,
  PETITION_ADD_ADDITIONAL_TEMPLATE,
  SETUP_ENDPOINT,
} from 'utils/Constant';

type Props = {
  modalOnClose: () => void;
  petitionId: string;
  depUserId: string;
  onlyShow: string;
  existingTemplateList: number[];
};
export const AddAdditionalBuildCaseTemplate: React.FC<any> = (props: Props) => {
  const {
    modalOnClose,
    petitionId,
    depUserId,
    onlyShow,
    existingTemplateList,
  } = props;

  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const [params, setParams] = React.useState({
    order: 'ASC',
    skip: 0,
    sortby: 'template_name',
    take: 50,
    templateName: '',
    visaType: '',
  });
  const [hasMoreResults, setHasMoreResults] = React.useState(false);
  const [tableLoader, setTableLoader] = React.useState(false);
  const [getMoreLoader, setGetMoreLoader] = React.useState(false);
  const [templateLoader, setTemplateLoader] = React.useState(true);
  const [templateList, setTemplateList] = React.useState({
    data: [],
    total: 0,
  });
  const [visaTypes, setVisaTypes] = React.useState<any>([]);
  const [confirm, setConfirm] = React.useState({
    show: false,
    templateFileFormat: '',
    templateFilePath: '',
    templateId: '',
    templateName: '',
  });
  const [newTemplate, setNewTemplate] = React.useState({
    show: false,
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const debouncedParams = useDebounce(params, 500);

  const getAttorneyDocTemplate = React.useCallback(() => {
    getAllListData(
      `${SETUP_ENDPOINT.DocumentTemplate}/all-template?order=${String(
        debouncedParams.order,
      )}&search=${String(debouncedParams.templateName)}&visaType=${String(
        debouncedParams.visaType,
      )}&skip=${String(debouncedParams.skip)}&take=${String(
        debouncedParams.take,
      )}`,
    )
      .then(async (res) => {
        const { data, total } = res ? res : { data: [], total: 0 };

        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setTemplateList((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
            total,
          }));
        } else {
          const idsEle = document.getElementById('paraentScroll');
          idsEle && (idsEle.scrollTop = 0);
          setTemplateList((pre) => ({
            ...pre,
            data: data,
            total,
          }));
        }
        setTableLoader(false);
        setTemplateLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setTemplateList);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch(() => {
        setTableLoader(false);
        setTemplateLoader(false);
        setGetMoreLoader(false);
        setHasMoreResults(false);
      });
  }, [
    debouncedParams.order,
    debouncedParams.skip,
    debouncedParams.take,
    debouncedParams.templateName,
    debouncedParams.visaType,
  ]);

  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setParams((pre) => ({
        ...pre,
        skip: pre.skip + 50,
      }));
    }
  };

  const addAddtionalTemplate = async () => {
    const confirm = await instantState(setConfirm);
    setTemplateLoader(true);
    addNewData(
      { templateId: confirm?.templateId },
      PETITION_ADD_ADDITIONAL_TEMPLATE(petitionId),
    )
      .then((res) => {
        setTemplateLoader(false);
        modalOnClose();
        setConfirm((pre) => ({
          ...pre,
          show: false,
        }));
        toast.success('Template is added successfully.');
        let url = `${String(
          AttorneyPrivateRoutes.PETITIONS.BUILDCASEEDIT,
        )}/${String(petitionId)}/${String(confirm?.templateId)}`;
        if (onlyShow && onlyShow === 'dependent') {
          url = url + `/${String(depUserId)}`;
        }

        if (searchParams.get('orgId')) {
          url =
            url +
            `?orgId=${String(searchParams.get('orgId'))}${
              searchParams.get('invited')
                ? '&invited=true&isPetition=true'
                : searchParams.get('associated')
                ? '&associated=true&isPetition=true'
                : ''
            }`;
        }

        navigate(url, {
          state: {
            data: {
              fileFormat: confirm?.templateFileFormat,
              pdfId: confirm?.templateId,
              templateFilePath: confirm?.templateFilePath,
              templateName: confirm?.templateName,
            },
          },
        });
      })
      .catch(() => {
        setTemplateLoader(false);
      });
  };

  React.useEffect(() => {
    getAttorneyDocTemplate();
  }, [getAttorneyDocTemplate, debouncedParams]);
  React.useEffect(() => {
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`).then(
      (resp) => {
        setVisaTypes(resp.data);
      },
    );
  }, []);

  return (
    <>
      <CustomModalView
        open={true}
        fullWidth={true}
        isFilters={true}
        maxWidth={'lg'}
        scroll={'paper'}
        visaTypes={visaTypes}
        close={() => modalOnClose()}
        defaultVisaSet={params.visaType}
        navigationLinkShow={true}
        navLinkTitle=" Create New Template "
        onClickNavLink={() =>
          setNewTemplate((pre) => ({
            ...pre,
            show: true,
          }))
        }
        filterVisaOnChange={(searchvalue) => {
          setTableLoader(true);
          setParams((pre) => ({
            ...pre,
            skip: 0,
            take: 50,
            visaType: searchvalue,
          }));
        }}
        filterOnChange={(searchvalue) => {
          setTableLoader(true);
          setParams((pre) => ({
            ...pre,
            skip: 0,
            take: 50,
            templateName: searchvalue,
          }));
        }}
        title="Document Template">
        <DialogContent id="paraentScroll">
          {templateLoader && <div className="liberty-loader" />}
          <InfiniteScroll
            dataLength={
              templateList && templateList.data && templateList.data.length
                ? templateList.data.length
                : 0
            }
            style={{
              overflow: 'unset',
            }}
            next={getMoreList && getMoreList}
            hasMore={hasMoreResults}
            scrollableTarget={'paraentScroll'}
            loader={
              getMoreLoader && (
                <div
                  style={{
                    backgroundColor: '#ffffff',
                    textAlign: 'center',
                    width: '100%',
                  }}>
                  <CircularProgress
                    size={30}
                    sx={{ color: '#002c69', marginBottom: 10, marginTop: 10 }}
                  />
                </div>
              )
            }
            endMessage>
            <table className="lead-table table-hover">
              <thead>
                <tr>
                  <th>TEMPLATE NAME</th>
                  <th>DESCRIPTIONS</th>
                  {/* <th>Type</th> */}
                </tr>
              </thead>
              <tbody>
                {tableLoader ? (
                  <tr>
                    <td colSpan={8}>
                      <div
                        style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          width: '100%',
                        }}>
                        <CircularProgress
                          size={30}
                          sx={{
                            color: '#002c69',
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {templateList &&
                    templateList.data &&
                    templateList.data.length
                      ? templateList.data.map((row: any, index) => (
                          <tr
                            key={index}
                            onClick={(e) =>
                              existingTemplateList &&
                              existingTemplateList.length &&
                              existingTemplateList.includes(row?.id)
                                ? toast.error(
                                    'This template is already mapped.',
                                  )
                                : setConfirm((pre) => ({
                                    ...pre,
                                    show: true,
                                    templateFileFormat: row?.file_format,
                                    templateFilePath: row?.template_file_path,
                                    templateId: row?.id,
                                    templateName: row?.template_name,
                                  }))
                            }
                            style={{ cursor: 'pointer' }}>
                            <td>{row?.template_name}</td>
                            <td>{row?.template_type}</td>
                            {/* <td>{row?.template_added_for}</td> */}
                          </tr>
                        ))
                      : !tableLoader && (
                          <tr className={'text-center'}>
                            <td colSpan={3} align={'center'}>
                              No Record(s) Found.
                            </td>
                          </tr>
                        )}
                  </>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </DialogContent>
      </CustomModalView>

      <ConfirmDialog
        title="Confirm Action"
        floatingBtnShow={true}
        open={confirm.show}
        onConfirm={(act) => {
          if (act !== 'yes') {
            setConfirm((pre) => ({
              ...pre,
              show: false,
            }));
          } else {
            addAddtionalTemplate();
          }
        }}
        autoCloseDisable={true}>
        <>
          <Typography component={'div'} width="100%">
            <div
              style={{
                color: '#939393',
                fontSize: '14px',
                fontWeight: 600,
                padding: '2px 12px',
              }}>
              {' '}
              {confirm.templateName}
            </div>
          </Typography>
          <Typography component={'p'} width="100%">
            Are you sure do you want add this document
          </Typography>
          <Typography component={'p'} width="100%">
            to this petition?
          </Typography>
        </>
      </ConfirmDialog>

      <ConfirmDialog
        title="Confirm Action"
        floatingBtnShow={true}
        open={newTemplate.show}
        onConfirm={(act) => {
          if (act !== 'yes') {
            setNewTemplate((pre) => ({
              ...pre,
              show: false,
            }));
          } else {
            navigate(AttorneyPrivateRoutes.DOCUMENT.DOCUMENTADD, {
              state: {
                templateType: 'document',
                visaTypeID: petitions.petitionData.visa_id,
              },
            });
          }
        }}
        autoCloseDisable={true}>
        {'Do you want proceed now?'}
      </ConfirmDialog>
    </>
  );
};
