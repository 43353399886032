import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
// import Bedit from 'assets/images/bedit.svg';
import CustomInput from 'components/CustomInput/customInput';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import useStyles from '../../../../PrivateStyle';

type ModalProps = {
  open?: any;
  handleClose?: any;
  control;
  errors;
  handleSubmit;
  cancelForm;
  onSubmitForm: (src: any) => void;
  fields?: any;
  fieldAppend?: any;
  fieldRemove?: any;
};

const ResponseTypeModal: FC<ModalProps> = ({
  open,
  handleClose,
  control,
  errors,
  handleSubmit,
  cancelForm,
  onSubmitForm,
  fields,
  fieldAppend,
  fieldRemove,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Typography
          component={'div'}
          className="custom-modal1"
          sx={{
            left: '15%',
            width: '65%',
          }}>
          <Typography className="d-flex-a d-flex-sb mb-2">
            <Typography
              component={'div'}
              sx={{
                color: '#333333 !important',
                fontFamily: 'Arial-Bold !important',
                fontSize: '1.25em !important',
              }}>
              Responses Type
            </Typography>
            <Typography className="d-flex-jae">
              <Typography
                className="d-flex-ja mr-1"
                sx={{
                  color: '#fff !important',
                }}>
                <AddCircleIcon
                  sx={{
                    cursor: 'pointer',
                    fill: '#4E72D1',
                    height: '44px',
                    width: '44px',
                  }}
                  onClick={() => {
                    fieldAppend({
                      id: Math.random().toString(36).substring(2, 9),
                      text: '',
                    });
                  }}
                />
              </Typography>
              <Typography>
                <CancelIcon
                  sx={{
                    cursor: 'pointer',
                    fill: 'gray',
                    height: '44px',
                    width: '44px',
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                />
              </Typography>
            </Typography>
          </Typography>
          <Grid container>
            <Grid item md={12}>
              <form
                id="add-response-type"
                onSubmit={handleSubmit(onSubmitForm)}>
                <Box
                  component="form"
                  className="lead-box"
                  style={{ padding: '1rem !important' }}
                  noValidate>
                  <Grid container>
                    <Grid md={12}>
                      <Typography
                        component={'div'}
                        className={classes.scrollbox}>
                        <Table
                          aria-label="sticky table"
                          className="lead-table"
                          style={{ minHeight: '80px' }}>
                          <TableHead>
                            <TableRow>
                              <TableCell className="text-left" scope="col">
                                S.NO
                              </TableCell>
                              <TableCell className="text-left" scope="col">
                                Response Type
                              </TableCell>
                              <TableCell scope="col">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          {fields.map((item, index) => {
                            return (
                              <TableBody key={item.id}>
                                <TableRow>
                                  <TableCell>{Number(index) + 1}</TableCell>
                                  <TableCell>
                                    <Controller
                                      control={control}
                                      defaultValue={''}
                                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                      name={`response.${index}.text`}
                                      render={({
                                        field: { onChange, value, name, ref },
                                        formState,
                                      }) => {
                                        return (
                                          <CustomInput
                                            placeHolder={'Response'}
                                            name={name}
                                            value={value}
                                            className={'custom-textarea'}
                                            error={
                                              errors.response?.[index]?.text
                                                ?.message || ''
                                            }
                                            onChange={(e) => {
                                              onChange(e);
                                            }}
                                            isTextArea={true}
                                          />
                                        );
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell className="text-center">
                                    <DeleteForeverIcon
                                      sx={{
                                        cursor: 'pointer',
                                        fill: 'red',
                                        height: '25px',
                                        width: '25px',
                                      }}
                                      onClick={() => fieldRemove(index)}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            );
                          })}
                        </Table>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container>
                  <Grid item md={12}>
                    <Typography
                      component={'div'}
                      className="d-flex-jae modal-footer">
                      <Button
                        className="mcancel-btn f-18 font-bold mr-1"
                        onClick={cancelForm}>
                        Cancel
                      </Button>
                      <Button
                        className="madd-btn f-18 font-bold"
                        type="submit"
                        form={'add-response-type'}>
                        Submit
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Typography>
      </Modal>
    </div>
  );
};

export default ResponseTypeModal;
