import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { updateData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

type Props = {
  requiredDocumentSection?: any;
  editId?: any;
  closeDocumentSection?: any;
};
export const SectionDocumentsList: React.FC<Props> = (props: Props) => {
  const { requiredDocumentSection, editId, closeDocumentSection } = props;
  const [sections, setSections] = useState({
    beneficiaryDocs: [],
    data: [],
    dependentDocs: [],
    petitionerDocs: [],
    stakeholder_docs: [],
  });
  const [loader, setLoader] = useState(true);

  const getApiList = useCallback(async () => {
    try {
      const list = await getAllListData(
        `${String(COMMON_ENDPOINT.RequestForRequiredDocument)}/${String(
          editId,
        )}?visaId=${String(requiredDocumentSection?.scheduleData?.visa_id)}`,
      );
      setLoader(false);
      setSections((pre) => ({
        ...pre,
        beneficiaryDocs: [
          ...(list && list.benificiery_docs ? list.benificiery_docs : []),
        ],
        data: [...(list && list.data ? list.data : [])],
        dependentDocs: [
          ...(list && list.dependent_docs ? list.dependent_docs : []),
        ],
        petitionerDocs: [
          ...(list && list.petitioner_docs ? list.petitioner_docs : []),
        ],
      }));
    } catch (error) {
      setLoader(false);
      toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredDocumentSection.scheduleData, editId]);

  const petitionerSelection = (e, data) => {
    const { checked } = e.target;
    const idx = sections.petitionerDocs.findIndex(
      (petitionerId) => Number(petitionerId) === Number(data.id),
    );
    if (idx === -1) {
      sections.petitionerDocs.push(data.id);
    } else {
      sections.petitionerDocs.splice(idx, 1);
    }
    const index = sections.data.findIndex((doc) => doc.id === data.id);
    sections.data[index].petitionerSelected = checked;
    setSections((pre) => ({
      ...pre,
      data: [...sections.data],
      // beneficiaryDocs: [...sections.beneficiaryDocs],
      petitionerDocs: [...sections.petitionerDocs],
    }));
  };
  const beneficiarySelection = (e, data) => {
    const { checked } = e.target;
    const idx = sections.beneficiaryDocs.findIndex(
      (petitionerId) => Number(petitionerId) === Number(data.id),
    );
    if (idx === -1) {
      sections.beneficiaryDocs.push(data.id);
    } else {
      sections.beneficiaryDocs.splice(idx, 1);
    }
    const index = sections.data.findIndex((doc) => doc.id === data.id);
    sections.data[index].beneficiarySelected = checked;
    setSections((pre) => ({
      ...pre,
      beneficiaryDocs: [...sections.beneficiaryDocs],
      data: [...sections.data],
      // petitionerDocs: [...sections.petitionerDocs],
    }));
  };
  const dependentSelection = (e, data) => {
    const { checked } = e.target;
    const idx = sections.dependentDocs.findIndex(
      (petitionerId) => Number(petitionerId) === Number(data.id),
    );
    if (idx === -1) {
      sections.dependentDocs.push(data.id);
    } else {
      sections.dependentDocs.splice(idx, 1);
    }
    const index = sections.data.findIndex((doc) => doc.id === data.id);
    sections.data[index].dependentSelected = checked;
    setSections((pre) => ({
      ...pre,
      data: [...sections.data],
      dependentDocs: [...sections.dependentDocs],
    }));
  };
  const submitDocument = () => {
    const bodyObj = getFormData();
    setLoader(true);
    updateData(editId, bodyObj, COMMON_ENDPOINT.RequestForRequiredDocument)
      .then((resp: any) => {
        closeDocumentSection();
        setLoader(false);
        toast.success('Updated successfully.', alertOptions);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(
          error ? error : 'Oops!!! Something went wrong',
          alertOptions,
        );
      });
  };

  const getFormData = () => {
    return {
      beneficiaryDocuments: sections.beneficiaryDocs,
      dependentDocuments: sections.dependentDocs,
      petitionerDocuments: sections.petitionerDocs,
      stakeHolderDocuments: [],
    };
  };

  useEffect(() => {
    setLoader(true);
    getApiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApiList, editId, requiredDocumentSection.scheduleData]);
  return (
    <>
      <DialogContent>
        {loader && <div className="liberty-loader" />}
        <table className="lead-table table-hover">
          <thead>
            <tr>
              <th scope="col">DOCUMENT TYPE</th>
              <th scope="col">DESCRIPTION</th>
              <th scope="col">BENEFICIARY</th>
              <th scope="col">PETITIONER</th>
              <th scope="col">DEPENDENT</th>
            </tr>
          </thead>
          <tbody>
            {sections && sections.data && sections.data.length > 0 ? (
              sections.data
                ?.filter((e) => e.status === true)
                .map((data, index) => (
                  <tr key={index}>
                    <td>
                      {/* style={{ width: '255px', wordBreak: 'break-all' }} */}
                      <div className="hideshow">{data?.document_type}</div>
                    </td>
                    <td>
                      <div className="hideshow">{data?.description}</div>
                    </td>
                    <td>
                      <Checkbox
                        size={'medium'}
                        disabled={data?.benificieryDocUploaded}
                        checked={data?.beneficiarySelected}
                        onChange={(e) => beneficiarySelection(e, data)}
                      />
                    </td>
                    <td>
                      <Checkbox
                        size={'medium'}
                        disabled={data?.petitionerDocUploaded}
                        checked={data?.petitionerSelected}
                        onChange={(e) => petitionerSelection(e, data)}
                      />
                    </td>
                    <td>
                      <Checkbox
                        size={'medium'}
                        disabled={data?.dependentDocUploaded}
                        checked={data?.dependentSelected}
                        onChange={(e) => dependentSelection(e, data)}
                      />
                    </td>
                  </tr>
                ))
            ) : (
              <tr className={'text-center'}>
                <th scope="row" colSpan={4}>
                  No Record(s) Found.
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
      {sections && sections.data.length > 0 && (
        <DialogActions>
          <Grid item md={12} sm={12} className="d-flex-jae mt-1 pr-2">
            <Typography component={'div'} className="custom-field u-pr-1">
              <Button
                className="n-add-btn f-18 font-bold"
                onClick={() => submitDocument()}>
                Submit
              </Button>
            </Typography>
          </Grid>
        </DialogActions>
      )}
    </>
  );
};

export default SectionDocumentsList;
