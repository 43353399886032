import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { AttorneyPrivateRoutes } from 'config';
// import InfinityScroll from 'components/InfinityScroll';
// import Pagination from 'components/Pagination';
// import { AttorneyPrivateRoutes } from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT, MASTER_ENDPOINT } from 'utils/Constant';

import useStyles from '../../PrivateStyle';

export const ContractFees: React.FC<any> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [params, setParams] = useState<any>({
    attorney: userDetail.userRoleName === 'attorney' ? true : false,
    id: '',
    majorsection: '',
    name: '',
    order: '',
    sectiontype: '',
    skip: 0,
    sortby: '',
    status: 'all',
    take: 50,
    visaId: '',
  });
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [sections, setSections] = useState<any>({
    data: [],
    total: 0,
  });
  const Status = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
    {
      label: 'All',
      value: 'all',
    },
  ];
  const debouncedParams = useDebounce(params, 500);

  // const [totaldatavalue, settotaldatavalue] = useState<number>(1);
  const [visaCode, setVisaCode] = useState<any>([]);

  useEffect(() => {
    getAllListData(
      `${String(MASTER_ENDPOINT.ContractFee)}?id=${String(
        debouncedParams.id,
      )}&name=${String(debouncedParams.name)}&sectiontype=${String(
        debouncedParams.sectiontype,
      )}&status=${String(
        debouncedParams.status === 'all' ? '' : debouncedParams.status,
      )}&majorsection=${String(debouncedParams.majorsection)}&skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}&order=${String(
        debouncedParams.order,
      )}&sortby=${String(debouncedParams.sortby)}&attorney=${String(
        debouncedParams.attorney,
      )}&visaId=${String(debouncedParams.visaId)}`,
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setSections((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        }
        // settotaldatavalue(resp.total);
        else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          const mergeData = !_.isEmpty(sections.data)
            ? [...sections.data, ...resp.data]
            : resp.data;
          setSections({
            count: resp.total,
            data: mergeData,
          });
        }
        // setSections({ data: resp.data, total: resp.total });
        // setVisaCode(resp.data.)
        setTableLoader(false);
        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setSections);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams, userDetail]);
  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };

  useEffect(() => {
    getAllListData(COMMON_ENDPOINT.VisaTypes)
      .then((resp: any) => {
        const visaOptions = [...resp.data];
        setVisaCode(visaOptions);
        // setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Grid container spacing={2}>
      <Grid item md={10} sm={12} sx={{ paddingRight: '30px' }}>
        <Typography
          component={'div'}
          className="d-flex-a w-100 d-flex-sb"
          display="flex"
          justifyContent="flex-end">
          <Typography
            component={'div'}
            className="d-flex-a"
            sx={{ borderRadius: '4px' }}>
            <Typography
              component={'div'}
              sx={{ marginRight: '.5rem', width: '255px' }}>
              <CustomSelect
                searchable={true}
                labelKey={'visa_code'}
                valueKey={'visa_id'}
                options={!_.isEmpty(visaCode) ? visaCode : []}
                onChange={(e) => {
                  setTableLoader(true);

                  setSections({
                    count: 0,
                    data: [],
                  });
                  setParams((prev) => ({
                    ...prev,
                    skip: 0,
                    take: 50,
                    visaId: e.target.value,
                  }));
                }}
                placeHolder={'Select'}
                className={'custom-search-input bo-rles'}
              />
            </Typography>

            <Typography sx={{ width: '165px' }}>
              <CustomInput
                placeHolder={'Type to filter'}
                className={'custom-search-input'}
                value={params.name}
                inputProps={{
                  endAdornment:
                    params.name !== '' ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => {
                            setTableLoader(true);

                            setSections({
                              count: 0,
                              data: [],
                            });
                            setParams((prev) => ({
                              ...prev,
                              name: '',
                              skip: 0,
                              take: 50,
                            }));
                          }}>
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                }}
                onChange={(e) => {
                  setTableLoader(true);

                  setSections({
                    count: 0,
                    data: [],
                  });
                  setParams((prev) => ({
                    ...prev,
                    name: e.target.value,
                    skip: 0,
                    take: 50,
                  }));
                }}
              />
            </Typography>
            <Typography sx={{ marginLeft: '0.5rem', width: '165px' }}>
              <CustomSelect
                searchable={true}
                labelKey={'label'}
                valueKey={'value'}
                isClearable={params.status === 'all' ? false : true}
                value={
                  params.status === 'all'
                    ? 'all'
                    : params.status
                    ? 'active'
                    : 'inactive'
                }
                options={Status}
                selectedValue={''}
                onChange={(e) => {
                  setTableLoader(true);

                  setSections({
                    count: 0,
                    data: [],
                  });
                  setParams((prev) => ({
                    ...prev,
                    skip: 0,
                    status:
                      e.target.value === 'all' || e.target.value === ''
                        ? 'all'
                        : e.target.value === 'active'
                        ? true
                        : false,
                    take: 50,
                  }));
                }}
                placeHolder={'Status'}
                className={'custom-search-input'}
              />
            </Typography>
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={10} sm={12} className="pr-2rem">
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <Typography
            component={'div'}
            sx={{
              borderRadius: '8px 8px 0 0',
            }}
            id="scrollParent"
            className={classes.scrollbox}>
            <InfiniteScroll
              dataLength={
                sections && sections.data && sections.data.length
                  ? sections.data.length
                  : 0
              }
              style={{
                overflow: 'unset',
              }}
              next={getMoreList && getMoreList}
              hasMore={hasMoreResults}
              scrollableTarget={'scrollParent'}
              loader={
                getMoreLoader && (
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      textAlign: 'center',
                      width: '100%',
                    }}>
                    <CircularProgress
                      size={30}
                      sx={{
                        color: '#002c69',
                        marginBottom: 10,
                        marginTop: 10,
                      }}
                    />
                  </div>
                )
              }
              endMessage>
              <table className="lead-table table-hover">
                <thead>
                  <tr>
                    <th className="W-5">Name</th>
                    <th className="W-1">Fees</th>
                    <th className="W-1">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {tableLoader ? (
                    <tr>
                      <td colSpan={8}>
                        <div
                          style={{
                            backgroundColor: '#ffffff',
                            textAlign: 'center',
                            width: '100%',
                          }}>
                          <CircularProgress
                            size={30}
                            sx={{
                              color: '#002c69',
                              marginBottom: 10,
                              marginTop: 10,
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {sections && sections.data && sections.data.length > 0
                        ? sections.data.map((item, index) => (
                            <tr
                              key={index}
                              onClick={() => {
                                navigate(
                                  `${
                                    AttorneyPrivateRoutes.CONTRACT.CONTRACTEDIT
                                  }/${String(item.id)}`,
                                );
                              }}
                              style={{ cursor: 'pointer' }}>
                              <td>
                                <div
                                  className="hideshow"
                                  style={{ width: '80%' }}>
                                  {item.name ? item.name : '-'}
                                </div>
                              </td>
                              <td>
                                {'$'}{' '}
                                {item.value
                                  ? parseFloat(item.value).toFixed(2)
                                  : '-'}
                              </td>
                              <td>{item.status ? 'Active' : 'Inactive'}</td>
                            </tr>
                          ))
                        : !tableLoader && (
                            <tr className={'text-center'}>
                              <td colSpan={4}>No Record(s) Found.</td>
                            </tr>
                          )}
                    </>
                  )}
                </tbody>
              </table>
            </InfiniteScroll>
          </Typography>
        )}
        {/* <Pagination
          total={
            !_.isEmpty(assignAttorneyData?.data)
              ? Number(assignAttorneyData?.total)
              : 0
          }
          skip={params.skip}
          take={params.take}
          setParams={setParams}
        /> */}
        {/* {sections && sections.data.length > 0 ? (
          <InfinityScroll
            paramState={setParams}
            // height={'56vh'}
            scrollableTargetId={'contractFees'}
            data={sections.data}
            count={sections.total}> */}
        {/* <Grid container spacing={1.5}>
              {sections.data.map((contract, index) => (
                <Grid md={6} item key={index}>
                  <Typography component={'div'} className="configureBox">
                    <NavLink
                      to={`${
                        AttorneyPrivateRoutes.CONTRACT.CONTRACTEDIT
                      }/${String(contract.id)}`}>
                      <Typography
                        className="configureItem"
                        sx={{ marginBottom: 'unset !important' }}>
                        <Grid container>
                          <Grid item md={10} sm={10}>
                            <Typography className="black-white-text ctitle">
                              {contract.name}
                            </Typography>
                            <Typography
                              className="black-white-text ctitle"
                              sx={{ marginTop: '1rem !important' }}>
                              ${contract.value}
                            </Typography>
                          </Grid>
                          <Grid item md={2} sm={2} className="d-flex-a">
                            <Typography className="blue-text">
                              {contract.status ? 'Active' : 'Inactive'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    </NavLink>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </InfinityScroll>
        ) : (
          <Typography
            className="note-box2"
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}>
            <Typography className="inb2" />
            <Typography className="black-white-text ctitle hideshow">
              No Record(s) Found.
            </Typography>
          </Typography>
        )} */}
        {/* <Pagination
          total={
            !_.isEmpty(sections?.data)
              ? Number(sections?.total)
              : Number(totaldatavalue + 1)
          }
          skip={params.skip}
          take={params.take}
          setParams={setParams}
        /> */}
      </Grid>
    </Grid>
  );
};

export default ContractFees;
