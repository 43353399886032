import { Grid, Typography } from '@mui/material';
import Varrow from 'assets/images/assarrow.svg';
import Contract from 'assets/images/contract.png';
// import Elipse from 'assets/images/Ellipse.svg';
import Overx from 'assets/images/overx.svg';
// import Path from 'assets/images/Path.svg';
import PathStrategy from 'assets/images/PathStrategy.svg';
import Strategy from 'assets/images/strategy.png';
import Upload from 'assets/images/upload.svg';
import Vx from 'assets/images/vx.svg';
import { AttorneyPrivateRoutes } from 'config';
import { useDebounce } from 'hooks';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { storeOrganizationData } from 'store/actions/LeadsOrPetitionerAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SETUP_ENDPOINT } from 'utils/Constant';

import Leads from '../../../assets/images/leadsBlue.png';
import Petition from '../../../assets/images/PetitionBlue.png';

export const OraganizationOverview: React.FC<any> = () => {
  const { editId } = useParams();
  const dispatch = useAppDispatch();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const organizationData: any = useAppSelector(
    (store: any) => store.organizationData.organization,
  );
  const [params] = useState<any>({
    firmMail: '',
    id: '',
    name: '',
    order: 'ASC',
    refererId: '',
    referredBy: '',
    skip: 0,
    sortby: 'firm_name',
    status: false,
    take: 50,
  });
  const debouncedParams = useDebounce(params, 500);
  const location: any = useLocation();
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes('invited')) {
      getAllListData(
        `${SETUP_ENDPOINT.ManageOrganization}/list?id=${String(
          editId,
        )}&firmMail=${String(debouncedParams.firmMail)}&referredBy=${String(
          debouncedParams.referredBy,
        )}&refererId=${String(debouncedParams.refererId)}&status=${String(
          debouncedParams.status,
        )}&order=${String(debouncedParams.order)}&sortby=${String(
          debouncedParams.sortby,
        )}&name=${String(debouncedParams.name)}&skip=${String(
          debouncedParams.skip,
        )}&take=${String(debouncedParams.take)}`,
      )
        .then((resp: any) => {
          dispatch(storeOrganizationData(resp && resp?.data[0]));
          setLoader(false);
        })
        .catch((e: any) => {
          console.log(e);
          setLoader(false);
        });
    }
    if (location.pathname.includes('associated')) {
      getAllListData(
        `/${String(userDetail?.userRoleName)}${
          SETUP_ENDPOINT.ManageUsers
        }/petitioner?name=${String(debouncedParams.name)}&id=${String(editId)}`,
      )
        .then((resp: any) => {
          dispatch(storeOrganizationData(resp && resp[0]));
          setLoader(false);
        })
        .catch((e: any) => {
          console.log(e);
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  return (
    <Typography component={'div'}>
      {loader ? <div className="liberty-loader" /> : ''}
      <Grid container>
        <Grid item md={10} sm={12} className="pl-0">
          <Typography
            component={'div'}
            className="black-white-text"
            sx={{
              fontFamily: 'Arial-Bold !important',
              fontSize: '1.25em !important',
              margin: '1.5rem 0 1.5rem 0',
            }}>
            Organization : {''}
            {location?.pathname?.includes('invited')
              ? organizationData?.firm_name
              : organizationData?.orgName}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          className="pl-0"
          sx={{ paddingRight: '2rem' }}>
          <Grid container className="o-lead-box">
            <Grid md={4} item className="m-41 m-64 st-1">
              <NavLink
                to={
                  location?.pathname.includes('invited')
                    ? `${String(
                        AttorneyPrivateRoutes.MANAGEORGANIZATION
                          .ORGANIZATIONINVITED.ORGANIZATIONEDIT,
                      )}/${String(editId)}`
                    : `${String(
                        AttorneyPrivateRoutes.MANAGEORGANIZATION
                          .ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCIATEDIT,
                      )}/${String(editId)}`
                }>
                <Typography component={'div'} className={'leadDetailBox '}>
                  <img src={Vx} alt="arrow" className="x-line" />
                  <img src={Overx} alt="arrow" className="r-line" />
                  <img src={Varrow} alt="arrow" className="v-line" />
                  <Typography
                    component={'div'}
                    className="d-flex align-center mb-2">
                    <Typography component={'div'} className="info d-flex-ja">
                      <img src={PathStrategy} alt="path" />
                    </Typography>
                    <Typography className="f-20 f-bold pl-2">
                      {'Organization'}
                    </Typography>
                  </Typography>
                  <Typography component={'div'} className="f-15 dis-text">
                    The names and contact information for the Beneficiary, Legal
                    Team and any other individuals related to the case.
                  </Typography>
                </Typography>
              </NavLink>
            </Grid>
            <Grid md={4} item className="m-41 m-64 pl-0 st-1">
              <Typography
                component={'div'}
                className={'leadDetailBox'}
                onClick={() => {
                  navigate(
                    location?.pathname.includes('invited')
                      ? `${String(
                          AttorneyPrivateRoutes.MANAGEORGANIZATION
                            .ORGANIZATIONINVITED.ORGANIZATIONINVITEDSTRATEGY,
                        )}/${String(editId)}`
                      : `${String(
                          AttorneyPrivateRoutes.MANAGEORGANIZATION
                            .ORGANIZATIONASSOCIATED
                            .ORGANIZATIONASSOCITEDSTRATEGY,
                        )}/${String(editId)}`,
                    {
                      state: {
                        orgId: editId,
                        organization: true,
                      },
                    },
                  );
                }}>
                <img src={Vx} alt="arrow" className="l-line" />
                <img src={Vx} alt="arrow" className="x-line" />
                <img src={Overx} alt="arrow" className="r-line" />
                <img src={Varrow} alt="arrow" className="v-line" />
                <Typography
                  component={'div'}
                  className="d-flex align-center mb-2">
                  <Typography component={'div'} className="info d-flex-ja">
                    <img src={Strategy} alt="path" />
                  </Typography>
                  <Typography className="f-20 f-bold pl-2">
                    Strategy Session
                  </Typography>
                </Typography>
                <Typography component={'div'} className="f-15 dis-text">
                  Attorney and Client collaborate to explore the best strategic
                  approach for an effective case strategy.
                </Typography>
              </Typography>
            </Grid>
            <Grid md={4} item className="m-64 pl-0 pt-0 st-1">
              <Typography
                component={'div'}
                className={'leadDetailBox '}
                onClick={() =>
                  navigate(
                    location?.pathname.includes('invited')
                      ? `${String(
                          AttorneyPrivateRoutes.MANAGEORGANIZATION
                            .ORGANIZATIONINVITED
                            .ORGANIZATIONINVITEDUPLOADDOCUMENT,
                        )}/${String(editId)}`
                      : `${String(
                          AttorneyPrivateRoutes.MANAGEORGANIZATION
                            .ORGANIZATIONASSOCIATED
                            .ORGANIZATIONASSOCIATEDUPLOADDOCUMENT,
                        )}/${String(editId)}`,
                    {
                      state: {
                        orgCommon: true,
                        orgId: editId,
                        organization: true,
                      },
                    },
                  )
                }>
                <Typography component={'div'}>
                  <img src={Vx} alt="arrow" className="l-line" />
                  <img src={Varrow} alt="arrow" className="v-line" />
                  <Typography
                    component={'div'}
                    className="d-flex align-center mb-2">
                    <Typography component={'div'} className="info d-flex-ja">
                      <img src={Upload} alt="path" />
                    </Typography>
                    <Typography className="f-20 f-bold pl-2">
                      Upload Documents
                    </Typography>
                  </Typography>
                  <Typography
                    component={'div'}
                    className="f-15 dis-text hideshow">
                    Upload case documents (e.g. passport, resume) for analysis
                    and integration into the platform’s supporting materials and
                    automatic extraction of answers to questions.
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
            <Grid md={4} item className="m-64 pt-0 st-1">
              <NavLink
                to={
                  location?.pathname.includes('invited')
                    ? `${String(
                        AttorneyPrivateRoutes.MANAGEORGANIZATION
                          .ORGANIZATIONINVITED
                          .ORGANIZATIONINVITEDCONTRACTPAYMENT,
                      )}/${String(editId)}`
                    : `${String(
                        AttorneyPrivateRoutes.MANAGEORGANIZATION
                          .ORGANIZATIONASSOCIATED
                          .ORGANIZATIONASSOCIATEDCONTRACTPAYMENT,
                      )}/${String(editId)}`
                }>
                <Typography component={'div'} className={'leadDetailBox '}>
                  <img src={Overx} alt="arrow" className="r-line" />
                  <Typography
                    component={'div'}
                    className="d-flex align-center mb-2">
                    <Typography component={'div'} className="info d-flex-ja">
                      <img src={Contract} alt="path" />
                    </Typography>
                    <Typography className="f-20 f-bold pl-2">
                      Contract and Payment Portal
                    </Typography>
                  </Typography>
                  <Typography component={'div'} className="f-15 dis-text">
                    Beneficiary or petitioner electronically signs the contract
                    and makes payment instantly online.
                  </Typography>
                </Typography>
              </NavLink>
            </Grid>
            <Grid md={4} item className="m-64 pl-0 pt-0 st-1">
              <Typography
                component={'div'}
                className={'leadDetailBox'}
                onClick={() =>
                  navigate(
                    location?.pathname.includes('invited')
                      ? `${String(
                          AttorneyPrivateRoutes.MANAGEORGANIZATION
                            .ORGANIZATIONINVITED.ORGANIZATIONINVITEDLEAD,
                        )}/${String(editId)}?orgId=${String(editId)}`
                      : `${String(
                          AttorneyPrivateRoutes.MANAGEORGANIZATION
                            .ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCITEDLEAD,
                        )}/${String(editId)}?orgId=${String(editId)}`,
                    {
                      state: {
                        data: location?.state?.data,
                        orgId: editId,
                        organization: true,
                        tabValue: location?.state?.tabValue,
                      },
                    },
                  )
                }>
                <Typography
                  component={'div'}
                  className="d-flex align-center mb-2">
                  <img src={Overx} alt="arrow" className="r-line" />
                  <img src={Leads} alt="arrow" style={{ width: '35px' }} />
                  <Typography className="f-20 f-bold pl-2">
                    {'Leads'}
                  </Typography>
                </Typography>
                <Typography
                  component={'div'}
                  className="f-15 dis-text hideshow">
                  List of cases that have not yet become clients by Beneficiary,
                  Petitioner, Visa Type, Notes, Status and Date Started. Click
                  on any Lead and it will open with options to review, edit and
                  take actions in Lead Information, Upload Documents, Strategy
                  Session and Contract and Payment. You will note Complete
                  Questions and Build Case Stations are not available for Leads
                  until they are promoted to Petitions.
                </Typography>
              </Typography>
            </Grid>
            <Grid md={4} item className="m-64 pl-0 pt-0 st-1">
              <Typography
                component={'div'}
                className={'leadDetailBox'}
                onClick={() =>
                  navigate(
                    location?.pathname?.includes('invited')
                      ? `${String(
                          AttorneyPrivateRoutes.MANAGEORGANIZATION
                            .ORGANIZATIONINVITED.ORGANIZATIONINVITEDPETITION,
                        )}/${String(editId)}?orgId=${String(editId)}`
                      : `${String(
                          AttorneyPrivateRoutes.MANAGEORGANIZATION
                            .ORGANIZATIONASSOCIATED
                            .ORGANIZATIONASSOCITEDPETITION,
                        )}/${String(editId)}?orgId=${String(editId)}`,
                    {
                      state: {
                        associated: location?.pathname?.includes('associated')
                          ? true
                          : false,
                        orgId: editId,
                        organization: true,
                        tabValue: location?.state?.tabValue,
                      },
                    },
                  )
                }>
                <Typography
                  component={'div'}
                  className="d-flex align-center mb-2">
                  <img
                    src={Petition}
                    alt="petition"
                    style={{ width: '35px' }}
                  />
                  <Typography className="f-20 f-bold pl-2">
                    {'Petitions'}
                  </Typography>
                </Typography>
                <Typography
                  component={'div'}
                  className="f-15 dis-text hideshow ">
                  List of cases for clients by Beneficiary, Petitioner, Visa
                  Type, Notes, Status and Date Started. Click on any Petition
                  and it will open with options to review, edit and take actions
                  in Lead Information, Strategy Session, Contract and Payment,
                  Upload Documents, Complete Questions, and Build Case Stations.
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Typography>
  );
};

export default OraganizationOverview;
