import { DialogContent } from '@mui/material';
import saveAs from 'file-saver';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllListData, getS3file } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

type Props = {
  leadContractPayment: any;
};
export const PreviousContractsListing: React.FC<Props> = (props: Props) => {
  const { leadContractPayment } = props;
  const [loader, setLoader] = useState(true);
  const [sections, setSections] = useState([]);
  const getPreviewDocument = useCallback(async () => {
    try {
      const list = await getAllListData(
        `${String(COMMON_ENDPOINT.ContractPrevious)}/${
          leadContractPayment.contactType.toLowerCase() === 'petitioner' ? 2 : 4
        }/${String(
          leadContractPayment.petitionerId
            ? leadContractPayment.petitionerId
            : leadContractPayment && leadContractPayment?.user_id
            ? leadContractPayment.user_id
            : '',
        )}?status=${String(
          leadContractPayment?.petitionerAssociated
            ? leadContractPayment.petitionerAssociated
            : '',
        )}`,
      );
      setLoader(false);
      setSections([...list.data]);
    } catch (error) {
      setLoader(false);
      toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openFile = (file) => {
    const obj = {
      key: file,
    };

    const fileName = file.split('/').pop();
    // setLoader(true);
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(`${String(fileName)} File Downloaded`, alertOptions);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };
  useEffect(() => {
    setLoader(true);
    getPreviewDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DialogContent>
      {loader && <div className="liberty-loader" />}
      <table className="lead-table table-hover">
        <thead>
          <tr>
            <th scope="col">{'FILE NAME'}</th>
            <th scope="col">{'SIGNED DATE'}</th>
            <th scope="col">{'APPROVED DATE'}</th>
            <th scope="col">{'APPROVED BY'}</th>
          </tr>
        </thead>
        <tbody>
          {sections && sections.length > 0 ? (
            sections.map((data, index) => (
              <tr key={index}>
                <td>
                  <a
                    style={{ color: '#1093df', cursor: 'pointer' }}
                    onClick={() => openFile(data.contractfile)}>
                    {data?.contractfile
                      ? data?.contractfile.split('/').pop()
                      : ''}
                  </a>
                </td>
                <td>
                  {data?.user_signed_data
                    ? moment(data?.user_signed_data).format('MM/DD/YYYY')
                    : '-'}
                </td>
                <td>
                  {data?.approvedby
                    ? moment(data?.contract_approved_date).format('MM/DD/YYYY')
                    : '-'}
                </td>
                <td>{data?.approvedby ? data?.approvedby : '-'}</td>
              </tr>
            ))
          ) : (
            <tr className={'text-center'}>
              <th scope="row" colSpan={4}>
                No Record(s) Found.
              </th>
            </tr>
          )}
        </tbody>
      </table>
    </DialogContent>
  );
};

export default PreviousContractsListing;
