import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  avatar: {
    backgroundColor: theme.palette?.secondary.main,
    margin: theme.spacing(1),
  },
  form: {
    // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    width: '100%',
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(8),
  },
  passwordErrorFormatLabel: {
    '& div': {
      marginRight: '3px',
    },
    alignItems: 'center',
    color: '#ffffff !important',
    display: 'flex',
    fontSize: '12px !important',
    letterSpacing: '0px',
    opacity: 1,
  },
  passwordErrorFormatTitle: {
    color: '#8392A5 !important',
    fontSize: '10px !important',
    letterSpacing: '0px',
    marginBottom: '.5rem !important',
    opacity: 1,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
