import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { AddContactPetitionerSchema } from 'utils/ValidatorSchema';

export const PetitionerContactAddAndEdit: React.FC<any> = () => {
  const navigate: any = useNavigate();
  const { editId, contactId } = useParams();
  const [loader, setLoader] = useState(true);
  const [statusToggle, setStatusToggle] = useState(false);
  const [contactTypes, setContactTypes] = useState<any>([]);
  const [mobileWithCode, setMobileWithCode] = useState<any>({
    MobileSocCode: 'us',
    mobileCode: '+1 ',
  });

  const activeStatusInput = useRef(null);
  const inActiveStatusInput = useRef(null);

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      contactEmail: '',
      contactType: '',
      designation: '',
      firstName: '',
      gender: '',
      lastName: '',
      mobileNumber: '',
      phoneNumber: '',
      primaryContact: false,
      status: 'active',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AddContactPetitionerSchema),
  });

  const cancelForm = () => {
    resetForm();
    navigate(-1);
  };

  const getInitialStatus = useCallback(async () => {
    await getAllListData(
      `${COMMON_ENDPOINT.ResponseTypes}/userinfo/${String(contactId)}`,
    )
      .then((resp) => {
        const {
          conatct_status,
          contact_mobile,
          contact_type,
          designation,
          email_id,
          first_name,
          gender,
          last_name,
          primary_contact,
          //   role_id,
          user_phone,
          country_code_mobile,
          country_source_code,
        } = resp;

        setValue('firstName', first_name ? first_name : '');
        setValue('lastName', last_name ? last_name : '');
        // setValue('mobileNumber', contact_mobile ? contact_mobile : '');
        setValue('phoneNumber', user_phone ? user_phone : '');
        setValue('contactType', contact_type ? Number(contact_type) : '');
        setValue('designation', designation ? designation : '');
        setValue('contactEmail', email_id ? email_id : '');
        setValue('gender', gender ? gender : '');
        setValue('primaryContact', primary_contact || false);
        setValue('status', conatct_status === 'active' ? true : false);
        setStatusToggle(conatct_status === 'active' ? true : false);

        setValue(
          'mobileNumber',
          contact_mobile
            ? String(contact_mobile).indexOf('+') !== -1
              ? contact_mobile
              : country_code_mobile
              ? String(country_code_mobile).concat(' ').concat(contact_mobile)
              : '+1 '.concat(contact_mobile)
            : '',
        );
        setMobileWithCode((pre) => ({
          ...pre,
          MobileSocCode: country_source_code ? country_source_code : 'us',
          mobileCode: country_code_mobile ? country_code_mobile : '+1 ',
        }));
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToggleStatus = () => setStatusToggle((b) => !b);

  const onSubmitForm = (data: any) => {
    setLoader(true);
    data['roleId'] = 3;
    data['status'] = statusToggle ? 'active' : 'inactive';

    data['mobileNumber'] = data['mobileNumber']
      ? String(data['mobileNumber'])
          .replace(
            mobileWithCode.mobileCode ? mobileWithCode.mobileCode : '',
            '',
          )
          .replace(/\s/g, '')
      : '';
    data['mobileCountryCode'] = mobileWithCode.mobileCode
      ? mobileWithCode.mobileCode
      : '+1 ';
    data['mobileSocCode'] = mobileWithCode.MobileSocCode
      ? mobileWithCode.MobileSocCode
      : 'us';

    if (!_.isUndefined(contactId)) {
      updateData(contactId, data, `${COMMON_ENDPOINT.ManagePetitioner}/user`)
        .then((resp: any) => {
          cancelForm();
          setLoader(false);
          toast.success('Updated successfully', alertOptions);
        })
        .catch((e) => {
          setLoader(false);
        });
    } else {
      addNewData(
        data,
        `${COMMON_ENDPOINT.ManagePetitioner}/user/${String(editId)}`,
      )
        .then((resp: any) => {
          toast.success('Created successfully', alertOptions);
          cancelForm();
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    getAllListData(COMMON_ENDPOINT.ResponseTypes)
      .then((typeResp: any) => {
        setContactTypes(typeResp);
        if (!_.isUndefined(contactId) && !_.isUndefined(editId)) {
          getInitialStatus();
        }
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  }, [contactId, editId, getInitialStatus]);

  return (
    <Grid container>
      <Grid item md={9} sm={12} className="pl-0">
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <>
            <Typography component={'div'} className="d-flex-a d-flex-sb">
              <Typography
                component={'div'}
                className="w-title black-white-text">
                Contact Person
              </Typography>
            </Typography>
            <form
              id="add-contact-for-petitioner"
              onSubmit={handleSubmit(onSubmitForm)}>
              <Typography
                component={'div'}
                className="addbox"
                sx={{ marginTop: 'unset !important' }}>
                <Box
                  component="form"
                  className="u-add-in"
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 2, sm: 1 }}>
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Contact Type <span className="required">*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="contactType"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'conatct_type'}
                                valueKey={'type_id'}
                                options={
                                  !_.isEmpty(contactTypes) ? contactTypes : []
                                }
                                name={name}
                                value={value}
                                onChange={(e) => onChange(e)}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.contactType?.message}
                                tabIndex={1}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          First Name <span className="required">*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="firstName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'First Name'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.firstName?.message}
                                onChange={(e) => onChange(e)}
                                tabIndex={2}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Gender
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="gender"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  { label: 'Male', value: 'M' },
                                  { label: 'Female', value: 'F' },
                                ]}
                                name={name}
                                value={value}
                                onChange={(e) => onChange(e)}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.gender?.message}
                                tabIndex={4}
                              />
                            );
                          }}
                        />
                      </Typography>
                      {/* <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Phone
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="phoneNumber"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Phone'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.phoneNumber?.message}
                                onChange={(e) => onChange(e)}
                                type={'number'}
                                tabIndex={6}
                              />
                            );
                          }}
                        />
                      </Typography> */}
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Email <span className="required">*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="contactEmail"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Email'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.contactEmail?.message}
                                onChange={(e) => onChange(e)}
                                type={'email'}
                                tabIndex={8}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} style={{ paddingTop: '15px' }}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Primary Contact <span className="required">*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={false}
                          name="primaryContact"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <Checkbox
                                inputRef={ref}
                                checked={value}
                                onChange={onChange}
                                name={name}
                                value={value}
                                color="primary"
                                disabled={
                                  watch('primaryContact') ? true : false
                                }
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Last Name <span className="required">*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="lastName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Last Name'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.lastName?.message}
                                onChange={(e) => onChange(e)}
                                tabIndex={3}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Designation
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="designation"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Designation'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.designation?.message}
                                onChange={(e) => onChange(e)}
                                tabIndex={5}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Mobile
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="mobileNumber"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              // <CustomInput
                              //   placeHolder={'Mobile'}
                              //   name={name}
                              //   value={value}
                              //   className={'custom-input'}
                              //   error={errors.mobileNumber?.message}
                              //   onChange={(e) => onChange(e)}
                              //   type={'number'}
                              //   tabIndex={7}
                              // />
                              <PhoneInput
                                placeholder="Mobile"
                                country={'us'}
                                value={value}
                                isValid={true}
                                onChange={(
                                  value,
                                  data,
                                  event,
                                  formattedValue,
                                ) => {
                                  const codes: any = data;
                                  onChange(formattedValue);
                                  setMobileWithCode({
                                    MobileSocCode: codes?.countryCode,
                                    mobileCode: '+'.concat(codes?.dialCode),
                                  });
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Contact Status <span className="required">*</span>
                        </Typography>
                        <div className="switch-field" tabIndex={9}>
                          <input
                            type="radio"
                            id="switch_left"
                            name="switchToggle"
                            value={'inactive'}
                            onChange={onToggleStatus}
                            checked={!statusToggle}
                            ref={inActiveStatusInput}
                          />
                          <label
                            htmlFor="switch_left d-flex-ja"
                            onClick={() => inActiveStatusInput.current.click()}>
                            Inactive
                          </label>

                          <input
                            type="radio"
                            id="switch_right"
                            name="switchToggle"
                            value={'active'}
                            onChange={onToggleStatus}
                            checked={statusToggle}
                            ref={activeStatusInput}
                          />
                          <label
                            htmlFor="switch_right d-flex-ja"
                            onClick={() => activeStatusInput.current.click()}>
                            Active
                          </label>
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Typography>
            </form>
            <Grid container className="bottom-fixed">
              <Grid item md={12}>
                <Typography component={'div'} className="d-flex-jae ">
                  <Button
                    className="cancel-btn mr-2"
                    type="button"
                    onClick={cancelForm}>
                    <Typography className="o-box" />
                    Cancel
                  </Button>
                  <Button
                    className="add-btn"
                    type="submit"
                    form={'add-contact-for-petitioner'}>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default PetitionerContactAddAndEdit;
