import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Tooltip, Typography } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { instantState } from 'hooks';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { deleteData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

type Props = {
  petitionStatusTableView: boolean;
  files: any;
  statusName: string;
  download: (src: any) => void;
  getPetitionStatus: () => void;
  setLastUpdatedData: (src: any) => void;
  mainIndex: number;
  // removeRecord: (src: any) => void;
};
export const PetitionStatusDocumentUploadTable: React.FC<Props> = (
  props: Props,
) => {
  const {
    petitionStatusTableView,
    files,
    download,
    statusName,
    setLastUpdatedData,
    mainIndex,
  } = props;
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const [deleteDialog, setDeleteDialog] = React.useState({
    id: '',
    show: false,
  });
  const [fileUploadLoading, setFileUploadLoading] = React.useState(false);

  const deleteSubmissionDoumentFile = async () => {
    const deleteDataInfo = await instantState(setDeleteDialog);
    setFileUploadLoading(true);
    deleteData(
      deleteDataInfo?.id,
      `${COMMON_ENDPOINT.submissionStatus}/upload-document/file`,
    )
      .then(() => {
        toast.success('Deleted successfully', alertOptions);
        setLastUpdatedData &&
          setLastUpdatedData((pre: Array<any>) => [
            ...pre.map((el, i) => ({
              ...(mainIndex === i
                ? {
                    ...el,
                    files:
                      el.files &&
                      el.files.filter((f) => f.id !== deleteDataInfo?.id),
                  }
                : { ...el }),
            })),
          ]);
        setFileUploadLoading(false);
        setDeleteDialog((pre) => ({
          ...pre,
          show: false,
        }));
      })
      .catch(() => {
        setFileUploadLoading(false);
      });
  };
  return (
    <Typography
      component={'div'}
      sx={{
        margin: 'auto',

        overflow: 'auto',
        transition: 'all 500ms ease-out',
        width: '90%',
        ...(petitionStatusTableView
          ? { maxHeight: '350px' }
          : { maxHeight: '0px' }),
      }}>
      {fileUploadLoading ? <div className="liberty-loader" /> : ''}
      <table className="lead-table table-hover mt-2">
        <thead>
          <tr>
            <th scope="col" style={{ width: '30%' }}>
              Status
            </th>
            <th scope="col" style={{ width: '20%' }}>
              Attachment
            </th>
            <th scope="col" style={{ width: '20%' }}>
              Added by
            </th>
            <th scope="col" style={{ width: '20%' }}>
              Added on
            </th>
            {userDetail.role_id === 3 && (
              <th scope="col" style={{ width: '10%' }}>
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {files && Array.isArray(files) && files.length ? (
            files.map((val: any, index) => (
              <tr key={index}>
                <td>{statusName ? statusName : '-'}</td>
                <td>
                  {val.file_key ? (
                    <Typography
                      component={'div'}
                      sx={{
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                        color: 'blue',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        val.file_key ? download(val.file_key) : ''
                      }>
                      Download
                    </Typography>
                  ) : (
                    '-'
                  )}
                </td>
                <td>{val?.creator_name}</td>
                <td>
                  {moment(new Date(val?.created_at)).format(
                    'MMM DD, YYYY hh:mm a',
                  )}
                </td>
                {userDetail.role_id === 3 && (
                  <td>
                    <Tooltip title={'Delete'} placement="top">
                      <DeleteForeverIcon
                        sx={{
                          cursor: 'pointer',
                          fill: 'red',
                          height: '25px',
                          width: '25px',
                        }}
                        onClick={() =>
                          setDeleteDialog((pre) => ({
                            ...pre,
                            id: val?.id,
                            show: true,
                          }))
                        }
                      />
                    </Tooltip>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>
                <Typography
                  component={'div'}
                  sx={{
                    color: 'red',
                    textAlign: 'center',
                    width: '100%',
                  }}>
                  {'No Record(s) Found.'}
                </Typography>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <ConfirmDialog
        title="DELETE"
        floatingBtnShow={true}
        open={deleteDialog.show}
        close={() => {
          setDeleteDialog((pre) => ({
            ...pre,
            show: false,
          }));
        }}
        onConfirm={(act) => {
          if (act === 'no') {
            setDeleteDialog((pre) => ({
              ...pre,
              id: '',
              show: false,
            }));
            return;
          }
          deleteSubmissionDoumentFile();
        }}
        autoCloseDisable={true}>
        {'Are you sure do you want to delete?'}
      </ConfirmDialog>
    </Typography>
  );
};
