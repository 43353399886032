import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

import useStyles from '../../PrivateStyle';

export const ContactSupport: React.FC<any> = () => {
  const classes = useStyles();

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12}>
        <Typography component={'div'} className={classes.contactPbox}>
          <Box component="form" className="w-450" noValidate autoComplete="off">
            <Grid container>
              <Grid item md={12}>
                <Typography component={'div'} className="custom-field">
                  <Typography component={'div'} className="custom-label">
                    <a
                      className="mailto"
                      href={`mailto:${String(
                        process.env.REACT_APP_CONTACT_MAIL,
                      )}`}>
                      Contact Support
                    </a>
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ContactSupport;
