import PrivateLayout from 'components/PrivateLayout';
import PublicLayout from 'components/PublicLayout';
import DocumentParsing from 'pages/private/Client/DocumentParsing';
import DocumentParsingView from 'pages/private/Client/DocumentParsing/Details';
import LeadsList from 'pages/private/Client/Leads';
import LeadAdd from 'pages/private/Client/Leads/AttorneyLeads';
import CompleteQuestion from 'pages/private/Client/Leads/CompleteQuestion';
import ContractPayment from 'pages/private/Client/Leads/ContractPayment';
import ContractAddEdit from 'pages/private/Client/Leads/ContractPayment/AddAndEdit';
import ContractViewer from 'pages/private/Client/Leads/ContractPayment/ContractViewer';
import ContractPaymentDetails from 'pages/private/Client/Leads/ContractPayment/Details';
import PaymentDetail from 'pages/private/Client/Leads/ContractPayment/PaymentDetail';
import AchPayment from 'pages/private/Client/Leads/ContractPayment/PaymentMethod/ACHpayment';
import CreditDebitPayment from 'pages/private/Client/Leads/ContractPayment/PaymentMethod/CreditDebitCard';
import PaymentMethodAndTerms from 'pages/private/Client/Leads/ContractPayment/PaymentMethodAndTerms';
import LeadsDetailList from 'pages/private/Client/Leads/DetailsList';
import { UploadDocument } from 'pages/private/Client/Leads/UploadDocument';
import { UploadPetitionsDocument } from 'pages/private/Client/Petitions/UploadPetitionsDocument';
import Socialshare from 'pages/private/Client/socialshare';
import Strategy from 'pages/private/Common/Strategy';
import StrategyInfo from 'pages/private/Common/Strategy/details';
import { ChangePassword } from 'pages/private/Master/ChangePassword';
import { Dashboard } from 'pages/private/Master/Dashboard';
import Myprofile from 'pages/private/Master/Myprofile';
import { RadioButtonCrossPopulate } from 'pages/private/Master/RadioButtonCrossPopulate';
import { RadioButtonCrossPopulateAdd } from 'pages/private/Master/RadioButtonCrossPopulate/Add';
import ConfigureQuestion from 'pages/private/Setup/ConfigureQuestion';
import QuestionAdd from 'pages/private/Setup/ConfigureQuestion/Add';
import ConfigureSection from 'pages/private/Setup/ConfigureSection';
import SectionAdd from 'pages/private/Setup/ConfigureSection/Add';
import DocumentTemplate from 'pages/private/Setup/DocumentTemplate';
import DocumentAdd from 'pages/private/Setup/DocumentTemplate/Add';
import DocumentType from 'pages/private/Setup/DocumentType';
import DocumentTypeAddAndEdit from 'pages/private/Setup/DocumentType/Add';
import { Rearrange } from 'pages/private/Setup/DocumentType/Add/Rearrange';
import { ManageLawFirm } from 'pages/private/Setup/ManageLawFirm';
import { ManageLawFirmAddAndEdit } from 'pages/private/Setup/ManageLawFirm/Add';
// import RepresentativeAddAndEdit from 'pages/private/Setup/ManageLawFirm/Representative';
import ManagePetitioner from 'pages/private/Setup/ManagePetitioners';
import { ManagePetitionerAddAndEdit } from 'pages/private/Setup/ManagePetitioners/Add';
import PetitionerContactAddAndEdit from 'pages/private/Setup/ManagePetitioners/Add/Contact';
import ManageUserList from 'pages/private/Setup/ManageUser';
import UserAdd from 'pages/private/Setup/ManageUser/Add';
import RolesPermission from 'pages/private/Setup/RolesPermissions';
import RoleAdd from 'pages/private/Setup/RolesPermissions/Add';
import { VisaType } from 'pages/private/Setup/VisaType';
import VisaAdd from 'pages/private/Setup/VisaType/Add';
import AcceptableUsePolicy from 'pages/public/ACCEPTABLEUSEPOLICY';
import DMCAPolicy from 'pages/public/DMCA';
import PrivacyPolicy from 'pages/public/PrivacyPolicy';
import {
  StripeInprocessPayment,
  StripeSucessPayment,
} from 'pages/public/StripePayment/PaymentSucess';
import TermsOfService from 'pages/public/Terms&Service';
import VersionHistory from 'pages/public/VersionHistory';
import AddVersion from 'pages/public/VersionHistory/add';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AdminPrivateRoutes } from '../index';
import { AssignAttorney } from './../../pages/private/Client/AssignAttorney/index';

const AdminRoutes: React.FC = (): React.ReactElement => {
  const {
    DASHBOARD,
    DOCUMENT,
    MYPROFILE,
    CHANGEPASSWORD,
    ASSIGNATTORNEY,
    ROLES,
    LEADS,
    MANAGEUSER,
    QUESTION,
    SECTION,
    POLICY,
    VISATYPE,
    MANAGEPETITIONERS,
    MANAGELAWFIRM,
    VERSIONHISTORY,
    SOCIALSHARE,
    PAYMENT,
  } = AdminPrivateRoutes;

  return (
    <Routes>
      <Route path="/" element={<Navigate to={DASHBOARD} />} />
      <Route
        path={DASHBOARD}
        element={
          <PrivateLayout
            routes={{
              path: DASHBOARD,
              show: false,
              title: 'Dashboard',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Dashboard />
          </PrivateLayout>
        }
      />
      <Route
        path={MYPROFILE}
        element={
          <PrivateLayout
            routes={{
              path: MYPROFILE,
              show: true,
              title: 'My Profile',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Myprofile />
          </PrivateLayout>
        }
      />
      <Route
        path={CHANGEPASSWORD}
        element={
          <PrivateLayout
            routes={{
              path: CHANGEPASSWORD,
              show: true,
              title: 'Change Password',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ChangePassword />
          </PrivateLayout>
        }
      />

      <Route path={POLICY.PRIVACYPOLICY} element={<PrivacyPolicy />} />
      <Route path={POLICY.DMCAPOLICY} element={<DMCAPolicy />} />
      <Route
        path={POLICY.ACCEPTABLEUSEPOLICY}
        element={<AcceptableUsePolicy />}
      />
      <Route path={POLICY.TERMOFSERVICES} element={<TermsOfService />} />
      <Route path={POLICY.VERSIONHISTORY} element={<VersionHistory />} />
      <Route path={VERSIONHISTORY} element={<AddVersion />} />

      <Route
        path={ROLES.ROLESPERMISSION}
        element={
          <PrivateLayout
            routes={{
              path: ROLES.ROLESPERMISSION,
              show: true,
              title: 'Roles & Permissions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <RolesPermission />
          </PrivateLayout>
        }
      />
      <Route
        path={`${ROLES.ROLESADD}/:roleId`}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: ROLES.ROLESPERMISSION,
              show: true,
              title: 'Roles & Permissions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <RoleAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${ROLES.ROLESEDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: ROLES.ROLESPERMISSION,
              show: true,
              title: 'Roles & Permissions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <RoleAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={MANAGEPETITIONERS.MANAGEPETITIONERSLIST}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'managePetitioners',
              path: MANAGEPETITIONERS.MANAGEPETITIONERSLIST,
              show: true,
              title: 'Manage Petitioners',
            }}
            showTools={true}
            showHeader={true}
            addEditRoute={{
              isAdd: true,
              isEdit: false,
              path: MANAGEPETITIONERS.MANAGEPETITIONERADD,
              title: 'Add New Petitioner',
            }}
            hasComponent={'no'}>
            <ManagePetitioner />
          </PrivateLayout>
        }
      />
      <Route
        path={MANAGEPETITIONERS.MANAGEPETITIONERADD}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: MANAGEPETITIONERS.MANAGEPETITIONERSLIST,
              show: true,
              title: 'Manage Petitioners',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ManagePetitionerAddAndEdit />
          </PrivateLayout>
        }
      />
      <Route
        path={`${MANAGEPETITIONERS.MANAGEPETITIONEREDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: MANAGEPETITIONERS.MANAGEPETITIONERSLIST,
              show: true,
              title: 'Manage Petitioners',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ManagePetitionerAddAndEdit />
          </PrivateLayout>
        }
      />
      <Route
        path={`${MANAGEPETITIONERS.CONTACTADD}/:editId`}
        element={
          <PrivateLayout
            routes={{
              path: MANAGEPETITIONERS.MANAGEPETITIONERSLIST,
              show: true,
              title: 'Add Contact Person',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <PetitionerContactAddAndEdit />
          </PrivateLayout>
        }
      />
      <Route
        path={`${MANAGEPETITIONERS.CONTACTEDIT}/:editId/:contactId`}
        element={
          <PrivateLayout
            routes={{
              path: MANAGEPETITIONERS.MANAGEPETITIONERSLIST,
              show: true,
              title: 'Edit Contact Person',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <PetitionerContactAddAndEdit />
          </PrivateLayout>
        }
      />

      <Route
        path={MANAGELAWFIRM.MANAGELAWFIRMLIST}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'manageLawFirm',
              path: MANAGELAWFIRM.MANAGELAWFIRMLIST,
              show: true,
              title: 'Manage Law Firm',
            }}
            showTools={false}
            showHeader={true}
            // addEditRoute={{
            //   isAdd: true,
            //   isEdit: false,
            //   path: MANAGELAWFIRM.MANAGELAWFIRMADD,
            //   title: 'Add New Firm',
            // }}
            hasComponent={'no'}>
            <ManageLawFirm />
          </PrivateLayout>
        }
      />
      {/* <Route
        path={MANAGELAWFIRM.MANAGELAWFIRMADD}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: MANAGELAWFIRM.MANAGELAWFIRMLIST,
              show: true,
              title: 'Manage Law Firm',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ManageLawFirmAddAndEdit />
          </PrivateLayout>
        }
      /> */}
      <Route
        path={`${MANAGELAWFIRM.MANAGELAWFIRMEDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: MANAGELAWFIRM.MANAGELAWFIRMLIST,
              show: true,
              title: 'Manage Law Firm',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ManageLawFirmAddAndEdit />
          </PrivateLayout>
        }
      />
      {/* <Route
        path={`${MANAGELAWFIRM.ADDREPRESENTIVE}/:editId`}
        element={
          <PrivateLayout
            routes={{
              path: MANAGELAWFIRM.MANAGELAWFIRMLIST,
              show: true,
              title: 'Add Contact Person',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <RepresentativeAddAndEdit />
          </PrivateLayout>
        }
      />
      <Route
        path={`${MANAGELAWFIRM.EDITREPTESENTIVE}/:editId/:contactId`}
        element={
          <PrivateLayout
            routes={{
              path: MANAGELAWFIRM.MANAGELAWFIRMLIST,
              show: true,
              title: 'Edit Contact Person',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <RepresentativeAddAndEdit />
          </PrivateLayout>
        }
      /> */}
      <Route
        path={MANAGEUSER.MANAGEUSERLIST}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'manageUsers',
              path: MANAGEUSER.MANAGEUSERLIST,
              show: true,
              title: 'Manage Users',
            }}
            showTools={true}
            showHeader={true}
            addEditRoute={{
              isAdd: true,
              isEdit: false,
              path: MANAGEUSER.USERADD,
              title: 'Add New User',
            }}
            hasComponent={'no'}>
            <ManageUserList />
          </PrivateLayout>
        }
      />
      <Route
        path={MANAGEUSER.USERADD}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: MANAGEUSER.MANAGEUSERLIST,
              show: true,
              title: 'Manage Users',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <UserAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${MANAGEUSER.USEREDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: MANAGEUSER.MANAGEUSERLIST,
              show: true,
              title: 'Manage Users',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <UserAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={QUESTION.CONFIGUREQUESTION}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'configureQuestions',
              path: QUESTION.CONFIGUREQUESTION,
              show: true,
              title: 'Configure Questions',
            }}
            showTools={true}
            showHeader={true}
            addEditRoute={{
              isAdd: true,
              isEdit: false,
              path: QUESTION.QUESTIONADD,
              title: 'Add New Question',
            }}
            hasComponent={'no'}>
            <ConfigureQuestion />
          </PrivateLayout>
        }
      />
      <Route
        path={QUESTION.QUESTIONADD}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: QUESTION.CONFIGUREQUESTION,
              show: true,
              title: 'Configure Questions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <QuestionAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${QUESTION.QUESTIONEDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: QUESTION.CONFIGUREQUESTION,
              show: true,
              title: 'Configure Questions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <QuestionAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={SECTION.CONFIGURESECTION}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'configureSections',
              path: SECTION.CONFIGURESECTION,
              show: true,
              title: 'Configure Sections',
            }}
            showTools={true}
            showHeader={true}
            addEditRoute={{
              isAdd: true,
              isEdit: false,
              path: SECTION.SECTIONADD,
              title: 'Add New Section',
            }}
            hasComponent={'no'}>
            <ConfigureSection />
          </PrivateLayout>
        }
      />
      <Route
        path={SECTION.SECTIONADD}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: SECTION.CONFIGURESECTION,
              show: true,
              title: 'Configure Sections',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <SectionAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${SECTION.SECTIONEDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: SECTION.CONFIGURESECTION,
              show: true,
              title: 'Configure Sections',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <SectionAdd />
          </PrivateLayout>
        }
      />

      <Route
        path={DOCUMENT.DOCUMENTTEMPLATE}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'documentTemplate',
              path: DOCUMENT.DOCUMENTTEMPLATE,
              show: true,
              title: 'Document Template',
            }}
            showTools={true}
            showHeader={true}
            addEditRoute={{
              isAdd: true,
              isEdit: false,
              path: DOCUMENT.DOCUMENTADD,
              title: 'Add New Template',
            }}
            hasComponent={'no'}>
            <DocumentTemplate />
          </PrivateLayout>
        }
      />
      <Route
        path={DOCUMENT.DOCUMENTADD}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: DOCUMENT.DOCUMENTTEMPLATE,
              show: true,
              title: 'Document Template',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <DocumentAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${DOCUMENT.DOCUMENTEDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: DOCUMENT.DOCUMENTTEMPLATE,
              show: true,
              title: 'Document Template',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <DocumentAdd />
          </PrivateLayout>
        }
      />

      <Route
        path={DOCUMENT.TYPES}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'manageDocumentTypes',
              path: DOCUMENT.TYPES,
              show: true,
              title: 'Manage Document Types',
            }}
            showTools={true}
            showHeader={true}
            addEditRoute={{
              isAdd: true,
              isEdit: false,
              path: DOCUMENT.DOCUMENTTPYEADD,
              title: 'Add New Type',
            }}
            hasComponent={'no'}>
            <DocumentType />
          </PrivateLayout>
        }
      />

      <Route
        path={DOCUMENT.REARRANGE}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'DocumentTypesOrderReArrange',
              path: DOCUMENT.REARRANGE,
              show: true,
              title: 'Document Types Order Re Arrange',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Rearrange />
          </PrivateLayout>
        }
      />

      <Route
        path={DOCUMENT.DOCUMENTTPYEADD}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: DOCUMENT.TYPES,
              show: true,
              title: 'Manage Document Types',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <DocumentTypeAddAndEdit />
          </PrivateLayout>
        }
      />

      <Route
        path={`${DOCUMENT.DOCUMENTTPYEEDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: DOCUMENT.TYPES,
              show: true,
              title: 'Manage Document Types',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <DocumentTypeAddAndEdit />
          </PrivateLayout>
        }
      />

      <Route
        path={LEADS.LEADSLIST}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'leads',
              path: LEADS.LEADSLIST,
              show: false,
              title: 'Leads',
            }}
            showTools={true}
            showHeader={true}
            addEditRoute={{
              isAdd: true,
              isEdit: false,
              path: LEADS.LEADSADD,
              title: 'New Lead',
            }}
            hasComponent={'no'}>
            <LeadsList />
          </PrivateLayout>
        }
      />
      <Route
        path={LEADS.LEADSADD}
        element={
          <PrivateLayout
            routes={{
              path: LEADS.LEADSLIST,
              show: true,
              title: 'Leads',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <LeadAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.LEADSEDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              path: LEADS.LEADSLIST,
              show: true,
              title: 'Leads',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <LeadAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.LEADDETAILSLIST}/:editId`}
        element={
          <PrivateLayout
            routes={{
              icon: 'leads',
              path: LEADS.LEADSLIST,
              show: true,
              title: 'Leads',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <LeadsDetailList />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.UPLOADDOCUMENT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'docupload',
              path: LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Upload Documents',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={''}>
            <UploadDocument />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.UPLOADPETITIONSDOCUMENT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'docupload',
              path: LEADS.UPLOADPETITIONSDOCUMENT,
              show: true,
              title: 'Upload Documents',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={''}>
            <UploadPetitionsDocument />
          </PrivateLayout>
        }
      />
      <Route
        path={LEADS.COMPLETEQUESTION}
        element={
          <PrivateLayout
            routes={{
              icon: 'question',
              path: LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Complete Question',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <CompleteQuestion />
          </PrivateLayout>
        }
      />
      <Route
        path={LEADS.STRATEGY}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'docupload',
              path: LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Strategy />
          </PrivateLayout>
        }
      />
      <Route
        path={LEADS.STRATEGYDETAIL}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'docupload',
              path: LEADS.STRATEGY,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <StrategyInfo />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.CONTRACTPAYMENT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'contract',
              path: LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Contract & Payment',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ContractPayment />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.VIEWEDITCONTRACTS}/:editId/:contractId/:userId/:contractType`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'contract',
              path: LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Contract & Payment',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ContractAddEdit />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.CONTRACTPAYMENTDETAILS}/:editId/:contractId/:userId/:contractType`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'contract',
              path: LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Contract & Payment',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ContractPaymentDetails />
          </PrivateLayout>
        }
      />
      <Route
        path={DOCUMENT.DOCUMENTPARSING}
        element={
          <PrivateLayout
            routes={{
              path: DOCUMENT.DOCUMENTPARSING,
              show: true,
              title: 'Document Parsing',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <DocumentParsing />
          </PrivateLayout>
        }
      />
      <Route
        path={DOCUMENT.DOCUMENTPARSINGVIEW}
        element={
          <PrivateLayout
            routes={{
              path: DOCUMENT.DOCUMENTPARSINGVIEW,
              show: true,
              title: 'Document Parsing',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <DocumentParsingView />
          </PrivateLayout>
        }
      />
      <Route
        path={VISATYPE.VISATYPELIST}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'manageVisaTypes',
              path: VISATYPE.VISATYPELIST,
              show: true,
              title: 'Visa Type',
            }}
            showTools={true}
            showHeader={true}
            addEditRoute={{
              isAdd: true,
              isEdit: false,
              path: VISATYPE.VISATYPEADD,
              title: 'Add Visa Type',
            }}
            hasComponent={'no'}>
            <VisaType />
          </PrivateLayout>
        }
      />
      <Route
        path={VISATYPE.VISATYPEADD}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: VISATYPE.VISATYPELIST,
              show: true,
              title: 'Visa Type',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <VisaAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${VISATYPE.VISATYPEEDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: VISATYPE.VISATYPELIST,
              show: true,
              title: 'Visa Type',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <VisaAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={ASSIGNATTORNEY}
        element={
          <PrivateLayout
            routes={{
              path: ASSIGNATTORNEY,
              show: true,
              title: 'Assign Attorney',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <AssignAttorney />
          </PrivateLayout>
        }
      />
      <Route
        path={SOCIALSHARE}
        element={
          <PrivateLayout
            routes={{
              path: SOCIALSHARE,
              show: false,
              title: 'Social',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Socialshare />
          </PrivateLayout>
        }
      />
      <Route
        path={PAYMENT.SECUREPAYMENT}
        element={
          <PublicLayout>
            <PaymentDetail />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.STRIPESUCCESS}
        element={
          <PublicLayout>
            <StripeSucessPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.STRIPEINPROCESS}
        element={
          <PublicLayout>
            <StripeInprocessPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.PAYMENTTERMSANDCONDITIONS}
        element={
          <PublicLayout>
            <PaymentMethodAndTerms />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.CREDITDEBITCARD}
        element={
          <PublicLayout>
            <CreditDebitPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.ACH}
        element={
          <PublicLayout>
            <AchPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.CONTRACTVIEWER}
        element={
          <PublicLayout>
            <ContractViewer />
          </PublicLayout>
        }
      />
      <Route
        path={QUESTION.RADIOBUTTONCROSSPOPULATE}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'configureQuestions',
              path: QUESTION.RADIOBUTTONCROSSPOPULATE,
              show: true,
              title: 'Radio Mapping',
            }}
            showTools={true}
            showHeader={true}
            addEditRoute={{
              isAdd: true,
              isEdit: false,
              path: QUESTION.RADIOBUTTONCROSSPOPULATEADD,
              title: 'Add Radio Mapping',
            }}
            hasComponent={'no'}>
            <RadioButtonCrossPopulate />
          </PrivateLayout>
        }
      />
      <Route
        path={QUESTION.RADIOBUTTONCROSSPOPULATEADD}
        element={
          <PrivateLayout
            routes={{
              navigate: true,
              path: QUESTION.RADIOBUTTONCROSSPOPULATE,
              show: true,
              title: 'Radio Mapping',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <RadioButtonCrossPopulateAdd />
          </PrivateLayout>
        }
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AdminRoutes;
