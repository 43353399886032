// import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  Popper,
  Tab,
  Typography,
} from '@mui/material';
import Win from 'assets/images/winning.png';
import CustomInput from 'components/CustomInput/customInput';
import InfinityScroll from 'components/InfinityScroll';
import { instantState } from 'hooks';
import _ from 'lodash';
import { Translate } from 'pages/private/Common/Translate/Translate';
import { WizardForm } from 'pages/private/Common/wizardForm';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { UserRoleType } from 'types';
import {
  COMMON_ENDPOINT,
  LEAD_LEVEL_TREE_VIEW_ENDPOINT,
  MASTER_ENDPOINT,
  SETUP_ENDPOINT,
  TREE_VIEW_ENDPOINT,
} from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import HistoryComments from '../../Common/HistoryComments';
import AddSection from './AddSection';
import { CopyAction } from './CopyAction';
import Depentent from './Depentent';

export const CompleteQuestion: React.FC<any> = () => {
  const { petitionId } = useParams();
  const navigate = useNavigate();
  const location: any = useLocation();
  const [searchParams] = useSearchParams();
  const wizardRef: any = useRef();

  const [loader, setLoader] = useState(true);
  const [tabValue, setTabValue] = useState('1');
  const [treeList, setTreeList] = useState([]);
  const [dependentUserId, setDependentUserId] = useState<any>();
  const [petitionerFormShow, setPetitionerFormShow] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);

  const [hcData, setHCData] = useState<any>({
    comments: [],
    history: [],
    loading: true,
    selectedQuestion: {},
  });

  const [leadLevelInformation, setLeadLevelInformation] = useState<any>({});
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [params, setParams] = useState<any>({
    skip: 0,
    take: 50,
  });

  const [isSection, setIsSection] = useState({
    allSection: false,
    data: [],
    show: false,
    type: 'beneficiary',
  });
  const [isCopy, setIsCopy] = useState<any>({
    data: [],
    show: false,
    type: 'petitioner',
  });

  const [sectionParams, setSectionParams] = useState<any>({
    name: '',
    sectionType: 'beneficiary',
    skip: 0,
    take: 50,
  });
  const [listDataMsg, setListDataMsg] = useState<any>('');

  const [winningStrategyAnchor, setWinningStrategyAnchor] = useState<any>({
    anchorEl: null,
    open: false,
  });

  const [winningStrategies, setWinningStrategies] = useState<any>({
    data: [],
    total: 0,
  });
  const [winningParams, setWinningParams] = useState<any>({
    search: '',
    skip: 0,
    take: 10,
  });

  const [selectedIndex, setSelectedIndex] = useState<any>({
    index: null,
    show: false,
  });

  const winningStrategyId = winningStrategyAnchor.open ? 'simple-popper' : null;

  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);

  const getQuestionWizardFormData = useCallback(
    async (tabtype?: string) => {
      try {
        const dependentUserId = await instantState(setDependentUserId);
        setLoader(true);
        setListDataMsg('');
        if (!_.isEmpty(petitions)) {
          const formType =
            tabtype === '1'
              ? 'beneficiary'
              : tabtype === '2'
              ? 'petitioner'
              : 'dependent';
          const formId =
            tabtype === '3'
              ? null
              : tabtype === '1'
              ? petitions?.petitionData?.beneficiary_form_id
              : petitions?.petitionData?.petitioner_form_id;
          const petitionId = petitions.petitionData.id;
          const beneficiaryId = petitions.petitionData.beneficiary_id;
          const visaId = petitions.petitionData.visa_id;
          const petitionerUser = petitions.petitionData.petitioner_user_id;
          const organizationId = petitions.petitionData.organization_id;
          const firmId = petitions.petitionData.firm_id;
          const attorneyUser = petitions.petitionData.attorney_id;
          const leadId = petitions.petitionData.lead_id;
          const petitionerAssociated =
            petitions?.petitionData?.petitioner_associated;
          const isAffilate = petitions?.petitionData?.isAffilateCase || '';
          const affilateRoleId = petitions?.petitionData?.affilateRoleId || '';
          getPetitionFormShowOrHide(visaId);
          const listData = await getAllListData(
            TREE_VIEW_ENDPOINT(
              formId,
              leadId,
              beneficiaryId,
              formType,
              petitionId,
              visaId,
              petitionerUser,
              organizationId,
              firmId,
              attorneyUser,
              0,
              0,
              petitionerAssociated,
              0,
              0,
              tabtype === '3' && dependentUserId ? dependentUserId : 0,
              isAffilate,
              affilateRoleId,
            ),
          );

          setTreeList(listData && Array.isArray(listData) ? listData : []);
          setListDataMsg(listData ? 'success' : '');

          setLoader(false);
        } else {
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        toast.error(
          error ? error : 'Oops!!! Something went wrong',
          alertOptions,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [petitions],
  );

  const handleChange = async (event, newValue) => {
    await instantState(setTabValue);
    setDependentUserId(0);
    setTabValue(newValue);
    setTreeList([]);
    setListDataMsg('');
    setHCData({
      comments: [],
      history: [],
      loading: false,
      selectedQuestion: {},
    });
    if (['1', '2'].includes(newValue)) {
      getQuestionWizardFormData(newValue);
    }
    setIsSection({
      allSection: false,
      data: [],
      show: false,
      type: 'beneficiary',
    });
  };

  const getSectionData = () => {
    getAllListData(
      `${String(COMMON_ENDPOINT.BeneficiaryAdd)}/${String(
        petitions.petitionData.visa_id,
      )}?skip=${String(sectionParams.skip)}&take=${String(
        sectionParams.take,
      )}&sectionType=${String(sectionParams.sectionType)}&name=${String(
        sectionParams.name,
      )}`, // make queryparams for name search
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setIsSection((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          setIsSection({
            allSection: false,
            data: resp.data,
            show: true,
            type: sectionParams.sectionType,
          });
        }
        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setIsSection);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch(() => {
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
  };
  const getCopyData = (type: string) => {
    const beneficiaryId = petitions.petitionData.beneficiary_id;
    const petitionerId = petitions.petitionData.organization_id;
    const petitionerAssociated = petitions?.petitionData?.petitioner_associated;
    {
      tabValue === '1'
        ? getAllListData(
            `${String(COMMON_ENDPOINT.CopyBeneficiary_Petitioner)}/${String(
              beneficiaryId,
            )}/${String(petitionId)}?petitionerAssociated=${String(
              petitionerAssociated,
            )}`,
          )
            .then(async (resp: any) => {
              const { data, count: total } = resp
                ? resp
                : { count: 0, data: [] };
              const moreLoader = await instantState(setGetMoreLoader);
              if (moreLoader) {
                setIsCopy((pre) => ({
                  ...pre,
                  data: [...pre.data, ...data],
                }));
              } else {
                const idsEle = document.getElementById('scrollParent');
                idsEle && (idsEle.scrollTop = 0);
                setIsCopy({
                  count: resp.length,
                  data:
                    resp && resp.length
                      ? resp.filter((e) => e.petitionid !== Number(petitionId))
                      : [],
                  show: true,
                  type,
                });
              }
              setLoader(false);
              setGetMoreLoader(false);
              const allList = await instantState(setIsCopy);
              if (allList.data && allList.data.length < Number(total)) {
                setHasMoreResults(true);
              } else {
                setHasMoreResults(false);
              }
            })
            .catch(() => {
              setGetMoreLoader(false);
              setLoader(false);
              setHasMoreResults(false);
            })
        : getAllListData(
            `${String(
              COMMON_ENDPOINT.CopyBeneficiary_Petitioner,
            )}?&skip=${String(params.skip)}&take=${String(
              params.take,
            )}&petitionerId=${String(
              petitionerId,
            )}&petitionerAssociated=${String(petitionerAssociated)}`,
          )
            .then(async (resp: any) => {
              const { data, count: total } = resp
                ? resp
                : { count: 0, data: [] };
              const moreLoader = await instantState(setGetMoreLoader);
              if (moreLoader) {
                setIsCopy((pre) => ({
                  ...pre,
                  data: [...pre.data, ...data],
                }));
              } else {
                const idsEle = document.getElementById('scrollParent');
                idsEle && (idsEle.scrollTop = 0);
                setIsCopy(() => ({
                  count: resp.count,
                  data:
                    resp.data && resp.data.length
                      ? resp.data.filter(
                          (e) => e.petitionid !== Number(petitionId),
                        )
                      : [],
                  show: true,
                  type,
                }));
              }
              setGetMoreLoader(false);
              setLoader(false);
              const allList = await instantState(setIsCopy);
              if (allList.data && allList.data.length < Number(total)) {
                setHasMoreResults(true);
              } else {
                setHasMoreResults(false);
              }
            })
            .catch(() => {
              setGetMoreLoader(false);
              setLoader(false);
              setHasMoreResults(false);
            });
    }
  };
  const getMoreListCopy = () => {
    setGetMoreLoader(true);
    setParams((pre) => ({
      ...pre,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      skip: pre.skip + 50,
    }));
  };
  const getMoreListAdd = () => {
    setGetMoreLoader(true);
    setSectionParams((pre) => ({
      ...pre,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      skip: pre.skip + 50,
    }));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleAddModalOpen = () => {
    setOpenAddModal(true);
  };

  const leadGetConfigureFormData = async (leadLevelInfo: any) => {
    await getAllListData(
      LEAD_LEVEL_TREE_VIEW_ENDPOINT(
        leadLevelInfo.beneficiary_form_id, // formId
        userDetail.id, // beneficiaryId
        leadLevelInfo.visa_type &&
          leadLevelInfo.visa_type.length &&
          leadLevelInfo.visa_type[0]
          ? leadLevelInfo.visa_type[0]
          : '', // visaId
        leadLevelInfo.lead_id, // leadId
      ),
    )
      .then((resp: any) => {
        setTreeList(resp);
        setListDataMsg('success');
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err, alertOptions);
        setLoader(false);
      });
  };

  const getWinningStrategies = () => {
    getAllListData(
      `${String(SETUP_ENDPOINT.ConfigSection)}/winning?search=${String(
        winningParams.search,
      )}&skip=${String(winningParams.skip)}&take=${String(winningParams.take)}`,
    )
      .then((resp: any) => {
        const mergeData = !_.isEmpty(winningStrategies.data)
          ? [...winningStrategies.data, ...resp.data]
          : resp.data;
        setWinningStrategies({
          data: mergeData,
          total: Number(resp.total),
        });
      })
      .catch((e: any) => {});
  };

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    const { currentTarget } = event;
    setWinningStrategyAnchor((pre) => ({
      ...pre,
      anchorEl: currentTarget,
      open: pre.open == true ? false : true,
    }));
    const winning = await instantState(setWinningStrategyAnchor);
    winning.anchorEl && getWinningStrategies();
  };

  const handleClickAway = () => {
    setWinningStrategyAnchor((pre) => ({
      ...pre,
      anchorEl: null,
      open: false,
    }));
  };

  const rowProps = (winning, index) => {
    return (
      <Typography component={'div'} className="pop-det-box">
        <Typography
          className={'pop-title '.concat(
            selectedIndex.index === index ? 'active' : '',
          )}
          onClick={() =>
            setSelectedIndex({
              index,
              show: !selectedIndex.show,
            })
          }>
          {winning.question}
        </Typography>
        {selectedIndex.index === index && (
          <Typography className="pop-dis">{winning.notes}</Typography>
        )}
      </Typography>
    );
  };

  useEffect(() => {
    getWinningStrategies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winningParams]);

  useEffect(() => {
    if (params.skip > 0) getCopyData(tabValue ? 'beneficiary' : 'petititoner');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (isSection.show) {
      getSectionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionParams]);

  const getPetitionFormShowOrHide = async (visaId: string) => {
    try {
      if (userDetail?.role_id === 4) {
        const result = await getAllListData(`/dashboard/visa/${visaId}`);
        setPetitionerFormShow(result && result?.show ? true : false);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  useEffect(() => {
    if (!_.isEmpty(petitions) && !searchParams.get('lead')) {
      if (location.state?.focusElement) {
        const activeTabValue = location.state?.focusElement.activeTab;
        setTabValue(
          activeTabValue === 'beneficiary'
            ? '1'
            : activeTabValue === 'petitioner'
            ? '2'
            : '3',
        );
      }
      getQuestionWizardFormData(
        location.state && location.state?.focusElement
          ? location.state?.focusElement.activeTab === 'beneficiary'
            ? '1'
            : location.state?.focusElement.activeTab === 'petitioner'
            ? '2'
            : '3'
          : '1',
      );
    }
    const leadUserId = searchParams.get('leadUser');
    const leadId = searchParams.get('lead');
    if (
      leadUserId &&
      userDetail.role_id === 4 &&
      searchParams.get('type') !== 'petition'
    ) {
      getAllListData(
        `${MASTER_ENDPOINT.Dashboard}/beneficiary?leadUserId=${String(
          leadUserId,
        )}`,
      )
        .then((resp: any) => {
          const leadLevelInfos =
            resp && resp.data && resp.data.length && resp.data[0]
              ? resp.data[0]
              : {};
          setLeadLevelInformation(leadLevelInfos);
          if (leadId) {
            leadGetConfigureFormData(leadLevelInfos);
          } else {
            getQuestionWizardFormData('1');

            getAllListData(
              `${COMMON_ENDPOINT.Petitions}?skip=0&take=1&id=${String(
                petitionId,
              )}`,
            ).then(async (petitionResp: any) => {
              if (petitionResp && petitionResp.data[0]) {
                // setPetitionsData(petitionResp.data[0]);
                const formType = 'beneficiary';
                const formId = petitionResp.data[0].beneficiary_form_id;
                const petitionIds = Number(petitionId);
                const beneficiaryId = petitionResp.data[0].beneficiary_id;
                const visaId = petitionResp.data[0].visa_id;
                const petitionerUser = petitionResp.data[0].petitioner_user_id;
                const organizationId = petitionResp.data[0].organization_id;
                const firmId = petitionResp.data[0].firm_id;
                const attorneyUser = petitionResp.data[0].attorney_id;
                const leadId = petitionResp.data[0].lead_id;
                if (petitionIds) {
                  getPetitionFormShowOrHide(visaId);
                }
                const listData = await getAllListData(
                  TREE_VIEW_ENDPOINT(
                    formId,
                    leadId,
                    beneficiaryId,
                    formType,
                    petitionIds,
                    visaId,
                    petitionerUser,
                    organizationId,
                    firmId,
                    attorneyUser,
                    0,
                    0,
                    null,
                    0,
                    0,
                  ),
                );
                setTreeList(listData);
                setLoader(false);
              }
            });
          }
        })
        .catch((e: any) => {
          toast.error('Something went wrong, Please try again.', alertOptions);
          navigate(-1);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitions, location.state?.focusElement]);

  return (
    <>
      {!_.isEmpty(petitions) && petitions?.petitionData ? (
        <Typography
          component={'div'}
          className="black-white-text"
          sx={{
            fontFamily: 'Arial-Bold !important',
            fontSize: '1.25em !important',
          }}>
          {petitions?.petitionData?.visa_type} {'for'}{' '}
          {petitions?.petitionData?.benificiery_name}
        </Typography>
      ) : (
        ''
      )}
      <Grid container>
        <Grid
          item
          md={10}
          sm={12}
          sx={{ paddingRight: '2rem', paddingTop: '1rem' }}>
          {loader && <div className="liberty-loader" />}
          <Box sx={{ width: '100%' }} className="cquesTab">
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  variant="scrollable"
                  onChange={handleChange}
                  aria-label="lab API tabs example">
                  <Tab label="Beneficiary" value="1" />
                  {(userDetail.userRoleName !== UserRoleType.Beneficiary ||
                    (petitionerFormShow &&
                      userDetail.userRoleName == UserRoleType.Beneficiary)) && (
                    <Tab label="Petitioner" value="2" />
                  )}
                  {[2, 3].includes(userDetail.role_id) ||
                  (userDetail.role_id === 4 &&
                    searchParams.get('type') === 'petition') ||
                  (petitions?.petitionData?.isAffilateCase &&
                    petitions?.petitionData?.affilateRoleId === 3) ? (
                    <Tab label="Dependent" value="3" />
                  ) : (
                    ''
                  )}
                  {['1', '2'].includes(tabValue) &&
                    ((!petitions?.petitionData?.isAffilateCase &&
                      userDetail.userRoleName === UserRoleType.Attorney) ||
                      (petitions?.petitionData?.isAffilateCase &&
                        [3].includes(
                          petitions?.petitionData?.affilateRoleId,
                        ))) && (
                      <Typography
                        component={'div'}
                        className=" d-flex-jae bu-color w-100 pl-2 pb-1 pointer">
                        <Button
                          className="n-add-btn d-flex-jae f-16"
                          onClick={() => {
                            setIsSection((pre) => ({ ...pre, show: true }));
                            setSectionParams({
                              name: '',
                              sectionType:
                                tabValue === '1'
                                  ? 'beneficiary'
                                  : tabValue === '2'
                                  ? 'petitioner'
                                  : 'dependent',
                              skip: 0,
                              take: 50,
                            });
                          }}>
                          <AddIcon
                            sx={{
                              fontSize: '22px !important',
                              marginRight: '8px',
                            }}
                          />
                          Add Form
                        </Button>
                      </Typography>
                    )}
                </TabList>
              </Box>
              {(searchParams.get('leadUser') !== '' &&
                userDetail.role_id === 4) ||
              !_.isEmpty(petitions) ? (
                <Typography
                  component={'div'}
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '0px 8px 8px 8px',
                    minHeight: '130px',
                  }}>
                  <TabPanel value="1">
                    {listDataMsg === 'success' && (
                      <Typography component={'div'}>
                        <WizardForm
                          focusElement={
                            location.state?.focusElement
                              ? location.state?.focusElement
                              : null
                          }
                          ref={wizardRef}
                          formType={'configureForms'}
                          list={treeList}
                          tabHidden={false}
                          formId={
                            userDetail.role_id === 4 && searchParams.get('lead')
                              ? leadLevelInformation.beneficiary_form_id
                              : petitions?.petitionData?.beneficiary_form_id
                          }
                          beneficiaryID={
                            userDetail.role_id === 4
                              ? userDetail.id
                              : petitions?.petitionData?.beneficiary_id
                          }
                          leadId={
                            userDetail.role_id === 4 && searchParams.get('lead')
                              ? leadLevelInformation.lead_id
                              : petitions?.petitionData?.lead_id
                          }
                          petitionID={
                            userDetail.role_id === 4 &&
                            searchParams.get('lead') &&
                            searchParams.get('type') !== 'petition'
                              ? ''
                              : petitions?.petitionData?.id
                          }
                          showManageOption={true}
                          setHCData={setHCData}
                        />
                      </Typography>
                    )}
                  </TabPanel>
                  {(userDetail.userRoleName !== UserRoleType.Beneficiary ||
                    (petitionerFormShow &&
                      userDetail.userRoleName == UserRoleType.Beneficiary)) && (
                    <TabPanel value="2">
                      {listDataMsg === 'success' && (
                        <Typography component={'div'}>
                          <WizardForm
                            focusElement={
                              location.state?.focusElement
                                ? location.state?.focusElement
                                : null
                            }
                            ref={wizardRef}
                            formType={'configureForms'}
                            list={treeList}
                            tabHidden={false}
                            formId={petitions.petitionData.petitioner_form_id}
                            beneficiaryID={
                              petitions.petitionData.beneficiary_id
                            }
                            leadId={petitions.petitionData.lead_id}
                            petitionID={petitions.petitionData.id}
                            showManageOption={true}
                            setHCData={setHCData}
                          />
                        </Typography>
                      )}
                    </TabPanel>
                  )}
                  {[2, 3].includes(userDetail.role_id) ||
                  (petitions?.petitionData?.isAffilateCase &&
                    petitions?.petitionData?.affilateRoleId) ||
                  (userDetail.role_id === 4 &&
                    searchParams.get('type') === 'petition') ? (
                    <TabPanel value="3">
                      {dependentUserId &&
                      dependentUserId !== 0 &&
                      listDataMsg === 'success' ? (
                        <>
                          <Typography
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              marginBottom: '1rem',
                            }}>
                            <Button
                              className="n-add-btn"
                              onClick={() => {
                                setHCData({
                                  comments: [],
                                  history: [],
                                  loading: false,
                                  selectedQuestion: {},
                                });
                                setDependentUserId(0);
                                setTreeList([]);
                                setListDataMsg('');
                              }}>
                              <ArrowBackIcon sx={{ marginRight: '1rem' }} />
                              Back
                            </Button>
                          </Typography>
                          <WizardForm
                            focusElement={
                              location.state?.focusElement
                                ? location.state?.focusElement
                                : null
                            }
                            formType={'dependent'}
                            list={treeList}
                            tabHidden={false}
                            formId={null}
                            beneficiaryID={
                              petitions.petitionData.beneficiary_id
                            }
                            leadId={petitions.petitionData.lead_id}
                            petitionID={petitions.petitionData.id}
                            showManageOption={true}
                            dependentPersonId={dependentUserId}
                            setHCData={setHCData}
                          />
                        </>
                      ) : (
                        <Depentent
                          open={open}
                          openAddModal={openAddModal}
                          setOpen={setOpen}
                          setOpenAddModal={setOpenAddModal}
                          setDependentUserId={setDependentUserId}
                          QuestionWizardFormData={() =>
                            getQuestionWizardFormData(tabValue)
                          }
                        />
                      )}
                    </TabPanel>
                  ) : (
                    ''
                  )}
                </Typography>
              ) : (
                ''
              )}
            </TabContext>
          </Box>
        </Grid>
        <Grid item md={2} sm={12}>
          <Typography
            component={'div'}
            className="bulk-add-box"
            style={{ cursor: 'pointer' }}>
            <Typography component={'div'} className="add-inner">
              {/* {['1', '2'].includes(tabValue) &&
                userDetail.userRoleName === UserRoleType.Attorney && (
                  <Typography component={'div'} className={'add-inner mb-1 '}>
                    <Typography component={'div'} className="add-inner-box" />
                    <Typography className="link-txt f-16 pt-5" />
                    <Typography
                      component={'div'}
                      className=" d-flex-a bu-color pl-2 pt-2 pb-1"
                      onClick={() => {
                        setIsSection((pre) => ({ ...pre, show: true }));
                        setSectionParams({
                          name: '',
                          sectionType:
                            tabValue === '1'
                              ? 'beneficiary'
                              : tabValue === '2'
                              ? 'petitioner'
                              : 'dependent',
                          skip: 0,
                          take: 50,
                        });
                      }}>
                      Add Section
                    </Typography>
                  </Typography>
                )} */}

              {!petitions?.petitionData?.isAffilateCase &&
                tabValue === '1' &&
                userDetail.userRoleName === UserRoleType.Attorney && (
                  <Typography
                    component={'div'}
                    className={'add-inner mb-1 mt-2'}>
                    <Typography component={'div'} className="add-inner-box" />
                    <Typography className="link-txt pt-5 f-16" />
                    <Typography
                      className="d-flex-a bu-color pl-2 pb-1 a-mt-3"
                      onClick={() => getCopyData('beneficiary')}>
                      Copy Beneficiary
                    </Typography>
                    <Typography
                      className="d-flex-a bu-color pt-2 pl-2 pb-1 a-mt-3"
                      onClick={() => {
                        setIsSection((pre) => ({
                          ...pre,
                          allSection: true,
                          show: true,
                          type: 'beneficiary',
                        }));
                      }}>
                      View / Add Form
                    </Typography>
                  </Typography>
                )}

              {!petitions?.petitionData?.isAffilateCase &&
                tabValue === '2' &&
                [2, 3].includes(userDetail.role_id) && (
                  <Typography
                    component={'div'}
                    className={'add-inner mb-1 mt-2'}>
                    <Typography component={'div'} className="add-inner-box" />
                    <Typography className="link-txt pt-5 f-16" />
                    <Typography
                      className="d-flex-a bu-color pl-2 pb-1 a-mt-3"
                      onClick={() => getCopyData('petitioner')}>
                      Copy Petition
                    </Typography>
                    {userDetail.userRoleName === UserRoleType.Attorney && (
                      <Typography
                        className="d-flex-a bu-color pt-2 pl-2 pb-1 a-mt-3"
                        onClick={() => {
                          setIsSection((pre) => ({
                            ...pre,
                            allSection: true,
                            show: true,
                            type: 'petitioner',
                          }));
                        }}>
                        View / Add Form
                      </Typography>
                    )}
                  </Typography>
                )}

              {tabValue === '3' &&
                ((userDetail.userRoleName === UserRoleType.Attorney &&
                  !petitions?.petitionData?.isAffilateCase) ||
                  (petitions?.petitionData?.isAffilateCase &&
                    [3].includes(petitions?.petitionData?.affilateRoleId))) &&
                (dependentUserId ? (
                  <Typography
                    component={'div'}
                    className={'add-inner mb-1 mt-2'}>
                    <Typography component={'div'} className="add-inner-box" />
                    <Typography className="link-txt pt-5 f-16" />
                    <Typography
                      className="d-flex-a bu-color pl-2 pb-1 a-mt-3"
                      onClick={() => {
                        setIsSection((pre) => ({ ...pre, show: true }));
                        setSectionParams({
                          name: '',
                          sectionType: 'dependent',
                          skip: 0,
                          take: 50,
                        });
                      }}>
                      Add Form
                    </Typography>
                  </Typography>
                ) : (
                  <>
                    <Typography component={'div'} className={'add-inner'}>
                      <Typography component={'div'} className="add-inner-box" />
                      <Typography className="link-txt f-16 pt-5" />
                      <Typography
                        className="black-white-text d-flex-a pl-2 pt-2 pb-1 a-mt-3"
                        onClick={() => handleOpen()}>
                        Add New
                      </Typography>
                    </Typography>
                    <Typography component={'div'} className={'add-inner mt-1 '}>
                      <Typography component={'div'} className="add-inner-box" />
                      <Typography
                        className="d-flex-a bu-color pt-2 pl-2 pb-1 a-mt-3"
                        onClick={() => handleAddModalOpen()}>
                        Add Existing
                      </Typography>
                    </Typography>
                  </>
                ))}

              <Typography component={'div'} className="add-inner mb-1 mt-2">
                <Typography component={'div'} className="add-inner-box" />
                <Typography className="link-txt pt-1 f-16" />
                <Typography
                  className="d-flex-a bu-color pl-2 pb-1 a-mt-3"
                  onClick={handleClick}>
                  <img
                    src={Win}
                    alt="im"
                    className="mr-1"
                    style={{ width: '20px !important' }}
                  />
                  Winning Strategies
                </Typography>
                <Popper
                  id={winningStrategyId}
                  className="own-popper"
                  open={winningStrategyAnchor.open}
                  anchorEl={winningStrategyAnchor.anchorEl}
                  placement="left">
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box sx={{ padding: '1rem 1rem 0.5rem 1rem' }}>
                      <Typography
                        className="new-searc"
                        sx={{ marginBottom: '.5rem' }}>
                        <CustomInput
                          placeHolder={'Type to filter'}
                          className={'custom-search-input'}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={async () => {
                                    setWinningStrategies({
                                      data: [],
                                      total: 0,
                                    });
                                    await instantState(setWinningStrategies);
                                    setWinningParams((prev) => ({
                                      ...prev,
                                      search: '',
                                      skip: 0,
                                      take: 10,
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setWinningStrategies({
                              data: [],
                              total: 0,
                            });

                            setWinningParams((prev) => ({
                              ...prev,
                              search: e.target.value,
                              skip: 0,
                              take: 10,
                            }));
                          }}
                          value={winningParams.search}
                        />
                      </Typography>
                      {winningStrategies.data &&
                      winningStrategies.data.length > 0 ? (
                        <InfinityScroll
                          paramState={setWinningParams}
                          height={'350px'}
                          scrollableTargetId={'winningStrategy'}
                          data={winningStrategies.data}
                          rowProps={rowProps}
                          count={winningStrategies.total}
                        />
                      ) : (
                        <Typography
                          component={'div'}
                          className="pop-det-box"
                          sx={{ height: '350px' }}>
                          <Typography className="pop-dis">
                            No Record(s) Found.
                          </Typography>
                        </Typography>
                      )}
                      <div className="pop-dis" style={{ textAlign: 'center' }}>
                        {!_.isEmpty(winningStrategies.data)
                          ? winningStrategies.data.length
                          : 0}{' '}
                        of {winningStrategies.total} winning strategies
                      </div>
                    </Box>
                  </ClickAwayListener>
                </Popper>
              </Typography>
              <Translate />
              {hcData.selectedQuestion && (
                <HistoryComments
                  hcData={hcData}
                  setHCData={setHCData}
                  wizardRef={wizardRef}
                  tabName={tabValue}
                  screenName={'complete-question'}
                  type={
                    location.state?.showType === 'leads' ? 'lead' : 'petition'
                  }
                  formId={
                    tabValue === '3'
                      ? null
                      : tabValue === '1'
                      ? petitions?.petitionData?.beneficiary_form_id
                      : petitions?.petitionData?.petitioner_form_id
                  }
                  leadId={null}
                  beneficiaryId={null}
                  petitionID={petitionId}
                  affilateUserList={
                    petitions?.petitionData?.isAffilateCase
                      ? petitions?.petitionData?.affilateUserList
                      : []
                  }
                />
              )}
            </Typography>
          </Typography>
        </Grid>

        {isSection.show && (
          <AddSection
            isDependent={
              sectionParams.sectionType === 'dependent' ? dependentUserId : null
            }
            setSectionParams={setSectionParams}
            isSection={isSection}
            setIsSection={setIsSection}
            treeList={treeList}
            getQuestionWizardFormData={() =>
              getQuestionWizardFormData(tabValue)
            }
            hasMoreResults={hasMoreResults}
            getMoreLoader={getMoreLoader}
            getMoreListAdd={getMoreListAdd}
          />
        )}
        {isCopy.show && (
          <CopyAction
            isCopy={isCopy}
            setIsCopy={setIsCopy}
            getQuestionWizardFormData={getQuestionWizardFormData}
            value={tabValue}
            setLoader={setLoader}
            params={params}
            setParams={setParams}
            hasMoreResults={hasMoreResults}
            getMoreLoader={getMoreLoader}
            getMoreListCopy={getMoreListCopy}
          />
        )}
      </Grid>
    </>
  );
};
export default CompleteQuestion;
