import { yupResolver } from '@hookform/resolvers/yup';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import AddIcon from 'assets/images/addr.svg';
// import bg380 from 'assets/images/rectangle380.png';
import Closeicon from 'assets/images/wclose.svg';
import DocIcon from 'assets/images/wdoc.svg';
import DownIcon from 'assets/images/wdown.svg';
import InvoiceLoader from 'components/ContentLoader/Invoice';
import TableLoader from 'components/ContentLoader/TableLoader';
import CustomInput from 'components/CustomInput/customInput';
import Footer from 'components/Footer';
import PdfUrlViewer from 'components/PdfViewer/PdfUrlViewer';
import { AttorneyPrivateRoutes } from 'config';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData, getS3file } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { rfeIssueAdd } from 'utils/ValidatorSchema';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  border: `1px solid ${theme.palette.divider}`,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <img src={DownIcon} alt="down" style={{ transform: 'rotate(270deg)' }} />
    }
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  backgroundColor: theme.palette.mode === 'dark' ? '#E2F2FF' : '#E2F2FF',
  flexDirection: 'row-reverse',
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: theme.spacing(2),
}));

export const RfeResponder: React.FC<any> = React.memo(() => {
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const navigate = useNavigate();
  const location: any = useLocation();
  const { petitionId, documentId } = useParams();
  const [searchParams] = useSearchParams();
  const [expand, setExpand] = useState(true);
  const [scale, setScale] = useState<number>(2);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [pdf, setPdf] = useState<any>('');
  const [images, setImages] = useState([]);
  const [PDFloader, setPDFLoader] = useState(true);
  const [showParentTable, setShowParentTable] = useState(true);
  const [showChildTable, setShowChildTable] = useState(true);
  const [tableLoader, setTableLoader] = useState(true);
  const [coOrdinates, setCoOrdinates] = useState({
    enabled: false,
    page_data: [],
    type: '',
  });

  const [rfeResult, setRFEResult] = useState({
    acceptedCount: 0,
    created_at: '',
    data: [],
    filePath: '',
    file_name: '',
    total: 0,
  });

  const [PDFUrl, setPDFUrl] = useState<any>('');

  const windowRef: any = useRef();

  const [open, setOpen] = useState(false);
  const [addDisable, setAddDisable] = useState(false);

  const [selectedPageData, setSelectedPageData] = useState<any>([]);

  const {
    control: control,
    handleSubmit: handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      bbox: '',
      docLevelGrouping: null,
      fileId: '',
      issueText: '',
      issueTitle: '',
      issueType: 'Text',
      pageGroup: null,
      pageNumber: null,
      parentIssueId: '',
      predIssue: '',
      predSubIssue: '',
      type: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(rfeIssueAdd),
  });

  const onSubmitForm = (data: any) => {
    setAddDisable(true);
    const emptyData = [];
    coOrdinates.page_data.map((pgdata) => {
      emptyData.push({
        combined_bbox: {
          x_max: Number(pgdata.w),
          x_min: Number(pgdata.x),
          y_max: Number(pgdata.h),
          y_min: Number(pgdata.y),
        },
        page_dim: [0, 0, 792, 612],
        page_info: [],
        page_number: Number(pgdata.pageNumber),
      });
    });
    const payload = {
      bbox: '',
      docLevelGrouping: Number(data.docLevelGrouping),
      fileId: data.fileId,
      issueText: data.issueText,
      issueTitle: data.issueTitle,
      issueType: 'Text',
      pageData: emptyData,
      pageGroup: Number(data.pageGroup),
      pageNumber: null,
      predIssue: '',
    };
    if (data.type === 'sub-issue' || data.type === 'parent-sub-issue') {
      payload['parentIssueId'] = data.parentIssueId;
      payload['predSubIssue'] = data.predSubIssue;
    }
    addNewData(
      payload,
      `${String(COMMON_ENDPOINT.rfeResponder)}/${
        data.type === 'issue' ? 'add-issue' : 'add-sub-issue'
      }`,
    )
      .then((res) => {
        if (res) {
          setAddDisable(false);
          getDocument();
          handleClose();
        } else {
          setAddDisable(false);
          toast.error('Something went wrong, Please try again', alertOptions);
        }
      })
      .catch((err) => {
        toast.error(err.message, alertOptions);
      });
  };

  const handleClickToView = useCallback((type: string, issue: any) => {
    setSelectedPageData(
      issue?.page_data.map((data) => ({ ...data, id: issue?.id })),
    );
  }, []);

  const handleClickOpen = useCallback(
    (type: string, issueData: any) => {
      if (coOrdinates.enabled) {
        setValue(
          'fileId',
          type === 'parent-sub-issue'
            ? issueData?.rfe_file_id
            : issueData[issueData.length - 1]?.rfe_file_id,
        );
        setValue('type', type);
        setValue(
          'pageGroup',
          Number(
            type === 'issue'
              ? issueData[issueData.length - 1]?.issue_in_page_group
              : type === 'parent-sub-issue'
              ? issueData?.issue_in_page_group
              : issueData[issueData.length - 1]?.sub_issue_in_page_group,
          ),
        );
        setValue(
          'docLevelGrouping',
          Number(
            type === 'issue'
              ? issueData[issueData.length - 1]?.issue_doc_level_grouping
              : type === 'parent-sub-issue'
              ? issueData?.issue_doc_level_grouping
              : issueData[issueData.length - 1]?.sub_issue_doc_level_grouping,
          ),
        );

        setValue(
          'parentIssueId',
          type === 'parent-sub-issue'
            ? issueData?.id
            : type === 'sub-issue'
            ? issueData[issueData.length - 1]?.rfe_pred_issue_id
            : '',
        );

        setCoOrdinates((pre) => ({ ...pre, type: type }));
        setOpen(true);
      } else {
        toast.warn(
          'You should draw a rectangle on the PDF to getting the Co-ordinates',
          alertOptions,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coOrdinates.enabled],
  );

  const handleClose = () => {
    setCoOrdinates({
      enabled: false,
      page_data: [],
      type: '',
    });
    setAddDisable(false);
    setOpen(false);
  };

  const scrollToItem = () => {
    windowRef.current && windowRef.current.scrollToItem(page - 1, 'start');
  };
  const overallPage = useCallback((totalPage) => {
    setTotal(totalPage);
  }, []);

  const toggleAccordion = () => {
    setExpand(!expand);
  };
  const showParent = () => {
    setShowParentTable(!showParentTable);
  };
  const showChild = () => {
    setShowChildTable(!showChildTable);
  };

  const getDocument = () => {
    if (documentId) {
      getAllListData(
        `${String(COMMON_ENDPOINT.rfeResponder)}/issues/${String(documentId)}`,
      )
        .then((resp: any) => {
          setShowParentTable(true);
          setShowChildTable(true);
          if (resp && resp.data && resp.data.length) {
            setRFEResult({
              acceptedCount: resp.acceptedCount ? resp.acceptedCount : 0,
              created_at: resp.fileCreated ? resp.fileCreated : '',
              data: resp.data,
              filePath: resp.file_path,
              file_name: resp.fileName ? resp.fileName : '',
              total: resp.total,
            });
            const obj = {
              key: resp.file_path,
            };
            showPdf(obj);
          } else {
            setTableLoader(false);
            setPDFLoader(false);
          }
        })
        .catch((err) => {
          setTableLoader(false);
          setPDFLoader(false);
        });
    }
  };
  const showPdf = (obj) => {
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        const url = URL.createObjectURL(data);
        setPDFUrl(url);
        setPDFLoader(false);
      })
      .catch((err) => {
        setPDFLoader(false);
        toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };
  const renderPage = async () => {
    const imagesList = [];
    const canvas = document.createElement('canvas');
    canvas.setAttribute('className', 'canv');

    if (pdf) {
      if (searchParams.get('viewall') && selectedPageData.length) {
        for (let i = 0; i < selectedPageData.length; i++) {
          const page = await pdf.getPage(selectedPageData[i].page_number);
          const viewport = page.getViewport({ scale: scale });
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const render_context = {
            canvasContext: canvas.getContext('2d'),
            viewport: viewport,
          };
          await page.render(render_context).promise;
          const img = canvas.toDataURL('image/png');
          imagesList.push({ img, pageIndex: page._pageIndex });
        }
      } else {
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const viewport = page.getViewport({ scale: scale });
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const render_context = {
            canvasContext: canvas.getContext('2d'),
            viewport: viewport,
          };
          await page.render(render_context).promise;
          const img = canvas.toDataURL('image/png');
          imagesList.push({ img, pageIndex: page._pageIndex });
        }
      }
      imagesList.length && setImages(imagesList);
    }
  };

  useEffect(() => {
    if (selectedPageData && selectedPageData.length) {
      renderPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPageData]);

  useEffect(() => {
    scrollToItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (pdf && !searchParams.get('viewall')) renderPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf]);

  useEffect(() => {
    if (location.state && location.state.parse) {
      const visaTypeSplitted =
        petitions && petitions.petitionData && petitions.petitionData.visa_type
          ? petitions.petitionData.visa_type.split('-')
          : '';
      const payload = {
        documentId: location.state.rfeResponse.id,
        documentPath: location.state.rfeResponse.document_path,
        visaType:
          String(visaTypeSplitted[0]).toLocaleLowerCase() +
          String(visaTypeSplitted[1]).toLocaleLowerCase(),
      };

      const obj = {
        key: `petitions/${petitionId}/rfe/${String(
          location.state.rfeResponse.document_path.split('uploaded_docs/')[1],
        )}`,
      };
      showPdf(obj);

      addNewData(payload, `${String(COMMON_ENDPOINT.rfeResponder)}/rfe-parser`)
        .then((res) => {
          if (res && res.documentId) {
            getAllListData(
              `${String(COMMON_ENDPOINT.rfeResponder)}/add-rfe-data/${String(
                res.documentId,
              )}`,
            )
              .then((resp: any) => {
                toast.success(resp.message, alertOptions);
                navigate('.', { replace: true });
              })
              .catch((err) => {
                navigate(-1);
              });
          } else {
            toast.error('Something went wrong, Please try again', alertOptions);
            navigate(-1);
          }
        })
        .catch((err) => {
          toast.error(err.message, alertOptions);
          navigate(-1);
        });
    } else {
      getDocument();
      setTableLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, petitions]);

  useEffect(() => {
    const externalScript = document.createElement('script');
    const loadError = (onError) => {
      console.error(onError);
    };

    const LoadExternalScript = () => {
      externalScript.onerror = loadError;
      externalScript.id = 'external';
      externalScript.async = true;
      externalScript.type = 'text/javascript';
      externalScript.setAttribute('crossorigin', 'anonymous');
      document.body.appendChild(externalScript);
      externalScript.src = '//mozilla.github.io/pdf.js/build/pdf.js';
    };
    LoadExternalScript();

    return () => {
      document.body.removeChild(externalScript);
    };
  }, []);

  // window.onbeforeunload = (event) => {
  //   const e = event || window.event;
  //   // Cancel the event
  //   e.preventDefault();
  //   if (e) {
  //     e.returnValue = ''; // Legacy method for cross browser support
  //     navigate('.', { replace: true });
  //   }
  //   return ''; // Legacy method for cross browser support
  // };

  return (
    <Typography component={'div'}>
      <Typography component={'div'} className="submis-head">
        <Typography component={'div'} className="s1-title">
          RFE Responder / Step 2 of 3
        </Typography>
        <Typography component={'div'} className="d-flex-a">
          {rfeResult.total > 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className="mr-2">
              <CircularProgress
                variant="determinate"
                value={(rfeResult.acceptedCount / rfeResult.total) * 100}
                sx={{ zIndex: 1 }}
              />
              <Typography
                position="absolute"
                className="d-flex-ja"
                sx={{
                  border: '2px solid #fff',
                  borderRadius: '50%',
                  color: '#fff !important',
                  fontSize: '12px',
                  height: '40px',
                  width: '40px',
                }}>
                {rfeResult.acceptedCount}/{rfeResult.total}
              </Typography>
            </Box>
          )}
          <img
            src={Closeicon}
            alt="Close"
            className="pointer"
            onClick={() =>
              navigate(
                `${String(AttorneyPrivateRoutes.RFERESPOEND.RFEVIEW)}/${String(
                  petitionId,
                )}`,
              )
            }
          />
        </Typography>
      </Typography>
      <Typography
        component={'div'}
        className="upload-body"
        sx={{ backgroundColor: ' #F6F6F6' }}>
        <Grid container columnSpacing={2.5}>
          <Grid item md={4} sm={12}>
            <Typography component={'div'} className="issue-top f-16">
              <Grid item md={10} sm={6}>
                <img src={DocIcon} alt="icon" className="mr-2" /> Issues
                Identified
              </Grid>
              {searchParams.get('viewall') && (
                <Grid item md={2} sm={6} className="d-flex-a pointer">
                  <Typography
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(
                        `${String(
                          AttorneyPrivateRoutes.RFERESPOEND.RFERESPONDER,
                        )}/${petitionId}/${String(documentId)}`,
                        {
                          state: { parse: false },
                        },
                      );
                    }}
                    sx={{ color: '#FFF' }}>
                    Exit View
                  </Typography>
                </Grid>
              )}
            </Typography>
            <Typography
              component={'div'}
              className="issue-box"
              style={{ overflowY: 'auto' }}>
              {tableLoader ? (
                <>
                  <div className="f-20 f-bold">
                    Please wait until parsing the pdf...
                  </div>
                  <TableLoader />
                </>
              ) : rfeResult.data && rfeResult.data.length > 0 ? (
                <Accordion expanded={expand}>
                  <AccordionSummary
                    onClick={toggleAccordion}
                    aria-controls={'panel1a-content'}
                    id={'panel1a-header'}>
                    <Typography
                      component={'div'}
                      className="d-flex-a d-flex-sb w-100">
                      <Typography className="f-19">
                        {rfeResult.file_name
                          ? `${rfeResult.file_name} - Added on : ${moment(
                              rfeResult.created_at,
                            ).format('MMM DD, YYYY - HH:mm A')}`
                          : '-'}
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component={'div'}>
                      <Typography
                        component={'div'}
                        sx={{
                          alignItems: 'flex-start',
                          display: 'flex',
                          marginTop: '16px',
                        }}>
                        <img
                          src={DownIcon}
                          alt="down"
                          style={{
                            transform: showParentTable
                              ? 'rotate(360deg)'
                              : 'rotate(270deg)',
                          }}
                          className="mt-2 mr-2 pointer"
                          onClick={() => showParent()}
                        />
                        <Typography component={'div'} sx={{ flex: 1 }}>
                          <TableContainer>
                            <Table
                              className="issue-table"
                              aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ width: '5%' }}>
                                    SI.No
                                  </TableCell>
                                  <TableCell sx={{ width: '45%' }}>
                                    Issue Title
                                  </TableCell>
                                  <TableCell sx={{ width: '45%' }}>
                                    Issue Text
                                  </TableCell>
                                  <TableCell sx={{ width: '5%' }}>
                                    {!searchParams.get('viewall') && (
                                      <Typography
                                        className="f-9 pointer d-flex-a"
                                        onClick={() =>
                                          handleClickOpen(
                                            'issue',
                                            rfeResult.data,
                                          )
                                        }>
                                        <img
                                          src={AddIcon}
                                          alt="add"
                                          className="mr-1"
                                        />
                                        Add
                                      </Typography>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {showParentTable && (
                                <TableBody>
                                  {rfeResult.data.map((rfeData, index) => (
                                    <>
                                      <TableRow
                                        key={rfeData.id}
                                        onClick={() => {
                                          searchParams.get('viewall') &&
                                            handleClickToView('issue', rfeData);
                                        }}
                                        className={
                                          searchParams.get('viewall')
                                            ? 'pointer'
                                            : ''
                                        }>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {rfeData.issue_title}
                                        </TableCell>
                                        <TableCell>
                                          <Typography
                                            component={'div'}
                                            className="hideshow">
                                            {rfeData.issue_text}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          {!searchParams.get('viewall') &&
                                            rfeData.subIssueList.length ===
                                              0 && (
                                              <Typography
                                                className="f-9 pointer d-flex-a"
                                                onClick={() =>
                                                  handleClickOpen(
                                                    'parent-sub-issue',
                                                    rfeData,
                                                  )
                                                }>
                                                <img
                                                  src={AddIcon}
                                                  alt="add"
                                                  className="mr-1"
                                                />
                                                Add
                                              </Typography>
                                            )}
                                        </TableCell>
                                      </TableRow>
                                      {rfeData.subIssueList &&
                                      rfeData.subIssueList.length > 0 ? (
                                        <TableRow
                                          sx={{
                                            backgroundColor: 'unset !important',
                                          }}>
                                          <TableCell
                                            colSpan={12}
                                            sx={{
                                              backgroundColor:
                                                'unset !important',
                                            }}>
                                            <Typography
                                              component={'div'}
                                              sx={{
                                                alignItems: 'flex-start',
                                                display: 'flex',
                                                marginTop: '16px',
                                              }}>
                                              <img
                                                src={DownIcon}
                                                alt="down"
                                                style={{
                                                  transform: showChildTable
                                                    ? 'rotate(360deg)'
                                                    : 'rotate(270deg)',
                                                }}
                                                className="mt-2 mr-2 pointer"
                                                onClick={() => showChild()}
                                              />
                                              <Typography
                                                component={'div'}
                                                sx={{ flex: 1 }}>
                                                <TableContainer>
                                                  <Table
                                                    className="issue-table"
                                                    aria-label="simple table">
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell
                                                          sx={{ width: '5%' }}>
                                                          SI.No
                                                        </TableCell>
                                                        <TableCell
                                                          sx={{ width: '45%' }}>
                                                          Sub-Issue Title
                                                        </TableCell>
                                                        <TableCell
                                                          sx={{ width: '45%' }}>
                                                          Sub-Issue Text
                                                        </TableCell>
                                                        <TableCell
                                                          sx={{ width: '5%' }}>
                                                          {!searchParams.get(
                                                            'viewall',
                                                          ) && (
                                                            <Typography
                                                              className="f-9 pointer d-flex-a"
                                                              onClick={() =>
                                                                handleClickOpen(
                                                                  'sub-issue',
                                                                  rfeData.subIssueList,
                                                                )
                                                              }>
                                                              <img
                                                                src={AddIcon}
                                                                alt="add"
                                                                className="mr-1"
                                                              />
                                                              Add
                                                            </Typography>
                                                          )}
                                                        </TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    {showChildTable && (
                                                      <TableBody>
                                                        {rfeData.subIssueList.map(
                                                          (subData, sIndex) => (
                                                            <TableRow
                                                              key={subData.id}
                                                              onClick={() => {
                                                                searchParams.get(
                                                                  'viewall',
                                                                ) &&
                                                                  handleClickToView(
                                                                    'sub-issue',
                                                                    subData,
                                                                  );
                                                              }}
                                                              className={
                                                                searchParams.get(
                                                                  'viewall',
                                                                )
                                                                  ? 'pointer'
                                                                  : ''
                                                              }>
                                                              <TableCell>
                                                                {Number(
                                                                  sIndex,
                                                                ) + 1}
                                                              </TableCell>
                                                              <TableCell>
                                                                {
                                                                  subData.sub_issue_title
                                                                }
                                                              </TableCell>
                                                              <TableCell>
                                                                <Typography
                                                                  component={
                                                                    'div'
                                                                  }
                                                                  className="hideshow">
                                                                  {
                                                                    subData.sub_issue_text
                                                                  }
                                                                </Typography>
                                                              </TableCell>
                                                              <TableCell />
                                                            </TableRow>
                                                          ),
                                                        )}
                                                      </TableBody>
                                                    )}
                                                  </Table>
                                                </TableContainer>
                                              </Typography>
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      ) : null}
                                    </>
                                  ))}
                                </TableBody>
                              )}
                            </Table>
                          </TableContainer>
                        </Typography>
                      </Typography>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Typography
                  className="note-box2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                  <Typography className="inb2" />
                  <Typography className="black-white-text ctitle hideshow">
                    No Record(s) Found.
                  </Typography>
                </Typography>
              )}

              {rfeResult.data.length > 0 && !searchParams.get('viewall') && (
                <Typography
                  component={'div'}
                  className="d-flex-jae mt-2"
                  sx={{
                    backgroundColor: 'white',
                    bottom: '-20px',
                    position: 'sticky',
                    zIndex: 1,
                  }}>
                  <Button
                    className="n-add-btn mt-2 mb-2"
                    onClick={() =>
                      navigate(
                        `${String(
                          AttorneyPrivateRoutes.RFERESPOEND.RFERESPONDERACCEPT,
                        )}/${petitionId}/${String(documentId)}`,
                      )
                    }>
                    Continue
                  </Button>
                </Typography>
              )}
            </Typography>
          </Grid>
          <Grid item md={8} sm={12}>
            <Typography component={'div'} sx={{ height: '75vh' }}>
              <Grid container md={12}>
                <Grid item md={12} sm={12}>
                  <Typography component={'div'} className="issue-top">
                    <Grid item md={5} sm={12}>
                      {/* <img src={DocIcon} alt="icon" className="mr-2" /> */}
                      {rfeResult.file_name ? rfeResult.file_name : '-'}
                    </Grid>
                    {searchParams.get('viewall') &&
                    searchParams.get('viewall') !== '' ? null : (
                      <Grid item md={3} sm={12} className="d-flex-a">
                        <Tooltip title="Zoom Out" className="pointer">
                          <ZoomOutIcon
                            className="mr-1"
                            onClick={() =>
                              rfeResult.data?.length > 0 &&
                              scale > 0.25 &&
                              setScale((v) => (v -= 0.25))
                            }
                          />
                        </Tooltip>
                        <Typography
                          component={'div'}
                          className={
                            'custom-search-input bo-rles pdf-zoom mr-1'
                          }>
                          {String(scale * 100).concat('%')}
                        </Typography>
                        <Tooltip
                          title="Zoom In"
                          className="pointer"
                          onClick={() =>
                            rfeResult.data?.length > 0 &&
                            scale < 3 &&
                            setScale((v) => (v += 0.25))
                          }>
                          <ZoomInIcon />
                        </Tooltip>
                      </Grid>
                    )}
                    {searchParams.get('viewall') &&
                    searchParams.get('viewall') !== '' ? null : (
                      <Grid item md={2} sm={12} className="d-flex-a">
                        <Tooltip title="Page Up" className="pointer">
                          <ArrowUpwardIcon
                            className="mr-1"
                            onClick={() => page > 1 && setPage(page - 1)}
                          />
                        </Tooltip>
                        <Divider
                          orientation="vertical"
                          flexItem
                          style={{ height: '22px', marginTop: '1px' }}
                        />
                        <Tooltip title="Page Down" className="pointer">
                          <ArrowDownwardIcon
                            className="ml-1"
                            onClick={() =>
                              page < pdf.numPages && setPage(page + 1)
                            }
                          />
                        </Tooltip>
                      </Grid>
                    )}
                    {searchParams.get('viewall') &&
                    searchParams.get('viewall') !== '' ? null : (
                      <Grid item md={2} sm={12} className="d-flex-a">
                        <Typography>
                          <CustomInput
                            value={page.toString()}
                            onChange={(e) => setPage(e.target.value)}
                            type="number"
                            className={'custom-pdf-input bo-rles mr-1'}
                            disabled={rfeResult.data?.length === 0}
                          />
                        </Typography>
                        <Divider
                          orientation="vertical"
                          flexItem
                          style={{
                            height: '20px',
                            marginTop: '7px',
                            rotate: '22deg',
                          }}
                        />
                        <span className="ml-1">{total}</span>
                      </Grid>
                    )}
                    {/* <Grid item md={1} sm={12}>
                      <MoreVertIcon />
                    </Grid> */}
                  </Typography>
                  {PDFloader ? (
                    <InvoiceLoader />
                  ) : (
                    <Grid
                      container
                      md={12}
                      sm={12}
                      sx={{
                        backgroundColor: '#A5A5A5 !important',
                      }}>
                      <Grid
                        item
                        md={2}
                        sm={12}
                        sx={{ maxHeight: '720px', overflowY: 'auto' }}>
                        {images?.map((data, index) => (
                          <>
                            <Box
                              key={index}
                              sx={{
                                '& > :not(style)': {
                                  height: 100,
                                  m: 2,
                                  width: 80,
                                },
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <img
                                src={data.img}
                                className="pointer"
                                onClick={() => setPage(index + 1)}
                                style={{
                                  border:
                                    index + 1 == page && 'solid 4px #92b6ef',
                                }}
                              />
                            </Box>

                            <Typography
                              className="d-flex-ja"
                              sx={{ fontSize: '12px' }}>
                              {index + 1}
                            </Typography>
                          </>
                        ))}
                      </Grid>
                      <Grid item md={10} sm={12}>
                        <Typography
                          component={'div'}
                          className="issue-box"
                          sx={{
                            backgroundColor: '#A5A5A5 !important',
                            padding: '0px 0px 20px 0px !important',
                          }}>
                          <PdfUrlViewer
                            url={PDFUrl}
                            setPdf={setPdf}
                            scale={scale}
                            windowRef={windowRef}
                            overallPage={overallPage}
                            shouldDraw={
                              !searchParams.get('viewall') ? true : false
                            }
                            selectedPage={
                              searchParams.get('viewall')
                                ? selectedPageData.length
                                  ? selectedPageData
                                  : null
                                : null
                            }
                            setCoOrdinates={
                              !searchParams.get('viewall') ? coOrdinates : null
                            }
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Typography>
      <Dialog className="c-modal-box" onClose={handleClose} open={open}>
        <Typography component={'div'} className="d-flex-a d-flex-sb mb-2">
          <Typography className="f-18">
            {/* {JSON.stringify(coOrdinates)} */}
            Add {coOrdinates.type === 'issue' ? 'Issue' : 'Sub-Issue'}
          </Typography>
          <CloseOutlinedIcon onClick={handleClose} />
        </Typography>
        <form id="issues-add-form" onSubmit={handleSubmit(onSubmitForm)}>
          <Typography component={'div'} className="custom-field u-pr-1 mb-1">
            <Typography component={'div'} className="custom-label">
              {coOrdinates.type === 'issue' ? 'Issue' : 'Sub-Issue'} Title{' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>

            <Controller
              control={control}
              defaultValue=""
              name="issueTitle"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <CustomInput
                    placeHolder={''}
                    name={name}
                    value={value}
                    className={'custom-input'}
                    error={errors.issueTitle?.message}
                    onChange={(e) => onChange(e)}
                    tabIndex={1}
                  />
                );
              }}
            />
          </Typography>
          <Typography
            component={'div'}
            className="custom-field u-pr-1 mb-1 mt-2">
            <Typography component={'div'} className="custom-label">
              {coOrdinates.type === 'issue' ? 'Issue' : 'Sub-Issue'} Text{' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>

            <Controller
              control={control}
              defaultValue=""
              name="issueText"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <CustomInput
                    placeHolder={''}
                    name={name}
                    value={value}
                    className={'custom-textarea'}
                    error={errors.issueText?.message}
                    onChange={(e) => onChange(e)}
                    isTextArea={true}
                    tabIndex={2}
                    minRows={5}
                  />
                );
              }}
            />
          </Typography>
        </form>
        <Typography component={'div'} className="d-flex-jae mt-2">
          {addDisable ? (
            <LoadingButton
              className="n-add-btn"
              endIcon={<SendIcon />}
              loading={addDisable}
              loadingPosition="end"
              variant="contained">
              <span>Accept</span>
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              form={'issues-add-form'}
              className="n-add-btn">
              Add
            </Button>
          )}
        </Typography>
      </Dialog>
      <Typography component={'div'} className="rfe-footer">
        <Footer />
      </Typography>
    </Typography>
  );
});
