export type themeObj = {
  common: {
    black: string;
    white: string;
  };
  primary: {
    main: string;
    dark: string;
    light: string;
    contrastText: string;
  };
  secondary: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  success: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  info: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  warning: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  error: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  text: {
    primary: string;
    secondary: string;
    disabled: string;
    hint: string;
  };
  background: {
    default: string;
    paper: string;
  };
  icon: string;
  divider: string;
  border: string;
};

export enum UserRoleType {
  Affilate = 'affilate',
  Attorney = 'attorney',
  Beneficiary = 'beneficiary',
  Admin = 'admin',
  Petitioner = 'petitioner',
}

export type LoginSubmitForm = {
  email: string;
  password: string;
};

export type RegisterSubmitForm = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

export type ForgotSubmitForm = {
  email: string;
};

export type CreateSubmitForm = {
  password: string;
  confirmPassword: string;
};

export type RolesModuleList = {
  display_name: string;
  module_id: number;
  module_name: string;
  status: string;
};
export type ModuleList = {
  id: number;
  module_id: number;
  role_name: string;
  screen_id: string;
  added_by: number;
  tab_id: number[];
};
export type CustomeRole = {
  moduleId: number;
  moduleList: Array<ModuleList>;
  moduleName: string;
};
export type SystemRole = {
  moduleId: number;
  moduleList: Array<ModuleList>;
  moduleName: string;
};
export type UserInfo = {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  gender: string;
  dob: string;
  address: string;
  street_number_and_name: string;
  unit_type: string;
  unit_number: string;
  zip_code: string;
  city: string;
  state: string;
  country: string;
  user_phone: string;
  contact_type: string;
  primary_contact: boolean;
  designation: string;
  country_source_code: string;
  country_code_mobile: string;
  contact_mobile: string;
  conatct_status: string;
  role_id: number;
  role_assigned: string;
  signature_key: string;
  petition_created: boolean;
  attorney_restrict: boolean;
  image: number;
  modified_at: string;
  created_at: string;
  email_id: string;
  userRoleName: string;
  companyId: number;
};
export type TabList = {
  allow: boolean;
  order: number;
  screen_id: number;
  status: boolean;
  tab_id: number;
  tab_name: string;
};
export type ScreenList = {
  allow: boolean;
  module_id: number;
  screen_id: number;
  screen_name: string;
  status: string;
  tabList: Array<TabList>;
};
export type RolesScreen = {
  moduleName: string;
  screenList: Array<ScreenList>;
};
export type RolesBodyObj = {
  roleId: number;
  roleName: string;
  screenIds: string;
  tabId: number[];
};
export type AddUserSubmitForm = {
  firstName: string;
  lastName: string;
  gender: string;
  logo: any;
  dateOfJoining: string;
  dateOfBirth: string;
  address: string;
  zipCode: string;
  city: any;
  state: any;
  country: any;
  unitType: string;
  unitNumber: string;
  phoneNumber: string;
  mobileNumber: string;
  contactEmail: string;
  roleAssignedId: any[];
  status: boolean;
  roleRestrict: boolean;
  title: string;
};
export type RegistrationFormType = {
  firstName: string;
  lastName: string;
  gender: string;
  dob: any;
  mobileNumber: string;
  email: string;
};
export type AddDependentform = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  realtionship: string;
};
export type AddVersionForm = {
  versionCode: string;
};
export type LoginAsForm = {
  role: string;
  email: string;
  lawFirm: string;
  organization: string;
};
export type PetitionersAddUserSubmitForm = {
  organizationName: string;
  organizationLogo: any;
  organizationAddress: string;
  organizationZipCode: string;
  organizationCity: any;
  organizationState: any;
  organizationCountry: any;
  organizationPhone: string;
  organizationFax: string;
  organizationEmail: string;
  organizationWebsite: string;
  streetNumberAndName: string;
  unitType: string;
  unitNumber: string;
  validTill: string;
  organizationStatus: string;
  contactType: string;
  firstName: string;
  primaryContact: boolean;
  lastName: string;
  middleName: string;
  gender: string;
  designation: string;
  phoneNumber: string;
  mobileNumber: string;
  contactEmail: string;
  status: string;
  typeOfBusiness: string;
  yearEstablished: string;
  totalNumberOfEmployees: string;
  grossAnnualIncome: string;
  netAnnualIncome: string;
  organizationFEINNumber: string;
  organizationSignatoryFirstName: string;
  organizationSignatoryLastName: string;
  organizationSignatoryEmail: string;
  organizationSignatoryTitle: string;
};
export type LawfirmAddUserSubmitForm = {
  attorneyName: string;
  attorneyLogo: string;
  attorneyAddress: string;
  attorneyZipCode: string;
  attorneyCity: any;
  attorneyState: any;
  attorneyCountry: any;
  attorneyPhone: string;
  attorneyFax: string;
  attorneyEmail: string;
  attorneyWebsite: string;
  validTill: string;
  streetNumberAndName: string;
  unitType: string;
  unitNumber: string;
  attorneyStatus: string;
  contactType: string;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: string;
  designation: string;
  phoneNumber: string;
  mobileNumber: string;
  contactEmail: string;
  primaryContact: boolean;
  roleId: string;
};
export type MyProfiles = {
  file: null;
  firstName: string;
  lastName: string;
  gender: string;
  dob: string;
  address: string;
  zipCode: string;
  city: any;
  state: any;
  country: any;
  phone: string;
  mobileNumber: string;
  streetNumberAndName: string;
  unitType: string;
  unitNumber: string;
  mobileCountryCode: string;
  mobileSocCode: string;
};

export type ConfigureQuestions = {
  question: string;
  pashtoQuestion: string;
  dariQuestion: string;
  responseType: string;
  response: any;
  responseBy: any[];
  status: boolean;
  notes: string;
  hint: string;
  example: string;
  issuesLanguage: string;
  issuesNumber: string;
  winningStrategyNote: string;
  pashtoNotes: string;
  dariNotes: string;
  answerUserField: string;
  linkCode: string;
  webUrlLink: string;
  winningStrategyLink: string;
  defaultAnswer: string;
};

export type ResponseTypeQuestions = {
  response: any;
};

export type ConfigureSections = {
  majorSection: boolean;
  sectionDescription: string;
  sectionStatus: boolean;
  sectionTitle: string;
  sectionType: string;
  templateId: string;
};
export type VisaTypeSection = {
  visaCode: string;
  visaDescription: string;
  country: string;
  status: string;
  beneficiaryDescription: string;
  petitionerDescription: string;
};

export type MyFirmtype = {
  attorneyAddress: string;
  paymentType: string;
  attorneyCaseManager: string;
  attorneyCity: string;
  attorneyContactPerson: string;
  attorneyContactSupport: any;
  attorneyCountry: string;
  attorneyEmail: string;
  attorneyFEIN: string;
  attorneyFax: string;
  attorneyName: string;
  attorneyPhone: string;
  attorneyState: string;
  attorneyStatebarNumber: string;
  attorneyStatus: string;
  attorneyUSCISElisAccountNumber: string;
  attorneyWebsite: string;
  attorneyZipCode: string;
  attorneylicensingAuthority: string;
  ccMailFirm: string;
  streetNumberAndName: string;
  unitNumber: string;
  unitType: string;
  validTill: string;
};
export type PaymentGatewayTypes = {
  contract_payment_enable: boolean;
  paymentType: string;
  paymentSecretId: string;
  heartlandSecretId?: string;
  cardServiceCharge?: string;
  achServiceCharge?: string;
};
export type VisaTypeMajor = {
  major: boolean;
};
export type AddnotesTypes = {
  contactType: string;
  sendMail: boolean;
  visibleTo?: any[];
  note?: string;
  teamMembers: string[];
  contactVisible: any;
};
export type DashboardFilterTypes = {
  attorneyId: string;
  fromDate: string;
  period: string;
  toDate: string;
};
export type MyOrganizationtype = {
  organizationLogo: any;
  organizationName: string;
  organizationAddress: string;
  organizationCity: any;
  organizationState: any;
  organizationCountry: any;
  organizationPhone: string;
  organizationFax: string;
  organizationEmail: string;
  organizationWebsite: string;
  organizationZipCode: string;
  validTill: string;
  organizationStatus: string;
  streetNumberAndName: string;
  unitType: string;
  unitNumber: string;
  typeOfBusiness: string;
  yearEstablished: string;
  totalNumberOfEmployees: string;
  grossAnnualIncome: string;
  netAnnualIncome: string;
  organizationSignatoryFirstName: string;
  organizationSignatoryLastName: string;
  organizationSignatoryEmail: string;
  organizationSignatoryTitle: string;
  organizationFEINNumber: string;
  isSignatoryUpdated: boolean;
  oldSignatoryId: string;
  newSignatoryId: string;
};
export type ContractFeeTypes = {
  name: string;
  status: boolean | string;
  value: string;
  visaId: any[];
};
export type ManageOrganizationtypes = {
  FirmName: string;
  streetNumberAndName: string;
  associated: boolean;
  unitType: string;
  unitNumber: string;
  organizationZipCode: string;
  organizationCity: any;
  organizationState: any;
  organizationCountry: any;
  organizationPhone: string;
  organizationFax: string;
  organizationEmail: string;
  organizationWebsite: string;
  typeOfBusiness: string;
  yearEstablished: string;
  totalNumberOfEmployees: string;
  grossAnnualIncome: string;
  netAnnualIncome: string;
  organizationFEINNumber: string;
  organizationSignatoryFirstName: string;
  organizationSignatoryLastName: string;
  organizationSignatoryEmail: string;
  organizationSignatoryTitle: string;
  organizationStatus: string;
  organizationName: string;
  isSignatoryUpdated: boolean;
  oldSignatoryId: string;
  newSignatoryId: string;
};
export type ManageOrganizationcustomtypes = {
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  email?: string;
  title?: string;
};

export type ACHFormTypes = {
  billingStreetAddress: string;
  billingCity: string;
  billingState: string;
  billingCountry: string;
  billingZip: string;
  checkHolderName: string;
  accountNumber: string;
  routingNumber: string;
  accountType: string;
  checkType: string;
  recaptchaReactive: string;
};

export type AdminDocumentTypes = {
  visaId: any[];
  documentType: string;
  validDocuments: string;
  description: string;
  defaultForPetitions: number[];
  documentParsing: string;
  parsingType: string;
  pashtoDocumentType: string;
  dariDocumentType: string;
};

export type AdminDocumentTemplateForm = {
  templateType: string;
  templateName: string;
  contractType: string;
  htmlContent: string;
  visaSelected: any[];
  imageData: any;
  templateAddedFor: string;
  templatFile: string;
  isTemplatFile: boolean;
};

export type ResourceStationForm = {
  documentName: string;
  documentDescription: string;
  visaId: any[];
  visibleTo: any[];
  file: string;
};

export type PassportTypes = {
  firstName: { confidence: string; value: string };
  middleName: { confidence: string; value: string };
  lastName: { confidence: string; value: string };
  gender: { confidence: string; value: string };
  dateOfBirth: { confidence: string; value: string };
  nationality: { confidence: string; value: string };
  countryName: { confidence: string; value: string };
  passportNumber: { confidence: string; value: string };
  expiryDate: { confidence: string; value: string };
};

export type RequestType = {
  documentName: string;
  responsibility: any[];
};

export type AddPetitionStatus = {
  date: string;
  status: string;
  titleOfDocument: string;
  sendEmail: boolean;
  additionalTitle: string;
  teamMembers: string[];
  notify: boolean;
  notifyPerson: string[];
  notifyDate: string;
  notifyNotes: string;
};

export type AddReceiptNotice = {
  sendEmail: boolean;
  teamMembers: string[];
  type: string;
  notes: string;
};

export type AddRfeRespondStep1 = {
  file: string;
  notes: string;
};

export type PassportTypesSchema = {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  nationality: string;
  countryName: string;
  passportNumber: string;
  expiryDate: string;
};
