import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomCheckBox from 'components/CustomCheckBox/customCheckBox';
import CustomModalView from 'components/CustomModalView/customModalView';
// import Pagination from 'components/Pagination';
// import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { addNewData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions, isJSON, jsonParseValue } from 'utils/helper';

export const CopyAction: React.FC<any> = ({
  isCopy,
  setIsCopy,
  getQuestionWizardFormData,
  value,
  setLoader,
  // params,
  // setParams,
  hasMoreResults: HasMoreResults,
  getMoreLoader,
  getMoreListCopy,
}) => {
  const { petitionId } = useParams();
  const [isopen1, setIsOpen1] = useState(false);
  const [isopen2, setIsOpen2] = useState(false);
  const [question, setQuestion] = useState<any>([]);
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const [checkboxlist, setCheckboxlist] = useState<any>([]);

  const modelClose1 = () => {
    setIsOpen1(false);
    setQuestion([]);
  };
  const modelClose2 = () => {
    setIsOpen2(false);
  };

  const getPetitionData = (row: any, value: string) => {
    // const beneficiaryformId = petitions.petitionData.beneficiary_form_id;
    // const petitionerformId = petitions.petitionData.petitioner_form_id;
    setQuestion([]);
    setLoader && setLoader(true);
    getAllListData(
      `${String(COMMON_ENDPOINT.CopyBeneficiary_PetitionerQuestion)}/${String(
        petitionId,
      )}/${String(row.petitionid)}?type=${
        value === '1' ? 'beneficiary' : 'petitioner'
      }`,
    )
      .then((Resp: any) => {
        setLoader && setLoader(false);
        setQuestion(
          Resp && Array.isArray(Resp) && Resp.length
            ? Resp.filter((e) => showAnswer(e) !== '-')
            : [],
        );
        setCheckboxlist(
          Resp && Array.isArray(Resp) && Resp.length
            ? Resp.filter((e) => showAnswer(e) !== '-')
            : [],
        );
      })
      .catch(() => {
        setLoader && setLoader(false);
      });
  };
  const Confirmsubmit = () => {
    const benificiaryId = petitions.petitionData.beneficiary_id;
    const formId = petitions.petitionData.beneficiary_form_id;
    const Answerdata = [];
    checkboxlist.map((item) => {
      Answerdata.push({
        answer: item.answer,
        questionId: item.question_id,
        sectionId: item.section_id,
      });
    });
    const Data = {
      benificiaryId: benificiaryId,
      formId: formId,
      formType: 'configureForms',
      list: Answerdata,
      petitionId: petitionId,
    };
    setLoader && setLoader(true);
    addNewData(Data, COMMON_ENDPOINT.AnswerCopy)
      .then((Resp: any) => {
        setLoader && setLoader(false);
        modelClose2();
        setIsCopy((pre) => ({
          ...pre,
          data: [],
          show: false,
        }));
        getQuestionWizardFormData(value);
      })
      .catch(() => {
        setLoader && setLoader(false);
      });
  };
  const Toast = (e) => {
    if (checkboxlist.length > 0) {
      setIsOpen2(true);
      setIsOpen1(false);
    } else {
      toast.error('Please select a question', alertOptions);
    }
  };
  const showAnswer = (question, key = 'answer') => {
    try {
      if (isJSON(question[key])) {
        return jsonParseValue(question[key]);
      } else {
        return question[key] &&
          String(question[key]) &&
          String(question[key]).trim()
          ? question[key]
          : '-';
      }
    } catch (error) {
      return '-';
    }
  };
  return (
    <Grid item md={2} sm={12}>
      <CustomModalView
        open={isCopy.show}
        close={() => {
          setIsCopy((pre) => ({
            ...pre,
            data: [],
            show: false,
          }));
          setQuestion([]);
        }}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'lg'}
        title={value === '1' ? 'Beneficiary List' : 'Petition List'}>
        <DialogContent id="scrollParent">
          <InfiniteScroll
            dataLength={
              isCopy && isCopy.data && isCopy.data.count ? isCopy.data.count : 0
            }
            style={{
              overflow: 'unset',
            }}
            next={getMoreListCopy && getMoreListCopy}
            hasMore={HasMoreResults}
            scrollableTarget={'scrollParent'}
            loader={
              getMoreLoader && (
                <div
                  style={{
                    backgroundColor: '#ffffff',
                    textAlign: 'center',
                    width: '100%',
                  }}>
                  <CircularProgress
                    size={30}
                    sx={{ color: '#002c69', marginBottom: 10, marginTop: 10 }}
                  />
                </div>
              )
            }
            endMessage>
            <table className="lead-table table-hover">
              <thead>
                <tr>
                  <th>BENEFICIARY NAME</th>
                  <th>PETITIONER NAME</th>
                  <th>PETITION DATE</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                {isCopy.data && isCopy.data.length ? (
                  isCopy.data.map((row: any, index) => (
                    <tr
                      key={index}
                      onClick={(e) => {
                        setIsOpen1(true);
                        getPetitionData(row, value);
                      }}
                      style={{ cursor: 'pointer' }}>
                      <td>
                        <a style={{ color: '#1093df' }}>
                          {row.benificiary_name}
                        </a>
                      </td>
                      <td>{row.prtitioner_name}</td>
                      <td>{moment(row.created_at).format('MM/DD/YYYY')}</td>
                      <td>{row.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr className={'text-center'}>
                    <td colSpan={5} align={'center'}>
                      No Record(s) Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </DialogContent>
        {/* <DialogActions>
          <Pagination
            total={
              !_.isEmpty(isCopy?.count)
                ? Number(isCopy?.count)
                : !_.isEmpty(isCopy?.data)
                ? Number(isCopy?.data?.length)
                : 0
            }
            skip={params.skip}
            take={params.take}
            setParams={setParams}
          />
        </DialogActions> */}
      </CustomModalView>

      <CustomModalView
        open={isopen1}
        close={modelClose1}
        fullWidth={true}
        title={
          value === '1' ? 'Beneficiary Question List' : 'Petition Question List'
        }
        maxWidth={'xl'}
        scroll={'paper'}
        autoCloseDisable={true}>
        <DialogContent>
          <table className="lead-table table-hover" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>SECTION NAME</th>
                <th>QUESTION</th>
                <th>ANSWER</th>
                <th style={{ color: '#174baf' }}>CURRENT ANSWER</th>
                <th
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                  ACTION{''}
                  <CustomCheckBox
                    checked={question.length === checkboxlist.length}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setCheckboxlist(question);
                      } else {
                        setCheckboxlist([]);
                      }
                    }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {question.length > 0 ? (
                question.map((row: any, index) => {
                  const Findex = checkboxlist.findIndex(
                    (item) => item.id === row.id,
                  );
                  return (
                    <tr key={index}>
                      <td>{row.section_name}</td>
                      <td>{row.question}</td>
                      <td
                        style={
                          showAnswer(row, 'currentAnswer') !== '-'
                            ? {
                                // backgroundColor: '#e55d5d94'
                                color: 'red',
                              }
                            : {}
                        }>
                        {showAnswer(row)}
                      </td>
                      <td
                        style={
                          showAnswer(row, 'currentAnswer') !== '-'
                            ? {
                                // backgroundColor: '#e55d5d94'
                                color: '#174baf',
                              }
                            : {}
                        }>
                        {showAnswer(row, 'currentAnswer')}
                      </td>
                      <td>
                        {
                          <CustomCheckBox
                            checked={Findex === -1 ? false : true}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setCheckboxlist([...checkboxlist, row]);
                              } else {
                                setCheckboxlist([
                                  ...checkboxlist.filter(
                                    (item) => item.id !== row.id,
                                  ),
                                ]);
                              }
                            }}
                          />
                        }
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className={'text-center'}>
                  <td colSpan={5} align={'center'}>
                    No Record(s) Found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
        {question.length > 0 && (
          <DialogActions>
            <Grid item md={12} sm={12} className="d-flex-jae mt-1 ">
              <Typography component={'div'} className="custom-field u-pr-1">
                <Button
                  className="n-add-btn f-18 font-bold"
                  onClick={(e) => {
                    Toast(e);
                  }}>
                  {value === '1' ? 'Copy Beneficiary' : 'Copy Petition'}
                </Button>
              </Typography>
            </Grid>
          </DialogActions>
        )}
      </CustomModalView>

      <ConfirmDialog
        open={isopen2}
        title={
          value === '1'
            ? 'Copy Beneficiary Confirmation'
            : 'Copy Petition Confirmation'
        }
        floatingBtnShow={true}
        onConfirm={(e) => {
          if (e === 'no') {
            modelClose2();
          } else {
            Confirmsubmit();
          }
        }}
        autoCloseDisable={true}>
        {/* Copying the information will overwrite all the {value === '1'  ? 'Beneficiary' : 'Petitioner'} details in
        the current petition with details from the petition you have selected.
        Do you wish to proceed? */}
        Copying the information will overwrite the information already provided
        in the current petition with details from the petition you have
        selected. Do you wish to proceed?
      </ConfirmDialog>
    </Grid>
  );
};
export default CopyAction;
