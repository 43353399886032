import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { ResourceStationForm } from 'types';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { ResourceStationFormSchema } from 'utils/ValidatorSchema';

import UploadIcon from '../../../../../assets/images/clupload.svg';
import DeleteIcon from '../../../../../assets/images/udelete.svg';

export const ResourceStationAdd: React.FC<any> = () => {
  const location: any = useLocation();
  const navigate: any = useNavigate();
  const { editId } = useParams();
  const [loader, setLoader] = useState(true);
  const [visaOptions, setVisaOptions] = useState<any>([]);
  const [existingVisaMap, setExistingVisaMap] = useState<any>([]);
  const [existingVisa, setExistingVisa] = useState<any>([]);
  const [fileProps, setFileProps] = useState<any>({
    file: '',
    fileEdit: false,
    fileName: '',
    selectedFile: null,
  });
  const [statusToggle, setStatusToggle] = useState(location?.state?.status);
  const onToggleStatus = () => setStatusToggle((b) => !b);
  // const userDetail: any = useAppSelector(
  //   (store: any) => store.userLogin.userInfo,
  // );
  const fileInput = useRef(null);
  const inActiveStatusInput = useRef(null);
  const activeStatusInput = useRef(null);
  const [roles, setRoles] = useState<any>([]);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ResourceStationForm>({
    criteriaMode: 'all',
    defaultValues: {
      documentDescription: '',
      documentName: '',
      file: '',
      visaId: null,
      visibleTo: null,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ResourceStationFormSchema),
  });

  const cancelForm = () => {
    resetForm();
    navigate(-1);
  };

  const onSubmitForm = (data: any) => {
    setLoader(true);
    if (_.isEmpty(watch('visaId'))) {
      setError('visaId', {
        message: 'Visa Type is required',
        type: 'custom',
      });
      return;
    } else if (_.isEmpty(fileProps?.fileName)) {
      setError('file', {
        message: 'File is required',
        type: 'custom',
      });
      setLoader(false);
      return;
    }
    if (!_.isEmpty(editId)) {
      const removeVisa = existingVisa.filter((e) => {
        return !data['visaId'].includes(Number(e));
      });
      const addVisa = data['visaId'].filter((e) => {
        return !existingVisa.includes(Number(e));
      });
      const removeVisaIds = [];
      existingVisaMap?.filter((e) => {
        if (removeVisa?.includes(e.visa_id)) {
          removeVisaIds.push(e['id']);
        }
      });

      const dataObj = {
        documentDescription: data['documentDescription'],
        documentName: data['documentName'],
        status: statusToggle,
        visaAdded: addVisa,
        visaRemoved: removeVisaIds,
        visibleTo: data['visibleTo'],
      };
      updateData(editId, dataObj, SETUP_ENDPOINT.ResourceStation)
        .then((resp: any) => {
          cancelForm();
          toast.success('Resource Station Updated Successfully', alertOptions);
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
        });
    } else {
      const formData = new FormData();
      formData.append('documentName', data['documentName']);
      formData.append('documentDescription', data['documentDescription']);
      formData.append('visaId', data['visaId']);
      formData.append('file', fileProps.selectedFile);
      formData.append('visibleTo', data['visibleTo']);
      addNewData(formData, SETUP_ENDPOINT.ResourceStation)
        .then((resp: any) => {
          cancelForm();
          toast.success('Resource Station Added Successfully', alertOptions);
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };
  const updateFile = (files) => {
    setLoader(true);
    if (!files && !fileProps?.file) {
      setError('file', {
        message: 'File is required',
        type: 'custom',
      });
      setLoader(false);
    } else {
      const formData = new FormData();
      formData.append('file', files);
      formData.append('documentKey', fileProps.file);
      updateData(editId, formData, SETUP_ENDPOINT.ResourceStationFile)
        .then((resp: any) => {
          getAllListData(
            `${String(SETUP_ENDPOINT.ResourceStation)}?id=${String(
              editId,
            )}&status=${String(statusToggle)}`,
          ).then((resp) => {
            resp?.data?.map((item) => {
              setValue('file', item?.document_key);
              setFileProps((pre) => ({
                ...pre,
                file: item?.document_key,
                fileEdit: true,
                fileName:
                  item?.document_key.split('/')[
                    item?.document_key.split('/').length - 1
                  ],
              }));
            });
          });
          toast.success('Document Updated Successfully', alertOptions);
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };

  const getInitialStatus = useCallback(async () => {
    setLoader(true);
    await getAllListData(
      `${String(SETUP_ENDPOINT.ResourceStation)}?id=${String(
        editId,
      )}&status=${String(statusToggle)}`,
    )
      .then((resp) => {
        const {
          document_name,
          document_description,
          visaMapList,
          document_key,
          visible_to,
        } = resp.data[0];
        setValue('documentName', document_name);
        setValue('documentDescription', document_description);
        const visaIds = visaMapList?.map((e) => e.visa_id);
        setExistingVisa(visaIds);
        setExistingVisaMap(visaMapList);
        setValue('visaId', visaIds);
        setValue('file', document_key);
        setValue('visibleTo', visible_to);
        setFileProps((pre) => ({
          ...pre,
          file: document_key,
          fileEdit: true,
          fileName: document_key.split('/')[document_key.split('/').length - 1],
        }));

        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`)
      .then((resp: any) => {
        const options = [];
        resp.data.map((visa) =>
          options.push({ label: visa.visa_code, value: visa.visa_id }),
        );
        setVisaOptions(options);
        getAllListData(COMMON_ENDPOINT.AdminRoles)
          .then((rolResp: any) => {
            const options = [];
            setRoles(
              rolResp
                .filter((role) => role.module_id !== 5 && role.module_id !== 1)
                .map((e) =>
                  options.push({ label: e.module_name, value: e.module_id }),
                ),
            );
            setRoles(options);
          })
          .catch((e: any) => {
            setLoader(false);
          });
        if (!_.isUndefined(editId)) {
          getInitialStatus();
        } else {
          setValue('visibleTo', [3, 4, 2]);
          setLoader(false);
        }
      })
      .catch((e: any) => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <Grid item md={11} sm={12}>
          <Typography
            component={'div'}
            className="d-flex-a d-flex-sb sticky-tab mb-2">
            <Typography
              component={'div'}
              className="w-title black-white-text"
              sx={{ margin: 'unset !important' }}>
              {!_.isUndefined(editId) ? 'Edit' : 'Add'} Resource Station
            </Typography>

            <Typography component={'div'} className="d-flex-a">
              <Button className="n-cancel-btn mr-2" onClick={cancelForm}>
                <Typography className="o-box" />
                Cancel
              </Button>
              <Button
                className="n-add-btn"
                type="submit"
                form={'add-doc-template'}>
                {_.isUndefined(editId) ? 'Save' : 'Update'}
              </Button>
            </Typography>
          </Typography>
          <form id="add-doc-template" onSubmit={handleSubmit(onSubmitForm)}>
            <Typography
              component={'div'}
              className="addbox"
              sx={{ marginTop: 'unset !important' }}>
              <Box
                component="form"
                className="u-add-in"
                noValidate
                autoComplete="off">
                <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Visa Type <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={null}
                        name="visaId"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              autoFocus={true}
                              closeMenuOnSelect={false}
                              labelKey={'label'}
                              valueKey={'value'}
                              options={visaOptions}
                              isMulti={true}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              placeHolder={'Select'}
                              className={'basic-multi-select'}
                              error={errors.visaId?.message}
                              tabIndex={1}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Description
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="documentDescription"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder={'Description'}
                              name={name}
                              value={value}
                              className={'custom-textarea'}
                              error={errors.documentDescription?.message}
                              onChange={onChange}
                              isTextArea={true}
                              minRows={3}
                              tabIndex={3}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Visible To <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        name="visibleTo"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              closeMenuOnSelect={false}
                              labelKey={'label'}
                              valueKey={'value'}
                              options={roles}
                              isMulti={true}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              placeHolder={'Select'}
                              className={'basic-multi-select'}
                              error={errors.visibleTo?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                    {!_.isEmpty(editId) ? (
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Status <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <div className="switch-field" tabIndex={3}>
                          <input
                            type="radio"
                            id="switch_left"
                            name="switchToggle"
                            value={'inactive'}
                            onChange={onToggleStatus}
                            checked={!statusToggle}
                            ref={inActiveStatusInput}
                          />
                          <label
                            htmlFor="switch_left d-flex-ja"
                            onClick={() => inActiveStatusInput.current.click()}>
                            Inactive
                          </label>

                          <input
                            type="radio"
                            id="switch_right"
                            name="switchToggle"
                            value={'active'}
                            onChange={onToggleStatus}
                            checked={statusToggle}
                            ref={activeStatusInput}
                          />
                          <label
                            htmlFor="switch_right d-flex-ja"
                            onClick={() => activeStatusInput.current.click()}>
                            Active
                          </label>
                        </div>
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Document Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="documentName"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder={'Document Name'}
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.documentName?.message}
                              onChange={onChange}
                              tabIndex={2}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Document <span style={{ color: 'red' }}>*</span>{' '}
                        <span
                          style={{
                            fontSize: '.75em !important',
                            fontStyle: 'italic',
                          }}>
                          (You can upload upto a maximum of 90MB per upload)
                        </span>
                      </Typography>
                      <Typography
                        component={'div'}
                        className="d-flex-a"
                        style={{ cursor: 'pointer' }}>
                        <Typography
                          component={'div'}
                          className="upload-drag-drop d-flex-ja pointer">
                          <Dropzone
                            onDrop={(files) => {
                              if (files[0].size / 1024 / 1024 < 90) {
                                setValue('file', files[0]?.name);
                                setFileProps((pre) => ({
                                  ...pre,
                                  fileName: files[0]?.name,
                                  selectedFile: files[0],
                                }));
                                !_.isEmpty(editId) && updateFile(files[0]);
                              } else {
                                fileInput.current.value = '';
                                toast.error(
                                  'File is too big, Please select a file less than 90 MB',
                                  alertOptions,
                                );
                                return;
                              }
                            }}>
                            {({ getRootProps }) => {
                              return (
                                <>
                                  <img src={UploadIcon} alt="icon" />
                                  <Typography
                                    {...getRootProps({
                                      className: 'dropzone',
                                      onClick: (event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        fileInput.current?.click();
                                      },
                                    })}
                                  />
                                </>
                              );
                            }}
                          </Dropzone>
                          <Typography className="f-16">
                            Drag and drop file here, or click to select file
                          </Typography>
                        </Typography>

                        <input
                          type="file"
                          hidden
                          ref={fileInput}
                          onChange={(e) => {
                            if (e.target?.files[0].size / 1024 / 1024 < 90) {
                              setValue('file', e.target?.files[0]?.name);
                              setFileProps((pre) => ({
                                ...pre,
                                fileName: e.target?.files[0]?.name,
                                selectedFile: e.target?.files[0],
                              }));
                              !_.isEmpty(editId) &&
                                updateFile(e.target?.files[0]);
                            } else {
                              fileInput.current.value = '';
                              toast.error(
                                'File is too big, Please select a file less than 90 MB',
                                alertOptions,
                              );
                              return;
                            }
                          }}
                          tabIndex={5}
                        />

                        <Typography sx={{ marginTop: '10px', width: '450px' }}>
                          <form className="d-flex-a">
                            <Typography
                              component={'div'}
                              className="custom-field mb-0  d-flex-1"
                              sx={{ marginBottom: 'unset !important' }}>
                              <CustomInput
                                className="custom-input"
                                disabled={true}
                                value={
                                  fileProps?.fileName ? fileProps?.fileName : ''
                                }
                                error={
                                  !fileProps?.fileName
                                    ? errors.file?.message
                                    : ''
                                }
                                inputProps={{
                                  endAdornment: fileProps?.fileName && (
                                    <InputAdornment position="end">
                                      <Tooltip
                                        title={'Remove'}
                                        placement={'top'}>
                                        <img
                                          src={DeleteIcon}
                                          alt="deleteIcon"
                                          style={{
                                            cursor: 'pointer',
                                            width: '28px',
                                          }}
                                          onClick={() => {
                                            fileInput.current.value = '';
                                            setFileProps((pre) => ({
                                              ...pre,
                                              fileName: '',
                                              selectedFile: '',
                                            }));
                                            setError('file', {
                                              message: 'File is required',
                                              type: 'custom',
                                            });
                                          }}
                                        />
                                      </Tooltip>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Typography>
                          </form>
                        </Typography>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Typography>
          </form>
        </Grid>
      )}
    </Grid>
  );
};

export default ResourceStationAdd;
