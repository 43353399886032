import './assemble.css';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Grid, Tooltip, Typography } from '@mui/material';
// import Close from 'assets/images/close.png';
import Cedit from 'assets/images/gedit.png';
import img from 'assets/images/image-icon.png';
import Pdf from 'assets/images/pdf-new.png';
import wrd from 'assets/images/word.png';
import React, { useImperativeHandle, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { updateData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { reorderDnD } from 'utils/helper';

import { Drag, Drop } from './drag-and-drop';

type Props = {
  list: Array<any>;
  subChildName?: string;
  setRomanValue: any;
  setIsShowRomanEditModal: any;
  setIsDeleteAssembles: any;
  deleteChild: any;
  type: string;
  petitionId: string;
  getAssembles?: any;
};
export const NestedListComponent = React.forwardRef((props: Props, ref) => {
  const {
    list,
    subChildName = 'documents_list',
    setIsShowRomanEditModal,
    setRomanValue,
    setIsDeleteAssembles,
    deleteChild,
    type,
    petitionId,
    getAssembles,
  } = props;

  useImperativeHandle(ref, () => ({
    reOrder(resuld: any) {
      handleDragEnd(resuld);
    },
  }));

  const [categories, setCategories] = useState([]);
  // [
  //   {
  //     ids: 'q101',
  //     items: [
  //       { ids: 'abc', original_name: 'First' },
  //       { ids: 'def', original_name: 'Second' },
  //     ],
  //     original_name: 'Category 1',
  //   },
  //   {
  //     ids: 'wkqx',
  //     items: [
  //       { ids: 'ghi', original_name: 'Third' },
  //       { ids: 'jkl', original_name: 'Fourth' },
  //     ],
  //     original_name: 'Category 2',
  //   },
  // ],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDragEnd = (result) => {
    const { type, source, destination } = result;
    if (!destination) return;

    const sourceCategoryId = source.droppableId;
    const destinationCategoryId = destination.droppableId;

    // Reordering items (Child)
    if (
      type === 'droppable-item-attachment' ||
      type === 'droppable-item-exhibit'
    ) {
      // If drag and dropping within the same category
      if (sourceCategoryId === destinationCategoryId) {
        const updatedOrder = reorderDnD(
          categories.find((category) => category.ids === sourceCategoryId)
            .documents_list,
          source.index,
          destination.index,
        );

        const updatedCategories = categories.map((category) =>
          category.ids !== sourceCategoryId
            ? category
            : { ...category, documents_list: updatedOrder },
        );

        const list = [];
        updatedOrder.map((child: any, index: number) => {
          list.push({
            id: Number(child.id),
            order: String(child.order).split('.')[0] + '.' + String(index + 1),
          });
        });

        const payload = {
          list: list,
        };

        updateData(
          petitionId,
          payload,
          `${String(COMMON_ENDPOINT.AssemblePetition)}/document-order`,
        )
          .then((data) => {
            getAssembles();
          })
          .catch((err) => {
            console.log(err);
          });
        setCategories(updatedCategories);
      } else {
        // const sourceOrder: any = categories.find(
        //   (category) => category.ids === sourceCategoryId,
        // ).documents_list;
        // const destinationOrder: any = categories.find(
        //   (category) => category.ids === destinationCategoryId,
        // ).documents_list;
        // const [removed] = sourceOrder.splice(source.index, 1);
        // destinationOrder.splice(destination.index, 0, removed);
        // destinationOrder[removed] = sourceOrder[removed];
        // delete sourceOrder[removed];
        // const updatedCategories = categories.map((category) =>
        //   category.ids === sourceCategoryId
        //     ? { ...category, documents_list: sourceOrder }
        //     : category.ids === destinationCategoryId
        //     ? { ...category, documents_list: destinationOrder }
        //     : category,
        // );
        // setCategories(updatedCategories);
      }
    }

    // Reordering categories (parent)
    if (
      type === 'droppable-category-attachment' ||
      type === 'droppable-category-exhibit'
    ) {
      const updatedCategories = reorderDnD(
        categories,
        source.index,
        destination.index,
      );

      const list = [];
      updatedCategories.map((parent: any, index: number) => {
        list.push({
          id: Number(parent.id),
          order: String(index + 1),
        });
      });

      const payload = {
        list: list,
      };

      updateData(
        petitionId,
        payload,
        `${String(COMMON_ENDPOINT.AssemblePetition)}/document-order`,
      )
        .then((data) => {
          getAssembles();
        })
        .catch((err) => {
          console.log(err);
        });

      setCategories(updatedCategories);
    }
  };

  React.useEffect(() => {
    setCategories(
      list && list.length
        ? list.map((el) => ({
            ...el,
            documents_list: el[subChildName]
              ? el[subChildName].map((c) => ({
                  ...c,
                  ids: (Math.random() + 1).toString(36).substring(7),
                }))
              : [],
            ids: (Math.random() + 1).toString(36).substring(7),
          }))
        : [],
    );
  }, [list, subChildName]);

  return (
    <Drop id={`droppable-${type}`} type={`droppable-category-${type}`}>
      {categories.map((category, categoryIndex) => {
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            <Drag
              className="draggable-category individual-list"
              key={category.ids}
              id={category.ids}
              index={categoryIndex}
              innerStyle={{ marginLeft: '8px', marginTop: '9px' }}
              dragableHide={type == 'coverPage'}>
              <div className="category-container">
                <div className="item  parent-head">
                  <Grid container>
                    <Grid
                      md={category.document_type === 'header' ? 10 : 10}
                      className="d-flex-b">
                      {category.document_type !== 'header' ? (
                        <img
                          className={category.roman_name ? 'pr-1' : ''}
                          src={
                            category?.key?.split('.').pop() === 'pdf'
                              ? Pdf
                              : ['png', 'jpg', 'jpeg', 'img'].includes(
                                  category?.key?.split('.').pop(),
                                )
                              ? img
                              : wrd
                          }
                          alt={
                            category?.key?.split('.').pop() === 'pdf'
                              ? 'pdf'
                              : ['png', 'jpg', 'jpeg', 'img'].includes(
                                  category?.key?.split('.').pop(),
                                )
                              ? 'img'
                              : 'word'
                          }
                        />
                      ) : (
                        ''
                      )}

                      <div className="wb parent-name-bold f-14">
                        {category.roman_name}{' '}
                        {category?.original_name?.length > 18 ? (
                          <Tooltip
                            title={category.original_name}
                            placement="top-start">
                            <Typography
                              component={'span'}
                              className="text-wrap ">
                              {category.original_name}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component={'span'} className="text-wrap ">
                            {category.original_name}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid md={2}>
                      <div className="d-flex-jae">
                        {type !== 'coverPage' && (
                          <img
                            src={Cedit}
                            alt="im"
                            className=" pointer mr-1"
                            onClick={() => {
                              setRomanValue(
                                'documentRoman',
                                category.roman_name
                                  ? category.roman_name.substring(
                                      1,
                                      category.roman_name.length - 1,
                                    )
                                  : '',
                              );
                              setRomanValue(
                                'documentName',
                                category.original_name
                                  ? category.original_name
                                  : '',
                              );
                              setIsShowRomanEditModal((pre) => ({
                                ...pre,
                                assemblePetitionId:
                                  category.assemble_petition_id,
                                documentRoman: category.roman_name
                                  ? category.roman_name.substring(
                                      1,
                                      category.roman_name.length - 1,
                                    )
                                  : '',
                                documentType: '',
                                id: category.id,
                                show: true,
                                type: 'parent',
                              }));
                            }}
                          />
                        )}
                        {/* <img
                          src={Close}
                          alt="im"
                          className="imgs pointer"
                          onClick={() =>
                            setIsDeleteAssembles({
                              id: category.id,
                              show: true,
                              type: type,
                            })
                          }
                        /> */}
                        <HighlightOffIcon
                          sx={{ fill: '#999999' }}
                          className="imgs pointer"
                          onClick={() =>
                            setIsDeleteAssembles({
                              id: category.id,
                              show: true,
                              type: type,
                            })
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>

                {category.document_type === 'header' ? (
                  <>
                    <Drop
                      key={category.ids}
                      id={`${String(category.ids)}`}
                      type={`droppable-item-${type}`}>
                      <div className="inner-child-place">
                        {category.documents_list &&
                        category.documents_list.length ? (
                          category.documents_list.map((item, index) => {
                            return (
                              <Drag
                                className="draggable"
                                key={item.ids}
                                id={item.ids}
                                index={index}
                                innerStyle={{}}>
                                <div className="item" style={{ width: '92%' }}>
                                  <Typography
                                    component={'div'}
                                    className="List">
                                    <Grid container>
                                      {/* <Grid md={1.2} className="d-flex-a">
                                       
                                      </Grid> */}
                                      <Grid md={10} className="d-flex-b">
                                        <img
                                          className={
                                            item.roman_name ? 'pr-1' : ''
                                          }
                                          src={
                                            item?.key?.split('.').pop() ===
                                            'pdf'
                                              ? Pdf
                                              : [
                                                  'png',
                                                  'jpg',
                                                  'jpeg',
                                                  'img',
                                                ].includes(
                                                  item?.key?.split('.').pop(),
                                                )
                                              ? img
                                              : wrd
                                          }
                                          alt={
                                            item.key === 'pdf'
                                              ? 'pdf'
                                              : [
                                                  'png',
                                                  'jpg',
                                                  'jpeg',
                                                  'img',
                                                ].includes(
                                                  item?.key?.split('.').pop(),
                                                )
                                              ? 'img'
                                              : 'word'
                                          }
                                        />
                                        <div className="wb d-flex-b child-name">
                                          {item.roman_name}
                                          {item?.original_name?.length > 18 ? (
                                            <Tooltip
                                              title={item.original_name}
                                              placement="top-start">
                                              <Typography
                                                component={'span'}
                                                className="text-wrap ">
                                                {item.original_name}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              component={'span'}
                                              className="text-wrap ">
                                              {item.original_name}
                                            </Typography>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid md={2}>
                                        <div className="d-flex-jae">
                                          <img
                                            src={Cedit}
                                            alt="im"
                                            className="pointer mr-1"
                                            onClick={() => {
                                              setRomanValue(
                                                'documentRoman',
                                                item.roman_name
                                                  ? item.roman_name.substring(
                                                      1,
                                                      item.roman_name.length -
                                                        1,
                                                    )
                                                  : '',
                                              );
                                              setRomanValue(
                                                'documentName',
                                                item.original_name
                                                  ? item.original_name
                                                  : '',
                                              );
                                              setIsShowRomanEditModal(
                                                (pre) => ({
                                                  ...pre,
                                                  assemblePetitionId:
                                                    item.assemble_petition_id,
                                                  documentRoman: item.roman_name
                                                    ? item.roman_name.substring(
                                                        1,
                                                        item.roman_name.length -
                                                          1,
                                                      )
                                                    : '',
                                                  documentType: '',
                                                  id: item.id,
                                                  show: true,
                                                  type: 'child',
                                                }),
                                              );
                                            }}
                                          />
                                          {/* <img
                                          src={Close}
                                          alt="im"
                                          className="imgs pointer"
                                          onClick={() => deleteChild(item.id)}
                                        /> */}
                                          <HighlightOffIcon
                                            sx={{ fill: '#999999' }}
                                            className="imgs pointer"
                                            onClick={() => deleteChild(item.id)}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Typography>
                                </div>
                              </Drag>
                            );
                          })
                        ) : (
                          <Grid container>
                            <Grid md={12} sm={12} className="d-flex-a">
                              <Typography
                                component={'span'}
                                sx={{
                                  color: 'red',
                                  paddingBottom: '12px',
                                  textAlign: 'center',
                                  width: '100%',
                                }}>
                                No Record(s) Found.
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    </Drop>
                    <Droppable droppableId={`${type}-${String(category.id)}`}>
                      {(provided) => (
                        <>
                          <Typography
                            className="d-flex-ja f-15 drop-parent"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            Drag and drop here
                            {provided.placeholder}
                          </Typography>
                          <br />
                        </>
                      )}
                    </Droppable>
                  </>
                ) : (
                  ''
                )}
              </div>
            </Drag>
          </>
        );
      })}
    </Drop>
  );
});
