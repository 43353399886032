import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import CustomDatePicker from 'components/CustomDatePicker/customDatePicker';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { addNewData } from 'services/CommonServices';
import { RegistrationFormType } from 'types';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { validateDate } from 'utils/helper';
// import { alertOptions } from 'utils/helper';
import { RegistrationFormSchema } from 'utils/ValidatorSchema';

export const RegistrationForm: React.FC<any> = () => {
  const [loader, setLoader] = useState(false);
  const [mobileWithCode, setMobileWithCode] = useState<any>({
    MobileSocCode: '',
    mobileCode: '',
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setError,
    formState: { errors },
  } = useForm<RegistrationFormType>({
    criteriaMode: 'all',
    defaultValues: {
      dob: '',
      email: '',
      firstName: '',
      gender: '',
      lastName: '',
      mobileNumber: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(RegistrationFormSchema),
  });
  const onSubmitForm = (data: any) => {
    if (
      data['dob'] &&
      !validateDate(moment(data['dob']).format('MM/DD/YYYY'))
    ) {
      setError('dob', {
        message: 'Not a valid date format.',
        type: 'custom',
      });
    } else {
      setLoader(true);
      data['dob'] = data['dob'] ? moment(data['dob']).format('MM/DD/YYYY') : '';
      data['role'] = '4';
      data['mobileCountryCode'] = mobileWithCode.mobileCode;
      data['mobileSocCode'] = mobileWithCode.MobileSocCode;
      addNewData(data, MASTER_ENDPOINT.Myprofile)
        .then((resp: any) => {
          setLoader(false);
          resetForm();
          handleOpen();
          // toast.success('Profile updated successfully', alertOptions);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };
  return (
    <Grid container>
      <Grid item md={10} sm={12} className="pl-0">
        <Typography component={'div'} style={{ color: '#fff' }}>
          <h1>Apply Visa for yourself Registration</h1>
        </Typography>
        {loader ? (
          <div className="liberty-loader-div" />
        ) : !open ? (
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Typography
              component={'div'}
              className="addbox"
              sx={{ marginTop: '4rem !important' }}>
              <Typography
                component={'div'}
                className="uadd-title"
                sx={{ paddingTop: '1rem !important' }}>
                Personal Details
              </Typography>
              <Box
                component="form"
                className="u-add-in"
                noValidate
                autoComplete="off">
                <Grid container columnSpacing={{ md: 2, sm: 1 }}>
                  <Grid item md={6} sm={12}>
                    <Typography
                      component={'div'}
                      className="custom-field u-pr-1 pb-1">
                      <Typography component={'div'} className="custom-label">
                        First Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="firstName"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder={''}
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.firstName?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={1}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography
                      component={'div'}
                      className="custom-field u-pr-1">
                      <Typography component={'div'} className="custom-label">
                        Gender
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="gender"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              tabIndex={3}
                              options={[
                                {
                                  label: 'Male',
                                  value: 'male',
                                },
                                {
                                  label: 'Female',
                                  value: 'female',
                                },
                              ]}
                              name={name}
                              value={value}
                              onChange={(val) => onChange(val)}
                              placeHolder={'Select'}
                              className={'custom-input'}
                              error={errors.gender?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography
                      component={'div'}
                      className="custom-field u-pr-1">
                      <Typography component={'div'} className="custom-label">
                        Email
                        <Typography component={'span'} className="Mui-error">
                          *
                        </Typography>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="email"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder={''}
                              name={name}
                              value={value}
                              className={'custom-input'}
                              onChange={(val) => onChange(val)}
                              type={'email'}
                              error={errors.email?.message}
                              tabIndex={5}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography
                      component={'div'}
                      className="custom-field u-pl-1 pb-1">
                      <Typography component={'div'} className="custom-label">
                        Last Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="lastName"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder={''}
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.lastName?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={2}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography
                      component={'div'}
                      className="custom-field u-pl-1">
                      <Typography component={'div'} className="custom-label">
                        Date of Birth
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="dob"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          const now = new Date();
                          return (
                            <CustomDatePicker
                              className="custom-input"
                              // errors={errors.dateOfBirth?.message}
                              name={name}
                              value={value}
                              onChangeDate={(val) => onChange(val)}
                              tabIndex={4}
                              maxDate={
                                new Date(
                                  now.getFullYear() - 18,
                                  now.getMonth(),
                                  now.getDate(),
                                )
                              }
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography
                      component={'div'}
                      className="custom-field u-pl-1">
                      <Typography component={'div'} className="custom-label">
                        Mobile
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="mobileNumber"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <PhoneInput
                              placeholder="Mobile Number"
                              country={'us'}
                              value={value}
                              onChange={(
                                value,
                                data,
                                event,
                                formattedValue,
                              ) => {
                                const codes: any = data;
                                onChange(formattedValue);
                                setMobileWithCode({
                                  MobileSocCode: codes?.countryCode,
                                  mobileCode: '+'.concat(codes?.dialCode),
                                });
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Typography>

            <Grid container>
              <Grid item md={12}>
                <Typography component={'div'} className="d-flex-jae my-2">
                  <Button
                    className="cancel-btn mr-2"
                    type="button"
                    onClick={() => {
                      navigate(-1);
                    }}>
                    <Typography className="o-box" />
                    Cancel
                  </Button>
                  <Button className="add-btn" type="submit" disabled={loader}>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        ) : null}

        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className="success-re-modal">
            <Typography component={'div'} className="d-flex-a">
              <CheckCircleOutlineIcon
                sx={{ fill: 'green', fontSize: '35px' }}
              />
              <Typography className="s-title mt-2">
                You have successfully completed your registration!
              </Typography>
              <Typography className="s-title mt-1">
                Please check your{' '}
                <span
                  style={{
                    textAlign: 'center',
                    textDecoration: 'underline',
                    textUnderlinePosition: 'from-font',
                  }}>
                  email
                </span>{' '}
                to activate your account.
              </Typography>
              <Button
                className="madd-btn"
                onClick={() => {
                  handleClose();
                  navigate('/login');
                }}>
                Done
              </Button>
            </Typography>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default RegistrationForm;
