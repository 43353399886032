import CloseIcon from '@mui/icons-material/Close';
import { Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import AddLead from 'assets/images/lead.png';
import AddPetition from 'assets/images/petition.png';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import Pagination from 'components/Pagination';
import { AttorneyPrivateRoutes, PetitionerPrivateRoutes } from 'config';
import _ from 'lodash';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

import CardView from './CardView';
import NotFound from './NotFound';
import TableView from './TableView';

type LeadsAndPetitions = {
  showType;
  visaTypes?: any;
  lists;
  contactTypes?: any;
  params;
  setParams: any;
  showAddBtn;
  setLoader?: any;
  getMoreLoader?: boolean;
  hasMoreResults?: boolean;
  getMoreList?: any;
  setListData?: any;
  orgId?: string;
};
export const LeadsOrPetitionsList: React.FC<LeadsAndPetitions> = ({
  showType,
  visaTypes,
  contactTypes,
  params,
  setParams,
  lists,
  showAddBtn,
  setLoader,
  getMoreLoader,
  hasMoreResults,
  getMoreList,
  setListData,
  orgId,
}) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  return (
    <Typography component={'div'}>
      <Grid container>
        <Grid item md={12} sm={12}>
          <Typography
            component={'div'}
            className="d-flex-a w-100 d-flex-sb mt-2"
            sx={{ marginBottom: '.5rem' }}>
            <Typography component={'div'}>
              {showAddBtn && userDetail.role_id !== 5 && (
                <img
                  style={{
                    cursor: 'pointer',
                    width: '70%',
                  }}
                  src={showType === 'leads' ? AddLead : AddPetition}
                  alt="add"
                  onClick={() => {
                    return navigate(
                      showType === 'leads'
                        ? userDetail.role_id === 2
                          ? PetitionerPrivateRoutes.LEADS.LEADSADD
                          : `${AttorneyPrivateRoutes.LEADS.LEADSADD}${
                              !_.isUndefined(orgId) && orgId !== ''
                                ? '?orgId=' + String(orgId)
                                : ''
                            }${
                              location.pathname.includes('invited')
                                ? '&invited=true'
                                : location.pathname.includes('associated')
                                ? '&associated=true'
                                : ''
                            }`
                        : `${AttorneyPrivateRoutes.PETITIONS.PETITIONADD}${
                            !_.isUndefined(orgId) && orgId !== ''
                              ? '?orgId=' + String(orgId)
                              : ''
                          }${
                            location.pathname.includes('invited')
                              ? '&invited=true'
                              : location.pathname.includes('associated')
                              ? '&associated=true'
                              : ''
                          }`,
                    );
                  }}
                />
              )}
            </Typography>
            <Typography
              component={'div'}
              className="d-flex-a"
              sx={{ borderRadius: '4px' }}>
              <Typography sx={{ marginRight: '.5rem', width: '250px' }}>
                <CustomInput
                  placeHolder={'Search'}
                  className={'custom-search-input'}
                  value={params.name}
                  inputProps={{
                    endAdornment:
                      params.name !== '' ? (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() => {
                              setParams((prev) => ({
                                ...prev,
                                name: '',
                                skip: 0,
                                take: 50,
                              }));
                            }}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                  }}
                  onChange={(e) => {
                    setParams((prev) => ({
                      ...prev,
                      name: e.target.value,
                      skip: 0,
                      take: 50,
                    }));
                  }}
                />
              </Typography>
              {showType === 'leads' ? (
                <Typography component={'div'} className="d-flex-a">
                  <Typography
                    component={'div'}
                    sx={{ width: '200px' }}
                    className="mr-1">
                    <CustomSelect
                      searchable={true}
                      labelKey={'visa_code'}
                      valueKey={'visa_id'}
                      options={!_.isEmpty(visaTypes) ? visaTypes : []}
                      onChange={(e) => {
                        setParams((prev) => ({
                          ...prev,
                          skip: 0,
                          take: 50,
                          visaId: e.target.value,
                        }));
                      }}
                      placeHolder={'Select visa type'}
                      className={'custom-search-input bo-rles'}
                    />
                  </Typography>
                  {contactTypes ? (
                    <Typography
                      component={'div'}
                      sx={{ width: '200px' }}
                      className="mr-1">
                      <CustomSelect
                        searchable={true}
                        labelKey={'full_name'}
                        valueKey={'id'}
                        options={!_.isEmpty(contactTypes) ? contactTypes : []}
                        onChange={(e) => {
                          setParams((prev) => ({
                            ...prev,
                            contactId: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                        placeHolder={'Contact person'}
                        className={'custom-search-input bo-rles'}
                      />
                    </Typography>
                  ) : (
                    ''
                  )}
                </Typography>
              ) : null}
            </Typography>
          </Typography>
        </Grid>

        {!_.isEmpty(lists?.data) && lists.data.length > 0 ? (
          lists.data.length > 10 ? (
            <TableView
              lists={lists}
              showType={showType}
              setLoader={setLoader}
              getMoreLoader={getMoreLoader}
              hasMoreResults={hasMoreResults}
              getMoreList={getMoreList}
              setListData={setListData}
            />
          ) : (
            <Grid container spacing={1.5}>
              <CardView
                lists={lists}
                showType={showType}
                setLoader={setLoader}
                setListData={setListData}
              />
            </Grid>
          )
        ) : (
          <NotFound />
        )}
      </Grid>
      {/* <Pagination
        total={
          !_.isEmpty(lists?.data)
            ? showType === 'leads'
              ? Number(lists?.count)
              : Number(lists?.total)
            : 0
        }
        skip={params.skip}
        take={params.take}
        setParams={setParams}
      /> */}
    </Typography>
  );
};

export default LeadsOrPetitionsList;
