import {
  Button,
  CircularProgress,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import Avator from 'assets/images/d-avator.svg';
import CustomModalView from 'components/CustomModalView/customModalView';
import { instantState } from 'hooks';
// import Pagination from 'components/Pagination';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { fileApiUrl } from 'utils/Config';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import useStyles from '../../PrivateStyle';

export const AssignAttorney: React.FC<any> = () => {
  const classes = useStyles();
  const [loader, setLoader] = useState<any>(true);
  const [isOpen, setIsOpen] = useState<any>({ benificieryId: '', show: false });
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [assignAttorneyData, setAssignAttorneyData] = useState<any>({});
  const [lawFirmData, setLawFirmData] = useState<any>([]);
  const [params, setParams] = useState<any>({
    attorney: '',
    petitioner: '',
    skip: 0,
    take: 50,
    visaId: '',
  });
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const callLawFirmApi = (item) => {
    setLoader(true);
    getAllListData(COMMON_ENDPOINT.ManageLawFirm)
      .then((resp: any) => {
        setLawFirmData(!_.isEmpty(resp.data) ? resp.data : []);
        setIsOpen({
          benificieryId: item.benificiery_id,
          show: true,
        });
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };

  const chooseFirm = (firm) => {
    setLoader(true);
    const payload = {
      attorneyContactId: firm.primaryContactId,
      attorneyId: firm.id,
      beneficieryId: isOpen.benificieryId,
      type:
        userDetail.userRoleName === 'admin'
          ? 'firm'
          : userDetail.userRoleName === 'attorney'
          ? 'firmuser'
          : '',
    };
    updateData('', payload, COMMON_ENDPOINT.AssignAttorney)
      .then((resp: any) => {
        getAssignAttorney();
        setLoader(false);
        setIsOpen({ benificieryId: '', show: false });
        toast.success('Assigned', alertOptions);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const getAssignAttorney = () => {
    getAllListData(
      `${COMMON_ENDPOINT.AssignAttorney}?skip=${String(
        params.skip,
      )}&take=${String(params.take)}&visaId=${String(
        params.visaId,
      )}&attorney=${String(params.attorney)}&petitioner=${String(
        params.petitioner,
      )}`,
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setAssignAttorneyData((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          setAssignAttorneyData(resp);
        }
        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setAssignAttorneyData);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
  };
  const getMoreList = () => {
    setGetMoreLoader(true);
    setParams((pre) => ({
      ...pre,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      skip: pre.skip + 50,
    }));
  };
  useEffect(() => {
    getAssignAttorney();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <Grid container spacing={2}>
      <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <Typography
            component={'div'}
            sx={{
              borderRadius: '8px 8px 0 0',
            }}
            className={classes.scrollbox}
            id="scrollParent">
            <InfiniteScroll
              dataLength={
                assignAttorneyData &&
                assignAttorneyData.data &&
                assignAttorneyData.data.length
                  ? assignAttorneyData.data.length
                  : 0
              }
              style={{
                overflow: 'unset',
              }}
              next={getMoreList && getMoreList}
              hasMore={hasMoreResults}
              scrollableTarget={'scrollParent'}
              loader={
                getMoreLoader && (
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      textAlign: 'center',
                      width: '100%',
                    }}>
                    <CircularProgress
                      size={30}
                      sx={{
                        color: '#002c69',
                        marginBottom: 10,
                        marginTop: 10,
                      }}
                    />
                  </div>
                )
              }
              endMessage>
              <table className="lead-table table-hover">
                <thead>
                  <tr>
                    <th>Organization</th>
                    <th>Petitioner</th>
                    <th>Beneficiary</th>
                    <th>Visa type</th>
                    <th>Attorney</th>
                    <th>Firm</th>
                    <th>action</th>
                  </tr>
                </thead>
                <tbody>
                  {assignAttorneyData &&
                  assignAttorneyData.data &&
                  assignAttorneyData.data.length > 0 ? (
                    assignAttorneyData.data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.organization ? item.organization : '-'}</td>
                        <td>
                          {item.petitioner_name ? item.petitioner_name : '-'}
                        </td>
                        <td>
                          {item.benificiery_name ? item.benificiery_name : '-'}
                        </td>
                        <td>{item.visa_type ? item.visa_type : '-'}</td>
                        <td>{item.attorney_name ? item.attorney_name : '-'}</td>
                        <td>{item.lawfirm ? item.lawfirm : '-'}</td>
                        <td>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: '#002c69',
                              borderRadius: '20px',
                              fontSize: '12px',
                              height: '24px',
                              lineHeight: '1',
                              minWidth: '80px',
                              opacity: '1',
                            }}
                            onClick={() => callLawFirmApi(item)}>
                            ReAssign
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className={'text-center'}>
                      <td colSpan={8}>No Record(s) Found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </InfiniteScroll>
          </Typography>
        )}
        {/* <Pagination
          total={
            !_.isEmpty(assignAttorneyData?.data)
              ? Number(assignAttorneyData?.total)
              : 0
          }
          skip={params.skip}
          take={params.take}
          setParams={setParams}
        /> */}
      </Grid>

      <CustomModalView
        open={isOpen.show}
        close={() => setIsOpen({ benificieryId: '', show: false })}
        title="Assign Attorney"
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'md'}>
        <DialogContent>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th scope="col">Pic</th>
                <th scope="col">Lawfirm/Attorney</th>
              </tr>
            </thead>
            <tbody>
              {lawFirmData.map((firm: any, index: number) => (
                <tr key={index} onClick={() => chooseFirm(firm)}>
                  <td>
                    <img
                      src={
                        firm?.firm_logo
                          ? `${fileApiUrl}${String(firm?.firm_logo)}`
                          : firm?.image
                          ? `${fileApiUrl}${String(firm?.image)}`
                          : Avator
                      }
                      alt="upic"
                      className={classes.userImg}
                    />
                  </td>
                  <td>
                    <Typography
                      className="rl-text"
                      style={{ cursor: 'pointer', marginTop: '10px' }}>
                      {firm?.firm_name} {firm?.first_name} {firm?.last_name}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </DialogContent>
      </CustomModalView>
    </Grid>
  );
};

export default AssignAttorney;
