import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import InfinityScroll from 'components/InfinityScroll';
// import Pagination from 'components/Pagination';
// import { AdminPrivateRoutes, AttorneyPrivateRoutes } from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
// import { NavLink } from 'react-router-dom';
import { deleteData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import useStyles from '../../PrivateStyle';

const Status = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
  {
    label: 'All',
    value: 'all',
  },
];

export const RadioButtonCrossPopulate: React.FC<any> = () => {
  const classes = useStyles();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [params, setParams] = useState<any>({
    id: '',
    linkCode: '',
    question: '',
    skip: 0,
    status: 'all',
    take: 50, // empty is all
  });
  const debouncedParams = useDebounce(params, 500);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [questions, setQuestions] = useState<any>([]);
  const [deleteDialog, setDeleteDialog] = React.useState({
    id: '',
    show: false,
  });

  const deleteBrief = React.useCallback(async () => {
    const deleteDialog = await instantState(setDeleteDialog);
    setLoader(true);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    deleteData('', `${SETUP_ENDPOINT.ConfigQuestion}/radio/${deleteDialog?.id}`)
      .then((e) => {
        setDeleteDialog((pre) => ({
          ...pre,
          show: false,
        }));
        setLoader(false);
        toast.success('Deleted successfully', alertOptions);
        setQuestions((pre) => ({
          ...pre,
          data:
            pre.data && Array.isArray(pre.data)
              ? pre.data.filter((e) => e.id !== deleteDialog?.id)
              : [],
        }));
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };
  useEffect(() => {
    getAllListData(
      `${SETUP_ENDPOINT.ConfigQuestion}?radioView=true&id=${String(
        debouncedParams.id,
      )}&skip=${String(debouncedParams.skip)}&take=${String(
        debouncedParams.take,
      )}&question=${String(
        debouncedParams.question,
      )}&responseType=5&linkCode=${String(
        debouncedParams.linkCode,
      )}&status=${String(
        debouncedParams.status === 'all' ? '' : debouncedParams.status,
      )}`,
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setQuestions((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          const mergeData = !_.isEmpty(questions)
            ? [...questions.data, ...resp.data]
            : resp.data;
          setQuestions({
            count: resp.total,
            data: mergeData,
          });
        }
        setTableLoader(false);

        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setQuestions);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams, userDetail]);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12} className="pr-2rem">
        <Typography component={'div'} className="configureBox">
          {loader ? (
            <div className="liberty-loader" />
          ) : (
            <Box>
              <Grid item md={12} sm={12}>
                <Typography
                  component={'div'}
                  className="d-flex-jae full-screen-searc mb-2 mt-2">
                  <Typography
                    component={'div'}
                    className="d-flex-a small-search"
                    sx={{
                      // backgroundColor: '#84898c',
                      borderRadius: '4px',
                    }}>
                    <Typography
                      className="d-flex-a searc-box1"
                      component={'div'}
                      sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: '4px',
                        marginRight: '.5rem',
                        width: '205px',
                      }}>
                      <SearchIcon
                        sx={{
                          fill: '#3d3f40 !important',
                          left: '5px',
                          position: 'absolute',
                          zIndex: '1',
                        }}
                      />
                      <CustomInput
                        placeHolder={'Search for Questions'}
                        className={'custom-search-input bo-rles'}
                        value={params.question}
                        inputProps={{
                          endAdornment:
                            params.question !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    setTableLoader(true);
                                    setQuestions({
                                      count: 0,
                                      data: [],
                                    });
                                    setParams((prev) => ({
                                      ...prev,
                                      question: '',
                                      skip: 0,
                                      take: 50,
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          setTableLoader(true);
                          setQuestions({
                            count: 0,
                            data: [],
                          });
                          setParams((prev) => ({
                            ...prev,
                            question: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                      />
                    </Typography>
                    <Typography
                      className="d-flex-a searc-box1"
                      component={'div'}
                      sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: '4px',
                        marginRight: '.5rem',
                        width: '200px',
                      }}>
                      <SearchIcon
                        sx={{
                          fill: '#3d3f40 !important',
                          left: '5px',
                          position: 'absolute',
                          zIndex: '1',
                        }}
                      />
                      <CustomInput
                        placeHolder={'Search for Link Code'}
                        className={'custom-search-input bo-rles'}
                        value={params.linkCode}
                        // }}
                        inputProps={{
                          endAdornment:
                            params.linkCode !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    setTableLoader(true);
                                    setQuestions({
                                      count: 0,
                                      data: [],
                                    });
                                    setParams((prev) => ({
                                      ...prev,
                                      linkCode: '',
                                      skip: 0,
                                      take: 50,
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          setTableLoader(true);
                          setQuestions({
                            count: 0,
                            data: [],
                          });
                          setParams((prev) => ({
                            ...prev,
                            linkCode: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                      />
                    </Typography>
                    <Typography sx={{ width: '205px' }} className="searc-box3">
                      <CustomSelect
                        searchable={true}
                        labelKey={'label'}
                        valueKey={'value'}
                        value={
                          params.status === 'all'
                            ? 'all'
                            : params.status
                            ? 'active'
                            : 'inactive'
                        }
                        isClearable={params.status === 'all' ? false : true}
                        options={Status}
                        onChange={(e) => {
                          setTableLoader(true);
                          setQuestions({
                            count: 0,
                            data: [],
                          });
                          setParams((prev) => ({
                            ...prev,
                            skip: 0,
                            status:
                              e.target.value === 'all' || e.target.value === ''
                                ? 'all'
                                : e.target.value === 'active'
                                ? true
                                : false,
                            take: 50,
                          }));
                        }}
                        placeHolder={'Status'}
                        className={'custom-search-input'}
                      />
                    </Typography>
                  </Typography>
                </Typography>
              </Grid>

              <Typography
                component={'div'}
                sx={{
                  borderRadius: '8px 8px 0 0',
                }}
                className={classes.scrollbox}
                id="scrollParent">
                <InfiniteScroll
                  dataLength={
                    questions && questions.data && questions.data.length
                      ? questions.data.length
                      : 0
                  }
                  style={{
                    overflow: 'unset',
                  }}
                  next={getMoreList && getMoreList}
                  hasMore={hasMoreResults}
                  scrollableTarget={'scrollParent'}
                  loader={
                    getMoreLoader && (
                      <div
                        style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          width: '100%',
                        }}>
                        <CircularProgress
                          size={30}
                          sx={{
                            color: '#002c69',
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        />
                      </div>
                    )
                  }
                  endMessage>
                  <table className="lead-table table-hover">
                    <thead>
                      <tr>
                        <th>Quest.Id</th>
                        <th>questions</th>
                        <th>responses</th>
                        <th>link Code</th>
                        <th>Mapped Link Code</th>
                        <th>status</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody style={{ cursor: 'pointer' }}>
                      {tableLoader ? (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <div
                              style={{
                                backgroundColor: '#ffffff',
                                textAlign: 'center',
                                width: '100%',
                              }}>
                              <CircularProgress
                                size={30}
                                sx={{
                                  color: '#002c69',
                                  marginBottom: 10,
                                  marginTop: 10,
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                          {questions &&
                          questions.data &&
                          questions.data.length > 0
                            ? questions.data.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.id ? item.id : '-'}</td>
                                  <td>
                                    <div
                                      className="hideshow"
                                      style={{
                                        width: '200px',
                                        wordBreak: 'break-all',
                                      }}>
                                      {item.question ? item.question : '-'}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className="hideshow"
                                      style={{
                                        width: '200px',
                                        wordBreak: 'break-all',
                                      }}>
                                      {String(item.response).trim()
                                        ? item.response
                                        : '-'}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className="hideshow"
                                      style={{
                                        width: '200px',
                                        wordBreak: 'break-all',
                                      }}>
                                      {item.link_code ? item.link_code : '-'}
                                    </div>
                                  </td>
                                  <td>
                                    {item?.pre_populate_question_details &&
                                    Array.isArray(
                                      item?.pre_populate_question_details,
                                    )
                                      ? item?.pre_populate_question_details.map(
                                          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                          (e) => `${e?.link_code}, `,
                                        )
                                      : ''}
                                  </td>
                                  <td>{item.status ? 'Active' : 'Inactive'}</td>
                                  <td>
                                    <Tooltip title="Delete" placement="top">
                                      <DeleteForeverSharpIcon
                                        style={{
                                          cursor: 'pointer',
                                          fill: 'red',
                                        }}
                                        onClick={() =>
                                          setDeleteDialog((pre) => ({
                                            ...pre,
                                            id: item?.id,
                                            show: true,
                                          }))
                                        }
                                      />
                                    </Tooltip>
                                  </td>
                                </tr>
                              ))
                            : !tableLoader && (
                                <tr className={'text-center'}>
                                  <td colSpan={10}>No Record(s) Found.</td>
                                </tr>
                              )}
                        </>
                      )}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </Typography>
            </Box>
          )}
        </Typography>
      </Grid>
      <ConfirmDialog
        title="DELETE"
        floatingBtnShow={true}
        open={deleteDialog.show}
        onConfirm={(act) => {
          if (act !== 'yes') {
            setDeleteDialog((pre) => ({
              ...pre,
              show: false,
            }));
          } else {
            deleteBrief();
          }
        }}
        autoCloseDisable={true}>
        {'Are you sure do you want to delete?'}
      </ConfirmDialog>
    </Grid>
  );
};
