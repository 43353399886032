import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import InfinityScroll from 'components/InfinityScroll';
// import Pagination from 'components/Pagination';
import { AdminPrivateRoutes } from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';

import useStyles from '../../PrivateStyle';

export const DocumentType: React.FC<any> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [params, setParams] = useState<any>({
    documentType: '',
    skip: 0,
    take: 50,
    visaId: '',
  });
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [typesData, setTypesData] = useState<any>([]);
  const [visaTypes, setVisaTypes] = useState<any>([]);
  const debouncedParams = useDebounce(params, 500);

  useEffect(() => {
    getAllListData(
      `${SETUP_ENDPOINT.DocumentType}?skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}&documentType=${String(
        debouncedParams.documentType,
      )}&visaId=${String(debouncedParams.visaId)}`,
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setTypesData((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          const mergeData = !_.isEmpty(typesData)
            ? [...typesData.data, ...resp.data]
            : resp.data;
          setTypesData({
            count: resp.total,
            data: mergeData,
          });
        }
        setTableLoader(false);
        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setTypesData);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams, userDetail]);
  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };

  useEffect(() => {
    getAllListData(COMMON_ENDPOINT.VisaTypes)
      .then((resp: any) => {
        const visaOptions = [...resp.data];
        setVisaTypes(visaOptions);
        // setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const rowProps = (type, index) => {
  //   return (
  //     <NavLink
  //       to={
  // userDetail.role_id === 1 &&
  // `${
  //   AdminPrivateRoutes.DOCUMENT.DOCUMENTTPYEEDIT
  // }/${String(item.id)}`,
  //       }
  //       key={index}>
  //       <Typography className="configureItem">
  //         <Typography className="d-flex-a d-flex-sb w-100">
  //           <Typography component={'div'} className="d-flex-3">
  //             <Typography className="d-flex-a black-white-text ctitle">
  //               {/* <Typography className="rl-text">Type:</Typography> */}
  //               {type.valid_documents}
  //             </Typography>

  //             <Typography
  //               component={'div'}
  //               className="d-flex-a w-100 d-flex-sb mt-2">
  //               <Typography className="rl-text">
  //                 Type: {type.document_type}
  //               </Typography>
  //             </Typography>
  //           </Typography>
  //         </Typography>
  //       </Typography>
  //     </NavLink>
  //   );
  // };
  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12} className="pr-2rem">
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <Box sx={{ width: '100%' }} className="documentTab">
            <Grid item md={12} sm={12}>
              <Typography component={'div'} className="d-flex-jae mb-2 mt-2">
                <Typography component={'div'} className="d-flex-a">
                  <Typography
                    component={'div'}
                    sx={{ marginRight: '.5rem', width: '255px' }}>
                    <CustomSelect
                      searchable={true}
                      labelKey={'visa_code'}
                      valueKey={'visa_id'}
                      options={!_.isEmpty(visaTypes) ? visaTypes : []}
                      onChange={(e) => {
                        setTableLoader(true);
                        setTypesData({
                          count: 0,
                          data: [],
                        });
                        setParams((prev) => ({
                          ...prev,
                          skip: 0,
                          take: 50,
                          visaId: e.target.value,
                        }));
                      }}
                      placeHolder={'Select Visa Type'}
                      className={'custom-search-input bo-rles'}
                    />
                  </Typography>
                  <Typography sx={{ width: '165px' }}>
                    <CustomInput
                      placeHolder={'Search'}
                      className={'custom-search-input bo-les'}
                      value={params.documentType}
                      inputProps={{
                        endAdornment:
                          params.documentType !== '' ? (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={() => {
                                  setTableLoader(true);

                                  setTypesData({
                                    count: 0,
                                    data: [],
                                  });
                                  setParams((prev) => ({
                                    ...prev,
                                    documentType: '',
                                    skip: 0,
                                    take: 50,
                                  }));
                                }}>
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                      }}
                      onChange={(e) => {
                        setTableLoader(true);

                        setTypesData({
                          count: 0,
                          data: [],
                        });
                        setParams((prev) => ({
                          ...prev,
                          documentType: e.target.value,
                          skip: 0,
                          take: 50,
                        }));
                      }}
                    />
                  </Typography>
                  <Typography
                    sx={{ marginLeft: '.5rem', width: '165px' }}
                    onClick={() =>
                      navigate(
                        userDetail.role_id === 1 &&
                          `${String(AdminPrivateRoutes.DOCUMENT.REARRANGE)}`,
                      )
                    }>
                    <Button
                      type="button"
                      className="n-add-btn mt-2 mb-1"
                      sx={{ marginTop: '9px!important' }}>
                      Rearrange
                    </Button>
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
            <Typography
              component={'div'}
              sx={{
                borderRadius: '8px 8px 0 0',
              }}
              className={classes.scrollbox}
              id="scrollParent">
              <InfiniteScroll
                dataLength={
                  typesData && typesData.data && typesData.data.length
                    ? typesData.data.length
                    : 0
                }
                style={{
                  overflow: 'unset',
                }}
                next={getMoreList && getMoreList}
                hasMore={hasMoreResults}
                scrollableTarget={'scrollParent'}
                loader={
                  getMoreLoader && (
                    <div
                      style={{
                        backgroundColor: '#ffffff',
                        textAlign: 'center',
                        width: '100%',
                      }}>
                      <CircularProgress
                        size={30}
                        sx={{
                          color: '#002c69',
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                      />
                    </div>
                  )
                }
                endMessage>
                <table className="lead-table table-hover">
                  <thead>
                    <tr>
                      <th>document type</th>
                      <th> valid documents</th>
                    </tr>
                  </thead>
                  <tbody style={{ cursor: 'pointer' }}>
                    {tableLoader ? (
                      <tr>
                        <td colSpan={8}>
                          <div
                            style={{
                              backgroundColor: '#ffffff',
                              textAlign: 'center',
                              width: '100%',
                            }}>
                            <CircularProgress
                              size={30}
                              sx={{
                                color: '#002c69',
                                marginBottom: 10,
                                marginTop: 10,
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {typesData &&
                        typesData.data &&
                        typesData.data.length > 0 ? (
                          typesData.data.map((item, index) => (
                            <tr
                              key={index}
                              onClick={() =>
                                navigate(
                                  userDetail.role_id === 1 &&
                                    `${
                                      AdminPrivateRoutes.DOCUMENT
                                        .DOCUMENTTPYEEDIT
                                    }/${String(item.id)}`,
                                )
                              }>
                              <td>
                                <div
                                  className="hideshow"
                                  style={{
                                    width: '255px',
                                    wordBreak: 'break-all',
                                  }}>
                                  {item.document_type
                                    ? item.document_type
                                    : '-'}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="hideshow"
                                  style={{
                                    width: '255px',
                                    wordBreak: 'break-all',
                                  }}>
                                  {item.valid_documents
                                    ? item.valid_documents
                                    : '-'}
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className={'text-center'}>
                            <td colSpan={4}>No Record(s) Found.</td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            </Typography>
            {/* <Typography component={'div'} className="configureBox">
              {typesData && typesData?.data.length > 0 ? (
                <InfinityScroll
                  paramState={setParams}
                  // height={'56vh'}
                  scrollableTargetId={'managePetitioner'}
                  data={typesData.data}
                  rowProps={rowProps}
                  count={typesData.count}
                />
              ) : (
                <Typography
                  className="note-box2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                  <Typography className="inb2" />
                  <Typography className="black-white-text ctitle hideshow">
                    No Record(s) Found.
                  </Typography>
                </Typography>
              )}
            </Typography> */}
          </Box>
        )}
        {/* <Pagination
          total={!_.isEmpty(typesData?.data) ? Number(typesData?.total) : 0}
          skip={params.skip}
          take={params.take}
          setParams={setParams}
        /> */}
      </Grid>
    </Grid>
  );
};

export default DocumentType;
