import jwtDecode from 'jwt-decode';

import { decrypt, encrypt } from './LocalStorage';

type AuthTokenType = {
  token: string;
};

class Storage {
  static KEYS = {
    AUTH_TOKEN: 'AUTH_TOKEN',
  };

  static setItem = (key: string, value: string): void => {
    if (!value) {
      value = '';
    }

    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, encrypt(value));
  };

  static getItem = (key: string, shouldParse?: boolean): string | null => {
    const keyVal = decrypt(localStorage.getItem(Storage.searchKey(key)));

    if (shouldParse && keyVal) {
      return JSON.parse(keyVal);
    }

    return keyVal;
  };

  static searchKey(key: string): string {
    const keys = Object.keys(localStorage);
    let i = keys.length;

    while (i--) {
      try {
        if (keys[i] === key) {
          return keys[i];
        }
      } catch (e) {
        localStorage.removeItem(keys[i]);
      }
    }

    return '';
  }

  static removeItem = (key: string): void => {
    localStorage.removeItem(Storage.searchKey(key));
  };

  static clear = (): void => {
    localStorage.clear();
  };

  static getTokens = () => {
    try {
      const userRole = Storage.getItem('userRole')
        ? Storage.getItem('userRole').concat('Token')
        : null;
      const appAuth = userRole ? Storage.getItem(userRole) : null;
      return appAuth ? (JSON.parse(appAuth) as AuthTokenType) : null;
    } catch (e) {
      return null;
    }
  };

  static getAdminTokens = () => {
    try {
      const appAuth = Storage.getItem('adminToken');
      return appAuth ? (JSON.parse(appAuth) as AuthTokenType) : null;
    } catch (e) {
      return null;
    }
  };

  static isTokenValidDetails = () => {
    const tokenData: any = Storage.getTokens();
    if (!tokenData || !tokenData.token) {
      return false;
    }
    const userData: any = jwtDecode(String(tokenData.token));
    if (!userData || (userData && userData.exp * 1000 < Date.now())) {
      return false;
    }
    return userData;
  };

  static updateAccessToken = (token) => {
    const userRole = Storage.getItem('userRole').concat('Token');
    const tokenData: any = Storage.getTokens();
    tokenData.token = token;
    Storage.setItem(userRole, JSON.stringify(tokenData));
  };

  static isTokenExpired = (token: string) => {
    if (!token || token === '') {
      return true;
    }

    const decoded: object | any = JSON.parse(window.atob(token.split('.')[1]));
    // eslint-disable-next-line no-prototype-builtins
    if (!decoded || !decoded.hasOwnProperty('exp')) {
      return true;
    }
    return Date.now() >= decoded.exp * 1000;
  };
}

export default Storage;
