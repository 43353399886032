import { Grid, Typography } from '@mui/material';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import _ from 'lodash';
import React from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

import LeadsOrPetitionsDetail from '../../Common/LeadsOrPetitions/Details';

export const PetitionDetailList: React.FC<any> = () => {
  const { petitionId } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const handleOpen = () => {
    {
      userDetail.role_id === 3 &&
        navigate(
          `${String(
            AttorneyPrivateRoutes.PETITIONS.UPLOADPETITIONSDOCUMENT,
          )}/${String(petitionId)}${
            searchParams.get('orgId')
              ? '?orgId=' + searchParams.get('orgId')
              : ''
          }${
            location.pathname.includes('invited')
              ? '&invited=true&isPetition=true'
              : location.pathname.includes('associated')
              ? '&associated=true&isPetition=true'
              : ''
          }`,
        );
    }
    {
      userDetail.role_id === 2 &&
        navigate(
          `${PetitionerPrivateRoutes.PETITIONS.UPLOADDOCUMENT}/${String(
            petitionId,
          )}`,
        );
    }
    {
      userDetail.role_id === 5 &&
        navigate(
          `${String(
            AffilatePrivateRoutes.ATTORNEY_PETITIONS.UPLOADPETITIONSDOCUMENT,
          )}/${String(petitionId)}`,
        );
    }
  };

  const { petitions } = useAppSelector((store: any) => store?.petitionUser);

  return (
    <Grid container>
      {!_.isEmpty(petitions) && !_.isEmpty(petitions.petitionData) && (
        <Grid item md={12} sm={12} className="pl-0">
          <Typography
            component={'div'}
            className="black-white-text d-flex-a"
            sx={{
              fontFamily: 'Arial-Bold !important',
              fontSize: '1.25em !important',
              margin: '1.5rem 0 1.5rem 0',
            }}>
            {petitions.petitionData.visa_type} for{' '}
            {petitions.petitionData?.benificiery_name}
            <div className="affliate">
              {' '}
              {petitions?.petitionData?.isAffilateCase
                ? '(Affiliate)'
                : ''}{' '}
            </div>
          </Typography>
        </Grid>
      )}
      <LeadsOrPetitionsDetail
        showType="petitions"
        handleOpen={handleOpen}
        listData={petitions}
      />
    </Grid>
  );
};

export default PetitionDetailList;
