export interface FirmState {
  edit?: boolean;
}
export interface Action {
  type: string;
  payload?: string;
}

const INITIAL_STATE: FirmState = { edit: false };

export const MyfirmReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case 'edit':
      return { edit: true };

    case 'noedit':
      return { edit: false };

    default:
      return state;
  }
};
