import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FormHelperText, Tooltip, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

type Props = {
  document: any;
  setLoader: (src: boolean) => void;
  sIndex: number;
  type?: string;
  multipleDocuemnt?: Array<any>;
};
export const EditFileNameUploadDocuments: React.FC<Props> = (props: Props) => {
  const { document, sIndex, setLoader, type = '', multipleDocuemnt } = props;

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [name, setName] = React.useState<string>(
    document?.original_name
      ? document?.original_name
          .split('.')
          .slice(
            0,
            document?.original_name.split('.').length === 1
              ? document?.original_name.split('.').length
              : document?.original_name.split('.').length - 1,
          )
          .join('')
      : '',
  );

  const saveDocumentName = async () => {
    try {
      if (!String(name).trim()) {
        toast.error('File Name should not be empty');
        return;
      }
      setLoader && setLoader(true);
      const bodyObj = {
        filename: `${name}.`.concat(
          document?.original_name.split('.').length > 1
            ? document?.original_name.split('.').pop()
            : '',
        ),
      };
      await updateData(
        '',
        bodyObj,
        type === 'dependent'
          ? COMMON_ENDPOINT.uploadDepentendDocummentFileNameEdit(
              document?.documentId,
            )
          : type === 'organization'
          ? `${COMMON_ENDPOINT.OrganizationUploadDocuments}/rename/${String(
              document?.documentId,
            )}`
          : COMMON_ENDPOINT.uploadDocumentFileNameEdit(document?.documentId),
      );
      setLoader && setLoader(false);
      document.original_name = bodyObj.filename;
      document.modified_at = new Date();
      document.modified_by = userDetail?.full_name;
      document.edit = false;
      const removeDoc = multipleDocuemnt.splice(sIndex, 1);
      multipleDocuemnt.unshift(removeDoc[0]);
      toast.success('File Name update successfully', alertOptions);
    } catch (error) {
      setLoader && setLoader(false);
    }
  };

  return (
    <Typography
      component={'div'}
      className="d-flex align-center black-white-text w-100">
      {document?.edit ? (
        <form
          className="w-100"
          onSubmit={(e) => {
            e.preventDefault();
            saveDocumentName();
          }}>
          <Typography
            component={'div'}
            className="black-white-text custom-field"
            sx={{
              marginBottom: 'unset !important',
            }}>
            <CustomInput
              focus={true}
              className="custom-input"
              placeHolder={''}
              name={`${sIndex}`}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {!String(name).trim() && (
              <FormHelperText style={{ color: '#ff0000', marginLeft: '0px' }}>
                {'File Name should not be empty'}
              </FormHelperText>
            )}
          </Typography>
        </form>
      ) : (
        <Typography component={'div'} className="black-white-text">
          {document.original_name}
          {' by '}
          {document.modified_by}
          <Typography component={'div'} className="black-white-text">
            {moment(new Date(document.modified_at)).format('MMMM DD, YYYY')}
          </Typography>
        </Typography>
      )}

      {document?.edit ? (
        <>
          <Tooltip title={'Cancel'} placement="top">
            <CloseIcon
              sx={{
                cursor: 'pointer',
                marginLeft: '10px',
              }}
              onClick={() => {
                document.edit = false;
              }}
            />
          </Tooltip>
          <Tooltip title={'Save'} placement="top">
            <SaveIcon
              sx={{
                cursor: 'pointer',
                marginLeft: '10px',
              }}
              onClick={() => saveDocumentName()}
            />
          </Tooltip>
        </>
      ) : (
        <Tooltip title={'Edit'} placement="top">
          <EditIcon
            sx={{
              cursor: 'pointer',
              marginLeft: '10px',
            }}
            onClick={() => {
              setName(
                document?.original_name
                  .split('.')
                  .slice(
                    0,
                    document?.original_name.split('.').length === 1
                      ? document?.original_name.split('.').length
                      : document?.original_name.split('.').length - 1,
                  )
                  .join(''),
              );
              document.edit = true;
            }}
          />
        </Tooltip>
      )}
    </Typography>
  );
};
