import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import InfinityScroll from 'components/InfinityScroll';
// import Pagination from 'components/Pagination';
import { AdminPrivateRoutes } from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
// import moment from 'moment';
import useStyles from 'pages/private/PrivateStyle';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';

const Status = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

export const VisaType: React.FC<any> = () => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const classes = useStyles();
  const navigate = useNavigate();

  const [params, setParams] = useState<any>({
    skip: 0,
    status: 'active',
    take: 50,
    visaCode: '',
    visaDescription: '',
  });
  const [loader, setLoader] = useState(true);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [petitionerData, setPetitionerData] = useState<any>({});
  const debouncedParams = useDebounce(params, 500);

  useEffect(() => {
    getAllListData(
      `${COMMON_ENDPOINT.VisaTypes}?skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}&visaCode=${String(
        debouncedParams.visaCode,
      )}&visaDescription=${String(
        debouncedParams.visaDescription,
      )}&status=${String(debouncedParams.status)}`,
    )
      .then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setPetitionerData((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          const mergeData = !_.isEmpty(petitionerData)
            ? [...petitionerData.data, ...resp.data]
            : resp.data;
          setPetitionerData({
            count: resp.total,
            data: mergeData,
          });
        }
        setLoader(false);
        setTableLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setPetitionerData);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);
  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={9.8} sm={12}>
        <Typography
          component={'div'}
          className="d-flex-jae full-screen-searc mb-2 mt-2">
          <Typography
            component={'div'}
            className="d-flex-a small-search"
            sx={{
              // backgroundColor: '#84898C',
              borderRadius: '4px',
            }}>
            <Typography
              sx={{
                marginRight: '.5rem',
                position: 'relative',
                width: '267px',
              }}
              component={'div'}>
              <SearchIcon
                sx={{
                  fill: '#3D3F40 !important',
                  left: '5px',
                  position: 'absolute',
                  top: '6px',
                  zIndex: '1',
                }}
              />
              <CustomInput
                placeHolder={'Search for Visa Code'}
                className={'custom-search-input bo-rles in-pad-se'}
                value={params.visaCode}
                inputProps={{
                  endAdornment:
                    params.visaCode !== '' ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => {
                            setPetitionerData({
                              count: 0,
                              data: [],
                            });
                            setParams((prev) => ({
                              ...prev,
                              skip: 0,
                              take: 50,
                              visaCode: '',
                            }));
                          }}>
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                }}
                onChange={(e) => {
                  setTableLoader(true);
                  setPetitionerData({
                    count: 0,
                    data: [],
                  });
                  setParams((prev) => ({
                    ...prev,
                    skip: 0,
                    take: 50,
                    visaCode: e.target.value,
                  }));
                }}
              />
            </Typography>
            <Typography
              sx={{
                marginRight: '.5rem',
                position: 'relative',
                width: '267px',
              }}>
              <SearchIcon
                sx={{
                  fill: '#3D3F40 !important',
                  left: '5px',
                  position: 'absolute',
                  top: '6px',
                  zIndex: '1',
                }}
              />
              <CustomInput
                placeHolder={'Search for Description'}
                className={'custom-search-input bo-rles in-pad-se'}
                value={params.visaDescription}
                inputProps={{
                  endAdornment:
                    params.visaDescription !== '' ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => {
                            setTableLoader(true);
                            setPetitionerData({
                              count: 0,
                              data: [],
                            });
                            setParams((prev) => ({
                              ...prev,
                              skip: 0,
                              take: 50,
                              visaDescription: '',
                            }));
                          }}>
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                }}
                onChange={(e) => {
                  setTableLoader(true);
                  setPetitionerData({
                    count: 0,
                    data: [],
                  });
                  setParams((prev) => ({
                    ...prev,
                    skip: 0,
                    take: 50,
                    visaDescription: e.target.value,
                  }));
                }}
              />
            </Typography>
            <Typography sx={{ width: '205px' }} className="searc-box3">
              <CustomSelect
                labelKey={'label'}
                valueKey={'value'}
                value={params.status}
                options={Status}
                selectedValue={''}
                onChange={(e) => {
                  setTableLoader(true);
                  setPetitionerData({
                    count: 0,
                    data: [],
                  });
                  setParams((prev) => ({
                    ...prev,
                    skip: 0,
                    status: e.target.value,
                    take: 50,
                  }));
                }}
                placeHolder={'Status'}
                className={'custom-search-input'}
              />
            </Typography>
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={9.8} sm={12}>
        <Typography component={'div'}>
          {loader ? (
            <div className="liberty-loader" />
          ) : (
            <TableContainer
              sx={{
                borderRadius: '10px 10px 0px 0px',
              }}>
              <Typography
                component={'div'}
                className={classes.scrollbox}
                id="scrollParent">
                <InfiniteScroll
                  dataLength={
                    petitionerData &&
                    petitionerData.data &&
                    petitionerData.data.length
                      ? petitionerData.data.length
                      : 0
                  }
                  style={{
                    overflow: 'unset',
                  }}
                  next={getMoreList && getMoreList}
                  hasMore={hasMoreResults}
                  scrollableTarget={'scrollParent'}
                  loader={
                    getMoreLoader && (
                      <div
                        style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          width: '100%',
                        }}>
                        <CircularProgress
                          size={30}
                          sx={{
                            color: '#002c69',
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        />
                      </div>
                    )
                  }
                  endMessage>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="lead-table table-hover">
                    <TableHead>
                      <TableRow>
                        <TableCell className="W-4">visa Code</TableCell>
                        <TableCell className="W-4">Description</TableCell>
                        <TableCell className="W-1">Country</TableCell>
                        <TableCell className="W-1">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* <TableRow>
                        
                      </TableRow> */}
                      {tableLoader ? (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <div
                              style={{
                                backgroundColor: '#ffffff',
                                textAlign: 'center',
                                width: '100%',
                              }}>
                              <CircularProgress
                                size={30}
                                sx={{
                                  color: '#002c69',
                                  marginBottom: 10,
                                  marginTop: 10,
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                          {!_.isEmpty(petitionerData?.data) &&
                          petitionerData.data.length > 0
                            ? petitionerData.data.map((petitioner, index) => (
                                // <NavLink
                                //   to={
                                //     userDetail.role_id === 1 &&
                                //     `${
                                //       AdminPrivateRoutes.VISATYPE.VISATYPEEDIT
                                //     }/${String(petitioner.visa_id)}`
                                //   }
                                //   key={index}>
                                <TableRow
                                  key={index}
                                  onClick={() =>
                                    navigate(
                                      userDetail.role_id === 1 &&
                                        `${
                                          AdminPrivateRoutes.VISATYPE
                                            .VISATYPEEDIT
                                        }/${String(petitioner.visa_id)}`,
                                    )
                                  }
                                  style={{ cursor: 'pointer' }}>
                                  <td>
                                    <div
                                      className="hideshow"
                                      style={{
                                        width: '200px',
                                        wordBreak: 'break-all',
                                      }}>
                                      {petitioner.visa_code
                                        ? petitioner.visa_code
                                        : '-'}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className="hideshow"
                                      style={{
                                        width: '200px',
                                        wordBreak: 'break-all',
                                      }}>
                                      {petitioner.visa_description
                                        ? petitioner.visa_description
                                        : '-'}
                                    </div>
                                  </td>
                                  <TableCell>
                                    {petitioner.country
                                      ? petitioner.country
                                      : '-'}
                                  </TableCell>
                                  <TableCell>
                                    {petitioner.status === 'active'
                                      ? 'Active'
                                      : 'Inactive'}
                                  </TableCell>
                                </TableRow>
                              ))
                            : !tableLoader && (
                                <tr className={'text-center'}>
                                  <td colSpan={8}>No Record(s) Found.</td>
                                </tr>
                              )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                  {/* <Pagination
          total={!_.isEmpty(lists?.data) ? Number(lists?.count) : 0}
          skip={params.skip}
          take={params.take}
          setParams={setParams}
        /> */}
                </InfiniteScroll>
              </Typography>
            </TableContainer>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default VisaType;
