import InfoMessage from 'components/InfoMessage';
import I129ParsingView from 'components/ParsingDocumentView/i-129PdfParsing';
import PrivateLayout from 'components/PrivateLayout';
import PublicLayout from 'components/PublicLayout';
import _ from 'lodash';
import { Calendar } from 'pages/private/Calendar';
import ReviewDocument from 'pages/private/Client/Common/ReviewDocument';
import DocumentParsing from 'pages/private/Client/DocumentParsing';
import DocumentParsingView from 'pages/private/Client/DocumentParsing/Details';
import LeadsList from 'pages/private/Client/Leads';
import LeadAdd from 'pages/private/Client/Leads/AttorneyLeads';
import CompleteQuestion from 'pages/private/Client/Leads/CompleteQuestion';
import ContractPayment from 'pages/private/Client/Leads/ContractPayment';
import ContractAddEdit from 'pages/private/Client/Leads/ContractPayment/AddAndEdit';
import ContractViewer from 'pages/private/Client/Leads/ContractPayment/ContractViewer';
import ContractPaymentDetails from 'pages/private/Client/Leads/ContractPayment/Details';
import PaymentDetail from 'pages/private/Client/Leads/ContractPayment/PaymentDetail';
import AchPayment from 'pages/private/Client/Leads/ContractPayment/PaymentMethod/ACHpayment';
import CreditDebitPayment from 'pages/private/Client/Leads/ContractPayment/PaymentMethod/CreditDebitCard';
import PaymentMethodAndTerms from 'pages/private/Client/Leads/ContractPayment/PaymentMethodAndTerms';
import LeadsDetailList from 'pages/private/Client/Leads/DetailsList';
import LeadsToPetition from 'pages/private/Client/Leads/LeadToPetition';
import { UploadDocument } from 'pages/private/Client/Leads/UploadDocument';
// import PaymentReport from 'pages/private/Client/PaymentReport';
import PetitionsList from 'pages/private/Client/Petitions';
import PetitionAdd from 'pages/private/Client/Petitions/Add';
import PetitionCaseDetail from 'pages/private/Client/Petitions/CaseDetail';
import CompleteQuestions from 'pages/private/Client/Petitions/CompleteQuestion';
import Contractpayment from 'pages/private/Client/Petitions/ContractPayment';
import PetitionDetailList from 'pages/private/Client/Petitions/DetailsList';
import PetitionBuildCase from 'pages/private/Client/Petitions/PetitionBuildCase';
import AcceptOrReject from 'pages/private/Client/Petitions/PetitionBuildCase/AcceptOrReject';
// import PetitionAssemble from 'pages/private/Client/Petitions/PetitionBuildCase/Assemble';
import AssembleDragDrop from 'pages/private/Client/Petitions/PetitionBuildCase/Assemble/AssembleDragDrop';
// import BriefCoverPage from 'pages/private/Client/Petitions/PetitionBuildCase/BriefCoverPage';
import BuildCaseEdit from 'pages/private/Client/Petitions/PetitionBuildCase/Edit';
import PreviewBriefCover from 'pages/private/Client/Petitions/PetitionBuildCase/PreviewBriefCover';
import SubmissionStation from 'pages/private/Client/Petitions/PetitionBuildCase/SubmissionStation';
import { RfeResponder } from 'pages/private/Client/Petitions/PetitionBuildCase/SubmissionStation/ReceiptNotice/RfeResponder/RfeResponder';
import { RfeResponseAccept } from 'pages/private/Client/Petitions/PetitionBuildCase/SubmissionStation/ReceiptNotice/RfeResponder/RfeResponseAccept';
import { RfeUpload } from 'pages/private/Client/Petitions/PetitionBuildCase/SubmissionStation/ReceiptNotice/RfeResponder/RfeUpload';
import { RfeView } from 'pages/private/Client/Petitions/PetitionBuildCase/SubmissionStation/ReceiptNotice/RfeResponder/RfeView';
import { UploadPetitionsDocument } from 'pages/private/Client/Petitions/UploadPetitionsDocument';
import { RequestAdditionalDocument } from 'pages/private/Client/Petitions/UploadPetitionsDocument/RequestAdditionalDocument';
import Socialshare from 'pages/private/Client/socialshare';
import WinningStrategy from 'pages/private/Client/WinningStrategy';
import { AssemblePreviewDownload } from 'pages/private/Common/AssemblePreview/AssemblePreviewDownload';
import { ParsingValuePreview } from 'pages/private/Common/ParsingValuePreview';
import Strategy from 'pages/private/Common/Strategy';
import StrategyInfo from 'pages/private/Common/Strategy/details';
import { ChangePassword } from 'pages/private/Master/ChangePassword';
import ContactSupport from 'pages/private/Master/ContactSupport';
import { Dashboard } from 'pages/private/Master/Dashboard';
import Myprofile from 'pages/private/Master/Myprofile';
import { OraganizationOverview } from 'pages/private/Organization/OraganizationOverview';
import OrganizationContractPaymentList from 'pages/private/Organization/OrganizationContractPaymentList';
import OraganizationStrategy from 'pages/private/Organization/Strategy';
import OraganizationStrategyInfo from 'pages/private/Organization/Strategy/details';
import ReportList from 'pages/private/Report';
import ConfigureQuestion from 'pages/private/Setup/ConfigureQuestion';
import QuestionAdd from 'pages/private/Setup/ConfigureQuestion/Add';
import ConfigureSection from 'pages/private/Setup/ConfigureSection';
import SectionAdd from 'pages/private/Setup/ConfigureSection/Add';
import ContractFees from 'pages/private/Setup/ContractFees';
import ContractAdd from 'pages/private/Setup/ContractFees/Add';
import DocumentTemplate from 'pages/private/Setup/DocumentTemplate';
import DocumentAdd from 'pages/private/Setup/DocumentTemplate/Add';
import ManageOrganization from 'pages/private/Setup/ManageOrganization';
import OrganizationAdd from 'pages/private/Setup/ManageOrganization/Add';
import ManageUserList from 'pages/private/Setup/ManageUser';
import UserAdd from 'pages/private/Setup/ManageUser/Add';
import MyFirm from 'pages/private/Setup/MyFirm';
import { MySubscription } from 'pages/private/Setup/MySubscription/index';
import ResourceStation from 'pages/private/Setup/ResourceStation';
import ResourceStationAdd from 'pages/private/Setup/ResourceStation/Add';
import RolesPermission from 'pages/private/Setup/RolesPermissions';
import RoleAdd from 'pages/private/Setup/RolesPermissions/Add';
import AcceptableUsePolicy from 'pages/public/ACCEPTABLEUSEPOLICY';
import DMCAPolicy from 'pages/public/DMCA';
import PrivacyPolicy from 'pages/public/PrivacyPolicy';
import {
  StripeInprocessPayment,
  StripeSucessPayment,
} from 'pages/public/StripePayment/PaymentSucess';
import TermsOfService from 'pages/public/Terms&Service';
import VersionHistory from 'pages/public/VersionHistory';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { logout } from 'store/actions/UserAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';

import {
  AttorneyPrivateRoutes,
  CommonPrivateRoutes,
  PublicRoutes,
} from '../index';

const MINUTES_CALL_15 = 900000;
const AttorneyRoutes: React.FC = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo: userDetail, sideMenu }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  const [open, setOpen] = useState<any>({
    message: '',
    show: false,
  });
  const [leadShow, setLeadShow] = useState(false);
  const [petitionsShow, setPetitionsShow] = useState(false);
  const [counter, setCounter] = useState(0);
  const [loader, setLoader] = React.useState(true);
  const [submissionPopup, setSubmissionPopup] = useState(false);

  const {
    CONTRACT,
    DASHBOARD,
    DOCUMENT,
    MYPROFILE,
    CHANGEPASSWORD,
    ROLES,
    LEADS,
    MANAGEORGANIZATION,
    MANAGEUSER,
    QUESTION,
    SECTION,
    MYFIRM,
    WINNINGSTRATEGY,
    CONTACTSUPPORT,
    PAYMENT,
    POLICY,
    PETITIONS,
    REPORTS,
    RFERESPOEND,
    DOCUMENTPREVIEW,
    DOCUMENTI129PREVIEW,
    DOCUMENTI129PREVIEWFOREXTRACTIONID,
    SOCIALSHARE,
    SUBSCRIPTIONPLAN,
    RESOURCE,
    CALENDAR,
  } = AttorneyPrivateRoutes;

  const handleClose = () => {
    sessionStorage.setItem('show_popup', 'true');
    setOpen({ message: '', show: false });
  };

  const logoutCall = () => {
    dispatch(logout());
    navigate(PublicRoutes.LOGIN);
  };

  useEffect(() => {
    if (sideMenu && !_.isEmpty(sideMenu?.sideMenuArray)) {
      sideMenu.sideMenuArray
        .find((indexval) => indexval.moduleId === 4)
        ?.moduleList.filter((menu) => {
          if (menu.screen_id === 11) {
            setLeadShow(true);
            setLoader(false);
          }
          if (menu.screen_id === 28) {
            setPetitionsShow(true);
            setLoader(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideMenu]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, MINUTES_CALL_15);

    if (counter > 0) {
      if (userDetail.role_id === 3 && userDetail.is_subscription_need) {
        getAllListData(COMMON_ENDPOINT.subscriptionPlan)
          .then((resp: any) => {
            const showPopup = sessionStorage.getItem('show_popup');
            if (
              showPopup == null &&
              resp.show_popup &&
              userDetail.customer_portal_show
            ) {
              setOpen({ message: resp.info_message, show: resp.show_popup });
            }
          })
          .catch((e: any) => {
            console.log(e);
            logoutCall();
          });
      }
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            userDetail.first_time_login ? (
              <Navigate to={MYFIRM} />
            ) : (
              <Navigate to={DASHBOARD} />
            )
          }
        />
        <Route
          path={DASHBOARD}
          element={
            <PrivateLayout
              routes={{
                path: DASHBOARD,
                show: false,
                title: 'Dashboard',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <Dashboard />
            </PrivateLayout>
          }
        />
        <Route
          path={MYPROFILE}
          element={
            <PrivateLayout
              routes={{
                path: MYPROFILE,
                show: true,
                title: 'My Profile',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <Myprofile />
            </PrivateLayout>
          }
        />
        <Route
          path={CHANGEPASSWORD}
          element={
            <PrivateLayout
              routes={{
                path: CHANGEPASSWORD,
                show: true,
                title: 'Change Password',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ChangePassword />
            </PrivateLayout>
          }
        />
        <Route
          path={CALENDAR}
          element={
            <PrivateLayout
              routes={{
                path: CALENDAR,
                show: true,
                title: 'Calendar',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <Calendar />
            </PrivateLayout>
          }
        />
        <Route
          path={PAYMENT.SECUREPAYMENT}
          element={
            <PublicLayout>
              <PaymentDetail />
            </PublicLayout>
          }
        />
        <Route path={PAYMENT.STRIPESUCCESS} element={<StripeSucessPayment />} />
        <Route
          path={PAYMENT.STRIPEINPROCESS}
          element={<StripeInprocessPayment />}
        />
        <Route path={POLICY.PRIVACYPOLICY} element={<PrivacyPolicy />} />
        <Route path={POLICY.DMCAPOLICY} element={<DMCAPolicy />} />
        <Route
          path={POLICY.ACCEPTABLEUSEPOLICY}
          element={<AcceptableUsePolicy />}
        />
        <Route path={POLICY.TERMOFSERVICES} element={<TermsOfService />} />
        <Route path={POLICY.VERSIONHISTORY} element={<VersionHistory />} />
        <Route
          path={`${LEADS.LEADDETAILSLIST}/:editId/petition`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                navigate: true,
                path: LEADS.LEADDETAILSLIST,
                show: true,
                title: 'petitions',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <LeadsToPetition />
            </PrivateLayout>
          }
        />

        <Route
          path={PAYMENT.PAYMENTTERMSANDCONDITIONS}
          element={
            <PublicLayout>
              <PaymentMethodAndTerms />
            </PublicLayout>
          }
        />
        <Route
          path={PAYMENT.CREDITDEBITCARD}
          element={
            <PublicLayout>
              <CreditDebitPayment />
            </PublicLayout>
          }
        />
        <Route
          path={PAYMENT.ACH}
          element={
            <PublicLayout>
              <AchPayment />
            </PublicLayout>
          }
        />
        <Route
          path={PAYMENT.CONTRACTVIEWER}
          element={
            <PublicLayout>
              <ContractViewer />
            </PublicLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCIATEDOVERVIEW}/:editId`}
          element={
            <PrivateLayout
              routes={{
                isDescription: 'Organization',
                navigate: true,
                path: MANAGEORGANIZATION.ORGANIZATIONLIST,
                show: true,
                title: 'Organization',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <OraganizationOverview />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONINVITED.ORGANIZATIONINVITEDOVERVIEW}/:editId`}
          element={
            <PrivateLayout
              routes={{
                isDescription: 'Organization',
                navigate: true,
                path: MANAGEORGANIZATION.ORGANIZATIONLIST,
                show: true,
                title: 'Organization',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <OraganizationOverview />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCIATEDUPLOADDOCUMENT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'docupload',
                isParamId: true,
                navigate: false,
                path: MANAGEORGANIZATION.ORGANIZATIONASSOCIATED
                  .ORGANIZATIONASSOCIATEDOVERVIEW,
                show: true,
                title: 'Upload Documents',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <UploadDocument />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONINVITED.ORGANIZATIONINVITEDUPLOADDOCUMENT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'docupload',
                isParamId: true,
                navigate: false,
                path: MANAGEORGANIZATION.ORGANIZATIONINVITED
                  .ORGANIZATIONINVITEDOVERVIEW,
                show: true,
                title: 'Upload Documents',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <UploadDocument />
            </PrivateLayout>
          }
        />

        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONINVITED.ORGANIZATIONINVITEDSTRATEGY}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'stratey',
                isParamId: true,
                navigate: true,
                path: MANAGEORGANIZATION.ORGANIZATIONINVITED
                  .ORGANIZATIONINVITEDOVERVIEW,
                show: true,
                title: 'Strategy',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <OraganizationStrategy />
            </PrivateLayout>
          }
        />
        <Route
          path={`${String(
            MANAGEORGANIZATION.ORGANIZATIONINVITED
              .ORGANIZATIONINVITEDSTRATEGYDETAIL,
          )}/:editId/:formId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'stratey',
                isParamId: true,
                navigate: true,
                path: MANAGEORGANIZATION.ORGANIZATIONINVITED
                  .ORGANIZATIONINVITEDOVERVIEW,
                show: true,
                title: 'Strategy',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <OraganizationStrategyInfo />
            </PrivateLayout>
          }
        />

        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCITEDSTRATEGY}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'stratey',
                isParamId: true,
                navigate: true,
                path: MANAGEORGANIZATION.ORGANIZATIONINVITED
                  .ORGANIZATIONINVITEDOVERVIEW,
                show: true,
                title: 'Strategy',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <OraganizationStrategy />
            </PrivateLayout>
          }
        />
        <Route
          path={`${String(
            MANAGEORGANIZATION.ORGANIZATIONASSOCIATED
              .ORGANIZATIONASSOCITEDSTRATEGYDETAIL,
          )}/:editId/:formId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'stratey',
                isParamId: true,
                navigate: true,
                path: MANAGEORGANIZATION.ORGANIZATIONINVITED
                  .ORGANIZATIONINVITEDOVERVIEW,
                show: true,
                title: 'Strategy',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <OraganizationStrategyInfo />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCIATEDCONTRACTPAYMENT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'contract',
                isParamId: true,
                navigate: false,
                path: MANAGEORGANIZATION.ORGANIZATIONASSOCIATED
                  .ORGANIZATIONASSOCIATEDOVERVIEW,
                show: true,
                title: 'Contract & Payment',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <OrganizationContractPaymentList />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONINVITED.ORGANIZATIONINVITEDCONTRACTPAYMENT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'contract',
                isParamId: true,
                navigate: false,
                path: MANAGEORGANIZATION.ORGANIZATIONINVITED
                  .ORGANIZATIONINVITEDOVERVIEW,
                show: true,
                title: 'Contract & Payment',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <OrganizationContractPaymentList />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONINVITED.ORGANIZATIONINVITEDLEADDETAILS}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'leads',
                isParamId: true,
                navigate: false,
                path: MANAGEORGANIZATION.ORGANIZATIONINVITED
                  .ORGANIZATIONINVITEDOVERVIEW,
                show: true,
                title: 'Leads',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <LeadsDetailList />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCIATEDLEADDETAILS}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'leads',
                isParamId: true,
                navigate: true,
                path: MANAGEORGANIZATION.ORGANIZATIONASSOCIATED
                  .ORGANIZATIONASSOCITEDLEAD,
                show: true,
                title: 'leads',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <LeadsDetailList />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONINVITED.ORGANIZATIONINVITEDPETITIONDETAILS}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'petitions',
                isParamId: true,
                navigate: false,
                path: MANAGEORGANIZATION.ORGANIZATIONINVITED
                  .ORGANIZATIONINVITEDPETITION,
                show: true,
                title: 'Petitions',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={'all'}>
              <PetitionDetailList />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCITEDPETITIONDETAILS}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'petitions',
                isParamId: true,
                navigate: true,
                path: PETITIONS.MYPETITIONLISTS,
                show: true,
                title: 'Petitions',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={'all'}>
              <PetitionDetailList />
            </PrivateLayout>
          }
        />

        <Route
          path={ROLES.ROLESPERMISSION}
          element={
            <PrivateLayout
              routes={{
                path: ROLES.ROLESPERMISSION,
                show: true,
                title: 'Roles & Permissions',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <RolesPermission />
            </PrivateLayout>
          }
        />
        <Route
          path={ROLES.ROLESADD}
          element={
            <PrivateLayout
              routes={{
                navigate: true,
                path: ROLES.ROLESPERMISSION,
                show: true,
                title: 'Roles & Permissions',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <RoleAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={`${ROLES.ROLESEDIT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                navigate: true,
                path: ROLES.ROLESPERMISSION,
                show: true,
                title: 'Roles & Permissions',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <RoleAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={MANAGEUSER.MANAGEUSERLIST}
          element={
            <PrivateLayout
              routes={{
                isDescription: 'manageUsers',
                path: MANAGEUSER.MANAGEUSERLIST,
                show: true,
                title: 'Manage Users',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ManageUserList />
            </PrivateLayout>
          }
        />
        <Route
          path={MANAGEUSER.USERADD}
          element={
            <PrivateLayout
              routes={{
                navigate: true,
                path: MANAGEUSER.MANAGEUSERLIST,
                show: true,
                title: 'Manage Users',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <UserAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEUSER.USEREDIT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                navigate: true,
                path: MANAGEUSER.MANAGEUSERLIST,
                show: true,
                title: 'Manage Users',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <UserAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={QUESTION.CONFIGUREQUESTION}
          element={
            <PrivateLayout
              routes={{
                isDescription: 'configureQuestions',
                path: QUESTION.CONFIGUREQUESTION,
                show: true,
                title: 'Configure Questions',
              }}
              showTools={true}
              showHeader={true}
              addEditRoute={{
                isAdd: true,
                isEdit: false,
                path: QUESTION.QUESTIONADD,
                title: 'Add New Question',
              }}
              hasComponent={'no'}>
              <ConfigureQuestion />
            </PrivateLayout>
          }
        />
        <Route
          path={QUESTION.QUESTIONADD}
          element={
            <PrivateLayout
              routes={{
                path: QUESTION.CONFIGUREQUESTION,
                show: true,
                title: 'Configure Questions',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <QuestionAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={`${QUESTION.QUESTIONEDIT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                path: QUESTION.CONFIGUREQUESTION,
                show: true,
                title: 'Configure Questions',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <QuestionAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={SECTION.CONFIGURESECTION}
          element={
            <PrivateLayout
              routes={{
                isDescription: 'configureSections',
                path: SECTION.CONFIGURESECTION,
                show: true,
                title: 'Configure Sections',
              }}
              showTools={true}
              showHeader={true}
              addEditRoute={{
                isAdd: true,
                isEdit: false,
                path: SECTION.SECTIONADD,
                title: 'Add New Section',
              }}
              hasComponent={'no'}>
              <ConfigureSection />
            </PrivateLayout>
          }
        />
        <Route
          path={SECTION.SECTIONADD}
          element={
            <PrivateLayout
              routes={{
                path: SECTION.CONFIGURESECTION,
                show: true,
                title: 'Configure Sections',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <SectionAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={`${SECTION.SECTIONEDIT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                path: SECTION.CONFIGURESECTION,
                show: true,
                title: 'Configure Sections',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <SectionAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={MANAGEORGANIZATION.ORGANIZATIONLIST}
          element={
            <PrivateLayout
              routes={{
                isDescription: 'Organization',
                path: MANAGEORGANIZATION.ORGANIZATIONLIST,
                show: false,
                title: 'Organization',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ManageOrganization />
            </PrivateLayout>
          }
        />
        <Route
          path={MANAGEORGANIZATION.ORGANIZATIONADD}
          element={
            <PrivateLayout
              routes={{
                path: MANAGEORGANIZATION.ORGANIZATIONLIST,
                show: true,
                title: 'Manage Organization',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <OrganizationAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCIATEDIT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                isParamId: true,
                navigate: true,
                path: MANAGEORGANIZATION.ORGANIZATIONASSOCIATED
                  .ORGANIZATIONASSOCIATEDOVERVIEW,
                show: true,
                title: 'Organization',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <OrganizationAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONINVITED.ORGANIZATIONEDIT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                isParamId: true,
                navigate: true,
                path: MANAGEORGANIZATION.ORGANIZATIONINVITED
                  .ORGANIZATIONINVITEDOVERVIEW,
                show: true,
                title: 'Organization',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <OrganizationAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONADD}`}
          element={
            <PrivateLayout
              routes={{
                navigate: true,
                path: MANAGEORGANIZATION.ORGANIZATIONLIST,
                show: true,
                title: 'Manage Organization',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <OrganizationAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={CONTRACT.CONTRACTFEES}
          element={
            <PrivateLayout
              routes={{
                isDescription: 'contractFees',
                path: CONTRACT.CONTRACTFEES,
                show: true,
                title: 'Contract Fees',
              }}
              showTools={true}
              showHeader={true}
              addEditRoute={{
                isAdd: true,
                isEdit: false,
                path: CONTRACT.CONTRACTADD,
                title: 'Add New Fees',
              }}
              hasComponent={'no'}>
              <ContractFees />
            </PrivateLayout>
          }
        />
        <Route
          path={CONTRACT.CONTRACTADD}
          element={
            <PrivateLayout
              routes={{
                navigate: true,
                path: CONTRACT.CONTRACTFEES,
                show: true,
                title: 'Contract Fees',
              }}
              showTools={true}
              showHeader={true}
              hasComponent={'no'}>
              <ContractAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={`${CONTRACT.CONTRACTEDIT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                navigate: true,
                path: CONTRACT.CONTRACTFEES,
                show: true,
                title: 'Contract Fees',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ContractAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={MYFIRM}
          element={
            <PrivateLayout
              routes={{
                navigate: true,
                path: MYFIRM,
                show: true,
                title: 'My Firm',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <MyFirm />
            </PrivateLayout>
          }
        />
        <Route
          path={SUBSCRIPTIONPLAN}
          element={
            userDetail.is_subscription_need ? (
              <PrivateLayout
                routes={{
                  icon: 'subscription',
                  path: SUBSCRIPTIONPLAN,
                  show: true,
                  title: 'My Subscriptions',
                }}
                showTools={false}
                showHeader={true}
                hasComponent={'no'}>
                <MySubscription />
              </PrivateLayout>
            ) : (
              <Navigate to={DASHBOARD} />
            )
          }
        />
        <Route
          path={DOCUMENT.DOCUMENTTEMPLATE}
          element={
            <PrivateLayout
              routes={{
                isDescription: 'documentTemplate',
                path: DOCUMENT.DOCUMENTTEMPLATE,
                show: true,
                title: 'Document Template',
              }}
              showTools={false}
              showHeader={true}
              addEditRoute={{
                isAdd: true,
                isEdit: false,
                path: DOCUMENT.DOCUMENTADD,
                title: 'Add New Template',
              }}
              hasComponent={'no'}>
              <DocumentTemplate />
            </PrivateLayout>
          }
        />
        <Route
          path={DOCUMENT.DOCUMENTADD}
          element={
            <PrivateLayout
              routes={{
                navigate: true,
                path: DOCUMENT.DOCUMENTTEMPLATE,
                show: true,
                title: 'Document Template',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <DocumentAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={`${DOCUMENT.DOCUMENTEDIT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                navigate: true,
                path: DOCUMENT.DOCUMENTTEMPLATE,
                show: true,
                title: 'Document Template',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <DocumentAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={RESOURCE.RESOURCESTATION}
          element={
            <PrivateLayout
              routes={{
                isDescription: 'resourceStation',
                path: RESOURCE.RESOURCESTATION,
                show: true,
                title: 'Resource Station',
              }}
              showTools={false}
              showHeader={true}
              addEditRoute={{
                isAdd: true,
                isEdit: false,
                path: RESOURCE.RESOURCESTATIONADD,
                title: 'Add Resource Station',
              }}
              hasComponent={'no'}>
              <ResourceStation />
            </PrivateLayout>
          }
        />
        <Route
          path={RESOURCE.RESOURCESTATIONADD}
          element={
            <PrivateLayout
              routes={{
                navigate: true,
                path: RESOURCE.RESOURCESTATION,
                show: true,
                title: 'Resource Station',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ResourceStationAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={`${String(RESOURCE.RESOURCESTATIONEDIT)}/:editId`}
          element={
            <PrivateLayout
              routes={{
                navigate: true,
                path: RESOURCE.RESOURCESTATION,
                show: true,
                title: 'Resource Station',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ResourceStationAdd />
            </PrivateLayout>
          }
        />
        <Route
          path={LEADS.LEADSLIST}
          element={
            loader ? (
              <div className="liberty-loader" />
            ) : leadShow ? (
              <PrivateLayout
                routes={{
                  icon: 'leads',
                  isDescription: 'leads',
                  path: LEADS.LEADSLIST,
                  show: false,
                  title: 'Leads',
                }}
                showTools={true}
                showHeader={true}
                addEditRoute={{
                  isAdd: true,
                  isEdit: false,
                  path: LEADS.LEADSADD,
                  title: 'New Lead',
                }}
                hasComponent={'no'}>
                <LeadsList />
              </PrivateLayout>
            ) : (
              <Navigate to={DASHBOARD} />
            )
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONINVITED.ORGANIZATIONINVITEDLEAD}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'leads',
                isDescription: 'Organization',
                navigate: false,
                path: MANAGEORGANIZATION.ORGANIZATIONINVITED
                  .ORGANIZATIONINVITEDOVERVIEW,
                show: true,
                title: 'leads',
              }}
              showTools={true}
              showHeader={true}
              addEditRoute={{
                isAdd: true,
                isEdit: false,
                path: LEADS.LEADSADD,
                title: 'New Lead',
              }}
              hasComponent={'no'}>
              <LeadsList />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCITEDLEAD}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'leads',
                isDescription: 'Organization',
                navigate: false,
                path: MANAGEORGANIZATION.ORGANIZATIONINVITED
                  .ORGANIZATIONINVITEDOVERVIEW,
                show: true,
                title: 'leads',
              }}
              showTools={true}
              showHeader={true}
              addEditRoute={{
                isAdd: true,
                isEdit: false,
                path: LEADS.LEADSADD,
                title: 'New Lead',
              }}
              hasComponent={'no'}>
              <LeadsList />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONINVITED.ORGANIZATIONINVITEDPETITION}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'petitions',
                isDescription: 'Organization',
                navigate: false,
                path: MANAGEORGANIZATION.ORGANIZATIONINVITED
                  .ORGANIZATIONINVITEDOVERVIEW,
                show: true,
                title: 'Petitions',
              }}
              showTools={true}
              showHeader={true}
              addEditRoute={{
                isAdd: true,
                isEdit: false,
                path: PETITIONS.PETITIONADD,
                title: 'New Petition',
              }}
              hasComponent={'petition-contact'}>
              <PetitionsList />
            </PrivateLayout>
          }
        />
        <Route
          path={`${MANAGEORGANIZATION.ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCITEDPETITION}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Organization',
                icon: 'petitions',
                isDescription: 'Organization',
                navigate: false,
                path: MANAGEORGANIZATION.ORGANIZATIONASSOCIATED
                  .ORGANIZATIONASSOCIATEDOVERVIEW,
                show: true,
                title: 'Petitions',
              }}
              showTools={true}
              showHeader={true}
              addEditRoute={{
                isAdd: true,
                isEdit: false,
                path: PETITIONS.PETITIONADD,
                title: 'New Petition',
              }}
              hasComponent={'petition-contact'}>
              <PetitionsList />
            </PrivateLayout>
          }
        />
        <Route
          path={LEADS.LEADSADD}
          element={
            loader ? (
              <div className="liberty-loader" />
            ) : leadShow ? (
              <PrivateLayout
                routes={{
                  icon: 'leads',
                  navigate: true,
                  path: LEADS.LEADSLIST,
                  show: true,
                  title: 'Leads',
                }}
                showTools={false}
                showHeader={true}
                hasComponent={'no'}>
                <LeadAdd />
              </PrivateLayout>
            ) : (
              <Navigate to={DASHBOARD} />
            )
          }
        />
        <Route
          path={`${LEADS.LEADSEDIT}/:editId`}
          element={
            loader ? (
              <div className="liberty-loader" />
            ) : leadShow ? (
              <PrivateLayout
                routes={{
                  caseTitle: 'Case Menu',
                  icon: 'caseinfo',
                  isParamId: true,
                  navigate: true,
                  path: LEADS.LEADDETAILSLIST,
                  show: true,
                  title: 'Lead Details',
                }}
                showTools={false}
                showHeader={true}
                hasComponent={'no'}>
                <LeadAdd />
              </PrivateLayout>
            ) : (
              <Navigate to={DASHBOARD} />
            )
          }
        />
        <Route
          path={`${LEADS.LEADDETAILSLIST}/:editId`}
          element={
            <PrivateLayout
              routes={{
                icon: 'leads',
                navigate: true,
                path: LEADS.LEADSLIST,
                show: true,
                title: 'Leads',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <LeadsDetailList />
            </PrivateLayout>
          }
        />
        <Route
          path={`${LEADS.UPLOADDOCUMENT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'docupload',
                isParamId: true,
                navigate: true,
                path: LEADS.LEADDETAILSLIST,
                show: true,
                title: 'Upload Documents',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <UploadDocument />
            </PrivateLayout>
          }
        />
        <Route
          path={`${LEADS.UPLOADLEADDOCUMENT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'docupload',
                isParamId: true,
                navigate: true,
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Upload Documents',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <UploadDocument />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.UPLOADPETITIONSDOCUMENT}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'docupload',
                isParamId: true,
                navigate: true,
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Upload Documents',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <UploadPetitionsDocument />
            </PrivateLayout>
          }
        />
        <Route
          path={LEADS.COMPLETEQUESTION}
          element={
            <PrivateLayout
              routes={{
                icon: 'question',
                path: LEADS.LEADDETAILSLIST,
                show: true,
                title: 'Complete Question',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <CompleteQuestion />
            </PrivateLayout>
          }
        />
        <Route
          path={`${LEADS.STRATEGY}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'stratey',
                isParamId: true,
                navigate: true,
                path: LEADS.LEADDETAILSLIST,
                show: true,
                title: 'Strategy',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <Strategy />
            </PrivateLayout>
          }
        />
        <Route
          path={`${LEADS.STRATEGYDETAIL}/:editId`}
          // path={LEADS.STRATEGYDETAIL}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'stratey',
                path: LEADS.LEADDETAILSLIST,
                show: true,
                title: 'Strategy',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <StrategyInfo />
            </PrivateLayout>
          }
        />
        <Route
          path={`${LEADS.CONTRACTPAYMENT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'contract',
                isParamId: true,
                navigate: true,
                path: LEADS.LEADDETAILSLIST,
                show: true,
                title: 'Contract & Payments',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ContractPayment />
            </PrivateLayout>
          }
        />
        <Route
          path={`${LEADS.VIEWEDITCONTRACTS}/:editId/:contractId/:userId/:contractType`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'contract',
                isParamId: true,
                navigate: true,
                path: LEADS.CONTRACTPAYMENTDETAILS,
                show: true,
                title: 'Contract & Payment',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ContractAddEdit />
            </PrivateLayout>
          }
        />
        <Route
          path={`${LEADS.CONTRACTPAYMENTDETAILS}/:editId/:contractId/:userId/:contractType`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'contract',
                isParamId: true,
                navigate: true,
                path: LEADS.LEADDETAILSLIST,
                show: true,
                title: 'Contract & Payment',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ContractPaymentDetails />
            </PrivateLayout>
          }
        />
        <Route
          path={`${LEADS.REVIEWDOCUMENT}/:editId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'question',
                // isParamId: true,
                // navigate: true,
                path: LEADS.LEADDETAILSLIST,
                show: true,
                title: 'Document Review',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ReviewDocument type={'lead'} />
            </PrivateLayout>
          }
        />
        <Route
          path={DOCUMENT.DOCUMENTPARSING}
          element={
            <PrivateLayout
              routes={{
                path: DOCUMENT.DOCUMENTPARSING,
                show: true,
                title: 'Document Parsing',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <DocumentParsing />
            </PrivateLayout>
          }
        />
        <Route
          path={DOCUMENT.DOCUMENTPARSINGVIEW}
          element={
            <PrivateLayout
              routes={{
                path: DOCUMENT.DOCUMENTPARSINGVIEW,
                show: true,
                title: 'Document Parsing',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <DocumentParsingView />
            </PrivateLayout>
          }
        />
        <Route
          path={`${WINNINGSTRATEGY}/:formId`}
          element={
            <PrivateLayout
              routes={{
                path: WINNINGSTRATEGY + '/56',
                show: true,
                title: 'Winning Strategy',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <WinningStrategy />
            </PrivateLayout>
          }
        />
        <Route
          path={CONTACTSUPPORT}
          element={
            <PrivateLayout
              routes={{
                path: CONTACTSUPPORT,
                show: true,
                title: 'Contact Support',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ContactSupport />
            </PrivateLayout>
          }
        />
        <Route
          path={PETITIONS.MYPETITIONLISTS}
          element={
            loader ? (
              <div className="liberty-loader" />
            ) : petitionsShow ? (
              <PrivateLayout
                routes={{
                  icon: 'petitions',
                  path: PETITIONS.MYPETITIONLISTS,
                  show: false,
                  title: 'Petitions',
                }}
                showTools={true}
                showHeader={true}
                addEditRoute={{
                  isAdd: true,
                  isEdit: false,
                  path: PETITIONS.PETITIONADD,
                  title: 'New Petition',
                }}
                hasComponent={'petition-contact'}>
                <PetitionsList />
              </PrivateLayout>
            ) : (
              <Navigate to={DASHBOARD} />
            )
          }
        />
        <Route
          path={PETITIONS.PETITIONADD}
          element={
            loader ? (
              <div className="liberty-loader" />
            ) : petitionsShow ? (
              <PrivateLayout
                routes={{
                  icon: 'leads',
                  navigate: true,
                  path: PETITIONS.MYPETITIONLISTS,
                  show: true,
                  title: 'Petitions',
                }}
                showTools={false}
                showHeader={true}
                hasComponent={'no'}>
                <PetitionAdd />
              </PrivateLayout>
            ) : (
              <Navigate to={DASHBOARD} />
            )
          }
        />
        <Route
          path={`${PETITIONS.PETITIONREVIEWLIST}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'question',
                // isParamId: true,
                // navigate: true,
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Document Review',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <ReviewDocument type={'petition'} />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.PIXLEX}/:petitionId/:htmlId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Brief Cover',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <PreviewBriefCover type={'petition'} />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.PETITIONEDIT}/:petitionId`}
          element={
            loader ? (
              <div className="liberty-loader" />
            ) : petitionsShow ? (
              <PrivateLayout
                routes={{
                  icon: 'leads',
                  navigate: true,
                  path: PETITIONS.MYPETITIONLISTS,
                  show: true,
                  title: 'Petitions',
                }}
                showTools={false}
                showHeader={true}
                hasComponent={'no'}
                // isPetition={true}
              >
                <PetitionAdd />
              </PrivateLayout>
            ) : (
              <Navigate to={DASHBOARD} />
            )
          }
        />
        <Route
          path={`${PETITIONS.ADDREQUESTDOCUMENT}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                isParamId: true,
                navigate: true,
                path: PETITIONS.UPLOADPETITIONSDOCUMENT,
                show: true,
                title: 'Request Additional Documents',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <RequestAdditionalDocument />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.BUILDCASE}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'question',
                isParamId: true,
                navigate: false,
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Build Case',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <PetitionBuildCase />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.BUILDCASEDEPENDENT}/:petitionId/:depUserId/:visaId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'question',
                isParamId: true,
                navigate: true,
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Build Case',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <PetitionBuildCase onlyShow="dependent" />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.BUILDCASEEDIT}/:petitionId/:templateId`}
          element={
            <PrivateLayout
              routes={{
                // caseTitle: 'Case Menu',
                icon: 'question',
                isParamId: true,
                navigate: true,
                path: PETITIONS.BUILDCASE,
                show: true,
                title: 'Build case',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <BuildCaseEdit />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.ACCEPTORREJECT}/:petitionId/:templateId/:buildId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'question',
                isParamId: true,
                navigate: true,
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Accept or Reject',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <AcceptOrReject />
            </PrivateLayout>
          }
        />
        {/* <Route
        path={`${PETITIONS.ASSEMBLE}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'assemble',
              path: PETITIONS.PETITIONLIST,
              show: true,
              title: 'Assemble',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <PetitionAssemble />
          </PrivateLayout>
        }
      /> */}
        <Route
          path={`${PETITIONS.ASSEMBLE}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'assemble',
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Assemble',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <AssembleDragDrop />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.SUBMISSIONSTATION}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'submission',
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Submission Station',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}
              setSubmissionPopup={setSubmissionPopup}>
              <SubmissionStation
                submissionPopup={submissionPopup}
                setSubmissionPopup={setSubmissionPopup}
              />
            </PrivateLayout>
          }
        />
        <Route
          path={`${String(RFERESPOEND.RFEUPLOAD)}/:petitionId`}
          element={<RfeUpload />}
        />
        <Route
          path={`${String(
            RFERESPOEND.RFERESPONDERACCEPT,
          )}/:petitionId/:documentId`}
          element={<RfeResponseAccept />}
        />
        <Route
          path={`${String(RFERESPOEND.RFERESPONDER)}/:petitionId/:documentId`}
          element={<RfeResponder />}
        />
        <Route
          path={`${String(RFERESPOEND.RFEVIEW)}/:petitionId`}
          element={<RfeView />}
        />

        {/* <Route
          path={`${PETITIONS.BRIEFCOVER}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'question',
                // isParamId: true,
                // navigate: true,
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Brief Cover Pages',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <BriefCoverPage />
            </PrivateLayout>
          }
        /> */}
        <Route
          path={`${PETITIONS.BUILDCASEEDIT}/:petitionId/:templateId/:depUserId`}
          element={
            <PrivateLayout
              routes={{
                // caseTitle: 'Case Menu',
                icon: 'question',
                isParamId: true,
                navigate: true,
                path: PETITIONS.BUILDCASE,
                show: true,
                title: 'Build case',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <BuildCaseEdit onlyShow="dependent" />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.PETITIONLIST}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                icon: 'petitions',
                navigate: true,
                path: PETITIONS.MYPETITIONLISTS,
                show: true,
                title: 'Petitions',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={'all'}>
              <PetitionDetailList />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.PETITIONCASEDETAIL}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'caseinfo',
                isParamId: true,
                navigate: true,
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Case Details',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <PetitionCaseDetail />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.STRATEGY}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'stratey',
                isParamId: 'petitionId',
                navigate: true,
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Strategy',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={false}>
              <Strategy />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.STRATEGYDETAIL}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'stratey',
                isParamId: 'petitionId',
                navigate: true,
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Strategy',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <StrategyInfo />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.COMPLETEQUESTIONS}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'question',
                isParamId: true,
                navigate: true,
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Complete Questions',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <CompleteQuestions />
            </PrivateLayout>
          }
        />
        <Route
          path={`${PETITIONS.CONTRACTPAYMENT}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'contract',
                isParamId: true,
                navigate: true,
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Contract & Payments',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}
              isPetition={true}>
              <Contractpayment />
            </PrivateLayout>
          }
        />
        <Route
          path={REPORTS.REPORTSLIST}
          element={
            <PrivateLayout
              routes={{
                path: REPORTS.REPORTSLIST,
                show: false,
                title: 'Reports',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ReportList />
            </PrivateLayout>
          }
        />
        <Route
          path={SOCIALSHARE}
          element={
            <PrivateLayout
              routes={{
                path: SOCIALSHARE,
                show: false,
                title: 'Social',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <Socialshare />
            </PrivateLayout>
          }
        />
        <Route
          path={`${DOCUMENTPREVIEW}/:docId`}
          element={
            <PrivateLayout
              routes={{
                path: DOCUMENTPREVIEW,
                show: false,
                title: 'Report',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <ParsingValuePreview />
            </PrivateLayout>
          }
        />
        <Route
          path={`${DOCUMENTI129PREVIEW}/:docId`}
          element={
            <PrivateLayout
              routes={{
                path: DOCUMENTI129PREVIEW,
                show: false,
                title: 'Report',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <I129ParsingView />
            </PrivateLayout>
          }
        />
        <Route
          path={`${DOCUMENTI129PREVIEWFOREXTRACTIONID}/:extractionId`}
          element={
            <PrivateLayout
              routes={{
                path: DOCUMENTI129PREVIEWFOREXTRACTIONID,
                show: false,
                title: 'Report',
              }}
              showTools={false}
              showHeader={true}
              hasComponent={'no'}>
              <I129ParsingView />
            </PrivateLayout>
          }
        />
        <Route
          path={`${CommonPrivateRoutes.ASSEMBLEPREVIEW}`}
          element={
            <PrivateLayout
              routes={{
                // path: DOCUMENTI129PREVIEWFOREXTRACTIONID,
                show: false,
                // title: 'Report',
              }}
              showTools={false}
              showHeader={false}
              DefaultHeaderHide={true}>
              <AssemblePreviewDownload />
            </PrivateLayout>
          }
        />
        <Route
          path={`${String(CommonPrivateRoutes.SUBMISSIONSTATION)}/:petitionId`}
          element={
            <PrivateLayout
              routes={{
                caseTitle: 'Case Menu',
                icon: 'submission',
                path: PETITIONS.PETITIONLIST,
                show: true,
                title: 'Submission Station',
              }}
              showTools={false}
              showHeader={true}
              DefaultHeaderHide={true}
              setSubmissionPopup={setSubmissionPopup}>
              <SubmissionStation
                submissionPopup={submissionPopup}
                setSubmissionPopup={setSubmissionPopup}
              />
            </PrivateLayout>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <InfoMessage
        showManageUser={true}
        open={open.show}
        close={handleClose}
        infoMessage={open.message}
      />
    </>
  );
};

export default AttorneyRoutes;
