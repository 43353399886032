import { Box, Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import React from 'react';

type PetitionerInfoIterFace = {
  petitions: any;
};

export const PetitionerInfo: React.FC<PetitionerInfoIterFace> = ({
  petitions,
}) => {
  const { petitionData } = petitions;
  return (
    <Grid item md={12} className="addbox">
      <Box component="form" className="lead-box" noValidate autoComplete="off">
        <Grid container>
          <Grid item md={6}>
            <Typography component={'div'} className="custom-field u-pl-1">
              <Typography
                component={'div'}
                className="custom-label"
                sx={{ margin: '.5rem 0 0 0 !important' }}>
                Petitioner
              </Typography>
              <Typography component={'div'} className="custom-field-row u-pr-1">
                <CustomInput
                  placeHolder={''}
                  name={''}
                  value={petitionData?.petitioner_user_name}
                  className={'custom-input'}
                  disabled={true}
                />
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography component={'div'} className="custom-field u-pl-1">
              <Typography
                component={'div'}
                className="custom-label"
                sx={{ margin: '.5rem 0 0 0 !important' }}>
                Beneficiary
              </Typography>
              <Typography component={'div'} className="custom-field-row u-pr-1">
                <CustomInput
                  placeHolder={''}
                  name={''}
                  value={petitionData?.benificiery_name}
                  className={'custom-input'}
                  disabled={true}
                />
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography component={'div'} className="custom-field u-pl-1">
              <Typography
                component={'div'}
                className="custom-label"
                sx={{ margin: '.5rem 0 0 0 !important' }}>
                Visa Type
              </Typography>
              <Typography component={'div'} className="custom-field-row u-pr-1">
                <CustomInput
                  placeHolder={''}
                  name={''}
                  value={petitionData?.visa_type}
                  className={'custom-input'}
                  disabled={true}
                />
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default PetitionerInfo;
