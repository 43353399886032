import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import Pagination from 'components/Pagination';
import { instantState } from 'hooks';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import SwipeableViews from 'react-swipeable-views';
import { toast } from 'react-toastify';
import {
  deleteData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import useStyles from '../../../PrivateStyle';
import TabViews from './TabViews';

const a11yProps = (index: number) => {
  return {
    'aria-controls': `full-width-tabpanel-${index}`,
    id: `full-width-tab-${index}`,
  };
};

type SectionProps = {
  sData?: any;
  setSectionData?: any;
  setConfirmOpen?: any;
  open?: any;
  editId?: string;
  cancelForm?: any;
  orderRewind?: (src: any) => void;
  submitOrder?: (param1: any, param2: string, param3: string) => void;
  beneficiaryList?: any;
  petitionerList?: any;
  strategyList?: any;
  dependentList?: any;
  setOrderChangeList?: any;
};

const SectionMapping: FC<SectionProps> = ({
  sData,
  setSectionData,
  setConfirmOpen,
  open,
  editId,
  cancelForm,
  orderRewind,
  beneficiaryList,
  petitionerList,
  strategyList,
  dependentList,
  submitOrder,
  setOrderChangeList,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);

  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [sectionParams, setSectionParams] = useState<any>({
    attorney: false,
    id: '',
    majorsection: '',
    name: '',
    order: '',
    sectiontype: '',
    skip: 0,
    sortby: '',
    status: true,
    take: 50,
  });
  const [sections, setSections] = useState<any>({
    data: [],
    total: 0,
  });
  const handleOpen = () => {
    setSectionParams((prev) => ({
      ...prev,
      sectiontype:
        tabValue === 0
          ? 'beneficiary'
          : tabValue === 1
          ? 'petitioner'
          : tabValue === 2
          ? 'strategy'
          : 'dependent',
      skip: 0,
      take: 50,
    }));
    setIsOpen(true);
    getAPIData();
  };
  const handleClose = () => {
    setIsOpen(false);
    setSectionParams((pre) => ({
      ...pre,
      name: '',
      sectiontype: '',
      skip: 0,
      take: 50,
    }));
    setSections((pre) => ({
      ...pre,
      data: [],
      total: 0,
    }));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleTabChangeIndex = (index: number) => {
    setTabValue(index);
  };

  const handleBeneficiaryDragEnd = (
    result: DropResult,
    provided?: ResponderProvided,
  ) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    // const temp = [...beneficiaryList];
    // const d = temp[result.destination!.index];
    // temp[result.destination!.index] = temp[result.source.index];
    // temp[result.source.index] = d;
    const temp = [...beneficiaryList];
    const obj = temp[result.source.index];
    temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, obj);

    setOrderChangeList((pre) => ({ ...pre, beneficiaryList: temp }));
    submitOrder(temp, 'beneficiary', 'toasterView');
  };

  const handlePetitionerDragEnd = (
    result: DropResult,
    provided?: ResponderProvided,
  ) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    // const temp = [...petitionerList];
    // const d = temp[result.destination!.index];
    // temp[result.destination!.index] = temp[result.source.index];
    // temp[result.source.index] = d;
    const temp = [...petitionerList];
    const obj = temp[result.source.index];
    temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, obj);

    setOrderChangeList((pre) => ({ ...pre, petitionerList: temp }));
    submitOrder(temp, 'petitioner', 'toasterView');
  };

  const handleStrategyDragEnd = (
    result: DropResult,
    provided?: ResponderProvided,
  ) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    // const temp = [...strategyList];
    // const d = temp[result.destination!.index];
    // temp[result.destination!.index] = temp[result.source.index];
    // temp[result.source.index] = d;
    const temp = [...strategyList];
    const obj = temp[result.source.index];
    temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, obj);
    setOrderChangeList((pre) => ({ ...pre, strategyList: temp }));
    submitOrder(temp, 'strategy', 'toasterView');
  };

  const handleDependentDragEnd = (
    result: DropResult,
    provided?: ResponderProvided,
  ) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    // const temp = [...dependentList];
    // const d = temp[result.destination!.index];
    // temp[result.destination!.index] = temp[result.source.index];
    // temp[result.source.index] = d;
    const temp = [...dependentList];
    const obj = temp[result.source.index];
    temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, obj);
    setOrderChangeList((pre) => ({ ...pre, dependentList: temp }));
    submitOrder(temp, 'dependent', 'toasterView');
  };

  const onUpdateOrRemoveQuestion = (remove = false, section?: any) => {
    let selectedData;

    if (remove) {
      selectedData =
        open.type === 'beneficiary'
          ? beneficiaryList[open.index]
          : open.type === 'petitioner'
          ? petitionerList[open.index]
          : open.type === 'strategy'
          ? strategyList[open.index]
          : dependentList[open.index];
    } else {
      selectedData = section;
    }

    const mappedSection = {
      majorSection: selectedData.major_section,
      sectionId: selectedData.id,
      sectionType: selectedData.section_type,
    };
    setLoader(true);
    const casePlanIndex = sData.findIndex(
      (val) => val.section_type === mappedSection.sectionType,
    );

    if (remove && _.isEmpty(section)) {
      deleteData(editId, `${SETUP_ENDPOINT.VisaType}/section`, mappedSection)
        .then(async (resp: any) => {
          if (remove) {
            // sData.splice(open.index, 1);
            setSectionData(
              sData.filter((data, i) => Number(data.id) !== Number(open.id)),
            );
            const inputVal = await instantState(setSectionData);
            orderRewind(inputVal);
            setConfirmOpen({
              id: null,
              index: null,
              show: false,
              type: '',
            });
            toast.success('Section deleted successfully', alertOptions);
            setLoader(false);
          }
        })
        .catch((e) => {
          setLoader(false);
        });
    } else {
      updateData(editId, mappedSection, `${SETUP_ENDPOINT.VisaType}/section`)
        .then((resp: any) => {
          sData.push({
            id: section.id,
            major_section: section.major_section,
            section_name: section.section_name,
            section_type: section.section_type,
          });
          handleClose();
          orderRewind(sData);
          if (mappedSection.sectionType === 'caseplan') {
            if (casePlanIndex !== -1) {
              sData.splice(casePlanIndex, 1);
            }
          }
          setLoader(false);
          toast.success(
            `Section added for ${String(section.section_type)}`,
            alertOptions,
          );
          setConfirmOpen({
            id: null,
            index: null,
            show: false,
            type: '',
          });
        })
        .catch((e) => {
          toast.error(
            'Unable to add the section. Please try again.',
            alertOptions,
          );
          setLoader(false);
        });
    }
  };

  const checkQuestionExisting = (id: string) => {
    if (sData.length) {
      return sData.some((val) => val.id === id);
    }
  };

  const selectTheSection = (section: any) => {
    if (section && section.id) {
      const checkDublicate = checkQuestionExisting(section.id);
      if (!checkDublicate) {
        onUpdateOrRemoveQuestion(false, section);
        handleClose();
      } else {
        toast.error('This section is already mapped.', alertOptions);
      }
    }
  };

  const getAPIData = () => {
    getAllListData(
      `${SETUP_ENDPOINT.ConfigSection}?id=${String(
        sectionParams.id,
      )}&skip=${String(sectionParams.skip)}&take=${String(
        sectionParams.take,
      )}&name=${String(sectionParams.name)}&sectiontype=${String(
        sectionParams.sectiontype,
      )}&majorsection=${String(sectionParams.majorsection)}&status=${String(
        sectionParams.status,
      )}&order=${String(sectionParams.order)}&sortby=${String(
        sectionParams.sortby,
      )}&attorney=${String(sectionParams.attorney)}`,
    )
      .then(async (qResp: any) => {
        const { data, total } = qResp ? qResp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setSections((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          setSections(qResp);
        }
        setTableLoader(false);
        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setSections);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      getAPIData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionParams]);

  const getMoreList = () => {
    if (isOpen && !tableLoader) {
      setGetMoreLoader(true);
      setSectionParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
        take: 50,
      }));
    }
  };
  return (
    <Grid item md={10} sm={12}>
      <Typography component={'div'} className="d-flex-a d-flex-sb">
        <Typography component={'div'} className="w-title black-white-text">
          Section Mapping
        </Typography>
        <Button type="button" className="n-add-btn mt-2" onClick={handleOpen}>
          Add Section
        </Button>
      </Typography>

      <Typography
        component={'div'}
        sx={{ backgroundColor: 'unset !important' }}>
        <Box className="mapTab">
          <AppBar position="static">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example">
              <Tab label="Beneficiary" {...a11yProps(0)} />
              <Tab label="Petitioner" {...a11yProps(1)} />
              <Tab label="Strategy" {...a11yProps(2)} />
              <Tab label="Dependent" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={tabValue}
            onChangeIndex={handleTabChangeIndex}>
            <TabViews
              tabValue={0}
              direction={theme.direction}
              type="beneficiary"
              handleDragEnd={handleBeneficiaryDragEnd}
              typeOfList={beneficiaryList}
              setConfirmOpen={setConfirmOpen}
            />
            <TabViews
              tabValue={1}
              direction={theme.direction}
              type="petitioner"
              handleDragEnd={handlePetitionerDragEnd}
              typeOfList={petitionerList}
              setConfirmOpen={setConfirmOpen}
            />
            <TabViews
              tabValue={2}
              direction={theme.direction}
              type="strategy"
              handleDragEnd={handleStrategyDragEnd}
              typeOfList={strategyList}
              setConfirmOpen={setConfirmOpen}
            />
            <TabViews
              tabValue={3}
              direction={theme.direction}
              type="dependent"
              handleDragEnd={handleDependentDragEnd}
              typeOfList={dependentList}
              setConfirmOpen={setConfirmOpen}
            />
          </SwipeableViews>
        </Box>
      </Typography>

      <ConfirmDialog
        title="DELETE"
        floatingBtnShow={true}
        open={open.show}
        onConfirm={(act) =>
          act === 'yes'
            ? onUpdateOrRemoveQuestion(true, {})
            : setConfirmOpen({
                id: null,
                index: null,
                show: false,
                type: '',
              })
        }
        autoCloseDisable={true}>
        Are you sure do you want to delete?
      </ConfirmDialog>

      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className={classes.newmodal}>
          <Grid container md={12}>
            <Grid md={2} style={{ alignSelf: 'center' }}>
              <Typography className="f-20 font-bold modal-title mr-1">
                Add Section
              </Typography>
            </Grid>
            <Grid md={9}>
              <Typography
                component={'div'}
                className="d-flex-jae full-screen-searc mb-2 mt-2">
                <Typography
                  component={'div'}
                  className="d-flex-a"
                  sx={{
                    // backgroundColor: '#ffffff',
                    borderRadius: '4px',
                  }}>
                  <Typography
                    className="d-flex-a"
                    component={'div'}
                    sx={{
                      backgroundColor: '#84898c',
                      borderRadius: '4px',
                      boxSizing: 'unset',
                      marginRight: '5.5rem',
                      width: '205px',
                    }}>
                    <CustomInput
                      placeHolder={'Search by name'}
                      className={'custom-input'}
                      size="small"
                      value={sectionParams.name}
                      inputProps={{
                        endAdornment:
                          sectionParams.question !== '' ? (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={(e) => {
                                  setTableLoader(true);

                                  setSectionParams((prev) => ({
                                    ...prev,
                                    name: '',
                                  }));
                                }}>
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                      }}
                      onChange={(e) => {
                        setTableLoader(true);

                        setSectionParams((prev) => ({
                          ...prev,
                          name: e.target.value,
                          skip: 0,
                          take: 50,
                        }));
                      }}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      backgroundColor: '#84898c !important',
                      borderRadius: '4px',
                      marginRight: '.5rem',
                      paddingLeft: 'unset !important',
                      width: '205px',
                    }}>
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={[
                        { label: 'All', value: '' },
                        { label: 'Beneficiary', value: 'beneficiary' },
                        { label: 'Petitioner', value: 'petitioner' },
                        { label: 'Case Plan', value: 'caseplan' },
                        { label: 'Strategy', value: 'strategy' },
                        { label: 'Contract', value: 'contract' },
                        { label: 'Dependent', value: 'dependent' },
                        { label: 'Winning Strategy', value: 'winning' },
                        {
                          label: 'Labor Condition Application',
                          value: 'lca',
                        },
                      ]}
                      value={
                        tabValue === 0
                          ? 'beneficiary'
                          : tabValue === 1
                          ? 'petitioner'
                          : tabValue === 2
                          ? 'strategy'
                          : tabValue === 3
                          ? 'dependent'
                          : ''
                      }
                      onChange={(e) => {
                        setTableLoader(true);
                        setSectionParams((prev) => ({
                          ...prev,
                          sectiontype: e.target.value,
                          skip: 0,
                          take: 50,
                        }));
                      }}
                      disabled={true}
                      placeHolder={'Select'}
                      className={'custom-input'}
                    />
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
            <Grid md={1}>
              <Typography component={'div'} className="d-flex-jae mt-1">
                <CancelIcon
                  sx={{
                    cursor: 'pointer',
                    fill: 'gray',
                    height: '44px',
                    width: '44px',
                  }}
                  onClick={() => handleClose()}
                />
              </Typography>
            </Grid>
          </Grid>
          {loader ? (
            <div className="liberty-loader" />
          ) : (
            <DialogContent
              id="scrollParent"
              style={{ height: '85%', padding: '0px' }}>
              <InfiniteScroll
                dataLength={
                  sections && sections.data && sections.data.length
                    ? sections.data.length
                    : 0
                }
                style={{
                  overflow: 'unset',
                }}
                next={getMoreList && getMoreList}
                hasMore={hasMoreResults}
                scrollableTarget={'scrollParent'}
                loader={
                  getMoreLoader && (
                    <div
                      style={{
                        backgroundColor: '#ffffff',
                        textAlign: 'center',
                        width: '100%',
                      }}>
                      <CircularProgress
                        size={30}
                        sx={{
                          color: '#002c69',
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                      />
                    </div>
                  )
                }
                endMessage>
                <table className="lead-table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">NAME</th>
                      <th scope="col">DESCRIPTION</th>
                      <th scope="col">TYPE</th>
                      <th scope="col">STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableLoader ? (
                      <tr>
                        <td colSpan={8}>
                          <div
                            style={{
                              backgroundColor: '#ffffff',
                              textAlign: 'center',
                              width: '100%',
                            }}>
                            <CircularProgress
                              size={30}
                              sx={{
                                color: '#002c69',
                                marginBottom: 10,
                                marginTop: 10,
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {sections && sections.data && sections.data.length > 0
                          ? sections.data.map((section, index) => (
                              <tr
                                key={index}
                                onClick={() => selectTheSection(section)}>
                                <td>
                                  <div
                                    className="hideshow"
                                    style={{
                                      width: '255px',
                                      wordBreak: 'break-all',
                                    }}>
                                    <a
                                      className="multiline"
                                      style={{ color: '#1093df' }}>
                                      {section.section_name
                                        ? section.section_name
                                        : '-'}
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  {' '}
                                  <div
                                    className="hideshow"
                                    style={{
                                      width: '255px',
                                      wordBreak: 'break-all',
                                    }}>
                                    {section.section_description
                                      ? section.section_description
                                      : '-'}
                                  </div>
                                </td>
                                <td style={{ textTransform: 'capitalize' }}>
                                  {section.section_type
                                    ? section.section_type
                                    : '-'}
                                </td>
                                <td>
                                  {section.section_status
                                    ? 'Active'
                                    : 'Inactive'}
                                </td>
                              </tr>
                            ))
                          : !tableLoader && (
                              <tr className={'text-center'}>
                                <th scope="row" colSpan={4}>
                                  No Record(s) Found.
                                </th>
                              </tr>
                            )}
                      </>
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            </DialogContent>
          )}
        </Box>
      </Modal>
    </Grid>
  );
};

export default SectionMapping;
