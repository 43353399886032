import {
  I_129_EXTRACTION_LENGTH,
  LEAD_SAVE,
  ORGANIZATION_DATA,
  PETITIONER_SAVE,
  RESET_PETITIONER_SAVE,
  RESET_SAVE,
} from 'store/types/LeadsOrPetitionerTypes';

const INITIAL_STATE: any = { leadData: null, roleId: null };
const INITIAL_PETITIONER_STATE: any = { petitions: {} };
const I_129_ETRACTION_LENGTH = [];
const INITIAL_ORGANIZATION_STATE = {};

export interface Action {
  type: string;
  payload?: any;
}

export const leadSaveReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case LEAD_SAVE:
      return {
        ...state,
        leadData: action.payload.leadData,
        roleId: action.payload.roleId,
      };
    case RESET_SAVE:
      return {
        ...state,
        leadData: null,
        roleId: null,
      };
    default:
      return state;
  }
};
export const organizationSaveReducer = (
  state = INITIAL_ORGANIZATION_STATE,
  action: Action,
) => {
  switch (action.type) {
    case ORGANIZATION_DATA:
      return {
        ...state,
        organization: action.payload,
      };
    default:
      return state;
  }
};
export const petitionerSaveReducer = (
  state = INITIAL_PETITIONER_STATE,
  action: Action,
) => {
  switch (action.type) {
    case PETITIONER_SAVE:
      return {
        ...state,
        petitions: action.payload,
      };
    case RESET_PETITIONER_SAVE:
      return {
        ...state,
        petitions: null,
      };
    default:
      return state;
  }
};

export const i_129ExtractionLengthReducer = (
  state = I_129_ETRACTION_LENGTH,
  action: Action,
) => {
  switch (action.type) {
    case I_129_EXTRACTION_LENGTH:
      return action.payload;
    default:
      return state;
  }
};
