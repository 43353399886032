export const Capitalize = (str: string) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

export const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  });
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
};

export const AfflicateRole = [
  { label: 'Petitioner', value: 2 },
  { label: 'Attorney', value: 3 },
  { label: 'Beneficiary', value: 4 },
  { label: 'View Only', value: 5 },
];
