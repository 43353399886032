import { Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { MASTER_ENDPOINT } from 'utils/Constant';

import AttorneyDashboard from './AttorneyDashboard';
import { BeneDashboard } from './BeneficiaryDashboard';
import InviteConfirmation from './InviteConfirmation';

const from = moment().startOf('year').format('YYYY-MM-DD');
const to = moment().endOf('year').format('YYYY-MM-DD');

export const Dashboard: React.FC<any> = () => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const [loader, setLoader] = useState(
    [1, 5].includes(userDetail.role_id) ? false : true,
  );
  const [dashboardCounts, setDashboardCounts] = useState({});
  const [contractPayments, setContractPayments] = useState<any>({});
  const [myCases, setMyCases] = useState<any>({});
  const [pendingReviews, setPendingReviews] = useState<any>({});
  const [documentReviews, setDocumentReviews] = useState<any>({});

  const [beneficiaryData, setBeneficiaryData] = useState([]);

  const [attorneyName, setAttorneyName] = useState('');
  const [dashboardKey, setDashboardKey] = useState({
    attorneyId: [3].includes(userDetail.role_id) ? userDetail.id : '',
    fromDate: from,
    toDate: to,
  });
  const [commentsWidget, setCommentsWidget] = useState({
    data: [],
    total: 0,
  });
  const [commentsParams, setCommentsParams] = useState({
    order: 'DESC',
    skip: 0,
    take: 10,
  });
  const [reportFilterKey, setReportFilterKey] = useState({
    amount: '',
    createdby: userDetail.id,
    firmId: '',
    fromDate: from,
    order: 'ASC',
    paymentMode: '',
    skip: 0,
    sortby: '',
    status: 'pending',
    take: 50,
    toDate: to,
    transactionId: '',
  });

  const [casesKey, setCasesKey] = useState({
    attorneyId: [3].includes(userDetail.role_id) ? userDetail.id : '',
    fromDate: from,
    skip: 0,
    take: 50,
    toDate: to,
  });

  const [pendingReviewParams, setPendingReviewParams] = useState({
    skip: 0,
    take: 50,
  });
  const [documentReviewParams, setDocumentReviewParams] = useState({
    skip: 0,
    take: 50,
  });

  const getAttorneyDashboard = useCallback(() => {
    if (dashboardKey.fromDate && dashboardKey.toDate) {
      getAllListData(
        `${MASTER_ENDPOINT.Dashboard}?attorneyId=${String(
          dashboardKey.attorneyId,
        )}&fromDate=${String(dashboardKey.fromDate)}&toDate=${String(
          dashboardKey.toDate,
        )}`,
      )
        .then((resp: any) => {
          setDashboardCounts(resp.getDashboardCounts);
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
  }, [dashboardKey.attorneyId, dashboardKey.fromDate, dashboardKey.toDate]);

  const getReportList = useCallback(() => {
    if (
      reportFilterKey.fromDate &&
      reportFilterKey.toDate &&
      reportFilterKey.take
    ) {
      getAllListData(
        `${MASTER_ENDPOINT.ContractReports}?skip=${String(
          reportFilterKey.skip,
        )}&take=${String(reportFilterKey.take)}&sortby=${String(
          reportFilterKey.sortby,
        )}&order=${String(reportFilterKey.order)}&firmId=${String(
          reportFilterKey.firmId,
        )}&status=${String(reportFilterKey.status)}&fromDate=${String(
          reportFilterKey.fromDate,
        )}&toDate=${String(reportFilterKey.toDate)}&createdby=${String(
          reportFilterKey.createdby,
        )}&transactionId=${String(
          reportFilterKey.transactionId,
        )}&amount=${String(reportFilterKey.amount)}&paymentMode=${String(
          reportFilterKey.paymentMode,
        )}`,
      )
        .then((resp: any) => {
          const mergeData = !_.isEmpty(contractPayments)
            ? [...contractPayments.data, ...resp.data]
            : resp.data;
          setContractPayments({
            count: resp.count,
            data: mergeData,
          });
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportFilterKey.fromDate,
    reportFilterKey.skip,
    reportFilterKey.take,
    reportFilterKey.toDate,
  ]);

  const getCases = useCallback(() => {
    if (casesKey.fromDate && casesKey.toDate && casesKey.take) {
      getAllListData(
        `${MASTER_ENDPOINT.Cases}?attorneyId=${String(
          casesKey.attorneyId,
        )}&skip=${String(casesKey.skip)}&take=${String(
          casesKey.take,
        )}&fromDate=${String(casesKey.fromDate)}&toDate=${String(
          casesKey.toDate,
        )}`,
      )
        .then((resp: any) => {
          const mergeData = !_.isEmpty(myCases)
            ? [...myCases.data, ...resp.data]
            : resp.data;
          setMyCases({
            data: mergeData,
            total: resp.total,
          });
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    casesKey.attorneyId,
    casesKey.fromDate,
    casesKey.skip,
    casesKey.take,
    casesKey.toDate,
  ]);

  const getDocumentPendingForReview = useCallback(() => {
    if (pendingReviewParams.take) {
      getAllListData(
        `${String(MASTER_ENDPOINT.DocumentPendingForReview)}?skip=${String(
          pendingReviewParams.skip,
        )}&take=${String(pendingReviewParams.take)}`,
      )
        .then((resp: any) => {
          const mergeData = !_.isEmpty(pendingReviews)
            ? [...pendingReviews.data, ...resp.data]
            : resp.data;
          setPendingReviews({
            data: mergeData,
            total: resp.total,
          });
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingReviewParams.skip, pendingReviewParams.take]);

  const getDocumentForReviewSent = useCallback(() => {
    if (documentReviewParams.take) {
      getAllListData(
        `${String(MASTER_ENDPOINT.DocumentReviewSent)}?skip=${String(
          documentReviewParams.skip,
        )}&take=${String(documentReviewParams.take)}`,
      )
        .then((resp: any) => {
          const mergeData = !_.isEmpty(documentReviews)
            ? [...documentReviews.data, ...resp.data]
            : resp.data;
          setDocumentReviews({
            data: mergeData,
            total: resp.total,
          });
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentReviewParams.skip, documentReviewParams.take]);

  const getCommentsForReviewSent = useCallback(() => {
    if (commentsParams.take) {
      getAllListData(
        `${String(MASTER_ENDPOINT.Comments)}?skip=${String(
          commentsParams.skip,
        )}&take=${String(commentsParams.take)}&order=${String(
          commentsParams.order,
        )}`,
      )
        .then((resp: any) => {
          const mergeData = !_.isEmpty(commentsWidget)
            ? [...commentsWidget.data, ...resp.data]
            : resp.data;
          setCommentsWidget({
            data: mergeData,
            total: resp.total,
          });
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsParams]);
  const filterOnChangeResetState = () => {
    setMyCases({
      data: [],
      total: 0,
    });
    setDashboardCounts({});
    setContractPayments({
      count: 0,
      data: [],
    });
  };

  useEffect(() => {
    if (userDetail.role_id === 3) {
      setAttorneyName(
        `${String(userDetail.first_name)} ${String(userDetail.last_name)}`,
      );
      // getValue();
    }
    if (userDetail.role_id === 4) {
      getAllListData(`${MASTER_ENDPOINT.Dashboard}/beneficiary`)
        .then((resp: any) => {
          setBeneficiaryData(
            resp && resp.data && resp.data.length ? resp.data : [],
          );
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail]);

  useEffect(() => {
    if ([2, 3].includes(userDetail.role_id)) {
      getAttorneyDashboard();
      setLoader(false);
    }
  }, [getAttorneyDashboard, userDetail.role_id]);

  useEffect(() => {
    if ([3].includes(userDetail.role_id)) {
      // Paymengt report only in attorney
      getReportList();
      setLoader(false);
    }
  }, [getReportList, userDetail.role_id]);

  useEffect(() => {
    if ([2, 3].includes(userDetail.role_id)) {
      getCases();
      setLoader(false);
    }
  }, [getCases, userDetail.role_id]);

  useEffect(() => {
    if ([2, 3].includes(userDetail.role_id)) {
      getDocumentPendingForReview();
      setLoader(false);
    }
  }, [getDocumentPendingForReview, userDetail.role_id]);

  useEffect(() => {
    if ([2, 3].includes(userDetail.role_id)) {
      getCommentsForReviewSent();
      setLoader(false);
    }
  }, [getCommentsForReviewSent, userDetail.role_id]);

  useEffect(() => {
    if (userDetail.role_id === 3) {
      getDocumentForReviewSent();
      setLoader(false);
    }
  }, [getDocumentForReviewSent, userDetail.role_id]);

  return (
    <Typography component={'div'}>
      <InviteConfirmation userDetail={userDetail} />
      {loader ? (
        <div className="liberty-loader" />
      ) : userDetail.role_id === 4 ? (
        <BeneDashboard
          beneficiaries={beneficiaryData}
          pendingReviews={pendingReviews}
          pendingReviewParams={pendingReviewParams}
          setPendingReviewParams={setPendingReviewParams}
          commentsWidget={commentsWidget}
          commentsParams={commentsParams}
          setCommentsParams={setCommentsParams}
        />
      ) : (
        <AttorneyDashboard
          dashboardKey={dashboardKey}
          setDashboardKey={setDashboardKey}
          attorneyName={attorneyName}
          setAttorneyName={setAttorneyName}
          counts={dashboardCounts}
          contract={contractPayments}
          reportFilterKey={reportFilterKey}
          setReportFilterKey={setReportFilterKey}
          cases={myCases}
          caseKey={casesKey}
          setCasesKey={setCasesKey}
          filterOnChangeResetState={filterOnChangeResetState}
          pendingReviews={pendingReviews}
          pendingReviewParams={pendingReviewParams}
          setPendingReviewParams={setPendingReviewParams}
          documentReviews={documentReviews}
          documentReviewParams={documentReviewParams}
          setDocumentReviewParams={setDocumentReviewParams}
          commentsWidget={commentsWidget}
          commentsParams={commentsParams}
          setCommentsParams={setCommentsParams}
        />
      )}
    </Typography>
  );
};
