/* eslint-disable @typescript-eslint/restrict-plus-operands */
// import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import TreeItem, { treeItemClasses, TreeItemProps } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
// import { Tooltip } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { alpha, styled } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { TransitionProps } from '@mui/material/transitions';
// web.cjs is required for IE11 support
import { animated, useSpring } from '@react-spring/web';
import * as React from 'react';

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ height: 14, width: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ height: 14, width: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ height: 14, width: 14 }}
      {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    marginLeft: 15,
    paddingLeft: 18,
  },
}));

type Props = {
  attachment: Array<any>;
  exhibit: Array<any>;
  buiildCover: Array<any>;
  brief: Array<any>;
  treeOrderRecall: null | number;
};
export const AssembleTreeView: React.FC<Props> = (props: Props) => {
  const { attachment, brief, exhibit, buiildCover, treeOrderRecall } = props;

  const [attachmentExpanded, attachmentSetExpanded] = React.useState<string[]>(
    [],
  );
  const [coverExpanded, setCoverExapnded] = React.useState<string[]>([]);
  const [briefExpanded, setBriefExpanded] = React.useState<string[]>([]);
  const [exhibitExpanded, exhibitSetExpanded] = React.useState<string[]>([]);

  const handleToggleAttachment = (
    event: React.SyntheticEvent,
    nodeIds: string[],
  ) => {
    attachmentSetExpanded(nodeIds);
  };
  const handleToggleCover = (
    event: React.SyntheticEvent,
    nodeIds: string[],
  ) => {
    setCoverExapnded(nodeIds);
  };
  const handleToggleBrief = (
    event: React.SyntheticEvent,
    nodeIds: string[],
  ) => {
    setBriefExpanded(nodeIds);
  };
  const handleToggleExhibit = (
    event: React.SyntheticEvent,
    nodeIds: string[],
  ) => {
    exhibitSetExpanded(nodeIds);
  };

  React.useEffect(() => {
    if (treeOrderRecall) {
      attachmentSetExpanded([
        '1',
        ...attachment
          .filter((ch: any) => ch.document_type === 'header')
          .map((r: { nodeId: number }) => String(r.nodeId + 1)),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeOrderRecall]);

  React.useEffect(() => {
    if (treeOrderRecall) {
      setCoverExapnded(['1', ...(buiildCover.length ? ['2'] : ['2'])]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeOrderRecall]);

  React.useEffect(() => {
    if (treeOrderRecall) {
      setBriefExpanded([
        '1',
        ...(brief && brief.length
          ? brief.map((r: { nodeId: number }) => String(r.nodeId + 1))
          : ['2']),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeOrderRecall]);

  React.useEffect(() => {
    if (treeOrderRecall) {
      exhibitSetExpanded([
        '1',
        ...exhibit
          .filter((ch: any) => ch.document_type === 'header')
          .map((r: { nodeId: number }) => String(r.nodeId + 1)),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeOrderRecall]);
  return (
    <>
      <TreeView
        aria-label="customized"
        expanded={coverExpanded}
        defaultExpanded={[]}
        onNodeToggle={handleToggleCover}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        sx={{ flexGrow: 1, padding: '12px 0px' }}>
        <StyledTreeItem
          nodeId="1"
          label="Cover"
          sx={{
            '.Mui-expanded': {
              backgroundColor: '#f4f9ff !important',
              padding: '7px',
            },
            '.MuiTreeItem-label': {
              fontFamily: 'Arial-Bold !important',
            },
          }}>
          {buiildCover && Array.isArray(buiildCover) && buiildCover.length ? (
            buiildCover.map((e, index) => {
              return (
                <StyledTreeItem
                  sx={{
                    '.Mui-expanded': {
                      backgroundColor: 'unset !important',
                    },
                    '.MuiTreeItem-label': {
                      fontFamily: 'Arial-Regular !important',
                      padding: '5px 0px',
                    },
                  }}
                  nodeId={String(2)}
                  label={e?.original_name}
                  key={index + 1}
                />
              );
            })
          ) : (
            <StyledTreeItem
              nodeId={String(2)}
              label={'   No Record(s) Found.'}
              sx={{
                '.Mui-expanded': {
                  backgroundColor: 'unset !important',
                },
                '.MuiTreeItem-label': {
                  color: 'red',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '14px',
                  padding: ' 7px 0px',
                },
              }}
            />
          )}
        </StyledTreeItem>
      </TreeView>

      <TreeView
        aria-label="customized"
        expanded={attachmentExpanded}
        defaultExpanded={[]}
        onNodeToggle={handleToggleAttachment}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        sx={{ flexGrow: 1, padding: '12px 0px' }}>
        <StyledTreeItem
          nodeId="1"
          label="Attachment"
          sx={{
            '.Mui-expanded': {
              backgroundColor: '#f4f9ff !important',
              padding: '7px',
            },
            '.MuiTreeItem-label': {
              fontFamily: 'Arial-Bold !important',
            },
          }}>
          {attachment && Array.isArray(attachment) && attachment.length ? (
            attachment.map((e, index) => {
              return (
                <StyledTreeItem
                  sx={{
                    '.Mui-expanded': {
                      backgroundColor: 'unset !important',
                    },
                    '.MuiTreeItem-label': {
                      fontFamily: 'Arial-Regular !important',
                      padding: '5px 0px',
                    },
                  }}
                  nodeId={String(e.nodeId + 1)}
                  label={`${String(e.roman_name ? e.roman_name : '')} ${String(
                    e.original_name ? e.original_name : '',
                  )}`}
                  key={index + 1}>
                  {e.document_type === 'header' ? (
                    e.documents_list &&
                    Array.isArray(e.documents_list) &&
                    e.documents_list.length ? (
                      e.documents_list.map((sub, subInd) => (
                        <StyledTreeItem
                          sx={{
                            '.MuiTreeItem-label': {
                              color: '#000000b5',
                              fontFamily: 'Arial-Regular !important',
                              fontSize: '14px',
                              padding: '7px 0px',
                            },
                          }}
                          key={subInd}
                          nodeId={String(sub.nodeId + 1)}
                          label={`${String(
                            sub.roman_name ? sub.roman_name : '',
                          )} ${String(
                            sub.original_name ? sub.original_name : '',
                          )}`}
                        />
                      ))
                    ) : (
                      <StyledTreeItem
                        nodeId={String(e.nodeId + 2)}
                        label={'   No Record(s) Found.'}
                        sx={{
                          '.Mui-expanded': {
                            backgroundColor: 'unset !important',
                          },
                          '.MuiTreeItem-label': {
                            color: 'red',
                            fontFamily: 'Arial-Regular !important',
                            fontSize: '14px',
                            padding: ' 7px 0px',
                          },
                        }}
                      />
                    )
                  ) : (
                    ''
                  )}
                </StyledTreeItem>
              );
            })
          ) : (
            <StyledTreeItem
              nodeId={String(2)}
              label={'   No Record(s) Found.'}
              sx={{
                '.Mui-expanded': {
                  backgroundColor: 'unset !important',
                },
                '.MuiTreeItem-label': {
                  color: 'red',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '14px',
                  padding: ' 7px 0px',
                },
              }}
            />
          )}
        </StyledTreeItem>
      </TreeView>

      <TreeView
        aria-label="customized"
        expanded={briefExpanded}
        defaultExpanded={[]}
        onNodeToggle={handleToggleBrief}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        sx={{ flexGrow: 1, padding: '12px 0px' }}>
        <StyledTreeItem
          nodeId="1"
          label="Brief"
          sx={{
            '.Mui-expanded': {
              backgroundColor: '#f4f9ff !important',
              padding: '7px',
            },
            '.MuiTreeItem-label': {
              fontFamily: 'Arial-Bold !important',
            },
          }}>
          {brief && Array.isArray(brief) && brief.length ? (
            brief.map((e, index) => {
              return (
                // <StyledTreeItem
                //   sx={{
                //     '.Mui-expanded': {
                //       backgroundColor: 'unset !important',
                //     },
                //     '.MuiTreeItem-label': {
                // fontFamily: 'Arial-Regular !important',
                // padding: '5px 0px',
                //     },
                //   }}
                //   nodeId={String(2)}
                //   label={e.template_name}
                //   key={index + 1}
                // />
                <div
                  key={index + 1}
                  className="d-flex align-center d-flex-sb"
                  style={{
                    padding: '0 8px',
                  }}>
                  <div className=" d-flex align-center">
                    <CloseSquare
                      sx={{
                        marginRight: '4px',
                        opacity: 0.3,
                      }}
                    />

                    <div
                      style={{
                        fontFamily: 'Arial-Regular !important',
                        padding: '10px 0px',
                      }}>
                      {e.template_name}
                    </div>
                  </div>

                  {/* <div>
                    <Tooltip title="Delete" placement="top">
                      <DeleteForeverSharpIcon
                        style={{ cursor: 'pointer', fill: 'red' }}
                        onClick={() =>
                          setDeleteDialog((pre) => ({
                            ...pre,
                            id: e.id,
                            show: true,
                          }))
                        }
                      />
                    </Tooltip>
                  </div> */}
                </div>
              );
            })
          ) : (
            <StyledTreeItem
              nodeId={String(2)}
              label={'   No Record(s) Found.'}
              sx={{
                '.Mui-expanded': {
                  backgroundColor: 'unset !important',
                },
                '.MuiTreeItem-label': {
                  color: 'red',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '14px',
                  padding: ' 7px 0px',
                },
              }}
            />
          )}
        </StyledTreeItem>
      </TreeView>

      <TreeView
        aria-label="customized"
        expanded={exhibitExpanded}
        defaultExpanded={[]}
        onNodeToggle={handleToggleExhibit}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        sx={{ flexGrow: 1, padding: '12px 0px' }}>
        <StyledTreeItem
          nodeId="1"
          label="Exhibit"
          sx={{
            '.Mui-expanded': {
              backgroundColor: '#f4f9ff !important',
              padding: '7px',
            },
            '.MuiTreeItem-label': {
              fontFamily: 'Arial-Bold !important',
            },
          }}>
          {exhibit && Array.isArray(exhibit) && exhibit.length ? (
            exhibit.map((e, index) => {
              return (
                <StyledTreeItem
                  nodeId={String(e.nodeId + 1)}
                  label={`${String(e.roman_name ? e.roman_name : '')} ${String(
                    e.original_name ? e.original_name : '',
                  )}`}
                  key={index + 1}
                  sx={{
                    '.Mui-expanded': {
                      backgroundColor: 'unset !important',
                    },
                    '.MuiTreeItem-label': {
                      fontFamily: 'Arial-Regular !important',
                      padding: '5px 0px',
                    },
                  }}>
                  {e.document_type === 'header' ? (
                    Array.isArray(e.documents_list) &&
                    e.documents_list.length ? (
                      e.documents_list.map((sub, subInd) => (
                        <StyledTreeItem
                          key={subInd}
                          nodeId={String(sub.nodeId + 1)}
                          label={`${String(
                            sub.roman_name ? sub.roman_name : '',
                          )} ${String(
                            sub.original_name ? sub.original_name : '',
                          )}`}
                          sx={{
                            '.MuiTreeItem-label': {
                              color: '#000000b5',
                              fontFamily: 'Arial-Regular !important',
                              fontSize: '14px',
                              padding: '7px 0px',
                            },
                          }}
                        />
                      ))
                    ) : (
                      <StyledTreeItem
                        nodeId={String(e.nodeId + 2)}
                        label={'   No Record(s) Found.'}
                        sx={{
                          '.Mui-expanded': {
                            backgroundColor: 'unset !important',
                          },
                          '.MuiTreeItem-label': {
                            color: 'red',
                            fontFamily: 'Arial-Regular !important',
                            fontSize: '14px',
                            padding: ' 7px 0px',
                          },
                        }}
                      />
                    )
                  ) : (
                    ''
                  )}
                </StyledTreeItem>
              );
            })
          ) : (
            <StyledTreeItem
              nodeId={String(2)}
              label={'   No Record(s) Found.'}
              sx={{
                '.Mui-expanded': {
                  backgroundColor: 'unset !important',
                },
                '.MuiTreeItem-label': {
                  color: 'red',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '14px',
                  padding: ' 7px 0px',
                },
              }}
            />
          )}
        </StyledTreeItem>
      </TreeView>
    </>
  );
};
