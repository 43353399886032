import { Box, Grid, Typography } from '@mui/material';
// import ArrowLeft from 'assets/images/arrow-left.svg';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const ReviewDocument: React.FC<any> = ({ type }) => {
  // const navigate: any = useNavigate();
  const location: any = useLocation();

  return (
    <Grid container>
      <Grid item md={12} sm={12} className="pl-0">
        <Typography component={'div'}>
          <Typography
            component={'div'}
            className="uadd-title-iframe black-white-text d-flex-a"
            sx={{ marginTop: 'unset !important' }}>
            {/* <Typography className="d-flex-a">
              <Typography
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(-1);
                }}>
                <img className="edit mr-2" src={ArrowLeft} alt="edit" />
              </Typography>
            </Typography> */}
            Document Review
          </Typography>
          <Box
            component="form"
            className="u-add-in-iframe"
            noValidate
            autoComplete="off">
            <Grid container>
              <Grid item md={12} sm={12} sx={{ minHeight: '80vh' }}>
                {location.state?.review.reviewDoc && (
                  <iframe
                    src={location.state?.review.token}
                    allowFullScreen
                    width={'100%'}
                    height={'100%'}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReviewDocument;
