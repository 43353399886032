// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Typography } from '@mui/material';
import Logo from 'assets/images/liberty-logoheader.png';
import Failure from 'assets/images/payment-failure.png';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SubscriptionFailure: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('attorneySubscription');
  }, []);

  return (
    <div>
      <Typography
        className=" black-white-text p-f pt-2"
        sx={{
          backgroundColor: '#000',
          height: '84px',
          position: 'sticky',
          top: '0',
        }}>
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <div className="innerBox payment-public">
        <img
          src={Failure}
          alt="Success"
          style={{ marginBottom: '30px', marginTop: '60px' }}
        />
        <Typography className="suc-title">Payment Failed</Typography>
        <Typography className="suc-dis">
          Your payment was not successful, please try again{' '}
        </Typography>{' '}
        <Typography className="suc-dis">
          If you would like to request for any Support in the meantime, please
          contact us at{' '}
          <a href={`mailto:${String(process.env.REACT_APP_CONTACT_MAIL)}`}>
            <span style={{ color: 'rgba(25, 82, 167, 1)' }}>
              {String(process.env.REACT_APP_CONTACT_MAIL)}
            </span>
          </a>
        </Typography>
        <Button className="success-btn-new" onClick={() => navigate('/login')}>
          Go to Login
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionFailure;
