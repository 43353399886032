import { yupResolver } from '@hookform/resolvers/yup';
// import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
// import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { InputText } from 'components/Forms/InputText';
import { UseFocus, useMountEffect } from 'hooks';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData } from 'services/CommonServices';
import { logout } from 'store/actions/UserAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { changePasswordSchema } from 'utils/ValidatorSchema';

export type ResetSubmitForm = {
  newPassword: string;
  confirmPassword: string;
  password: string;
};

import useStyles from '../../PrivateStyle';

export const ChangePassword: React.FC<any> = () => {
  const [inputFocus, setInputFocus] = UseFocus();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [loader, setLoader] = useState(false);
  const {
    control: passwordControl,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<ResetSubmitForm>({
    defaultValues: {
      confirmPassword: '',
      newPassword: '',
      password: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(changePasswordSchema),
  });

  const submitHandler = ({
    newPassword,
    confirmPassword,
    password,
  }: ResetSubmitForm) => {
    setLoader(true);
    const payload = {
      confirmPassword: confirmPassword,
      currentPassword: password,
      newPassword: newPassword,
    };

    addNewData(payload, MASTER_ENDPOINT.Changepassword)
      .then((resp: any) => {
        toast.success('Password has been updated successfully.', alertOptions);
        reset();
        dispatch(logout());
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  useMountEffect(setInputFocus);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12}>
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <form
            className="formheight"
            onSubmit={handleSubmit(submitHandler)}
            noValidate
            autoComplete="off">
            <Typography component={'div'} className={classes.contactPbox}>
              <Box component="form" className="w-450" autoComplete="off">
                <Grid container>
                  <Grid item md={12}>
                    <Typography component={'div'}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Current Password{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={passwordControl}
                          defaultValue=""
                          name="password"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <InputText
                                isFormSubmittedOnce={!!formState.submitCount}
                                inputRefObj={inputFocus}
                                errors={errors.password?.message}
                                name={name}
                                isRefreshValue={true}
                                value={value}
                                onChangeText={(e) => {
                                  const vals = e.replace(/\s/g, '');
                                  return onChange(vals);
                                }}
                                isSecureTextEntry={true}
                                isEditable={false}
                                type={'password'}
                                className="custom-input"
                                placeholder="Current Password"
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        New Password <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Controller
                        control={passwordControl}
                        defaultValue=""
                        name="newPassword"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors.newPassword?.message}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(e) => {
                                const vals = e.replace(/\s/g, '');
                                return onChange(vals);
                              }}
                              isSecureTextEntry={true}
                              isEditable={false}
                              type={'password'}
                              className="custom-input"
                              placeholder="Password"
                            />
                          );
                        }}
                      />
                    </Typography>

                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Confirm Password <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Controller
                        control={passwordControl}
                        defaultValue=""
                        name="confirmPassword"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors.confirmPassword?.message}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(e) => {
                                const vals = e.replace(/\s/g, '');
                                return onChange(vals);
                              }}
                              isSecureTextEntry={true}
                              isEditable={false}
                              type={'password'}
                              className="custom-input"
                              placeholder="Confirm Password"
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography>
                      <Typography
                        component={'div'}
                        className="custom-field d-flex-a">
                        <input
                          className={classes.cinput}
                          type="checkbox"
                          // disabled
                          checked={watch('newPassword').length >= 8}
                        />
                        <Typography className={classes.ctext}>
                          Length should be minimum 8 characters
                        </Typography>
                      </Typography>
                      <Typography
                        component={'div'}
                        className="custom-field d-flex-a">
                        <input
                          className={classes.cinput}
                          type="checkbox"
                          // disabled
                          checked={/[A-Z]+/.test(watch('newPassword'))}
                        />
                        <Typography className={classes.ctext}>
                          Use minimum one upper case letter
                        </Typography>
                      </Typography>
                      <Typography
                        component={'div'}
                        className="custom-field d-flex-a">
                        <input
                          className={classes.cinput}
                          type="checkbox"
                          // disabled
                          checked={/[*@!$#%&()^~|{}]+/.test(
                            watch('newPassword'),
                          )}
                        />
                        <Typography className={classes.ctext}>
                          Use minimum one special character (! @ # $ % ^ & * ~
                          |)
                        </Typography>
                      </Typography>
                      <Typography
                        component={'div'}
                        className="custom-field d-flex-a">
                        <input
                          className={classes.cinput}
                          type="checkbox"
                          // disabled
                          checked={/[0-9]+/.test(watch('newPassword'))}
                        />
                        <Typography className={classes.ctext}>
                          Use minimum one number (0-9)
                        </Typography>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Typography>
            <Grid container>
              <Grid item md={12}>
                <Typography component={'div'} className="d-flex-ja my-2">
                  <Button
                    className="cancel-btn mr-2"
                    onClick={() =>
                      navigate(`/${String(userDetail?.userRoleName)}/dashboard`)
                    }>
                    <Typography className="o-box" />
                    Cancel
                  </Button>
                  <Button className="add-btn" type="submit" disabled={loader}>
                    Save
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        )}
      </Grid>
    </Grid>
  );
};
