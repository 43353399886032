/* eslint-disable @typescript-eslint/restrict-template-expressions */
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomCheckBox from 'components/CustomCheckBox/customCheckBox';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
// import Pagination from 'components/Pagination';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
// import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import useStyles from '../../../PrivateStyle';

type LeadOneProps = {
  control;
  errors;
  setValue;
  getValues;
  setError;
  watch;
  handleSubmit;
  cancelForm;
  onSubmitForm: (src: any) => void;
  fields?: any;
  fieldAppend?: any;
  fieldRemove?: any;
  organizations?: any;
  editId?: string;
  setDeleteDialog: (src: any) => void;
  beneficiaryUpdate: (index: number, obj: any) => any;
  formOneIsValid: any;
  leadData: any;
  affiliateFields?: any;
  affiliateFieldAppend?: any;
  affiliateFieldRemove?: any;
  affiliateUpdate?: any;
  firmData?: any;
  petitionerContactFields?: any;
  petitionerContactFieldAppend?: any;
  petitionerContactFieldRemove?: any;
  petitionerContactUpdate?: any;
};

const BeneficiaryAndPetitionerForm: FC<LeadOneProps> = ({
  control,
  errors,
  setValue,
  getValues,
  setError,
  watch,
  handleSubmit,
  cancelForm,
  onSubmitForm,
  fields,
  fieldAppend,
  fieldRemove,
  organizations,
  editId,
  setDeleteDialog,
  beneficiaryUpdate,
  formOneIsValid,
  leadData,
  affiliateFields,
  affiliateFieldAppend,
  affiliateFieldRemove,
  affiliateUpdate,

  petitionerContactFields,
  petitionerContactFieldAppend,
  petitionerContactFieldRemove,
  petitionerContactUpdate,
  firmData,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [petitioners, setPetitioners] = useState<any>([]);
  const [contactPerson, setContactPerson] = useState<any>({});
  const [leadParams, setLeadParams] = useState<any>({
    emailId: '',
    name: '',
    order: 'ASC',
    skip: 0,
    sortby: 'first_name',
    take: 50,
  });
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [errorType, setErrorType] = useState<any>(false);
  const [afParams, setAfParams] = useState<any>({
    name: '',
    order: 'ASC',
    skip: 0,
    sortby: 'first_name',
    take: 50,
  });
  const [orderDirection, setOrderDirection] = useState<any>({
    created_at: 'asc',
    email_id: 'asc',
    first_name: 'asc',
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isAfOpen, setIsAfOpen] = useState(false);
  const handleLeadsOpen = () => setIsOpen(true);
  const handleLeadsClose = () => {
    setLeadParams((prev) => ({
      ...prev,
      emailId: '',
      name: '',
      order: 'ASC',
      skip: 0,
      sortby: 'first_name',
      take: 50,
    }));
    setOrderDirection((prev) => ({
      ...prev,
      created_at: 'asc',
      email_id: 'asc',
      first_name: 'asc',
    }));
    setIsOpen(false);
  };
  const handleAffiliatessOpen = () => setIsAfOpen(true);
  const handleAffiliatesClose = () => {
    setAfParams((prev) => ({
      ...prev,
      name: '',
      skip: 0,
      take: 50,
    }));
    setIsAfOpen(false);
  };
  const handleSortRequest = (sortby: string) => {
    setOrderDirection((prev) => ({
      ...prev,
      [sortby]: prev[sortby] === 'asc' ? 'desc' : 'asc',
    }));
    setLeadParams((prev) => ({
      ...prev,
      order: orderDirection[sortby] === 'asc' ? 'DESC' : 'ASC',
      skip: 0,
      sortby: sortby,
      take: 50,
    }));
  };
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [availableLeads, setAvailableLeads] = useState<any>({});
  const [availableAffiliates, setAvailableAffiliates] = useState<any>({});
  const [petitionerEmailDuplicate, setPetitionerEmailDuplicate] =
    useState<boolean>(false);
  const [petitionerEmailDuplicateAnother, setPetitionerEmailDuplicateAnother] =
    useState<boolean>(false);
  const [resentInvite, setResentInvite] = useState(false);

  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
  });
  const debouncedParams = useDebounce(leadParams, 500);

  const inviteResend = async () => {
    if (
      petitioners.some(
        (e) => e.already_invite_sent && e.email_id === contactPerson.email_id,
      )
    ) {
      const data = {
        FirmName: firmData.firm_name,
        email: contactPerson.email_id,
        mailSend: true,
        module: 3,
        subject: 'Inviting you to join with  us',
      };
      await addNewData(data, SETUP_ENDPOINT.InviteResend)
        .then((resp: any) => {
          toast.success('Invite Sent', alertOptions);
        })
        .catch((e) => {});
    } else {
      setResentInvite(true);
    }
  };
  const resentContactPerson = async () => {
    const data = {
      FirmName: firmData.firm_name,
      email: contactPerson.email_id,
      mailSend: true,
      module: 3,
      subject: 'Inviting you to join with  us',
    };
    await addNewData(data, SETUP_ENDPOINT.InviteResend)
      .then((resp: any) => {
        toast.success('Invite Sent', alertOptions);
        setResentInvite(false);
      })
      .catch((e) => {});
  };
  const selectedLeadAffiliateDetails = (lead: any, type: string) => {
    if (lead && lead.id) {
      const id = editId ? '' : lead.id;
      if (type === 'leads') {
        if (fields && fields.some((el) => el?.userId === lead.id)) {
          toast.dismiss();
          toast.error('User is Already Exists.', alertOptions);
          return;
        }
        fieldAppend({
          beneficiaryEmail: lead.email_id,
          beneficiaryFirstName: lead.first_name,
          beneficiaryLastName: lead.last_name,
          beneficiaryMobileCode: lead.country_source_code
            ? lead.country_source_code
            : 'us',
          beneficiaryMobileNumber: lead.contact_mobile
            ? String(lead.contact_mobile).indexOf('+') !== -1
              ? lead.contact_mobile
              : lead.country_code_mobile
              ? String(lead.country_code_mobile)
                  .concat(' ')
                  .concat(lead.contact_mobile)
              : '+1 '.concat(lead.contact_mobile)
            : '',
          checkEmailValidate: '',
          disable: true,
          id: id,
          ids: id,
          leadUserId: '',
          loginCheck:
            lead.conatct_status && lead.conatct_status !== 'pending'
              ? true
              : false,
          mobileCountryCode: lead.country_code_mobile,
          mobileSocCode: lead.country_source_code,
          sendEmailDisable:
            lead.conatct_status && lead.conatct_status !== 'pending'
              ? true
              : false,
          sendMail:
            lead.conatct_status && lead.conatct_status !== 'pending'
              ? true
              : false,
          status: '',
          type: 'existing',
          userId: lead.id,
        });
        handleLeadsClose();
      } else {
        if (
          affiliateFields &&
          affiliateFields.some((el) => el?.userId === lead.id)
        ) {
          toast.dismiss();
          toast.error('User is Already Exists.', alertOptions);
          return;
        }
        affiliateFieldAppend({
          affilateEmail: lead.email_id,
          affilateFirstName: lead.first_name,
          affilateLastName: lead.last_name,
          affilateMobileNumber: lead.contact_mobile
            ? String(lead.contact_mobile).indexOf('+') !== -1
              ? lead.contact_mobile
              : lead.country_code_mobile
              ? String(lead.country_code_mobile)
                  .concat(' ')
                  .concat(lead.contact_mobile)
              : '+1 '.concat(lead.contact_mobile)
            : '',
          affilateRoleId: '',
          id: id,
          ids: id,
          mobileCountryCode: lead.country_code_mobile,
          mobileSocCode: lead.country_source_code,
          sendEmailDisable:
            lead.conatct_status && lead.conatct_status !== 'pending'
              ? true
              : false,
          sendMail:
            lead.conatct_status && lead.conatct_status !== 'pending'
              ? true
              : false,
          type: 'existing',
          userId: lead.id,
        });
        handleAffiliatesClose();
      }
    }
  };

  const getPetitionerDetails = (
    attorneyId: string,
    associatedCheck: boolean,
  ) => {
    if (!_.isNull(attorneyId)) {
      if (_.isUndefined(attorneyId)) {
        return;
      }
      resetFields();
      const check = getAssociatedOrNot(attorneyId, associatedCheck);
      if (check) {
        const findId = organizations.find(
          (orgData) =>
            orgData?.id === attorneyId && orgData?.status === associatedCheck,
        )?.ids;

        getAllListData(
          `${SETUP_ENDPOINT.SelectMenu}/petitioner/${String(attorneyId)}`,
        ).then((contactResp: any) => {
          setValue('petitionerId', Number(findId));
          setPetitioners(contactResp);

          if (searchParams.get('orgId') && searchParams.get('orgId') !== '') {
            const cp = contactResp.find((person) => person.is_signatory);
            if (!_.isEmpty(cp)) {
              setContactPerson(cp);
              setValue('petitionerContactId', cp.id);
            }
          } else {
            setContactPerson(
              contactResp.find((person) =>
                person.id === watch('petitionerContactId')
                  ? watch('petitionerContactId')
                  : null,
              ),
            );
          }
        });
        resetFields();
      } else if (check === false) {
        const findId = organizations.find(
          (orgData) =>
            orgData.id === attorneyId && orgData.status === associatedCheck,
        )?.ids;
        getAllListData(
          `${SETUP_ENDPOINT.SelectMenu}/invite/${String(attorneyId)}`,
        ).then((contactResp: any) => {
          setValue('petitionerId', Number(findId));
          setPetitioners(contactResp);
          setContactPerson(
            contactResp.find((person) =>
              person.id === watch('petitionerContactId')
                ? watch('petitionerContactId')
                : null,
            ),
          );
        });
        resetFields();
      }
    } else {
      setValue('petitionerId', null);
      resetFields();
    }
  };

  const getAssociatedOrNot = (checkId: string, associatedCheck: boolean) => {
    if (organizations.length) {
      const associate = organizations.find(
        (val) =>
          Number(val.id) === Number(checkId) && val.status === associatedCheck,
      );

      const petitionerAssociated =
        watch('orgType') === 'new' || watch('orgType') === 'sameas'
          ? false
          : associate?.status
          ? associate?.status
          : false;
      setValue('petitionerAssociated', petitionerAssociated);
      return associate?.status;
    }
    return null;
  };

  const resetFields = () => {
    setValue('orgName', '');
    setValue('contactPersonName', '');
    setValue('contactEmail', '');
    setValue('contactLastName', '');
    setValue('sendMail', false);
    setValue('contactMobileNumber', '');
    setValue('contactMobileCountryCode', '');
    setValue('contactMobileSocCode', '');
    // setValue('petitionerContactId', null);
  };

  const onApprove = (action: string) => {
    if (action === 'yes') {
      setConfirmOpen((pre) => ({
        ...pre,
        show: false,
      }));
      setPetitionerEmailDuplicate(false);
    }
  };
  const checkPetitionEmail = async (email: string) => {
    const checkEmail = email.match(
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9+_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{1,15})$/,
    );
    setPetitionerEmailDuplicate(false);
    if (checkEmail) {
      try {
        await getAllListData(COMMON_ENDPOINT.EmailCheck(email));
        setPetitionerEmailDuplicate(false);
        setPetitionerEmailDuplicateAnother(false);
      } catch (error) {
        if (error === 'Email Already Exists') {
          setPetitionerEmailDuplicate(true);
          setConfirmOpen((pre) => ({
            ...pre,
            show: true,
          }));
        } else if (error === 'Email Already Exists With Another Account') {
          setPetitionerEmailDuplicateAnother(true);
          setError('contactEmail', {
            message: 'Email Already Exists With Another Account',
            type: 'custom',
          });
        }
      }
    }
  };

  const deleteBeneficiary = (index, item, type) => {
    if (editId && item && item.ids) {
      setDeleteDialog((pre) => ({
        ...pre,
        deleteId: item,
        deleteType: type,
        index,
        show: true,
      }));
    } else {
      type === 'affiliate'
        ? affiliateFieldRemove(index)
        : type === 'beneficiary'
        ? fieldRemove(index)
        : petitionerContactFieldRemove(index);
    }
  };
  const saveBeneficiary = (index, item, type) => {
    item =
      getValues(
        type === 'affiliate'
          ? 'affilateDetails'
          : type === 'beneficiary'
          ? 'beneficiaryDetails'
          : 'petitionerContactDetails',
      ) &&
      getValues(
        type === 'affiliate'
          ? 'affilateDetails'
          : type === 'beneficiary'
          ? 'beneficiaryDetails'
          : 'petitionerContactDetails',
      ).length
        ? getValues(
            type === 'affiliate'
              ? 'affilateDetails'
              : type === 'beneficiary'
              ? 'beneficiaryDetails'
              : 'petitionerContactDetails',
          )[index]
        : null;

    if (type === 'beneficiary') {
      if (
        item &&
        item.beneficiaryFirstName &&
        (!item.beneficiaryEmail ||
          (item.beneficiaryEmail &&
            item.beneficiaryEmail.match(
              // eslint-disable-next-line no-useless-escape
              /^([a-zA-Z0-9+_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{1,15})$/,
            )))
      ) {
        if (editId && item.ids) {
          const beneficiaryDetail = leadData?.beneficiaryDetails?.find(
            (e) => e.user_id === item.ids,
          );

          const bodyObj = {
            ...item,
            beneficiaryMobileNumber: item.beneficiaryMobileNumber
              ? String(item.beneficiaryMobileNumber).split(/(?<=^\S+)\s/)[1]
              : '',
            id: item.ids,
          };
          if (beneficiaryDetail?.email_id) {
            // if (item.beneficiaryEmail === '') {
            //   toast.error(
            //     "If it is existing user then you can edit the email and also can't remove the email.If it is not an existing user then you can remove and edit email",
            //   );
            // } else {
            updateData(
              '',
              bodyObj,
              COMMON_ENDPOINT.LeadUnderBeneficiaryEdit(editId, item.ids),
            ).then(() => {
              beneficiaryUpdate(index, {
                ...item,
                beneficiaryMobileNumber: item.beneficiaryMobileNumber,
                // beneficiaryMobileNumber: item.beneficiaryMobileNumber
                //   ? item.mobileCountryCode
                //     ? String(item.mobileCountryCode).concat(
                //         item.beneficiaryMobileNumber,
                //       )
                //     : ''
                //   : '',
                disable: true,
              });
            });
            // }
          } else {
            updateData(
              '',
              bodyObj,
              COMMON_ENDPOINT.LeadUnderBeneficiaryEdit(editId, item.ids),
            ).then(() => {
              beneficiaryUpdate(index, {
                ...item,
                beneficiaryMobileNumber: item.beneficiaryMobileNumber,
                // beneficiaryMobileNumber: item.beneficiaryMobileNumber
                // ? item.mobileCountryCode
                //   ? String(item.mobileCountryCode).concat(
                //       item.beneficiaryMobileNumber,
                //     )
                //   : ''
                // : '',
                disable: true,
              });
            });
          }
        } else {
          const bodyObj = {
            ...item,
            beneficiaryMobileNumber: item.beneficiaryMobileNumber
              ? String(item.beneficiaryMobileNumber).split(/(?<=^\S+)\s/)[1]
              : '',
            id: item.ids,
          };
          addNewData(
            bodyObj,
            COMMON_ENDPOINT.LeadUnderBeneficiaryAdd(editId),
          ).then((data) => {
            const modifyObj = {
              ...bodyObj,
              beneficiaryMobileNumber: item.beneficiaryMobileNumber,
              ids: data.data.id,
              leadUserId: data.data.lead_user_id,
              status: data.data.petiton_started,
              type: 'existing',
            };
            beneficiaryUpdate(index, {
              ...modifyObj,
              disable: true,
            });
          });
        }
      } else {
        if (item && item.beneficiaryFirstName === '') {
          toast.error('Beneficiary first name is required', alertOptions);
        }
      }
    }
    if (type === 'affiliate') {
      if (
        item &&
        item.affilateFirstName &&
        item.affilateRoleId &&
        (!item.affilateEmail ||
          (item.affilateEmail &&
            item.affilateEmail.match(
              // eslint-disable-next-line no-useless-escape
              /^([a-zA-Z0-9+_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{1,15})$/,
            )))
      ) {
        if (editId && item.ids) {
          const bodyObj = {
            ...item,
            affilateMobileNumber: item.affilateMobileNumber
              ? String(item.affilateMobileNumber).split(/(?<=^\S+)\s/)[1]
              : '',
            id: item.ids,
          };
          updateData(
            '',
            bodyObj,
            COMMON_ENDPOINT.LeadUnderAffiliateEdit(editId, item.ids),
          ).then(() => {
            affiliateUpdate(index, {
              ...item,
              affilateMobileNumber: item.affilateMobileNumber,
              disable: true,
            });
          });
        } else {
          const bodyObj = {
            ...item,
            affilateMobileNumber: item.affilateMobileNumber
              ? String(item.affilateMobileNumber).split(/(?<=^\S+)\s/)[1]
              : '',
            id: item.ids,
          };
          addNewData(
            bodyObj,
            COMMON_ENDPOINT.LeadUnderAffiliateAdd(editId),
          ).then((data) => {
            const modifyObj = {
              ...bodyObj,
              affilateMobileNumber: item.affilateMobileNumber,
              // affilateMobileNumber: data.data.contact_mobile
              //   ? data.data.country_code_mobile
              //     ? String(data.data.country_code_mobile).concat(
              //         data.data.contact_mobile,
              //       )
              //     : ''
              //   : '',
              ids: data.data.id,
              leadUserId: data.data.lead_user_id,
              status: data.data.petiton_started,
              type: 'existing',
            };
            affiliateUpdate(index, {
              ...modifyObj,
              disable: true,
            });
          });
        }
      } else {
        if (item && item.affilateFirstName === '') {
          toast.error('Affiliate first name is required', alertOptions);
        } else if (item && item.affilateRoleId === '') {
          toast.error('Affiliate Role is required', alertOptions);
        }
      }
    }
    if (type === 'petitioner') {
      if (
        item &&
        item.petitionerFirstName &&
        (!item.petitionerEmail ||
          (item.petitionerEmail &&
            item.petitionerEmail.match(
              // eslint-disable-next-line no-useless-escape
              /^([a-zA-Z0-9+_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{1,15})$/,
            )))
      ) {
        if (editId && item.ids) {
          const bodyObj = {
            ...item,
            id: item.ids,
            petitionerMobileNumber: item.petitionerMobileNumber
              ? String(item.petitionerMobileNumber).split(/(?<=^\S+)\s/)[1]
              : '',
          };
          updateData(
            '',
            bodyObj,
            COMMON_ENDPOINT.LeadUnderAdditionalPetitionerEdit(editId, item.ids),
          ).then(() => {
            toast.success('Updated successfully');
            petitionerContactUpdate(index, {
              ...item,
              disable: true,
              petitionerMobileNumber: item.petitionerMobileNumber,
              // petitionerMobileNumber: item.petitionerMobileNumber
              //   ? item.contact_country_code
              //     ? String(item.contact_country_code).concat(
              //         item.petitionerMobileNumber,
              //       )
              //     : ''
              //   : '',
            });
          });
        } else {
          const bodyObj = {
            ...item,
            id: item.ids,
            petitionerMobileNumber: item.petitionerMobileNumber
              ? String(item.petitionerMobileNumber).split(/(?<=^\S+)\s/)[1]
              : '',
          };
          addNewData(
            bodyObj,
            COMMON_ENDPOINT.LeadUnderAddittionalPetitionerAdd(editId),
          ).then((data) => {
            toast.success('Added successfully');
            const modifyObj = {
              ...bodyObj,
              ids: data.id,
              petitionerMobileNumber: item.petitionerMobileNumber,
              // petitionerMobileNumber: data.contact_mobile_number
              //   ? data.contact_country_code
              //     ? data.contact_country_code.concat(data.contact_mobile_number)
              //     : ''
              //   : '',
            };
            petitionerContactUpdate(index, {
              ...modifyObj,
              disable: true,
            });
          });
        }
      } else {
        if (item && item.petitionerFirstName === '') {
          toast.error('First name is required', alertOptions);
        }
      }
    }
  };

  const CheckDuplicate = (
    currentIndex: number,
    email: string,
    type: string,
  ) => {
    const details = getValues(
      type === 'affiliate'
        ? 'affilateDetails'
        : type === 'beneficiary'
        ? 'beneficiaryDetails'
        : 'petitionerContactDetails',
    );

    const findDuplicate =
      details && details.length
        ? details.some((e, index) => {
            const eEmail =
              type === 'affiliate'
                ? e.affilateEmail
                : type === 'beneficiary'
                ? e.beneficiaryEmail
                : e.petitionerEmail;

            return (
              eEmail &&
              email &&
              currentIndex !== index &&
              eEmail.toLocaleLowerCase().trim() ===
                email.toLocaleLowerCase().trim()
            );
          })
        : false;
    setErrorType(findDuplicate ? true : false);
    return findDuplicate
      ? 'An email address can only be associated with one client/entity'
      : '';
  };

  useEffect(() => {
    getAllListData(
      `${COMMON_ENDPOINT.BeneficiaryList}?skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}&sortby=${String(
        debouncedParams.sortby,
      )}&order=${String(debouncedParams.order)}${
        debouncedParams.name !== '' ? '&name='.concat(debouncedParams.name) : ''
      }${
        debouncedParams.emailId !== ''
          ? '&emailId='.concat(encodeURIComponent(debouncedParams.emailId))
          : ''
      }`,
    )
      .then(async (res: any) => {
        const { data, count: total } = res ? res : { count: 0, data: [] };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setAvailableLeads((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          setAvailableLeads(res);
        }
        setGetMoreLoader(false);
        setTableLoader(false);
        const allList = await instantState(setAvailableLeads);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setGetMoreLoader(false);
        setHasMoreResults(false);
      });
  }, [debouncedParams]);

  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setLeadParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };

  useEffect(() => {
    getAllListData(
      `${COMMON_ENDPOINT.AffiliateList}?skip=${String(
        afParams.skip,
      )}&take=${String(afParams.take)}${
        afParams.name !== ''
          ? '&name='.concat(encodeURIComponent(afParams.name))
          : ''
      }&type=affiliate`,
    )
      .then(async (res: any) => {
        const { data, count: total } = res ? res : { count: 0, data: [] };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setAvailableAffiliates((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          setAvailableAffiliates(res);
        }
        setGetMoreLoader(false);
        const allList = await instantState(setAvailableAffiliates);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setGetMoreLoader(false);
        setHasMoreResults(false);
      });
  }, [afParams]);
  const getMoreListAffiliates = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setAfParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };

  useEffect(() => {
    console.warn({ insideCom: watch('orgType') });
    // if (!_.isUndefined(editId)) {
    if (watch('petitionerId') && watch('orgType') === 'existing') {
      const orgData = organizations.find((org) =>
        org.ids === watch('petitionerId') ? watch('petitionerId') : null,
      );
      if (orgData) {
        getPetitionerDetails(orgData.id, orgData.status);
      }
    } else {
      if (!editId) {
        if (searchParams.get('orgId') && searchParams.get('orgId') !== '') {
          const orgData = organizations.find(
            (org) => org.id === Number(searchParams.get('orgId')),
          );

          if (orgData) {
            getPetitionerDetails(orgData.id, orgData.status);
          }
        } else {
          setValue('petitionerId', null);
          setContactPerson({});
          setValue('petitionerContactId', null);
        }
      }
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, organizations, watch('orgType'), watch('petitionerId')]);

  const onSubmit = (e: any) => {
    if (petitionerEmailDuplicate) {
      setConfirmOpen((pre) => ({
        ...pre,
        show: true,
      }));
      return;
    } else if (petitionerEmailDuplicateAnother) {
      setError('contactEmail', {
        message: 'Email Already Exists With Another Account',
        type: 'custom',
      });
      toast.error('Email Already Exists With Another Account', alertOptions);
      return;
    }
    onSubmitForm(e);
  };

  return (
    <Typography component={'div'}>
      <form id="lead-step-one" onSubmit={handleSubmit(onSubmit)}>
        <Typography component={'div'} sx={{ marginTop: '0rem !important' }}>
          {/* Beneficiary Form  */}
          <Typography component={'div'} className={classes.leadwiteBox}>
            <Typography component={'div'} className="d-flex-a d-flex-sb mb-1">
              <Typography
                component={'div'}
                className="uadd-title"
                sx={{
                  paddingBottom: 'unset !important',
                  paddingTop: 'unset !important',
                }}>
                Beneficiary
              </Typography>
              {!leadData?.isAffilateCase ? (
                <Typography className="d-flex-a">
                  <Typography component={'div'}>
                    <Controller
                      control={control}
                      defaultValue="new"
                      name="leadOrgType"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}>
                            <FormControlLabel
                              value="new"
                              control={<Radio />}
                              label="New"
                            />
                            <FormControlLabel
                              value="existing"
                              control={<Radio />}
                              label="Existing"
                            />
                          </RadioGroup>
                        );
                      }}
                    />
                  </Typography>
                  <Typography
                    className="d-flex-ja"
                    sx={{
                      backgroundColor: '#002c69',
                      borderRadius: '5px',
                      color: '#fff !important',
                      cursor: 'pointer',
                      fontFamily: 'Arial-Medium !important',
                      fontSize: '25px !important',
                      height: '32px',
                      width: '32px',
                    }}
                    onClick={() => {
                      if (watch('leadOrgType') === 'existing') {
                        // Show popup menu
                        getAllListData(
                          `${COMMON_ENDPOINT.BeneficiaryList}?skip=${String(
                            leadParams.skip,
                          )}&take=${String(leadParams.take)}&sortby=${String(
                            leadParams.sortby,
                          )}&order=${String(leadParams.order)}${
                            leadParams.name !== ''
                              ? '&name='.concat(leadParams.name)
                              : ''
                          }`,
                        ).then((res: any) => {
                          setAvailableLeads(res);
                          handleLeadsOpen();
                        });
                      } else {
                        fieldAppend({
                          beneficiaryEmail: '',
                          beneficiaryFirstName: '',
                          beneficiaryLastName: '',
                          beneficiaryMobileCode: 'us',
                          beneficiaryMobileNumber: '',
                          checkEmailValidate: '',
                          disable: false,
                          id: '',
                          ids: '',
                          leadUserId: '',
                          loginCheck: false,
                          mobileCountryCode: '+1 ',
                          mobileSocCode: 'us',
                          sendEmailDisable: false,
                          sendMail: false,
                          status: '',
                          type: watch('leadOrgType'),
                        });
                      }
                    }}>
                    +
                  </Typography>
                </Typography>
              ) : (
                ''
              )}
            </Typography>
            <Grid container>
              <Grid item md={12}>
                <Box
                  component="form"
                  className="lead-box"
                  style={{ padding: '1rem !important' }}
                  noValidate
                  autoComplete="off">
                  {fields.length ? (
                    <Typography component={'div'}>
                      {fields.map((item: any, index: number) => {
                        return (
                          <Grid
                            container
                            key={item.id}
                            columnSpacing={{ md: 2, sm: 1 }}>
                            <Grid item md={2} sm={12}>
                              <Typography
                                component={'div'}
                                className="custom-field-row"
                                style={{ marginBottom: '0px !important' }}>
                                <Typography
                                  component={'div'}
                                  className="custom-label"
                                  sx={{ marginTop: '.5rem !important' }}>
                                  First Name{' '}
                                  <Typography
                                    component={'span'}
                                    className="Mui-error">
                                    *
                                  </Typography>
                                </Typography>

                                <Controller
                                  control={control}
                                  defaultValue={''}
                                  name={`beneficiaryDetails.${index}.beneficiaryFirstName`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'First Name'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={
                                          errors.beneficiaryDetails?.[index]
                                            ?.beneficiaryFirstName?.message ||
                                          ''
                                        }
                                        onChange={(e) => {
                                          const vals = e.target.value.replace(
                                            /[^a-zA-Z s]/,
                                            '',
                                          );
                                          onChange(vals);
                                        }}
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.loginCheck
                                              ? true
                                              : false
                                            : item.type === 'existing' &&
                                              item.loginCheck
                                            ? true
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2} sm={12}>
                              <Typography
                                component={'div'}
                                className="custom-field-row"
                                style={{ marginBottom: '0px !important' }}>
                                <Typography
                                  component={'div'}
                                  className="custom-label"
                                  sx={{ margin: '.5rem 0 0 0 !important' }}>
                                  Last Name
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={''}
                                  name={`beneficiaryDetails.${index}.beneficiaryLastName`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'Last Name'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={
                                          errors.beneficiaryDetails?.[index]
                                            ?.beneficiaryLastName?.message || ''
                                        }
                                        onChange={(e) => {
                                          const vals = e.target.value.replace(
                                            /[^a-zA-Z s]/,
                                            '',
                                          );
                                          onChange(vals);
                                        }}
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.loginCheck
                                              ? true
                                              : false
                                            : item.type === 'existing' &&
                                              item.loginCheck
                                            ? true
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2.6} sm={12}>
                              <Typography
                                component={'div'}
                                className="custom-field-row"
                                style={{ marginBottom: '0px !important' }}>
                                <Typography
                                  component={'div'}
                                  className="custom-label"
                                  sx={{ margin: '.5rem 0 0 0 !important' }}>
                                  Email
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={''}
                                  name={`beneficiaryDetails.${index}.beneficiaryEmail`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'Email'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={
                                          errors.beneficiaryDetails?.[index]
                                            ?.beneficiaryEmail?.message ||
                                          CheckDuplicate(
                                            index,
                                            value,
                                            'beneficiary',
                                          )
                                        }
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                        type="email"
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.loginCheck
                                              ? true
                                              : false
                                            : item.type === 'existing'
                                            ? true
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2.4} sm={12}>
                              <Typography
                                component={'div'}
                                className="custom-field-row"
                                style={{ marginBottom: '0px !important' }}>
                                <Typography
                                  component={'div'}
                                  className="custom-label"
                                  sx={{ margin: '.5rem 0 0 0 !important' }}>
                                  Phone
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={''}
                                  name={`beneficiaryDetails.${index}.beneficiaryMobileNumber`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <PhoneInput
                                        placeholder="Phone"
                                        inputStyle={{ width: '200px' }}
                                        country={
                                          item.beneficiaryMobileCode
                                            ? item.beneficiaryMobileCode
                                            : 'us'
                                        }
                                        value={value}
                                        onChange={(
                                          value,
                                          data,
                                          event,
                                          formattedValue,
                                        ) => {
                                          const codes: any = data;
                                          onChange(formattedValue);
                                          setValue(
                                            `beneficiaryDetails.${index}.mobileCountryCode`,
                                            '+'.concat(codes?.dialCode),
                                          );
                                          setValue(
                                            `beneficiaryDetails.${index}.mobileSocCode`,
                                            codes?.countryCode,
                                          );
                                          setValue(
                                            `beneficiaryDetails.${index}.beneficiaryMobileCode`,
                                            codes?.countryCode,
                                          );
                                        }}
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.loginCheck
                                              ? true
                                              : false
                                            : item.type === 'existing' &&
                                              item.loginCheck
                                            ? true
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2} sm={12}>
                              <Typography
                                component={'div'}
                                className="custom-field-row d-flex-a"
                                sx={{ paddingTop: '30px' }}>
                                <Controller
                                  control={control}
                                  defaultValue={false}
                                  name={`beneficiaryDetails.${index}.sendMail`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomCheckBox
                                        checked={value}
                                        onChange={onChange}
                                        // name={name}
                                        value={value}
                                        color="success"
                                        disabled={
                                          item.disable || item?.sendEmailDisable
                                        }
                                      />
                                    );
                                  }}
                                />
                                {!item.beneficiaryInviteSent ? (
                                  <Typography
                                    sx={{
                                      color: '#333333 !important',
                                      fontFamily: 'Arial-Bold !important',
                                      fontSize: '15px !important',
                                    }}>
                                    {item.loginCheck
                                      ? ' User Created'
                                      : 'Send Invitation '}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      color: '#333333 !important',
                                      fontFamily: 'Arial-Bold !important',
                                      fontSize: '15px !important',
                                    }}>
                                    {item.loginCheck
                                      ? 'User Created'
                                      : 'Resend '}
                                  </Typography>
                                )}
                              </Typography>
                            </Grid>

                            <Grid
                              md={1}
                              sm={12}
                              sx={{
                                display: 'flex',
                                paddingLeft: '0.5rem !important',
                                paddingTop: '1rem !important',
                              }}>
                              {!leadData?.isAffilateCase ? (
                                <>
                                  {((!item.status && !item.loginCheck) ||
                                    (!item.loginCheck && item.status)) &&
                                    editId &&
                                    item.disable &&
                                    item.ids && (
                                      <Tooltip title={'Edit'} placement="top">
                                        <EditIcon
                                          sx={{
                                            cursor:
                                              (!item.status &&
                                                !item.loginCheck) ||
                                              (!item.loginCheck && item.status)
                                                ? ' pointer'
                                                : 'not-allowed',
                                            fill: 'gray',
                                            height: '25px',
                                            marginTop: '30px',
                                            width: '25px',
                                          }}
                                          onClick={() =>
                                            beneficiaryUpdate(index, {
                                              ...item,
                                              disable: false,
                                            })
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                  {editId &&
                                    (!item.disable ||
                                      (item.type === 'existing' &&
                                        !item.ids)) && (
                                      <Tooltip title={'Save'} placement="top">
                                        <SaveIcon
                                          sx={{
                                            cursor: 'pointer',
                                            fill: 'gray',
                                            height: '25px',
                                            marginTop: '30px',
                                            width: '25px',
                                          }}
                                          onClick={() =>
                                            saveBeneficiary(
                                              index,
                                              item,
                                              'beneficiary',
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                  <Tooltip
                                    title={
                                      item.status
                                        ? 'Petition already created for the Lead. Details can not be deleted.'
                                        : 'Delete'
                                    }
                                    placement="top">
                                    <DeleteForeverIcon
                                      sx={{
                                        cursor: item.status
                                          ? 'not-allowed'
                                          : 'pointer',
                                        fill: 'red',
                                        height: '25px',
                                        marginTop: '30px',
                                        width: '25px',
                                      }}
                                      {...(item.status
                                        ? {}
                                        : {
                                            onClick: () =>
                                              deleteBeneficiary(
                                                index,
                                                item,
                                                'beneficiary',
                                              ),
                                          })}
                                    />
                                  </Tooltip>{' '}
                                </>
                              ) : (
                                ''
                              )}
                              {editId &&
                                ![5].includes(leadData?.affilateRoleId) && (
                                  <Tooltip
                                    title={
                                      item.status
                                        ? 'Petition already created for the Lead. Please go to Document Checklist in Petition to upload documents'
                                        : 'Upload'
                                    }
                                    placement="top">
                                    <UploadFileIcon
                                      sx={{
                                        cursor: item.status
                                          ? 'not-allowed'
                                          : 'pointer',
                                        fill: 'gray',
                                        height: '25px',
                                        marginTop: '30px',
                                        width: '25px',
                                      }}
                                      {...(item.status
                                        ? {}
                                        : {
                                            onClick: () =>
                                              navigate(
                                                `${
                                                  userDetail.role_id === 3
                                                    ? AttorneyPrivateRoutes
                                                        .LEADS.UPLOADDOCUMENT
                                                    : userDetail.role_id === 2
                                                    ? PetitionerPrivateRoutes
                                                        .LEADS.UPLOADDOCUMENT
                                                    : userDetail.role_id ===
                                                        5 &&
                                                      leadData?.isAffilateCase &&
                                                      leadData?.affilateRoleId ===
                                                        3
                                                    ? AffilatePrivateRoutes
                                                        .ATTORNEY_LEADS
                                                        .UPLOADDOCUMENT
                                                    : AffilatePrivateRoutes
                                                        .PETITIONER_LEADS
                                                        .UPLOADDOCUMENT
                                                }/${String(leadData.id)}${
                                                  searchParams.get('orgId')
                                                    ? '?orgId=' +
                                                      searchParams.get('orgId')
                                                    : ''
                                                }${
                                                  searchParams.get('invited')
                                                    ? '&invited=true&isLead=true'
                                                    : searchParams.get(
                                                        'associated',
                                                      )
                                                    ? '&associated=true&isLead=true'
                                                    : ''
                                                }`,
                                                {
                                                  state: {
                                                    data: {
                                                      ...item,
                                                      name: `${
                                                        item.beneficiaryFirstName
                                                      } ${
                                                        item.beneficiaryLastName
                                                          ? item.beneficiaryLastName
                                                          : ''
                                                      }`,
                                                      personId: item.ids,
                                                    },
                                                    isBack: true,
                                                    screen: 'lead',
                                                    user: 'beneficiary',
                                                  },
                                                },
                                              ),
                                          })}
                                    />
                                  </Tooltip>
                                )}
                              {!item.status &&
                              !leadData?.isAffilateCase &&
                              ((editId && item.loginCheck) ||
                                (!editId && item.type === 'existing')) ? (
                                <Tooltip
                                  title={
                                    editId
                                      ? item.loginCheck
                                        ? 'You can not edit beneficiary details because there is a pre-existing beneficiary with this email address'
                                        : ''
                                      : item.type === 'new'
                                      ? ''
                                      : item.loginCheck
                                      ? 'You can not edit beneficiary details because there is a pre-existing beneficiary with this email address'
                                      : 'You are not allowed to edit email when Beneficiary is added from existing list. Please save the changes and go to Edit Lead to update the details'
                                  }
                                  placement="top">
                                  <InfoIcon
                                    sx={{
                                      cursor: 'pointer',
                                      fill: 'gray',
                                      height: '25px',
                                      marginTop: '30px',
                                      width: '25px',
                                    }}
                                  />
                                </Tooltip>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Typography>
                  ) : (
                    <Grid item md={12} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-label"
                        sx={{
                          margin: '1.2rem 0 0 0 !important',
                          textAlign: 'center',
                        }}>
                        No Beneficiary Added
                      </Typography>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Typography>
          {/* Petitioner Form  */}
          <Typography component={'div'} className={classes.leadwiteBox}>
            <Typography component={'div'} className="d-flex-a d-flex-sb mb-1">
              <Typography
                component={'div'}
                className="uadd-title"
                sx={{
                  paddingBottom: 'unset !important',
                  paddingTop: 'unset !important',
                }}>
                Petitioner
              </Typography>
              {!leadData?.isAffilateCase ? (
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue="existing"
                    name="orgType"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            if (e.target.value !== 'new') {
                              setPetitionerEmailDuplicate(false);
                            }
                            resetFields();
                          }}>
                          <FormControlLabel
                            value="existing"
                            control={<Radio />}
                            label="Existing"
                          />
                          <FormControlLabel
                            value="new"
                            control={<Radio />}
                            label="New"
                          />
                          <FormControlLabel
                            value="sameas"
                            control={<Radio />}
                            label="Same as Beneficiary"
                          />
                        </RadioGroup>
                      );
                    }}
                  />
                </Typography>
              ) : (
                ''
              )}
            </Typography>
            <Box
              component="form"
              className="lead-box"
              sx={{ paddingRight: '25px !important' }}
              noValidate
              autoComplete="off">
              <Grid container columnSpacing={{ md: 2, sm: 1 }}>
                {watch('orgType') === 'new' && (
                  <>
                    <Grid item md={6} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-field-row">
                        <Typography
                          component={'div'}
                          className="custom-label"
                          sx={{ margin: '.5rem 0 0 0 !important' }}>
                          Organization Name{' '}
                          <Typography component={'span'} className="Mui-error">
                            *
                          </Typography>
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'orgName'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Organization Name'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.orgName?.message || ''}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography
                        sx={{ margin: '15px 0 !important' }}
                        component={'div'}
                        className="custom-field-row">
                        <Typography
                          component={'div'}
                          className="custom-label"
                          sx={{ marginTop: '.5rem !important' }}>
                          First Name{' '}
                          <Typography component={'span'} className="Mui-error">
                            *
                          </Typography>
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'contactPersonName'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'First Name'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.contactPersonName?.message || ''}
                                onChange={(e) => {
                                  const vals = e.target.value.replace(
                                    /[^a-zA-Z s]/,
                                    '',
                                  );
                                  onChange(vals);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography
                        component={'div'}
                        className="custom-field-row">
                        <Typography
                          component={'div'}
                          className="custom-label"
                          sx={{ margin: '.5rem 0 0 0 !important' }}>
                          Email{' '}
                          <Typography component={'span'} className="Mui-error">
                            *
                          </Typography>
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'contactEmail'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Contact Email'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.contactEmail?.message || ''}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                onBlur={() => checkPetitionEmail(value)}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-field-row d-flex-a u-pl-1"
                        sx={{ paddingTop: '30px !important' }}>
                        <Controller
                          control={control}
                          defaultValue={false}
                          name={'sendMail'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomCheckBox
                                checked={value}
                                onChange={onChange}
                                // name={name}
                                value={value}
                                color="success"
                              />
                            );
                          }}
                        />
                        <Typography
                          component={'div'}
                          className="custom-label"
                          sx={{ margin: '.5rem 0 0 0 !important' }}>
                          Send Invitation
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{ margin: '15px 0 !important' }}
                        component={'div'}
                        className="custom-field-row u-pl-1">
                        <Typography
                          component={'div'}
                          className="custom-label"
                          sx={{ margin: '.5rem 0 0 0 !important' }}>
                          Last Name{' '}
                          <Typography component={'span'} className="Mui-error">
                            *
                          </Typography>
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'contactLastName'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Last Name'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.contactLastName?.message || ''}
                                onChange={(e) => {
                                  const vals = e.target.value.replace(
                                    /[^a-zA-Z s]/,
                                    '',
                                  );
                                  onChange(vals);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography
                        component={'div'}
                        className="custom-field-row u-pl-1">
                        <Typography
                          component={'div'}
                          className="custom-label"
                          sx={{ margin: '.5rem 0 0 0 !important' }}>
                          Phone
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'contactMobileNumber'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <PhoneInput
                                placeholder="phone"
                                inputStyle={{ width: '210px' }}
                                country={'us'}
                                value={value}
                                onChange={(
                                  value,
                                  data,
                                  event,
                                  formattedValue,
                                ) => {
                                  const codes: any = data;
                                  onChange(formattedValue);
                                  setValue(
                                    'contactMobileCountryCode',
                                    '+'.concat(codes?.dialCode),
                                  );
                                  setValue(
                                    'contactMobileSocCode',
                                    codes?.countryCode,
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </>
                )}

                {watch('orgType') === 'existing' && (
                  <>
                    <Grid item md={6} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-alignment custom-field-row">
                        <Typography
                          component={'div'}
                          className="custom-label"
                          sx={{ margin: '.5rem 0 0 0 !important' }}>
                          Organization Name
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={null}
                          name="petitionerId"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'org_name'}
                                valueKey={'ids'}
                                options={
                                  !_.isEmpty(organizations) ? organizations : []
                                }
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  const orgData = organizations.find(
                                    (org) => org.ids === e.target.value,
                                  );
                                  if (orgData) {
                                    setValue('petitionerContactId', null);
                                    getPetitionerDetails(
                                      orgData.id,
                                      orgData.status,
                                    );
                                  } else {
                                    setValue('petitionerContactId', null);
                                    setContactPerson({});
                                    setPetitioners([]);
                                    resetFields();
                                  }
                                }}
                                disabled={leadData?.isAffilateCase}
                                placeHolder={'Select'}
                                className={'custom-input'}
                              />
                            );
                          }}
                        />
                      </Typography>
                      {!_.isEmpty(petitioners) && (
                        <Typography
                          component={'div'}
                          className="custom-field-row">
                          <Typography
                            component={'div'}
                            className="custom-label"
                            sx={{ margin: '.5rem 0 0 0 !important' }}>
                            Email
                          </Typography>

                          <CustomInput
                            placeHolder={'Email'}
                            value={
                              !_.isEmpty(contactPerson)
                                ? contactPerson.email_id
                                : ''
                            }
                            className={'custom-input'}
                            disabled={true}
                          />
                        </Typography>
                      )}
                      {!leadData?.isAffilateCase &&
                        !_.isEmpty(petitioners) &&
                        !_.isEmpty(contactPerson) &&
                        contactPerson.email_id &&
                        // !_.isUndefined(leadData) &&
                        // leadData.petitioner_assigned &&
                        // !leadData.petitioner_associated &&
                        !watch('petitionerAssociated') && (
                          <Typography component={'div'} className="d-flex-a">
                            <Button
                              className="n-add-btn"
                              onClick={inviteResend}>
                              Send Invitation
                            </Button>
                          </Typography>
                        )}
                      {watch('petitionerAssociated') &&
                        editId &&
                        !_.isEmpty(petitioners) &&
                        !_.isUndefined(
                          leadData && leadData.petitioner_assigned,
                        ) && (
                          <Typography component={'div'} className="d-flex-a">
                            <Button
                              className="n-add-btn"
                              onClick={() =>
                                navigate(
                                  `${
                                    userDetail.role_id === 3
                                      ? AttorneyPrivateRoutes.LEADS
                                          .UPLOADDOCUMENT
                                      : userDetail.role_id === 2
                                      ? PetitionerPrivateRoutes.LEADS
                                          .UPLOADDOCUMENT
                                      : userDetail.role_id === 5 &&
                                        leadData?.isAffilateCase &&
                                        leadData?.affilateRoleId === 3
                                      ? AffilatePrivateRoutes.ATTORNEY_LEADS
                                          .UPLOADDOCUMENT
                                      : AffilatePrivateRoutes.PETITIONER_LEADS
                                          .UPLOADDOCUMENT
                                  }/${String(leadData.id)}${
                                    searchParams.get('orgId')
                                      ? '?orgId=' + searchParams.get('orgId')
                                      : ''
                                  }${
                                    searchParams.get('invited')
                                      ? '&invited=true&isLead=true'
                                      : searchParams.get('associated')
                                      ? '&associated=true&isLead=true'
                                      : ''
                                  }`,
                                  {
                                    state: {
                                      data: {
                                        name: organizations.find(
                                          (org) =>
                                            org.id ===
                                              leadData.petitioner_assigned &&
                                            org.status ===
                                              leadData.petitioner_associated,
                                        )?.org_name,
                                        personId: leadData.petitioner_assigned,
                                      },
                                      isBack: true,
                                      user: 'petitioner',
                                    },
                                  },
                                )
                              }>
                              Upload Document
                            </Button>
                          </Typography>
                        )}
                    </Grid>
                    {!_.isEmpty(petitioners) && (
                      <Grid item md={6} sm={12}>
                        <Typography
                          component={'div'}
                          className="custom-field-row">
                          <Typography
                            component={'div'}
                            className="custom-label"
                            sx={{ margin: '.5rem 0 0 0 !important' }}>
                            Contact Person
                          </Typography>

                          <Controller
                            control={control}
                            defaultValue={null}
                            name="petitionerContactId"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomSelect
                                  labelKey={'full_name'}
                                  valueKey={'id'}
                                  options={
                                    !_.isEmpty(petitioners) ? petitioners : []
                                  }
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    setValue(
                                      'petitionerContactId',
                                      Number(e.target.value),
                                    );
                                    setContactPerson(
                                      petitioners.find(
                                        (person) =>
                                          person.id === e.target.value,
                                      ),
                                    );
                                  }}
                                  placeHolder={'Select'}
                                  className={'custom-input'}
                                  error={errors.petitionerContactId?.message}
                                  disabled={leadData?.isAffilateCase}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography
                          component={'div'}
                          className="custom-field-row">
                          <Typography
                            component={'div'}
                            className="custom-label"
                            sx={{ margin: '.5rem 0 0 0 !important' }}>
                            Mobile
                          </Typography>

                          <PhoneInput
                            placeholder="Phone"
                            inputStyle={{ width: '200px' }}
                            country={'us'}
                            // country={
                            //   !_.isEmpty(contactPerson)
                            //     ? contactPerson.country_source_code
                            //       ? contactPerson.country_source_code
                            //       : contactPerson.mobile_soc_code
                            //       ? contactPerson.mobile_soc_code
                            //       : 'us'
                            //     : 'us'
                            // }
                            value={
                              !_.isEmpty(contactPerson) &&
                              contactPerson.contact_mobile
                                ? String(contactPerson.contact_mobile).indexOf(
                                    '+',
                                  ) !== -1
                                  ? contactPerson.contact_mobile
                                  : contactPerson.country_code_mobile
                                  ? String(contactPerson.country_code_mobile)
                                      .concat(' ')
                                      .concat(contactPerson.contact_mobile)
                                  : contactPerson.mobile_country_code
                                  ? String(contactPerson.mobile_country_code)
                                      .concat(' ')
                                      .concat(contactPerson.contact_mobile)
                                  : '+1 '.concat(contactPerson.contact_mobile)
                                : '+1 '
                            }
                            // className={'custom-input'}
                            disabled={true}
                          />
                        </Typography>

                        {editId &&
                          !watch('petitionerAssociated') &&
                          ![5].includes(leadData?.affilateRoleId) &&
                          !_.isEmpty(petitioners) &&
                          !_.isUndefined(
                            leadData && leadData.petitioner_assigned,
                          ) && (
                            <Typography component={'div'} className="d-flex-a">
                              <Button
                                className="n-add-btn"
                                onClick={() =>
                                  navigate(
                                    `${
                                      userDetail.role_id === 3
                                        ? AttorneyPrivateRoutes.LEADS
                                            .UPLOADDOCUMENT
                                        : userDetail.role_id === 2
                                        ? PetitionerPrivateRoutes.LEADS
                                            .UPLOADDOCUMENT
                                        : userDetail.role_id === 5 &&
                                          leadData?.isAffilateCase &&
                                          leadData?.affilateRoleId === 3
                                        ? AffilatePrivateRoutes.ATTORNEY_LEADS
                                            .UPLOADDOCUMENT
                                        : AffilatePrivateRoutes.PETITIONER_LEADS
                                            .UPLOADDOCUMENT
                                    }/${String(leadData.id)}${
                                      searchParams.get('orgId')
                                        ? '?orgId=' + searchParams.get('orgId')
                                        : ''
                                    }${
                                      searchParams.get('invited')
                                        ? '&invited=true&isLead=true'
                                        : searchParams.get('associated')
                                        ? '&associated=true&isLead=true'
                                        : ''
                                    }`,
                                    {
                                      state: {
                                        data: {
                                          name: organizations.find(
                                            (org) =>
                                              org.id ===
                                                leadData.petitioner_assigned &&
                                              org.status ===
                                                leadData.petitioner_associated,
                                          )?.org_name,
                                          personId:
                                            leadData.petitioner_assigned,
                                        },
                                        isBack: true,
                                        user: 'petitioner',
                                      },
                                    },
                                  )
                                }>
                                Upload Document
                              </Button>
                            </Typography>
                          )}
                      </Grid>
                    )}
                  </>
                )}

                {watch('orgType') === 'sameas' && (
                  <Grid item md={12} sm={12}>
                    <Typography
                      component={'div'}
                      className="custom-label"
                      sx={{
                        margin: '.5rem 0 0 0 !important',
                        textAlign: 'center',
                      }}>
                      No Petitioner details not required
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Typography>
          {/* Additional Petitioner Contacts Form  */}
          <Typography component={'div'} className={classes.leadwiteBox}>
            <Typography component={'div'} className="d-flex-a d-flex-sb mb-1">
              <Typography
                component={'div'}
                className="uadd-title"
                sx={{
                  paddingBottom: 'unset !important',
                  paddingTop: 'unset !important',
                }}>
                Additional Petitioner Contacts
              </Typography>
              {!leadData?.isAffilateCase ? (
                <Typography
                  className="d-flex-ja"
                  sx={{
                    backgroundColor: '#002c69',
                    borderRadius: '5px',
                    color: '#fff !important',
                    cursor: 'pointer',
                    fontFamily: 'Arial-Medium !important',
                    fontSize: '25px !important',
                    height: '32px',
                    width: '32px',
                  }}
                  onClick={() => {
                    petitionerContactFieldAppend({
                      contactMobileCountryCode: '+1 ',
                      contactMobileSocCode: 'us',
                      disable: false,
                      ids: '',
                      orgName: '',
                      petitionerEmail: '',
                      petitionerFirstName: '',
                      petitionerLastName: '',
                      petitionerMobileCode: 'us',
                      petitionerMobileNumber: '',
                      roleId: 2,
                      sendMail: false,
                      status: '',
                    });
                  }}>
                  +
                </Typography>
              ) : (
                ''
              )}
            </Typography>
            <Grid container>
              <Grid item md={12} sm={12}>
                <Box
                  component="form"
                  className="lead-box"
                  style={{ padding: '1rem !important' }}
                  noValidate
                  autoComplete="off">
                  {petitionerContactFields.length ? (
                    <Typography>
                      {petitionerContactFields.map(
                        (item: any, index: number) => {
                          return (
                            <Grid
                              container
                              key={item.id}
                              columnSpacing={{ md: 2, sm: 1 }}>
                              <Grid item md={2} sm={12}>
                                <Typography
                                  component={'div'}
                                  className="custom-field-row"
                                  style={{ marginBottom: '0px !important' }}>
                                  <Typography
                                    component={'div'}
                                    className="custom-label">
                                    Organization Name
                                  </Typography>
                                  <Controller
                                    control={control}
                                    defaultValue={''}
                                    name={`petitionerContactDetails.${index}.orgName`}
                                    render={({
                                      field: { onChange, value, name, ref },
                                      formState,
                                    }) => {
                                      return (
                                        <CustomInput
                                          placeHolder={'Organization Name'}
                                          name={name}
                                          value={value}
                                          className={'custom-input'}
                                          error={
                                            errors.petitionerContactDetails?.[
                                              index
                                            ]?.orgName?.message || ''
                                          }
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                          disabled={item.disable}
                                        />
                                      );
                                    }}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item md={2} sm={12}>
                                <Typography
                                  component={'div'}
                                  className="custom-field-row"
                                  style={{ marginBottom: '0px !important' }}>
                                  <Typography
                                    component={'div'}
                                    className="custom-label">
                                    First Name{' '}
                                    <Typography
                                      component={'span'}
                                      className="Mui-error">
                                      *
                                    </Typography>
                                  </Typography>
                                  <Controller
                                    control={control}
                                    defaultValue={''}
                                    name={`petitionerContactDetails.${index}.petitionerFirstName`}
                                    render={({
                                      field: { onChange, value, name, ref },
                                      formState,
                                    }) => {
                                      return (
                                        <CustomInput
                                          placeHolder={'First Name'}
                                          name={name}
                                          value={value}
                                          className={'custom-input'}
                                          error={
                                            errors.petitionerContactDetails?.[
                                              index
                                            ]?.petitionerFirstName?.message ||
                                            ''
                                          }
                                          onChange={(e) => {
                                            const vals = e.target.value.replace(
                                              /[^a-zA-Z s]/,
                                              '',
                                            );
                                            onChange(vals);
                                          }}
                                          disabled={item.disable}
                                        />
                                      );
                                    }}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item md={2} sm={12}>
                                <Typography
                                  component={'div'}
                                  className="custom-field-row"
                                  style={{ marginBottom: '0px !important' }}>
                                  <Typography
                                    component={'div'}
                                    className="custom-label">
                                    Last Name
                                  </Typography>
                                  <Controller
                                    control={control}
                                    defaultValue={''}
                                    name={`petitionerContactDetails.${index}.petitionerLastName`}
                                    render={({
                                      field: { onChange, value, name, ref },
                                      formState,
                                    }) => {
                                      return (
                                        <CustomInput
                                          placeHolder={'Last Name'}
                                          name={name}
                                          value={value}
                                          className={'custom-input'}
                                          error={
                                            errors.petitionerContactDetails?.[
                                              index
                                            ]?.petitionerLastName?.message || ''
                                          }
                                          onChange={(e) => {
                                            const vals = e.target.value.replace(
                                              /[^a-zA-Z s]/,
                                              '',
                                            );
                                            onChange(vals);
                                          }}
                                          disabled={item.disable}
                                        />
                                      );
                                    }}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item md={2.5} sm={12}>
                                <Typography
                                  component={'div'}
                                  className="custom-field-row"
                                  style={{ marginBottom: '0px !important' }}>
                                  <Typography
                                    component={'div'}
                                    className="custom-label">
                                    Email
                                  </Typography>
                                  <Controller
                                    control={control}
                                    defaultValue={''}
                                    name={`petitionerContactDetails.${index}.petitionerEmail`}
                                    render={({
                                      field: { onChange, value, name, ref },
                                      formState,
                                    }) => {
                                      return (
                                        <CustomInput
                                          placeHolder={'Email'}
                                          name={name}
                                          value={value}
                                          className={'custom-input'}
                                          error={
                                            errors.petitionerContactDetails?.[
                                              index
                                            ]?.petitionerEmail?.message ||
                                            CheckDuplicate(
                                              index,
                                              value,
                                              'petitioner',
                                            )
                                          }
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                          type="email"
                                          disabled={item.disable}
                                        />
                                      );
                                    }}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item md={2.5} sm={12}>
                                <Typography
                                  component={'div'}
                                  className="custom-field-row"
                                  style={{ marginBottom: '0px !important' }}>
                                  <Typography
                                    component={'div'}
                                    className="custom-label">
                                    Phone
                                  </Typography>
                                  <Controller
                                    control={control}
                                    defaultValue={''}
                                    name={`petitionerContactDetails.${index}.petitionerMobileNumber`}
                                    render={({
                                      field: { onChange, value, name, ref },
                                      formState,
                                    }) => {
                                      return (
                                        <PhoneInput
                                          inputStyle={{ width: '200px' }}
                                          country={
                                            item.contactMobileSocCode
                                              ? item.contactMobileSocCode
                                              : 'us'
                                          }
                                          placeholder={'Phone'}
                                          value={value}
                                          onChange={(
                                            value,
                                            data,
                                            event,
                                            formattedValue,
                                          ) => {
                                            const codes: any = data;
                                            onChange(formattedValue);
                                            setValue(
                                              `petitionerContactDetails.${index}.contactMobileCountryCode`,
                                              '+'.concat(codes?.dialCode),
                                            );
                                            setValue(
                                              `petitionerContactDetails.${index}.contactMobileSocCode`,
                                              codes?.countryCode,
                                            );
                                            setValue(
                                              `petitionerContactDetails.${index}.petitionerMobileCode`,
                                              codes?.countryCode,
                                            );
                                          }}
                                          disabled={item.disable}
                                        />
                                      );
                                    }}
                                  />
                                </Typography>
                              </Grid>
                              {!leadData?.isAffilateCase ? (
                                <Grid
                                  md={1}
                                  sm={12}
                                  sx={{
                                    paddingLeft: '0.5rem !important',
                                    paddingTop: '1rem !important',
                                  }}>
                                  {editId && item.disable && (
                                    <Tooltip title={'Edit'} placement="top">
                                      <EditIcon
                                        sx={{
                                          cursor: item.status
                                            ? 'not-allowed'
                                            : 'pointer',
                                          fill: 'gray',
                                          height: '25px',
                                          marginTop: '20px',
                                          width: '25px',
                                        }}
                                        onClick={() =>
                                          petitionerContactUpdate(index, {
                                            ...item,
                                            disable: false,
                                          })
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                  {editId && !item.disable && (
                                    <Tooltip title={'Save'} placement="top">
                                      <SaveIcon
                                        sx={{
                                          cursor: 'pointer',
                                          fill: 'gray',
                                          height: '25px',
                                          marginTop: '30px',
                                          width: '25px',
                                        }}
                                        onClick={() =>
                                          saveBeneficiary(
                                            index,
                                            item,
                                            'petitioner',
                                          )
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                  <Tooltip title={'Delete'} placement="top">
                                    <DeleteForeverIcon
                                      sx={{
                                        cursor: 'pointer',
                                        fill: 'red',
                                        height: '25px',
                                        marginTop: '30px',
                                        width: '25px',
                                      }}
                                      onClick={() =>
                                        deleteBeneficiary(
                                          index,
                                          item,
                                          'petitioner',
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </Grid>
                              ) : (
                                ''
                              )}
                            </Grid>
                          );
                        },
                      )}
                    </Typography>
                  ) : (
                    <Grid item md={12} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-label"
                        sx={{
                          margin: '1.2rem 0 0 0 !important',
                          textAlign: 'center',
                        }}>
                        No Additional Petitioner Contacts Added
                      </Typography>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Typography>
          ;{/* Affiliate Form  */}
          <Typography component={'div'} className={classes.leadwiteBox}>
            <Typography
              component={'div'}
              className="d-flex-a d-flex-sb mb-1 mt-1">
              <Typography
                component={'div'}
                className="uadd-title"
                sx={{
                  paddingBottom: 'unset !important',
                  paddingTop: 'unset !important',
                }}>
                Affiliate
              </Typography>
              {!leadData?.isAffilateCase ? (
                <Typography className="d-flex-a">
                  <Typography component={'div'}>
                    <Controller
                      control={control}
                      defaultValue="new"
                      name="affiliateType"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}>
                            <FormControlLabel
                              value="new"
                              control={<Radio />}
                              label="New"
                            />
                            <FormControlLabel
                              value="existing"
                              control={<Radio />}
                              label="Existing"
                            />
                          </RadioGroup>
                        );
                      }}
                    />
                  </Typography>
                  <Typography
                    className="d-flex-ja"
                    sx={{
                      backgroundColor: '#002c69',
                      borderRadius: '5px',
                      color: '#fff !important',
                      cursor: 'pointer',
                      fontFamily: 'Arial-Medium !important',
                      fontSize: '25px !important',
                      height: '32px',
                      width: '32px',
                    }}
                    onClick={() => {
                      if (watch('affiliateType') === 'existing') {
                        // Show popup menu
                        getAllListData(
                          `${COMMON_ENDPOINT.AffiliateList}?skip=${String(
                            afParams.skip,
                          )}&take=${String(afParams.take)}&sortby=${String(
                            afParams.sortby,
                          )}&order=${String(afParams.order)}${
                            afParams.name !== ''
                              ? '&name='.concat(afParams.name)
                              : ''
                          }&type=affiliate`,
                        ).then((res: any) => {
                          setAvailableAffiliates(res);
                          handleAffiliatessOpen();
                        });
                      } else {
                        affiliateFieldAppend({
                          affilateEmail: '',
                          affilateFirstName: '',
                          affilateLastName: '',
                          affilateMobileNumber: '',
                          affilateRoleId: '',
                          id: '',
                          ids: '',
                          mobileCountryCode: '+1 ',
                          mobileSocCode: 'us',
                          sendMail: false,
                          type: watch('affiliateType'),
                        });
                      }
                    }}>
                    +
                  </Typography>
                </Typography>
              ) : (
                ''
              )}
            </Typography>
            <Grid container>
              <Grid item md={12}>
                <Box
                  component="form"
                  className="lead-box"
                  style={{ padding: '1rem !important' }}
                  noValidate
                  autoComplete="off">
                  {affiliateFields?.length ? (
                    <Typography>
                      {affiliateFields.map((item: any, index: number) => {
                        return (
                          <Grid
                            container
                            key={item.id}
                            columnSpacing={{ md: 2, sm: 1 }}>
                            <Grid item md={2} sm={12}>
                              <Typography
                                component={'div'}
                                className="custom-field-row"
                                style={{ marginBottom: '0px !important' }}>
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  First Name{' '}
                                  <Typography
                                    component={'span'}
                                    className="Mui-error">
                                    *
                                  </Typography>
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={''}
                                  name={`affilateDetails.${index}.affilateFirstName`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'First Name'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={
                                          errors.affilateDetails?.[index]
                                            ?.affilateFirstName?.message || ''
                                        }
                                        onChange={(e) => {
                                          const vals = e.target.value.replace(
                                            /[^a-zA-Z s]/,
                                            '',
                                          );
                                          onChange(vals);
                                        }}
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.type === 'existing' &&
                                              !item.ids
                                              ? true
                                              : false
                                            : item.loginCheck
                                            ? true
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2} sm={12}>
                              <Typography
                                component={'div'}
                                className="custom-field-row"
                                style={{ marginBottom: '0px !important' }}>
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  Last Name
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={''}
                                  name={`affilateDetails.${index}.affilateLastName`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'Last Name'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={
                                          errors.affilateDetails?.[index]
                                            ?.affilateLastName?.message || ''
                                        }
                                        onChange={(e) => {
                                          const vals = e.target.value.replace(
                                            /[^a-zA-Z s]/,
                                            '',
                                          );
                                          onChange(vals);
                                        }}
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.type === 'existing' &&
                                              !item.ids
                                              ? true
                                              : false
                                            : item.loginCheck
                                            ? true
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2} sm={12}>
                              <Typography
                                component={'div'}
                                className="custom-field-row"
                                style={{ marginBottom: '0px !important' }}>
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  Email
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={''}
                                  name={`affilateDetails.${index}.affilateEmail`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomInput
                                        placeHolder={'Email'}
                                        name={name}
                                        value={value}
                                        className={'custom-input'}
                                        error={
                                          errors.affilateDetails?.[index]
                                            ?.affilateEmail?.message ||
                                          CheckDuplicate(
                                            index,
                                            value,
                                            'affiliate',
                                          )
                                        }
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                        type="email"
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.type === 'existing' &&
                                              !item.ids
                                              ? true
                                              : false
                                            : item.loginCheck
                                            ? true
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2} sm={12}>
                              <Typography
                                component={'div'}
                                className="custom-field-row"
                                style={{ marginBottom: '0px !important' }}>
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  Phone
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={''}
                                  name={`affilateDetails.${index}.affilateMobileNumber`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <PhoneInput
                                        placeholder="Phone"
                                        inputStyle={{ width: '200px' }}
                                        country={
                                          item.mobileSocCode
                                            ? item.mobileSocCode
                                            : 'us'
                                        }
                                        value={value}
                                        onChange={(
                                          value,
                                          data,
                                          event,
                                          formattedValue,
                                        ) => {
                                          const codes: any = data;
                                          onChange(formattedValue);
                                          setValue(
                                            `affilateDetails.${index}.mobileCountryCode`,
                                            '+'.concat(codes?.dialCode),
                                          );
                                          setValue(
                                            `affilateDetails.${index}.mobileSocCode`,
                                            codes?.countryCode,
                                          );
                                        }}
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.type === 'existing' &&
                                              !item.ids
                                              ? true
                                              : false
                                            : item.loginCheck
                                            ? true
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={1} sm={12}>
                              <Typography
                                component={'div'}
                                className="custom-field-row send-invite">
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  Send Invitation
                                </Typography>
                                <Controller
                                  control={control}
                                  defaultValue={false}
                                  name={`affilateDetails.${index}.sendMail`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomCheckBox
                                        checked={value}
                                        onChange={onChange}
                                        // name={name}
                                        value={value}
                                        color="success"
                                        disabled={
                                          item.disable || item?.sendEmailDisable
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>

                            <Grid item md={2} sm={12} className="d-flex-a">
                              <Typography
                                component={'div'}
                                className="u-pr-1"
                                sx={{
                                  paddingBottom: errorType ? '22px' : '0px',
                                  paddingTop: errorType ? '0px' : '25px',
                                  width: '100%',
                                }}>
                                <Controller
                                  control={control}
                                  defaultValue={null}
                                  name={`affilateDetails.${index}.affilateRoleId`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                    formState,
                                  }) => {
                                    return (
                                      <CustomSelect
                                        labelKey={'label'}
                                        valueKey={'value'}
                                        options={[
                                          { label: 'Petitioner', value: 2 },
                                          { label: 'Attorney', value: 3 },
                                          // { label: 'Beneficiary', value: 4 },
                                          { label: 'View Only', value: 5 },
                                        ]}
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                        placeHolder={'Select'}
                                        className={'custom-input'}
                                        error={
                                          errors.affilateDetails?.[index]
                                            ?.affilateRoleId?.message || ''
                                        }
                                        // selectedValue="3"
                                        disabled={
                                          item.disable ||
                                          (editId
                                            ? item.loginCheck
                                              ? true
                                              : false
                                            : false)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>

                            {!leadData?.isAffilateCase ? (
                              <Grid
                                md={1}
                                sm={12}
                                sx={{
                                  display: 'flex',
                                  paddingLeft: '0.5rem !important',
                                  paddingTop: '1rem !important',
                                }}>
                                {!item.status &&
                                  editId &&
                                  !item.loginCheck &&
                                  item.disable && (
                                    <Tooltip title={'Edit'} placement="top">
                                      <EditIcon
                                        sx={{
                                          cursor: item.status
                                            ? 'not-allowed'
                                            : 'pointer',
                                          fill: 'gray',
                                          height: '25px',
                                          marginTop: '30px',
                                          width: '25px',
                                        }}
                                        onClick={() =>
                                          affiliateUpdate(index, {
                                            ...item,
                                            disable: false,
                                          })
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                {editId && !item.disable && (
                                  <Tooltip title={'Save'} placement="top">
                                    <SaveIcon
                                      sx={{
                                        cursor: 'pointer',
                                        fill: 'gray',
                                        height: '25px',
                                        marginTop: '30px',
                                        width: '25px',
                                      }}
                                      onClick={() =>
                                        saveBeneficiary(
                                          index,
                                          item,
                                          'affiliate',
                                        )
                                      }
                                    />
                                  </Tooltip>
                                )}
                                <Tooltip
                                  title={
                                    item.status
                                      ? 'Petition already created for the Lead. Details can not be deleted.'
                                      : 'Delete'
                                  }
                                  placement="top">
                                  <DeleteForeverIcon
                                    sx={{
                                      cursor: item.status
                                        ? 'not-allowed'
                                        : 'pointer',
                                      fill: 'red',
                                      height: '25px',
                                      marginTop: '30px',
                                      width: '25px',
                                    }}
                                    {...(item.status
                                      ? {}
                                      : {
                                          onClick: () =>
                                            deleteBeneficiary(
                                              index,
                                              item,
                                              'affiliate',
                                            ),
                                        })}
                                  />
                                </Tooltip>
                                {!item.status &&
                                ((editId && item.loginCheck) ||
                                  (!editId && item.type === 'existing')) ? (
                                  <Tooltip
                                    title={
                                      editId
                                        ? item.loginCheck
                                          ? 'You are not allowed to edit this details since Affiliate account is already created in application'
                                          : ''
                                        : item.type === 'new'
                                        ? ''
                                        : item.loginCheck
                                        ? 'You are not allowed to edit this details since Affiliate account is already created in application'
                                        : 'You are not allowed to edit email when Affiliate is added from existing list. Please save the changes and go to Edit Lead to update the details'
                                    }
                                    placement="top">
                                    <InfoIcon
                                      sx={{
                                        cursor: 'pointer',
                                        fill: 'gray',
                                        height: '25px',
                                        marginTop: '30px',
                                        width: '25px',
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  ''
                                )}
                              </Grid>
                            ) : (
                              ''
                            )}
                          </Grid>
                        );
                      })}
                    </Typography>
                  ) : (
                    <Grid item md={12} sm={12}>
                      <Typography
                        component={'div'}
                        className="custom-label"
                        sx={{
                          margin: '1.2rem 0 0 0 !important',
                          textAlign: 'center',
                        }}>
                        No Affiliate Added{' '}
                      </Typography>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Typography>
          ;;
        </Typography>
      </form>
      <Grid container className="bottom-fixed">
        <Grid item md={12}>
          <Typography component={'div'} className="d-flex-jae">
            <Button className="cancel-btn mr-2" onClick={cancelForm}>
              <Typography className="o-box" />
              Cancel
            </Button>
            <Button className="add-btn" type="submit" form={'lead-step-one'}>
              Continue
            </Button>
          </Typography>
        </Grid>
      </Grid>

      <CustomModalView
        open={isOpen}
        close={handleLeadsClose}
        title="Available Leads"
        value={leadParams.name}
        isFilters={false}
        fullWidth={true}
        maxWidth={'md'}
        // inputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">
        //       <IconButton
        //         aria-label="toggle password visibility"
        //         edge="end"
        //         {...(leadParams.name === ''
        //           ? {}
        //           : {
        //               onClick: () => {
        //                 setTableLoader(true);
        //                 setLeadParams((prev) => ({
        //                   ...prev,
        //                   name: '',
        //                   skip: 0,
        //                   take: 50,
        //                 }));
        //               },
        //             })}
        //       />
        //     </InputAdornment>
        //   ),
        // }}
        // filterOnChange={(value) => {
        // setTableLoader(true);
        // setLeadParams((prev) => ({
        //   ...prev,
        //   name: value,
        //   skip: 0,
        //   take: 50,
        // }));
        // }}
      >
        <Typography
          component={'div'}
          sx={{
            borderRadius: '8px 8px 0 0',
          }}
          className={classes.scrollbox}
          id="scrollParent">
          <InfiniteScroll
            dataLength={
              availableLeads &&
              availableLeads.data &&
              availableLeads.data.length
                ? availableLeads.data.length
                : 0
            }
            style={{
              overflow: 'unset',
            }}
            next={getMoreList && getMoreList}
            hasMore={hasMoreResults}
            scrollableTarget={'scrollParent'}
            loader={
              getMoreLoader && (
                <div
                  style={{
                    backgroundColor: '#ffffff',
                    textAlign: 'center',
                    width: '100%',
                  }}>
                  <CircularProgress
                    size={30}
                    sx={{
                      color: '#002c69',
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  />
                </div>
              )
            }
            endMessage>
            <table className="lead-table table-hover">
              <thead>
                <tr>
                  <th onClick={() => handleSortRequest('first_name')}>
                    <TableSortLabel
                      active={true}
                      direction={orderDirection.first_name}>
                      NAME
                    </TableSortLabel>
                  </th>
                  <th onClick={() => handleSortRequest('email_id')}>
                    <TableSortLabel
                      active={true}
                      direction={orderDirection.email_id}>
                      EMAIL
                    </TableSortLabel>
                  </th>
                  {/* <th>TYPE</th> */}
                  <th onClick={() => handleSortRequest('created_at')}>
                    <TableSortLabel
                      active={true}
                      direction={orderDirection.created_at}>
                      LEAD DATE
                    </TableSortLabel>
                  </th>
                </tr>
              </thead>

              <tbody
                style={{
                  position: 'sticky',
                  top: '40px',
                  zIndex: 1,
                }}>
                <tr>
                  <td>
                    <Typography
                      component={'div'}
                      className="d-flex-ja mr-1"
                      sx={{
                        color: '#84898c !important',
                      }}>
                      <CustomInput
                        placeHolder={'Search'}
                        className={'custom-search-model'}
                        value={leadParams.name}
                        inputProps={{
                          endAdornment:
                            leadParams.name !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={(e) => {
                                    setTableLoader(true);
                                    setLeadParams((prev) => ({
                                      ...prev,
                                      name: '',
                                      skip: 0,
                                      take: 50,
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          setTableLoader(true);
                          setLeadParams((prev) => ({
                            ...prev,
                            name: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                      />
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      component={'div'}
                      className="d-flex-ja mr-1"
                      sx={{
                        color: '#84898c !important',
                      }}>
                      <CustomInput
                        placeHolder={'Search'}
                        className={'custom-search-model'}
                        value={leadParams.emailId}
                        inputProps={{
                          endAdornment:
                            leadParams.emailId !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={(e) => {
                                    setTableLoader(true);
                                    setLeadParams((prev) => ({
                                      ...prev,
                                      emailId: '',
                                      skip: 0,
                                      take: 50,
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          setTableLoader(true);
                          setLeadParams((prev) => ({
                            ...prev,
                            emailId: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                      />
                    </Typography>
                  </td>
                  <td />
                </tr>
              </tbody>
              <tbody>
                {tableLoader ? (
                  <tr>
                    <td colSpan={8}>
                      <div
                        style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          width: '100%',
                        }}>
                        <CircularProgress
                          size={30}
                          sx={{
                            color: '#002c69',
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {!_.isEmpty(availableLeads) &&
                    availableLeads.data.length > 0
                      ? availableLeads.data.map((lead, index) => (
                          <tr
                            style={{ cursor: 'pointer' }}
                            key={index}
                            onClick={() =>
                              selectedLeadAffiliateDetails(lead, 'leads')
                            }>
                            <td>
                              <a
                                style={{
                                  color: '#1093df',
                                  textTransform: 'capitalize',
                                }}>
                                {lead.first_name}&nbsp;
                                <span>{lead.last_name}</span>
                              </a>
                            </td>
                            <td>{lead.email_id ? lead.email_id : '-'}</td>
                            {/* <td>{lead.type ? lead.type : '-'}</td> */}
                            <td>
                              {lead.created_at
                                ? moment(lead.created_at).format('yyyy-MM-DD')
                                : '-'}
                            </td>
                          </tr>
                        ))
                      : !tableLoader && (
                          <tr className={'text-center'}>
                            <th scope="row" colSpan={4}>
                              No Record(s) Found.
                            </th>
                          </tr>
                        )}
                  </>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </Typography>
        {/* <Pagination
          total={
            !_.isEmpty(availableLeads?.data) ? Number(availableLeads?.count) : 0
          }
          skip={leadParams.skip}
          take={leadParams.take}
          setParams={setLeadParams}
        /> */}
      </CustomModalView>

      <CustomModalView
        open={isAfOpen}
        close={handleAffiliatesClose}
        title="Available Affiliates"
        isFilters={true}
        maxWidth={'md'}
        inputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                {...(afParams.name === ''
                  ? {}
                  : {
                      onClick: () => {
                        setAfParams((prev) => ({
                          ...prev,
                          name: '',
                          skip: 0,
                          take: 50,
                        }));
                      },
                    })}
              />
            </InputAdornment>
          ),
        }}
        filterOnChange={(value) => {
          setAfParams((prev) => ({
            ...prev,
            name: value,
            skip: 0,
            take: 50,
          }));
        }}>
        <Typography
          component={'div'}
          sx={{
            borderRadius: '8px 8px 0 0',
          }}
          className={classes.scrollbox}
          id="scrollParent">
          <InfiniteScroll
            dataLength={
              availableAffiliates &&
              availableAffiliates.data &&
              availableAffiliates.data.length
                ? availableAffiliates.data.length
                : 0
            }
            style={{
              overflow: 'unset',
            }}
            next={getMoreListAffiliates && getMoreListAffiliates}
            hasMore={hasMoreResults}
            scrollableTarget={'scrollParent'}
            loader={
              getMoreLoader && (
                <div
                  style={{
                    backgroundColor: '#ffffff',
                    textAlign: 'center',
                    width: '100%',
                  }}>
                  <CircularProgress
                    size={30}
                    sx={{
                      color: '#002c69',
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  />
                </div>
              )
            }
            endMessage>
            <table className="lead-table table-hover">
              <thead>
                <tr>
                  {['NAME', 'MOBILE', 'EMAIL'].map((column, index) => (
                    <th scope="col" style={{ width: '300px' }} key={index}>
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(availableAffiliates) &&
                availableAffiliates.data.length > 0 ? (
                  availableAffiliates.data.map((affiliate, index) => (
                    <tr
                      style={{ cursor: 'pointer' }}
                      key={index}
                      onClick={() =>
                        selectedLeadAffiliateDetails(affiliate, 'affiliate')
                      }>
                      <td>
                        <a style={{ color: '#1093df' }}>
                          {affiliate.full_name && affiliate.full_name.trim()
                            ? affiliate.full_name
                            : '-'}
                        </a>
                      </td>
                      <td>
                        {affiliate.contact_mobile
                          ? affiliate.contact_mobile
                          : '-'}
                      </td>
                      <td>{affiliate.email_id ? affiliate.email_id : '-'}</td>
                    </tr>
                  ))
                ) : (
                  <tr className={'text-center'}>
                    <th scope="row" colSpan={5}>
                      No Record(s) Found.
                    </th>
                  </tr>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </Typography>
        {/* <Pagination
          total={
            !_.isEmpty(availableAffiliates?.data)
              ? Number(availableAffiliates?.count)
              : 0
          }
          skip={afParams.skip}
          take={afParams.take}
          setParams={setAfParams}
        /> */}
      </CustomModalView>
      <ConfirmDialog
        title={'Resent Invite'}
        floatingBtnShow={true}
        open={resentInvite}
        onConfirm={(act) =>
          act === 'yes' ? resentContactPerson() : setResentInvite(false)
        }
        autoCloseDisable={true}>
        Invite already sent . Now sending to New invite{' '}
        {contactPerson?.email_id} will become active, old invite will become
        inactive. Confirm update?
      </ConfirmDialog>
      <ConfirmDialog
        title="CONFIRM"
        floatingBtnShow={true}
        open={confirmOpen.show}
        onConfirm={(act) =>
          act === 'yes'
            ? onApprove(act)
            : setConfirmOpen({
                show: false,
              })
        }
        autoCloseDisable={true}>
        {
          'A Petitioner organization already exists for the email address provided by you. Do you wish to create another organization with the same email address? '
        }
      </ConfirmDialog>
    </Typography>
  );
};

export default BeneficiaryAndPetitionerForm;
