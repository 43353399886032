import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
import { AttorneyPrivateRoutes } from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import {
  COMMON_ENDPOINT,
  SETUP_ENDPOINT,
  TREE_VIEW_ENDPOINT,
} from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { PetitionAddSchema } from 'utils/ValidatorSchema';

import useStyles from '../../../PrivateStyle';

export const PetitionAdd: React.FC<any> = () => {
  const classes = useStyles();
  const { petitionId } = useParams();
  const navigate: any = useNavigate();
  const [loader, setLoader] = useState(true);
  const [petitionData, setPetitionData] = useState<any>({});
  const [organizations, setOrganizations] = useState<any>([]);
  const [visaTypes, setVisaTypes] = useState<any>([]);
  const [firmData, setFirmData] = useState<any>([]);
  const [attorneyContactPersonList, setAttorneyContactPersonList] =
    useState<any>([]);
  const [leadData, setLeadData] = useState<any>({});
  const [selectedLead, setSelectedLead] = useState<any>({});

  const [leadParams, setLeadParams] = useState<any>({
    name: '',
    skip: 0,
    take: 50,
  });
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleLeadsOpen = () => setIsOpen(true);
  const handleLeadsClose = () => {
    setLeadParams((prev) => ({
      ...prev,
      name: '',
      skip: 0,
      take: 50,
    }));
    setIsOpen(false);
  };

  const debouncedParams = useDebounce(leadParams, 500);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      attorneyId: null,
      beneficiaryId: null,
      caseUserId: null,
      leadId: null,
      organizationId: null,
      petitionerAssociated: false,
      visaId: null,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(PetitionAddSchema),
  });

  const cancelForm = () => {
    resetForm();
    navigate(-1);
  };
  const petitionFormAndBeneficiaryFormCall = (petitionData: any) => {
    const formTypes = [
      { formId: petitionData?.beneficiary_form_id, formType: 'beneficiary' },
      { formId: petitionData?.petitioner_form_id, formType: 'petitioner' },
    ];
    formTypes.map(async (el) => {
      if (!el.formId) return;
      const petitionId = petitionData.id;
      const beneficiaryId = petitionData.beneficiary_id;
      const visaId = petitionData.visa_id;
      const petitionerUser = petitionData.petitioner_user_id;
      const organizationId = petitionData.organization_id;
      const firmId = petitionData.firm_id;
      const attorneyUser = petitionData.attorney_id;
      const leadId = petitionData.lead_id;
      const petitionerAssociated = petitionData?.petitioner_associated;
      const isAffilate = petitionData?.isAffilateCase || '';
      const affilateRoleId = petitionData?.affilateRoleId || '';
      return await getAllListData(
        TREE_VIEW_ENDPOINT(
          el.formId,
          leadId,
          beneficiaryId,
          el.formType,
          petitionId,
          visaId,
          petitionerUser,
          organizationId,
          firmId,
          attorneyUser,
          0,
          0,
          petitionerAssociated,
          0,
          0,
          0,
          isAffilate,
          affilateRoleId,
        ),
      );
    });
  };
  const onSubmitForm = (data: any) => {
    // const lead = leadData.data.find(
    //   (leadInfo) => leadInfo.leaduserid === Number(data['beneficiaryId']),
    // );

    const manualOrg = organizations.find(
      (ch) => ch.ids === Number(data['organizationId']),
    )?.id;

    setLoader(true);
    data['attorneyId'] = Number(data['attorneyId'])
      ? Number(data['attorneyId'])
      : null;
    data['beneficiaryId'] = Number(data['beneficiaryId']);
    data['caseUserId'] = Number(data['caseUserId']);
    data['leadId'] = Number(data['leadId']);
    data['visaId'] = Number(data['visaId']);
    data['organizationId'] = manualOrg ? Number(manualOrg) : null;
    data['firmId'] = userDetail.companyId;
    data['leadUserId'] = selectedLead.leaduserid;
    data['roleId'] = 3;

    if (!_.isUndefined(petitionId)) {
      updateData(petitionId, data, COMMON_ENDPOINT.Petitions)
        .then((resp: any) => {
          resetForm();
          setLoader(false);
          toast.success('Updated successfully', alertOptions);
          navigate(AttorneyPrivateRoutes.PETITIONS.MYPETITIONLISTS);
        })
        .catch((e) => {
          setLoader(false);
        });
    } else {
      addNewData(data, COMMON_ENDPOINT.Petitions)
        .then((resp: any) => {
          resetForm();
          setLoader(false);
          petitionFormAndBeneficiaryFormCall(resp?.data);
          toast.success('Created successfully', alertOptions);
          navigate(AttorneyPrivateRoutes.PETITIONS.MYPETITIONLISTS);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };
  const getInitialStatus = useCallback(async () => {
    return new Promise((resolve, reject) => {
      getAllListData(
        `${COMMON_ENDPOINT.Petitions}?id=${String(
          petitionId,
        )}&benificieryId=&firmId=&organizationId=&visaId=&skip=&take=&order=&sortby=`,
      )
        .then((resp) => {
          resolve(resp.data[0]);
          setPetitionData(resp.data[0]);
          setValue(
            'attorneyId',
            resp.data[0].attorney_user_id
              ? resp.data[0].attorney_user_id
              : null,
          );
          setValue('beneficiaryId', resp.data[0].beneficiary_id);
          setValue('caseUserId', resp.data[0].case_user_id);
          setValue('visaId', resp.data[0].visa_id);
          setValue('leadId', resp.data[0].lead_id);
          setValue('petitionerAssociated', resp.data[0].petitioner_associated);
        })
        .catch((e) => {
          reject(e);
          setLoader(false);
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apiCall = async () => {
    try {
      if (!_.isUndefined(petitionId)) {
        await getInitialStatus();
      }
      getAllListData(`${SETUP_ENDPOINT.SelectMenu}/petitioner`)
        .then(async (orgResp: any) => {
          if (petitionId) {
            const editOrgInfo = await instantState(setPetitionData);
            const orgsIds = (
              orgResp && orgResp.length
                ? orgResp.map((e: any, i: number) => ({ ...e, ids: i + 1 }))
                : []
            ).find(
              (el) =>
                el.status === editOrgInfo.petitioner_associated &&
                el.id === editOrgInfo.petitioner_assigned,
            )?.ids;
            setValue('organizationId', orgsIds);
          }
          setOrganizations(
            orgResp && orgResp.length
              ? orgResp.map((e: any, i: number) => ({ ...e, ids: i + 1 }))
              : [],
          );
        })
        .catch((e: any) => {
          setLoader(false);
        });
      getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`).then(
        (visaResp: any) => {
          setVisaTypes(visaResp.data);
        },
      );
      getAllListData(
        `/${String(userDetail.userRoleName)}${SETUP_ENDPOINT.MyFirm}`,
      ).then((firmResp: any) => {
        setFirmData(firmResp);
      });
      getAllListData(
        `${SETUP_ENDPOINT.SelectMenu}/lawfirm/${String(userDetail.companyId)}`,
      ).then((lawResp: any) => {
        setAttorneyContactPersonList(lawResp);
      });
      // getAllListData(COMMON_ENDPOINT.CreateLead).then((leadResp: any) => {
      //   setLeadData(leadResp.data);
      // });
      setLoader(false);
    } catch (e: any) {
      setLoader(false);
    }
  };

  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setLeadParams((pre) => ({
        ...pre,
        skip: Number(pre.skip) + 50,
      }));
    }
  };

  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllListData(
      `${COMMON_ENDPOINT.CreateLead}?skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}${
        debouncedParams.name !== '' ? '&name='.concat(debouncedParams.name) : ''
      }`,
    )
      .then(async (res: any) => {
        const { data, total } = res ? res : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setLeadData((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          setLeadData(res);
        }
        setGetMoreLoader(false);
        setTableLoader(false);
        const allList = await instantState(setLeadData);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setGetMoreLoader(false);
        setHasMoreResults(false);
      });
  }, [debouncedParams]);

  return (
    <Grid container>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
          <Typography
            component={'div'}
            className="w-title black-white-text d-flex-a"
            sx={{ marginTop: 'unset !important' }}>
            {petitionId ? 'Edit Petition' : 'Start a New Visa Petition'}
          </Typography>
          <Typography component={'div'}>
            <form id="petition-add-form" onSubmit={handleSubmit(onSubmitForm)}>
              <Typography
                component={'div'}
                className="addbox"
                sx={{ marginTop: '0rem !important' }}>
                <Box
                  component="form"
                  className="u-add-in"
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Firm
                        </Typography>
                        <CustomInput
                          value={firmData.firm_name}
                          className={'custom-input'}
                          disabled
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Visa Types <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="visaId"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'visa_code'}
                                valueKey={'visa_id'}
                                options={!_.isEmpty(visaTypes) ? visaTypes : []}
                                name={name}
                                value={value}
                                onChange={(val) => onChange(val)}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.visaId?.message}
                                searchable={true}
                                disabled={!_.isUndefined(petitionId)}
                                tabIndex={2}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Attorney <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="attorneyId"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                isClearable={false}
                                labelKey={'full_name'}
                                valueKey={'id'}
                                options={
                                  !_.isEmpty(attorneyContactPersonList)
                                    ? attorneyContactPersonList
                                    : []
                                }
                                name={name}
                                value={value}
                                onChange={(e) => onChange(e)}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.attorneyId?.message}
                                tabIndex={4}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Choose from Leads{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        {!petitionId ? (
                          // <Controller
                          //   control={control}
                          //   defaultValue=""
                          //   name="beneficiaryId"
                          //   render={({
                          //     field: { onChange, value, name, ref },
                          //     formState,
                          //   }) => {
                          //     return (
                          //       <CustomSelect
                          //         labelKey={'full_name'}
                          //         valueKey={'leaduserid'}
                          //         options={!_.isEmpty(leadData) ? leadData : []}
                          //         name={name}
                          //         value={value}
                          //         sorting={true}
                          //         onChange={(e) => {
                          //           onChange(e);
                          //           const selectedlead = leadData.find(
                          //             (lead) =>
                          //               lead.leaduserid === e.target.value,
                          //           );
                          //           if (selectedlead && selectedlead.leadid) {
                          //             setSelectedLead(selectedlead);
                          //             setValue(
                          //               'leadId',
                          //               selectedlead.leadid
                          //                 ? selectedlead.leadid
                          //                 : null,
                          //             );
                          //             setValue(
                          //               'caseUserId',
                          //               selectedlead.case_manager &&
                          //                 !_.isArray(selectedlead.case_manager)
                          //                 ? selectedlead.case_manager
                          //                 : _.isArray(selectedlead.case_manager)
                          //                 ? selectedlead.case_manager[0]
                          //                 : '',
                          //             );
                          //             setValue(
                          //               'attorneyId',
                          //               selectedlead.attorney_contact_id
                          //                 ? selectedlead.attorney_contact_id
                          //                 : null,
                          //             );
                          //           }
                          //         }}
                          //         placeHolder={'Select'}
                          //         className={'custom-input'}
                          //         error={errors.beneficiaryId?.message}
                          //         searchable={true}
                          //         disabled={!_.isUndefined(petitionId)}
                          //         tabIndex={1}
                          //       />
                          //     );
                          //   }}
                          // />
                          <Typography className="d-flex-a">
                            <Typography
                              className="d-flex-ja"
                              sx={{
                                backgroundColor: '#002c69',
                                borderRadius: '5px',
                                color: '#fff !important',
                                cursor: 'pointer',
                                fontFamily: 'Arial-Medium !important',
                                fontSize: '18px !important',
                                height: '35px',
                                width: '24%',
                              }}
                              onClick={() => {
                                getAllListData(
                                  `${COMMON_ENDPOINT.CreateLead}?skip=0&take=50`,
                                ).then((res: any) => {
                                  setLeadData(res);
                                  handleLeadsOpen();
                                });
                              }}>
                              Select
                            </Typography>
                            <Typography component={'div'}>
                              <Typography
                                component={'div'}
                                className="uadd-title"
                                sx={{
                                  marginLeft: '3rem',
                                  paddingBottom: 'unset !important',
                                  paddingTop: 'unset !important',
                                }}>
                                {!_.isEmpty(selectedLead)
                                  ? selectedLead.full_name
                                  : ''}
                              </Typography>
                            </Typography>
                          </Typography>
                        ) : (
                          <CustomInput
                            placeHolder={''}
                            name={'beneficiary Name'}
                            value={petitionData?.benificiery_name}
                            className={'custom-input'}
                            type="text"
                            disabled={true}
                          />
                        )}

                        {errors.beneficiaryId?.message &&
                          !watch('beneficiaryId') && (
                            <FormHelperText style={{ color: '#FF0000' }}>
                              {errors.beneficiaryId?.message.toString()}
                            </FormHelperText>
                          )}
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Petitioner
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="organizationId"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'org_name'}
                                valueKey={'ids'}
                                tabIndex={3}
                                options={
                                  !_.isEmpty(organizations) ? organizations : []
                                }
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  setValue(
                                    'petitionerAssociated',
                                    e.target.value
                                      ? organizations.find(
                                          (petition) =>
                                            petition.ids === e.target.value,
                                        )?.status
                                      : false,
                                  );
                                }}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.organizationId?.message}
                                searchable={true}
                                disabled={!_.isUndefined(petitionId)}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Case Manager
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="caseUserId"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                isClearable={false}
                                labelKey={'full_name'}
                                valueKey={'id'}
                                options={
                                  !_.isEmpty(attorneyContactPersonList)
                                    ? attorneyContactPersonList
                                    : []
                                }
                                name={name}
                                value={value}
                                onChange={(val) => onChange(val)}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.caseUserId?.message}
                                tabIndex={5}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Typography>
            </form>
            <Grid container className="bottom-fixed">
              <Grid item md={12} sm={12}>
                <Typography component={'div'} className="d-flex-ja">
                  <Button className="cancel-btn mr-2" onClick={cancelForm}>
                    <Typography className="o-box" />
                    Cancel
                  </Button>
                  <Button
                    className="add-btn"
                    type="submit"
                    form={'petition-add-form'}
                    tabIndex={6}>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      )}
      <CustomModalView
        open={isOpen}
        close={handleLeadsClose}
        title="Available Leads"
        value={leadParams.name}
        isFilters={true}
        filterOnChange={(e) => {
          setTableLoader(true);
          setLeadParams((prev) => ({
            ...prev,
            name: e,
            skip: 0,
            take: 50,
          }));
        }}
        fullWidth={true}
        maxWidth={'md'}>
        <Typography
          component={'div'}
          sx={{
            borderRadius: '8px 8px 0 0',
          }}
          className={classes.scrollbox}
          id="scrollParent">
          <InfiniteScroll
            dataLength={
              leadData && leadData.data && leadData.data.length
                ? leadData.data.length
                : 0
            }
            style={{
              overflow: 'unset',
            }}
            next={getMoreList && getMoreList}
            hasMore={hasMoreResults}
            scrollableTarget={'scrollParent'}
            loader={
              getMoreLoader && (
                <div
                  style={{
                    backgroundColor: '#ffffff',
                    textAlign: 'center',
                    width: '100%',
                  }}>
                  <CircularProgress
                    size={30}
                    sx={{
                      color: '#002c69',
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  />
                </div>
              )
            }
            endMessage>
            <table className="lead-table table-hover">
              <thead>
                <tr>
                  <th>Beneficiary Name</th>
                  <th>Beneficiary Email</th>
                  <th>Petitioner Name</th>
                  <th>Visa Type</th>
                  <th>Lead Date</th>
                </tr>
              </thead>
              <tbody>
                {tableLoader ? (
                  <tr>
                    <td colSpan={8}>
                      <div
                        style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          width: '100%',
                        }}>
                        <CircularProgress
                          size={30}
                          sx={{
                            color: '#002c69',
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {!_.isEmpty(leadData) && leadData.data.length > 0
                      ? leadData.data.map((lead, index) => (
                          <tr
                            style={{ cursor: 'pointer' }}
                            key={index}
                            onClick={() => {
                              setValue('beneficiaryId', lead.id);
                              if (lead.leadid) {
                                setSelectedLead(lead);
                                setValue(
                                  'leadId',
                                  lead.leadid ? lead.leadid : null,
                                );
                                setValue(
                                  'caseUserId',
                                  lead.case_manager &&
                                    !_.isArray(lead.case_manager)
                                    ? lead.case_manager
                                    : _.isArray(lead.case_manager)
                                    ? lead.case_manager[0]
                                    : '',
                                );
                                setValue(
                                  'attorneyId',
                                  lead.attorney_contact_id
                                    ? lead.attorney_contact_id
                                    : null,
                                );

                                const orgsIds = organizations.find(
                                  (ch) =>
                                    ch.status === lead.petitioner_associated &&
                                    ch.id === lead.petitioner_assigned,
                                )?.ids;

                                setValue('organizationId', orgsIds);
                                setValue(
                                  'petitionerAssociated',
                                  lead.petitioner_associated,
                                );

                                setValue(
                                  'visaId',
                                  lead.visa_type && lead.visa_type.length
                                    ? lead.visa_type[0]
                                    : null,
                                );
                              }
                              handleLeadsClose();
                            }}>
                            <td>
                              <a
                                style={{
                                  color: '#1093df',
                                  textTransform: 'capitalize',
                                }}>
                                {lead.full_name}
                              </a>
                            </td>
                            <td>{lead.email_id ? lead.email_id : '-'}</td>
                            <td>
                              {lead.petitioner_organization_name
                                ? lead.petitioner_organization_name
                                : '-'}
                            </td>
                            <td>{lead.visa_name ? lead.visa_name : '-'}</td>
                            <td>
                              {lead.leaddate
                                ? moment(lead.leaddate).format('yyyy-MM-DD')
                                : '-'}
                            </td>
                          </tr>
                        ))
                      : !tableLoader && (
                          <tr className={'text-center'}>
                            <th scope="row" colSpan={4}>
                              No Record(s) Found.
                            </th>
                          </tr>
                        )}
                  </>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </Typography>
      </CustomModalView>
    </Grid>
  );
};

export default PetitionAdd;
