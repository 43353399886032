import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import useStyles from '../../../PrivateStyle';

type QAIProps = {
  control;
  errors;
  setValue;
  watch;
  processingTypes?: any;
  filingTypes?: any;
};

const QuestionAndIssues: FC<QAIProps> = ({
  control,
  errors,
  setValue,
  watch,
  processingTypes,
  filingTypes,
}) => {
  const classes = useStyles();
  return (
    <Typography component={'div'}>
      <Grid container>
        <Grid item md={12}>
          <Typography component={'div'} className={classes.leadwiteBox}>
            <Typography
              component={'div'}
              className="uadd-title"
              sx={{ paddingTop: '0px !important' }}>
              Processing Questions & Potential Issues
            </Typography>
            <Box
              component="form"
              className="lead-box"
              sx={{ paddingRight: '20px !important' }}
              noValidate
              autoComplete="off">
              <Grid container columnSpacing={{ md: 2, sm: 1 }}>
                <Grid item md={12} sm={12}>
                  <Typography
                    component={'div'}
                    className="custom-field-row"
                    style={{ marginBottom: '0px !important' }}>
                    <Typography component={'div'} className="custom-label">
                      Premium Processing
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue="no"
                      name="premiumProcessing"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}>
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        );
                      }}
                    />
                  </Typography>
                </Grid>

                <Grid item md={6} sm={12}>
                  <Typography
                    component={'div'}
                    className="custom-field-row"
                    style={{ marginBottom: '0px !important' }}>
                    <Typography component={'div'} className="custom-label">
                      Type of Processing
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={'processingType'}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomSelect
                            labelKey={'processing_type'}
                            valueKey={'id'}
                            options={
                              !_.isEmpty(processingTypes) ? processingTypes : []
                            }
                            name={name}
                            value={value}
                            onChange={onChange}
                            placeHolder={'Select'}
                            className={'custom-input'}
                            error={errors.processingType?.message}
                            searchable={true}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography
                    component={'div'}
                    className="custom-field-row"
                    style={{ marginBottom: '0px !important' }}>
                    <Typography component={'div'} className="custom-label">
                      Type of Filing
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={'fillingType'}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomSelect
                            labelKey={'filing_type'}
                            valueKey={'id'}
                            options={!_.isEmpty(filingTypes) ? filingTypes : []}
                            name={name}
                            value={value}
                            onChange={onChange}
                            placeHolder={'Select'}
                            className={'custom-input'}
                            error={errors.fillingType?.message}
                            searchable={true}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>

                <Grid item md={6} sm={12}>
                  <Typography
                    component={'div'}
                    className="custom-field-row"
                    style={{ marginBottom: '0px !important' }}>
                    <Typography component={'div'} className="custom-label">
                      Number of Dependents Filing With (Spouse or child and only
                      if the beneficiary and dependent are in the U.S.)
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={'numberOfDependents'}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomInput
                            placeHolder={'Number of Dependents'}
                            name={name}
                            value={value}
                            className={'custom-input'}
                            error={errors.numberOfDependents?.message || ''}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography
                    component={'div'}
                    className="custom-field-row"
                    style={{ marginBottom: '0px !important' }}>
                    <Typography component={'div'} className="custom-label">
                      Number of Employees in the U.S. Company Today
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={'totalEmployeesInCompany'}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomInput
                            placeHolder={'Number of Employees'}
                            name={name}
                            value={value}
                            className={'custom-input'}
                            error={
                              errors.totalEmployeesInCompany?.message || ''
                            }
                            onChange={(val) =>
                              onChange(val.target.value.replace(/[^\d]/g, ''))
                            }
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Typography>
        </Grid>

        <Grid item md={12}>
          <Typography component={'div'} className={classes.leadwiteBox}>
            <Typography
              component={'div'}
              className="uadd-title"
              sx={{ paddingTop: '0px !important' }}>
              Issues
            </Typography>

            <Box
              component="form"
              className="lead-box"
              style={{ padding: '1rem !important' }}
              noValidate
              autoComplete="off">
              <Grid container columnSpacing={{ md: 2, sm: 1 }}>
                <Grid item md={6} sm={12} className="d-flex-a">
                  <Typography
                    component={'div'}
                    className="custom-field-row u-pr-1 d-flex"
                    style={{ marginBottom: '0px !important' }}>
                    <Typography
                      component={'div'}
                      className="custom-label"
                      sx={{ margin: '.5rem 0 0 0 !important' }}>
                      Employment Offered
                    </Typography>

                    <Controller
                      control={control}
                      defaultValue={'yes'}
                      name={'employmentOffered'}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <RadioGroup
                            className="ml-2"
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}>
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography
                    component={'div'}
                    className="custom-field-row u-pr-1"
                    style={{ marginBottom: '0px !important' }}>
                    <Controller
                      control={control}
                      defaultValue={null}
                      name={'employmentOfferedExplain'}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomInput
                            placeHolder={'Describe'}
                            name={name}
                            value={value}
                            className={'custom-textarea'}
                            error={
                              errors.employmentOfferedExplain?.message || ''
                            }
                            onChange={(e) => {
                              onChange(e);
                            }}
                            isTextArea={true}
                            disabled={
                              watch('employmentOffered') === 'no' ? false : true
                            }
                          />
                        );
                      }}
                    />
                    <Typography
                      component={'div'}
                      className="custom-label"
                      sx={{ margin: '.5rem 0 0 0 !important' }}>
                      If No, Describe
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item md={6} sm={12} className="d-flex-a">
                  <Typography
                    component={'div'}
                    className="custom-field-row u-pr-1 d-flex"
                    style={{ marginBottom: '0px !important' }}>
                    <Typography
                      component={'div'}
                      className="custom-label"
                      sx={{ margin: '.5rem 0 0 0 !important' }}>
                      Visa Denials
                    </Typography>
                    <Typography className="d-flex pl-4">
                      <Controller
                        control={control}
                        defaultValue={'no'}
                        name={'visaDenials'}
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <RadioGroup
                              className="ml-2"
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}>
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          );
                        }}
                      />
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography
                    component={'div'}
                    className="custom-field-row u-pr-1"
                    style={{ marginBottom: '0px !important' }}>
                    <Controller
                      control={control}
                      defaultValue={null}
                      name={'visaDenialsExplain'}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomInput
                            placeHolder={'Describe'}
                            name={name}
                            value={value}
                            className={'custom-textarea'}
                            error={errors.visaDenialsExplain?.message || ''}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            isTextArea={true}
                            disabled={
                              watch('visaDenials') === 'yes' ? false : true
                            }
                          />
                        );
                      }}
                    />
                    <Typography
                      component={'div'}
                      className="custom-label"
                      sx={{ margin: '.5rem 0 0 0 !important' }}>
                      If Yes, Describe
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item md={6} sm={12} className="d-flex-a">
                  <Typography
                    component={'div'}
                    className="custom-field-row u-pr-1 d-flex"
                    style={{ marginBottom: '0px !important' }}>
                    <Typography
                      component={'div'}
                      className="custom-label"
                      sx={{ margin: '.5rem 0 0 0 !important' }}>
                      Convictions
                    </Typography>
                    <Typography className="d-flex pl-4">
                      <Controller
                        control={control}
                        defaultValue={'no'}
                        name={'convictions'}
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <RadioGroup
                              className="ml-2"
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}>
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          );
                        }}
                      />
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography
                    component={'div'}
                    className="custom-field-row u-pr-1"
                    style={{ marginBottom: '0px !important' }}>
                    <Controller
                      control={control}
                      defaultValue={null}
                      name={'convictionsExplain'}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomInput
                            placeHolder={'Describe'}
                            name={name}
                            value={value}
                            className={'custom-textarea'}
                            error={errors.convictionsExplain?.message || ''}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            isTextArea={true}
                            disabled={
                              watch('convictions') === 'yes' ? false : true
                            }
                          />
                        );
                      }}
                    />
                    <Typography
                      component={'div'}
                      className="custom-label"
                      sx={{ margin: '.5rem 0 0 0 !important' }}>
                      If Yes, Describe
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item md={6} sm={12} className="d-flex-a">
                  <Typography
                    component={'div'}
                    className="custom-field-row u-pr-1 d-flex"
                    style={{ marginBottom: '0px !important' }}>
                    <Typography
                      component={'div'}
                      className="custom-label"
                      sx={{ margin: '.5rem 0 0 0 !important' }}>
                      Overstay US Visa
                    </Typography>
                    <Typography className="d-flex pl-1">
                      <Controller
                        control={control}
                        defaultValue={'no'}
                        name={'overstayUsVisa'}
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <RadioGroup
                              className="ml-2"
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}>
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          );
                        }}
                      />
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography
                    component={'div'}
                    className="custom-field-row u-pr-1"
                    style={{ marginBottom: '0px !important' }}>
                    <Controller
                      control={control}
                      defaultValue={null}
                      name={'overstayUsVisaExplain'}
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomInput
                            placeHolder={'Describe'}
                            name={name}
                            value={value}
                            className={'custom-textarea'}
                            error={errors.overstayUsVisaExplain?.message || ''}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            isTextArea={true}
                            disabled={
                              watch('overstayUsVisa') === 'yes' ? false : true
                            }
                          />
                        );
                      }}
                    />
                    <Typography
                      component={'div'}
                      className="custom-label"
                      sx={{ margin: '.5rem 0 0 0 !important' }}>
                      If Yes, Describe
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};

export default QuestionAndIssues;
