import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import CustomCheckBox from 'components/CustomCheckBox/customCheckBox';
import CustomInput from 'components/CustomInput/customInput';
import CustomTextArea from 'components/CustomTextArea/customTextArea';
import { instantState } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData } from 'services/CommonServices';
import {
  ParsingCount,
  storeI_129ExtractionLength,
} from 'store/actions/LeadsOrPetitionerAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BASE_URL, COMMON_ENDPOINT } from 'utils/Constant';
import {
  alertOptions,
  checkAns,
  checkRadioButtonEnableAns,
  getColorFormat,
  getColorFormatbyTextArea,
} from 'utils/helper';

import { DocumentViewer } from '../CustomDocumentViewer/CustomeDocumentViewer';

export const I129ParsingView: React.FC<any> = () => {
  const navigate = useNavigate();
  const { docId, extractionId } = useParams();
  const [value, setValue] = React.useState('1');
  const dispatch = useAppDispatch();
  const { userInfo: userDetail }: any = useAppSelector(
    (store: any) => store.userLogin,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ParsingData, setParsingData] = React.useState(null);
  const [fileSrc, setFileSrc] = React.useState('');
  const [penQuestionList, setPenQuestionList] = React.useState([]);
  const [benQuestionList, setBenQuestionList] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [inputDisable, setInputDisable] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getFormPdfFields = (questionList: Array<any>, parsingData) => {
    const finalArr = [];
    questionList.map((arrval) => {
      if (arrval.select) {
        const originalObj = {
          answer: arrval.answer,
          formId: arrval.formId,
          questionId: arrval.questionId,
        };
        finalArr.push(originalObj);
      }
    });
    return {
      documentFor: parsingData.roleId,
      documentId: parsingData.uploadId,
      documentUploadedFrom:
        parsingData['petition_id'] && parsingData.petition_id
          ? 'petition'
          : 'lead',
      leadOrLeadUserOrPetitionId:
        parsingData['petition_id'] && parsingData.petition_id
          ? parsingData.petition_id
          : parsingData.lead_user_id
          ? parsingData.lead_user_id
          : '',
      list: finalArr,
    };
  };
  const updatePdfValues = (questionList: Array<any>, parsingData: any) => {
    const submittedFormValue = getFormPdfFields(questionList, parsingData);
    addNewData(
      submittedFormValue,
      `${COMMON_ENDPOINT.uploadDocumentI129ParsingDataUpdate}`,
    )
      .then(() => {
        navigate(-1);
        setLoader(false);
        getParsingCount();
        toast.success('Data has been updated.');
      })
      .catch(() => {
        setLoader(false);
        toast.error(
          'Unable to update the details. Please try again.',
          alertOptions,
        );
      });
  };

  const getFormData = (questionList: Array<any>, parsingData) => {
    const finalArr = [];
    questionList.map((arrval) => {
      if (arrval.select) {
        const originalObj = {
          answer:
            arrval.response_type === 5 || arrval.response_type === 6
              ? arrval.multiple_pdf_field && arrval.multiple_pdf_field.length
                ? JSON.stringify(arrval.multiple_pdf_field)
                : JSON.stringify([])
              : arrval.answer,
          formId: arrval.formId,
          questionId: arrval.questionId,
        };
        finalArr.push(originalObj);
      }
    });
    return {
      documentId: parsingData.docId ? parsingData.docId : '',
      formId: finalArr[0].formId,
      leadUserId: parsingData.lead_user_id ? parsingData.lead_user_id : '',
      list: finalArr,
      petitionId: parsingData.petition_id ? parsingData.petition_id : '',
    };
  };
  const petitionBasedUpdate = (questionList, parsingData) => {
    const submittedFormValue = getFormData(questionList, parsingData);
    addNewData(
      submittedFormValue,
      `${String(
        COMMON_ENDPOINT.uploadDocumentI129PetitionBasedUpdate(parsingData.id),
      )}`,
    )
      .then(() => {
        navigate(-1);
        setLoader(false);
        getParsingCount();
        toast.success('Updated successfully');
      })
      .catch(() => {
        setLoader(false);
        toast.error('Could not update. Please try again after sometime');
      });
  };

  const leadBasedUpdate = (questionList, parsingData) => {
    const submittedFormValue = getFormData(questionList, parsingData);
    addNewData(
      submittedFormValue,
      `${COMMON_ENDPOINT.uploadDocumentI129LeadBasedUpdate(
        parsingData.id,
        parsingData &&
          parsingData['bulkDocPdfView'] &&
          parsingData.bulkDocPdfView
          ? 'bulk'
          : '',
      )}`,
    )
      .then(() => {
        navigate(-1);
        setLoader(false);
        getParsingCount();
        toast.success('Updated successfully');
      })
      .catch(() => {
        setLoader(false);
        toast.error('Could not update. Please try again after sometime');
      });
  };
  const onConfirm = async () => {
    const questionList: Array<any> = [
      ...(await instantState(setPenQuestionList)),
      ...(await instantState(setBenQuestionList)),
    ];
    const checkAllUnSelect =
      questionList && !questionList.length
        ? true
        : questionList.every((val) => !val.select);
    if (checkAllUnSelect) {
      toast.error('Please select atleast one question.', alertOptions);
      return;
    }
    const parsingData: any = await instantState(setParsingData);
    setLoader(true);
    if (
      parsingData &&
      parsingData['bulkDocPdfView'] &&
      parsingData.bulkDocPdfView
    ) {
      updatePdfValues(questionList, parsingData);
      return;
    }
    parsingData['petition_id'] && parsingData.petition_id
      ? petitionBasedUpdate(questionList, parsingData)
      : leadBasedUpdate(questionList, parsingData);
  };
  const getPdfFieldBulk = (visaId, petitionOrbenRoleId, bodyData) => {
    addNewData(
      bodyData,
      `${COMMON_ENDPOINT.uploadDocumentI129ParsingData(
        visaId,
        petitionOrbenRoleId,
      )}`,
    ).then((res) => {
      const { data }: { data: Array<any> } =
        res && res.data && res.data.length > 0 ? res : { data: [] };
      if (petitionOrbenRoleId === 2) {
        setPenQuestionList(
          data.map((e) => {
            return { ...e, select: true };
          }),
        );
      } else if (petitionOrbenRoleId === 4) {
        setBenQuestionList(
          data.map((e) => {
            return { ...e, select: true };
          }),
        );
      }
    });
  };
  const getPdfFieldValuesInSectionBased = (
    parsingId: number,
    petitionOrbenId: number,
  ) => {
    getAllListData(
      `${COMMON_ENDPOINT.uploadDocumentI129ExtractionData(
        parsingId,
        petitionOrbenId,
      )}`,
    ).then((res) => {
      const { data } =
        res && res.data && res.data.length > 0 ? res : { data: [] };
      if (petitionOrbenId === 2) {
        setPenQuestionList(
          data.map((e) => {
            return { ...e, select: true };
          }),
        );
      } else if (petitionOrbenId === 4) {
        setBenQuestionList(
          data.map((e) => {
            return { ...e, select: true };
          }),
        );
      }
    });
  };
  const mappingKey = useCallback(async () => {
    const parsingData: any = await instantState(setParsingData);
    if (
      parsingData &&
      // eslint-disable-next-line no-prototype-builtins
      parsingData['bulkDocPdfView'] &&
      parsingData.bulkDocPdfView
    ) {
      // if (parsingData.data && parsingData.data.length) {
      //   parsingData.data.map((val) => {
      //     if (val) {
      //       const obj: any = val;
      //       for (const key in obj) {
      //         // eslint-disable-next-line no-prototype-builtins
      //         if (obj.hasOwnProperty(key)) {
      //           finalArr.push(obj[key]);
      //         }
      //       }
      //     }
      //     return finalArr;
      //   });
      // }
      // New method
      const finalArr = [];
      if (parsingData.data && Object.keys(parsingData.data).length) {
        finalArr.push(parsingData.data);
      }
      const bodyData = {
        documentData: finalArr,
      };
      setInputDisable(parsingData.is_read);
      getPdfFieldBulk(parsingData.visaId, 2, bodyData); // petion Queation List
      getPdfFieldBulk(parsingData.visaId, 4, bodyData); // beneficiary Question list
      return;
    }

    setInputDisable(parsingData.is_read);
    getPdfFieldValuesInSectionBased(parsingData.id, 2); // petion Queation List
    getPdfFieldValuesInSectionBased(parsingData.id, 4); // beneficiary Question list
  }, []);
  const getCurrentUploadDocInfo = useCallback(() => {
    getAllListData(`${COMMON_ENDPOINT.GetParticularDocId}/${String(docId)}`)
      .then((res) => {
        setLoader(false);
        if (res) {
          const obj = {
            beneficiaryId: res.user_id,
            bulkDocPdfView: res.from_bulk_document_parsing,
            data: res.document_data,
            defaultDocUpload: true,
            docId: res.docId,
            documentFor: res.doc_uploaded_from,
            id: res.form_extraction_rowid,
            is_read:
              // eslint-disable-next-line no-prototype-builtins
              res && res['is_parsing_value_submitted']
                ? res.is_parsing_value_submitted
                : false,
            lead_user_id: res.leadUserId,
            orgId: res.user_id,
            parsingTypeCheck: res.parsingType,
            petitionId:
              res.doc_uploaded_from === 'petition'
                ? res.petitionId
                : res.leadUserId,
            petition_id:
              res.doc_uploaded_from === 'petition' ? res.petitionId : '',
            previewValues: res.document_data,
            previewView: true,
            roleId: res.role_id,
            selectedDocumentId: res.doc_submitted_id,
            uploadId: res.id,
            visaId: res.visaId ? res.visaId : '',
          };
          setParsingData(obj);
          setFileSrc(
            `${BASE_URL}/upload-documents/viewDocument?key=${String(res.id)}`,
          );
          mappingKey();
        }
      })
      .catch(() => {
        setLoader(false);
      });
  }, [docId, mappingKey]);
  const getExtarctioIdGetValue = useCallback(() => {
    getAllListData(
      `${COMMON_ENDPOINT.uploadDocumentI128GetExtractionInfo(
        '',
        '',
        '',
        extractionId,
      )}`,
    )
      .then((res) => {
        setLoader(false);
        const obj = res.data[0];
        setParsingData(obj);
        setFileSrc(
          `${BASE_URL}/upload-documents/viewDocument?key=${String(obj.docId)}`,
        );
        mappingKey();
      })
      .catch(() => {
        setLoader(false);
      });
  }, [extractionId, mappingKey]);
  useEffect(() => {
    if (docId) {
      getCurrentUploadDocInfo();
    } else if (extractionId) {
      getExtarctioIdGetValue();
    }
  }, [docId, extractionId, getCurrentUploadDocInfo, getExtarctioIdGetValue]);

  const checkedOptionVal = (
    e,
    checkedOption,
    qus,
    index,
    parseInfo,
    setParseInfo,
  ) => {
    if (
      qus &&
      // eslint-disable-next-line no-prototype-builtins
      qus['multiple_pdf_field'] &&
      qus.multiple_pdf_field.length
    ) {
      const newArr = [];
      qus.multiple_pdf_field.map((val) => {
        if (val.response.toLowerCase() === checkedOption.toLowerCase()) {
          if (qus.response_type === 5) {
            val.status = e.target.checked;
          } else {
            val.status = e.target.checked;
          }
        } else if (val.response.toLowerCase() !== checkedOption.toLowerCase()) {
          if (qus.response_type === 5) {
            val.status = false;
          }
        }
        newArr.push({ ...val });
      });
      qus.multiple_pdf_field = [];
      newArr.map((newVal) => {
        qus.multiple_pdf_field.push({ ...newVal });
      });
      qus.answer = JSON.stringify(qus.multiple_pdf_field);
      const arr = parseInfo;
      arr[index] = qus;
      setParseInfo([...arr]);
    }
  };

  const getParsingCount = async () => {
    const data = await ParsingCount(userDetail?.id);
    dispatch(storeI_129ExtractionLength(data));
  };

  return (
    <Grid container>
      <Grid item md={12} className="sticky-tab">
        <Typography component={'div'} className="d-flex-jae">
          {value === '1' && (
            <Button className="n-cancel-btn mr-1" onClick={() => navigate(-1)}>
              Back
            </Button>
          )}
          <Button
            className="n-add-btn mr-1"
            onClick={() => setValue(value === '1' ? '2' : '1')}>
            {value === '1' ? 'Next' : 'Back'}
          </Button>
          {!inputDisable && value === '2' && (
            <Button className="n-add-btn" onClick={() => onConfirm()}>
              Submit
            </Button>
          )}
        </Typography>
      </Grid>
      <Grid item md={12}>
        {loader && <div className="liberty-loader" />}
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography
              className="assem-box"
              id="parentdocId"
              sx={{
                maxHeight: '106vh',
                minHeight: '100vh',
                overflow: 'auto',
              }}>
              <DocumentViewer
                fileBlob={fileSrc}
                fileType={'pdf'}
                width={
                  document.getElementById('parentdocId') &&
                  document.getElementById('parentdocId').offsetWidth
                    ? `${
                        document.getElementById('parentdocId').offsetWidth - 100
                      }px`
                    : 'auto'
                }
              />
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Box sx={{ width: '100%' }} className="parsingTab">
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example">
                    <Tab label="Petitioner Form" value="1" />
                    <Tab label="Beneficiary Form" value="2" />
                  </TabList>
                </Box>
                <Typography component={'div'}>
                  <TabPanel
                    value="1"
                    sx={{
                      padding: 'unset',
                    }}>
                    <Typography component={'div'}>
                      <Box
                        component="form"
                        className="u-add-in"
                        sx={{
                          backgroundColor: 'unset !important',
                          border: 'unset !important',
                          maxHeight: '100vh',
                          overflowX: 'hidden',
                          overflowY: 'auto',
                          padding: '1rem !important',
                        }}
                        noValidate
                        autoComplete="off">
                        {penQuestionList && penQuestionList.length > 0 ? (
                          penQuestionList.map((item, index) => (
                            <Grid container spacing={2} key={index}>
                              <Grid item md={11}>
                                <Typography
                                  component={'div'}
                                  className="custom-field u-pr-1">
                                  <Typography
                                    component={'div'}
                                    className="custom-label">
                                    {item?.question}
                                  </Typography>
                                  {/* <div className="mb-1">{item?.lincode}</div> */}

                                  {[2].includes(item.response_type) && (
                                    <CustomTextArea
                                      className={''.concat(
                                        item?.answerConfidence !== '90'
                                          ? getColorFormatbyTextArea(
                                              item?.answerConfidence,
                                            )
                                          : 'custom-textarea',
                                      )}
                                      placeHolder=""
                                      onChange={(e) => {
                                        const arr = penQuestionList;
                                        arr[index].answer = e.target.value;
                                        setPenQuestionList([...arr]);
                                      }}
                                      value={item?.answer}
                                      disabled={inputDisable}
                                    />
                                  )}

                                  {![5, 6, 2].includes(item.response_type) && (
                                    <CustomInput
                                      className={''.concat(
                                        item?.answerConfidence !== '90'
                                          ? getColorFormat(
                                              item?.answerConfidence,
                                            )
                                          : 'custom-input',
                                      )}
                                      placeHolder=""
                                      onChange={(e) => {
                                        const arr = penQuestionList;
                                        arr[index].answer = e.target.value;
                                        setPenQuestionList([...arr]);
                                      }}
                                      value={item?.answer}
                                      disabled={inputDisable}
                                    />
                                  )}

                                  {[6].includes(item.response_type) &&
                                    (item.response && item.response.length
                                      ? item.response.map(
                                          (checkVal, checkBoxIndex) => (
                                            <Typography
                                              key={checkBoxIndex}
                                              component={'div'}>
                                              <CustomCheckBox
                                                name={checkVal}
                                                checked={checkAns(
                                                  checkVal,
                                                  item.answer,
                                                )}
                                                value={checkVal}
                                                onChange={(event) =>
                                                  checkedOptionVal(
                                                    event,
                                                    checkVal,
                                                    item,
                                                    index,
                                                    penQuestionList,
                                                    setPenQuestionList,
                                                  )
                                                }
                                                disabled={inputDisable}
                                              />
                                            </Typography>
                                          ),
                                        )
                                      : null)}

                                  {[5].includes(item.response_type) &&
                                    (item.response && item.response.length
                                      ? item.response.map(
                                          (checkVal, checkBoxIndex) => (
                                            <Typography
                                              component={'div'}
                                              key={checkBoxIndex}>
                                              <Typography component={'div'}>
                                                <FormControlLabel
                                                  className="radio-bottom"
                                                  value={checkVal}
                                                  name={item.questionId}
                                                  checked={checkAns(
                                                    checkVal,
                                                    item.answer,
                                                  )}
                                                  control={<Radio />}
                                                  onChange={(event) =>
                                                    checkedOptionVal(
                                                      event,
                                                      checkVal,
                                                      item,
                                                      index,
                                                      penQuestionList,
                                                      setPenQuestionList,
                                                    )
                                                  }
                                                  disabled={inputDisable}
                                                  label={checkVal}
                                                />
                                              </Typography>
                                              {item.response.length - 1 ===
                                                checkBoxIndex &&
                                                !inputDisable &&
                                                checkRadioButtonEnableAns(
                                                  item.answer,
                                                ) && (
                                                  <Typography
                                                    className="mt-1 n-add-btn"
                                                    component={'button'}
                                                    onClick={() =>
                                                      checkedOptionVal(
                                                        {
                                                          target: {
                                                            checked: false,
                                                          },
                                                        },
                                                        '',
                                                        item,
                                                        index,
                                                        penQuestionList,
                                                        setPenQuestionList,
                                                      )
                                                    }>
                                                    Reset
                                                  </Typography>
                                                )}
                                            </Typography>
                                          ),
                                        )
                                      : null)}
                                </Typography>
                              </Grid>
                              <Grid item md={1} className="d-flex-a">
                                <Checkbox
                                  checked={item?.select}
                                  onChange={(e) => {
                                    const arr = penQuestionList;
                                    arr[index].select = e.target.checked;
                                    setPenQuestionList([...arr]);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ))
                        ) : (
                          <Typography
                            component={'div'}
                            sx={{
                              color: 'red',
                              textAlign: 'center',
                              width: '100%',
                            }}>
                            {'No Record(s) Found.'}
                          </Typography>
                        )}
                      </Box>
                    </Typography>
                  </TabPanel>
                  <TabPanel
                    value="2"
                    sx={{
                      padding: 'unset',
                    }}>
                    <Typography component={'div'}>
                      <Box
                        component="form"
                        className="u-add-in"
                        sx={{
                          backgroundColor: 'unset !important',
                          border: 'unset !important',
                          maxHeight: '100vh',
                          overflowX: 'hidden',
                          overflowY: 'auto',
                          padding: '1rem !important',
                        }}
                        noValidate
                        autoComplete="off">
                        {benQuestionList && benQuestionList.length > 0 ? (
                          benQuestionList.map((item, index) => (
                            <Grid container spacing={2} key={index}>
                              <Grid item md={11}>
                                <Typography
                                  component={'div'}
                                  className="custom-field u-pr-1">
                                  <Typography
                                    component={'div'}
                                    className="custom-label">
                                    {item?.question}
                                  </Typography>
                                  {/* <div className="mb-1">{item?.lincode}</div> */}

                                  {[2].includes(item.response_type) && (
                                    <CustomTextArea
                                      className={''.concat(
                                        item?.answerConfidence !== '90'
                                          ? getColorFormatbyTextArea(
                                              item?.answerConfidence,
                                            )
                                          : 'custom-textarea',
                                      )}
                                      placeHolder=""
                                      onChange={(e) => {
                                        const arr = benQuestionList;
                                        arr[index].answer = e.target.value;
                                        setBenQuestionList([...arr]);
                                      }}
                                      value={item?.answer}
                                      disabled={inputDisable}
                                    />
                                  )}

                                  {![5, 6, 2].includes(item.response_type) && (
                                    <CustomInput
                                      className={''.concat(
                                        item?.answerConfidence !== '90'
                                          ? getColorFormat(
                                              item?.answerConfidence,
                                            )
                                          : 'custom-input',
                                      )}
                                      placeHolder=""
                                      onChange={(e) => {
                                        const arr = benQuestionList;
                                        arr[index].answer = e.target.value;
                                        setBenQuestionList([...arr]);
                                      }}
                                      value={item?.answer}
                                      disabled={inputDisable}
                                    />
                                  )}

                                  {[6].includes(item.response_type) &&
                                    (item.response && item.response.length
                                      ? item.response.map(
                                          (checkVal, checkBoxIndex) => (
                                            <Typography
                                              key={checkBoxIndex}
                                              component={'div'}>
                                              <CustomCheckBox
                                                name={checkVal}
                                                checked={checkAns(
                                                  checkVal,
                                                  item.answer,
                                                )}
                                                value={checkVal}
                                                onChange={(event) =>
                                                  checkedOptionVal(
                                                    event,
                                                    checkVal,
                                                    item,
                                                    index,
                                                    benQuestionList,
                                                    setBenQuestionList,
                                                  )
                                                }
                                                disabled={inputDisable}
                                              />
                                            </Typography>
                                          ),
                                        )
                                      : null)}

                                  {[5].includes(item.response_type) &&
                                    (item.response && item.response.length
                                      ? item.response.map(
                                          (checkVal, checkBoxIndex) => (
                                            <Typography
                                              component={'div'}
                                              key={checkBoxIndex}>
                                              <Typography component={'div'}>
                                                <FormControlLabel
                                                  className="radio-bottom"
                                                  value={checkVal}
                                                  name={item.questionId}
                                                  checked={checkAns(
                                                    checkVal,
                                                    item.answer,
                                                  )}
                                                  control={<Radio />}
                                                  onChange={(event) =>
                                                    checkedOptionVal(
                                                      event,
                                                      checkVal,
                                                      item,
                                                      index,
                                                      benQuestionList,
                                                      setBenQuestionList,
                                                    )
                                                  }
                                                  disabled={inputDisable}
                                                  label={checkVal}
                                                />
                                              </Typography>
                                              {item.response.length - 1 ===
                                                checkBoxIndex &&
                                                !inputDisable &&
                                                checkRadioButtonEnableAns(
                                                  item.answer,
                                                ) && (
                                                  <Typography
                                                    className="mt-1 n-add-btn"
                                                    component={'button'}
                                                    onClick={() =>
                                                      checkedOptionVal(
                                                        {
                                                          target: {
                                                            checked: false,
                                                          },
                                                        },
                                                        '',
                                                        item,
                                                        index,
                                                        benQuestionList,
                                                        setBenQuestionList,
                                                      )
                                                    }>
                                                    Reset
                                                  </Typography>
                                                )}
                                            </Typography>
                                          ),
                                        )
                                      : null)}
                                </Typography>
                              </Grid>
                              <Grid item md={1} className="d-flex-a">
                                <Checkbox
                                  checked={item?.select}
                                  onChange={(e) => {
                                    const arr = benQuestionList;
                                    arr[index].select = e.target.checked;
                                    setBenQuestionList([...arr]);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ))
                        ) : (
                          <Typography
                            component={'div'}
                            sx={{
                              color: 'red',
                              textAlign: 'center',
                              width: '100%',
                            }}>
                            {'No Record(s) Found.'}
                          </Typography>
                        )}
                      </Box>
                    </Typography>
                  </TabPanel>
                </Typography>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default I129ParsingView;
