import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

export const PaymentMethodAndTerms: React.FC<any> = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [searchParams] = useSearchParams();

  const [loader, setLoader] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState<any>({});

  const updateStripeCheckout = async (type) => {
    if (
      paymentInfo.paymentGatwayType &&
      paymentInfo.paymentGatwayType === 'stripe'
    ) {
      setLoader(true);
      const payload = {
        hashKey: searchParams.get('hashKey'),
        paymentType: type,
      };
      await addNewData(
        payload,
        `${COMMON_ENDPOINT.ContractPayment}/stripe/checkout`,
      )
        .then((resp: any) => {
          setLoader(false);
          resp && resp.url ? (window.location.href = resp.url) : null;
        })
        .catch((e) => {
          setLoader(false);
        });
    } else {
      type === 'ach'
        ? navigate(
            `/secure-payment/transfer/ach?hashKey=${searchParams.get(
              'hashKey',
            )}`,
          )
        : navigate(
            `/secure-payment/transfer/card?hashKey=${searchParams.get(
              'hashKey',
            )}`,
          );
    }
  };

  useEffect(() => {
    if (searchParams.get('hashKey') !== '') {
      getAllListData(
        `${COMMON_ENDPOINT.ContractPayment}?hashKey=${searchParams.get(
          'hashKey',
        )}`,
      )
        .then((info: any) => {
          setPaymentInfo(info.data);
          const paymentAmount =
            info.data && info.data.paymentTotal ? info.data.paymentTotal : '';
          if (!paymentAmount) {
            navigate(`/secure-payment?hashKey=${searchParams.get('hashKey')}`);
            return;
          }
          setLoader(false);
        })
        .catch((e) => {
          // toast.error(
          //   'The link is invalid. Please contact your attorney.',
          //   alertOptions,
          // );
          navigate('/login');
          setLoader(false);
        });
    } else {
      toast.error('Page not found', alertOptions);
      navigate('/login');
    }
  }, [navigate, searchParams]);

  return (
    <Grid container>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <Grid item md={10} className="pl-0">
          <Typography component={'div'} className="d-flex-a d-flex-sb mb-2">
            <Typography className="black-white-text f-22 font-bold">
              Payment Terms And Conditions
            </Typography>
            <div>
              <Button
                className="cancel-btn mr-4 "
                sx={{
                  borderRadius: '5px !important',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '1em !important',
                  width: '120px !important',
                }}
                onClick={() => navigate(-1)}>
                <Typography
                  className="o-box d-flex-a"
                  sx={{ width: 'unset !important' }}
                />
                <ArrowBackIcon sx={{ marginRight: '.5rem' }} />
                Back
              </Button>
            </div>
          </Typography>
          <Typography className="term-box">
            <Typography className="f-20 font-bold mb-2">
              Authorization
            </Typography>
            <Typography className="f-15 term-txt mb-2">
              By checking I accept the Terms and Conditions below I authorize my
              bank to debit my specified account for the amount of my payment.
              This is a one-time payment which will occur on the next business
              day or as soon as practical thereafter.
            </Typography>
            <Typography className="f-15 term-txt mb-2">
              {' '}
              If my payment cannot be completed for any reason, including
              insufficient funds or error in the information which I submitted,
              I will retain the same liability, which is my sole responsibility,
              for payment as though I had not attempted to make the payment.{' '}
            </Typography>
            <Typography className="f-15 term-txt" sx={{ marginBottom: '2rem' }}>
              {' '}
              I also understand that additional fees and penalties may be
              collected to the extent of applicable law.
            </Typography>
            <Typography className="f-20 font-bold mb-2">Receipt</Typography>
            <Typography className="f-15 term-txt" sx={{ marginBottom: '2rem' }}>
              A receipt can be printed after payment is accepted which may serve
              as evidence of payment. If an email address is provided during the
              payment process, a receipt will be emailed to you after the
              payment is processed.
            </Typography>
            <Typography className="f-20 font-bold mb-2">Contact</Typography>
            <Typography className="f-15 term-txt" sx={{ marginBottom: '2rem' }}>
              If for any reason you wish to make a change to the payment after
              submission, please contact OnlineVisas at{' '}
              <a href="mailto:finance@velielaw.com">finance@velielaw.com</a> or{' '}
              <a href="tel:+1-405-310-4333">+1 405 310 4333</a>.
            </Typography>
            <Typography component={'div'} className="d-flex-jae">
              <Button
                className="n-add-btn"
                sx={{
                  borderRadius: '5px !important',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '0.9em !important',
                  height: '47px !important',
                  width: '131px !important',
                }}
                onClick={() => {
                  if (location.state) {
                    updateStripeCheckout(location.state?.type);
                  }
                }}>
                Agree
              </Button>
            </Typography>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default PaymentMethodAndTerms;
