import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';
import useResizeObserver from 'use-resize-observer';

import Page from './Page';
import PdfPage from './PdfPage';

const PdfViewer = (props) => {
  const {
    width,
    height,
    itemCount,
    shouldDraw,
    selectedPage,
    setCoOrdinates,
    getPdfPage,
    scale,
    gap,
    windowRef,
  } = props;

  const [pages, setPages] = useState([]);
  const [selectedId, setSeletedID] = useState('');

  const listRef: any = useRef();

  const {
    ref,
    width: internalWidth = 400,
    height: internalHeight = 600,
  } = useResizeObserver();

  const fetchPage = useCallback(
    (index) => {
      const selectedIndex = selectedPage
        ? selectedPage[index]
          ? selectedPage[index].page_number - 1
          : null
        : index;

      if (!pages[index]) {
        getPdfPage(selectedIndex).then((page) => {
          setPages((prev) => {
            const next = [...prev];
            next[index] = page;
            return next;
          });
          listRef.current.resetAfterIndex(index);
        });
      }
      if (
        selectedPage &&
        selectedPage[index] &&
        selectedId !== selectedPage[index].id
      ) {
        setPages([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPdfPage, pages, selectedPage],
  );

  const handleItemSize = useCallback(
    (index) => {
      const page = pages[index];
      if (page) {
        const viewport = page.getViewport({ scale });
        return Number(viewport.height) + Number(gap);
      }
      return 50;
    },
    [pages, scale, gap],
  );

  const handleListRef = useCallback(
    (elem) => {
      listRef.current = elem;
      if (windowRef) {
        windowRef.current = elem;
      }
    },
    [windowRef],
  );

  useEffect(() => {
    listRef.current.resetAfterIndex(0);
  }, [scale]);

  useEffect(() => {
    if (selectedPage) {
      setSeletedID(selectedPage[0].id);
    }
  }, [selectedPage]);

  const style = {
    background: '#A5A5A5',
    border: '1px solid #ccc',
    height,
    width,
  };

  return (
    <div ref={ref} style={style}>
      <VariableSizeList
        ref={handleListRef}
        width={internalWidth}
        height={internalHeight}
        itemCount={itemCount}
        itemSize={handleItemSize}>
        {({ index, style }) => {
          fetchPage(index);
          return (
            <Page style={style}>
              <PdfPage
                page={pages[index]}
                scale={scale}
                shouldDraw={shouldDraw}
                selectedPage={selectedPage ? selectedPage[index] : null}
                setCoOrdinates={setCoOrdinates}
              />
            </Page>
          );
        }}
      </VariableSizeList>
    </div>
  );
};

PdfViewer.propTypes = {
  gap: PropTypes.number,
  getPdfPage: PropTypes.func.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  itemCount: PropTypes.number.isRequired,
  scale: PropTypes.number,
  selectedPage: PropTypes.any,
  setCoOrdinates: PropTypes.any,
  shouldDraw: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  windowRef: PropTypes.object,
};

PdfViewer.defaultProps = {
  gap: 40,
  height: '70vh',
  scale: 2,
  width: '100%',
};

export default PdfViewer;
