import BorderColorIcon from '@mui/icons-material/BorderColor';
import SourceIcon from '@mui/icons-material/Source';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import { Box, DialogContent, Grid, Menu, Typography } from '@mui/material';
import Baccept from 'assets/images/b-accept.png';
// import Bdownload from 'assets/images/b-download.png';
import Beye from 'assets/images/b-eye.png';
import Layer from 'assets/images/layer.png';
import Pdf from 'assets/images/pdf.png';
import wrd from 'assets/images/wrd.png';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { DocumentViewer } from 'components/CustomDocumentViewer/CustomeDocumentViewer';
import CustomModalView from 'components/CustomModalView/customModalView';
import {
  AffilatePrivateRoutes,
  BeneficiaryPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import saveAs from 'file-saver';
import { instantState } from 'hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData, getS3file, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import { ResourceStation } from '../ResourceStation';

export const ReviewAndApprove: React.FC<any> = ({ onlyShow }) => {
  const { petitionId } = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const location: any = useLocation();
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [loader, setLoader] = useState(true);
  const [visaSpecificDocument, setVisaSpecificDocument] = useState<any>({
    approveDoc: [],
    reviewDoc: [],
  });
  const [isOpenView, setIsOpenView] = useState<any>({
    document: null,
    show: null,
  });
  const [approveConfirm, setApproveConfirm] = useState({
    document: null,
    show: false,
  });
  const [openReview, setOpenReview] = useState({
    fileType: '',
    fileUrl: '',
    open: false,
  });
  const [resourceStation, setResourceStation] = useState({
    open: false,
  });

  const open = Boolean(isOpenView.show);

  const getVisaSpecificDocById = useCallback(
    (dependentUserId?) => {
      setLoader(true);
      let url: string =
        String(COMMON_ENDPOINT.DocumentBuild) +
        '/' +
        String(petitionId) +
        '/' +
        String(
          petitions && petitions.petitionData
            ? petitions.petitionData.visa_id
            : '',
        );
      if (dependentUserId) {
        url =
          url +
          `?formType=dependent&dependentUserId=${String(dependentUserId)}`;
      }
      getAllListData(url)
        .then((res: any) => {
          setLoader(false);
          const key =
            userDetail?.role_id === 4 ||
            (petitions?.petitionData?.isAffilateCase &&
              petitions?.petitionData?.affilateRoleId === 4)
              ? 'beneficiary_build_document_status'
              : userDetail?.role_id === 2 ||
                (petitions?.petitionData?.isAffilateCase &&
                  petitions?.petitionData?.affilateRoleId === 2)
              ? 'petitioner_build_document_status'
              : '';
          setVisaSpecificDocument((pre) => ({
            ...pre,
            approveDoc:
              res && res.length && Array.isArray(res)
                ? res.filter(
                    (e) =>
                      e.build_document_for &&
                      Array.isArray(e.build_document_for) &&
                      e.build_document_for.includes(userDetail?.role_id) &&
                      e[key] === 'approved',
                  )
                : [],
            reviewDoc:
              res && res.length && Array.isArray(res)
                ? res.filter(
                    (e) =>
                      e.build_document_for &&
                      Array.isArray(e.build_document_for) &&
                      e.build_document_for.includes(userDetail?.role_id) &&
                      e[key] === 'reviewPending',
                  )
                : [],
          }));
        })
        .catch(() => {
          setLoader(false);
        });
    },
    [petitionId, petitions, userDetail?.role_id],
  );

  const approve = async (doc: any) => {
    const buildDocId = doc?.latestBuild[0]?.id;
    setLoader(true);
    try {
      await updateData(
        buildDocId,
        {
          documentFor: [
            petitions?.petitionData?.isAffilateCase
              ? petitions?.petitionData?.affilateRoleId
              : userDetail?.role_id,
          ],
          documentName: doc?.current_formName,
          documentStatus: doc?.isEdited ? 'pending' : 'approved',
          petitionId: petitionId,
        },
        COMMON_ENDPOINT.DocumentBuildSendTo,
      );
      toast.info(
        doc?.isEdited ? 'Document sent for review' : 'Document approved',
        alertOptions,
      );
      getVisaSpecificDocById();
      setIsOpenView((pre) => ({
        ...pre,
        show: null,
      }));
    } catch (error) {
      setLoader(false);
    }
  };
  const latestBuild = (data?) => {
    if (data.latestBuild !== 0) {
      if (data.file_format === 'document') {
        openFile(data.latestBuild[0].file_key);
      } else {
        setIsOpenView((pre) => ({
          ...pre,
          show: null,
        }));
        const url =
          String(process.env.REACT_APP_DOCUMENT_URL) +
          String(data.latestBuild[0].id);
        window.open(url);
      }
    }
  };

  const openFile = (file) => {
    const obj = {
      key: file,
    };

    const fileName = file.split('/').pop();
    setLoader(true);
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        saveAs(data, fileName);
        toast.success(`${String(fileName)} File Downloaded`, alertOptions);
        setIsOpenView((pre) => ({
          ...pre,
          show: null,
        }));
        setLoader(false);
      })
      .catch((err) => {
        setIsOpenView((pre) => ({
          ...pre,
          show: null,
        }));
        setLoader(false);
        // toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };

  useEffect(() => {
    if (!_.isUndefined(petitionId) && !_.isEmpty(petitions)) {
      getVisaSpecificDocById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitions]);

  return (
    <Grid container>
      {loader ? <div className="liberty-loader" /> : null}
      {!_.isEmpty(petitions) && !_.isEmpty(petitions.petitionData) && (
        <Grid item md={10} sm={12} sx={{ paddingRight: '2rem' }}>
          <Typography
            component={'div'}
            className="black-white-text"
            sx={{
              fontFamily: 'Arial-Bold !important',
              fontSize: '1.25em !important',
              fontWeight: 400,
              margin: '1.5rem 0 0rem 0',
            }}>
            {petitions.petitionData.visa_type} for{' '}
            {petitions.petitionData?.benificiery_name}
          </Typography>

          <Box
            sx={{ minHeight: '230px', padding: '20px', width: '100%' }}
            className="documentTab">
            <Grid item md={12} sm={12}>
              <Grid container className="pe-box">
                <Typography
                  component={'div'}
                  className="black-white-text mb-2 mt-2"
                  sx={{
                    fontFamily: 'Arial-Bold !important',
                    fontSize: '1.25em !important',
                    fontWeight: 400,
                    width: '100%',
                  }}>
                  Documents waiting for review
                </Typography>

                {visaSpecificDocument?.reviewDoc.length > 0 ? (
                  visaSpecificDocument?.reviewDoc.map(
                    (document: any, index: number) => (
                      <Grid
                        item
                        md={3}
                        sm={6}
                        sx={{ paddingRight: '1rem', paddingTop: '1rem' }}
                        key={index}>
                        <Typography
                          className="build-box d-flex-a pointer"
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(e) => {
                            setIsOpenView({
                              document,
                              show: e.currentTarget,
                            });
                          }}>
                          <img
                            src={document.file_format === 'pdf' ? Pdf : wrd}
                            alt={
                              document.file_format === 'pdf' ? 'pdf' : 'word'
                            }
                          />
                          <span
                            className="f-15 font-bold black-white-text d-flex-1"
                            style={{
                              maxWidth: '100%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              // whiteSpace: 'nowrap',
                              // wordBreak: 'break-all',
                            }}>
                            {document?.current_formName}
                          </span>
                        </Typography>
                      </Grid>
                    ),
                  )
                ) : (
                  <Typography
                    className="note-box2 mt-2"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <Typography className="inb2" />
                    <Typography className="black-white-text ctitle hideshow">
                      No Record(s) Found.
                    </Typography>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{ minHeight: '230px', padding: '20px', width: '100%' }}
            className="documentTab">
            <Grid item md={12} sm={12}>
              <Grid container className="pe-box">
                <Typography
                  component={'div'}
                  className="black-white-text"
                  sx={{
                    fontFamily: 'Arial-Bold !important',
                    fontSize: '1.25em !important',
                    fontWeight: 400,
                    margin: '1.5rem 0 0rem 0',
                    width: '100%',
                  }}>
                  Approved documents
                </Typography>

                {visaSpecificDocument?.approveDoc.length > 0 ? (
                  visaSpecificDocument?.approveDoc.map(
                    (document: any, index: number) => (
                      <Grid
                        item
                        md={3}
                        sm={6}
                        sx={{ paddingRight: '1rem', paddingTop: '1rem' }}
                        key={index}>
                        <Typography
                          className="build-box d-flex-a pointer"
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(e) => {
                            setIsOpenView({
                              document,
                              show: e.currentTarget,
                            });
                          }}>
                          <img
                            src={document.file_format === 'pdf' ? Pdf : wrd}
                            alt={
                              document.file_format === 'pdf' ? 'pdf' : 'word'
                            }
                          />
                          <span
                            className="f-15 font-bold black-white-text d-flex-1"
                            style={{
                              maxWidth: '100%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              wordBreak: 'break-all',
                            }}>
                            {document?.current_formName}
                          </span>
                        </Typography>
                      </Grid>
                    ),
                  )
                ) : (
                  <Typography
                    className="note-box2 mt-2"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <Typography className="inb2" />
                    <Typography className="black-white-text ctitle hideshow">
                      No Record(s) Found.
                    </Typography>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}

      {open && isOpenView.document?.id && (
        <Menu
          className="pe-custom"
          id="basic-menu"
          anchorEl={isOpenView.show}
          open={open}
          onClose={() => setIsOpenView({ document: {}, show: null })}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <div className="pe-tooltip">
            {!_.isEmpty(isOpenView) ? (
              <div className="blue-box" style={{ minHeight: '65px' }}>
                <img
                  style={{
                    height: '60px',
                    width: '60px',
                  }}
                  src={isOpenView.document?.file_format === 'pdf' ? Pdf : wrd}
                  alt={
                    isOpenView.document?.file_format === 'pdf' ? 'pdf' : 'word'
                  }
                />
                <span className="font-bold text d-flex-1">
                  {isOpenView.document?.template_name}
                </span>
              </div>
            ) : (
              <div className="blue-box">
                <span className="f-22 font-bold text d-flex-1">#</span>
              </div>
            )}
            <div className="black-box">
              {/* Edit Doc  OR  Build PDF */}
              {isOpenView.document[
                userDetail?.role_id === 2 ||
                (petitions?.petitionData?.isAffilateCase &&
                  petitions?.petitionData?.affilateRoleId === 2)
                  ? 'petitioner_build_document_status'
                  : 'beneficiary_build_document_status'
              ] === 'reviewPending' ? (
                <Typography
                  component={'div'}
                  className="d-flex-a mb-2"
                  sx={{
                    cursor: !isOpenView.document?.latestBuild.length
                      ? 'not-allowed'
                      : 'pointer',
                  }}
                  onClick={() => {
                    // if (isOpenView.document?.file_format !== 'pdf') {
                    const url = `${String(
                      userDetail?.role_id === 2
                        ? PetitionerPrivateRoutes.PETITIONS.BUILDCASEEDIT
                        : userDetail?.role_id === 5 &&
                          petitions?.petitionData?.isAffilateCase &&
                          petitions?.petitionData?.affilateRoleId === 2
                        ? AffilatePrivateRoutes.PETITIONER_PETITIONS
                            .BUILDCASEEDIT
                        : userDetail?.role_id === 5 &&
                          petitions?.petitionData?.isAffilateCase &&
                          petitions?.petitionData?.affilateRoleId === 4
                        ? AffilatePrivateRoutes.BENEFICIARYROUTE.BUILDCASEEDIT
                        : BeneficiaryPrivateRoutes.BUILDCASEEDIT,
                    )}/${String(petitionId)}/${String(
                      isOpenView.document?.templateid,
                    )}`;

                    navigate(url, {
                      // state: {
                      //   data: {
                      //     document: isOpenView.document,
                      //     leadUserId:
                      //       searchParams.get('leadUser') !== ''
                      //         ? searchParams.get('leadUser')
                      //         : null,
                      //   },
                      // },
                      state: {
                        data: {
                          document: isOpenView.document,
                          fileFormat: isOpenView.document?.file_format,
                          leadUserId:
                            searchParams.get('leadUser') !== ''
                              ? searchParams.get('leadUser')
                              : null,
                          pdfId: isOpenView.document?.templateid,
                          templateFilePath:
                            isOpenView.document?.template_file_path,
                          templateName: isOpenView.document?.template_name,
                        },
                        showType: location.state?.showType,
                      },
                    });
                    // }
                  }}>
                  <BorderColorIcon />
                  <Typography
                    className={'f-18 pl-2 '.concat(
                      isOpenView.document?.latestBuild.length > 0
                        ? 'text'
                        : 'text-off',
                    )}>
                    Edit
                  </Typography>
                </Typography>
              ) : null}

              {/* Edit  OR  Approve*/}
              {isOpenView.document[
                userDetail?.role_id === 2 ||
                (petitions?.petitionData?.isAffilateCase &&
                  petitions?.petitionData?.affilateRoleId === 2)
                  ? 'petitioner_build_document_status'
                  : 'beneficiary_build_document_status'
              ] === 'reviewPending' ? (
                <Typography
                  component={'div'}
                  className="d-flex-a mb-2"
                  sx={{
                    cursor: !isOpenView.document?.latestBuild.length
                      ? 'not-allowed'
                      : 'pointer',
                  }}
                  onClick={() =>
                    setApproveConfirm((pre) => ({
                      ...pre,
                      show: true,
                    }))
                  }>
                  <img src={Baccept} alt="img" />
                  <Typography
                    className={'f-18 pl-2 '.concat(
                      isOpenView.document?.latestBuild.length > 0
                        ? 'text'
                        : 'text-off',
                    )}>
                    {isOpenView.document?.isEdited
                      ? 'Send for Review'
                      : 'Approve'}
                  </Typography>
                </Typography>
              ) : null}

              {/* Download */}
              <Typography
                component={'div'}
                className="d-flex-a mb-2"
                sx={{
                  cursor: !isOpenView.document?.latestBuild.length
                    ? 'not-allowed'
                    : 'pointer',
                }}
                onClick={() => latestBuild(isOpenView.document)}>
                <VerticalAlignBottomIcon />
                <Typography
                  className={'f-18 pl-2 '.concat(
                    isOpenView.document?.latestBuild.length > 0
                      ? 'text'
                      : 'text-off',
                  )}>
                  Download
                </Typography>
              </Typography>

              {/* Review */}
              <Typography
                component={'div'}
                className="d-flex-a mb-2"
                sx={{
                  cursor: !isOpenView.document?.latestBuild.length
                    ? 'not-allowed'
                    : 'pointer',
                }}
                onClick={() => {
                  setIsOpenView((pre) => ({
                    ...pre,
                    show: null,
                  }));
                  setOpenReview((pre) => ({
                    ...pre,
                    fileType: 'pdf',
                    fileUrl:
                      String(process.env.REACT_APP_DOCUMENT_URL) +
                      String(isOpenView.document?.latestBuild[0].id),
                    open: true,
                  }));
                }}>
                <img src={Beye} alt="img" />
                <Typography
                  className={'f-18 pl-2 '.concat(
                    isOpenView.document?.latestBuild.length > 0
                      ? 'text'
                      : 'text-off',
                  )}>
                  Review
                </Typography>
              </Typography>
            </div>
          </div>
        </Menu>
      )}
      {userDetail.role_id !== 3 && (
        <Grid item md={2} sm={12}>
          <Typography component={'div'} className="add-box">
            <Typography component={'div'} className="add-inner-box" />
            <Typography className=" bu-color f-16 pt-5 pb-2 pl-2">
              Select any case document to view options
            </Typography>
            <Typography
              className=" built-option d-flex-a mb-1"
              onClick={() =>
                userDetail.role_id === 2
                  ? navigate(
                      `${String(
                        PetitionerPrivateRoutes.PETITIONS.SUBMISSIONSTATION,
                      )}/${String(petitionId)}`,
                    )
                  : userDetail.role_id === 4
                  ? navigate(
                      `${String(
                        BeneficiaryPrivateRoutes.SUBMISSIONSTATION,
                      )}/${String(petitionId)}?leadUser=${String(
                        searchParams.get('leadUser'),
                      )}`,
                    )
                  : userDetail.role_id === 5
                  ? navigate(
                      `${String(
                        AffilatePrivateRoutes.PETITIONER_PETITIONS
                          .SUBMISSIONSTATION,
                      )}/${String(petitionId)}`,
                    )
                  : null
              }>
              {' '}
              <img
                width="35"
                src={Layer}
                alt="im"
                className="mr-1"
                style={{ width: '35px !important' }}
              />
              Submission Station
            </Typography>
            <Typography
              className=" built-option hover d-flex-a mb-1"
              onClick={(e) =>
                setResourceStation((pre) => ({
                  ...pre,
                  open: true,
                }))
              }>
              {' '}
              <SourceIcon
                className="mr-1"
                style={{ width: '35px !important' }}
              />
              Resource Station
            </Typography>
          </Typography>
        </Grid>
      )}

      <ConfirmDialog
        title="Confirm Action"
        floatingBtnShow={true}
        open={approveConfirm.show}
        onConfirm={async (act) => {
          if (act !== 'yes') {
            setApproveConfirm((pre) => ({
              ...pre,
              show: false,
            }));
            return;
          }
          setApproveConfirm((pre) => ({
            ...pre,
            show: false,
          }));
          const doc = await instantState(setIsOpenView);
          approve(doc.document);
        }}
        autoCloseDisable={true}>
        {isOpenView.document?.isEdited
          ? 'Are you sure do you want to send this document for review?'
          : 'Are you sure do you want approve this document?'}
      </ConfirmDialog>

      <CustomModalView
        open={openReview?.open}
        fullWidth={true}
        maxWidth={'lg'}
        scroll={'paper'}
        close={() =>
          setOpenReview((pre) => ({
            ...pre,
            fileType: '',
            fileUrl: '',
            open: false,
          }))
        }
        title="Review Document">
        <DialogContent>
          <Typography
            className="assem-box"
            id="parentdocId"
            sx={{
              minHeight: '30vh',
            }}>
            <DocumentViewer
              fileBlob={openReview?.fileUrl}
              fileType={openReview?.fileType}
              width={
                document.getElementById('parentdocId') &&
                document.getElementById('parentdocId').offsetWidth
                  ? `${
                      document.getElementById('parentdocId').offsetWidth - 100
                    }px`
                  : 'auto'
              }
            />
          </Typography>
        </DialogContent>
      </CustomModalView>

      {resourceStation.open ? (
        <ResourceStation
          modalOnClose={() =>
            setResourceStation((pre) => ({
              ...pre,
              open: false,
            }))
          }
        />
      ) : null}
    </Grid>
  );
};

export default ReviewAndApprove;
