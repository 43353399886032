import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Grid, Tab, Typography } from '@mui/material';
import React, { useState } from 'react';

import ContractReport from './ContractReport';
import PaymentReport from './PaymentReport';

export const ReportList: React.FC = React.memo(() => {
  const [tabValue, setTabValue] = useState('payment');

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Grid container>
      <Grid item md={12} sm={12}>
        <Box sx={{ width: '100%' }} className="documentTab">
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                variant="scrollable"
                onChange={handleChange}
                aria-label="lab API tabs example">
                <Tab label="Payments" value="payment" />
                <Tab label="Goal sheet" value="contract" />
              </TabList>
            </Box>

            <Typography component={'div'}>
              <TabPanel value="payment">
                <Typography component={'div'}>
                  <Grid item md={12} sm={12} sx={{ paddingRight: '1.8rem' }}>
                    <PaymentReport tab={tabValue} />
                  </Grid>
                </Typography>
              </TabPanel>
              <TabPanel value="contract">
                <Typography component={'div'}>
                  <Grid item md={12} sm={12} sx={{ paddingRight: '1.8rem' }}>
                    <ContractReport tab={tabValue} />
                  </Grid>
                </Typography>
              </TabPanel>
            </Typography>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
});

export default ReportList;
