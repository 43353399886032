import { getAllListData } from 'services/CommonServices';
import {
  I_129_EXTRACTION_LENGTH,
  LEAD_SAVE,
  ORGANIZATION_DATA,
  PETITIONER_SAVE,
  RESET_PETITIONER_SAVE,
  RESET_SAVE,
} from 'store/types/LeadsOrPetitionerTypes';
import { COMMON_ENDPOINT } from 'utils/Constant';

export const storeLeadData = (lead: any, roleId?: number) => {
  return (dispatch) => {
    dispatch({
      payload: {
        leadData: lead,
        roleId: roleId,
      },
      type: LEAD_SAVE,
    });
  };
};

export const resetLeadData = () => {
  return (dispatch) => {
    dispatch({
      payload: null,
      type: RESET_SAVE,
    });
  };
};
export const storeOrganizationData = (data: any) => {
  return (dispatch) => {
    dispatch({
      payload: data,
      type: ORGANIZATION_DATA,
    });
  };
};
export const storePetitionerData = (data: any) => {
  return (dispatch) => {
    dispatch({
      // payload: {
      //   petitionerData: data.petitionData,
      //   teamData: data.teams,
      //   memberEmails: data.teamMemberEmails,
      //   notes: data.notes,
      // },
      payload: data,
      type: PETITIONER_SAVE,
    });
  };
};

export const resetPetitionerData = () => {
  return (dispatch) => {
    dispatch({
      payload: null,
      type: RESET_PETITIONER_SAVE,
    });
  };
};

export const storeI_129ExtractionLength = (data: Array<any>) => {
  return (dispatch) => {
    dispatch({
      payload: data,
      type: I_129_EXTRACTION_LENGTH,
    });
  };
};

export const ParsingCount = (userId: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    getAllListData(
      COMMON_ENDPOINT.DocumentParseList(
        null,
        'success',
        'DESC',
        'false',
        null,
        null,
        userId,
      ),
    )
      .then((resp: any) => {
        resolve(resp?.data || 0);
      })
      .catch(() => {
        resolve([]);
      });
  });
};
