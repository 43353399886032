import { Button, Grid, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

export const PreviewBriefCover: React.FC<any> = () => {
  const { petitionId, htmlId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { petitions } = useAppSelector((store: any) => store?.petitionUser);
  const [url, setUrl] = useState('');
  const [savedType, setSavedType] = useState('pdf');

  const updateSavedType = (values) => {
    setSavedType(values);
    updateData(
      '',
      {},
      `${COMMON_ENDPOINT.GenerateDocs}/${petitionId}?saveType=${String(
        values,
      )}`,
    )
      .then((resp: any) => {})
      .catch((e) => {
        toast.error('Failed to update', alertOptions);
      });
  };

  useEffect(() => {
    setSavedType(
      !_.isEmpty(petitions) &&
        petitions.petitionData &&
        petitions.petitionData.brief_cover_save_type
        ? petitions.petitionData.brief_cover_save_type
        : 'pdf',
    );
    if (htmlId !== '0' && htmlId) {
      setUrl(
        `${process.env.REACT_APP_APIURL}/generate-document/pixelex/${htmlId}?type=html&petitionId=${petitionId}`,
      );
    } else {
      const queryParamsId = searchParams.get('pixelixeId');
      setUrl(
        `${process.env.REACT_APP_APIURL}/generate-document/pdf-view-pixlex/${petitionId}/${queryParamsId}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitionId, petitions]);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      {!_.isEmpty(petitions) && !_.isEmpty(petitions.petitionData) && (
        <Grid item md={12} sm={12} className="pl-0">
          <Typography
            component={'div'}
            className="black-white-text"
            sx={{
              fontFamily: 'Arial-Bold !important',
              fontSize: '1.25em !important',
              margin: '1.5rem 0 1.5rem 0',
            }}>
            {petitions.petitionData.visa_type} for{' '}
            {petitions.petitionData?.benificiery_name}
          </Typography>
        </Grid>
      )}
      <Grid item md={12}>
        <Grid container columnSpacing={{ md: 2, sm: 1 }} className="pe-box">
          <Grid item md={12}>
            <Typography
              className="d-flex-jae mb-2 black-white-text"
              style={{
                alignItems: 'baseline',
              }}>
              Save Type
              <Typography
                component={'div'}
                sx={{ margin: '0 1rem', minWidth: '125px' }}>
                <CustomSelect
                  name="savedType"
                  id="savedType"
                  labelKey={'label'}
                  valueKey={'value'}
                  value={savedType}
                  closeMenuOnSelect={false}
                  options={[
                    {
                      label: 'PDF',
                      value: 'pdf',
                    },
                    {
                      label: 'Template',
                      value: 'html',
                    },
                  ]}
                  onChange={(e) => updateSavedType(e.target.value)}
                  placeHolder={'Select'}
                  className={'custom-search-input'}
                />
              </Typography>
              <Button className="n-add-btn " onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <iframe
        allowFullScreen
        frameBorder="0"
        width={'100%'}
        height="750"
        src={`https://studio.pixelixe.com/#api?apiKey=jeaL6mTsI1cC4D5DTfEMQxQUpsw1&width=1000&height=300&pdf=true&graphicUrl=${encodeURIComponent(
          url,
        )}&graphicName=sampleBrif&uid=${petitionId}&outputURL=${encodeURIComponent(
          `${process.env.REACT_APP_APIURL}/generate-document/pixlex`,
        )}`}
      />
    </Grid>
  );
};

export default PreviewBriefCover;
