import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, Typography } from '@mui/material';
import CreditCard from 'assets/images/creditcard.png';
import { instantState } from 'hooks';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData } from 'services/CommonServices';
// import { setRecaptcha } from 'store/actions/UserAction';
// import { useAppDispatch } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import HeartLander from './HeartLander';

export const CreditDebitPayment: React.FC<any> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // const dispatch = useAppDispatch();

  const [loader, setLoader] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [paymentInfo, setPaymentInfo] = useState<any>({});
  const [confirm, setConfirm] = useState(true);
  // const [paymentVisible, setPaymentVisible] = useState(true);
  const [reCaptchaValue, setReCaptchaValue] = useState(null);

  const googleCaptchaCheck = async (respCardsecure) => {
    const reCaptchaValue = await instantState(setReCaptchaValue);
    if (reCaptchaValue) {
      const tokenFormValues = {
        reCaptcha: reCaptchaValue,
      };
      setLoader(true);
      await addNewData(
        tokenFormValues,
        `${COMMON_ENDPOINT.ContractPayment}/token-validate`,
      )
        .then((resp: any) => {
          cardPaymentApi(respCardsecure);
        })
        .catch((e) => {
          setLoader(false);
          toast.error('Enter a valid captcha.', alertOptions);
        });
    } else {
      setLoader(false);
      toast.error('Enter a valid captcha.', alertOptions);
    }
  };

  const cardPaymentApi = (bodyData: any) => {
    const paymentBody = getpaymentData(bodyData);
    addNewData(paymentBody, `${COMMON_ENDPOINT.ContractPayment}/card`)
      .then((resp: any) => {
        // toast.success('Payment successfully', alertOptions);
        setLoader(false);
        // setPaymentVisible(false);
        navigate('/secure-payment/success');
        // dispatch(setRecaptcha(null));
      })
      .catch((e) => {
        toast.error(
          'Your payment failed. Please try again after sometime or contact your attorney.',
          alertOptions,
        );
        setLoader(false);
      });
  };

  const getpaymentData = (pay) => {
    return {
      cardNumber: pay.card.number,
      cvn: '123',
      expMonth: pay.exp_month,
      expYear: pay.exp_year,
      hashKey: searchParams.get('hashKey'),
      paymentTotal: paymentInfo.totalPaymentAmount,
      postalCode: '123456',
      tokenValue: pay.token_value,
    };
  };

  useEffect(() => {
    if (searchParams.get('hashKey') !== '') {
      setLoader(true);
      getAllListData(
        `${COMMON_ENDPOINT.ContractPayment}?hashKey=${searchParams.get(
          'hashKey',
        )}`,
      )
        .then((info: any) => {
          setPaymentInfo(info.data);
          const paymentAmount =
            info.data && info.data.paymentTotal ? info.data.paymentTotal : '';
          if (!paymentAmount) {
            navigate(`/secure-payment?hashKey=${searchParams.get('hashKey')}`);
            return;
          }
          setLoader(false);
        })
        .catch((e) => {
          // toast.error(
          //   'The link is invalid. Please contact your attorney.',
          //   alertOptions,
          // );
          navigate('/login');
          setLoader(false);
        });
    } else {
      toast.error('Page not found', alertOptions);
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <Grid item md={10} className="pl-0">
          <Typography component={'div'} className="d-flex-a d-flex-sb mb-2">
            <Typography component={'div'} className="d-flex-a ">
              <Typography className="black-white-text f-22 font-bold">
                Payment Amount:
              </Typography>
              <Typography className="black-white-text f-22 font-bold pl-2">
                $
                {!_.isEmpty(paymentInfo)
                  ? parseFloat(paymentInfo.totalPaymentAmount).toFixed(2)
                  : '-'}
              </Typography>
            </Typography>
            <div>
              <Button
                className="cancel-btn mr-4 "
                sx={{
                  borderRadius: '5px !important',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '1em !important',
                  width: '120px !important',
                }}
                onClick={() => navigate(-1)}>
                <Typography
                  className="o-box d-flex-a"
                  sx={{ width: 'unset !important' }}
                />
                <ArrowBackIcon sx={{ marginRight: '1rem' }} />
                Back
              </Button>
            </div>
          </Typography>
          <Typography component={'div'} className="payment-me-box">
            <Typography
              component={'div'}
              className="top-box"
              sx={{
                borderBottom: '1px solid lightgrey',
                padding: '15px 46px 5px 46px !important',
              }}>
              <Grid container className="pt-2">
                <Grid item md={4} sm={12}>
                  <Typography className="oname-label f-15">
                    Payment Amount:
                  </Typography>
                  <Typography className="oname-text f-15 font-bold">
                    USD{' '}
                    {!_.isEmpty(paymentInfo)
                      ? parseFloat(paymentInfo.paymentTotal).toFixed(2)
                      : '-'}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12}>
                  <Typography className="oname-label f-15">
                    Transaction Fee:
                  </Typography>
                  <Typography className="oname-text f-15 font-bold">
                    USD{' '}
                    {!_.isEmpty(paymentInfo)
                      ? parseFloat(paymentInfo.transactionAmount).toFixed(2)
                      : '-'}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12}>
                  <Typography className="oname-label f-15">
                    Total Amount:
                  </Typography>
                  <Typography className="oname-text f-15 font-bold">
                    USD{' '}
                    {!_.isEmpty(paymentInfo)
                      ? parseFloat(paymentInfo.totalPaymentAmount).toFixed(2)
                      : '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
            <Typography component={'div'} className="top-box">
              <Grid container className="pt-2">
                <Grid item md={6} sm={12} className="mb-2">
                  <Typography className="oname-label f-15">
                    Firm Name:
                  </Typography>
                  <Typography className="oname-text f-15 font-bold">
                    {!_.isEmpty(paymentInfo) ? paymentInfo.lawFirmName : '-'}
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} className="mb-2">
                  <Typography className="oname-label f-15">
                    {paymentInfo?.paymentInitiatedFor === 4
                      ? 'Beneficiary'
                      : 'Organization'}
                    {' Name:'}
                  </Typography>
                  <Typography className="oname-text f-15 font-bold">
                    {!_.isEmpty(paymentInfo) ? paymentInfo.userName : '-'}
                  </Typography>
                </Grid>

                {paymentInfo?.paymentInitiatedFor === 4 && (
                  <Grid item md={6} sm={12} className="mb-2">
                    <Typography className="oname-label f-15">
                      Organization Name:
                    </Typography>

                    <Typography className="oname-text f-15 font-bold">
                      {paymentInfo?.orgName}
                    </Typography>
                  </Grid>
                )}
                {paymentInfo?.paymentInitiatedFor === 2 && (
                  <Grid item md={6} sm={12} className="mb-2">
                    {paymentInfo?.paymentInitiatedFor === 2 && (
                      <Typography className="oname-label f-15">
                        Beneficiary Name:
                      </Typography>
                    )}
                    <Typography className="oname-text f-15 font-bold">
                      {paymentInfo?.beneficiaryname}
                    </Typography>
                  </Grid>
                )}
                <Grid item md={6} sm={12} className="mb-2">
                  <Typography className="oname-label f-15">
                    Visa Type:
                  </Typography>
                  <Typography className="oname-text f-15 font-bold">
                    {paymentInfo?.visaType ? paymentInfo?.visaType : '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
            <Typography component={'div'} className="bottom-box">
              <img src={CreditCard} alt="im" className="cardim" />
              <Box
                component="form"
                sx={{
                  backgroundColor: 'unset !important',
                }}
                noValidate
                autoComplete="off">
                <Grid container>
                  <Grid item md={12}>
                    <HeartLander
                      submitted={submitted}
                      setSubmitted={setSubmitted}
                      amount={String(paymentInfo.totalPaymentAmount)}
                      confirm={confirm}
                      setConfirm={setConfirm}
                      setLoader={setLoader}
                      googleCaptchaCheck={googleCaptchaCheck}
                      setReCaptchaValue={setReCaptchaValue}
                      reCaptchaValue={reCaptchaValue}
                      toast={toast}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Typography>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CreditDebitPayment;
