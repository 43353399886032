import './style.css';

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import CustomDatePicker from 'components/CustomDatePicker/customDatePicker';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';

type ReportFillterProps = {
  params;
  userDetail;
  setParams;
  tab;
};

export const ReportFillter: React.FC<ReportFillterProps> = ({
  params,
  userDetail,
  setParams,
  tab,
}) => {
  const [attorneyList, setAttorneyList] = useState<any>([]);
  const [petitionList, setPetitionList] = useState<any>([]);
  const [visaList, setVisaList] = useState<any>([]);
  const [invoicePurposeList, setInvoicePurposeList] = useState<any>([]);
  const [contactTypes, setContactTypes] = useState<any>([]);

  const resetFilter = () => {
    setParams({
      amount: '',
      contactId: 'all',
      createdby: '',
      firmId: userDetail?.companyId,
      fromDate: '',
      order: 'DESC',
      paymentMode: 'all',
      paymentReportType: tab,
      period: 'all',
      petitionerAssociated: 'false',
      petitionerId: '',
      petitionerIds: '',
      skip: 0,
      sortby: '',
      status: 'success',
      take: 50,
      toDate: '',
      totalAmount: '',
      transactionId: '',
      visaType: 'all',
    });
  };

  const handleChange = (event, name) => {
    const { value } = event.target;

    if (name == 'period' && value == 'currentWeek') {
      setParams((prev) => ({
        ...prev,
        fromDate: moment().startOf('week').format('YYYY-MM-DD'),
        [name]: value,
        skip: 0,
        take: 50,
        toDate: moment().endOf('week').format('YYYY-MM-DD'),
      }));
    } else if (name == 'period' && value == 'previousWeek') {
      setParams((prev) => ({
        ...prev,
        fromDate: moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .format('YYYY-MM-DD'),
        [name]: value,
        skip: 0,
        take: 50,
        toDate: moment()
          .subtract(1, 'weeks')
          .endOf('week')
          .format('YYYY-MM-DD'),
      }));
    } else if (name == 'period' && value && value == 0) {
      setParams((prev) => ({
        ...prev,
        fromDate: moment().startOf('month').format('YYYY-MM-DD'),
        [name]: value,
        skip: 0,
        take: 50,
        toDate: moment().endOf('month').format('YYYY-MM-DD'),
      }));
    } else if (name == 'period' && value == 1) {
      setParams((prev) => ({
        ...prev,
        fromDate: moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        [name]: value,
        skip: 0,
        take: 50,
        toDate: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD'),
      }));
    } else if (name == 'period' && value == 3) {
      setParams((prev) => ({
        ...prev,
        fromDate: moment()
          .quarter(moment().quarter())
          .startOf('quarter')
          .format('YYYY-MM-DD'),
        [name]: value,
        skip: 0,
        take: 50,
        toDate: moment()
          .quarter(moment().quarter())
          .endOf('quarter')
          .format('YYYY-MM-DD'),
      }));
    } else if (name == 'period' && value == 6) {
      const previous = moment().subtract(1, 'quarter');
      setParams((prev) => ({
        ...prev,
        fromDate: moment(previous).startOf('quarter').format('YYYY-MM-DD'),
        [name]: value,
        skip: 0,
        take: 50,
        toDate: moment(previous).endOf('quarter').format('YYYY-MM-DD'),
      }));
    } else if (name == 'period' && value == 12) {
      setParams((prev) => ({
        ...prev,
        fromDate: moment().startOf('year').format('YYYY-MM-DD'),
        [name]: value,
        skip: 0,
        take: 50,
        toDate: moment().endOf('year').format('YYYY-MM-DD'),
      }));
    } else if (name == 'petitionerId') {
      const selectedPetitionerId = petitionList.find(
        (petition) => petition.ids === value,
      );

      setParams((prev) => ({
        ...prev,
        [name]: selectedPetitionerId?.id,
        petitionerIds: selectedPetitionerId?.ids,
      }));
    } else {
      setParams((prev) => ({
        ...prev,
        fromDate:
          name == 'period' && value == 'today'
            ? moment().format('YYYY-MM-DD')
            : name == 'period' &&
              (value === '' || value === 'all' || value == 2)
            ? ''
            : params.fromDate,
        [name]: value
          ? value
          : [
              'amount',
              'totalAmount',
              'transactionId',
              'petitionerId',
              'visaType',
              'invoicePurpose',
              'contactId',
            ].includes(name)
          ? ''
          : 'all',
        skip: 0,
        take: 50,
        toDate:
          name == 'period' && value == 'today'
            ? moment().format('YYYY-MM-DD')
            : name == 'period' &&
              (value === '' || value === 'all' || value == 2)
            ? ''
            : params.toDate,
      }));
    }
  };

  const handleDateChange = (value, name) => {
    setParams((prev) => ({
      ...prev,
      [name]: moment(new Date(value)).format('yyyy-MM-DD'),
      skip: 0,
      take: 50,
    }));
  };
  const getInvoicePurposeList = () => {
    getAllListData(`${COMMON_ENDPOINT.Contract}/payment-option/list`).then(
      (res) => {
        setInvoicePurposeList(
          res && Array.isArray(res) && res.length
            ? res.filter((e) => e.id !== 6)
            : [],
        );
      },
    );
  };
  useEffect(() => {
    getAllListData(COMMON_ENDPOINT.ManageLawFirm)
      .then((lawFirmDatas) => {
        setAttorneyList(lawFirmDatas?.data);
      })
      .catch((e: any) => {
        console.warn(e);
      });
    getAllListData(`${SETUP_ENDPOINT.SelectMenu}/petitioner`)
      .then((orgResp: any) => {
        const allOpt = [
          {
            ids: 'all',
            org_name: 'All',
          },
        ];

        const orgsIds =
          orgResp && orgResp.length
            ? orgResp.map((e: any, i: number) => ({ ...e, ids: i + 1 }))
            : [];

        setPetitionList(allOpt.concat(orgsIds));
      })
      .catch((e: any) => {
        console.warn(e);
      });
    getAllListData(`${COMMON_ENDPOINT.VisaTypes}`)
      .then((resp: any) => {
        const allOpt = [
          {
            visa_code: 'All',
            visa_id: 'all',
          },
        ];
        setVisaList(allOpt.concat(resp.data));
      })
      .catch((e: any) => {
        console.warn(e);
      });
    getInvoicePurposeList();
    getAllListData(
      `${String(SETUP_ENDPOINT.SelectMenu)}/lawfirm/${String(
        userDetail.companyId,
      )}`,
    )
      .then((resp: any) => {
        const allOpt = [
          {
            full_name: 'All',
            id: 'all',
          },
        ];
        setContactTypes(allOpt.concat(resp));
      })
      .catch((e: any) => {
        console.warn(e);
      });
  }, [userDetail.companyId]);

  return (
    <Grid container>
      <Grid item md={12} sm={12} className="pl-0 pt-2 mt-2">
        <Typography component={'div'}>
          <Typography
            component={'form'}
            className="u-add-in"
            sx={{
              border: 'unset !important',
              marginBottom: '10px',
              padding:
                params.status !== 'success' && tab === 'payment'
                  ? '16px 18px 8px 10px'
                  : '16px 18px 0px 10px',
            }}>
            <Grid container columnSpacing={{ md: 2, sm: 1 }}>
              <Grid item md={2} sm={6}>
                <Typography component={'div'} className="custom-field">
                  <Typography className="custom-label">
                    Select Attorney <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <CustomSelect
                    labelKey={'firm_name'}
                    valueKey={'id'}
                    options={!_.isEmpty(attorneyList) ? attorneyList : []}
                    name={'firmId'}
                    value={params?.firmId}
                    placeHolder={'Select Attorney'}
                    className={'custom-input'}
                    disabled={
                      userDetail?.userRoleName == 'attorney' ? true : false
                    }
                    onChange={(val) => handleChange(val, 'firmId')}
                  />
                </Typography>
              </Grid>
              <Grid item md={tab === 'payment' ? 1 : 2} sm={6}>
                <Typography component={'div'} className="custom-field">
                  <Typography className="custom-label">
                    Status <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <CustomSelect
                    isClearable={false}
                    labelKey={'label'}
                    valueKey={'value'}
                    options={[
                      {
                        label: 'Success',
                        value: 'success',
                      },
                      {
                        label: 'Pending',
                        value: 'pending',
                      },
                      {
                        label: 'Failed',
                        value: 'failed',
                      },
                    ]}
                    name={'status'}
                    value={params?.status === '' ? 'success' : params?.status}
                    placeHolder={'Status'}
                    className={'custom-input'}
                    onChange={(val) =>
                      handleChange(val === '' ? 'success' : val, 'status')
                    }
                  />
                </Typography>
              </Grid>
              <Grid item md={tab === 'payment' ? 1.5 : 2} sm={6}>
                <Typography component={'div'} className="custom-field">
                  <Typography className="custom-label">
                    Period <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <CustomSelect
                    isClearable={false}
                    labelKey={'label'}
                    valueKey={'value'}
                    options={[
                      {
                        label: 'All',
                        value: 'all',
                      },
                      {
                        label: 'Today',
                        value: 'today',
                      },
                      {
                        label: 'Current Week',
                        value: 'currentWeek',
                      },
                      {
                        label: 'Previous Week',
                        value: 'previousWeek',
                      },
                      {
                        label: 'Current Month',
                        value: '0',
                      },
                      {
                        label: 'Previous Month',
                        value: '1',
                      },
                      {
                        label: 'Current Quarter',
                        value: '3',
                      },
                      {
                        label: 'Previous Quarter',
                        value: '6',
                      },
                      {
                        label: 'Current Year',
                        value: '12',
                      },
                      {
                        label: 'Custom',
                        value: '2',
                      },
                    ]}
                    name={'period'}
                    value={params?.period === '' ? 'all' : params?.period}
                    placeHolder={'Select'}
                    className={'custom-input'}
                    onChange={(val) => {
                      handleChange(val === '' ? 'all' : val, 'period');
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={tab === 'payment' ? 3 : 4} sm={6}>
                <Typography component={'div'} className="custom-field">
                  <Typography className="custom-label">Date</Typography>
                  <Grid container>
                    <Grid item md={5} sm={5}>
                      <CustomDatePicker
                        className="custom-input"
                        name={'fromDate'}
                        value={params.fromDate ? params.fromDate : null}
                        isEditable={params?.period == 2 ? false : true}
                        isReadOnlyInput={true}
                        maxDate={new Date(params?.toDate)}
                        onChangeDate={(val) =>
                          handleDateChange(val, 'fromDate')
                        }
                        isUpdatefeildpararms={true}
                      />
                      {params.period === '2' && !params.fromDate ? (
                        <FormHelperText style={{ color: '#ff0000' }}>
                          {'Start Date is required'}
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item md={2} sm={2} className="d-flex-ja">
                      to
                    </Grid>
                    <Grid item md={5} sm={5}>
                      <CustomDatePicker
                        className="custom-input"
                        name={'toDate'}
                        value={params.toDate ? params.toDate : null}
                        minDate={new Date(params?.fromDate)}
                        isReadOnlyInput={true}
                        isEditable={params?.period == 2 ? false : true}
                        onChangeDate={(val) => handleDateChange(val, 'toDate')}
                        isUpdatefeildpararms={true}
                      />
                      <span>{''}</span>
                      {params.period === '2' && !params.toDate ? (
                        <FormHelperText style={{ color: '#ff0000' }}>
                          {'End Date is required'}
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>

              {tab === 'contract' && <Grid item md={1.5} sm={6} />}

              <Grid item md={tab === 'contract' ? 2 : 1.5} sm={6}>
                <Typography component={'div'} className="custom-field">
                  <Typography className="custom-label">
                    Petitioner Name
                  </Typography>
                  <CustomSelect
                    isClearable={
                      params?.petitionerId === '' ||
                      params?.petitionerId === 'all'
                        ? false
                        : true
                    }
                    labelKey={'org_name'}
                    valueKey={'ids'}
                    options={petitionList}
                    name={'petitionerId'}
                    value={
                      params?.petitionerId === ''
                        ? 'all'
                        : params?.petitionerIds
                    }
                    placeHolder="Petitioner Name"
                    className={'custom-input'}
                    onChange={(val) => {
                      // console.log('dd', val.target.value);
                      handleChange(val, 'petitionerId');
                      setParams((prev) => ({
                        ...prev,
                        petitionerAssociated:
                          val.target.value === 'all' || val.target.value === ''
                            ? 'false'
                            : String(
                                petitionList.find(
                                  (petition) =>
                                    petition.ids === val.target.value,
                                )?.status,
                              ),
                      }));
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={tab === 'contract' ? 2 : 1.5} sm={6}>
                <Typography component={'div'} className="custom-field">
                  <Typography className="custom-label">Visa Type</Typography>
                  <CustomSelect
                    isClearable={
                      params?.visaType === '' || params?.visaType === 'all'
                        ? false
                        : true
                    }
                    labelKey={'visa_code'}
                    valueKey={'visa_id'}
                    options={visaList}
                    name={'visaType'}
                    value={params?.visaType === '' ? 'all' : params?.visaType}
                    placeHolder="Visa Type"
                    className={'custom-input'}
                    onChange={(val) => handleChange(val, 'visaType')}
                  />
                </Typography>
              </Grid>

              {tab === 'payment' && (
                <Grid item md={1.5} sm={6}>
                  <Typography component={'div'} className="custom-field">
                    <Typography className="custom-label">
                      Payment Amount
                    </Typography>
                    <CustomInput
                      placeHolder={'Search by amount'}
                      name={'amount'}
                      value={params?.amount}
                      className={'custom-input'}
                      type={'number'}
                      inputProps={{
                        endAdornment:
                          params.amount !== '' ? (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={() => {
                                  setParams((prev) => ({
                                    ...prev,
                                    amount: '',
                                    skip: 0,
                                    take: 50,
                                  }));
                                }}>
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                      }}
                      onChange={(val) => handleChange(val, 'amount')}
                    />
                  </Typography>
                </Grid>
              )}

              {tab === 'contract' && (
                <>
                  <Grid item md={2} sm={6} className="">
                    <Typography component={'div'} className="custom-field">
                      <Typography className="custom-label">
                        Contact Person
                      </Typography>
                      <CustomSelect
                        isClearable={
                          params?.contactId === '' ||
                          params?.contactId === 'all'
                            ? false
                            : true
                        }
                        labelKey={'full_name'}
                        valueKey={'id'}
                        options={!_.isEmpty(contactTypes) ? contactTypes : []}
                        name={'contactId'}
                        value={
                          params?.contactId === '' ? 'all' : params?.contactId
                        }
                        placeHolder={'Contact person'}
                        className={'custom-search-input bo-rles'}
                        onChange={(val) => handleChange(val, 'contactId')}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={5} sm={6} />
                  <Grid item md={1} sm={6} className="d-flex-jae">
                    <Button className="n-add-btn" onClick={resetFilter}>
                      Reset
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
            {tab === 'payment' && (
              <Grid
                container
                columnSpacing={{ md: 2, sm: 1 }}
                className={params.status !== 'success' ? 'd-flex-sb' : ''}>
                {params.status === 'success' && (
                  <Grid item md={2} sm={6}>
                    <Typography component={'div'} className="custom-field">
                      <Typography className="custom-label">
                        Payment Method
                      </Typography>
                      <CustomSelect
                        isClearable={false}
                        labelKey={'label'}
                        valueKey={'value'}
                        options={[
                          {
                            label: 'All',
                            value: 'all',
                          },
                          {
                            label: 'Card',
                            value: 'card',
                          },
                          {
                            label: 'ACH',
                            value: 'ach',
                          },
                        ]}
                        name={'paymentMode'}
                        value={
                          params?.paymentMode === ''
                            ? 'all'
                            : params?.paymentMode
                        }
                        placeHolder=""
                        className={'custom-input'}
                        onChange={(val) =>
                          handleChange(val === '' ? 'all' : val, 'paymentMode')
                        }
                      />
                    </Typography>
                  </Grid>
                )}
                {params.status === 'success' && (
                  <Grid item md={2.5} sm={6}>
                    <Typography component={'div'} className="custom-field">
                      <Typography className="custom-label">
                        Transaction ID
                      </Typography>
                      <CustomInput
                        placeHolder={'Search by ID'}
                        name={'transactionId'}
                        value={params?.transactionId}
                        className={'custom-input'}
                        inputProps={{
                          endAdornment:
                            params.transactionId !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    setParams((prev) => ({
                                      ...prev,
                                      skip: 0,
                                      take: 50,
                                      transactionId: '',
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(val) => handleChange(val, 'transactionId')}
                      />
                    </Typography>
                  </Grid>
                )}
                {params.status === 'success' && (
                  <Grid item md={1.5} sm={6}>
                    <Typography component={'div'} className="custom-field">
                      <Typography className="custom-label">
                        Total Amount
                      </Typography>
                      <CustomInput
                        placeHolder={'Search by total amount'}
                        name={'totalAmount'}
                        value={params?.totalAmount}
                        type={'number'}
                        className={'custom-input'}
                        inputProps={{
                          endAdornment:
                            params.totalAmount !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    setParams((prev) => ({
                                      ...prev,
                                      skip: 0,
                                      take: 50,
                                      totalAmount: '',
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(val) => handleChange(val, 'totalAmount')}
                      />
                    </Typography>
                  </Grid>
                )}
                <Grid item md={2} sm={6} className="">
                  <Typography component={'div'} className="custom-field">
                    <Typography className="custom-label">
                      Invoice Purpose
                    </Typography>
                    <CustomSelect
                      isClearable={
                        params?.invoicePurpose === '' ||
                        params?.invoicePurpose === 'all'
                          ? false
                          : true
                      }
                      labelKey={'name'}
                      valueKey={'id'}
                      options={invoicePurposeList}
                      name={'invoicePurpose'}
                      value={
                        params?.invoicePurpose === ''
                          ? 'all'
                          : params?.invoicePurpose
                      }
                      placeHolder="Invoice Purpose"
                      className={'custom-input'}
                      onChange={(val) => handleChange(val, 'invoicePurpose')}
                    />
                  </Typography>
                </Grid>
                <Grid item md={2} sm={6} className="">
                  <Typography component={'div'} className="custom-field">
                    <Typography className="custom-label">
                      Contact Person
                    </Typography>
                    <CustomSelect
                      isClearable={
                        params?.contactId === '' || params?.contactId === 'all'
                          ? false
                          : true
                      }
                      labelKey={'full_name'}
                      valueKey={'id'}
                      options={!_.isEmpty(contactTypes) ? contactTypes : []}
                      name={'contactId'}
                      value={
                        params?.contactId === '' ? 'all' : params?.contactId
                      }
                      placeHolder={'Contact person'}
                      className={'custom-search-input bo-rles'}
                      onChange={(val) => handleChange(val, 'contactId')}
                    />
                  </Typography>
                </Grid>

                <Grid item md={2} sm={6} className="d-flex-jae">
                  <Button className="n-add-btn" onClick={resetFilter}>
                    Reset
                  </Button>
                </Grid>
              </Grid>
            )}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReportFillter;
