import PrivateLayout from 'components/PrivateLayout';
import PublicLayout from 'components/PublicLayout';
import LeadsList from 'pages/private/Client/Leads';
import ContractPayment from 'pages/private/Client/Leads/ContractPayment';
import ContractViewer from 'pages/private/Client/Leads/ContractPayment/ContractViewer';
import ContractPaymentDetails from 'pages/private/Client/Leads/ContractPayment/Details';
import PaymentDetail from 'pages/private/Client/Leads/ContractPayment/PaymentDetail';
import AchPayment from 'pages/private/Client/Leads/ContractPayment/PaymentMethod/ACHpayment';
import CreditDebitPayment from 'pages/private/Client/Leads/ContractPayment/PaymentMethod/CreditDebitCard';
import PaymentMethodAndTerms from 'pages/private/Client/Leads/ContractPayment/PaymentMethodAndTerms';
import LeadsDetailList from 'pages/private/Client/Leads/DetailsList';
import PetitionerLeadAdd from 'pages/private/Client/Leads/petitionerLeads';
import { PetitionerLandingPage } from 'pages/private/Client/Leads/petitionerLeads/petitionerLeadLandPage';
import UploadDocument from 'pages/private/Client/Leads/UploadDocument';
import PetitionsList from 'pages/private/Client/Petitions';
import { PetitionCaseDetail } from 'pages/private/Client/Petitions/CaseDetail';
import CompleteQuestions from 'pages/private/Client/Petitions/CompleteQuestion';
import { Contractpayment } from 'pages/private/Client/Petitions/ContractPayment';
// import PetitionAdd from 'pages/private/Client/Petitions/Add';
import { PetitionDetailList } from 'pages/private/Client/Petitions/DetailsList';
import PetitionerOrBeneficiaryBuildCaseEdit from 'pages/private/Client/Petitions/PetitionBuildCase/Edit/PetitionerOrBeneficiaryEdit';
import ReviewAndApprove from 'pages/private/Client/Petitions/PetitionBuildCase/ReviewAndApprove';
import SubmissionStation from 'pages/private/Client/Petitions/PetitionBuildCase/SubmissionStation';
import { UploadPetitionsDocument } from 'pages/private/Client/Petitions/UploadPetitionsDocument';
import Socialshare from 'pages/private/Client/socialshare';
import { AssemblePreviewDownload } from 'pages/private/Common/AssemblePreview/AssemblePreviewDownload';
import Strategy from 'pages/private/Common/Strategy';
import StrategyInfo from 'pages/private/Common/Strategy/details';
import { ChangePassword } from 'pages/private/Master/ChangePassword';
import { Dashboard } from 'pages/private/Master/Dashboard';
import Myprofile from 'pages/private/Master/Myprofile';
import AssociationRequest from 'pages/private/Setup/AssociatedLawFirm';
import ManageUserList from 'pages/private/Setup/ManageUser';
import UserAdd from 'pages/private/Setup/ManageUser/Add';
import MyOrganization from 'pages/private/Setup/MyOrganization';
import { RolesPermission } from 'pages/private/Setup/RolesPermissions';
import RoleAdd from 'pages/private/Setup/RolesPermissions/Add';
import AcceptableUsePolicy from 'pages/public/ACCEPTABLEUSEPOLICY';
import DMCAPolicy from 'pages/public/DMCA';
import PrivacyPolicy from 'pages/public/PrivacyPolicy';
import {
  StripeInprocessPayment,
  StripeSucessPayment,
} from 'pages/public/StripePayment/PaymentSucess';
import TermsOfService from 'pages/public/Terms&Service';
import VersionHistory from 'pages/public/VersionHistory';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { CommonPrivateRoutes, PetitionerPrivateRoutes } from '../index';

const PetitionerRoutes: React.FC = (): React.ReactElement => {
  const {
    DASHBOARD,
    CHANGEPASSWORD,
    MYPROFILE,
    ROLES,
    MANAGEUSER,
    PETITIONS,
    POLICY,
    LEADS,
    MYORGANIZATION,
    MYORGANIZATIONEDIT,
    SOCIALSHARE,
    PAYMENT,
  } = PetitionerPrivateRoutes;
  return (
    <Routes>
      <Route path="/" element={<Navigate to={DASHBOARD} />} />
      <Route
        path={DASHBOARD}
        element={
          <PrivateLayout
            routes={{
              linkShow: true,
              path: DASHBOARD,
              show: false,
              title: 'Dashboard',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Dashboard />
          </PrivateLayout>
        }
      />
      <Route
        path={MYORGANIZATION}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'MyOrganiZation',
              linkShow: false,
              path: MYORGANIZATION,
              show: true,
              title: 'My Organization',
            }}
            showTools={true}
            showHeader={true}
            addEditRoute={{
              isAdd: false,
              isEdit: true,
              path: MYORGANIZATIONEDIT,
              title: 'Edit Organization',
            }}>
            <MyOrganization />
          </PrivateLayout>
        }
      />
      <Route
        path={MYORGANIZATIONEDIT}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              path: MYORGANIZATIONEDIT,
              show: true,
              title: 'My OrganiZation',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <MyOrganization />
          </PrivateLayout>
        }
      />
      <Route
        path={MYPROFILE}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              path: MYPROFILE,
              show: true,
              title: 'My Profile',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Myprofile />
          </PrivateLayout>
        }
      />
      <Route
        path={CHANGEPASSWORD}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              path: CHANGEPASSWORD,
              show: true,
              title: 'Change Password',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ChangePassword />
          </PrivateLayout>
        }
      />
      <Route
        path={ROLES.ROLESPERMISSION}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              path: ROLES.ROLESPERMISSION,
              show: true,
              title: 'Roles & Permissions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <RolesPermission />
          </PrivateLayout>
        }
      />
      <Route
        path={`${ROLES.ROLESADD}/:roleId`}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              navigate: true,
              path: ROLES.ROLESPERMISSION,
              show: true,
              title: 'Roles & Permissions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <RoleAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${ROLES.ROLESEDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              navigate: true,
              path: ROLES.ROLESPERMISSION,
              show: true,
              title: 'Roles & Permissions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <RoleAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={MANAGEUSER.MANAGEUSERLIST}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'manageUsers',
              linkShow: false,
              path: MANAGEUSER.MANAGEUSERLIST,
              show: true,
              title: 'Manage Users',
            }}
            showTools={true}
            showHeader={true}
            addEditRoute={{
              isAdd: true,
              isEdit: false,
              path: MANAGEUSER.USERADD,
              title: 'Add New User',
            }}
            hasComponent={'no'}>
            <ManageUserList />
          </PrivateLayout>
        }
      />
      <Route
        path={MANAGEUSER.USERADD}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              navigate: true,
              path: MANAGEUSER.MANAGEUSERLIST,
              show: true,
              title: 'Manage Users',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <UserAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${MANAGEUSER.USEREDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              navigate: true,
              path: MANAGEUSER.MANAGEUSERLIST,
              show: true,
              title: 'Manage Users',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <UserAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={LEADS.LEADSLIST}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'leads',
              linkShow: true,
              path: LEADS.LEADSLIST,
              show: false,
              title: 'Leads',
            }}
            showTools={true}
            showHeader={true}
            addEditRoute={{
              isAdd: true,
              isEdit: false,
              path: LEADS.LEADSADD,
              title: 'New Lead',
            }}
            hasComponent={'no'}>
            <LeadsList />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.LEADDETAILSLIST}/:editId`}
        element={
          <PrivateLayout
            routes={{
              icon: 'leads',
              linkShow: false,
              navigate: true,
              path: LEADS.LEADSLIST,
              show: true,
              title: 'Leads',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <LeadsDetailList />
          </PrivateLayout>
        }
      />
      <Route
        path={LEADS.LEADSADD}
        element={
          <PrivateLayout
            routes={{
              icon: 'leads',
              linkShow: false,
              navigate: true,
              path: LEADS.LEADSLIST,
              show: true,
              title: 'Leads',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <PetitionerLeadAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.LEADSEDIT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Lead Menu',
              icon: 'casemenu',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: LEADS.LEADDETAILSLIST,
              show: true,
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            {/* <PetitionerLeadAdd /> */}
            <PetitionerLandingPage />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.CONTRACTPAYMENT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'contract',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Contract & Payments',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ContractPayment />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.CONTRACTPAYMENTDETAILS}/:editId/:contractId/:userId/:contractType`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'contract',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Contract & Payment',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ContractPaymentDetails />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.UPLOADDOCUMENT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'docupload',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Upload Documents',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={''}>
            <UploadDocument />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.STRATEGY}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'stratey',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Strategy />
          </PrivateLayout>
        }
      />
      <Route
        path={`${LEADS.STRATEGYDETAIL}/:editId`}
        // path={LEADS.STRATEGYDETAIL}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'stratey',
              linkShow: false,
              path: LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <StrategyInfo />
          </PrivateLayout>
        }
      />
      <Route
        path={PETITIONS.MYPETITIONLISTS}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'petitions',
              linkShow: true,
              path: PETITIONS.MYPETITIONLISTS,
              show: false,
              title: 'Petitions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'petition-contact'}>
            <PetitionsList />
          </PrivateLayout>
        }
      />
      <Route
        path={`${PETITIONS.PETITIONLIST}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              icon: 'petitions',
              isDescription: 'petitions',
              linkShow: false,
              navigate: true,
              path: PETITIONS.MYPETITIONLISTS,
              show: true,
              title: 'Petitions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={'all'}>
            <PetitionDetailList />
          </PrivateLayout>
        }
      />
      <Route
        path={`${PETITIONS.PETITIONCASEDETAIL}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'caseinfo',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: PETITIONS.PETITIONLIST,
              show: true,
              title: 'Case Details',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <PetitionCaseDetail />
          </PrivateLayout>
        }
      />
      <Route
        path={`${PETITIONS.COMPLETEQUESTIONS}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'question',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: PETITIONS.PETITIONLIST,
              show: true,
              title: 'Complete Questions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <CompleteQuestions />
          </PrivateLayout>
        }
      />
      <Route
        path={`${PETITIONS.CONTRACTPAYMENT}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'contract',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: PETITIONS.PETITIONLIST,
              show: true,
              title: 'Contract & Payments',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <Contractpayment />
          </PrivateLayout>
        }
      />
      <Route
        path={`${PETITIONS.UPLOADDOCUMENT}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'docupload',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: PETITIONS.PETITIONLIST,
              show: true,
              title: 'Upload Documents',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <UploadPetitionsDocument />
          </PrivateLayout>
        }
      />
      <Route
        path={`${PETITIONS.STRATEGY}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'stratey',
              isParamId: 'petitionId',
              linkShow: false,
              navigate: true,
              path: PETITIONS.PETITIONLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <Strategy />
          </PrivateLayout>
        }
      />
      <Route
        path={`${PETITIONS.STRATEGYDETAIL}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'stratey',
              isParamId: 'petitionId',
              navigate: true,
              path: PETITIONS.PETITIONLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <StrategyInfo />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(PETITIONS.SUBMISSIONSTATION)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'submission',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: PETITIONS.PETITIONLIST,
              show: true,
              title: 'Submission Station',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <SubmissionStation />
          </PrivateLayout>
        }
      />

      <Route
        path={`${String(PETITIONS.REVIEWANDAPPROVE)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'submission',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: PETITIONS.PETITIONLIST,
              show: true,
              title: 'Review & Approve',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <ReviewAndApprove />
          </PrivateLayout>
        }
      />

      <Route
        path={`${String(PETITIONS.BUILDCASEEDIT)}/:petitionId/:templateId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'submission',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: PETITIONS.PETITIONLIST,
              show: true,
              title: 'Review & Approve',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <PetitionerOrBeneficiaryBuildCaseEdit />
          </PrivateLayout>
        }
      />

      <Route
        path={PETITIONS.ASSOCIATELAWFIRM}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              path: PETITIONS.ASSOCIATELAWFIRM,
              show: true,
              title: 'Associated Law Firm',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <AssociationRequest />
          </PrivateLayout>
        }
      />
      <Route
        path={SOCIALSHARE}
        element={
          <PrivateLayout
            routes={{
              linkShow: true,
              path: SOCIALSHARE,
              show: false,
              title: 'Social',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Socialshare />
          </PrivateLayout>
        }
      />
      <Route
        path={`${CommonPrivateRoutes.ASSEMBLEPREVIEW}`}
        element={
          <PrivateLayout
            routes={{
              // path: DOCUMENTI129PREVIEWFOREXTRACTIONID,
              show: false,
              // title: 'Report',
            }}
            showTools={false}
            showHeader={false}
            DefaultHeaderHide={true}>
            <AssemblePreviewDownload />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(CommonPrivateRoutes.SUBMISSIONSTATION)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'submission',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: PETITIONS.PETITIONLIST,
              show: true,
              title: 'Submission Station',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <SubmissionStation />
          </PrivateLayout>
        }
      />

      <Route path={POLICY.PRIVACYPOLICY} element={<PrivacyPolicy />} />
      <Route path={POLICY.DMCAPOLICY} element={<DMCAPolicy />} />
      <Route
        path={POLICY.ACCEPTABLEUSEPOLICY}
        element={<AcceptableUsePolicy />}
      />
      <Route path={POLICY.TERMOFSERVICES} element={<TermsOfService />} />
      <Route path={POLICY.VERSIONHISTORY} element={<VersionHistory />} />

      <Route
        path={PAYMENT.SECUREPAYMENT}
        element={
          <PublicLayout>
            <PaymentDetail />
          </PublicLayout>
        }
      />

      <Route
        path={PAYMENT.STRIPESUCCESS}
        element={
          <PublicLayout>
            <StripeSucessPayment />
          </PublicLayout>
        }
      />

      <Route
        path={PAYMENT.STRIPEINPROCESS}
        element={
          <PublicLayout>
            <StripeInprocessPayment />
          </PublicLayout>
        }
      />

      <Route
        path={PAYMENT.PAYMENTTERMSANDCONDITIONS}
        element={
          <PublicLayout>
            <PaymentMethodAndTerms />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.CREDITDEBITCARD}
        element={
          <PublicLayout>
            <CreditDebitPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.ACH}
        element={
          <PublicLayout>
            <AchPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.CONTRACTVIEWER}
        element={
          <PublicLayout>
            <ContractViewer />
          </PublicLayout>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default PetitionerRoutes;
