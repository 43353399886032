import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { Grid, Tooltip, Typography } from '@mui/material';
import Folder from 'assets/images/folder.png';
import YFolder from 'assets/images/foldery.png';
import Sedits from 'assets/images/sedit.png';
import Star from 'assets/images/star.svg';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import { instantState } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
// import { getPetitionApiData } from 'utils/Handlers';
import { alertOptions } from 'utils/helper';

type CardProps = {
  showType;
  lists;
  setLoader: any;
  setListData?: any;
};
export const CardView: React.FC<CardProps> = ({
  showType,
  lists,
  setLoader,
  setListData,
}) => {
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const { editId } = useParams();
  const location = useLocation();
  // const { petitions } = useAppSelector((store: any) => store.petitionUser);
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [deleteConfirm, setDeleteConfirm] = React.useState({
    deleteId: '',
    info: {
      lead_status: '',
    },
    show: false,
  });

  const deleteLead = async () => {
    setDeleteLoader(true);
    const deletConfirm = await instantState(setDeleteConfirm);
    deleteData(
      '',
      `${String(COMMON_ENDPOINT.DeleteLead)}/${String(deletConfirm.deleteId)}`,
    )
      .then(() => {
        setDeleteConfirm((pre) => ({
          ...pre,
          show: false,
        }));
        setDeleteLoader(false);
        toast.success('Deleted successfully', alertOptions);
        showType === 'leads' && setListData
          ? setListData((pre) => ({
              ...pre,
              data: [...pre.data.filter((e) => e.id !== deletConfirm.deleteId)],
            }))
          : '';
      })
      .catch(() => {
        setDeleteLoader(false);
      });
    setDeleteLoader(false);
  };

  const renderBeneficiaryDetails = (data: any) => {
    if (showType === 'leads') {
      const benefciaryData =
        !_.isEmpty(data.beneficiaryDetails) &&
        data.beneficiaryDetails
          .map((x) => `${String(x.first_name)} ${String(x.last_name)}`)
          .join(', ');

      return (
        <Typography
          className="black-white-text ctitle"
          sx={{
            opacity: '0.9',
          }}>
          {benefciaryData}{' '}
          {data?.visa_name_list.map(
            (visa, vIndex) => `(${String(visa.visa_code)})`,
          )}
        </Typography>
      );
    } else {
      return (
        <Typography
          className="black-white-text ctitle"
          sx={{
            opacity: '0.9',
          }}>
          {data.benificiery_name} {`(${String(data.visa_type)})`}
        </Typography>
      );
    }
  };

  return (
    <>
      {deleteLoader && <div className="liberty-loader" />}
      {lists.data.map((data, index) => (
        <Grid md={6} sm={12} item key={index}>
          <Typography component={'div'} className="leadBox">
            <Typography
              component={'div'}
              className={'leadItem'}
              onClick={() => {
                if (showType === 'petitions') {
                  if (
                    data.isAffilateCase &&
                    [5].includes(data?.affilateRoleId)
                  ) {
                    toast.info('This case has view only access.');
                    return;
                  }
                } else if (showType === 'leads') {
                  if (
                    data.isAffilateCase &&
                    [5].includes(data?.affilateRoleId)
                  ) {
                    toast.info('This Case has view only access.');
                    return;
                  }
                }
                navigate(
                  showType === 'leads'
                    ? userDetail.role_id === 3
                      ? location.pathname.includes('manage-organization')
                        ? location.pathname.includes('invited')
                          ? `${
                              AttorneyPrivateRoutes.MANAGEORGANIZATION
                                .ORGANIZATIONINVITED
                                .ORGANIZATIONINVITEDLEADDETAILS
                            }/${String(data.id)}${
                              editId ? '?orgId=' + editId : ''
                            }`
                          : location.pathname.includes('associated') &&
                            `${
                              AttorneyPrivateRoutes.MANAGEORGANIZATION
                                .ORGANIZATIONASSOCIATED
                                .ORGANIZATIONASSOCIATEDLEADDETAILS
                            }/${String(data.id)}${
                              editId ? '?orgId=' + editId : ''
                            }`
                        : `${
                            AttorneyPrivateRoutes.LEADS.LEADDETAILSLIST
                          }/${String(data.id)}`
                      : userDetail.role_id === 5
                      ? data.isAffilateCase && data.affilateRoleId === 3
                        ? `${
                            AffilatePrivateRoutes.ATTORNEY_LEADS.LEADDETAILSLIST
                          }/${String(data.id)}`
                        : `${
                            AffilatePrivateRoutes.PETITIONER_LEADS
                              .LEADDETAILSLIST
                          }/${String(data.id)}`
                      : `${
                          PetitionerPrivateRoutes.LEADS.LEADDETAILSLIST
                        }/${String(data.id)}`
                    : userDetail.role_id === 3
                    ? location.pathname.includes('manage-organization')
                      ? location.pathname.includes('invited')
                        ? `${
                            AttorneyPrivateRoutes.MANAGEORGANIZATION
                              .ORGANIZATIONINVITED
                              .ORGANIZATIONINVITEDPETITIONDETAILS
                          }/${String(data.id)}${
                            editId ? '?orgId=' + editId : ''
                          }`
                        : location.pathname.includes('associated') &&
                          `${
                            AttorneyPrivateRoutes.MANAGEORGANIZATION
                              .ORGANIZATIONASSOCIATED
                              .ORGANIZATIONASSOCITEDPETITIONDETAILS
                          }/${String(data.id)}${
                            editId ? '?orgId=' + editId : ''
                          }`
                      : `${String(
                          AttorneyPrivateRoutes.PETITIONS.PETITIONLIST,
                        )}/${String(data.id)}`
                    : userDetail.role_id === 5
                    ? `${
                        AffilatePrivateRoutes.PETITIONER_PETITIONS.PETITIONLIST
                      }/${String(data.id)}`
                    : `${String(
                        PetitionerPrivateRoutes.PETITIONS.PETITIONLIST,
                      )}/${String(data.id)}`,
                );
              }}
              sx={{ marginBottom: 'unset !important' }}>
              {data.premium_processing && data.premium_processing !== 'no' && (
                <img
                  src={Star}
                  alt="star"
                  style={{
                    bottom: '10px',
                    position: 'absolute',
                    right: '10px',
                  }}
                />
              )}

              {(showType === 'leads' || showType === 'petitions') &&
                data?.isAffilateCase && (
                  <Typography
                    component={'div'}
                    sx={{
                      color: 'white',
                      fontSize: '16px',
                      fontStyle: 'italic',
                      position: 'absolute',
                      right: '15px',
                      top: '5px',
                    }}>
                    {' '}
                    Affiliate
                  </Typography>
                )}
              {showType === 'petitions' &&
                userDetail.role_id === 3 &&
                !data?.isAffilateCase && (
                  <img
                    src={Sedits}
                    alt="Edit"
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '5px',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `${
                          AttorneyPrivateRoutes.PETITIONS.PETITIONEDIT
                        }/${String(data.id)}`,
                      );
                    }}
                  />
                )}

              <Grid container>
                <Grid item md={11} sm={11} xs={11}>
                  <Typography className="d-flex d-flex-sb w-100">
                    <Typography component={'div'}>
                      <img className="d-block" src={Folder} alt="folder" />
                      <img className="d-none" src={YFolder} alt="folder" />
                    </Typography>
                    <Typography
                      component={'div'}
                      sx={{ paddingLeft: '1rem' }}
                      className="d-flex-1">
                      {renderBeneficiaryDetails(data)}
                      <Typography
                        className="black-white-text f-14"
                        sx={{
                          marginTop: '.5rem !important',
                          opacity: '0.8',
                        }}>
                        Last activity by You {moment(data.created_at).fromNow()}
                      </Typography>
                    </Typography>
                  </Typography>
                </Grid>
                {showType === 'leads' &&
                  userDetail.role_id === 3 &&
                  !data?.isAffilateCase && (
                    <Grid
                      item
                      md={1}
                      sm={1}
                      xs={1}
                      sx={{
                        position: 'relative',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteConfirm((pre) => ({
                          ...pre,
                          deleteId: data.id,
                          info: data,
                          show: true,
                        }));
                      }}>
                      <Tooltip title="Delete" placement="top">
                        <DeleteForeverSharpIcon
                          style={{
                            cursor: 'pointer',
                            fill: 'red',
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  )}
              </Grid>
            </Typography>
          </Typography>
        </Grid>
      ))}
      <ConfirmDialog
        title="Confirm Action"
        floatingBtnShow={true}
        open={deleteConfirm.show}
        onConfirm={(act) => {
          if (act !== 'yes') {
            setDeleteConfirm((pre) => ({
              ...pre,
              show: false,
            }));
          } else {
            deleteLead();
          }
        }}
        autoCloseDisable={true}>
        {deleteConfirm?.info?.lead_status === 'petition' ? (
          <>
            <Typography
              component={'p'}
              width="100%"
              sx={{
                color: 'unset',
                fontSize: 'unset',
              }}>
              Are you sure, want to delete this lead?
            </Typography>
            <Typography
              component={'p'}
              width="100%"
              sx={{
                color: 'unset',
                fontSize: 'unset',
              }}>
              Petition is already created. if you delete this lead petition also
              deleted
            </Typography>
          </>
        ) : (
          'Are you sure, want to delete this lead?'
        )}
      </ConfirmDialog>
    </>
  );
};

export default CardView;
