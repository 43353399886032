import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import PdfViewer from './PdfViewer';

// const pdfjsLib: any = window['pdfjs-dist/build/pdf'];
declare const pdfjsLib: any;
const PdfUrlViewer = (props) => {
  const {
    url,
    setPdf,
    scale,
    overallPage,
    selectedPage,
    shouldDraw,
    setCoOrdinates,
    ...others
  } = props;

  // pdfjsLib.GlobalWorkerOptions.workerSrc =
  //   '//mozilla.github.io/pdf.js/build/pdf.worker.js';
  const pdfRef: any = useRef(null);

  const [itemCount, setItemCount] = useState(0);

  const handleGetPdfPage = useCallback(
    (index: number) => {
      if (pdfRef.current) {
        overallPage && overallPage(pdfRef.current.numPages);
        return pdfRef.current.getPage(index + 1);
      }
    },
    [overallPage],
  );

  useEffect(() => {
    if (url) {
      const loadingTask: any = pdfjsLib.getDocument(url);
      loadingTask.promise.then(
        (pdf) => {
          if (pdf) {
            setPdf(pdf);
            pdfRef.current = pdf;
            setItemCount(
              selectedPage ? selectedPage.length : pdf._pdfInfo.numPages,
            );
          }
        },
        (reason) => {
          // PDF loading error
          console.warn(reason);
        },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, selectedPage]);

  useEffect(() => {
    const externalScript = document.createElement('script');
    const loadError = (onError) => {
      console.error(onError);
    };

    const LoadExternalScript = () => {
      externalScript.onerror = loadError;
      externalScript.id = 'external';
      externalScript.async = true;
      externalScript.type = 'text/javascript';
      externalScript.setAttribute('crossorigin', 'anonymous');
      document.body.appendChild(externalScript);
      externalScript.src = '//mozilla.github.io/pdf.js/build/pdf.js';
    };
    LoadExternalScript();

    return () => {
      document.body.removeChild(externalScript);
    };
  }, []);

  return pdfRef.current ? (
    <PdfViewer
      {...others}
      url={url}
      itemCount={itemCount}
      scale={scale}
      getPdfPage={handleGetPdfPage}
      shouldDraw={shouldDraw}
      selectedPage={selectedPage}
      setCoOrdinates={setCoOrdinates}
    />
  ) : null;
};

PdfUrlViewer.propTypes = {
  overallPage: PropTypes.any.isRequired,
  scale: PropTypes.number.isRequired,
  selectedPage: PropTypes.any,
  setCoOrdinates: PropTypes.any,
  setPdf: PropTypes.any,
  shouldDraw: PropTypes.bool,
  url: PropTypes.any.isRequired,
  windowRef: PropTypes.any.isRequired,
};

export default PdfUrlViewer;
