import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { RadionButtonPopulateSchema } from 'utils/ValidatorSchema';

export const RadioButtonCrossPopulateAdd: React.FC<any> = () => {
  const navigate = useNavigate();
  const [questionList, setQuestionList] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<{
    questionIds: number[];
    question: number;
  }>({
    criteriaMode: 'all',
    defaultValues: {
      question: null,
      questionIds: undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(RadionButtonPopulateSchema),
  });

  const cancelForm = () => {
    navigate(-1);
  };

  const onSubmitForm = (data: { questionIds: number[]; question: number }) => {
    const updateObj = {
      questionIds:
        data.questionIds && data.questionIds.some((e) => e === data.question)
          ? data.questionIds
          : [...data.questionIds, ...[data.question]],
    };
    setLoader(true);
    updateData(
      `${data.question}`,
      updateObj,
      `${SETUP_ENDPOINT.ConfigQuestion}/radio`,
    )
      .then(() => {
        toast.success('Mapped successfully', alertOptions);
        resetForm();
        setLoader(false);
      })
      .catch(() => {
        toast.error('Oops!!! Something went wrong', alertOptions);
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getAllListData(
      `${SETUP_ENDPOINT.ConfigQuestion}?status=true&responseType=5`,
    ).then((resp: any) => {
      setQuestionList(
        resp && resp.data && Array.isArray(resp.data)
          ? resp.data.filter((e) => e?.link_code)
          : [],
      );
    });
  }, []);
  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      {loader ? <div className="liberty-loader" /> : ''}
      <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
        <Typography
          component={'div'}
          className="d-flex-a d-flex-sb sticky-tab mb-2">
          <Typography
            component={'div'}
            className="w-title black-white-text"
            sx={{ margin: 'unset !important' }}>
            Radio Question Mapping
          </Typography>
          <Typography component={'div'} className="d-flex-a">
            <Button className="n-cancel-btn mr-2" onClick={cancelForm}>
              <Typography className="o-box" />
              Cancel
            </Button>
            <Button className="n-add-btn" type="submit" form={'add-question'}>
              {'Submit'}
            </Button>
          </Typography>
        </Typography>

        <form id="add-question" onSubmit={handleSubmit(onSubmitForm)}>
          <Typography
            component={'div'}
            className="addbox"
            sx={{
              marginTop: 'unset !important',
            }}>
            <Box
              component="form"
              className="u-add-in"
              noValidate
              autoComplete="off">
              <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                <Grid item md={6}>
                  <Typography component={'div'} className="custom-field">
                    <Typography component={'div'} className="custom-label">
                      Question <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      name="question"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomSelect
                            closeMenuOnSelect={false}
                            labelKey={'link_code'}
                            valueKey={'id'}
                            options={questionList}
                            isMulti={false}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder={'Select'}
                            className={'custom-input'}
                            error={errors.question?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography component={'div'} className="custom-field">
                    <Typography component={'div'} className="custom-label">
                      Mapping Questions <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      name="questionIds"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomSelect
                            closeMenuOnSelect={false}
                            labelKey={'link_code'}
                            valueKey={'id'}
                            options={questionList}
                            isMulti={true}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder={'Select'}
                            className={'basic-multi-select'}
                            error={errors.questionIds?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Typography>
        </form>
      </Grid>
    </Grid>
  );
};
