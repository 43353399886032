import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormHelperText,
  // FormControlLabel,
  Grid,
  // Radio,
  Typography,
} from '@mui/material';
import Logo from 'assets/images/liberty-logoheader.png';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { PublicRoutes } from 'config';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData } from 'services/CommonServices';
// import { addNewData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { getPublicRequest } from 'utils/Handlers';
import { alertOptions } from 'utils/helper';
import { StripeSignupFirmSchema } from 'utils/ValidatorSchema';

const PlanSignupForm: React.FC = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);

  const [mobileWithCode, setMobileWithCode] = useState<any>({
    mobileCode: '',
    mobileSocCode: '',
  });
  const [createdUser, setCreateUser] = useState<any>([]);
  const [loader, setLoader] = useState(true);

  const [selectedPlan, setSelectedPlan] = useState({
    price: {
      amount: 0,
      id: queryParams.get('price-id'),
      type: '',
    },
    product: {
      description: '',
      id: queryParams.get('product-id'),
      metadata: { total_user: '1-3' },
      name: '',
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      choosedUser: selectedPlan.product.metadata.total_user.split('-')[0],
      email: '',
      firmName: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(StripeSignupFirmSchema),
  });
  const signUpFirm = (data: any) => {
    addNewData(
      { email: data.email },
      `${MASTER_ENDPOINT.StripeEmailExist}`,
    ).then(() => {
      data['mobileCode'] = mobileWithCode.mobileCode;
      data['mobileSocCode'] = mobileWithCode.mobileSocCode
        ? String(mobileWithCode.mobileSocCode).toUpperCase()
        : null;

      localStorage.setItem('attorneySubscription', JSON.stringify(data));
      navigate(PublicRoutes.STRIPE.BILLINGDETAILS, {
        state: {
          formData: data,
          price:
            queryParams.get('product-id') &&
            queryParams.get('price-id') &&
            selectedPlan.price
              ? selectedPlan.price
              : null,
          product:
            queryParams.get('product-id') &&
            queryParams.get('price-id') &&
            selectedPlan.product
              ? selectedPlan.product
              : null,
          trialId:
            queryParams.get('options') && queryParams.get('options')
              ? queryParams.get('options')
              : null,
        },
      });
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  // const onChangePlan = (e, plan, price) => {
  //   setValue('choosedUser', plan.metadata.total_user === '1-3' ? 1 : 4);
  //   setSelectedPlan({
  //     price: {
  //       amount: price.unit_amount / 100,
  //       id: price.id,
  //       type: price.recurring.interval,
  //     },
  //     product: plan,
  //   });
  //   setIsChecked(e.currentTarget.checked);
  // };

  useEffect(() => {
    if (queryParams.get('product-id') && queryParams.get('price-id')) {
      getPublicRequest(MASTER_ENDPOINT.StripeProductList)
        .then((resp: any) => {
          // setPricePlans(resp);

          const currentPlan = resp.find(
            (plan) => plan.id === queryParams.get('product-id'),
          );
          const attorneySubscription: any = JSON.parse(
            localStorage.getItem('attorneySubscription'),
          );
          if (currentPlan) {
            const text = [];
            const start = Number(currentPlan.metadata.total_user.split('-')[0]);
            const endData = Number(
              currentPlan.metadata.total_user.split('-')[1],
            );
            for (let i: any = start; i <= endData; i++) {
              text.push({
                label: String(i),
                value: i,
              });
            }
            setCreateUser(text);

            if (attorneySubscription) {
              setValue('email', attorneySubscription.email);
              setValue('firmName', attorneySubscription.firmName);
              setValue('firstName', attorneySubscription.firstName);
              setValue('lastName', attorneySubscription.lastName);
              setValue('choosedUser', attorneySubscription.choosedUser);
              setValue('phone', attorneySubscription.phone);
            } else {
              setValue(
                'choosedUser',
                currentPlan.metadata.total_user.split('-')[0],
              );
            }

            currentPlan.price.filter((price) => {
              if (price.id === queryParams.get('price-id')) {
                setSelectedPlan({
                  price: {
                    amount: price.unit_amount / 100,
                    id: queryParams.get('price-id'),
                    type: price.recurring.interval,
                  },
                  product: currentPlan,
                });
              }
            });

            setLoader(false);
          } else {
            toast.error('Product pricing not found.', alertOptions);
            navigate('/login');
          }
        })
        .catch((e: any) => {
          toast.error(e.error.message, alertOptions);
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.get('price-id')]);

  // useEffect(() => {
  //   if (!location.state) {
  //     navigate(PublicRoutes.STRIPE.PRICING);
  //   }
  // }, [location.state, navigate]);

  return (
    <div>
      <Typography
        className=" black-white-text p-f pt-2"
        sx={{
          backgroundColor: '#000',
          height: '84px',
        }}>
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <form onSubmit={handleSubmit(signUpFirm)}>
          <div
            className="innerBox payment-public"
            style={{
              height: 'calc(100vh - 161px)',
              overflow: 'auto',
              textAlign: 'left',
            }}>
            <div className="minmax-paybox">
              <Grid container sx={{ paddingTop: '43px' }}>
                <Grid item md={8} sm={12}>
                  <Typography className="ab2-title">
                    Sign Up for Visas.AI
                  </Typography>
                  {/* <Typography className="ab-info">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse scelerisque at dolor sit amet mattis. Cras
                    mattis sem id pellentesque pulvinar. Nullam sed ipsum
                    euismod.
                  </Typography> */}
                </Grid>
                {/* <Grid item md={4} sm={12} className="d-flex-jae">
                  <Typography className="change-btn d-flex-a">
                    {pricePlans.length ? 'Change Plan' : 'No Plan'}
                    {pricePlans.length && (
                      <div className="over-box">
                        {pricePlans.map(
                          (plan, index) =>
                            plan.active && (
                              <div key={index}>
                                <p>{plan.name}</p>
                                <div
                                  className="d-flex-a"
                                  style={{ padding: '1px 12px 1px 12px' }}>
                                  {plan.price.length &&
                                    plan.price.map((price, pIndex) => (
                                      <div key={pIndex} className="d-flex-a">
                                        <FormControlLabel
                                          checked={
                                            isChecked &&
                                            price.id === selectedPlan.price.id
                                          }
                                          control={<Radio />}
                                          onChange={(event) =>
                                            onChangePlan(event, plan, price)
                                          }
                                          label={capitalize(
                                            price.recurring.interval,
                                          )}
                                        />
                                      </div>
                                    ))}
                                </div>
                              </div>
                            ),
                        )}
                      </div>
                    )}
                  </Typography>
                </Grid> */}
              </Grid>
              <Grid container sx={{ paddingTop: '24px' }}>
                <Grid md={9}>
                  <Typography component={'div'}>
                    <Box
                      component="form"
                      className="u-add-in"
                      sx={{
                        background: 'unset',
                        border: 'unset',
                        borderRadius: '12px !important',
                        marginLeft: '-24px',
                        padding: 'unset !important',
                      }}
                      noValidate
                      autoComplete="off">
                      <Grid container columnSpacing={{ md: 3, sm: 2 }}>
                        <Grid item md={6} sm={12}>
                          <Typography
                            component={'div'}
                            className="custom-field ">
                            <Typography
                              component={'div'}
                              className="custom-label ">
                              First Name <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              name="firstName"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    placeHolder="First Name"
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errors.firstName?.message}
                                    onChange={(e) => {
                                      const vals = e.target.value.replace(
                                        /[^a-zA-Z s]/,
                                        '',
                                      );
                                      onChange(vals);
                                    }}
                                    tabIndex={1}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12}>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label ">
                              Last Name <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              name="lastName"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    placeHolder="Last Name"
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errors.lastName?.message}
                                    onChange={(e) => {
                                      const vals = e.target.value.replace(
                                        /[^a-zA-Z]/,
                                        '',
                                      );
                                      onChange(vals);
                                    }}
                                    tabIndex={2}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12}>
                          <Typography
                            component={'div'}
                            className="custom-field ">
                            <Typography
                              component={'div'}
                              className="custom-label ">
                              Mobile Number{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              name="phone"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  // <CustomInput
                                  //   placeHolder="Phone"
                                  //   name={name}
                                  //   value={value}
                                  //   className={'custom-input'}
                                  //   error={errors.phone?.message}
                                  //   onChange={(e) =>
                                  //     onChange(
                                  //       e.target.value.replace(/[^\d]/g, ''),
                                  //     )
                                  //   }
                                  //   type={'text'}
                                  //   tabIndex={3}
                                  // />
                                  <>
                                    <PhoneInput
                                      placeholder="Phone Number"
                                      country={'us'}
                                      value={value}
                                      onChange={(
                                        value,
                                        data,
                                        event,
                                        formattedValue,
                                      ) => {
                                        const codes: any = data;
                                        onChange(formattedValue);
                                        setMobileWithCode({
                                          mobileCode: '+'.concat(
                                            codes?.dialCode,
                                          ),
                                          mobileSocCode: codes?.countryCode,
                                        });
                                      }}
                                    />
                                    {errors.phone?.message && (
                                      <FormHelperText
                                        style={{ color: '#FF0000' }}>
                                        {errors.phone?.message.toString()}
                                      </FormHelperText>
                                    )}
                                  </>
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12}>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label ">
                              Email <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              name="email"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    maxLength={50}
                                    placeHolder="Email"
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errors.email?.message}
                                    onChange={(val) => onChange(val)}
                                    tabIndex={4}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12}>
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label ">
                              Attorney Firm Name{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              name="firmName"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomInput
                                    maxLength={50}
                                    placeHolder="Attorney Firm Name"
                                    name={name}
                                    value={value}
                                    className={'custom-input'}
                                    error={errors.firmName?.message}
                                    onChange={(val) => onChange(val)}
                                    tabIndex={5}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={7.5} sm={12} sx={{ paddingTop: '25px' }}>
                          <Typography className="ab-title">
                            How many users do you have?
                          </Typography>
                          <Typography
                            component={'div'}
                            className="d-flex-a mt-1">
                            <Typography
                              component={'div'}
                              sx={{ width: '150px' }}>
                              <Controller
                                control={control}
                                name="choosedUser"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomSelect
                                      name={name}
                                      value={value}
                                      labelKey={'label'}
                                      valueKey={'value'}
                                      options={createdUser}
                                      onChange={(val) => onChange(val)}
                                      error={errors.choosedUser?.message}
                                      placeHolder={'Select user'}
                                      className={'custom-input'}
                                      isClearable={false}
                                      searchable={false}
                                      tabIndex={6}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                            <Typography
                              sx={{ fontSize: '14px', paddingLeft: '8px' }}>
                              User(s)
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={7.5} sm={12} sx={{ paddingTop: '25px' }}>
                          <Typography className="ab-title ">
                            Your Plan Details
                          </Typography>
                          {queryParams.get('price-id') ? (
                            <Typography
                              component={'div'}
                              className="plan-card mt-1">
                              <Typography className="plan-title ">
                                {selectedPlan.product.name}
                              </Typography>
                              <Typography className="plan-info">
                                ({selectedPlan.product.metadata.total_user}{' '}
                                users)
                              </Typography>
                              <Typography className="idea-plan">
                                {selectedPlan.product.description}
                              </Typography>
                              <Typography className="prise-info">
                                <span>PRICE:</span>{' '}
                                <span className="fon-500">
                                  {' '}
                                  ${selectedPlan.price.amount} / User /{' '}
                                  {capitalize(selectedPlan.price.type)}
                                </span>
                              </Typography>
                            </Typography>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
          <div
            className="d-flex-jae"
            style={{
              backgroundColor: '#fff',
              borderTop: '1px solid #ccc',
              minHeight: '77px',
              padding: '18px',
            }}>
            <Button
              className="n-cancel-btn"
              sx={{ marginRight: '15px' }}
              onClick={() => goBack()}>
              Cancel
            </Button>
            <Button className="n-add-btn" type="submit" tabIndex={7}>
              Save & Continue
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default PlanSignupForm;
