/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import Add from 'assets/images/badd.svg';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomCheckBox from 'components/CustomCheckBox/customCheckBox';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
import { AttorneyPrivateRoutes } from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getAllListData,
  getSingleData,
  updateData,
} from 'services/CommonServices';
import { storeOrganizationData } from 'store/actions/LeadsOrPetitionerAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { alertOptions } from 'utils/helper';

import { ManageOrganizationtypes } from '../../../../../types';
import {
  COMMON_ENDPOINT,
  MASTER_ENDPOINT,
  SETUP_ENDPOINT,
} from '../../../../../utils/Constant';
import { ManageOrganizationAdd } from '../../../../../utils/ValidatorSchema';
import ContactPersonList from '../contact-person-list/customcomponent/index';
// import ContactPersonList from '../contact-person-list';

export const OrganizationAdd: React.FC<any> = () => {
  const navigate: any = useNavigate();
  const { editId } = useParams();
  const [confirmDialog, setConfirmDialog] = useState({
    show: false,
  });
  const [params, setParams] = React.useState({
    associated: location.pathname.includes('associated') ? true : false,
    name: '',
    skip: 0,
    take: 50,
  });
  const debouncedParams = useDebounce(params, 500);
  const [dialogView, setDialogView] = React.useState<boolean>(false);
  const [list, setList] = React.useState([]);
  const [selectPetitionId, setSelectPetitionId] = React.useState([]);
  const [selectAllPetitionId, setSelectAllPetitionId] = React.useState([]);
  const [countries, setCountries] = useState<any>({
    cities: [],
    coutries: [],
    state: [],
  });
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dropDownChange, setDropDownChange] = useState(false);
  const {
    control: control,
    // handleSubmit: handleSubmit,
    // reset: resetForm,
    setValue,
    watch,
    formState: { errors },
    getValues,
  } = useForm<ManageOrganizationtypes>({
    criteriaMode: 'all',
    defaultValues: {
      FirmName: '',
      associated: false,
      grossAnnualIncome: '',
      isSignatoryUpdated: false,
      netAnnualIncome: '',
      newSignatoryId: '',
      oldSignatoryId: '',
      organizationCity: '',
      organizationCountry: '',
      organizationEmail: '',
      organizationFEINNumber: '',
      organizationFax: '',
      organizationName: '',
      organizationPhone: '',
      organizationSignatoryEmail: '',
      organizationSignatoryFirstName: '',
      organizationSignatoryLastName: '',
      organizationSignatoryTitle: '',
      organizationState: '',
      organizationStatus: 'active',
      organizationWebsite: '',
      organizationZipCode: '',
      streetNumberAndName: '',
      totalNumberOfEmployees: '',
      typeOfBusiness: '',
      unitNumber: '',
      unitType: 'apt',
      yearEstablished: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ManageOrganizationAdd),
  });
  const [contactslist, setcontactslist] = useState<any>([]);
  const [contactslistNew, setcontactslistNew] = useState<any>([]);
  const [singlecontactslist, setsinglecontactslist] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [seclect, setseclect] = useState<any>(false);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const getCountriesDependent = useCallback((key?: string, value?: number) => {
    getAllListData(
      key === ''
        ? MASTER_ENDPOINT.Countries
        : `${MASTER_ENDPOINT.Countries}?${key}=${String(value)}`,
    )
      .then((countryResp: any) => {
        if (key === 'countryId') {
          setCountries((prev) => ({
            ...prev,
            countries: countryResp.countries,
            states: countryResp.states,
          }));
        }
        if (key === 'stateId') {
          setCountries((prev) => ({
            ...prev,
            cities: countryResp.cities,
            countries: countryResp.countries,
          }));
        }
        if (key === '') {
          setCountries({
            cities: countryResp.cities,
            countries: countryResp.countries,
            states: countryResp.states,
          });
        }
        // setLoader(false);
      })
      .catch((e: any) => {
        // setLoader(false);
      });
  }, []);
  useEffect(() => {
    getAllListData(
      `/${String(SETUP_ENDPOINT.OrganizationDetails)}/${String(
        editId,
      )}?associated=${debouncedParams.associated}&skip=${
        debouncedParams.skip
      }&take=${debouncedParams.take}&name=${debouncedParams.name}`,
    )
      .then((resp: any) => {
        setList(resp.data && resp.data);
        setSelectAllPetitionId(resp.data && resp.data.map((e) => e.id));
      })
      .catch((e) => {
        // setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);
  const checkDataList = (value, data) => {
    if (value) {
      setSelectPetitionId((pre) => [...pre, data.id]);
    } else {
      setSelectPetitionId((pre) => [...pre.filter((e) => e !== data.id)]);
    }
  };
  const cancelForm = () => {
    // resetForm();
    if (userDetail.role_id === 3) {
      navigate(
        location.pathname.includes('associated')
          ? `${AttorneyPrivateRoutes.MANAGEORGANIZATION.ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCIATEDOVERVIEW}/${editId}`
          : `${AttorneyPrivateRoutes.MANAGEORGANIZATION.ORGANIZATIONINVITED.ORGANIZATIONINVITEDOVERVIEW}/${editId}`,
        {
          state: {
            tab: location.pathname.includes('invited')
              ? 'invited'
              : 'associated',
          },
        },
      );
    } else {
      navigate(-1);
    }
    // navigate(-1);
  };
  const changeConfirmation = async (action: string) => {
    if (action !== 'yes') {
      setConfirmDialog((pre) => ({
        ...pre,
        show: false,
      }));
      setDropDownChange(false);
      return Savefunction();
    }
    await setDialogView(true);
  };

  const Savefunction = async () => {
    const seclect = await instantState(setseclect);
    const dropDownChange = await instantState(setDropDownChange);
    setConfirmDialog((pre) => ({
      ...pre,
      show: false,
    }));
    // handleSubmit(Savefunction);
    // Object.entries({
    //   grossAnnualIncome: watch().grossAnnualIncome,
    //   netAnnualIncome: watch().netAnnualIncome,
    //   organizationAddress: watch().streetNumberAndName,
    //   organizationCity: watch().organizationCity,
    //   organizationCountry: watch().organizationCountry,
    //   organizationEmail: watch().organizationEmail,
    //   organizationFEINNumber: watch().organizationFEINNumber,
    //   organizationFax: watch().organizationFax,
    //   organizationName: watch().organizationName,
    //   organizationPhone: watch().organizationPhone,
    //   organizationSignatoryEmail: watch().organizationSignatoryEmail,
    //   organizationSignatoryFirstName: watch().organizationSignatoryFirstName,
    //   organizationSignatoryLastName: watch().organizationSignatoryLastName,
    //   organizationSignatoryTitle: watch().organizationSignatoryTitle,
    //   organizationState: watch().organizationState,
    //   organizationStatus: watch().organizationStatus,
    //   organizationWebsite: watch().organizationWebsite,
    //   organizationZipCode: watch().organizationZipCode,
    //   streetNumberAndName: watch().streetNumberAndName,
    //   totalNumberOfEmployees: watch().totalNumberOfEmployees,
    //   typeOfBusiness: watch().typeOfBusiness,
    //   unitNumber: watch().unitNumber,
    //   unitType: watch().unitType,
    //   validTill: '',
    //   yearEstablished: watch().yearEstablished,
    // }).forEach((ea) => formData.append(`${ea[0]}`, `${ea[1]}`));

    if (Object.entries(errors).length > 0) {
      return;
    } else {
      const postFormData: any = new FormData();
      postFormData.append('organizationName', watch().organizationName);

      postFormData.append('streetNumberAndName', watch().streetNumberAndName);

      postFormData.append('unitType', watch().unitType);

      postFormData.append('unitNumber', watch().unitNumber);

      postFormData.append('organizationZipCode', watch().organizationZipCode);

      postFormData.append('organizationCity', String(watch().organizationCity));

      postFormData.append(
        'organizationState',
        String(watch().organizationState),
      );

      postFormData.append(
        'organizationCountry',
        String(watch().organizationCountry),
      );

      postFormData.append('organizationPhone', watch().organizationPhone);

      postFormData.append('organizationFax', watch().organizationFax);

      postFormData.append('organizationEmail', watch().organizationEmail);

      postFormData.append('organizationWebsite', watch().organizationWebsite);

      postFormData.append('typeOfBusiness', watch().typeOfBusiness);

      postFormData.append('yearEstablished', watch().yearEstablished);

      postFormData.append(
        'totalNumberOfEmployees',
        watch().totalNumberOfEmployees,
      );

      postFormData.append('grossAnnualIncome', watch().grossAnnualIncome);

      postFormData.append('netAnnualIncome', watch().netAnnualIncome);

      postFormData.append(
        'organizationFEINNumber',
        watch().organizationFEINNumber,
      );

      postFormData.append(
        'organizationSignatoryFirstName',
        watch().organizationSignatoryFirstName,
      );

      postFormData.append(
        'organizationSignatoryLastName',
        watch().organizationSignatoryLastName,
      );

      postFormData.append(
        'organizationSignatoryEmail',
        watch().organizationSignatoryEmail,
      );

      postFormData.append(
        'organizationSignatoryTitle',
        watch().organizationSignatoryTitle,
      );
      postFormData.append('organizationStatus', 'active');
      postFormData.append('isSignatoryUpdated', seclect);
      postFormData.append(
        'newSignatoryId',
        watch().newSignatoryId ? watch().newSignatoryId : '',
      );
      postFormData.append(
        'oldSignatoryId',
        watch().oldSignatoryId ? watch().oldSignatoryId : '',
      );
      if (selectPetitionId.length && location.pathname.includes('associated')) {
        postFormData.append('petitionId', selectPetitionId);
        postFormData.append('updatePetitionData', true);
      }
      if (dropDownChange && selectAllPetitionId.length) {
        postFormData.append('petitionId', selectAllPetitionId);
        postFormData.append('updatePetitionData', true);
      }
      setLoader(true);
      if (location.pathname.includes('invited')) {
        const dataObj = {
          ...getValues(),
          isSignatoryUpdated: seclect,
          petitionId: selectPetitionId,
          updatePetitionData: true,
        };
        const bodyData = {
          ...getValues(),
          isSignatoryUpdated: seclect,
        };
        const dropDownUpdate = {
          ...getValues(),
          isSignatoryUpdated: seclect,
          petitionId: selectAllPetitionId,
          updatePetitionData: true,
        };
        updateData(
          editId,
          selectPetitionId.length
            ? dataObj
            : selectAllPetitionId.length && dropDownChange
            ? dropDownUpdate
            : bodyData,
          `${String(SETUP_ENDPOINT.ManageOrganizationInvite)}`,
        )
          .then((updated) => {
            setLoader(false);
            // cancelForm();
            toast.success('Updated successfully', alertOptions);
            setDialogView(false);
            setDropDownChange(false);
            setSelectPetitionId([]);
            setValue('oldSignatoryId', watch('newSignatoryId'));
            getAllData();
          })
          .catch((err) => {
            setLoader(false);
            toast.error('Failed', alertOptions);
          });
      }

      if (location.pathname.includes('associated')) {
        updateData(
          editId,
          postFormData,
          `${String(SETUP_ENDPOINT.ManageOrganizationAssociate)}`,
        )
          .then((updated) => {
            setLoader(false);
            toast.success('Updated successfully', alertOptions);
            setDialogView(false);
            setSelectPetitionId([]);
            setDropDownChange(false);
            setValue('oldSignatoryId', watch('newSignatoryId'));
            getAllData();
            // cancelForm();
          })
          .catch((err) => {
            setLoader(false);
            toast.error('Failed', alertOptions);
          });
      }
    }
  };
  // const onSubmitForm = (data: any) => {
  //   Savefunction();
  // };

  const contactPersonChange = (e) => {
    setDropDownChange(true);
    const contactPersonSelect = contactslist.find(
      (person) => person.id === e.target.value,
    );
    if (contactPersonSelect) {
      setseclect(true);
      setValue(
        'organizationSignatoryFirstName',
        contactPersonSelect?.first_name || '',
      );
      setValue(
        'organizationSignatoryLastName',
        contactPersonSelect?.last_name || '',
      );
      setValue(
        'organizationSignatoryEmail',
        contactPersonSelect?.email_id || '',
      );
      setValue('organizationSignatoryTitle', contactPersonSelect?.title || '');
      setValue('newSignatoryId', contactPersonSelect?.id);
    }
    Savefunction();
  };

  const getAllData = () => {
    getSingleData(
      editId,
      location.pathname.includes('associated')
        ? SETUP_ENDPOINT.ManageOrganizationAssociate
        : SETUP_ENDPOINT.ManageOrganizationEdit,
    ).then((editresultdata) => {
      dispatch(
        storeOrganizationData(editresultdata && editresultdata?.data[0]),
      );
      location.pathname.includes('associated')
        ? getAllListData(
            `${COMMON_ENDPOINT.ManagePetitioner}/user/${String(editId)}`,
          )
            .then((userResp: any) => {
              setcontactslistNew(userResp.length > 0 ? userResp : []);
              setcontactslist(userResp.length > 0 ? userResp : []);
              const signatoryContact = userResp?.find((e) => {
                return e.is_signatory;
              });
              setValue('newSignatoryId', signatoryContact?.id);
              setValue('oldSignatoryId', signatoryContact?.id);
            })
            .catch((e: any) => {
              setLoader(false);
            })
        : setcontactslist(
            editresultdata.contacts && Array.isArray(editresultdata.contacts)
              ? editresultdata.contacts
              : [],
          );
      setcontactslistNew(
        editresultdata.contacts && Array.isArray(editresultdata.contacts)
          ? editresultdata.contacts
          : [],
      );

      setsinglecontactslist(editresultdata ?? '');
      setValue('FirmName', editresultdata?.data[0]?.firm_name || '');
      setValue('organizationName', editresultdata?.data[0]?.org_name || '');

      setValue(
        'streetNumberAndName',
        editresultdata.data[0].org_street_number_and_name || '',
      );

      setValue('unitType', editresultdata.data[0].org_unit_type || '');

      setValue('unitNumber', editresultdata.data[0].org_unit_number || '');

      setValue(
        'organizationZipCode',
        editresultdata.data[0].org_zip_code || '',
      );

      setValue(
        'organizationCity',
        Number(editresultdata.data[0].org_city) || '',
      );

      setValue(
        'organizationState',
        Number(editresultdata.data[0].org_state) || '',
      );

      setValue(
        'organizationCountry',
        Number(editresultdata.data[0].org_country) || '',
      );

      setValue('organizationPhone', editresultdata.data[0].org_phone || '');

      setValue('organizationFax', editresultdata.data[0].org_fax || '');

      setValue(
        'organizationEmail',
        editresultdata.data[0].org_email
          ? editresultdata.data[0].org_email
          : '',
      );

      setValue('organizationWebsite', editresultdata.data[0].org_website || '');

      setValue(
        'typeOfBusiness',
        editresultdata.data[0].org_business_type || '',
      );

      setValue(
        'yearEstablished',
        editresultdata.data[0].org_year_established || '',
      );

      setValue(
        'totalNumberOfEmployees',
        editresultdata.data[0].org_total_employess || '',
      );

      setValue(
        'grossAnnualIncome',
        editresultdata.data[0].org_gross_annual_income || '',
      );

      setValue(
        'netAnnualIncome',
        editresultdata.data[0].org_net_annual_income || '',
      );

      setValue(
        'organizationFEINNumber',
        editresultdata.data[0].org_fein_number || '',
      );

      setValue(
        'organizationSignatoryFirstName',
        editresultdata.data[0].org_signatory_first_name || '',
      );

      setValue(
        'organizationSignatoryLastName',
        editresultdata.data[0].org_signatory_last_name || '',
      );

      setValue(
        'organizationSignatoryEmail',
        editresultdata.data[0].org_signatory_email || '',
      );

      setValue(
        'organizationSignatoryTitle',
        editresultdata.data[0].org_signatory_title || '',
      );
      setValue('organizationStatus', 'active');

      !_.isNull(editresultdata.data[0].org_state)
        ? getCountriesDependent('stateId', editresultdata.data[0].org_state)
        : getCountriesDependent('');
      !_.isNull(editresultdata.data[0].org_country)
        ? getCountriesDependent('countryId', editresultdata.data[0].org_country)
        : getCountriesDependent('');

      const signatoryContact = editresultdata?.contacts?.find((e) => {
        return e.is_signatory;
      });
      setValue('newSignatoryId', signatoryContact?.id);
      setValue('oldSignatoryId', signatoryContact?.id);
      // setNewContactUser(signatoryContact?.id);
    });
  };
  useEffect(() => {
    // getSingleData(editId, SETUP_ENDPOINT.ManageOrganizationEdit).then(
    getAllData();
  }, []);

  // const getcustomfieldanswer = useCallback(async () => {
  //   try {
  //     const customfieldsvar = await getSingleData(
  //       '80',
  //       `${SETUP_ENDPOINT.ManageOrganization}/contact`,
  //     );
  //     // setcustomfields(customfieldsvar);
  //   } catch (err) {
  //   }
  // }, []);
  useEffect(() => {
    if (location.pathname.includes('associated')) {
      setValue('associated', true);
    } else {
      setValue('associated', false);
    }
    // getcustomfieldanswer();
    // getAllListData(MASTER_ENDPOINT.Countries).then((ct) => {
    // getAllListData(`${MASTER_ENDPOINT.Countries}?${key}=${String(value)}`).then((ct) => {
    // getAllListData(`${MASTER_ENDPOINT.Countries}?countryId=${String(18)}`).then(
    getAllListData(MASTER_ENDPOINT.Countries).then((ct) => {
      setCountries((p) => ({ ...p, countries: [...ct.countries] }));
    });
  }, []);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <form onSubmit={Savefunction}>
          <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
            <Typography component={'div'} className="w-title black-white-text">
              Edit Organization
            </Typography>
            <Typography
              component={'div'}
              className="addbox"
              sx={{ marginTop: '1rem !important' }}>
              <Typography
                component={'div'}
                className="uadd-title"
                sx={{ paddingTop: '0rem !important' }}>
                Organizaton Details
              </Typography>
              <Box component="div" className="u-add-in">
                <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                  {location.pathname.includes('associated') && (
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field ">
                        <Typography component={'div'} className="custom-label">
                          Organization Name{' '}
                          <Typography component={'span'} className="Mui-error">
                            *
                          </Typography>
                        </Typography>
                        <Controller
                          control={control}
                          name={'organizationName'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                maxLength={50}
                                placeHolder="Organization Name"
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.organizationName?.message}
                                onChange={(val) => {
                                  onChange(val);
                                }}
                                tabIndex={1}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  )}
                  {!location.pathname.includes('associated') && (
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field ">
                        <Typography component={'div'} className="custom-label">
                          Organization Name{' '}
                          <Typography component={'span'} className="Mui-error">
                            *
                          </Typography>
                        </Typography>
                        <Controller
                          control={control}
                          name={'FirmName'}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                maxLength={50}
                                placeHolder="Organization Name"
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.FirmName?.message}
                                onChange={(val) => {
                                  onChange(val);
                                }}
                                tabIndex={1}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  )}
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Street Number And Name
                      </Typography>
                      <Controller
                        control={control}
                        name="streetNumberAndName"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder=" Street Number And Name"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.streetNumberAndName?.message}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              tabIndex={2}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Unit Type
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue="apt"
                        name="unitType"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name={name}
                              value={value}
                              tabIndex={3}
                              onChange={(e) => {
                                onChange(e);
                              }}>
                              <FormControlLabel
                                value="apt"
                                control={<Radio />}
                                label="Apartment"
                              />
                              <FormControlLabel
                                value="ste"
                                control={<Radio />}
                                label="Suite"
                              />
                              <FormControlLabel
                                value="flr"
                                control={<Radio />}
                                label="Floor"
                              />
                              {getValues().unitType && (
                                <FormControlLabel
                                  value=""
                                  control={
                                    <span
                                      className="reset-button"
                                      onClick={() => setValue('unitType', '')}>
                                      Reset
                                    </span>
                                  }
                                  label=""
                                />
                              )}
                            </RadioGroup>
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Unit Number
                      </Typography>
                      <Controller
                        control={control}
                        name="unitNumber"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Unit Number"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.unitNumber?.message}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              tabIndex={4}
                            />
                          );
                        }}
                      />{' '}
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Country
                      </Typography>
                      {/* <Controller
                      control={control}
                      name="organizationCountry"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomInput
                            maxLength={50}
                            placeHolder="organizationCountry"
                            name={name}
                            value={value}
                            className={'custom-input'}
                            error={errors.organizationCountry?.message}
                            onChange={(val) => {
                              onChange(val);
                            }}
                          />
                        );
                      }}
                    /> */}
                      <Controller
                        control={control}
                        defaultValue=""
                        name="organizationCountry"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              searchable={true}
                              labelKey={'name'}
                              valueKey={'id'}
                              options={
                                !_.isEmpty(countries?.countries)
                                  ? countries?.countries
                                  : []
                              }
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                getCountriesDependent(
                                  'countryId',
                                  e.target.value,
                                );
                              }}
                              placeHolder={'Select'}
                              className={'custom-input'}
                              error={errors.organizationCountry?.message}
                              tabIndex={5}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        State
                      </Typography>
                      {/* <Controller
                      control={control}
                      name="organizationState"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomInput
                            maxLength={50}
                            placeHolder="organizationState"
                            name={name}
                            value={value}
                            className={'custom-input'}
                            error={errors.organizationState?.message}
                            onChange={(val) => {
                              onChange(val);
                            }}
                          />
                        );
                      }}
                    /> */}
                      <Controller
                        control={control}
                        defaultValue=""
                        name="organizationState"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              searchable={true}
                              labelKey={'name'}
                              valueKey={'id'}
                              options={
                                !_.isEmpty(countries?.states)
                                  ? countries?.states
                                  : []
                              }
                              name={name}
                              value={value}
                              onChange={(el) => {
                                onChange(el);
                                getCountriesDependent(
                                  'stateId',
                                  el.target.value,
                                );
                              }}
                              placeHolder="select"
                              className={'custom-input'}
                              error={errors.organizationState?.message}
                              tabIndex={6}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        City
                      </Typography>
                      {/* <Controller
                      control={control}
                      name="organizationCity"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <CustomInput
                            maxLength={50}
                            placeHolder="organizationCity"
                            name={name}
                            value={value}
                            className={'custom-input'}
                            error={errors.organizationCity?.message}
                            onChange={(val) => {
                              onChange(val);
                            }}
                          />
                        );
                      }}
                    /> */}

                      <Controller
                        control={control}
                        defaultValue=""
                        name="organizationCity"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              searchable={true}
                              labelKey={'name'}
                              valueKey={'id'}
                              options={
                                !_.isEmpty(countries?.cities)
                                  ? countries?.cities
                                  : []
                              }
                              name={name}
                              value={value}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              placeHolder="select"
                              className={'custom-input'}
                              error={errors.organizationCity?.message}
                              tabIndex={7}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Zip / Postal Code
                      </Typography>
                      <Controller
                        control={control}
                        name="organizationZipCode"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Zip / Postal Code"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.organizationZipCode?.message}
                              onChange={(val) => {
                                onChange(
                                  val.target.value.replace(/[^\d]/g, ''),
                                );
                              }}
                              type={'text'}
                              tabIndex={8}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Phone
                      </Typography>
                      <Controller
                        control={control}
                        name="organizationPhone"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Phone"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.organizationPhone?.message}
                              onChange={(val) => {
                                onChange(
                                  val.target.value.replace(/[^\d]/g, ''),
                                );
                              }}
                              type={'text'}
                              tabIndex={9}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>

                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Fax Number
                      </Typography>
                      <Controller
                        control={control}
                        name="organizationFax"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Fax Number"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.organizationFax?.message}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              tabIndex={10}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Email{' '}
                        <Typography component={'span'} className="Mui-error">
                          *
                        </Typography>
                      </Typography>
                      <Controller
                        control={control}
                        name="organizationEmail"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Email"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.organizationEmail?.message}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              tabIndex={11}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Website
                      </Typography>
                      <Controller
                        control={control}
                        name="organizationWebsite"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Website"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.organizationWebsite?.message}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              tabIndex={12}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Type of Business
                      </Typography>
                      <Controller
                        control={control}
                        name="typeOfBusiness"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Type of Business"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.typeOfBusiness?.message}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              tabIndex={13}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Year Business Established
                      </Typography>
                      <Controller
                        control={control}
                        name="yearEstablished"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Year Business Established"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.yearEstablished?.message}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              tabIndex={14}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Current Number of Employees in the US
                      </Typography>
                      <Controller
                        control={control}
                        name="totalNumberOfEmployees"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Current Number of Employees in the US"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.totalNumberOfEmployees?.message}
                              onChange={(val) =>
                                onChange(val.target.value.replace(/[^\d]/g, ''))
                              }
                              tabIndex={15}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Gross Annual Income
                      </Typography>
                      <Controller
                        control={control}
                        name="grossAnnualIncome"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Gross Annual Income"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.grossAnnualIncome?.message}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              tabIndex={16}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Net Annual Income
                      </Typography>
                      <Controller
                        control={control}
                        name="netAnnualIncome"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Net Annual Income"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.netAnnualIncome?.message}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              tabIndex={17}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>

                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Federal Employer Identification Number (FEIN)
                      </Typography>
                      <Controller
                        control={control}
                        name="organizationFEINNumber"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Federal Employer Identification Number (FEIN)"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.organizationFEINNumber?.message}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              tabIndex={18}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Typography>
            <Typography
              component={'div'}
              className="addbox"
              sx={{ marginTop: '1rem !important' }}>
              <Typography
                component={'div'}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                }}>
                <Typography
                  component={'div'}
                  className="uadd-title"
                  sx={{ marginTop: '5px', paddingTop: 'unset !important' }}>
                  Signatory Detail
                </Typography>

                <Typography
                  component={'div'}
                  className="outform-select"
                  style={{
                    fontSize: '1rem',
                    width: '250px',
                  }}>
                  <Controller
                    control={control}
                    name="newSignatoryId"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <CustomSelect
                          labelKey={'fullName'}
                          valueKey={'id'}
                          value={value}
                          options={
                            contactslistNew &&
                            Array.isArray(contactslistNew) &&
                            contactslistNew.length
                              ? contactslistNew.map(
                                  (e: {
                                    first_name: string;
                                    last_name: string;
                                  }) => ({
                                    ...e,
                                    fullName: `${e.first_name} ${
                                      e.last_name ? e.last_name : ''
                                    }`,
                                  }),
                                )
                              : []
                          }
                          onChange={(e) => contactPersonChange(e)}
                          placeHolder={'Select'}
                          className={'custom-input mr-1'}
                          isClearable={false}
                          searchable={true}
                        />
                      );
                    }}
                  />
                </Typography>
              </Typography>
              <Box
                component="form"
                className="u-add-in"
                noValidate
                autoComplete="off">
                <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        First Name
                      </Typography>
                      <Controller
                        control={control}
                        name="organizationSignatoryFirstName"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="First Name"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={
                                errors.organizationSignatoryFirstName?.message
                              }
                              onChange={(val) => {
                                onChange(val);
                              }}
                              disabled={true}
                              tabIndex={19}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Email
                      </Typography>
                      <Controller
                        control={control}
                        name="organizationSignatoryEmail"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Email"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.organizationSignatoryEmail?.message}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              disabled={true}
                              tabIndex={21}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Last Name
                      </Typography>
                      <Controller
                        control={control}
                        name="organizationSignatoryLastName"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Last Name"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={
                                errors.organizationSignatoryLastName?.message
                              }
                              onChange={(val) => {
                                onChange(val);
                              }}
                              disabled={true}
                              tabIndex={20}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field ">
                      <Typography component={'div'} className="custom-label">
                        Title
                      </Typography>
                      <Controller
                        control={control}
                        name="organizationSignatoryTitle"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Title"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.organizationSignatoryTitle?.message}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              disabled={true}
                              tabIndex={22}
                            />
                          );
                        }}
                      />{' '}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Typography>
            <Grid container className="bottom-fixed">
              <Grid item md={12}>
                <Typography component={'div'} className="d-flex-jae">
                  <Button
                    className="cancel-btn mr-2"
                    onClick={() => cancelForm()}>
                    <Typography className="o-box" />
                    Cancel
                  </Button>
                  <Button
                    className="add-btn"
                    onClick={() =>
                      // Savefunction()
                      setConfirmDialog({
                        show: true,
                      })
                    }>
                    Save
                  </Button>
                </Typography>
              </Grid>
            </Grid>

            <Typography
              component={'div'}
              className="addbox"
              sx={{ marginTop: '1rem !important' }}>
              <Typography component={'div'} className={'addbox-heading'}>
                <Typography
                  component={'div'}
                  className="cp-add-title"
                  sx={{ paddingTop: 'unset !important' }}>
                  Contact Person
                </Typography>
                <Tooltip title={'Add'} placement="top">
                  <Typography
                    component={'img'}
                    src={Add}
                    alt={'add'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      setcontactslist((p) => [
                        ...p,
                        {
                          ...(location.pathname.includes('associated')
                            ? {}
                            : {
                                editId:
                                  singlecontactslist?.contacts[-1]?.editId,
                                invite_id:
                                  singlecontactslist?.contacts[-1]?.invite_id,
                              }),
                        },
                      ])
                    }
                  />
                </Tooltip>
              </Typography>
              <Box
                component="div"
                className="u-add-in"
                style={{ backgroundColor: '#fff;' }}
                tabIndex={23}>
                {contactslist?.length >= 0
                  ? contactslist?.map((val, i) => (
                      <ContactPersonList
                        contactdetails={val}
                        editId={editId}
                        key={i}
                        index={i}
                        setcontactslist={setcontactslist}
                        associated={
                          singlecontactslist &&
                          singlecontactslist.data &&
                          singlecontactslist.data[0] &&
                          singlecontactslist.data[0].accepted
                        }
                        getAllData={getAllData}
                      />
                    ))
                  : []}
              </Box>
            </Typography>
          </Grid>
        </form>
      )}
      <ConfirmDialog
        title="Confirmation"
        floatingBtnShow={true}
        open={confirmDialog.show}
        close={() => {
          setConfirmDialog((pre) => ({
            ...pre,
            show: false,
          }));
        }}
        visibleClose
        onConfirm={(act) => changeConfirmation(act)}
        autoCloseDisable={true}>
        {
          'Do you want to update this latest petitioner Information in petitions?'
        }
      </ConfirmDialog>
      <CustomModalView
        open={dialogView}
        fullWidth={true}
        maxWidth={'lg'}
        scroll={'paper'}
        autoCloseDisable={true}
        isFilters={true}
        filterOnChange={(searchvalue) => {
          setParams((pre) => ({
            ...pre,
            name: searchvalue,
            skip: 0,
            take: 50,
          }));
        }}
        title="Petitions List"
        close={() => {
          setDialogView(false);
          setSelectPetitionId([]);
          setParams((pre) => ({
            ...pre,
            name: '',
            skip: 0,
            take: 50,
          }));
        }}>
        {loader && <div className="liberty-loader" />}
        <DialogContent>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th scope="col" style={{ width: '50%' }}>
                  {'Name'}
                </th>
                <th scope="col" style={{ width: '20%' }}>
                  {'Date'}
                </th>
                <th
                  scope="col"
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                  Action
                  <CustomCheckBox
                    checked={list.length === selectPetitionId.length}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectPetitionId(list.map((e) => e.id));
                      } else {
                        setSelectPetitionId([]);
                      }
                    }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {list && list.length ? (
                list.map((data, index) => (
                  <tr key={index} style={{ cursor: 'pointer' }}>
                    <td>
                      <a>
                        {data?.first_name} {data?.last_name} for{' '}
                        {data?.visa_code}
                      </a>
                    </td>
                    <td>{moment(data?.created_at).format('MM/DD/YYYY')}</td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {' '}
                      <Checkbox
                        size={'medium'}
                        checked={selectPetitionId.some((e) => e === data.id)}
                        onChange={(e) => checkDataList(e.target.checked, data)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr className={'text-center'}>
                  <th scope="row" colSpan={3}>
                    No Record(s) Found.
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
        {selectPetitionId.length && selectPetitionId.length > 0 ? (
          <DialogActions>
            <Typography
              component={'div'}
              className="d-flex-jae"
              sx={{ padding: '0 1.5rem' }}>
              <Button
                className="n-add-btn"
                sx={{
                  borderRadius: '5px !important',
                  float: 'right',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '0.9em !important',
                  height: '35px !important',
                  margin: '15px',
                  width: '149px !important',
                }}
                onClick={() => Savefunction()}>
                Submit
              </Button>
            </Typography>
          </DialogActions>
        ) : (
          ''
        )}
      </CustomModalView>
    </Grid>
  );
};

export default OrganizationAdd;
