import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import { instantState, useSetState } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { COMMON_ENDPOINT, MASTER_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import useStyles from '../../../../PrivateStyle';

type ContactFeeProps = {
  lists?: any;
  setList?: any;
  contractId?;
  contractType?;
  isVisaChanged?: boolean;
};

export const ContactFeeTable: React.FC<ContactFeeProps> = ({
  lists,
  setList,
  contractId,
  contractType,
  isVisaChanged,
}) => {
  const classes = useStyles();
  const [contractQuestionParams, setContractQuestionParams] = useState<any>({
    id: '',
    name: '',
    order: '',
    skip: 0,
    sortby: '',
    status: true,
    take: 50,
    value: '',
    visaId: '',
  });
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tablelLoader, setTableLoader] = useState(false);
  const [visaTypes, setVisaTypes] = useState<any>([]);
  const [contractQuestions, setContractQuestions] = useState<any>({});
  const [totalValue, setTotalValue] = useState<any>('0.0');
  const [addNewFee, setAddNewFee, getNewFee] = useSetState([]);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    index: null,
    show: false,
  });
  const [feesDialogOpen, setFeesDialogOpen] = useState(false);

  const total = () => {
    const filterSelectedContractList = lists.filter(
      (contract) => !contract.disabled,
    );
    let totalAmount = 0;
    filterSelectedContractList.map((val) => {
      if (val.status) totalAmount = Number(totalAmount) + Number(val.value);
    });
    setTotalValue(Number(totalAmount).toFixed(2));
  };

  const enableDisable = (event, i, allChange?) => {
    // Over All Change
    if (allChange) {
      if (event.target.checked) {
        lists.map((arrVal) => {
          (arrVal.disabled = false), (arrVal.status = true);
        });
      } else {
        lists.map((arrVal) => {
          (arrVal.disabled = true), (arrVal.status = false);
        });
      }
      setList([...lists]);
      total();
      lists.every((val) => val.disabled === false);

      // event change trigger save
      submitContractFees();
      return;
    }
    // Individual Chnage
    if (event.target.checked) {
      lists[i].disabled = false;
      lists[i].status = true;
    } else {
      lists[i].disabled = true;
      lists[i].status = false;
    }
    setList([...lists]);
    total();
    lists.every((val) => val.disabled === false);

    // event change trigger save
    submitContractFees();
  };

  const getContractFeesForm = useCallback(
    (emptyInsert) => {
      let filterSelectedContractList = [];
      if (!emptyInsert) {
        filterSelectedContractList = lists;
      }
      let totalAmount = 0;
      filterSelectedContractList.map((val, index) => {
        val.order = Number(index) + 1;
        if (val.status) totalAmount = Number(totalAmount) + Number(val.value);
      });
      return {
        leadFor: contractType,
        leadId: Number(contractId),
        list: filterSelectedContractList,
        paymentAmount: String(Number(totalAmount).toFixed(2)),
      };
    },
    [contractId, contractType, lists],
  );

  const submitContractFees = (popupDisable?, emptyInsert?) => {
    const checkValueSelected = lists.some(
      (val) => (!val.value || Number(val.value) <= 0) && val.status,
    );
    // console.log('checkValueSelected', checkValueSelected);

    const feesValues = getContractFeesForm(emptyInsert);

    if (!checkValueSelected) {
      updateData('', feesValues, `${COMMON_ENDPOINT.Contract}/contracttable`)
        .then((resp: any) => {
          if (!popupDisable) {
            toast.success('Fees Updated Successfully', alertOptions);
          }
        })
        .catch((err) => {
          toast.error('Unable to update', alertOptions);
        });
    } else {
      toast.error('Fees amount should be greater than 0');
    }
  };

  const addMoreFees = () => {
    setLoader(true);
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`)
      .then((resp: any) => {
        setVisaTypes(resp.data);
        setFeesDialogOpen(true);
        setAddNewFee([...lists]);
      })
      .catch((e: any) => {
        setLoader(false);
      });
    getContractFeeQuestion();
  };

  const changeListedValue = (e, index) => {
    const { value } = e.target;
    lists[index].value = value;
    setList([...lists]);
  };

  const getContractFeeQuestion = () => {
    getAllListData(
      `${String(MASTER_ENDPOINT.ContractFee)}?id=${String(
        contractQuestionParams.id,
      )}&name=${String(contractQuestionParams.name)}&value=${String(
        contractQuestionParams.value,
      )}&status=${String(contractQuestionParams.status)}&skip=${String(
        contractQuestionParams.skip,
      )}&take=${String(contractQuestionParams.take)}&order=${String(
        contractQuestionParams.order,
      )}&sortby=${String(contractQuestionParams.sortby)}&visaId=${String(
        contractQuestionParams.visaId,
      )}`,
    ).then(async (resp: any) => {
      const { data, total } = resp ? resp : { data: [], total: 0 };
      const moreLoader = await instantState(setGetMoreLoader);
      if (moreLoader) {
        setContractQuestions((pre) => ({
          ...pre,
          data: [...pre.data, ...data],
        }));
      } else {
        const idsEle = document.getElementById('scrollParent');
        idsEle && (idsEle.scrollTop = 0);
        setContractQuestions(resp);
      }
      setLoader(false);
      setGetMoreLoader(false);
      setTableLoader(false);
      const allList = await instantState(setContractQuestions);
      if (allList.data && allList.data.length < Number(total)) {
        setHasMoreResults(true);
      } else {
        setHasMoreResults(false);
      }
    });
  };

  const getMoreList = () => {
    if (!tablelLoader) {
      setGetMoreLoader(true);
      setContractQuestionParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };
  const disableFees = () => {
    lists.map((val) => {
      val.value = Number(val.value).toFixed(2);
      if (!val.status) {
        val.disabled = true;
      } else {
        val.disabled = false;
      }
    });
    total();
  };

  const deleteRecord = () => {
    lists.splice(confirmOpen.index, 1);
    submitContractFees();
    total();
    setConfirmOpen({
      index: null,
      show: false,
    });
  };

  const checkAllContractFee = () => {
    if (lists?.length > 0) {
      return lists.filter((val) => val.status === true).length === lists.length
        ? true
        : false;
    } else {
      return false;
    }
  };

  const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const temp = [...lists];
    const obj = temp[result.source.index];
    temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, obj);
    // const d = temp[result.destination!.index];
    // temp[result.destination!.index] = temp[result.source.index];
    // temp[result.source.index] = d;
    setList(temp);
    // // const feeIds = temp.map((val) => val.id);

    temp.map((val, index) => {
      val.order = Number(index) + 1;
    });
    const payload = {
      leadFor: contractType,
      leadId: Number(contractId),
      list: temp,
      paymentAmount: String(Number(totalValue).toFixed(2)),
    };

    updateData('', payload, `${COMMON_ENDPOINT.Contract}/contracttable`)
      .then((resp: any) => {})
      .catch((err) => {
        toast.error(err ? err : 'Unable to reorder', alertOptions);
      });
  };

  const alreadyVisaKeyDisabled = (feesId) => {
    return addNewFee.some((val) => val.id === feesId.id);
  };

  const updateDisabled = (data) => {
    return lists.filter((val) => val.id === data.id).length > 0 ? true : false;
  };

  const selectFees = async () => {
    const addNewFeeList = await getNewFee();
    addNewFeeList.forEach((val) => {
      delete val.visa_id_array;
      const index = lists.findIndex((listsVal) => listsVal.id === val.id);
      if (index === -1) {
        lists.push({
          ...val,
          ...{ background: false, disabled: false, status: true },
        });
      }
    });
    submitContractFees();
    total();

    setFeesDialogOpen(false);
    setContractQuestionParams((pre) => ({
      ...pre,
      name: '',
      visaId: '',
    }));
  };
  const addingFees = (event: any, fees: any) => {
    if (event.target.checked) {
      setAddNewFee((pre) => [...pre, fees]);
    } else {
      const index = addNewFee.findIndex((val) => val.id === fees.id);
      if (index !== -1) {
        addNewFee.splice(index, 1);
        setAddNewFee([...addNewFee]);
      }
    }
  };

  useEffect(() => {
    disableFees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    total();
    setAddNewFee([...lists]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists]);

  useEffect(() => {
    if (isVisaChanged) {
      submitContractFees('NOPopup', 'feesReset');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisaChanged]);

  useEffect(() => {
    getContractFeeQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractQuestionParams]);

  return (
    <Grid container>
      <Grid item md={12} sm={12}>
        <Typography
          component={'div'}
          className="black-white-text"
          sx={{
            fontFamily: 'Arial-Bold !important',
            fontSize: '1.25em !important',
            marginTop: '1rem',
          }}>
          Contract Fees
        </Typography>
        <Typography component={'div'}>
          <TableContainer>
            <Typography
              component={'div'}
              className={classes.scrollbox}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '8px 8px 0 0',
                marginTop: '0.5rem',
              }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="lead-table table-hover"
                style={{ backgroundColor: '#fff', minHeight: '106px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>
                      <Checkbox
                        color={'primary'}
                        size={'medium'}
                        checked={checkAllContractFee()}
                        disabled={lists.length === 0}
                        onChange={(e) => enableDisable(e, '', 'all')}
                      />
                      ENABLE{' '}
                    </TableCell>
                    <TableCell>NAME</TableCell>
                    <TableCell>
                      AMOUNT &nbsp;
                      <Typography
                        component={'span'}
                        style={{ color: '#495057', fontSize: '14px' }}>
                        $ {totalValue}
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: DroppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}>
                        {lists.length > 0 ? (
                          lists.map((data, index) => (
                            <React.Fragment key={index}>
                              <Draggable
                                key={data.id}
                                draggableId={String(data.id)}
                                index={index}>
                                {(
                                  draggableProvided: DraggableProvided,
                                  snapshot: DraggableStateSnapshot,
                                ) => {
                                  return (
                                    <TableRow
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps
                                          .style,
                                        background: snapshot.isDragging
                                          ? 'rgba(245,245,245, 0.75)'
                                          : data.background
                                          ? '#d3d3d38c'
                                          : 'none',
                                      }}>
                                      <TableCell>
                                        <div
                                          {...draggableProvided.dragHandleProps}>
                                          <OpenWithIcon />
                                        </div>
                                      </TableCell>
                                      <TableCell>
                                        <Checkbox
                                          color={
                                            data.status ? 'primary' : 'default'
                                          }
                                          size={'medium'}
                                          checked={data?.status ? true : false}
                                          id={index}
                                          name={index}
                                          value={index}
                                          onChange={(e) =>
                                            enableDisable(e, index)
                                          }
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          component={'div'}
                                          className="multiline">
                                          {data?.name}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          className="d-flex-a custom-field"
                                          sx={{
                                            marginBottom: 'unset !important',
                                          }}>
                                          $
                                          <Typography
                                            sx={{
                                              paddingLeft: '1rem',
                                              width: '150px',
                                            }}>
                                            <CustomInput
                                              type="number"
                                              className={'custom-input'}
                                              value={data?.value}
                                              onChange={(e) =>
                                                changeListedValue(e, index)
                                              }
                                              onBlur={submitContractFees}
                                              disabled={
                                                data['disabled'] !== undefined
                                                  ? data['disabled']
                                                  : true
                                              }
                                            />
                                          </Typography>
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {!data?.background && (
                                          <DeleteForeverIcon
                                            sx={{
                                              cursor: 'pointer',
                                              fill: 'red',
                                              height: '25px',
                                              width: '25px',
                                            }}
                                            onClick={() =>
                                              setConfirmOpen({
                                                index,
                                                show: true,
                                              })
                                            }
                                          />
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }}
                              </Draggable>
                            </React.Fragment>
                          ))
                        ) : (
                          <tr className={'text-center'}>
                            <td colSpan={5} align={'center'}>
                              No Record(s) Found.
                            </td>
                          </tr>
                        )}
                        {droppableProvided.placeholder}
                        {lists.length > 0 && (
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              style={{ textAlign: 'left' }}>
                              <Typography>
                                <Button
                                  className="n-add-btn"
                                  sx={{
                                    height: '40px !important',
                                  }}
                                  onClick={() => addMoreFees()}>
                                  + Add More
                                </Button>
                              </Typography>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              TOTAL
                            </TableCell>
                            <TableCell>
                              <Typography
                                className="custom-field"
                                sx={{ marginBottom: 'unset !important' }}>
                                <CustomInput
                                  className={'custom-input'}
                                  value={totalValue}
                                  disabled={true}
                                />
                              </Typography>
                            </TableCell>
                            <TableCell />
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </Typography>
          </TableContainer>
        </Typography>
      </Grid>
      <ConfirmDialog
        title="DELETE"
        floatingBtnShow={true}
        open={confirmOpen.show}
        onConfirm={(act) =>
          act === 'yes'
            ? deleteRecord()
            : setConfirmOpen({
                index: null,
                show: false,
              })
        }
        autoCloseDisable={true}>
        Are you sure do you want to delete?
      </ConfirmDialog>

      <CustomModalView
        open={feesDialogOpen}
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        autoCloseDisable={true}
        isFilters={true}
        title="Contract Fees"
        filterOnChange={(e) => {
          setTableLoader(true);
          setContractQuestionParams((pre) => ({
            ...pre,
            name: e,
            skip: 0,
            take: 50,
          }));
        }}
        filterVisaOnChange={(e) => {
          setContractQuestionParams((pre) => ({
            ...pre,
            skip: 0,
            take: 50,
            visaId: e,
          }));
        }}
        visaTypes={visaTypes}
        close={() => {
          setContractQuestionParams((pre) => ({
            ...pre,
            name: '',
            skip: 0,
            take: 50,
            visaId: '',
          }));
          setFeesDialogOpen(false);
        }}>
        <DialogContent id="scrollParent">
          {loader && <div className="liberty-loader" />}
          <Typography
            component={'div'}
            sx={{
              borderRadius: '8px 8px 0 0',
            }}>
            <InfiniteScroll
              dataLength={
                contractQuestions &&
                contractQuestions.data &&
                contractQuestions.data.length
                  ? contractQuestions.data.length
                  : 0
              }
              style={{
                overflow: 'unset',
              }}
              next={getMoreList && getMoreList}
              hasMore={hasMoreResults}
              scrollableTarget={'scrollParent'}
              loader={
                getMoreLoader && (
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      textAlign: 'center',
                      width: '100%',
                    }}>
                    <CircularProgress
                      size={30}
                      sx={{
                        color: '#002c69',
                        marginBottom: 10,
                        marginTop: 10,
                      }}
                    />
                  </div>
                )
              }
              endMessage>
              <table className="lead-table table-hover">
                <thead>
                  <tr>
                    <th scope="col">NAME</th>
                    <th scope="col">FEES</th>
                    <th scope="col">STATUS</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {tablelLoader ? (
                    <tr>
                      <td colSpan={8}>
                        <div
                          style={{
                            backgroundColor: '#ffffff',
                            textAlign: 'center',
                            width: '100%',
                          }}>
                          <CircularProgress
                            size={30}
                            sx={{
                              color: '#002c69',
                              marginBottom: 10,
                              marginTop: 10,
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {' '}
                      {contractQuestions.data &&
                      contractQuestions.data.length > 0
                        ? contractQuestions.data.map((data, index) => (
                            <tr key={index} style={{ cursor: 'pointer' }}>
                              <td>
                                <a style={{ color: '#1093df' }}>{data?.name}</a>
                              </td>
                              <td>$ {data?.value}</td>
                              <td>{data?.status ? 'Active' : 'Inactive'} </td>
                              <td>
                                <Checkbox
                                  size={'medium'}
                                  checked={alreadyVisaKeyDisabled(data)}
                                  onChange={(e) => addingFees(e, data)}
                                  disabled={updateDisabled(data)}
                                />
                              </td>
                            </tr>
                          ))
                        : !tablelLoader && (
                            <tr className={'text-center'}>
                              <th scope="row" colSpan={4}>
                                No Record(s) Found.
                              </th>
                            </tr>
                          )}
                    </>
                  )}
                </tbody>
              </table>
            </InfiniteScroll>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Typography component={'div'} className="d-flex-jae mt-1 ">
            <Button className="n-add-btn" onClick={() => selectFees()}>
              Submit
            </Button>
          </Typography>
        </DialogActions>
      </CustomModalView>
    </Grid>
  );
};

export default ContactFeeTable;
