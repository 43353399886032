import { Button, Typography } from '@mui/material';
import Delete from 'assets/images/delete.png';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

export const ListData: React.FC<any> = ({
  listData,
  index,
  createScheduleFeedback,
  setConfirmOpen,
  scheduleNow,
  headerShow,
}) => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const organizationData: any = useAppSelector(
    (store: any) => store.organizationData.organization,
  );
  const location: any = useLocation();

  return (
    <>
      {!headerShow ? (
        <Typography
          component={'div'}
          className="black-white-text mb-2"
          sx={{
            fontFamily: 'Arial-Bold !important',
            fontSize: '1.25em !important',
            marginLeft: '3px',
          }}>
          Organization : {''}
          {location?.pathname?.includes('invited')
            ? organizationData?.firm_name
            : organizationData?.orgName}
        </Typography>
      ) : (
        ''
      )}

      <Typography
        className="d-flex-jae mb-2 mt-2 sticky-tab"
        style={{
          alignItems: 'baseline',
        }}>
        <Typography component={'div'}>
          <div
            className="outform-select"
            style={{
              fontSize: '1rem',
              marginRight: '1rem',
              width: '250px',
            }}
          />
        </Typography>
        <Button className="n-add-btn" onClick={() => scheduleNow()}>
          Create Strategy
        </Button>
      </Typography>
      <Typography component={'div'} className="configureBox strategy">
        {listData && listData.data && listData.data.length > 0 ? (
          listData.data.map((item, index1) => {
            return (
              <Typography
                component={'div'}
                className="configureItem"
                key={index1}
                onClick={() => createScheduleFeedback(item)}>
                <Typography
                  component={'div'}
                  sx={{
                    position: 'relative',
                  }}
                  className="d-flex-a d-flex-sb w-100">
                  <Typography
                    component={'div'}
                    className="d-flex-3"
                    sx={{ flex: '4' }}>
                    <Typography
                      className="black-white-text strate-text"
                      sx={{ fontSize: '17px !important' }}>
                      <span className="strategy_secion_name">
                        {' '}
                        {item?.section_name}
                      </span>{' '}
                      Session For{' '}
                      {item?.strategyFor
                        ? item?.userName
                        : item?.username || item?.userName}
                      <span
                        className="n-blue-text pl-2"
                        style={{ fontSize: '17px !important' }}>
                        Scheduled Date:
                        {item?.start_date
                          ? moment(item?.start_date).format(
                              'YYYY-MM-DD hh:mm A',
                            )
                          : ''}
                      </span>
                    </Typography>
                    <Typography
                      component={'div'}
                      className="d-flex-a w-100 d-flex-sb mt-2">
                      <Typography className="rl-text">
                        Updated By:{' '}
                        {item?.modifieduser
                          ? `${String(item?.modifieduser)}  at`
                          : ''}{' '}
                        {item?.modified_at
                          ? moment(item?.modified_at).format(
                              'YYYY-MM-DD hh:mm A',
                            )
                          : ''}
                      </Typography>
                    </Typography>
                  </Typography>
                  {userDetail.role_id === 3 ? (
                    <Typography
                      component={'div'}
                      sx={{
                        cursor: 'pointer',
                      }}
                      className=" d-flex-1 d-flex-jae">
                      <Typography
                        component={'div'}
                        className="d-flex-a"
                        onClick={(e) => {
                          e.stopPropagation();
                          setConfirmOpen((pre) => ({
                            ...pre,
                            delete: true,
                            index: index,
                            message: 'Are you sure do you want to delete?',
                            show: true,
                            strategyId: item.strategy_id
                              ? item.strategy_id
                              : item.id,
                            title: 'DELETE',
                          }));
                        }}>
                        <img src={Delete} alt="delete" />
                        <span
                          className="black-white-text pl-1 f-14"
                          style={{ paddingTop: '4px' }}>
                          Delete
                        </span>
                      </Typography>
                    </Typography>
                  ) : null}
                </Typography>
              </Typography>
            );
          })
        ) : (
          <Typography
            className="note-box2"
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}>
            <Typography className="inb2" />
            <Typography className="black-white-text ctitle hideshow">
              No Record(s) Found.
            </Typography>
          </Typography>
        )}
      </Typography>
    </>
  );
};

export default ListData;
