import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomInput from 'components/CustomInput/customInput';
import React from 'react';

type WinningStrategyListTitles = {
  title;
  setFilterParams;
  filterParams;
};

export const WinningStrategyListTitle: React.FC<WinningStrategyListTitles> =
  React.memo(({ title, setFilterParams, filterParams }) => {
    return (
      <Grid item md={12} sm={12}>
        <Typography component={'div'} className="d-flex d-flex-sb">
          <Typography className="c-title black-white-text">{title}</Typography>
          <Typography component={'div'} className="d-flex">
            <Typography
              component={'div'}
              className="d-flex-a small-search"
              sx={{
                // backgroundColor: '#84898c',
                borderRadius: '4px',
              }}>
              <Typography
                className="d-flex-a searc-box2"
                component={'div'}
                sx={{
                  backgroundColor: '#ffffff',
                  borderRadius: '4px',
                  marginRight: '.5rem',
                  width: '175px',
                }}>
                <SearchIcon
                  sx={{
                    fill: '#3d3f40 !important',
                    left: '5px',
                    position: 'absolute',
                    zIndex: '1',
                  }}
                />
                <CustomInput
                  placeHolder={'Question'}
                  className={'custom-search-input bo-rles'}
                  value={filterParams.question}
                  inputProps={{
                    endAdornment:
                      filterParams.question !== '' ? (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() => {
                              setFilterParams((prev) => ({
                                ...prev,
                                question: '',
                              }));
                            }}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                  }}
                  onChange={(e) => {
                    setFilterParams((prev) => ({
                      ...prev,
                      question: e.target.value,
                    }));
                  }}
                />
              </Typography>
              <Typography
                sx={{
                  borderRadius: '4px',
                  marginRight: '.5rem',
                  paddingLeft: 'unset !important',
                  position: 'relative',
                  width: '175px',
                }}
                className="d-flex-a"
                component={'div'}>
                <SearchIcon
                  sx={{
                    fill: '#3d3f40 !important',
                    left: '5px',
                    position: 'absolute',
                    zIndex: '1',
                  }}
                />
                <CustomInput
                  placeHolder={'Link Code'}
                  className={'custom-search-input bo-rles in-pad-se'}
                  value={filterParams.link_code}
                  inputProps={{
                    endAdornment:
                      filterParams.link_code !== '' ? (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={(e) => {
                              setFilterParams((prev) => ({
                                ...prev,
                                link_code: '',
                              }));
                            }}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                  }}
                  onChange={(e) => {
                    setFilterParams((prev) => ({
                      ...prev,
                      link_code: e.target.value,
                    }));
                  }}
                />
              </Typography>
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    );
  });

export default WinningStrategyListTitle;
