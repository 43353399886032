import { Box, Button, Grid, Typography } from '@mui/material';
import { instantState } from 'hooks';
import React, { useEffect, useState } from 'react';
import { getAllListData, updateData } from 'services/CommonServices';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';

import useStyles from '../pages/private/PrivateStyle';
import ConfirmDialog from './ConfirmDialog/ConfirmDialog';

type Props = {
  loader: boolean;
  setLoader: any;
  isDashboard: boolean;
};

export const PendingInvites: React.FC<any> = (props: Props) => {
  const { loader, setLoader, isDashboard } = props;
  const classes = useStyles();
  const [petitionerData, setPetitionerData] = useState<any>({});
  const [selectedPetiioner, setSelectedPetitioner] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);

  const onSuccess = async () => {
    const selectedPetiioner = await instantState(setSelectedPetitioner);

    updateData(
      selectedPetiioner?.id,
      { status: true },
      COMMON_ENDPOINT.acceptedInviteLawFirm,
    ).then((resp: any) => {
      getApi();
      setIsOpen(false);
    });
  };

  const renderlist = () => {
    return (
      <Box sx={{ width: '100%' }} className="documentTab">
        <Grid item md={12} sm={12}>
          {isDashboard ? (
            <Typography component={'div'}>
              <Typography className="c-title black-white-text">
                Pending Invites
              </Typography>
            </Typography>
          ) : null}
        </Grid>
        <Typography component={'div'} className="configureBox">
          {petitionerData?.length > 0 ? (
            petitionerData?.map((petitioner: any, index: number) => (
              <Typography className="configureItem" key={index}>
                <Typography className={classes.userItemInner} />
                <Typography className="d-flex-a d-flex-sb w-100">
                  <Typography component={'div'} className="d-flex-3">
                    <Typography className="black-white-text ctitle">
                      {petitioner?.attorney_name
                        ? petitioner?.attorney_name
                        : '-'}{' '}
                    </Typography>
                    <Typography className="rl-text">
                      Referred by: {petitioner?.attorney_user_fname}{' '}
                      {petitioner?.attorney_user_lname} (
                      {petitioner?.referred_by})
                    </Typography>
                  </Typography>
                  <Typography
                    className="me-2 blue-text d-flex-1 text-right"
                    justifyContent={'space-around'}>
                    {petitioner.accepted ? (
                      ' '
                    ) : (
                      <Button
                        className="add-btn"
                        style={{ marginRight: '20px' }}
                        sx={{
                          borderRadius: '5px !important',
                          fontFamily: 'Arial-Regular !important',
                          fontSize: '0.9em !important',
                          height: '35px !important',
                          textTransform: 'capitalize !important',
                          width: '118px !important',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedPetitioner(petitioner);
                          setIsOpen(true);
                        }}>
                        {'Accept'}
                      </Button>
                    )}
                  </Typography>
                  <Typography style={{ color: 'white' }}>
                    {petitioner.accepted ? 'Associated' : 'Pending'}
                  </Typography>
                </Typography>
              </Typography>
            ))
          ) : (
            <Typography
              className="note-box2"
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}>
              <Typography className="inb2" />
              <Typography className="black-white-text ctitle hideshow">
                No Record(s) Found.
              </Typography>
            </Typography>
          )}
        </Typography>
      </Box>
    );
  };
  const getApi = () => {
    getAllListData(`${String(SETUP_ENDPOINT.ManageOrganization)}/pendinglist`)
      .then((resp: any) => {
        setPetitionerData(resp);
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loader ? (
        <div className="liberty-loader" />
      ) : isDashboard && petitionerData?.length > 0 ? (
        renderlist()
      ) : !isDashboard ? (
        renderlist()
      ) : null}
      <ConfirmDialog
        open={isOpen}
        title="Invite Confirmation"
        floatingBtnShow={true}
        onConfirm={(e) => {
          console.warn(e);
          if (e === 'yes') {
            onSuccess();
            return;
          }
          setIsOpen(false);
        }}
        autoCloseDisable={true}>
        You have invited from {selectedPetiioner?.attorney_user_fname}{' '}
        {selectedPetiioner?.attorney_user_lname} (
        {selectedPetiioner?.referred_by})
      </ConfirmDialog>
    </>
  );
};

export default PendingInvites;
