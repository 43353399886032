import { yupResolver } from '@hookform/resolvers/yup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  styled,
  Switch,
  SwitchProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import React, { FC, Fragment, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { updateData } from 'services/CommonServices';
import { VisaTypeMajor } from 'types';
import { SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { VisaTypeMajorSchema } from 'utils/ValidatorSchema';

import useStyles from '../../../PrivateStyle';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  '& .MuiButtonBase-root': {
    padding: '0 0 !important',
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        // backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        border: 0,
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      color: '#fff',
      transform: 'translateX(16px)',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: '6px solid #fff',
      color: '#33cf4d',
    },
    margin: 2,
    padding: 0,
    transitionDuration: '300ms',
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    height: 22,
    width: 22,
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
  height: 26,
  padding: 0,
  width: 42,
}));
type viewProps = {
  tabValue?: any;
  direction?: any;
  type?: string;
  handleDragEnd?: any;
  typeOfList?: any;
  setConfirmOpen?: any;
  // submitOrder?: (param1: any, param2: string, param3: string) => void;
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const TabViews: FC<viewProps> = ({
  tabValue,
  direction,
  type,
  handleDragEnd,
  typeOfList,
  setConfirmOpen,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [majorSections, setMajorSection] = useState({
    id: '',
    major: false,
    name: '',
  });
  const classes = useStyles();
  const modalClose2 = () => {
    setValue('major', true);
    setModalOpen(false);
  };
  const { control, setValue } = useForm<VisaTypeMajor>({
    criteriaMode: 'all',
    defaultValues: {
      major: false,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(VisaTypeMajorSchema),
  });

  const majorSection = () => {
    console.log(majorSections);

    const payload = {
      manadatory: majorSections.major,
    };
    updateData(majorSections.id, payload, SETUP_ENDPOINT.ConfigMajor).then(
      (et) => {
        setModalOpen(false);
        const index =
          typeOfList && Array.isArray(typeOfList)
            ? typeOfList.findIndex((e) => e.id === majorSections.id)
            : -1;
        if (index !== -1) {
          typeOfList[index].major_section = majorSections.major;
        }
        toast.success('Updated successfully', alertOptions);
      },
    );
  };

  return (
    <>
      <TabPanel value={tabValue} index={tabValue} dir={direction}>
        <TableContainer>
          <Typography
            component={'div'}
            sx={{
              borderRadius: '8px 8px 0 0',
            }}
            className={classes.scrollbox}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className="lead-table table-hover"
              style={{ minHeight: '106px' }}>
              <TableHead>
                <TableRow>
                  {['beneficiary', 'petitioner'].includes(type) ? (
                    <TableCell className="W-2">&nbsp;</TableCell>
                  ) : (
                    ''
                  )}
                  <TableCell
                    className={
                      ['beneficiary', 'petitioner'].includes(type)
                        ? 'W-3'
                        : 'W-4'
                    }>
                    SECTION NAME
                  </TableCell>
                  <TableCell
                    className={
                      ['beneficiary', 'petitioner'].includes(type)
                        ? 'W-3'
                        : 'W-4'
                    }>
                    SECTION TYPE
                  </TableCell>
                  <TableCell className="W-2">MANDATORY SECTION</TableCell>
                  <TableCell className="W-2">ACTION</TableCell>
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(droppableProvided: DroppableProvided) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}>
                      {typeOfList.length > 0 ? (
                        typeOfList.map((sectionData, index) => (
                          <Fragment key={index}>
                            <Draggable
                              key={sectionData.id}
                              draggableId={String(sectionData.id)}
                              index={index}>
                              {(
                                draggableProvided: DraggableProvided,
                                snapshot: DraggableStateSnapshot,
                              ) => {
                                return (
                                  <TableRow
                                    key={index}
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    style={{
                                      ...draggableProvided.draggableProps.style,
                                      background: snapshot.isDragging
                                        ? 'rgba(0,0,0,.075)'
                                        : '',
                                      cursor: 'pointer',
                                    }}>
                                    {['beneficiary', 'petitioner'].includes(
                                      type,
                                    ) ? (
                                      <TableCell>
                                        <div
                                          {...draggableProvided.dragHandleProps}>
                                          <OpenWithIcon />
                                        </div>
                                      </TableCell>
                                    ) : (
                                      ''
                                    )}
                                    <TableCell>
                                      <Typography
                                        component={'div'}
                                        className="hideshow">
                                        {sectionData.section_name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        component={'div'}
                                        className="hideshow">
                                        {sectionData.section_type}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Controller
                                        control={control}
                                        defaultValue={false}
                                        name="major"
                                        render={({
                                          field: { onChange, value, name, ref },
                                          formState,
                                        }) => {
                                          return (
                                            <FormGroup
                                              sx={{ marginLeft: '30px' }}>
                                              <Stack
                                                direction="row"
                                                spacing={1}
                                                alignItems="center">
                                                <FormControlLabel
                                                  control={
                                                    <IOSSwitch
                                                      checked={
                                                        sectionData.major_section
                                                      }
                                                      onChange={(e) => {
                                                        const { checked } =
                                                          e.target;
                                                        onChange(e);
                                                        setMajorSection({
                                                          id: sectionData.id,
                                                          major: checked,
                                                          name: sectionData.section_name,
                                                        });
                                                        setModalOpen(true);
                                                      }}
                                                    />
                                                  }
                                                  label={''}
                                                />
                                              </Stack>
                                            </FormGroup>
                                          );
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <DeleteForeverIcon
                                        sx={{
                                          cursor: 'pointer',
                                          fill: 'red',
                                          height: '25px',
                                          width: '25px',
                                        }}
                                        onClick={() =>
                                          setConfirmOpen({
                                            id: String(sectionData.id),
                                            index,
                                            show: true,
                                            type,
                                          })
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              }}
                            </Draggable>
                          </Fragment>
                        ))
                      ) : (
                        <tr className={'text-center'}>
                          <th scope="row" colSpan={5}>
                            No Record(s) Found.
                          </th>
                        </tr>
                      )}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </Typography>
        </TableContainer>
      </TabPanel>
      <ConfirmDialog
        open={modalOpen}
        floatingBtnShow={true}
        onConfirm={(e) => {
          if (e === 'yes') {
            majorSection();
          } else {
            modalClose2();
          }
        }}
        autoCloseDisable={true}>
        Do you want to make this {majorSections.name} section As {''}
        {majorSections.major ? 'Mandatory' : 'Non Mandatory'}
      </ConfirmDialog>
    </>
  );
};

export default TabViews;
