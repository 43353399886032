import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { AttorneyPrivateRoutes } from 'config';
import saveAs from 'file-saver';
import { instantState, useDebounce } from 'hooks';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData, getS3file } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import useStyles from '../../PrivateStyle';

export const ResourceStation: React.FC<any> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [params, setParams] = useState<any>({
    documentDescription: '',
    documentName: '',
    id: '',
    order: 'DESC',
    skip: 0,
    sortby: 'created_at',
    status: true,
    take: 50,
    visaId: '',
  });
  const [loader, setLoader] = useState(true);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [visaTypes, setVisaTypes] = React.useState<any>([]);
  const debouncedParams = useDebounce(params, 500);
  const Status = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ];
  const [resourceList, setResourceList] = useState<any>({
    Count: 0,
    data: [],
  });

  useEffect(() => {
    getAllListData(
      `${String(SETUP_ENDPOINT.ResourceStation)}?order=${String(
        debouncedParams.order,
      )}&name=${String(debouncedParams.documentName)}&visaId=${String(
        debouncedParams.visaId,
      )}&id=${String(debouncedParams.id)}&skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}&status=${String(
        debouncedParams.status,
      )}&sortby=${String(debouncedParams.sortby)}`,
    )
      .then(async (res: any) => {
        const { data, count: total } = res ? res : { count: 0, data: [] };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setResourceList((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
            total,
          }));
        } else {
          const idsEle = document.getElementById('paraentScroll');
          idsEle && (idsEle.scrollTop = 0);
          setResourceList((pre) => ({
            ...pre,
            data: data,
            total,
          }));
        }
        setLoader(false);
        setTableLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setResourceList);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch(() => {
        setLoader(false);
        setTableLoader(false);
        setGetMoreLoader(false);
        setHasMoreResults(false);
      });
  }, [debouncedParams]);

  const openFile = (file) => {
    // setLoader(true);
    const obj = {
      key: file,
    };
    const fileName = file.split('/').pop();
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(`${String(fileName)} File Downloaded`, alertOptions);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };
  React.useEffect(() => {
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`).then(
      (resp) => {
        setVisaTypes(resp.data);
      },
    );
  }, []);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <>
          <Grid item md={10} sm={12} className="pr-2rem">
            <Box sx={{ width: '100%' }} className="documentTab">
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  marginBottom: '15px',
                }}>
                <Typography
                  component={'div'}
                  className="d-flex-jae full-screen-searc">
                  <Typography
                    component={'div'}
                    className="d-flex-jae full-screen-searc">
                    <Typography
                      component={'div'}
                      className="d-flex-a small-search"
                      sx={{
                        // backgroundColor: '#84898c',
                        borderRadius: '4px',
                      }}>
                      <Typography
                        className="d-flex-a searc-box1"
                        component={'div'}
                        sx={{
                          backgroundColor: '#ffffff',
                          borderRadius: '4px',
                          marginRight: '.5rem',
                          width: '175px',
                        }}>
                        <SearchIcon
                          sx={{
                            fill: '#3d3f40 !important',
                            left: '5px',
                            position: 'absolute',
                            zIndex: '1',
                          }}
                        />
                        <CustomInput
                          placeHolder={'Document Name'}
                          className={'custom-search-input bo-rles'}
                          value={params.documentName}
                          inputProps={{
                            endAdornment:
                              params.documentName !== '' ? (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={() => {
                                      setParams((prev) => ({
                                        ...prev,
                                        documentName: '',
                                        skip: 0,
                                        take: 50,
                                      }));
                                    }}>
                                    <CloseIcon />
                                  </IconButton>
                                </InputAdornment>
                              ) : null,
                          }}
                          onChange={(e) => {
                            setParams((prev) => ({
                              ...prev,
                              documentName: e.target.value,
                              skip: 0,
                              take: 50,
                            }));
                          }}
                        />
                      </Typography>

                      <Typography
                        sx={{ width: '200px' }}
                        className="searc-box3">
                        <CustomSelect
                          labelKey={'visa_code'}
                          valueKey={'visa_id'}
                          options={visaTypes}
                          onChange={(e) => {
                            setParams((prev) => ({
                              ...prev,
                              skip: 0,
                              take: 50,
                              visaId: e.target.value,
                            }));
                          }}
                          placeHolder={'Select Visa Type'}
                          className={'custom-search-input'}
                        />
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: '5px',
                        }}
                        className="d-flex-a"
                        component={'div'}>
                        <CustomSelect
                          isClearable={false}
                          labelKey={'label'}
                          valueKey={'value'}
                          options={Status}
                          value={params.status ? 'active' : 'inactive'}
                          onChange={(e) => {
                            setTableLoader(true);
                            setParams((prev) => ({
                              ...prev,
                              skip: 0,
                              status:
                                e.target.value === 'active' ? true : false,
                              take: 50,
                            }));
                          }}
                          placeHolder={'Status'}
                          className={'custom-search-input'}
                        />
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              </Box>
              <Typography component={'div'}>
                {userDetail.role_id === 3 && (
                  <Typography
                    component={'div'}
                    sx={{
                      borderRadius: '8px 8px 0 0',
                    }}
                    className={classes.scrollbox}
                    id="scrollParent">
                    <InfiniteScroll
                      dataLength={
                        resourceList &&
                        resourceList.data &&
                        resourceList.data.length
                          ? resourceList.data.length
                          : 0
                      }
                      style={{
                        overflow: 'unset',
                      }}
                      next={getMoreList && getMoreList}
                      hasMore={hasMoreResults}
                      scrollableTarget={'scrollParent'}
                      loader={
                        getMoreLoader && (
                          <div
                            style={{
                              backgroundColor: '#ffffff',
                              textAlign: 'center',
                              width: '100%',
                            }}>
                            <CircularProgress
                              size={30}
                              sx={{
                                color: '#002c69',
                                marginBottom: 10,
                                marginTop: 10,
                              }}
                            />
                          </div>
                        )
                      }
                      endMessage>
                      <table className="lead-table table-hover">
                        <thead>
                          <tr>
                            <th className="W-4">Document Name</th>
                            <th className="W-3"> Description</th>
                            <th className="W-2"> Action</th>
                          </tr>
                        </thead>
                        <tbody className="pointer">
                          {tableLoader ? (
                            <tr>
                              <td colSpan={8}>
                                <div
                                  style={{
                                    backgroundColor: '#ffffff',
                                    textAlign: 'center',
                                    width: '100%',
                                  }}>
                                  <CircularProgress
                                    size={30}
                                    sx={{
                                      color: '#002c69',
                                      marginBottom: 10,
                                      marginTop: 10,
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ) : (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                              {resourceList &&
                              resourceList?.data &&
                              resourceList?.data?.length > 0
                                ? resourceList?.data?.map((item, index) => (
                                    <tr
                                      key={index}
                                      onClick={() =>
                                        navigate(
                                          userDetail.role_id === 3 &&
                                            `${String(
                                              AttorneyPrivateRoutes.RESOURCE
                                                .RESOURCESTATIONEDIT,
                                            )}/${String(item.id)}`,
                                          {
                                            state: {
                                              status: params.status,
                                            },
                                          },
                                        )
                                      }>
                                      <td>
                                        {item?.document_name
                                          ? item?.document_name
                                          : '-'}
                                      </td>
                                      <td>
                                        {item?.document_description
                                          ? item?.document_description
                                          : '-'}
                                      </td>
                                      <td>
                                        <Button
                                          className="n-add-btn"
                                          sx={{
                                            borderRadius: '5px !important',
                                            height: '30px !important',
                                            minWidth: '90px !important',
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            openFile(item.document_key);
                                          }}>
                                          Download
                                        </Button>
                                      </td>
                                    </tr>
                                  ))
                                : !tableLoader && (
                                    <tr className={'text-center'}>
                                      <td colSpan={4}>No Record(s) Found.</td>
                                    </tr>
                                  )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </Typography>
                )}
              </Typography>
              {loader ? <div className="liberty-loader" /> : ''}
            </Box>
          </Grid>
          <Grid item md={2} sm={12}>
            {userDetail.role_id === 3 && (
              <Typography component={'div'} className="add-box">
                <Typography component={'div'} className="add-inner-box" />
                {/* <Typography className=" user-dis pt-5 ">
              Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do
              eiusmod temporinci d idunt ut labore
              <Typography />
            </Typography> */}
                <Typography
                  className=" built-option d-flex-a mb-1 mt-2"
                  onClick={() =>
                    navigate(
                      `${String(
                        AttorneyPrivateRoutes.RESOURCE.RESOURCESTATIONADD,
                      )}`,
                    )
                  }>
                  {' '}
                  <span className="plus d-flex-ja">+</span>
                  Add Resource Station
                </Typography>
              </Typography>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default ResourceStation;
