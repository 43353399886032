import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';

import LeadAdd from '../AttorneyLeads';
import PetitionerLeadAdd from '.';

export const PetitionerLandingPage: React.FC<any> = () => {
  const { editId } = useParams();
  const [loader, setLoader] = React.useState(true);
  const [leadData, setLeadData] = React.useState<any>({});

  const getLeadInfo = useCallback(async () => {
    try {
      setLoader(true);
      const info = await getAllListData(
        `${COMMON_ENDPOINT.LeadList}?id=${String(editId)}`,
      );
      setLeadData(
        info && info.data && Array.isArray(info.data) && info.data[0]
          ? info.data[0]
          : {},
      );
      console.warn({ info });
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }, [editId]);
  React.useEffect(() => {
    getLeadInfo();
  }, [editId, getLeadInfo]);
  return loader ? (
    <div className="liberty-loader" />
  ) : leadData?.isAffilateCase && [3].includes(leadData?.affilateRoleId) ? (
    <LeadAdd />
  ) : (
    <PetitionerLeadAdd />
  );
};
