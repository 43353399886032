import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Checkbox, Grid, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import React from 'react';

export const DocumentParsingView: React.FC<any> = () => {
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Grid container>
      <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <Typography className="assem-box">
              <iframe
                src="https://research.google.com/pubs/archive/44678.pdf"
                style={{ height: '592px', width: '100%' }}
              />
            </Typography>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box sx={{ width: '100%' }} className="parsingTab">
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example">
                    <Tab label="Petitioner Form" value="1" />
                    <Tab label="Beneficiary Form" value="2" />
                  </TabList>
                </Box>
                <Typography component={'div'}>
                  <TabPanel value="1">
                    <Typography component={'div'}>
                      <Box
                        component="form"
                        className="u-add-in"
                        sx={{
                          backgroundColor: 'unset !important',
                          border: 'unset !important',
                          padding: 'unset !important',
                        }}
                        noValidate
                        autoComplete="off">
                        <Grid container>
                          <Grid item md={11}>
                            <Typography
                              component={'div'}
                              className="custom-field u-pr-1">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Petitioner Company Name
                              </Typography>
                              <TextField
                                className="custom-input"
                                placeholder=""
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={1} className="d-flex-a">
                            <Checkbox />
                          </Grid>
                          <Grid item md={11}>
                            <Typography
                              component={'div'}
                              className="custom-field u-pr-1">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Petitioner Full Name
                              </Typography>
                              <TextField
                                className="custom-input"
                                placeholder=""
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={1} className="d-flex-a">
                            <Checkbox />
                          </Grid>
                          <Grid item md={11}>
                            <Typography
                              component={'div'}
                              className="custom-field u-pr-1">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Petitioner Street Number and Name
                              </Typography>
                              <TextField
                                className="custom-input"
                                placeholder=""
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={1} className="d-flex-a">
                            <Checkbox />
                          </Grid>
                          <Grid item md={11}>
                            <Typography
                              component={'div'}
                              className="custom-field u-pr-1">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Petitioner Address Unit Number
                              </Typography>
                              <TextField
                                className="custom-input"
                                placeholder=""
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={1} className="d-flex-a">
                            <Checkbox />
                          </Grid>
                          <Grid item md={11}>
                            <Typography
                              component={'div'}
                              className="custom-field u-pr-1">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Petitioner City
                              </Typography>
                              <TextField
                                className="custom-input"
                                placeholder=""
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={1} className="d-flex-a">
                            <Checkbox />
                          </Grid>
                          <Grid item md={11}>
                            <Typography
                              component={'div'}
                              className="custom-field u-pr-1">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Petitioner State
                              </Typography>
                              <TextField
                                className="custom-input"
                                placeholder=""
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={1} className="d-flex-a">
                            <Checkbox />
                          </Grid>
                        </Grid>
                      </Box>
                    </Typography>
                  </TabPanel>
                  <TabPanel value="2">2</TabPanel>
                </Typography>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentParsingView;
