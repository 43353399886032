import 'react-phone-input-2/lib/style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControlLabel,
  // FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Avator from 'assets/images/d-avator.svg';
import pencil from 'assets/images/pencil.svg';
import pencilImg from 'assets/images/pencil-img.svg';
import CustomDatePicker from 'components/CustomDatePicker/customDatePicker';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { FileUploadDialog } from 'components/Fileupload/fileuploadDialog';
import { AttorneyPrivateRoutes, PetitionerPrivateRoutes } from 'config';
import _ from 'lodash';
import moment from 'moment';
// import Multiselect from 'multiselect-react-dropdown';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { AddUserSubmitForm } from 'types';
import { fileApiUrl } from 'utils/Config';
import { COMMON_ENDPOINT, MASTER_ENDPOINT } from 'utils/Constant';
import { alertOptions, profileFileType, validateDate } from 'utils/helper';
import { AddUserSchema } from 'utils/ValidatorSchema';

export const UserAdd: React.FC<any> = () => {
  const navigate: any = useNavigate();
  const { editId } = useParams();

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  // const hiddenFileInput = useRef(null);
  const activeStatusInput = useRef(null);
  const inActiveStatusInput = useRef(null);

  const [currentStatus, setCurrentStatus] = useState('');
  const [loader, setLoader] = useState(true);
  const [roles, setRoles] = useState<any>([]);
  const [countries, setCountries] = useState<any>({
    cities: [],
    coutries: [],
    state: [],
  });
  const [profileImage, setProfileImage] = useState<any>({
    selectedImage: null,
  });

  // const [roleAssignedIds, setRoleAssignedIds] = useState<any>([]);
  const [statusToggle, setStatusToggle] = useState('pending');
  const [isPrimaryUser, setIsPrimaryUser] = useState(false);
  const [mobileWithCode, setMobileWithCode] = useState<any>({
    MobileSocCode: 'us',
    mobileCode: '+1 ',
  });
  const [fileUploadOpen, setFileUploadOpen] = useState<any>({
    open: false,
  });
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setError,
    setValue,
    watch,
    getValues,
    // watch,
    formState: { errors },
  } = useForm<AddUserSubmitForm>({
    criteriaMode: 'all',
    defaultValues: {
      address: '',
      city: '',
      contactEmail: '',
      country: '',
      dateOfBirth: '',
      dateOfJoining: '',
      firstName: '',
      gender: '',
      lastName: '',
      logo: [],
      mobileNumber: '',
      phoneNumber: '',
      roleAssignedId: null,
      roleRestrict: false,
      state: '',
      status: true,
      title: '',
      unitNumber: '',
      unitType: '',
      zipCode: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AddUserSchema),
  });

  const cancelForm = () => {
    resetForm();
    // navigate(-1);
    if (userDetail.role_id === 3) {
      navigate(`${AttorneyPrivateRoutes.MANAGEUSER.MANAGEUSERLIST}`);
    } else if (userDetail.role_id === 2) {
      navigate(`${PetitionerPrivateRoutes.MANAGEUSER.MANAGEUSERLIST}`);
    } else {
      navigate(-1);
    }
  };

  const getInitialStatus = useCallback(() => {
    setLoader(true);
    getAllListData(`${COMMON_ENDPOINT.ManageAddEditUsers}/${String(editId)}`)
      .then((resp) => {
        const {
          first_name,
          last_name,
          city,
          conatct_status,
          // contact_mobile,
          country_code_mobile,
          country_source_code,
          unit_type,
          country,
          date_of_join,
          dob,
          email_id,
          gender,
          image,
          role_assigned,
          state,
          address,
          unit_number,
          user_phone,
          zip_code,
          title,
          attorney_restrict,
          primary_contact,
        } = resp;
        setCurrentStatus(conatct_status);
        setIsPrimaryUser(primary_contact ? true : false);
        !_.isNull(country)
          ? getCountriesDependent('countryId', country)
          : getCountriesDependent('');
        !_.isNull(state)
          ? getCountriesDependent('stateId', state)
          : getCountriesDependent('');

        if (image) {
          setProfileImage({
            imageURL: `${fileApiUrl}${String(image)}`,
            selectedImage: { image },
          });
        }

        setValue('firstName', first_name || '');
        setValue('title', title || '');
        setValue('lastName', last_name || '');
        setValue('gender', gender || '');
        setValue('dateOfJoining', date_of_join || '');
        setValue('dateOfBirth', dob || '');
        setValue('address', address || '');
        setValue('unitType', unit_type || '');
        setValue('unitNumber', unit_number || '');
        setValue('country', Number(country) || '');
        setValue('zipCode', zip_code || '');
        setValue('state', Number(state) || '');
        setValue('city', Number(city) || '');
        setValue('phoneNumber', user_phone || '');
        setValue(
          'mobileNumber',
          resp.contact_mobile
            ? String(resp.contact_mobile).indexOf('+') !== -1
              ? resp.contact_mobile
              : resp.country_code_mobile
              ? String(resp.country_code_mobile)
                  .concat(' ')
                  .concat(resp.contact_mobile)
              : '+1 '.concat(resp.contact_mobile)
            : '',
        );
        setValue('contactEmail', email_id || '');
        setValue(
          'roleAssignedId',
          role_assigned ? role_assigned.split(',') : null,
        );
        setValue('roleRestrict', attorney_restrict || false);
        setValue('status', conatct_status === 'active' ? true : false || true);
        setStatusToggle(conatct_status);
        setMobileWithCode({
          MobileSocCode: country_source_code,
          mobileCode: country_code_mobile,
        });

        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
    getAllListData(`${COMMON_ENDPOINT.ResponseTypes}/roles`)
      .then((rolResp: any) => {
        const options = [];
        rolResp.map((e) =>
          options.push({ label: e.role_name, value: e.id.toString() }),
        );
        setRoles(options);

        // setRoleAssignedIds(
        //   rolResp
        //     .filter(
        //       (obj) => role_assigned.split(',').indexOf(obj.id) !== -1,
        //     )
        //     .map((selected) => selected.id),
        // );
      })
      .catch((e: any) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToggleStatus = (evet) => {
    setStatusToggle(evet.target.value);
  };

  const onSubmitForm = (data: any) => {
    if (
      data['dateOfBirth'] &&
      !validateDate(moment(data['dateOfBirth']).format('MM/DD/YYYY'))
    ) {
      setError('dateOfBirth', {
        message: 'Not a valid date format.',
        type: 'custom',
      });
    } else if (
      data['dateOfJoining'] &&
      !validateDate(moment(data['dateOfJoining']).format('MM/DD/YYYY'))
    ) {
      setError('dateOfJoining', {
        message: 'Not a valid date format.',
        type: 'custom',
      });
    } else {
      if (watch('roleAssignedId')?.length === 0) {
        setError('roleAssignedId', {
          message: 'Role is required',
          type: 'custom',
        });
        return;
      } else {
        setError('roleAssignedId', {
          message: '',
          type: 'custom',
        });
        setLoader(true);
        const postFormData = new FormData();
        !_.isBuffer(profileImage.selectedImage) &&
          postFormData.append('logo', profileImage.selectedImage);
        postFormData.append(
          'firstName',
          data['firstName'] ? data['firstName'] : '',
        );
        postFormData.append('title', data['title'] ? data['title'] : '');
        postFormData.append(
          'lastName',
          data['lastName'] ? data['lastName'] : '',
        );
        postFormData.append('gender', data['gender'] ? data['gender'] : '');
        postFormData.append(
          'dateOfJoining',
          data['dateOfJoining']
            ? moment(data['dateOfJoining']).format('YYYY-MM-DD hh:mm')
            : null,
        );
        postFormData.append(
          'dateOfBirth',
          data['dateOfBirth']
            ? moment(data['dateOfBirth']).format('YYYY-MM-DD hh:mm')
            : null,
        );
        postFormData.append('address', data['address'] ? data['address'] : '');
        postFormData.append('zipCode', data['zipCode'] ? data['zipCode'] : '');
        postFormData.append('city', data['city'] ? String(data['city']) : '');
        postFormData.append(
          'state',
          data['state'] ? String(data['state']) : '',
        );
        postFormData.append(
          'country',
          data['country'] ? String(data['country']) : '',
        );
        postFormData.append(
          'unitType',
          data['unitType'] ? data['unitType'] : '',
        );
        postFormData.append(
          'unitNumber',
          data['unitNumber'] ? data['unitNumber'] : '',
        );
        userDetail.role_id === 3 &&
          postFormData.append(
            'roleRestrict',
            data['roleRestrict'] ? data['roleRestrict'] : false,
          );
        postFormData.append(
          'phoneNumber',
          data['phoneNumber'] ? data['phoneNumber'] : '',
        );
        postFormData.append(
          'mobileNumber',
          data['mobileNumber']
            ? String(data['mobileNumber']).split(/(?<=^\S+)\s/)[1]
            : '',
        );

        postFormData.append('mobileCode', mobileWithCode.mobileCode);
        postFormData.append(
          'MobileSocCode',
          String(mobileWithCode.MobileSocCode).toUpperCase(),
        );

        postFormData.append(
          'contactEmail',
          data['contactEmail'] ? data['contactEmail'] : '',
        );
        postFormData.append(
          'roleAssignedId',
          data['roleAssignedId'] ? data['roleAssignedId'] : '',
        );

        postFormData.append(
          'status',
          !_.isUndefined(editId) ? String(statusToggle) : 'pending',
        );

        if (!_.isUndefined(editId)) {
          updateData(editId, postFormData, COMMON_ENDPOINT.ManageAddEditUsers)
            .then((resp: any) => {
              toast.success(
                resp && resp.message ? resp.message : 'Updated successfully',
                alertOptions,
              );
              setLoader(false);
              cancelForm();
            })
            .catch((e) => {
              setLoader(false);
            });
        } else {
          addNewData(postFormData, COMMON_ENDPOINT.ManageAddEditUsers)
            .then((resp: any) => {
              toast.success(
                'Created Successfully. User created link sent to your mail id.',
                alertOptions,
              );
              setLoader(false);
              cancelForm();
            })
            .catch((e) => {
              setLoader(false);
            });
        }
      }
    }
  };

  const getCountriesDependent = useCallback((key?: string, value?: number) => {
    getAllListData(
      key === ''
        ? MASTER_ENDPOINT.Countries
        : `${MASTER_ENDPOINT.Countries}?${key}=${String(value)}`,
    )
      .then((countryResp: any) => {
        if (key === 'countryId') {
          setCountries((prev) => ({
            ...prev,
            countries: countryResp.countries,
            states: countryResp.states,
          }));
        }
        if (key === 'stateId') {
          setCountries((prev) => ({
            ...prev,
            cities: countryResp.cities,
            countries: countryResp.countries,
          }));
        }
        if (key === '') {
          setCountries({
            cities: countryResp.cities,
            countries: countryResp.countries,
            states: countryResp.states,
          });
        }
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  }, []);

  const profileSelected = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    setProfileImage({
      selectedImage: file,
    });
    reader.onloadend = () => {
      setProfileImage((prev) => ({
        ...prev,
        imageURL: [reader.result],
      }));
    };
  };

  const getSubscriptionsWithRoles = () => {
    getAllListData(COMMON_ENDPOINT.subscriptionPlan)
      .then((subResp: any) => {
        if (
          Number(subResp.total_user) <= Number(subResp.activeUserCount) &&
          userDetail?.is_subscription_need
        ) {
          navigate(AttorneyPrivateRoutes.MANAGEUSER.MANAGEUSERLIST, {
            state: true,
          });
        } else {
          getAllListData(`${COMMON_ENDPOINT.ResponseTypes}/roles`)
            .then((rolResp: any) => {
              const options = [];
              rolResp.map((e) =>
                options.push({ label: e.role_name, value: e.id }),
              );
              setRoles(options);
              getCountriesDependent('');
            })
            .catch((e: any) => {
              setLoader(false);
            });
        }
      })
      .catch((e: any) => {
        navigate(AttorneyPrivateRoutes.MANAGEUSER.MANAGEUSERLIST, {
          state: true,
        });
      });
  };

  useEffect(() => {
    if (!_.isUndefined(editId)) {
      getInitialStatus();
    } else {
      if (userDetail.role_id === 3) {
        getSubscriptionsWithRoles();
      } else {
        getAllListData(`${COMMON_ENDPOINT.ResponseTypes}/roles`)
          .then((rolResp: any) => {
            const options = [];
            rolResp.map((e) =>
              options.push({ label: e.role_name, value: e.id }),
            );
            setRoles(options);
            getCountriesDependent('');
          })
          .catch((e: any) => {
            setLoader(false);
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeUploadDialog = useCallback((action: any) => {
    if (action) {
      profileSelected(action);
    } else {
      setFileUploadOpen((pre) => ({
        ...pre,
        open: false,
      }));
    }
  }, []);

  return (
    <Grid container>
      <Grid item md={10} sm={12} className="pl-0" sx={{ paddingRight: '1rem' }}>
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <>
            <form id="add-user" onSubmit={handleSubmit(onSubmitForm)}>
              <Typography
                component={'div'}
                className="addbox"
                sx={{ marginTop: '4rem !important' }}>
                <div className="user-poto-box">
                  <div
                    className="pencil-box"
                    onClick={() => setFileUploadOpen({ open: true })}>
                    <img className="d-block" src={pencil} alt="pencileditsvg" />
                    <img
                      className="d-none"
                      src={pencilImg}
                      alt="pencileditsvg"
                    />

                    {/* <input
                      type="file"
                      hidden
                      ref={hiddenFileInput}
                      onChange={(e) => profileSelected(e.target.files)}
                      accept="image/png, image/jpeg"
                    /> */}
                  </div>
                  {fileUploadOpen.open && (
                    <FileUploadDialog
                      open={fileUploadOpen.open}
                      title={'Upload Profile '}
                      allowedFileType={profileFileType}
                      errorMessage={
                        'You can upload documents of types JPEG, PNG only'
                      }
                      info={
                        '(You can upload documents of types JPEG, PNG only)'
                      }
                      onConfirm={closeUploadDialog}
                      setFileUploadOpen={setFileUploadOpen}
                    />
                  )}
                  {_.isBuffer(profileImage.selectedImage) ? (
                    <img
                      src={profileImage.imageURL}
                      alt="user"
                      className="user-poto"
                    />
                  ) : (
                    <img
                      src={
                        !profileImage?.imageURL ? Avator : profileImage.imageURL
                      }
                      alt="user"
                      className="user-poto"
                    />
                  )}
                </div>
                <Typography
                  component={'div'}
                  className="uadd-title"
                  sx={{ paddingTop: '3.5rem !important' }}>
                  Personal Details
                </Typography>
                <Box
                  component="form"
                  className="u-add-in"
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                    <Grid item md={6}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          First Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="firstName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'First Name'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.firstName?.message}
                                onChange={(val) => {
                                  const vals = val.target.value.replace(
                                    /[^a-zA-Z s]/,
                                    '',
                                  );
                                  onChange(vals);
                                }}
                                tabIndex={1}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Gender
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="gender"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  {
                                    label: 'Male',
                                    value: 'male',
                                  },
                                  {
                                    label: 'Female',
                                    value: 'female',
                                  },
                                ]}
                                name={name}
                                value={value}
                                onChange={(val) => onChange(val)}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.gender?.message}
                                tabIndex={3}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Job Role
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="title"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Job Role'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.title?.message}
                                onChange={(val) => {
                                  onChange(val);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Last Name <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="lastName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Last Name'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.lastName?.message}
                                onChange={(val) => onChange(val)}
                                tabIndex={2}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Date of Birth
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="dateOfBirth"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            const now = new Date();
                            return (
                              <CustomDatePicker
                                className="custom-input"
                                errors={errors.dateOfBirth?.message}
                                name={name}
                                value={value}
                                onChangeDate={(val) => onChange(val)}
                                tabIndex={5}
                                isReadOnlyInput={true}
                                maxDate={
                                  new Date(
                                    now.getFullYear() - 18,
                                    now.getMonth(),
                                    now.getDate(),
                                  )
                                }
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Date of Joining
                        </Typography>
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            className="custom-input"
                            value={value}
                            onChange={(newValue) => {
                              setValue(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField className="custom-input" {...params} />
                            )}
                          />
                        </LocalizationProvider> */}
                        <Controller
                          control={control}
                          defaultValue=""
                          name="dateOfJoining"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomDatePicker
                                className="custom-input"
                                PopperProps={{
                                  className: 'custom-date-disabled',
                                }}
                                errors={errors.dateOfJoining?.message}
                                name={name}
                                value={value}
                                onChangeDate={(val) => onChange(val)}
                                disableFuture={true}
                                tabIndex={4}
                                isReadOnlyInput={true}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Typography component={'div'} className="uadd-title">
                  Address
                </Typography>
                <Box
                  component="form"
                  className="u-add-in"
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                    <Grid item md={6}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Street Number And Name
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="address"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Street Number And Name'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.address?.message}
                                onChange={(val) => onChange(val)}
                                tabIndex={6}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Unit Number
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="unitNumber"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Unit Number'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.unitNumber?.message}
                                onChange={(val) => onChange(val)}
                                tabIndex={8}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          State
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="state"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                searchable={true}
                                labelKey={'name'}
                                valueKey={'id'}
                                tabIndex={10}
                                options={
                                  !_.isEmpty(countries?.states)
                                    ? countries?.states
                                    : []
                                }
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  getCountriesDependent(
                                    'stateId',
                                    e.target.value,
                                  );
                                }}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.state?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Zip / Postal Code
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="zipCode"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Zip / Postal Code'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.zipCode?.message}
                                onChange={(val) =>
                                  onChange(
                                    val.target.value.replace(/[^\d]/g, ''),
                                  )
                                }
                                type={'text'}
                                tabIndex={12}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Unit Type
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue="appartment"
                          name="unitType"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name={name}
                                value={value}
                                tabIndex={7}
                                onChange={(e) => {
                                  onChange(e);
                                }}>
                                <FormControlLabel
                                  value="appartment"
                                  control={<Radio />}
                                  label="Apartment"
                                />
                                <FormControlLabel
                                  value="suite"
                                  control={<Radio />}
                                  label="Suite"
                                />
                                <FormControlLabel
                                  value="floor"
                                  control={<Radio />}
                                  label="Floor"
                                />
                                {getValues().unitType && (
                                  <FormControlLabel
                                    value=""
                                    control={
                                      <span
                                        className="reset-button"
                                        onClick={() =>
                                          setValue('unitType', '')
                                        }>
                                        Reset
                                      </span>
                                    }
                                    label=""
                                  />
                                )}
                              </RadioGroup>
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Country
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="country"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                searchable={true}
                                labelKey={'name'}
                                valueKey={'id'}
                                tabIndex={9}
                                options={
                                  !_.isEmpty(countries?.countries)
                                    ? countries?.countries
                                    : []
                                }
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  getCountriesDependent(
                                    'countryId',
                                    e.target.value,
                                  );
                                }}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.country?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          City
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="city"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                searchable={true}
                                labelKey={'name'}
                                valueKey={'id'}
                                options={
                                  !_.isEmpty(countries?.cities)
                                    ? countries?.cities
                                    : []
                                }
                                name={name}
                                value={value}
                                onChange={(val) => onChange(val)}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.city?.message}
                                tabIndex={11}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Typography component={'div'} className="uadd-title">
                  Contact Details
                </Typography>
                <Box
                  component="form"
                  className="u-add-in"
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                    <Grid item md={6}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Phone
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="phoneNumber"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Phone Number'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.phoneNumber?.message}
                                onChange={(val) => onChange(val)}
                                type={'number'}
                                tabIndex={13}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Email{' '}
                          <Typography component={'span'} className="Mui-error">
                            *
                          </Typography>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="contactEmail"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Email'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.contactEmail?.message}
                                onChange={(val) => onChange(val)}
                                type={'email'}
                                disabled={
                                  !_.isUndefined(editId)
                                    ? currentStatus === 'pending'
                                      ? false
                                      : true
                                    : false
                                }
                                tabIndex={13}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Role(s){' '}
                          <Typography component={'span'} className="Mui-error">
                            *
                          </Typography>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name="roleAssignedId"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                closeMenuOnSelect={false}
                                labelKey={'label'}
                                valueKey={'value'}
                                options={roles}
                                isMulti={true}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  // setRoleAssignedIds(value);
                                }}
                                placeHolder={'Select'}
                                className={'basic-multi-select'}
                                error={errors.roleAssignedId?.message}
                                // tabIndex={1}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Mobile
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="mobileNumber"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <PhoneInput
                                placeholder="Mobile"
                                country={'us'}
                                value={value}
                                onChange={(
                                  value,
                                  data,
                                  event,
                                  formattedValue,
                                ) => {
                                  const codes: any = data;
                                  onChange(formattedValue);
                                  setMobileWithCode({
                                    MobileSocCode: codes?.countryCode,
                                    mobileCode: '+'.concat(codes?.dialCode),
                                  });
                                }}
                              />
                              // <CustomInput
                              //   placeHolder={''}
                              //   name={name}
                              //   value={value}
                              //   className={'custom-input'}
                              //   error={errors.mobileNumber?.message}
                              //   onChange={(val) => onChange(val)}
                              //   type={'number'}
                              // />
                            );
                          }}
                        />
                      </Typography>
                      {!_.isUndefined(editId) &&
                        (!isPrimaryUser ||
                          (isPrimaryUser && currentStatus !== 'active')) && (
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Status
                            </Typography>
                            <div className="switch-field" tabIndex={14}>
                              <input
                                type="radio"
                                id="switch_left"
                                name="switchToggle"
                                value={'inactive'}
                                onChange={onToggleStatus}
                                checked={statusToggle === 'inactive'}
                                ref={inActiveStatusInput}
                              />
                              <label
                                htmlFor="switch_left d-flex-ja"
                                onClick={() =>
                                  inActiveStatusInput.current.click()
                                }>
                                Inactive
                              </label>

                              {currentStatus === 'pending' ? (
                                <>
                                  <input
                                    type="radio"
                                    id="switch_right"
                                    name="switchToggle"
                                    value={'pending'}
                                    onChange={onToggleStatus}
                                    checked={statusToggle === 'pending'}
                                    ref={activeStatusInput}
                                  />
                                  <label
                                    htmlFor="switch_right d-flex-ja"
                                    onClick={() =>
                                      activeStatusInput.current.click()
                                    }>
                                    Pending
                                  </label>
                                </>
                              ) : (
                                <>
                                  <input
                                    type="radio"
                                    id="switch_right"
                                    name="switchToggle"
                                    value={'active'}
                                    onChange={onToggleStatus}
                                    checked={statusToggle === 'active'}
                                    ref={activeStatusInput}
                                  />
                                  <label
                                    htmlFor="switch_right d-flex-ja"
                                    onClick={() =>
                                      activeStatusInput.current.click()
                                    }>
                                    Active
                                  </label>
                                </>
                              )}
                            </div>
                          </Typography>
                        )}
                      {userDetail.role_id === 3 && (
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Lead Access
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={false}
                            name="roleRestrict"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name={name}
                                  value={value}
                                  tabIndex={15}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}>
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="Restricted"
                                  />
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Full"
                                  />
                                </RadioGroup>
                              );
                            }}
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Typography>
            </form>
            <Grid container className="bottom-fixed">
              <Grid item md={12}>
                <Typography component={'div'} className="d-flex-jae ">
                  <Button
                    className="cancel-btn mr-2"
                    type="button"
                    onClick={cancelForm}>
                    <Typography className="o-box" />
                    Cancel
                  </Button>
                  <Button
                    className="add-btn"
                    type="submit"
                    form={'add-user'}
                    disabled={loader}>
                    Save
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default UserAdd;
