import React from 'react';

export const UploadPetitionsDocumentTableHeadting: React.FC = React.memo(() => {
  return (
    <thead>
      <tr>
        <th>Petitioner</th>
        <th>Beneficiary</th>
        <th>Visa Type</th>
        <th>Status</th>
        <th>Required Documents</th>
        <th>Req. Add Document</th>
      </tr>
    </thead>
  );
});

export default UploadPetitionsDocumentTableHeadting;
