import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PassportTypesSchema } from 'types';
import { getColorFormat } from 'utils/helper';
import { passportSchema } from 'utils/ValidatorSchema';

import { DocumentViewer } from '../CustomDocumentViewer/CustomeDocumentViewer';

type PassportVal = {
  firstName: { confidence: string; value: string };
  middleName: { confidence: string; value: string };
  lastName: { confidence: string; value: string };
  gender: { confidence: string; value: string };
  dateOfBirth: { confidence: string; value: string };
  nationality: { confidence: string; value: string };
  countryName: { confidence: string; value: string };
  passportNumber: { confidence: string; value: string };
  expiryDate: { confidence: string; value: string };
};
type Props = {
  fileUrl: string;
  passportInfo: PassportVal;
  onConfirm: (src, val) => void;
  setPassportInfo?: (src: PassportVal) => void;
  uploadDisable?: boolean;
  BackBtn?: boolean;
  inputDisable?: boolean;
  fileType?: string;
};

export const PassportParsing: React.FC<Props> = (props: Props) => {
  const {
    fileUrl,
    onConfirm,
    passportInfo,
    setPassportInfo,
    uploadDisable,
    BackBtn,
    inputDisable,
    fileType = '',
  } = props;
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PassportTypesSchema>({
    criteriaMode: 'all',
    defaultValues: {
      countryName: '',
      dateOfBirth: '',
      expiryDate: '',
      firstName: '',
      gender: '',
      lastName: '',
      middleName: '',
      nationality: '',
      passportNumber: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(passportSchema),
  });

  const onSubmitForm = () => {
    onConfirm && onConfirm('submit', passportInfo);
  };

  React.useEffect(() => {
    console.log('passportInfo', passportInfo);
    setValue('firstName', passportInfo?.firstName?.value || '');
    setValue('middleName', passportInfo?.middleName?.value || '');
    setValue('lastName', passportInfo?.lastName?.value || '');
    setValue('gender', String(passportInfo?.gender?.value).toLowerCase() || '');
    setValue('dateOfBirth', passportInfo?.dateOfBirth?.value || '');
    setValue('countryName', passportInfo?.countryName?.value || '');
    setValue('expiryDate', passportInfo?.expiryDate?.value || '');
    setValue('nationality', passportInfo?.nationality?.value || '');
    setValue('passportNumber', passportInfo?.passportNumber?.value || '');
  }, [passportInfo, setValue]);
  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={7}>
            <Typography
              className="assem-box"
              id="parentdocId"
              sx={{
                maxHeight: '100vh',
                minHeight: '100vh',
                overflow: 'auto',
              }}>
              <DocumentViewer
                fileBlob={fileUrl}
                fileType={fileType}
                width={
                  document.getElementById('parentdocId') &&
                  document.getElementById('parentdocId').offsetWidth
                    ? `${
                        document.getElementById('parentdocId').offsetWidth - 100
                      }px`
                    : 'auto'
                }
              />
            </Typography>
          </Grid>

          <Grid item md={5} sm={12}>
            <Typography component={'div'}>
              <Box
                component="form"
                className="u-add-in"
                sx={{
                  backgroundColor: 'unset !important',
                  border: 'unset !important',
                  maxHeight: '100vh',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  padding: 'unset !important',
                }}
                noValidate
                autoComplete="off">
                <form>
                  <Grid container>
                    <Grid item md={12}>
                      <Typography
                        component={'div'}
                        className="custom-field u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          First Name <span style={{ color: 'red' }}>*</span>
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'firstName'}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                ref={ref}
                                name={name}
                                className={''.concat(
                                  passportInfo.firstName.confidence !== '90'
                                    ? getColorFormat(
                                        passportInfo.firstName.confidence,
                                      )
                                    : 'custom-input',
                                )}
                                error={errors?.firstName?.message || ''}
                                placeHolder="First Name"
                                onChange={(e) => {
                                  onChange(e);
                                  setPassportInfo({
                                    ...passportInfo,
                                    firstName: {
                                      ...{
                                        ...passportInfo.firstName,
                                        value: e.target.value,
                                      },
                                    },
                                  });
                                }}
                                value={value}
                                disabled={inputDisable}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={12}>
                      <Typography
                        component={'div'}
                        className="custom-field u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Middle Name
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'middleName'}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                ref={ref}
                                name={name}
                                className={''.concat(
                                  passportInfo.firstName.confidence !== '90'
                                    ? getColorFormat(
                                        passportInfo.firstName.confidence,
                                      )
                                    : 'custom-input',
                                )}
                                error={errors?.middleName?.message || ''}
                                placeHolder="Middle Name"
                                onChange={(e) => {
                                  onChange(e);
                                  setPassportInfo({
                                    ...passportInfo,
                                    middleName: {
                                      ...{
                                        ...passportInfo.middleName,
                                        value: e.target.value,
                                      },
                                    },
                                  });
                                }}
                                value={value}
                                disabled={inputDisable}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={12}>
                      <Typography
                        component={'div'}
                        className="custom-field u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Last Name
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'lastName'}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                ref={ref}
                                name={name}
                                className={''.concat(
                                  passportInfo.lastName.confidence !== '90'
                                    ? getColorFormat(
                                        passportInfo.lastName.confidence,
                                      )
                                    : 'custom-input',
                                )}
                                placeHolder="Last Name"
                                error={errors?.lastName?.message || ''}
                                onChange={(e) => {
                                  onChange(e);
                                  setPassportInfo({
                                    ...passportInfo,
                                    lastName: {
                                      ...{
                                        ...passportInfo.lastName,
                                        value: e.target.value,
                                      },
                                    },
                                  });
                                }}
                                value={value}
                                disabled={inputDisable}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={12}>
                      <Typography
                        component={'div'}
                        className="custom-field u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Gender <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'gender'}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <>
                                <RadioGroup
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  value={value ? value.toLowerCase() : ''}
                                  name={name}
                                  ref={ref}
                                  onChange={(e) => {
                                    onChange(e);
                                    setPassportInfo({
                                      ...passportInfo,
                                      gender: {
                                        ...{
                                          ...passportInfo.gender,
                                          value: e.target.value,
                                        },
                                      },
                                    });
                                  }}>
                                  <FormControlLabel
                                    className="radio-bottom"
                                    value="m"
                                    control={<Radio disabled={inputDisable} />}
                                    label="Male"
                                  />
                                  <FormControlLabel
                                    className="radio-bottom"
                                    value="f"
                                    control={<Radio disabled={inputDisable} />}
                                    label="Female"
                                  />
                                </RadioGroup>
                                {errors.gender?.message && (
                                  <FormHelperText style={{ color: '#FF0000' }}>
                                    {errors.gender?.message}
                                  </FormHelperText>
                                )}
                              </>
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={12}>
                      <Typography
                        component={'div'}
                        className="custom-field u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Date Of Birth (MM/DD/YYYY){' '}
                          <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'dateOfBirth'}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                ref={ref}
                                name={name}
                                error={errors?.dateOfBirth?.message || ''}
                                className={''.concat(
                                  passportInfo.dateOfBirth.confidence !== '90'
                                    ? getColorFormat(
                                        passportInfo.dateOfBirth.confidence,
                                      )
                                    : 'custom-input',
                                )}
                                placeHolder="Date Of Birth (MM/DD/YYYY)"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  setPassportInfo({
                                    ...passportInfo,
                                    dateOfBirth: {
                                      ...{
                                        ...passportInfo.dateOfBirth,
                                        value: e.target.value,
                                      },
                                    },
                                  });
                                }}
                                disabled={inputDisable}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={12}>
                      <Typography
                        component={'div'}
                        className="custom-field u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Nationality
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'nationality'}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                ref={ref}
                                name={name}
                                className={''.concat(
                                  passportInfo.nationality.confidence !== '90'
                                    ? getColorFormat(
                                        passportInfo.nationality.confidence,
                                      )
                                    : 'custom-input',
                                )}
                                placeHolder="Nationality"
                                value={value}
                                error={errors?.nationality?.message || ''}
                                onChange={(e) => {
                                  onChange(e);
                                  setPassportInfo({
                                    ...passportInfo,
                                    nationality: {
                                      ...{
                                        ...passportInfo.nationality,
                                        value: e.target.value,
                                      },
                                    },
                                  });
                                }}
                                disabled={inputDisable}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={12}>
                      <Typography
                        component={'div'}
                        className="custom-field u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Country
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'countryName'}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                ref={ref}
                                name={name}
                                error={errors?.countryName?.message || ''}
                                className={''.concat(
                                  passportInfo.countryName.confidence !== '90'
                                    ? getColorFormat(
                                        passportInfo.countryName.confidence,
                                      )
                                    : 'custom-input',
                                )}
                                placeHolder="Country"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  setPassportInfo({
                                    ...passportInfo,
                                    countryName: {
                                      ...{
                                        ...passportInfo.countryName,
                                        value: e.target.value,
                                      },
                                    },
                                  });
                                }}
                                disabled={inputDisable}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={12}>
                      <Typography
                        component={'div'}
                        className="custom-field u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Passport Number{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'passportNumber'}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                ref={ref}
                                name={name}
                                error={errors?.passportNumber?.message || ''}
                                className={''.concat(
                                  passportInfo.passportNumber.confidence !==
                                    '90'
                                    ? getColorFormat(
                                        passportInfo.passportNumber.confidence,
                                      )
                                    : 'custom-input',
                                )}
                                placeHolder="Passport Number"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  setPassportInfo({
                                    ...passportInfo,
                                    passportNumber: {
                                      ...{
                                        ...passportInfo.passportNumber,
                                        value: e.target.value,
                                      },
                                    },
                                  });
                                }}
                                disabled={inputDisable}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={12}>
                      <Typography
                        component={'div'}
                        className="custom-field u-pr-1">
                        <Typography component={'div'} className="custom-label">
                          Expiry Date (MM/DD/YYYY){' '}
                          <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'expiryDate'}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                ref={ref}
                                name={name}
                                className={''.concat(
                                  passportInfo.expiryDate.confidence !== '90'
                                    ? getColorFormat(
                                        passportInfo.expiryDate.confidence,
                                      )
                                    : 'custom-input',
                                )}
                                placeHolder="Expiry Date (MM/DD/YYYY)"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  setPassportInfo({
                                    ...passportInfo,
                                    expiryDate: {
                                      ...{
                                        ...passportInfo.expiryDate,
                                        value: e.target.value,
                                      },
                                    },
                                  });
                                }}
                                error={errors?.expiryDate?.message || ''}
                                disabled={inputDisable}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Typography component={'div'} className="d-flex-jae mt-1 ">
          {!uploadDisable && (
            <Button
              className="back-btn mr-1"
              onClick={() => onConfirm('reUpload', '')}>
              Re Upload
            </Button>
          )}
          {BackBtn && (
            <Button className="back-btn mr-1" onClick={() => navigate(-1)}>
              Back
            </Button>
          )}
          {!inputDisable && (
            <Button
              className="n-add-btn"
              form={'passport-form'}
              onClick={() => {
                handleSubmit && handleSubmit(onSubmitForm)();
              }}>
              Submit
            </Button>
          )}
        </Typography>
      </DialogActions>
    </>
  );
};
