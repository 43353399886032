import OpenWithIcon from '@mui/icons-material/OpenWith';
import { Box, Button, Modal, Typography } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Closeicon from 'assets/images/wclose.svg';
import DownIcon from 'assets/images/wdown.svg';
import DownloadIcon from 'assets/images/wdownload.svg';
import EditIcon from 'assets/images/wedit.svg';
import { AttorneyPrivateRoutes } from 'config';
import saveAs from 'file-saver';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData, getS3file, updateData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  border: `1px solid ${theme.palette.divider}`,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <img src={DownIcon} alt="down" style={{ transform: 'rotate(270deg)' }} />
    }
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  backgroundColor: theme.palette.mode === 'dark' ? '#E2F2FF' : '#E2F2FF',
  flexDirection: 'row-reverse',
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: theme.spacing(2),
}));

export const RfeView: React.FC<any> = () => {
  const [expand, setExpand] = useState(0);
  const { petitionId } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [showParentTable, setShowParentTable] = useState(true);
  const [showChildTable, setShowChildTable] = useState(true);
  const [rfeData, setRFEData] = useState({
    data: [],
    total: 0,
  });
  const [rfeIssues, setRFEIssues] = useState({
    acceptedCount: 0,
    data: [],
    filePath: '',
    total: 0,
  });
  const [showResponse, setShowResponse] = useState({
    content: '',
    open: false,
  });
  const toggleAccordion = (index, id) => {
    setExpand(index === expand ? -1 : index);
    if (index !== expand) {
      setLoader(true);
      getIssues(id);
    }
  };

  const showParent = (i) => {
    setShowParentTable(!showParentTable);
  };
  const showChild = (i) => {
    setShowChildTable(!showChildTable);
  };

  const getIssues = (id: string) => {
    getAllListData(
      `${String(COMMON_ENDPOINT.rfeResponder)}/issues/${String(id)}`,
    ).then((resp: any) => {
      setShowParentTable(true);
      setShowChildTable(true);
      if (resp && resp.data && resp.data.length) {
        setRFEIssues({
          acceptedCount: resp.acceptedCount ? resp.acceptedCount : 0,
          data: resp.data,
          filePath: resp.file_path,
          total: resp.total,
        });
      } else {
        setRFEIssues({
          acceptedCount: 0,
          data: [],
          filePath: resp.file_path,
          total: 0,
        });
      }
      setLoader(false);
    });
  };

  const getAllRfeData = (petitionId) => {
    getAllListData(
      `${String(COMMON_ENDPOINT.rfeResponder)}/${String(petitionId)}`,
    ).then((resp: any) => {
      if (resp && resp.data && resp.data.length) {
        setRFEData({ data: resp.data, total: resp.count });
        getIssues(resp.data[0]?.id);
      } else {
        setLoader(false);
      }
    });
  };

  const handleIssuesDrageAndDrop = (type: string, issuesOrder: any) => {
    const orderdPayload = {
      issuesOrder: issuesOrder,
      responseType: type,
    };

    updateData(
      '',
      orderdPayload,
      `${String(COMMON_ENDPOINT.rfeResponder)}/update-order`,
    )
      .then((resp: any) => {
        getAllRfeData(petitionId);
        toast.success(resp.message, alertOptions);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const handleIssueDragParent = (
    result: DropResult,
    provided?: ResponderProvided,
  ) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const temp = [...rfeIssues.data];
    const obj = temp[result.source.index];
    temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, obj);
    setRFEIssues((pre) => ({
      ...pre,
      data: temp,
    }));
    const issuesOrder = [];
    temp.map((val, index) => {
      issuesOrder.push({ id: val.id, order: Number(index) + 1 });
    });
    handleIssuesDrageAndDrop('issue', issuesOrder.length ? issuesOrder : []);
  };

  const handleIssueDragSub = (
    result: DropResult,
    provided?: ResponderProvided,
    pIndex?,
  ) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const subIssue = rfeIssues.data[pIndex].subIssueList;
    const temp = [...subIssue];
    const obj = temp[result.source.index];
    temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, obj);
    rfeIssues.data[pIndex].subIssueList = [...temp];
    setRFEIssues((pre) => ({
      ...pre,
      data: temp,
    }));
    const issuesOrder = [];
    temp.map((val, index) => {
      issuesOrder.push({ id: val.id, order: Number(index) + 1 });
    });
    handleIssuesDrageAndDrop(
      'sub-issue',
      issuesOrder.length ? issuesOrder : [],
    );
  };

  const fileDownload = (key) => {
    const obj = {
      key: key,
    };
    const fileName = key.split('/').pop();
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(`${String(fileName)} File Downloaded`, alertOptions);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };

  useEffect(() => {
    if (!_.isUndefined(petitionId)) {
      getAllRfeData(petitionId);
    } else {
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitionId]);

  return (
    <Typography component={'div'}>
      {loader ? <div className="liberty-loader" /> : null}
      <Typography component={'div'} className="submis-head">
        <Typography component={'div'} className="s1-title">
          RFE Responder / View Files
        </Typography>
        <Typography component={'div'} className="d-flex-a">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="mr-2">
            <CircularProgress
              variant="determinate"
              value={(rfeIssues.acceptedCount / rfeIssues.total) * 100}
              sx={{ zIndex: 1 }}
            />
            <Typography
              position="absolute"
              className="d-flex-ja"
              sx={{
                border: '2px solid #fff',
                borderRadius: '50%',
                color: '#fff !important',
                fontSize: '12px',
                height: '40px',
                width: '40px',
              }}>
              {rfeIssues.acceptedCount}/{rfeIssues.total}
            </Typography>
          </Box>
          <img
            src={Closeicon}
            alt="Close"
            className="pointer"
            onClick={() =>
              navigate(
                `${String(
                  AttorneyPrivateRoutes.PETITIONS.SUBMISSIONSTATION,
                )}/${String(petitionId)}`,
              )
            }
          />
        </Typography>
      </Typography>
      <Typography component={'div'} className="submis-body">
        {rfeIssues.data.length > 0 && (
          <Typography className="sub-title">
            Total Issues Completed - {rfeIssues.acceptedCount}
          </Typography>
        )}
        {rfeData.data && rfeData.data.length > 0 ? (
          rfeData.data.map((rfe, index) => (
            <Accordion expanded={expand === index ? true : false} key={index}>
              <AccordionSummary
                onClick={(e) => toggleAccordion(index, rfe.id)}
                aria-controls={`panel${String(index)}a-content`}
                id={`panel${String(index)}a-header`}>
                <Typography
                  component={'div'}
                  className="d-flex-a d-flex-sb w-100">
                  <Typography className="f-19">
                    {rfe.file_name} - Added on :{' '}
                    {moment(rfe.created_at).format('MMM DD, YYYY - HH:mm A')}
                    {/* Form G-28 - Added on : Feb 13, 2023 - 10:30 AM */}
                  </Typography>
                  <Typography component={'div'} className="d-flex-a">
                    <img
                      src={EditIcon}
                      alt="icon"
                      className="mr-3 pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(
                          `${String(
                            AttorneyPrivateRoutes.RFERESPOEND.RFERESPONDER,
                          )}/${petitionId}/${String(rfe.id)}`,
                          {
                            state: { parse: false },
                          },
                        );
                      }}
                    />
                    <img
                      src={DownloadIcon}
                      alt="icon"
                      className="mr-3 pointer"
                      onClick={() => fileDownload(rfe.s3_file_path)}
                    />
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component={'div'}>
                  <Typography
                    component={'div'}
                    sx={{
                      alignItems: 'flex-start',
                      display: 'flex',
                      marginTop: '16px',
                    }}>
                    <img
                      src={DownIcon}
                      alt="down"
                      style={{
                        transform: showParentTable
                          ? 'rotate(360deg)'
                          : 'rotate(270deg)',
                      }}
                      className="mt-2 mr-2 pointer"
                      onClick={() => showParent(index)}
                    />
                    <Typography component={'div'} sx={{ flex: 1 }}>
                      <TableContainer>
                        <Table
                          className="issue-table"
                          sx={{ minWidth: 650 }}
                          aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>&nbsp;</TableCell>
                              <TableCell sx={{ width: '5%' }}>SI.No</TableCell>
                              <TableCell sx={{ width: '25%' }}>
                                Issue Title
                              </TableCell>
                              <TableCell sx={{ width: '30%' }}>
                                Issue Text
                              </TableCell>
                              <TableCell sx={{ width: '30%' }}>
                                Issue Response
                              </TableCell>
                              <TableCell sx={{ width: '10%' }}>
                                Status
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {showParentTable && (
                            <DragDropContext onDragEnd={handleIssueDragParent}>
                              <Droppable
                                droppableId="droppable"
                                direction="vertical">
                                {(droppableProvided: DroppableProvided) => (
                                  <TableBody
                                    ref={droppableProvided.innerRef}
                                    {...droppableProvided.droppableProps}>
                                    {rfeIssues.data.map((issues, rIndex) => (
                                      <React.Fragment key={rIndex}>
                                        <Draggable
                                          key={issues.id}
                                          draggableId={String(issues.id)}
                                          index={rIndex}>
                                          {(
                                            draggableProvided: DraggableProvided,
                                            snapshot: DraggableStateSnapshot,
                                          ) => {
                                            return (
                                              <TableRow
                                                ref={draggableProvided.innerRef}
                                                {...draggableProvided.draggableProps}
                                                style={{
                                                  ...draggableProvided
                                                    .draggableProps.style,
                                                  background:
                                                    snapshot.isDragging
                                                      ? 'rgba(245,245,245, 0.75)'
                                                      : 'none',
                                                }}
                                                sx={{
                                                  '&:last-child td, &:last-child th':
                                                    {
                                                      border: '0px !important',
                                                    },
                                                }}>
                                                <TableCell>
                                                  <div
                                                    {...draggableProvided.dragHandleProps}>
                                                    <OpenWithIcon />
                                                  </div>
                                                </TableCell>
                                                <TableCell>
                                                  {rIndex + 1}
                                                </TableCell>
                                                <TableCell>
                                                  {issues.issue_title}
                                                </TableCell>
                                                <TableCell>
                                                  <Typography
                                                    component={'div'}
                                                    className="hideshow">
                                                    {issues.issue_text}
                                                  </Typography>
                                                </TableCell>
                                                <TableCell>
                                                  <div
                                                    className="pointer"
                                                    onClick={() => {
                                                      if (
                                                        issues.issue_response &&
                                                        issues.issue_response
                                                          .length >= 50
                                                      ) {
                                                        setShowResponse({
                                                          content:
                                                            issues.issue_response,
                                                          open: true,
                                                        });
                                                      }
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        issues.issue_response
                                                          ? issues
                                                              .issue_response
                                                              .length >= 50
                                                            ? issues.issue_response
                                                                .slice(0, 50)
                                                                .concat('...')
                                                            : issues.issue_response
                                                          : '-',
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell>
                                                  <div
                                                    className={
                                                      issues.issue_status ===
                                                      'pending'
                                                        ? 'pending'
                                                        : 'accept'
                                                    }>
                                                    {capitalize(
                                                      issues.issue_status,
                                                    )}
                                                  </div>
                                                </TableCell>
                                              </TableRow>
                                            );
                                          }}
                                        </Draggable>
                                        {issues.subIssueList &&
                                        issues.subIssueList.length > 0 ? (
                                          <TableRow
                                            sx={{
                                              backgroundColor:
                                                'unset !important',
                                            }}>
                                            <TableCell
                                              colSpan={12}
                                              sx={{
                                                backgroundColor:
                                                  'unset !important',
                                              }}>
                                              <Typography
                                                component={'div'}
                                                sx={{
                                                  alignItems: 'flex-start',
                                                  display: 'flex',
                                                  marginTop: '16px',
                                                }}>
                                                <img
                                                  src={DownIcon}
                                                  alt="down"
                                                  style={{
                                                    transform: showChildTable
                                                      ? 'rotate(360deg)'
                                                      : 'rotate(270deg)',
                                                  }}
                                                  className="mt-2 mr-2 pointer"
                                                  onClick={() =>
                                                    showChild(index)
                                                  }
                                                />
                                                <Typography
                                                  component={'div'}
                                                  sx={{ flex: 1 }}>
                                                  <TableContainer>
                                                    <Table
                                                      className="issue-table"
                                                      sx={{ minWidth: 650 }}
                                                      aria-label="simple table">
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell>
                                                            &nbsp;
                                                          </TableCell>
                                                          <TableCell
                                                            sx={{
                                                              width: '5%',
                                                            }}>
                                                            SI.No
                                                          </TableCell>
                                                          <TableCell
                                                            sx={{
                                                              width: '25%',
                                                            }}>
                                                            Sub-Issue Title
                                                          </TableCell>
                                                          <TableCell
                                                            sx={{
                                                              width: '30%',
                                                            }}>
                                                            Sub-Issue Text
                                                          </TableCell>
                                                          <TableCell
                                                            sx={{
                                                              width: '30%',
                                                            }}>
                                                            Sub-Issue Response
                                                          </TableCell>
                                                          <TableCell
                                                            sx={{
                                                              width: '10%',
                                                            }}>
                                                            Status
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      {showChildTable && (
                                                        <DragDropContext
                                                          onDragEnd={(
                                                            result,
                                                            provided,
                                                          ) =>
                                                            handleIssueDragSub(
                                                              result,
                                                              provided,
                                                              rIndex,
                                                            )
                                                          }>
                                                          <Droppable
                                                            droppableId="droppable"
                                                            direction="vertical">
                                                            {(
                                                              droppableProvided: DroppableProvided,
                                                            ) => (
                                                              <TableBody
                                                                ref={
                                                                  droppableProvided.innerRef
                                                                }
                                                                {...droppableProvided.droppableProps}>
                                                                {issues.subIssueList.map(
                                                                  (
                                                                    subIssues,
                                                                    siIndex,
                                                                  ) => (
                                                                    <React.Fragment
                                                                      key={
                                                                        siIndex
                                                                      }>
                                                                      <Draggable
                                                                        key={
                                                                          subIssues.id
                                                                        }
                                                                        draggableId={String(
                                                                          subIssues.id,
                                                                        )}
                                                                        index={
                                                                          siIndex
                                                                        }>
                                                                        {(
                                                                          draggableProvided: DraggableProvided,
                                                                          snapshot: DraggableStateSnapshot,
                                                                        ) => {
                                                                          return (
                                                                            <TableRow
                                                                              ref={
                                                                                draggableProvided.innerRef
                                                                              }
                                                                              {...draggableProvided.draggableProps}
                                                                              style={{
                                                                                ...draggableProvided
                                                                                  .draggableProps
                                                                                  .style,
                                                                                background:
                                                                                  snapshot.isDragging
                                                                                    ? 'rgba(245,245,245, 0.75)'
                                                                                    : 'none',
                                                                              }}
                                                                              sx={{
                                                                                '&:last-child td, &:last-child th':
                                                                                  {
                                                                                    border:
                                                                                      '0px !important',
                                                                                  },
                                                                              }}>
                                                                              <TableCell>
                                                                                <div
                                                                                  {...draggableProvided.dragHandleProps}>
                                                                                  <OpenWithIcon />
                                                                                </div>
                                                                              </TableCell>
                                                                              <TableCell>
                                                                                {Number(
                                                                                  siIndex,
                                                                                ) +
                                                                                  1}
                                                                              </TableCell>
                                                                              <TableCell>
                                                                                {
                                                                                  subIssues.sub_issue_title
                                                                                }
                                                                              </TableCell>
                                                                              <TableCell>
                                                                                <Typography
                                                                                  component={
                                                                                    'div'
                                                                                  }
                                                                                  className="hideshow">
                                                                                  {
                                                                                    subIssues.sub_issue_text
                                                                                  }
                                                                                </Typography>
                                                                              </TableCell>
                                                                              <TableCell>
                                                                                <div
                                                                                  className="pointer"
                                                                                  onClick={() => {
                                                                                    if (
                                                                                      subIssues.sub_issue_response &&
                                                                                      subIssues
                                                                                        .sub_issue_response
                                                                                        .length >=
                                                                                        50
                                                                                    ) {
                                                                                      setShowResponse(
                                                                                        {
                                                                                          content:
                                                                                            subIssues.sub_issue_response,
                                                                                          open: true,
                                                                                        },
                                                                                      );
                                                                                    }
                                                                                  }}
                                                                                  dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                      subIssues.sub_issue_response
                                                                                        ? subIssues
                                                                                            .sub_issue_response
                                                                                            .length >=
                                                                                          50
                                                                                          ? subIssues.sub_issue_response
                                                                                              .slice(
                                                                                                0,
                                                                                                50,
                                                                                              )
                                                                                              .concat(
                                                                                                '...',
                                                                                              )
                                                                                          : subIssues.sub_issue_response
                                                                                        : '-',
                                                                                  }}
                                                                                />
                                                                              </TableCell>
                                                                              <TableCell>
                                                                                <div
                                                                                  className={
                                                                                    subIssues.sub_issue_status ===
                                                                                    'pending'
                                                                                      ? 'pending'
                                                                                      : 'accept'
                                                                                  }>
                                                                                  {capitalize(
                                                                                    subIssues.sub_issue_status,
                                                                                  )}
                                                                                </div>
                                                                              </TableCell>
                                                                            </TableRow>
                                                                          );
                                                                        }}
                                                                      </Draggable>
                                                                    </React.Fragment>
                                                                  ),
                                                                )}
                                                              </TableBody>
                                                            )}
                                                          </Droppable>
                                                        </DragDropContext>
                                                      )}
                                                    </Table>
                                                  </TableContainer>
                                                </Typography>
                                              </Typography>
                                            </TableCell>
                                          </TableRow>
                                        ) : null}
                                      </React.Fragment>
                                    ))}
                                  </TableBody>
                                )}
                              </Droppable>
                            </DragDropContext>
                          )}
                        </Table>
                      </TableContainer>
                    </Typography>
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography
            className="note-box2"
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}>
            <Typography className="inb2" />
            <Typography className="black-white-text ctitle hideshow">
              No Record(s) Found.
            </Typography>
          </Typography>
        )}
      </Typography>
      {showResponse.open && (
        <Modal
          open={showResponse.open}
          onClose={() => {
            setShowResponse({
              content: '',
              open: false,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className="success-modal custom-modal pd">
            <Typography className="f-18 s-title info-message-text">
              <div
                dangerouslySetInnerHTML={{
                  __html: showResponse.content,
                }}
              />
            </Typography>
            <Typography component={'div'} className="d-flex-ja mt-2 ">
              <Button
                className="n-add-btn"
                sx={{
                  borderRadius: '5px !important',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '0.9em !important',
                  height: '35px !important',
                  width: '100px !important',
                }}
                onClick={() =>
                  setShowResponse({
                    content: '',
                    open: false,
                  })
                }
                autoFocus>
                Ok
              </Button>
            </Typography>
          </Box>
        </Modal>
      )}
    </Typography>
  );
};
