import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import { AttorneyPrivateRoutes } from 'config';
import _ from 'lodash';
import { WizardForm } from 'pages/private/Common/wizardForm';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { ContratBuildSchema } from 'utils/ValidatorSchema';

import ContactFeeTable from './ContactFeeTable';

export const ContractAddEdit: React.FC<any> = () => {
  const { editId, contractId, userId, contractType } = useParams();
  const location: any = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [loader, setLoader] = useState(true);
  const [visaTypes, setVisaTypes] = useState<any>([]);
  const [contractList, setContractList] = useState<any>([]);
  const [contractFeesList, setContractFeesList] = useState<any>([]);
  const [formData, setFormData] = useState<any>([]);
  const [selectedContractType, setSelectedContractType] = useState<any>('');
  // const [formType, setFormType] = useState('configureForms');
  const [formId, setFormId] = useState(null);
  const [isVisaChangedForFees, setIsVisaChangedForFees] = useState(false);

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      contractType: '',
      visaType: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ContratBuildSchema),
  });

  const getContractConfigureForms = (contractForm, changeOnly?) => {
    if (!_.isEmpty(contractForm)) {
      setFormData([]);
      setFormId(contractForm.id);
      if (formId || contractForm.id) {
        getConfigureFormData('', contractForm);
      }
      if (changeOnly) {
        contractFormIdSave(contractForm.id, 'contract');
      }
    }
  };

  const contractFormIdSave = (formId, type) => {
    const id =
      contractType.toLowerCase() === 'petitioner'
        ? 2
        : contractType.toLowerCase() === 'affilate'
        ? 5
        : 4;
    updateData(
      '',
      {},
      `${COMMON_ENDPOINT.Contract}/mapform/${contractId}?formId=${String(
        formId,
      )}&approveFor=${id}&type=${String(type)}`,
    );
  };
  const getConfigureFormData = (link?, contractSelectedFor?) => {
    if (
      !selectedContractType &&
      !contractSelectedFor?.id &&
      !location.state?.selectedContract.contractformid
    ) {
      return false;
    }
    getTreeView(
      contractSelectedFor?.id
        ? contractSelectedFor.id
        : selectedContractType
        ? selectedContractType
        : location.state?.selectedContract.contractformid,
      null,
      location.state?.selectedContract.id,
      location.state?.selectedContract.visaselectedid
        ? location.state?.selectedContract.visaselectedid
        : null,
      null,
      location.state?.selectedContract.organizationId,
      userDetail.companyId,
      location.state?.selectedContract.attorney_contact_id,
      Number(editId),
      contractType.toLowerCase() === 'petitioner'
        ? 2
        : contractType.toLowerCase() === 'affilate'
        ? 5
        : 4,
      null,
      location.state?.selectedContract.petitionerAssociated === undefined
        ? null
        : location.state?.selectedContract.petitionerAssociated,
      Number(userId),
      'contract',
      contractSelectedFor && contractSelectedFor.contractType
        ? contractSelectedFor.contractType
        : null,
    ).then((res) => {
      setFormData(res);
      if (link) {
        setTimeout(() => {
          const focuselement = document.getElementById(link);
          if (focuselement) {
            focuselement.scrollIntoView();
            focuselement.setAttribute(
              'style',
              'background-color: #f6f6f6;display: inline;',
            );
          }
        }, 1000);
      }
      setContractFeesList(
        res[0].extraContractQuestion.length > 0
          ? res[0].extraContractQuestion
          : [],
      );
    });
  };

  const getVisaTypes = (id, changeOnly?, manuallyChange?) => {
    location.state.selectedContract.visaselectedid = id;
    setFormData([]);
    getConfigureFormData();
    if (changeOnly) {
      // setVisaChanged(manuallyChange ? id : '');
      setIsVisaChangedForFees(true);
      contractFormIdSave(id, 'visa');
    }
  };

  const getTreeView = async (
    id: number,
    petitionId?: number,
    beneficiaryId?: number,
    visaId?: number,
    petitionerUserId?: number,
    orgId?: number,
    firmId?: number,
    caseUserId?: number,
    leadId?: number,
    leadFor?: number,
    dependentPersonId?: number,
    petitionerAssociated?: boolean,
    leaduserId?: number,
    newUrlType?: string,
    contractSelectedFor?: number,
  ) => {
    const checkUrl = newUrlType
      ? '/petition-form/'
      : '/admin/setup/forms/tree/';

    let url = `${checkUrl}${id}?`;
    if (petitionId) {
      url = `${url}petitionId=${petitionId}&`;
    }
    if (beneficiaryId) {
      url = `${url}beneficiaryId=${beneficiaryId}&`;
    }
    if (visaId) {
      url = `${url}visaId=${visaId}&`;
    }
    if (petitionerUserId) {
      url = `${url}petitionerUser=${petitionerUserId}&`;
    }
    if (orgId) {
      url = `${url}organizationId=${orgId}&`;
    }
    if (firmId) {
      url = `${url}firmId=${firmId}&`;
    }
    if (caseUserId) {
      url = `${url}attorneyUser=${caseUserId}&`;
    }
    if (leadId) {
      url = `${url}leadId=${leadId}&`;
    }
    if (leadFor) {
      url = `${url}leadFor=${leadFor}&`;
    }
    if (dependentPersonId) {
      url = `${url}dependentUserId=${dependentPersonId}&`;
    }
    if (leaduserId) {
      url = `${url}leadUserId=${leaduserId}&`;
    }
    if (petitionerAssociated === true || petitionerAssociated === false) {
      url = `${url}associated=${String(petitionerAssociated)}&`;
    }
    if (newUrlType) {
      url = `${url}formType=${newUrlType}&`;
    }
    if (contractSelectedFor) {
      url = `${url}contractSelectedFor=${contractSelectedFor}`;

      if (contractType.toLowerCase() === 'affilate') {
        url = `${url}&isAffilate=true`;
      }
    }
    return await getAllListData(url);
  };

  useEffect(() => {
    const winningStrategyCheck = location.state?.winningStrategy;
    if (winningStrategyCheck) {
      const winningStrategyFormId: any = editId;
      const link: any = window.location.href;
      setFormId(Number(winningStrategyFormId));
      // setFormType('winning');
      getConfigureFormData(link, null);
      return;
    }

    setValue('visaType', location.state?.selectedContract.visaselectedid || '');

    location.state?.selectedContract?.visaApiCall &&
      contractFormIdSave(
        location.state?.selectedContract?.visaselectedid,
        'visa',
      );
    if (
      location.state?.selectedContract &&
      location.state?.selectedContract.contractformid !== ''
    ) {
      setValue(
        'contractType',
        location.state?.selectedContract.contractformid || '',
      );

      getContractConfigureForms({
        contractType:
          contractType.toLowerCase() === 'petitioner'
            ? 2
            : contractType.toLowerCase() === 'affilate'
            ? 5
            : 4,
        id: location.state?.selectedContract.contractformid,
      });
    }
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`)
      .then((resp: any) => {
        setVisaTypes(resp.data);
      })
      .catch((e: any) => {
        setLoader(false);
      });
    getAllListData(
      `${SETUP_ENDPOINT.ConfigSection}?id=&name=&sectiontype=contract&status=true&majorsection=&skip=&take=&order=ASC&sortby=section_name&attorney=true`,
    )
      .then((cResp: any) => {
        setContractList(cResp.data);
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sx={{ paddingRight: '1rem' }}>
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <Typography component={'div'}>
            {location.state && location.state?.contractName !== '' && (
              <Typography
                component={'div'}
                className="d-flex-a d-flex-sb sticky-tab mb-1">
                <Typography
                  component={'div'}
                  className="black-white-text"
                  sx={{
                    fontFamily: 'Arial-Bold !important',
                    fontSize: '1.25em !important',
                  }}>
                  Contract: {location.state?.contractName}
                </Typography>
                <Button
                  className="cancel-btn"
                  sx={{
                    borderRadius: '5px !important',
                    fontFamily: 'Arial-Regular !important',
                    fontSize: '0.9rem !important',
                    // height: '35px !important',
                    width: '120px !important',
                  }}
                  onClick={() => {
                    navigate(
                      `${
                        AttorneyPrivateRoutes.LEADS.CONTRACTPAYMENTDETAILS
                      }/${String(editId)}/${String(contractId)}/${String(
                        userId,
                      )}/${String(contractType)}${
                        searchParams.get('orgId')
                          ? '?orgId=' + String(searchParams.get('orgId'))
                          : ''
                      }${
                        searchParams.get('invited')
                          ? '&invited=true'
                          : searchParams.get('associated')
                          ? '&associated=true'
                          : ''
                      }${
                        searchParams.get('isLead')
                          ? '&isLead=true'
                          : searchParams.get('isPetition')
                          ? '&isPetition=true'
                          : ''
                      }`,
                      {
                        state: {
                          contractName: location.state?.contractName,
                          id: location.state?.id,
                          isLead: location.state?.isLead,
                          isOrg: searchParams.get('orgId') ? true : false,
                        },
                      },
                    );
                  }}>
                  <Typography
                    className="o-box d-flex-a"
                    sx={{ width: 'unset !important' }}
                  />
                  <ArrowBackIcon sx={{ marginRight: '.5rem' }} />
                  Back
                </Button>
              </Typography>
            )}
            <form id="contract-build-form">
              <Grid container>
                <Grid item md={12}>
                  <Box
                    component="form"
                    className="lead-box mt-1"
                    sx={{
                      border: 'unset',
                      borderBottom: '1px solid lightgrey',
                      padding: '15px 35px',
                    }}
                    noValidate
                    autoComplete="off">
                    <Grid container>
                      <Grid item md={6} sm={6}>
                        <Typography
                          component={'div'}
                          className="custom-field-row u-pr-1"
                          style={{ marginBottom: '0px !important' }}>
                          <Controller
                            control={control}
                            defaultValue={''}
                            name={'contractType'}
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomSelect
                                  labelKey={'section_name'}
                                  valueKey={'id'}
                                  options={
                                    contractType.toLowerCase() === 'affilate'
                                      ? [
                                          {
                                            id: 42,
                                            section_name: 'Individual Contract',
                                          },
                                        ]
                                      : !_.isEmpty(contractList)
                                      ? contractList
                                      : []
                                  }
                                  name={name}
                                  value={value}
                                  isClearable={false}
                                  onChange={(e) => {
                                    onChange(e);
                                    setSelectedContractType(e?.target?.value);
                                    getContractConfigureForms(
                                      {
                                        contractType:
                                          contractType.toLowerCase() ===
                                          'affilate'
                                            ? 5
                                            : contractList.find(
                                                (contract) =>
                                                  contract.id ===
                                                  e.target.value,
                                              )?.contractType,
                                        id: e.target.value,
                                      },
                                      'change',
                                    );
                                  }}
                                  placeHolder={'Select'}
                                  className={'custom-input'}
                                  error={errors.contractType?.message}
                                  searchable={true}
                                />
                              );
                            }}
                          />
                          <Typography
                            component={'div'}
                            className="custom-label"
                            sx={{ margin: '.5rem 0 0 0 !important' }}>
                            Contract Type <span className="required">*</span>
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={6}>
                        <Typography
                          component={'div'}
                          className="custom-field-row u-pr-1"
                          style={{ marginBottom: '0px !important' }}>
                          <Controller
                            control={control}
                            defaultValue=""
                            name={'visaType'}
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomSelect
                                  labelKey={'visa_code'}
                                  valueKey={'visa_id'}
                                  options={
                                    !_.isEmpty(visaTypes) ? visaTypes : []
                                  }
                                  name={name}
                                  value={value}
                                  isClearable={false}
                                  onChange={(e) => {
                                    onChange(e);
                                    getVisaTypes(
                                      e.target.value,
                                      'change',
                                      'manualChange',
                                    );
                                  }}
                                  placeHolder={'Select'}
                                  className={'custom-input'}
                                  error={errors.visaType?.message}
                                  searchable={true}
                                />
                              );
                            }}
                          />
                          <Typography
                            component={'div'}
                            className="custom-label"
                            sx={{ margin: '.5rem 0 0 0 !important' }}>
                            Visa Type{' '}
                            <Typography
                              component={'span'}
                              className="Mui-error">
                              *
                            </Typography>
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <WizardForm
                formType={
                  location.state?.winningStrategy ? 'winning' : 'contract'
                }
                list={formData}
                tabHidden={true}
                formId={formId}
                beneficiaryID={location.state?.selectedContract.id}
                leadId={
                  location.state?.selectedContract.id ? null : Number(editId)
                }
                contractFor={
                  contractType.toLowerCase() === 'petitioner'
                    ? 2
                    : contractType.toLowerCase() === 'affilate'
                    ? 5
                    : 4
                }
              />

              <ContactFeeTable
                contractId={contractId}
                contractType={
                  contractType.toLowerCase() === 'petitioner'
                    ? 2
                    : contractType.toLowerCase() === 'affilate'
                    ? 5
                    : 4
                }
                lists={contractFeesList}
                setList={setContractFeesList}
                isVisaChanged={isVisaChangedForFees}
              />
            </form>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ContractAddEdit;
