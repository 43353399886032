import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomInput from 'components/CustomInput/customInput';
// import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import useStyles from '../../../PrivateStyle';

type QuestionProps = {
  qData?: any;
  setQuestionData?: any;
  setConfirmOpen?: any;
  open?: any;
  editId?: string;
  cancelForm?: any;
};

const QuestionMapping: FC<QuestionProps> = ({
  qData,
  setQuestionData,
  setConfirmOpen,
  open,
  editId,
  cancelForm,
}) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [questionParams, setQuestionParams] = useState<any>({
    id: '',
    linkCode: '',
    question: '',
    responseType: '',
    skip: 0,
    status: true,
    take: 50,
  });

  const debouncedParams = useDebounce(questionParams, 500);

  const [questions, setQuestions] = useState<any>([]);
  const [responseTypes, setResponseTypes] = useState<any>([]);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
    getApiData();
  };
  const handleClose = () => {
    setIsOpen(false);
    setQuestionParams((pre) => ({
      ...pre,
      linkCode: '',
      question: '',
      responseType: '',
      skip: 0,
      take: 50,
    }));
    setQuestionParams((pre) => ({
      ...pre,
      data: [],
      total: 0,
    }));
  };

  const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    // const temp = [...qData];
    // const d = temp[result.destination!.index];
    // temp[result.destination!.index] = temp[result.source.index];
    // temp[result.source.index] = d;
    const temp = [...qData];
    const obj = temp[result.source.index];
    temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, obj);

    setQuestionData(temp);
    const questionIds = temp.map((val) => val.id);

    updateData(
      editId,
      { questions: questionIds },
      `${SETUP_ENDPOINT.ConfigSection}/order`,
    )
      .then((resp: any) => {})
      .catch((e) => {});
  };

  const onUpdateOrRemoveQuestion = (remove = false, question?: any) => {
    updateData(
      editId,
      { questionId: remove ? Number(open.id) : question.id },
      `${SETUP_ENDPOINT.ConfigSection}/question`,
    )
      .then((resp: any) => {
        if (remove && _.isEmpty(question)) {
          qData.splice(open.index, 1);
        } else {
          qData.push({
            id: question.id,
            link_code: question.link_code,
            question: question.question,
            type: question.responseTypeValue,
          });
          handleClose();
        }
        setConfirmOpen({
          id: null,
          index: null,
          show: false,
        });
      })
      .catch((e) => {});
  };

  const checkQuestionExisting = (id: string) => {
    if (qData.length) {
      return qData.some((val) => val.id === id);
    }
  };

  const selectTheQuestion = (qustion: any) => {
    if (qustion && qustion.id) {
      const checkDuplicate = checkQuestionExisting(qustion.id);
      if (!checkDuplicate) {
        onUpdateOrRemoveQuestion(false, qustion);
        handleClose();
      } else {
        toast.error('This question already mapped.', alertOptions);
      }
    }
  };

  const getApiData = () => {
    getAllListData(
      `${SETUP_ENDPOINT.ConfigQuestion}?id=${String(
        debouncedParams.id,
      )}&skip=${String(debouncedParams.skip)}&take=${String(
        debouncedParams.take,
      )}&question=${String(debouncedParams.question)}&responseType=${String(
        debouncedParams.responseType,
      )}&linkCode=${String(debouncedParams.linkCode)}&status=${String(
        debouncedParams.status,
      )}`,
    )
      .then(async (qResp: any) => {
        const { data, total } = qResp ? qResp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setQuestions((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          const mergeData = !_.isEmpty(questions)
            ? [...questions.data, ...qResp.data]
            : qResp.data;
          setQuestions({
            count: qResp.total,
            data: mergeData,
          });
          setQuestions((pre) => ({
            ...pre,
            data: qResp.data,
          }));
        }
        setTableLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setQuestions);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setGetMoreLoader(false);
        setHasMoreResults(false);
      });
  };
  useEffect(() => {
    if (isOpen) {
      getApiData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);
  const getMoreList = () => {
    if (isOpen && !tableLoader) {
      setGetMoreLoader(true);
      setQuestionParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };
  useEffect(() => {
    if (isOpen) {
      setLoader(true);
      getAllListData(`${COMMON_ENDPOINT.ResponseTypes}/responsetypes`)
        .then((typeResp: any) => {
          const typeOptions = [...typeResp.options];

          setResponseTypes(typeOptions);
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
  }, [isOpen]);

  return (
    <Grid container>
      <Grid item md={12} sm={12}>
        <Typography component={'div'} className="d-flex-a d-flex-sb">
          <Typography component={'div'} className="w-title black-white-text">
            Question Mapping
          </Typography>
          <Button
            type="button"
            className="n-add-btn mt-2 mb-2"
            onClick={handleOpen}>
            Add Question
          </Button>
        </Typography>

        <Typography component={'div'}>
          <TableContainer>
            <Typography
              component={'div'}
              sx={{
                borderRadius: '8px 8px 0 0',
              }}
              className={classes.scrollbox}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="lead-table table-hover"
                style={{ minHeight: '106px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>NO</TableCell>
                    <TableCell>QUESTION</TableCell>
                    <TableCell>LINK CODE</TableCell>
                    <TableCell>QUESTION TYPE</TableCell>
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: DroppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}>
                        {qData.length > 0 ? (
                          qData.map((questionData, index) => (
                            <Fragment key={index}>
                              <Draggable
                                key={questionData.id}
                                draggableId={String(questionData.id)}
                                index={index}>
                                {(
                                  draggableProvided: DraggableProvided,
                                  snapshot: DraggableStateSnapshot,
                                ) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps
                                          .style,
                                        background: snapshot.isDragging
                                          ? 'rgba(0,0,0,.075)'
                                          : '',
                                        cursor: 'pointer',
                                      }}>
                                      <TableCell>
                                        <div
                                          {...draggableProvided.dragHandleProps}>
                                          <OpenWithIcon />
                                        </div>
                                      </TableCell>
                                      <TableCell>{Number(index) + 1}</TableCell>
                                      <TableCell>
                                        <Typography
                                          component={'div'}
                                          className="hideshow">
                                          {questionData.question}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          component={'div'}
                                          className="hideshow">
                                          {questionData.link_code}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          component={'div'}
                                          className="hideshow">
                                          {questionData.type}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <DeleteForeverIcon
                                          sx={{
                                            cursor: 'pointer',
                                            fill: 'red',
                                            height: '25px',
                                            width: '25px',
                                          }}
                                          onClick={() =>
                                            setConfirmOpen({
                                              id: String(questionData.id),
                                              index,
                                              show: true,
                                            })
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                }}
                              </Draggable>
                            </Fragment>
                          ))
                        ) : (
                          <tr className={'text-center'}>
                            <th scope="row" colSpan={6}>
                              No Record(s) Found.
                            </th>
                          </tr>
                        )}
                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </Typography>
          </TableContainer>
        </Typography>

        <ConfirmDialog
          title="DELETE"
          floatingBtnShow={true}
          open={open.show}
          onConfirm={(act) =>
            act === 'yes'
              ? onUpdateOrRemoveQuestion(true, {})
              : setConfirmOpen({
                  id: null,
                  index: null,
                  show: false,
                })
          }
          autoCloseDisable={true}>
          Are you sure do you want to delete?
        </ConfirmDialog>
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className={classes.newmodal}>
            <Grid container md={12}>
              <Grid md={2} style={{ alignSelf: 'center' }}>
                <Typography className="f-20 font-bold modal-title mr-1">
                  Add Question
                </Typography>
              </Grid>
              <Grid md={9}>
                <Typography
                  component={'div'}
                  className="d-flex-jae full-screen-searc mb-2 mt-2">
                  <Typography
                    component={'div'}
                    className="d-flex-a"
                    sx={{
                      // backgroundColor: '#ffffff',
                      borderRadius: '4px',
                    }}>
                    <Typography
                      className="d-flex-a"
                      component={'div'}
                      sx={{
                        backgroundColor: '#84898c',
                        borderRadius: '4px',
                        boxSizing: 'unset',
                        marginRight: '.5rem',
                        width: '205px',
                      }}>
                      <CustomInput
                        placeHolder={'Search for question'}
                        className={'custom-input'}
                        size="small"
                        value={questionParams.question}
                        inputProps={{
                          endAdornment:
                            questionParams.question !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    setTableLoader(true);

                                    setQuestionParams((prev) => ({
                                      ...prev,
                                      question: '',
                                      skip: 0,
                                      take: 50,
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          setTableLoader(true);

                          setQuestionParams((prev) => ({
                            ...prev,
                            question: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                      />
                    </Typography>
                    <Typography
                      sx={{
                        backgroundColor: '#84898c !important',
                        borderRadius: '4px',
                        marginRight: '.5rem',
                        paddingLeft: 'unset !important',
                        width: '205px',
                      }}>
                      <CustomSelect
                        extraStyle={{ color: '#84898c !important' }}
                        labelKey={'type'}
                        valueKey={'id'}
                        options={!_.isEmpty(responseTypes) ? responseTypes : []}
                        onChange={(e) => {
                          setTableLoader(true);

                          setQuestionParams((prev) => ({
                            ...prev,
                            responseType: e.target.value,
                          }));
                        }}
                        placeHolder={'Select for response type'}
                        className={'custom-input'}
                      />
                    </Typography>
                    <Typography
                      sx={{
                        backgroundColor: '#84898c',
                        borderRadius: '4px',
                        marginRight: '.5rem',
                        width: '205px',
                      }}>
                      <CustomInput
                        size="small"
                        placeHolder={'Search for link code'}
                        className={'custom-input'}
                        value={questionParams.linkCode}
                        inputProps={{
                          endAdornment:
                            questionParams.linkCode !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    setTableLoader(true);

                                    setQuestionParams((prev) => ({
                                      ...prev,
                                      linkCode: '',
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          setTableLoader(true);

                          setQuestionParams((prev) => ({
                            ...prev,
                            linkCode: e.target.value,
                          }));
                        }}
                      />
                    </Typography>
                  </Typography>
                </Typography>
              </Grid>
              <Grid md={1}>
                <Typography component={'div'} className="d-flex-jae mt-1">
                  <CancelIcon
                    sx={{
                      cursor: 'pointer',
                      fill: 'gray',
                      height: '44px',
                      width: '44px',
                    }}
                    onClick={() => handleClose()}
                  />
                </Typography>
              </Grid>
            </Grid>
            {loader ? (
              <div className="liberty-loader" />
            ) : (
              <DialogContent
                id="scrollParent"
                style={{ height: '85%', padding: '0px' }}>
                <InfiniteScroll
                  dataLength={
                    questions && questions.data && questions.data.length
                      ? questions.data.length
                      : 0
                  }
                  style={{
                    overflow: 'unset',
                  }}
                  next={getMoreList && getMoreList}
                  hasMore={hasMoreResults}
                  scrollableTarget={'scrollParent'}
                  loader={
                    getMoreLoader && (
                      <div
                        style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          width: '100%',
                        }}>
                        <CircularProgress
                          size={30}
                          sx={{
                            color: '#002c69',
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        />
                      </div>
                    )
                  }
                  endMessage>
                  <table className="lead-table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">QUEST.ID</th>
                        <th scope="col">QUESTIONS</th>
                        <th scope="col">RESPONSE TYPE</th>
                        <th scope="col">RESPONSES</th>
                        <th scope="col">LINK CODE</th>
                        <th scope="col">STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableLoader ? (
                        <tr>
                          <td colSpan={8}>
                            <div
                              style={{
                                backgroundColor: '#ffffff',
                                textAlign: 'center',
                                width: '100%',
                              }}>
                              <CircularProgress
                                size={30}
                                sx={{
                                  color: '#002c69',
                                  marginBottom: 10,
                                  marginTop: 10,
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      ) : (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                          {questions?.data?.length > 0
                            ? questions?.data.map((question, index) => (
                                <tr
                                  key={index}
                                  onClick={() => selectTheQuestion(question)}>
                                  <th scope="row">{question.id}</th>
                                  <td>
                                    <a
                                      className="multiline"
                                      style={{ color: '#1093df' }}>
                                      {question.question}
                                    </a>
                                  </td>
                                  <td>{question.responseTypeValue}</td>
                                  <td>
                                    <div
                                      className="hideshow"
                                      style={{
                                        width: '255px',
                                        wordBreak: 'break-all',
                                      }}>
                                      {question?.response?.length > 0
                                        ? question.response.map((resp) => resp)
                                        : ''}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className="hideshow"
                                      style={{
                                        width: '255px',
                                        wordBreak: 'break-all',
                                      }}>
                                      {question.link_code}
                                    </div>
                                  </td>
                                  <td>
                                    {question.status ? 'Active' : 'Inactive'}
                                  </td>
                                </tr>
                              ))
                            : !tableLoader && (
                                <tr className={'text-center'}>
                                  <th scope="row" colSpan={6}>
                                    No Record(s) Found.
                                  </th>
                                </tr>
                              )}
                        </>
                      )}
                    </tbody>
                  </table>
                </InfiniteScroll>
                {/* </Typography> */}
              </DialogContent>
            )}
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default QuestionMapping;
