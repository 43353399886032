import { Tooltip, Typography } from '@mui/material';
import { Counting } from 'components/Counting';
import React, { useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { kFormatter } from 'utils/helper';

type ListPropsTypes = {
  dashboardCounts: any;
};

const LeadsAndKPIs: React.FC<any> = (props: ListPropsTypes) => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const { dashboardCounts } = props;

  const [kpi, setKPI] = useState('sales');

  const displayLeads = (
    label: string,
    leadValues: string | number,
    value?: any,
  ) => {
    return typeof leadValues === 'string' ? (
      <Tooltip title={'$'.concat(value)} placement="top">
        <Typography component={'div'} className="total-item">
          <Typography component={'div'} className="t-inner-box" />
          <Typography className="t-title">{label}</Typography>
          <Typography className="t-count">{leadValues}</Typography>
        </Typography>
      </Tooltip>
    ) : (
      <Typography component={'div'} className="total-item">
        <Typography component={'div'} className="t-inner-box" />
        <Typography className="t-title">{label}</Typography>
        <Counting end={leadValues} />
      </Typography>
    );
  };

  return (
    <Typography component={'div'}>
      {userDetail.role_id === 3 && (
        <Typography component={'div'} className="total-box">
          {displayLeads(
            'Started Leads',
            dashboardCounts.startedLeads
              ? kFormatter(dashboardCounts.startedLeads)
              : 0,
          )}
          {displayLeads(
            'Leads In Progress',
            dashboardCounts.leadInProgress
              ? kFormatter(dashboardCounts.leadInProgress)
              : 0,
          )}
          {displayLeads(
            'Abandoned Leads',
            dashboardCounts.abondedLeads
              ? kFormatter(dashboardCounts.abondedLeads)
              : 0,
          )}
          {displayLeads(
            'Approved Leads',
            dashboardCounts.approvedPetitions
              ? kFormatter(dashboardCounts.approvedPetitions)
              : 0,
          )}
          {displayLeads(
            'Denied Leads',
            dashboardCounts.rejectedPetitions
              ? kFormatter(dashboardCounts.rejectedPetitions)
              : 0,
          )}
        </Typography>
      )}
      <Typography component={'div'}>
        <Typography className="c-title black-white-text">
          Key Performance Indicators
          {userDetail.role_id === 3 && (
            <select
              className="kpi-select"
              onChange={(event: any) => setKPI(event.target.value)}
              value={kpi}>
              <option value="sales">Sales</option>
              <option value="production">Production</option>
            </select>
          )}
        </Typography>
        {userDetail.role_id === 2 && (
          <Typography component={'div'} className="total-box">
            {displayLeads(
              'Leads',
              dashboardCounts.startedLeads
                ? kFormatter(dashboardCounts.startedLeads)
                : 0,
            )}

            {displayLeads(
              'Petitions',
              dashboardCounts.noOfPetitions
                ? kFormatter(dashboardCounts.noOfPetitions)
                : 0,
            )}

            {displayLeads(
              'Submitted',
              dashboardCounts.submittedPetitions
                ? kFormatter(dashboardCounts.submittedPetitions)
                : 0,
              dashboardCounts.contractValue,
            )}

            {displayLeads(
              'Approved',
              dashboardCounts.approvedPetitions
                ? kFormatter(dashboardCounts.approvedPetitions)
                : 0,
            )}

            {displayLeads(
              'Denied',
              0,
              // dashboardCounts.rejectedPetitions
              //   ? kFormatter(dashboardCounts.rejectedPetitions)
              //   : 0,
            )}

            {displayLeads(
              'Approval %',
              dashboardCounts.approvedPercentage
                ? kFormatter(dashboardCounts.approvedPercentage)
                : 0,
            )}

            {displayLeads(
              'RFES',
              dashboardCounts.rfePetitions
                ? kFormatter(dashboardCounts.rfePetitions)
                : 0,
            )}
            {displayLeads(
              'RFES Submitted',
              dashboardCounts.rfeSubmittedPetitions
                ? kFormatter(dashboardCounts.rfeSubmittedPetitions)
                : 0,
            )}
            {displayLeads(
              'RFES %',
              dashboardCounts.rfePercentage
                ? kFormatter(dashboardCounts.rfePercentage)
                : 0,
            )}
          </Typography>
        )}
        {userDetail.role_id === 3 && kpi === 'sales' ? (
          <Typography component={'div'} className="total-box">
            {displayLeads(
              'Strategy Sessions',
              dashboardCounts.noOfStrategySession
                ? kFormatter(dashboardCounts.noOfStrategySession)
                : 0,
            )}

            {displayLeads(
              'Contract Sent',
              dashboardCounts.noOFContractSent
                ? kFormatter(dashboardCounts.noOFContractSent)
                : 0,
            )}

            {displayLeads(
              'Contract Value',
              dashboardCounts.contractValue
                ? kFormatter(dashboardCounts.contractValue)
                : 0,
              dashboardCounts.contractValue,
            )}

            {displayLeads(
              'New Clients',
              dashboardCounts.noOfNewClients
                ? kFormatter(dashboardCounts.noOfNewClients)
                : 0,
            )}

            {displayLeads(
              'Individuals',
              dashboardCounts.noOfIndividuals
                ? kFormatter(dashboardCounts.noOfIndividuals)
                : 0,
            )}

            {displayLeads(
              'Enterprises',
              dashboardCounts.noOfEnterPrise
                ? kFormatter(dashboardCounts.noOfEnterPrise)
                : 0,
            )}

            {displayLeads(
              'Petitions',
              dashboardCounts.noOfPetitions
                ? kFormatter(dashboardCounts.noOfPetitions)
                : 0,
            )}
          </Typography>
        ) : (
          userDetail.role_id === 3 && (
            <Typography component={'div'} className="total-box">
              {displayLeads(
                'Open Cases',
                dashboardCounts.kpiLeads
                  ? kFormatter(dashboardCounts.kpiLeads)
                  : 0,
              )}

              {displayLeads(
                'Visas Submitted',
                dashboardCounts.submittedPetitions
                  ? kFormatter(dashboardCounts.submittedPetitions)
                  : 0,
              )}

              {displayLeads(
                'Visas Denied',
                dashboardCounts.rejectedPetitions
                  ? kFormatter(dashboardCounts.rejectedPetitions)
                  : 0,
              )}

              {displayLeads(
                'Approval %',
                dashboardCounts.approvedPercentage
                  ? kFormatter(dashboardCounts.approvedPercentage)
                  : 0,
              )}

              {displayLeads(
                'RFES',
                dashboardCounts.rfePetitions
                  ? kFormatter(dashboardCounts.rfePetitions)
                  : 0,
              )}

              {displayLeads(
                'RFES Submitted',
                dashboardCounts.rfeSubmittedPetitions
                  ? kFormatter(dashboardCounts.rfeSubmittedPetitions)
                  : 0,
              )}

              {displayLeads(
                'RFE %',
                dashboardCounts.rfePercentage
                  ? kFormatter(dashboardCounts.rfePercentage)
                  : 0,
              )}
            </Typography>
          )
        )}
      </Typography>
    </Typography>
  );
};

export default LeadsAndKPIs;
