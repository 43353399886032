import { CircularProgress, DialogContent } from '@mui/material';
import CustomModalView from 'components/CustomModalView/customModalView';
import { instantState, useDebounce } from 'hooks';
// import Pagination from 'components/Pagination';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';

// import { alertOptions } from 'utils/helper';
import DocumentSectionList from './DocumentSectionList';

type Props = {
  sectionType?: string;
  searchValue?: string;
  onSelect?: (src: any) => void;
  petitionData: any;
  setCopyDocumentSection?: any;
  getList?: any;
  isAffilateCase?: any;
  affilateRoleId?: any;
  attorneyAssigned?: any;
  AttorneyQueryParam?: any;
  showAllQueryParam?: any;
  visasNameShown?: boolean;
  visaSearch?: any;
};
export const SectionList: React.FC<Props> = (props: Props) => {
  const {
    sectionType = '',
    searchValue = '',
    onSelect,
    petitionData = '',
    setCopyDocumentSection,
    getList,
    isAffilateCase,
    affilateRoleId,
    attorneyAssigned,
    AttorneyQueryParam,
    showAllQueryParam,
    visasNameShown = false,
    visaSearch = '',
  } = props;
  const [sections, setSections] = useState({ data: [], total: 0 });
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [documenSectionInfo, setDocumenSectionInfo] = useState<{
    open: boolean;
    selectedData: any | null;
  }>({
    open: false,
    selectedData: [],
  });
  const [loader, setLoader] = useState(true);
  const [params, setParams] = useState<{
    attorney: boolean;
    skip: number;
    take: number;
    sortby: string;
    order: string;
    status: boolean;
    sectiontype: string;
    name: string;
    majorsection: string;
    petitionerId: any;
    petitionerAssociated: any;
    showAll?: boolean;
    visaId: string;
  }>({
    attorney: AttorneyQueryParam ? AttorneyQueryParam : false,
    majorsection: '',
    name: '',
    order: 'ASC',
    petitionerAssociated: petitionData?.petitioner_associated
      ? petitionData?.petitioner_associated
      : false,
    petitionerId: petitionData?.organization_id
      ? petitionData?.organization_id
      : '',
    sectiontype: sectionType,
    skip: 0,
    sortby: 'section_name',
    status: true,
    take: 50,
    visaId: '',
    ...(showAllQueryParam ? { showAll: true } : {}),
  });
  const debouncedParams = useDebounce(params, 500);
  const onselectSection = (data: any) => {
    if (debouncedParams?.sectiontype == 'petitions') {
      setDocumenSectionInfo((pre) => ({
        ...pre,
        open: true,
        selectedData: data,
      }));
    } else {
      onSelect && onSelect(data);
    }
  };
  const getSearchBasedVal = useCallback(() => {
    if (debouncedParams.name !== searchValue.trim()) {
      setParams((pre) => ({
        ...pre,
        name: searchValue,
        skip: 0,
        take: 50,
      }));
    }
    if (debouncedParams.visaId !== visaSearch) {
      setParams((pre) => ({
        ...pre,
        skip: 0,
        take: 50,
        visaId: visaSearch,
      }));
    }
  }, [debouncedParams.name, debouncedParams.visaId, searchValue, visaSearch]);
  const getApiList = useCallback(() => {
    try {
      getAllListData(
        debouncedParams?.sectiontype === 'petitions'
          ? COMMON_ENDPOINT.CopyDocumentParseList(
              debouncedParams.skip,
              debouncedParams.take,
              debouncedParams.petitionerId,
              debouncedParams.petitionerAssociated,
              debouncedParams.name,
              debouncedParams?.visaId,
            )
          : `${String(SETUP_ENDPOINT.ConfigSection)}?id=${''}${
              debouncedParams?.showAll
                ? `&showAll=${String(debouncedParams?.showAll)}`
                : ''
            }&name=${String(debouncedParams?.name)}&sectiontype=${String(
              debouncedParams?.sectiontype,
            )}&status=${String(debouncedParams?.status)}&majorsection=${String(
              debouncedParams?.majorsection,
            )}&skip=${String(debouncedParams?.skip)}&take=${String(
              debouncedParams?.take,
            )}&order=${String(debouncedParams?.order)}&sortby=${String(
              debouncedParams?.sortby,
            )}&attorney=${String(debouncedParams?.attorney)}${
              isAffilateCase
                ? `&isAffilate=true&affilateRoleId=${Number(
                    affilateRoleId,
                  )}&firmId=${Number(attorneyAssigned)}`
                : ''
            }`,
      ).then(async (resp: any) => {
        const { data, total } = resp ? resp : { data: [], total: 0 };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setSections((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          setSections((pre) => ({
            ...pre,
            data: [
              ...(data
                ? petitionData && petitionData?.id
                  ? data.filter((e) => e.petitionid !== petitionData?.id)
                  : data
                : []),
            ],
            total:
              sections && sections.total
                ? sections.total
                : // : sections?.count
                  // ? sections?.count
                  0,
          }));
        }
        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setSections);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      });
    } catch (error) {
      setLoader(false);
      setGetMoreLoader(false);
      setHasMoreResults(false);
      // toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedParams?.attorney,
    debouncedParams?.majorsection,
    debouncedParams?.name,
    debouncedParams?.order,
    debouncedParams?.sectiontype,
    debouncedParams?.skip,
    debouncedParams?.sortby,
    debouncedParams?.status,
    debouncedParams?.take,
    debouncedParams?.visaId,
  ]);
  const getMoreList = () => {
    setGetMoreLoader(true);
    setParams((pre) => ({
      ...pre,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      skip: pre.skip + 50,
    }));
  };

  const closeSectionListModel = () => {
    setDocumenSectionInfo((pre) => ({
      ...pre,
      open: false,
    }));
    setCopyDocumentSection &&
      setCopyDocumentSection((pre) => ({
        ...pre,
        open: false,
      }));
    getList && getList();
  };
  useEffect(() => {
    getSearchBasedVal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSearchBasedVal, searchValue, visaSearch]);

  useEffect(() => {
    setLoader(true);
    getApiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApiList, debouncedParams]);
  return (
    <>
      <DialogContent id="scrollParent">
        {loader && <div className="liberty-loader" />}
        <InfiniteScroll
          dataLength={
            sections && sections.data && sections.data.length
              ? sections.data.length
              : 0
          }
          style={{
            overflow: 'unset',
          }}
          next={getMoreList && getMoreList}
          hasMore={hasMoreResults}
          scrollableTarget={'scrollParent'}
          loader={
            getMoreLoader && (
              <div
                style={{
                  backgroundColor: '#ffffff',
                  textAlign: 'center',
                  width: '100%',
                }}>
                <CircularProgress
                  size={30}
                  sx={{ color: '#002c69', marginBottom: 10, marginTop: 10 }}
                />
              </div>
            )
          }
          endMessage>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th scope="col">
                  {params?.sectiontype == 'petitions'
                    ? 'BENEFICIARY NAME'
                    : 'NAME'}
                </th>
                <th scope="col">
                  {params?.sectiontype == 'petitions'
                    ? 'PETITIONER NAME'
                    : 'DESCRIPTION'}
                </th>
                <th scope="col">
                  {params?.sectiontype == 'petitions'
                    ? 'PETITION DATE'
                    : 'TYPE'}
                </th>
                <th scope="col">STATUS</th>
              </tr>
            </thead>
            <tbody>
              {sections && sections.data && sections.data.length > 0 ? (
                sections.data.map((data, index) => (
                  <tr
                    key={index}
                    onClick={() => onselectSection(data)}
                    style={{ cursor: 'pointer' }}>
                    <td>
                      <a style={{ color: '#1093df' }}>
                        {params?.sectiontype == 'petitions'
                          ? visasNameShown
                            ? String(data?.visa_name) +
                              ' For ' +
                              String(data.benificiary_name)
                            : data.benificiary_name
                          : data?.section_name}
                      </a>
                    </td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {params?.sectiontype == 'petitions'
                        ? data.prtitioner_name
                        : data?.section_description}
                    </td>
                    <td className="text-capitalize">
                      {params?.sectiontype == 'petitions'
                        ? moment(data.created_at).format('yyyy-MM-DD hh:mm A')
                        : data?.section_type === 'lca'
                        ? 'Labor Condition Application'
                        : data?.section_type}
                    </td>
                    <td>
                      {params?.sectiontype == 'petitions'
                        ? data.status
                        : data?.section_status
                        ? 'Active'
                        : 'Inactive'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className={'text-center'}>
                  <th scope="row" colSpan={4}>
                    No Record(s) Found.
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </InfiniteScroll>
      </DialogContent>
      {/* <DialogActions>
        <Typography component={'div'} className="d-flex-jae mt-1 ">
          <Pagination
            total={sections.total}
            skip={params.skip}
            take={params.take}
            setParams={setParams}
          />
        </Typography>
      </DialogActions> */}
      {documenSectionInfo.open && (
        <CustomModalView
          open={true}
          fullWidth={true}
          maxWidth={'xl'}
          scroll={'paper'}
          autoCloseDisable={true}
          isFilters={false}
          title="Documents List"
          close={() =>
            setDocumenSectionInfo((pre) => ({
              ...pre,
              open: false,
            }))
          }>
          <DocumentSectionList
            documenSectionInfo={documenSectionInfo}
            petitionData={petitionData}
            closeSectionListModel={closeSectionListModel}
          />
        </CustomModalView>
      )}
    </>
  );
};
