import { Typography } from '@mui/material';
import {
  AttorneyPrivateRoutes,
  BeneficiaryPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { MASTER_ENDPOINT } from 'utils/Constant';

import useStyles from '../../../PrivateStyle';

type ListPropsTypes = {
  commentsReview: any;
  commentsParams: any;
  setCommentsParams: any;
};

export const CommentsTable: React.FC<any> = (props: ListPropsTypes) => {
  const { commentsReview, setCommentsParams } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const [commentsWidget, setCommentsWidget] = useState({
    data: [],
    total: 0,
  });
  const [commentParam, setCommentParam] = useState({
    order: 'DESC',
    skip: 0,
    take: 10,
  });
  const [, setLoader] = useState(false);

  useEffect(() => {
    if (userDetail.role_id === 4) {
      if (commentParam.take) {
        getAllListData(
          `${String(MASTER_ENDPOINT.Comments)}?skip=${String(
            commentParam.skip,
          )}&take=${String(commentParam.take)}&order=${String(
            commentParam.order,
          )}`,
        )
          .then((resp: any) => {
            const mergeData = !_.isEmpty(commentsWidget)
              ? [...commentsWidget.data, ...resp.data]
              : resp.data;
            setCommentsWidget({
              data: mergeData,
              total: Number(resp.total),
            });
          })
          .catch((e: any) => {
            setLoader(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentParam]);

  return (
    <Typography component={'div'}>
      <Typography className="c-title black-white-text">Comments</Typography>
      {/* <Typography component={'div'} className="cp-box">
        <Typography component={'div'} className="cp-flex-4"> */}
      <Typography
        component={'div'}
        sx={{
          borderRadius: '8px 8px 0 0',
          height: '325px',
        }}
        className={classes.scrollbox}
        id="scrollParent">
        <InfiniteScroll
          dataLength={
            userDetail.role_id !== 4
              ? commentsReview &&
                commentsReview?.data &&
                commentsReview?.data?.length
                ? commentsReview.data.length
                : 0
              : commentsWidget &&
                commentsWidget?.data &&
                commentsWidget?.data?.length
              ? commentsWidget.data.length
              : 0
          }
          style={{
            overflow: 'unset',
          }}
          // height={
          //   userDetail.role_id !== 4
          //     ? commentsReview.data.length > 3
          //       ? 325
          //       : null
          //     : commentsWidget.data.length > 3
          //     ? 325
          //     : null
          // }
          next={() => {
            if (userDetail.role_id !== 4) {
              if (commentsReview?.data.length < Number(commentsReview?.total)) {
                setCommentsParams((prev) => ({
                  ...prev,
                  skip: Number(prev.skip) + Number(prev.take),
                  take: prev.take,
                }));
              }
            } else {
              if (commentsWidget?.data.length < Number(commentsWidget?.total)) {
                setCommentParam((prev) => ({
                  ...prev,
                  skip: Number(prev.skip) + Number(prev.take),
                  take: prev.take,
                }));
              }
            }
          }}
          hasMore={
            userDetail.role_id !== 4
              ? commentsReview?.data?.length < Number(commentsReview?.total)
              : commentsWidget?.data?.length < Number(commentsWidget?.total)
          }
          scrollableTarget={'scrollParent'}
          loader={
            <div className="lds-ellipsis">
              <div />
              <div />
              <div />
              <div />
            </div>
          }
          endMessage>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th>Case Name</th>
                <th>Question</th>
                <th>Comments</th>
                <th>Added by</th>
                <th>Added On</th>
                {/* <th>Screen</th> */}
              </tr>
            </thead>

            <tbody style={{ cursor: 'pointer' }}>
              {userDetail.role_id !== 4 ? (
                commentsReview &&
                commentsReview.data &&
                commentsReview.data.length > 0 ? (
                  commentsReview.data.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        if (item.type && item.screen) {
                          if (item.type === 'petition') {
                            // redirect to petition routes
                            if (userDetail.role_id === 3) {
                              item.screen === 'strategy'
                                ? navigate(
                                    `${String(
                                      AttorneyPrivateRoutes.PETITIONS
                                        .STRATEGYDETAIL,
                                    )}/${String(item.petition_id)}`,
                                    {
                                      state: {
                                        data: {
                                          benificiery_id:
                                            item.tab !== 'petitioner'
                                              ? item.beneficiary_id
                                              : null,
                                          formId: item.form_id,
                                          lead_id:
                                            item.tab === 'petitioner'
                                              ? item.beneficiary_id
                                              : null,
                                          petitionId: item.petition_id,
                                          showType: 'petitions',
                                        },
                                        focusElement: {
                                          activeTab: item.tab,
                                          questionId: item.question_id,
                                          sectionId: item.section_id,
                                        },
                                      },
                                    },
                                  )
                                : item.screen === 'complete-question'
                                ? navigate(
                                    `${String(
                                      AttorneyPrivateRoutes.PETITIONS
                                        .COMPLETEQUESTIONS,
                                    )}/${String(item.petition_id)}`,
                                    {
                                      state: {
                                        focusElement: {
                                          activeTab: item.tab,
                                          questionId: item.question_id,
                                          sectionId: item.section_id,
                                        },
                                        showType: 'petitions',
                                      },
                                    },
                                  )
                                : navigate(
                                    `${String(
                                      AttorneyPrivateRoutes.PETITIONS
                                        .BUILDCASEEDIT,
                                    )}/${String(item.petition_id)}/${String(
                                      item.form_id,
                                    )}`,
                                    {
                                      state: {
                                        focusElement: {
                                          activeTab: item.tab,
                                          questionId: item.question_id,
                                          sectionId: item.section_id,
                                        },
                                        showType: 'petitions',
                                      },
                                    },
                                  );
                            }
                            if (userDetail.role_id === 2) {
                              item.screen === 'strategy'
                                ? navigate(
                                    `${String(
                                      PetitionerPrivateRoutes.PETITIONS
                                        .STRATEGYDETAIL,
                                    )}/${String(item.petition_id)}`,
                                    {
                                      state: {
                                        data: {
                                          benificiery_id:
                                            item.tab !== 'petitioner'
                                              ? item.beneficiary_id
                                              : null,
                                          formId: item.form_id,
                                          lead_id:
                                            item.tab === 'petitioner'
                                              ? item.beneficiary_id
                                                ? item.beneficiary_id
                                                : item.lead_id
                                              : null,
                                          petitionId: item.petition_id,
                                          showType: 'petitions',
                                        },
                                        focusElement: {
                                          activeTab: item.tab,
                                          questionId: item.question_id,
                                          sectionId: item.section_id,
                                        },
                                      },
                                    },
                                  )
                                : item.screen === 'complete-question'
                                ? navigate(
                                    `${String(
                                      PetitionerPrivateRoutes.PETITIONS
                                        .COMPLETEQUESTIONS,
                                    )}/${String(item.petition_id)}`,
                                    {
                                      state: {
                                        focusElement: {
                                          activeTab: item.tab,
                                          questionId: item.question_id,
                                          sectionId: item.section_id,
                                        },
                                        showType: 'petitions',
                                      },
                                    },
                                  )
                                : null;
                              // navigate(
                              //     `${String(
                              //       PetitionerPrivateRoutes.PETITIONS.BUILDCASEEDIT,
                              //     )}/${String(item.petition_id)}/${String(
                              //       item.form_id,
                              //     )}`,
                              //     {
                              //       state: {
                              //         // data: { // Need to work here for build-case
                              //         //   document: isOpenView.document,
                              //         //   fileFormat: isOpenView.document?.file_format,
                              //         //   leadUserId:
                              //         //     searchParams.get('leadUser') !== ''
                              //         //       ? searchParams.get('leadUser')
                              //         //       : null,
                              //         //   pdfId: isOpenView.document?.templateid,
                              //         //   templateFilePath:
                              //         //     isOpenView.document?.template_file_path,
                              //         //   templateName: isOpenView.document?.template_name,
                              //         // },
                              //         focusElement: {
                              //           activeTab: item.tab,
                              //           questionId: item.question_id,
                              //           sectionId: item.section_id,
                              //         },
                              //         showType: 'petitions',
                              //       },
                              //     },
                              //   );
                            }
                            if (userDetail.role_id === 4) {
                              // item.screen === 'strategy'
                              //   ? navigate(
                              //       `${String(
                              //         BeneficiaryPrivateRoutes.BENEFICIARYSTRATEGYDETAIL,
                              //       )}/${String(item.petition_id)}`,
                              //       {
                              //         state: {
                              //           data: {
                              //             benificiery_id:
                              //               item.tab !== 'petitioner'
                              //                 ? item.beneficiary_id
                              //                 : null,
                              //             formId: item.form_id,
                              //             lead_id:
                              //               item.tab === 'petitioner'
                              //                 ? item.beneficiary_id
                              //                 : null,
                              //             petitionId: item.petition_id,
                              //             showType: 'petitions',
                              //           },
                              //           focusElement: {
                              //             activeTab: item.tab,
                              //             questionId: item.question_id,
                              //             sectionId: item.section_id,
                              //           },
                              //         },
                              //       },
                              //     )
                              //   : item.screen === 'complete-question'
                              //   ? item.tab !== 'petitioner'
                              //     ? navigate(
                              //         `${String(
                              //           BeneficiaryPrivateRoutes.COMPLETEQUESTIONS,
                              //         )}/${String(item.petition_id)}`,
                              //         {
                              //           state: {
                              //             focusElement: {
                              //               activeTab: item.tab,
                              //               questionId: item.question_id,
                              //               sectionId: item.section_id,
                              //             },
                              //             showType: 'petitions',
                              //           },
                              //         },
                              //       )
                              //     : toast.error(
                              //         'You are not allow to view this comments',
                              //         alertOptions,
                              //       )
                              //   : navigate(
                              //       `${String(
                              //         BeneficiaryPrivateRoutes.BUILDCASEEDIT,
                              //       )}/${String(item.petition_id)}/${String(
                              //         item.form_id,
                              //       )}`,
                              //       {
                              //         state: {
                              //           focusElement: {
                              //             activeTab: item.tab,
                              //             questionId: item.question_id,
                              //             sectionId: item.section_id,
                              //           },
                              //           showType: 'petitions',
                              //         },
                              //       },
                              //     );
                            }
                          } else {
                            // redirect to lead routes
                            if (userDetail.role_id === 3) {
                              item.screen === 'strategy'
                                ? navigate(
                                    `${String(
                                      AttorneyPrivateRoutes.LEADS
                                        .STRATEGYDETAIL,
                                    )}/${String(item.lead_id)}`,
                                    {
                                      state: {
                                        data: {
                                          benificiery_id:
                                            item.tab !== 'petitioner'
                                              ? item.beneficiary_id
                                              : null,
                                          formId: item.form_id,
                                          lead_id:
                                            item.tab === 'petitioner'
                                              ? item.beneficiary_id
                                              : null,
                                          petitionId: item.petition_id,
                                          showType: 'leads',
                                        },
                                        focusElement: {
                                          activeTab: item.tab,
                                          questionId: item.question_id,
                                          sectionId: item.section_id,
                                        },
                                      },
                                    },
                                  )
                                : null;
                            }
                            if (userDetail.role_id === 2) {
                              item.screen === 'strategy'
                                ? navigate(
                                    `${String(
                                      PetitionerPrivateRoutes.LEADS
                                        .STRATEGYDETAIL,
                                    )}/${String(item.lead_id)}`,
                                    {
                                      state: {
                                        data: {
                                          benificiery_id:
                                            item.tab !== 'petitioner'
                                              ? item.beneficiary_id
                                              : null,
                                          formId: item.form_id,
                                          lead_id:
                                            item.tab === 'petitioner'
                                              ? item.beneficiary_id
                                                ? item.beneficiary_id
                                                : item.lead_id
                                              : null,
                                          petitionId: item.petition_id,
                                          showType: 'leads',
                                        },
                                        focusElement: {
                                          activeTab: item.tab,
                                          questionId: item.question_id,
                                          sectionId: item.section_id,
                                        },
                                      },
                                    },
                                  )
                                : null;
                            }
                            if (userDetail.role_id === 4) {
                              // item.screen === 'strategy'
                              //   ? navigate(
                              //       `${String(
                              //         BeneficiaryPrivateRoutes.BENEFICIARYSTRATEGYDETAIL,
                              //       )}/${String(item.petition_id)}`,
                              //       {
                              //         state: {
                              //           data: {
                              //             benificiery_id:
                              //               item.tab !== 'petitioner'
                              //                 ? item.beneficiary_id
                              //                 : null,
                              //             formId: item.form_id,
                              //             lead_id:
                              //               item.tab === 'petitioner'
                              //                 ? item.beneficiary_id
                              //                 : null,
                              //             petitionId: item.petition_id,
                              //             showType: 'petitions',
                              //           },
                              //           focusElement: {
                              //             activeTab: item.tab,
                              //             questionId: item.question_id,
                              //             sectionId: item.section_id,
                              //           },
                              //         },
                              //       },
                              //     )
                              //   : null;
                            }
                          }
                        }
                      }}>
                      <td>
                        <div
                          className="hideshow"
                          style={{
                            width: '200px',
                            wordBreak: 'break-all',
                          }}>
                          {item.visa_type ? item.visa_type.concat(' of ') : ''}
                          {item.beneficiary_name}
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideshow"
                          style={{
                            width: '200px',
                            wordBreak: 'break-all',
                          }}>
                          {item.question}
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideshow"
                          style={{
                            width: '200px',
                            wordBreak: 'break-all',
                          }}>
                          {item.comments}
                        </div>
                      </td>
                      <td>{item.comment_added_by}</td>
                      <td>
                        {moment(item.created_on).format('MM-DD-yyyy hh:mm A')}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className={'text-center'}>
                    <td colSpan={10}>No Record(s) Found.</td>
                  </tr>
                )
              ) : commentsWidget &&
                commentsWidget.data &&
                commentsWidget.data.length > 0 ? (
                commentsWidget.data.map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      if (item.type && item.screen) {
                        if (item.type === 'petition') {
                          // redirect to petition routes
                          if (userDetail.role_id === 3) {
                            item.screen === 'strategy'
                              ? navigate(
                                  `${String(
                                    AttorneyPrivateRoutes.PETITIONS
                                      .STRATEGYDETAIL,
                                  )}/${String(item.petition_id)}`,
                                  {
                                    state: {
                                      data: {
                                        benificiery_id:
                                          item.tab !== 'petitioner'
                                            ? item.beneficiary_id
                                            : null,
                                        formId: item.form_id,
                                        lead_id:
                                          item.tab === 'petitioner'
                                            ? item.beneficiary_id
                                            : null,
                                        petitionId: item.petition_id,
                                        showType: 'petitions',
                                      },
                                      focusElement: {
                                        activeTab: item.tab,
                                        questionId: item.question_id,
                                        sectionId: item.section_id,
                                      },
                                    },
                                  },
                                )
                              : item.screen === 'complete-question'
                              ? navigate(
                                  `${String(
                                    AttorneyPrivateRoutes.PETITIONS
                                      .COMPLETEQUESTIONS,
                                  )}/${String(item.petition_id)}`,
                                  {
                                    state: {
                                      focusElement: {
                                        activeTab: item.tab,
                                        questionId: item.question_id,
                                        sectionId: item.section_id,
                                      },
                                      showType: 'petitions',
                                    },
                                  },
                                )
                              : navigate(
                                  `${String(
                                    AttorneyPrivateRoutes.PETITIONS
                                      .BUILDCASEEDIT,
                                  )}/${String(item.petition_id)}/${String(
                                    item.form_id,
                                  )}`,
                                  {
                                    state: {
                                      focusElement: {
                                        activeTab: item.tab,
                                        questionId: item.question_id,
                                        sectionId: item.section_id,
                                      },
                                      showType: 'petitions',
                                    },
                                  },
                                );
                          }
                          if (userDetail.role_id === 2) {
                            item.screen === 'strategy'
                              ? navigate(
                                  `${String(
                                    PetitionerPrivateRoutes.PETITIONS
                                      .STRATEGYDETAIL,
                                  )}/${String(item.petition_id)}`,
                                  {
                                    state: {
                                      data: {
                                        benificiery_id:
                                          item.tab !== 'petitioner'
                                            ? item.beneficiary_id
                                            : null,
                                        formId: item.form_id,
                                        lead_id:
                                          item.tab === 'petitioner'
                                            ? item.beneficiary_id
                                            : null,
                                        petitionId: item.petition_id,
                                        showType: 'petitions',
                                      },
                                      focusElement: {
                                        activeTab: item.tab,
                                        questionId: item.question_id,
                                        sectionId: item.section_id,
                                      },
                                    },
                                  },
                                )
                              : item.screen === 'complete-question'
                              ? navigate(
                                  `${String(
                                    PetitionerPrivateRoutes.PETITIONS
                                      .COMPLETEQUESTIONS,
                                  )}/${String(item.petition_id)}`,
                                  {
                                    state: {
                                      focusElement: {
                                        activeTab: item.tab,
                                        questionId: item.question_id,
                                        sectionId: item.section_id,
                                      },
                                      showType: 'petitions',
                                    },
                                  },
                                )
                              : null;
                          }
                          if (userDetail.role_id === 4) {
                            item.screen === 'complete-question' &&
                            item.type === 'petition'
                              ? navigate(
                                  `${
                                    BeneficiaryPrivateRoutes.COMPLETEQUESTIONS
                                  }/${String(
                                    item?.petition_id,
                                  )}?leadUser=${String(
                                    item.lead_user_id,
                                  )}&type=petition`,
                                  {
                                    state: {
                                      focusElement: {
                                        activeTab: item.tab,
                                        questionId: item.question_id,
                                        sectionId: item.section_id,
                                      },
                                    },
                                  },
                                )
                              : item.screen === 'strategy' &&
                                item.type === 'petition' &&
                                item.petition_id
                              ? navigate(
                                  `${String(
                                    BeneficiaryPrivateRoutes.BENEFICIARYPETITIONERSTRATEGYDETAIL,
                                  )}/${String(
                                    item.petition_id,
                                  )}?leadUser=${String(
                                    item.lead_user_id,
                                  )}&type=petition`,
                                  {
                                    state: {
                                      data: {
                                        benificiery_id:
                                          item.tab !== 'petitioner'
                                            ? item.beneficiary_id
                                            : null,
                                        formId: item.form_id,
                                        lead_id:
                                          item.tab === 'petitioner'
                                            ? item.beneficiary_id
                                            : null,
                                        petitionId: item.petition_id,
                                        showType: 'petitions',
                                      },
                                      focusElement: {
                                        activeTab: item.tab,
                                        questionId: item.question_id,
                                        sectionId: item.section_id,
                                      },
                                    },
                                  },
                                )
                              : '';
                          }
                        } else {
                          // redirect to lead routes
                          if (userDetail.role_id === 3) {
                            item.screen === 'strategy'
                              ? navigate(
                                  `${String(
                                    AttorneyPrivateRoutes.LEADS.STRATEGYDETAIL,
                                  )}/${String(item.lead_id)}`,
                                  {
                                    state: {
                                      data: {
                                        benificiery_id:
                                          item.tab !== 'petitioner'
                                            ? item.beneficiary_id
                                            : null,
                                        formId: item.form_id,
                                        lead_id:
                                          item.tab === 'petitioner'
                                            ? item.beneficiary_id
                                            : null,
                                        petitionId: item.petition_id,
                                        showType: 'petitions',
                                      },
                                      focusElement: {
                                        activeTab: item.tab,
                                        questionId: item.question_id,
                                        sectionId: item.section_id,
                                      },
                                    },
                                  },
                                )
                              : null;
                          }
                          if (userDetail.role_id === 2) {
                            item.screen === 'strategy'
                              ? navigate(
                                  `${String(
                                    PetitionerPrivateRoutes.PETITIONS
                                      .STRATEGYDETAIL,
                                  )}/${String(item.petition_id)}`,
                                  {
                                    state: {
                                      data: {
                                        benificiery_id:
                                          item.tab !== 'petitioner'
                                            ? item.beneficiary_id
                                            : null,
                                        formId: item.form_id,
                                        lead_id:
                                          item.tab === 'petitioner'
                                            ? item.beneficiary_id
                                            : null,
                                        petitionId: item.petition_id,
                                        showType: 'petitions',
                                      },
                                      focusElement: {
                                        activeTab: item.tab,
                                        questionId: item.question_id,
                                        sectionId: item.section_id,
                                      },
                                    },
                                  },
                                )
                              : null;
                          }
                          if (userDetail.role_id === 4) {
                            navigate(
                              `${String(
                                BeneficiaryPrivateRoutes.BENEFICIARYSTRATEGYDETAIL,
                              )}/${String(item.lead_id)}`,
                              {
                                state: {
                                  data: {
                                    benificiery_id:
                                      item.tab !== 'petitioner'
                                        ? item.beneficiary_id
                                        : null,
                                    formId: item.form_id,
                                    lead_id:
                                      item.tab === 'petitioner'
                                        ? item.beneficiary_id
                                        : item.lead_id,
                                    petitionId: item.petition_id,
                                    showType: 'petitions',
                                  },
                                  focusElement: {
                                    activeTab: item.tab,
                                    questionId: item.question_id,
                                    sectionId: item.section_id,
                                  },
                                },
                              },
                            );
                          }
                        }
                      }
                    }}>
                    <td>
                      <div
                        className="hideshow"
                        style={{
                          width: '200px',
                          wordBreak: 'break-all',
                        }}>
                        {item.visa_type ? item.visa_type.concat(' of ') : ''}
                        {item.beneficiary_name}
                      </div>
                    </td>
                    <td>
                      <div
                        className="hideshow"
                        style={{
                          width: '200px',
                          wordBreak: 'break-all',
                        }}>
                        {item.question}
                      </div>
                    </td>
                    <td>
                      <div
                        className="hideshow"
                        style={{
                          width: '200px',
                          wordBreak: 'break-all',
                        }}>
                        {item.comments}
                      </div>
                    </td>
                    <td>{item.comment_added_by}</td>
                    <td>
                      {moment(item.created_on).format('DD-MM-yyyy hh:mm A')}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className={'text-center'}>
                  <td colSpan={10}>No Record(s) Found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </InfiniteScroll>
      </Typography>
      {/* </Typography>
      </Typography> */}
    </Typography>
  );
};
