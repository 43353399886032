import { Box, Typography } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteData, getAllListData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import PetitionerRequestAdditionalTableHeading from './PetitionerRequestAdditionalTableHeading';
import PetitionerRequestTableDisplays from './PetitionerRequestTableDisplay';

type PetitionerRequestAdditionalDocumentsListIterFace = {
  petitions: any;
  petitionId: any;
  moduleList: any;
  reloadAPI: any;
};

export const PetitionerRequestAdditionalDocumentsList: React.FC<
  PetitionerRequestAdditionalDocumentsListIterFace
> = ({ petitions, petitionId, moduleList, reloadAPI }) => {
  const [loader, setLoader] = useState(true);
  const [listData, setListData] = useState<any>({});
  const [confirmOpen, setConfirmOpen] = useState<any>({
    index: null,
    show: false,
  });
  const getDocumentList = useCallback(() => {
    getAllListData(
      `${String(
        COMMON_ENDPOINT.RequestForRequiredDocument,
      )}/extra-docs/${String(petitionId)}`,
    )
      .then((resp: any) => {
        setListData(resp);
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteRequestDocument = useCallback((id) => {
    deleteData(
      confirmOpen.index ? confirmOpen.index : id,
      `${String(COMMON_ENDPOINT.RequestForRequiredDocument)}/extra-docs`,
    )
      .then((resp: any) => {
        setConfirmOpen({
          index: null,
          show: false,
        });
        setLoader(false);
        toast.success('Deleted successfully', alertOptions);
        getDocumentList();
      })
      .catch((error: any) => {
        setConfirmOpen({
          index: null,
          show: false,
        });
        setLoader(false);
        toast.error(
          error ? error : 'Oops!!! Something went wrong',
          alertOptions,
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteRequestDocumentConfirm = useCallback((id) => {
    setConfirmOpen({
      index: id,
      show: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDocumentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitionId, reloadAPI]);

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Box component="form" className="addbox" noValidate autoComplete="off">
      <Typography component={'div'} sx={{ overflow: 'auto' }}>
        <table className="lead-table">
          <PetitionerRequestAdditionalTableHeading />
          <tbody>
            {!_.isEmpty(listData?.data) && listData.data.length > 0 ? (
              listData.data.map((data, index) => {
                return (
                  <PetitionerRequestTableDisplays
                    data={data}
                    key={index}
                    moduleList={moduleList}
                    deleteRequestDocumentConfirm={deleteRequestDocumentConfirm}
                    petitionId={petitionId}
                    beneficiaryDocs={listData?.benificiery_docs}
                    petitionerDocs={listData?.petitioner_docs}
                    setListData={setListData}
                    index={index}
                    getDocumentList={getDocumentList}
                    dependentDocs={listData?.dependent_docs}
                  />
                );
              })
            ) : (
              <tr className={'text-center'}>
                <td scope="row" colSpan={5} style={{ color: 'black' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    No Record(s) Found.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Typography>
      <ConfirmDialog
        title="DELETE"
        floatingBtnShow={true}
        open={confirmOpen.show}
        onConfirm={(act) =>
          act === 'yes'
            ? deleteRequestDocument(confirmOpen?.index)
            : setConfirmOpen({
                index: null,
                show: false,
              })
        }
        autoCloseDisable={true}>
        Are you sure do you want to delete?
      </ConfirmDialog>
    </Box>
  );
};

export default PetitionerRequestAdditionalDocumentsList;
