import { Typography } from '@mui/material';
import imageLoader from 'assets/images/Liberty-loader.gif';
import React from 'react';

export const Loader: React.FC<any> = React.memo(() => {
  return (
    <Typography
      component={'div'}
      sx={{
        left: ' 50%',
        position: 'fixed',
        top: ' 50%',
        transform: 'translate(-50%, -50%)',
      }}>
      <img src={imageLoader} alt="" />
    </Typography>
  );
});
