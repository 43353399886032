import { yupResolver } from '@hookform/resolvers/yup';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, DialogContent, Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
// import Multiselect from 'multiselect-react-dropdown';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
// import { useAppSelector } from 'store/hooks';
import { ConfigureQuestions, ResponseTypeQuestions } from 'types';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import {
  ConfigureQuestionSchema,
  ResponseTypeQuestionsSchema,
} from 'utils/ValidatorSchema';

import ResponseTypeModal from './ResponseTypeModal';

export const QuestionAdd: React.FC<any> = () => {
  const navigate: any = useNavigate();
  const { editId } = useParams();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dialogView, setDialogView] = React.useState<boolean>(false);
  const activeStatusInput = useRef(null);
  const inActiveStatusInput = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [loader, setLoader] = useState(true);
  const [newQuestionID, setNewQuestionID] = useState('');
  const [statusToggle, setStatusToggle] = useState(true);
  const [roles, setRoles] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [responseBy, setResponseBy] = useState<any>([]);
  const [responseTypes, setResponseTypes] = useState<any>([]);
  const [selectedResponses, setSelectedResponses] = useState<any>([]);
  const [systemFields, setSystemFields] = useState<any>([]);
  const [filterSearch, setFilterSearch] = useState<any>({
    section_name: '',
  });
  const [sectionList, setSectionList] = useState<any>([]);
  const [sectionListAll, setSectionListAll] = useState<any>([]);
  const [linkCodeEdit, setLinkCodeInEdit] = useState('');

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    // setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ConfigureQuestions>({
    criteriaMode: 'all',
    defaultValues: {
      answerUserField: '',
      dariNotes: '',
      dariQuestion: '',
      defaultAnswer: '',
      example: '',
      hint: '',
      issuesLanguage: '',
      issuesNumber: '',
      linkCode: '',
      notes: '',
      pashtoNotes: '',
      pashtoQuestion: '',
      question: '',
      response: [],
      responseBy: null,
      responseType: '',
      status: true,
      webUrlLink: '',
      winningStrategyLink: '',
      winningStrategyNote: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ConfigureQuestionSchema),
  });

  const {
    control: controlRT,
    handleSubmit: handleSubmitRT,
    reset: resetFormRT,
    formState: { errors: errorsRT },
  } = useForm<ResponseTypeQuestions>({
    criteriaMode: 'all',
    defaultValues: {
      response: [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ResponseTypeQuestionsSchema),
  });

  const {
    fields: responseFields,
    append: responeAppend,
    remove: responseFieldRemove,
  } = useFieldArray({ control: controlRT, name: 'response' });

  const cancelForm = () => {
    resetForm();

    const removedIndex: Array<number> = [];
    responseFields.map((e: any, index: number) => {
      removedIndex.push(index);
    });
    responseFieldRemove(removedIndex);

    navigate(-1);
  };

  const getFieldsOfQuestion = () => {
    getAllListData(`${SETUP_ENDPOINT.ConfigQuestion}/fields`)
      .then((filedResp: any) => {
        setSystemFields(filedResp.data);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    if (filterSearch.section_name === '') {
      setSectionList(sectionListAll);
    }
    if (filterSearch.section_name) {
      setSectionList(
        sectionListAll.filter(
          (item) =>
            item.section_name
              .toLowerCase()
              .indexOf(filterSearch.section_name.toLowerCase()) > -1,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearch.section_name]);
  const getInitialStatus = useCallback(async () => {
    setLoader(true);
    await getAllListData(
      `${SETUP_ENDPOINT.ConfigQuestion}?id=${String(editId)}`,
    )
      .then((resp) => {
        const {
          answer_user_field,
          dari_notes,
          dari_question,
          default_answer,
          link_code,
          notes,
          pashto_notes,
          pashto_question,
          question,
          response,
          response_by,
          response_type,
          status,
          web_url_link,
          winning_strategy_link,
          hint,
          example,
          issues_language,
          issues_number,
          winning_strategy_note,
        } = resp.data[0];
        setSectionList(resp.sectionList ? resp.sectionList : []);
        setSectionListAll(resp.sectionList ? resp.sectionList : []);
        setLinkCodeInEdit(link_code ? link_code : null);
        getAllListData(COMMON_ENDPOINT.AdminRoles)
          .then((rolResp: any) => {
            const options = [];
            setRoles(
              rolResp
                .filter((role) => role.module_id !== 5 && role.module_id !== 1)
                .map((e) =>
                  options.push({ label: e.module_name, value: e.module_id }),
                ),
            );
            setRoles(options);
            setResponseBy(
              rolResp
                .filter((obj) => response_by.indexOf(obj.module_id) !== -1)
                .map((selected) => selected.visa_id),
            );
            getResponseTypes();
          })
          .catch((e: any) => {
            setLoader(false);
          });

        if (Number(response_type) === 10) {
          getFieldsOfQuestion();
        }

        setValue('answerUserField', answer_user_field || '');
        setValue('dariNotes', dari_notes || '');
        setValue('pashtoNotes', pashto_notes || '');
        setValue('pashtoQuestion', pashto_question || '');
        setValue('dariQuestion', dari_question || '');
        setValue('defaultAnswer', default_answer || '');
        setValue('linkCode', link_code || '');
        setValue('notes', notes || '');
        setValue('question', question || '');
        setValue('responseType', response_type || '');
        setValue('responseBy', response_by || '');
        setValue('winningStrategyLink', winning_strategy_link || '');
        setValue('webUrlLink', web_url_link || '');

        setValue('hint', hint || '');
        setValue('example', example || '');
        setValue('issuesLanguage', issues_language || '');
        setValue('issuesNumber', issues_number || '');
        setValue('winningStrategyNote', winning_strategy_note || '');

        setValue('status', status);
        setStatusToggle(status);
        if (response.length) {
          setValue('response', response);
          response.map((resp, index) => responeAppend({ text: resp }));
          setSelectedResponses(response);
        }

        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToggleStatus = () => setStatusToggle((b) => !b);

  const onSubmitForm = (data: any) => {
    setLoader(true);
    data['answerUserField'] = data['answerUserField']
      ? Number(data['answerUserField'])
      : null;
    data['responseType'] = Number(data['responseType']);
    data['status'] = statusToggle;

    if (!_.isEmpty(editId)) {
      if (
        linkCodeEdit &&
        String(linkCodeEdit).trim() ===
          (data['linkCode'] ? String(data['linkCode']).trim() : '')
      ) {
        data['linkCode'] = null;
      }
      updateData(editId, data, SETUP_ENDPOINT.ConfigQuestion)
        .then((resp: any) => {
          cancelForm();
          setLoader(false);
          toast.success('Updated successfully', alertOptions);
        })
        .catch((e) => {
          setLoader(false);
        });
    } else {
      addNewData(data, SETUP_ENDPOINT.ConfigQuestion)
        .then((resp: any) => {
          cancelForm();
          toast.success('Created successfully', alertOptions);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };
  const onSubmitResponseTypeForm = (data: any) => {
    if (!_.isEmpty(data)) {
      const responses = data['response'].map((resp) => resp.text);
      setValue('response', responses);
      setSelectedResponses(responses);
    }
    // const removedIndex: Array<number> = [];
    // responseFields.map((e: any, index: number) => {
    //   removedIndex.push(index);
    // });
    // responseFieldRemove(removedIndex);
    handleClose();
  };

  const getResponseTypes = useCallback(() => {
    getAllListData(`${COMMON_ENDPOINT.ResponseTypes}/responsetypes`)
      .then((response: any) => {
        setResponseTypes(response.options);
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    if (editId) {
      setNewQuestionID(editId);
      getInitialStatus();
    } else {
      getAllListData(`${SETUP_ENDPOINT.ConfigQuestion}/id`)
        .then((getIdResp: any) => {
          setNewQuestionID(getIdResp);
          getAllListData(COMMON_ENDPOINT.AdminRoles)
            .then((rolResp: any) => {
              const options = [];
              setRoles(
                rolResp
                  .filter(
                    (role) => role.module_id !== 5 && role.module_id !== 1,
                  )
                  .map((e) =>
                    options.push({ label: e.module_name, value: e.module_id }),
                  ),
              );
              setRoles(options);

              setValue('responseBy', [3, 4, 2]);
              getResponseTypes();
            })
            .catch((e: any) => {
              setLoader(false);
            });
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
        <Typography
          component={'div'}
          className="d-flex-a d-flex-sb sticky-tab mb-2">
          <Typography
            component={'div'}
            className="w-title black-white-text"
            sx={{ margin: 'unset !important' }}>
            {_.isUndefined(editId) ? 'Add' : 'Edit'} Question
          </Typography>
          <Typography component={'div'} className="d-flex-a">
            <Button className="n-cancel-btn mr-2" onClick={cancelForm}>
              <Typography className="o-box" />
              Cancel
            </Button>
            <Button
              className="n-add-btn"
              type="submit"
              form={'add-question'}
              disabled={
                [4, 5, 6].includes(Number(watch('responseType'))) &&
                selectedResponses.length === 0
              }>
              {_.isUndefined(editId) ? 'Submit' : 'Update'}
            </Button>
          </Typography>
        </Typography>
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <>
            <form
              id="add-question"
              // className="default-scroll-box"
              onSubmit={handleSubmit(onSubmitForm)}>
              <Typography
                component={'div'}
                className="addbox"
                sx={{
                  marginTop: 'unset !important',
                }}>
                <Box
                  component="form"
                  className="u-add-in"
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                    <Grid item md={6}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Question ID <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <CustomInput
                          disabled
                          value={newQuestionID}
                          className={'custom-input'}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field ">
                        <Grid container>
                          <Grid
                            item
                            md={
                              [4, 5, 6].includes(Number(watch('responseType')))
                                ? 10
                                : 12
                            }>
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Response Type{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=""
                              name="responseType"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <CustomSelect
                                    labelKey={'type'}
                                    valueKey={'id'}
                                    options={
                                      !_.isEmpty(responseTypes)
                                        ? responseTypes
                                        : []
                                    }
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                      if (Number(e.target.value) === 10) {
                                        getFieldsOfQuestion();
                                      }
                                      if (
                                        [4, 5, 6].includes(
                                          Number(watch('responseType')),
                                        )
                                      ) {
                                        handleOpen();
                                      }
                                    }}
                                    placeHolder={'Select'}
                                    className={'custom-input'}
                                    error={errors.responseType?.message}
                                    tabIndex={2}
                                  />
                                );
                              }}
                            />
                          </Grid>
                          {[4, 5, 6].includes(
                            Number(watch('responseType')),
                          ) && (
                            <Grid
                              item
                              md={2}
                              sx={{
                                paddingLeft: '.6rem !important',
                                paddingTop: '.9rem !important',
                              }}>
                              <Typography component={'div'} className="pt-2">
                                <EditIcon
                                  sx={{
                                    cursor: 'pointer',
                                    fill: selectedResponses.length
                                      ? 'gray'
                                      : 'red',
                                    height: '25px',
                                    width: '25px',
                                  }}
                                  onClick={handleOpen}
                                />
                                {selectedResponses.length === 0 && (
                                  <span className={'Mui-error'}>Required</span>
                                )}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Response By <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name="responseBy"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                closeMenuOnSelect={false}
                                labelKey={'label'}
                                valueKey={'value'}
                                options={roles}
                                isMulti={true}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder={'Select'}
                                className={'basic-multi-select'}
                                error={errors.responseBy?.message}
                                // disabled={allowAccess}
                              />
                              // <>
                              //   <Multiselect
                              //     {...field}
                              //     placeholder={
                              //       watch('responseBy')?.length === 0
                              //         ? 'Response By'
                              //         : ''
                              //     }
                              //     displayValue="module_name"
                              //     onSelect={(selected, item) => {
                              //       if (selected.length) {
                              //         setError('responseBy', {
                              //           message: '',
                              //           type: 'custom',
                              //         });
                              //       } else {
                              //         setError('responseBy', {
                              //           message: 'Response By is required',
                              //           type: 'custom',
                              //         });
                              //       }
                              //       setValue(
                              //         'responseBy',
                              //         selected.map((role) => role.module_id),
                              //       );
                              //     }}
                              //     onRemove={(selected, item) => {
                              //       if (selected.length) {
                              //         setError('responseBy', {
                              //           message: '',
                              //           type: 'custom',
                              //         });
                              //       } else {
                              //         setError('responseBy', {
                              //           message: 'Response By is required',
                              //           type: 'custom',
                              //         });
                              //       }
                              //       setValue(
                              //         'responseBy',
                              //         selected.map((role) => role.module_id),
                              //       );
                              //     }}
                              //     selectedValues={responseBy}
                              //     options={!_.isEmpty(roles) ? roles : []}
                              //   />
                              //   {errors.responseBy?.message && (
                              //     <FormHelperText style={{ color: '#FF0000' }}>
                              //       {errors.responseBy?.message}
                              //     </FormHelperText>
                              //   )}
                              // </>
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Winning Strategy Reference
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="winningStrategyLink"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Winning Strategy Reference'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                disabled={
                                  watch('webUrlLink').length > 0 && true
                                }
                                error={errors.winningStrategyLink?.message}
                                onChange={(val) => onChange(val)}
                                tabIndex={4}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Default Response
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="defaultAnswer"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Default Response'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.defaultAnswer?.message}
                                onChange={(val) => onChange(val)}
                                isTextArea={true}
                                disabled={
                                  ![1, 2].includes(
                                    Number(watch('responseType')),
                                  )
                                }
                                tabIndex={6}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Hint
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="hint"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Hint'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.hint?.message}
                                onChange={(val) => onChange(val)}
                                isTextArea={true}
                                tabIndex={8}
                              />
                            );
                          }}
                        />
                      </Typography>

                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Issue/Number
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="issuesNumber"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Issue/Number'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.issuesNumber?.message}
                                onChange={(val) => onChange(val)}
                                isTextArea={true}
                                tabIndex={10}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field ">
                        <Typography component={'div'} className="custom-label">
                          Website Reference
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="webUrlLink"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Website Reference'}
                                name={name}
                                value={value}
                                disabled={
                                  watch('winningStrategyLink').length > 0 &&
                                  true
                                }
                                className={'custom-textarea'}
                                error={errors.webUrlLink?.message}
                                onChange={(val) => onChange(val)}
                                tabIndex={12}
                                isTextArea={true}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Pashto Question (Optional)
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="pashtoQuestion"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Pashto Question (Optional)'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.pashtoQuestion?.message}
                                onChange={(val) => onChange(val)}
                                isTextArea={true}
                                tabIndex={14}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Dari Question (Optional)
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="dariQuestion"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Dari Question (Optional)'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.dariQuestion?.message}
                                onChange={(val) => onChange(val)}
                                isTextArea={true}
                                tabIndex={16}
                              />
                            );
                          }}
                        />
                      </Typography>

                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Status <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <div className="switch-field">
                          <input
                            type="radio"
                            id="switch_left"
                            name="switchToggle"
                            value={'inactive'}
                            onChange={onToggleStatus}
                            checked={!statusToggle}
                            ref={inActiveStatusInput}
                            tabIndex={18}
                          />
                          <label
                            htmlFor="switch_left d-flex-ja"
                            onClick={() => inActiveStatusInput.current.click()}>
                            Inactive
                          </label>

                          <input
                            type="radio"
                            id="switch_right"
                            name="switchToggle"
                            value={'active'}
                            onChange={onToggleStatus}
                            checked={statusToggle}
                            ref={activeStatusInput}
                          />
                          <label
                            htmlFor="switch_right d-flex-ja"
                            onClick={() => activeStatusInput.current.click()}>
                            Active
                          </label>
                        </div>
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Question <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="question"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Question'}
                                name={name}
                                value={value}
                                minRows={5}
                                className={'custom-textarea'}
                                error={errors.question?.message}
                                onChange={(val) => onChange(val)}
                                isTextArea={true}
                                tabIndex={1}
                              />
                            );
                          }}
                        />
                      </Typography>
                      {Number(watch('responseType')) === 10 && (
                        <Typography component={'div'} className="custom-field">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            System Fields{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={''}
                            name="answerUserField"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomSelect
                                  labelKey={'name'}
                                  valueKey={'id'}
                                  options={
                                    !_.isEmpty(systemFields) ? systemFields : []
                                  }
                                  name={name}
                                  value={value}
                                  onChange={(val) => onChange(val)}
                                  placeHolder={'Select'}
                                  className={'custom-input'}
                                  error={errors.answerUserField?.message}
                                  tabIndex={3}
                                />
                              );
                            }}
                          />
                        </Typography>
                      )}
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Link Code
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="linkCode"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Link Code'}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.linkCode?.message}
                                onChange={(val) => onChange(val)}
                                tabIndex={3}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Description
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="notes"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Description'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.notes?.message}
                                onChange={(val) => onChange(val)}
                                isTextArea={true}
                                tabIndex={5}
                                minRows={
                                  Number(watch('responseType')) !== 10 ? 7 : 3
                                }
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Example
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="example"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Example'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.example?.message}
                                onChange={(val) => onChange(val)}
                                isTextArea={true}
                                tabIndex={7}
                              />
                            );
                          }}
                        />
                      </Typography>

                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Issue/language
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="issuesLanguage"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Issue/language'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.issuesLanguage?.message}
                                onChange={(val) => onChange(val)}
                                isTextArea={true}
                                tabIndex={9}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Winning Strategy Note
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="winningStrategyNote"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Winning Strategy Note'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.winningStrategyNote?.message}
                                onChange={(val) => onChange(val)}
                                isTextArea={true}
                                tabIndex={11}
                              />
                            );
                          }}
                        />
                      </Typography>

                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Pashto Description (Optional)
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="pashtoNotes"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Pashto Description'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.pashtoNotes?.message}
                                onChange={(val) => onChange(val)}
                                isTextArea={true}
                                tabIndex={15}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Dari Description (Optional)
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="dariNotes"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={'Dari Description'}
                                name={name}
                                value={value}
                                className={'custom-textarea'}
                                error={errors.dariNotes?.message}
                                onChange={(val) => onChange(val)}
                                isTextArea={true}
                                tabIndex={17}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Typography>
            </form>

            {/* <Grid container>
              <Grid item md={12}>
                <Typography component={'div'} className="d-flex-ja my-2">
                  <Button className="cancel-btn mr-2" onClick={cancelForm}>
                    <Typography className="o-box" />
                    Cancel
                  </Button>
                  <Button
                    className="add-btn"
                    type="submit"
                    form={'add-question'}
                    disabled={
                      [4, 5, 6].includes(Number(watch('responseType'))) &&
                      selectedResponses.length === 0
                    }>
                    Save
                  </Button>
                </Typography>
              </Grid>
            </Grid> */}
          </>
        )}
        <ResponseTypeModal
          open={open}
          handleClose={handleClose}
          control={controlRT}
          errors={errorsRT}
          handleSubmit={handleSubmitRT}
          onSubmitForm={onSubmitResponseTypeForm}
          cancelForm={() => {
            resetFormRT();
            handleClose();
          }}
          fields={responseFields}
          fieldAppend={responeAppend}
          fieldRemove={responseFieldRemove}
        />
      </Grid>
      {searchParams.get('show') && (
        <Grid item md={2} sm={12}>
          <Typography component={'div'} className="add-box">
            <Typography component={'div'} className="add-inner-box" />
            <Typography
              className="built-option d-flex-a mt-1"
              onClick={() => {
                setDialogView(true);
              }}>
              {'Section Name'}
            </Typography>
          </Typography>
        </Grid>
      )}
      <CustomModalView
        open={dialogView}
        fullWidth={true}
        maxWidth={'sm'}
        scroll={'paper'}
        autoCloseDisable={true}
        title="Section Name"
        isFilters={true}
        filterOnChange={(searchvalue) => {
          setFilterSearch((pre) => ({
            ...pre,
            section_name: searchvalue,
          }));
        }}
        close={() => {
          setDialogView(false);
          setFilterSearch((pre) => ({
            ...pre,
            section_name: '',
          }));
        }}>
        {loader && <div className="liberty-loader" />}
        <DialogContent>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th scope="col" style={{ width: '50%' }}>
                  {'Name'}
                </th>
              </tr>
            </thead>
            <tbody>
              {sectionList && sectionList.length ? (
                sectionList.map((data, index) => (
                  <tr key={index} style={{ cursor: 'pointer' }}>
                    <td>
                      <a>{data?.section_name}</a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className={'text-center'}>
                  <th scope="row" colSpan={2}>
                    No Record(s) Found.
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
      </CustomModalView>
    </Grid>
  );
};

export default QuestionAdd;
