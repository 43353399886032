import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  avatorcss: {
    borderRadius: '50%',
    height: '60px',
    marginBottom: '16px',
    marginTop: '16px',
    objectFit: 'cover',
    width: '60px',
  },
  avatoricon: {
    cursor: 'pointer',
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  border: {
    backgroundColor: '#3357D9',
    height: '4px',
    width: '114px',
  },
  bottext: {
    color: '#343434',
    fontSize: '18px !important',
    opacity: ' 0.7',
    padding: '23px 0 23px 0',
  },
  cinput: {
    border: '1px solid #707070',
    borderRadius: '2px',
    height: '14px',
    margin: 'unset !important',
    padding: 'unset !important',
    width: '14px',
  },
  contactPbox: {
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '395px',
    padding: '40px 0px 51px 0px',
  },
  // contractPayBox: {
  //   backgroundColor: '#ffffff',
  //   border: '1px solid #ccc',
  //   borderRadius: '12px',
  //   minHeight: '200px',
  // },
  ctext: {
    color: '#333333 !important',
    fontSize: '15px !important',
    paddingLeft: '18px !important',
  },
  disable: {
    cursor: 'no-drop',
  },
  leadwiteBox: {
    backgroundColor: '#f2f2f2',
    borderRadius: '8px',
    marginBottom: '10px !important',
    padding: '16px 24px 24px 24px',
  },
  mymail: {
    color: '#343434 !important',
    fontSize: '18px !important',
    opacity: 0.4,
    paddingTop: '.5rem',
  },
  myname: {
    color: '#343434 !important',
    fontFamily: 'Arial-Bold important',
    fontSize: '26px !important',
    paddingTop: '.5rem',
  },
  myphoto: {
    border: '6px solid #D8D8D8',
    borderRadius: '50%',
    height: '199px',
    objectFit: 'cover',
    width: '199px',
  },
  mz: {
    margin: '4rem 0 1rem 0 !important',
  },
  newmodal: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    height: '80vh',
    left: '50%',
    padding: '24px',
    paddingTop: '0px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
  },
  pbox: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    minHeight: '130px',
    padding: '2rem',
  },
  pointer: {
    cursor: 'pointer',
  },
  profilePbox: {
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
    minHeight: '590px',
  },
  rbox: {
    alignItems: 'center',
    border: '1px solid #CCCCCC',
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px !important',
    minHeight: '50px',
    padding: '0 2rem',
  },
  rolePbox: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    marginBottom: '8px !important',
    minHeight: '106px',
    padding: '24px 70px 8px 70px',
  },
  rtext: {
    color: '#343434 !important',
    fontSize: '1.06em !important',
    fontWeight: '600 !important',
  },
  scrollbox: {
    // backgroundColor: '#FFFFFF',
    maxHeight: '70vh',
    minHeight: '106px',
    overflow: 'auto',
  },
  sigContainer: {
    backgroundColor: '#FFFFFF',
    margin: '0 auto',
  },
  subscriptionBox: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '70vh',
    padding: '4.5rem 4.5rem 4.5rem 4.5rem',
  },
  tbox: {
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    minHeight: '106px',
  },
  userImg: {
    backgroundColor: '#D3D4D5',
    borderRadius: '50%',
    height: '36px',
    objectFit: 'cover',
    width: '36px',
  },
  userItemInner: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    bottom: '0',
    left: '0',
    minHeight: '67px',
    opacity: '0.15',
    position: 'absolute',
    right: '0',
    top: '0',
    transition: '.5s',
  },
}));

export default useStyles;
