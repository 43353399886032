export class NavBarLists {
  public static navBarIcons: Array<{
    icon: string;
    menuList: string;
    menuListId: number;
    subnav: Array<any>;
    flag: boolean;
    routerLink: string;
  }> = [
    // {
    //   flag: false,
    //   icon: '/assets/images/dashboard.png',
    //   menuList: 'Dashboard',
    //   menuListId: 1,
    //   routerLink: '/dashboard',
    //   subnav: [],
    // },
    {
      flag: false,
      icon: '/assets/images/profile.png',
      menuList: 'My Profile',
      menuListId: 2,
      routerLink: '/profile',
      subnav: [],
    },
    {
      flag: true,
      icon: '/assets/images/setup.png',
      menuList: 'Setup',
      menuListId: 3,
      routerLink: '#',
      subnav: [
        {
          menu: 'Roles & Permissions',
          routerLink: '/setup/roles-permissions',
          screenId: 3,
        },
        {
          menu: 'Manage Users',
          routerLink: '/setup/manage-users',
          screenId: 4,
        },
        {
          menu: 'Manage Petitioners',
          routerLink: '/setup/petitioner',
          screenId: 5,
          tabList: [
            {
              add_router: '',
              tabId: 7,
              tabName: 'Petitioner',
            },
            {
              add_router: '/custom-fields',
              tabId: 8,
              tabName: 'Custom Fields',
            },
          ],
        },
        {
          menu: 'Manage Law Firms',
          routerLink: '/setup/law-firm',
          screenId: 6,
          tabList: [
            {
              add_router: '',
              tabId: 10,
              tabName: 'Law firm',
            },
            {
              add_router: '/add/lawFirm-custom-fields',
              tabId: 9,
              tabName: 'Custom Fields',
            },
          ],
        },
        {
          menu: 'Visa Types',
          routerLink: '/setup/visa-types',
          screenId: 7,
        },
        {
          menu: 'Configure Questions',
          routerLink: '/setup/configure-questions',
          screenId: 8,
        },
        {
          menu: 'Configure Sections',
          routerLink: '/setup/configure-section',
          screenId: 9,
        },
        {
          menu: 'Document Type',
          routerLink: '/setup/document-types',
          screenId: 10,
        },
        // New Added
        {
          menu: 'My Firm',
          routerLink: '/setup/my-firm',
          screenId: 17,
        },
        // {
        //   menu: 'Sections',
        //   routerLink: '#',
        //   screenId: 18,
        // },
        {
          menu: 'Document Templates',
          routerLink: '/setup/document-template',
          screenId: 19,
        },
        {
          menu: 'Manage Organization',
          routerLink: '/manage-organization',
          screenId: 20,
        },
        {
          menu: 'My Organization',
          routerLink: '/setup/my-organization',
          screenId: 30,
        },
        // {
        //   menu: 'My Organization Edit',
        //   routerLink: '/setup/my-organization/edit',
        //   screenId: 30,
        // },
        {
          menu: 'Associated Law Firm',
          routerLink: '/setup/associated-law-firm',
          screenId: 31,
        },
        // {
        //   screenId: 34,
        //   menu: 'Define Checklist',
        //   routerLink: '/setup/define-checklist'
        // },
        // Development mode
        // {
        //   menu: 'Configure Forms',
        //   routerLink: '/setup/configureform-manage',
        //   screenId: 36,
        // },
        // Local mode
        // {
        //   menu: 'Configure Forms',
        //   routerLink: '/setup/configureform-manage',
        //   screenId: 37,
        // },
        {
          menu: 'Contract Fees',
          routerLink: '/setup/contract-fees',
          screenId: 39,
        },
        {
          menu: 'My Subscriptions',
          routerLink: '/setup/my-subscription',
          screenId: 42,
        },
        {
          menu: 'Resource Station',
          routerLink: '/setup/resource-station',
          screenId: 43,
        },
      ],
    },
    {
      flag: true,
      icon: '/assets/images/transaction.png',
      menuList: 'Petitions',
      menuListId: 4,
      routerLink: '#',
      subnav: [
        {
          menu: 'Leads',
          routerLink: '/petitions/leads',
          screenId: 11,
        },
        // {
        //   screenId: 12,
        //   menu: 'Cases',
        //   routerLink: '#'
        // },
        {
          menu: 'Assign Attorney',
          routerLink: '/petitions/assign-attorney',
          screenId: 13,
        },
        {
          menu: 'Strategy Session',
          routerLink: '/petitions/schedule-strategy-session',
          screenId: 14,
        },
        {
          menu: 'Track Case Status',
          routerLink: '/petitions/track-case-status',
          screenId: 15,
        },
        // New Added
        {
          menu: 'Request Documents',
          routerLink: '/petitions/request-documents',
          screenId: 21,
        },
        // {
        //   screenId: 22,
        //   menu: 'View / Fill Forms',
        //   routerLink: '/petitions/view-fill-forms'
        // },
        {
          menu: 'Upload Documents',
          routerLink: '/petitions/upload-documents',
          screenId: 23,
        },
        {
          menu: 'Document Checklist',
          // Change Name Review Documents TO Document Checklist
          routerLink: '/petitions/document-checklist',
          screenId: 24,
        },
        {
          menu: 'Generate Documents',
          routerLink: '/petitions/generate-document',
          screenId: 25,
        },
        // {
        //   screenId: 26,
        //   menu: 'Document Checklist',
        //   routerLink: '/petitions/document-checklist'
        // },
        {
          menu: 'Submission status',
          routerLink: '/petitions/submission-status',
          screenId: 27,
        },
        {
          menu: 'Petitions',
          routerLink: '/petitions/my-petitions',
          screenId: 28,
        },
        {
          menu: 'Get Leads',
          routerLink: '/petitions/get-leads',
          screenId: 29,
        },
        {
          menu: 'Add Stakeholder / Beneficiary',
          routerLink: '/petitions/add-stakeholders',
          screenId: 32,
        },
        {
          menu: 'Document Parsing',
          routerLink: '/petitions/document-parsing-list',
          screenId: 37,
        },
        // Development
        {
          menu: 'Contract',
          routerLink: '/petitions/contract',
          screenId: 35,
        },
        // Local
        {
          menu: 'Contract',
          routerLink: '/petitions/contract',
          screenId: 46,
        },
        {
          menu: 'Reports',
          routerLink: '/reports',
          screenId: 40,
        },
        {
          menu: 'Winning Strategy',
          routerLink: '/winning-strategy/56',
          screenId: 41,
        },
      ],
    },
    {
      flag: false,
      icon: '/assets/images/service1.png',
      menuList: 'Contact Support',
      menuListId: 6,
      routerLink: `mailto:${String(process.env.REACT_APP_CONTACT_MAIL)}`,
      subnav: [],
    },
    {
      flag: false,
      icon: '/assets/images/reports.png',
      menuList: 'Stats & Reports',
      menuListId: 5,
      routerLink: '/stats-reports',
      subnav: [],
    },
  ];
}
