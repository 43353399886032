/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import {
  AdminPrivateRoutes,
  AttorneyPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppStyles } from 'style/variable';
import { ModuleList } from 'types';

type Props = {
  anchorEl: any;
  open: boolean;
  handleClose: (src: any) => void;
  addButtonShow?: boolean;
  menuList: Array<ModuleList>;
  roleId: number;
  currentRoleId: number;
};
export const RolesModuleMenu: React.FC<Props> = (props: Props) => {
  const {
    anchorEl,
    open,
    handleClose,
    addButtonShow = false,
    menuList,
    roleId,
    currentRoleId = 1,
  } = props;
  const navigate = useNavigate();

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          '&:before': {
            bgcolor: AppStyles.background.blue,
            content: '""',
            display: 'block',
            height: 10,
            position: 'absolute',
            right: 14,
            top: 0,
            transform: 'translateY(-50%) rotate(45deg)',
            width: 10,
            zIndex: 0,
          },
          backgroundColor: AppStyles.background.blue,
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          minWidth: '220px',
          overflow: 'visible',
          padding: '8px 18px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <Typography
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '250px',
          overflow: 'auto',
        }}>
        {menuList && menuList.length
          ? menuList.map((e, index) => (
              <MenuItem
                key={index}
                sx={{
                  color: AppStyles.background.primary,
                  cursor: addButtonShow ? 'pointer' : 'not-allowed',
                  fontSize: '16px',
                  margin: '8px 0px',
                }}
                {...(addButtonShow
                  ? {
                      onClick: () =>
                        navigate(
                          roleId === 1
                            ? `${AdminPrivateRoutes.ROLES.ROLESEDIT}/${e.id}`
                            : roleId === 3
                            ? `${AttorneyPrivateRoutes.ROLES.ROLESEDIT}/${e.id}`
                            : roleId === 2
                            ? `${PetitionerPrivateRoutes.ROLES.ROLESEDIT}/${e.id}`
                            : '',
                        ),
                    }
                  : {})}>
                {e.role_name}
              </MenuItem>
            ))
          : null}
      </Typography>
      {addButtonShow && (
        <MenuItem
          sx={{
            display: 'flex',
            fontSize: '16px',
            justifyContent: 'center',
            margin: '8px 0px',
          }}>
          <Button
            onClick={() =>
              navigate(
                roleId === 1
                  ? `${AdminPrivateRoutes.ROLES.ROLESADD}/${currentRoleId}`
                  : roleId === 3
                  ? AttorneyPrivateRoutes.ROLES.ROLESADD
                  : roleId === 2
                  ? PetitionerPrivateRoutes.ROLES.ROLESADD
                  : '',
              )
            }
            className="n-add-btn"
            sx={{
              backgroundColor: '#20328A',
              borderRadius: '5px !important',
              height: '33px !important',
              marginTop: '15px',
              position: 'fixed',
              textAlign: 'center',
              textTransform: 'capitalize !important',
              width: '165px !important',
            }}>
            Add
          </Button>
        </MenuItem>
      )}
    </Menu>
  );
};
