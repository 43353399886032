import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

// import useStyles from '../../PrivateStyle';

const Socialshare: React.FC<any> = () => {
  const [Videos, setVideos] = useState<any>([]);
  // const classes = useStyles();

  async function ens() {
    try {
      const response = await fetch(
        'https://www.googleapis.com/youtube/v3/search?part=snippet&type=video&order=date&safeSearch=none&maxResults=50&channelId=UCJz9OmWUTz6JYbFmsLWRg2w&key=AIzaSyCEA3aAOTbX_y9cmmSP9yaPkZXvf-3-LVQ',
      );

      const res = await response.json();

      setVideos(res['items']);
    } catch (err) {
      // console.log(err);
    }
  }

  useEffect(() => {
    ens();
    (window as any)?.twttr?.widgets?.load();
  }, []);

  return (
    <Grid
      container
      columnSpacing={{ md: 2, sm: 1 }}
      style={{
        textAlign: 'center',
        width: '100%',
      }}>
      {/* <Grid item md={10} sm={12}> */}
      <Grid item md={4} sm={12}>
        <Typography
          component={'div'}
          style={{
            // background: 'rgba(0, 123, 255, 1)',
            background: 'white',
            borderRadius: '10px',
            height: '654',
            overflowY: 'auto',
          }}>
          <div className="facebook-feeds" style={{ background: 'white' }}>
            {/* <div
                style={{
                  backgroundColor: '#3b5998',
                  borderBottom: '1px solid #e7eaf0',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  color: '#ffffff',
                  padding: '17px 0px 17px 27px',
                }}>
                Facebook
              </div> */}
            {/* <div>
                <iframe
                  src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&width=90&layout=button_count&action=like&size=small&share=false&height=21&appId"
                  width="90"
                  height="21"
                  style={{ border: 'none', overflow: 'hidden' }}
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen={true}
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                />
              </div> */}
            <div
              className="row"
              style={{
                alignItems: 'center',
                backgroundColor: '#007bff',
                borderBottom: '1px solid #e7eaf0',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                color: ' #ffffff',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 1rem',
              }}>
              <div className="col-md-8">
                <h4 className="">Facebook</h4>
              </div>
              <div className="col-md-4">
                {/* <div
                    className="follow"
                    style={{ height: '16px', marginTop: '-2px' }}> */}
                <div className="follow" style={{ height: '16px' }}>
                  {/* <iframe
                      src="https://www.facebook.com/plugins/like.php?href=https://www.facebook.com/OnlineVisasInc"
                      width="99"
                      height="21"
                      style={{ border: 'none', overflow: 'hidden' }}
                      scrolling="no"
                      frameBorder="0"
                      allowFullScreen={true}
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    /> */}

                  <iframe
                    src="https://www.facebook.com/plugins/like.php?href=https://www.facebook.com/OnlineVisasInc"
                    width="220"
                    height="65"
                    style={{ border: 'none', overflow: 'hidden' }}
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  />
                </div>
              </div>
            </div>

            <div className="dis-block">
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/OnlineVisasInc&tabs=timeline&width=380&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="380"
                height="600"
                style={{ border: 'none', overflow: 'hidden' }}
                scrolling="no"
                frameBorder="0"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              />
            </div>

            {/* <div className="dis-none">
                <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/OnlineVisasInc&tabs=timeline&width=400&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                  width="400"
                  height="600"
                  style={{ border: 'none', overflow: 'hidden' }}
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen={true}
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                />
              </div> */}
          </div>
        </Typography>
      </Grid>
      <Grid item md={4} sm={12}>
        <Typography
          component={'div'}
          style={{
            background: 'white',
            borderRadius: '10px',
            height: '654',
          }}>
          <div
            className=""
            style={{
              flex: 2,
              height: '672px',
              overflowY: 'auto',
            }}>
            <div>
              <div className="right-side-view m-top-10">
                <div className="tweets-heading-section">
                  <div
                    className="row"
                    style={{
                      alignItems: 'center',
                      background: 'rgb(67, 161, 242, 1)',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '0 1rem',
                      verticalAlign: 'middle',
                      width: '100%',
                    }}>
                    <div className="col-md-8">
                      <h4
                        className="tweet-msg"
                        style={{
                          color: 'white',
                          transform: 'scale(0.9) translateY(-2.5px)',
                        }}>
                        Tweets by OnlineVisas
                      </h4>
                    </div>
                    <div className="col-md-4">
                      <div className="follow">
                        <a
                          href="https://twitter.com/OnlineVisas?ref_src=twsrc%5Etfw"
                          className="twitter-follow-button"
                          data-size="large"
                          data-show-screen-name="false"
                          data-show-count="false">
                          Follow @OnlineVisas
                        </a>
                        <script
                          async
                          src="https://platform.twitter.com/widgets.js"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    overflowY: 'auto',
                    transform: 'scale(0.999)',
                  }}>
                  <div
                    className="twitter-feeds1"
                    style={{
                      height: '570px',
                      marginTop: '5px',
                    }}>
                    <a
                      className="twitter-timeline"
                      data-chrome="noscrollbar nofooter noheader"
                      href="https://twitter.com/OnlineVisas?ref_src=twsrc%5Etfw"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Typography>
      </Grid>
      <Grid item md={4} sm={12}>
        <Typography
          component={'div'}
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            height: '654',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
          <div
            className="youtube-feeds"
            style={{
              background: 'rgba(255, 0, 0, 1)',
              padding: '17px 0px',
              paddingBottom: '0px',
            }}>
            <div className="yheader">
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  fontWeight: 600,
                  justifyContent: 'space-between',
                  marginTop: '9px',
                  padding: '5px 1rem',
                  transform: 'translateY(-15px)',
                }}>
                <span style={{ color: 'white' }}>YouTube</span>
                <div className="subs" style={{ marginTop: '-4px' }}>
                  <div
                    className="g-ytsubscribe"
                    data-channelid="UCJz9OmWUTz6JYbFmsLWRg2w"
                    data-layout="default"
                    data-count="default"
                  />
                  <iframe
                    // hspace={0}
                    marginHeight={0}
                    marginWidth={0}
                    scrolling="no"
                    style={{
                      borderStyle: 'none',
                      height: '24px',
                      left: '0px',
                      margin: '0px',
                      position: 'static',
                      top: '0px',
                      visibility: 'visible',
                      width: ' 111px',
                    }}
                    tabIndex={0}
                    // vspace={0}
                    id="I0_1662533859187"
                    name="I0_1662533859187"
                    src="https://www.youtube.com/subscribe_embed?usegapi=1&amp;channelid=UCJz9OmWUTz6JYbFmsLWRg2w&amp;count=default&amp;layout=default&amp;origin=https%3A%2F%2Fstage-liberty.itechlabs.net&amp;gsrc=3p&amp;ic=1&amp;jsh=m%3B%2F_%2Fscs%2Fabc-static%2F_%2Fjs%2Fk%3Dgapi.lb.en.z9QjrzsHcOc.O%2Fd%3D1%2Frs%3DAHpOoo8359JQqZQ0dzCVJ5Ui3CZcERHEWA%2Fm%3D__features__#_methods=onPlusOne%2C_ready%2C_close%2C_open%2C_resizeMe%2C_renderstart%2Concircled%2Cdrefresh%2Cerefresh%2Conload&amp;id=I0_1662533859187&amp;_gfid=I0_1662533859187&amp;parent=https%3A%2F%2Fstage-liberty.itechlabs.net&amp;pfname=&amp;rpctoken=34928097"
                    data-gapiattached="true"
                    width="100%"
                    frameBorder="0"
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                alignContent: 'center',
                alignItems: 'center',
                background: 'white',
                display: 'flex',
                flexDirection: 'column',
                height: '615px',
                overflowX: 'hidden',
                overflowY: 'auto',
                padding: '5px',
              }}>
              {Videos?.map((video, i) => (
                <div
                  className="ypdetailbox"
                  style={{
                    borderBottom: '2px solid #80808012',
                    paddingBottom: '4px',
                    width: '100%',
                  }}
                  key={i}>
                  <div className="ydetailbox" style={{ margin: '5px' }}>
                    <div className="yinfo">
                      <div>
                        <h5 style={{ margin: '10px' }}>
                          <a href="https://www.youtube.com/watch?v={{video.id.videoId}}">
                            {/* {video.snippet.title} */}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: video.snippet.title,
                              }}
                            />
                          </a>
                        </h5>
                      </div>

                      {/* <div>{{video.snippet.publishedAt|date:'dd MMM, yyyy'}}</div> */}
                      <div
                        style={{
                          color: '#9e9292',
                          fontSize: '12px',
                          margin: '12px',
                        }}>
                        {video.snippet.publishedAt &&
                          moment(video.snippet.publishedAt).format(
                            'dddd, MMMM Do YYYY, h:mm A',
                          )}
                      </div>
                    </div>
                    <div className="w-160">
                      <a
                        target="_blank"
                        href={`https://www.youtube.com/watch?v=${String(
                          video.id.videoId,
                        )}`}
                        rel="noreferrer">
                        <img
                          src={video.snippet.thumbnails.medium.url}
                          className="thumbimage"
                          alt="..."
                          style={{ width: '100%' }}
                        />
                      </a>
                    </div>
                    <div className="text-break" style={{ margin: '10px' }}>
                      {video.snippet.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Typography>
      </Grid>
    </Grid>
  );
};
export default Socialshare;
