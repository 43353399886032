import OpenWithIcon from '@mui/icons-material/OpenWith';
import { Box, Button, Grid, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';

import { alertOptions } from './../../../../../utils/helper';

export const Rearrange = () => {
  const navigate = useNavigate();
  const [visaTypes, setVisaTypes] = useState<any>([]);
  const [dragData, setDragData] = useState<any>([]);
  const [typesData, setTypesData] = useState<any>([]);
  const [selectId, setSelectId] = useState<any>('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getAllListData(COMMON_ENDPOINT.VisaTypes)
      .then((resp: any) => {
        setVisaTypes(resp.data);
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectId) {
      getAllListData(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${SETUP_ENDPOINT.DocumentTypeArrange}/${selectId}`,
      ).then((resp) => {
        setTypesData(resp);
        setLoader(false);
      });
    }
  }, [selectId]);

  const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const temp = [...typesData];
    const obj = temp[result.source.index];
    temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, obj);

    setTypesData(temp);
    const documentOrder = [];

    temp.map((val, index) =>
      documentOrder.push({
        documentId: val.id,
        order: index,
        visaId: selectId,
      }),
    );

    setDragData(documentOrder);
  };
  const onSubmit = (data: any) => {
    if (selectId) {
      if (!_.isEmpty(dragData)) {
        setLoader(true);
        updateData(
          selectId,
          {
            list: dragData,
          },
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `${SETUP_ENDPOINT.DocumentTypeArrange}`,
        )
          .then((resp: any) => {
            toast.success('Order Reassigned Successfully', alertOptions);
            setLoader(false);
          })
          .catch((err) => setLoader(false));
      } else {
        toast.error('Please rearrange the format');
      }
    } else {
      toast.error('Please Select Visa Type');
    }
  };
  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12} className="pr-2rem">
        <Box sx={{ width: '100%' }} className="documentTab">
          <Grid item md={12} sm={12}>
            <Typography component={'div'} className="d-flex-jae mb-2 mt-2">
              <Typography component={'div'} className="d-flex-a">
                <Typography
                  component={'div'}
                  sx={{ marginRight: '.5rem', width: '255px' }}>
                  <CustomSelect
                    searchable={true}
                    labelKey={'visa_code'}
                    valueKey={'visa_id'}
                    options={!_.isEmpty(visaTypes) ? visaTypes : []}
                    onChange={(e) => {
                      setSelectId(e.target.value);
                      if (!e.target.value) setTypesData('');
                      setDragData([]);
                    }}
                    placeHolder={'Select Visa Type'}
                    className={'custom-search-input bo-rles'}
                  />
                </Typography>
                <Typography component={'div'} className="d-flex-a">
                  <Button
                    className="n-cancel-btn mr-2"
                    onClick={() => navigate(-1)}>
                    <Typography className="o-box" />
                    Cancel
                  </Button>
                  <Button
                    className="n-add-btn"
                    type="button"
                    onClick={onSubmit}>
                    Submit
                  </Button>
                </Typography>
              </Typography>
            </Typography>
          </Grid>
          <form>
            {loader ? (
              <div className="liberty-loader" />
            ) : (
              <table className="lead-table table-hover">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>document type</th>
                    <th> valid documents</th>
                  </tr>
                </thead>

                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: DroppableProvided) => (
                      <tbody
                        style={{ cursor: 'pointer' }}
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}>
                        {typesData && typesData.length > 0 ? (
                          typesData.map((item, index) => (
                            <Fragment key={index}>
                              <Draggable
                                key={item.id}
                                draggableId={String(item.id)}
                                index={index}>
                                {(
                                  draggableProvided: DraggableProvided,
                                  snapshot: DraggableStateSnapshot,
                                ) => {
                                  return (
                                    <tr
                                      key={index}
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps
                                          .style,
                                        background: snapshot.isDragging
                                          ? 'rgba(0,0,0,.075)'
                                          : '',
                                        cursor: 'pointer',
                                      }}>
                                      <td>
                                        <div
                                          {...draggableProvided.dragHandleProps}>
                                          <OpenWithIcon />
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className="hideshow"
                                          style={{
                                            width: '255px',
                                            wordBreak: 'break-all',
                                          }}>
                                          {item.document_type
                                            ? item.document_type
                                            : '-'}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className="hideshow"
                                          style={{
                                            width: '255px',
                                            wordBreak: 'break-all',
                                          }}>
                                          {item.valid_documents
                                            ? item.valid_documents
                                            : '-'}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }}
                              </Draggable>
                            </Fragment>
                          ))
                        ) : (
                          <tr className={'text-center'}>
                            <td colSpan={4}>No Record(s) Found.</td>
                          </tr>
                        )}
                        {droppableProvided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </table>
            )}
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};
