import {
  Button,
  Checkbox,
  FormHelperText,
  // MenuItem,
  // Select,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CustomSelect from 'components/CustomSelect/customSelect';
import { Loader } from 'components/loader';
import {
  AdminPrivateRoutes,
  AttorneyPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { RolesModuleList, RolesScreen } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import useStyles from '../../../PrivateStyle';

export const RoleAdd: React.FC<any> = () => {
  const classes = useStyles();
  const { roleId, editId } = useParams();
  const navigation = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const [moduleList, setModuleList] = useState<Array<RolesModuleList>>([]);
  const [module, setmodule] = useState<{ roleName: string; moduleId: string }>({
    moduleId: '',
    roleName: '',
  });
  const [submit, setSubmit] = useState<boolean>(false);
  const [screenList, setScreenList] = useState<Array<RolesScreen>>([]);
  const [loding, setLoading] = useState<boolean>(false);

  const getModuleListApi = useCallback(() => {
    getAllListData(COMMON_ENDPOINT.AdminRoles)
      .then((response: any) => {
        setModuleList(response.filter((role) => role.module_id !== 5));
      })
      .catch((e) => {});
  }, []);

  const getScreenListApi = useCallback((roleId: number) => {
    getAllListData(`${COMMON_ENDPOINT.GetAdminRoleById}/${roleId}`)
      .then((response: any) => {
        setScreenList(response);
      })
      .catch((e) => {});
  }, []);
  const getModuleById = useCallback((rolesMappingId: string) => {
    getAllListData(`${COMMON_ENDPOINT.RoleMap}/${rolesMappingId}`)
      .then((response: any) => {
        setScreenList(response.rolesList);
        setmodule((pre) => ({
          ...pre,
          moduleId: response.moduleId,
          roleName: response.roleName,
        }));
      })
      .catch((e) => {});
  }, []);
  const valueUpdate = (
    checked: boolean,
    mainIndex: number,
    screenIndex: number,
  ) => {
    const arr = [...screenList];
    arr[mainIndex].screenList[screenIndex].allow = checked;
    if (
      !checked &&
      arr[mainIndex].screenList[screenIndex].tabList &&
      arr[mainIndex].screenList[screenIndex].tabList.length
    ) {
      arr[mainIndex].screenList[screenIndex].tabList = arr[
        mainIndex
      ].screenList[screenIndex].tabList.map((e) => {
        return { ...e, allow: false };
      });
    }
    setScreenList([...arr]);
  };
  const tabValueUpdate = (
    checked: boolean,
    mainIndex: number,
    screenIndex: number,
    tabIndex: number,
  ) => {
    const arr = [...screenList];
    arr[mainIndex].screenList[screenIndex].tabList[tabIndex].allow = checked;
    setScreenList([...arr]);
  };

  const onSubmit = async () => {
    setSubmit(true);
    if (!module.moduleId || !module.roleName) {
      return;
    }

    if (!screenList.some((e) => e.screenList.some((screen) => screen.allow))) {
      toast.error(
        'Please select atleast one permission.Dashboard must be checked',
        alertOptions,
      );
    } else if (
      screenList.some((e) =>
        e.screenList.some(
          (screen) =>
            screen.allow &&
            screen.tabList &&
            screen.tabList.length &&
            screen.tabList.every((tab) => !tab.allow),
        ),
      )
    ) {
      toast.error('Please select atleast one Petitions TAB.', alertOptions);
    } else if (
      screenList.some((e) =>
        e.screenList.find((screen) => !screen.allow && screen.screen_id === 1),
      )
    ) {
      toast.error('Please Select Dashboard', alertOptions);
    } else {
      setLoading(true);
      const bodyData = getFormData();
      if (editId) {
        await updateData('', bodyData, COMMON_ENDPOINT.RoleMap)
          .then((resp: any) => {
            toast.success('Role updated successfully.', alertOptions);
            setLoading(false);
            setSubmit(false);
            cancel();
          })
          .catch((e) => {
            setLoading(false);
          });
      } else {
        await addNewData(bodyData, COMMON_ENDPOINT.RoleMap)
          .then((resp: any) => {
            toast.success('Role added successfully.', alertOptions);
            setLoading(false);
            setSubmit(false);
            cancel();
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    }
  };

  const getFormData = () => {
    const { screenIds, tabIds } = getScreenIds();
    return {
      roleId: editId ? Number(editId) : Number(module.moduleId),
      roleName: module.roleName,
      screenIds: screenIds.toString(),
      tabId: tabIds,
    };
  };
  const getScreenIds = (): { tabIds: Array<any>; screenIds: Array<any> } => {
    const screenIds = [];
    const tabIds = [];
    screenList.map((e) => {
      e.screenList.map((screen) => {
        if (screen.allow) {
          screenIds.push(screen.screen_id);
          if (screen.tabList && screen.tabList.length && screen.allow) {
            screen.tabList.map((tab) => {
              if (tab.allow) tabIds.push(tab.tab_id);
            });
          }
        }
      });
    });
    return { screenIds, tabIds };
  };
  const cancel = () => {
    navigation(
      userDetail && userDetail.role_id === 1
        ? AdminPrivateRoutes.ROLES.ROLESPERMISSION
        : userDetail && userDetail.role_id === 3
        ? AttorneyPrivateRoutes.ROLES.ROLESPERMISSION
        : userDetail && userDetail.role_id === 2
        ? PetitionerPrivateRoutes.ROLES.ROLESPERMISSION
        : '',
    );
  };
  useEffect(() => {
    if (editId) {
      getModuleById(editId);
    } else {
      setmodule((pre) => ({
        ...pre,
        moduleId: roleId ? roleId : String(userDetail.role_id),
      }));
      getScreenListApi(
        roleId ? Number(roleId) : userDetail && userDetail.role_id,
      );
    }
    getModuleListApi();
  }, [
    editId,
    getModuleById,
    getModuleListApi,
    getScreenListApi,
    roleId,
    userDetail,
  ]);

  if (!moduleList || !moduleList.length || loding) {
    return <Loader />;
  }
  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12}>
        <Typography component={'div'} className={classes.rolePbox}>
          <Box component="form" noValidate autoComplete="off">
            <Grid container columnSpacing={{ md: 2, sm: 1 }}>
              <Grid item md={6}>
                <Typography component={'div'} className="custom-field">
                  <Typography component={'div'} className="custom-label">
                    Role
                    <Typography component={'span'} className="Mui-error">
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    className="custom-input"
                    placeholder="First Name"
                    value={module.roleName}
                    onChange={(e) =>
                      setmodule((pre) => ({
                        ...pre,
                        roleName: e.target.value,
                      }))
                    }
                  />
                  <FormHelperText error={true}>
                    {submit && !module.roleName ? 'Role is required' : ''}
                  </FormHelperText>
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography component={'div'} className="custom-field">
                  <Typography component={'div'} className="custom-label">
                    Module
                  </Typography>
                  <CustomSelect
                    id="demo-simple-select-standard"
                    options={moduleList}
                    labelKey={'module_name'}
                    valueKey={'module_id'}
                    value={module.moduleId}
                    disabled={
                      (userDetail && userDetail.role_id !== 1) || !!editId
                    }
                    isClearable={false}
                    error={submit && !module.moduleId}
                    onChange={(e) => {
                      setmodule((pre) => ({
                        ...pre,
                        moduleId: e.target.value,
                      })),
                        getScreenListApi(Number(e.target.value));
                    }}
                  />
                  {/* {moduleList && moduleList.length
                      ? moduleList.map((e, index) => (
                          <MenuItem value={e.module_id} key={index}>
                            {e.module_name}
                          </MenuItem>
                        ))
                      : null} */}
                  {/* ></CustomSelect> */}
                  <FormHelperText error={true}>
                    {submit && !module.moduleId ? 'Module is required' : ''}
                  </FormHelperText>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Typography>
        <Typography component={'div'} className={classes.tbox}>
          <table className="lead-table table-hover">
            <thead>
              <tr>
                <th>SUB MODULE</th>
                <th>SCREEN</th>
                <th>TAB Name</th>
                <th>TAB Access</th>
                <th>ACCESS</th>
                <th>DENY</th>
              </tr>
            </thead>
            <tbody>
              {screenList && screenList.length
                ? screenList.map((val, mainIndex) =>
                    val.screenList.map((screen, screenIndex) => (
                      <React.Fragment key={screenIndex}>
                        <tr>
                          <td>
                            {screenIndex === 0 ? (
                              <span>{val.moduleName} </span>
                            ) : null}
                          </td>
                          <td>{screen?.screen_name}</td>
                          <td>-</td>
                          <td>-</td>
                          <td>
                            <Checkbox
                              sx={{ padding: 'unset' }}
                              checked={screen?.allow}
                              disabled={screen?.allow}
                              onChange={(e) =>
                                valueUpdate(
                                  e.target.checked,
                                  mainIndex,
                                  screenIndex,
                                )
                              }
                            />
                          </td>
                          <td>
                            <Checkbox
                              sx={{ padding: 'unset' }}
                              checked={!screen?.allow}
                              disabled={!screen?.allow}
                              onChange={(e) =>
                                valueUpdate(
                                  !e.target.checked,
                                  mainIndex,
                                  screenIndex,
                                )
                              }
                            />
                          </td>
                        </tr>
                        {screen.tabList && screen.tabList.length
                          ? screen.tabList.map((subTab, tabIndex) => (
                              <tr key={tabIndex}>
                                <th />
                                <td />
                                <td>{subTab?.tab_name}</td>
                                <td>
                                  <Checkbox
                                    sx={{ padding: 'unset' }}
                                    checked={subTab?.allow}
                                    disabled={!screen?.allow}
                                    onChange={(e) =>
                                      tabValueUpdate(
                                        e.target.checked,
                                        mainIndex,
                                        screenIndex,
                                        tabIndex,
                                      )
                                    }
                                  />
                                </td>
                                <td />
                                <td />
                              </tr>
                            ))
                          : null}
                      </React.Fragment>
                    )),
                  )
                : null}
            </tbody>
          </table>
        </Typography>
        <Grid container className="bottom-fixed">
          <Grid item md={12}>
            <Typography component={'div'} className="d-flex-jae ">
              <Button className="cancel-btn mr-2" onClick={() => cancel()}>
                <Typography component={'div'} className="o-box" />
                Cancel
              </Button>
              <Button className="add-btn" onClick={() => onSubmit()}>
                Save
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RoleAdd;
