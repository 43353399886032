import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { yupResolver } from '@hookform/resolvers/yup';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import Ovie from 'assets/images/ovie.png';
import Closeicon from 'assets/images/wclose.svg';
import DocIcon from 'assets/images/wdoc.svg';
// import { CKEditor } from 'ckeditor4-react';
import InvoiceLoader from 'components/ContentLoader/Invoice';
import CustomInput from 'components/CustomInput/customInput';
import Footer from 'components/Footer';
import PdfUrlViewer from 'components/PdfViewer/PdfUrlViewer';
import { AttorneyPrivateRoutes } from 'config';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  getS3file,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { rfeRespondStep3 } from 'utils/ValidatorSchema';

export const RfeResponseAccept: React.FC<any> = () => {
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const navigate = useNavigate();
  const { petitionId, documentId } = useParams();
  const [openSug, setOpenSug] = useState(false);

  const [scale] = useState<number>(2);
  const [page, setPage] = useState<number>(1);
  // const [total, setTotal] = useState<number>(0);
  const [pdf, setPdf] = useState<any>(null);
  const [images, setImages] = useState([]);
  const [PDFloader, setPDFLoader] = useState(true);
  const [acceptDisable, setAcceptDisable] = useState(false);
  const [responseLoder, setResponseLoder] = useState(false);
  const [issueCount, setIssueCount] = useState<number>(0);
  const [responseCount, setResponseCount] = useState<number>(0);
  const [rfeResult, setRFEResult] = useState({
    acceptedCount: 0,
    created_at: '',
    data: [],
    filePath: '',
    file_name: '',
    total: 0,
  });
  const [issueSubResult, setIssueSubResult] = useState([]);
  const [PDFUrl, setPDFUrl] = useState<any>('');
  const [loader, setLoader] = useState(true);
  const [suggestions, setSuggestions] = useState<any>({
    query: '',
    recommended_answers: [],
    rfe_question: '',
  });
  const [activeResponse, setActiveResponse] = useState<string>('');
  // const [editor, setEditor] = useState<any | null>(null);
  const [selectedPageData, setSelectedPageData] = useState<any>([]);
  const windowRef: any = useRef();

  const {
    control: control,
    handleSubmit: handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      id: '',
      issueResponse: '',
      issueStatus: 'pending',
      issueText: '',
      issueTitle: '',
      predIssue: '',
      predSubIssue: '',
      responseType: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(rfeRespondStep3),
  });

  // const handleInstanceReady = ({ editor }: any) => {
  //   console.log('editor!!!!', editor);
  //   setEditor(editor);
  //   setValue('issueResponse', getValues('issueResponse'));
  //   editor && editor.setData(getValues('issueResponse'));
  // };

  const refIssueUpdate = (payload, events) => {
    updateData(
      '',
      payload,
      `${String(COMMON_ENDPOINT.rfeResponder)}/rfe-response`,
    )
      .then((resp: any) => {
        if (events === 'submit' && payload.accepted) {
          getDocument();
          toast.success('Issue updated successfully', alertOptions);
        }
        setAcceptDisable(false);
      })
      .catch((e) => {
        setAcceptDisable(false);
      });
  };

  const onBlurToSubmit = (key, value) => {
    const payload = {
      accepted: getValues('issueStatus') === 'pending' ? false : true,
      id: getValues('id'),
      issueResponse:
        key === 'issueResponse' ? value : getValues('issueResponse'),
      issueText: key === 'issueText' ? value : getValues('issueText'),
      issueTitle: key === 'issueTitle' ? value : getValues('issueTitle'),
      responseType: getValues('responseType'),
    };
    refIssueUpdate(payload, 'blur');
  };

  const onSubmitForm = (data: any) => {
    setAcceptDisable(true);
    const payload = {
      accepted: true,
      id: data.id,
      issueResponse: data.issueResponse,
      issueText: data.issueText,
      issueTitle: data.issueTitle,
      responseType: data.responseType,
    };
    refIssueUpdate(payload, 'submit');
  };

  const handleClickSuggesstions = () => {
    const visaTypeSplitted =
      petitions && petitions.petitionData && petitions.petitionData.visa_type
        ? petitions.petitionData.visa_type.split('-')
        : '';
    setResponseLoder(true);
    addNewData(
      {
        predIssue: watch('predIssue') ? watch('predIssue') : '',
        predSubIssue: watch('predSubIssue') ? watch('predSubIssue') : '',
        query: watch('issueText') ? watch('issueText') : '',
        visaType:
          String(visaTypeSplitted[0]).toLocaleLowerCase() +
          String(visaTypeSplitted[1]).toLocaleLowerCase(),
      },
      `${String(COMMON_ENDPOINT.rfeResponder)}/issue-response`,
    )
      .then((res) => {
        if (res.data) {
          setSuggestions({
            query: res.data.query,
            recommended_answers: res.data.recommended_answers,
            rfe_question: res.data.rfe_question,
          });
          setResponseLoder(false);
          setOpenSug(true);
        } else {
          setResponseLoder(false);
        }
      })
      .catch((err) => {
        setResponseLoder(false);
        toast.error('No issues found.', alertOptions);
      });
  };
  const handleCloseSuggesstions = () => {
    setResponseLoder(false);
    setOpenSug(false);
  };

  // const overallPage = (totalPage) => {
  //   setTotal(totalPage);
  // };

  const getDocument = () => {
    if (documentId) {
      getAllListData(
        `${String(COMMON_ENDPOINT.rfeResponder)}/issues/${String(documentId)}`,
      )
        .then((resp: any) => {
          setRFEResult({
            acceptedCount: resp.acceptedCount ? resp.acceptedCount : 0,
            created_at: resp.fileCreated ? resp.fileCreated : '',
            data: resp.data,
            filePath: resp.file_path,
            file_name: resp.fileName ? resp.fileName : '',
            total: resp.total,
          });

          const emptyIssues = [];
          resp.data.map((issue) => {
            issue['isParentIssue'] = true;
            emptyIssues.push(issue);
            if (issue.subIssueList && issue.subIssueList.length) {
              issue.subIssueList.map((subIssue) => {
                subIssue['isSubIssue'] = true;
                emptyIssues.push(subIssue);
              });
            }
          });
          if (emptyIssues.length) {
            setIssueSubResult(emptyIssues);
            setSelectedPageData(
              emptyIssues[0]?.page_data.map((data) => ({
                ...data,
                id: emptyIssues[0]?.id,
              })),
            );
            setValue('id', emptyIssues[0]?.id);
            setValue(
              'issueTitle',
              emptyIssues[0]?.isParentIssue
                ? emptyIssues[0].issue_title
                : emptyIssues[0].sub_issue_title,
            );
            setValue(
              'issueText',
              emptyIssues[0]?.isParentIssue
                ? emptyIssues[0].issue_text
                : emptyIssues[0].sub_issue_text,
            );
            setValue(
              'issueResponse',
              emptyIssues[0]?.isParentIssue
                ? emptyIssues[0].issue_response
                  ? emptyIssues[0].issue_response
                  : ''
                : emptyIssues[0].sub_issue_response
                ? emptyIssues[0].sub_issue_response
                : '',
            );
            setValue(
              'issueStatus',
              emptyIssues[0]?.isParentIssue
                ? emptyIssues[0].issue_status
                : emptyIssues[0].sub_issue_status,
            );
            // editor &&
            //   editor.setData(
            //     emptyIssues[0]?.isParentIssue
            //       ? emptyIssues[0].issue_response
            //       : emptyIssues[0].sub_issue_response,
            //   );
            setValue(
              'responseType',
              emptyIssues[0]?.isParentIssue ? 'issue' : 'sub-issue',
            );
            setValue(
              'predIssue',
              emptyIssues[0]?.isParentIssue
                ? emptyIssues[0].issue_pred_issue
                  ? emptyIssues[0].issue_pred_issue
                  : ''
                : emptyIssues[0].sub_issue_pred_issue
                ? emptyIssues[0].sub_issue_pred_issue
                : '',
            );
            setValue(
              'predSubIssue',
              emptyIssues[0]?.isParentIssue
                ? emptyIssues[0].issue_pred_subissue
                  ? emptyIssues[0].issue_pred_subissue
                  : ''
                : emptyIssues[0].sub_issue_pred_subissue
                ? emptyIssues[0].sub_issue_pred_subissue
                : '',
            );
          }

          const obj = {
            key: resp.file_path,
          };
          showPdf(obj);
          setPDFLoader(false);
        })
        .catch((err) => {
          setPDFLoader(false);
        });
    }
  };

  const showPdf = (obj) => {
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        const url = URL.createObjectURL(data);
        setPDFUrl(url);
        setPDFLoader(false);
      })
      .catch((err) => {
        setPDFLoader(false);
        toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };
  const renderPage = async () => {
    const imagesList = [];
    const canvas = document.createElement('canvas');
    canvas.setAttribute('className', 'canv');

    if (pdf && selectedPageData.length) {
      for (let i = 0; i < selectedPageData.length; i++) {
        const page = await pdf.getPage(selectedPageData[i].page_number);
        const viewport = page.getViewport({ scale: scale });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const render_context = {
          canvasContext: canvas.getContext('2d'),
          viewport: viewport,
        };
        await page.render(render_context).promise;
        const img = canvas.toDataURL('image/png');
        imagesList.push({ img, pageIndex: page._pageIndex });
      }
    }
    if (imagesList.length) setImages(imagesList);
  };

  const nextIssue = () => {
    setIssueCount(issueCount + 1);
  };

  const previousIssue = () => {
    setIssueCount(issueCount - 1);
  };

  const previousResponse = () => {
    if (suggestions.recommended_answers.length) {
      setResponseCount(responseCount - 1);
    }
  };

  const nextResponse = () => {
    if (suggestions.recommended_answers.length) {
      setResponseCount(responseCount + 1);
    }
  };

  const scrollToItem = () => {
    windowRef.current && windowRef.current.scrollToItem(page - 1, 'start');
  };

  useEffect(() => {
    scrollToItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (issueCount >= 0) {
      const issues = issueSubResult[issueCount];
      setSelectedPageData(
        issues?.page_data.map((data) => ({ ...data, id: issues?.id })),
      );
      setValue('id', issues?.id);
      setValue(
        'issueTitle',
        issues?.isParentIssue ? issues?.issue_title : issues?.sub_issue_title,
      );
      setValue(
        'issueText',
        issues?.isParentIssue ? issues?.issue_text : issues?.sub_issue_text,
      );
      setValue(
        'issueResponse',
        issues?.isParentIssue
          ? issues?.issue_response
            ? issues?.issue_response
            : ''
          : issues?.sub_issue_response
          ? issues?.sub_issue_response
          : '',
      );
      setValue(
        'issueStatus',
        issues?.isParentIssue ? issues?.issue_status : issues?.sub_issue_status,
      );
      // editor &&
      // editor.setData(
      //   issues?.isParentIssue
      //     ? issues?.issue_response
      //     : issues?.sub_issue_response,
      // );
      setValue('responseType', issues?.isParentIssue ? 'issue' : 'sub-issue');
      setValue(
        'predIssue',
        issues?.isParentIssue
          ? issues?.issue_pred_issue
            ? issues?.issue_pred_issue
            : ''
          : issues?.sub_issue_pred_issue
          ? issues?.sub_issue_pred_issue
          : '',
      );
      setValue(
        'predSubIssue',
        issues?.isParentIssue
          ? issues?.issue_pred_subissue
            ? issues?.issue_pred_subissue
            : ''
          : issues?.sub_issue_pred_subissue
          ? issues?.sub_issue_pred_subissue
          : '',
      );
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueCount]);

  useEffect(() => {
    if (selectedPageData && selectedPageData.length) {
      renderPage();
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPageData]);

  useEffect(() => {
    if (responseCount >= 0) {
      setActiveResponse(suggestions.recommended_answers[responseCount]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCount]);

  useEffect(() => {
    getDocument();
    const externalScript = document.createElement('script');
    const loadError = (onError) => {
      console.error(onError);
    };

    const LoadExternalScript = () => {
      externalScript.onerror = loadError;
      externalScript.id = 'external';
      externalScript.async = true;
      externalScript.type = 'text/javascript';
      externalScript.setAttribute('crossorigin', 'anonymous');
      document.body.appendChild(externalScript);
      externalScript.src = '//mozilla.github.io/pdf.js/build/pdf.js';
    };
    LoadExternalScript();

    return () => {
      document.body.removeChild(externalScript);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Typography component={'div'}>
      <Typography component={'div'} className="submis-head">
        <Typography component={'div'} className="s1-title">
          RFE Responder / Step 3 of 3
        </Typography>
        <Typography component={'div'} className="d-flex-a">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="mr-2">
            <CircularProgress
              variant="determinate"
              value={(rfeResult.acceptedCount / rfeResult.total) * 100}
              sx={{ zIndex: 1 }}
            />
            <Typography
              position="absolute"
              className="d-flex-ja"
              sx={{
                border: '2px solid #fff',
                borderRadius: '50%',
                color: '#fff !important',
                fontSize: '12px',
                height: '40px',
                width: '40px',
              }}>
              {rfeResult.acceptedCount}/{rfeResult.total}
            </Typography>
          </Box>
          <img
            src={Closeicon}
            alt="Close"
            className="pointer"
            onClick={() =>
              navigate(
                `${String(
                  AttorneyPrivateRoutes.RFERESPOEND.RFERESPONDER,
                )}/${petitionId}/${String(documentId)}`,
                {
                  state: { parse: false },
                },
              )
            }
          />
        </Typography>
      </Typography>
      <Typography
        component={'div'}
        className="upload-body"
        sx={{ backgroundColor: ' #F6F6F6' }}>
        <Grid
          container
          columnSpacing={2.5}
          sx={{ marginTop: '16px !important' }}>
          <Grid item md={4} sm={12}>
            <Typography
              component={'div'}
              className="d-flex-a d-flex-sb issue-top">
              <Grid item md={10} sm={6}>
                <img src={DocIcon} alt="icon" className="mr-2" /> Issues
              </Grid>
              <Grid item md={2} sm={6} className="d-flex-a pointer">
                <Typography
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(
                      `${String(
                        AttorneyPrivateRoutes.RFERESPOEND.RFERESPONDER,
                      )}/${petitionId}/${String(documentId)}?viewall=true`,
                      {
                        state: { parse: false },
                      },
                    );
                  }}
                  sx={{ color: '#FFF' }}>
                  View all
                </Typography>
              </Grid>
            </Typography>
            <Typography
              component={'div'}
              className="issue-box"
              style={{ overflowY: 'auto' }}>
              <form id="issues-form" onSubmit={handleSubmit(onSubmitForm)}>
                <Typography
                  component={'div'}
                  className="d-flex-a d-flex-sb mb-2 mt-2">
                  <Button
                    className="n-add-btn"
                    sx={{
                      cursor: issueCount === 0 ? 'not-allowed' : 'pointer',
                    }}
                    disabled={issueCount === 0}
                    onClick={() => previousIssue()}>
                    Previous
                  </Button>
                  <Button
                    className="n-add-btn"
                    sx={{
                      cursor:
                        issueCount >= issueSubResult.length - 1
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                    disabled={issueCount >= issueSubResult.length - 1}
                    onClick={() => nextIssue()}>
                    Next
                  </Button>
                </Typography>
                <Typography component={'div'} className="custom-field u-pr-1">
                  <Typography component={'div'} className="custom-label">
                    Issue Title <span style={{ color: 'red' }}>*</span>
                  </Typography>

                  <Controller
                    control={control}
                    defaultValue=""
                    name="issueTitle"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <CustomInput
                          placeHolder={''}
                          name={name}
                          value={value}
                          className={'custom-input'}
                          error={errors.issueTitle?.message}
                          onChange={(e) => onChange(e)}
                          onBlur={(e) =>
                            onBlurToSubmit('issueTitle', e.target.value)
                          }
                          tabIndex={1}
                        />
                      );
                    }}
                  />
                </Typography>

                <Typography component={'div'} className="custom-field u-pr-1">
                  <Typography component={'div'} className="custom-label">
                    Issue Text <span style={{ color: 'red' }}>*</span>
                  </Typography>

                  <Controller
                    control={control}
                    defaultValue=""
                    name="issueText"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <CustomInput
                          placeHolder={''}
                          name={name}
                          value={value}
                          className={'custom-textarea'}
                          error={errors.issueText?.message}
                          onChange={(e) => onChange(e)}
                          onBlur={(e) =>
                            onBlurToSubmit('issueText', e.target.value)
                          }
                          isTextArea={true}
                          tabIndex={2}
                          minRows={5}
                        />
                      );
                    }}
                  />
                </Typography>

                <Typography component={'div'} className="custom-field u-pr-1">
                  <Typography
                    component={'div'}
                    className="custom-label mb-1 mt-2 d-flex-a">
                    Issue Response
                    {responseLoder ? (
                      <CircularProgress
                        sx={{
                          height: 'auto !important',
                          marginLeft: '10px !important',
                          width: '16px !important',
                        }}
                      />
                    ) : (
                      <Tooltip
                        sx={{ cursor: 'pointer' }}
                        onClick={handleClickSuggesstions}
                        title="Suggestions Recommendations"
                        placement="right"
                        arrow>
                        <InfoOutlinedIcon className=" ml-1" />
                      </Tooltip>
                    )}
                    <Dialog
                      className="recomment-modal-box"
                      onClose={handleCloseSuggesstions}
                      open={openSug}>
                      <Typography className="d-flex-jae">
                        {' '}
                        <CloseOutlinedIcon onClick={handleCloseSuggesstions} />
                      </Typography>
                      <Typography
                        component={'div'}
                        className="recom-box d-flex-a mt-2 mb-2">
                        <img src={Ovie} alt="icon" className="im" />
                        Here are some suggestions/recommendations
                      </Typography>
                      <Typography className="mb-2 mt-1 f-c">
                        {suggestions.rfe_question}
                      </Typography>
                      <Typography
                        component={'div'}
                        className="d-flex-a d-flex-sb mb-2">
                        <Button
                          className="n-add-btn"
                          sx={{
                            cursor:
                              responseCount === 0 ? 'not-allowed' : 'pointer',
                          }}
                          disabled={responseCount === 0}
                          onClick={() => previousResponse()}>
                          Previous
                        </Button>
                        <Button
                          className="n-add-btn"
                          sx={{
                            cursor:
                              responseCount >=
                              suggestions.recommended_answers.length - 1
                                ? 'not-allowed'
                                : 'pointer',
                          }}
                          disabled={
                            responseCount >=
                            suggestions.recommended_answers.length - 1
                          }
                          onClick={() => nextResponse()}>
                          Next
                        </Button>
                      </Typography>
                      <Typography className="info-box">
                        {activeResponse}
                      </Typography>
                      <Typography className="d-flex-jae mt-2">
                        <Button
                          className="n-add-btn"
                          onClick={() => {
                            setValue('issueResponse', activeResponse);
                            // editor && editor.setData(activeResponse);
                            handleCloseSuggesstions();
                          }}>
                          Use this
                        </Button>
                      </Typography>
                    </Dialog>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="issueResponse"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <CKEditor
                          editor={ClassicEditor}
                          // data={
                          //   watch('issueResponse') !== ''
                          //     ? watch('issueResponse')
                          //     : ''
                          // }
                          data={value}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            onChange(data);
                          }}
                          onBlur={(event, editor) => {
                            const data = editor.getData();
                            setValue('issueResponse', data);
                            onBlurToSubmit('issueResponse', data);
                          }}
                        />

                        // <CKEditor
                        //   name={name}
                        //   initData={
                        //     watch('issueResponse') !== ''
                        //       ? watch('issueResponse')
                        //       : ''
                        //   }
                        //   onChange={(e) => onChange(e.editor.getData())}
                        //   onBlur={(e) => {
                        //     setEditor(e.editor.getData());
                        //     onBlurToSubmit('issueResponse', e.editor.getData());
                        //   }}
                        //   onInstanceReady={handleInstanceReady}
                        // />
                      );
                    }}
                  />
                </Typography>
              </form>
              <Typography component={'div'} className="d-flex-jae mt-2">
                {acceptDisable ? (
                  <LoadingButton
                    className="n-add-btn"
                    endIcon={<SendIcon />}
                    loading={acceptDisable}
                    loadingPosition="end"
                    variant="contained">
                    <span>Accept</span>
                  </LoadingButton>
                ) : (
                  <Button
                    className="n-add-btn"
                    type="submit"
                    form={'issues-form'}>
                    Accept
                  </Button>
                )}
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={8} sm={12}>
            <Typography component={'div'} sx={{ height: '75vh' }}>
              <Grid container md={12}>
                <Grid item md={12} sm={12}>
                  <Typography component={'div'} className="issue-top">
                    <Grid item md={5} sm={12}>
                      {/* <img src={DocIcon} alt="icon" className="mr-2" /> */}
                      {rfeResult.file_name ? rfeResult.file_name : '-'}
                    </Grid>
                    {/* <Grid item md={2} sm={12} className="d-flex-a">
                      <Tooltip title="Zoom Out" className="pointer">
                        <ZoomOutIcon
                          className="mr-1"
                          onClick={() =>
                            rfeResult.data?.length > 0 &&
                            scale > 0.25 &&
                            setScale((v) => (v -= 0.25))
                          }
                        />
                      </Tooltip>
                      <Typography
                        component={'div'}
                        className={'custom-search-input bo-rles pdf-zoom mr-1'}>
                        {String(scale * 100).concat('%')}
                      </Typography>
                      <Tooltip
                        title="Zoom In"
                        className="pointer"
                        onClick={() =>
                          rfeResult.data?.length > 0 &&
                          scale < 3 &&
                          setScale((v) => (v += 0.25))
                        }>
                        <ZoomInIcon />
                      </Tooltip>
                    </Grid> */}
                    {/* <Grid item md={2} sm={12} className="d-flex-a">
                      <Tooltip title="Page Up" className="pointer">
                        <ArrowUpwardIcon
                          className="mr-1"
                          onClick={() => page > 1 && setPage(page - 1)}
                        />
                      </Tooltip>
                      <Divider
                        orientation="vertical"
                        flexItem
                        style={{ height: '22px', marginTop: '1px' }}
                      />
                      <Tooltip title="Page Down" className="pointer">
                        <ArrowDownwardIcon
                          className="ml-1"
                          onClick={() =>
                            page < pdf.numPages && setPage(page + 1)
                          }
                        />
                      </Tooltip>
                    </Grid> */}
                    {/* <Grid item md={2} sm={12} className="d-flex-a">
                      <Typography>
                        <CustomInput
                          value={page.toString()}
                          onChange={(e) => setPage(e.target.value)}
                          type="number"
                          className={'custom-pdf-input bo-rles mr-1'}
                          disabled={rfeResult.data?.length === 0}
                        />
                      </Typography>
                      <Divider
                        orientation="vertical"
                        flexItem
                        style={{
                          height: '20px',
                          marginTop: '7px',
                          rotate: '22deg',
                        }}
                      />
                      <span className="ml-1">{total}</span>
                    </Grid> */}
                    {/* <Grid item md={1} sm={12}>
                      <MoreVertIcon />
                    </Grid> */}
                  </Typography>
                  {PDFloader ? (
                    <InvoiceLoader />
                  ) : (
                    <Grid
                      container
                      md={12}
                      sm={12}
                      sx={{
                        backgroundColor: '#A5A5A5 !important',
                      }}>
                      <Grid
                        item
                        md={2}
                        sm={12}
                        sx={{ maxHeight: '720px', overflowY: 'auto' }}>
                        {images?.map((data, index) => (
                          <>
                            <Box
                              key={index}
                              sx={{
                                '& > :not(style)': {
                                  height: 100,
                                  m: 2,
                                  width: 80,
                                },
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <img
                                src={data.img}
                                className="pointer"
                                onClick={() => setPage(index + 1)}
                                style={{
                                  border:
                                    index + 1 === page && 'solid 4px #92b6ef',
                                }}
                              />
                            </Box>

                            <Typography
                              className="d-flex-ja"
                              sx={{ fontSize: '12px' }}>
                              {index + 1}
                            </Typography>
                          </>
                        ))}
                      </Grid>
                      <Grid item md={10} sm={12}>
                        <Typography
                          component={'div'}
                          className="issue-box"
                          sx={{
                            backgroundColor: '#A5A5A5 !important',
                            padding: '0px 0px 20px 0px !important',
                          }}>
                          <PdfUrlViewer
                            url={PDFUrl}
                            setPdf={setPdf}
                            scale={scale}
                            windowRef={windowRef}
                            // overallPage={overallPage}
                            shouldDraw={false}
                            selectedPage={
                              selectedPageData.length ? selectedPageData : null
                            }
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Typography>
      <Typography component={'div'} className="rfe-footer">
        <Footer />
      </Typography>
    </Typography>
  );
};
