import { Typography } from '@mui/material';
import { PassportParsing } from 'components/ParsingDocumentView/passport';
import { PdfParsing } from 'components/ParsingDocumentView/pdfParsing';
import { ResumeParsing } from 'components/ParsingDocumentView/resume';
import { instantState } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData } from 'services/CommonServices';
import { PassportTypes } from 'types';
import { BASE_URL, COMMON_ENDPOINT } from 'utils/Constant';
import {
  alertOptions,
  changeDobFormate,
  formateDate,
  getGenderValues,
  passportDefaultValues,
  resumeDefaultValues,
} from 'utils/helper';

export const ParsingValuePreview: React.FC<any> = () => {
  const { docId } = useParams();
  const navigate = useNavigate();
  const [parsingType, setParsingType] = useState('');
  const [fileSrc, setFileSrc] = useState('');
  const [passportValues, setPassportValues] = useState<PassportTypes>(
    passportDefaultValues,
  );
  const [resumeInfo, setResumeInfo] = useState<{
    474: string[];
    284: string;
  }>(resumeDefaultValues);
  const [parsingData, setParsingData] = useState<Array<any>>([]);
  const [docInfo, setDocInfo] = useState(null);
  const [loader, setLoader] = useState(true);

  const resumeOnConfirm = (type: string, info: any) => {
    if (type === 'submit') {
      if (parsingType === 'passport') {
        setPassportValues({ ...info });
      } else if (parsingType === 'resume') {
        setResumeInfo({ ...info });
      } else if (
        parsingType === 'lca' ||
        parsingType === 'g-28' ||
        parsingType === 'i-140'
      ) {
        setParsingData([...info]);
      }
      uploadCorrect();
      return;
    }
  };
  const getLcaActualQuestionAndAnswer = useCallback(
    async (array: Array<any>, type: string) => {
      try {
        const parsingResponse = await addNewData(
          { list: array, type: type },
          COMMON_ENDPOINT.uploadDocumentGetParsingValue,
        );
        const parsingVal =
          parsingResponse && parsingResponse.data ? parsingResponse.data : [];
        setParsingData(parsingVal);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        toast.error('Values not parsed properly', alertOptions);
      }
    },
    [],
  );
  const mappingValues = useCallback(
    (arr: Array<any>, type: string) => {
      const lcaParsingValues = arr.map((val) => {
        const finalArr = [];
        if (val) {
          const obj: any = val;
          for (const key in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(key)) {
              const originalObj = {
                answer: '',
                keyValue: '',
              };
              const element = obj[key];
              originalObj.keyValue = key;
              originalObj.answer = element;
              if (element) {
                finalArr.push(originalObj);
              }
            }
          }
        }
        val[1] = finalArr;
        return val[1] && val[1].length ? val[1] : [];
      });
      const newArry = [].concat(...lcaParsingValues);
      if (newArry && newArry.length) {
        setLoader(true);
        getLcaActualQuestionAndAnswer(newArry, type);
      }
    },
    [getLcaActualQuestionAndAnswer],
  );
  const bodyFormData = useCallback(
    async (docData: any) => {
      const docformdata = {
        documentData: JSON.stringify(docData),
        documentFor: 'lead',
        documentId: docInfo?.id,
        documentSelectedId: docInfo?.doc_submitted_id,
        submitted: true,
      };

      return await docformdata;
    },
    [docInfo?.doc_submitted_id, docInfo?.id],
  );
  const getPassportDefaultFormData = useCallback(
    async (previewCheck?: boolean) => {
      const passportValues: any = await instantState(setPassportValues);
      const docData = {
        ...passportValues,
        dateOfBirth: {
          confidence: passportValues.dateOfBirth.confidence,
          value: changeDobFormate(passportValues.dateOfBirth.value),
        },
        gender: {
          confidence: passportValues.gender.confidence,
          value: getGenderValues(passportValues.gender.value),
        },
      };
      return await bodyFormData(docData);
    },
    [bodyFormData],
  );
  const getResumeDefaultFormData = useCallback(
    async (previewCheck?: boolean) => {
      const arr = [];
      const resumeInfo: any = await instantState(setResumeInfo);
      arr.push({
        answer: JSON.stringify(resumeInfo[474].filter((e) => e)),
        id: 474,
      });
      arr.push({ answer: resumeInfo[284], id: 284 });
      return await bodyFormData(arr);
    },
    [bodyFormData],
  );
  const getLcaDefaultFormData = useCallback(
    async (previewCheck?: boolean) => {
      const finalArr = [];
      const parsingData: any = await instantState(setParsingData);
      parsingData.map((val: any) => {
        if (val.questionId) {
          if (val.response_type === 5 || val.response_type === 6) {
            val.answer =
              val.multiple_pdf_field && val.multiple_pdf_field.length
                ? JSON.stringify(val.multiple_pdf_field)
                : JSON.stringify([]);
          }
          finalArr.push(val);
        }
      });
      return await bodyFormData(finalArr);
    },
    [bodyFormData],
  );
  const updatePreviewUploadApi = useCallback(
    async (formData) => {
      try {
        setLoader(true);
        await addNewData(
          formData,
          COMMON_ENDPOINT.uploadDocumentParsingUpdate(
            docInfo?.doc_uploaded_from === 'petition'
              ? docInfo?.petitionId
              : docInfo?.leadUserId,
            docInfo?.role_id,
            docInfo?.user_id,
          ),
        );
        navigate(-1);
        setLoader(false);
      } catch (error) {
        setLoader(false);
      }
    },
    [
      docInfo?.doc_uploaded_from,
      docInfo?.leadUserId,
      docInfo?.petitionId,
      docInfo?.role_id,
      docInfo?.user_id,
      navigate,
    ],
  );
  const uploadCorrect = useCallback(async () => {
    if (parsingType === 'passport') {
      const formdata = await getPassportDefaultFormData();
      await updatePreviewUploadApi(formdata);
      return;
    } else if (parsingType === 'resume') {
      const formdata = await getResumeDefaultFormData();
      await updatePreviewUploadApi(formdata);
      return;
    } else if (
      parsingType === 'lca' ||
      parsingType === 'g-28' ||
      parsingType === 'i-140'
    ) {
      const formdata = await getLcaDefaultFormData();
      await updatePreviewUploadApi(formdata);
      return;
    }
  }, [
    getLcaDefaultFormData,
    getPassportDefaultFormData,
    getResumeDefaultFormData,
    parsingType,
    updatePreviewUploadApi,
  ]);
  const getPreviewResumeValues = (values: Array<any>) => {
    if (values && values.length) {
      values.map((val) => {
        if (val.id === 284) {
          setResumeInfo((pre) => ({
            ...pre,
            284: val.answer ? val.answer : '',
          }));
          return;
        }
        if (val.id === 474) {
          let jsonParsevalues = [];
          try {
            jsonParsevalues = JSON.parse(val.answer);
            // eslint-disable-next-line no-empty
          } catch (error) {}
          setResumeInfo((pre) => ({
            ...pre,
            474:
              jsonParsevalues && jsonParsevalues.length
                ? jsonParsevalues
                : [''],
          }));
          return;
        }
      });
    }
  };
  const getPreviewPassport = (entity, bulkUpload?) => {
    if (bulkUpload) {
      setPassportValues((pre) => ({
        ...pre,
        countryName: {
          confidence:
            typeof entity.countryName === 'string' ||
            entity.countryName instanceof String
              ? '90'
              : entity.countryName && entity.countryName.confidence
              ? entity.countryName.confidence
              : '90',
          value:
            typeof entity.countryName === 'string' ||
            entity.countryName instanceof String
              ? entity.countryName
              : entity.countryName && entity.countryName.value
              ? entity.countryName.value
              : '',
        },
        dateOfBirth: {
          confidence:
            typeof entity.dateOfBirth === 'string' ||
            entity.dateOfBirth instanceof String
              ? '90'
              : entity.dateOfBirth && entity.dateOfBirth.confidence
              ? entity.dateOfBirth.confidence
              : '90',
          value:
            typeof entity.dateOfBirth === 'string' ||
            entity.dateOfBirth instanceof String
              ? changeDobFormate(entity.dateOfBirth)
              : entity.dateOfBirth && entity.dateOfBirth.value
              ? changeDobFormate(entity.dateOfBirth.value)
              : '',
        },
        expiryDate: {
          confidence:
            typeof entity.expiryDate === 'string' ||
            entity.expiryDate instanceof String
              ? '90'
              : entity.expiryDate && entity.expiryDate.confidence
              ? entity.expiryDate.confidence
              : '90',
          value:
            typeof entity.expiryDate === 'string' ||
            entity.expiryDate instanceof String
              ? formateDate(entity.expiryDate)
              : entity.expiryDate && entity.expiryDate.value
              ? formateDate(entity.expiryDate.value)
              : '',
        },
        firstName: {
          confidence:
            typeof entity.firstName === 'string' ||
            entity.firstName instanceof String
              ? '90'
              : entity.firstName && entity.firstName.confidence
              ? entity.firstName.confidence
              : '90',
          value:
            typeof entity.firstName === 'string' ||
            entity.firstName instanceof String
              ? entity.firstName
              : entity.firstName && entity.firstName.value
              ? entity.firstName.value
              : '',
        },
        gender: {
          confidence:
            typeof entity.gender === 'string' || entity.gender instanceof String
              ? '90'
              : entity.gender && entity.gender.confidence
              ? entity.gender.confidence
              : '90',
          value:
            typeof entity.gender === 'string' || entity.gender instanceof String
              ? entity.gender
              : entity.gender && entity.gender.value
              ? entity.gender.value
              : '',
        },
        lastName: {
          confidence:
            typeof entity.lastName === 'string' ||
            entity.lastName instanceof String
              ? '90'
              : entity.lastName && entity.lastName.confidence
              ? entity.lastName.confidence
              : '90',
          value:
            typeof entity.lastName === 'string' ||
            entity.lastName instanceof String
              ? entity.lastName
              : entity.lastName && entity.lastName.value
              ? entity.lastName.value
              : '',
        },
        middleName: { confidence: '', value: '' },
        nationality: {
          confidence:
            typeof entity.userNationality === 'string' ||
            entity.userNationality instanceof String
              ? '90'
              : entity.userNationality && entity.userNationality.confidence
              ? entity.userNationality.confidence
              : '90',
          value:
            typeof entity.userNationality === 'string' ||
            entity.userNationality instanceof String
              ? entity.userNationality
              : entity.userNationality && entity.userNationality.value
              ? entity.userNationality.value
              : '',
        },
        passportNumber: {
          confidence:
            typeof entity.passportNumber === 'string' ||
            entity.passportNumber instanceof String
              ? '90'
              : entity.passportNumber && entity.passportNumber.confidence
              ? entity.passportNumber.confidence
              : '90',
          value:
            typeof entity.passportNumber === 'string' ||
            entity.passportNumber instanceof String
              ? entity.passportNumber
              : entity.passportNumber && entity.passportNumber.value
              ? entity.passportNumber.value
              : '',
        },
      }));
      return;
    }
    setPassportValues((pre) => ({
      ...pre,
      countryName: {
        confidence: '90',
        value:
          typeof entity.countryName === 'string' ||
          entity.countryName instanceof String
            ? entity.countryName
            : entity.countryName && entity.countryName.value
            ? entity.countryName.value
            : '',
      },
      dateOfBirth: {
        confidence: '90',
        value:
          typeof entity.dateOfBirth === 'string' ||
          entity.dateOfBirth instanceof String
            ? entity.dateOfBirth
            : entity.dateOfBirth && entity.dateOfBirth.value
            ? entity.dateOfBirth.value
            : '',
      },
      expiryDate: {
        confidence: '90',
        value:
          typeof entity.expiryDate === 'string' ||
          entity.expiryDate instanceof String
            ? entity.expiryDate
            : entity.expiryDate && entity.expiryDate.value
            ? entity.expiryDate.value
            : '',
      },
      firstName: {
        confidence: '90',
        value:
          typeof entity.firstName === 'string' ||
          entity.firstName instanceof String
            ? entity.firstName
            : entity.firstName && entity.firstName.value
            ? entity.firstName.value
            : '',
      },
      gender: {
        confidence: '90',
        value:
          typeof entity.gender === 'string' || entity.gender instanceof String
            ? entity.gender === 'male'
              ? 'M'
              : 'F'
            : entity.gender && entity.gender.value
            ? entity.gender.value === 'male'
              ? 'M'
              : 'F'
            : '',
      },
      lastName: {
        confidence: '90',
        value:
          typeof entity.lastName === 'string' ||
          entity.lastName instanceof String
            ? entity.lastName
            : entity.lastName && entity.lastName.value
            ? entity.lastName.value
            : '',
      },
      middleName: { confidence: '90', value: '' },
      nationality: {
        confidence: '90',
        value:
          typeof entity.nationality === 'string' ||
          entity.nationality instanceof String
            ? entity.nationality
            : entity.nationality && entity.nationality.value
            ? entity.nationality.value
            : '',
      },
      passportNumber: {
        confidence: '90',
        value:
          typeof entity.passportNumber === 'string' ||
          entity.passportNumber instanceof String
            ? entity.passportNumber
            : entity.passportNumber && entity.passportNumber.value
            ? entity.passportNumber.value
            : '',
      },
    }));
  };
  const getCurrentUploadDocInfo = useCallback(() => {
    getAllListData(`${COMMON_ENDPOINT.GetParticularDocId}/${String(docId)}`)
      .then(
        (resp: {
          id: string;
          parsingType: string;
          document_data: any;
          from_bulk_document_parsing: boolean;
        }) => {
          setLoader(false);
          setDocInfo(resp);
          setFileSrc(
            `${BASE_URL}/upload-documents/viewDocument?key=${resp.id}`,
          );
          setParsingType(resp.parsingType);

          if (
            (resp.parsingType === 'lca' ||
              resp.parsingType === 'g-28' ||
              resp.parsingType === 'i-140') &&
            resp &&
            resp.from_bulk_document_parsing &&
            resp.document_data
          ) {
            mappingValues(resp.document_data, resp.parsingType);
            return;
          }
          if (
            resp.parsingType === 'lca' ||
            resp.parsingType === 'g-28' ||
            resp.parsingType === 'i-140'
          ) {
            setParsingData(resp.document_data);
          } else if (resp.parsingType === 'resume') {
            getPreviewResumeValues(resp.document_data);
          } else if (resp.parsingType === 'passport') {
            getPreviewPassport(
              resp.document_data,
              resp.from_bulk_document_parsing,
            );
          }
        },
      )
      .catch(() => {
        setLoader(false);
      });
  }, [docId, mappingValues]);
  useEffect(() => {
    getCurrentUploadDocInfo();
  }, [docId, getCurrentUploadDocInfo]);
  return (
    <Typography
      component={'div'}
      sx={{
        backgroundColor: '#FFFFFF',
        minHeight: '130px',
        padding: '1.5rem !immportant',
      }}>
      {loader && <div className="liberty-loader" />}
      {parsingType === 'resume' && (
        <ResumeParsing
          fileUrl={fileSrc}
          resumeInfo={resumeInfo}
          onConfirm={resumeOnConfirm}
          setResumeInfo={setResumeInfo}
          uploadDisable={true}
          BackBtn={true}
          inputDisable={docInfo?.is_parsing_value_submitted}
          fileType={
            docInfo &&
            docInfo.mime_type &&
            docInfo.mime_type.split('/')[1] === 'pdf'
              ? 'pdf'
              : docInfo?.mime_type
          }
        />
      )}

      {parsingType === 'passport' && (
        <PassportParsing
          fileUrl={fileSrc}
          passportInfo={passportValues}
          onConfirm={resumeOnConfirm}
          setPassportInfo={setPassportValues}
          uploadDisable={true}
          BackBtn={true}
          inputDisable={docInfo?.is_parsing_value_submitted}
          fileType={
            docInfo &&
            docInfo.mime_type &&
            docInfo.mime_type.split('/')[1] === 'pdf'
              ? 'pdf'
              : docInfo?.mime_type
          }
        />
      )}

      {(parsingType === 'lca' ||
        parsingType === 'g-28' ||
        parsingType === 'i-140') && (
        <PdfParsing
          fileUrl={fileSrc}
          parseInfo={parsingData}
          onConfirm={resumeOnConfirm}
          setParseInfo={setParsingData}
          uploadDisable={true}
          BackBtn={true}
          inputDisable={docInfo?.is_parsing_value_submitted}
          fileType={
            docInfo &&
            docInfo.mime_type &&
            docInfo.mime_type.split('/')[1] === 'pdf'
              ? 'pdf'
              : docInfo?.mime_type
          }
        />
      )}
    </Typography>
  );
};
