/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import { PublicRoutes } from 'config';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from 'store/actions/UserAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { getAllListData } from '../../../../services/CommonServices';
import { COMMON_ENDPOINT, MASTER_ENDPOINT } from '../../../../utils/Constant';
import useStyles from '../../PrivateStyle';

export const MySubscription = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const [loader, setLoader] = useState(false);
  const [myPlans, setMyPlans] = useState<any>({});
  const [progress, setProgress] = React.useState(0);

  const planUpgrade = async () => {
    await getAllListData(MASTER_ENDPOINT.StripeUpgrade)
      .then((resp: any) => {
        if (resp && resp.url) {
          window.location.href = resp.url;
        }
      })
      .catch((e: any) => {});
  };

  const logoutCall = () => {
    dispatch(logout());
    navigate(PublicRoutes.LOGIN);
  };

  useEffect(() => {
    (async () => {
      setLoader(true);
      await getAllListData(COMMON_ENDPOINT.subscriptionPlan)
        .then((resp: any) => {
          setMyPlans(resp);
          setProgress(100 * (resp.activeUserCount / resp.total_user));
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
          logoutCall();
        });
    })();

    return () => {
      setLoader(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12}>
        <Typography component={'div'} className={classes.subscriptionBox}>
          <Box component="form" autoComplete="off">
            <Grid container>
              <Grid item md={12}>
                {userDetail.customer_portal_show && (
                  <>
                    <Typography
                      component={'div'}
                      className="f-31 pd pl-1"
                      style={{ color: '#343434' }}>
                      Dear {userDetail.first_name}
                      {','}
                    </Typography>

                    <Typography
                      component={'div'}
                      className=" pl-1 f-20 pd mt-2"
                      style={{ color: '#848080', lineHeight: '30px' }}>
                      You will be redirected to the Customer Portal for managing
                      your subscription. To proceed, please disable the Popup
                      Blocker on your browser and then click on the{' '}
                      {'"Continue"'} button.
                    </Typography>
                    <Typography
                      component={'div'}
                      className="d-flex-ja mt-2 my-2">
                      <Button
                        className="add-btn"
                        type="button"
                        onClick={planUpgrade}
                        style={{ height: '45px', width: '300px' }}>
                        Continue
                      </Button>
                    </Typography>
                  </>
                )}
                <div
                  className="subscription-box"
                  style={{ backgroundColor: '#FFFFFF' }}>
                  <div className="pt-3">
                    <Grid container columnSpacing={{ md: 3, sm: 2 }}>
                      <Typography className="pb-bold pl-1">
                        Your Plan Details
                      </Typography>
                      <Grid
                        item
                        md={12}
                        sm={12}
                        className="d-flex content-center">
                        <Typography component={'div'} className="plan-card">
                          <Typography
                            component={'div'}
                            className="d-flex d-flex-sb">
                            <Typography className="d-flex plan-title">
                              {myPlans.strip_product_name}
                              <Button className="assem-badge ml-1">
                                {capitalize(
                                  myPlans.recurring?.interval.concat('ly'),
                                )}
                              </Button>
                            </Typography>

                            <Typography className="plan-info d-flex-a">
                              <span style={{ fontSize: '18px' }}>Status</span>
                              {'     '}
                              <Button className="assem-badge ml-1">
                                {capitalize(myPlans.subscription_status)}
                              </Button>
                            </Typography>
                          </Typography>

                          <Typography
                            component={'div'}
                            className="d-flex d-flex-sa">
                            <Grid item md={6} sm={12}>
                              <Typography className="plan-info">
                                {myPlans.total_user} of{' '}
                                {myPlans.activeUserCount} users
                              </Typography>
                              <Box sx={{ width: '80%' }}>
                                <LinearProgress
                                  className="progress"
                                  variant="determinate"
                                  value={progress}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={6} sm={12} className={'d-flex-jae'}>
                              <Typography
                                component={'div'}
                                className="d-flex content-end plan-title">
                                <span style={{ fontSize: '30px' }}>
                                  $
                                  {myPlans.planAmount
                                    ? Number(myPlans.planAmount) / 100
                                    : '0'}{' '}
                                  <span
                                    style={{
                                      color: '#848080',
                                      fontSize: '15px',
                                    }}>
                                    {' '}
                                    /{' '}
                                    {capitalize(
                                      myPlans.recurring?.interval.concat('ly'),
                                    )}
                                  </span>
                                </span>
                              </Typography>
                            </Grid>
                          </Typography>
                          <Typography
                            component={'div'}
                            style={{ paddingTop: '30px' }}>
                            <Typography className="plan-info d-flex">
                              <span style={{ fontSize: '18px' }}>
                                Joined on:
                              </span>
                              <span className="fon-500 pl-6  plan-title">
                                {moment(myPlans.subscription_start_date).format(
                                  'MMMM DD, YYYY',
                                )}
                              </span>
                            </Typography>
                            <Typography className="plan-info d-flex">
                              <span style={{ fontSize: '18px' }}>
                                Renewal on:
                              </span>{' '}
                              <span className="fon-500 pl-6  plan-title">
                                {moment(myPlans.subscription_end_date).format(
                                  'MMMM DD, YYYY',
                                )}
                              </span>
                            </Typography>
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};
