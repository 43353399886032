import { Grid, Typography } from '@mui/material';
import React from 'react';

import useStyles from '../../../../PrivateStyle';

function NotFound() {
  const classes = useStyles();
  return (
    <Grid md={12} item>
      <Typography component={'div'} className="leadBox">
        <Typography
          className="leadItem"
          sx={{ marginBottom: 'unset !important' }}>
          <Typography className={classes.userItemInner} />
          <Typography className="d-flex d-flex-sb w-100">
            <Typography
              component={'div'}
              sx={{ paddingLeft: '.5rem' }}
              className="d-flex-1">
              <Typography
                className="d-flex content-center black-white-text ctitle"
                sx={{
                  opacity: '0.9',
                }}>
                No Record(s) Found.
              </Typography>
            </Typography>
          </Typography>
        </Typography>
      </Typography>
    </Grid>
  );
}

export default NotFound;
