// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, Typography } from '@mui/material';
import Logo from 'assets/images/liberty-logoheader.png';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
// import { PublicRoutes } from 'config';
import _, { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addNewData, getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

const BillingDetails: React.FC = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [acceptTC, setAcceptTC] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showTCError, setShowTCError] = useState('');
  const [paymentTerms, setPaymentTerms] = useState<any>({});
  const [paymentPrivacy, setPaymentPrivacy] = useState<any>({});

  const confirmPayment = () => {
    if (!acceptTC) {
      setShowTCError(
        'You must agree with the Terms of Services and Privacy Policy',
      );
    } else {
      setConfirmDialog(true);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleConfirmPay = (action: string) => {
    if (action !== 'yes') {
      setConfirmDialog(false);
      return;
    }
    if (action === 'yes') {
      setLoader(true);
      const payload = {
        displayAmount: location.state?.price?.amount,
        email: location.state?.formData?.email,
        firmName: location.state?.formData?.firmName,
        firstName: location.state?.formData?.firstName,
        lastName: location.state?.formData?.lastName,
        mobileCode: location.state?.formData?.mobileCode,
        mobileSocCode: location.state?.formData?.mobileSocCode,
        phone: location.state?.formData?.phone
          ? String(location.state?.formData?.phone).split(/(?<=^\S+)\s/)[1]
          : '',
        stripProductName: location.state?.product?.name,
        stripePriceId: location.state?.price?.id,
        stripeProductId: location.state?.product?.id,
        stripeTrialId: location.state?.trialId ? location.state?.trialId : null,
        totalUser: Number(location.state?.formData?.choosedUser),
      };
      addNewData(payload, MASTER_ENDPOINT.StripeCreateCheckoutSession)
        .then((resp: any) => {
          setConfirmDialog(false);
          setLoader(false);
          if (resp.url) {
            window.location.href = resp.url;
          } else {
            navigate('/login');
          }
        })
        .catch((e) => {
          setLoader(false);
          setConfirmDialog(false);
        });
    }
  };

  useEffect(() => {
    if (!location.state) {
      window.open('https://visas.ai/pricing/', '_self');
    }
  }, [location.state, navigate]);

  useEffect(() => {
    getAllListData(MASTER_ENDPOINT.PaymentTermsLink)
      .then((resp: any) => {
        if (resp) {
          setLoader(false);
          resp.find((terms) => {
            if (terms.key === 'payment_terms') {
              setPaymentTerms(terms);
            }
            if (terms.key === 'payment_policy') {
              setPaymentPrivacy(terms);
            }
          });
        }
      })
      .catch((e: any) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <div>
      <Typography
        className=" black-white-text p-f pt-2"
        sx={{
          backgroundColor: '#000',
          height: '84px',
          position: 'sticky',
          top: '0',
        }}>
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <div className="innerBox payment-public" style={{ textAlign: 'left' }}>
        <div className="minmax-paybox">
          <Grid container sx={{ paddingTop: '43px' }}>
            <Grid item md={6} sm={12}>
              <Typography className="ab-title">Billing Details</Typography>
              {/* <Typography className="ab-info">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </Typography> */}
              <Grid
                container
                rowSpacing={3}
                columnSpacing={3}
                sx={{
                  marginLeft: '-24px !important',
                  paddingBottom: '24px',
                }}>
                <Grid item md={6} sm={12}>
                  <Typography className="bill-label">Name</Typography>
                  <Typography className="bill-text">
                    {String(location.state?.formData?.firstName) +
                      ' ' +
                      String(location.state?.formData?.lastName)}
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography className="bill-label">Mobile Number</Typography>
                  <Typography className="bill-text">
                    {location.state?.formData?.phone}
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography className="bill-label">
                    Attorney Firm Name
                  </Typography>
                  <Typography className="bill-text">
                    {location.state?.formData?.firmName}
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography className="bill-label">Email</Typography>
                  <Typography className="bill-text">
                    {location.state?.formData?.email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={3}
                sx={{
                  borderTop: '1px solid #ccc',
                  paddingTop: '16px',
                }}>
                <Grid item md={12} sm={12}>
                  <Typography className="bill-title">
                    <b>{location.state?.product?.name}</b>
                  </Typography>
                  <Typography className="bill-text">
                    ({location.state?.product?.metadata?.total_user} users)
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12}>
                  <Typography className="bill-text">
                    {location.state?.product?.description}
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography className="bill-label">QUANTITY</Typography>
                  <Typography className="bill-text">
                    {location.state?.formData?.choosedUser} user(s)
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Typography className="bill-label">PRICE</Typography>
                  <Typography className="bill-text">
                    <b>
                      {' '}
                      ${location.state?.price?.amount} /{' '}
                      {capitalize(location.state?.price?.type)}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              className="pay-end-box"
              sx={{ display: 'flex' }}>
              <Typography className="summary-box">
                <Typography
                  className="ab-title"
                  sx={{ paddingBottom: 'unset !important' }}>
                  ORDER SUMMARY
                </Typography>
                <Grid container rowSpacing={3}>
                  <Grid item md={12} sm={12}>
                    <Typography className="bill-label">ITEM</Typography>
                    <Typography
                      className="bill-title"
                      sx={{ paddingBottom: '8px' }}>
                      {location.state?.product?.name}
                    </Typography>
                    <Typography className="sum-text" sx={{ opacity: '0.8' }}>
                      ({location.state?.product?.metadata.total_user} users)
                    </Typography>
                  </Grid>
                  <Grid item md={12} sm={12}>
                    <Typography className="bill-label">QUANTITY</Typography>
                    <Typography className="sum-text">
                      {location.state?.formData?.choosedUser} user(s)
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6}>
                    <Typography className="bill-label">PRICE</Typography>
                    <Typography className="sum-text">
                      {capitalize(location.state?.price?.type)}
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6}>
                    <Typography
                      className="bill-label"
                      sx={{ visibility: 'hidden' }}>
                      amount
                    </Typography>
                    <Typography
                      sx={{ paddingRight: '10px', textAlign: 'right' }}>
                      <b>${location.state?.price?.amount}</b>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    sx={{
                      borderTop: '1px solid #ccc',
                      marginTop: '45px !important',
                      padding: '8px 0 0px 0 !important',
                    }}
                  />
                  <Grid item md={6} sm={6}>
                    <b>Today{"'"}s Charges </b>
                  </Grid>
                  <Grid item md={6} sm={6}>
                    <Typography
                      sx={{ paddingRight: '10px', textAlign: 'right' }}>
                      <b>
                        $
                        {Number(location.state?.price?.amount) *
                          Number(location.state?.formData?.choosedUser)}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item md={6} sm={12}>
              <Typography
                component={'div'}
                className="d-flex-a"
                sx={{ paddingTop: '34px' }}>
                <input
                  type="checkbox"
                  onChange={(event) => setAcceptTC(event.currentTarget.checked)}
                  checked={acceptTC}
                  id="check"
                />
                <label>
                  <Typography
                    component={'label'}
                    style={{ cursor: 'pointer', paddingLeft: '10px' }}
                    htmlFor="check">
                    {' '}
                    I agree to the{' '}
                  </Typography>
                  <Typography
                    component={'span'}
                    style={{ color: 'rgba(25, 82, 167, 1) !important' }}>
                    <a
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      {...(!_.isEmpty(paymentTerms) && paymentTerms?.url
                        ? {
                            component: 'a',
                            href: paymentTerms?.url,
                            target: '_blank',
                          }
                        : {
                            component: 'a',
                            href: '#',
                          })}>
                      Terms of Service
                    </a>
                  </Typography>{' '}
                  and{' '}
                  <Typography
                    component={'span'}
                    style={{ color: 'rgba(25, 82, 167, 1) !important' }}>
                    <a
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      {...(!_.isEmpty(paymentPrivacy) && paymentPrivacy?.url
                        ? {
                            component: 'a',
                            href: paymentPrivacy?.url,
                            target: '_blank',
                          }
                        : {
                            component: 'a',
                            href: '#',
                          })}>
                      Privacy Policy
                    </a>
                  </Typography>
                </label>
                {!acceptTC && showTCError !== '' && (
                  <p style={{ paddingLeft: '10px' }}>
                    <span style={{ color: 'red' }}>{showTCError}</span>
                  </p>
                )}
              </Typography>
            </Grid>
            <Grid item md={6} sm={12}>
              <div
                className="d-flex-jae"
                style={{
                  paddingTop: '34px',
                }}>
                <Button
                  className="cancel-pay-button"
                  sx={{ marginRight: '15px' }}
                  onClick={() => goBack()}>
                  Cancel
                </Button>
                <Button className="pay-button" onClick={confirmPayment}>
                  PAY SECURELY
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <ConfirmDialog
        title="Confirm"
        floatingBtnShow={true}
        open={confirmDialog}
        close={() => {
          setConfirmDialog(false);
        }}
        onConfirm={handleConfirmPay}
        autoCloseDisable={true}>
        {'Are you sure you would like to complete the payment?'}
      </ConfirmDialog>
    </div>
  );
};

export default BillingDetails;
