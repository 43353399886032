export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_TOKEN = 'USER_TOKEN';
export const USER_REFRESH_TOKEN = 'USER_REFRESH_TOKEN';
export const USER_SIDEMENU_SUCCESS = 'USER_SIDEMENU_SUCCESS';
export const USER_PROFILES_SUCCESS = 'USER_PROFILES_SUCCESS';
export const USER_SIDEMENU_FAIL = 'USER_SIDEMENU_FAIL';
export const USER_PROFILES_FAIL = 'USER_PROFILES_FAIL';
export const VERIFY_CAPTCHA = 'VERIFY_CAPTCHA';
