import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
// import Multiselect from 'multiselect-react-dropdown';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { AdminDocumentTypes } from 'types';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { AdminDocumentTypeSchema } from 'utils/ValidatorSchema';

export const DocumentAdd: React.FC<any> = () => {
  const navigate: any = useNavigate();
  const { editId } = useParams();

  const [loader, setLoader] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visaTypes, setVisaTypes] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visaTypeIds, setVisaTypeIds] = useState<any>([]);
  const [visaOptions, setVisaOptions] = useState<any>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [defaultForPetitionIds, setDefaultForPetitionIds] = useState<any>([]);

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    // setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AdminDocumentTypes>({
    criteriaMode: 'all',
    defaultValues: {
      dariDocumentType: '',
      defaultForPetitions: [],
      description: '',
      documentParsing: 'no',
      documentType: '',
      parsingType: '',
      pashtoDocumentType: '',
      validDocuments: '',
      visaId: null,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AdminDocumentTypeSchema),
  });

  const cancelForm = () => {
    resetForm();
    navigate(-1);
  };

  const getInitialStatus = useCallback(async () => {
    setLoader(true);
    await getAllListData(
      `${String(SETUP_ENDPOINT.DocumentType)}?id=${String(
        editId,
      )}&skip=0&take=1`,
    )
      .then((resp) => {
        const {
          dari_document_type,
          pashto_document_type,
          description,
          document_parsing,
          default_for_petition,
          visa_type_id,
          document_type,
          valid_documents,
          parsing_type,
        } = resp.data[0];

        const visaAssinged = visa_type_id.map(Number);
        setVisaTypeIds(
          visaTypes
            .filter((obj) => visaAssinged.indexOf(obj.visa_id) !== -1)
            .map((selected) => selected.visa_id),
        );

        setDefaultForPetitionIds(
          visaTypes
            .filter((obj) => default_for_petition.indexOf(obj.visa_id) !== -1)
            .map((selected) => selected.visa_id),
        );

        setValue('documentType', document_type ? document_type : '');
        setValue('validDocuments', valid_documents ? valid_documents : '');
        setValue('parsingType', parsing_type ? parsing_type : '');
        setValue(
          'dariDocumentType',
          dari_document_type ? dari_document_type : '',
        );
        setValue(
          'pashtoDocumentType',
          pashto_document_type ? pashto_document_type : '',
        );
        setValue('description', description ? description : '');
        setValue('documentParsing', document_parsing ? 'yes' : 'no' || 'no');
        setValue(
          'defaultForPetitions',
          default_for_petition ? default_for_petition : [],
        );
        setValue('visaId', visa_type_id ? visa_type_id : '');
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitForm = (data: any) => {
    setLoader(true);
    data['documentParsing'] = data['documentParsing'] === 'no' ? false : true;

    if (!_.isEmpty(editId)) {
      updateData(editId, data, SETUP_ENDPOINT.DocumentType)
        .then((resp: any) => {
          cancelForm();
          setLoader(false);
          getVisaType();
          toast.success('Document type updated.', alertOptions);
        })
        .catch((e) => {
          setLoader(false);
        });
    } else {
      addNewData(data, SETUP_ENDPOINT.DocumentType)
        .then((resp: any) => {
          cancelForm();
          getVisaType();
          toast.success('Document type created.', alertOptions);
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };

  const getVisaType = () => {
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`)
      .then((resp: any) => {
        const options = [];
        resp.data.map((visa) =>
          options.push({ label: visa.visa_code, value: visa.visa_id }),
        );
        setVisaOptions(options);
        if (!_.isUndefined(editId)) {
          getInitialStatus();
        } else {
          setLoader(false);
        }
      })
      .catch((e: any) => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  useEffect(() => {
    getVisaType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
          <Typography
            component={'div'}
            className="d-flex-a d-flex-sb sticky-tab">
            <Typography component={'div'} className="w-title black-white-text">
              {!_.isUndefined(editId) ? 'Edit' : 'Add'} Document Type
            </Typography>

            <Typography component={'div'} className="d-flex-a">
              <Button className="n-cancel-btn mr-2" onClick={cancelForm}>
                <Typography className="o-box" />
                Cancel
              </Button>
              <Button className="n-add-btn" type="submit" form={'add-doc-type'}>
                {_.isUndefined(editId) ? 'Submit' : 'Update'}
              </Button>
            </Typography>
          </Typography>

          <form id="add-doc-type" onSubmit={handleSubmit(onSubmitForm)}>
            <Typography
              component={'div'}
              className="addbox"
              sx={{ marginTop: 'unset !important' }}>
              <Box
                component="form"
                className="u-add-in"
                noValidate
                autoComplete="off">
                <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                  <Grid item md={6}>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Visa Type{' '}
                        <Typography component={'span'} className="Mui-error">
                          *
                        </Typography>
                      </Typography>
                      <Controller
                        control={control}
                        name={'visaId'}
                        defaultValue={null}
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              autoFocus={true}
                              closeMenuOnSelect={false}
                              labelKey={'label'}
                              valueKey={'value'}
                              options={visaOptions}
                              isMulti={true}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                setVisaTypeIds(value);
                              }}
                              placeHolder={'Select'}
                              className={'basic-multi-select'}
                              error={errors.visaId?.message}
                              // disabled={allowAccess}
                              tabIndex={1}
                            />
                            // <>
                            //   <Multiselect
                            //     {...field}
                            //     closeOnSelect={true}
                            //     placeholder={
                            //       watch('visaId').length === 0
                            //         ? 'Visa Type'
                            //         : ''
                            //     }
                            //     displayValue="visa_code"
                            //     onSelect={(selected, item) => {
                            //       if (selected.length) {
                            //         setError('visaId', {
                            //           message: '',
                            //           type: 'custom',
                            //         });
                            //       } else {
                            //         setError('visaId', {
                            //           message: 'Visa Type is required',
                            //           type: 'custom',
                            //         });
                            //       }
                            //       setValue(
                            //         'visaId',
                            //         selected
                            //           .map((visa) => visa.visa_id)
                            //           .join(','),
                            //       );
                            //     }}
                            //     onRemove={(selected, item) => {
                            //       if (selected.length) {
                            //         setError('visaId', {
                            //           message: '',
                            //           type: 'custom',
                            //         });
                            //       } else {
                            //         setError('visaId', {
                            //           message: 'Response By is required',
                            //           type: 'custom',
                            //         });
                            //       }
                            //       setValue(
                            //         'visaId',
                            //         selected
                            //           .map((visa) => visa.visa_id)
                            //           .join(','),
                            //       );
                            //     }}
                            //     selectedValues={visaTypeIds}
                            //     options={
                            //       !_.isEmpty(visaTypes) ? visaTypes : null
                            //     }
                            //     keepSearchTerm={true}
                            //     // tabIndex={1}
                            //   />
                            //   {errors.visaId?.message && (
                            //     <FormHelperText style={{ color: '#FF0000' }}>
                            //       {errors.visaId?.message}
                            //     </FormHelperText>
                            //   )}
                            // </>
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Pashto Document Type (Optional)
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="pashtoDocumentType"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder={'Pashto Document Type (Optional)'}
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.pashtoDocumentType?.message}
                              onChange={onChange}
                              // isTextArea={true}
                              tabIndex={3}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Valid Documents{' '}
                        <Typography component={'span'} className="Mui-error">
                          *
                        </Typography>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="validDocuments"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder={'Valid Documents'}
                              name={name}
                              value={value}
                              className={'custom-textarea'}
                              error={errors.validDocuments?.message}
                              onChange={onChange}
                              isTextArea={true}
                              minRows={3}
                              tabIndex={5}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Default documents for petitions
                      </Typography>
                      <Controller
                        control={control}
                        name={'defaultForPetitions'}
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              closeMenuOnSelect={false}
                              labelKey={'label'}
                              valueKey={'value'}
                              options={visaOptions}
                              isMulti={true}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                setDefaultForPetitionIds(value);
                              }}
                              placeHolder={'Select'}
                              className={'basic-multi-select'}
                              // error={errors.roleAssignedId?.message}
                              // tabIndex={1}
                            />
                            // <Multiselect
                            //   {...field}
                            //   displayValue="visa_code"
                            //   placeholder={
                            //     watch('defaultForPetitions').length === 0
                            //       ? 'Default documents for petitions'
                            //       : ''
                            //   }
                            //   onSelect={(selected, item) => {
                            //     setValue(
                            //       'defaultForPetitions',
                            //       selected.map((visa) => visa.visa_id),
                            //     );
                            //   }}
                            //   onRemove={(selected, item) => {
                            //     setValue(
                            //       'defaultForPetitions',
                            //       selected
                            //         .map((visa) => visa.visa_id)
                            //         .join(','),
                            //     );
                            //   }}
                            //   selectedValues={defaultForPetitionIds}
                            //   options={!_.isEmpty(visaTypes) ? visaTypes : []}
                            //   // tabIndex={7}
                            // />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Parsing Type
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={''}
                        name="parsingType"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              options={[
                                {
                                  label: 'G-28',
                                  value: 'g-28',
                                },
                                {
                                  label: 'I-129',
                                  value: 'i-129',
                                },
                                {
                                  label: 'I-140',
                                  value: 'i-140',
                                },
                                {
                                  label: 'LCA',
                                  value: 'lca',
                                },
                                {
                                  label: 'Passport',
                                  value: 'passport',
                                },
                                {
                                  label: 'Resume',
                                  value: 'resume',
                                },
                              ]}
                              name={name}
                              value={value}
                              onChange={onChange}
                              placeHolder={'Select'}
                              className={'custom-input'}
                              error={errors.parsingType?.message}
                              disabled={
                                watch('documentParsing') === 'yes'
                                  ? false
                                  : true
                              }
                              tabIndex={9}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Document Type{' '}
                        <Typography component={'span'} className="Mui-error">
                          *
                        </Typography>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="documentType"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder={'Document Type'}
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.documentType?.message}
                              onChange={onChange}
                              // isTextArea={true}
                              tabIndex={2}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Dari Document Type (Optional)
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="dariDocumentType"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder={'Dari Document Type (Optional)'}
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.dariDocumentType?.message}
                              onChange={onChange}
                              // isTextArea={true}
                              tabIndex={4}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Description
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="description"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder={'Description'}
                              name={name}
                              value={value}
                              className={'custom-textarea'}
                              error={errors.description?.message}
                              onChange={onChange}
                              isTextArea={true}
                              minRows={3}
                              tabIndex={6}
                            />
                          );
                        }}
                      />
                    </Typography>

                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Document Parsing
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue="no"
                        name="documentParsing"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              tabIndex={8}>
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                            </RadioGroup>
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Typography>

            {/* {_.isUndefined(editId) && (
              <Grid container>
                <Grid item md={12}>
                  <Typography component={'div'} className="d-flex-ja my-2">
                    <Button className="cancel-btn mr-2" onClick={cancelForm}>
                      <Typography className="o-box" />
                      Cancel
                    </Button>
                    <Button
                      className="add-btn"
                      type="submit"
                      form={'add-doc-type'}>
                      Submit
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            )} */}
          </form>
        </Grid>
      )}
    </Grid>
  );
};

export default DocumentAdd;
