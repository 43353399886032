import { Button, FormControlLabel, FormGroup, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

export const ReportExportButton: React.FC<any> = React.memo(
  ({ clickToDownLoad, tab, isOrg, params, setParams }) => {
    const handleChangeSucess = (event) => {
      const { checked } = event.target;
      setParams((prev) => ({
        ...prev,
        status: checked ? 'success' : 'pending',
      }));
    };

    return (
      <Typography component={'div'} className="d-flex-a d-flex-sb">
        <Typography className="black-white-text font-bold f-18">
          {tab === 'payment' ? 'Payment Reports' : 'Goal Sheet Reports'}
        </Typography>
        <Typography
          component={'div'}
          className={isOrg ? 'd-flex-a pb-2' : 'd-flex-a'}>
          {isOrg && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={params.status == 'success' ? true : false}
                    onChange={handleChangeSucess}
                  />
                }
                label={
                  <Typography className="f-16 black-white-text">
                    {params.status == 'success' ? 'Success' : 'Pending'}
                  </Typography>
                }
              />
            </FormGroup>
          )}

          <Button className="n-add-btn" onClick={clickToDownLoad}>
            Export
          </Button>
        </Typography>
      </Typography>
    );
  },
);

export default ReportExportButton;
