import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { storePetitionerData } from 'store/actions/LeadsOrPetitionerAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { UserRoleType } from 'types';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import LeadsOrPetitionsDetail from '../../Common/LeadsOrPetitions/Details';

export const LeadsDetailList: React.FC<any> = () => {
  const { editId } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { petitions } = useAppSelector((store: any) => store.petitionUser);
  const dispatch = useAppDispatch();
  const [isOppen, setIsOpen] = useState(false);
  const [leadData, setLeadData] = useState<any>({});
  const [loader, setLoader] = useState(true);
  const [selectedUser, setSelectedUser] = useState({
    data: null,
    option: 'beneficiary',
  });
  const [organizations, setOrganizations] = useState<any>([]);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const handleOpen = () => {
    if (
      leadData?.beneficiaryDetails &&
      leadData?.beneficiaryDetails.length === 1
    ) {
      const selectData = leadData?.beneficiaryDetails[0];

      setSelectedUser((pre) => ({
        ...pre,
        data: {
          beneficiaryEmail: selectData.email_id,
          beneficiaryFirstName: selectData.first_name,
          beneficiaryLastName: selectData.last_name,
          beneficiaryMobileCode: selectData.country_source_code,
          beneficiaryMobileNumber: selectData.contact_mobile,
          id: selectData?.id,
          ids: selectData.user_id,
          leadUserId: selectData.id,
          mobileCountryCode: selectData.country_code_mobile,
          mobileSocCode: '',
          petitionStarted: selectData?.petiton_started,
          sendMail: selectData.conatct_status === 'active' ? true : false,
        },
      }));
    }

    let types = 'beneficiary';
    if (leadData?.beneficiaryDetails && leadData?.beneficiaryDetails.length) {
      types = 'beneficiary';
    } else if (leadData?.petitioner_assigned) {
      types = 'petitioner';
    }
    setSelectedUser((pre) => ({
      ...pre,
      option: types,
    }));

    if (
      (leadData?.beneficiaryDetails && leadData?.beneficiaryDetails.length) ||
      leadData?.petitioner_assigned
    ) {
      setIsOpen(true);
    }
  };
  const handleClose = () => setIsOpen(false);

  const getInitialStatus = useCallback(() => {
    return getAllListData(
      `${COMMON_ENDPOINT.LeadList}?id=${String(
        editId,
      )}&status=&skip=0&take=10&sortby=&order=&visaId=&organizationId=&orgType=&leadsSelected=&associated=&name=`,
    )
      .then(async (resp) => {
        if (resp.data && resp.data.length === 0) {
          navigate('/');
          return;
        } else {
          setLeadData(
            resp.data && resp.data.length
              ? resp.data[0]
              : { count: 0, data: [] },
          );
          if (petitions && petitions.petitionData) {
            petitions.petitionData['affilateUserList'] =
              resp.data[0].affilateUserList;
            petitions.petitionData['affilate_type'] =
              resp.data[0].affilate_type;
            petitions.petitionData['isAffilateCase'] =
              resp.data[0].isAffilateCase;
            const petitionsState = {
              dependents: petitions?.dependents,
              leadList: resp.data[0],
              notes: petitions?.notes,
              petitionData: petitions?.petitionData,
              teamMemberEmails: petitions?.teamMemberEmails,
              teams: petitions?.teams,
            };
            await dispatch(storePetitionerData(petitionsState));
          }

          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      !_.isEmpty(leadData) &&
      userDetail.userRoleName !== UserRoleType.Petitioner
    ) {
      getAllListData(
        `${SETUP_ENDPOINT.SelectMenu}/petitioner${
          leadData?.isAffilateCase
            ? `?isAffilate=true&affilateRoleId=${String(
                leadData?.affilateRoleId,
              )}&firmId=${String(leadData?.attorney_assigned)}${
                leadData?.affilateRoleId === 2 && leadData?.petitioner_assigned
                  ? `&petitionerId=${String(leadData?.petitioner_assigned)}`
                  : ''
              }`
            : ''
        }`,
      ).then((orgResp: any) => {
        setOrganizations(orgResp);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadData]);

  useEffect(() => {
    if (!_.isUndefined(editId)) {
      getInitialStatus();
      if (userDetail.userRoleName === UserRoleType.Petitioner) {
        getAllListData(`/${String(SETUP_ENDPOINT.OrganizationDetails)}`).then(
          (orgResps: any) => {
            setOrganizations([
              {
                id: userDetail?.companyId,
                org_name: orgResps.org_name,
                status: true,
              },
            ]);
          },
        );
      }
    }
  }, [editId, getInitialStatus, userDetail]);

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Grid container>
      <Grid item md={12} sm={12} className="pl-0">
        <Typography
          component={'div'}
          className="black-white-text d-flex-a"
          sx={{
            fontFamily: 'Arial-Bold !important',
            fontSize: '1.25em !important',
            margin: '1.5rem 0 1.5rem 0',
          }}>
          Case:{' '}
          {leadData?.beneficiaryDetails?.length
            ? leadData?.beneficiaryDetails
                ?.map((x) => `${String(x.first_name)} ${String(x.last_name)}`)
                .join(', ')
            : ' - '}{' '}
          {leadData?.visa_name_list && leadData?.visa_name_list?.length
            ? leadData?.visa_name_list.map(
                (visa, vIndex) => `(${String(visa.visa_code)})`,
              )
            : ''}{' '}
          <div className="affliate">
            {' '}
            {leadData?.isAffilateCase ? '(Affiliate)' : ''}{' '}
          </div>
          <Modal
            open={isOppen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className="custom-modal">
              <Typography
                component={'div'}
                className="f-20 font-bold modal-title">
                Select User
              </Typography>
              <form>
                <Typography component={'div'} className="d-flex-a m-border">
                  <Typography className="f-15">Upload To:</Typography>
                  <Typography component={'div'} className="d-flex-a">
                    <Typography component={'div'} className="pl-2">
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        value={selectedUser.option}
                        onChange={(e) => {
                          setSelectedUser((pre) => ({
                            ...pre,
                            option: e.target.value,
                          }));
                        }}>
                        {leadData?.beneficiaryDetails &&
                        leadData?.beneficiaryDetails.length > 0 ? (
                          <FormControlLabel
                            value="beneficiary"
                            control={<Radio />}
                            label="Beneficiary"
                          />
                        ) : null}
                        {leadData?.petitioner_assigned ? (
                          <FormControlLabel
                            value="petitioner"
                            control={<Radio />}
                            label="Petitioner"
                          />
                        ) : null}
                      </RadioGroup>
                    </Typography>
                  </Typography>
                </Typography>

                <Typography
                  component={'div'}
                  className="custom-field mb-0"
                  sx={{
                    marginBottom: 'unset !important',
                    padding: '1.5rem 2rem 0  2rem',
                  }}>
                  {selectedUser.option === 'beneficiary' && (
                    <CustomSelect
                      isClearable={false}
                      labelKey={'fullName'}
                      valueKey={'id'}
                      value={selectedUser?.data?.id || null}
                      options={
                        leadData?.beneficiaryDetails &&
                        leadData?.beneficiaryDetails.map((ben) => ({
                          ...ben,
                          fullName:
                            ben.first_name &&
                            String(ben.first_name).concat(
                              ' '.concat(String(ben.last_name)),
                            ),
                        }))
                      }
                      onChange={(e) => {
                        const selectData = leadData?.beneficiaryDetails.find(
                          (beneficiary) => beneficiary.id === e.target.value,
                        );
                        if (selectData) {
                          setSelectedUser((pre) => ({
                            ...pre,
                            data: {
                              beneficiaryEmail: selectData.email_id,
                              beneficiaryFirstName: selectData.first_name,
                              beneficiaryLastName: selectData.last_name,
                              beneficiaryMobileCode:
                                selectData.country_source_code,
                              beneficiaryMobileNumber:
                                selectData.contact_mobile,
                              id: selectData?.id,
                              ids: selectData.user_id,
                              leadUserId: selectData.id,
                              mobileCountryCode: selectData.country_code_mobile,
                              mobileSocCode: '',
                              petitionStarted: selectData?.petiton_started,
                              sendMail:
                                selectData.conatct_status === 'active'
                                  ? true
                                  : false,
                            },
                          }));
                        } else {
                          setSelectedUser((pre) => ({
                            ...pre,
                            data: null,
                          }));
                        }
                      }}
                      {...(leadData?.beneficiaryDetails &&
                      leadData?.beneficiaryDetails.length === 1
                        ? { value: leadData?.beneficiaryDetails[0].id }
                        : {})}
                      placeHolder={'Select'}
                      className={'custom-input'}
                    />
                  )}
                </Typography>

                <Typography
                  component={'div'}
                  className="d-flex-jae modal-footer">
                  <Button
                    className="mcancel-btn f-18 font-bold mr-1"
                    onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="madd-btn f-18 font-bold"
                    onClick={() => {
                      if (
                        selectedUser.option === 'beneficiary' &&
                        selectedUser?.data &&
                        selectedUser?.data?.petitionStarted
                      ) {
                        toast.info(
                          'Petition already created for the Lead. Please go to Document Checklist in Petition to upload documents',
                          alertOptions,
                        );
                        return;
                      }
                      if (
                        selectedUser.option === 'petitioner' &&
                        leadData?.beneficiaryDetails &&
                        leadData?.beneficiaryDetails.length &&
                        leadData?.beneficiaryDetails.some(
                          (e) => e.petiton_started,
                        )
                      ) {
                        toast.info(
                          'Petition already created for the Lead. Please go to Document Checklist in Petition to upload documents',
                          alertOptions,
                        );
                        return;
                      }
                      navigate(
                        userDetail.role_id === 3
                          ? `${
                              AttorneyPrivateRoutes.LEADS.UPLOADDOCUMENT
                            }/${String(leadData.id)}${
                              searchParams.get('orgId')
                                ? '?orgId=' + searchParams.get('orgId')
                                : ''
                            }${
                              location.pathname.includes('invited')
                                ? '&invited=true&isLead=true'
                                : location.pathname.includes('associated')
                                ? '&associated=true&isLead=true'
                                : ''
                            }`
                          : userDetail.role_id === 5
                          ? leadData?.isAffilateCase &&
                            leadData?.affilateRoleId === 3
                            ? `${String(
                                AffilatePrivateRoutes.ATTORNEY_LEADS
                                  .UPLOADDOCUMENT,
                              )}/${String(leadData.id)}`
                            : `${String(
                                AffilatePrivateRoutes.PETITIONER_LEADS
                                  .UPLOADDOCUMENT,
                              )}/${String(leadData.id)}`
                          : userDetail.role_id === 2 &&
                            `${
                              PetitionerPrivateRoutes.LEADS.UPLOADDOCUMENT
                            }/${String(leadData.id)}`,
                        {
                          state: {
                            data:
                              selectedUser.option === 'beneficiary'
                                ? {
                                    ...selectedUser.data,
                                    name: `${String(
                                      selectedUser?.data?.beneficiaryFirstName,
                                    )} ${
                                      selectedUser?.data?.beneficiaryLastName
                                        ? String(
                                            selectedUser?.data
                                              ?.beneficiaryLastName,
                                          )
                                        : ''
                                    }`,
                                    personId: selectedUser?.data?.ids,
                                  }
                                : {
                                    name: organizations.find(
                                      (org) =>
                                        org.id ===
                                          leadData.petitioner_assigned &&
                                        org.status ===
                                          leadData.petitioner_associated,
                                    )?.org_name,
                                    personId: leadData.petitioner_assigned,
                                  },
                            user: selectedUser.option,
                          },
                        },
                      );
                    }}
                    {...(!selectedUser.option ||
                    (selectedUser.option === 'beneficiary' &&
                      _.isEmpty(selectedUser.data))
                      ? {
                          disabled: true,
                          style: {
                            cursor: 'not-allowed',
                            pointerEvents: 'all',
                          },
                        }
                      : {})}>
                    Submit
                  </Button>
                </Typography>
              </form>
            </Box>
          </Modal>
        </Typography>
      </Grid>
      <LeadsOrPetitionsDetail
        showType="leads"
        listData={leadData}
        handleOpen={handleOpen}
      />
    </Grid>
  );
};

export default LeadsDetailList;
