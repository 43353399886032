import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import {
  RESET_PETITIONER_SAVE,
  RESET_SAVE,
} from 'store/types/LeadsOrPetitionerTypes';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { postRequest } from 'utils/Handlers';
import { alertOptions } from 'utils/helper';

import Storage from '../../utils/Storage';
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REFRESH_TOKEN,
  VERIFY_CAPTCHA,
} from '../types/UserTypes';

export const login = (email: string, password: string, loginType?: string) => {
  return async (dispatch) => {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const params = {
      data:
        loginType === 'login'
          ? {
              email,
              password,
            }
          : {
              email,
            },
      token: '',
      url:
        loginType === 'login'
          ? MASTER_ENDPOINT.Login
          : MASTER_ENDPOINT.Login.concat('/other'),
    };
    return await postRequest(params)
      .then((response: any) => {
        const { token, first_time_login, info_message, show_popup } = response;
        const userData: any = jwtDecode(String(token));
        if (token && userData['data']?.id) {
          const tokenData: any = {
            token: token,
            userId: userData['data'].id,
          };

          Storage.setItem(
            'userRole',
            userData['data'].userRoleName.toLowerCase(),
          );
          Storage.setItem(
            userData['data'].userRoleName.toLowerCase().concat('Token'),
            JSON.stringify(tokenData),
          );
        }
        userData['data']['token'] = token;
        userData['data']['first_time_login'] = first_time_login;
        userData['data']['show_popup'] = show_popup;
        userData['data']['info_message'] = info_message;

        // userData['refreshToken'] = refreshToken;

        // getRequest({
        //   token: token,
        //   url: MASTER_ENDPOINT.SideMenu,
        // })
        //   .then((getResp: any) => {
        //     dispatch({
        //       payload: getResp,
        //       type: USER_SIDEMENU_SUCCESS,
        //     });
        //   })
        //   .catch((e: any) => {
        //     dispatch({
        //       payload: 'Something went wrong while getting sidemenus.',
        //       type: USER_SIDEMENU_FAIL,
        //     });
        //   });

        // getRequest({
        //   token: token,
        //   url: MASTER_ENDPOINT.Myprofile,
        // })
        //   .then((pResp: any) => {
        //     dispatch({
        //       payload: pResp,
        //       type: USER_PROFILES_SUCCESS,
        //     });
        //   })
        //   .catch((e: any) => {
        //     dispatch({
        //       payload: 'Something went wrong while getting profile.',
        //       type: USER_PROFILES_FAIL,
        //     });
        //   });

        dispatch({
          payload: userData['data'],
          type: USER_LOGIN_SUCCESS,
        });
      })
      .catch((error: any) => {
        let errorMessage = !error
          ? 'We are unable to reach the server. Please try after sometime'
          : 'Something went wrong!';
        if (error?.error?.message) {
          errorMessage = error?.error?.message;
        }

        if (loginType === 'login') {
          dispatch({
            payload: errorMessage,
            type: USER_LOGIN_FAIL,
          });
          toast.error(
            String(errorMessage) || 'Invalid email or password',
            alertOptions,
          );
        } else {
          toast.error(errorMessage, alertOptions);
        }
      });
  };
};

export const refreshToken: any = (token: string) => (dispatch) => {
  dispatch({
    payload: token,
    type: USER_REFRESH_TOKEN,
  });
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      payload: null,
      type: RESET_SAVE,
    });
    dispatch({
      payload: null,
      type: RESET_PETITIONER_SAVE,
    });
    dispatch({
      type: USER_LOGOUT,
    });
    Storage.clear();
  };
};

export const setRecaptcha = (captchaValue: string) => {
  return (dispatch) => {
    dispatch({
      payload: captchaValue,
      type: VERIFY_CAPTCHA,
    });
  };
};
