/* eslint-disable @typescript-eslint/no-namespace */
import './style/style.scss';

import LoadingToRedirect from 'components/LoadingToRedirect';
import PrivateRoutes from 'config/routes/PrivateRoutes';
import PublicRoutes from 'config/routes/PublicRoutes';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PROFILES_SUCCESS,
  USER_SIDEMENU_SUCCESS,
} from 'store/types/UserTypes';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { alertOptions, ValidatePublicUrl } from 'utils/helper';
import Storage from 'utils/Storage';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'ng-pdf-view-edit': React.DetailedHTMLProps<any, any>;
    }
  }
}

const App: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const { userInfo: userDetail, sidemenus }: any = useAppSelector(
    (store: any) => store.userLogin,
  );
  const TokenStatus = Storage.getTokens();
  const [loading, setLoading] = useState(true);

  const handleAppEntry = useCallback(() => {
    if (
      !_.isEmpty(TokenStatus) &&
      !Storage.isTokenExpired(TokenStatus?.token)
    ) {
      const userData: any = jwtDecode(TokenStatus.token);
      dispatch({
        payload: userData.data,
        type: USER_LOGIN_SUCCESS,
      });
      if (_.isUndefined(sidemenus) || _.isEmpty(sidemenus)) {
        getAllListData(MASTER_ENDPOINT.SideMenu).then((resp: any) => {
          dispatch({
            payload: resp,
            type: USER_SIDEMENU_SUCCESS,
          });
        });
        getAllListData(MASTER_ENDPOINT.Myprofile).then((pResp: any) => {
          dispatch({
            payload: pResp,
            type: USER_PROFILES_SUCCESS,
          });
        });
      }
    } else {
      if (location.state) {
        if (location.state?.from) {
          // toast.error('Please login again... ', alertOptions);
        } else {
          !ValidatePublicUrl(location.pathname) &&
            toast.error('Session expired! Please login again. ', alertOptions);
        }
      }
      // dispatch({
      //   type: USER_LOGOUT,
      // });
      // Storage.clear();
      // searchParams.get('hashKey') === '' && navigate('/login');
    }

    const handleInvalidToken = (e) => {
      if (!e.key && !e.oldValue && !e.newValue) {
        dispatch({
          type: USER_LOGOUT,
        });
        Storage.clear();
        searchParams.get('hashKey') === '' && navigate('/login');
      }
    };
    window.addEventListener('storage', handleInvalidToken);

    setLoading(false);

    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TokenStatus?.token]);

  useEffect(() => {
    const handlevalidToken = (e) => {
      if (e.key && !e.oldValue && e.newValue) {
        if (TokenStatus?.token) {
          const userData: any = jwtDecode(TokenStatus?.token);
          dispatch({
            payload: userData?.data,
            type: USER_LOGIN_SUCCESS,
          });
        }
      }
    };
    window.addEventListener('storage', handlevalidToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TokenStatus?.token]);

  useEffect(() => {
    setLoading(true);
    handleAppEntry();
  }, [handleAppEntry]);

  if (loading) {
    return <LoadingToRedirect />;
  }
  return !_.isEmpty(userDetail) ? (
    <Suspense fallback={<div>loading...</div>}>
      <PrivateRoutes />
    </Suspense>
  ) : (
    <PublicRoutes />
  );
};

export default App;
