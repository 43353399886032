import { Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';

import useStyles from '../../PrivateStyle';
import WinningStrategyListDisplay from './WinningStrategyListDisplay';
import WinningStrategyListTitle from './WinningStrategyListTitle';

type WinningStrategyLists = {
  lists;
  setFilterParams;
  filterParams;
};

export const WinningStrategyList: React.FC<WinningStrategyLists> = ({
  lists,
  setFilterParams,
  filterParams,
}) => {
  const classes = useStyles();
  return (
    <Typography component={'div'}>
      {!_.isEmpty(lists) && lists.length > 0 ? (
        lists.map((data, index) => (
          <Typography component={'div'} key={index}>
            <WinningStrategyListTitle
              key={index}
              title={data.name}
              setFilterParams={setFilterParams}
              filterParams={filterParams}
            />
            <WinningStrategyListDisplay
              data={data.questionDetails}
              key={index}
              filterParams={filterParams}
            />
          </Typography>
        ))
      ) : (
        <>
          <WinningStrategyListTitle
            title="Winning Strategies"
            setFilterParams={setFilterParams}
            filterParams={filterParams}
          />
          <Typography component={'div'} className="leadBox">
            <Typography
              className="leadItem"
              sx={{ marginBottom: 'unset !important' }}>
              <Typography className={classes.userItemInner} />
              <Typography className="d-flex d-flex-sb w-100">
                <Typography
                  component={'div'}
                  sx={{ paddingLeft: '.5rem' }}
                  className="d-flex-1">
                  <Typography
                    className="d-flex content-center black-white-text ctitle"
                    sx={{
                      opacity: '0.9',
                    }}>
                    No Record(s) Found.
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
          </Typography>
        </>
      )}
    </Typography>
  );
};

export default WinningStrategyList;
