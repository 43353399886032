import { Checkbox, Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import { instantState } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
// import { useAppDispatch } from 'store/hooks';
import { alertOptions } from 'utils/helper';

declare const Heartland: any;

type HpsProps = {
  submitted: any;
  setSubmitted: any;
  amount: string;
  confirm: boolean;
  setConfirm: any;
  setLoader: any;
  googleCaptchaCheck: (src: any) => void;
  setReCaptchaValue: (src: any) => void;
  reCaptchaValue: any;
  toast: any;
};

const HeartLander: React.FC<HpsProps> = ({
  submitted,
  setSubmitted,
  amount,
  confirm,
  setConfirm,
  setReCaptchaValue,
  reCaptchaValue,
  toast,
  googleCaptchaCheck,
  setLoader,
}) => {
  // const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hps, setHps] = useState(null);
  const [internalLoader, setInternalLoader] = useState(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorVal, setErrorVal] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [success, setSuccess] = useState(null);

  const initiateHeartLand = useCallback(() => {
    setHps(
      new Heartland.HPS({
        // Configure the iframe fields to tell the library where
        // the iframe should be inserted into the DOM and some
        // basic options
        fields: {
          cardCvv: {
            placeholder: 'CVV',
            target: 'iframesCardCvv',
          },
          cardExpiration: {
            placeholder: 'MM / YYYY',
            target: 'iframesCardExpiration',
          },
          cardNumber: {
            placeholder: '•••• •••• •••• ••••',
            target: 'iframesCardNumber',
          },
          submit: {
            target: 'iframesSubmit',
            value: `Pay $ ${parseFloat(amount).toFixed(2)}`,
          },
        },

        onEvent: function (ev) {
          // console.log(ev);
        },
        // Callback when an error is received from the service
        onTokenError: async function (resp) {
          const h = { ...resp, dateNow: Date.now() };
          setErrorVal({ ...resp, dateNow: Date.now() });
          const imitisateVal = await instantState(setErrorVal);
          setInternalLoader && setInternalLoader(true);
          setTimeout(() => {
            setInternalLoader && setInternalLoader(false);
            setSubmitted(true);
            if (h.dateNow === imitisateVal.dateNow)
              toast && toast.error(resp?.error?.message, alertOptions);
          }, 2000);
        },

        // Callback when a token is received from the service
        onTokenSuccess: async function (resp) {
          const h = { ...resp, dateNow: Date.now() };
          setSuccess({ ...resp, dateNow: Date.now() });
          setSubmitted(true);
          const confirm = await instantState(setConfirm);
          const reCaptchaValue = await instantState(setReCaptchaValue);
          if (!confirm || !reCaptchaValue) {
            return;
          }
          const imitisateVal = await instantState(setSuccess);
          setInternalLoader && setInternalLoader(true);
          setTimeout(() => {
            setInternalLoader && setInternalLoader(false);
            setSubmitted(true);
            if (confirm && h.dateNow === imitisateVal.dateNow) {
              googleCaptchaCheck(resp);
            }
          }, 2000);
        },

        publicKey: String(process.env.REACT_APP_PAYMENT_PUBLIC_KEY),
        // Collection of CSS to inject into the iframes.
        // These properties can match the site's styles
        // to create a seamless experience.
        style: {
          '#heartland-field': {
            '-o-transition':
              'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
            '-webkit-box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075)',
            '-webkit-transition':
              'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
            'background-color': '#fff',
            border: '1px solid #ccc',
            'border-radius': '0px',
            'box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075)',
            'box-sizing': 'border-box',
            color: '#555',
            display: 'block',
            'font-family': 'sans-serif',
            'font-size': '14px',
            height: '35px',
            'line-height': '1.42857143',
            padding: '6px 12px',
            transition:
              'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
            width: '100%',
          },
          '#heartland-field-wrapper #heartland-field:focus': {
            border: '1px solid #3989e3',
            'box-shadow': 'none',
            height: '35px',
            outline: 'none',
          },
          '#heartland-field.invalid.card-type-amex': {
            background:
              'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right',
            'background-position-y': '-44px',
            'background-size': '50px 90px',
          },
          '#heartland-field.invalid.card-type-discover': {
            background:
              'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right',
            'background-position-y': '-44px',
            'background-size': '85px 90px',
          },
          '#heartland-field.invalid.card-type-jcb': {
            background:
              'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right',
            'background-position-y': '-44px',
            'background-size': '55px 94px',
          },
          '#heartland-field.invalid.card-type-mastercard': {
            background:
              'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right',
            'background-position-y': '-52px',
            'background-size': '62px 105px',
          },
          '#heartland-field.invalid.card-type-visa': {
            background:
              'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right',
            'background-position-y': '-44px',
            'background-size': '83px 88px',
          },
          '#heartland-field.valid.card-type-amex': {
            background:
              'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right top',
            'background-size': '50px 90px',
          },
          '#heartland-field.valid.card-type-discover': {
            background:
              'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right',
            'background-position-y': '1px',
            'background-size': '85px 90px',
          },
          '#heartland-field.valid.card-type-jcb': {
            background:
              'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right top',
            'background-position-y': '2px',
            'background-size': '55px 94px',
          },
          '#heartland-field.valid.card-type-mastercard': {
            background:
              'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right',
            'background-position-y': '-1px',
            'background-size': '62px 105px',
          },
          '#heartland-field.valid.card-type-visa': {
            background:
              'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right top',
            'background-size': '82px 86px',
          },
          '#heartland-field[name=cardCvv]': {
            background:
              'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/cvv1.png?raw=true) no-repeat right',
            'background-position': 'right 10px bottom 6px',
            'background-size': '42px 22px',
          },
          '#heartland-field[name=submit]': {
            'background-color': '#36b46e',
            border: '0px solid transparent',
            color: '#ffffff',
            'font-family': 'sans-serif',
            'text-transform': 'uppercase',
          },
          '#heartland-field[name=submit]:focus': {
            'background-color': '#258851',
            color: '#ffffff',
            outline: 'none',
          },
          '#heartland-field[name=submit]:hover': {
            'background-color': '#258851',
          },
          '#heartland-field[placeholder]': {
            'letter-spacing': '3px',
          },
          'heartland-field-wrapper #heartland-field': {
            height: '35px',
          },
          input: {
            background: '#fff',
            border: '1px solid',
            borderColor: '#bbb3b9 #c7c1c6 #c7c1c6',
            boxSizing: 'border-box',
            fontFamily: 'Arial-Regular',
            fontSize: '16px',
            height: '35px',
            lineHeight: '1',
            margin: '0 .5em 0 0',
            maxWidth: '100%',
            outline: '0',
            padding: '0.5278em',
            verticalAlign: 'baseline',
            width: '100% !important',
          },
          'input#heartland-field[name=cardNumber]': {
            background:
              'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-inputcard-blank@2x.png?raw=true) no-repeat right',
            'background-position': 'right 10px bottom 6px',
            'background-size': '36px 22px',
          },
          'input#heartland-field[name=cardNumber]::-ms-clear': {
            display: 'none',
          },
          'input[type=submit]': {
            '-moz-user-select': 'none',
            '-ms-touch-action': 'manipulation',
            '-ms-user-select': 'none',
            '-webkit-user-select': 'none',
            backgroundColor: '#337ab7',
            backgroundImage: 'none',
            border: '1px solid transparent',
            borderColor: '#2e6da4',
            borderRadius: '4px',
            boxSizing: 'border-box',
            color: '#fff',
            cursor: 'pointer',
            display: 'inline-block',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.42857143',
            marginBottom: '0',
            padding: '6px 12px',
            textAlign: 'center',
            touchAction: 'manipulation',
            userSelect: 'none',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
          },
        },

        type: 'iframe',
      }),
    );
  }, [
    amount,
    googleCaptchaCheck,
    setConfirm,
    setReCaptchaValue,
    setSubmitted,
    toast,
  ]);

  useEffect(() => {
    initiateHeartLand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form-wrapper">
      {internalLoader ? <div className="liberty-loader" /> : ''}

      <form
        id="iframes"
        action=""
        method="GET"
        className="pay-pad"
        style={{ border: 'unset !important', padding: 'unset !important' }}>
        <div id="ss-banner" />
        <Grid container columnSpacing={{ md: 2, sm: 1 }}>
          <Grid item md={6} sm={12}>
            <Typography component={'div'} className="custom-field">
              <Typography
                component={'label'}
                className="custom-label"
                htmlFor="iframesCardNumber"
                sx={{ lineHeight: '1.7rem !important' }}>
                Card Holder Name <span className="required">*</span>
              </Typography>
              <Typography component={'div'} id="iframesCardHolderName">
                <CustomInput
                  className="custom-input"
                  placeHolder="Name"
                  id="heartland-field"
                  name="holdername"
                />
              </Typography>
            </Typography>
            <Typography component={'label'} className="custom-field">
              <Typography
                component={'label'}
                className="custom-label"
                htmlFor="iframesCardExpiration"
                sx={{ lineHeight: '1.7rem !important' }}>
                Card Expiration <span className="required">*</span>
              </Typography>
              <Typography component={'div'} id="iframesCardExpiration" />
            </Typography>
          </Grid>
          <Grid item md={6} sm={12}>
            <Typography component={'div'} className="custom-field">
              <Typography
                component={'label'}
                className="custom-label"
                htmlFor="iframesCardNumber"
                sx={{ lineHeight: '1.7rem !important' }}>
                Card Number <span className="required">*</span>
              </Typography>
              <Typography component={'div'} id="iframesCardNumber" />
            </Typography>

            <Typography component={'div'} className="custom-field">
              <Typography
                component={'label'}
                className="custom-label"
                sx={{ lineHeight: '1.7rem !important' }}
                htmlFor="iframesCardCvv">
                Card CVV <span className="required">*</span>
              </Typography>
              <Typography component={'div'} id="iframesCardCvv" />
            </Typography>
          </Grid>
          <Grid item md={6} sm={12}>
            <Typography component={'div'} className="captca">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                onChange={(e) => setReCaptchaValue(e)}
              />
            </Typography>
            {submitted && !reCaptchaValue && (
              <div
                className="invalid-feedback font-bold"
                style={{ color: 'red' }}>
                <div>Captcha is required</div>
              </div>
            )}
          </Grid>
        </Grid>
        <Typography component={'div'} className="d-flex-a d-flex-sb">
          <Typography component={'div'} className="d-flex-a">
            <Checkbox
              className="pb-0 pl-0 sv"
              checked={confirm}
              onChange={(e) => setConfirm(e.target.checked)}
              color="primary"
            />
            <Typography
              className="f-15 font-bold pf-t"
              style={{
                color: submitted && !confirm ? 'red' : 'black',
              }}>
              I AGREE TO THE TERMS OF USE AND PRIVACY POLICY
            </Typography>
          </Typography>
          <div
            id="iframesSubmit"
            style={{ textAlign: 'right', width: '210px' }}
          />
        </Typography>
      </form>
    </div>
  );
};

export default HeartLander;
