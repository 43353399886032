import { CircularProgress, TableContainer, Typography } from '@mui/material';
// import InfinityScroll from 'components/InfinityScroll';
import { AttorneyPrivateRoutes } from 'config';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
// import Pagination from 'components/Pagination';
import { useNavigate } from 'react-router-dom';

import useStyles from '../../PrivateStyle';

type DocumentParsingLists = {
  lists;
  params;
  setParams: any;
  getMoreLoader?: boolean;
  hasMoreResults?: boolean;
  getMoreList?: any;
  setListData?: any;
};

export const DocumentParsingList: React.FC<DocumentParsingLists> = ({
  params,
  setParams,
  lists,
  getMoreLoader,
  hasMoreResults,
  getMoreList,
  setListData,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <TableContainer
      sx={{
        backgroundColor: 'unset !important',
        borderRadius: '10px 10px 0px 0px',
      }}>
      <Typography
        component={'div'}
        className={classes.scrollbox}
        id="scrollParent">
        <InfiniteScroll
          dataLength={
            lists && lists.data && lists.data.length ? lists.data.length : 0
          }
          style={{
            overflow: 'unset',
          }}
          next={getMoreList && getMoreList}
          hasMore={hasMoreResults}
          scrollableTarget={'scrollParent'}
          loader={
            getMoreLoader && (
              <div
                style={{
                  backgroundColor: '#ffffff',
                  textAlign: 'center',
                  width: '100%',
                }}>
                <CircularProgress
                  size={30}
                  sx={{ color: '#002c69', marginBottom: 10, marginTop: 10 }}
                />
              </div>
            )
          }
          endMessage>
          <Typography component={'div'}>
            <table className="lead-table table-hover">
              <thead>
                <tr>
                  <th className="W-5">BENEFICIARY</th>
                  <th className="W-1">STATUS</th>
                  <th className="W-1">DATE</th>
                </tr>
              </thead>
              <tbody>
                {lists && lists.data.length > 0 ? (
                  lists.data.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        navigate(
                          `${String(
                            AttorneyPrivateRoutes.DOCUMENTI129PREVIEWFOREXTRACTIONID,
                          )}/${String(item.id)}`,
                        );
                      }}
                      style={{ cursor: 'pointer' }}>
                      <td>
                        {item.beneficiery_name ? item.beneficiery_name : '-'}
                      </td>
                      <td>
                        {item.parsing_status
                          ? capitalize(item.parsing_status)
                          : '-'}
                      </td>
                      <td>
                        {item.created_at
                          ? moment(item.created_at).format('yyyy-MM-DD hh:mm a')
                          : '-'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className={'text-center'}>
                    <td colSpan={4}>No Record(s) Found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Typography>
        </InfiniteScroll>
      </Typography>
    </TableContainer>
  );
};

export default DocumentParsingList;
