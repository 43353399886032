import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from '@mui/material';
import CustomCheckBox from 'components/CustomCheckBox/customCheckBox';
import CustomInput from 'components/CustomInput/customInput';
import CustomTextArea from 'components/CustomTextArea/customTextArea';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  checkAns,
  checkRadioButtonEnableAns,
  getColorFormat,
  getColorFormatbyTextArea,
} from 'utils/helper';

import { DocumentViewer } from '../CustomDocumentViewer/CustomeDocumentViewer';

type Props = {
  fileUrl: string;
  parseInfo: Array<any>;
  onConfirm: (src, val) => void;
  setParseInfo?: (src: any) => void;
  uploadDisable?: boolean;
  BackBtn?: boolean;
  inputDisable?: boolean;
  fileType?: string;
};

export const PdfParsing: React.FC<Props> = (props: Props) => {
  const {
    fileUrl,
    onConfirm,
    parseInfo,
    setParseInfo,
    uploadDisable,
    BackBtn,
    inputDisable,
    fileType = '',
  } = props;
  const navigate = useNavigate();

  const checkedOptionVal = (e, checkedOption, qus, index) => {
    if (
      qus &&
      // eslint-disable-next-line no-prototype-builtins
      qus.hasOwnProperty('multiple_pdf_field') &&
      qus.multiple_pdf_field.length
    ) {
      const newArr = [];
      qus.multiple_pdf_field.map((val) => {
        if (val.response.toLowerCase() === checkedOption.toLowerCase()) {
          if (qus.response_type === 5) {
            val.status = e.target.checked;
          } else {
            val.status = e.target.checked;
          }
        } else if (val.response.toLowerCase() !== checkedOption.toLowerCase()) {
          if (qus.response_type === 5) {
            val.status = false;
          }
        }
        newArr.push({ ...val });
      });
      qus.multiple_pdf_field = [];
      newArr.map((newVal) => {
        qus.multiple_pdf_field.push({ ...newVal });
      });
      qus.answer = JSON.stringify(qus.multiple_pdf_field);
      const arr = parseInfo;
      arr[index] = qus;
      setParseInfo([...arr]);
    }
  };
  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <Typography
              className="assem-box"
              id="parentdocId"
              sx={{
                maxHeight: '100vh',
                minHeight: '100vh',
                overflow: 'auto',
              }}>
              <DocumentViewer
                fileBlob={fileUrl}
                fileType={fileType}
                width={
                  document.getElementById('parentdocId') &&
                  document.getElementById('parentdocId').offsetWidth
                    ? `${
                        document.getElementById('parentdocId').offsetWidth - 100
                      }px`
                    : 'auto'
                }
              />
            </Typography>
          </Grid>

          <Grid item md={6} sm={12}>
            <Typography component={'div'}>
              <Box
                component="form"
                className="u-add-in"
                sx={{
                  backgroundColor: 'unset !important',
                  border: 'unset !important',
                  maxHeight: '100vh',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  padding: 'unset !important',
                }}
                noValidate
                autoComplete="off">
                <Grid container>
                  {parseInfo && parseInfo.length > 0 ? (
                    parseInfo.map(
                      (item, index) =>
                        item.questionId && (
                          <Grid item md={12} key={index}>
                            <Typography component={'div'}>
                              <Typography
                                component={'div'}
                                className="custom-field u-pr-1">
                                <Typography
                                  component={'div'}
                                  className="custom-label">
                                  {item.questionName}
                                </Typography>
                                {/* <div className="mb-1">{item?.lincode}</div> */}

                                {[2].includes(item.response_type) && (
                                  <CustomTextArea
                                    className={''.concat(
                                      item?.answerConfidence !== '90'
                                        ? getColorFormatbyTextArea(
                                            item?.answerConfidence,
                                          )
                                        : 'custom-textarea',
                                    )}
                                    placeHolder=""
                                    onChange={(e) => {
                                      const arr = parseInfo;
                                      arr[index].answer = e.target.value;
                                      setParseInfo([...arr]);
                                    }}
                                    value={item?.answer}
                                    disabled={inputDisable}
                                  />
                                )}
                                {![5, 6, 2].includes(item.response_type) && (
                                  <CustomInput
                                    className={''.concat(
                                      item?.answerConfidence !== '90'
                                        ? getColorFormat(item?.answerConfidence)
                                        : 'custom-input',
                                    )}
                                    placeHolder=""
                                    onChange={(e) => {
                                      const arr = parseInfo;
                                      arr[index].answer = e.target.value;
                                      setParseInfo([...arr]);
                                    }}
                                    value={item?.answer}
                                    disabled={inputDisable}
                                  />
                                )}

                                {[6].includes(item.response_type) &&
                                  (item.response && item.response.length
                                    ? item.response.map(
                                        (checkVal, checkBoxIndex) => (
                                          <Typography
                                            key={checkBoxIndex}
                                            component={'div'}>
                                            <CustomCheckBox
                                              name={checkVal}
                                              checked={checkAns(
                                                checkVal,
                                                item.answer,
                                              )}
                                              value={checkVal}
                                              onChange={(event) =>
                                                checkedOptionVal(
                                                  event,
                                                  checkVal,
                                                  item,
                                                  index,
                                                )
                                              }
                                              disabled={inputDisable}
                                            />
                                          </Typography>
                                        ),
                                      )
                                    : null)}

                                {[5].includes(item.response_type) &&
                                  (item.response && item.response.length
                                    ? item.response.map(
                                        (checkVal, checkBoxIndex) => (
                                          <Typography
                                            component={'div'}
                                            key={checkBoxIndex}>
                                            <Typography component={'div'}>
                                              <FormControlLabel
                                                className="radio-bottom"
                                                value={checkVal}
                                                name={item.questionId}
                                                checked={checkAns(
                                                  checkVal,
                                                  item.answer,
                                                )}
                                                control={<Radio />}
                                                onChange={(event) =>
                                                  checkedOptionVal(
                                                    event,
                                                    checkVal,
                                                    item,
                                                    index,
                                                  )
                                                }
                                                disabled={inputDisable}
                                                label={checkVal}
                                              />
                                            </Typography>
                                            {item.response.length - 1 ===
                                              checkBoxIndex &&
                                              !inputDisable &&
                                              checkRadioButtonEnableAns(
                                                item.answer,
                                              ) && (
                                                <Typography
                                                  className="mt-1 n-add-btn"
                                                  component={'button'}
                                                  onClick={() =>
                                                    checkedOptionVal(
                                                      {
                                                        target: {
                                                          checked: false,
                                                        },
                                                      },
                                                      '',
                                                      item,
                                                      index,
                                                    )
                                                  }>
                                                  Reset
                                                </Typography>
                                              )}
                                          </Typography>
                                        ),
                                      )
                                    : null)}
                              </Typography>
                            </Typography>
                          </Grid>
                        ),
                    )
                  ) : (
                    <Typography> Data Not Found</Typography>
                  )}
                </Grid>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Typography component={'div'} className="d-flex-jae mt-1 ">
          {!uploadDisable && (
            <Button
              className="back-btn mr-1"
              onClick={() => onConfirm('reUpload', '')}>
              Re Upload
            </Button>
          )}
          {BackBtn && (
            <Button className="back-btn mr-1" onClick={() => navigate(-1)}>
              Back
            </Button>
          )}
          {!inputDisable && (
            <Button
              className="n-add-btn"
              onClick={() => onConfirm('submit', parseInfo)}>
              Submit
            </Button>
          )}
        </Typography>
      </DialogActions>
    </>
  );
};
