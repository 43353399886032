import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';

import PetitionerRequestlDocumentAdd from './PetitionerRequestDocumentAdd';

type PetitionerRequestAdditionalDocumentsIterFace = {
  petitions: any;
  petitionId: any;
  moduleList: any;
  setReloadAPI: any;
  reloadAPI: any;
};

export const PetitionerRequestAdditionalDocuments: React.FC<
  PetitionerRequestAdditionalDocumentsIterFace
> = ({ petitions, petitionId, moduleList, setReloadAPI, reloadAPI }) => {
  const [showAddNew, setShowAddNew] = useState(false);
  return (
    <Grid item md={12} component={'div'} className="custom-label addbox">
      <Box
        component="form"
        className="lead-box"
        style={{ padding: '1rem !important' }}
        noValidate
        autoComplete="off">
        <Typography component={'div'} className="d-flex-a d-flex-sb mb-1">
          <Typography
            component={'div'}
            className="uadd-title"
            sx={{
              paddingBottom: 'unset !important',
              paddingTop: 'unset !important',
            }}>
            Documents
          </Typography>
          <Typography className="d-flex-a">
            <Typography
              className="d-flex-ja"
              sx={{
                _color: '#fff !important',
                backgroundColor: '#4E72D1',
                borderRadius: '50%',
                get color() {
                  return this._color;
                },
                set color(value) {
                  this._color = value;
                },
                cursor: 'pointer',
                fontFamily: 'Arial-Bold !important',
                fontSize: '35px !important',
                height: '44px',
                width: '44px',
              }}
              onClick={() => {
                setShowAddNew(showAddNew ? false : true);
              }}>
              {showAddNew ? '-' : '+'}
            </Typography>
          </Typography>
        </Typography>
      </Box>
      {showAddNew && (
        <PetitionerRequestlDocumentAdd
          petitions={petitions}
          petitionId={petitionId}
          moduleList={moduleList}
          reloadAPI={reloadAPI}
          setReloadAPI={setReloadAPI}
        />
      )}
    </Grid>
  );
};

export default PetitionerRequestAdditionalDocuments;
