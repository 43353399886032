/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import EditIcon from '@mui/icons-material/Edit';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  Button,
  Modal,
  Stack,
  Switch,
  SwitchProps,
  Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Bedit from 'assets/images/bpedit.svg';
import profileimagelogo from 'assets/images/d-avator.svg';
import pencil from 'assets/images/pencil.svg';
import pencilImg from 'assets/images/pencil-img.svg';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomDatePicker from 'components/CustomDatePicker/customDatePicker';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { FileUploadDialog } from 'components/Fileupload/fileuploadDialog';
import _ from 'lodash';
import moment from 'moment';
// import Multiselect from 'multiselect-react-dropdown';
import React, {
  useCallback,
  useEffect,
  // useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { MyFirmtype, PaymentGatewayTypes } from 'types/index';
import { fileApiUrl } from 'utils/Config';
import {
  COMMON_ENDPOINT,
  MASTER_ENDPOINT,
  SETUP_ENDPOINT,
} from 'utils/Constant';
import { alertOptions, profileFileType, validateDate } from 'utils/helper';
import { MyfirmSchema, PaymentGatewaySchema } from 'utils/ValidatorSchema';

import Payment from '../../../../assets/images/stripe-pg.png';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  '& .MuiButtonBase-root': {
    padding: '0 0 !important',
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        border: 0,
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      color: '#fff',
      transform: 'translateX(16px)',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: '6px solid #fff',
      color: '#33cf4d',
    },
    margin: 2,
    padding: 0,
    transitionDuration: '300ms',
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    height: 22,
    width: 22,
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
  height: 26,
  padding: 0,
  width: 42,
}));

export const MyFirm: React.FC<any> = () => {
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [loader, setLoader] = useState(false);
  const [loaderPG, setLoaderPG] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [followSteps, setFollowSteps] = useState(false);
  // const [paymentResp, setPaymentResp] = useState<any>({});

  // const uploadRef = useRef<any>();
  // const multiSelectRef = React.createRef<any>();

  const [profileImage, setProfileImage] = useState<any>({
    imageURL: null,
    selectedImage: null,
  });
  const [countries, setCountries] = useState<any>({
    cities: [],
    coutries: [],
    state: [],
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [attorneySupportIds, setAttorneySupportIds] = useState<any>([]);
  const [contactPersonList, setContactPersonList] = useState<any>([]);
  const [caseManagerList, setCaseManagerList] = useState<any>([]);
  const [paymentResp, setPaymentResp] = useState<any>([]);
  const [useEditDetail, setUseEditDetail] = useState(true);
  const [editDetailPG, setEditDetailPG] = useState(false);
  const [fileUploadOpen, setFileUploadOpen] = useState<any>({
    open: false,
  });
  const ChooseThePaymentTypes = [
    // {
    //   label: 'Stripe',
    //   value: 'stripe',
    // },
    {
      label: 'HeartLand',
      value: 'heartland',
    },
  ];

  const modelClose = () => {
    setModalOpen(false);
  };
  const modalClose2 = () => {
    setStripeValue('contract_payment_enable', true);
    setModalOpen(false);
  };

  const {
    control: control,
    handleSubmit: handleSubmit,
    setValue: setValueAdd,
    getValues,
    setError,
    watch,
    formState: { errors },
  } = useForm<MyFirmtype>({
    criteriaMode: 'all',
    defaultValues: {
      attorneyAddress: '',
      attorneyCaseManager: '',
      attorneyCity: '',
      attorneyContactPerson: '',
      attorneyContactSupport: [],
      attorneyCountry: '',
      attorneyEmail: '',
      attorneyFEIN: '',
      attorneyFax: '',
      attorneyName: '',
      attorneyPhone: '',
      attorneyState: '',
      attorneyStatebarNumber: '',
      attorneyStatus: 'active',
      attorneyUSCISElisAccountNumber: '',
      attorneyWebsite: '',
      attorneyZipCode: '',
      attorneylicensingAuthority: '',
      ccMailFirm: '',
      streetNumberAndName: '',
      unitNumber: '',
      unitType: '',
      validTill: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(MyfirmSchema),
  });

  const {
    control: controlPG,
    handleSubmit: handlePGSubmit,
    formState: { errors: pgErrors },
    setValue: setStripeValue,
    setError: setStripeError,
    // reset: resetPG,
    watch: watchPG,
  } = useForm<PaymentGatewayTypes>({
    criteriaMode: 'all',
    defaultValues: {
      achServiceCharge: '0.00',
      cardServiceCharge: '0.00',
      contract_payment_enable: false,
      heartlandSecretId: '',
      paymentSecretId: '',
      paymentType: 'heartland',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(PaymentGatewaySchema),
  });

  const getFirmPaymentDetails = () => {
    setLoaderPG(true);
    getAllListData(`${String(COMMON_ENDPOINT.PaymentGateway)}`)
      .then((resp: any) => {
        setPaymentResp(resp);
        setLoaderPG(false);
        setStripeValue('contract_payment_enable', resp.contract_payment_enable);
        setStripeValue(
          'paymentType',
          resp.id ? (resp?.payment_type ? resp.payment_type : '') : 'heartland',
        );
        setStripeValue(
          'paymentSecretId',
          resp?.payment_secret_id ? resp.payment_secret_id : '',
        );
        setStripeValue(
          'heartlandSecretId',
          resp?.payment_type === 'heartland' && resp?.payment_secret_id
            ? resp.payment_secret_id
            : '',
        );
        setStripeValue(
          'cardServiceCharge',
          resp?.card_service_charge ? String(resp.card_service_charge) : '0.00',
        );
        setStripeValue(
          'achServiceCharge',
          resp?.ach_service_charge ? String(resp.ach_service_charge) : '0.00',
        );
      })
      .catch((e: any) => {
        setLoaderPG(false);
      });
  };

  const cancelForm = () => {
    getInitialStatus();
    setUseEditDetail(true);
  };
  const cancelFormPg = () => {
    getFirmPaymentDetails();
    setEditDetailPG(false);
    setStripeValue(
      'contract_payment_enable',
      paymentResp.contract_payment_enable,
    );
  };
  // const handleCheck = (e) => {
  //   setStripeValue('contract_payment_enable', e.target.checked);
  // };
  const onSubmitForm = async (data: any) => {
    if (
      data['validTill'] &&
      !validateDate(moment(data['validTill']).format('MM/DD/YYYY'))
    ) {
      setError('validTill', {
        message: 'Not a valid date format.',
        type: 'custom',
      });
    } else {
      setLoader(true);
      const postFormData = new FormData();
      postFormData.append(
        'attorneyLogo',
        profileImage.selectedImage ? profileImage.selectedImage : null,
      );

      await Object.keys(data).forEach((key) => {
        if (key === 'validTill') {
          postFormData.append(
            'validTill',
            data['validTill']
              ? moment(data['validTill']).format('MM/DD/YYYY')
              : '',
          );
        } else {
          postFormData.append(key, data[key]);
        }
      });

      await updateData(
        userDetail?.companyId,
        postFormData,
        COMMON_ENDPOINT.ManageLawFirm,
      )
        .then((et) => {
          setLoader(false);
          getInitialStatus();
          setUseEditDetail(true);
          toast.success('Updated successfully', alertOptions);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const onSubmitPGForm = (data: any) => {
    if (data.paymentType === 'stripe' && data.paymentSecretId === '') {
      setStripeError('paymentSecretId', {
        message: 'Secret Key is required',
        type: 'custom',
      });
      return;
    } else {
      setStripeError('paymentSecretId', {
        message: '',
        type: 'custom',
      });
    }

    if (data.paymentType === 'heartland' && data.heartlandSecretId === '') {
      setStripeError('heartlandSecretId', {
        message: 'Secret Key is required',
        type: 'custom',
      });
      return;
    } else {
      setStripeError('heartlandSecretId', {
        message: '',
        type: 'custom',
      });
    }

    setLoaderPG(true);
    if (data.contract_payment_enable) {
      const payload = {
        achServiceCharge: Number(data.achServiceCharge),
        cardServiceCharge: Number(data.cardServiceCharge),
        paymentEnable: data.contract_payment_enable,
        paymentSecretId:
          data.paymentType === 'stripe'
            ? data.paymentSecretId
            : data.paymentType === 'heartland'
            ? data.heartlandSecretId
            : '',
        paymentType: data.paymentType,
      };

      addNewData(payload, COMMON_ENDPOINT.PaymentGateway)
        .then(() => {
          setLoaderPG(false);
          setEditDetailPG(false);
          getFirmPaymentDetails();
          toast.success('Payment Configured Successfully', alertOptions);
        })
        .catch(() => {
          setLoaderPG(false);
        });
    } else {
      paymentdisable();
    }
  };

  const paymentdisable = () => {
    updateData(
      '',
      { paymentEnable: false },
      `${String(COMMON_ENDPOINT.PaymentGateway)}/payment-disable-enable`,
    )
      .then((Resp: any) => {
        setLoaderPG(false);
        setEditDetailPG(false);
        getFirmPaymentDetails();
        toast.success('Payment details updated successfully', alertOptions);
      })
      .catch(() => {
        setLoaderPG(false);
      });
  };

  const getCountriesDependent = (key?: string, value?: number) => {
    getAllListData(
      key === ''
        ? MASTER_ENDPOINT.Countries
        : `${MASTER_ENDPOINT.Countries}?${key}=${String(value)}`,
    )
      .then((countryResp: any) => {
        if (key === 'countryId') {
          setCountries((prev) => ({
            ...prev,
            countries: countryResp.countries,
            states: countryResp.states,
          }));
        }
        if (key === 'stateId') {
          setCountries((prev) => ({
            ...prev,
            cities: countryResp.cities,
            countries: countryResp.countries,
          }));
        }
        if (key === '') {
          setCountries({
            cities: countryResp.cities,
            countries: countryResp.countries,
            states: countryResp.states,
          });
        }
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };

  const getAttorenyContactPersonList = useCallback((firmId) => {
    getAllListData(
      `${SETUP_ENDPOINT.SelectMenu}/lawfirm/${String(firmId)}`,
    ).then((resp: any) => {
      setContactPersonList(resp);
      const options = [];
      resp.map((e) => options.push({ label: e.full_name, value: e.id }));
      setContactPersonList(options);
      setCaseManagerList(resp);

      const filterSupprotValues =
        resp &&
        resp.length &&
        watch('attorneyContactSupport') &&
        watch('attorneyContactSupport').length
          ? resp.filter((e) => watch('attorneyContactSupport').includes(e.id))
          : [];

      setAttorneySupportIds(filterSupprotValues);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialStatus = useCallback(async () => {
    setLoader(true);
    await getAllListData(
      `/${String(userDetail.userRoleName)}${SETUP_ENDPOINT.MyFirm}`,
    )
      .then((resp) => {
        const {
          firm_address,
          firm_name,
          firm_city,
          firm_country,
          firm_email,
          firm_cc_mail,
          firm_logo,
          firm_state,
          firm_fax,
          firm_fein,
          firm_licensing_authority,
          firm_phone,
          firm_state_bar_number,
          firm_status,
          firm_street_number_and_name,
          firm_unit_number,
          firm_unit_type,
          firm_uscis_elis_account_number,
          firm_valid_till,
          firm_website,
          firm_zip_code,
          // role_id,
          attorney_contact_id,
          attorney_support,
          case_manager,
        } = resp;

        if (firm_logo) {
          setProfileImage((prev) => ({
            ...prev,
            imageURL: firm_logo
              ? `${fileApiUrl}${String(firm_logo)}`
              : profileimagelogo,
          }));
        }
        !_.isNull(firm_country)
          ? getCountriesDependent('countryId', firm_country)
          : getCountriesDependent('');
        !_.isNull(firm_state)
          ? getCountriesDependent('stateId', firm_state)
          : getCountriesDependent('');
        setLoader(false);
        setValueAdd('attorneyName', firm_name || '');
        setValueAdd('attorneyAddress', firm_address || '');
        setValueAdd('attorneyCity', firm_city || '');
        setValueAdd('attorneyState', firm_state || '');
        setValueAdd('attorneyCountry', firm_country || '');
        setValueAdd('attorneyPhone', firm_phone || '');
        setValueAdd('attorneyFax', firm_fax || '');
        setValueAdd('attorneyEmail', firm_email || '');
        setValueAdd('ccMailFirm', firm_cc_mail || '');
        setValueAdd('attorneyWebsite', firm_website || '');
        setValueAdd(
          'validTill',
          firm_valid_till ? moment(firm_valid_till).format('MM/DD/YYYY') : '',
        );
        setValueAdd('streetNumberAndName', firm_street_number_and_name || '');
        setValueAdd('unitType', firm_unit_type || '');
        setValueAdd('unitNumber', firm_unit_number || '');
        setValueAdd('attorneyFEIN', firm_fein || '');
        setValueAdd('attorneyZipCode', firm_zip_code || '');
        setValueAdd('attorneyStatus', firm_status || '');
        setValueAdd('attorneyStatebarNumber', firm_state_bar_number || '');
        setValueAdd(
          'attorneylicensingAuthority',
          firm_licensing_authority || '',
        );
        setValueAdd(
          'attorneyUSCISElisAccountNumber',
          firm_uscis_elis_account_number || '',
        );
        setValueAdd('attorneyCaseManager', case_manager || '');
        setValueAdd('attorneyContactPerson', attorney_contact_id || '');
        setValueAdd(
          'attorneyContactSupport',
          attorney_support ? attorney_support : [],
        );
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname.split('/').pop() === 'edit' && !useEditDetail) {
      cancelForm();
    }
    getFirmPaymentDetails();
    getInitialStatus();
    getAttorenyContactPersonList(userDetail.companyId);
    getCountriesDependent('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const profileSelected = (file) => {
    setProfileImage((prev) => ({
      ...prev,
      selectedImage: file,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfileImage((prev) => ({
        ...prev,
        imageURL: reader.result,
      }));
    };
    reader.readAsDataURL(file);
  };
  const closeUploadDialog = useCallback((action: any) => {
    if (action) {
      profileSelected(action);
    } else {
      setFileUploadOpen((pre) => ({
        ...pre,
        open: false,
      }));
    }
  }, []);
  return (
    <Grid container>
      <Grid item md={10} sm={12} sx={{ marginTop: '1rem !important' }}>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <fieldset
            style={{ border: 'none', padding: 'unset' }}
            disabled={useEditDetail}>
            <Typography component={'div'} className="addbox">
              {/* <img className="user-photo" src="" alt="user" /> */}
              <div className="user-poto-box">
                {!useEditDetail && (
                  <div
                    className="pencil-box"
                    onClick={() => setFileUploadOpen({ open: true })}>
                    <img className="d-block" src={pencil} alt="pencileditsvg" />
                    <img
                      className="d-none"
                      src={pencilImg}
                      alt="pencileditsvg"
                    />
                    {/* <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={uploadRef}
                      onChange={(e) => {
                        profileSelected(e.target.files);
                      }}
                    /> */}
                  </div>
                )}

                <img
                  src={
                    profileImage.imageURL
                      ? profileImage.imageURL
                      : profileimagelogo
                  }
                  alt=""
                  className={'user-poto'}
                />
              </div>
              {fileUploadOpen.open && (
                <FileUploadDialog
                  open={fileUploadOpen.open}
                  title={'Upload Profile '}
                  allowedFileType={profileFileType}
                  errorMessage={
                    'You can upload documents of types JPEG, PNG only'
                  }
                  info={'(You can upload documents of types JPEG, PNG only)'}
                  onConfirm={closeUploadDialog}
                  setFileUploadOpen={setFileUploadOpen}
                />
              )}
              <Typography
                component={'div'}
                className="uadd-title"
                sx={{ paddingTop: '3.5rem !important' }}>
                Firm Details
              </Typography>
              <Box
                component="form"
                className="u-add-in"
                noValidate
                autoComplete="off">
                <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                  <Grid item md={6}>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Firm Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        name="attorneyName"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Firm Name"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.attorneyName?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={1}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Unit Type
                      </Typography>

                      <Controller
                        control={control}
                        defaultValue="appartment"
                        name="unitType"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name={name}
                              value={value}
                              tabIndex={3}
                              onChange={(e) => {
                                onChange(e);
                              }}>
                              <FormControlLabel
                                value="apt"
                                control={<Radio />}
                                label="Apartment"
                              />
                              <FormControlLabel
                                value="ste"
                                control={<Radio />}
                                label="Suite"
                              />
                              <FormControlLabel
                                value="flr"
                                control={<Radio />}
                                label="Floor"
                              />
                              {!useEditDetail && getValues().unitType && (
                                <FormControlLabel
                                  value=""
                                  control={
                                    <span
                                      className="reset-button"
                                      onClick={() =>
                                        setValueAdd('unitType', '')
                                      }>
                                      Reset
                                    </span>
                                  }
                                  label=""
                                />
                              )}
                            </RadioGroup>
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Country
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="attorneyCountry"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              isClearable={false}
                              disabled={useEditDetail}
                              searchable={true}
                              labelKey={'name'}
                              valueKey={'id'}
                              options={
                                !_.isEmpty(countries?.countries)
                                  ? countries?.countries
                                  : []
                              }
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                getCountriesDependent(
                                  'countryId',
                                  e.target.value,
                                );
                              }}
                              placeHolder={'Select'}
                              className={'custom-input'}
                              error={errors.attorneyCountry?.message}
                              tabIndex={5}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        City
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="attorneyCity"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              isClearable={false}
                              disabled={useEditDetail}
                              searchable={true}
                              labelKey={'name'}
                              valueKey={'id'}
                              options={
                                !_.isEmpty(countries?.cities)
                                  ? countries?.cities
                                  : []
                              }
                              name={name}
                              value={value}
                              onChange={(val) => onChange(val)}
                              placeHolder={'Select'}
                              className={'custom-input'}
                              error={errors.attorneyCity?.message}
                              tabIndex={7}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Phone
                      </Typography>
                      <Controller
                        control={control}
                        name="attorneyPhone"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Phone Number"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.attorneyPhone?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={9}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Email <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        name="attorneyEmail"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Email"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.attorneyEmail?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={11}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        CC Email
                      </Typography>
                      <Controller
                        control={control}
                        name="ccMailFirm"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Email"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.ccMailFirm?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={13}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Contact Person
                      </Typography>
                      <Controller
                        control={control}
                        name="attorneyContactPerson"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              disabled={useEditDetail}
                              searchable={true}
                              labelKey={'label'}
                              valueKey={'value'}
                              options={
                                !_.isEmpty(contactPersonList)
                                  ? contactPersonList
                                  : []
                              }
                              name={name}
                              value={value}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              placeHolder={'Select'}
                              className={'custom-input'}
                              tabIndex={15}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Support Officer
                      </Typography>

                      <Controller
                        control={control}
                        name="attorneyContactSupport"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              closeMenuOnSelect={false}
                              labelKey={'label'}
                              valueKey={'value'}
                              options={contactPersonList}
                              isMulti={true}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                setAttorneySupportIds(value);
                              }}
                              placeHolder={'Select'}
                              className={'basic-multi-select'}
                              // error={errors.visaSelected?.message}
                              disabled={!useEditDetail ? false : true}
                              // tabIndex={1}
                            />

                            // <Multiselect
                            //   {...field}
                            //   disable={useEditDetail}
                            //   placeholder={
                            //     watch('attorneyContactSupport').length === 0
                            //       ? 'Support Officer'
                            //       : ''
                            //   }
                            //   displayValue="full_name"
                            //   onSelect={(selected, item) => {
                            //     setValueAdd(
                            //       'attorneyContactSupport',
                            //       selected.map((support) => support.id),
                            //     );
                            //   }}
                            //   onRemove={(selected, item) => {
                            //     setValueAdd(
                            //       'attorneyContactSupport',
                            //       selected.map((support) => support.id),
                            //     );
                            //   }}
                            //   options={
                            //     !_.isEmpty(contactPersonList)
                            //       ? contactPersonList
                            //       : []
                            //   }
                            //   ref={multiSelectRef}
                            //   selectedValues={attorneySupportIds}
                            // />
                          );
                        }}
                      />
                    </Typography>

                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        State Bar Number
                      </Typography>
                      <Controller
                        control={control}
                        name="attorneyStatebarNumber"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="State Bar Number"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.attorneyStatebarNumber?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={19}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        USCIS ELIS Account Number
                      </Typography>
                      <Controller
                        control={control}
                        name="attorneyUSCISElisAccountNumber"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="USCIS ELIS Account Number"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={
                                errors.attorneyUSCISElisAccountNumber?.message
                              }
                              onChange={(val) => onChange(val)}
                              tabIndex={22}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Street Number And Name
                      </Typography>
                      <Controller
                        control={control}
                        name="streetNumberAndName"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Street Number And Name"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.streetNumberAndName?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={2}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Unit Number
                      </Typography>
                      <Controller
                        control={control}
                        name="unitNumber"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Unit Number"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.unitNumber?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={4}
                            />
                          );
                        }}
                      />{' '}
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        State
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="attorneyState"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              isClearable={false}
                              disabled={useEditDetail}
                              searchable={true}
                              labelKey={'name'}
                              valueKey={'id'}
                              options={
                                !_.isEmpty(countries?.states)
                                  ? countries?.states
                                  : []
                              }
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                getCountriesDependent(
                                  'stateId',
                                  e.target.value,
                                );
                              }}
                              placeHolder={'Select'}
                              className={'custom-input'}
                              error={errors.attorneyState?.message}
                              tabIndex={6}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Zip / Postal Code
                      </Typography>
                      <Controller
                        control={control}
                        name="attorneyZipCode"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Zip / Postal Code"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.attorneyZipCode?.message}
                              onChange={(val) =>
                                onChange(val.target.value.replace(/[^\d]/g, ''))
                              }
                              tabIndex={8}
                              type={'text'}
                            />
                          );
                        }}
                      />
                    </Typography>

                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Fax Number
                      </Typography>
                      <Controller
                        control={control}
                        name="attorneyFax"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder=" Fax Number"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.attorneyFax?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={10}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Website
                      </Typography>
                      <Controller
                        control={control}
                        name="attorneyWebsite"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Website"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.attorneyWebsite?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={12}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Valid Until
                      </Typography>

                      <Controller
                        control={control}
                        defaultValue=""
                        name="validTill"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomDatePicker
                              {...ref}
                              isReadOnlyInput={true}
                              isEditable={true}
                              // isEditable={useEditDetail ? true : false}
                              className="custom-input"
                              errors={errors.validTill?.message}
                              name={name}
                              value={value}
                              onChangeDate={(val) => onChange(val)}
                              tabIndex={14}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Case Manager
                      </Typography>
                      <Controller
                        control={control}
                        name="attorneyCaseManager"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              disabled={useEditDetail}
                              searchable={true}
                              labelKey={'full_name'}
                              valueKey={'id'}
                              options={
                                !_.isEmpty(caseManagerList)
                                  ? caseManagerList
                                  : []
                              }
                              name={name}
                              value={value}
                              onChange={(val) => onChange(val)}
                              placeHolder={'Select'}
                              className={'custom-input'}
                              tabIndex={16}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Law Firm / Business FEIN
                      </Typography>
                      <Controller
                        control={control}
                        name="attorneyFEIN"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder=" Law Firm / Business FEIN "
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.attorneyFEIN?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={18}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Licensing Authority
                      </Typography>
                      <Controller
                        control={control}
                        name="attorneylicensingAuthority"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              maxLength={50}
                              placeHolder="Licensing Authority"
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.attorneylicensingAuthority?.message}
                              onChange={(val) => onChange(val)}
                              tabIndex={20}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Typography>

            {!useEditDetail && (
              <Grid container className="bottom-fixed ">
                <Grid item md={12}>
                  <Typography component={'div'} className="d-flex-jae ">
                    <Button
                      className="cancel-btn mr-2"
                      onClick={() => cancelForm()}>
                      <Typography className="o-box" />
                      Cancel
                    </Button>
                    <Button className="add-btn" type="submit" disabled={loader}>
                      Save
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </fieldset>
        </form>
      </Grid>
      <Grid item md={10} sm={12}>
        <fieldset style={{ border: 'none', padding: 'unset' }}>
          <form id="payment-gateway" onSubmit={handlePGSubmit(onSubmitPGForm)}>
            <Typography component={'div'} className="addbox">
              <Typography component={'div'} className="d-flex-a">
                <Typography
                  component={'div'}
                  className="uadd-title"
                  sx={{ paddingTop: '0.5rem !important' }}>
                  Payment Gateway Integration
                </Typography>
                {!editDetailPG ? (
                  <Typography
                    component={'div'}
                    className="d-flex-jae"
                    sx={{ cursor: 'pointer', paddingLeft: '5px' }}
                    onClick={() => setEditDetailPG(true)}>
                    <Tooltip title={'Edit'} placement={'top-end'}>
                      <EditIcon sx={{ fill: '#275fce' }} />
                    </Tooltip>
                  </Typography>
                ) : null}
              </Typography>
              <Box
                component="form"
                className="u-add-in"
                noValidate
                autoComplete="off">
                <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                  <Grid item md={12}>
                    <Typography
                      component={'div'}
                      className="custom-field d-flex-a">
                      <Typography
                        component={'div'}
                        className="custom-label"
                        sx={{
                          marginRight: '16px !important',
                          marginTop: '10px !important',
                        }}>
                        Do you wish to collect payment through Visas.AI
                      </Typography>

                      <Controller
                        control={controlPG}
                        defaultValue={false}
                        name="contract_payment_enable"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <FormGroup sx={{ marginLeft: '30px' }}>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center">
                                <Typography
                                  className="custom-label"
                                  sx={{
                                    marginTop: '10px !important',
                                  }}>
                                  No
                                </Typography>
                                <FormControlLabel
                                  disabled={!editDetailPG}
                                  control={
                                    <IOSSwitch
                                      sx={{ marginLeft: '2px' }}
                                      checked={value}
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        onChange(e);
                                        if (!checked) {
                                          setModalOpen(true);
                                        }
                                      }}
                                      disabled={!editDetailPG}
                                    />
                                  }
                                  label={''}
                                />
                                <Typography
                                  className="custom-label"
                                  sx={{
                                    marginTop: '10px !important',
                                  }}>
                                  Yes
                                </Typography>
                              </Stack>
                            </FormGroup>
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  {editDetailPG && (
                    <>
                      <Grid item md={6} sm={12}>
                        {watchPG('contract_payment_enable') && (
                          <Typography
                            component={'div'}
                            className="custom-field">
                            <Typography
                              component={'div'}
                              className="custom-label">
                              Choose Your Payment Type{' '}
                              <span style={{ color: 'red' }}>
                                *{' '}
                                {watchPG('contract_payment_enable') &&
                                  watchPG('paymentType') === 'stripe' &&
                                  editDetailPG && (
                                    <Typography
                                      component={'span'}
                                      sx={{
                                        cursor: 'pointer',
                                        paddingLeft: '5px',
                                      }}
                                      onClick={() => setFollowSteps(true)}>
                                      <Tooltip
                                        title="Steps to follow"
                                        placement="top-start">
                                        <InfoRoundedIcon
                                          style={{
                                            cursor: 'pointer',
                                            fill: 'rgb(14 106 234)',
                                            marginBottom: '-7px',
                                            marginLeft: '-4px ',
                                          }}
                                        />
                                      </Tooltip>
                                    </Typography>
                                  )}
                              </span>
                            </Typography>
                            <Typography>
                              <Controller
                                control={controlPG}
                                name="paymentType"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomSelect
                                      disabled={true}
                                      searchable={true}
                                      labelKey={'label'}
                                      valueKey={'value'}
                                      options={ChooseThePaymentTypes}
                                      placeHolder={'Select'}
                                      name={name}
                                      value={value}
                                      error={pgErrors.paymentType?.message}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Typography>
                        )}
                        {watchPG('contract_payment_enable') &&
                          watchPG('paymentType') !== '' && (
                            <Typography
                              component={'div'}
                              className="custom-field"
                              sx={{
                                marginTop:
                                  pgErrors.paymentSecretId?.message && '35px',
                              }}>
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Card Service Charge (%)
                              </Typography>
                              <Controller
                                control={controlPG}
                                name="cardServiceCharge"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      disabled={!editDetailPG}
                                      type={'number'}
                                      placeHolder="Enter card service charges"
                                      value={value}
                                      name={name}
                                      className={'custom-input'}
                                      error={
                                        pgErrors.cardServiceCharge?.message
                                      }
                                      onChange={(val) => {
                                        const vals = val.target.value.replace(
                                          /^0/,
                                          '',
                                        );
                                        return onChange(vals);
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          )}
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sm={12}
                        sx={{ paddingTop: '5px !important' }}>
                        {/* <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label" />
                    </Typography> */}
                        {watchPG('contract_payment_enable') &&
                          watchPG('paymentType') === 'stripe' && (
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Stripe Secret Key{' '}
                                <span style={{ color: 'red' }}>*</span>
                              </Typography>
                              <Controller
                                control={controlPG}
                                name="paymentSecretId"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      disabled={!editDetailPG}
                                      placeHolder="Enter secret key"
                                      value={value}
                                      name={name}
                                      className={'custom-input'}
                                      error={pgErrors.paymentSecretId?.message}
                                      onChange={(val) => {
                                        const vals = val.target.value.replace(
                                          /\s/,
                                          '',
                                        );
                                        onChange(vals);
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          )}

                        {watchPG('contract_payment_enable') &&
                          watchPG('paymentType') === 'heartland' && (
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                Heartland Secret Key{' '}
                                <span style={{ color: 'red' }}>*</span>
                              </Typography>
                              <Controller
                                control={controlPG}
                                name="heartlandSecretId"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      disabled={!editDetailPG}
                                      placeHolder="Enter secret key"
                                      value={value}
                                      name={name}
                                      className={'custom-input'}
                                      error={
                                        pgErrors.heartlandSecretId?.message
                                      }
                                      onChange={(val) => {
                                        const vals = val.target.value.replace(
                                          /\s/,
                                          '',
                                        );
                                        onChange(vals);
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          )}

                        {watchPG('contract_payment_enable') &&
                          watchPG('paymentType') !== '' && (
                            <Typography
                              component={'div'}
                              className="custom-field">
                              <Typography
                                component={'div'}
                                className="custom-label">
                                ACH Service Charge ($)
                              </Typography>
                              <Controller
                                control={controlPG}
                                name="achServiceCharge"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <CustomInput
                                      disabled={!editDetailPG}
                                      type={'number'}
                                      placeHolder="Enter ACH service charges"
                                      value={value}
                                      name={name}
                                      className={'custom-input'}
                                      error={pgErrors.achServiceCharge?.message}
                                      onChange={(val) => {
                                        const vals = val.target.value.replace(
                                          /^0/,
                                          '',
                                        );
                                        return onChange(vals);
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          )}
                        {watchPG('contract_payment_enable') && editDetailPG && (
                          <Typography component={'div'} className="d-flex-jae ">
                            <Button
                              className="cancel-btn mr-2"
                              onClick={() => {
                                cancelFormPg();
                              }}>
                              <Typography className="o-box" />
                              Cancel
                            </Button>
                            <Button
                              className="add-btn"
                              type="submit"
                              form={'payment-gateway'}
                              disabled={loaderPG}>
                              Submit
                            </Button>
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Typography>
            {/* <Grid container className="bottom-fixed ">
              <Grid item md={12}></Grid>
            </Grid> */}
          </form>
        </fieldset>
      </Grid>

      <ConfirmDialog
        open={modalOpen}
        floatingBtnShow={true}
        onConfirm={(e) => {
          if (e === 'yes') {
            modelClose();
            paymentdisable();
          } else {
            modalClose2();
          }
        }}
        autoCloseDisable={true}>
        Clients can no longer make payments to you via Visas.AI including any
        link that you have already shared and pending for payment. Are you sure
        you want to disable payments?
      </ConfirmDialog>
      <Modal
        open={followSteps}
        onClose={() => setFollowSteps(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="custom-modal-note">
          <Typography className="d-flex d-flex-sb f-20 font-bold modal-title">
            <Typography component={'div'} className="f-20 d-flex-a font-bold">
              Stripe API Configuration Guide
            </Typography>
            <Typography onClick={() => setFollowSteps(false)}>
              <CancelRoundedIcon
                fontSize="large"
                sx={{ cursor: 'pointer', fill: 'grey' }}
              />
            </Typography>
          </Typography>
          <Grid container md={12} sx={{ padding: '16px' }}>
            <Grid md={2}>
              <Typography component={'div'} className="f-16  font-bold">
                Stripe API URL:
              </Typography>
            </Grid>
            <Grid md={10}>
              <Typography component={'div'} className="f-16 font-bold">
                <a
                  style={{
                    color: 'blue',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() =>
                    window.open(
                      'https://dashboard.stripe.com/test/apikeys',
                      '_blank',
                    )
                  }>
                  {' '}
                  https://dashboard.stripe.com/test/apikeys{' '}
                </a>
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12} sx={{ padding: '16px' }}>
            <Grid md={2}>
              <Typography component={'div'} className="f-16 font-bold">
                API Screenshot:
              </Typography>
            </Grid>
            <Grid md={10}>
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  style={{
                    border: '1px solid #edd5d5',
                    borderRadius: '8px',
                    width: '100%',
                  }}
                  src={Payment}
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {useEditDetail && (
        <Grid item md={2} sm={12}>
          {userDetail.role_id === 3 && (
            <Typography component={'div'} className="add-box">
              <Typography component={'div'} className="add-inner-box" />
              <Typography
                className=" built-option d-flex-a mt-1"
                onClick={() => setUseEditDetail(false)}>
                <img src={Bedit} alt="edit" className="mr-1" />
                Edit Firm Details
              </Typography>
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default MyFirm;
