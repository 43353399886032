import UploadIcon from '@mui/icons-material/Upload';
import { Button, DialogContent, Grid, Typography } from '@mui/material';
import Delete from 'assets/images/deleteicon.png';
import Upload from 'assets/images/uploadnow.svg';
import View from 'assets/images/viewicon.png';
import Parsed from 'assets/images/viewparsed.png';
import { DocumentViewer } from 'components/CustomDocumentViewer/CustomeDocumentViewer';
import CustomModalView from 'components/CustomModalView/customModalView';
import saveAs from 'file-saver';
import { EditFileNameUploadDocuments } from 'pages/private/Common/EditFileNameUploadDocuments';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { getS3file } from 'services/CommonServices';
import { UserRoleType } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions, parsingTypeArr } from 'utils/helper';

type UploadDocumentListInterFace = {
  document;
  leadInfo;
  index;
  userDetail;
  setConfirmOpen;
  setFileUploadOpen;
  setLoader;
  setIsUploadDocs;
  isUploadDocs;
  parsedValuesView;
  petitions: any;
};

export const UploadDocumentList: React.FC<UploadDocumentListInterFace> = ({
  document,
  leadInfo,
  index,
  userDetail,
  setConfirmOpen,
  setFileUploadOpen,
  setLoader,
  setIsUploadDocs,
  isUploadDocs,
  parsedValuesView,
  petitions,
}) => {
  const { isAffilateCase } = petitions?.petitionData || {
    isAffilateCase: false,
  };
  const [openReview, setOpenReview] = useState({
    fileType: '',
    fileUrl: '',
    key: '',
    open: false,
  });

  const viewFileDoc = (file: string, mimeType?: string) => {
    const filePathUrl = `${String(
      process.env.REACT_APP_FILE_URL,
    )}0?key=${file}&contentType=${mimeType}`;

    setOpenReview({
      fileType: mimeType,
      fileUrl: filePathUrl,
      key: file,
      open: true,
    });
  };

  const downloadFileDoc = (file: string) => {
    const obj = {
      key: file,
    };
    const fileName = file.split('/').pop();
    setLoader(true);
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(`${fileName} Downloaded`, alertOptions);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };
  return (
    <>
      <li
        key={index}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          setIsUploadDocs({
            index: index,
            show: true,
          })
        }>
        <Typography
          component={'div'}
          className={
            isUploadDocs?.index === index && isUploadDocs?.show
              ? document?.multipleDocumentsList?.length > 0
                ? 'blue-ul'
                : 'white-ul'
              : ''
          }>
          <div
            style={{
              display: 'flex',
              padding: '15px 0',
              position: 'relative',
            }}>
            {document?.multipleDocumentsList?.length > 0 && (
              <span className="g-badge d-flex-ja">
                {Number(document.multipleDocumentsList.length)}
              </span>
            )}
            <div className="u-no" style={{ textAlign: 'right' }}>
              {Number(index) + 1}
            </div>
            <span>{document?.document_type}</span>
          </div>
          {isUploadDocs?.index === index && isUploadDocs?.show && (
            <Button
              className="u-btn-outer"
              onClick={() => {
                // if (
                //   document.parsing_type === 'i-129' &&
                //   leadInfo &&
                //   (!leadInfo.visa_type || leadInfo.visa_type.length === 0)
                // ) {
                //   toast.error('Please select the visa type', alertOptions);
                //   return;
                // }
                // if (
                //   document.parsing_type === 'i-129' &&
                //   leadInfo &&
                //   leadInfo?.hasOwnProperty.call('attorney_assigned') &&
                //   !leadInfo?.attorney_assigned
                // ) {
                //   toast.error(
                //     'Please select the attorney firm in the lead to review and accept the parsed data.',
                //     alertOptions,
                //   );
                //   return;
                // }
                setFileUploadOpen((pre) => ({
                  ...pre,
                  documentInfo: document,
                  editDocId: null,
                  open: true,
                  roleId: 4,
                }));
              }}>
              <img src={Upload} alt="upload" />
            </Button>
          )}
          {isUploadDocs?.index === index &&
          isUploadDocs?.show &&
          document?.multipleDocumentsList?.length > 0 ? (
            <ul>
              {document?.multipleDocumentsList?.map((subDoc, sIndex) => (
                <li key={sIndex}>
                  <Grid
                    container
                    sx={{
                      paddingLeft: '2.5rem !important',
                      paddingRight: '2rem !important',
                    }}>
                    <Grid
                      item
                      md={5}
                      sm={12}
                      className="f-15"
                      sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        wordBreak: 'break-all',
                      }}>
                      {' '}
                      <Typography component={'div'} className="udot" />
                      <EditFileNameUploadDocuments
                        document={subDoc}
                        sIndex={sIndex}
                        setLoader={setLoader}
                        multipleDocuemnt={document?.multipleDocumentsList}
                      />
                    </Grid>
                    <Grid md={2} sm={3} className="text-center f-15 d-flex-ja">
                      <Typography
                        className="white-text"
                        onClick={() =>
                          viewFileDoc(subDoc.document_path, subDoc.mime_type)
                        }>
                        <img src={View} alt="View" className="pr-1" />
                        View
                      </Typography>
                    </Grid>
                    {!parsingTypeArr.includes(document?.parsing_type) &&
                      !isAffilateCase && (
                        <Grid
                          md={2}
                          sm={3}
                          className="text-center f-15 d-flex-ja">
                          {' '}
                          <Typography
                            className="white-text"
                            onClick={() => {
                              setFileUploadOpen((pre) => ({
                                ...pre,
                                documentInfo: document,
                                editDocId: subDoc.documentId,
                                open: true,
                                roleId: 4,
                              }));
                            }}>
                            <span className="d-flex-jae f-16">
                              <UploadIcon
                                style={{
                                  fill: '#FFFFFF',
                                }}
                              />
                              Re Upload
                            </span>
                          </Typography>
                        </Grid>
                      )}
                    {userDetail.userRoleName === UserRoleType.Attorney &&
                      !isAffilateCase && (
                        <Grid
                          md={2}
                          sm={3}
                          className="text-center f-15 d-flex-ja">
                          <Typography
                            className="white-text d-flex align-center"
                            onClick={() => {
                              setConfirmOpen({
                                doc: subDoc,
                                index: sIndex,
                                show: true,
                              });
                            }}>
                            <img
                              src={Delete}
                              alt="view"
                              className="pr-1 pb-0"
                            />
                            Delete
                          </Typography>
                        </Grid>
                      )}
                    {userDetail.userRoleName === UserRoleType.Attorney &&
                      parsingTypeArr.includes(document.parsing_type) &&
                      !isAffilateCase && (
                        <Grid
                          md={3}
                          sm={3}
                          className="text-center f-15 d-flex-ja">
                          <Typography
                            className="white-text d-flex align-center"
                            onClick={() => {
                              parsedValuesView(
                                document.parsing_type,
                                subDoc,
                                document.currentDocumentId,
                                document.role_id,
                              );
                            }}>
                            <img src={Parsed} alt="view" className="pr-1" />
                            Parsed Values{' '}
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                </li>
              ))}
            </ul>
          ) : null}
        </Typography>
      </li>
      {openReview?.open && (
        <CustomModalView
          open={openReview?.open}
          fullWidth={true}
          maxWidth={'lg'}
          scroll={'paper'}
          close={() =>
            setOpenReview({
              fileType: '',
              fileUrl: '',
              key: '',
              open: false,
            })
          }
          onFileDownloadIcon={() => downloadFileDoc(openReview.key)}
          title="View Document">
          <DialogContent>
            <Typography
              className="assem-box"
              id="parentdocId"
              sx={{
                minHeight: '30vh',
              }}>
              <DocumentViewer
                fileBlob={openReview.fileUrl}
                fileType={openReview.fileType}
                // width={
                //   document.getElementById('parentdocId') &&
                //   document.getElementById('parentdocId')?.offsetWidth
                //     ? `${
                //         document.getElementById('parentdocId')?.offsetWidth -
                //         100
                //       }px`
                //     : 'auto'
                // }
                applyBorder={true}
              />
            </Typography>
          </DialogContent>
        </CustomModalView>
      )}
    </>
  );
};

export default UploadDocumentList;
