import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button, Grid, Typography } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { instantState } from 'hooks';
import _ from 'lodash';
// import Multiselect from 'multiselect-react-dropdown';
// import { PDFReaderLocal } from 'pages/private/Common/pdfViwers';
import React, { FC, useCallback, useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import { toast } from 'react-toastify';
import { addNewData, deleteData, updateData } from 'services/CommonServices';
import { SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

type DocumentFieldsProps = {
  editId: string;
  fileProps: any;
  documentFields: any;
  setDocumentFields: any;
  mappedQuestion: any;
  mappedQuestionWithLabelValues: any;
  pdfAlreadyMappedValues;
};

const DocumentFieldsViewer: FC<DocumentFieldsProps> = ({
  editId,
  fileProps,
  documentFields,
  // setDocumentFields,
  mappedQuestion,
  mappedQuestionWithLabelValues,
  pdfAlreadyMappedValues,
}) => {
  // const [questionIds, setQuestionIds] = useState<any>([]);
  const [inputFieds, setInputFieds] = useState([]);
  const [docState, setDocState] = useState<any>({
    index: null,
    responseList: [],
    responseSelectCheck: null,
    selectCheckAndRadio: null,
    selectMappingQus: [],
    selectedDocField: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    show: false,
  });
  const [mappingQusSubmitted, setMappingQusSubmitted] = useState(false);
  const [filedLoader, setFieldLoader] = useState(false);
  const pdfRef = React.useRef(null);

  const addInput = useCallback(
    (annotation: any, rect: number[] = null) => {
      // add input to page
      const copyval: any = annotation;
      let alreadyMappedQus;
      if (pdfAlreadyMappedValues && pdfAlreadyMappedValues.length) {
        alreadyMappedQus = pdfAlreadyMappedValues.find((doc) => {
          try {
            const parseValue = JSON.parse(doc.field_name);
            if (parseValue && parseValue.length) {
              const checkArrMappedvalue = parseValue.find((val) => {
                if (copyval['radioButton'] && copyval.radioButton) {
                  return (
                    val.pdf_field_name === copyval.fieldName &&
                    val.pdf_field_id === copyval.id
                  );
                }
                return val.pdf_field_name === copyval.fieldName;
              });
              return checkArrMappedvalue;
            } else {
              return null;
            }
          } catch (error) {
            return doc.field_name === copyval.fieldName;
          }
        });
      }
      const pdfObj = {
        doc_field: copyval.fieldName,
        doc_id: copyval.id,
        exportValue: copyval['exportValue']
          ? copyval.exportValue
          : copyval['radioButton'] && copyval.radioButton
          ? copyval['buttonValue'] && copyval.buttonValue
            ? copyval.buttonValue
            : Date.now()
          : '',
        id: alreadyMappedQus && alreadyMappedQus.id ? alreadyMappedQus.id : '',
        pdfActualValue: copyval.fieldValue,
        pdf_doc_field: `${String(copyval.id)} ${String(copyval.fieldName)
          .split('.')
          .pop()
          .replace(/ *\[[^\]]*]/, '')}`,
        previousRes:
          alreadyMappedQus && alreadyMappedQus.field_name
            ? getArrvaluse(alreadyMappedQus.field_name)
            : [],
        question_id:
          alreadyMappedQus && alreadyMappedQus.question_id
            ? alreadyMappedQus.question_id
            : '',
        radioGroup:
          copyval['radioButton'] && copyval.radioButton
            ? copyval.radioButton
            : false,
        tooltip: copyval.alternativeText,
      };
      return pdfObj;
    },
    [pdfAlreadyMappedValues],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadComplete = async (pdf: any) => {
    const finalInuts = [];
    const pagesArr = [];
    for (let i = 1; i <= pdf.numPages; i++) {
      pagesArr.push(i);
    }
    const PromiseArr = await pagesArr.map(async (e) => {
      let currentPage = null;
      await pdf
        .getPage(e)
        .then((p) => {
          currentPage = p;
          // get the annotations of the current page
          return p.getAnnotations();
        })
        .then(async (ann) => {
          // ugly cast due to missing typescript definitions
          // please contribute to complete @types/pdfjs-dist
          const annotations = ann;

          return await annotations
            .filter((a) => a.subtype === 'Widget') // get the form field annotation only
            .map(async (a) => {
              // get the rectangle that represent the single field
              // and resize it according to the current DPI
              const dpiRatio = 96 / 72;
              const fieldRect = currentPage
                .getViewport(dpiRatio)
                .convertToViewportRectangle(a.rect);

              // add the corresponding input
              return await finalInuts.push(addInput(a, fieldRect));
            });
        });
    });
    await Promise.all(PromiseArr);
    return finalInuts;
  };

  const getArrvaluse = (check) => {
    try {
      const arr = JSON.parse(check);
      return arr && arr.length ? arr : [];
    } catch (error) {
      if (check && Array.isArray(check) && check.length) {
        return check;
      }
      return [];
    }
  };

  const selectDocFields = (doc, fieldIndex) => {
    setDocState((pre) => ({
      ...pre,
      index: fieldIndex,
      responseList: [],
      responseSelectCheck: null,
      selectCheckAndRadio: null,
      selectedDocField: doc,
    }));

    if (doc.exportValue) {
      if (doc && doc.previousRes && doc.previousRes && doc.previousRes.length) {
        const indx = doc.previousRes.findIndex((val) => {
          if (doc.radioGroup) {
            return (
              val.pdf_field_name === doc.doc_field &&
              val.pdf_field_id === doc.doc_id
            );
          }
          return val.pdf_field_name === doc.doc_field;
        });
        if (indx !== -1) {
          setDocState((pre) => ({
            ...pre,
            responseSelectCheck: doc.previousRes[indx].response,
          }));
        }
      }
      if (doc && doc.question_id.length) {
        const question = mappedQuestion.find(
          (val) => val.id === doc.question_id[0],
        );
        if (question) {
          responseSelect(question.response);
        }
        setDocState((pre) => ({
          ...pre,
          selectCheckAndRadio:
            doc && doc.question_id && doc.question_id.length
              ? doc.question_id[0]
              : null,
        }));
      }
      setDocState((pre) => ({
        ...pre,
        selectMappingQus: [],
      }));
    } else {
      const selectMappings = doc && doc.question_id ? doc.question_id : [];
      setDocState((pre) => ({
        ...pre,
        selectMappingQus: selectMappings,
      }));
      // setQuestionIds(
      //   mappedQuestionWithLabelValues.filter(
      //     (obj) => selectMappings.indexOf(obj.value) !== -1,
      //   ),
      // );
    }
  };

  const responseSelect = async (selctValues) => {
    if (selctValues === null) {
      setDocState((pre) => ({
        ...pre,
        responseList: [],
        responseSelectCheck: null,
      }));
      return;
    }
    const docState = await instantState(setDocState);
    if (docState.selectedDocField && docState.selectedDocField.exportValue) {
      if (selctValues && selctValues && selctValues.length) {
        let arr = [];
        arr = [...selctValues];
        setDocState((pre) => ({
          ...pre,
          responseList: arr.map((val) => {
            return {
              name: val,
              value: val,
            };
          }),
        }));
      } else {
        //  setDocState((pre) => ({
        //    ...pre,
        //    responseSelectCheck: null,
        //    responseList: [],
        //  }));
      }
    } else {
      //  setDocState((pre) => ({
      //    ...pre,
      //    responseSelectCheck: null,
      //    responseList: [],
      //  }));
    }
  };

  const deleteConfirmation = async (action: string) => {
    if (action !== 'yes') {
      setDeleteDialog((pre) => ({
        ...pre,
        show: false,
      }));
      return;
    }
    const docStates = await instantState(setDocState);
    if (docStates.selectedDocField && docStates.selectedDocField.exportValue) {
      const resArr: Array<any> = docStates.selectedDocField.previousRes
        ? docStates.selectedDocField.previousRes
        : [];
      const indx = resArr.findIndex(
        (val) => val.pdf_field_name === docStates.selectedDocField.doc_field,
      );
      if (indx !== -1) {
        resArr.splice(indx, 1);
      }
      return;
    }
    deleteData(
      docStates.selectedDocField.id,
      SETUP_ENDPOINT.DocumentTemplateLinkCode,
    )
      .then((resp: any) => {
        setMappingQusSubmitted(false);
        if (docStates.index !== null) {
          const bodyFields = getMappingFieldsValues();
          const fields = inputFieds;
          fields[docStates.index].question_id = null;
          fields[docStates.index].previousRes =
            fileProps.documentType === 'pdf' &&
            docStates.selectedDocField.exportValue
              ? getArrvaluse(bodyFields.fieldName)
              : [];

          setInputFieds([...fields]);
        }

        setDocState((pre) => ({
          ...pre,
          index: null,
          responseSelectCheck: null,
          selectCheckAndRadio: null,
          selectMappingQus: [],
          selectedDocField: null,
        }));
      })
      .catch((e) => {
        toast.error(
          'Unable to remove the information. Please try again.Failed to remove',
          alertOptions,
        );
        setMappingQusSubmitted(false);
        // this.submitButtonDisabled = false;
      });

    setDeleteDialog((pre) => ({
      ...pre,
      show: false,
    }));
  };

  const getMappingFieldsValues = () => {
    if (docState.selectedDocField.exportValue) {
      const existArr: Array<any> = [];
      existArr.push({
        doc_field: docState.selectedDocField.pdf_doc_field,
        exportValue: docState.selectedDocField.exportValue,
        pdf_field_id: docState.selectedDocField.doc_id,
        pdf_field_name: docState.selectedDocField.doc_field,
        response: docState.responseSelectCheck,
        status: false,
      });
      const question = mappedQuestion.find(
        (val) => val.id === docState.selectCheckAndRadio,
      );
      return {
        fieldName: JSON.stringify(existArr),
        questionId: docState.selectedDocField.exportValue
          ? [docState.selectCheckAndRadio]
          : docState.selectMappingQus,
        radioGroup: docState.selectedDocField.radioGroup,
        responseId: question.response_type,
      };
    }
    return {
      fieldName: docState.selectedDocField.doc_field,
      questionId: docState.selectedDocField.exportValue
        ? [docState.selectCheckAndRadio]
        : docState.selectMappingQus,
      radioGroup: docState.selectedDocField.radioGroup,
      responseId: null,
    };
  };

  const saveQus = async () => {
    const docState = await instantState(setDocState);
    setMappingQusSubmitted(true);
    if (
      docState.selectedDocField &&
      docState.selectedDocField.doc_field &&
      (docState.selectMappingQus.length || docState.selectCheckAndRadio)
    ) {
      if (
        docState.selectedDocField.exportValue &&
        !docState.responseSelectCheck
      ) {
        return;
      }
      const bodyFields = getMappingFieldsValues();
      // this.submitButtonDisabled = true;
      if (docState.selectedDocField.id) {
        updateData(
          docState.selectedDocField.id,
          bodyFields,
          SETUP_ENDPOINT.DocumentTemplateLinkCode,
        )
          .then((resp: any) => {
            setMappingQusSubmitted(false);
            if (docState.index !== null) {
              const fields = inputFieds;
              fields[docState.index].question_id = bodyFields.questionId;
              fields[docState.index].previousRes =
                fileProps.documentType === 'pdf' &&
                docState.selectedDocField.exportValue
                  ? getArrvaluse(bodyFields.fieldName)
                  : [];

              setInputFieds([...fields]);
            }

            setDocState((pre) => ({
              ...pre,
              index: null,
              responseSelectCheck: null,
              selectCheckAndRadio: null,
              selectMappingQus: [],
              selectedDocField: null,
            }));

            // this.submitButtonDisabled = false;
          })
          .catch((e) => {
            toast.error(
              'Unable to save the information. Please try again.',
              alertOptions,
            );
            setMappingQusSubmitted(false);
            // this.submitButtonDisabled = false;
          });

        return;
      } else {
        addNewData(
          bodyFields,
          `${SETUP_ENDPOINT.DocumentTemplateLinkCode}/${editId}`,
        )
          .then((res: any) => {
            setMappingQusSubmitted(false);
            if (docState.index !== null) {
              const fields = inputFieds;
              fields[docState.index].id =
                res && res.dataResult && res.dataResult.id
                  ? res.dataResult.id
                  : null;
              fields[docState.index].question_id = bodyFields.questionId;
              fields[docState.index].previousRes =
                fileProps.documentType === 'pdf' &&
                docState.selectedDocField.exportValue
                  ? getArrvaluse(bodyFields.fieldName)
                  : [];
              setInputFieds([...fields]);
            }
            setDocState((pre) => ({
              ...pre,
              index: null,
              responseSelectCheck: null,
              selectCheckAndRadio: null,
              selectMappingQus: [],
              selectedDocField: null,
            }));
            // this.submitButtonDisabled = false;
          })
          .catch((e) => {
            toast.error(
              'Unable to save the information. Please try again.',
              alertOptions,
            );
            setMappingQusSubmitted(false);
            // this.submitButtonDisabled = false;
          });
      }
    }
  };
  const complete = async (pdfObj) => {
    setFieldLoader(true);
    const finalVal = await loadComplete(pdfObj?.detail);
    setFieldLoader(false);
    setInputFieds(finalVal);
  };
  const onError = (error: any) => {
    setFieldLoader(false);
  };
  useEffect(() => {
    if (fileProps && fileProps.documentType === 'pdf' && !inputFieds.length) {
      setFieldLoader(true);
    } else if (documentFields && documentFields.length) {
      setInputFieds(documentFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    pdfRef &&
      pdfRef.current &&
      pdfRef.current.addEventListener('pdfdocumentproxy', complete);

    pdfRef &&
      pdfRef.current &&
      pdfRef.current.addEventListener('pdfdocumenterror', onError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const copyRef = pdfRef && pdfRef.current ? pdfRef.current : null;
    return () => {
      copyRef && copyRef.removeEventListener('pdfdocumenterror', onError);

      copyRef && copyRef.removeEventListener('pdfdocumentproxy', complete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container>
      <Grid item md={12} sm={12}>
        <Typography
          component={'div'}
          className="w-title black-white-text pt-2 pb-2">
          Documents Fields
        </Typography>
      </Grid>
      <Grid item md={6} sm={12} className="pr-1">
        <Typography
          component={'div'}
          sx={{
            backgroundColor: '#fff',
            height: '500px',
            overflowY: 'auto',
          }}>
          <Typography component={'div'} className="doc-field-box">
            {filedLoader ? (
              <div className="liberty-loader" />
            ) : inputFieds && inputFieds.length > 0 ? (
              inputFieds.map((field, index) => (
                <Typography
                  key={index}
                  className={'doc-field-item'
                    .concat(
                      !field?.question_id || field?.question_id.length === 0
                        ? ' red'
                        : ' normal',
                    )
                    .concat(docState.index === index ? ' active' : '')}
                  onClick={() => selectDocFields(field, index)}>
                  {field?.pdf_doc_field
                    ? field?.pdf_doc_field
                    : field?.doc_field}
                </Typography>
              ))
            ) : (
              ''
            )}
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={6} sm={12} className="pl-1">
        <Typography
          component={'div'}
          sx={{
            backgroundColor: '#fff',
            minHeight: '500px',
            padding: '2rem',
          }}>
          <form
            style={{
              border: 'unset !important',
              padding: 'unset !important',
            }}>
            <Typography component={'div'} className="custom-field">
              <Typography className="custom-label">Document Field</Typography>
              <CustomInput
                placeHolder={''}
                value={
                  docState.selectedDocField?.pdf_doc_field
                    ? docState.selectedDocField?.pdf_doc_field
                    : docState.selectedDocField?.doc_field
                }
                error={
                  mappingQusSubmitted &&
                  !docState.selectedDocField?.doc_field &&
                  'Document Field is required'
                }
                className={'custom-input'}
                disabled
              />
            </Typography>
            {fileProps.documentType === 'pdf' && (
              <Typography component={'div'} className="custom-field">
                <Typography className="custom-label">
                  Field Description
                </Typography>
                <div className="col-md-9 col-sm-9 mb-30">
                  <div
                    className="card"
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      minHeight: '150px',
                      padding: '8px 11px !important',
                    }}>
                    {docState.selectedDocField?.tooltip}
                  </div>
                </div>
              </Typography>
            )}
            <Typography component={'div'} className="custom-field">
              <Typography className="custom-label">Mapping Question</Typography>
              {!docState.selectedDocField ||
                (docState.selectedDocField &&
                  !docState.selectedDocField.exportValue && (
                    <>
                      <CustomSelect
                        closeMenuOnSelect={true}
                        labelKey={'label'}
                        valueKey={'value'}
                        options={mappedQuestionWithLabelValues}
                        isMulti={true}
                        value={docState.selectMappingQus}
                        onChange={(e) => {
                          const selected = e.target.value
                            ? mappedQuestionWithLabelValues.filter(
                                (obj) =>
                                  e.target.value.indexOf(obj.value) !== -1,
                              )
                            : [];
                          setDocState((pre) => ({
                            ...pre,
                            selectMappingQus: selected.length
                              ? selected.map((ques) => ques.value)
                              : [],
                          }));
                        }}
                        error={
                          mappingQusSubmitted &&
                          docState.selectedDocField &&
                          docState.selectMappingQus.length === 0
                            ? 'Mapping Question is required'
                            : ''
                        }
                        placeHolder={'Mapping Question'}
                        className={'basic-multi-select'}
                      />
                      {/* <Multiselect
                        closeOnSelect={true}
                        placeholder={'Mapping Question'}
                        displayValue="link_code"
                        onSelect={(selected, item) => {
                          setDocState((pre) => ({
                            ...pre,
                            selectMappingQus: selected.map((ques) => ques.id),
                          }));
                        }}
                        onRemove={(selected, item) => {
                          setDocState((pre) => ({
                            ...pre,
                            selectMappingQus: selected.map((ques) => ques.id),
                          }));
                        }}
                        // selectedValues={docState.selectMappingQus}
                        selectedValues={questionIds}
                        options={
                          !_.isEmpty(mappedQuestion) ? mappedQuestion : []
                        }
                        keepSearchTerm={true}
                      />
                      {mappingQusSubmitted &&
                        docState.selectedDocField &&
                        docState.selectedDocField.exportValue &&
                        !docState.selectCheckAndRadio && (
                          <span className={'Mui-error'}>
                            Mapping Question is required
                          </span>
                        )} */}
                    </>
                  ))}
              {docState.selectedDocField &&
                docState.selectedDocField.exportValue && (
                  // <CustomSelect
                  //   labelKey={'link_code'}
                  //   valueKey={'id'}
                  //   options={!_.isEmpty(mappedQuestion) ? mappedQuestion : []}
                  //   value={docState.selectCheckAndRadio}
                  //   onChange={(e) => {
                  //     const resp = mappedQuestion.find(
                  //       (ques) => ques.id === e.target.value,
                  //     );
                  //     setDocState((pre) => ({
                  //       ...pre,
                  //       selectCheckAndRadio: resp,
                  //     }));
                  //     responseSelect(resp);
                  //   }}
                  //   error={
                  //     mappingQusSubmitted &&
                  //     docState.selectedDocField &&
                  //     docState.selectedDocField.exportValue &&
                  //     !docState.selectCheckAndRadio &&
                  //     'Mapping Question is required'
                  //   }
                  //   placeHolder={''}
                  //   className={'custom-input'}
                  //   searchable={true}
                  // />
                  <Select
                    values={
                      docState.selectCheckAndRadio
                        ? [
                            mappedQuestion.find(
                              (ques) =>
                                ques.id === docState.selectCheckAndRadio,
                            ),
                          ]
                        : []
                    }
                    options={!_.isEmpty(mappedQuestion) ? mappedQuestion : []}
                    labelField={'link_code'}
                    valueField={'id'}
                    searchBy={'link_code'}
                    onChange={(e) => {
                      const resp = mappedQuestion.find(
                        (ques) =>
                          ques.id === (e && e[0] && e[0].id ? e[0].id : null),
                      );
                      if (resp) {
                        setDocState((pre) => ({
                          ...pre,
                          selectCheckAndRadio: resp.id,
                        }));
                        responseSelect(resp.response);
                      }
                    }}
                  />
                )}
            </Typography>

            {fileProps.documentType === 'pdf' && (
              <Typography component={'div'} className="custom-field">
                <Typography className="custom-label">Response</Typography>
                <CustomSelect
                  labelKey={'name'}
                  valueKey={'value'}
                  options={
                    !_.isEmpty(docState.responseList)
                      ? docState.responseList
                      : []
                  }
                  value={docState.responseSelectCheck}
                  onChange={(e) => {
                    const resp = docState.responseList.find(
                      (response) => response.value === e.target.value,
                    );
                    if (resp) {
                      setDocState((pre) => ({
                        ...pre,
                        responseSelectCheck: resp.value,
                      }));
                    }
                    // responseSelect(resp);
                  }}
                  error={
                    mappingQusSubmitted &&
                    docState.selectedDocField?.exportValue &&
                    !docState.responseSelectCheck &&
                    'Response is required'
                  }
                  placeHolder={''}
                  className={'custom-input'}
                  searchable={true}
                />
              </Typography>
            )}

            <Typography className="d-flex-jae">
              {docState.selectedDocField && docState.selectedDocField.id && (
                <DeleteForeverIcon
                  className="tras-btn"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setDeleteDialog({
                      show: true,
                    });
                  }}
                />
              )}
              <Typography className=" d-flex-1 text-right">
                <Button
                  className="n-add-btn"
                  sx={{
                    borderRadius: '5px !important',
                    height: '35px !important',
                  }}
                  onClick={saveQus}>
                  {docState.selectedDocField && docState.selectedDocField.id
                    ? 'Update'
                    : 'Save'}
                </Button>
              </Typography>
            </Typography>
          </form>
        </Typography>
        <ConfirmDialog
          title="DELETE"
          floatingBtnShow={true}
          open={deleteDialog.show}
          close={() => {
            setDeleteDialog((pre) => ({
              ...pre,
              show: false,
            }));
          }}
          onConfirm={(act) => deleteConfirmation(act)}
          autoCloseDisable={true}>
          {'Are you sure do you want to delete?'}
        </ConfirmDialog>
      </Grid>
      {fileProps && fileProps.documentType === 'pdf' && (
        <div
          id="parentdocId"
          style={{
            height: '2px',
          }}>
          {/* <PDFReaderLocal
            onError={onError}
            showAllPage={true}
            url={fileProps.pdfSrc}
            hidden={true}
            onDocumentComplete={complete}
          /> */}
          <ng-pdf-view-edit
            ref={pdfRef}
            pdfurl={fileProps.pdfSrc}
            showall="true"
            inputfieldenable="false"
            page="1"
            viewhidden="true"
            pdfviewerwidth={
              document.getElementById('parentdocId') &&
              document.getElementById('parentdocId').offsetWidth
                ? `${
                    document.getElementById('parentdocId').offsetWidth - 100
                  }px`
                : 'auto'
            }
          />
        </div>
      )}
    </Grid>
  );
};

export default DocumentFieldsViewer;
