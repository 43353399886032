// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, List, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles } from '@mui/styles';
import Logo from 'assets/images/liberty-logoheader.png';
// import Space from 'assets/images/space.png';
import Footer from 'components/Footer';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';

export const useStyles = makeStyles((theme) => ({
  space: {
    backgroundColor: '#09171f',
    backgroundImage: `url(${'https://d17p81usn86n3p.cloudfront.net/space.png'})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    bottom: '0',
    left: '0',
    minHeight: '100%',
    position: 'fixed',
    right: '0',
    top: '0',
    width: '100%',
  },
}));

const VersionHistory: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [list, SetList] = useState<any>([]);
  const [id, SetId] = useState<any>('');
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  useEffect(() => {
    getAllListData(COMMON_ENDPOINT.Version_History)
      .then((resp: any) => {
        SetList(resp.data);
        SetId(resp.data[0].version_id);
      })
      .catch((e: any) => {});
  }, []);
  return (
    <div>
      <Typography className=" black-white-text p-f pt-2">
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <Typography className={classes.space} />
      <div className="innerBox">
        <div
          className=" d-flex-a d-flex-sb mb-1 sticky-tab"
          style={{ top: '0px' }}>
          <div className="black-white-text font-bold f-20 p-wtext">
            Version History Details
          </div>
          <div>
            <Button
              className="cancel-btn mr-2"
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '1em !important',
                width: '120px !important',
              }}
              onClick={() =>
                navigate(`/${String(userDetail?.userRoleName)}/dashboard`)
              }>
              <Typography
                className="o-box d-flex-a"
                sx={{ width: 'unset !important' }}
              />
              {/* <ArrowBackIcon sx={{ marginRight: '.5rem' }} /> */}
              cancel
            </Button>
          </div>
        </div>

        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #e4e4e4',
            borderRadius: '5px',
            boxShadow: '0 0 12px -1px #888',
            padding: '2rem',
          }}>
          <div style={{ paddingBottom: '1rem' }}>
            Please find below the version history of the application. It only
            includes final releases and not pre-release or beta software.
          </div>
          {list && list.length > 0
            ? list.map((e) => (
                <Accordion key={e}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="d-flex-a d-flex-sb w-100">
                      <div>Version {e.version_id}</div>
                      <div style={{ paddingRight: '1rem' }}>
                        {moment(e.created_on).format('yyyy-MM-DD hh:mm A')}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {/* <ListItem alignItems="flex-start"></ListItem> */}
                      <div
                        dangerouslySetInnerHTML={{ __html: e?.release_notes }}
                      />
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))
            : ''}
        </div>
      </div>
      <Footer versionId={id} />
    </div>
  );
};

export default VersionHistory;
