import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import RuleIcon from '@mui/icons-material/Rule';
import SourceIcon from '@mui/icons-material/Source';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import Assemble from 'assets/images/assemble.png';
import Bsend from 'assets/images/b-send.png';
import Layer from 'assets/images/layer.png';
import Pdf from 'assets/images/pdf.png';
import wrd from 'assets/images/wrd.png';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomModalView from 'components/CustomModalView/customModalView';
import { AffilatePrivateRoutes, AttorneyPrivateRoutes } from 'config';
import saveAs from 'file-saver';
import { instantState } from 'hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteData,
  getAllListData,
  getS3file,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import { AddAdditionalBuildCaseTemplate } from './AddAdditionalTemplate';
import { ResourceStation } from './ResourceStation';

export const PetitionBuildCase: React.FC<any> = ({ onlyShow }) => {
  const { petitionId, depUserId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location: any = useLocation();
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [loader, setLoader] = useState(true);
  const [isOpenView, setIsOpenView] = useState<any>({
    document: null,
    show: null,
  });
  const open = Boolean(isOpenView.show);
  const [dependentList, setDependentList] = useState<any>([]);
  const [visaSpecificDocument, setVisaSpecificDocument] = useState<any>([]);
  const [previousDocument, setPreviousDocument] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({
    id: null,
    index: null,
    item: null,
    show: false,
  });
  const [newTemplate, setNewTemplate] = React.useState({
    show: false,
  });
  const [buildConfirm, setBuildConfirm] = useState({
    show: false,
  });
  const [sendToChooseModal, setSendToChooseModal] = useState({
    open: false,
    selectUser: 2,
  });
  const [additionalBuildDoc, setAdditionalBuildDoc] = useState({
    open: false,
  });
  const [resourceStation, setResourceStation] = useState({
    open: false,
  });

  const getDependentList = useCallback((petId) => {
    getAllListData(
      `${String(COMMON_ENDPOINT.PetitionDependent)}/${String(petId)}`,
    ).then((pResp: any) => {
      setDependentList(pResp);
      setLoader(false);
    });
  }, []);

  const redirectDependentForm = (data) => {
    setVisaSpecificDocument([]);
    navigate(
      `${String(AttorneyPrivateRoutes.PETITIONS.BUILDCASEDEPENDENT)}/${String(
        petitionId,
      )}/${String(data.id)}/${String(
        petitions && petitions.petitionData
          ? petitions.petitionData.visa_id
          : '',
      )}?dependentName=${
        String(data.first_name) + ' ' + String(data.last_name)
      }`,
    );
  };

  const latestBuild = (data?) => {
    if (data.latestBuild !== 0) {
      if (data.file_format === 'document') {
        openFile(data.latestBuild[0].file_key);
      } else {
        const url =
          String(process.env.REACT_APP_DOCUMENT_URL) +
          String(data.latestBuild[0].id);
        window.open(url);
      }
    }
  };

  const getVisaSpecificDocById = useCallback(
    (dependentUserId?) => {
      let url = `${String(COMMON_ENDPOINT.DocumentBuild)}/${String(
        petitionId,
      )}/${String(
        petitions && petitions.petitionData
          ? petitions.petitionData.visa_id
          : '',
      )}?`;
      if (dependentUserId) {
        url =
          url +
          `&formType=dependent&dependentUserId=${String(dependentUserId)}`;
      }
      if (
        petitions?.petitionData?.isAffilateCase &&
        petitions?.petitionData?.affilateRoleId
      ) {
        url =
          url +
          `&isAffilate=${String(
            petitions?.petitionData?.isAffilateCase,
          )}&firmId=${String(
            petitions?.petitionData?.attorney_assigned,
          )}&affilateRoleId=${String(petitions?.petitionData?.affilateRoleId)}`;
      }
      getAllListData(url)
        .then((res: any) => {
          setLoader(false);
          setVisaSpecificDocument(res);
        })
        .catch(() => {
          setLoader(false);
        });
    },
    [petitionId, petitions],
  );

  const openFile = (file) => {
    const obj = {
      key: file,
    };

    const fileName = file.split('/').pop();
    setLoader(true);
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        saveAs(data, fileName);
        toast.success(`${String(fileName)} File Downloaded`, alertOptions);
        setIsOpenView((pre) => ({
          ...pre,
          show: null,
        }));
        setLoader(false);
      })
      .catch((err) => {
        setIsOpenView((pre) => ({
          ...pre,
          show: null,
        }));
        setLoader(false);
        // toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };

  const openFileToNewWindow = (pathId, wordPath) => {
    if (isOpenView.document?.file_format === 'document') {
      openFile(wordPath);
    } else {
      const url = String(process.env.REACT_APP_DOCUMENT_URL) + String(pathId);
      window.open(url);
    }
  };

  const deletePdfVersion = (item, index) => {
    setDeleteDialog((pre) => ({
      ...pre,
      id: item.id,
      index,
      item,
      show: true,
    }));
  };

  const onDelete = (action: string) => {
    if (action !== 'yes') {
      setDeleteDialog((pre) => ({
        ...pre,
        show: false,
      }));
      return;
    }
    deleteData(deleteDialog.id, COMMON_ENDPOINT.GenerateDocs).then(() => {
      isOpenView.document?.previousVersions.splice(deleteDialog.index, 1);
      if (
        deleteDialog.index === 0 &&
        isOpenView.document?.previousVersions[0]
      ) {
        isOpenView &&
          isOpenView.document &&
          isOpenView.document.latestBuild &&
          (isOpenView.document.latestBuild = [
            isOpenView.document?.previousVersions[0],
          ]);
        isOpenView &&
          isOpenView.document &&
          (isOpenView.document.build_document_for =
            isOpenView.document?.previousVersions[0].build_document_for);

        isOpenView &&
          isOpenView.document &&
          (isOpenView.document.beneficiary_build_document_status =
            isOpenView.document?.previousVersions[0].beneficiary_build_document_status);

        isOpenView &&
          isOpenView.document &&
          (isOpenView.document.petitioner_build_document_status =
            isOpenView.document?.previousVersions[0].petitioner_build_document_status);
      }
      if (isOpenView.document?.previousVersions.length === 0) {
        isOpenView &&
          isOpenView.document &&
          isOpenView.document.latestBuild &&
          (isOpenView.document.latestBuild = []);

        isOpenView &&
          isOpenView.document &&
          (isOpenView.document.build_document_for = []);

        isOpenView &&
          isOpenView.document &&
          (isOpenView.document.beneficiary_build_document_status = 'pending');

        isOpenView &&
          isOpenView.document &&
          (isOpenView.document.petitioner_build_document_status = 'pending');
      }
      toast.info('Record deleted', alertOptions);
      setDeleteDialog({
        id: null,
        index: null,
        item: null,
        show: false,
      });
    });
  };

  // const nameParser = useCallback((value) => {
  //   const fileName = value.split('/generate/')[1];
  //   const timeStamp = fileName.split('.')[0];
  //   if (timeStamp) {
  //     const modifiedFileName = moment(Number(timeStamp)).format(
  //       'DD-MM-YYYY h:mm:ss',
  //     );
  //     return modifiedFileName;
  //   }
  //   return '';
  // }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const buildNow = (templateId: string) => {
    setLoader(true);
    // applyHighlightedContent();
    let url = `${String(COMMON_ENDPOINT.DocumentBuild)}/${String(
      petitionId,
    )}/${String(petitions.petitionData?.beneficiary_id)}/${String(
      templateId,
    )}?leadId=${String(petitions.petitionData?.lead_id)}`;
    if (onlyShow && onlyShow === 'dependent') {
      url = url + `&buildtype=dependent&dependentUserId=${String(depUserId)}`;
    }

    getAllListData(url)
      .then((res: any) => {
        setIsOpenView((pre) => ({
          ...pre,
          show: null,
        }));
        if (res.documentType === 'pdf') {
          setLoader(false);
          const url =
            String(process.env.REACT_APP_DOCUMENT_URL) + String(res.data.id);
          window.open(url, '_blank');
        }
        const dependentUserId =
          onlyShow && onlyShow === 'dependent' && !_.isUndefined(depUserId)
            ? depUserId
            : '';
        getVisaSpecificDocById(dependentUserId);

        // else {
        //   const obj = {
        //     key: res.key,
        //   };
        //   const fileName = res.key.split('/').pop();
        //   getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
        //     .then((data: any) => {
        //       setLoader(false);
        //       saveAs(data, fileName);
        //       // getLinkDataForHTMLParsing();
        //       getLinkDataForHTMLParsing();
        //       toast.success(
        //         `${String(fileName)} File Downloaded`,
        //         alertOptions,
        //       );
        //     })
        //     .catch((err) => {
        //       setLoader(false);
        //       toast.error(
        //         err ? err : 'Oops!!! Something went wrong',
        //         alertOptions,
        //       );
        //     });
        // }
      })
      .catch((err) => {
        toast.error(err, alertOptions);
        setIsOpenView((pre) => ({
          ...pre,
          show: null,
        }));
        setLoader(false);
      });
  };

  const openModal = (doc: any) => {
    if (
      doc?.beneficiary_build_document_status === 'pending' &&
      doc?.petitioner_build_document_status === 'pending'
    ) {
      setSendToChooseModal((pre) => ({
        ...pre,
        open: true,
        selectUser: 2,
      }));
    } else {
      if (doc.build_document_for && Array.isArray(doc.build_document_for)) {
        doc.build_document_for.includes(2) &&
        doc?.petitioner_build_document_status === 'reviewPending'
          ? toast.info('Document already sent for review', alertOptions)
          : doc.build_document_for.includes(2) &&
            doc?.petitioner_build_document_status === 'approved'
          ? toast.info('Document has beeen reviewed', alertOptions)
          : '';

        doc.build_document_for.includes(4) &&
        doc?.beneficiary_build_document_status === 'reviewPending'
          ? toast.info('Document already sent for review', alertOptions)
          : doc.build_document_for.includes(4) &&
            doc?.beneficiary_build_document_status === 'approved'
          ? toast.info('Document has beeen reviewed', alertOptions)
          : '';
      }
    }
  };
  const sendTo = async (doc: any) => {
    const buildDocId = doc?.latestBuild[0]?.id;
    const user = await instantState(setSendToChooseModal);
    setLoader(true);
    try {
      await updateData(
        buildDocId,
        {
          documentFor: [user?.selectUser],
          documentName: doc?.current_formName,
          documentStatus: 'reviewPending',
          petitionId: petitionId,
        },
        COMMON_ENDPOINT.DocumentBuildSendTo,
      );
      toast.info('Your document has been sent for review.', alertOptions);
      getVisaSpecificDocById();
      setSendToChooseModal((pre) => ({
        ...pre,
        open: false,
        selectUser: 2,
      }));
      setIsOpenView((pre) => ({
        ...pre,
        show: null,
      }));
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(petitions) && petitions.petitionData) {
      const dependentUserId =
        onlyShow && onlyShow === 'dependent' && !_.isUndefined(depUserId)
          ? depUserId
          : '';
      getVisaSpecificDocById(dependentUserId);
      // petitions.petitionData.beneficiary_id &&
      //   getAllUploadedImage(petitionId, petitions.petitionData.beneficiary_id);
      getDependentList(petitionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitions, onlyShow, depUserId]);

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Grid container>
      {!_.isEmpty(petitions) && !_.isEmpty(petitions.petitionData) && (
        <Grid item md={10} sm={12} sx={{ paddingRight: '2rem' }}>
          {onlyShow && onlyShow === 'dependent' ? (
            <Typography className="d-flex-a d-flex-sb">
              <Typography
                component={'div'}
                className="black-white-text d-flex-a"
                sx={{
                  fontFamily: 'Arial-Bold !important',
                  fontSize: '1.25em !important',
                  fontWeight: 400,
                  margin: '1.5rem 0 0rem 0',
                }}>
                {petitions.petitionData.visa_type} Dependent for{' '}
                {searchParams.get('dependentName')}
              </Typography>
              <Button
                className="cancel-btn mr-2 "
                sx={{
                  borderRadius: '5px !important',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '0.9em !important',
                  marginTop: '1rem',
                  // height: '35px !important',
                  width: '120px !important',
                }}
                onClick={() => {
                  setVisaSpecificDocument([]);
                  navigate(-1);
                }}>
                <Typography
                  className="o-box d-flex-a"
                  sx={{ width: 'unset !important' }}
                />
                <ArrowBackIcon sx={{ marginRight: '.5rem' }} />
                Back
              </Button>
            </Typography>
          ) : petitions?.petitionData ? (
            <Typography
              component={'div'}
              className="black-white-text"
              sx={{
                fontFamily: 'Arial-Bold !important',
                fontSize: '18px !important',
              }}>
              {petitions.petitionData.visa_type} for{' '}
              {petitions.petitionData?.benificiery_name}
            </Typography>
          ) : (
            ''
          )}
          <Grid item md={12} sm={12} sx={{ paddingRight: '2rem' }}>
            <Grid container className="pe-box">
              {visaSpecificDocument.length > 0 ? (
                visaSpecificDocument.map((document: any, index: number) => (
                  <Grid
                    item
                    md={3}
                    sm={6}
                    sx={{ paddingRight: '1rem', paddingTop: '1rem' }}
                    key={index}>
                    <Typography
                      className="build-box d-flex-a pointer"
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e) =>
                        setIsOpenView({ document, show: e.currentTarget })
                      }>
                      <img
                        src={document.file_format === 'pdf' ? Pdf : wrd}
                        alt={document.file_format === 'pdf' ? 'pdf' : 'word'}
                      />
                      <span
                        className="f-15 font-bold black-white-text d-flex-1"
                        style={{
                          maxWidth: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          // whiteSpace: 'nowrap',
                          // wordBreak: 'break-all',
                        }}>
                        {document.template_name}
                      </span>
                      {(['reviewPending', 'approved'].includes(
                        document?.beneficiary_build_document_status,
                      ) &&
                        document.build_document_for &&
                        Array.isArray(document.build_document_for) &&
                        document.build_document_for.includes(4)) ||
                      (['reviewPending', 'approved'].includes(
                        document?.petitioner_build_document_status,
                      ) &&
                        document.build_document_for &&
                        Array.isArray(document.build_document_for) &&
                        document.build_document_for.includes(2)) ? (
                        <Typography
                          component={'div'}
                          sx={{
                            bottom: '0',
                            position: 'absolute',
                            right: '10px',
                            top: '8px',
                          }}>
                          {document?.beneficiary_build_document_status ===
                            'approved' ||
                          document?.petitioner_build_document_status ===
                            'approved' ? (
                            <Tooltip
                              title={`Approved by ${
                                document.build_document_for.includes(2)
                                  ? 'Petitioner'
                                  : 'Beneficiary'
                              }`}
                              placement="top">
                              <CheckIcon
                                color={'success'}
                                sx={{
                                  fontSize: '24px',
                                  fontWeight: 800,
                                }}
                              />
                            </Tooltip>
                          ) : (
                            ''
                          )}

                          {document?.beneficiary_build_document_status ===
                            'reviewPending' ||
                          document?.petitioner_build_document_status ===
                            'reviewPending' ? (
                            <Tooltip
                              title={`Pending for review with ${
                                document.build_document_for.includes(2)
                                  ? 'Petitioner'
                                  : 'Beneficiary'
                              }`}
                              placement="top">
                              <InfoRoundedIcon color={'info'} />
                            </Tooltip>
                          ) : (
                            ''
                          )}
                        </Typography>
                      ) : document?.beneficiary_build_document_status ===
                          'pending' ||
                        document?.petitioner_build_document_status ===
                          'pending' ? (
                        document?.isAttorneyConfirmationPending ? (
                          <Typography
                            component={'div'}
                            sx={{
                              bottom: '0',
                              position: 'absolute',
                              right: '10px',
                              top: '8px',
                            }}>
                            <Tooltip
                              title={'You must accept or reject the answer'}
                              placement="top">
                              <InfoRoundedIcon color={'info'} />
                            </Tooltip>
                          </Typography>
                        ) : null
                      ) : null}
                      <Badge
                        className="badge-tick"
                        badgeContent={
                          document.previousVersions
                            ? document.previousVersions.length
                            : 0
                        }
                        color={
                          document?.beneficiary_build_document_status ===
                            'approved' ||
                          document?.petitioner_build_document_status ===
                            'approved'
                            ? 'success'
                            : 'error'
                        }
                      />
                    </Typography>
                    {open && isOpenView.document?.id === document.id && (
                      <Menu
                        className="pe-custom"
                        id="basic-menu"
                        anchorEl={isOpenView.show}
                        open={open}
                        onClose={() =>
                          setIsOpenView({ document: {}, show: null })
                        }
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}>
                        <div className="pe-tooltip">
                          {!_.isEmpty(isOpenView) ? (
                            <div
                              className="blue-box"
                              style={{ minHeight: '65px' }}>
                              <img
                                style={{
                                  height: '60px',
                                  width: '60px',
                                }}
                                src={
                                  isOpenView.document?.file_format === 'pdf'
                                    ? Pdf
                                    : wrd
                                }
                                alt={
                                  isOpenView.document?.file_format === 'pdf'
                                    ? 'pdf'
                                    : 'word'
                                }
                              />
                              <span className="font-bold text d-flex-1">
                                {isOpenView.document?.template_name}
                              </span>
                            </div>
                          ) : (
                            <div className="blue-box">
                              <span className="f-22 font-bold d-flex-1">#</span>
                            </div>
                          )}
                          {!isOpenView.document
                            ?.isAttorneyConfirmationPending ? (
                            <div className="black-box">
                              {/* Previous Versions */}
                              {(!petitions?.petitionData?.isAffilateCase &&
                                userDetail.role_id === 3) ||
                              (petitions?.petitionData?.isAffilateCase &&
                                [3].includes(
                                  petitions?.petitionData?.affilateRoleId,
                                )) ? (
                                <Typography
                                  component={'div'}
                                  className="d-flex-a mb-2"
                                  sx={{
                                    cursor: !isOpenView.document
                                      ?.previousVersions.length
                                      ? 'not-allowed'
                                      : 'pointer',
                                  }}
                                  onClick={() =>
                                    isOpenView.document?.previousVersions
                                      .length && setPreviousDocument(true)
                                  }>
                                  {isOpenView.document?.previousVersions
                                    .length > 0 ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon
                                      sx={{
                                        color: '#9a9a9ab3 !important',
                                      }}
                                    />
                                  )}
                                  <Typography
                                    className={'f-18 pl-2 '.concat(
                                      isOpenView.document?.previousVersions
                                        .length > 0
                                        ? 'text'
                                        : 'text-off',
                                    )}>
                                    Previous Versions
                                  </Typography>
                                </Typography>
                              ) : (
                                ''
                              )}

                              {/* Edit Doc */}
                              {/* {isOpenView.document?.file_format !== 'pdf' ? ( */}
                              <Typography
                                component={'div'}
                                className="d-flex-a mb-2"
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (
                                    onlyShow !== 'dependent' &&
                                    isOpenView.document?.latestBuild.length &&
                                    ((isOpenView.document?.latestBuild[0]
                                      ?.beneficiary_build_document_status ===
                                      'reviewPending' &&
                                      isOpenView.document?.build_document_for &&
                                      Array.isArray(
                                        isOpenView.document?.build_document_for,
                                      ) &&
                                      isOpenView.document?.build_document_for.includes(
                                        4,
                                      )) ||
                                      (isOpenView.document?.latestBuild[0]
                                        ?.petitioner_build_document_status ===
                                        'reviewPending' &&
                                        isOpenView.document
                                          ?.build_document_for &&
                                        Array.isArray(
                                          isOpenView.document
                                            ?.build_document_for,
                                        ) &&
                                        isOpenView.document?.build_document_for.includes(
                                          2,
                                        )))
                                  ) {
                                    setBuildConfirm((pre) => ({
                                      ...pre,
                                      show: true,
                                    }));
                                    return;
                                  }
                                  let url =
                                    userDetail.role_id === 3
                                      ? `${String(
                                          AttorneyPrivateRoutes.PETITIONS
                                            .BUILDCASEEDIT,
                                        )}/${String(petitionId)}/${String(
                                          isOpenView.document?.templateid,
                                        )}${
                                          searchParams.get('orgId')
                                            ? '?orgId=' +
                                              searchParams.get('orgId')
                                            : ''
                                        }${
                                          searchParams.get('invited')
                                            ? '&invited=true&isPetition=true'
                                            : searchParams.get('associated')
                                            ? '&associated=true&isPetition=true'
                                            : ''
                                        }`
                                      : userDetail.role_id === 5
                                      ? `${String(
                                          AffilatePrivateRoutes
                                            .ATTORNEY_PETITIONS.BUILDCASEEDIT,
                                        )}/${String(petitionId)}/${String(
                                          isOpenView.document?.templateid,
                                        )}`
                                      : null;
                                  if (
                                    onlyShow &&
                                    url &&
                                    onlyShow === 'dependent'
                                  ) {
                                    url = url + `/${String(depUserId)}`;
                                  }

                                  if (url) {
                                    navigate(url, {
                                      state: {
                                        data: {
                                          fileFormat:
                                            isOpenView.document?.file_format,
                                          pdfId:
                                            isOpenView.document?.templateid,
                                          templateFilePath:
                                            isOpenView.document
                                              ?.template_file_path,
                                          templateName:
                                            isOpenView.document?.template_name,
                                        },
                                        showType: location.state?.showType,
                                      },
                                    });
                                  }
                                }}>
                                <BorderColorIcon />
                                <Typography className="text f-18 pl-2">
                                  Edit
                                </Typography>
                              </Typography>
                              {/* // ) : (
                              //   <Typography
                              //     component={'div'}
                              //     className="d-flex-a mb-2"
                              //     sx={{ cursor: 'pointer' }}
                              //     onClick={() => {
                              //       if (
                              //         onlyShow !== 'dependent' &&
                              //         isOpenView.document?.latestBuild.length &&
                              //         ((isOpenView.document?.latestBuild[0]
                              //           ?.beneficiary_build_document_status ===
                              //           'reviewPending' &&
                              //           isOpenView.document
                              //             ?.build_document_for &&
                              //           Array.isArray(
                              //             isOpenView.document
                              //               ?.build_document_for,
                              //           ) &&
                              //           isOpenView.document?.build_document_for.includes(
                              //             4,
                              //           )) ||
                              //           (isOpenView.document?.latestBuild[0]
                              //             ?.petitioner_build_document_status ===
                              //             'reviewPending' &&
                              //             isOpenView.document
                              //               ?.build_document_for &&
                              //             Array.isArray(
                              //               isOpenView.document
                              //                 ?.build_document_for,
                              //             ) &&
                              //             isOpenView.document?.build_document_for.includes(
                              //               2,
                              //             )))
                              //       ) {
                              //         setBuildConfirm((pre) => ({
                              //           ...pre,
                              //           show: true,
                              //         }));
                              //         return;
                              //       }
                              //       buildNow(isOpenView.document?.templateid);
                              //     }}>
                              //     <BorderColorIcon />
                              //     <Typography className="text f-18 pl-2">
                              //       {'Build'}
                              //     </Typography>
                              //   </Typography>
                              // )} */}

                              {/* Download */}
                              {(!petitions?.petitionData?.isAffilateCase &&
                                userDetail.role_id === 3) ||
                              (petitions?.petitionData?.isAffilateCase &&
                                [3].includes(
                                  petitions?.petitionData?.affilateRoleId,
                                )) ? (
                                <Typography
                                  component={'div'}
                                  className="d-flex-a mb-2"
                                  sx={{
                                    cursor: !isOpenView.document?.latestBuild
                                      .length
                                      ? 'not-allowed'
                                      : 'pointer',
                                  }}
                                  // onClick={() => buildNow(isOpenView.document)} // buildNow will be on edit page
                                  onClick={() =>
                                    latestBuild(isOpenView.document)
                                  }>
                                  <VerticalAlignBottomIcon />
                                  <Typography
                                    className={'f-18 pl-2 '.concat(
                                      isOpenView.document?.latestBuild.length >
                                        0
                                        ? 'text'
                                        : 'text-off',
                                    )}>
                                    Download Copy
                                  </Typography>
                                </Typography>
                              ) : (
                                ''
                              )}

                              {/* Sent To */}
                              {onlyShow !== 'dependent' &&
                              ((!petitions?.petitionData?.isAffilateCase &&
                                userDetail.role_id === 3) ||
                                (petitions?.petitionData?.isAffilateCase &&
                                  [3].includes(
                                    petitions?.petitionData?.affilateRoleId,
                                  ))) ? (
                                <Typography
                                  component={'div'}
                                  className="d-flex-a"
                                  sx={{
                                    cursor:
                                      !isOpenView.document?.latestBuild
                                        .length ||
                                      (isOpenView.document?.latestBuild
                                        .length &&
                                        (isOpenView.document?.latestBuild[0]
                                          ?.beneficiary_build_document_status !==
                                          'pending' ||
                                          isOpenView.document?.latestBuild[0]
                                            ?.petitioner_build_document_status !==
                                            'pending'))
                                        ? 'not-allowed'
                                        : 'pointer',
                                  }}
                                  // onClick={() => buildNow(isOpenView.document)} // buildNow will be on edit page
                                  {...(isOpenView.document?.latestBuild
                                    .length &&
                                  isOpenView.document?.latestBuild[0]
                                    ?.beneficiary_build_document_status ===
                                    'pending' &&
                                  isOpenView.document?.latestBuild[0]
                                    ?.petitioner_build_document_status ===
                                    'pending'
                                    ? {
                                        onClick: () =>
                                          openModal(isOpenView.document),
                                      }
                                    : {})}>
                                  <Typography
                                    component={'img'}
                                    src={Bsend}
                                    alt="send to"
                                  />
                                  <Typography
                                    className={'f-18 pl-2 '.concat(
                                      isOpenView.document?.latestBuild.length >
                                        0 &&
                                        isOpenView.document?.latestBuild
                                          .length &&
                                        isOpenView.document?.latestBuild[0]
                                          ?.beneficiary_build_document_status ===
                                          'pending' &&
                                        isOpenView.document?.latestBuild[0]
                                          ?.petitioner_build_document_status ===
                                          'pending'
                                        ? 'text'
                                        : 'text-off',
                                    )}>
                                    Send to
                                  </Typography>
                                </Typography>
                              ) : (
                                ''
                              )}
                            </div>
                          ) : (
                            <div className="black-box">
                              <Typography
                                component={'div'}
                                className="d-flex-a mb-2"
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  navigate(
                                    `${String(
                                      AttorneyPrivateRoutes.PETITIONS
                                        .ACCEPTORREJECT,
                                    )}/${String(petitionId)}/${String(
                                      isOpenView.document?.templateid,
                                    )}/${String(
                                      isOpenView.document?.latestBuild
                                        ? isOpenView.document?.latestBuild[0].id
                                        : '',
                                    )}`,
                                    {
                                      state: {
                                        data: {
                                          fileFormat:
                                            isOpenView.document?.file_format,
                                          templateFilePath:
                                            isOpenView.document
                                              ?.template_file_path,
                                          templateName:
                                            isOpenView.document?.template_name,
                                          updatedBy:
                                            isOpenView.document?.latestBuild[0].build_document_for.includes(
                                              2,
                                            )
                                              ? 'Petitioner'
                                              : 'Beneficiary',
                                          // userName:
                                          //   isOpenView.document?.latestBuild[0]
                                          //     .user_name,
                                        },
                                      },
                                    },
                                  );
                                }}>
                                <RuleIcon />
                                <Typography className="text f-18 pl-2">
                                  Accept or Reject
                                </Typography>
                              </Typography>
                            </div>
                          )}
                        </div>
                      </Menu>
                    )}
                  </Grid>
                ))
              ) : (
                <Typography
                  className="note-box2 mt-2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                  <Typography className="inb2" />
                  <Typography className="black-white-text ctitle hideshow">
                    No Record(s) Found.
                  </Typography>
                </Typography>
              )}
            </Grid>

            {_.isUndefined(onlyShow) && dependentList.length > 0 && (
              <Grid container className="pe-box">
                <Grid item md={12}>
                  <Typography
                    className="black-white-text uadd-title"
                    sx={{
                      fontSize: '18px !important',
                      paddingBottom: '16px !important',
                      paddingLeft: '0px !important',
                      paddingTop: '22px !important',
                    }}>
                    Dependents Document Template
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item md={12} sm={12} sx={{ paddingRight: '16px' }}>
                    <table className="lead-table table-hover">
                      <thead>
                        <tr>
                          <th>NAME</th>
                          <th>EMAIL</th>
                          <th>RELATION</th>
                          <th>DOCUMENT TEMPLATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dependentList.map((row, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {String(row.first_name) +
                                  ' ' +
                                  String(row.last_name)}
                              </td>
                              <td>{row.email ? row.email : '-'}</td>
                              <td>
                                {row.relationship ? row.relationship : '-'}
                              </td>
                              <td>
                                <Button
                                  className="n-add-btn"
                                  onClick={() => redirectDependentForm(row)}>
                                  View
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {previousDocument && (
              <CustomModalView
                open={previousDocument}
                fullWidth={true}
                maxWidth={'md'}
                scroll={'paper'}
                close={() => setPreviousDocument(false)}
                title="Previous Document">
                <DialogContent>
                  <table className="lead-table table-hover">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: '30%' }}>
                          File Name
                        </th>
                        <th scope="col" style={{ width: '30%' }}>
                          Created At
                        </th>
                        <th scope="col" style={{ width: '50%' }}>
                          STATUS
                        </th>
                        {petitions?.petitionData?.isAffilateCase ? (
                          ''
                        ) : (
                          <th scope="col">DELETE</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {isOpenView.document?.previousVersions.length > 0 ? (
                        isOpenView.document?.previousVersions.map(
                          (datas, index) => (
                            <tr key={index}>
                              <td>
                                <a
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    openFileToNewWindow(
                                      datas.id,
                                      datas?.file_key,
                                    )
                                  }>
                                  {datas?.file_key.split('/')[3]}
                                </a>
                              </td>
                              <td
                                style={{
                                  color: '#1093df',
                                }}>
                                {/* <a
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    openFileToNewWindow(
                                      datas.id,
                                      datas?.file_key,
                                    )
                                  }> */}
                                {/* {nameParser(datas?.file_key)} */}
                                {datas.updated_at
                                  ? moment(datas.updated_at).format(
                                      'MM-DD-YYYY',
                                    )
                                  : '-'}
                                {/* </a> */}
                              </td>
                              <td
                                style={{
                                  color:
                                    datas?.beneficiary_build_document_status ===
                                      'approved' ||
                                    datas?.petitioner_build_document_status ===
                                      'approved'
                                      ? 'green'
                                      : datas?.beneficiary_build_document_status ===
                                          'reviewPending' ||
                                        datas?.petitioner_build_document_status ===
                                          'reviewPending'
                                      ? 'red'
                                      : 'black',
                                }}>
                                {datas.build_document_for &&
                                Array.isArray(datas.build_document_for)
                                  ? datas?.beneficiary_build_document_status ===
                                      'approved' ||
                                    datas?.petitioner_build_document_status ===
                                      'approved'
                                    ? `Approved by ${
                                        datas.build_document_for.includes(2)
                                          ? 'Petitioner'
                                          : 'Beneficiary'
                                      }`
                                    : datas?.beneficiary_build_document_status ===
                                        'reviewPending' ||
                                      datas?.petitioner_build_document_status ===
                                        'reviewPending'
                                    ? `Pending for review with ${
                                        datas.build_document_for.includes(2)
                                          ? 'Petitioner'
                                          : 'Beneficiary'
                                      }`
                                    : 'Review submitted, Waiting for approval'
                                  : 'Pending'}
                              </td>
                              {petitions?.petitionData?.isAffilateCase ? (
                                ''
                              ) : (
                                <td>
                                  <Tooltip title={'Delete'} placement="top">
                                    <DeleteForeverIcon
                                      sx={{
                                        cursor: 'pointer',
                                        fill: 'red',
                                        height: '25px',
                                        width: '25px',
                                      }}
                                      onClick={() =>
                                        deletePdfVersion(datas, index)
                                      }
                                    />
                                  </Tooltip>
                                </td>
                              )}
                            </tr>
                          ),
                        )
                      ) : (
                        <tr className={'text-center'}>
                          <th scope="row" colSpan={5}>
                            No Record(s) Found.
                          </th>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </DialogContent>

                {/* <DialogActions>
                <Typography
                  component={'div'}
                  className="d-flex-jae"
                  sx={{ padding: '0 1.5rem' }}>
                  <Button
                    className="n-cancel-btn mr-1"
                    sx={{
                      backgroundColor: '#bcc7dc !important',
                      borderRadius: '5px !important',
                      float: 'right',
                      fontFamily: 'Arial-Regular !important',
                      fontSize: '0.9em !important',
                      height: '35px !important',
                      width: '149px !important',
                    }}
                    onClick={() => setPreviousDocument(false)}>
                    Close
                  </Button>
                </Typography>
              </DialogActions> */}
              </CustomModalView>
            )}

            <ConfirmDialog
              title="Confirm Action"
              floatingBtnShow={true}
              open={deleteDialog.show}
              onConfirm={(act) => onDelete(act)}
              autoCloseDisable={true}>
              {'Are you sure, want to delete this Version?'}
            </ConfirmDialog>
          </Grid>
        </Grid>
      )}
      {(!petitions?.petitionData?.isAffilateCase && userDetail.role_id === 3) ||
      (petitions?.petitionData?.isAffilateCase &&
        [3].includes(petitions?.petitionData?.affilateRoleId)) ? (
        <Grid item md={2} sm={12}>
          {!onlyShow && (
            <Typography component={'div'} className="add-box">
              <Typography component={'div'} className="add-inner-box" />
              <Typography className=" bu-color f-16 pt-5 pb-2 pl-2">
                Select any case document to view options
              </Typography>
              <Typography
                className=" built-option  d-flex-a mb-1"
                onClick={() => {
                  if (userDetail.role_id === 3) {
                    navigate(
                      `${String(
                        AttorneyPrivateRoutes.PETITIONS.ASSEMBLE,
                      )}/${String(petitionId)}${
                        searchParams.get('orgId')
                          ? '?orgId=' + searchParams.get('orgId')
                          : ''
                      }${
                        searchParams.get('invited')
                          ? '&invited=true&isPetition=true'
                          : searchParams.get('associated')
                          ? '&associated=true&isPetition=true'
                          : ''
                      }`,
                    );
                    return;
                  }
                  if (userDetail.role_id === 5) {
                    navigate(
                      `${String(
                        AffilatePrivateRoutes.ATTORNEY_PETITIONS.ASSEMBLE,
                      )}/${String(petitionId)}`,
                    );
                    return;
                  }
                }}>
                {' '}
                <img
                  src={Assemble}
                  alt="im"
                  className="mr-1"
                  style={{ width: '20px !important' }}
                />
                Assemble
              </Typography>
              <Typography
                className=" built-option hover d-flex-a mb-1"
                onClick={() => {
                  if (userDetail.role_id === 3) {
                    navigate(
                      `${String(
                        AttorneyPrivateRoutes.PETITIONS.SUBMISSIONSTATION,
                      )}/${String(petitionId)}${
                        searchParams.get('orgId')
                          ? '?orgId=' + searchParams.get('orgId')
                          : ''
                      }${
                        searchParams.get('invited')
                          ? '&invited=true&isPetition=true'
                          : searchParams.get('associated')
                          ? '&associated=true&isPetition=true'
                          : ''
                      }`,
                    );
                    return;
                  }
                  if (userDetail.role_id === 5) {
                    navigate(
                      `${String(
                        AffilatePrivateRoutes.ATTORNEY_PETITIONS
                          .SUBMISSIONSTATION,
                      )}/${String(petitionId)}`,
                    );
                    return;
                  }
                }}>
                {' '}
                <img
                  width="35"
                  src={Layer}
                  alt="im"
                  className="mr-1"
                  style={{ width: '35px !important' }}
                />
                Submission Station
              </Typography>
              {!petitions?.petitionData?.isAffilateCase && (
                <>
                  <Typography
                    className=" built-option hover d-flex-a mb-1"
                    onClick={(e) =>
                      setResourceStation((pre) => ({
                        ...pre,
                        open: true,
                      }))
                    }>
                    {' '}
                    <SourceIcon
                      className="mr-1"
                      style={{ width: '35px !important' }}
                    />
                    Resource Station
                  </Typography>
                  <Typography
                    className="built-option d-flex-a mb-1"
                    onClick={(e) =>
                      setAdditionalBuildDoc((pre) => ({
                        ...pre,
                        open: true,
                      }))
                    }>
                    <span className="plus d-flex-ja mr-1">+</span>
                    Add Document
                  </Typography>
                  <Typography
                    className="built-option d-flex-a mb-1"
                    onClick={(e) =>
                      setNewTemplate((pre) => ({
                        ...pre,
                        show: true,
                      }))
                    }>
                    <span className="plus d-flex-ja mr-1">+</span>
                    Create New Document
                  </Typography>
                </>
              )}
            </Typography>
          )}
        </Grid>
      ) : (
        ''
      )}
      <ConfirmDialog
        title="Confirm Action"
        floatingBtnShow={true}
        open={newTemplate.show}
        onConfirm={(act) => {
          if (act !== 'yes') {
            setNewTemplate((pre) => ({
              ...pre,
              show: false,
            }));
          } else {
            navigate(AttorneyPrivateRoutes.DOCUMENT.DOCUMENTADD, {
              state: {
                templateType: 'document',
                visaTypeID: petitions.petitionData.visa_id,
              },
            });
          }
        }}
        autoCloseDisable={true}>
        {'Do you want proceed now?'}
      </ConfirmDialog>
      <ConfirmDialog
        title="Confirm Action"
        floatingBtnShow={true}
        open={buildConfirm.show}
        onConfirm={(act) => {
          if (act !== 'yes') {
            setBuildConfirm((pre) => ({
              ...pre,
              show: false,
            }));
          } else {
            setBuildConfirm((pre) => ({
              ...pre,
              show: false,
            }));
            // if (isOpenView.document?.file_format !== 'pdf') {
            let url = `${String(
              AttorneyPrivateRoutes.PETITIONS.BUILDCASEEDIT,
            )}/${String(petitionId)}/${String(
              isOpenView.document?.templateid,
            )}${
              searchParams.get('orgId')
                ? '?orgId=' + searchParams.get('orgId')
                : ''
            }${
              searchParams.get('invited')
                ? '&invited=true&isPetition=true'
                : searchParams.get('associated')
                ? '&associated=true&isPetition=true'
                : ''
            }`;
            if (onlyShow && onlyShow === 'dependent') {
              url = url + `/${String(depUserId)}`;
            }
            navigate(url, {
              state: {
                data: {
                  fileFormat: isOpenView.document?.file_format,
                  pdfId: isOpenView.document?.templateid,
                  templateFilePath: isOpenView.document?.template_file_path,
                  templateName: isOpenView.document?.template_name,
                },
                showType: location.state?.showType,
              },
            });
            // } else {
            //   buildNow(isOpenView.document?.templateid);
            // }
          }
        }}
        autoCloseDisable={true}>
        {
          'Building a new document will cancel the document already pending for review. Do you wish to proceed?'
        }
      </ConfirmDialog>
      <CustomModalView
        open={sendToChooseModal.open}
        fullWidth={true}
        maxWidth={'sm'}
        close={() =>
          setSendToChooseModal((pre) => ({
            ...pre,
            open: false,
          }))
        }
        title="Document Review">
        <>
          <DialogContent sx={{ paddingBottom: '0px' }}>
            <Typography component={'div'} className="d-flex-a m-border">
              <Typography className="f-15">Send to:</Typography>
              <Typography component={'div'} className="d-flex-a">
                <Typography component={'div'} className="pl-2">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    value={sendToChooseModal?.selectUser}
                    onChange={(e) => {
                      setSendToChooseModal((pre) => ({
                        ...pre,
                        selectUser: Number(e.target.value),
                      }));
                    }}>
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Petitioner"
                    />
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label="Beneficiary"
                    />
                  </RadioGroup>
                </Typography>
              </Typography>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Typography
              component={'div'}
              className="d-flex-jae"
              sx={{ padding: '0 1.5rem' }}>
              <Button
                className="n-add-btn"
                sx={{
                  borderRadius: '5px !important',
                  float: 'right',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '0.9em !important',
                  height: '35px !important',
                  margin: '15px',
                  width: '149px !important',
                }}
                onClick={() => sendTo(isOpenView.document)}>
                Submit
              </Button>
            </Typography>
          </DialogActions>
        </>
      </CustomModalView>

      {additionalBuildDoc.open ? (
        <AddAdditionalBuildCaseTemplate
          petitionId={petitionId}
          onlyShow={onlyShow}
          depUserId={depUserId}
          existingTemplateList={
            visaSpecificDocument &&
            Array.isArray(visaSpecificDocument) &&
            visaSpecificDocument.length
              ? visaSpecificDocument.map((e: any) => e.templateid)
              : []
          }
          modalOnClose={() =>
            setAdditionalBuildDoc((pre) => ({
              ...pre,
              open: false,
            }))
          }
        />
      ) : null}
      {resourceStation.open ? (
        <ResourceStation
          modalOnClose={() =>
            setResourceStation((pre) => ({
              ...pre,
              open: false,
            }))
          }
        />
      ) : null}
    </Grid>
  );
};

export default PetitionBuildCase;
