import { yupResolver } from '@hookform/resolvers/yup';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, TableCell, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from 'assets/images/liberty-logoheader.png';
// import Space from 'assets/images/space.png';
import { CKEditor } from 'ckeditor4-react';
import CustomInput from 'components/CustomInput/customInput';
import Pagination from 'components/Pagination';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { AddVersionForm } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { AddVersionformSchema } from 'utils/ValidatorSchema';

export const useStyles = makeStyles((theme) => ({
  space: {
    backgroundColor: '#09171f',
    backgroundImage: `url(${'https://d17p81usn86n3p.cloudfront.net/space.png'})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    bottom: '0',
    left: '0',
    minHeight: '100%',
    position: 'fixed',
    right: '0',
    top: '0',
    width: '100%',
  },
}));

const AddVersion: React.FC<any> = () => {
  const [params, setParams] = useState<any>({
    skip: 0,
    take: 50,
  });
  const defaultText =
    '<p><strong>New Features</strong></p><ul><li><br></li></ul><p><strong>BugsSquashed</strong></p><ul><li><br></li></ul><p><strong>Announcements</strong>&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;</p><ol><li>&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;</li></ol>';
  const classes = useStyles();
  const [list, SetList] = useState<any>([]);
  // const [content, SetContent] = useState<string>(defaultText);
  // const [showcontent, SetShowContent] = useState<boolean>(true);
  const [editid, SetEditId] = useState<string>('');
  const [editor, setEditor] = useState<any | null>(null);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    formState: { errors },
    setValue,
  } = useForm<AddVersionForm>({
    criteriaMode: 'all',
    defaultValues: {
      versionCode: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AddVersionformSchema),
  });
  const setEdit = (evt) => {
    SetEditId(evt.id);
    editor && editor.setData(evt.release_notes);
    setValue('versionCode', evt.version_id);
  };
  const getVersionData = () => {
    getAllListData(`${COMMON_ENDPOINT.Version_History}`)
      .then((resp: any) => {
        SetList(resp.data);
      })
      .catch((e: any) => {});
  };
  useEffect(() => {
    getVersionData();
  }, [params]);

  const Submitform = (data: any) => {
    const versiondata = {
      releaseNote: editor && editor.getData(),
      versionCode: data.versionCode,
    };
    if (editid) {
      updateData(editid, versiondata, COMMON_ENDPOINT.Add_Version_History)
        .then((Resp: any) => {
          resetForm();
          editor && editor.setData(defaultText);
          SetEditId('');
          getVersionData();
        })
        .catch(() => {});
    } else {
      addNewData(versiondata, COMMON_ENDPOINT.Add_Version_History)
        .then((Resp: any) => {
          resetForm();
          getVersionData();
          editor && editor.setData(defaultText);
        })
        .catch(() => {});
    }
  };
  const handleInstanceReady = ({ editor }: any) => {
    setEditor(editor);
    editor.setData(defaultText);
  };
  return (
    <Typography component={'div'} style={{ overflowX: 'hidden' }}>
      <Typography className=" black-white-text p-f pt-2">
        <img
          src={Logo}
          alt="logo"
          style={{ paddingLeft: '1rem', width: '155px' }}
        />
      </Typography>
      <div className={classes.space} />
      <form onSubmit={handleSubmit(Submitform)}>
        <div
          className="innerBox"
          style={{ marginLeft: '-135px', marginRight: '-115px' }}>
          <div className="d-flex-jae w-100">
            <div className=" d-flex-a d-flex-sb mb-1">
              <Button
                className="cancel-btn mr-2"
                onClick={() => {
                  editor.setData(defaultText);
                  resetForm();
                }}>
                <Typography className="o-box" />
                Cancel
              </Button>
            </div>
            <div className=" d-flex-a d-flex-sb mb-1">
              <Button
                type="submit"
                className="add-btn"
                style={{ cursor: 'pointer' }}>
                {editid ? 'update' : 'save'}
              </Button>
            </div>
          </div>
          <Grid container columnSpacing={{ md: 2, sm: 1 }}>
            <Grid item md={7} sm={12}>
              <div className="card" style={{ height: '100%', padding: '1rem' }}>
                <Typography component={'div'} className="custom-field">
                  <Typography component={'div'} className="custom-label">
                    <h2 className="f-16 font-bold">Version Code</h2>
                  </Typography>
                  <Controller
                    control={control}
                    name={'versionCode'}
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <CustomInput
                          placeHolder={''}
                          name={name}
                          value={value}
                          className={'custom-input'}
                          error={errors.versionCode?.message}
                          onChange={(e) => onChange(e)}
                        />
                      );
                    }}
                  />
                </Typography>
                <Typography component={'div'} style={{ paddingTop: '5px' }}>
                  <h2 className="f-16 font-bold">Version Description</h2>
                  <CKEditor
                    // initData={content}
                    // onChange={(evt) => SetContent(evt.editor.getData())}
                    onInstanceReady={handleInstanceReady}
                  />
                </Typography>
              </div>
            </Grid>
            <Grid item md={5} sm={12}>
              <div className="card">
                <table className="section-table table-hover">
                  <thead>
                    <tr>
                      <th>VERSION CODE</th>
                      <th>UPDATED ON</th>
                      <th>ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody style={{ overflow: 'auto' }}>
                    {list && list.length > 0 ? (
                      list.map((e, index) => (
                        <tr
                          key={index}
                          //   onClick={() => addSection(data)}
                          style={{ cursor: 'pointer' }}>
                          <td>{e.version_id}</td>
                          <td>
                            {moment(e.created_on).format('yyyy-MM-DD hh:mm A')}
                          </td>
                          <td>
                            {' '}
                            <TableCell>
                              <Tooltip title={'Edit'} placement="top">
                                <EditIcon
                                  sx={{
                                    cursor: 'pointer',
                                    fill: '#174baf',
                                    height: '25px',
                                    width: '25px',
                                  }}
                                  onClick={() => {
                                    setEdit(e);
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className={'text-center'}>
                        <td colSpan={5} align={'center'}>
                          No Record(s) Found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  total={!_.isEmpty(list?.data) ? Number(list?.total) : 0}
                  skip={params.skip}
                  take={params.take}
                  setParams={setParams}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
    </Typography>
  );
};
export default AddVersion;
