import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import React from 'react';

export const CompleteQuestion: React.FC<any> = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12}>
        <Box sx={{ width: '100%' }} className="cquesTab">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                variant="scrollable"
                onChange={handleChange}
                aria-label="lab API tabs example">
                <Tab label="Beneficiary Details I-129" value="1" />
                <Tab label="I-129 Part 4 Beneficiary Questions" value="2" />
                <Tab label="Beneficiary Details I-129" value="3" />
                <Tab label="I-129 Part 4 Beneficiary Questions" value="4" />
              </TabList>
            </Box>
            <Typography
              component={'div'}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '0px 8px 8px 8px',
                minHeight: '130px',
              }}>
              <TabPanel value="1">
                <Typography
                  component={'div'}
                  sx={{
                    fontFamily: 'Arial-Bold !important',
                    fontSize: '1.25em',
                  }}>
                  Add Contract Fees
                </Typography>
                <Typography component={'div'}>
                  <Box
                    component="form"
                    className="u-add-in"
                    sx={{
                      backgroundColor: 'unset !important',
                      border: 'unset !important',
                    }}
                    noValidate
                    autoComplete="off">
                    <Grid container>
                      <Grid item md={12}>
                        <Typography
                          component={'div'}
                          className="custom-field u-pr-1">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Visa Type
                          </Typography>
                          <TextField className="custom-input" placeholder="" />
                        </Typography>
                        <Typography
                          component={'div'}
                          className="custom-field u-pr-1">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Fees
                          </Typography>
                          <TextField className="custom-input" placeholder="" />
                        </Typography>
                        <Typography
                          component={'div'}
                          className="custom-field u-pr-1">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Visa Type
                          </Typography>
                          <TextField className="custom-input" placeholder="" />
                        </Typography>
                        <Typography
                          component={'div'}
                          className="custom-field u-pr-1">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Fees
                          </Typography>
                          <TextField className="custom-input" placeholder="" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Typography>
                <Typography
                  component={'div'}
                  sx={{
                    fontFamily: 'Arial-Bold !important',
                    fontSize: '1.25em',
                  }}>
                  Add Contract Fees
                </Typography>
                <Typography component={'div'}>
                  <Box
                    component="form"
                    className="u-add-in"
                    sx={{
                      backgroundColor: 'unset !important',
                      border: 'unset !important',
                    }}
                    noValidate
                    autoComplete="off">
                    <Grid container>
                      <Grid item md={12}>
                        <Typography
                          component={'div'}
                          className="custom-field u-pr-1">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Visa Type
                          </Typography>
                          <TextField className="custom-input" placeholder="" />
                        </Typography>
                        <Typography
                          component={'div'}
                          className="custom-field u-pr-1">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Fees
                          </Typography>
                          <TextField className="custom-input" placeholder="" />
                        </Typography>
                        <Typography
                          component={'div'}
                          className="custom-field u-pr-1">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Visa Type
                          </Typography>
                          <TextField className="custom-input" placeholder="" />
                        </Typography>
                        <Typography
                          component={'div'}
                          className="custom-field u-pr-1">
                          <Typography
                            component={'div'}
                            className="custom-label">
                            Fees
                          </Typography>
                          <TextField className="custom-input" placeholder="" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Typography>
              </TabPanel>
              <TabPanel value="2">Item Two</TabPanel>
              <TabPanel value="3">Item Two</TabPanel>
              <TabPanel value="4">Item Two</TabPanel>
            </Typography>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CompleteQuestion;
