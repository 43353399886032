import PrivateLayout from 'components/PrivateLayout';
import PublicLayout from 'components/PublicLayout';
import BeneficiaryContractHistory from 'pages/private/Client/Beneficiary/ContractHistory';
import BeneficiaryDetailList from 'pages/private/Client/Beneficiary/DetailsList';
import ContractViewer from 'pages/private/Client/Leads/ContractPayment/ContractViewer';
import PaymentDetail from 'pages/private/Client/Leads/ContractPayment/PaymentDetail';
import AchPayment from 'pages/private/Client/Leads/ContractPayment/PaymentMethod/ACHpayment';
import CreditDebitPayment from 'pages/private/Client/Leads/ContractPayment/PaymentMethod/CreditDebitCard';
import PaymentMethodAndTerms from 'pages/private/Client/Leads/ContractPayment/PaymentMethodAndTerms';
import UploadDocument from 'pages/private/Client/Leads/UploadDocument';
import PetitionCaseDetail from 'pages/private/Client/Petitions/CaseDetail';
import CompleteQuestions from 'pages/private/Client/Petitions/CompleteQuestion';
import PetitionerOrBeneficiaryBuildCaseEdit from 'pages/private/Client/Petitions/PetitionBuildCase/Edit/PetitionerOrBeneficiaryEdit';
import ReviewAndApprove from 'pages/private/Client/Petitions/PetitionBuildCase/ReviewAndApprove';
import SubmissionStation from 'pages/private/Client/Petitions/PetitionBuildCase/SubmissionStation';
import { UploadPetitionsDocument } from 'pages/private/Client/Petitions/UploadPetitionsDocument';
import Socialshare from 'pages/private/Client/socialshare';
import { AssemblePreviewDownload } from 'pages/private/Common/AssemblePreview/AssemblePreviewDownload';
import { Strategy } from 'pages/private/Common/Strategy';
import StrategyInfo from 'pages/private/Common/Strategy/details';
import { ChangePassword } from 'pages/private/Master/ChangePassword';
import { Dashboard } from 'pages/private/Master/Dashboard';
import { CommentsTable } from 'pages/private/Master/Dashboard/AttorneyDashboard/CommentsTable';
// import { CommentsWidgets } from 'pages/private/Master/Dashboard/AttorneyDashboard/CommentsWidgets';
import PendingReviews from 'pages/private/Master/Dashboard/AttorneyDashboard/PendingReviews';
import Myprofile from 'pages/private/Master/Myprofile';
import AcceptableUsePolicy from 'pages/public/ACCEPTABLEUSEPOLICY';
import DMCAPolicy from 'pages/public/DMCA';
import PrivacyPolicy from 'pages/public/PrivacyPolicy';
import {
  StripeInprocessPayment,
  StripeSucessPayment,
} from 'pages/public/StripePayment/PaymentSucess';
import TermsOfService from 'pages/public/Terms&Service';
import VersionHistory from 'pages/public/VersionHistory';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { BeneficiaryPrivateRoutes, CommonPrivateRoutes } from '../index';

const BeneficiaryRoutes: React.FC<any> = (): React.ReactElement => {
  const {
    DASHBOARD,
    PAYMENT,
    CHANGEPASSWORD,
    DOCUMENTREVIEW,
    MYPROFILE,
    POLICY,
    SOCIALSHARE,
    COMMENTS,
    BENEFICIARYDETAILLIST,
    UPLOADDOCUMENT,
    UPLOADPETITIONSDOCUMENT,
    COMPLETEQUESTIONS,
    BENEFICIARYCONTRACTHISTORY,
    BENEFICIARYLEADCONTRACTHISTORY,
    BENEFICIARYCASEDETAIL,
    BENEFICIARYSTRATEGY,
    BENEFICIARYSTRATEGYDETAIL,
    BENEFICIARYLEADSTRATEGY,
    REVIEWANDAPPROVE,
    BUILDCASEEDIT,
    LEADCOMPLETEQUESTIONS,
    BENEFICIARYPETITIONERSTRATEGYDETAIL,
    SUBMISSIONSTATION,
  } = BeneficiaryPrivateRoutes;
  return (
    <Routes>
      <Route path="/" element={<Navigate to={DASHBOARD} />} />
      <Route
        path={DASHBOARD}
        element={
          <PrivateLayout
            routes={{
              linkShow: true,
              path: DASHBOARD,
              show: false,
              title: 'Dashboard',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Dashboard />
          </PrivateLayout>
        }
      />
      {/* <Route
        path={BENEFICIARYREVIEWDOCUMENT}
        element={
          <PrivateLayout
            routes={{
              linkShow: true,
              path: BENEFICIARYREVIEWDOCUMENT,
              show: false,
              title: 'Review documents',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <BeneficiaryPendingReviews />
          </PrivateLayout>
        }
      /> */}
      <Route
        path={SOCIALSHARE}
        element={
          <PrivateLayout
            routes={{
              linkShow: true,
              path: SOCIALSHARE,
              show: false,
              title: 'Social',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Socialshare />
          </PrivateLayout>
        }
      />
      <Route
        path={COMMENTS}
        element={
          <PrivateLayout
            routes={{
              linkShow: true,
              path: COMMENTS,
              show: false,
              title: 'Comments',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <CommentsTable />
          </PrivateLayout>
        }
      />
      <Route
        path={DOCUMENTREVIEW}
        element={
          <PrivateLayout
            routes={{
              linkShow: true,
              path: DOCUMENTREVIEW,
              show: false,
              title: 'DocumentRevieew',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <PendingReviews />
          </PrivateLayout>
        }
      />
      <Route
        path={MYPROFILE}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              path: MYPROFILE,
              show: true,
              title: 'My Profile',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Myprofile />
          </PrivateLayout>
        }
      />
      <Route
        path={CHANGEPASSWORD}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              path: CHANGEPASSWORD,
              show: true,
              title: 'Change Password',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ChangePassword />
          </PrivateLayout>
        }
      />
      {/* <Route
        path={`${BENEFICIARYDETAILLISTWITHID}/:editId/:leadUserId`}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              path: DASHBOARD,
              show: true,
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <BeneficiaryDetailList />
          </PrivateLayout>
        }
      /> */}
      <Route
        path={`${BENEFICIARYDETAILLIST}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              path: DASHBOARD,
              show: true,
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <BeneficiaryDetailList />
          </PrivateLayout>
        }
      />
      <Route
        path={`${BENEFICIARYCASEDETAIL}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'caseinfo',
              path: BENEFICIARYDETAILLIST,
              show: true,
              title: 'Case Details',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <PetitionCaseDetail notesShowHide={true} />
          </PrivateLayout>
        }
      />
      <Route
        path={`${UPLOADDOCUMENT}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'docupload',
              path: BENEFICIARYDETAILLIST,
              show: true,
              title: 'Upload Documents',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <UploadDocument />
          </PrivateLayout>
        }
      />
      <Route
        path={`${UPLOADPETITIONSDOCUMENT}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'docupload',
              path: BENEFICIARYDETAILLIST,
              show: true,
              title: 'Upload Documents',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <UploadPetitionsDocument />
          </PrivateLayout>
        }
      />
      <Route
        path={`${COMPLETEQUESTIONS}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'question',
              path: BENEFICIARYDETAILLIST,
              show: true,
              title: 'Complete Questions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <CompleteQuestions />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(LEADCOMPLETEQUESTIONS)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'question',
              path: BENEFICIARYDETAILLIST,
              show: true,
              title: 'Complete Questions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <CompleteQuestions />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(BENEFICIARYCONTRACTHISTORY)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'contract',
              path: BENEFICIARYDETAILLIST,
              show: true,
              title: 'Contract & Payment',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <BeneficiaryContractHistory />
          </PrivateLayout>
        }
      />
      <Route
        path={`${BENEFICIARYLEADCONTRACTHISTORY}/:editId`}
        element={
          <PrivateLayout
            routes={{
              // caseTitle: 'Case Menu',
              icon: 'contract',
              path: BENEFICIARYDETAILLIST,
              show: true,
              title: 'Contract & Payment',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <BeneficiaryContractHistory />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(BENEFICIARYLEADSTRATEGY)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              // caseTitle: 'Case Menu',
              icon: 'stratey',
              path: BENEFICIARYDETAILLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <Strategy />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(BENEFICIARYSTRATEGY)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'stratey',
              isParamId: 'petitionId',
              path: BENEFICIARYDETAILLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <Strategy />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(BENEFICIARYSTRATEGYDETAIL)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: '',
              icon: 'stratey',
              path: BENEFICIARYSTRATEGY,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <StrategyInfo />
          </PrivateLayout>
        }
      />

      <Route
        path={`${String(BENEFICIARYPETITIONERSTRATEGYDETAIL)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: '',
              icon: 'stratey',
              path: BENEFICIARYSTRATEGY,
              show: true,
              title: '',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <StrategyInfo />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(SUBMISSIONSTATION)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'submission',
              path: BENEFICIARYDETAILLIST,
              show: true,
              title: 'Submission Station',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <SubmissionStation />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(REVIEWANDAPPROVE)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'stratey',
              path: BENEFICIARYDETAILLIST,
              show: true,
              title: 'Review & Approve',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <ReviewAndApprove />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(BUILDCASEEDIT)}/:petitionId/:templateId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'submission',
              path: BENEFICIARYDETAILLIST,
              show: true,
              title: 'Review & Approve',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <PetitionerOrBeneficiaryBuildCaseEdit />
          </PrivateLayout>
        }
      />
      <Route path={POLICY.PRIVACYPOLICY} element={<PrivacyPolicy />} />
      <Route path={POLICY.DMCAPOLICY} element={<DMCAPolicy />} />
      <Route
        path={POLICY.ACCEPTABLEUSEPOLICY}
        element={<AcceptableUsePolicy />}
      />
      <Route path={POLICY.TERMOFSERVICES} element={<TermsOfService />} />
      <Route path={POLICY.VERSIONHISTORY} element={<VersionHistory />} />
      <Route
        path={PAYMENT.SECUREPAYMENT}
        element={
          <PublicLayout>
            <PaymentDetail />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.PAYMENTTERMSANDCONDITIONS}
        element={
          <PublicLayout>
            <PaymentMethodAndTerms />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.CREDITDEBITCARD}
        element={
          <PublicLayout>
            <CreditDebitPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.ACH}
        element={
          <PublicLayout>
            <AchPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.CONTRACTVIEWER}
        element={
          <PublicLayout>
            <ContractViewer />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.STRIPESUCCESS}
        element={
          <PublicLayout>
            <StripeSucessPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.STRIPEINPROCESS}
        element={
          <PublicLayout>
            <StripeInprocessPayment />
          </PublicLayout>
        }
      />
      <Route
        path={`${CommonPrivateRoutes.ASSEMBLEPREVIEW}`}
        element={
          <PrivateLayout
            routes={{
              // path: DOCUMENTI129PREVIEWFOREXTRACTIONID,
              show: false,
              // title: 'Report',
            }}
            showTools={false}
            showHeader={false}
            DefaultHeaderHide={true}>
            <AssemblePreviewDownload />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(CommonPrivateRoutes.SUBMISSIONSTATION)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              path: DASHBOARD,
              show: true,
              title: 'Submission Station',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <SubmissionStation />
          </PrivateLayout>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default BeneficiaryRoutes;
