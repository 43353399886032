import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Link, Typography } from '@mui/material';
import { Footer } from 'components/Footer';
import { InputText } from 'components/Forms/InputText';
import { OnlineVisaVideo } from 'components/Onlinevisavideo';
import { UseFocus, useMountEffect } from 'hooks';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData } from 'services/CommonServices';
import { ForgotSubmitForm } from 'types';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { forgotSchema } from 'utils/ValidatorSchema';

const ForgotPassword: React.FC = () => {
  const [inputFocus, setInputFocus] = UseFocus();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const {
    control: forgotControl,
    handleSubmit,
    formState: { errors: forgotErrors },
    reset,
  } = useForm<ForgotSubmitForm>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(forgotSchema),
  });

  const submitHandler = ({ email }: ForgotSubmitForm) => {
    setLoader(true);
    addNewData({ email: email }, MASTER_ENDPOINT.ForgotPassword)
      .then((resp: any) => {
        setLoader(false);
        reset();
        navigate('/login');
        toast.success(
          'Reset password email has been sent to your registered email address',
          alertOptions,
        );
      })
      .catch((e) => {
        setLoader(false);
        // toast.error('Oops!!! Something went wrong', alertOptions);
      });
  };

  useMountEffect(setInputFocus);

  return (
    <Grid container>
      <Grid
        item
        md={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '100vh',
        }}>
        <OnlineVisaVideo />
        <div className="login login-temp temp">
          <h2 style={{ color: '#fff', marginBottom: '0.5rem' }}>
            Reset Password
          </h2>
          <Typography component={'div'} className="top-text">
            {
              "Enter your email address and we'll email you a link to reset your password"
            }
          </Typography>
          <div>
            <form className="formheight" onSubmit={handleSubmit(submitHandler)}>
              <Typography style={{ marginBottom: '.4rem' }}>
                <label className="label-text">
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
              </Typography>
              <Typography component={'div'} style={{ marginBottom: '.5rem' }}>
                <Controller
                  control={forgotControl}
                  defaultValue=""
                  name="email"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={inputFocus}
                        errors={forgotErrors.email?.message || ''}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(e) => {
                          const vals = e.replace(/\s/g, '');
                          return onChange(vals);
                        }}
                        isEditable={false}
                        type={'text'}
                        variant="filled"
                        className="l-input"
                        placeholder="Your email address"
                      />
                    );
                  }}
                />
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
                disabled={loader}>
                Send Email
              </Button>
            </form>
            <Typography component={'div'} className="d-flex-ja">
              <Link
                sx={{
                  color: '#fff',
                  margin: '1rem 0',
                  textDecoration: 'underline solid #fff',
                }}
                underline="hover"
                href="#"
                onClick={() => navigate('/login')}>
                Back to Login
              </Link>
            </Typography>
            <div className="fotter">
              <Typography className="s-text">
                For technical assistance, please email{' '}
                <a
                  href={`mailto:${String(process.env.REACT_APP_CONTACT_MAIL)}`}>
                  {String(process.env.REACT_APP_CONTACT_MAIL)}
                </a>
              </Typography>
            </div>
          </div>
        </div>
        <div className="stick-footer">
          <Footer />
        </div>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
