import Grid from '@mui/material/Grid';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomModalView from 'components/CustomModalView/customModalView';
import { AttorneyPrivateRoutes } from 'config';
import moment from 'moment';
// import moment from 'moment';
import { SectionList } from 'pages/private/Common/SectionList';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { STRATEGY_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import ListData from './List';

export const OraganizationStrategy: React.FC<any> = () => {
  const { editId } = useParams();
  const navigate = useNavigate();
  const location: any = useLocation();

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const [strategyList, setStrategyList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [sectionSearch, setSectionSearch] = useState('');
  const [chooseSection, setChooseSection] = useState<{
    open: boolean;
    scheduleData: any | null;
  }>({
    open: false,
    scheduleData: null,
  });
  const [confirmOpen, setConfirmOpen] = useState<{
    strategyId: string | null;
    index: number | null;
    show: boolean;
    message: string;
    title: string;
    delete: boolean;
  }>({
    delete: true,
    index: null,
    message: '',
    show: false,
    strategyId: null,
    title: '',
  });

  const selectSections = (sectionsInfo: any) => {
    openWizardFormAnswer(sectionsInfo.id, true);
  };

  const getFormData = (formId) => {
    const startDateAndTime = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
    const endDateAndTime = moment()
      .add(30, 'minutes')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS');
    return {
      endDate: endDateAndTime,
      firmId: userDetail.companyId,
      formId: Number(formId),
      notes: '',
      organizationAssociated: location?.pathname.includes('invited')
        ? false
        : true,
      organizationId: editId,
      startDate: startDateAndTime,
    };
  };

  const openWizardFormAnswer = async (
    formId: string,
    isCallStrategyCreation: boolean,
    strategyId?: string,
  ) => {
    const data: any = {};
    if (isCallStrategyCreation) {
      const createSchedule = await getFormData(formId);
      try {
        const scheduleData = await addNewData(
          createSchedule,
          STRATEGY_ENDPOINT.OrganizationStrategy,
        );
        if (scheduleData && scheduleData.data) {
          data.form_id = scheduleData.data.form_id;
          data.strategy_id = scheduleData.data.id;
        }
        navigate(
          location?.pathname.includes('invited')
            ? `${AttorneyPrivateRoutes.MANAGEORGANIZATION.ORGANIZATIONINVITED.ORGANIZATIONINVITEDSTRATEGYDETAIL}/${editId}/${formId}`
            : `${AttorneyPrivateRoutes.MANAGEORGANIZATION.ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCITEDSTRATEGYDETAIL}/${editId}/${formId}`,
          {
            state: {
              data: {
                ...data,
              },
              orgId: editId,
              organization: true,
            },
          },
        );
      } catch (error) {
        setLoader(false);
      }
    } else {
      data.form_id = formId;
      data.strategy_id = strategyId;
      navigate(
        location?.pathname.includes('invited')
          ? `${AttorneyPrivateRoutes.MANAGEORGANIZATION.ORGANIZATIONINVITED.ORGANIZATIONINVITEDSTRATEGYDETAIL}/${editId}/${formId}`
          : `${AttorneyPrivateRoutes.MANAGEORGANIZATION.ORGANIZATIONASSOCIATED.ORGANIZATIONASSOCITEDSTRATEGYDETAIL}/${editId}/${formId}`,
        {
          state: {
            data: {
              ...data,
            },
            orgId: editId,
            organization: true,
          },
        },
      );
    }
  };
  const createScheduleFeedback = (scheduleData: any) => {
    if (scheduleData.form_id) {
      openWizardFormAnswer(scheduleData.form_id, false, scheduleData.id);
    } else {
      setChooseSection((pre) => ({
        ...pre,
        open: true,
        scheduleData: scheduleData,
      }));
    }
  };
  const cancelConfirmation = () => {
    setConfirmOpen((pre) => ({
      ...pre,
      delete: false,
      index: null,
      show: false,
      strategyId: null,
    }));
  };
  const createFeedback = () => {
    setChooseSection((pre) => ({
      ...pre,
      open: true,
    }));
    setSectionSearch('');
  };

  const scheduleNow = () => {
    // setConfirmOpen((pre) => ({
    //   ...pre,
    //   delete: false,
    //   index: null,
    //   message:
    //     'Do you wish to schedule an appointment and start the session now?',
    //   show: true,
    //   strategyId: null,
    //   title: 'CONFIRM SCHEDULE CREATION',
    // }));
    createFeedback();
  };
  const deleteStrategy = async (id: string) => {
    try {
      setLoader(true);

      await deleteData(id, STRATEGY_ENDPOINT.strategyOrganizationDelete)
        .then(() => {
          cancelConfirmation();
          getStrategyList();
          toast.success('Deleted successfully', alertOptions);
        })
        .catch((err) => {
          toast.error(err, alertOptions);
        });
    } catch (error) {
      setLoader(false);
      toast.error('Failed to delete', alertOptions);
    }
  };

  const getStrategyList = useCallback(async () => {
    try {
      const strategyList = await getAllListData(
        `${
          STRATEGY_ENDPOINT.OrganizationStrategy
        }?organizationId=${editId}&associated=${
          location?.pathname.includes('invited') ? 'false' : 'true'
        }&skip=0&take=50&order=DESC&sortby=created_at&sectionName=`,
      );
      setStrategyList(strategyList);
      setLoader(false);
      return strategyList;
    } catch (error) {
      setLoader(false);
      toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
  }, [editId, location?.pathname]);

  useEffect(() => {
    getStrategyList();
  }, [editId, getStrategyList]);

  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={9} sm={12}>
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <ListData
            listData={strategyList}
            createScheduleFeedback={createScheduleFeedback}
            setConfirmOpen={setConfirmOpen}
            scheduleNow={scheduleNow}
            headerShow={false}
          />
        )}

        <ConfirmDialog
          title={confirmOpen?.title}
          floatingBtnShow={true}
          open={confirmOpen.show}
          onConfirm={(act) =>
            act === 'yes'
              ? confirmOpen.delete && deleteStrategy(confirmOpen.strategyId)
              : cancelConfirmation()
          }
          autoCloseDisable={true}>
          {confirmOpen?.message}
        </ConfirmDialog>

        {chooseSection.open && (
          <CustomModalView
            open={true}
            fullWidth={true}
            maxWidth={'xl'}
            scroll={'paper'}
            autoCloseDisable={true}
            isFilters={true}
            title="Add Section"
            filterOnChange={(e) => setSectionSearch(e)}
            close={() =>
              setChooseSection((pre) => ({
                ...pre,
                open: false,
              }))
            }>
            <SectionList
              sectionType={'strategy'}
              searchValue={sectionSearch}
              onSelect={selectSections}
              petitionData=""
              attorneyAssigned={null}
            />
          </CustomModalView>
        )}
      </Grid>
    </Grid>
  );
};

export default OraganizationStrategy;
