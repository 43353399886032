import PrivateLayout from 'components/PrivateLayout';
import PublicLayout from 'components/PublicLayout';
import LeadsList from 'pages/private/Client/Leads';
import LeadAdd from 'pages/private/Client/Leads/AttorneyLeads';
import ContractPayment from 'pages/private/Client/Leads/ContractPayment';
import ContractViewer from 'pages/private/Client/Leads/ContractPayment/ContractViewer';
import ContractPaymentDetails from 'pages/private/Client/Leads/ContractPayment/Details';
import PaymentDetail from 'pages/private/Client/Leads/ContractPayment/PaymentDetail';
import AchPayment from 'pages/private/Client/Leads/ContractPayment/PaymentMethod/ACHpayment';
import CreditDebitPayment from 'pages/private/Client/Leads/ContractPayment/PaymentMethod/CreditDebitCard';
import PaymentMethodAndTerms from 'pages/private/Client/Leads/ContractPayment/PaymentMethodAndTerms';
import LeadsDetailList from 'pages/private/Client/Leads/DetailsList';
import LeadsToPetition from 'pages/private/Client/Leads/LeadToPetition';
import PetitionerLeadAdd from 'pages/private/Client/Leads/petitionerLeads';
import UploadDocument from 'pages/private/Client/Leads/UploadDocument';
import PetitionsList from 'pages/private/Client/Petitions';
import PetitionCaseDetail from 'pages/private/Client/Petitions/CaseDetail';
import CompleteQuestions from 'pages/private/Client/Petitions/CompleteQuestion';
import Contractpayment from 'pages/private/Client/Petitions/ContractPayment';
import PetitionDetailList from 'pages/private/Client/Petitions/DetailsList';
import PetitionBuildCase from 'pages/private/Client/Petitions/PetitionBuildCase';
import AcceptOrReject from 'pages/private/Client/Petitions/PetitionBuildCase/AcceptOrReject';
import AssembleDragDrop from 'pages/private/Client/Petitions/PetitionBuildCase/Assemble/AssembleDragDrop';
import BuildCaseEdit from 'pages/private/Client/Petitions/PetitionBuildCase/Edit';
import PetitionerOrBeneficiaryBuildCaseEdit from 'pages/private/Client/Petitions/PetitionBuildCase/Edit/PetitionerOrBeneficiaryEdit';
import ReviewAndApprove from 'pages/private/Client/Petitions/PetitionBuildCase/ReviewAndApprove';
import SubmissionStation from 'pages/private/Client/Petitions/PetitionBuildCase/SubmissionStation';
import UploadPetitionsDocument from 'pages/private/Client/Petitions/UploadPetitionsDocument';
import Strategy from 'pages/private/Common/Strategy';
import StrategyInfo from 'pages/private/Common/Strategy/details';
import { ChangePassword } from 'pages/private/Master/ChangePassword';
// import { Dashboard } from 'pages/private/Master/Dashboard';
import Myprofile from 'pages/private/Master/Myprofile';
import AcceptableUsePolicy from 'pages/public/ACCEPTABLEUSEPOLICY';
import DMCAPolicy from 'pages/public/DMCA';
import PrivacyPolicy from 'pages/public/PrivacyPolicy';
import {
  StripeInprocessPayment,
  StripeSucessPayment,
} from 'pages/public/StripePayment/PaymentSucess';
import TermsOfService from 'pages/public/Terms&Service';
import VersionHistory from 'pages/public/VersionHistory';
import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AffilatePrivateRoutes } from '../index';

const AffilateRoutes: React.FC = (): React.ReactElement => {
  const {
    ATTORNEY_PETITIONS,
    BENEFICIARYROUTE,
    // DASHBOARD,
    CHANGEPASSWORD,
    MYPROFILE,
    POLICY,
    ATTORNEY_LEADS,
    PETITIONER_LEADS,
    PETITIONER_PETITIONS,
    PAYMENT,
  } = AffilatePrivateRoutes;
  const [submissionPopup, setSubmissionPopup] = useState(false);
  return (
    <Routes>
      <Route path="/" element={<Navigate to={ATTORNEY_LEADS.LEADSLIST} />} />
      {/* <Route
        path={DASHBOARD}
        element={
          <PrivateLayout
            routes={{
              linkShow: true,
              path: DASHBOARD,
              show: false,
              title: 'Dashboard',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Dashboard />
          </PrivateLayout>
        }
      /> */}

      <Route
        path={MYPROFILE}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              path: MYPROFILE,
              show: true,
              title: 'My Profile',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Myprofile />
          </PrivateLayout>
        }
      />
      <Route
        path={CHANGEPASSWORD}
        element={
          <PrivateLayout
            routes={{
              linkShow: false,
              path: CHANGEPASSWORD,
              show: true,
              title: 'Change Password',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ChangePassword />
          </PrivateLayout>
        }
      />

      <Route
        path={ATTORNEY_LEADS.LEADSLIST}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'leads',
              linkShow: true,
              path: ATTORNEY_LEADS.LEADSLIST,
              show: false,
              title: 'Leads',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <LeadsList />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(ATTORNEY_LEADS.LEADDETAILSLIST)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              icon: 'leads',
              navigate: true,
              path: ATTORNEY_LEADS.LEADSLIST,
              show: true,
              title: 'Leads',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <LeadsDetailList />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(ATTORNEY_LEADS.LEADDETAILSLIST)}/:editId/petition`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              navigate: true,
              path: ATTORNEY_LEADS.LEADDETAILSLIST,
              show: true,
              title: 'petitions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <LeadsToPetition />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(ATTORNEY_LEADS.LEADSEDIT)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'caseinfo',
              isParamId: true,
              navigate: true,
              path: ATTORNEY_LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Lead Details',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <LeadAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(ATTORNEY_LEADS.UPLOADDOCUMENT)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'docupload',
              isParamId: true,
              navigate: true,
              path: ATTORNEY_LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Upload Documents',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <UploadDocument />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(ATTORNEY_LEADS.STRATEGY)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'stratey',
              isParamId: true,
              navigate: true,
              path: ATTORNEY_LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Strategy />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(ATTORNEY_LEADS.STRATEGYDETAIL)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'stratey',
              path: ATTORNEY_LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <StrategyInfo />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(ATTORNEY_LEADS.CONTRACTPAYMENT)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'contract',
              isParamId: true,
              navigate: true,
              path: ATTORNEY_LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Contract & Payments',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ContractPayment />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(
          ATTORNEY_LEADS.CONTRACTPAYMENTDETAILS,
        )}/:editId/:contractId/:userId/:contractType`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'contract',
              isParamId: true,
              navigate: true,
              path: ATTORNEY_LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Contract & Payment',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ContractPaymentDetails />
          </PrivateLayout>
        }
      />

      <Route
        path={ATTORNEY_PETITIONS.MYPETITIONLISTS}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'leads',
              linkShow: true,
              path: ATTORNEY_PETITIONS.MYPETITIONLISTS,
              show: false,
              title: 'Petitions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <PetitionsList />
          </PrivateLayout>
        }
      />

      <Route
        path={`${String(ATTORNEY_PETITIONS.PETITIONLIST)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              icon: 'petitions',
              navigate: true,
              path: ATTORNEY_PETITIONS.MYPETITIONLISTS,
              show: true,
              title: 'Petitions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={'all'}>
            <PetitionDetailList />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(ATTORNEY_PETITIONS.PETITIONCASEDETAIL)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'caseinfo',
              isParamId: true,
              navigate: true,
              path: ATTORNEY_PETITIONS.PETITIONLIST,
              show: true,
              title: 'Case Details',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <PetitionCaseDetail />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(
          ATTORNEY_PETITIONS.UPLOADPETITIONSDOCUMENT,
        )}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'docupload',
              isParamId: true,
              navigate: true,
              path: ATTORNEY_PETITIONS.PETITIONLIST,
              show: true,
              title: 'Upload Documents',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <UploadPetitionsDocument />
          </PrivateLayout>
        }
      />

      <Route
        path={`${String(ATTORNEY_PETITIONS.STRATEGY)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'stratey',
              isParamId: 'petitionId',
              navigate: true,
              path: ATTORNEY_PETITIONS.PETITIONLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={false}>
            <Strategy />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(ATTORNEY_PETITIONS.STRATEGYDETAIL)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'stratey',
              isParamId: 'petitionId',
              navigate: true,
              path: ATTORNEY_PETITIONS.PETITIONLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <StrategyInfo />
          </PrivateLayout>
        }
      />

      <Route
        path={`${String(ATTORNEY_PETITIONS.COMPLETEQUESTIONS)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'question',
              isParamId: true,
              navigate: true,
              path: ATTORNEY_PETITIONS.PETITIONLIST,
              show: true,
              title: 'Complete Questions',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <CompleteQuestions />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(ATTORNEY_PETITIONS.CONTRACTPAYMENT)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'contract',
              isParamId: true,
              navigate: true,
              path: ATTORNEY_PETITIONS.PETITIONLIST,
              show: true,
              title: 'Contract & Payments',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <Contractpayment />
          </PrivateLayout>
        }
      />

      <Route
        path={`${String(ATTORNEY_PETITIONS.BUILDCASE)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'question',
              isParamId: true,
              navigate: false,
              path: ATTORNEY_PETITIONS.PETITIONLIST,
              show: true,
              title: 'Build Case',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <PetitionBuildCase />
          </PrivateLayout>
        }
      />

      <Route
        path={`${String(
          ATTORNEY_PETITIONS.BUILDCASEEDIT,
        )}/:petitionId/:templateId`}
        element={
          <PrivateLayout
            routes={{
              // caseTitle: 'Case Menu',
              icon: 'question',
              isParamId: true,
              navigate: true,
              path: ATTORNEY_PETITIONS.BUILDCASE,
              show: true,
              title: 'Build case',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <BuildCaseEdit />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(
          ATTORNEY_PETITIONS.ACCEPTORREJECT,
        )}/:petitionId/:templateId/:buildId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'question',
              isParamId: true,
              navigate: true,
              path: ATTORNEY_PETITIONS.PETITIONLIST,
              show: true,
              title: 'Accept or Reject',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <AcceptOrReject />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(ATTORNEY_PETITIONS.ASSEMBLE)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'assemble',
              path: ATTORNEY_PETITIONS.PETITIONLIST,
              show: true,
              title: 'Assemble',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <AssembleDragDrop />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(ATTORNEY_PETITIONS.SUBMISSIONSTATION)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'submission',
              path: ATTORNEY_PETITIONS.PETITIONLIST,
              show: true,
              title: 'Submission Station',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}
            setSubmissionPopup={setSubmissionPopup}>
            <SubmissionStation
              submissionPopup={submissionPopup}
              setSubmissionPopup={setSubmissionPopup}
            />
          </PrivateLayout>
        }
      />

      <Route
        path={PETITIONER_LEADS.LEADSLIST}
        element={
          <PrivateLayout
            routes={{
              isDescription: 'leads',
              linkShow: true,
              path: PETITIONER_LEADS.LEADSLIST,
              show: false,
              title: 'Leads',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <LeadsList />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(PETITIONER_LEADS.LEADDETAILSLIST)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              icon: 'leads',
              navigate: true,
              path: PETITIONER_LEADS.LEADSLIST,
              show: true,
              title: 'Leads',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <LeadsDetailList />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(PETITIONER_LEADS.LEADSEDIT)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Lead Menu',
              icon: 'casemenu',
              isParamId: true,
              navigate: true,
              path: PETITIONER_LEADS.LEADDETAILSLIST,
              show: true,
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <PetitionerLeadAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(PETITIONER_LEADS.CONTRACTPAYMENT)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'contract',
              isParamId: true,
              navigate: true,
              path: PETITIONER_LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Contract & Payments',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ContractPayment />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(
          PETITIONER_LEADS.CONTRACTPAYMENTDETAILS,
        )}/:editId/:contractId/:userId/:contractType`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'contract',
              isParamId: true,
              navigate: true,
              path: PETITIONER_LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Contract & Payment',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <ContractPaymentDetails />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(PETITIONER_LEADS.UPLOADDOCUMENT)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'docupload',
              isParamId: true,
              navigate: true,
              path: PETITIONER_LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Upload Documents',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={''}>
            <UploadDocument />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(PETITIONER_LEADS.STRATEGY)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'stratey',
              isParamId: true,
              navigate: true,
              path: PETITIONER_LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <Strategy />
          </PrivateLayout>
        }
      />
      <Route
        path={`${String(PETITIONER_LEADS.STRATEGYDETAIL)}/:editId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'stratey',
              path: PETITIONER_LEADS.LEADDETAILSLIST,
              show: true,
              title: 'Strategy',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}>
            <StrategyInfo />
          </PrivateLayout>
        }
      />

      <Route
        path={`${String(PETITIONER_PETITIONS.REVIEWANDAPPROVE)}/:petitionId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'submission',
              isParamId: true,
              linkShow: false,
              navigate: true,
              path: PETITIONER_PETITIONS.PETITIONLIST,
              show: true,
              title: 'Review & Approve',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <ReviewAndApprove />
          </PrivateLayout>
        }
      />

      <Route
        path={`${String(
          BENEFICIARYROUTE.BUILDCASEEDIT,
        )}/:petitionId/:templateId`}
        element={
          <PrivateLayout
            routes={{
              caseTitle: 'Case Menu',
              icon: 'submission',
              path: BENEFICIARYROUTE.BENEFICIARYDETAILLIST,
              show: true,
              title: 'Review & Approve',
            }}
            showTools={false}
            showHeader={true}
            hasComponent={'no'}
            isPetition={true}>
            <PetitionerOrBeneficiaryBuildCaseEdit />
          </PrivateLayout>
        }
      />

      <Route path={POLICY.PRIVACYPOLICY} element={<PrivacyPolicy />} />

      <Route path={POLICY.DMCAPOLICY} element={<DMCAPolicy />} />
      <Route
        path={POLICY.ACCEPTABLEUSEPOLICY}
        element={<AcceptableUsePolicy />}
      />
      <Route path={POLICY.TERMOFSERVICES} element={<TermsOfService />} />
      <Route path={POLICY.VERSIONHISTORY} element={<VersionHistory />} />

      <Route
        path={PAYMENT.SECUREPAYMENT}
        element={
          <PublicLayout>
            <PaymentDetail />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.PAYMENTTERMSANDCONDITIONS}
        element={
          <PublicLayout>
            <PaymentMethodAndTerms />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.CREDITDEBITCARD}
        element={
          <PublicLayout>
            <CreditDebitPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.ACH}
        element={
          <PublicLayout>
            <AchPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.CONTRACTVIEWER}
        element={
          <PublicLayout>
            <ContractViewer />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.STRIPESUCCESS}
        element={
          <PublicLayout>
            <StripeSucessPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.STRIPEINPROCESS}
        element={
          <PublicLayout>
            <StripeInprocessPayment />
          </PublicLayout>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AffilateRoutes;
