import PublicLayout from 'components/PublicLayout';
import ContractViewer from 'pages/private/Client/Leads/ContractPayment/ContractViewer';
import PaymentDetail from 'pages/private/Client/Leads/ContractPayment/PaymentDetail';
import AchPayment from 'pages/private/Client/Leads/ContractPayment/PaymentMethod/ACHpayment';
import CreditDebitPayment from 'pages/private/Client/Leads/ContractPayment/PaymentMethod/CreditDebitCard';
import PaymentMethodAndTerms from 'pages/private/Client/Leads/ContractPayment/PaymentMethodAndTerms';
// import ManageLawFirmAddAndEdit from 'pages/private/Setup/ManageLawFirm/Add';
import { ManagePetitionerAddAndEdit } from 'pages/private/Setup/ManagePetitioners/Add';
import AcceptableUsePolicy from 'pages/public/ACCEPTABLEUSEPOLICY';
import CreatePassword from 'pages/public/CreatePassword';
import DMCAPolicy from 'pages/public/DMCA';
import ForgotPassword from 'pages/public/ForgotPassword';
import Login from 'pages/public/Login';
import { RegistrationForm } from 'pages/public/RegistrationForm/index';
import SignUp from 'pages/public/SignUp';
import {
  StripeInprocessPayment,
  StripeSucessPayment,
} from 'pages/public/StripePayment/PaymentSucess';
import BillingDetails from 'pages/public/Subscription-plan/BillingDetails';
import CardForm from 'pages/public/Subscription-plan/CardForm';
import PlanSignupForm from 'pages/public/Subscription-plan/PlanSignupForm';
import Pricing from 'pages/public/Subscription-plan/Pricing';
import SubscriptionFailure from 'pages/public/Subscription-plan/SubscriptionFailure';
import SubscriptionSuccess from 'pages/public/Subscription-plan/SubscriptionSuccess';
import TermsOfService from 'pages/public/Terms&Service';
import VersionHistory from 'pages/public/VersionHistory';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { PublicRoutes as PublicRoutesDirectives } from '../index';
import PrivacyPolicy from './../../pages/public/PrivacyPolicy/index';

const PublicRoutes: React.FC = () => {
  const {
    LOGIN,
    ORGANIZATION,
    // LAWFIRM,
    FORGOTPASSWORD,
    CREATEPASSWORD,
    REGISTRATIONFORM,
    PAYMENT,
    POLICY,
    SIGNUP,
    STRIPE,
  } = PublicRoutesDirectives;
  const location = useLocation();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate
            to={'/login'}
            replace
            state={{
              from:
                location?.pathname?.split('/').pop() !== 'change-password'
                  ? location
                  : '',
            }}
          />
        }
      />
      <Route path={LOGIN} element={<Login />} />
      <Route
        path={ORGANIZATION}
        element={
          <PublicLayout>
            <ManagePetitionerAddAndEdit from={'signup'} />
          </PublicLayout>
        }
      />
      {/* <Route
        path={LAWFIRM}
        element={
          <PublicLayout>
            <ManageLawFirmAddAndEdit from={'signup'} />
          </PublicLayout>
        }
      /> */}
      <Route
        path={REGISTRATIONFORM}
        element={
          <PublicLayout>
            <RegistrationForm />
          </PublicLayout>
        }
      />
      <Route path={FORGOTPASSWORD} element={<ForgotPassword />} />
      <Route path={CREATEPASSWORD} element={<CreatePassword />} />
      <Route path={SIGNUP} element={<SignUp />} />
      <Route
        path={PAYMENT.SECUREPAYMENT}
        element={
          <PublicLayout>
            <PaymentDetail />
          </PublicLayout>
        }
      />
      <Route path={STRIPE.PRICING} element={<Pricing />} />
      <Route path={STRIPE.SIGNUP} element={<PlanSignupForm />} />
      <Route path={STRIPE.CARDFORM} element={<CardForm />} />
      <Route path={STRIPE.SUCCESS} element={<SubscriptionSuccess />} />
      <Route path={STRIPE.FAILURE} element={<SubscriptionFailure />} />
      <Route path={STRIPE.BILLINGDETAILS} element={<BillingDetails />} />

      <Route
        path={PAYMENT.PAYMENTTERMSANDCONDITIONS}
        element={
          <PublicLayout>
            <PaymentMethodAndTerms />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.CREDITDEBITCARD}
        element={
          <PublicLayout>
            <CreditDebitPayment />
          </PublicLayout>
        }
      />
      <Route
        path={PAYMENT.ACH}
        element={
          <PublicLayout>
            <AchPayment />
          </PublicLayout>
        }
      />
      <Route path={PAYMENT.STRIPESUCCESS} element={<StripeSucessPayment />} />
      <Route
        path={PAYMENT.STRIPEINPROCESS}
        element={<StripeInprocessPayment />}
      />

      <Route path={POLICY.PRIVACYPOLICY} element={<PrivacyPolicy />} />

      <Route path={POLICY.DMCAPOLICY} element={<DMCAPolicy />} />
      <Route
        path={POLICY.ACCEPTABLEUSEPOLICY}
        element={<AcceptableUsePolicy />}
      />
      <Route path={POLICY.TERMOFSERVICES} element={<TermsOfService />} />
      <Route path={POLICY.VERSIONHISTORY} element={<VersionHistory />} />
      <Route
        path={PAYMENT.CONTRACTVIEWER}
        element={
          <PublicLayout>
            <ContractViewer />
          </PublicLayout>
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to={'/login'}
            replace
            state={{
              from:
                location?.pathname?.split('/').pop() !== 'change-password'
                  ? location
                  : '',
            }}
          />
        }
      />
    </Routes>
  );
};

export default PublicRoutes;
