import Button from '@mui/material/Button';
import React from 'react';

type UploadPetitionsDocumentTableDisplays = {
  data: any;
  openRequiredDocument?: (src: any) => void;
  openRequiredAddDocument?: (src: any) => void;
};

export const UploadPetitionsDocumentTableDisplay: React.FC<
  UploadPetitionsDocumentTableDisplays
> = ({ data, openRequiredDocument, openRequiredAddDocument }) => {
  return (
    <tr>
      <td>{data?.organization ? data?.organization : '-'}</td>
      <td>{data?.benificiery_name ? data?.benificiery_name : '-'}</td>
      <td>{data?.visa_type ? data?.visa_type : '-'}</td>
      <td>{data?.status ? data?.status : '-'}</td>
      <td>
        <Button
          className="n-add-btn"
          sx={{
            borderRadius: '5px !important',
            fontFamily: 'Arial-Regular !important',
            fontSize: '0.9em !important',
            height: '35px !important',
            width: '149px !important',
          }}
          onClick={(e) => openRequiredDocument(data)}>
          Select
        </Button>
      </td>
      <td>
        <Button
          className="n-add-btn"
          sx={{
            borderRadius: '5px !important',
            fontFamily: 'Arial-Regular !important',
            fontSize: '0.9em !important',
            height: '35px !important',
            width: '149px !important',
          }}
          onClick={(e) => openRequiredAddDocument(data)}>
          Select
        </Button>
      </td>
    </tr>
  );
};

export default UploadPetitionsDocumentTableDisplay;
