import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { updateData } from 'services/CommonServices';
import { SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

export const DocumentSectionList: React.FC<any> = (props) => {
  const { petitionId } = useParams();
  const { documenSectionInfo, petitionData, closeSectionListModel } = props;
  const [sections, setSections] = useState({
    data: [],
    documentSelectedList: [],
  });
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
  });
  const [loader, setLoader] = useState(true);
  const getApiList = useCallback(async () => {
    try {
      if (documenSectionInfo?.selectedData) {
        const { selectedData } = documenSectionInfo;
        const list = await getAllListData(
          `${String(SETUP_ENDPOINT.CopyPetitionerData)}/${String(
            selectedData?.petitionid,
          )}`,
        );
        setLoader(false);
        if (list) {
          const tempDocumentList = [];
          list.map((val) => {
            tempDocumentList.push({
              currentDocumentId: val.currentDocumentId,
              documentPath: val.document_path,
              mimeType: val.mimeType,
              originalName: val.original_name,
            });
          });
          setSections((pre) => ({
            ...pre,
            data: [...(list ? list : [])],
            documentSelectedList: [
              ...(tempDocumentList ? tempDocumentList : []),
            ],
          }));
        }
      }
    } catch (error) {
      setLoader(false);
      // toast.error(error ? error : 'Oops!!! Something went wrong', alertOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const submitCopyDocument = () => {
    if (sections.documentSelectedList.length) {
      setConfirmOpen((pre) => ({
        ...pre,
        show: true,
      }));
      return;
    } else {
      toast.error('Please select a document', alertOptions);
    }
  };

  const setDocumentChecked = (doc) => {
    return sections.documentSelectedList.some(
      (val) => val.currentDocumentId === doc.currentDocumentId,
    );
  };
  const removeOrAddDoc = (event, data) => {
    const index = sections.documentSelectedList.findIndex(
      (doc) => doc.currentDocumentId === data.currentDocumentId,
    );
    if (index === -1 && event.target.checked) {
      sections.documentSelectedList.push({
        currentDocumentId: data.currentDocumentId,
        documentPath: data.document_path,
        mimeType: data.mimeType,
        originalName: data.original_name,
      });
    }
    if (index !== -1 && !event.target.checked) {
      sections.documentSelectedList.splice(index, 1);
    }
    setSections((pre) => ({
      ...pre,
      documentSelectedList: [...sections.documentSelectedList],
    }));
  };
  const onApprove = (action: string) => {
    if (action === 'yes') {
      setConfirmOpen((pre) => ({
        ...pre,
        show: false,
      }));
      sendCopyDocument();
    }
  };
  const getDocFormData = () => {
    return {
      organizationId: petitionData.organization_id,
      roleId: 2,
      selectedDocuments: sections.documentSelectedList,
    };
  };
  const sendCopyDocument = () => {
    const bodyObj = getDocFormData();
    setLoader(true);
    updateData(petitionId, bodyObj, SETUP_ENDPOINT.CopyPetitionerData).then(
      (resp: any) => {
        if (resp && resp.status === 200) {
          closeSectionListModel();
          toast.success('Documents submited successfully.', alertOptions);
        } else {
          setLoader(false);
          toast.error('Oops!!! Something went wrong', alertOptions);
        }
      },
    );
  };
  const enableDisable = (event, i, allChange?) => {
    const { checked } = event.target;
    if (allChange) {
      sections.documentSelectedList = [];
      if (checked) {
        sections.data.map((data) => {
          sections.documentSelectedList.push({
            currentDocumentId: data.currentDocumentId,
            documentPath: data.document_path,
            mimeType: data.mimeType,
            originalName: data.original_name,
          });
        });
      }
      setSections((pre) => ({
        ...pre,
        documentSelectedList: [
          ...(sections?.documentSelectedList
            ? sections.documentSelectedList
            : []),
        ],
      }));
    }
  };
  useEffect(() => {
    setLoader(true);
    getApiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documenSectionInfo]);

  return (
    <DialogContent>
      {loader && <div className="liberty-loader" />}
      <table className="lead-table table-hover">
        <thead>
          <tr>
            <th scope="col">DOCUMENT TYPE</th>
            <th scope="col">FILE / DOCUMENT NAME</th>
            <th scope="col">
              ACTION
              <Checkbox
                color={'primary'}
                size={'medium'}
                checked={
                  sections?.data?.length ===
                  sections?.documentSelectedList?.length
                    ? true
                    : false
                }
                onChange={(e) => enableDisable(e, '', 'all')}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {sections && sections.data.length > 0 ? (
            sections.data.map((data, index) => (
              <tr key={index}>
                <td>
                  <a style={{ color: '#1093df' }}>{data.document_type}</a>
                </td>
                <td style={{ textTransform: 'capitalize' }}>
                  {data.original_name}
                </td>
                <td>
                  <Checkbox
                    size={'medium'}
                    checked={setDocumentChecked(data)}
                    onChange={(e) => removeOrAddDoc(e, data)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className={'text-center'}>
              <th scope="row" colSpan={4}>
                No Record(s) Found.
              </th>
            </tr>
          )}
        </tbody>
      </table>
      <DialogActions>
        {sections?.documentSelectedList?.length > 0 && (
          <Typography className={'text-right'}>
            <Button
              className="n-add-btn mt-1"
              sx={{
                height: '40px !important',
              }}
              onClick={() => submitCopyDocument()}>
              Copy Documents
            </Button>
          </Typography>
        )}
      </DialogActions>
      <ConfirmDialog
        title="CONFIRM"
        floatingBtnShow={true}
        open={confirmOpen.show}
        onConfirm={(act) =>
          act === 'yes'
            ? onApprove(act)
            : setConfirmOpen({
                show: false,
              })
        }
        autoCloseDisable={true}>
        {
          ' This will overwrite any selected document that you have uploaded in the current petition with the documents from the petition you have selected. Do you wish to proceed? '
        }
      </ConfirmDialog>
    </DialogContent>
  );
};

export default DocumentSectionList;
