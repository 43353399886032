import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from 'assets/images/liberty-logoheader.png';
import OvieRectangle from 'assets/images/ovie-rectangle.svg';
import Ovieim from 'assets/images/ovie-universal.png';
// import Space from 'assets/images/space.png';
import React from 'react';

// import { useAppSelector } from 'store/hooks';
import Footer from './Footer';

export const useStyles = makeStyles((theme) => ({
  space: {
    backgroundColor: '#09171f',
    backgroundImage: `url(${'https://d17p81usn86n3p.cloudfront.net/space.png'})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '100vh',
    width: '100%',
  },
}));

type headConfigsProps = {
  children: any;
};

const PublicLayout = (props: headConfigsProps) => {
  const { children } = props;
  const classes = useStyles();

  // const userDetail: any = useAppSelector(
  //   (store: any) => store.userLogin.userInfo,
  // );

  return (
    <Grid container>
      <Grid item md={12} className={classes.space}>
        <Typography component={'div'} className="main-container">
          <Typography component={'div'} className="dark-theme">
            <Typography component={'div'} className="black-white-head d-flex-a">
              <Typography
                component={'div'}
                className="nav-flex-1 black-white-text">
                <img
                  src={Logo}
                  alt="logo"
                  style={{ paddingLeft: '1rem', width: '155px' }}
                />
              </Typography>

              <Typography component={'div'} className="nav-flex-1 d-flex-jae">
                <Typography sx={{ position: 'relative' }}>
                  <Typography
                    sx={{
                      position: 'relative',
                      top: '7px',
                      width: '275px',
                      zIndex: 3,
                    }}
                    component={'img'}
                    src={OvieRectangle}
                    alt="imgae"
                  />
                  <Typography
                    className="p-image"
                    style={{ backgroundImage: `url(${String(Ovieim)})` }}
                  />
                </Typography>
              </Typography>
            </Typography>

            <Typography
              component={'div'}
              className="mainpadding"
              style={{ minHeight: '90vh' }}>
              {children}
            </Typography>
            <Footer />
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PublicLayout;
