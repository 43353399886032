import { Grid } from '@mui/material';
import { instantState, useDebounce } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';

import LeadsOrPetitionsList from '../Common/LeadsOrPetitions/Lists';

export const LeadsList: React.FC<any> = () => {
  const { editId } = useParams();
  const location: any = useLocation();
  const [loader, setLoader] = useState(true);
  const [listData, setListData] = useState<any>({});
  const [visaTypes, setVisaTypes] = useState<any>([]);
  const [contactTypes, setContactTypes] = useState<any>([]);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [params, setParams] = useState({
    associated: location?.pathname?.includes('invited')
      ? false
      : location?.pathname?.includes('associated')
      ? true
      : '',
    contactId: '',
    id: '',
    leadsSelected: '',
    name: '',
    order: '',
    orgType: '',
    organizationId:
      location?.pathname.includes('manage-organization') &&
      (location?.pathname?.includes('invited') ||
        location?.pathname?.includes('associated'))
        ? editId
        : '',
    skip: 0,
    sortby: '',
    status: '',
    take: 50,
    visaId: '',
  });

  const debouncedParams = useDebounce(params, 500);

  const getApi = useCallback(() => {
    setHasMoreResults(false);

    getAllListData(
      `${COMMON_ENDPOINT.LeadList}?id=${String(
        debouncedParams.id,
      )}&visaId=${String(debouncedParams.visaId)}&organizationId=${String(
        debouncedParams.organizationId,
      )}&orgType=${String(debouncedParams.orgType)}&status=${String(
        debouncedParams.status,
      )}&order=${String(debouncedParams.order)}&sortby=${String(
        debouncedParams.sortby,
      )}&name=${String(debouncedParams.name)}&skip=${String(
        debouncedParams.skip,
      )}&take=${String(debouncedParams.take)}&leadsSelected=${String(
        debouncedParams.leadsSelected,
      )}&associated=${String(debouncedParams.associated)}&contactId=${String(
        debouncedParams.contactId,
      )}`,
    )
      .then(async (resp: any) => {
        const { data, count: total } = resp ? resp : { count: 0, data: [] };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setListData((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          setListData(resp);
        }
        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setListData);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
  }, [
    debouncedParams.associated,
    debouncedParams.id,
    debouncedParams.contactId,
    debouncedParams.leadsSelected,
    debouncedParams.name,
    debouncedParams.order,
    debouncedParams.orgType,
    debouncedParams.organizationId,
    debouncedParams.skip,
    debouncedParams.sortby,
    debouncedParams.status,
    debouncedParams.take,
    debouncedParams.visaId,
  ]);

  useEffect(() => {
    getApi();
  }, [debouncedParams, getApi]);

  const getMoreList = () => {
    setGetMoreLoader(true);
    setParams((pre) => ({
      ...pre,
      skip: pre.skip + 50,
    }));
  };

  useEffect(() => {
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`)
      .then((resp: any) => {
        const visaOptions = [...resp.data];
        setVisaTypes(visaOptions);
        // setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
    getAllListData(
      `${String(SETUP_ENDPOINT.SelectMenu)}/lawfirm/${String(
        userDetail.companyId,
      )}`,
    ).then((resp: any) => {
      setContactTypes(resp);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid
        item
        md={10}
        sm={12}
        sx={{ paddingLeft: '0px !important' }}
        className="pr-2rem">
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <LeadsOrPetitionsList
            showType="leads"
            visaTypes={visaTypes}
            contactTypes={contactTypes}
            setParams={setParams}
            lists={listData}
            params={params}
            showAddBtn={true}
            hasMoreResults={hasMoreResults}
            getMoreLoader={getMoreLoader}
            getMoreList={getMoreList}
            setListData={setListData}
            orgId={
              location?.state?.organization && location?.state?.orgId
                ? location?.state?.orgId
                : ''
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default LeadsList;
