// import OvVideo from 'assets/videos/Online-Visas-animated-logo.mp4';
import React, { ReactElement } from 'react';

export const OnlineVisaVideo: React.FC<any> = (): ReactElement => (
  <video autoPlay muted loop className="bannervideo">
    <source
      src={
        'https://d17p81usn86n3p.cloudfront.net/Online-Visas-animated-logo.mp4'
      }
      type="video/mp4"
    />
  </video>
);
