import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowLeft from 'assets/images/arrow-left.svg';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { AffilatePrivateRoutes, PetitionerPrivateRoutes } from 'config';
import { instantState } from 'hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import {
  COMMON_ENDPOINT,
  MASTER_ENDPOINT,
  SETUP_ENDPOINT,
} from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { LeadCreateStepOneSchema } from 'utils/ValidatorSchema';

import AddNote from '../../Common/AddNote';
import AttorneyForm from './AttorneyForm';
import BeneficiaryAndPetitionerForm from './BeneficiaryAndPetitionerForm';

export const PetitionerLeadAdd: React.FC<any> = () => {
  const navigate: any = useNavigate();
  const { editId } = useParams();
  const [loader, setLoader] = useState(true);
  const [formSteps, setFormSteps] = useState(1);
  const [visaTypes, setVisaTypes] = useState<any>([]);
  const [organization, setOrganization] = useState<any>([]);
  const [attorneyList, setAttorneyList] = useState<any>([]);
  const [processingTypes, setProcessingTypes] = useState<any>([]);
  const [filingTypes, setFilingTypes] = useState<any>([]);
  const [attorneyContactPersonList, setAttorneyContactPersonList] =
    useState<any>([]);
  const [attorneyInitDetails, setAttorneyInitDetails] = useState<any>({
    attorneyCountryCode: '+1 ',
    attorneyEmail: '',
    attorneyName: '',
    attorneyPosition: '',
    attorneyTelephone: '',
  });
  const [viewNotes, setViewNotes] = useState<any>({
    leadOrPetitionId: '',
    show: false,
    type: '',
  });
  const [refresh, setRefresh] = useState(null);
  const [attorneySupportIds, setAttorneySupportIds] = useState<any>([]);
  const [additionalAttorneyContactIds, setAdditionalAttorneyContactIds] =
    useState<any>([]);

  const [formOneData, setFormOneData] = useState(null);
  const [leadData, setLeadData] = useState<any>({});
  const [deleteDialog, setDeleteDialog] = useState({
    deleteId: null,
    deleteType: '',
    index: null,
    show: false,
  });
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const {
    control: controlOne,
    handleSubmit: handleSubmitOne,
    reset: resetFormOne,
    setValue: setValueOne,
    getValues: getValueOne,
    setError: setErrorOne,
    watch: watchOne,
    formState: { errors: errorsOne, isValid: formOneIsValid },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      affilateDetails: [],
      affiliateType: 'new',
      beneficiaryDetails: [],
      beneficiaryEditDetails: [],
      contactEmail: '',
      contactLastName: '',
      contactMobileCountryCode: '',
      contactMobileNumber: '',
      contactMobileSocCode: '',
      contactPersonName: '',
      convictions: 'no',
      employmentOffered: 'no',
      employmentOfferedExplain: null,
      fillingType: null,
      leadOrgType: 'new',
      numberOfDependents: null,
      orgName: '',
      orgType: 'existing',
      petitionerAssociated: true,
      petitionerContactId: null,
      petitionerId: null,
      processingType: null,
      sendMail: false,
      visaDenials: 'no',
      visaDenialsExplain: null,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LeadCreateStepOneSchema),
  });

  const {
    control: controlThree,
    handleSubmit: handleSubmitThree,
    reset: resetFormThree,
    setValue: setValueThree,
    watch: watchThree,
    formState: { errors: errorsThree },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      additionalAttorneyContacts: [],
      attorneyContactId: '',
      attorneyId: '',
      attorneySupport: [],
      caseManager: [],
      leadEmail: '',
      leadNotes: '',
      leadStatus: 'prospect',
      visaType: [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LeadCreateStepOneSchema),
  });

  const {
    fields: beneficiaryFields,
    append: beneficiaryAppend,
    remove: beneficiaryFieldRemove,
    update: beneficiaryUpdate,
  } = useFieldArray({ control: controlOne, name: 'beneficiaryDetails' });
  const {
    // fields: affiliateFields,
    append: affiliateAppend,
    // remove: affiliateFieldRemove,
    // update: affiliateUpdate,
  } = useFieldArray({ control: controlOne, name: 'affilateDetails' });

  const cancelforStepOne = () => {
    if (!location.pathname.includes('add-leads') && userDetail.role_id === 2) {
      navigate(`${PetitionerPrivateRoutes.LEADS.LEADDETAILSLIST}/${editId}`);
    } else if (userDetail.role_id === 5) {
      navigate(
        `${AffilatePrivateRoutes.PETITIONER_LEADS.LEADDETAILSLIST}/${editId}`,
      );
    } else {
      navigate(-1);
    }
  };
  const cancelFormOne = () => {
    resetFormOne();
    resetFormThree();
    const removedIndex: Array<number> = [];
    beneficiaryFields.map((e: any, index: number) => {
      removedIndex.push(index);
    });
    beneficiaryFieldRemove(removedIndex);
    navigate(-1);
  };

  const onSubmitStepOne = (data: any) => {
    if (data.beneficiaryDetails && data.beneficiaryDetails.length) {
      const uniqueValues = new Set(
        data.beneficiaryDetails
          .filter((v) => v.beneficiaryEmail)
          .map((e) => e.beneficiaryEmail.toLocaleLowerCase().trim()),
      );
      if (
        uniqueValues.size <
        data.beneficiaryDetails.filter((v) => v.beneficiaryEmail).length
      ) {
        toast.error(
          'An email address can only be associated with one client/entity ',
          alertOptions,
        );
        return;
      } else if (editId && data.beneficiaryDetails.some((e) => !e.disable)) {
        toast.error('Please save beneficiary details', alertOptions);
        return;
      }
    }
    setFormOneData(data);
    setFormSteps(2);
  };

  // const onSubmitStepTwo = (data: any) => {
  //   if (data.petitionerContactDetails && data.petitionerContactDetails.length) {
  //     const uniqueValues = new Set(
  //       data.petitionerContactDetails
  //         .filter((v) => v.petitionerEmail)
  //         .map((e) => e.petitionerEmail.toLocaleLowerCase().trim()),
  //     );
  //     if (uniqueValues.size < data.petitionerContactDetails.length) {
  //       toast.error(
  //         'One email address can be associated with only one ',
  //         alertOptions,
  //       );
  //       return;
  //     } else if (
  //       editId &&
  //       data.petitionerContactDetails.some((e) => !e.disable)
  //     ) {
  //       toast.error('Please save additional petitioner details', alertOptions);
  //       return;
  //     }
  //   }
  //   setFormTwoData(data);
  //   setFormSteps(3);
  // };

  const onSubmitStepThree = (data: any) => {
    setLoader(true);
    // data['leadEmail'] = editData.email ? editData.email : '';
    data['employmentOffered'] =
      data['employmentOffered'] === 'no' ? false : true;
    data['visaDenials'] = data['visaDenials'] === 'no' ? false : true;
    data['convictions'] = data['convictions'] === 'no' ? false : true;
    data['overstayUsVisa'] = data['overstayUsVisa'] === 'no' ? false : true;
    data['leadEmail'] = '';
    data['attorneyId'] = data['attorneyId'] || null;
    data['attorneyContactId'] = data['attorneyContactId'] || null;
    data['visaType'] = data['visaType']
      ? Array.isArray(data['visaType'])
        ? data['visaType']
        : [data['visaType']]
      : [];
    data['caseManager'] = data['caseManager']
      ? Array.isArray(data['caseManager'])
        ? data['caseManager']
        : [data['caseManager']]
      : [];
    data['additionalAttorneyContacts'] =
      data['additionalAttorneyContacts'] &&
      Array.isArray(data['additionalAttorneyContacts'])
        ? data['additionalAttorneyContacts']
        : [];
    data['attorneySupport'] =
      data['attorneySupport'] && Array.isArray(data['attorneySupport'])
        ? data['attorneySupport']
        : [];
    const formData = {
      ...formOneData,
      ...data,
      fillingType:
        data?.fillingType && String(data?.fillingType).trim()
          ? data?.fillingType
          : null,
      numberOfDependents:
        data?.numberOfDependents && String(data?.numberOfDependents).trim()
          ? data?.numberOfDependents
          : null,
      processingType:
        data?.processingType && String(data?.processingType).trim()
          ? data?.processingType
          : null,
      totalEmployeesInCompany:
        data?.totalEmployeesInCompany &&
        String(data?.totalEmployeesInCompany).trim()
          ? data?.totalEmployeesInCompany
          : null,
      // eslint-disable-next-line sort-keys-fix/sort-keys-fix
      affilateDetails:
        formOneData &&
        formOneData.affilateDetails &&
        formOneData.affilateDetails.length
          ? formOneData.affilateDetails.map((val: any) => {
              return { ...val, id: val.ids };
            })
          : [],
      beneficiaryDetails:
        formOneData &&
        formOneData.beneficiaryDetails &&
        formOneData.beneficiaryDetails.length
          ? formOneData.beneficiaryDetails.map((val: any) => {
              return { ...val, id: val.ids };
            })
          : [],
      leadOrgType: formOneData.orgType,
      orgType:
        formOneData.orgType === 'existing'
          ? formOneData.petitionerAssociated
            ? 'existing'
            : 'new'
          : formOneData.orgType,
      petitionerContactDetails: [],
    };
    if (!_.isUndefined(editId)) {
      updateData(editId, formData, COMMON_ENDPOINT.CreateLead)
        .then((resp: any) => {
          cancelFormOne();
          setLoader(false);
          toast.success(resp?.message, alertOptions);
        })
        .catch((e) => {
          setLoader(false);
        });
    } else {
      addNewData(formData, COMMON_ENDPOINT.CreateLead)
        .then((resp: any) => {
          cancelFormOne();
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };

  const getInitialStatus = useCallback(async () => {
    setLoader(true);
    return await getAllListData(
      `${COMMON_ENDPOINT.LeadList}?id=${String(
        editId,
      )}&status=&skip=0&take=10&sortby=&order=&visaId=&organizationId=&orgType=&leadsSelected=&associated=&name=`,
    )
      .then((resp) => {
        const {
          affilateUserList,
          additional_attorney,
          attorney_assigned,
          attorney_contact_id,
          attorney_support,
          case_manager,
          beneficiaryDetails,
          org_contact,
          org_contact_last,
          mail_trigger,
          org_type,
          org_name,
          org_mail,
          employment_offered,
          type_of_filing,
          type_of_processing,
          overstay_us_visa,
          overstay_us_visa_explain,
          number_of_dependents,
          employees_in_company,
          org_mobile_number,
          lead_status,
          convictions,
          employment_offered_explain,
          convictions_explain,
          notes,
          visa_denials,
          visa_denials_explain,
          premium_processing,
          petitioner_assigned,
          petitioner_associated,
          petitioner_conatact_id,
          visa_type,
        } = resp.data[0];
        setLeadData(resp.data[0]);
        setValueThree('attorneySupport', attorney_support);
        setValueThree('attorneyContactId', attorney_contact_id || null);
        setValueThree('attorneyId', attorney_assigned || null);
        setValueThree(
          'caseManager',
          case_manager && Array.isArray(case_manager) ? case_manager : [],
        );
        setValueThree(
          'overstayUsVisaExplain',
          overstay_us_visa_explain || null,
        );
        setValueThree(
          'overstayUsVisa',
          overstay_us_visa ? 'yes' : 'no' || 'no',
        );
        setValueThree(
          'premiumProcessing',
          premium_processing === 'yes' ? 'yes' : 'no' || 'no',
        );
        setValueThree('visaDenials', visa_denials ? 'yes' : 'no' || 'no');
        setValueThree('convictions', convictions ? 'yes' : 'no' || 'no');
        setValueThree('convictionsExplain', convictions_explain || null);
        setValueThree(
          'employmentOffered',
          employment_offered ? 'yes' : 'no' || 'no',
        );
        setValueThree(
          'employmentOfferedExplain',
          employment_offered_explain || null,
        );
        setValueThree('fillingType', type_of_filing || null);
        setValueThree('numberOfDependents', number_of_dependents || null);
        setValueThree('visaDenialsExplain', visa_denials_explain || null);
        setValueThree('totalEmployeesInCompany', employees_in_company || null);
        setValueThree('processingType', type_of_processing || null);
        setValueThree('additionalAttorneyContacts', additional_attorney || []);

        if (affilateUserList.length) {
          affilateUserList.map((resp, index) =>
            affiliateAppend({
              affilateEmail: resp.email_id,
              affilateFirstName: resp.first_name,
              affilateLastName: resp.last_name,
              affilateMobileNumber: resp.contact_mobile
                ? String(resp.contact_mobile).indexOf('+') !== -1
                  ? resp.contact_mobile
                  : resp.country_code_mobile
                  ? String(resp.country_code_mobile)
                      .concat(' ')
                      .concat(resp.contact_mobile)
                  : '+1 '.concat(resp.contact_mobile)
                : '',
              affilateRoleId: resp.affilate_role_id,
              disable: true,
              id: resp.user_id,
              ids: resp.user_id,
              loginCheck:
                resp.conatct_status && resp.conatct_status !== 'pending'
                  ? true
                  : false,
              mobileCountryCode: resp.country_code_mobile,
              mobileSocCode: resp.country_source_code,
              sendEmailDisable:
                resp.conatct_status && resp.conatct_status !== 'pending'
                  ? true
                  : false,
              sendMail: resp.conatct_status === 'active' ? true : false,
              status: '',
              type: 'existing',
              userId: resp.user_id,
            }),
          );
        }
        if (beneficiaryDetails.length) {
          beneficiaryDetails.map((resp, index) =>
            beneficiaryAppend({
              beneficiaryEmail: resp.email_id,
              beneficiaryFirstName: resp.first_name,
              beneficiaryInviteSent: resp.beneficiary_invite_sent,
              beneficiaryLastName: resp.last_name,
              beneficiaryMobileCode: resp.country_source_code,
              beneficiaryMobileNumber: resp.contact_mobile
                ? String(resp.contact_mobile).indexOf('+') !== -1
                  ? resp.contact_mobile
                  : resp.country_code_mobile
                  ? String(resp.country_code_mobile)
                      .concat(' ')
                      .concat(resp.contact_mobile)
                  : '+1 '.concat(resp.contact_mobile)
                : '',
              disable: true,
              id: resp.user_id,
              ids: resp.user_id,
              leadUserId: resp.id,
              loginCheck:
                resp.conatct_status && resp.conatct_status !== 'pending'
                  ? true
                  : false,
              mobileCountryCode: resp.country_code_mobile,
              mobileSocCode: resp.country_source_code,
              sendEmailDisable:
                resp.conatct_status && resp.conatct_status !== 'pending'
                  ? true
                  : false,
              sendMail: resp.conatct_status === 'active' ? true : false,
              status: resp.petiton_started,
              type: 'existing',
            }),
          );
        }

        setValueOne('contactEmail', org_mail || '');
        setValueOne('contactPersonName', org_contact || '');
        setValueOne('contactLastName', org_contact_last || '');
        setValueOne('contactMobileNumber', org_mobile_number || '');
        setValueOne('orgName', org_name || '');
        setValueOne('orgType', org_type === 'sameas' ? 'sameas' : 'existing');
        setValueOne('petitionerAssociated', petitioner_associated || false);
        setValueOne(
          'petitionerContactId',
          Number(petitioner_conatact_id) || null,
        );
        setValueOne('petitionerId', Number(petitioner_assigned) || null);
        setValueOne('sendMail', mail_trigger);

        // if (petitionerContactDetails.length) {
        //   petitionerContactDetails.map((resp, index) =>
        //     petitionerContactAppend({
        //       contactMobileCountryCode: resp.contact_country_code,
        //       contactMobileSocCode: resp.contact_soc_code,
        //       disable: true,
        //       ids: resp.id,
        //       leadId: resp.lead_id,
        //       orgName: resp.org_name,
        //       petitionerEmail: resp.contact_email,
        //       petitionerFirstName: resp.contact_first_name,
        //       petitionerLastName: resp.contact_last_name,
        //       petitionerMobileCode: resp.contact_soc_code,
        //       petitionerMobileNumber: resp.contact_mobile_number,
        //       roleId: resp.role_id,
        //       sendMail: false,
        //       status: '',
        //     }),
        //   );
        //
        setValueThree('leadNotes', notes || '');
        setValueThree('leadStatus', lead_status || 'prospect');
        setValueThree(
          'visaType',
          visa_type && Array.isArray(visa_type) ? visa_type[0] : '',
        );
        setLoader(false);
        getBasicInfo();
      })
      .catch((e) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBasicInfo = async () => {
    setLoader(true);
    const leadInfo: {
      isAffilateCase: boolean;
      attorney_assigned: number;
      petitioner_assigned: number;
      affilateRoleId: number;
      org_name: string;
      firm_name: string;
      active_type: boolean;
    } = await instantState(setLeadData);
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`)
      .then((visaResp: any) => {
        setVisaTypes(visaResp.data);
      })
      .catch((e: any) => {
        setLoader(false);
      });
    getAllListData(`/${String(SETUP_ENDPOINT.OrganizationDetails)}`)
      .then((orgResps: any) => {
        setOrganization([
          {
            id:
              editId && leadInfo && leadInfo.isAffilateCase
                ? leadInfo.petitioner_assigned
                : userDetail?.companyId,
            org_name:
              editId && leadInfo && leadInfo.isAffilateCase
                ? leadInfo?.org_name
                : orgResps.org_name,
          },
        ]);
        setValueOne(
          'petitionerId',
          editId && leadInfo && leadInfo.isAffilateCase
            ? leadInfo.petitioner_assigned
            : userDetail?.companyId,
        );
        if (!editId) {
          setValueOne('petitionerContactId', Number(userDetail?.id) || null);
        }
      })
      .catch((e: any) => {
        setLoader(false);
      });
    if (editId && leadInfo?.isAffilateCase && leadInfo?.petitioner_assigned) {
      getAllListData(
        `${SETUP_ENDPOINT.SelectMenu}/lawfirm?lawFirmId=${
          leadInfo && leadInfo.isAffilateCase ? leadInfo.attorney_assigned : ''
        }${
          leadInfo?.isAffilateCase
            ? `&isAffilate=true&affilateRoleId=${String(
                leadInfo?.affilateRoleId,
              )}&firmId=${String(leadInfo?.attorney_assigned)}${
                leadInfo?.affilateRoleId === 2 && leadInfo?.petitioner_assigned
                  ? `&petitionerId=${String(leadInfo?.petitioner_assigned)}`
                  : ''
              }`
            : ''
        }`,
      )
        .then((lawFirmList: any) => {
          if (lawFirmList && lawFirmList.length && lawFirmList.length === 1) {
            setValueThree('attorneyId', lawFirmList[0].id);
          }
          setAttorneyList(lawFirmList);
          setLoader(false);
          setRefresh(Date.now());
        })
        .catch((e: any) => {
          setLoader(false);
        });
    } else {
      getAllListData(`${SETUP_ENDPOINT.SelectMenu}/lawfirm`)
        .then((lawFirmList: any) => {
          if (!editId && lawFirmList && lawFirmList.length) {
            setValueThree('attorneyId', lawFirmList[0].id);
          }
          setAttorneyList(lawFirmList);
          setLoader(false);
          setRefresh(Date.now());
        })
        .catch((e: any) => {
          setLoader(false);
        });
      // setAttorneyList([
      //   {
      //     firm_name: leadInfo?.firm_name,
      //     id: Number(leadInfo?.attorney_assigned),
      //     status: leadInfo?.active_type,
      //   },
      // ]);
      // setLoader(false);
    }
  };
  const processingTypeData = async () => {
    await getAllListData(
      `${MASTER_ENDPOINT.ProcessingTypes}?type=processingType`,
    )
      .then((processResp: any) => {
        setProcessingTypes(processResp);
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };
  const filingTypeData = async () => {
    await getAllListData(`${MASTER_ENDPOINT.ProcessingTypes}?type=filingType`)
      .then((fillingResp: any) => {
        setFilingTypes(fillingResp);
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    processingTypeData();
    filingTypeData();
    if (editId) {
      getInitialStatus();
    } else {
      getBasicInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = (action: string) => {
    if (action !== 'yes') {
      setDeleteDialog((pre) => ({
        ...pre,
        show: false,
      }));
      return;
    }
    if (deleteDialog.deleteType === 'beneficiary') {
      deleteData(
        '',
        COMMON_ENDPOINT.LeadUnderDeleteBeneficiary(
          editId,
          deleteDialog.deleteId && deleteDialog.deleteId.ids
            ? deleteDialog.deleteId.ids
            : '',
        ),
      ).then(() => {
        beneficiaryFieldRemove(deleteDialog.index);
      });
      setDeleteDialog((pre) => ({
        ...pre,
        show: false,
      }));
    }
  };
  return (
    <Grid container spacing={2}>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <Grid item md={12} sm={12}>
          <Typography
            component={'div'}
            className="d-flex-a d-flex-sb sticky-tab"
            sx={{ padding: '0px 14px' }}>
            <Typography
              component={'div'}
              className="w-title black-white-text d-flex-a"
              sx={{ marginTop: 'unset !important' }}>
              <Typography className="d-flex-a">
                {formSteps !== 1 && (
                  <Typography
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setFormSteps(formSteps === 3 ? 2 : 1);
                    }}>
                    <img className="edit mr-2" src={ArrowLeft} alt="edit" />
                  </Typography>
                )}
              </Typography>
              {editId ? 'Edit Lead' : 'Add New Lead'}
            </Typography>
            {editId && ![5].includes(leadData?.affilateRoleId) && (
              <Typography component={'div'} className="d-flex-jae my-2">
                <Button
                  className="cancel-btn mr-2"
                  onClick={() =>
                    setViewNotes({
                      leadOrPetitionId: editId,
                      show: true,
                      type: 'lead',
                    })
                  }>
                  <Typography className="o-box" />
                  {userDetail.userRoleName == 'petitioner'
                    ? 'Contact Attorney'
                    : 'Response'}
                </Button>
              </Typography>
            )}
          </Typography>
          <Grid container sx={{ margin: '10px 0 !important' }}>
            <Grid item md={6} sm={6} sx={{ paddingRight: '10px' }}>
              <Typography
                className={formSteps === 1 ? 'steps step-active' : 'steps'}
              />
            </Grid>
            <Grid md={6} sm={6} sx={{ paddingLeft: '10px' }}>
              <Typography
                className={formSteps === 2 ? 'steps step-active' : 'steps'}
              />
            </Grid>
          </Grid>
          {formSteps === 1 ? (
            <BeneficiaryAndPetitionerForm
              control={controlOne}
              errors={errorsOne}
              setValue={setValueOne}
              getValues={getValueOne}
              setError={setErrorOne}
              watch={watchOne}
              handleSubmit={handleSubmitOne}
              onSubmitForm={onSubmitStepOne}
              cancelForm={() => {
                cancelforStepOne();
                // cancelFormOne();
                // navigate(-1);
              }}
              fields={beneficiaryFields}
              fieldAppend={beneficiaryAppend}
              fieldRemove={beneficiaryFieldRemove}
              organizations={organization}
              editId={editId}
              setDeleteDialog={setDeleteDialog}
              beneficiaryUpdate={beneficiaryUpdate}
              formOneIsValid={formOneIsValid}
              leadData={leadData}
              refresh={refresh}
            />
          ) : (
            <AttorneyForm
              control={controlThree}
              errors={errorsThree}
              setValue={setValueThree}
              watch={watchThree}
              handleSubmit={handleSubmitThree}
              onSubmitForm={onSubmitStepThree}
              cancelForm={() => {
                // resetFormThree();
                setFormSteps(1);
              }}
              processingTypes={processingTypes}
              filingTypes={filingTypes}
              firmData={null}
              visaTypes={visaTypes}
              attorneyContactPersonList={attorneyContactPersonList}
              setAttorneyContactPersonList={setAttorneyContactPersonList}
              attorneyInitDetails={attorneyInitDetails}
              setAttorneyInitDetails={setAttorneyInitDetails}
              attorneySupportIds={attorneySupportIds}
              setAttorneySupportIds={setAttorneySupportIds}
              additionalAttorneyContactIds={additionalAttorneyContactIds}
              setAdditionalAttorneyContactIds={setAdditionalAttorneyContactIds}
              editId={editId}
              attorneyList={attorneyList}
              leadData={leadData}
            />
          )}
        </Grid>
      )}
      {viewNotes.show && (
        <AddNote
          notes={viewNotes}
          setNotes={setViewNotes}
          type="lead"
          showModel={true}
          showTable={true}
        />
      )}
      <ConfirmDialog
        title="DELETE"
        floatingBtnShow={true}
        open={deleteDialog.show}
        close={() => {
          setDeleteDialog((pre) => ({
            ...pre,
            show: false,
          }));
        }}
        onConfirm={(act) => onDelete(act)}
        autoCloseDisable={true}>
        {'Are you sure do you want to delete?'}
      </ConfirmDialog>
    </Grid>
  );
};

export default PetitionerLeadAdd;
