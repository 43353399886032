/* eslint-disable sort-keys-fix/sort-keys-fix */

import _ from 'lodash';

/* eslint-disable @typescript-eslint/restrict-plus-operands */
export const BASE_URL = process.env.REACT_APP_APIURL;
export const MASTER_ENDPOINT = {
  Cases: '/dashboard/cases',
  Changepassword: '/changePassword',
  ContractFee: '/contract-question',
  ContractReports: '/contract-payment/report',
  Countries: '/masterdata/countries',
  CreatePassword: '/createPassword',
  Dashboard: '/dashboard',
  Comments: '/dashboard/comments',
  DocumentPendingForReview: '/dashboard/document-pending-for-review',
  DocumentReviewSent: '/dashboard/document-review-sent',
  ForgotPassword: '/forgotpassword',
  Login: '/login',
  Myprofile: '/beneficiary/profile',
  ProcessingTypes: '/masterdata/processing',
  SideMenu: '/sidemenu',
  PaymentTermsLink: '/masterdata/payment-terms-link',
  StripeUpgrade: '/signup-payment/plan/upgrade',
  StripeProductList: '/signup-payment/product-list',
  StripeCreateCheckoutSession: '/signup-payment/create-checkout-session',
  StripeEmailExist: '/signup-payment/email/exist',
};

export const SETUP_ENDPOINT = {
  AddPetitioner: '/petitioner/profile',
  ConfigQuestion: '/configure-question',
  ConfigSection: '/configure-section',
  ConfigMajor: '/configure-section/mandatory',
  CopyPetitionerData: '/copy-petitioner-data/docview',
  DocumentTemplate: '/document-template',
  DocumentTemplateLinkCode: '/template-link-code-mapping',
  DocumentType: '/document-type',
  DocumentTypeArrange: '/document-type-order',
  InviteResend: '/petitioner/setup/invite-lawfirm/resend',
  ManageOrganization: '/petitioner/setup/invite-lawfirm',
  ManageOrganizationAssociate: '/admin/setup/petitioner',
  ManageOrganizationEdit: '/petitioner/setup/invite-lawfirm/list',
  ManageOrganizationInvite: '/petitioner/setup/invite-lawfirm/invite',
  ManageUsers: '/setup/users',
  MyFirm: '/setup/myfirm',
  OrganizationDetails: 'petitioner/setup/myOrganization',
  SelectMenu: '/selectmenu',
  VisaType: '/admin/setup/visatypes',
  ResourceStation: '/resource-station',
  ResourceStationFile: '/resource-station/file',
};

export const COMMON_ENDPOINT = {
  AWSS3FILES: '/aws-files',
  AddLawFirm: '/attorney/profile',
  Add_Version_History: '/version-history',
  AdminRoles: '/admin/roles',
  AffiliateList: '/profiles',
  AnswerCopy: '/copy-petitioner-data/answerCopy',
  ApproveContract: '/generate-document/contract-pdf',
  Assemble: '/common/assemble',
  AssemblePetition: '/assemble-petition',
  AssignAttorney: '/assign-attorney',
  AssociatedList: '/attorney/setup/users/petitioner',
  BeneficiaryAdd: '/admin/setup/visatypes/section',
  BeneficiaryAddSection: '/petitions/updateform',
  BeneficiaryList: '/common/beneficiary',
  BulkDocumentUpload: '/bulk-document-upload',
  Contact: '/petitions/petition-notes',
  Contract: '/contract',
  ContractPayment: '/contract-payment',
  ContractDownloadPDF: 'contract/read-contract/pdf',
  ContractPrevious: '/contract/previous',
  CopyBeneficiary_Petitioner: '/copy-petitioner-data',
  CopyBeneficiary_PetitionerQuestion: '/copy-petitioner-data/questionView',
  PaymentGateway: '/firm-contract-payment-method/config',
  CopyDocumentLead: '/upload-documents/petitioner/upload',
  CopyDocumentParseList: (
    skip: number,
    take: number,
    petitionerId: number,
    petitionerAssociated: string,
    name: string,
    visaId: any,
  ) => {
    return `/copy-petitioner-data?skip=${skip ? skip : 0}&take=${
      take ? take : 20
    }&petitionerId=${
      petitionerId ? petitionerId : ''
    }&petitionerAssociated=${petitionerAssociated}&name=${
      name ? name : ''
    }&visaId=${String(visaId)}`;
  },
  CreateLead: '/common/leads',
  CustomFields: '/common/custom-fields',
  DeleteLead: '/common/leads',
  DependentUpload: '/upload-documents/dependent/upload',
  DocumentBuild: '/document-production',
  DocumentBuildSendTo: '/document-production/document-status',
  DocumentParse: '/document-parsing',
  OrganizationStrategyList: (
    id: number,
    organizationId: number,
    firmId: number,
    associated: string,
    formType: string,
    strategyId: string,
  ) => {
    return `/petition-form/${id ? id : ''}?organizationId=${
      organizationId ? organizationId : ''
    }&firmId=${firmId ? firmId : ''}&associated=${associated}&formType=${
      formType ? formType : ''
    }&strategyId=${strategyId ? strategyId : ''}&answerOnly=true`;
  },
  DocumentParseList: (
    id: number,
    status: string,
    order: string,
    read: string,
    skip: number,
    take: number,
    userId: number,
  ) => {
    return `/document-parsing/extractionData?id=${id ? id : ''}&status=${
      status ? status : 'success'
    }&order=${order ? order : 'DESC'}&read=${read ? read : 'false'}&skip=${
      skip ? skip : 0
    }&take=${take ? take : 20}&userId=${userId ? userId : ''}`;
  },
  DraftDocument: '/document-production/draft',
  EnterpriseContract: '/contract/enterprise-contract',
  Email: '/selectmenu/teammembers',
  EmailCheck: (email: string) => `/common/emailCheck?email=${email}`,
  GenerateDocs: '/generate-document',
  GetAdminRoleById: '/admin/setup/roles',
  GetParticularDocId: '/upload-documents/doc-info',
  LeadList: '/common/leadslist',
  LeadUnderAdditionalPetitionerEdit: (leadId: string, beneficiaryId: string) =>
    `/common/petitioner/${leadId}/${beneficiaryId}`,
  LeadUnderAddittionalPetitionerAdd: (leadId: string) =>
    `/common/petitioner/${leadId}`,
  LeadUnderAffiliateAdd: (afId: string) => `/common/affilate/${afId}`,
  LeadUnderAffiliateDelete: (afId: string, beneficiaryId: string) =>
    `/common/affilate/${afId}/${beneficiaryId}`,
  LeadUnderAffiliateEdit: (afId: string, beneficiaryId: string) =>
    `/common/affilate/${afId}/${beneficiaryId}`,
  LeadUnderBeneficiaryAdd: (leadId: string) => `/common/beneficiary/${leadId}`,
  LeadUnderBeneficiaryEdit: (leadId: string, beneficiaryId: string) =>
    `/common/beneficiary/${leadId}/${beneficiaryId}`,
  LeadUnderDeleteAdditionalPetitioner: (leadid: string, petitionerId: string) =>
    `/common/petitioner/${leadid}/${petitionerId}`,
  LeadUnderDeleteBeneficiary: (leadId: string, beneficiaryId: string) =>
    `/common/beneficiary/${leadId}/${beneficiaryId}`,
  ManageAddEditUsers: '/admin/setup/users',
  SECTIONAPPROVE: '/petitions/section/approve',
  ManageLawFirm: '/admin/setup/lawfirm',
  ManagePetitioner: '/admin/setup/petitioner',
  OrganizationUploadDocuments: '/upload-documents/petitioner/upload',
  PaymentReportParseList: (
    status: string,
    order: string,
    skip: number,
    take: number,
    sortby: string,
    firmId: number,
    fromDate: string,
    toDate: string,
    createdby: string,
    transactionId: string,
    amount: string,
    totalAmount: string,
    paymentMode: string,
    paymentReportType?: string,
    petitionerId?: string,
    petitionerAssociated?: string,
    visaType?: string,
    invoicePurpose?: string,
    contactId?: string,
  ) => {
    let url = `/contract-payment/report?status=${
      status ? status : 'success'
    }&order=${order ? order : 'DESC'}&skip=${skip ? skip : 0}&take=${
      take ? take : 20
    }&sortby=${sortby ? sortby : ''}&firmId=${firmId ? firmId : ''}&fromDate=${
      fromDate ? fromDate : ''
    }&toDate=${toDate ? toDate : ''}&createdby=${
      createdby ? createdby : ''
    }&transactionId=${transactionId ? transactionId : ''}&amount=${
      amount ? amount : ''
    }&totalAmount=${totalAmount ? totalAmount : ''}&paymentMode=${
      paymentMode === 'all' ? '' : paymentMode
    }`;

    if (paymentReportType === 'contract') {
      url = url + '&paymentReportType=' + paymentReportType;
    }
    if (petitionerId && petitionerId !== 'all') {
      url = url + '&petitionerId=' + petitionerId;
    }
    if (petitionerAssociated && !_.isUndefined(petitionerAssociated)) {
      url = url + '&petitionerAssociated=' + petitionerAssociated;
    }
    if (visaType && visaType !== 'all') {
      url = url + '&visaType=' + visaType;
    }
    if (invoicePurpose) {
      url = url + '&invoicePurpose=' + invoicePurpose;
    }
    if (contactId && contactId !== 'all') {
      url = url + '&contactId=' + contactId;
    }

    return url;
  },
  PaymentSlip: '/contract-payment/slip/',
  PetitionDependent: '/petition-dependent',
  Petitions: '/petitions',
  RequestDocument: '/request-document/default-view',
  RequestForRequiredDocument: '/request-document',
  ResponseTypes: '/admin/setup/common',
  ResourceStation: '/resource-station',
  ReviewDocuments: '/review-documents',
  RoleMap: '/admin/setup/roles/mapping',
  Version_History:
    '/version-history?id=&versionId=&skip=0&take=50&sortby=id&order=DESC',
  VisaTypes: '/admin/setup/visatypes',
  WinningStrategyList: (formId: number, formType: string) => {
    return `/petition-form/${formId ? formId : '56'}?formType=${
      formType ? formType : 'winning'
    }`;
  },
  acceptedInviteLawFirm: '/petitioner/setup/invite-lawfirm',
  subscriptionPlan: '/attorney/setup/myfirm/subscription/details',
  submissionStatus: '/submission-status',
  uploadDocumentFile: (id: string, roleId: string, personId: string) =>
    `/upload-documents/${id}?roleId=${roleId}&addFor=${personId}`,
  uploadDocumentFileEdit: (id: string, roleId: string) =>
    `/upload-documents/${id}?roleId=${roleId}`,
  uploadDocumentFileNameEdit: (id: string) => `/upload-documents/rename/${id}`,
  uploadDepentendDocummentFileNameEdit: (id: string) =>
    `/upload-documents/dependent/upload/rename/${id}`,
  uploadDocumentGetParsingValue: '/form-data-populate/lca/brief',
  dependentUploadDocumnet: (id: string) =>
    `/upload-documents/dependent/upload/${id}`,
  uploadDocumentI128GetExtractionInfo: (
    userId: string,
    status: string,
    readStatus: string,
    id?: string,
  ) =>
    `/document-parsing/extractionData?id=${
      id ? id : ''
    }&status=${status}&skip&take&order=DESC&read=${readStatus}&userId=${userId}`,
  uploadDocumentI129ExtractionData: (
    id: number,
    petitonOrBenificaryId: number,
  ) => `/form-data-populate/${id}/${petitonOrBenificaryId}`,
  uploadDocumentI129LeadBasedUpdate: (formExtrationId: number, type: string) =>
    `/common/benificiery-data/${formExtrationId}?type=${type}`,
  uploadDocumentI129ParsingData: (visaId: number, roled: number) =>
    `/form-data-populate/bulk-view/${visaId ? visaId : 1}/${roled}`,
  uploadDocumentI129ParsingDataUpdate: '/form-data-populate/form-bulk',
  uploadDocumentI129PetitionBasedUpdate: (id: string) =>
    `/form-data-populate/form/${id}`,
  uploadDocumentI_129RefrenceIdSave: (
    orgId: string,
    uploadFor: string,
    attorneyContactUserId: string,
  ) =>
    `/document-parsing/extractionData?companyId=${
      orgId ? orgId : ''
    }&uploadFor=${uploadFor ? uploadFor : ''}&userId=${
      attorneyContactUserId ? attorneyContactUserId : ''
    }`,
  uploadDocumentParsing: (
    addin: number,
    roleId: number,
    uploadtype: string,
  ) => {
    if (uploadtype !== 'i129') {
      (addin = null), (roleId = null);
    }
    return `/document-parsing?addFor=${addin ? addin : ''}&roleId=${
      roleId ? roleId : ''
    }`;
  },
  uploadDocumentParsingUpdate: (id: number, roleId: number, personId: number) =>
    `/upload-documents/update-doc/${id}?roleId=${roleId}&addFor=${personId}`,
  pdfFieldsValue: '/template-link-code-mapping/fields/list',
  rfeResponder: '/rfe-responder',
  bulkDocumentDownload: '/upload-documents/bulkdownload',
};

export const STRATEGY_ENDPOINT = {
  copyStrategyList: (formId: string) =>
    `/petitioner/transaction/strategysession/list?formId=${formId}`,
  copyStrategyQuestionList: (beneficiaryId: string, formId: string) =>
    `/petitioner/transaction/strategysession/copy-strategy?beneficiaryId=${beneficiaryId}&formId=${formId}`,
  copyStrategyQusAndAnswer:
    '/petitioner/transaction/strategysession/copy-strategy',
  leadLevelStrategyList: (
    leadId: string,
    userId?: string,
    affilate?: boolean,
    orgStrategy?: boolean,
    formId?: string,
    orgId?: string,
    orgAssoicated?: string,
    skip?: any,
    take?: any,
  ) =>
    `/petitioner/transaction/strategysession/list/${leadId}?skip=&take=&userId=${
      userId ? userId : ''
    }${affilate ? '&isAffilate=true' : ''}${
      orgStrategy
        ? `&orgStrategy=true&formId=${formId}${
            orgId && orgAssoicated
              ? `&organizationId=${orgId}&associated=${orgAssoicated}`
              : ''
          }`
        : ''
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    }${take ? `&skip=${skip}&take=${take}` : ''}`,
  scheduleStrategy: '/petitioner/transaction/strategysession',
  strategyDelete: (id: string) =>
    `/petitioner/transaction/strategysession/delete/${id}`,
  strategyPersonList: (leadId: string) => `/contract/${leadId}`,
  updateStrategyFormId: (strategyId: number, formId: number) =>
    `/petitioner/transaction/strategysession/form/${strategyId}/${formId}`,
  PetitionStrategyList: '/petitioner/transaction/strategysession/petition/list',
  OrganizationStrategy:
    '/petitioner/transaction/strategysession/strategy-master',
  updateOrganizationStrategyFormId: (strategyId: string, formId: string) =>
    `/petitioner/transaction/strategysession/form/strategy-master/${strategyId}/${formId}`,
  strategyOrganizationDelete:
    '/petitioner/transaction/strategysession/strategy-master',
};

export const Fill_FORM_ENDPOINT = '/fillform';
export const LEAD_LEVEL_FORM_ENDPOINT = '/form-data-populate/lead';
export const ASSEMBEL_ENDPOINT = '/common/assemble';
export const TREE_VIEW_ENDPOINT = (
  id: number,
  leadId?: string,
  beneficiaryId?: number,
  formType?: string,
  petitionId?: number,
  visaId?: number,
  petitionerUserId?: number,
  orgId?: number,
  firmId?: number,
  caseUserId?: number,
  leadFor?: number,
  dependentPersonId?: number,
  petitionerAssociated?: boolean,
  leaduserId?: number,
  contractSelectedFor?: number,
  dependentUserId?: number,
  isAffilate?: boolean,
  affilateRoleId?: number,
) => {
  let url = '/petition-form/' + id + '?';
  if (petitionId) {
    url = url + 'petitionId=' + petitionId + '&';
  }
  if (beneficiaryId) {
    url = url + 'beneficiaryId=' + beneficiaryId + '&';
  }
  if (visaId) {
    url = url + 'visaId=' + visaId + '&';
  }
  if (petitionerUserId) {
    url = url + 'petitionerUser=' + petitionerUserId + '&';
  }
  if (orgId) {
    url = url + 'organizationId=' + orgId + '&';
  }
  if (firmId) {
    url = url + 'firmId=' + firmId + '&';
  }
  if (caseUserId) {
    url = url + 'attorneyUser=' + caseUserId + '&';
  }
  if (leadId) {
    url = url + 'leadId=' + leadId + '&';
  }
  if (leadFor) {
    url = url + 'leadFor=' + leadFor + '&';
  }
  if (dependentPersonId) {
    url = url + 'dependentUserId=' + dependentPersonId + '&';
  }
  if (leaduserId) {
    url = url + 'leadUserId=' + leaduserId + '&';
  }
  if (petitionerAssociated === true || petitionerAssociated === false) {
    url = url + 'associated=' + petitionerAssociated + '&';
  }
  if (formType) {
    url = url + 'formType=' + formType + '&';
  }
  if (contractSelectedFor) {
    url = url + 'contractSelectedFor=' + contractSelectedFor + '&';
  }
  if (dependentUserId) {
    url = url + 'dependentUserId=' + dependentUserId + '&';
  }
  if (isAffilate && affilateRoleId) {
    url =
      url + `isAffilate=${String(isAffilate)}&affilateRoleId=${affilateRoleId}`;
  }
  return url;
};
export const ORG_TREE_VIEW_ENDPOINT = (
  id: string,
  formType?: string,
  orgId?: string,
  firmId?: number,
  petitionerAssociated?: boolean,
  strategyId?: string,
) => {
  let url = '/petition-form/' + id + '?';
  if (orgId) {
    url = url + 'organizationId=' + orgId + '&';
  }
  if (firmId) {
    url = url + 'firmId=' + firmId + '&';
  }
  if (petitionerAssociated || !petitionerAssociated) {
    url = url + 'associated=' + String(petitionerAssociated) + '&';
  }
  if (formType) {
    url = url + 'formType=' + formType + '&';
  }
  if (strategyId) {
    url = url + 'strategyId=' + strategyId;
  }

  return url;
};
export const LEAD_LEVEL_TREE_VIEW_ENDPOINT = (
  formId: number,
  beneficiaryId: number,
  visaId: number,
  leadId: number,
) => {
  const url = `/petition-form/${formId ? formId : ''}?beneficiaryId=${
    beneficiaryId ? beneficiaryId : ''
  }&visaId=${visaId ? visaId : ''}&leadId=${
    leadId ? leadId : ''
  }&formType=leadUser`;
  return url;
};
export const PETITION_ADD_ADDITIONAL_TEMPLATE = (petitionId: string) =>
  `/petitions/petition-template/${petitionId}`;

export const PETITION_STATUS = [
  {
    label: 'Submitted',
    value: 'submitted',
  },
  {
    label: 'RFE',
    value: 'rfe',
  },
  {
    label: 'RFE Submitted',
    value: 'rfesubmitted',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
];
