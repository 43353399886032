import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  BeneficiaryPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { addNewData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { AddDependentform } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { Dependentform } from 'utils/ValidatorSchema';

import useStyles from '../../../../PrivateStyle';

export const Depentent: React.FC<any> = ({
  open,
  openAddModal,
  setOpen,
  setOpenAddModal,
  setDependentUserId,
  QuestionWizardFormData,
  DependentuploadDocument,
  leadUserId,
}) => {
  const classes = useStyles();
  const { petitionId } = useParams();
  const [loader, setLoader] = React.useState(true);
  const [data, setData] = useState<any>([]);
  const [listData, setListData] = useState<any>([]);
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const navigate = useNavigate();
  const sectionTypes: any = [
    {
      id: 'father',
      name: 'Father',
    },
    {
      id: 'mother',
      name: 'Mother',
    },
    {
      id: 'spouse',
      name: 'Spouse',
    },
    {
      id: 'son',
      name: 'Son',
    },
    {
      id: 'daughter',
      name: 'Daughter',
    },
  ];
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    formState: { errors },
    // setDatas,
  } = useForm<AddDependentform>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      middleName: '',
      realtionship: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(Dependentform),
  });

  const getDependentList = () => {
    getAllListData(`${COMMON_ENDPOINT.PetitionDependent}/${String(petitionId)}`)
      .then((Resp: any) => {
        setData(Resp);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!_.isUndefined(petitionId) && !_.isEmpty(petitions)) {
      getDependentList();
      if (openAddModal) {
        getAllListData(
          `${COMMON_ENDPOINT.PetitionDependent}/existing/${String(
            petitions.petitionData.beneficiary_id,
          )}/${String(petitionId)}`,
        )
          .then((Resp: any) => {
            setListData(Resp);
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitions, openAddModal]);

  const onSubmitForm = (data: any) => {
    data['beneficieryId'] = petitions.petitionData.beneficiary_id;
    data['dependentType'] = 'new';
    data['dependentUserId'] = '';
    data['petitionId'] = petitions.petitionData.id;
    addNewData(data, COMMON_ENDPOINT.PetitionDependent)
      .then((resp: any) => {
        getDependentList();
        setOpen(false);
        resetForm();
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const Update = (data: any) => {
    const postdata = {
      beneficieryId: petitions.petitionData.beneficiary_id,
      dependentType: 'existing',
      dependentUserId: data.id,
      email: data.email,
      firstName: data.first_name,
      lastName: data.last_name,
      middleName: data.middle_name,
      petitionId: Number(petitionId),
      realtionship: data.relationship,
    };
    addNewData(postdata, `${COMMON_ENDPOINT.PetitionDependent}`)
      .then((Resp: any) => {
        setOpenAddModal(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <Grid container>
      <Grid item md={12} sm={12}>
        {loader && <div className="liberty-loader" />}
        <Typography component={'div'}>
          <Grid container>
            <Grid item md={12} sm={12}>
              <table className="lead-table table-hover">
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>RELATION</th>
                    <th>FORM</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((row, index) => (
                      <tr key={index}>
                        <td>
                          {String(row.first_name) + ' ' + String(row.last_name)}
                        </td>
                        <td>{row.email ? row.email : '-'}</td>
                        <td>{row.relationship ? row.relationship : '-'}</td>
                        <td>
                          <a
                            style={{
                              color: '#1093df',
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            onClick={() => {
                              if (
                                userDetail &&
                                [2, 3, 4, 5].includes(userDetail.role_id) &&
                                DependentuploadDocument === 'dependentUpload'
                              ) {
                                navigate(
                                  userDetail.role_id === 3
                                    ? `${String(
                                        AttorneyPrivateRoutes.LEADS
                                          .UPLOADLEADDOCUMENT,
                                      )}/${String(petitionId)}`
                                    : userDetail.role_id === 2
                                    ? `${String(
                                        PetitionerPrivateRoutes.LEADS
                                          .UPLOADDOCUMENT,
                                      )}/${String(petitionId)}`
                                    : userDetail.role_id === 5
                                    ? `${String(
                                        AffilatePrivateRoutes.ATTORNEY_LEADS
                                          .UPLOADDOCUMENT,
                                      )}/${String(petitionId)}`
                                    : `${String(
                                        BeneficiaryPrivateRoutes.UPLOADDOCUMENT,
                                      )}/${String(
                                        petitionId,
                                      )}?leadUser=${String(
                                        petitionId,
                                      )}&lead=${String(leadUserId)}`,
                                  {
                                    state: {
                                      DependentuploadDocument:
                                        'dependentUpload',
                                      dependentName: `${
                                        String(row.first_name) +
                                        ' ' +
                                        String(row.last_name)
                                      }`,
                                      dependentUserId: row.id,
                                      id: petitionId,
                                      isBack: true,
                                      isLead: false,
                                      leadId: petitions.leadList.id,
                                      screen: 'petition',
                                    },
                                  },
                                );
                              } else {
                                setDependentUserId(row.id);
                                QuestionWizardFormData();
                              }
                            }}>
                            {userDetail &&
                            [2, 3, 4, 5].includes(userDetail.role_id) &&
                            DependentuploadDocument === 'dependentUpload'
                              ? 'Upload'
                              : userDetail && userDetail?.role_id === 3
                              ? 'Select view/edit to add a new form'
                              : 'View/Edit'}
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className={'text-center'}>
                      <td scope="row" colSpan={5} style={{ color: 'red' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}>
                          {DependentuploadDocument === 'dependentUpload' &&
                          userDetail &&
                          userDetail?.role_id === 3
                            ? 'No Record(s) Found.'
                            : userDetail && userDetail?.role_id === 3
                            ? 'Please click on Add New to the right under Ovie to add a dependent.'
                            : 'No Record(s) Found.'}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Typography style={{ width: '60%' }}>
        <CustomModalView
          open={open}
          close={setOpen}
          title="Dependent"
          fullWidth={true}>
          <Typography component={'div'} className={classes.scrollbox}>
            <Typography
              component={'div'}
              // className="addbox"
              sx={{ marginTop: 'unset !important' }}>
              <form id="dependent-add" onSubmit={handleSubmit(onSubmitForm)}>
                <Box
                  component="form"
                  className="u-add-in"
                  sx={{
                    backgroundColor: 'unset !important',
                    border: 'unset !important',
                  }}
                  noValidate
                  autoComplete="off">
                  <Grid container columnSpacing={{ md: 2, sm: 1 }}>
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          First Name <span className="required">*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="firstName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.firstName?.message}
                                onChange={(e) => onChange(e)}
                                tabIndex={1}
                              />
                            );
                          }}
                        />
                      </Typography>
                      {/* <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Middle Name
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="middleName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.middleName?.message}
                                onChange={(e) => onChange(e)}
                                tabIndex={3}
                              />
                            );
                          }}
                        />
                      </Typography> */}
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Last Name <span className="required">*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="lastName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.lastName?.message}
                                onChange={(e) => onChange(e)}
                                tabIndex={3}
                              />
                            );
                          }}
                        />
                      </Typography>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Email
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="email"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.email?.message}
                                onChange={(e) => onChange(e)}
                                tabIndex={5}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Middle Name
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="middleName"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.middleName?.message}
                                onChange={(e) => onChange(e)}
                                tabIndex={2}
                              />
                            );
                          }}
                        />
                      </Typography>
                      {/* <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Email
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="email"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomInput
                                placeHolder={''}
                                name={name}
                                value={value}
                                className={'custom-input'}
                                error={errors.email?.message}
                                onChange={(e) => onChange(e)}
                                tabIndex={4}
                              />
                            );
                          }}
                        />
                      </Typography> */}
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Relation <span className="required">*</span>
                        </Typography>
                        <FormControl>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="realtionship"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <CustomSelect
                                  labelKey={'name'}
                                  valueKey={'id'}
                                  options={sectionTypes}
                                  name={name}
                                  value={value}
                                  onChange={onChange}
                                  placeHolder={'Select'}
                                  className={'custom-input'}
                                  error={errors.realtionship?.message}
                                  tabIndex={4}
                                />
                              );
                            }}
                          />
                        </FormControl>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Typography>
            <Grid container>
              <Grid item md={12}>
                <Typography
                  component={'div'}
                  className="d-flex-jae my-2 "
                  sx={{ paddingRight: '34px' }}>
                  <Button
                    className="add-btn"
                    type="submit"
                    form={'dependent-add'}>
                    Save
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </CustomModalView>
      </Typography>
      <Typography>
        <Grid item md={12} sm={12}>
          <CustomModalView
            open={openAddModal}
            close={setOpenAddModal}
            fullWidth={true}
            title="Dependents">
            <Typography
              component={'div'}
              className={classes.scrollbox}
              sx={{ padding: '0 24px' }}>
              <table className="lead-table table-hover">
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>EMAIL</th>
                  </tr>
                </thead>
                <tbody>
                  {listData.length > 0 ? (
                    listData.map((row: any, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={() => Update(row)}
                          style={{ cursor: 'pointer' }}>
                          <td>
                            {row.first_name
                              ? `${String(row.first_name)} ${String(
                                  row.last_name,
                                )}`
                              : '-'}
                          </td>
                          <td>{row.email ? row.email : '-'}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className={'text-center'}>
                      <td colSpan={5} align={'center'}>
                        No Record(s) Found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Typography>
          </CustomModalView>
        </Grid>
      </Typography>
    </Grid>
  );
};

export default Depentent;
