import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { AdminPrivateRoutes, AttorneyPrivateRoutes } from 'config';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { ConfigureSections } from 'types';
import { SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { ConfigureSectionSchema } from 'utils/ValidatorSchema';

import QuestionMapping from './QuestionMapping';

export const QuestionAdd: React.FC<any> = () => {
  const navigate: any = useNavigate();
  const { editId } = useParams();

  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );

  const activeStatusInput = useRef(null);
  const inActiveStatusInput = useRef(null);

  const [loader, setLoader] = useState(true);
  const [statusToggle, setStatusToggle] = useState(true);
  const [visaDocuments, setVisaDocuments] = useState<any>([]);
  const [questionDetails, setQuestionDetails] = useState<any>([]);
  const [incVisaCode, setIncVisaCode] = useState<any>([]);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    id: null,
    index: null,
    show: false,
  });

  const [params] = useState<any>({
    attorney: userDetail.role_id === 3 ? true : false,
    id: '',
    majorsection: '',
    name: '',
    order: '',
    sectiontype: '',
    skip: 0,
    sortby: '',
    status: '',
    take: 1,
  });

  const sectionTypes: any = [
    {
      id: 'beneficiary',
      name: 'Beneficiary',
    },
    {
      id: 'petitioner',
      name: 'Petitioner',
    },
    {
      id: 'caseplan',
      name: 'Case Plan',
    },
    {
      id: 'strategy',
      name: 'Strategy',
    },
    {
      id: 'contract',
      name: 'Contract',
    },
    {
      id: 'dependent',
      name: 'Dependent',
    },
    {
      id: 'winning',
      name: 'Winning Strategy',
    },
    {
      id: 'lca',
      name: 'Labor Condition Application',
    },
  ];

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ConfigureSections>({
    criteriaMode: 'all',
    defaultValues: {
      majorSection: true,
      sectionDescription: '',
      sectionStatus: false,
      sectionTitle: '',
      sectionType: '',
      templateId: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ConfigureSectionSchema),
  });

  const cancelForm = () => {
    resetForm();
    navigate(
      userDetail.role_id === 1
        ? AdminPrivateRoutes.SECTION.CONFIGURESECTION
        : userDetail.role_id === 3
        ? AttorneyPrivateRoutes.SECTION.CONFIGURESECTION
        : '',
    );
  };

  const getDocumentTemplateListByContract = () => {
    getAllListData(
      `${String(
        SETUP_ENDPOINT.DocumentTemplate,
      )}?order=DESC&templateName=&templateType=&documentType=contract&id=`,
    )
      .then((contractResp: any) => {
        if (userDetail.role_id === 1) {
          setVisaDocuments(contractResp.admin.length ? contractResp.admin : []);
        } else if (userDetail.role_id === 3) {
          const visaDocs = [...contractResp.admin, ...contractResp.attorney];
          setVisaDocuments(visaDocs);
        }
        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };

  const getInitialStatus = useCallback((rId?) => {
    setLoader(true);
    getAllListData(
      `${String(SETUP_ENDPOINT.ConfigSection)}?id=${
        rId ? String(rId) : String(editId)
      }&name=${String(params.name)}&sectiontype=${String(
        params.sectiontype,
      )}&status=${String(params.status)}&majorsection=${String(
        params.majorsection,
      )}&skip=${String(params.skip)}&take=${String(params.take)}&order=${String(
        params.order,
      )}&sortby=${String(params.sortby)}&attorney=${String(params.attorney)}`,
    )
      .then((resp) => {
        const {
          major_section,
          section_name,
          section_description,
          section_status,
          section_type,
          template_id,
          questionDeatails,
          question_id,
          includedInVisas,
        } = resp.data[0];

        if (!_.isUndefined(editId)) {
          setQuestionDetails(
            questionDeatails
              ? questionOrderRearrange(questionDeatails, question_id)
              : [],
          );
          setIncVisaCode(includedInVisas.map((val) => val.visa_code));
        }

        setValue('majorSection', major_section);
        setValue('sectionTitle', section_name);
        setValue('sectionDescription', section_description);
        setValue('sectionType', section_type);
        setValue('templateId', template_id);
        setValue('sectionStatus', section_status);
        setStatusToggle(section_status);

        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
    // if (section_type === 'contract') {
    getDocumentTemplateListByContract();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const questionOrderRearrange = (questionDeatails: any, questionIds: any) => {
    const qusOrder = [];
    if (questionIds && questionIds.length) {
      questionIds
        // .filter((item, i, arr) => arr.indexOf(item) === i) // remove duplicate question
        .map((qusId) => {
          const searchQus = questionDeatails.find(
            (qusFullView) => qusFullView.id === qusId,
          );
          if (searchQus) {
            qusOrder.push(searchQus);
          }
        });
      questionDeatails = qusOrder;
    }
    return questionIds && questionIds.length ? questionDeatails : [];
  };

  const onToggleStatus = () => setStatusToggle((b) => !b);

  const onSubmitForm = (data: any) => {
    setLoader(true);

    data['sectionStatus'] = String(statusToggle);
    data['majorSection'] = String(data['majorSection']);

    if (!_.isEmpty(editId)) {
      updateData(editId, data, SETUP_ENDPOINT.ConfigSection)
        .then((resp: any) => {
          resetForm();
          getInitialStatus(editId);
          toast.success('Section updated', alertOptions);
          // setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
        });
    } else {
      addNewData(data, SETUP_ENDPOINT.ConfigSection)
        .then((resp: any) => {
          navigate(
            userDetail.role_id === 1
              ? `${AdminPrivateRoutes.SECTION.SECTIONEDIT}/${String(
                  resp.data.id,
                )}`
              : userDetail.role_id === 3
              ? `${AttorneyPrivateRoutes.SECTION.SECTIONEDIT}/${String(
                  resp.data.id,
                )}`
              : '',
          );
          getInitialStatus(resp.data.id);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    if (editId) {
      getInitialStatus();
    } else {
      getDocumentTemplateListByContract();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      {loader ? (
        <div className="liberty-loader" />
      ) : (
        <Grid item md={10} sm={12} sx={{ paddingRight: '1rem' }}>
          <form id="add-section" onSubmit={handleSubmit(onSubmitForm)}>
            <Typography
              component={'div'}
              className="d-flex-a d-flex-sb sticky-tab mb-2">
              <Typography
                component={'div'}
                className="w-title black-white-text"
                sx={{ margin: 'unset !important' }}>
                {_.isUndefined(editId) ? 'Add' : 'Edit'} Section
              </Typography>

              <Typography component={'div'} className="d-flex-a">
                <Button className="n-cancel-btn mr-2" onClick={cancelForm}>
                  <Typography className="o-box" />
                  Cancel
                </Button>
                <Button
                  className="n-add-btn"
                  type="submit"
                  form={'add-section'}>
                  {_.isUndefined(editId) ? 'Submit' : 'Update'}
                </Button>
              </Typography>
            </Typography>

            <Typography
              component={'div'}
              className="addbox"
              sx={{
                marginTop: 'unset !important',
              }}>
              <Box
                component="form"
                className="u-add-in"
                noValidate
                autoComplete="off">
                <Grid container columnSpacing={{ md: 3, sm: 1 }}>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Section Title{' '}
                        <Typography component={'span'} className="Mui-error">
                          *
                        </Typography>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="sectionTitle"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder={'Section Title'}
                              name={name}
                              value={value}
                              className={'custom-input'}
                              error={errors.sectionTitle?.message}
                              onChange={onChange}
                              tabIndex={1}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Section Description
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="sectionDescription"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomInput
                              placeHolder={'Section Description'}
                              name={name}
                              value={value}
                              className={'custom-textarea'}
                              error={errors.sectionDescription?.message}
                              onChange={onChange}
                              isTextArea={true}
                              tabIndex={3}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography
                      component={'div'}
                      className="custom-field d-flex-a pt-2">
                      <Typography
                        component={'div'}
                        className="custom-label mr-1 mt-1">
                        Mandatory Section
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={true}
                        name="majorSection"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <Checkbox
                              inputRef={ref}
                              checked={value}
                              onChange={onChange}
                              name={name}
                              value={value}
                              color="primary"
                              tabIndex={5}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography
                      component={'div'}
                      className=""
                      style={{ fontStyle: 'italic' }}>
                      Mandatory means this section will be viewable in the
                      section for users to answer. Example, I-129 questions will
                      be viewable to Petitioners in Answer Questions Section. If
                      not clicked they are not Mandatory are not viewable unless
                      you add them to a specific case. This may include a series
                      of potential questions like a Business Plan or Adjustment
                      of Status questions.
                    </Typography>

                    {watch('sectionType') === 'contract' && (
                      <Typography component={'div'} className="custom-field">
                        <Typography component={'div'} className="custom-label">
                          Documents{' '}
                          <Typography component={'span'} className="Mui-error">
                            *
                          </Typography>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name="templateId"
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <CustomSelect
                                labelKey={'template_name'}
                                valueKey={'id'}
                                options={
                                  !_.isEmpty(visaDocuments) ? visaDocuments : []
                                }
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeHolder={'Select'}
                                className={'custom-input'}
                                error={errors.templateId?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Section Type{' '}
                        <Typography component={'span'} className="Mui-error">
                          *
                        </Typography>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="sectionType"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <CustomSelect
                              labelKey={'name'}
                              valueKey={'id'}
                              options={sectionTypes}
                              name={name}
                              value={value}
                              onChange={onChange}
                              placeHolder={'Select'}
                              className={'custom-input'}
                              error={errors.sectionType?.message}
                              tabIndex={2}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className="custom-field">
                      <Typography component={'div'} className="custom-label">
                        Status
                      </Typography>
                      <div className="switch-field">
                        <input
                          type="radio"
                          id="switch_left"
                          name="switchToggle"
                          value={'false'}
                          onChange={onToggleStatus}
                          checked={!statusToggle}
                          ref={inActiveStatusInput}
                          tabIndex={4}
                        />
                        <label
                          htmlFor="switch_left d-flex-ja"
                          onClick={() => inActiveStatusInput.current.click()}>
                          Inactive
                        </label>

                        <input
                          type="radio"
                          id="switch_right"
                          name="switchToggle"
                          value={'true'}
                          onChange={onToggleStatus}
                          checked={statusToggle}
                          ref={activeStatusInput}
                        />
                        <label
                          htmlFor="switch_right d-flex-ja"
                          onClick={() => activeStatusInput.current.click()}>
                          Active
                        </label>
                      </div>
                    </Typography>
                    {!_.isUndefined(editId) && incVisaCode.length > 0 ? (
                      <Typography
                        component={'div'}
                        className="custom-field d-flex-a"
                        style={{ paddingTop: ' 94px ' }}>
                        <Typography component={'div'} className="custom-label">
                          Included In Visa Types
                        </Typography>
                        {incVisaCode.map((visa, vIndex) => (
                          <Typography
                            component={'div'}
                            className="u-pl-1"
                            key={vIndex}>
                            {visa}
                          </Typography>
                        ))}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Typography>

            {/* {_.isUndefined(editId) && (
                <Grid container>
                  <Grid item md={12}>
                    <Typography component={'div'} className="d-flex-ja my-2">
                      <Button className="cancel-btn mr-2" onClick={cancelForm}>
                        <Typography className="o-box" />
                        Cancel
                      </Button>
                      <Button
                        className="add-btn"
                        type="submit"
                        form={'add-section'}>
                        Submit
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              )} */}
            {!_.isUndefined(editId) && (
              <QuestionMapping
                qData={questionDetails}
                setConfirmOpen={setConfirmOpen}
                open={confirmOpen}
                editId={editId}
                cancelForm={cancelForm}
                setQuestionData={setQuestionDetails}
              />
            )}
          </form>
        </Grid>
      )}
    </Grid>
  );
};

export default QuestionAdd;
