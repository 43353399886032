import { Button, Typography } from '@mui/material';
import Avator from 'assets/images/d-avator.svg';
// import Pagination from 'components/Pagination';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { fileApiUrl } from 'utils/Config';

type ListPropsTypes = {
  cases: any;
  caseKey: any;
  setCasesKey: any;
};
// const PAGE_LIMIT = 10;
const Cases: React.FC<any> = (props: ListPropsTypes) => {
  const { cases, setCasesKey } = props;
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [selectedMyCase, setSelectedMyCase] = useState<any>({
    currentIndex: 0,
    selectedPerson: {},
  });

  const getSelectedCaseList = (value, index) => {
    setSelectedMyCase({
      currentIndex: index,
      selectedPerson: value,
    });
  };

  useEffect(() => {
    if (cases && !_.isEmpty(cases.data)) {
      setSelectedMyCase((pre) => ({
        ...pre,
        selectedPerson: cases.data[0],
      }));
    } else {
      setSelectedMyCase((pre) => ({
        currentIndex: 0,
        selectedPerson: {},
      }));
    }
  }, [cases]);

  return (
    <Typography component={'div'}>
      <Typography className="c-title black-white-text">My Cases</Typography>
      <Typography component={'div'} className="cp-box">
        <Typography component={'div'} className="cp-flex-4">
          <Typography component={'div'}>
            {cases && cases.data && cases.data.length > 0 ? (
              <InfiniteScroll
                dataLength={cases.data.length}
                next={() => {
                  if (cases.data.length < cases.total) {
                    setCasesKey((prev) => ({
                      ...prev,
                      skip: Number(prev.skip) + Number(prev.take),
                      take: prev.take,
                    }));
                  }
                }}
                hasMore={cases.data.length < cases.total}
                loader={
                  <div className="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                }
                height={625}
                scrollableTarget="scrollableDiv">
                {cases.data.map((myCase: any, sIndex: number) => (
                  <Typography
                    component={'div'}
                    className={`cp-item pay-container d-flex-a ${
                      sIndex === selectedMyCase.currentIndex
                        ? 'selectColor'
                        : null
                    }`}
                    key={sIndex}
                    onClick={() => getSelectedCaseList(myCase, sIndex)}
                    style={{ cursor: 'pointer' }}>
                    <Typography
                      component={'div'}
                      sx={{ width: '75%', wordWrap: 'break-word' }}>
                      <Typography
                        component={'h2'}
                        className="cp-name black-white-text">
                        {myCase.visa_name_list} {myCase.beneficiaryName}{' '}
                        {moment(myCase.created_at).format('DD MMM, yyyy')}
                      </Typography>
                      <Typography component={'p'} className="cp-by">
                        Attorney: {myCase.attorney_user_name}
                      </Typography>
                    </Typography>
                    <Typography
                      component={'div'}
                      className="d-flex-a d-flex-sb"
                      sx={{
                        minWidth: '260px',
                      }}>
                      <Button
                        component={'div'}
                        className="n-add-btn"
                        sx={{
                          height: '35px !important',
                          width: '50px !important',
                        }}
                        onClick={() =>
                          navigate(
                            myCase.petitionId
                              ? `/${String(
                                  userDetail.userRoleName,
                                )}/petitions/overview/${String(
                                  myCase.petitionId,
                                )}`
                              : `/${String(
                                  userDetail.userRoleName,
                                )}/petitions/lead-details/${String(
                                  myCase.lead_id,
                                )}`,
                          )
                        }>
                        View
                      </Button>
                      <Typography
                        sx={{ margin: '0 32px' }}
                        component={'h6'}
                        className="cp-status">
                        {myCase.lead_status}
                      </Typography>
                    </Typography>
                    {/* <Typography component={'div'} className="cp-flex-3">
                      <Typography
                        component={'div'}
                        className="cp-name black-white-text">
                        {myCase.visa_name_list} {myCase.beneficiaryName}{' '}
                        {moment(myCase.created_at).format('DD MMM, yyyy')}
                      </Typography>
                      <Typography component={'div'} className="cp-by">
                        Attorney: {myCase.attorney_user_name}
                      </Typography>
                    </Typography> */}
                    {/* <Typography
                      component={'div'}
                      className="cp-flex-1 d-flex-jae">
                      <Typography component={'div'} className="cp-status">
                        {myCase.lead_status}
                      </Typography>
                    </Typography> */}
                  </Typography>
                ))}
              </InfiniteScroll>
            ) : (
              <Typography
                className="note-box2"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                <Typography className="inb2" />
                <Typography className="black-white-text ctitle hideshow">
                  No Record(s) Found.
                </Typography>
              </Typography>
            )}
          </Typography>
          {/* <Pagination
            total={!_.isEmpty(cases) ? Number(cases.total) : 0}
            skip={caseKey.skip}
            take={caseKey.take}
            setParams={setCasesKey}
          /> */}
        </Typography>
        {!_.isEmpty(selectedMyCase.selectedPerson) && (
          <Typography component={'div'} className="cp-flex-1">
            <Typography component={'div'} className="cp-info-box">
              <Typography component={'div'} className="cp-inner" />
              <Typography component={'div'} className="d-flex-ja flex-column">
                <img
                  src={
                    selectedMyCase.selectedPerson?.beneficiaryImage
                      ? `${fileApiUrl}${String(
                          selectedMyCase.selectedPerson?.beneficiaryImage,
                        )}`
                      : Avator
                  }
                  alt="profile"
                />
                <Typography className="black-white-text in-text">
                  {selectedMyCase.selectedPerson?.visa_name_list}{' '}
                  {selectedMyCase.selectedPerson?.beneficiaryName}
                  {'\n'}
                  <span>
                    {moment(selectedMyCase.selectedPerson?.created_at).format(
                      'DD MMM, yyyy',
                    )}
                  </span>
                </Typography>
                <Typography className="cp-status">
                  {selectedMyCase.selectedPerson?.lead_status === 'petition'
                    ? 'Open petition'
                    : selectedMyCase.selectedPerson?.lead_status}
                </Typography>
              </Typography>
              <Typography
                component={'div'}
                style={{ gap: '1px' }}
                className="d-flex-a">
                <Typography component={'div'} className="p-box">
                  <Typography component={'div'} className="p-inner" />
                  <Typography className="black-white-text mb-1 f-14">
                    Last Activity:
                  </Typography>
                  <Typography className="italic-status">
                    {selectedMyCase.selectedPerson?.contract_updated_at
                      ? moment(
                          selectedMyCase.selectedPerson?.contract_updated_at,
                        ).format('DD MMM, yyyy')
                      : '-'}
                  </Typography>
                </Typography>
                <Typography component={'div'} className="p-box">
                  <Typography component={'div'} className="p-inner" />
                  <Typography className="black-white-text mb-1 f-14">
                    Fees:
                  </Typography>
                  <Typography className="italic-status">
                    {selectedMyCase.selectedPerson?.payment_total
                      ? selectedMyCase.selectedPerson?.payment_total
                      : '-'}
                  </Typography>
                </Typography>
              </Typography>
              <Typography className="team-text black-white-text">
                TEAM MEMBERS
              </Typography>
              <Typography
                component={'div'}
                className="team-box d-flex d-flex-sa">
                <Typography component={'div'} className="d-flex-j flex-column">
                  <img
                    src={
                      selectedMyCase.selectedPerson?.attorneyImage
                        ? `${fileApiUrl}${String(
                            selectedMyCase.selectedPerson?.attorneyImage,
                          )}`
                        : Avator
                    }
                    className="team-profile"
                    alt="avatar"
                  />
                  <Typography className="team-name  black-white-text">
                    {selectedMyCase.selectedPerson?.attorney_user_name
                      ? selectedMyCase.selectedPerson?.attorney_user_name
                      : '-'}
                  </Typography>
                  <Typography className="team-role">Attorney</Typography>
                </Typography>
                <Typography component={'div'} className="d-flex-j flex-column">
                  <img src={Avator} alt="avatar" className="team-profile" />

                  {selectedMyCase?.selectedPerson?.case_managers
                    ? selectedMyCase?.selectedPerson?.case_managers?.map(
                        (user: any, index: number) => (
                          <Typography
                            key={index}
                            className="f-12 black-white-text">
                            {user?.first_name} {user?.last_name}
                            {selectedMyCase?.selectedPerson?.case_managers
                              ?.length -
                              1 >
                            index
                              ? ', '
                              : ''}
                          </Typography>
                        ),
                      )
                    : '-'}
                  <Typography className="team-role">Case Manager</Typography>
                </Typography>
                <Typography component={'div'} className="d-flex-j flex-column">
                  <img
                    src={
                      selectedMyCase.selectedPerson?.petitionerImage
                        ? `${fileApiUrl}${String(
                            selectedMyCase.selectedPerson?.petitionerImage,
                          )}`
                        : Avator
                    }
                    alt="avatar"
                    className="team-profile"
                  />
                  <Typography className="team-name black-white-text">
                    {selectedMyCase.selectedPerson?.petitionerName
                      ? selectedMyCase.selectedPerson?.petitionerName
                      : '-'}
                  </Typography>
                  <Typography className="team-role">
                    Petitioner Contact
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
          </Typography>
        )}
      </Typography>
    </Typography>
  );
};

export default React.memo(Cases);
