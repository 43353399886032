import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

export const InviteConfirmation: React.FC<any> = ({ userDetail }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [referredByInfo, setReferredByInfo] = useState<any>({});

  const handleOnConfirm = (action: string) => {
    if (action === 'yes') {
      updateData(
        referredByInfo?.id,
        { status: true },
        `${String(SETUP_ENDPOINT.ManageOrganization)}`,
      ).then((res) => {
        toast.success('Invitation accepted successfully.', alertOptions);
        setOpenDialog(false);
      });
    } else {
      setOpenDialog(false);
    }
    localStorage.removeItem('orgHash');
  };
  useEffect(() => {
    const hash = localStorage.getItem('orgHash');
    if (hash) {
      getAllListData(
        `${String(SETUP_ENDPOINT.ManageOrganization)}?hash=${String(hash)}`,
      )
        .then((resp: any) => {
          if (resp?.firm_mail === userDetail?.email_id) {
            setOpenDialog(true);
            setReferredByInfo(resp);
          }
        })
        .catch((e: any) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ConfirmDialog
      title="Invite Confirmation!"
      floatingBtnShow={true}
      open={openDialog}
      close={() => {
        setOpenDialog(false);
      }}
      autoCloseDisable={true}
      onConfirm={(act) => handleOnConfirm(act)}>
      {'You have invited from '} {referredByInfo?.referred_by}
    </ConfirmDialog>
  );
};

export default InviteConfirmation;
