import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Typography,
} from '@mui/material';
// import Bdownload from 'assets/images/b-download.png';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import InfinityScroll from 'components/InfinityScroll';
// import Pagination from 'components/Pagination';
import { AdminPrivateRoutes, AttorneyPrivateRoutes } from 'config';
import { saveAs } from 'file-saver';
import { instantState, useDebounce } from 'hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData, getS3file } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';

import useStyles from '../../PrivateStyle';

const ContractTypes = [
  {
    label: 'Document',
    value: 'document',
  },
  {
    label: 'Contract',
    value: 'contract',
  },
  {
    label: 'Dependent',
    value: 'dependent',
  },
  {
    label: 'Dependent Forms',
    value: 'dependent-form',
  },
];
export const DocumentTemplate: React.FC<any> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [params, setParams] = useState<any>({
    documentType: '',
    id: '',
    order: 'DESC',
    skip: 0,
    take: 50,
    templateName: '',
    templateType: '',
  });
  const [loader, setLoader] = useState(true);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [tabValue, setTabValue] = useState(
    userDetail.role_id === 3 ? 'attorney-template' : 'admin-template',
  );
  const debouncedParams = useDebounce(params, 500);

  const [adminDocumentTemplates, setAdminDocumentTemplates] = useState<any>({
    admin: [],
    adminCount: 0,
  });
  const [attornyDocumentTemplates, setAttorneyDocumentTemplates] =
    useState<any>({
      attorney: [],
      attorneyCount: 0,
    });

  const downloadFile = (file: string) => {
    const obj = {
      key: file,
    };

    const fileName = file.split('/').pop();
    setLoader(true);
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(
          `${String(fileName)} File downloaded successfully`,
          alertOptions,
        );
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err ? err : 'something Went Wrong', alertOptions);
      });
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 'admin-template') {
      setAdminDocumentTemplates({
        admin: [],
        adminCount: 0,
      });
    } else {
      setAttorneyDocumentTemplates({
        attorney: [],
        attorneyCount: 0,
      });
    }
    setParams((pre) => ({
      ...pre,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      skip: 0,
      take: 50,
    }));
    setTabValue(newValue);
  };
  useEffect(() => {
    getAllListData(
      `${SETUP_ENDPOINT.DocumentTemplate}?order=${String(
        debouncedParams.order,
      )}&templateName=${String(
        debouncedParams.templateName,
      )}&templateType=${String(
        debouncedParams.templateType,
      )}&documentType=${String(debouncedParams.documentType)}&id=${String(
        debouncedParams.id,
      )}&skip=${String(debouncedParams.skip)}&take=${String(
        debouncedParams.take,
      )}`,
    )
      .then(async (resp: any) => {
        if (tabValue === 'admin-template') {
          const { admin, adminCount: total } = resp
            ? resp
            : { admin: [], adminCount: 0 };
          const moreLoader = await instantState(setGetMoreLoader);
          if (moreLoader) {
            setAdminDocumentTemplates((pre) => ({
              ...pre,
              admin: [...pre.admin, ...admin],
            }));
          } else {
            const idsEle = document.getElementById('scrollParent');
            idsEle && (idsEle.scrollTop = 0);
            const mergeData = !_.isEmpty(adminDocumentTemplates.admin)
              ? [...adminDocumentTemplates.admin, ...resp.admin]
              : resp.admin;
            setAdminDocumentTemplates({
              admin: mergeData,
              adminCount: resp.adminCount,
            });
          }
          setTableLoader(false);
          setGetMoreLoader(false);
          const allList = await instantState(setAdminDocumentTemplates);
          if (allList.admin && allList.admin.length < Number(total)) {
            setHasMoreResults(true);
          } else {
            setHasMoreResults(false);
          }
        } else {
          const { attorney, attorneyCount: total } = resp
            ? resp
            : { attorney: [], attorneyCount: 0 };
          const moreLoader = await instantState(setGetMoreLoader);
          if (moreLoader) {
            setAttorneyDocumentTemplates((pre) => ({
              ...pre,
              attorney: [...pre.attorney, ...attorney],
            }));
          } else {
            const idsEle = document.getElementById('scrollParent');
            idsEle && (idsEle.scrollTop = 0);
            const mergeData = !_.isEmpty(attornyDocumentTemplates.attorney)
              ? [...attornyDocumentTemplates.attorney, ...resp.attorney]
              : resp.attorney;
            setAttorneyDocumentTemplates({
              attorney: mergeData,
              attorneyCount: resp.attorneyCount,
            });
            setTableLoader(false);
            setGetMoreLoader(false);
            const allList = await instantState(setAttorneyDocumentTemplates);
            if (allList.attorney && allList.attorney.length < Number(total)) {
              setHasMoreResults(true);
            } else {
              setHasMoreResults(false);
            }
          }
        }
        setLoader(false);
      })
      .catch((e: any) => {
        setTableLoader(false);
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);
  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };
  return (
    <Grid container columnSpacing={{ md: 2, sm: 1 }}>
      <Grid item md={10} sm={12} className="pr-2rem">
        <Box sx={{ width: '100%' }} className="documentTab">
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Typography
                component={'div'}
                className="d-flex-a w-100 d-flex-sb mt-2">
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example">
                  {userDetail.role_id === 3 && (
                    <Tab label="Attorney Template" value="attorney-template" />
                  )}
                  <Tab label="Admin Template" value="admin-template" />
                </TabList>
                <Typography
                  component={'div'}
                  className="d-flex-jae full-screen-searc">
                  <Typography
                    component={'div'}
                    className="d-flex-a small-search"
                    sx={{
                      // backgroundColor: '#84898c',
                      borderRadius: '4px',
                    }}>
                    <Typography
                      className="d-flex-a searc-box1"
                      component={'div'}
                      sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: '4px',
                        marginRight: '.5rem',
                        width: '175px',
                      }}>
                      <SearchIcon
                        sx={{
                          fill: '#3d3f40 !important',
                          left: '5px',
                          position: 'absolute',
                          zIndex: '1',
                        }}
                      />
                      <CustomInput
                        placeHolder={'Template name'}
                        className={'custom-search-input bo-rles'}
                        value={params.templateName}
                        inputProps={{
                          endAdornment:
                            params.templateName !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    if (tabValue === 'admin-template') {
                                      setTableLoader(true);
                                      setAdminDocumentTemplates({
                                        admin: [],
                                        adminCount: 0,
                                      });
                                    } else {
                                      setTableLoader(true);
                                      setAttorneyDocumentTemplates({
                                        attorney: [],
                                        attorneyCount: 0,
                                      });
                                    }
                                    setParams((prev) => ({
                                      ...prev,
                                      skip: 0,
                                      take: 50,
                                      templateName: '',
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          if (tabValue === 'admin-template') {
                            setTableLoader(true);

                            setAdminDocumentTemplates({
                              admin: [],
                              adminCount: 0,
                            });
                          } else {
                            setTableLoader(true);

                            setAttorneyDocumentTemplates({
                              attorney: [],
                              attorneyCount: 0,
                            });
                          }
                          setParams((prev) => ({
                            ...prev,
                            skip: 0,
                            take: 50,
                            templateName: e.target.value,
                          }));
                        }}
                      />
                    </Typography>
                    <Typography
                      sx={{
                        borderRadius: '4px',
                        marginRight: '.5rem',
                        paddingLeft: 'unset !important',
                        position: 'relative',
                        width: '175px',
                      }}
                      className="d-flex-a"
                      component={'div'}>
                      <SearchIcon
                        sx={{
                          fill: '#3d3f40 !important',
                          left: '5px',
                          position: 'absolute',
                          zIndex: '1',
                        }}
                      />
                      <CustomInput
                        placeHolder={'Description '}
                        className={'custom-search-input bo-rles in-pad-se'}
                        value={params.templateType}
                        inputProps={{
                          endAdornment:
                            params.templateType !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    if (tabValue === 'admin-template') {
                                      setTableLoader(true);

                                      setAdminDocumentTemplates({
                                        admin: [],
                                        adminCount: 0,
                                      });
                                    } else {
                                      setTableLoader(true);

                                      setAttorneyDocumentTemplates({
                                        attorney: [],
                                        attorneyCount: 0,
                                      });
                                    }
                                    setParams((prev) => ({
                                      ...prev,
                                      skip: 0,
                                      take: 50,
                                      templateType: '',
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          if (tabValue === 'admin-template') {
                            setTableLoader(true);

                            setAdminDocumentTemplates({
                              admin: [],
                              adminCount: 0,
                            });
                          } else {
                            setTableLoader(true);

                            setAttorneyDocumentTemplates({
                              attorney: [],
                              attorneyCount: 0,
                            });
                          }
                          setParams((prev) => ({
                            ...prev,
                            skip: 0,
                            take: 50,
                            templateType: e.target.value,
                          }));
                        }}
                      />
                    </Typography>
                    <Typography sx={{ width: '150px' }} className="searc-box3">
                      <CustomSelect
                        searchable={true}
                        labelKey={'label'}
                        valueKey={'value'}
                        options={ContractTypes}
                        selectedValue={''}
                        onChange={(e) => {
                          if (tabValue === 'admin-template') {
                            setTableLoader(true);

                            setAdminDocumentTemplates({
                              admin: [],
                              adminCount: 0,
                            });
                          } else {
                            setTableLoader(true);

                            setAttorneyDocumentTemplates({
                              attorney: [],
                              attorneyCount: 0,
                            });
                          }
                          setParams((prev) => ({
                            ...prev,
                            documentType: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                        placeHolder={'Select'}
                        className={'custom-search-input'}
                      />
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Box>

            <Typography component={'div'}>
              {/* <Typography component={'div'} className="d-flex-jae w-100 mt-2"> */}
              {/* <Grid item md={12} sm={12}> */}

              {/* </Grid> */}
              {/* </Typography> */}
              <TabPanel value="admin-template">
                <Typography
                  component={'div'}
                  sx={{
                    borderRadius: '8px 8px 0 0',
                  }}
                  className={classes.scrollbox}
                  id="scrollParent">
                  <InfiniteScroll
                    dataLength={
                      adminDocumentTemplates &&
                      adminDocumentTemplates.admin &&
                      adminDocumentTemplates.admin.length
                        ? adminDocumentTemplates.admin.length
                        : 0
                    }
                    style={{
                      overflow: 'unset',
                    }}
                    next={getMoreList && getMoreList}
                    hasMore={hasMoreResults}
                    scrollableTarget={'scrollParent'}
                    loader={
                      getMoreLoader && (
                        <div
                          style={{
                            backgroundColor: '#ffffff',
                            textAlign: 'center',
                            width: '100%',
                          }}>
                          <CircularProgress
                            size={30}
                            sx={{
                              color: '#002c69',
                              marginBottom: 10,
                              marginTop: 10,
                            }}
                          />
                        </div>
                      )
                    }
                    endMessage>
                    <table className="lead-table table-hover">
                      <thead>
                        <tr>
                          <th className="W-4">template name</th>
                          <th className="W-3"> Descriptions</th>
                          <th className="W-2"> type</th>
                          {userDetail.role_id === 3 && (
                            <th className="W-1">action</th>
                          )}
                        </tr>
                      </thead>
                      <tbody className={userDetail.role_id === 1 && 'pointer'}>
                        {tableLoader ? (
                          <tr>
                            <td colSpan={8}>
                              <div
                                style={{
                                  backgroundColor: '#ffffff',
                                  textAlign: 'center',
                                  width: '100%',
                                }}>
                                <CircularProgress
                                  size={30}
                                  sx={{
                                    color: '#002c69',
                                    marginBottom: 10,
                                    marginTop: 10,
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>
                            {adminDocumentTemplates &&
                            adminDocumentTemplates.admin &&
                            adminDocumentTemplates.admin.length > 0
                              ? adminDocumentTemplates.admin.map(
                                  (item, index) => (
                                    <tr
                                      key={index}
                                      onClick={() =>
                                        navigate(
                                          userDetail.role_id === 3 &&
                                            item.role_id === 1
                                            ? ''
                                            : userDetail.role_id === 1 &&
                                                `${
                                                  AdminPrivateRoutes.DOCUMENT
                                                    .DOCUMENTEDIT
                                                }/${String(item.id)}`,
                                        )
                                      }>
                                      <td>
                                        {item.template_name
                                          ? item.template_name
                                          : '-'}
                                      </td>
                                      <td>
                                        {item.template_type
                                          ? item.template_type
                                          : '-'}
                                      </td>
                                      <td>
                                        {item.template_added_for
                                          ? item.template_added_for
                                          : '-'}
                                      </td>
                                      {userDetail.role_id === 3 &&
                                        item.role_id === 1 && (
                                          <td>
                                            <Button
                                              variant="contained"
                                              style={{
                                                backgroundColor: '#002c69',
                                                borderRadius: '20px',
                                                fontSize: '12px',
                                                height: '24px',
                                                lineHeight: '1',
                                                minWidth: '80px',
                                                opacity: '1',
                                              }}
                                              onClick={() =>
                                                downloadFile(
                                                  item?.template_file_path,
                                                )
                                              }>
                                              Download
                                            </Button>
                                          </td>
                                        )}
                                    </tr>
                                  ),
                                )
                              : !tableLoader && (
                                  <tr className={'text-center'}>
                                    <td colSpan={4}>No Record(s) Found.</td>
                                  </tr>
                                )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </Typography>
              </TabPanel>
              {userDetail.role_id === 3 && (
                <TabPanel value="attorney-template">
                  <Typography
                    component={'div'}
                    sx={{
                      borderRadius: '8px 8px 0 0',
                    }}
                    className={classes.scrollbox}
                    id="scrollParent">
                    <InfiniteScroll
                      dataLength={
                        attornyDocumentTemplates &&
                        attornyDocumentTemplates.attorney &&
                        attornyDocumentTemplates.attorney.length
                          ? attornyDocumentTemplates.attorney.length
                          : 0
                      }
                      style={{
                        overflow: 'unset',
                      }}
                      next={getMoreList && getMoreList}
                      hasMore={hasMoreResults}
                      scrollableTarget={'scrollParent'}
                      loader={
                        getMoreLoader && (
                          <div
                            style={{
                              backgroundColor: '#ffffff',
                              textAlign: 'center',
                              width: '100%',
                            }}>
                            <CircularProgress
                              size={30}
                              sx={{
                                color: '#002c69',
                                marginBottom: 10,
                                marginTop: 10,
                              }}
                            />
                          </div>
                        )
                      }
                      endMessage>
                      <table className="lead-table table-hover">
                        <thead>
                          <tr>
                            <th className="W-5">template Name</th>
                            <th className="W-4"> Descriptions</th>
                            <th className="W-1"> Type</th>
                          </tr>
                        </thead>
                        <tbody className="pointer">
                          {tableLoader ? (
                            <tr>
                              <td colSpan={8}>
                                <div
                                  style={{
                                    backgroundColor: '#ffffff',
                                    textAlign: 'center',
                                    width: '100%',
                                  }}>
                                  <CircularProgress
                                    size={30}
                                    sx={{
                                      color: '#002c69',
                                      marginBottom: 10,
                                      marginTop: 10,
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ) : (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                              {attornyDocumentTemplates &&
                              attornyDocumentTemplates.attorney &&
                              attornyDocumentTemplates.attorney.length > 0
                                ? attornyDocumentTemplates.attorney.map(
                                    (item, index) => (
                                      <tr
                                        key={index}
                                        onClick={() =>
                                          navigate(
                                            userDetail.role_id === 3 &&
                                              item.role_id === 1
                                              ? ''
                                              : userDetail.role_id === 3 &&
                                                  `${
                                                    AttorneyPrivateRoutes
                                                      .DOCUMENT.DOCUMENTEDIT
                                                  }/${String(item.id)}`,
                                          )
                                        }>
                                        <td>
                                          {item.template_name
                                            ? item.template_name
                                            : '-'}
                                        </td>
                                        <td>
                                          {item.template_type
                                            ? item.template_type
                                            : '-'}
                                        </td>
                                        <td>
                                          {item.template_added_for
                                            ? item.template_added_for
                                            : '-'}
                                        </td>
                                      </tr>
                                    ),
                                  )
                                : !tableLoader && (
                                    <tr className={'text-center'}>
                                      <td colSpan={4}>No Record(s) Found.</td>
                                    </tr>
                                  )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </Typography>
                </TabPanel>
              )}
            </Typography>
            {loader ? <div className="liberty-loader" /> : ''}
          </TabContext>
        </Box>
      </Grid>
      <Grid item md={2} sm={12}>
        {userDetail.role_id === 3 && tabValue === 'attorney-template' && (
          <Typography component={'div'} className="add-box">
            <Typography component={'div'} className="add-inner-box" />
            {/* <Typography className=" user-dis pt-5 ">
              Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do
              eiusmod temporinci d idunt ut labore
              <Typography />
            </Typography> */}
            <Typography
              className=" built-option d-flex-a mb-1 mt-2"
              onClick={() =>
                navigate(
                  `${String(AttorneyPrivateRoutes.DOCUMENT.DOCUMENTADD)}`,
                )
              }>
              {' '}
              <span className="plus d-flex-ja">+</span>
              Add New Template
            </Typography>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default DocumentTemplate;
