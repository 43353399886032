import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avator from 'assets/images/d-avator.svg';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import InfoMessage from 'components/InfoMessage';
// import InfinityScroll from 'components/InfinityScroll';
// import Pagination from 'components/Pagination';
import {
  AdminPrivateRoutes,
  AttorneyPrivateRoutes,
  PetitionerPrivateRoutes,
  PublicRoutes,
} from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { logout } from 'store/actions/UserAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fileApiUrl } from 'utils/Config';
// import { fileApiUrl } from 'utils/Config';
import {
  COMMON_ENDPOINT,
  MASTER_ENDPOINT,
  SETUP_ENDPOINT,
} from 'utils/Constant';

import useStyles from '../../PrivateStyle';

const Status = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
];
export const ManageUserList: React.FC<any> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [params, setParams] = useState<any>({
    name: '',
    skip: 0,
    status: 'active',
    take: 50,
  });
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [userData, setUserData] = useState<any>({ count: 0, data: [] });
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const debouncedParams = useDebounce(params, 500);
  const [subscriptionData, setSubscriptionData] = useState<any>({});
  const [open, setOpen] = useState<any>({
    message: '',
    show: false,
  });

  const handleCloseMessage = () => {
    sessionStorage.setItem('show_popup', 'true');
    setOpen({ message: '', show: false });
  };

  const planUpgrade = async () => {
    await getAllListData(MASTER_ENDPOINT.StripeUpgrade)
      .then((resp: any) => {
        window.location.href = resp.url;
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };

  const logoutCall = () => {
    dispatch(logout());
    navigate(PublicRoutes.LOGIN);
  };

  useEffect(() => {
    const roleName =
      userDetail.role_id === 2
        ? '/petitoner'
        : `/${String(userDetail.userRoleName)}`;
    getAllListData(
      roleName +
        `${SETUP_ENDPOINT.ManageUsers}?skip=${String(
          debouncedParams.skip,
        )}&take=${String(debouncedParams.take)}${
          debouncedParams.name !== ''
            ? '&name='.concat(debouncedParams.name)
            : ''
        }&status=${String(debouncedParams.status)}`,
    )
      .then(async (resp: any) => {
        const { data, count: total } = resp ? resp : { count: 0, data: [] };
        const moreLoader = await instantState(setGetMoreLoader);
        if (moreLoader) {
          setUserData((pre) => ({
            ...pre,
            data: [...pre.data, ...data],
          }));
        } else {
          const idsEle = document.getElementById('scrollParent');
          idsEle && (idsEle.scrollTop = 0);
          const mergeData = !_.isEmpty(userData.data)
            ? [...userData.data, ...resp.data]
            : resp.data;
          setUserData({
            count: resp.count,
            data: mergeData,
          });
        }
        setTableLoader(false);
        setLoader(false);
        setGetMoreLoader(false);
        const allList = await instantState(setUserData);
        if (allList.data && allList.data.length < Number(total)) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch((e: any) => {
        setTableLoader(false);
        setGetMoreLoader(false);
        setLoader(false);
        setHasMoreResults(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const getMoreList = () => {
    if (!tableLoader) {
      setGetMoreLoader(true);
      setParams((pre) => ({
        ...pre,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        skip: pre.skip + 50,
      }));
    }
  };

  useEffect(() => {
    if (userDetail.role_id === 3) {
      getAllListData(COMMON_ENDPOINT.subscriptionPlan)
        .then((resp: any) => {
          setSubscriptionData(resp);
          const showPopup = sessionStorage.getItem('show_popup');
          if (showPopup == null && resp.show_popup) {
            setOpen({ message: resp.info_message, show: resp.show_popup });
          }
        })
        .catch((e: any) => {
          setLoader(false);
          logoutCall();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Grid container>
      <Grid
        item
        md={10}
        sm={12}
        sx={{ paddingRight: '30px', paddingTop: '20px' }}>
        <Typography
          component={'div'}
          className="d-flex-jae full-screen-searc mb-1">
          <Typography
            component={'div'}
            className="d-flex-a small-search"
            sx={{ borderRadius: '4px' }}>
            <Typography
              sx={{
                borderRadius: '4px',
                marginRight: '.5rem',
                paddingLeft: 'unset !important',
                width: '205px',
              }}
              className="searc-box2">
              <CustomInput
                placeHolder={'Search'}
                className={'custom-search-input'}
                value={params.name}
                inputProps={{
                  endAdornment:
                    params.name !== '' ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => {
                            setTableLoader(true);
                            setUserData({
                              count: 0,
                              data: [],
                            });
                            setParams((prev) => ({
                              ...prev,
                              name: '',
                              skip: 0,
                              take: 50,
                            }));
                          }}>
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                }}
                onChange={(e) => {
                  setTableLoader(true);

                  setUserData({
                    count: 0,
                    data: [],
                  });
                  setParams((prev) => ({
                    ...prev,
                    name: e.target.value,
                    skip: 0,
                    take: 50,
                  }));
                }}
              />
            </Typography>
            <Typography sx={{ width: '205px' }} className="searc-box3">
              <CustomSelect
                isClearable={false}
                labelKey={'label'}
                valueKey={'value'}
                options={Status}
                value={params.status}
                onChange={(e) => {
                  setTableLoader(true);
                  setUserData({
                    count: 0,
                    data: [],
                  });
                  setParams((prev) => ({
                    ...prev,
                    skip: 0,
                    status: e.target.value,
                    take: 50,
                  }));
                }}
                placeHolder={'Status'}
                className={'custom-search-input'}
              />
            </Typography>
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={10} sm={12} className="pr-2rem">
        {loader ? (
          <div className="liberty-loader" />
        ) : (
          <Typography
            component={'div'}
            sx={{
              borderRadius: '8px 8px 0 0',
            }}
            id="scrollParent"
            className={classes.scrollbox}>
            <InfiniteScroll
              dataLength={userData.data.length > 0 ? userData.data.length : 0}
              style={{
                overflow: 'unset',
              }}
              next={getMoreList && getMoreList}
              hasMore={hasMoreResults}
              scrollableTarget={'scrollParent'}
              loader={
                getMoreLoader && (
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      textAlign: 'center',
                      width: '100%',
                    }}>
                    <CircularProgress
                      size={30}
                      sx={{
                        color: '#002c69',
                        marginBottom: 10,
                        marginTop: 10,
                      }}
                    />
                  </div>
                )
              }
              endMessage>
              <table className="lead-table table-hover">
                <thead>
                  <tr>
                    <th className="W-3">User Name</th>
                    <th className="W-1">Gender</th>
                    <th className="W-2">Email(User Name)</th>
                    <th className="W-1">Phone</th>
                    <th className="W-2">Mobile</th>
                    <th className="W-1">Role(S)</th>
                  </tr>
                </thead>
                <tbody>
                  {tableLoader ? (
                    <tr>
                      <td colSpan={8}>
                        <div
                          style={{
                            backgroundColor: '#ffffff',
                            textAlign: 'center',
                            width: '100%',
                          }}>
                          <CircularProgress
                            size={30}
                            sx={{
                              color: '#002c69',
                              marginBottom: 10,
                              marginTop: 10,
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : userData.data.length > 0 ? (
                    userData.data.map((user: any, index: number) => (
                      <tr
                        key={index}
                        onClick={() => {
                          navigate(
                            userDetail.role_id === 1
                              ? `${
                                  AdminPrivateRoutes.MANAGEUSER.USEREDIT
                                }/${String(user.id)}`
                              : userDetail.role_id === 3
                              ? `${
                                  AttorneyPrivateRoutes.MANAGEUSER.USEREDIT
                                }/${String(user.id)}`
                              : userDetail.role_id === 2
                              ? `${
                                  PetitionerPrivateRoutes.MANAGEUSER.USEREDIT
                                }/${String(user.id)}`
                              : '',
                          );
                        }}
                        style={{ cursor: 'pointer' }}>
                        <td
                          style={{
                            display: 'flex',
                            justifyContent: 'Unset',
                            minWidth: '100%',
                          }}>
                          <img
                            src={
                              !user?.image
                                ? Avator
                                : `${fileApiUrl}${String(user?.image)}`
                            }
                            alt="upic"
                            className={classes.userImg}
                          />
                          <span
                            style={{
                              paddingLeft: '12px',
                              paddingTop: '5px',
                            }}>
                            {user?.full_name}
                          </span>
                        </td>
                        <td>{user.gender ? user.gender : '-'}</td>
                        <td>{user?.email_id}</td>
                        <td>{user.user_phone ? user.user_phone : '-'}</td>
                        <td>
                          {user.contact_mobile ? user.contact_mobile : '-'}
                        </td>
                        <td>
                          <div className="hideshow">
                            {!_.isEmpty(user?.screenList)
                              ? user?.screenList[0]?.role_name
                              : '-'}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    !tableLoader && (
                      <tr className={'text-center'}>
                        <td colSpan={6}>No Record(s) Found.</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </InfiniteScroll>
          </Typography>
        )}
      </Grid>

      {Number(subscriptionData.total_user) >
      Number(subscriptionData.activeUserCount) ? (
        <Grid item md={2} sm={12}>
          <Typography component={'div'} className="add-box">
            <Typography component={'div'} className="add-inner-box" />
            <Typography
              className="built-option d-flex-a mt-1"
              onClick={() => {
                if (
                  Number(subscriptionData.total_user) >
                  Number(subscriptionData.activeUserCount)
                ) {
                  navigate(
                    `${String(AttorneyPrivateRoutes.MANAGEUSER.USERADD)}`,
                  );
                } else {
                  setLoader(true);
                  planUpgrade();
                }
              }}>
              {' '}
              <span className="plus d-flex-ja">+</span>
              {Number(subscriptionData.total_user) >
              Number(subscriptionData.activeUserCount)
                ? 'Add New User'
                : 'Upgrade Plan'}
            </Typography>
          </Typography>
        </Grid>
      ) : userDetail?.is_subscription_need ? (
        <Grid item md={2} sm={12}>
          <Typography component={'div'} className="add-box">
            <Typography component={'div'} className="add-inner-box" />
            <Typography
              className="built-option d-flex-a mt-1 pl-2 pt-2 pb-1"
              onClick={() => {
                navigate(`${String(AttorneyPrivateRoutes.SUBSCRIPTIONPLAN)}`);
              }}>
              <span className="plus d-flex-ja">+</span>
              {userDetail.customer_portal_show ? 'Upgrade Plan' : 'View Plan'}
            </Typography>
          </Typography>
        </Grid>
      ) : (
        userDetail.role_id === 3 && (
          <Grid item md={2} sm={12}>
            <Typography component={'div'} className="add-box">
              <Typography component={'div'} className="add-inner-box" />
              <Typography
                className="built-option d-flex-a mt-1"
                onClick={() => {
                  navigate(
                    `${String(AttorneyPrivateRoutes.MANAGEUSER.USERADD)}`,
                  );
                }}>
                {' '}
                <span className="plus d-flex-ja">+</span>
                Add New User
              </Typography>
            </Typography>
          </Grid>
        )
      )}
      <InfoMessage
        showManageUser={false}
        open={open.show}
        close={handleCloseMessage}
        infoMessage={open.message}
      />
    </Grid>
  );
};

export default ManageUserList;
