/* eslint-disable @typescript-eslint/no-unused-vars */
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { DialogContent } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import { AttorneyPrivateRoutes } from 'config';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';

export const Calendar: React.FC<any> = () => {
  const currentMonth = moment();
  const [loader, setLoader] = useState(false);
  const [notesModel, setNotesModel] = useState(false);
  const [data, setData] = useState<any>([]);
  const [selectedPetitionId, setSelectedPetitionId] = useState<any>();
  const [selectedId, setSelectedId] = useState<any>();
  const [name, setName] = useState({
    beneficiaryName: '',
    petitionername: '',
    visaType: '',
  });
  const [param, setParam] = useState<any>({
    endStr: '',
    startStr: '',
  });

  const navigate = useNavigate();
  const [notes, setNotes] = React.useState('');
  const handleClose = () => {
    setNotesModel(false);
  };
  const handleEventClick = (arg) => {
    setSelectedId(arg.event.groupId);
    setSelectedPetitionId(arg.event.id);
    const name = arg?.event?.title?.split(':').pop();
    setNotes(name);
    setNotesModel(true);
    setName((pre) => ({
      ...pre,
      beneficiaryName: arg?.event?._def?.extendedProps?.beneficiaryName,
      petitionername: arg?.event?._def?.extendedProps?.petitionerName,
      visaType: arg?.event?._def?.extendedProps?.visaType,
    }));
  };
  const handleEventMouseEnter = (arg) => {
    arg.el.style.cursor = 'pointer';
  };

  React.useEffect(() => {
    if (param.startStr) {
      getAllListData(
        `${String(
          COMMON_ENDPOINT.submissionStatus,
        )}/upload-document/calender?isNotify=true&notifyStartDate=${String(
          encodeURIComponent(moment(param.startStr).startOf('day').format()),
        )}&notifyEndDate=${String(
          encodeURIComponent(moment(param.endStr).endOf('day').format()),
        )}`,
      )
        .then((list: any) => {
          setData(
            list.data && Array.isArray(list.data)
              ? list.data.map((e) => ({
                  beneficiaryName: e.benificiery_name,
                  end: e.notifyDate,
                  groupId: e.id,
                  id: e.petition_id,
                  petitionerName: e.petitioner_name,
                  start: e.notifyDate,
                  title: `${String(
                    e.visa_type ? e.visa_type : '',
                  )}  for  ${String(
                    e.benificiery_name ? e.benificiery_name : '',
                  )}  notes : ${String(e.notifyNotes)}`,
                  visaType: e.visa_type,
                }))
              : [],
          );
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
        });
    }
  }, [param]);

  return (
    <>
      {loader ? <div className="liberty-loader" /> : ''}
      <div className="calendar">
        <div className="calendar-sidebar">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              center: 'title',
              left: 'prev,next today',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            displayEventTime={false}
            eventTimeFormat={{
              hour: 'numeric',
              meridiem: false,
            }}
            eventMaxStack={1}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            events={(fetchInfo, successCallback, failureCallback) => {
              const middate = new Date(
                (new Date(fetchInfo.startStr).getTime() +
                  new Date(fetchInfo.endStr).getTime()) /
                  2,
              );
              const startOfMonth = moment(middate).startOf('month').format();
              const endOfMonth = moment(middate).endOf('month').format();
              if (param.startStr !== startOfMonth) {
                setParam((prev) => ({
                  ...prev,
                  endStr: endOfMonth,
                  startStr: startOfMonth,
                }));
              }
              successCallback(data);
            }}
            eventClick={(e) => handleEventClick(e)}
            eventMouseEnter={handleEventMouseEnter}
          />
        </div>

        <CustomModalView
          open={notesModel}
          close={handleClose}
          title={'Notes'}
          scroll={'paper'}
          fullWidth={true}
          minHeight={'auto'}
          maxWidth={'md'}
          linkRedirect={true}
          petitionerName={name?.petitionername}
          beneficiaryName={name?.beneficiaryName}
          visaType={name?.visaType}
          onClickNavLink={() =>
            navigate(
              `${String(
                AttorneyPrivateRoutes.PETITIONS.SUBMISSIONSTATION,
              )}/${String(selectedPetitionId)}?Id=${String(selectedId)}`,
            )
          }>
          <DialogContent>
            <CustomInput
              className={'custom-textarea-note'}
              isTextArea={true}
              extraStyle={{
                fontFamily: 'Arial-Regular !important',
                fontSize: '16px',
                fontWeight: '500',
                height: '100%',
                width: '100%',
              }}
              minRows={6}
              value={notes}
            />
          </DialogContent>
        </CustomModalView>
      </div>
    </>
  );
};
