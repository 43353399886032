import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import _ from 'lodash';
// import Multiselect from 'multiselect-react-dropdown';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { addNewData } from 'services/CommonServices';
import { RequestType } from 'types';
import { COMMON_ENDPOINT } from 'utils/Constant';
import { alertOptions } from 'utils/helper';
import { PetitionerRequestlDocumentAddSchema } from 'utils/ValidatorSchema';

type PetitionerRequestlDocumentAddIterFace = {
  petitions: any;
  petitionId: any;
  moduleList: any[];
  setReloadAPI: any;
  reloadAPI: any;
};

export const PetitionerRequestlDocumentAdd: React.FC<
  PetitionerRequestlDocumentAddIterFace
> = ({ petitions, petitionId, moduleList, setReloadAPI, reloadAPI }) => {
  const [loader, setLoader] = useState(false);
  const onSubmitForm = (data: any) => {
    const responseBy = data['responsibility'] ? data['responsibility'] : '';
    data['responsibility'] =
      responseBy && Array.isArray(responseBy)
        ? responseBy.map((e) => Number(e))
        : [];
    const bodyObj = data;
    setLoader(true);
    addNewData(
      bodyObj,
      `${String(
        COMMON_ENDPOINT.RequestForRequiredDocument,
      )}/extra-docs/${String(petitionId)}`,
    )
      .then((resp: any) => {
        setLoader(false);
        setReloadAPI(reloadAPI ? false : true);
        resetForm();
        toast.success('Added successfully', alertOptions);
      })
      .catch((error: any) => {
        setLoader(false);
        toast.error(
          error ? error : 'Oops!!! Something went wrong',
          alertOptions,
        );
      });
  };

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    // setValue,
    // setError,
    // watch,
    formState: { errors },
  } = useForm<RequestType>({
    criteriaMode: 'all',
    defaultValues: {
      documentName: '',
      responsibility: null,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(PetitionerRequestlDocumentAddSchema),
  });

  return loader ? (
    <div className="liberty-loader" />
  ) : (
    <Box
      component="div"
      className="lead-box"
      style={{ marginTop: '1rem', padding: '1rem !important' }}>
      <form
        id="petitioner-document-add-form"
        noValidate
        onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container className="lead-box">
          <Grid item md={4}>
            <Typography component={'div'} className="custom-field u-pl-1">
              <Typography component={'div'} className="custom-label">
                Document Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Controller
                control={control}
                defaultValue=""
                name="documentName"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <CustomInput
                      placeHolder={'Document Name'}
                      name={name}
                      value={value}
                      className={'custom-input'}
                      error={errors.documentName?.message || ''}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography component={'div'} className="custom-field u-pl-1">
              <Typography component={'div'} className="custom-label">
                Responsibility <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Controller
                control={control}
                name="responsibility"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <CustomSelect
                      closeMenuOnSelect={false}
                      labelKey={'label'}
                      valueKey={'value'}
                      options={moduleList}
                      isMulti={true}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      placeHolder={'Select'}
                      className={'basic-multi-select'}
                      error={errors.responsibility?.message}
                      // disabled={allowAccess}
                    />
                    // <>
                    //   <Multiselect
                    //     {...field}
                    //     // inputRef={ref}
                    //     displayValue="module_name"
                    //     onSelect={(selected, item) => {
                    //       if (selected.length) {
                    //         setError('responsibility', {
                    //           message: '',
                    //           type: 'custom',
                    //         });
                    //       } else {
                    //         setError('responsibility', {
                    //           message: 'Responsibility is required',
                    //           type: 'custom',
                    //         });
                    //       }
                    //       setValue(
                    //         'responsibility',
                    //         selected
                    //           .map((module) => module.module_id)
                    //           .join(','),
                    //       );
                    //     }}
                    //     onRemove={(selected, item) => {
                    //       if (selected.length) {
                    //         setError('responsibility', {
                    //           message: '',
                    //           type: 'custom',
                    //         });
                    //       } else {
                    //         setError('responsibility', {
                    //           message: 'Responsibility is required',
                    //           type: 'custom',
                    //         });
                    //       }
                    //       setValue(
                    //         'responsibility',
                    //         selected
                    //           .map((module) => module.module_id)
                    //           .join(','),
                    //       );
                    //     }}
                    //     selectedValues={[]}
                    //     options={!_.isEmpty(moduleList) ? moduleList : []}
                    //   />
                    //   {errors.responsibility?.message && (
                    //     <FormHelperText style={{ color: '#FF0000' }}>
                    //       {errors.responsibility?.message}
                    //     </FormHelperText>
                    //   )}
                    // </>
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography
              component={'div'}
              className="d-flex-ja"
              style={{ marginTop: '25px' }}>
              <Button
                className="n-add-btn"
                sx={{
                  borderRadius: '5px !important',
                  float: 'right',
                  fontFamily: 'Arial-Regular !important',
                  fontSize: '0.9em !important',
                  height: '35px !important',
                  width: '149px !important',
                }}
                type="submit"
                form={'petitioner-document-add-form'}>
                Save
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default PetitionerRequestlDocumentAdd;
