export const PublicRoutes = {
  CREATEPASSWORD: '/new-password',
  FORGOTPASSWORD: '/forgot-password',
  LAWFIRM: '/attorney/signup',
  LOGIN: '/login',
  ORGANIZATION: '/petitioner/signup',
  PAYMENT: {
    ACH: '/secure-payment/transfer/ach',
    CONTRACTVIEWER: '/secure-payment/contract-viewer',
    CREDITDEBITCARD: '/secure-payment/transfer/card',
    PAYMENTTERMSANDCONDITIONS: '/secure-payment/payment-option',
    SECUREPAYMENT: '/secure-payment',
    STRIPEINPROCESS: '/secure-payment/processing',
    STRIPESUCCESS: '/secure-payment/success',
  },
  POLICY: {
    ACCEPTABLEUSEPOLICY: '/acceptable-use-policy',
    DMCAPOLICY: '/dmca-policy',
    PRIVACYPOLICY: '/privacy-policy',
    TERMOFSERVICES: '/client-agreement',
    VERSIONHISTORY: '/version-history/details',
  },
  REGISTRATIONFORM: '/beneficiary/signup',
  RESETPASSWORD: '/reset-password',
  SIGNUP: '/signup-type',
  STRIPE: {
    BILLINGDETAILS: '/payment-billing-details',
    CARDFORM: '/payment-card-form',
    FAILURE: '/payment-failure',
    PRICING: '/payment-pricing',
    SIGNUP: '/payment-signup-form',
    SUCCESS: '/payment-success',
  },
};

export const AdminPrivateRoutes = {
  ASSIGNATTORNEY: '/admin/petitions/assign-attorney',
  CHANGEPASSWORD: '/admin/change-password',
  DASHBOARD: '/admin/dashboard',
  DOCUMENT: {
    DOCUMENTADD: '/admin/setup/document-template/add-template',
    DOCUMENTEDIT: '/admin/setup/document-template/edit-template',
    DOCUMENTPARSING: '/admin/setup/document-parsing',
    DOCUMENTPARSINGVIEW: '/admin/setup/document-parsing/view',
    DOCUMENTTEMPLATE: '/admin/setup/document-template',
    DOCUMENTTPYEADD: '/admin/setup/document-types/add-manage-document-types',
    DOCUMENTTPYEEDIT: '/admin/setup/document-types/edit-manage-document-types',
    REARRANGE: '/admin/setup/document-types/order-changes',
    TYPES: '/admin/setup/document-types',
  },
  LEADS: {
    BUILDCASE: '/admin/petitions/lead-details/build-case',
    BUILDCASEASEMBLE: '/admin/petitions/lead-details/build-case/assemble',
    BUILDCASEEDIT: '/admin/petitions/lead-details/build-case/edit',
    COMPLETEQUESTION: '/admin/petitions/lead-details/complete-question',
    CONTRACTPAYMENT: '/admin/petitions/lead-details/contract-payment',
    CONTRACTPAYMENTDETAILS:
      '/admin/petitions/lead-details/contract-payment/details',
    LEADDETAILSLIST: '/admin/petitions/lead-details',
    LEADSADD: '/admin/petitions/add-leads',
    LEADSEDIT: '/admin/petitions/edit-leads',
    LEADSLIST: '/admin/petitions/leads',
    PAYMENTMETHOD:
      '/admin/petitions/lead-details/contract-payment/payment-methods',
    PAYMENTPENDINGLIST:
      '/admin/petitions/lead-details/contract-payment/payment-list',
    PAYMENTVIEW: '/admin/petitions/lead-details/contract-payment/payment/view',
    STRATEGY: '/admin/petitions/lead-details/strategy',
    STRATEGYDETAIL: '/admin/petitions/lead-details/strategy/details',
    SUBMISSIONSTATION:
      '/admin/petitions/petition-details/build-case/submission-station',
    UPLOADDOCUMENT: '/admin/petitions/lead-details/upload-document',
    UPLOADPETITIONSDOCUMENT:
      '/attorney/petitions/petitions-details/upload-document',
    VIEWEDITCONTRACTS:
      '/admin/petitions/lead-details/contract-payment/view-edit',
  },
  MANAGELAWFIRM: {
    ADDREPRESENTIVE: '/admin/setup/law-firm/add-representative',
    EDITREPTESENTIVE: '/admin/setup/law-firm/edit-representative',
    MANAGELAWFIRMADD: '/admin/setup/law-firm/add-law-firm',
    MANAGELAWFIRMEDIT: '/admin/setup/law-firm',
    MANAGELAWFIRMLIST: '/admin/setup/law-firm',
  },
  MANAGEORGANIZATION: {
    ORGANIZATIONADD: '/admin/setup/manage-organization/add-organization',
    ORGANIZATIONEDIT: '/admin/setup/manage-organization/edit-organization',
    ORGANIZATIONLIST: '/admin/setup/manage-organization',
  },
  MANAGEPETITIONERS: {
    CONTACTADD: '/admin/setup/petitioner/add-contact',
    CONTACTEDIT: '/admin/setup/petitioner/edit-contact',
    MANAGEPETITIONERADD: '/admin/setup/petitioner/add-petitioner',
    MANAGEPETITIONEREDIT: '/admin/setup/petitioner/edit',
    MANAGEPETITIONERSLIST: '/admin/setup/petitioner',
  },
  MANAGEUSER: {
    MANAGEUSERLIST: '/admin/setup/manage-users',
    USERADD: '/admin/setup/manage-users/add-user',
    USEREDIT: '/admin/setup/manage-users/edit-user',
  },
  MYPROFILE: '/admin/profile',
  PAYMENT: {
    ACH: '/secure-payment/transfer/ach',
    CONTRACTVIEWER: '/secure-payment/contract-viewer',
    CREDITDEBITCARD: '/secure-payment/transfer/card',
    PAYMENTTERMSANDCONDITIONS: '/secure-payment/payment-option',
    SECUREPAYMENT: '/secure-payment',
    STRIPEINPROCESS: '/secure-payment/processing',
    STRIPESUCCESS: '/secure-payment/success',
  },
  POLICY: {
    ACCEPTABLEUSEPOLICY: '/acceptable-use-policy',
    DMCAPOLICY: '/dmca-policy',
    PRIVACYPOLICY: '/privacy-policy',
    TERMOFSERVICES: '/client-agreement',
    VERSIONHISTORY: '/version-history/details',
  },
  QUESTION: {
    CONFIGUREQUESTION: '/admin/setup/configure-questions',
    QUESTIONADD: '/admin/setup/configure-questions/add-qestion',
    QUESTIONEDIT: '/admin/setup/configure-questions/edit-question',
    RADIOBUTTONCROSSPOPULATE: '/admin/setup/configure-question/radio-populate',
    RADIOBUTTONCROSSPOPULATEADD:
      '/admin/setup/configure-question/radio-populate/add',
  },
  ROLES: {
    ROLESADD: '/admin/setup/roles-permissions/add-roles-permissions',
    ROLESEDIT: '/admin/setup/roles-permissions/edit-roles-permissions',
    ROLESPERMISSION: '/admin/setup/roles-permissions',
  },
  SECTION: {
    CONFIGURESECTION: '/admin/setup/configure-section',
    SECTIONADD: '/admin/setup/configure-section/add',
    SECTIONEDIT: '/admin/setup/configure-section/edit-section',
  },
  SOCIALSHARE: '/admin/social',
  VERSIONHISTORY: '/version-history',
  VISATYPE: {
    VISATYPEADD: '/admin/setup/visa-types/add-visa-types',
    VISATYPEEDIT: '/admin/setup/visa-types/edit-visa-types',
    VISATYPELIST: '/admin/setup/visa-types',
  },
};

export const AttorneyPrivateRoutes = {
  CALENDAR: '/attorney/dashboard/calendar',
  CHANGEPASSWORD: '/attorney/change-password',
  CONTACTSUPPORT: '/attorney/contact-support',
  CONTRACT: {
    CONTRACTADD: '/attorney/setup/contract-fees/add-contract',
    CONTRACTEDIT: '/attorney/setup/contract-fees/edit-contract',
    CONTRACTFEES: '/attorney/setup/contract-fees',
  },
  DASHBOARD: '/attorney/dashboard',

  DOCUMENT: {
    DOCUMENTADD: '/attorney/setup/document-template/add-template',
    DOCUMENTEDIT: '/attorney/setup/document-template/edit-template',
    DOCUMENTPARSING: '/attorney/petitions/document-parsing-list',
    DOCUMENTPARSINGVIEW: '/attorney/petitions/document-parsing/view/:id',
    DOCUMENTPARSINGVIEWPARAM: '/attorney/petitions/document-parsing/view',
    DOCUMENTTEMPLATE: '/attorney/setup/document-template',
  },
  DOCUMENTI129PREVIEW: '/attorney/document-checklist-view/i-129/preview',
  DOCUMENTI129PREVIEWFOREXTRACTIONID:
    '/attorney/document-checklist-view/i-129/extraction-form',
  DOCUMENTPREVIEW: '/attorney/document-checklist-view/preview',
  LEADS: {
    COMPLETEQUESTION: '/attorney/petitions/lead-details/complete-question',
    CONTRACTPAYMENT: '/attorney/petitions/lead-details/contract-payment',
    CONTRACTPAYMENTDETAILS:
      '/attorney/petitions/lead-details/contract-payment/details',
    LEADDETAILSLIST: '/attorney/petitions/lead-details',
    LEADSADD: '/attorney/petitions/add-leads',
    LEADSEDIT: '/attorney/petitions/edit-leads',
    LEADSLIST: '/attorney/petitions/leads',
    PAYMENTMETHOD:
      '/attorney/petitions/lead-details/contract-payment/payment-methods',
    PAYMENTPENDINGLIST:
      '/attorney/petitions/lead-details/contract-payment/payment-list',
    PAYMENTVIEW:
      '/attorney/petitions/lead-details/contract-payment/payment/view',
    REVIEWDOCUMENT: '/attorney/petitions/lead-details/review-document',
    STRATEGY: '/attorney/petitions/lead-details/strategy',
    STRATEGYDETAIL: '/attorney/petitions/lead-details/strategy/details',
    UPLOADDOCUMENT: '/attorney/petitions/lead-details/upload-document',
    UPLOADLEADDOCUMENT:
      '/attorney/petitions/lead-details/upload-document/dependent',
    VIEWEDITCONTRACTS:
      '/attorney/petitions/lead-details/contract-payment/view-edit',
  },
  MANAGEORGANIZATION: {
    ORGANIZATIONADD: '/attorney/setup/manage-organization/add-organization',
    ORGANIZATIONASSOCIATED: {
      ORGANIZATIONASSOCIATEDCONTRACTPAYMENT:
        '/attorney/manage-organization/associated/payments',
      ORGANIZATIONASSOCIATEDIT:
        '/attorney/manage-organization/associated/edit-organization',
      ORGANIZATIONASSOCIATEDLEADDETAILS:
        '/attorney/manage-organization/associated/leadOverview',
      ORGANIZATIONASSOCIATEDOVERVIEW:
        '/attorney/manage-organization/associated/overview',
      ORGANIZATIONASSOCIATEDUPLOADDOCUMENT:
        '/attorney/manage-organization/associated/upload-document',
      ORGANIZATIONASSOCITEDLEAD:
        '/attorney/manage-organization/associated/leads',
      ORGANIZATIONASSOCITEDPETITION:
        '/attorney/manage-organization/associated/my-petitions',
      ORGANIZATIONASSOCITEDPETITIONDETAILS:
        '/attorney/manage-organization/associated/petitionOverview',
      ORGANIZATIONASSOCITEDSTRATEGY:
        '/attorney/manage-organization/associated/strategy',
      ORGANIZATIONASSOCITEDSTRATEGYDETAIL:
        '/attorney/manage-organization/associated/strategy/details',
    },
    ORGANIZATIONINVITED: {
      ORGANIZATIONEDIT:
        '/attorney/manage-organization/invited/edit-organization',
      ORGANIZATIONINVITEDCONTRACTPAYMENT:
        '/attorney/manage-organization/invited/payments',
      ORGANIZATIONINVITEDLEAD: '/attorney/manage-organization/invited/leads',
      ORGANIZATIONINVITEDLEADDETAILS:
        '/attorney/manage-organization/invited/leadOverview',
      ORGANIZATIONINVITEDOVERVIEW:
        '/attorney/manage-organization/invited/overview',
      ORGANIZATIONINVITEDPETITION:
        '/attorney/manage-organization/invited/my-petitions',
      ORGANIZATIONINVITEDPETITIONDETAILS:
        '/attorney/manage-organization/invited/petitionOverview',
      ORGANIZATIONINVITEDSTRATEGY:
        '/attorney/manage-organization/invited/strategy',
      ORGANIZATIONINVITEDSTRATEGYDETAIL:
        '/attorney/manage-organization/invited/strategy/details',
      ORGANIZATIONINVITEDUPLOADDOCUMENT:
        '/attorney/manage-organization/invited/upload-document',
    },
    ORGANIZATIONLIST: '/attorney/manage-organization',
  },
  MANAGEUSER: {
    MANAGEUSERLIST: '/attorney/setup/manage-users',
    USERADD: '/attorney/setup/manage-users/add-user',
    USEREDIT: '/attorney/setup/manage-users/edit-user',
  },
  MYFIRM: '/attorney/setup/my-firm',
  MYPROFILE: '/attorney/profile',
  PAYMENT: {
    ACH: '/secure-payment/transfer/ach',
    CONTRACTVIEWER: '/secure-payment/contract-viewer',
    CREDITDEBITCARD: '/secure-payment/transfer/card',
    PAYMENTTERMSANDCONDITIONS: '/secure-payment/payment-option',
    SECUREPAYMENT: '/secure-payment',
    STRIPEINPROCESS: '/secure-payment/processing',
    STRIPESUCCESS: '/secure-payment/success',
  },
  PETITIONS: {
    ACCEPTORREJECT:
      '/attorney/petitions/my-petitions/build-case/accept-or-reject',
    ADDREQUESTDOCUMENT:
      '/attorney/petitions/request-documents/request-additional-documents',
    ASSEMBLE: '/attorney/petitions/my-petitions/build-case/assemble',
    BRIEFCOVER: '/attorney/petitions/my-petitions/build-case/brief-cover',
    BUILDCASE: '/attorney/petitions/my-petitions/build-case/generate-document',
    BUILDCASEADDITIONALDOCUMENT:
      '/attorney/petitions/my-petitions/build-case/generate-document/additional-doc',
    BUILDCASEDEPENDENT:
      '/attorney/petitions/my-petitions/build-case/generate-document/depentent',
    BUILDCASEEDIT: '/attorney/petitions/my-petitions/build-case/edit',
    COMPLETEQUESTIONS: '/attorney/petitions/overview/complete-question',
    CONTRACTPAYMENT: '/attorney/petitions/overview/contract-payment',
    CONTRACTPAYMENTDETAILS:
      '/attorney/petitions/overview/contract-payment/details',
    MYPETITIONLISTS: '/attorney/petitions/my-petitions',
    PAYMENTREPORT: '/attorney/petitions/payment-report',
    PETITIONADD: '/attorney/petitions/start-new-petition',
    PETITIONCASEDETAIL: '/attorney/petitions/overview/case-details',
    PETITIONEDIT: '/attorney/petitions/edit-petiton',
    PETITIONLIST: '/attorney/petitions/overview',
    PETITIONLeadContractEdit: '/attorney/petitions/leads/edit-leads:leadId',
    PETITIONLeadContractEditPARAM: '/attorney/petitions/leads/edit-leads',
    PETITIONREVIEWLIST: '/attorney/petitions/petition-details/review-document',
    PIXLEX: '/attorney/petitions/my-petitions/pixlex',
    STRATEGY: '/attorney/petitions/petition-details/strategy',
    STRATEGYDETAIL: '/attorney/petitions/petition-details/strategy/details',
    SUBMISSIONSTATION:
      '/attorney/petitions/my-petitions/build-case/submission-station',
    UPLOADPETITIONSDOCUMENT:
      '/attorney/petitions/petition-details/upload-document',
  },
  POLICY: {
    ACCEPTABLEUSEPOLICY: '/acceptable-use-policy',
    DMCAPOLICY: '/dmca-policy',
    PRIVACYPOLICY: '/privacy-policy',
    TERMOFSERVICES: '/client-agreement',
    VERSIONHISTORY: '/version-history/details',
  },
  QUESTION: {
    CONFIGUREQUESTION: '/attorney/setup/configure-questions',
    QUESTIONADD: '/attorney/setup/configure-questions/add-question',
    QUESTIONEDIT: '/attorney/setup/configure-questions/edit-question',
  },
  REPORTS: {
    REPORTSLIST: '/attorney/reports',
  },
  RESOURCE: {
    RESOURCESTATION: '/attorney/setup/resource-station',
    RESOURCESTATIONADD: '/attorney/setup/resource-station/add-resource-station',
    RESOURCESTATIONEDIT: '/attorney/setup/resource-station/edit-template',
  },
  RFERESPOEND: {
    RFERESPONDER: '/attorney/petitions/my-petitions/build-case/rfe-responder',
    RFERESPONDERACCEPT:
      '/attorney/petitions/my-petitions/build-case/rfe-response-accept',
    RFEUPLOAD: '/attorney/petitions/my-petitions/build-case/rfe-upload',
    RFEVIEW: '/attorney/petitions/my-petitions/build-case/rfe-viewer',
  },
  ROLES: {
    ROLESADD: '/attorney/setup/roles-permissions/add-roles-permissions',
    ROLESEDIT: '/attorney/setup/roles-permissions/edit-roles-permissions',
    ROLESPERMISSION: '/attorney/setup/roles-permissions',
  },
  SECTION: {
    CONFIGURESECTION: '/attorney/setup/configure-section',
    SECTIONADD: '/attorney/setup/configure-section/add',
    SECTIONEDIT: '/attorney/setup/configure-section/edit-section',
  },
  SOCIALSHARE: '/attorney/social',
  SUBSCRIPTIONPLAN: '/attorney/setup/my-subscription',
  WINNINGSTRATEGY: '/attorney/winning-strategy',
};

export const BeneficiaryPrivateRoutes = {
  BENEFICIARYCASEDETAIL: '/beneficiary/dashboard/overview/case-details',
  BENEFICIARYCONTRACTHISTORY:
    '/beneficiary/petitions/lead-details/overview/contract-history',
  BENEFICIARYDETAILLIST: '/beneficiary/dashboard/overview',
  BENEFICIARYLEADCONTRACTHISTORY: '/beneficiary/dashboard/contract-history',
  BENEFICIARYLEADSTRATEGY: '/beneficiary/petitions/lead-details/strategy',
  BENEFICIARYPETITIONERSTRATEGYDETAIL:
    '/beneficiary/petitions/petition-details/strategy/details',
  BENEFICIARYREVIEWDOCUMENT: '/beneficiary/dashboard/review-document',
  BENEFICIARYSTRATEGY: '/beneficiary/petitions/petition-details/strategy',
  BENEFICIARYSTRATEGYDETAIL:
    '/beneficiary/petitions/lead-details/strategy/details',
  BUILDCASEEDIT: '/beneficiary/petitions/petition-details/build-case/edit',
  CHANGEPASSWORD: '/beneficiary/change-password',
  COMMENTS: '/beneficiary/comments',
  COMPLETEQUESTIONS: '/beneficiary/petitions/overview/complete-question',
  DASHBOARD: '/beneficiary/dashboard',
  DOCUMENTREVIEW: '/beneficiary/documentreview',
  LEADCOMPLETEQUESTIONS:
    '/beneficiary/petitions/lead-details/complete-question',

  MYPROFILE: '/beneficiary/profile',
  PAYMENT: {
    ACH: '/secure-payment/transfer/ach',
    CONTRACTVIEWER: '/secure-payment/contract-viewer',
    CREDITDEBITCARD: '/secure-payment/transfer/card',
    PAYMENTTERMSANDCONDITIONS: '/secure-payment/payment-option',
    SECUREPAYMENT: '/secure-payment',
    STRIPEINPROCESS: '/secure-payment/processing',
    STRIPESUCCESS: '/secure-payment/success',
  },
  POLICY: {
    ACCEPTABLEUSEPOLICY: '/acceptable-use-policy',
    DMCAPOLICY: '/dmca-policy',
    PRIVACYPOLICY: '/privacy-policy',
    TERMOFSERVICES: '/client-agreement',
    VERSIONHISTORY: '/version-history/details',
  },
  REVIEWANDAPPROVE: '/beneficiary/petitions/petition-details/review-approve',
  SOCIALSHARE: '/beneficiary/social',
  SUBMISSIONSTATION:
    '/beneficiary/petitions/petition-details/build-case/submission-station',
  UPLOADDOCUMENT: '/beneficiary/petitions/lead-details/upload-document',
  UPLOADPETITIONSDOCUMENT:
    '/beneficiary/petitions/petitions-details/upload-document',
};

export const PetitionerPrivateRoutes = {
  CHANGEPASSWORD: '/petitioner/change-password',
  DASHBOARD: '/petitioner/dashboard',
  LEADS: {
    CONTRACTPAYMENT: '/petitioner/petitions/lead-details/contract-payment',
    CONTRACTPAYMENTDETAILS:
      '/petitioner/petitions/lead-details/contract-payment/details',
    LEADDETAILSLIST: '/petitioner/petitions/lead-details',
    LEADSADD: '/petitioner/petitions/add-leads',
    LEADSEDIT: '/petitioner/petitions/edit-leads',
    LEADSLIST: '/petitioner/petitions/leads',
    STRATEGY: '/petitioner/petitions/lead-details/strategy',
    STRATEGYDETAIL: '/petitioner/petitions/lead-details/strategy/details',
    UPLOADDOCUMENT: '/petitioner/petitions/lead-details/upload-document',
  },
  MANAGEUSER: {
    MANAGEUSERLIST: '/petitioner/setup/manage-users',
    USERADD: '/petitioner/setup/manage-users/add-user',
    USEREDIT: '/petitioner/setup/manage-users/edit-user',
  },
  MYORGANIZATION: '/petitioner/setup/my-organization',
  MYORGANIZATIONEDIT: '/petitioner/setup/my-organization/edit',
  MYPROFILE: '/petitioner/profile',
  PAYMENT: {
    ACH: '/secure-payment/transfer/ach',
    CONTRACTVIEWER: '/secure-payment/contract-viewer',
    CREDITDEBITCARD: '/secure-payment/transfer/card',
    PAYMENTTERMSANDCONDITIONS: '/secure-payment/payment-option',
    SECUREPAYMENT: '/secure-payment',
    STRIPEINPROCESS: '/secure-payment/processing',
    STRIPESUCCESS: '/secure-payment/success',
  },
  PETITIONS: {
    ASSOCIATELAWFIRM: '/petitioner/setup/associated-law-firm',
    BUILDCASEEDIT: '/petitioner/petitions/petition-details/build-case/edit',
    COMPLETEQUESTIONS: '/petitioner/petitions/overview/complete-question',
    CONTRACTPAYMENT: '/petitioner/petitions/overview/contract-payment',
    MYPETITIONLISTS: '/petitioner/petitions/my-petitions',
    PETITIONADD: '/petitioner/petitions/start-new-petition',
    PETITIONCASEDETAIL: '/petitioner/petitions/overview/case-details',
    PETITIONEDIT: '/petitioner/petitions/edit-petiton',
    PETITIONLIST: '/petitioner/petitions/overview',
    REVIEWANDAPPROVE:
      '/petitioner/petitions/petition-details/build-case/review-approve',
    STRATEGY: '/petitioner/petitions/petition-details/strategy',
    STRATEGYDETAIL: '/petitioner/petitions/petition-details/strategy/details',
    SUBMISSIONSTATION:
      '/petitioner/petitions/petition-details/build-case/submission-station',
    UPLOADDOCUMENT: '/petitioner/petitions/petitions-details/upload-document',
    // CONTRACTPAYMENTDETAILS:
    //   '/petitioner/petitions/overview/contract-payment/details',
  },
  POLICY: {
    ACCEPTABLEUSEPOLICY: '/acceptable-use-policy',
    DMCAPOLICY: '/dmca-policy',
    PRIVACYPOLICY: '/privacy-policy',
    TERMOFSERVICES: '/client-agreement',
    VERSIONHISTORY: '/version-history/details',
  },
  ROLES: {
    ROLESADD: '/petitioner/setup/roles-permissions/add-roles-permissions',
    ROLESEDIT: '/petitioner/setup/roles-permissions/edit-roles-permissions',
    ROLESPERMISSION: '/petitioner/setup/roles-permissions',
  },
  SOCIALSHARE: '/petitioner/social',
};

export const AffilatePrivateRoutes = {
  ATTORNEY_LEADS: {
    CONTRACTPAYMENT: '/affilate/petitions/lead-details/contract-payment',
    CONTRACTPAYMENTDETAILS:
      '/affilate/petitions/lead-details/contract-payment/details',
    LEADDETAILSLIST: '/affilate/lead-details',
    LEADSEDIT: '/affilate/edit-leads',
    LEADSLIST: '/affilate/leads',
    STRATEGY: '/affilate/lead-details/strategy',
    STRATEGYDETAIL: '/affilate/petition-details/strategy/details',
    UPLOADDOCUMENT: '/affilate/lead-details/upload-document',
  },
  ATTORNEY_PETITIONS: {
    ACCEPTORREJECT:
      '/affilate/petitions/my-petitions/build-case/accept-or-reject',
    ADDREQUESTDOCUMENT:
      '/affilate/petitions/request-documents/request-additional-documents',
    ASSEMBLE: '/affilate/petitions/my-petitions/build-case/assemble',
    BRIEFCOVER: '/affilate/petitions/my-petitions/build-case/brief-cover',
    BUILDCASE: '/affilate/petitions/my-petitions/build-case/generate-document',
    BUILDCASEADDITIONALDOCUMENT:
      '/affilate/petitions/my-petitions/build-case/generate-document/additional-doc',
    BUILDCASEDEPENDENT:
      '/affilate/petitions/my-petitions/build-case/generate-document/depentent',
    BUILDCASEEDIT: '/affilate/petitions/my-petitions/build-case/edit',
    COMPLETEQUESTIONS: '/affilate/petitions/overview/complete-question',
    CONTRACTPAYMENT: '/affilate/petitions/overview/contract-payment',
    CONTRACTPAYMENTDETAILS:
      '/affilate/petitions/overview/contract-payment/details',
    MYPETITIONLISTS: '/affilate/petitions/my-petitions',
    PAYMENTREPORT: '/affilate/petitions/payment-report',
    PETITIONADD: '/affilate/petitions/start-new-petition',
    PETITIONCASEDETAIL: '/affilate/petitions/overview/case-details',
    PETITIONEDIT: '/affilate/petitions/edit-petiton',
    PETITIONLIST: '/affilate/petitions/overview',
    PETITIONLeadContractEdit: '/affilate/petitions/leads/edit-leads:leadId',
    PETITIONLeadContractEditPARAM: '/affilate/petitions/leads/edit-leads',
    PETITIONREVIEWLIST: '/affilate/petitions/lead-details/review-document',
    PIXLEX: '/affilate/petitions/my-petitions/pixlex',
    STRATEGY: '/affilate/petitions/petition-details/strategy',
    STRATEGYDETAIL: '/affilate/petitions/petition-details/strategy/details',
    SUBMISSIONSTATION:
      '/affilate/petitions/my-petitions/build-case/submission-station',
    UPLOADPETITIONSDOCUMENT:
      '/affilate/petitions/petition-details/upload-document',
  },
  BENEFICIARYROUTE: {
    BENEFICIARYDETAILLIST: '/affilate/dashboard/overview',
    BUILDCASEEDIT: '/affilate/petitions/petition-details/build-case/edit',
  },
  CHANGEPASSWORD: '/affilate/change-password',
  DASHBOARD: '/affilate/dashboard',
  MYPROFILE: '/affilate/profile',
  PAYMENT: {
    ACH: '/secure-payment/transfer/ach',
    CONTRACTVIEWER: '/secure-payment/contract-viewer',
    CREDITDEBITCARD: '/secure-payment/transfer/card',
    PAYMENTTERMSANDCONDITIONS: '/secure-payment/payment-option',
    SECUREPAYMENT: '/secure-payment',
    STRIPEINPROCESS: '/secure-payment/processing',
    STRIPESUCCESS: '/secure-payment/success',
  },
  PETITIONER_LEADS: {
    CONTRACTPAYMENT: '/affilate/petitions/lead-details/contract-payment',
    CONTRACTPAYMENTDETAILS:
      '/affilate/petitions/lead-details/contract-payment/details',
    LEADDETAILSLIST: '/affilate/petitions/lead-details',
    LEADSEDIT: '/affilate/petitions/edit-leads',
    LEADSLIST: '/affilate/petitions/leads',
    STRATEGY: '/affilate/petitions/lead-details/strategy',
    STRATEGYDETAIL: '/affilate/petitions/lead-details/strategy/details',
    UPLOADDOCUMENT: '/affilate/petitions/lead-details/upload-document',
  },
  PETITIONER_PETITIONS: {
    ASSOCIATELAWFIRM: '/affilate/setup/associated-law-firm',
    BUILDCASEEDIT: '/affilate/petitions/petition-details/build-case/edit',
    COMPLETEQUESTIONS: '/affilate/petitions/overview/complete-question',
    CONTRACTPAYMENT: '/affilate/petitions/overview/contract-payment',
    MYPETITIONLISTS: '/affilate/petitions/my-petitions',
    PETITIONADD: '/affilate/petitions/start-new-petition',
    PETITIONCASEDETAIL: '/affilate/petitions/overview/case-details',
    PETITIONEDIT: '/affilate/petitions/edit-petiton',
    PETITIONLIST: '/affilate/petitions/overview',
    REVIEWANDAPPROVE:
      '/affilate/petitions/petition-details/build-case/review-approve',
    STRATEGY: '/affilate/petitions/petition-details/strategy',
    STRATEGYDETAIL: '/affilate/petitions/petition-details/strategy/details',
    SUBMISSIONSTATION:
      '/affilate/petitions/petition-details/build-case/submission-station',
    UPLOADDOCUMENT: '/affilate/petitions/petitions-details/upload-document',
    // CONTRACTPAYMENTDETAILS:
    //   '/affilate/petitions/overview/contract-payment/details',
  },
  POLICY: {
    ACCEPTABLEUSEPOLICY: '/acceptable-use-policy',
    DMCAPOLICY: '/dmca-policy',
    PRIVACYPOLICY: '/privacy-policy',
    TERMOFSERVICES: '/client-agreement',
    VERSIONHISTORY: '/version-history/details',
  },
};

export const CommonPrivateRoutes = {
  ASSEMBLEPREVIEW: '/assemble/preview/document',
  SUBMISSIONSTATION: '/build-case/submission-station',
};
