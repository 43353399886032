import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';

type ModalProps = {
  open?: any;
  close?: any;
  title?: string;
  subTitle?: string;
  children?: any;
  onConfirm?: any;
  floatingBtnShow?: any;
  autoCloseDisable?: boolean;
  buildForm?: string;
  selectForm?: string;
  visibleClose?: boolean;
  selctionButton?: boolean;
};

const ConfirmDialog: React.FC<ModalProps> = ({
  open,
  close,
  title,
  subTitle,
  children,
  onConfirm,
  floatingBtnShow,
  buildForm,
  selectForm,
  selctionButton,
  autoCloseDisable = false,
  visibleClose = false,
}) => {
  // useEffect(() => {
  //   const listener = (event) => {
  //     if (event.code === 'Enter') {
  //       event.preventDefault();
  //       onConfirm('yes');
  //     } else if (event.code === 'Space') {
  //       event.preventDefault();
  //       onConfirm('no');
  //     }
  //   };
  //   document.addEventListener('keydown', listener);
  //   return () => {
  //     document.removeEventListener('keydown', listener);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <Modal
      open={open}
      {...(autoCloseDisable ? {} : { onClose: () => close() })}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className="custom-pay-modal">
        {visibleClose && (
          <Typography component={'div'}>
            <CancelIcon className="cancel-icon" onClick={() => close()} />
          </Typography>
        )}
        <Typography className="f-20 font-bold text-center">{title}</Typography>
        <Typography className="f-15 text-center pm-txt">{children}</Typography>

        {floatingBtnShow && (
          <Typography component={'div'} className="d-flex-ja mt-1 ">
            <Button
              className="n-cancel-btn mr-1"
              sx={{
                backgroundColor: '#bcc7dc !important',
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '0.9em !important',
                height: '35px !important',
                width: '149px !important',
              }}
              onClick={() => onConfirm('no')}>
              {selectForm ? 'MyFirm' : 'No'}
            </Button>
            <Button
              className="n-add-btn"
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '0.9em !important',
                height: '35px !important',
                width: '149px !important',
              }}
              onClick={() => onConfirm('yes')}
              autoFocus>
              {buildForm ? 'Build Now' : 'yes'}
            </Button>
          </Typography>
        )}
        {selctionButton && (
          <Typography component={'div'} className="d-flex-ja mt-1 d-flex-sa">
            <Button
              className="n-add-btn"
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '0.9em !important',
                height: '35px !important',
                width: '150px !important',
              }}
              onClick={() => onConfirm('individual')}
              autoFocus>
              {'Previous Petitions'}
            </Button>
            <Button
              className="n-add-btn"
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '0.9em !important',
                height: '35px !important',
                width: '150px !important',
              }}
              onClick={() => onConfirm('overall')}
              autoFocus>
              {'Organization'}
            </Button>
          </Typography>
        )}
      </Box>
    </Modal>
  );
};
export default ConfirmDialog;
